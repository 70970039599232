import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { stylesToGridData } from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/propertyUtils'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import LineCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/LineCellRenderer'
import FontFamilyCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/FontFamilyCellRenderer'
import SliderCellEditor from 'components/common/CellEditors/SliderCellEditor'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StylesGrid = forwardRef(({ elementStyle, handleUpdate }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      return gridData
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        maxWidth: 200,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'customDropDownLine') {
            return {
              component: 'agRichSelectCellEditor',
              popup: true,
              params: {
                cellRenderer: LineCellRenderer,
                values: params.data?.dropDownValues,
                valueListMaxHeight: 125,
              },
            }
          }

          if (params.data?.type === 'customDropDownFontFamily') {
            return {
              component: 'agRichSelectCellEditor',
              popup: true,
              params: {
                cellRenderer: FontFamilyCellRenderer,
                values: params.data?.dropDownValues,
                valueListMaxHeight: 125,
              },
            }
          }

          if (params.data?.type === 'color') {
            return {
              component: ColorPickerCellEditor,
              params: {
                color: params.value,
                defaultColor: params.data?.defaultValue,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'slider') {
            return {
              component: SliderCellEditor,
              params: {
                cellRenderer: SliderCellEditor,
                min: params.data?.min,
                max: params.data?.max,
                step: params.data?.step,
              },
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index].value = params.newValue
          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          if (params.data?.hasCustomRenderer) {
            return {
              component: 'colorCellRenderer',
              params: {
                width: params.data?.value,
              },
            }
          }

          if (params.data?.type === 'customDropDownLine') {
            return {
              component: LineCellRenderer,
            }
          }

          if (params.data?.type === 'customDropDownFontFamily') {
            return {
              component: FontFamilyCellRenderer,
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, params.data?.precision || 0)}`
          }
          return params?.data?.value
        },
      },
    ],
    [],
  )

  const getPropertyData = (data) => {
    let propertyData = []
    if (!data) return propertyData

    if (data.hasOwnProperty('color'))
      propertyData.push({ label: `Text Color`, value: '#3333', type: 'color', tag: 'color', defaultValue: '#333333' })
    if (data.hasOwnProperty('backgroundColor'))
      propertyData.push({
        label: `Background Color`,
        value: '#fff',
        type: 'color',
        tag: `backgroundColor`,
        defaultValue: '#fff',
      })
    if (data.hasOwnProperty('opacity'))
      propertyData.push({ label: `Opacity`, value: 1, type: 'slider', tag: `opacity`, min: 0.05, max: 1, step: 0.05 })
    if (data.hasOwnProperty('borderColor'))
      propertyData.push({
        label: `Border Color`,
        value: '#333',
        type: 'color',
        tag: `borderColor`,
        defaultValue: '#333',
      })
    if (data.hasOwnProperty('borderStyle'))
      propertyData.push({
        label: `Border Style`,
        value: 'solid',
        type: 'customDropDownLine',
        tag: `borderStyle`,
        dropDownValues: ['none', 'solid', 'dotted', 'dashed'],
      })
    if (data.hasOwnProperty('borderWidth'))
      propertyData.push({
        label: `Border Width`,
        value: 1,
        type: 'number',
        tag: `borderWidth`,
        min: 1,
        max: 10,
        precision: 0,
      })
    if (data.hasOwnProperty('fontFamily'))
      propertyData.push({
        label: `Font`,
        value: 'Arial',
        type: 'customDropDownFontFamily',
        tag: `fontFamily`,
        dropDownValues: [
          'Times New Roman',
          'Arial',
          'Verdana',
          'Helvetica',
          'Courier New',
          'Georgia',
          'Palatino',
          'Lucida Console',
        ],
      })
    if (data.hasOwnProperty('fontSize'))
      propertyData.push({
        label: `Font Size`,
        value: 14,
        type: 'number',
        tag: `fontSize`,
        min: 8,
        max: 72,
        precision: 0,
      })
    if (data.hasOwnProperty('fontWeight'))
      propertyData.push({
        label: `Font Weight`,
        value: '400 - normal',
        type: 'dropDown',
        tag: `fontWeight`,
        dropDownValues: [
          '100 - lightest',
          '200',
          '300',
          '400 - normal',
          '500',
          '600',
          '700 - bold',
          '800',
          '900 - boldest',
        ],
      })
    if (data.hasOwnProperty('fontStyle'))
      propertyData.push({
        label: `Italic`,
        value: false,
        type: 'bool',
        tag: `fontStyle`,
      })
    if (data.hasOwnProperty('justifyContent'))
      propertyData.push({
        label: `Horizontal Align`,
        value: 'center',
        type: 'dropDown',
        tag: `horizontalAlign`,
        dropDownValues: ['left', 'center', 'right'],
      })
    if (data.hasOwnProperty('alignItems'))
      propertyData.push({
        label: `Vertical Align`,
        value: 'middle',
        type: 'dropDown',
        tag: `verticalAlign`,
        dropDownValues: ['top', 'middle', 'bottom'],
      })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    const gridData = stylesToGridData(data)

    for (let i = 0; i < propertyData.length; i++) {
      if (propertyData[i].tag === '') continue
      if (!gridData.hasOwnProperty(propertyData[i].tag)) continue
      propertyData[i].value = gridData[propertyData[i].tag]
    }

    return propertyData
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getPropertyData(elementStyle)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
})

export default StylesGrid
