import React, { useState, useRef, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import InputModal from 'components/common/InputModal'
import ConfirmDialog from 'components/common/ConfirmDialog'
import * as yup from 'yup'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useSoloCloud from 'components/common/hooks/useSoloCloud'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { appColors } from 'utils'

const CredentialsModal = ({ submitFunction, cancelFunction }) => {
  const formValidationSchema = yup.object({
    userName: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  })

  let formData = [
    {
      tag: 'userName',
      value: '',
      text: 'Username',
      inputType: 'text',
    },
    {
      tag: 'password',
      value: '',
      text: 'Password',
      inputType: 'text',
    },
  ]

  return (
    <InputModal
      open={true}
      onClose={cancelFunction}
      title={'Enter Credentials'}
      formData={formData}
      submitForm={submitFunction}
      cancelFunction={cancelFunction}
      validationSchema={formValidationSchema}
    />
  )
}

const SoloCloudCredentials = ({ creds, updateCreds, setStatus, config }) => {
  const [showUpdateCredsModal, setShowUpdateCredsModal] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const isValidating = useRef(false)
  const _isMounted = useRef(true)
  const { getBackColor, getWindowBarColor } = useInnovaTheme()

  const {
    handleAcceptTargetLine,
    getConfigTargetLine,
    updatingSoloCloud,
    getSoloCloudErrors,
    getSoloCloudWarnings,
    getSoloCloudSuccess,
  } = useSoloCloud()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (getSoloCloudErrors() !== '') setStatus({ show: true, severity: 'error', message: getSoloCloudErrors() })
    if (getSoloCloudWarnings() !== '' && getSoloCloudErrors() === '')
      setStatus({ show: true, severity: 'warning', message: getSoloCloudWarnings() })
    if (getSoloCloudSuccess() !== '') setStatus({ show: true, severity: 'success', message: getSoloCloudSuccess() })
  }, [getSoloCloudErrors, getSoloCloudWarnings, getSoloCloudSuccess, setStatus])

  const token = useInnovaAxios({
    url: '/solocloud/token',
  })

  const ValidateCredentials = async (newCreds) => {
    if (!newCreds) return false
    if (!newCreds.hasOwnProperty('userName') || !newCreds.hasOwnProperty('password')) return false

    if (isValidating.current) return false
    if (!_isMounted.current) return false

    isValidating.current = true
    let res = await token({ authUser: newCreds.userName, password: newCreds.password })
    if (!_isMounted.current) return false
    isValidating.current = false

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return false
    }

    if (res?.data?.access_token === '') {
      setStatus({ show: true, severity: 'error', message: `Credentials invalid` })
      return false
    }

    return true
  }

  const HandleUpdateCreds = async (newCreds) => {
    let resCreds = await ValidateCredentials(newCreds)
    if (!resCreds) return

    setShowUpdateCredsModal(false)
    updateCreds(newCreds)
  }

  const handleClearCredentials = () => {
    updateCreds({ userName: '', password: '' })
  }

  const textFieldStyle = {
    backgroundColor: getBackColor(),
    color:  appColors.itemTextColor,
    margin: '5px',
    paddingLeft: '15px',
    paddingTop: '15px',
    height: '40px',
    '& label': {
      left: '0.1em',
      top: '0.25em',
      fontSize: '0.8rem',
    },
    readOnly: true,
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '175px',
        backgroundColor: getWindowBarColor(),
        overflow: 'hidden',
        border: '1px solid gray',
      }}>
      {showUpdateCredsModal && (
        <CredentialsModal
          submitFunction={(newCreds) => {
            HandleUpdateCreds(newCreds)
          }}
          cancelFunction={() => setShowUpdateCredsModal(false)}
        />
      )}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Box sx={textFieldStyle}>{`User Name: ${creds?.userName}`}</Box>
        <Box sx={textFieldStyle}>{`Password: ${creds?.password}`}</Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            flex: 1,
            backgroundColor: getBackColor(),
          }}>
          <Button variant='outlined' color={creds?.valid ? 'success' : 'error'} sx={{ margin: '5px' }}>
            {creds.valid ? 'CONNECTED' : 'DISCONNECTED'}
          </Button>
          <Button variant='contained' sx={{ margin: '5px' }} onClick={() => setShowUpdateCredsModal(true)}>
            UPDATE
          </Button>
          <Button
            variant='contained'
            sx={{ margin: '5px' }}
            onClick={() =>
              setConfirm({
                show: true,
                title: 'Clear Credentials',
                text: `Are you sure you want to clear credentials?`,
                onConfirm: handleClearCredentials,
              })
            }>
            CLEAR
          </Button>
          <Button
            variant='outlined'
            color={config.targetLineUuid && !updatingSoloCloud ? 'success' : 'error'}
            sx={{ margin: '5px' }}
            onClick={() => {
              if (!config.targetLineUuid && !updatingSoloCloud) return
              handleAcceptTargetLine(true, getConfigTargetLine())
            }}>
            New Offset Plan
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default SoloCloudCredentials
