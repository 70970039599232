import React, { useEffect, useRef, useState, useMemo } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { selectedEngineeringCaseAtom } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { Tooltip, Box, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledDeleteIcon = styled(Iconify)({
  color: '#C00000',
  fontSize: '16px',
})

const StyledAddIcon = styled(Iconify)({
  color: '#00C000',
  fontSize: '16px',
})

const StyledIconHeader = styled(IconButton)({
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'center',
})

const FlowRatesEditor = () => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { getUnitsText } = useUnits()
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const inputRow = useRef({ actualWell: selectedEngCase?.actualwell, recordId: selectedEngCase?.recordId })
  const { getAgGridTheme } = useInnovaTheme()

  const getData = useInnovaAxios({
    url: '/engineering/getFlowRates',
  })

  const addData = useInnovaAxios({
    url: '/engineering/addFlowRate',
  })

  const updateData = useInnovaAxios({
    url: '/engineering/updateFlowRate',
  })

  const deleteData = useInnovaAxios({
    url: '/engineering/deleteFlowRate',
  })

  const isCurEngCaseValid = () => {
    if (selectedEngCase === null || selectedEngCase === undefined) return false
    if (!selectedEngCase.hasOwnProperty('recordId')) return false
    if (!selectedEngCase.hasOwnProperty('actualwell')) return false
    if (typeof selectedEngCase.actualwell !== 'string') return false
    if (selectedEngCase.actualwell === '') return false
    return true
  }

  const handleDelete = async (sequenceNumbers) => {
    if (!Array.isArray(sequenceNumbers)) return
    if (sequenceNumbers.length === 0) return
    if (!gridApi.current) return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (!selectedEngCase.hasOwnProperty('actualwell')) return
    if (typeof selectedEngCase.actualwell !== 'string') return
    if (selectedEngCase.actualwell === '') return

    gridApi.current.applyTransaction({ remove: sequenceNumbers })

    let seqNumStr = ''
    for (let i = 0; i < sequenceNumbers.length; i++) {
      if (seqNumStr !== '') seqNumStr += '|'
      seqNumStr += sequenceNumbers[i].sequenceno
    }

    let payload = {
      actualWell: selectedEngCase.actualwell,
      recordId: selectedEngCase.recordId,
      sequenceno: seqNumStr,
    }

    setLoading(true)
    await deleteData(payload)
    if (_isMounted.current) {
      setLoading(false)
    }
  }

  const handleAddRow = async () => {
    if (isLoading) return
    if (!isPinnedRowDataCompleted()) return
    setLoading(true)
    let res = await addData(inputRow.current)
    if (_isMounted.current) {
      setLoading(false)
    }

    if (!res?.error) {
      inputRow.current.sequenceno = res.data.sequenceno

      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = { actualWell: selectedEngCase?.actualwell, recordId: selectedEngCase?.recordId }
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('sequenceno')) return
    if (isLoading) return

    let payload = {
      actualWell: data.actualwell,
      recordId: data.recordId,
      sequenceno: data.sequenceno,
      flowRate: data.flowRate,
    }

    setLoading(true)
    await updateData(payload)
    if (_isMounted.current) {
      setLoading(false)
    }
  }

  const fetchData = async () => {
    if (isLoading) return
    if (selectedEngCase === null || selectedEngCase === undefined) {
      if (_isMounted.current) setData([])
      return
    }
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (!selectedEngCase.hasOwnProperty('actualwell')) return
    if (typeof selectedEngCase.actualwell !== 'string') return
    if (selectedEngCase.actualwell === '') return

    let payload = { wellName: selectedEngCase.actualwell, engCase: selectedEngCase.recordId }
    setLoading(true)
    const response = await getData(payload)
    if (_isMounted.current) {
      setData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    inputRow.current = { actualWell: selectedEngCase?.actualwell, recordId: selectedEngCase?.recordId }

    return () => {
      _isMounted.current = false
    }
  }, [selectedEngCase]) // eslint-disable-line react-hooks/exhaustive-deps

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <StyledIconHeader
              onClick={() =>
                params.node?.rowPinned !== 'bottom'
                  ? handleDelete([{ sequenceno: params.data?.sequenceno }])
                  : handleAddRow()
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <StyledDeleteIcon icon='fa-regular:trash-alt' />
              ) : (
                <StyledAddIcon icon='fluent:add-12-filled' />
              )}
            </StyledIconHeader>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.sequenceno)
    }
  }, [])

  function createPinnedCellPlaceholder({ colDef }) {
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function isPinnedRowDataCompleted() {
    return columnDefs.every((def) => {
      if (def.field === 'actions') return true
      return inputRow.current[def.field]
    })
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    gridApi.applyTransaction({ update: itemsToUpdate })
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      autoHeight: true,
      flex: 1,
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const columnDefs = [
    {
      field: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      cellRendererSelector: () => {
        return {
          component: actionIconRenderer,
        }
      },
      cellStyle: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '4px !important',
        paddingRight: '4px !important',
        borderRight: '1px solid gray !important',
      },
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      headerName: `Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)})`,
      field: 'flowRate',
      maxWidth: '200',
      cellStyle: centerAlignCell,
      valueSetter: (params) => {
        if (/^(?:\d{1,4}(?:\.\d{1,2})?|10000(?:\.0{1,2})?)$/.test(params.newValue)) {
          let data = { ...params.data }
          data.flowRate = params.newValue
          if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
          if (params.node?.rowPinned === 'bottom') {
            inputRow.current[params.colDef.field] = params.newValue
          }
        }
        return true
      },
      editable: true,
    },
  ]

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 25px)' }}>
      {isCurEngCaseValid() ? (
        <AgGridReact
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          headerHeight={30}
        />
      ) : null}
    </div>
  )
}

export default FlowRatesEditor
