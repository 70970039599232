import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getRows = (params) => {
  if (!params.node.data.hasOwnProperty('detailData')) return []
  if (!Array.isArray(params.node.data.detailData)) return []

  const rows = [
    {
      outlineLevel: 1,
      cells: [
        cell(''),
        cell('Label', 'header'),
        cell('# Connections', 'header'),
        cell('Connection Time (min)', 'header'),
        cell('Btm to Slip (min)', 'header'),
        cell('Slip to Slip (min)', 'header'),
        cell('Slip to Btm (min)', 'header'),
      ],
    },
  ].concat(
    ...params.node.data?.detailData?.map((record) => [
      {
        outlineLevel: 1,
        cells: [
          cell(''),
          cell(record.label, 'body'),
          cell(record.numConnections, 'body'),
          cell(record.connection, 'body'),
          cell(record.btm2slp, 'body'),
          cell(record.slp2slp, 'body'),
          cell(record.slp2btm, 'body'),
        ],
      },
    ]),
  )
  return rows
}

const cell = (text, styleId) => {
  return {
    styleId: styleId,
    data: {
      type: /^\d+$/.test(text) ? 'Number' : 'String',
      value: String(text),
    },
  }
}

const ConnectionAnalsysisWellGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  const getConnectionData = useInnovaAxios({
    url: '/kpi/connectionsAnalysis',
  })

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('connectionAnalysisKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.connectionAnalysisKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const getConnectionTimeData = (conn) => {
    if (!conn) return null

    let btm2slp = conn.wt2WtTime - conn.surveyTime - conn.slp2SlpTime
    if (btm2slp < 0) btm2slp = 0
    let slp2btm = conn.surveyTime
    if (slp2btm < 0) slp2btm = 0

    return {
      btm2slp: btm2slp / 60,
      slp2slp: conn.slp2SlpTime / 60,
      slp2btm: slp2btm / 60,
      connection: conn.connectionTime / 60,
    }
  }

  const addNewConnectionData = (oldData, newData) => {
    if (!oldData) return
    if (!newData) return

    let output = { ...oldData }
    output.numConnections++
    output.connection += newData.connection
    output.btm2slp += newData.btm2slp
    output.slp2slp += newData.slp2slp
    output.slp2btm += newData.slp2btm
    return output
  }

  const detailSort = (a, b) => {
    if (a.label === 'day') return -1 // 'day' comes first
    if (b.label === 'day') return 1 // 'day' comes first
    if (a.label === 'night') return -1 // 'night' comes second
    if (b.label === 'night') return 1 // 'night' comes second

    // Sort by numbers in descending order
    const numA = parseFloat(a.label)
    const numB = parseFloat(b.label)

    return numB - numA
  }

  const getWellData = (data) => {
    if (!data) return null

    let output = {
      rank: 0,
      wellName: data.wellName,
      jobNum: data.jobNum,
      rig: data.rig,
      state: data.state,
      county: data.county,
      numConnections: 0,
      connection: 0,
      btm2slp: 0,
      slp2slp: 0,
      slp2btm: 0,
      detailData: [],
    }

    const detailMap = new Map()
    detailMap.set('day', { label: 'Day', numConnections: 0, connection: 0, btm2slp: 0, slp2slp: 0, slp2btm: 0 })
    detailMap.set('night', { label: 'Night', numConnections: 0, connection: 0, btm2slp: 0, slp2slp: 0, slp2btm: 0 })

    if (!Array.isArray(data.connections)) return output

    data.connections.forEach((conn) => {
      const connData = getConnectionTimeData(conn)
      if (connData) {
        output.numConnections++
        output.connection += connData.connection
        output.btm2slp += connData.btm2slp
        output.slp2slp += connData.slp2slp
        output.slp2btm += connData.slp2btm

        let dayNightData = detailMap.get(conn.dayShift ? 'day' : 'night')
        if (dayNightData) {
          dayNightData = addNewConnectionData(dayNightData, connData)
          detailMap.set(conn.dayShift ? 'day' : 'night', dayNightData)
        }

        if (!detailMap.has(conn.holeSize)) {
          detailMap.set(conn.holeSize, {
            label: `${conn.holeSize}`,
            numConnections: 0,
            connection: 0,
            btm2slp: 0,
            slp2slp: 0,
            slp2btm: 0,
          })
        }

        let holeSizeData = detailMap.get(conn.holeSize)
        if (holeSizeData) {
          holeSizeData = addNewConnectionData(holeSizeData, connData)
          detailMap.set(conn.holeSize, holeSizeData)
        }
      }
    })

    output.detailData = Array.from(detailMap.values())

    if (output.numConnections > 0) {
      output.connection = output.connection / output.numConnections
      output.btm2slp = output.btm2slp / output.numConnections
      output.slp2slp = output.slp2slp / output.numConnections
      output.slp2btm = output.slp2btm / output.numConnections

      for (let i = 0; i < output.detailData.length; i++) {
        output.detailData[i].connection = output.detailData[i].connection / output.detailData[i].numConnections
        output.detailData[i].btm2slp = output.detailData[i].btm2slp / output.detailData[i].numConnections
        output.detailData[i].slp2slp = output.detailData[i].slp2slp / output.detailData[i].numConnections
        output.detailData[i].slp2btm = output.detailData[i].slp2btm / output.detailData[i].numConnections
      }
    }

    output.detailData.sort(detailSort)
    return output
  }

  const summarizeWellData = (data) => {
    if (!Array.isArray(data)) return []

    let output = []
    for (let i = 0; i < data.length; i++) {
      const wellData = getWellData(data[i])
      if (wellData) output.push(wellData)
    }

    output.sort((a, b) => (a.connection > b.connection ? 1 : -1))
    for (let i = 0; i < data.length; i++) {
      output[i].rank = i + 1
    }

    return output
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const dataResponse = await getConnectionData({
      wellList: array2pipestr(selectedWells),
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }

    setTimeout(() => {
      autoSizeColumns()
    }, 500)
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const defaultColDef = {
    resizable: true,
    sortable: false,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
    cellStyle: centerAlignCell,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: '',
        autoHeight: true,
        minWidth: 60,
        maxWidth: 60,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: `Rank`,
        colId: 'rank',
        field: 'rank',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
      },
      {
        headerName: `Wellname`,
        colId: 'wellName',
        field: 'wellName',
      },
      {
        headerName: `Job Number`,
        colId: 'jobNum',
        field: 'jobNum',
      },
      {
        headerName: `Rig`,
        colId: 'rig',
        field: 'rig',
      },
      {
        headerName: `State`,
        colId: 'state',
        field: 'state',
      },
      {
        headerName: `County`,
        colId: 'county',
        field: 'county',
      },
      {
        headerName: `# Connections`,
        colId: 'numConnections',
        field: 'numConnections',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
      },
      {
        headerName: `Connection Time (min)`,
        colId: 'connection',
        field: 'connection',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `Btm to Slip (min)`,
        colId: 'btm2slp',
        field: 'btm2slp',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `Slip to Slip (min)`,
        colId: 'slp2slp',
        field: 'slp2slp',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `Slip to Btm (min)`,
        colId: 'slp2btm',
        field: 'slp2btm',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
    ],
    [],
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('connectionAnalysisKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            columnKeys: [
              'rank',
              'wellName',
              'jobNum',
              'rig',
              'state',
              'county',
              'numConnections',
              'connection',
              'btm2slp',
              'slp2slp',
              'slp2btm',
            ],
            getCustomContentBelowRow: (params) => getRows(params),
            fileName: 'Connection Analysis.xlsx',
            sheetName: 'Connections',
            columnWidth: 90,
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: 'Label',
            field: 'label',
            colId: 'label',
          },
          {
            headerName: `# Connections`,
            colId: 'numConnections',
            field: 'numConnections',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 0),
          },
          {
            headerName: `Connection Time (min)`,
            colId: 'connection',
            field: 'connection',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            headerName: `Btm to Slip (min)`,
            colId: 'btm2slp',
            field: 'btm2slp',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            headerName: `Slip to Slip (min)`,
            colId: 'slp2slp',
            field: 'slp2slp',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
          {
            headerName: `Slip to Btm (min)`,
            colId: 'slp2btm',
            field: 'slp2btm',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
          },
        ],
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data?.detailData)
      },
    }
  }, [])

  const excelStyles = useMemo(() => {
    return [
      {
        id: 'header',
        interior: {
          color: '#aaaaaa',
          pattern: 'Solid',
        },
      },
      {
        id: 'body',
        interior: {
          color: '#dddddd',
          pattern: 'Solid',
        },
      },
    ]
  }, [])

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        loading={isLoading}
        rowData={summarizeWellData(data)}
        columnDefs={sortColDefs(columnDefs, 'connectionAnalysisKpiGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        headerHeight={30}
        masterDetail={true}
        detailCellRendererParams={detailCellRendererParams}
        excelStyles={excelStyles}
      />
    </div>
  )
}

export default ConnectionAnalsysisWellGrid
