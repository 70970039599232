import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { Box, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import { Icon as Iconify } from '@iconify/react'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { pointInterp, pointInterpTVD } from 'utils/surveyFunctions'
import { uuidv4 } from 'utils/stringFunctions'
import Alert from '@mui/material/Alert'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AnnotationsPage = ({ wellName, isPlan = false }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const isFetchingWellbore = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [annotationsData, setAnnotationsData] = useState([])
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { getUnitsText } = useUnits()
  const surveys = useRef([])
  const wellNameRef = useRef(wellName)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme } = useInnovaTheme()

  const getWellbore = useInnovaAxios({
    url: isPlan ? '/plannedWell/getPlannedWellbore' : '/well/getActualWellbore',
  })

  const getData = useInnovaAxios({
    url: isPlan ? '/plannedWell/getAnnotations' : '/well/getAnnotations',
  })

  const addData = useInnovaAxios({
    url: isPlan ? '/plannedWell/addAnnotations' : '/well/addAnnotations',
  })

  const addMultiData = useInnovaAxios({
    url: '/well/addMultiAnnotations',
  })

  const updateData = useInnovaAxios({
    url: '/well/updateMultiAnnotations',
  })

  const deleteMultiData = useInnovaAxios({
    url: '/well/deleteMultiAnnotations',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchWellbore()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getData({ wellName: wellName })
    if (!_isMounted.current) return
    setLoading(false)
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!Array.isArray(res.data)) return
    for (let i = 0; i < res.data.length; i++) {
      res.data[i].uid = uuidv4() //Required for the delete / update
    }

    setAnnotationsData(res.data.sort(sortData))
  }

  const fetchWellbore = async () => {
    if (isFetchingWellbore.current) return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    isFetchingWellbore.current = true
    const res = await getWellbore({ wellName: wellName, planName: wellName, getSlideDistance: false })
    isFetchingWellbore.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    surveys.current = Array.isArray(res.data) ? res.data : []
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate([{ ...event.data, rowNum: event.node.rowIndex, column: event.colDef.colId }])
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push({ ...data, rowNum: i, column: 'NONE' })
    }

    handleUpdate(itemsToUpdate)
  }

  let reqFields = useMemo(() => ['md', 'tvd', 'annotation'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    if (!inputRow.current.hasOwnProperty('md') && !inputRow.current.hasOwnProperty('tvd')) return false
    if (!inputRow.current.hasOwnProperty('annotation')) return false

    return true
  }, [])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('annotationsGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('annotationsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Annotations.xlsx',
            sheetName: 'Annotation',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Delete Selected',
        disabled: false,
        action: () => {
          onClickDelete()
        },
        icon: '<span class="iconify" data-icon="fa-regular:trash-alt" data-width="20" style="color:#FF0000"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleDelete = useCallback(async () => {
    if (!gridApi.current) return
    if (isDeleting.current) return
    let cellRanges = gridApi.current.getCellRanges()
    if (!Array.isArray(cellRanges)) return
    if (cellRanges.length === 0) return

    let selectedRowNumbers = []
    cellRanges.forEach((range) => {
      let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
      let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)

      for (let i = startRowIndex; i <= endRowIndex; i++) {
        selectedRowNumbers.push(i)
      }
    })

    if (selectedRowNumbers.length === 0) return

    let selectedData = []
    gridApi.current.forEachNode((node) => {
      if (node.data) {
        if (selectedRowNumbers.findIndex((rowNum) => rowNum === node.rowIndex) >= 0) {
          selectedData.push({ ...node.data, rowNum: node.rowIndex })
        }
      }
    })

    if (selectedData.length === 0) return

    isDeleting.current = true
    let res = await deleteMultiData({
      wellName: wellNameRef.current,
      isPlan: isPlan,
      annotations: JSON.stringify(selectedData),
    })

    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: selectedData,
      })
    }
  }, [deleteMultiData, setStatus, isPlan])

  const interpMd = useCallback((md) => {
    if (typeof md !== 'number') return { md: -1, tvd: -1 }
    if (!Array.isArray(surveys.current)) return { md: -1, tvd: -1 }
    if (surveys.current.length === 0) return { md: -1, tvd: -1 }
    return pointInterp(surveys.current, md)
  }, [])

  const interpTvd = useCallback((tvd) => {
    if (typeof tvd !== 'number') return { md: -1, tvd: -1 }
    if (!Array.isArray(surveys.current)) return { md: -1, tvd: -1 }
    if (surveys.current.length === 0) return { md: -1, tvd: -1 }
    return pointInterpTVD(surveys.current, tvd)
  }, [])

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current.wellName = wellNameRef.current
    inputRow.current = htmlSymbolHandling(inputRow.current)

    if (inputRow.current.md !== undefined && inputRow.current.md !== null) {
      let svy = interpMd(inputRow.current.md)
      inputRow.current.tvd = svy.tvd >= 0 ? svy.tvd : 0
    } else if (inputRow.current.tvd !== undefined && inputRow.current.tvd !== null) {
      let svy = interpTvd(inputRow.current.tvd)
      inputRow.current.md = svy.md >= 0 ? svy.md : 0
    }

    let res = await addData(inputRow.current)

    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    inputRow.current.uid = uuidv4()
    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])

      setTimeout(() => {
        gridApi.current.applyColumnState({
          state: [{ colId: 'md', sort: 'asc' }],
          defaultState: { sort: null },
        })
      }, 100)
    }
  }, [addData, setStatus, isPinnedRowDataCompleted, interpMd, interpTvd])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const handleAddMultipleRows = useCallback(
    async (data) => {
      if (isAdding.current) return
      if (!Array.isArray(data)) return
      if (data.length === 0) return

      for (let i = 0; i < data.length; i++) {
        data[i].uid = uuidv4()
      }

      isAdding.current = true
      let res = await addMultiData({ annotations: JSON.stringify(data), wellName: wellNameRef.current, isPlan: isPlan })
      isAdding.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          add: data,
        })
      }

      setTimeout(() => {
        gridApi.current.applyColumnState({
          state: [{ colId: 'md', sort: 'asc' }],
          defaultState: { sort: null },
        })
      }, 100)
    },
    [addMultiData, setStatus, isPlan],
  )

  function sortData(a, b) {
    if (a.md > b.md) return 1
    if (a.md < b.md) return -1
    return 0
  }

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])

      if (data[i].column === 'md' && data[i].md !== undefined && data[i].md !== null) {
        let svy = interpMd(data[i].md)
        data[i].tvd = svy.tvd >= 0 ? svy.tvd : 0
      }

      if (data[i].column === 'tvd' && data[i].tvd !== undefined && data[i].tvd !== null) {
        let svy = interpTvd(data[i].tvd)
        data[i].md = svy.md >= 0 ? svy.md : 0
      }

      if (typeof data[i].md !== 'number') data[i].md = 0
      if (typeof data[i].tvd !== 'number') data[i].tvd = 0
      if (typeof data[i].annotation !== 'string') data[i].annotation = ''
    }

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateData({ annotations: JSON.stringify(data), wellName: wellNameRef.current, isPlan: isPlan })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })

      setTimeout(() => {
        gridApi.current.applyColumnState({
          state: [{ colId: 'md', sort: 'asc' }],
          defaultState: { sort: null },
        })
      }, 100)
    }
  }

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete() : handleAddRow())}
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'md',
        colId: 'md',
        headerName: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Md...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'tvd',
        colId: 'tvd',
        headerName: `TVD (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Tvd...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'annotation',
        colId: 'annotation',
        headerName: 'Annotation',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Annotation...'
          return unescapeHtml(params.value)
        },
      },
    ],
    [centerAlignCell, actionIconRenderer, getUnitsText],
  )

  function isValidNumber(input) {
    if (typeof input !== 'string' && typeof input !== 'number') return false
    if (typeof input === 'string' && input === '') return false
    if (typeof input === 'string') input = Number(input)
    return typeof input === 'number' && !isNaN(input)
  }

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let annotationData = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          annotationData.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!isValidNumber(annotationData.data.md) && !isValidNumber(annotationData.data.tvd)) continue
        if (isValidNumber(annotationData.data.md)) {
          annotationData.data.md = Number(annotationData.data.md)
          let svy = interpMd(annotationData.data.md)
          annotationData.data.tvd = svy.tvd >= 0 ? svy.tvd : 0
        } else if (isValidNumber(annotationData.data.tvd)) {
          annotationData.data.tvd = Number(annotationData.data.tvd)
          let svy = interpTvd(annotationData.data.tvd)
          annotationData.data.md = svy.md >= 0 ? svy.md : 0
        }

        annotationData.data.rowNum = curRowIndex

        rowsToUpdate.push(cloneDeep({ ...annotationData.data, column: 'md' }))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newAnnotation = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newAnnotation[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!isValidNumber(newAnnotation.md) && !isValidNumber(newAnnotation.tvd)) continue

        if (isValidNumber(newAnnotation.md)) {
          newAnnotation.md = Number(newAnnotation.md)
          let svy = interpMd(newAnnotation.md)
          newAnnotation.tvd = svy.tvd >= 0 ? svy.tvd : 0
        } else if (isValidNumber(newAnnotation.tvd)) {
          newAnnotation.tvd = Number(newAnnotation.tvd)
          let svy = interpTvd(newAnnotation.tvd)
          newAnnotation.md = svy.md >= 0 ? svy.md : 0
        }

        rowsToAdd.push(newAnnotation)
      }
    }

    handleUpdate(rowsToUpdate)
    handleAddMultipleRows(rowsToAdd.reverse())

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  const onClickDelete = useCallback(
    (data) => {
      setConfirm({
        show: true,
        title: 'Delete selection',
        text: `Are you sure you want to delete selected rows?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: `100%`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={annotationsData}
            columnDefs={sortColDefs(columnDefs, 'annotationsGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            getRowId={getRowId}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={processDataFromClipboard}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default AnnotationsPage
