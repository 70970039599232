import { useEffect, useRef } from 'react'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaAxios from './useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { decToSexa } from 'utils/mapFunctions'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

const useSurveyReport = () => {
  const _isMounted = useRef(false)
  const fetchingReport = useRef(false)
  const { getUnitsText } = useUnits()
  const { getCurrentOrgIcon } = useOrgIcons()
  const surveyReportData = useRef(null)
  const surveyData = useRef(null)
  const errorEllipseData = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)

  const getSurveyReportData = useInnovaAxios({
    url: '/well/getSurveyReport',
  })

  const getErrorEllipseReportData = useInnovaAxios({
    url: '/antiCollision/errorEllipseReport',
  })

  const getErrorEllipseDiagnostic = useInnovaAxios({
    url: '/antiCollision/errorEllipseDiagnostic',
  })

  useEffect(() => {
    _isMounted.current = true


    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchErrorEllipseDiagnostic = async (wellName, isPlan) => {
    if (!wellName) return { error: true, message: 'Well name is null' }
    if (typeof wellName !== 'string') return { error: true, message: 'Well name not a string' }
    if (wellName === '') return { error: true, message: 'Well name is empty' }
    if (fetchingReport.current) return { error: true, message: 'currently fetching' }

    fetchingReport.current = true
    const res = await getErrorEllipseDiagnostic({ wellName: wellName, isPlan: isPlan })
    fetchingReport.current = false

    if (!_isMounted.current) return { error: true, message: 'not mounted' }
    if (res?.error) return { error: true, message: `${res?.error?.response?.data?.error}` }
    return { error: false, message: '', data: res.data }
  }

  const fetchErrorEllipseReportData = async (wellName, isPlan = false) => {
    if (!wellName) return { error: true, message: 'Well name is null' }
    if (typeof wellName !== 'string') return { error: true, message: 'Well name not a string' }
    if (wellName === '') return { error: true, message: 'Well name is empty' }
    if (fetchingReport.current) return { error: true, message: 'currently fetching' }

    fetchingReport.current = true
    const res = await getErrorEllipseReportData({ wellName: wellName, isPlan: isPlan })
    fetchingReport.current = false

    if (!_isMounted.current) return { error: true, message: 'not mounted' }
    if (res?.error) return { error: true, message: `${res?.error?.response?.data?.error}` }
    errorEllipseData.current = res.data ? res.data : null
    return { error: false, message: '' }
  }


  const fetchSurveyReportData = async (wellName) => {
    if (!wellName) return { error: true, message: 'Well name is null' }
    if (typeof wellName !== 'string') return { error: true, message: 'Well name not a string' }
    if (wellName === '') return { error: true, message: 'Well name is empty' }
    if (fetchingReport.current) return { error: true, message: 'currently fetching' }

    fetchingReport.current = true
    const res = await getSurveyReportData({ wellName: wellName })
    fetchingReport.current = false

    if (!_isMounted.current) return { error: true, message: 'not mounted' }
    if (res?.error) return { error: true, message: `${res?.error?.response?.data?.error}` }
    surveyReportData.current = res.data ? res.data : null
    return { error: false, message: '' }
  }

  const getResponse = (item) => {
    if (item === true) return 'Yes'
    if (item === false) return 'No'
    return item
  }

  const getSurveyProgram = (wellName, surveyProgram) => {
    if (!wellName || !surveyProgram || !Array.isArray(surveyProgram)) return null
    let programs = [
      [
        { text: 'Survey Program', isHeader: true, width: '15%' },
        { text: wellName, isHeader: true, width: '85%', textAlign: 'left' },
      ],
      [
        { text: `Depth From (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
        { text: `Depth To (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
        { text: 'Survey', isBold: true, textAlign: 'left' },
        { text: 'Survey Tool', isBold: true, textAlign: 'left' },
      ],
    ]

    for (let i = 0; i < surveyProgram.length; i++) {
      programs.push([
        { text: numberWithCommasDecimals(surveyProgram[i].DepthFrom, 2) },
        { text: numberWithCommasDecimals(surveyProgram[i].DepthTo, 2) },
        { text: surveyProgram[i].Survey, textAlign: 'left' },
        { text: surveyProgram[i].IPM, textAlign: 'left' },
      ])
    }
    return programs
  }

  const generatePdfSurveyReport = () => {
    if (!surveyData.current || !surveyReportData.current) {
      return null
    }

    const { actualWell, facility, field, well, operator } = surveyReportData.current

    if (!actualWell || !facility || !field || !well || !operator) {
      return null
    }

    const getSurveyReportData = () => {
      if (!surveyData.current) return null

      let surveyReport = [
        [{ text: 'Survey Report', columnSpan: 19, isHeader: true, fontSize: 7 }],
        [
          { text: `MD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Inc\n(°)`, isHeader: true },
          { text: `Azi\n(°)`, isHeader: true },
          { text: `TVD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `NS\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `EW\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `VS\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `DLS\n(${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true },
          { text: `BR\n(${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true },
          { text: `TR\n(${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true },
          { text: `TF\n(°)`, isHeader: true },
          { text: `CL\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Map Northing\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Map Easting\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Latitude`, isHeader: true },
          { text: `Longitude`, isHeader: true },
          { text: `UP(+)/DN(-)\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Left(-)/Right(+)\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        ],
      ]

      for (let i = 0; i < surveyData.current.length; i++) {
        surveyReport.push([
          { text: numberWithCommasDecimals(surveyData.current[i].md, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].inc, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].azi, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].tvd, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].ns, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].ew, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].vs, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].dls, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].br, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].tr, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].tf, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].cl, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].gridNorth, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].gridEast, 2) },
          { text: surveyData.current[i].latitude.replace('\\', '') },
          { text: surveyData.current[i].longitude.replace('\\', '') },
          { text: numberWithCommasDecimals(surveyData.current[i].ud, 2) },
          { text: numberWithCommasDecimals(surveyData.current[i].lr, 2) },
        ])
      }

      return surveyReport
    }

    const getMagnetics = (magModels) => {
      if (!magModels) return null
      if (!Array.isArray(magModels)) return null
      for (let i = 0; i < magModels.length; i++) {
        if (magModels[i].IsActive === true) return magModels[i]
      }

      if (magModels.length > 0) return magModels[0]
      return null
    }

    let currentMagnetics = getMagnetics(actualWell.magnetics?.magneticsGrid)
    let docData = [
      {
        showTitle: true,
        title: 'Standard Survey Report',
        showLogo: true,
        logoWidth: '30%',
        logo: getCurrentOrgIcon(),
        logoHeight: 65,
        columnWidths: ['12.5%', '37.5%', '12.5%', '37.5%'],
        height: 13,
        data: [
          [
            { text: 'Operator', isHeader: true },
            { text: operator.opName, textAlign: 'left' },
            { text: 'Local co-ord ref', isHeader: true },
            { text: `${field.localCoordRef} Centered`, textAlign: 'left' },
          ],
          [
            { text: 'Field', isHeader: true },
            { text: field.field, textAlign: 'left' },
            { text: 'TVD Reference', isHeader: true },
            { text: actualWell.depthReference.Name, textAlign: 'left' },
          ],
          [
            { text: 'Facility', isHeader: true },
            { text: facility.facility, textAlign: 'left' },
            { text: 'North Reference', isHeader: true },
            { text: field.northRef, textAlign: 'left' },
          ],
          [
            { text: 'Well', isHeader: true },
            { text: well.well, textAlign: 'left' },
            { text: 'Survey Calc Method', isHeader: true },
            { text: 'Minimum Curvature', textAlign: 'left' },
          ],
          [
            { text: 'Wellbore', isHeader: true },
            { text: actualWell.actualWellName, textAlign: 'left' },
            { text: '', isHeader: true },
            { text: '' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Field', isHeader: true, width: '15%' },
            { text: field.field, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'CRS', isBold: true },
            { text: field.crs, textAlign: 'left' },
            { text: 'North Reference', isBold: true },
            { text: field.northRef, textAlign: 'left' },
          ],
          [
            { text: 'Apply Scale Factor', isBold: true },
            {
              text: getResponse(field.applySf), textAlign: 'left',
            },
            { text: 'Scale Factor', isBold: true },
            { text: numberWithCommasDecimals(field.sf, 3), textAlign: 'left' },
          ],
          [
            { text: 'System Datum', isBold: true },
            { text: field.systemDatum , textAlign: 'left'},
            { text: 'Depth Datum->MSL', isBold: true },
            { text: `${numberWithCommasDecimals(actualWell.depthReference.Elevation, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Facility', isHeader: true, width: '15%' },
            { text: facility.facility, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Map Northing', isBold: true },
            { text: facility.gridNorth ? `${numberWithCommasDecimals(facility.gridNorth, 2)} ${getUnitsText(UNITS_FOR.Depth)}` : '', textAlign: 'left' },
            { text: 'Map Easting', isBold: true },
            { text: facility.gridEast ? `${numberWithCommasDecimals(facility.gridEast, 2)} ${getUnitsText(UNITS_FOR.Depth)}` : '', textAlign: 'left' },
          ],
          [
            { text: 'Latitude', isBold: true },
            { text: `${facility.latDd}° ${facility.latMm}' ${numberWithCommasDecimals(facility.latSs, 3)}" ${facility.latNs}`, textAlign: 'left' },
            { text: 'Longitude', isBold: true },
            {
              text: `${facility.longDd}° ${facility.longMm}' ${numberWithCommasDecimals(facility.longSs, 3)}" ${facility.longEw}`, textAlign: 'left',
            },
          ],
          [
            { text: 'Vertical Uncertainty', isBold: true },
            { text: `${numberWithCommasDecimals(facility.vertUncert, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Horizontal Uncertainty', isBold: true },
            { text: `${numberWithCommasDecimals(facility.horzUncert, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [{ text: 'Grid Convergence', isBold: true }, { text: facility.gridConv, textAlign: 'left' }, { text: '' }, { text: '' }],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Well', isHeader: true, width: '15%' },
            { text: well.well, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Local North', isBold: true },
            { text: `${numberWithCommasDecimals(well.localNorth, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Local East', isBold: true },
            { text: `${numberWithCommasDecimals(well.localEast, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: 'Map Northing', isBold: true },
            { text: `${numberWithCommasDecimals(well.gridNorth, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Map Easting', isBold: true },
            { text: `${numberWithCommasDecimals(well.gridEast, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: 'Latitude', isBold: true },
            { text: `${well.latDd}° ${well.latMm}' ${numberWithCommasDecimals(well.latSs, 3)}" ${well.latNs}`, textAlign: 'left' },
            { text: 'Longitude', isBold: true },
            {
              text: `${well.longDd}° ${well.longMm}' ${numberWithCommasDecimals(well.longSs, 3)}" ${well.longEw}`, textAlign: 'left',
            },
          ],
          [
            { text: 'Depth Datum', isBold: true },
            { text: actualWell.depthReference.Name, textAlign: 'left' },
            { text: 'Datum Elevation', isBold: true },
            { text: `${numberWithCommasDecimals(actualWell.depthReference.Elevation, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: actualWell.depthReference.isOffshore ? 'Water Depth' : 'GL Elevation', isBold: true },
            { text: `${numberWithCommasDecimals(actualWell.depthReference.waterDepthGl, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: '', isBold: true },
            { text: '' },
          ],
          [
            { text: 'Grid Convergence', isBold: true },
            { text: well.gridConv, textAlign: 'left' },
            { text: '', isBold: true },
            { text: '' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Wellbore', isHeader: true, width: '15%' },
            { text: actualWell.actualWellName, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Magnetic Model', isBold: true },
            { text: currentMagnetics !== null ? currentMagnetics.Model : '', textAlign: 'left' },
            { text: 'Date', isBold: true },
            { text: currentMagnetics !== null ? currentMagnetics.UKDate : '', textAlign: 'left' },
          ],
          [
            { text: 'Total Field (nT)', isBold: true },
            { text: numberWithCommasDecimals(currentMagnetics !== null ? currentMagnetics.TotalField : 0, 2), textAlign: 'left' },
            { text: 'Dip Angle (°)', isBold: true },
            { text: numberWithCommasDecimals(currentMagnetics !== null ? currentMagnetics.Dip : 0, 3), textAlign: 'left' },
          ],
          [
            { text: 'Declination (°)', isBold: true },
            { text: numberWithCommasDecimals(currentMagnetics !== null ? currentMagnetics.Dec : 0, 3), textAlign: 'left' },
            { text: '' },
            { text: '' },
          ],
          [
            { text: 'VS Origin', isBold: true },
            { text: 'Well', textAlign: 'left' },
            { text: 'VS Azimuth', isBold: true },
            { text: numberWithCommasDecimals(actualWell.verticalSection.VsAzi, 2), textAlign: 'left' },
          ],
          [
            { text: 'VS Origin NS', isBold: true },
            { text: `${numberWithCommasDecimals(actualWell.verticalSection.VsOrgNorth, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'VS Origin EW', isBold: true },
            { text: `${numberWithCommasDecimals(actualWell.verticalSection.VsOrgEast, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '17.5%', '17.5%', '50%'],
        manualWidth: true,
        height: 13,
        data: getSurveyProgram(actualWell?.actualWellName, actualWell?.surveyProgram),
      },
      {
        sectionAfter: 5,
        fixedHeaders: 2,
        manualWidth: true,
        fontSize: 5,
        height: '12px',
        columnWidths: [
          '5%',
          '4%',
          '4%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '5%',
          '4%',
          '4%',
          '7%',
          '7%',
          '9%',
          '9%',
          '6%',
          '6%',
        ],
        data: getSurveyReportData(),
      },
    ]

    return docData
  }

  const getSurveyReportPdfData = async (wellName, surveys) => {
    surveyData.current = surveys
    let res = await fetchSurveyReportData(wellName)
    if (res?.error) {
      return res
    }

    let pdfData = generatePdfSurveyReport()
    if (!pdfData) return null

    return ({
      fileName: `Survey Report - ${removeSpecialSymbols(wellName)}`,
      data: <PdfDocument data={pdfData} multiDocument={false} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings}/>
    })
  }

  const getCrsUnits = (units) => {
    if (units === 'US survey foot') return 'Usft'
    return units
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const getErrorReportBody = () => {
    if (!errorEllipseData.current) return null

    let errorReport = [
      [{ text: 'Error Ellipse Report', columnSpan: 19, isHeader: true, fontSize: 7 }],
      [
        { text: `MD\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Inc\n(°)`, isHeader: true },
        { text: `Azi\n(°)`, isHeader: true },
        { text: `High Side Error\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Lateral Error\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Vertical Error\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Semi Major\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Semi Minor\n(${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Ellipse Azi\n(°)`, isHeader: true },
        { text: `Survey Tool\n `, isHeader: true, textAlign: 'left' },
      ],
    ]

    if (!Array.isArray(errorEllipseData.current?.surveys)) return errorReport
    for (let i = 0; i < errorEllipseData.current?.surveys.length; i++) {
      errorReport.push([
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].md, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].inc, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].azi, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].covarianceMatrix.highSideError, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].covarianceMatrix.lateralError, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].covarianceMatrix.verticalError, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].covarianceMatrix.semiMajor, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].covarianceMatrix.semiMinor, 2) },
        { text: numberWithCommasDecimals(errorEllipseData.current?.surveys[i].covarianceMatrix.semiMinorAzi, 2) },
        { text: errorEllipseData.current?.surveys[i].ipm }
      ])
    }

    return errorReport
  }

  const generatePdfErrorEllipseReport = () => {
    if (!errorEllipseData.current) return null

    let data = errorEllipseData.current
    let docData = [
      {
        showTitle: true,
        title: 'Standard Error Ellipse Report',
        showLogo: true,
        logo: getCurrentOrgIcon(),
        logoWidth: '30%',
        logoHeight: 65,
        columnWidths: ['12.5%', '37.5%', '12.5%', '37.5%'],
        height: 13,
        data: [
          [
            { text: 'Operator', isHeader: true },
            { text: data?.operator, textAlign: 'left' },
            { text: 'Local co-ord ref', isHeader: true },
            { text: `${data.localCoordRef} Centered`, textAlign: 'left' },
          ],
          [
            { text: 'Field', isHeader: true },
            { text: data?.field, textAlign: 'left' },
            { text: 'TVD Reference', isHeader: true },
            { text: data.depthDatumName, textAlign: 'left' },
          ],
          [
            { text: 'Facility', isHeader: true },
            { text: data?.facility, textAlign: 'left' },
            { text: 'North Reference', isHeader: true },
            { text: data.northRef, textAlign: 'left' },
          ],
          [
            { text: 'Well', isHeader: true },
            { text: data.parentWell, textAlign: 'left' },
            { text: 'Survey Calc Method', isHeader: true },
            { text: 'Minimum Curvature', textAlign: 'left' },
          ],
          [
            { text: 'Wellbore', isHeader: true },
            { text: data.wellName, textAlign: 'left' },
            { text: 'Output Errors are at', isHeader: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.sigmaReports, 3)} Sigma`, textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Field', isHeader: true, width: '15%' },
            { text: data.field, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'CRS', isBold: true },
            { text: data.crs.name, textAlign: 'left' },
            { text: 'North Reference', isBold: true },
            { text: data.northRef, textAlign: 'left' },
          ],
          [
            { text: 'Apply Scale Factor', isBold: true },
            {
              text: getResponse(data.applySf), textAlign: 'left'
            },
            { text: 'Scale Factor', isBold: true },
            { text: numberWithCommasDecimals(data.crs.scaleFactor, 3), textAlign: 'left' },
          ],
          [
            { text: 'System Datum', isBold: true },
            { text: data?.systemDatum, textAlign: 'left' },
            { text: 'Depth Datum->MSL', isBold: true },
            { text: `${numberWithCommasDecimals(data.datumElevation, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: 'Scan Method', isBold: true },
            { text: data?.acScanMeth, textAlign: 'left' },
            { text: 'Error Surface', isBold: true },
            { text: data.errorSurface === 'Elliptical Conic' ? 'Pedal Curve' : data.errorSurface, textAlign: 'left' },
          ],
          [
            { text: 'Casing Included', isBold: true },
            { text: getResponse(data.antiCollisionSettings.incCasing), textAlign: 'left' },
            { text: 'Casing Method', isBold: true },
            { text: data.antiCollisionSettings.incCasing ? data.antiCollisionSettings.casingMethod : '', textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Facility', isHeader: true, width: '15%' },
            { text: data.facility, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Map Northing', isBold: true },
            { text: data.facilityCoords.gridNorth ? `${numberWithCommasDecimals(data.facilityCoords.gridNorth, 2)} ${getCrsUnits(data.crs.uom)}` : '', textAlign: 'left' },
            { text: 'Map Easting', isBold: true },
            { text: data.facilityCoords.gridEast ? `${numberWithCommasDecimals(data.facilityCoords.gridEast, 2)} ${getCrsUnits(data.crs.uom)}` : '', textAlign: 'left' },
          ],
          [
            { text: 'Latitude', isBold: true },
            { text: decToSexa(data.facilityCoords.latitude), textAlign: 'left' },
            { text: 'Longitude', isBold: true },
            {
              text: decToSexa(data.facilityCoords.longitude), textAlign: 'left',
            },
          ],
          [
            { text: 'Vertical Uncertainty', isBold: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.facilityUncertainty.vertical, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Horizontal Uncertainty', isBold: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.facilityUncertainty.horizontal, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [{ text: 'Grid Convergence', isBold: true }, { text: numberWithCommasDecimals(data.gridConv, 2), textAlign: 'left' }, { text: '' }, { text: '' }],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Well', isHeader: true, width: '15%' },
            { text: data.parentWell, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Local North', isBold: true },
            { text: `${numberWithCommasDecimals(data.wellCoords.localNorth, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Local East', isBold: true },
            { text: `${numberWithCommasDecimals(data.wellCoords.localEast, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: 'Map Northing', isBold: true },
            { text: `${numberWithCommasDecimals(data.wellCoords.gridNorth, 2)} ${getCrsUnits(data.crs.uom)}`, textAlign: 'left' },
            { text: 'Map Easting', isBold: true },
            { text: `${numberWithCommasDecimals(data.wellCoords.gridEast, 2)} ${getCrsUnits(data.crs.uom)}`, textAlign: 'left' },
          ],
          [
            { text: 'Latitude', isBold: true },
            { text: decToSexa(data.wellCoords.latitude), textAlign: 'left' },
            { text: 'Longitude', isBold: true },
            {
              text: decToSexa(data.wellCoords.longitude), textAlign: 'left',
            },
          ],
          [
            { text: 'Depth Datum', isBold: true },
            { text: data.depthDatumName, textAlign: 'left' },
            { text: 'Datum Elevation', isBold: true },
            { text: `${numberWithCommasDecimals(data.datumElevation, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: data.antiCollisionSettings?.isOffshore ? 'Water Depth' : 'GL Elevation', isBold: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.waterDepthGl, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Grid Convergence', isBold: true },
            { text: numberWithCommasDecimals(data.gridConv, 2), textAlign: 'left' },
          ],
          [
            { text: 'Vertical Uncertainty', isBold: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.wellUncertainty.vertical, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'Horizontal Uncertainty', isBold: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.wellUncertainty.horizontal, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
          [
            { text: 'Inc Only Wells vert', isBold: true },
            { text: 'Yes', textAlign: 'left' },
            { text: 'Bggm Error Estimates', isBold: true },
            { text: getResponse(data.antiCollisionSettings.useBggmErrorEstimates), textAlign: 'left' },
          ],
          [
            { text: 'Use WPTS Sep Rule', isBold: true },
            { text: getResponse(data.antiCollisionSettings.wptsData.useWpts), textAlign: 'left' },
            { text: 'Surface Margin', isBold: true },
            { text: numberWithCommasDecimals(data.antiCollisionSettings.wptsData.surfaceMargin, 2), textAlign: 'left' },
          ],
          [
            { text: 'Minimum Separation', isBold: true },
            { text: getResponse(data.antiCollisionSettings.acAlerts.surfaceMargin), textAlign: 'left' },
            { text: 'Plus Cone of Expansion', isBold: true },
            { text: numberWithCommasDecimals(data.antiCollisionSettings.acAlerts.coneOfExpansion, 2) + '/1000', textAlign: 'left' },
          ],
          [
            { text: 'Proj Ahead Uncert', isBold: true },
            { text: numberWithCommasDecimals(data.antiCollisionSettings.wptsData.projectionUncert, 2), textAlign: 'left' },
            { text: 'Warning levels @', isBold: true },
            { text: `${numberWithCommasDecimals(data.antiCollisionSettings.sigma, 3)} Sigma`, textAlign: 'left' },
          ],
        ],
      },
      {
        sectionAfter: 5,
        columnWidths: ['15%', '35%', '15%', '35%'],
        manualWidth: true,
        height: 13,
        data: [
          [
            { text: 'Wellbore', isHeader: true, width: '15%' },
            { text: data.wellName, isHeader: true, width: '85%', textAlign: 'left' },
          ],
          [
            { text: 'Magnetic Model', isBold: true },
            { text: data.magnetics.model, textAlign: 'left' },
            { text: 'Date', isBold: true },
            {
              text: isValidDate(data.magnetics.date) ? new Date(Date.parse(data.magnetics.date + 'T00:00:01')).toLocaleDateString('default', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }) : '', textAlign: 'left'
            },
          ],
          [
            { text: 'Total Field (nT)', isBold: true },
            { text: numberWithCommasDecimals(data.magnetics.bTotal, 2), textAlign: 'left' },
            { text: 'Dip Angle (°)', isBold: true },
            { text: numberWithCommasDecimals(data.magnetics.dip, 3), textAlign: 'left' },
          ],
          [
            { text: 'Declination (°)', isBold: true },
            { text: numberWithCommasDecimals(data.magnetics.dec, 3), textAlign: 'left' },
            { text: '' },
            { text: '' },
          ],
          [
            { text: 'VS Origin', isBold: true },
            { text: data.vsOrigin, textAlign: 'left' },
            { text: 'VS Azimuth', isBold: true },
            { text: numberWithCommasDecimals(data.vsAzi, 2), textAlign: 'left' },
          ],
          [
            { text: 'VS Origin NS', isBold: true },
            { text: `${numberWithCommasDecimals(data.vsNs, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
            { text: 'VS Origin EW', isBold: true },
            { text: `${numberWithCommasDecimals(data.vsEw, 2)} ${getUnitsText(UNITS_FOR.Depth)}`, textAlign: 'left' },
          ],
        ],
      },
      {

        sectionAfter: 5,
        columnWidths: ['15%', '17.5%', '17.5%', '50%'],
        manualWidth: true,
        height: 13,
        data: getSurveyProgram(data.wellName, data.surveyProgram),
      },
      {
        sectionAfter: 5,
        fixedHeaders: 2,
        manualWidth: true,
        fontSize: 5,
        height: '12px',
        columnWidths: [
          '10%',
          '10%',
          '10%',
          '10%',
          '10%',
          '10%',
          '10%',
          '10%',
          '10%',
          '10%',
        ],
        data: getErrorReportBody(),
      },
    ]

    return docData
  }

  const getErrorEllipseReportPdfData = async (wellName) => {
    let res = await fetchErrorEllipseReportData(wellName)
    if (res?.error) {
      return res
    }

    let pdfData = generatePdfErrorEllipseReport()
    if (!pdfData) return null

    return ({
      fileName: `Error Ellipse Report - ${removeSpecialSymbols(wellName)}`,
      data: <PdfDocument data={pdfData} multiDocument={false} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings}/>
    })
  }

  return { getSurveyReportPdfData, getErrorEllipseReportPdfData, fetchErrorEllipseDiagnostic }
}

export default useSurveyReport
