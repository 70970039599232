import React, { useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import CurveSettingsGrid from './CurveSettingsGrid'
import CurveShadingGrid from './CurveShadingGrid'
import CurveOperationsGrid from './CurveOperationsGrid'
import { cloneDeep } from 'lodash'
import { curveOperations, shadeType } from '../curveDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SETTINGS_TAB = 'settings'
const SHADING_TAB = 'shading'
const CALCULATED_TAB = 'calculated'

const UpdateCurveSettingsModal = ({ data, track, title, open, setOpen, submitFunction }) => {
  const [tabSelection, setTabSelection] = useState(data.calculated ? CALCULATED_TAB : SETTINGS_TAB)
  const [curveData, setCurveData] = useState(data)
  const curveDataRef = useRef(data)
  const { theme, getWindowBarColor } = useInnovaTheme()

  const updateCurveData = () => {
    if (!curveDataRef.current) return
    return curveDataRef.current
  }

  const handleUpdate = (updateData, source) => {
    if (!updateData) return
    let newData = cloneDeep(curveDataRef.current)
    if (source === 'settings') {
      newData[updateData.tag] = updateData.value
    }

    if (source === 'shading') {
      if (updateData.tag === 'shadeType') {
        let value = shadeType.find((shade) => shade.label === updateData.value).value
        newData.shading[0][updateData.tag] = { label: updateData.value, value: value }
      } else {
        newData.shading[0][updateData.tag] = updateData.value
      }
    }

    if (source === 'operations') {
      if (updateData.tag === 'operation') {
        let value = curveOperations.find((operation) => operation.label === updateData.value).value
        newData.operations[0][updateData.tag] = { label: updateData.value, value: value }
      } else if (updateData.tag === 'color' || updateData.tag === 'scaleMin' || updateData.tag === 'scaleMax') {
        newData[updateData.tag] = updateData.value
      } else {
        newData.operations[0][updateData.tag] = updateData.value
      }
    }

    curveDataRef.current = newData
    setCurveData(newData) //Required to update state
  }

  const TabHeader = ({ tabData }) => {
    if (!tabData) return null
    if (!tabData.visible) return null
    let tabActive = tabSelection === tabData.tag
    let tabBackColor = tabActive ? '#FFFFFF' : '#181d1f'
    let textColor = tabActive ? '#181d1f' : '#FFFFFF'
    if(theme !== 'dark') {
      tabBackColor = tabActive ? getWindowBarColor() : '#FFF'
      textColor = tabActive ? '#FFFFFF' : '#181d1f'
    }

    return (
      <Box
        sx={{
          maxWidth: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRight: '1px solid #808080',
          paddingLeft: '5px',
          paddingRight: '10px',
          fontWeight: tabActive ? 'bold' : 'normal',
          backgroundColor: tabBackColor,
          color: textColor,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={tabData.onClick}>
        {tabData.label}
      </Box>
    )
  }

  let TABS = [
    { label: 'Settings', onClick: () => setTabSelection(SETTINGS_TAB), tag: SETTINGS_TAB, visible: true },
    {
      label: 'Shading',
      onClick: () => setTabSelection(SHADING_TAB),
      tag: SHADING_TAB,
      visible: curveData?.shaded,
    },
    {
      label: 'Calculated',
      onClick: () => setTabSelection(CALCULATED_TAB),
      tag: CALCULATED_TAB,
      visible: curveData?.calculated,
    },
  ]

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '600px',
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{`${title}: ${data.label}`}</DialogTitle>
      <DialogContent
        style={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '30px',
            width: 'calc(100% - 5px)',
            border: '1px solid #808080',
          }}>
          {TABS.map((tab, index) => (
            <TabHeader key={index} tabData={tab} />
          ))}
        </Box>
        <Box
          style={{
            display: 'flex',
            width: 'calc(100% - 5px)',
            height: '40vh',
            border: '1px solid #808080',
          }}>
          {tabSelection === SETTINGS_TAB && (
            <CurveSettingsGrid data={curveDataRef.current} handleUpdate={handleUpdate} />
          )}
          {tabSelection === SHADING_TAB && (
            <CurveShadingGrid data={curveDataRef.current} handleUpdate={handleUpdate} track={track} />
          )}
          {tabSelection === CALCULATED_TAB && (
            <CurveOperationsGrid data={curveDataRef.current} handleUpdate={handleUpdate} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ margin: '5px' }}
          variant='outlined'
          type='button'
          color='secondary'
          onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            submitFunction(updateCurveData())
          }}
          color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(UpdateCurveSettingsModal)
