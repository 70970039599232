import React, { useRef, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AntiCollisionDashboardAlertSettingsGrid = ({ data, handleUpdate }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', data)
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getContextMenuItems = (params) => {
    return ['copy']
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: 'Min',
        field: 'min',
        colId: 'min',
        sortable: false,
        editable: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: 'Max',
        field: 'max',
        colId: 'max',
        sortable: false,
        editable: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: 'Color',
        colId: 'color',
        field: 'color',
        editable: false,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellStyle: (params) => {
          return { ...centerAlignCell, backgroundColor: params.value }
        },
      },
    ],
    [centerAlignCell],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={sortColDefs(columnDefs, 'acDashboardWellGrid')}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default AntiCollisionDashboardAlertSettingsGrid
