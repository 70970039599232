import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import IpmSelectorEditor from 'components/common/CellEditors/IpmSelectorEditor'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SurveyPropertiesGrid = ({ surveyData, handleUpdate, errorModels, tieOnSurveyHeaders, units }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getAgGridTheme, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 300,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 400,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => {
          if (params.data?.tag === 'parentSurveyWell' || params.data?.tag === 'parentTieOn') {
            let inputMeth = ''
            params.api.forEachNode((node) => {
              if (node.data?.tag === 'inputMeth') inputMeth = node.data?.value
            })

            return inputMeth === 'Survey'
          }

          return params?.data?.editable
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'ipm') {
            return {
              component: IpmSelectorEditor,
              params: {
                errorModels: Array.isArray(params.data?.dropDownValues)
                  ? params.data?.dropDownValues.map((errModel, index) => {
                      return { errModel: errModel, uid: index }
                    })
                  : [],
                maxWidth: containerRef.current ? containerRef.current.clientWidth : 100,
                maxHeight: containerRef.current ? containerRef.current.clientHeight : 100,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: -1000000,
                max: 1000000,
                precision: 7,
              },
            }
          }

          return null
        },
        valueGetter: (params) => {
          if (params?.data?.type === 'text') {
            return unescapeHtml(params?.data?.value)
          }

          if (params?.data?.type === 'number') {
            return parseFloat(params?.data?.value)
          }

          return params?.data?.value
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, 2)}`
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getSurveyData = (data) => {
    let svy = [
      { label: `Survey Name`, value: '', id: -1, type: 'text', tag: `survey`, editable: true },
      {
        label: `IPM`,
        value: '',
        id: -1,
        type: 'ipm',
        tag: `ipm`,
        editable: true,
        dropDownValues: Array.isArray(errorModels) ? errorModels : [],
      },
      { label: `Depth From (${units})`, value: 0, id: -1, type: 'number', tag: `depthFrom`, editable: false },
      { label: `Depth To (${units})`, value: 0, id: -1, type: 'number', tag: `depthTo`, editable: false },
      {
        label: `Tie On Type`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `inputMeth`,
        editable: true,
        dropDownValues:
          Array.isArray(tieOnSurveyHeaders) && tieOnSurveyHeaders.length > 0
            ? ['Surface', 'Survey', 'User Defined']
            : ['Surface', 'User Defined'],
      },
      {
        label: `Tie On Well`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `parentSurveyWell`,
        editable: true,
        dropDownValues: [...new Set(tieOnSurveyHeaders?.map((item) => item.wellName))],
      },
      {
        label: `Tie On Survey`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `parentTieOn`,
        editable: true,
        dropDownValues: [
          ...new Set(
            tieOnSurveyHeaders?.filter((item) => item.wellName === data?.parentSurveyWell).map((item) => item?.survey),
          ),
        ],
      },
    ]

    for (let i = 0; i < svy.length; i++) {
      svy[i].id = i
      if (svy[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(svy[i].tag)) continue
      if (!data[svy[i].tag]) continue
      svy[i].value = data[svy[i].tag]

      if (svy[i].tag === 'inputMeth') {
        if (data[svy[i].tag] === 'SURVEY') svy[i].value = 'Survey'
        if (data[svy[i].tag] === 'SURFACE') svy[i].value = 'Surface'
        if (data[svy[i].tag] === 'USER') svy[i].value = 'User Defined'
      }
    }

    return svy
  }

  const getDepthDatumText = () => {
    if (!surveyData) return ''
    return `All depths relative to ${surveyData?.datumName} @ ${numberWithCommasDecimals(
      surveyData?.datumElevation,
      2,
    )} (${units})`
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <div ref={containerRef} style={{ height: 'calc(100% - 25px)', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getSurveyData(surveyData)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
      <Box sx={{ width: '100%', height: '25px', backgroundColor: getWindowBarColor, color: 'white', paddingLeft: '10px' }}>
        {getDepthDatumText()}
      </Box>
    </Box>
  )
}

export default SurveyPropertiesGrid
