export const buildPipeDelimitedString = (inputArray, delimiter = '|') => {
  if (!Array.isArray(inputArray)) return ''
  if (inputArray.length === 0) return ''

  let outputStr = ''
  inputArray.forEach((item, index) => {
    if (typeof item === 'string') outputStr += item
    if (typeof item === 'number') outputStr += item.toString()
    if (index < inputArray.length - 1) outputStr += delimiter
  })

  return outputStr
}
