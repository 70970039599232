import { appColors } from 'utils'
import Box from '@mui/material/Box'
import { round } from 'utils/numberFunctions'

const ToolFaceIndicator = ({ etf = 0, ttf = 0, slideGrade = 100, tfType = 'Mag', radius = '40px' }) => {
  const getTextColor = (grade) => {
    if (grade === null || grade === undefined) return appColors.rotateColor
    if (typeof grade === 'string') grade = parseFloat(grade)
    if (grade <= 0) return appColors.rotateColor
    if (grade <= 50) return 'tomato'
    if (grade <= 75) return appColors.slideColor
    return appColors.slideColor
  }
  const normalizeTF = (tf) => {
    if (tf === null || tf === undefined) return 0
    if (typeof tf === 'string') tf = parseFloat(tf)
    if (tf > 360) return tf - 360
    if (tf < 0) return tf + 360
    return tf
  }

  const getTfValue = (target, effective) => {
    if (target === null || target === undefined) target = 0
    if (effective === null || effective === undefined) effective = 0
    if (typeof target === 'string') target = parseFloat(target)
    if (typeof effective === 'string') effective = parseFloat(effective)
    if (effective === target) return target
    if (target === 0 && Math.abs(effective) > 0) return effective
    if (effective === 0 && Math.abs(target) > 0) return target
    return effective
  }

  const getTfText = (target, effective, type) => {
    let tf = normalizeTF(getTfValue(target, effective))
    if (typeof type !== 'string') type = 'grav'
    type = type.toLowerCase()
    let typeText = 'M'
    if (type !== 'mag') {
      typeText = 'R'
      if (tf > 180) {
        typeText = 'L'
        tf = 360 - tf
      }
    }

    return round(tf.toString(), 2) + typeText
  }

  const getRadius = () => {
    if (typeof radius === 'number') return radius
    if (typeof radius === 'string') {
      let rCopy = radius
      return parseInt(rCopy.replace('px', '')) + 5
    }
  }

  return (
    <Box
      sx={{
        borderColor: appColors.headerTextColor,
        backgroundColor: appColors.borderColor,
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px',
        borderRadius: '50%',
        border: '1px solid #C0C0C0',
        display: 'flex',
        height: radius,
        minWidth: radius,
        transform: `rotate(${normalizeTF(getTfValue(ttf, etf))}deg)`,
      }}>
      <Box
        sx={{
          borderColor: appColors.headerTextColor,
          backgroundColor: appColors.headerTextColor,
          width: '2px',
          position: 'absolute',
          top: '0px',
          height: `${getRadius() / 2}px`,
          opacity: 0.5,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: '600',
          fontSize: 12,
          color: getTextColor(slideGrade),
          transform: `rotate(-${normalizeTF(getTfValue(ttf, etf))}deg)`,
        }}>
        {getTfText(ttf, etf, tfType)}
      </Box>
    </Box>
  )
}

export default ToolFaceIndicator
