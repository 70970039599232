import ErrorEllipse from "./ErrorEllipse"

const OffsetErrorEllipses = ({ offsetData, offsetsOn, display, scale }) => {
  if (!display) return null
  if (!offsetsOn) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null

  let segments = 16
  let interval = 2
  if (offsetData.length > 20) {
    segments = 8
    interval = 5
  }

  return offsetData.map((osWell, index) => (
    <ErrorEllipse
      interval={interval}
      segments={segments}
      wellData={osWell}
      key={`${osWell.name}ellipse${index}`}
      display={true}
      scale={scale}
    />
  ))
}

export default OffsetErrorEllipses