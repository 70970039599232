import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { chartSeriesColors, appColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import { Box } from '@mui/material'
import useInnovaTheme from './hooks/useInnovaTheme'

const DonutChartJs = ({
  title = null,
  maxToolTipLength = 999999,
  showGradient = true,
  backGroundColor = null,
  showTitle = true,
  innerRadius = '60%',
  padding = '20%',
  data = [
    {
      value: 2,
      color: '#8bc34a',
      label: 'Series 1',
    },
    {
      value: 2,
      color: '#ff6347',
      label: 'Series 2',
    },
    {
      value: 3,
      color: '#87CEEB',
      label: 'Series 3',
    },
    {
      value: 5,
      color: '#03a9f4',
      label: 'Series 4',
    },
  ],
  centerText = null,
  showCenterText = true,
  showLegend = true,
  legendLocation = 'bottom', //bottom, top, left, right
  labelColor = null,
  titleColor = null,
  maintainAspectRatio = false,
}) => {
  const { theme, getBackColor, getTextColor } = useInnovaTheme()

  if (!backGroundColor) backGroundColor = getBackColor()
  if (!titleColor) titleColor = getTextColor()
  if (!labelColor) labelColor = theme === 'dark' ? appColors.headerTextColor : '#000000'

  const calcRadiusStr = (paddingStr) => {
    if (typeof paddingStr !== 'string') return '80%'
    if (paddingStr === '') return '100%'
    paddingStr = paddingStr.replaceAll('%', '')
    let padding = 100 - parseInt(paddingStr)
    if (typeof padding !== 'number') return '100%'
    return `${padding}%`
  }

  const getSeriesColor = (data, index, opacity) => {
    if (typeof index !== 'number') getRandomColor()
    if (!data) getRandomColor()
    if (!Array.isArray(data)) return getRandomColor()
    if (index < 0 || index >= data.length) return getRandomColor()

    if (data[index].hasOwnProperty('color')) {
      if (data[index].hasOwnProperty('opacity') && typeof data[index].opacity === 'string') {
        return data[index].color + data[index].opacity
      }

      if (typeof opacity !== 'string') opacity = 'ff'
      if (opacity === '') opacity = 'ff'
      return data[index].color + opacity
    }

    if (index < chartSeriesColors.length) return chartSeriesColors[index]
    return getRandomColor()
  }

  const chartOptions = {
    responsive: true,
    radius: calcRadiusStr(padding),
    maintainAspectRatio: maintainAspectRatio,
    cutout: innerRadius,
    animation: false,
    plugins: {
      CenterTextPlugIn: {
        centerText: centerText ? centerText : '0',
        color: labelColor,
        visible: showCenterText,
      },
      legend: {
        position: legendLocation ? legendLocation : 'bottom',
        display: showLegend,
        maxWidth: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 5,
          color: labelColor,
        },
      },
      title: {
        display: showTitle,
        color: titleColor,
        position: 'top',
        align: 'center',
        text: title,
      },
      tooltip: {
        enabled: true,
        xAlign: 0,
        yAlign: 20,
        bodyAlign: 'left',
        callbacks: {
          label: (tooltipItem) => {
            return splitStrToRows(`${tooltipItem.label}`, maxToolTipLength, tooltipItem.formattedValue)
          },
        },
      },
      GradientPlugIn: {
        showGradient: showGradient,
        theme: theme,
      },
    },
  }

  const splitStrToRows = (inputStr, maxLen = 999999, value) => {
    if (typeof inputStr !== 'string') return ''
    if (inputStr === '') return ''
    if (typeof maxLen !== 'number') return `${inputStr} : ${value}`
    if (inputStr.length <= maxLen) return `${inputStr} : ${value}`

    const splitInput = ['']
    inputStr.split(' ').forEach((word) => {
      let lastIndex = splitInput.length - 1
      if (splitInput[lastIndex].length + word.length > maxLen) {
        splitInput[lastIndex] = splitInput[lastIndex].trim()
        splitInput.push('')
      }

      splitInput[lastIndex] += word
      splitInput[lastIndex] += ' '
      splitInput.push(`: ${value}`)
    })
    return splitInput
  }

  const createChartData = () => {
    const chartData = {
      labels: data.map((item) => item.label),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: data.map((item, index) => getSeriesColor(data, index, 'dd')),
          borderColor: data.map((item, index) => '#141414'),
          hoverBorderColor: data.map((item, index) => getSeriesColor(data, index, 'ff')),
          hoverBackgroundColor: data.map((item, index) => getSeriesColor(data, index, 'aa')),
          hoverOffset: 1,
          borderWidth: 1,
          borderRadius: 3,
          borderAlign: 'inner',
        },
      ],
    }

    return chartData
  }

  const getCenterText = () => {
    let sum = data.reduce((a, b) => a + b.value, 0)

    let sumText = sum
    if (sum % 1 !== 0) sumText = sum.toFixed(2)
    if (sum % 1 === 0) sumText = sum.toFixed(0)

    return centerText ? centerText : sumText
  }

  if (!showCenterText) chartOptions.plugins.CenterTextPlugIn.centerText = null
  if (showCenterText) chartOptions.plugins.CenterTextPlugIn.centerText = getCenterText()

  return (
    <Box sx={{ width: '100%', height: '100%', background: backGroundColor }}>
      {Array.isArray(data) && data.length > 0 ? (
        <Doughnut type='doughnut' options={chartOptions} data={createChartData()} />
      ) : (
        <Box
          sx={{
            fontSize: 20,
            fontWeight: '500',
            color: appColors.headerTextColor,
            textAlign: 'center',
            paddingRight: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            width: '100%',
            height: '100%',
          }}>
          No Data
        </Box>
      )}
    </Box>
  )
}

export default DonutChartJs
