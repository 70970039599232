import React, { useRef } from 'react'
import { appColors } from 'utils'
import { round } from 'lodash'
import { useRecoilValue } from 'recoil'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { currentInfrastructureIcdsSelector, currentInfrastructureIcdsVersionSelector } from 'atoms'
import { Bar } from 'react-chartjs-2'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const IcdsUtilizationChart = ({ showTitle }) => {
  const data = useRecoilValue(currentInfrastructureIcdsSelector)
  const icdsVersion = useRecoilValue(currentInfrastructureIcdsVersionSelector)
  const chartRef = useRef()
  const { theme } = useInnovaTheme()
  const yAxisMemoryMaxVal = useRef(50)

  const createChartData = () => {
    if (!Array.isArray(data)) return { datasets: [] }
    if (data.length === 0) return { datasets: [] }

    var chartData = {
      labels: data.map((item) => {
        return item.organization
      }),
      datasets: [
        {
          label: 'CPU',
          data: [],
          yAxisID: 'y',
          borderColor: appColors.rotateColor,
          backgroundColor: appColors.rotateColor,
          maxBarThickness: 24,
        },
        {
          label: 'Memory',
          data: [],
          yAxisID: 'yAxisMemory',
          borderColor: appColors.slideColor,
          backgroundColor: appColors.slideColor,
          maxBarThickness: 24,
        },
      ],
    }

    data.forEach((item) => {
      chartData.datasets[0].data.push(item?.monit?.cpu)
      let memoryVal = item?.monit?.memory / 1000000
      chartData.datasets[1].data.push(memoryVal)
      if (memoryVal > yAxisMemoryMaxVal.current) {
        yAxisMemoryMaxVal.current = Math.ceil(memoryVal / 10) * 10
      }
    })
    return chartData
  }

  const chartOptions = {
    layout: {
      padding: {
        right: 20,
      },
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'CPU %',
          font: {
            size: 20,
            weight: 600,
          },
          color: appColors.rotateColor,
        },
        ticks: {
          precision: 0,
          color: appColors.rotateColor,
        },
        position: 'left',
      },
      yAxisMemory: {
        min: 0,
        max: () => {
          return yAxisMemoryMaxVal.current
        },
        title: {
          display: true,
          text: 'Memory (MB)',
          font: {
            size: 20,
            weight: 600,
          },
          color: appColors.slideColor,
        },
        ticks: {
          color: appColors.slideColor,
        },
        position: 'right',
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: showTitle,
        text: 'ICDS CPU & Memory Usage',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return [tooltipItem[0].label, `V${icdsVersion}`, `${tooltipItem[0].dataset.label}:`]
          },
          label: function (context) {
            return [
              `${numberWithCommasDecimals(round(context.parsed.y, 2), 2)}${
                context.dataset.label === 'CPU' ? '%' : 'MB'
              }`,
            ]
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  return <Bar ref={chartRef} options={chartOptions} data={createChartData()} />
}

export default IcdsUtilizationChart
