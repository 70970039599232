import React from 'react'
import Box from '@mui/material/Box'
import { Scatter } from 'react-chartjs-2'
import { appColors } from 'utils'
import moment from 'moment'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const ApiHealthSummaryChart = ({ data }) => {
  const { theme, getChartBackColor } = useInnovaTheme()

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      annotation: {},
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: true,
        text: 'API Trendlines',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return [
              `${new moment(context.parsed.x).format().substring(0, 10)} date`,
              `           ${context.parsed.y} count`,
            ]
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        type: 'time',
        time: {
          unit: 'day',
        },
        title: {
          display: false,
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        beginAtZero: true,
        reverse: false,
        title: {
          display: true,
          text: 'Count',
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  }

  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  // function getLineColor(inputVal, baseColor) {
  //   // if (inputVal > 80) return 'tomato'
  //   // if (inputVal > 50) return 'orange'

  //   return baseColor
  // }

  const getLineColor = (datasetIndex) => {
    switch (datasetIndex) {
      case 0:
        return '#59FF00CF'
      case 1:
        return '#389F00CF'
      case 2:
        return '#00A3FFCF'
      case 3:
        return '#00669FCF'
      case 4:
        return '#F800FFCF'
      case 5:
        return '#9B009FCF'
      case 6:
        return '#FFFF00CF'
      case 7:
        return '#9F9D00CF'
      case 8:
        return '#FF0000CF'
      case 9:
        return '#9F0000CF'
      case 10:
        return '#FF9900CF'
      case 11:
        return '#9F6000CF'
      default:
        return '#F0F0F0'
    }
  }

  const getDatasetIndex = (logItemLevel, logItemType) => {
    switch (logItemType) {
      case 'api':
        if (logItemLevel === 'error') return 0
        if (logItemLevel === 'warn') return 1
        break
      case 'model':
        if (logItemLevel === 'error') return 2
        if (logItemLevel === 'warn') return 3
        break
      case 'store':
        if (logItemLevel === 'error') return 4
        if (logItemLevel === 'warn') return 5
        break
      case 'chat':
        if (logItemLevel === 'error') return 6
        if (logItemLevel === 'warn') return 7
        break
      case 'icds':
        if (logItemLevel === 'error') return 8
        if (logItemLevel === 'warn') return 9
        break
      case 'other':
      default:
        if (logItemLevel === 'error') return 10
        if (logItemLevel === 'warn') return 11
        return -1
    }
  }

  const createChartData = () => {
    const chartJsData = {
      datasets: [
        {
          type: 'line',
          label: 'Api-Errors',
          data: [],
          borderColor: '#59FF00CF',
          backgroundColor: '#59FF00CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#59FF00CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Api-Warnings',
          data: [],
          borderColor: '#389F00CF',
          backgroundColor: '#389F00CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#389F00CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Model-Errors',
          data: [],
          borderColor: '#00A3FFCF',
          backgroundColor: '#00A3FFCF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#00A3FFCF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Model-Warnings',
          data: [],
          borderColor: '#00669FCF',
          backgroundColor: '#00669FCF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#00669FCF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Store-Errors',
          data: [],
          borderColor: '#F800FFCF',
          backgroundColor: '#F800FFCF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#F800FFCF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Store-Warnings',
          data: [],
          borderColor: '#9B009FCF',
          backgroundColor: '#9B009FCF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#9B009FCF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Chat-Errors',
          data: [],
          borderColor: '#FFFF00CF',
          backgroundColor: '#FFFF00CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#FFFF00CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Chat-Warnings',
          data: [],
          borderColor: '#9F9D00CF',
          backgroundColor: '#9F9D00CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#9F9D00CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Icds-Errors',
          data: [],
          borderColor: '#FF0000CF',
          backgroundColor: '#FF0000CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#FF0000CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Icds-Warnings',
          data: [],
          borderColor: '#9F0000CF',
          backgroundColor: '#9F0000CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#9F0000CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Other-Errors',
          data: [],
          borderColor: '#FF9900CF',
          backgroundColor: '#FF9900CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#FF9900CF'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'Other-Warnings',
          data: [],
          borderColor: '#9F6000CF',
          backgroundColor: '#9F6000CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#9F6000CF'),
            borderWidth: 3,
          },
        },
      ],
    }

    if (Array.isArray(data)) {
      data.forEach((item) => {
        if (item.level === 'error' || item.level === 'warn') {
          const date = item.ts.split('T')[0]
          const dsIdx = getDatasetIndex(item.level, item.type)
          const index = chartJsData.datasets[dsIdx].data.findIndex((element) => date === element.x)
          if (index < 0) {
            chartJsData.datasets[dsIdx].data.push({
              x: date,
              y: 1,
              color: getLineColor(dsIdx),
            })
          } else {
            chartJsData.datasets[dsIdx].data[index].y += 1
          }
        }
      })
    }
    return chartJsData
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '99.9%',
        overflow: 'hidden',
        backgroundColor: getChartBackColor(),
      }}>
      <Scatter options={options} data={createChartData()} />
    </Box>
  )
}

export default ApiHealthSummaryChart
