// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'

import { multiParse } from 'utils'
import useInnovaAxios from './useInnovaAxios'
import { Buffer } from 'buffer'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'

export const LOGO_PRIMARY = 'PRIMARY'
export const LOGO_SECONDARY = 'SECONDARY'

const useOperatorLogos = (initialOperator) => {
  const _isMounted = useRef(false)
  const [loading, setLoading] = useState(false)
  const [logos, setLogos] = useState([])
  const operator = useRef(initialOperator)
  const isFetching = useRef(false)
  const isDeleting = useRef(false)
  const isUpdating = useRef(false)
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  const deleteOperatorLogos = useInnovaAxios({
    url: '/operator/deleteLogos',
  })

  const isOperatorValid = () => {
    if (!operator.current) return false
    if (typeof operator.current !== 'string') return false
    if (operator.current === '') return false
    return true
  }

  useEffect(() => {
    _isMounted.current = true
    fetchLogos()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLogos = async () => {
    if (!_isMounted.current) return
    if (!isOperatorValid()) return
    if (isFetching.current) return

    isFetching.current = true
    const res = await getOperatorLogos({
      operator: operator.current,
    })

    isFetching.current = false
    if (!_isMounted.current) return
    if (res?.error) return
    if (!res?.data) return

    const mfpBoundary = res.data.substring(2, res.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(res.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === LOGO_PRIMARY) return logos[0].data
    if (type === LOGO_SECONDARY && logos.length > 1) return logos[1].data
    return null
  }

  const refreshLogos = (newOperator) => {
    if (!_isMounted.current) return
    if (newOperator) {
      operator.current = newOperator
    }
    fetchLogos()
  }

  const deleteLogo = async (type) => {
    if (!isOperatorValid()) return
    if (!Array.isArray(logos)) return { error: true, message: `no logos to delete` }
    if (logos.length === 0) return { error: true, message: `no logos to delete` }
    if (!type) return { error: true, message: `no type specified` }
    if (typeof type !== 'string') return { error: true, message: `type not a string` }
    if (type !== LOGO_PRIMARY && type !== LOGO_SECONDARY) return { error: true, message: `type not primary or secondary` }
    if (isDeleting.current) return
    if (!_isMounted.current) return
    setLoading(true)

    isDeleting.current = true
    let res = await deleteOperatorLogos({ operator: operator.current, imageSpecifier: type })
    isDeleting.current = false

    if (!_isMounted.current) return

    if (res?.error) {
      return { error: true, message: `${res?.error?.response?.data?.error}` }
    }

    if (type === LOGO_PRIMARY) {
      logos[0] = {
        data: "data:image/*;base64,",
        filename: "empty2",
        type: "text/plain; charset=utf-8"
      }
    }

    if (type === LOGO_SECONDARY && logos.length > 1) {
      logos[1] = {
        data: "data:image/*;base64,",
        filename: "empty2",
        type: "text/plain; charset=utf-8"
      }
    }

    setLoading(false)
    return { error: false, message: '' }
  }

  const uploadLogo = async (type, file) => {
    if (!isOperatorValid()) return
    if (isUpdating.current) return { error: true, message: `currently updating` }
    if (!file) return { error: true, message: `no file` }
    if (!_isMounted.current) return { error: true, message: `component not mounted` }
    if (typeof type !== 'string') return { error: true, message: `type not a string` }
    if (type !== LOGO_PRIMARY && type !== LOGO_SECONDARY) return { error: true, message: `type not primary or secondary` }

    let formData = new FormData()

    isUpdating.current = true
    setLoading(true)
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: '/operator/uploadLogos',
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('operator', operator.current)
    formData.append('imageSpecifier', type)
    formData.append('file', file)

    try {
      await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 60000,
        ...options,
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    } catch (err) {
      isUpdating.current = false
      if (_isMounted.current) setLoading(false)
      return { error: true, message: `${err?.response?.data?.error}` }
    }
    isUpdating.current = false

    if (_isMounted.current) setLoading(false)
    refreshLogos()

    return { error: false, message: `` }
  }

  return { getLogo, refreshLogos, deleteLogo, loading, uploadLogo }
}

export default useOperatorLogos
