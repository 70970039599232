import React, { useEffect } from 'react'
import map from 'lodash/map'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

const AUTH_HOME_ROUTE = '/well-list'

const MainRouter = ({ children, publicRoutes, privateRoutes }) => {
  const unlisten = React.useRef(null)
  const { isAuthenticated } = useInnovaAuth()
  let location = useLocation()
  const navigate = useNavigate()

  const redirectToHome = (isAuthenticated, pathname) => {
    if (isAuthenticated && pathname === '/') {
      navigate(AUTH_HOME_ROUTE)
    }
  }

  useEffect(() => {
    unlisten.current = (location) => {
      if (location) redirectToHome(isAuthenticated, location.pathname)
    }

    if (location) redirectToHome(isAuthenticated, location.pathname)

    return () => {
      unlisten.current()
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      {children}
      <Routes>
        {map(publicRoutes, ({ path, Component }) => {
          return <Route key={path} path={path} element={<Component />} />
        })}
        {map(privateRoutes, ({ path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              <PrivateRoute>
                <Component />
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

export default MainRouter
