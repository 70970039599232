import React, { useEffect, useRef } from 'react'
import MapView from 'components/common/MapView/MapView'
import { Box } from '@mui/material'
import useWellData from 'components/common/hooks/useWellData'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { convertTgtToLatLng } from 'utils/mapFunctions'

const WellMapCard = () => {
  const _isMounted = useRef(false)
  const { wellData } = useWellData()
  const { getUnitsText } = useUnits()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getLeaseLines = () => {
    if (!Array.isArray(wellData?.targets)) return []
    let leaseLines = []
    wellData?.targets.forEach((tgt, i) => {
      if (!tgt) return
      if (!tgt.hasOwnProperty('isLeaseLine')) return
      if (tgt.isLeaseLine === false) return
      if (!tgt.hasOwnProperty('polygonPoints')) return
      if (!Array.isArray(tgt.polygonPoints)) return
      if (tgt.polygonPoints.length < 2) return

      leaseLines.push({
        coords: convertTgtToLatLng(
          {
            lat: wellData?.summary?.latitude,
            lng: wellData?.summary?.longitude,
          },
          tgt,
          getUnitsText(UNITS_FOR.Depth),
        ),
      })
    })

    return leaseLines
  }

  const getWells = () => {
    if (!wellData) return
    if (!wellData?.summary) return

    let wells = {}

    let refWellFound = false

    if (Array.isArray(wellData?.offsetWells)) {
      wellData?.offsetWells.forEach((well) => {
        if (!Array.isArray(well?.surveyData)) return

        wells[well.offsetWellbore] = {
          id: well?.offsetWellbore,
          actualWell: well?.offsetWellbore,
          latitude: well.surveyData[0].latitude,
          longitude: well.surveyData[0].longitude,
          isPlan: well?.wellStatus !== 'Actual',
        }

        if (well.actualWell === wellData?.summary?.actualWell) {
          wells[well?.offsetWellbore].state = wellData?.summary?.state
          wells[well?.offsetWellbore].county = wellData?.summary?.county
          wells[well?.offsetWellbore].rig = wellData?.summary?.rig
          wells[well?.offsetWellbore].jobNum = wellData?.summary?.jobNum
          wells[well?.offsetWellbore].wellStatus = wellData?.summary?.wellStatus
          refWellFound = true
        }
      })
    }

    if (!refWellFound) {
      wells[wellData?.summary?.actualWell] = {
        id: wellData?.summary?.actualWell,
        actualWell: wellData?.summary?.actualWell,
        latitude: wellData?.summary?.latitude,
        longitude: wellData?.summary?.longitude,
        state: wellData?.summary?.state,
        county: wellData?.summary?.county,
        rig: wellData?.summary?.rig,
        jobNum: wellData?.summary?.jobNum,
        wellStatus: wellData?.summary?.wellStatus,
      }
    }

    return Object.values(wells)
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
      }}>
      {wellData?.summary ? (
        <MapView
          center={{
            lat: wellData?.summary?.latitude,
            lng: wellData?.summary?.longitude,
          }}
          zoom={12}
          wells={getWells()}
          leaseLines={getLeaseLines()}
          showWellPaths={true}
        />
      ) : null}
    </Box>
  )
}

export default WellMapCard
