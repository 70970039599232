import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom, assetSearchLayoutAtom, selectedAssetSearchAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import AssetSearchToolSnGrid from './AssetSearchToolSnGrid'
import AssetHistoryGrid from './AssetHistoryGrid'
import AssetSearchCharts from './AssetSearchCharts'

const AssetSearchPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [pageLayout, setPageLayout] = useRecoilState(assetSearchLayoutAtom)
  const isLoading = useRef(false)
  const [toolData, setToolData] = useState([])
  const [filteredToolData, setFilteredToolData] = useState([])

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const selectedAsset = useRecoilValue(selectedAssetSearchAtom)
  const [showControls, setShowControls] = useState(getPaneVisible(0))
  const [showAssetHistory, setShowAssetHistory] = useState(getPaneVisible(1))
  const [assetHistory, setAssetHistory] = useState(null)
  const [dateRange, setDateRange] = useState({ dateTo: '', dateFrom: '' })

  const getAssetSearch = useInnovaAxios({
    url: '/assets/assetSearch',
  })

  const getToolList = useInnovaAxios({
    url: '/assets/getToolSn',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchToolSn()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActivePage(PAGE_KEYS.assetSearchKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchAssetHistory()
  }, [selectedAsset, dateRange]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchToolSn = async () => {
    if (isLoading.current) return
    isLoading.current = true

    const response = await getToolList()
    if (_isMounted.current) {
      setToolData(Array.isArray(response.data) ? response.data : [])
      setFilteredToolData(Array.isArray(response.data) ? response.data : [])
      isLoading.current = false
    }
  }

  function validateDates(dates) {
    const { dateTo, dateFrom } = dates

    let newDates = { ...dates }
    if (new Date(Date.parse(dateFrom)).getTime() > new Date(Date.parse(dateTo)).getTime()) {
      newDates.dateFrom = dateTo
      newDates.dateTo = dateFrom
    }

    return newDates
  }

  const fetchAssetHistory = async () => {
    if (isLoading.current) return
    if (selectedAsset === null) {
      setAssetHistory(null)
      return
    }

    isLoading.current = true
    let dates = validateDates(dateRange)

    const response = await getAssetSearch({
      serialNum: selectedAsset.serialNum,
      toolType: selectedAsset.toolTypeEnum,
      dateTo: dates.dateTo ? dates.dateTo : null,
      dateFrom: dates.dateFrom ? dates.dateFrom : null,
    })
    if (_isMounted.current) {
      setAssetHistory(response.data ? response.data : null)
      isLoading.current = false
    }
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '40%' : '40%'
    if (index >= pageLayout.length) return index === 0 ? '40%' : '40%'
    return pageLayout[index].size
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showControls)
    setShowControls(!showControls)
  }

  const showHideAssetHistory = () => {
    onSetPaneVisible(1, !showAssetHistory)
    setShowAssetHistory(!showAssetHistory)
  }

  return (
    <SplitPane
      split='vertical'
      onDragFinished={(params) => onDragFinished(params, 0)}
      defaultSize={getInitialPaneSize(0)}
      size={showControls ? getInitialPaneSize(0) : '25px'}
      allowResize={showControls}
      style={{
        height: 'calc(100vh - 64px)',
        marginLeft: leftPos,
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
      }}>
      <AssetSearchToolSnGrid
        showHidePane={showHidePane}
        showControls={showControls}
        dateRange={dateRange}
        setDateRange={setDateRange}
        toolData={toolData}
        setFilteredToolData={setFilteredToolData}
      />
      <SplitPane
        split='horizontal'
        size={showAssetHistory ? getInitialPaneSize(1) : 'calc(100% - 25px)'}
        allowResize={showAssetHistory}
        defaultSize={getInitialPaneSize(1)}
        onDragFinished={(params) => onDragFinished(params, 1)}>
        <AssetSearchCharts assetHistory={assetHistory} toolData={filteredToolData} />
        <AssetHistoryGrid
          showHideGridPane={showHideAssetHistory}
          showGrid={showAssetHistory}
          assetHistory={assetHistory}
        />
      </SplitPane>
    </SplitPane>
  )
}

export default AssetSearchPage
