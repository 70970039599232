import React, { useRef, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WitsmlSelectionGrid = ({ selectedLog }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()
    return time1 < time2
  }

  const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
  }

  const getGridData = () => {
    let data = [
      { id: 0, label: 'Log Name', value: selectedLog?.logName ? selectedLog?.logName : '' },
      { id: 1, label: 'Type', value: selectedLog?.logType ? selectedLog?.logType : '' },
      { id: 2, label: 'Start Time', value: selectedLog?.startTime ? dateTimeFormatter(selectedLog?.startTime) : '' },
      { id: 3, label: 'End Time', value: selectedLog?.endTime ? dateTimeFormatter(selectedLog?.endTime) : '' },
      { id: 4, label: 'Start Depth', value: selectedLog?.startDepth ? selectedLog?.startDepth : '' },
      { id: 5, label: 'End Depth', value: selectedLog?.endDepth ? selectedLog?.endDepth : '' },
    ]

    data.splice(selectedLog?.endDepth > 0 ? 2 : 4, 2)
    return data
  }

  const updateGridData = () => {
    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: getGridData(),
      })
    }

    autoSizeColumns()
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    updateGridData()
  }, [selectedLog]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const leftAlignCell = useMemo(() => ({ display: 'flex', justifyContent: 'start', alignItems: 'center' }), [])

  let columnDefs = [
    {
      field: 'label',
      colId: 'label',
      headerName: '',
      minWidth: 200,
      cellStyle: leftAlignCell,
    },
    {
      field: 'value',
      colId: 'value',
      headerName: 'Value',
      minWidth: 200,
      cellStyle: leftAlignCell,
    },
  ]

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={getGridData()}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={0}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default WitsmlSelectionGrid
