import React, { useRef } from 'react'
import { appColors } from 'utils'
import { Bar } from 'react-chartjs-2'
import { currentInfrastructureAcqCacheSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DataCacheChart = ({ id }) => {
  const chartRef = useRef()
  const data = useRecoilValue(currentInfrastructureAcqCacheSelector)
  const { theme } = useInnovaTheme()

  const createChartData = () => {
    if (!id || !Array.isArray(data[id])) return { datasets: [] }
    if (data[id].length === 0) return { datasets: [] }

    var chartData = {
      labels: data[id].map((item) => {
        return item?.wellName
      }),
      datasets: [
        {
          label: 'Raw',
          data: [
            ...data[id].map((item, i) => {
              return {
                x: i + 1,
                y: item?.rawCache?.witsDataCount,
                startTime: item?.rawCache?.startTime,
                stopTime: item?.rawCache?.stopTime,
              }
            }),
          ],
          borderColor: '#3B21D9',
          backgroundColor: '#3B21D9',
          maxBarThickness: 24,
        },
        {
          label: '02 mins',
          data: [
            ...data[id].map((item, i) => {
              return {
                x: i + 1,
                y: item?.dec02Cache?.witsDataCount,
                startTime: item?.dec02Cache?.startTime,
                stopTime: item?.dec02Cache?.stopTime,
              }
            }),
          ],
          borderColor: '#DB3C94',
          backgroundColor: '#DB3C94',
          maxBarThickness: 24,
        },
        {
          label: '05 mins',
          data: [
            ...data[id].map((item, i) => {
              return {
                x: i + 1,
                y: item?.dec05Cache?.witsDataCount,
                startTime: item?.dec05Cache?.startTime,
                stopTime: item?.dec05Cache?.stopTime,
              }
            }),
          ],
          borderColor: '#5A87C6',
          backgroundColor: '#5A87C6',
          maxBarThickness: 24,
        },
        {
          label: '10 mins',
          data: [
            ...data[id].map((item, i) => {
              return {
                x: i + 1,
                y: item?.dec10Cache?.witsDataCount,
                startTime: item?.dec10Cache?.startTime,
                stopTime: item?.dec10Cache?.stopTime,
              }
            }),
          ],
          borderColor: appColors.slideColor,
          backgroundColor: appColors.slideColor,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const chartOptions = {
    layout: {
      padding: {
        right: 20,
      },
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Count',
          font: {
            size: 20,
            weight: 600,
          },
          color: 'rgba(102, 102, 102, 1)',
        },
        ticks: {
          precision: 0,
          color: 'rgba(102, 102, 102, 1)',
        },
        position: 'left',
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
        display: true,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return [tooltipItem[0]?.label ? tooltipItem[0]?.label : '']
          },
          label: function (context) {
            return [
              `${context?.dataset?.label}: ${context?.raw?.y ? context?.raw?.y : 0}`,
              `Start: ${isValidDate(context?.raw?.startTime) ? context?.raw?.startTime : ''}`,
              `Stop: ${isValidDate(context?.raw?.stopTime) ? context?.raw?.stopTime : ''}`,
            ]
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  return (
    <Bar
      ref={chartRef}
      options={chartOptions}
      data={data[id]?.length > 0 ? createChartData() : { labels: ['no wells'], datasets: [] }}
    />
  )
}

export default DataCacheChart
