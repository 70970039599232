import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar, Avatar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import { debounce } from 'lodash'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getComponentColor, getDrillStringIcon } from 'components/common/drillStringFunctions'
import useDrillStringDropDowns from 'components/common/hooks/useDrillStringDropDowns'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ComponentCatalogPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [catalogData, setCatalogData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedComponent = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getUnitsText } = useUnits()
  const { getCompTypesList, getDropDownLists } = useDrillStringDropDowns()
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getComponents = useAxiosGzip({
    url: '/componentCatalog/getCompCatalogGz',
  })

  const addComponent = useInnovaAxios({
    url: '/componentCatalog/addCatalogItem',
  })

  const updateComponent = useInnovaAxios({
    url: '/componentCatalog/updateCatalogItem',
  })

  const deleteComponent = useInnovaAxios({
    url: '/componentCatalog/deleteCatalogItem',
  })

  const resetCatalog = useInnovaAxios({
    url: '/componentCatalog/resetCatalog',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.compCatalogPageKey)
    fetchCompCatalog()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  const fetchCompCatalog = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getComponents()

    if (!_isMounted.current) return
    setLoading(false)

    if (response?.error) return
    setCatalogData(Array.isArray(response.data) ? response.data : [])
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  let reqFields = useMemo(() => ['compType', 'description', 'od', 'id'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function isPinnedRowDataCompleted() {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('compCatalogGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('compCatalogGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'CompCatalog.xlsx',
            sheetName: 'Catalog',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addComponent(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.recordId = res.data.recordId
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('recordId')) return

    if (isUpdating.current) return
    data = htmlSymbolHandling(data)

    isUpdating.current = true
    let res = await updateComponent(data)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
    selectedComponent.current = cloneDeep(data)

    setConfirm({
      show: true,
      title: 'Delete Component',
      text: `Are you sure you want to delete ${data.description}?`,
      type: 'delete',
    })
  }

  const onClickReset = () => {
    setConfirm({
      show: true,
      title: 'Delete Component',
      text: `Are you sure you want to reset the catalog to defaults?`,
      type: 'reset',
    })
  }

  const handleReset = async () => {
    if (isDeleting.current) return
    setLoading(true)
    isDeleting.current = true
    let res = await resetCatalog()
    isDeleting.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    await fetchCompCatalog()
  }

  const handleDelete = async () => {
    if (!selectedComponent.current) return
    if (!selectedComponent.current.hasOwnProperty('recordId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteComponent(selectedComponent.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedComponent.current],
      })
    }

    selectedComponent.current = null
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const componentIconRenderer = useCallback((params) => {
    if (!params.data) return null
    if (!params?.data?.hasOwnProperty('compType')) return null
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          borderRadius: '2px',
        }}>
        <Avatar
          alt={params?.data?.compType}
          src={getDrillStringIcon(params?.data?.compType)}
          style={{ width: 30, height: 30, alignItems: 'center', justifyContent: 'center' }}
        />
      </Box>
    )
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'componentIcon',
        colId: 'componentIcon',
        headerName: '',
        editable: false,
        filter: null,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: centerAlignCell,
        cellRenderer: componentIconRenderer,
      },
      {
        headerName: 'Description',
        colId: 'description',
        field: 'description',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        headerName: 'Tool Type',
        colId: 'compType',
        field: 'compType',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          const toolTypes = getCompTypesList()
          return { values: Array.isArray(toolTypes) ? toolTypes : [] }
        },
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getComponentColor(params.value),
          }
        },
      },
      {
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'od',
        field: 'od',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'Od...'
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.od, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) < parseFloat(params?.data?.id)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `OD must be greater than id`,
            })
            return false
          }

          let data = { ...params.data }
          data.od = params.newValue

          if (params.node?.rowPinned !== 'bottom') {
            handleUpdate(data)
            return true
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        },
      },
      {
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'id',
        field: 'id',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'Id...'
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.id, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) > parseFloat(params?.data?.od)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `ID must be less than od`,
            })
            return false
          }

          let data = { ...params.data }
          data.id = params.newValue

          if (params.node?.rowPinned !== 'bottom') {
            handleUpdate(data)
            return true
          }

          inputRow.current[params.colDef.field] = params.newValue
          return true
        },
      },
      {
        headerName: `TJ OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'tjOd',
        field: 'tjOd',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.tjOd, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) < parseFloat(params?.data?.tjId)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `TJ OD must be greater than TJ ID`,
            })
            return false
          }

          let data = { ...params.data }
          data.tjOd = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        headerName: `TJ ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'tjId',
        field: 'tjId',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.tjId, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) > parseFloat(params?.data?.tjOd)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `TJ ID must be less than TJ OD`,
            })
            return false
          }

          let data = { ...params.data }
          data.tjId = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        headerName: `Adj Wt (${getUnitsText(UNITS_FOR.UnitWeight)})`,
        colId: 'adjWeight',
        field: 'adjWeight',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.adjWeight, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) < parseFloat(params?.data?.nomWeight)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `Adjusted Wt must be greater than nominal wt`,
            })
            return false
          }

          let data = { ...params.data }
          data.adjWeight = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        headerName: `Nom Wt (${getUnitsText(UNITS_FOR.UnitWeight)})`,
        colId: 'nomWeight',
        field: 'nomWeight',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.nomWeight, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) > parseFloat(params?.data?.adjWeight)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `Nominal Wt must be less than adjusted wt`,
            })
            return false
          }

          let data = { ...params.data }
          data.nomWeight = params.newValue
          handleUpdate(data)
          return true
        },
      },
      {
        field: 'materialGrade',
        colId: 'materialGrade',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        headerName: 'Material Grade',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let dropDownLists = getDropDownLists()
          let filteredMaterials = dropDownLists?.materialGrades.filter(
            (material) => material.type === params.data?.compType,
          )

          return {
            values: filteredMaterials.map((item) => item.matGrade),
          }
        },
      },
      {
        headerName: 'Connection',
        colId: 'connection',
        field: 'connection',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueGetter: (params) => unescapeHtml(params.data?.connection),
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let dropDownLists = getDropDownLists()
          return { values: dropDownLists?.connectionTypes }
        },
      },
      {
        headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'length',
        field: 'length',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.length, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        headerName: `Burst (${getUnitsText(UNITS_FOR.Pressure)})`,
        colId: 'burst',
        field: 'burst',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.burst, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        headerName: `Collapse (${getUnitsText(UNITS_FOR.Pressure)})`,
        colId: 'collapse',
        field: 'collapse',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.collapse, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        headerName: `Tensile Yield`,
        colId: 'tensileYield',
        field: 'tensileYield',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.tensileYield, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        headerName: `Torsional Yield`,
        colId: 'torsionalYield',
        field: 'torsionalYield',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.torsionalYield, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
    ],
    [centerAlignCell, getUnitsText, getDropDownLists, getCompTypesList, handleUpdate, componentIconRenderer], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.type === 'delete' ? handleDelete : handleReset}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={catalogData}
            columnDefs={sortColDefs(columnDefs, 'compCatalogGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchCompCatalog()
              },
            },
            {
              icon: <Iconify icon='mdi:lock-reset' style={{ width: '25px', height: '25px', color: 'red' }} />,
              name: 'Reset',
              onClick: () => {
                onClickReset()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default ComponentCatalogPage
