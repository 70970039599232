import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { appColors } from 'utils'
import fileDownload from 'js-file-download'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import * as jszip from 'jszip'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'


const ErrorModelUploadModal = ({ open, onClose }) => {
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  const [files, setFiles] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showWait, setShowWait] = useState(false)
  const [importSucceeded, setImportSucceeded] = useState(false)
  const title = 'Upload Error Models'
  const { theme } = useInnovaTheme()


  const uploadFiles = async (emFiles) => {
    const uploadUrl = '/errorModels/uploadErrorModel'
    if (!Array.isArray(files)) return false

    const zip = new jszip()
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i])
    }

    let zipFile = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })

    let formData = new FormData()
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: uploadUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('file', zipFile)

    try {
      const response = await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 300000,
        ...options,
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      if (response.error) {
        console.error('uploadErrorModel response error:', response.error)
        return false
      } else {
        return response?.data
      }
    } catch (err) {
      console.error('uploadErrorModel exception:', err)
      return false
    }
  }

  const handleUpload = async () => {
    setShowWait(true)
    const response = await uploadFiles(files)
    setShowWait(false)
    if (response) {
      if (response?.files && response.files.length > 0) {
        let errors = false
        response.files.forEach((file) => {
          if (!file.success) {
            errors = true
          }
        })
        if (errors) {
          // save the complete response
          let dateTime = new Date(Date.now()).toISOString().split('.')[0].replaceAll(':', '-')
          let filename = 'ErrorModelUploadResponse-' + dateTime + '.log'
          fileDownload(JSON.stringify(response), filename)

          setStatus({
            show: true,
            severity: 'error',
            message: 'Errors detected during upload, please check the log file',
          })
        } else {
          if (response.warnings.length > 0) {
            // save the complete response
            let dateTime = new Date(Date.now()).toISOString().split('.')[0].replaceAll(':', '-')
            let filename = 'ErrorModelUploadResponse-' + dateTime + '.log'
            fileDownload(JSON.stringify(response), filename)

            setStatus({
              show: true,
              severity: 'warning',
              message: 'Upload completed with warnings, please check log file',
            })
            setImportSucceeded(true)
          } else {
            setStatus({ show: true, severity: 'success', message: 'Upload completely successfully' })
            setImportSucceeded(true)
            handleCloseEMImport()
          }
        }
      }
    } else {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Error detected during upload, no response',
      })
    }
  }

  const onSelectMultipleFiles = (event) => {
    let selFiles = []
    if (event.target.files && event.target.files.length > 0) {
      let fileCount = event.target.files.length
      if (fileCount > 20) {
        fileCount = 20
        setStatus({ show: true, severity: 'info', message: '20 file limit enforced' })
      }
      for (let i = 0; i < fileCount; i++) {
        selFiles.push(event.target.files[i])
      }
    }
    setFiles(selFiles)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseEMImport = async () => {
    onClose(importSucceeded)
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '600px',
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={handleCloseEMImport}>
      <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={showWait}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <form id='errorModelImport'>
          <Box sx={{ height: 'fit-content', margin: '6px' }}>
            <label htmlFor='btn-upload'>
              <input
                id='btn-upload'
                name='btn-upload'
                style={{ display: 'none' }}
                type='file'
                multiple={true}
                accept='.ipm'
                onChange={onSelectMultipleFiles}
              />
              <Button
                sx={{
                  marginRight: '10px !important',
                  color: appColors.headerTextColor,
                  border: '1px solid #C0C0C0',
                  '&:hover': {
                    borderColor: '#FFF',
                  },
                }}
                variant='outlined'
                component='span'
                type='button'>
                Select files
              </Button>
            </label>
            {files && files.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  border: '1px solid black',
                  borderRadius: '4px',
                  padding: '8px 8px 8px 8px',
                  marginTop: '6px',
                }}>
                {files.map((file, index) => (
                  <Box
                    key={`${index}-error-model-file`}
                    sx={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block',
                      maxWidth: '100%',
                      verticalAlign: 'middle',
                      color: theme === 'dark' ? appColors.headerTextColor : '#000',
                    }}>{`${index + 1})  ${file.name}`}</Box>
                ))}
              </Box>
            ) : null}
          </Box>
          <Button
            sx={{ marginTop: '16px', marginLeft: '6px' }}
            color='primary'
            variant='contained'
            type='button'
            onClick={handleUpload}>
            Import
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' type='button' onClick={handleCloseEMImport} color='primary'>
          Close
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={4000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default ErrorModelUploadModal
