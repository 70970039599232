import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { camelCaseToLabel, numberWithCommasDecimals, uuidv4 } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DirectionalScoreCardSettingsModal = ({ organization, onClose }) => {
  const gridApi = useRef(null)
  const _isMounted = useRef(true)
  const [isLoading, setLoading] = useState(false)
  const { getUnitsText } = useUnits()
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const getSettings = useInnovaAxios({
    url: '/admin/directionalScoreCard/getDirectionalScoreCardSettings',
  })

  const updateSettings = useInnovaAxios({
    url: '/admin/directionalScoreCard/updateDirectionalScoreCardSettings',
  })

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const groupRowRendererParams = {
    suppressCount: true,
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) {
      return
    }

    gridApi.current?.autoSizeAllColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getSettings({ organization: organization })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      return
    }

    if (!res.data) return

    let rowData = []
    for (let wellSection in res.data) {
      let header = ''
      let units = 'dogleg'
      if (wellSection === 'landing') {
        header = `Landing tolerance`
        units = 'depth'
      }

      if (wellSection === 'ArtificialLift') header = `Artificial Lift Limits`
      if (wellSection === 'vertical') header = `Vertical DLS Limit`
      if (wellSection === 'tangent') header = `Tangent DLS Limit`
      if (wellSection === 'lateral') header = `Lateral DLS Limit`
      if (wellSection === 'rss') {
        header = `RSS Curve DLS Tolerance`
        units = '%'
      }
      if (wellSection === 'motor') {
        header = `Motor Curve DLS Tolerance`
        units = '%'
      }

      for (let criteria in res.data[wellSection]) {
        if (wellSection !== 'ArtificialLift') {
          let newRow = {
            uid: uuidv4(),
            group: wellSection,
            groupHeader: header,
            criteriaName: criteria,
            label: camelCaseToLabel(criteria),
            min: res.data[wellSection][criteria].min,
            max: res.data[wellSection][criteria].max,
            units: units,
          }

          rowData.push(newRow)
          continue
        }

        let newRow = {
          uid: uuidv4(),
          group: wellSection,
          groupHeader: header,
          criteriaName: criteria,
          label: camelCaseToLabel(criteria),
          min: res.data[wellSection][criteria],
          max: 0,
          units: '',
        }

        rowData.push(newRow)
      }
    }

    gridApi.current.setGridOption('rowData', rowData)
  }

  const handleUpdate = useCallback(
    async (params) => {
      if (!params) return

      let data = {}
      params.api.forEachNode((node) => {
        if (!node.data) return
        if (!data[node.data.group]) data[node.data.group] = {}
        if (!data[node.data.group][node.data.criteriaName]) {
          if (node.data.group !== 'ArtificialLift') {
            data[node.data.group][node.data.criteriaName] = { min: node.data.min, max: node.data.max }
          }

          if (node.data.group === 'ArtificialLift') {
            data[node.data.group][node.data.criteriaName] = node.data.min
          }
        }
      })

      setLoading(true)
      let res = await updateSettings({ organization: organization, settings: JSON.stringify(data) })
      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        return
      }
    },
    [updateSettings, organization],
  )

  const gridOptions = useMemo(() => {
    return {
      onCellEditingStopped: (params) => {
        handleUpdate(params)
      },
      loadingOverlayComponent: CustomLoadingOverlay,
    }
  }, [handleUpdate])

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchData()
  }

  const getContextMenuItems = (params) => {
    return ['copy']
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'group',
        colId: 'group',
        rowGroup: true,
        hide: true,
        editable: false,
        valueGetter: (params) => {
          let unitsText = ''
          if (params.data?.units === 'dogleg') unitsText = `(${getUnitsText(UNITS_FOR.Dogleg)})`
          if (params.data?.units === 'depth') unitsText = `(${getUnitsText(UNITS_FOR.Depth)})`
          if (params.data?.units === '%') unitsText = `(%)`
          return params.data?.groupHeader + ' ' + unitsText
        },
      },
      {
        minWidth: 200,
        field: 'criteriaName',
        colId: 'criteriaName',
        headerName: 'Criteria',
        editable: false,
        valueGetter: (params) => params.data?.label,
      },
      {
        minWidth: 150,
        field: 'min',
        colId: 'min',
        headerName: 'Min',
        editable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: (params) => {
          return {
            min: params.data?.units !== '%' ? -100000000 : 0,
            max: params.data?.units !== '%' ? 100000000 : 1,
            precision: params.data?.units !== '%' ? 2 : 4,
          }
        },
      },
      {
        minWidth: 150,
        field: 'max',
        colId: 'max',
        headerName: 'Max',
        editable: (params) => params.data?.group !== 'ArtificialLift',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: (params) => {
          return {
            min: params.data?.units !== '%' ? -100000000 : 0,
            max: params.data?.units !== '%' ? 100000000 : 1,
            precision: params.data?.units !== '%' ? 2 : 4,
          }
        },
      },
    ],
    [getUnitsText],
  )

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth='xl'
        PaperProps={{
          sx: {
            width: '70vw',
            height: '80vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => onClose()}>
        <DialogTitle>Directional Scorecard Settings</DialogTitle>
        <DialogContent>
          <div className={getAgGridTheme()} style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              loading={isLoading}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              headerHeight={30}
              onFirstDataRendered={onFirstDataRendered}
              onGridReady={onGridReady}
              getContextMenuItems={getContextMenuItems}
              gridOptions={gridOptions}
              getRowId={getRowId}
              groupRowRendererParams={groupRowRendererParams}
              groupDisplayType={'groupRows'}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => onClose()} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default DirectionalScoreCardSettingsModal
