import React, { useEffect, useRef } from 'react'
import { Vector3, CatmullRomCurve3, DoubleSide, Shape, ExtrudeGeometry, BufferGeometry, BufferAttribute } from 'three'
import { Sphere, Box, Cylinder } from '@react-three/drei'
import { rotateAroundWorldAxis, resetObjectRotation } from './WorkSight'
import TextLabel from './TextLabel'
import { cloneDeep } from 'lodash'

const TARGET_OPACITY = 0.3

export const DrillersTargets = ({ targets, display, labelColor }) => {
  if (!display) return null
  if (!Array.isArray(targets)) return null
  if (targets.length === 0) return null

  return targets.map((tgt, i) => {
    if (!tgt.hasDrillersTarget) return null

    let tgtCopy = cloneDeep(tgt)
    tgtCopy.geometry = 'polygon'
    tgtCopy.points = cloneDeep(tgt.drillersTargetPoints)
    tgtCopy.color = 'red'

    return (
      <group key={`tgtDriller${i}`}>
        <TextLabel
          key={`tgtLabel${i}`}
          label={`${tgtCopy.drillersTargetText}`}
          size={1}
          color={labelColor}
          position={new Vector3(tgtCopy.x - 2, tgtCopy.y + 2, tgtCopy.z - 2)}
        />
        <Target tgt={tgtCopy} key={`tgtGeoDriller${i}`} />
      </group>
    )
  })
}

const Targets = ({ targets, display, labelColor }) => {
  if (!display) return null
  if (!Array.isArray(targets)) return null
  if (targets.length === 0) return null

  return targets.map((tgt, i) => {
    return (
      <group key={`tgt${i}`}>
        <TextLabel
          key={`tgtLabel${i}`}
          label={`${tgt.text}`}
          size={1}
          color={labelColor}
          position={new Vector3(tgt.x + 2, tgt.y + 1, tgt.z + 2)}
        />
        <Target tgt={tgt} key={`tgtGeo${i}`} />
      </group>
    )
  })
}
const RectangleTarget = ({ tgt }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current === null) return
    resetObjectRotation(ref.current)
    rotateTarget(ref.current, tgt)
  }, [ref, tgt])

  return (
    <group ref={ref} key={`rectTarget${tgt.name}`}>
      <Box args={[tgt.length, tgt.height, tgt.width]}>
        <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={TARGET_OPACITY} />
      </Box>
    </group>
  )
}
const CircleTarget = ({ tgt }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current === null) return

    resetObjectRotation(ref.current)
    rotateTarget(ref.current, tgt)
  }, [ref, tgt])

  return (
    <group ref={ref} key={`circleTarget${tgt.name}`}>
      <Cylinder args={[tgt.radius, tgt.radius, tgt.thickness, 32, 1, false, tgt.arcStart, tgt.arcLength]}>
        <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={TARGET_OPACITY} />
      </Cylinder>
    </group>
  )
}
const EllipseTarget = ({ tgt }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current === null) return

    resetObjectRotation(ref.current)
    rotateAroundWorldAxis(ref.current, 'x', new Vector3(Math.PI / 2.0, 0, 0))
    rotateAroundWorldAxis(ref.current, 'y', new Vector3(0, Math.PI / 2.0, 0))
    rotateTarget(ref.current, tgt)
  }, [ref, tgt])

  function CalcGeo() {
    const shape = new Shape()
    let numPoints = 100
    let angleInterval = Math.abs(tgt.arcLength) / numPoints
    for (let i = 0; i < numPoints; i++) {
      let segmentAngle = tgt.arcStart + angleInterval * i
      let cosAngle = Math.cos(segmentAngle)
      let sinAngle = Math.sin(segmentAngle)
      let signCos = 1
      let signSin = 1
      if (cosAngle < 0) signCos = -1
      if (sinAngle < 0) signSin = -1

      let x = signSin * Math.sqrt(Math.pow(tgt.semiMajor, 2) * Math.pow(sinAngle, 2))
      let y = signCos * Math.sqrt(Math.pow(tgt.semiMinor, 2) * Math.pow(cosAngle, 2))

      if (i === 0) shape.moveTo(x, y)
      if (i > 0) shape.lineTo(x, y)
    }

    const extrudeSettings = {
      steps: 2,
      depth: tgt.thickness,
      bevelEnabled: false,
    }

    const geo = new ExtrudeGeometry(shape, extrudeSettings)
    return geo
  }

  return (
    <group ref={ref} key={`ellipseTarget${tgt.name}`}>
      <mesh visible geometry={CalcGeo()}>
        <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={TARGET_OPACITY} />
      </mesh>
    </group>
  )
}

const Target = ({ tgt }) => {
  if (tgt.geometry === 'point') {
    return (
      <group position={[tgt.x, tgt.y, tgt.z]} key={`pointTgt${tgt.name}`}>
        <Sphere args={[2, 16, 16]}>
          <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={TARGET_OPACITY} />
        </Sphere>
      </group>
    )
  }

  if (tgt.geometry === 'rectangle') {
    return (
      <group position={[tgt.x, tgt.y, tgt.z]} key={`recTgtGroup${tgt.name}`}>
        <RectangleTarget tgt={tgt} />
      </group>
    )
  }

  if (tgt.geometry === 'circle') {
    return (
      <group position={new Vector3(tgt.x, tgt.y, tgt.z)} key={`circTgtGroup${tgt.name}`}>
        <CircleTarget tgt={tgt} />
      </group>
    )
  }

  if (tgt.geometry === 'polygon' && tgt.isLeaseLine && Array.isArray(tgt.points) && tgt.points.length > 1) {
    let pts = []
    tgt.points.forEach((pt) => pts.push(new Vector3(pt.top.x, pt.top.y, pt.top.z)))

    return pts.map((pt, i) => {
      if (i === 0) return null
      const path = new CatmullRomCurve3([pt, pts[i - 1]])
      return (
        <mesh visible key={`polyPoint${tgt.name}${i}`}>
          <tubeGeometry args={[path, 64, 1, 16, false]} />
          <meshStandardMaterial color={tgt.color} side={DoubleSide} />
        </mesh>
      )
    })
  }

  if (tgt.geometry === 'polygon' && !tgt.isLeaseLine && Array.isArray(tgt.points) && tgt.points.length > 1) {
    let points = tgt.points

    // Create vertices for the top and bottom faces
    const topVertices = []
    const bottomVertices = []

    points.forEach((point) => {
      topVertices.push(point.top.x, point.top.y, point.top.z || 0)
      bottomVertices.push(point.bottom.x, point.bottom.y, point.bottom.z || 0)
    })

    // Create faces for the top and bottom faces
    const topFaces = []
    const bottomFaces = []

    for (let i = 0; i < points.length - 2; i++) {
      topFaces.push(0, i + 1, i + 2)
      bottomFaces.push(0, i + 2, i + 1) // Reverse order for bottom face
    }

    // Create sides vertices by connecting corresponding top and bottom points
    // No need to duplicate the vertices, just create side faces
    const sideFaces = []

    // Assuming the first vertex of the top and the first vertex of the bottom are the same point
    // then the loop should start at 1 and connect each point to the next, forming quads
    // These quads are represented by two triangles each
    for (let i = 0; i < points.length; i++) {
      let nextIndex = (i + 1) % points.length

      // First triangle of the side face
      sideFaces.push(i, nextIndex, i + points.length)
      // Second triangle of the side face
      sideFaces.push(nextIndex, nextIndex + points.length, i + points.length)
    }

    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new BufferAttribute(new Float32Array([...topVertices, ...bottomVertices]), 3))

    // Add top, bottom, and side faces to geometry
    geometry.setIndex([...topFaces, ...bottomFaces, ...sideFaces])

    return (
      <mesh geometry={geometry}>
        <meshBasicMaterial color={tgt.color} transparent opacity={TARGET_OPACITY} side={DoubleSide} />
      </mesh>
    )
  }

  if (tgt.geometry === 'ellipse') {
    return (
      <group position={new Vector3(tgt.x, tgt.y, tgt.z)} key={`ellipseTgtGroup${tgt.name}`}>
        <EllipseTarget tgt={tgt} />
      </group>
    )
  }

  return null
}

function rotateTarget(tgtObj, tgt) {
  let rotation = new Vector3(0, tgt.rotation, 0)
  rotateAroundWorldAxis(tgtObj, 'y', rotation)

  rotation = new Vector3(0, 0, tgt.dipAngle)
  rotateAroundWorldAxis(tgtObj, 'z', rotation)

  rotation = new Vector3(0, tgt.dipAzi, 0)
  rotateAroundWorldAxis(tgtObj, 'y', rotation)
}

export default Targets
