import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import EndpointUsageGrid from './EndpointUsageGrid'
import EndpointUsageBarChart from './EndpointUsageBarChart'
import Carousel from 'react-material-ui-carousel'
import EndpointUsageRequestsPerSecondChart from './EndpointUsageRequestsPerSecondChart'

const EndpointUsagePage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [showChart, setShowChart] = useState(true)
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminInnovaEndpointUsageKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SplitPane
      split='horizontal'
      defaultSize={'50%'}
      size={showChart ? '50%' : 0}
      style={{
        marginTop: '64px',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        minHeight: 'calc(100vh - 64px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}
      allowResize={true}>
      <Carousel autoPlay={false} height={'100%'} fullHeightHover={false} swipe={false} sx={{ width: '100%' }}>
        <EndpointUsageBarChart data={filteredData} />
        <EndpointUsageRequestsPerSecondChart data={filteredData} />
      </Carousel>
      <EndpointUsageGrid
        showChart={showChart}
        setShowChart={setShowChart}
        setFilteredData={setFilteredData}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </SplitPane>
  )
}

export default EndpointUsagePage
