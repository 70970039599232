import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import IncidentsGrid from './IncidentsGrid'
import IncidentHistoryChart from './IncidentFrequencyChart'

const IncidentDashboardPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const incidentData = useRef([])
  const [filteredIncidentData, setFilteredIncidentData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getIncidents = useAxiosGzip({
    url: '/drillStringIncidents/getIncidents',
  })

  const fetchIncidents = async () => {
    if (isLoading) return
    setIsLoading(true)

    const response = await getIncidents()
    if (_isMounted.current) {
      incidentData.current = Array.isArray(response.data) ? response.data : []
      setIsLoading(false)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    fetchIncidents()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActivePage(PAGE_KEYS.incidentDashboardPageKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SplitPane
      split='horizontal'
      defaultSize={'50%'}
      size={'50%'}
      style={{
        marginTop: '64px',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        minHeight: 'calc(100vh - 64px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}
      allowResize={true}>
      <IncidentHistoryChart filteredIncidents={filteredIncidentData} />
      <IncidentsGrid
        incidentData={incidentData.current}
        setFilteredIncidentData={setFilteredIncidentData}
        isLoading={isLoading}
      />
    </SplitPane>
  )
}

export default IncidentDashboardPage
