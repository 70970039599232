import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getComponentColor } from 'components/common/drillStringFunctions'
import { cloneDeep } from 'lodash'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ShippingInventoryGrid = ({
  inventoryList,
  updateTicketTools = null,
  setStatus,
  selectedTicket = null,
  setSelectedTicket,
  isSelectedToolsGrid,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getUnitsText } = useUnits()
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  let columnDefs = useMemo(
    () => [
      {
        field: 'uid',
        colId: 'uid',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 30,
        pinned: 'left',
        lockPosition: 'left',
        dndSource: true,
        valueFormatter: (params) => '',
      },
      {
        colId: 'serialNum',
        field: 'serialNum',
        headerName: 'SN',
        valueGetter: (params) => unescapeHtml(params.data?.serialNum),
      },
      {
        headerName: 'Description',
        colId: 'description',
        field: 'description',
        cellStyle: { alignItem: 'end' },
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        headerName: 'Tool Type',
        colId: 'toolType',
        field: 'toolType',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getComponentColor(params.value),
          }
        },
        valueGetter: (params) => unescapeHtml(params.data?.toolType),
      },
      {
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'od',
        field: 'od',
        valueGetter: (params) => unescapeHtml(params.data?.od),
      },
      {
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'id',
        field: 'id',
        valueGetter: (params) => unescapeHtml(params.data?.id),
      },
      {
        headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'length',
        field: 'length',
        valueFormatter: (params) => {
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.length, 2)}`
        },
      },
      {
        headerName: 'Cnx Top',
        colId: 'connectionTop',
        field: 'connectionTop',
        valueGetter: (params) => unescapeHtml(params.data?.connectionTop),
      },
      {
        headerName: 'Cnx Bottom',
        colId: 'connectionBottom',
        field: 'connectionBottom',
        valueGetter: (params) => unescapeHtml(params.data?.connectionBottom),
      },
      {
        headerName: 'Basket',
        colId: 'basket',
        field: 'basket',
        valueGetter: (params) => unescapeHtml(params.data?.basket),
      },
      {
        headerName: 'Location',
        colId: 'location',
        field: 'location',
        valueGetter: (params) => unescapeHtml(params.data?.location),
      },
      {
        headerName: `Weight (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'})`,
        colId: 'weight',
        field: 'weight',
        valueGetter: (params) => unescapeHtml(params.data?.weight),
      },
      {
        headerName: 'Date on Location',
        colId: 'dateOn',
        field: 'dateOn',
        sortable: false,
        valueGetter: (params) => unescapeHtml(params.data?.dateOn),
      },
      {
        headerName: 'Date off Location',
        colId: 'dateOff',
        field: 'dateOff',
        sortable: false,
        valueGetter: (params) => unescapeHtml(params.data?.dateOff),
      },
      {
        headerName: 'On Location',
        colId: 'onLocation',
        field: 'onLocation',
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Failed',
        colId: 'failed',
        field: 'failed',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Ready for Backload',
        colId: 'readyForBackload',
        field: 'readyForBackload',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'In Hole',
        colId: 'inHole',
        field: 'inHole',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Comments',
        colId: 'comments',
        field: 'comments',
        cellStyle: { alignItems: 'start' },
        editable: false,
        valueFormatter: (params) => unescapeHtml(params.data?.comments),
        valueGetter: (params) => unescapeHtml(params.data?.comments),
      },
      {
        headerName: 'Make',
        colId: 'make',
        field: 'make',
        editable: false,
        valueGetter: (params) => unescapeHtml(params.data?.make),
      },
      {
        headerName: 'Model',
        colId: 'model',
        field: 'model',
        editable: false,
        valueGetter: (params) => unescapeHtml(params.data?.model),
      },
      {
        headerName: 'Dirty',
        colId: 'dirty',
        field: 'dirty',
        valueGetter: (params) => params.data?.totalToolHours > 0,
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        headerName: 'Prev D&C Hrs',
        colId: 'prevToolHours',
        field: 'prevToolHours',
        editable: false,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.prevToolHours, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        headerName: 'Total D&C Hrs',
        colId: 'totalToolHours',
        field: 'totalToolHours',
        editable: false,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.totalToolHours, 2)}`
        },
      },
    ],
    [getUnitsText],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
      cellStyle: centerAlignCell,
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const gridDragOver = (event) => {
    const dragSupported = event.dataTransfer.types.length

    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  const gridDrop = async (event) => {
    event.preventDefault()
    if (!gridApi.current) return

    const jsonData = event.dataTransfer.getData('application/json')
    const data = JSON.parse(jsonData)

    if (!data) {
      return
    }

    if (!gridApi.current) return

    let compExists = false
    let toolData = []
    gridApi.current.forEachNode((rowNode) => {
      if (rowNode.data) {
        if (rowNode.data.uid === data.uid) compExists = true
        toolData.push(cloneDeep(rowNode.data))
      }
    })

    if (compExists) return

    let ticketData = cloneDeep(selectedTicket)

    if (isSelectedToolsGrid) {
      toolData.push(data)
      ticketData.tools = cloneDeep(toolData)
    }

    if (!isSelectedToolsGrid) {
      if (Array.isArray(ticketData.tools)) {
        let index = ticketData.tools.findIndex((tool) => tool.uid === data.uid)
        if (index >= 0) ticketData.tools.splice(index, 1)
      }
    }

    let res = await updateTicketTools(ticketData)
    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.message}`,
      })

      return
    }

    setSelectedTicket(ticketData)

    gridApi.current.applyTransaction({
      add: [data],
    })
  }

  return (
    <div
      className={getAgGridTheme()}
      style={{ width: '100%', height: '100%' }}
      onDragOver={gridDragOver}
      onDrop={(e) => gridDrop(e)}>
      <AgGridReact
        rowData={inventoryList}
        columnDefs={columnDefs}
        defaultColDefs={defaultColDef}
        getRowId={getRowId}
        gridOptions={gridOptions}
        rowSelection={'single'}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        rowDragManaged={true}
      />
    </div>
  )
}

export default ShippingInventoryGrid
