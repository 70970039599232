import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import {
  sortColDefs,
  htmlSymbolHandling,
  relativeTime,
  isValidDate,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { throttle } from 'lodash'
import { styled } from '@mui/styles'
import ConfirmDialog from 'components/common/ConfirmDialog'
import cloneDeep from 'lodash/cloneDeep'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { appColors } from 'utils'
import { checkPermission } from 'components/userPermissions'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import WellSelectorModal from 'components/Bids/WellSelectorModal'
import * as XLSX from '@sheet/core'
import { removeSymbolsFromName } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const BidsListGrid = ({
  bids,
  isLoading,
  isVisible,
  showHidePane,
  refreshData,
  addNewBid,
  updateBid,
  deleteBid,
  copyBid,
  setSelectedBid,
  handlePrintBid,
  getOperators,
  transferBidToWell,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const selectedBid = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const { user } = useInnovaAuth()
  const [showWellSelectorModal, setShowWellSelectorModal] = useState(false)
  const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const throttledSetQuickFilter = throttle((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    throttledSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  function onSelectionChanged() {
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows)) setSelectedBid(null)
    if (selectedRows.length === 0) setSelectedBid(null)
    setSelectedBid(selectedRows[0])
  }

  function isPinnedRowDataCompleted() {
    if (!inputRow.current?.hasOwnProperty('operator')) return false
    if (!inputRow.current?.hasOwnProperty('description')) return false
    return true
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddBid(event.data)
      }

      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate(event.data)
      }
    },
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onSelectionChanged: onSelectionChanged,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()

    let firstBid = null
    params.api.forEachNode((node) => {
      if (!firstBid && node.data) firstBid = cloneDeep(node.data)
    })

    setSelectedBid(firstBid)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('bidListGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('bidListGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>', //ask dan
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Bids.xlsx',
            sheetName: 'Bids',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Refresh data',
        disabled: false,
        action: () => {
          refreshData()
        },
        icon: '<span class="iconify" data-icon="material-symbols:refresh" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const onClickDelete = useCallback((data) => {
    selectedBid.current = cloneDeep(data)
    setConfirm({
      show: true,
      title: 'Delete Bid',
      text: `Are you sure you want to delete bid ${data.description}?`,
    })
  }, [])

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'operator' && colDef.field !== 'description') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const handleAddBid = async (data) => {
    if (!isPinnedRowDataCompleted()) return

    let res = await addNewBid(data)
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        add: [res.data],
        addIndex: 0,
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleTransferBidToWell = useCallback(async (data) => {
    selectedBid.current = cloneDeep(data)
    setShowWellSelectorModal(true)
  }, [])

  const handleCopyBid = useCallback(
    async (data) => {
      let res = await copyBid(data)
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          add: [res.data],
          addIndex: 0,
        })
      }
    },
    [copyBid, gridApi],
  )

  const transferBid = useCallback(
    async (bid, wellName) => {
      let res = await transferBidToWell(bid, wellName)

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      setStatus({ show: true, severity: 'success', message: res.data })
      selectedBid.current = null
    },
    [transferBidToWell],
  )

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      let res = await updateBid(htmlSymbolHandling(data))
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          update: [res.data],
        })
      }
    },

    [updateBid, gridApi],
  )

  const handleDelete = async () => {
    if (!selectedBid.current) return
    let res = await deleteBid(selectedBid.current)
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [res.data],
      })
    }

    selectedBid.current = null
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    value += 'T00:00:01Z'
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const onClickPrintBid = useCallback(async () => {
    await handlePrintBid()
  }, [handlePrintBid])

  const onXlsxExport = useCallback((data) => {
    let bidItems = []
    if (Array.isArray(data.bidDetails)) {
      for (let i = 0; i < data.bidDetails.length; i++) {
        bidItems.push([
          data.bidDetails[i].description,
          data.bidDetails[i].costCode,
          data.bidDetails[i].cost,
          data.bidDetails[i].costSchedule,
        ])

        if (Array.isArray(data.bidDetails[i].details)) {
          for (let j = 0; j < data.bidDetails[i].details.length; j++) {
            let subItem = data.bidDetails[i].details[j]
            bidItems.push([subItem.description, subItem.costCode, subItem.cost, subItem.costSchedule])
          }
        }
      }
    }

    let ws = XLSX.utils.aoa_to_sheet(
      [
        ['Operator', data.operator],
        ['Description', data.description],
        ['Date', data.dateCreated],
        ['Approval Date', data.dateApproved],
        ['Expiry Date', data.dateExpires],
        [''],
        ['Description', 'Cost Code', 'Rate', 'Schedule'],
        ...bidItems,
      ],
      {
        origin: 'A1',
      },
    )

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Bid')
    let fileName = removeSymbolsFromName(data.operator + '.xlsx')
    XLSX.writeFile(wb, fileName)
  }, [])

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'copy') {
        handleCopyBid(data)
      }

      if (action === 'print') {
        onClickPrintBid()
      }

      if (action === 'transfer') {
        handleTransferBidToWell(data)
      }

      if (action === 'excel') {
        onXlsxExport(data)
      }

      if (action === 'approve') {
        data.approvedBy = user?.name
        data.dateApproved = new Date(Date.now()).toISOString().substring(0, 10)
        handleUpdate(data)
      }
    },
    [onXlsxExport, onClickDelete, onClickPrintBid, handleUpdate, handleCopyBid, handleTransferBidToWell, user],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          let menuItems = [
            {
              label: 'Delete Bid',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledPopupMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Print Bid',
              action: 'print',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledPopupMenuIcon icon='material-symbols:print' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Excel export',
              action: 'excel',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledPopupMenuIcon icon='vscode-icons:file-type-excel' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Copy Bid',
              action: 'copy',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledPopupMenuIcon icon='gg:copy' />
                </StyledIconContainer>
              ),
            },
          ]

          if (params.data?.approvedBy !== '') {
            menuItems.push({
              label: 'Transfer to well',
              action: 'transfer',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledPopupMenuIcon icon='mingcute:transfer-4-fill' />
                </StyledIconContainer>
              ),
            })
          }

          if (
            checkPermission('canApprove', userRole.roleAttributes?.permissions) &&
            (params.data?.approvedBy === '' || !params.data?.approvedBy)
          ) {
            menuItems.push({
              label: 'Approve',
              action: 'approve',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledPopupMenuIcon icon='material-symbols:order-approve' color={'green'} />
                </StyledIconContainer>
              ),
            })
          }

          return {
            menuItems: menuItems,
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'operator',
        colId: 'operator',
        headerName: 'Customer',
        pinned: 'left',
        lockPosition: 'left',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          let opList = getOperators()
          return {
            values: Array.isArray(opList) ? opList : [],
          }
        },
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        pinned: 'left',
        lockPosition: 'left',
        editable: true,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return 'Description...'
          return unescapeHtml(params.data?.description)
        },
      },
      {
        field: 'dateCreated',
        colId: 'dateCreated',
        headerName: 'Date',
        valueFormatter: (params) => {
          if (!isValidDate(params?.data?.dateCreated)) return ''
          if (isDateLessThan(params.data?.dateCreated, '1990-01-01')) return ''
          return `${dateTimeFormatter(params?.data?.dateCreated)} [${relativeTimeFormatter(params.data?.dateCreated)}]`
        },
      },
      {
        field: 'approvedBy',
        colId: 'approvedBy',
        headerName: 'Approved By',
        editable: false,
        valueFormatter: (params) => getUserNameFromEmail(params.data?.approvedBy),
      },
      {
        field: 'dateApproved',
        colId: 'dateApproved',
        headerName: 'Approved Date',
        editable: false,
        valueFormatter: (params) => {
          if (!isValidDate(params?.data?.dateApproved) || params.data?.approvedBy === '') return ''
          if (isDateLessThan(params.data?.dateApproved, '1990-01-01')) return ''
          return `${dateTimeFormatter(params?.data?.dateApproved)} [${relativeTimeFormatter(
            params.data?.dateApproved,
          )}]`
        },
      },
      {
        field: 'dateExpires',
        colId: 'dateExpires',
        headerName: 'Expires',
        editable: (params) => {
          if (params.data?.approvedBy === '' || !params.data?.approvedBy) return true
          return false
        },
        cellStyle: (params) => {
          let color = isDateLessThan(params.data?.dateExpires, new Date(Date.now()).toISOString().substring(0, 10))
            ? 'red'
            : '#59FF00'
          return { ...centerAlignCell, color: color }
        },
        valueFormatter: (params) => {
          if (!isValidDate(params?.data?.dateExpires)) return ''
          if (isDateLessThan(params.data?.dateExpires, '1990-01-01')) return ''
          return `${dateTimeFormatter(params?.data?.dateExpires)} [${relativeTimeFormatter(params.data?.dateExpires)}]`
        },
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
      },
      {
        field: 'archived',
        colId: 'archived',
        headerName: 'Archived',
        editable: true,
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      },
    ],
    [centerAlignCell, dateTimeFormatter, getOperators, handleMenuClick, relativeTimeFormatter, userRole],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showWellSelectorModal ? (
        <WellSelectorModal
          operatorName={selectedBid.current?.operator}
          onClose={(data) => {
            setShowWellSelectorModal(false)
            if (!data) return
            transferBid(cloneDeep(selectedBid.current), data)
          }}
        />
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          display: isVisible ? 'flex' : 'none',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box
        sx={{
          display: isVisible ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 65px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={cloneDeep(bids)}
            columnDefs={sortColDefs(columnDefs, 'bidListGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            rowSelection={'single'}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </Box>
      {!isVisible ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Bids List
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default BidsListGrid
