import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { actionBarWidthAtom } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import SplitPane from 'components/common/SplitPane'
import { Box } from '@mui/material'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import WellListGrid from './WellListGrid'
import { currentPageAtom, refreshWellList, userUserRoleAtom } from 'atoms'
import AddIcon from '@mui/icons-material/Add'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { checkFeature } from 'components/userPermissions'
import EdmImportModal from 'components/WellList/EdmImportModal'
import AddInstantWellModal from './AddInstantWellModal'
import Carousel from 'react-material-ui-carousel'
import WellsMapPage from './WellsMapPage'
import FootageDashboardPage from './FootageDashboard/FootageDashboardPage'
import WellTimelineGanttChart from './WellTimelineGanttChart'

const WellListPage = () => {
  const _isMounted = useRef(false)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [showChart, setShowChart] = useState(true)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const refreshWells = useSetRecoilState(refreshWellList)
  const [showEdmImport, setShowEdmImport] = useState(false)
  const [showAddInstantWell, setShowAddInstantWell] = useState(false)
  const [filteredWells, setFilteredWells] = useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wellListKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleEdmImport = () => {
    setShowEdmImport(true)
  }

  const handleNewInstantWell = () => {
    setShowAddInstantWell(true)
  }

  const handleCloseEdmImport = async (refresh) => {
    setShowEdmImport(false)
    if (refresh) {
      refreshWells(Date.now().toString())
    }
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          refreshWells(Date.now().toString())
        },
      },
    ]

    if (checkFeature(26, userRole?.roleAttributes?.featureId)) {
      actions.push(
        {
          icon: (
            <Iconify icon='akar-icons:cloud-upload' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />
          ),
          name: 'Compass EDM Import',
          onClick: handleEdmImport,
        },
        {
          icon: <AddIcon />,
          name: 'Add well',
          onClick: handleNewInstantWell,
        },
      )
    }

    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Charts',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Charts',
        onClick: () => handleShowChart(),
      })
    }
    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const handleCloseAddInstantWell = async () => {
    setShowAddInstantWell(false)
  }

  const WellsGrid = useMemo(() => <WellListGrid setFilteredData={setFilteredWells} />, [setFilteredWells])

  return (
    <React.Fragment>
      {showEdmImport && <EdmImportModal open={showEdmImport} onClose={handleCloseEdmImport} refresh={refreshWells} />}
      {showAddInstantWell ? (
        <AddInstantWellModal open={showAddInstantWell} onClose={handleCloseAddInstantWell} refresh={refreshWells} />
      ) : null}
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={showChart ? '50%' : 0}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <Carousel autoPlay={false} height={'100%'} sx={{ width: '100%' }} swipe={false} fullHeightHover={false}>
          <WellsMapPage filteredWells={filteredWells} />
          <FootageDashboardPage />
          <WellTimelineGanttChart wellData={filteredWells} title={'Well Timeline'} />
        </Carousel>
        {WellsGrid}
      </SplitPane>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
    </React.Fragment>
  )
}

export default WellListPage
