import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const isValidDate = (value) => {
  return value instanceof Date || !isNaN(Date.parse(value))
}

const RigStateDiagDetailsGrid = ({ data, masterGridApi, uuid }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      if (masterGridApi && masterGridApi?.gridOptions?.api) {
        masterGridApi.removeDetailGridInfo(uuid)
      }
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: true,
    wrapText: false,
    editable: false,
    enableCellChangeFlash: true,
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: true,
        pinned: 'left',
        lockPosition: 'left',
        valueFormatter: (params) => {
          if (!params.value) return ''
          if (typeof params.value !== 'string') return ''
          if (!params.value.trim()) return ''

          //Converts camel case variable name to readable string
          let regex = /([a-z])([0-9])(?=[A-Z])/g
          let result = params.value.replace(regex, '$1 $2 ')
          result = result.replace(/([a-z])([A-Z])/g, '$1 $2')
          result = result.charAt(0).toUpperCase() + result.slice(1)

          return result
        },
      },
      {
        field: 'value',
        colId: 'value',
        headerName: 'Value',
        valueFormatter: (params) => {
          if (params.value === null || params.value === undefined) return ''
          if (typeof params.value === 'object') return JSON.stringify(params.value)
          if (typeof params.value === 'boolean') return params.value ? 'True' : 'False'
          if (typeof params.value === 'string' && isValidDate(params.value)) return dateTimeFormatter(params.value)
          if (typeof params.value === 'number') return params.value.toFixed(2)
          return params.value
        },
      },
    ],
    [dateTimeFormatter],
  )

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (masterGridApi) {
      masterGridApi.addDetailGridInfo(uuid, {
        id: uuid,
        api: params.api,
      })
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={data}
        columnDefs={sortColDefs(columnDefs, 'rigStateDiagDetailsGrid')}
        defaultColDef={defaultColDef}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        getRowId={getRowId}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default RigStateDiagDetailsGrid
