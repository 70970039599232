import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import { numberToString } from 'utils/numberFunctions'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import {
  numberWithCommasDecimals,
  removeSpecialSymbols,
  removeSymbolsFromName,
} from 'utils/stringFunctions'
import useOrgIcons from './useOrgIcons'
import { getDeltaTime } from 'utils'
import * as XLSX from '@sheet/image'

function useSlideSheets() {
  const _isMounted = useRef(true)
  const slideSheetData = useRef([])
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const { getUnitsText } = useUnits()
  const { getCurrentOrgIcon } = useOrgIcons()
  const [surveyData, setSurveyData] = useState([])
  const userRole = useRecoilValue(userUserRoleAtom)

  const getSlideSheets = useInnovaAxios({
    url: '/well/slideSheets/getAllSlideSheets',
  })

  const getSlideSheetReportHeader = useInnovaAxios({
    url: '/well/slideSheets/getSlideSheetReportHeader',
  })

  const getSurvey = useInnovaAxios({
    url: '/well/getActualWellbore',
  })

  const getSlideSheet = useInnovaAxios({
    url: '/well/slideSheets/getSlideSheet',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchSurvey()
    fetchSlideSheets()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSurvey = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    const response = await getSurvey({ wellName: currentWellRef.current })
    if (_isMounted.current) {
      if (response?.data) {
        setSurveyData(response.data)
      }
    }
  }

  const fetchSlideSheets = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getSlideSheets({ wellName: currentWellRef.current })

    if (_isMounted.current) {
      setLoading(false)
      slideSheetData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const fetchSlideSheetData = () => {
    if (!Array.isArray(slideSheetData.current)) return []
    if (slideSheetData.current?.length <= 0) return []

    let sortedData = slideSheetData.current.reverse()
    return sortedData
  }

  const fetchSlideSheet = async (bhaNum) => {
    if (typeof bhaNum !== 'number') return null
    if (typeof currentWellRef.current !== 'string') return null
    if (currentWellRef.current === '') return null
    if (isLoading.current) return null
    isLoading.current = true
    const response = await getSlideSheet({
      wellName: currentWellRef.current,
      bhaNum: bhaNum,
    })
    isLoading.current = false
    if (response.error) return null
    if (!response.data) return null
    if (!Array.isArray(response.data)) return null
    if (response.data.length === 0) return null
    return response.data
  }

  const getSlideSheetPdfData = async (reportIds) => {
    if (!Array.isArray(reportIds)) return null
    await fetchSlideSheets()

    if (!slideSheetData.current || !Array.isArray(slideSheetData.current)) return null

    let pdfData = []
    for (let i = 0; i < reportIds.length; i++) {
      pdfData.push(await generatePdfDocument(reportIds[i], slideSheetData.current))
    }

    return {
      fileName: `Slide Sheets - ${removeSpecialSymbols(currentWell)}`,
      data: <PdfDocument data={pdfData} multiDocument={true} pageOrientation={'landscape'} reportSettings={userRole?.userPrefs?.reportSettings} />,
    }
  }

  const parseDate = (date) => {
    if (!date) return ''

    let parsedDate = date.split(/[-T]+/)
    return `${parsedDate[1]}/${parsedDate[2]}/${parsedDate[0]}`
  }

  const getSlideSheetRows = (slideSheet, isPdf) => {
    if (!slideSheet) return []
    if (!slideSheet.hasOwnProperty('slideRecords')) return []
    if (!Array.isArray(slideSheet.slideRecords)) return []

    let allData = []

    if (isPdf) {
      allData.push(
        [
          { text: 'Drilling Data', isHeader: true, fontSize: 7, columnSpan: 11 },
          { text: 'Survey Data', isHeader: true, fontSize: 7, columnSpan: 4 },
          { text: 'Parameters', isHeader: true, fontSize: 7, columnSpan: 9 },
          { text: 'Weights', isHeader: true, fontSize: 7, columnSpan: 3 },
          { text: 'Comments', isHeader: true, fontSize: 7 },
        ],
        [
          { text: 'Date', isHeader: true, fontSize: 7 },
          { text: 'Start Time', isHeader: true, fontSize: 7 },
          { text: 'End Time', isHeader: true, fontSize: 7 },
          { text: 'Hrs', isHeader: true, fontSize: 7 },
          { text: 'Start Depth', isHeader: true, fontSize: 7 },
          { text: 'End Depth', isHeader: true, fontSize: 7 },
          { text: 'Course Len.', isHeader: true, fontSize: 7 },
          { text: 'Operation', isHeader: true, fontSize: 7 },
          { text: 'Tgt TF', isHeader: true, fontSize: 7 },
          { text: 'TF', isHeader: true, fontSize: 7 },
          { text: 'MY', isHeader: true, fontSize: 7 },
          { text: 'MD', isHeader: true, fontSize: 7 },
          { text: 'INC', isHeader: true, fontSize: 7 },
          { text: 'AZI', isHeader: true, fontSize: 7 },
          { text: 'DLS', isHeader: true, fontSize: 7 },
          { text: 'WOB', isHeader: true, fontSize: 7 },
          { text: 'RPM', isHeader: true, fontSize: 7 },
          { text: 'FLOW', isHeader: true, fontSize: 7 },
          { text: 'On/Off Btm Tq', isHeader: true, fontSize: 7 },
          { text: 'ROP', isHeader: true, fontSize: 7 },
          { text: 'SPP On Btm', isHeader: true, fontSize: 7 },
          { text: 'SPP Off Btm', isHeader: true, fontSize: 7 },
          { text: 'Diff', isHeader: true, fontSize: 7 },
          { text: 'Temp', isHeader: true, fontSize: 7 },
          { text: 'PU', isHeader: true, fontSize: 7 },
          { text: 'ROT', isHeader: true, fontSize: 7 },
          { text: 'SO', isHeader: true, fontSize: 7 },
          { text: 'Comments', isHeader: true, fontSize: 7 },
        ],
      )
    }

    let slideRecords = slideSheet.slideRecords
    let surveyIndex = 0
    let svyFound = false

    for (let i = 0; i < slideRecords.length; i++) {
      let slideRecord = slideRecords[i]

      let tempSurveyIndex = surveyIndex
      while (
        surveyIndex < surveyData.length &&
        (surveyData[surveyIndex].md < slideRecord.startDepth || surveyData[surveyIndex].md >= slideRecord.endDepth)
      ) {
        surveyIndex++
      }

      if (surveyIndex === surveyData.length) {
        surveyIndex = tempSurveyIndex
        svyFound = true
      }

      let hrs = getDeltaTime(slideRecord.startTime, slideRecord.endTime, true)
      let rop = hrs > 0 ? slideRecord.cl / hrs : 0

      let comments = slideRecord.comments
      if (slideRecord.formation !== '') {
        if (comments !== '') comments += ','
        comments += slideRecord.formation
      }

      allData.push([
        { text: parseDate(slideRecord.date) },
        { text: slideRecord.startTime },
        { text: slideRecord.endTime },
        { text: numberToString(hrs, 2) },
        { text: numberToString(slideRecord.startDepth, 2) },
        { text: numberToString(slideRecord.endDepth, 2) },
        { text: numberToString(slideRecord.cl, 2) },
        { text: slideRecord.state },
        { text: slideRecord.state === 'Slide' ? numberToString(slideRecord?.ttf, 2) : '' },
        { text: slideRecord.state === 'Slide' ? numberToString(slideRecord?.etf, 2) : '' },
        { text: slideRecord.state === 'Slide' ? numberToString(slideRecord?.motorYield, 2) : '' },
        { text: svyFound ? '' : numberToString(surveyData[surveyIndex].md, 2) },
        { text: svyFound ? '' : numberToString(surveyData[surveyIndex].inc, 2) },
        { text: svyFound ? '' : numberToString(surveyData[surveyIndex].azi, 2) },
        { text: svyFound ? '' : numberToString(surveyData[surveyIndex].dls, 2) },
        { text: numberToString(slideRecord.wob, 2) }, // WOB
        { text: numberToString(slideRecord.rpm, 2) },
        { text: numberToString(slideRecord.flow, 2) },
        { text: numberToString(slideRecord.torque, 2) + '/' + numberToString(slideRecord.offBtmTq, 2) },
        { text: numberToString(rop, 2) }, // ROP
        { text: numberToString(slideRecord.onBtmPress, 2) },
        { text: numberToString(slideRecord.offBtmPress, 2) },
        {
          text: numberToString(
            slideRecord.onBtmPress - slideRecord.offBtmPress > 0 ? slideRecord.onBtmPress - slideRecord.offBtmPress : 0,
            2,
          ),
        },
        { text: numberToString(slideRecord.temperature, 2) },
        { text: slideRecord.puWeight ? numberToString(slideRecord.puWeight, 2) : '' },
        { text: slideRecord.rotWeight ? numberToString(slideRecord.rotWeight, 2) : '' },
        { text: slideRecord.soWeight ? numberToString(slideRecord.soWeight, 2) : '' },
        { text: comments },
      ])

      svyFound = false
    }

    return allData
  }

  const generatePdfDocument = async (id, pdfReportData) => {
    if (id === null || id === undefined) return null
    let reportIndex = pdfReportData.findIndex((slideSheet) => slideSheet.bhaNum === id)
    if (reportIndex < 0) return null
    if (!currentWellRef.current || currentWellRef.current?.length < 1) return null

    const slideSheet = pdfReportData[reportIndex]
    const payload = {
      wellName: currentWellRef.current,
      bhaNum: id,
    }

    const res = await getSlideSheetReportHeader(payload)
    if (res.error) return null
    if (!res?.data) return null

    let slideSheetReportHeader = res.data
    let slidePerc = 0
    let rotPerc = 0

    if (slideSheet.totalDrilled > 0) {
      rotPerc = (slideSheet.rotateDrilled / slideSheet.totalDrilled) * 100.0
      slidePerc = (slideSheet.slideDrilled / slideSheet.totalDrilled) * 100.0
    }

    let docData = [
      {
        showTitle: true,
        title: slideSheet.bhaDescription + ' - Slide Sheet',
        fontSize: 7,
        sectionAfter: 5,
        showLogo: true,
        logoWidth: '25%',
        logo: getCurrentOrgIcon(),
        logoHeight: 30,
        columnWidths: ['6%', '26%', '9%', '5%', '7%', '4%', '7%', '5%', '10%', '4%', '7%', '10%'],
        data: [
          [
            { text: 'Operator:' },
            { text: slideSheetReportHeader?.operator, textAlign: 'left' },
            { text: 'BHA:' },
            { text: slideSheet.bhaDescription, textAlign: 'left' },
            { text: 'BHA #:' },
            { text: slideSheet.bhaNum, textAlign: 'left' },
            { text: 'Datum Elev:' },
            { text: numberToString(slideSheetReportHeader?.elevation, 2), textAlign: 'left' },
            { text: 'North Reference:' },
            { text: slideSheetReportHeader?.northRef, textAlign: 'left' },
            { text: 'Total Drilled:' },
            { text: numberToString(slideSheet.totalDrilled, 2), textAlign: 'left' },
          ],
          [
            { text: 'Facility:' },
            { text: slideSheetReportHeader?.facility, textAlign: 'left' },
            { text: 'Survey Offset:' },
            { text: numberToString(slideSheetReportHeader?.surveyOffset, 2), textAlign: 'left' },
            { text: 'BHA:' },
            { text: slideSheetReportHeader?.bhaDesc, textAlign: 'left' },
            { text: 'Units:' },
            { text: getUnitsText(UNITS_FOR.Depth), textAlign: 'left' },
            { text: 'Declination:' },
            { text: slideSheetReportHeader?.dec, textAlign: 'left' },
            { text: 'Rotate:' },
            { text: `${numberToString(slideSheet.rotateDrilled, 2)} (${numberToString(rotPerc, 2)}%)`, textAlign: 'left' },
          ],
          [
            { text: 'Well Name:' },
            { text: slideSheetReportHeader?.wellName, textAlign: 'left' },
            { text: 'Gamma Offset:' },
            { text: numberToString(slideSheetReportHeader?.gammaOffset, 2), textAlign: 'left' },
            { text: 'Bit:' },
            { text: slideSheetReportHeader?.bitType, textAlign: 'left' },
            { text: 'Grid Conv:' },
            { text: slideSheetReportHeader?.gridConv, textAlign: 'left' },
            { text: 'VS Azimuth:' },
            { text: slideSheetReportHeader?.vsAzi, textAlign: 'left' },
            { text: 'Slide:' },
            { text: `${numberToString(slideSheet.slideDrilled, 2)} (${numberToString(slidePerc, 2)}%)`, textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 5,
        sectionAfter: 5,
        manualWidth: true,
        fixedHeaders: 2,
        columnWidths: [
          '4%',
          '4%',
          '4%',
          '3%',
          '4%',
          '4%',
          '7%',
          '7%',
          '3%',
          '3%',
          '2%', // Drilling Data
          '3%',
          '3%',
          '3%',
          '3%', // Survey Data
          '3%',
          '3%',
          '3%',
          '3%',
          '3%',
          '5%',
          '5%',
          '3%',
          '3%', // Parameters
          '2.3%',
          '2.4%',
          '2.3%', // Weights
          '5%',// Comments
        ],
        data: getSlideSheetRows(slideSheet, true),
      },
    ]

    return docData
  }

  const makeEmptyArray = (size) => {
    if (typeof size !== 'number') return
    return Array(size).fill(undefined)
  }

  const getCellStyle = (
    fgColor = '#FFFFFF',
    horizontalAlign = 'center',
    verticalAlign = 'center',
    bold = false,
    top = '',
    right = '',
    bottom = '',
    left = '',
    fontSize = 12,
  ) => {
    return {
      fgColor: { rgb: fgColor },
      alignment: {
        horizontal: horizontalAlign,
        vertical: verticalAlign,
      },
      bold: bold,
      top: { style: top },
      right: { style: right },
      bottom: { style: bottom },
      left: { style: left },
      sz: fontSize,
    }
  }

  const handleXlsExport = async (bhaNum, slideData) => {
    if (!bhaNum || typeof bhaNum !== 'number') return null
    if (!slideData) return null
    if (!slideData.hasOwnProperty('slideRecords')) return null

    const payload = {
      wellName: currentWellRef.current,
      bhaNum: bhaNum,
    }

    const res = await getSlideSheetReportHeader(payload)
    if (res.error) return null
    if (!res?.data) return null

    let slideSheetHeader = res.data

    let slidePerc = 0
    let rotPerc = 0

    if (slideData?.totalDrilled > 0) {
      rotPerc = (slideData?.rotateDrilled / slideData?.totalDrilled) * 100.0
      slidePerc = (slideData?.slideDrilled / slideData?.totalDrilled) * 100.0
    }

    let ws = XLSX.utils.aoa_to_sheet(
      [
        [...makeEmptyArray(3), 'Slide Report'],
        [],
        [
          'Operator:',
          ...makeEmptyArray(4),
          slideSheetHeader?.operator,
          ...makeEmptyArray(2),
          'BHA Description:',
          ...makeEmptyArray(4),
          slideSheetHeader?.bhaDesc,
          ...makeEmptyArray(4),
          'RKB:',
          ...makeEmptyArray(3),
          slideSheetHeader?.elevation,
          ...makeEmptyArray(1),
          'North Reference:',
          ...makeEmptyArray(1),
          slideSheetHeader?.northRef,
          ...makeEmptyArray(1),
          'Total Drilled:',
          ...makeEmptyArray(1),
          numberWithCommasDecimals(slideData?.totalDrilled, 2),
        ],
        [
          'Site:',
          ...makeEmptyArray(4),
          slideSheetHeader?.facility,
          ...makeEmptyArray(2),
          'BHA / Bit Type:',
          ...makeEmptyArray(4),
          `${slideSheetHeader?.bhaType} / ${slideSheetHeader?.bitType}`,
          ...makeEmptyArray(4),
          'Units:',
          ...makeEmptyArray(3),
          getUnitsText(UNITS_FOR.Depth),
          ...makeEmptyArray(1),
          'Declination:',
          ...makeEmptyArray(1),
          slideSheetHeader?.dec,
          ...makeEmptyArray(1),
          'Slide:',
          ...makeEmptyArray(1),
          `${numberToString(slideData?.slideDrilled, 2)} (${numberToString(slidePerc, 2)}%)`,
        ],
        [
          'Well Name',
          ...makeEmptyArray(4),
          slideSheetHeader?.wellName,
          ...makeEmptyArray(2),
          'Survey / Gamma Offset:',
          ...makeEmptyArray(4),
          `${numberWithCommasDecimals(slideSheetHeader?.surveyOffset, 2)} / ${numberWithCommasDecimals(
            slideSheetHeader?.gammaOffset,
            2,
          )}`,
          ...makeEmptyArray(4),
          'Grid Conv:',
          ...makeEmptyArray(3),
          slideSheetHeader?.gridConv,
          ...makeEmptyArray(1),
          'VS Azimuth:',
          ...makeEmptyArray(1),
          slideSheetHeader?.vsAzi,
          ...makeEmptyArray(1),
          'Rotate:',
          ...makeEmptyArray(1),
          `${numberToString(slideData?.rotateDrilled, 2)} (${numberToString(rotPerc, 2)}%)`,
        ],
        [],
        [
          'Summary',
          ...makeEmptyArray(10),
          'Survey Data',
          ...makeEmptyArray(3),
          'Drilling Params',
          ...makeEmptyArray(8),
          'Weights',
          ...makeEmptyArray(2),
          'Comments',
        ],
        [
          'Date',
          'Start Time',
          'End Time',
          'Hrs',
          'Start Depth',
          'End Depth',
          'CL',
          'Operation',
          'Tgt TF',
          'TF',
          'MY',
          'MD',
          'INC',
          'AZI',
          'DLS',
          'WOB',
          'RPM',
          'FLOW',
          'On/Off Btm Tq',
          'ROP',
          'On Btm Press',
          'Off Btm Press',
          'Diff',
          'Temp',
          'PU',
          'ROT',
          'SO',
        ],
      ],
      {
        origin: 'B2',
      },
    )

    //add Title font size
    ws['E2'].s = { sz: 18 }

    ws['!images'] = [
      {
        '!pos': {
          c: 1,
          r: 0,
          x: 1,
          y: 0, // start at the top-left corner of A3
          w: 115,
          h: 115,
        },
        '!datatype': 'base64', // we are passing a Base64 representation
        '!data': getCurrentOrgIcon(), // image data
      },
    ]

    ws['!rows'] = [...makeEmptyArray(1), { hpx: 80 }]

    ws['!merges'] = [
      //Title
      {
        s: { r: 1, c: 4 },
        e: { r: 1, c: 32 },
        style: getCellStyle('#FFFFFF', 'center', 'center', true, 'thin', 'thin', 'thin', 'thin', 22),
      },
      //operator header
      {
        s: { r: 3, c: 1 },
        e: { r: 3, c: 5 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //operator data
      {
        s: { r: 3, c: 6 },
        e: { r: 3, c: 8 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //BHA Desc header
      {
        s: { r: 3, c: 9 },
        e: { r: 3, c: 13 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //BHA Desc data
      {
        s: { r: 3, c: 14 },
        e: { r: 3, c: 18 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //RKB header
      {
        s: { r: 3, c: 19 },
        e: { r: 3, c: 22 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //RKB data
      {
        s: { r: 3, c: 23 },
        e: { r: 3, c: 24 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //North ref header
      {
        s: { r: 3, c: 25 },
        e: { r: 3, c: 26 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //North ref data
      {
        s: { r: 3, c: 27 },
        e: { r: 3, c: 28 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Total Drilled header
      {
        s: { r: 3, c: 29 },
        e: { r: 3, c: 30 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Total Drilled data
      {
        s: { r: 3, c: 31 },
        e: { r: 3, c: 32 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Site header
      {
        s: { r: 4, c: 1 },
        e: { r: 4, c: 5 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Site data
      {
        s: { r: 4, c: 6 },
        e: { r: 4, c: 8 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //BHA / Bit Type header
      {
        s: { r: 4, c: 9 },
        e: { r: 4, c: 13 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //BHA / Bit Type data
      {
        s: { r: 4, c: 14 },
        e: { r: 4, c: 18 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Units header
      {
        s: { r: 4, c: 19 },
        e: { r: 4, c: 22 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Units data
      {
        s: { r: 4, c: 23 },
        e: { r: 4, c: 24 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Declination header
      {
        s: { r: 4, c: 25 },
        e: { r: 4, c: 26 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Declination data
      {
        s: { r: 4, c: 27 },
        e: { r: 4, c: 28 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Slide header
      {
        s: { r: 4, c: 29 },
        e: { r: 4, c: 30 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Slide data
      {
        s: { r: 4, c: 31 },
        e: { r: 4, c: 32 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Well name header
      {
        s: { r: 5, c: 1 },
        e: { r: 5, c: 5 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Well name data
      {
        s: { r: 5, c: 6 },
        e: { r: 5, c: 8 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Survey Gamma Offset header
      {
        s: { r: 5, c: 9 },
        e: { r: 5, c: 13 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Survey Gamma Offset data
      {
        s: { r: 5, c: 14 },
        e: { r: 5, c: 18 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Grid conv header
      {
        s: { r: 5, c: 19 },
        e: { r: 5, c: 22 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Grid conv data
      {
        s: { r: 5, c: 23 },
        e: { r: 5, c: 24 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //VS Azi header
      {
        s: { r: 5, c: 25 },
        e: { r: 5, c: 26 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //VS Azi data
      {
        s: { r: 5, c: 27 },
        e: { r: 5, c: 28 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      //Rotate header
      {
        s: { r: 5, c: 29 },
        e: { r: 5, c: 30 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      //Rotate data
      {
        s: { r: 5, c: 31 },
        e: { r: 5, c: 32 },
        style: getCellStyle('#FFFFFF', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      },
      // Drilling Data
      {
        s: { r: 7, c: 1 },
        e: { r: 7, c: 11 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      // Survey Data
      {
        s: { r: 7, c: 12 },
        e: { r: 7, c: 15 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      // Drilling Parameters
      {
        s: { r: 7, c: 16 },
        e: { r: 7, c: 24 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      // Weights
      {
        s: { r: 7, c: 25 },
        e: { r: 7, c: 27 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
      // Comments
      {
        s: { r: 7, c: 28 },
        e: { r: 8, c: 32 },
        style: getCellStyle(userRole?.userPrefs?.reportSettings?.primaryColor, 'center', 'center', true, 'thin', 'thin', 'thin', 'thin'),
      },
    ]

    let xlsSlideSheetData = getSlideSheetRows(slideData, false)

    const xlsData = []
    let rowNum = 9
    for (const rowArray of xlsSlideSheetData) {
      const subArray = []
      for (const item of rowArray) {
        subArray.push(item.text)
      }
      //Comments column is a merge
      ws['!merges'].push({
        s: { r: rowNum, c: 28 },
        e: { r: rowNum, c: 32 },
        style: getCellStyle('#FFFFFF', 'left', 'center', false, 'thin', 'thin', 'thin', 'thin'),
      })
      rowNum++
      xlsData.push(subArray)
    }

    return { ws: ws, data: xlsData }
  }

  const getXlsCellRange = (start, end) => {
    if (!start || !end) return 'A1:A2'
    let startAddress = XLSX.utils.encode_cell(start)
    let endAddress = XLSX.utils.encode_cell(end)
    return `${startAddress}:${endAddress}`
  }

  const generateColumnWidth = (ws, colNum, chWidth) => {
    if (!ws) return
    return (ws['!cols'][colNum] = { wch: chWidth })
  }

  const getWorksheetStyles = (ws) => {
    if (!ws) return

    if (!ws['!cols']) ws['!cols'] = []
    generateColumnWidth(ws, 1, 13) // date col
    generateColumnWidth(ws, 5, 10) // start depth col
    generateColumnWidth(ws, 8, 25) // op col
    generateColumnWidth(ws, 21, 12) // on btm pressure col
    generateColumnWidth(ws, 22, 12) // off btm pressure col

    for (const field in ws) {
      if (field === '!ref' || field === '!merges') continue

      let isHeader = false

      let decodedAddress = XLSX.utils.decode_cell(field)
      if (decodedAddress.r === 8) isHeader = true

      //determine if cell is part of a slide row for row highlighting
      let isSlideRow =
        ws[XLSX.utils.encode_cell({ r: decodedAddress?.r, c: 8 })]?.v === 'Slide' && decodedAddress?.c < 28
          ? true
          : false

      ws[field].s = getCellStyle(
        isHeader ? userRole?.userPrefs?.reportSettings?.primaryColor : isSlideRow ? '#CCFFFF' : '#FFFFFF',
        'center',
        'center',
        isHeader,
        'thin',
        'thin',
        'thin',
        'thin',
      )
    }

    if (ws.hasOwnProperty('!merges')) {
      ws['!merges'].forEach((cellAddresses) => {
        const { s, e, style } = cellAddresses

        let cellRange = getXlsCellRange(s, e)

        if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
      })
    }
  }

  const getXlsFileName = (bhaNumRep) => {
    let currentOp = ''
    if (Array.isArray(slideSheetData.current) && slideSheetData.current?.length > 0)
      currentOp = `${slideSheetData.current[0]?.operator}_`

    let currentWell = currentWellRef.current ? `${removeSymbolsFromName(currentWellRef.current)}_` : ''
    let bhaList = bhaNumRep ? `_BHA_${bhaNumRep}` : '_All_BHAs'
    return `${currentOp}${currentWell}Slide_Sheet${bhaList}.xlsx`
  }

  const getSlideSheetXlsData = async (bhaNums, bhaNumRep = null) => {
    if (!Array.isArray(bhaNums)) return null
    if (!bhaNums || bhaNums.length <= 0) return null
    if (!slideSheetData.current) return null
    if (!Array.isArray(slideSheetData.current)) return null
    if (!currentWellRef.current) return null
    if (currentWellRef.current?.length < 1) return null

    let wb = XLSX.utils.book_new()
    for (let i = 0; i < bhaNums.length; i++) {
      let reportIndex = slideSheetData.current.findIndex((slideSheet) => slideSheet.bhaNum === bhaNums[i])
      if (reportIndex < 0) return null
      const { ws, data } = await handleXlsExport(bhaNums[i], slideSheetData.current[reportIndex])

      XLSX.utils.sheet_add_aoa(ws, data, { origin: 'B10' })

      getWorksheetStyles(ws)

      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `BHA ${slideSheetData.current[reportIndex].bhaNumRep}`,
      )
    }

    XLSX.writeFile(wb, getXlsFileName(bhaNumRep), {
      cellStyles: true,
      bookImages: true,
    })
  }

  return {
    loading,
    fetchSlideSheets,
    fetchSlideSheet,
    fetchSlideSheetData,
    getSlideSheetPdfData,
    getSlideSheetXlsData,
  }
}

export default useSlideSheets
