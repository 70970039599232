import React, { useRef, useEffect } from 'react'
import { useMap } from '@vis.gl/react-google-maps'
import { wellsMapLayersSelectorAtom } from 'atoms'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { useRecoilValue } from 'recoil'

export const STATES = 'States'
export const COUNTIES = 'Counties'
export const SHALE_PLAYS = 'Shale Plays'
export const CONVENTIONAL_PLAYS = 'Conventional Plays'

const setLayer = (layerRef, layerName, selectedLayers, gMap, geoJSON, color1, color2) => {
  if (!gMap || !geoJSON) return
  if (layerRef.current[layerName]) {
    const { geoLayer } = layerRef.current[layerName]

    if (!selectedLayers[layerName]) {
      if (geoLayer) geoLayer.setMap(null)
      return
    }

    if (geoLayer) {
      geoLayer.setMap(gMap)
      return
    }
  }

  if (!selectedLayers[layerName]) return

  const newGeoLayer = new window.google.maps.Data({ map: gMap })
  layerRef.current[layerName] = { geoLayer: newGeoLayer }
  newGeoLayer.addGeoJson(geoJSON)
  newGeoLayer.setStyle((feature) => {
    return {
      fillColor: feature.getProperty('isColorful') ? color2 : color1,
      strokeColor: feature.getProperty('isColorful') ? color2 : color1,
      strokeWeight: 0.25,
      fillOpacity: 0.15,
    }
  })

  newGeoLayer.addListener('click', (event) => {
    const currentValue = event.feature.getProperty('isColorful')
    newGeoLayer.setStyle((feature) => {
      return {
        fillColor: currentValue ? color1 : color2,
        strokeColor: currentValue ? color1 : color2,
        strokeWeight: 0.25,
        fillOpacity: 0.15,
      }
    })

    event.feature.setProperty('isColorful', !currentValue)
  })

  newGeoLayer.addListener('mouseover', (event) => {
    const { geoLayer } = layerRef.current[layerName]
    geoLayer.revertStyle()
    geoLayer.overrideStyle(event.feature, { strokeWeight: 4 })
    layerRef.current[layerName] = { geoLayer }
  })

  newGeoLayer.addListener('mouseout', () => {
    const { geoLayer } = layerRef.current[layerName]
    if (geoLayer) geoLayer.revertStyle()
  })
}

const Layers = () => {
  const map = useMap()
  const _isMounted = useRef(false)
  const selLayers = useRecoilValue(wellsMapLayersSelectorAtom)
  const isFetching = useRef({ STATES: false, COUNTIES: false, SHALE_PLAYS: false, CONVENTIONAL_PLAYS: false })
  const geoJson = useRef({ STATES: null, COUNTIES: null, SHALE_PLAYS: null, CONVENTIONAL_PLAYS: null })
  const layersRef = useRef({})

  const getStates = useAxiosGzip({
    url: '/maps/geoJSON/gzip/states',
  })

  const getCounties = useAxiosGzip({
    url: '/maps/geoJSON/gzip/counties',
  })

  const getShalePlays = useAxiosGzip({
    url: '/maps/geoJSON/gzip/shalePlays',
  })

  const getConventionalPlays = useAxiosGzip({
    url: '/maps/geoJSON/gzip/conventionalPlays',
  })

  useEffect(() => {
    if (!_isMounted.current) return
    if (!map) return
    setLayer(layersRef, STATES, selLayers, map, geoJson.current[STATES], 'red', 'dark red')
    setLayer(layersRef, COUNTIES, selLayers, map, geoJson.current[COUNTIES], 'blue', 'aqua')
    setLayer(layersRef, SHALE_PLAYS, selLayers, map, geoJson.current[SHALE_PLAYS], 'black', 'gray')
    setLayer(layersRef, CONVENTIONAL_PLAYS, selLayers, map, geoJson.current[SHALE_PLAYS], 'darkgreen', 'darkolivegreen')
  }, [selLayers, map])

  const fetchGeoJson = async (fetchFunc, tag) => {
    if (!_isMounted.current) return
    if (isFetching.current[tag]) return

    isFetching.current[tag] = true
    const res = await fetchFunc()
    isFetching.current[tag] = false

    if (!_isMounted.current) return
    if (res?.error) return
    if (!res?.data) return

    geoJson.current[tag] = res.data
  }

  useEffect(() => {
    _isMounted.current = true
    fetchGeoJson(getStates, STATES)
    fetchGeoJson(getCounties, COUNTIES)
    fetchGeoJson(getShalePlays, SHALE_PLAYS)
    fetchGeoJson(getConventionalPlays, CONVENTIONAL_PLAYS)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment />
}

export default Layers
