import React, { useEffect, useState } from 'react'
import useDrillString from 'components/common/hooks/useDrillString/useDrillString'
import DrillStringGrid from 'components/WellPages/DrillStringPages/DrillStringGrid'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import useMotorReportImages from 'components/common/hooks/useMotorReportImages'

const DrillStringWellGrid = ({ wellName, setStatus }) => {
  const _isMounted = React.useRef(false)
  const [drillStringData, setDrillStringData] = useState([])
  const { fetchImagesManual } = useMotorReportImages()

  const {
    loading: isLoadingDrillString,
    getDrillStringHeader,
    getBhaReportPdfData,
    getMotorReportPdfData,
    getMotorPerformanceXlsExport,
    getBhaXlsExport,
    fetchBhaIaddExport,
  } = useDrillString(wellName)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      if (!isLoadingDrillString) setDrillStringData(getDrillStringHeader(-1))
    }
  }, [isLoadingDrillString, wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const onBhaPdfExport = async (bhaNum) => {
    let pdfDoc = await getBhaReportPdfData(bhaNum)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onIaddExport = async (bhaNum) => {
    let bhaJson = await fetchBhaIaddExport(bhaNum)
    if (!bhaJson) return
    let blob = new Blob([JSON.stringify(bhaJson)], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, `iaddBhaExport.txt`)
  }

  const onXlsExport = (bhaNum, exportType) => {
    if (exportType === 'performanceExport') getMotorPerformanceXlsExport(bhaNum)
    if (exportType === 'bhaExport') getBhaXlsExport(bhaNum)
    return
  }

  const onMotorReportPdfExport = async (bhaNum) => {
    if (bhaNum === null || bhaNum === undefined) return

    let motorImages = await fetchImagesManual(bhaNum, wellName)
    let pdfDoc = await getMotorReportPdfData(bhaNum, motorImages)
    if (!pdfDoc) return

    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  return (
    <DrillStringGrid
      gridData={drillStringData}
      isLoading={isLoadingDrillString}
      setFilteredData={() => {}}
      onBhaPdfExport={onBhaPdfExport}
      onMotorReportPdfExport={onMotorReportPdfExport}
      setStatus={setStatus}
      setShowChart={() => {}}
      showChart={false}
      wellName={wellName}
      onXlsExport={onXlsExport}
      onIaddExport={onIaddExport}
    />
  )
}
export default DrillStringWellGrid
