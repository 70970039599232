import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { Box, IconButton, Snackbar } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const MagneticsGrid = ({ wellName, isPlan }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const wellNameRef = useRef(wellName)
  const [isLoading, setLoading] = useState(false)
  const [magneticsData, setMagneticsData] = useState([])
  const magModels = useRef(['User Defined'])
  const inputRow = useRef({})
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, getChartBackColor } = useInnovaTheme()

  const getMagnetics = useInnovaAxios({
    url: isPlan ? '/plannedWell/getMagnetics' : '/well/getMagnetics',
  })

  const updateMagnetics = useInnovaAxios({
    url: isPlan ? '/plannedWell/updateMagnetics' : '/well/updateMagnetics',
  })

  const deleteMagnetics = useInnovaAxios({
    url: isPlan ? '/plannedWell/deleteMagnetics' : '/well/deleteMagnetics',
  })

  const addMagnetics = useInnovaAxios({
    url: isPlan ? '/plannedWell/addMagnetics' : '/well/addMagnetics',
  })

  const getMagneticsModels = useInnovaAxios({
    url: '/well/getMagneticModels',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchMagnetics()
    fetchMagneticsModels()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    wellNameRef.current = wellName
    fetchMagnetics()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMagnetics = async () => {
    if (isLoading) return
    if (!wellName) return
    setLoading(true)

    const response = await getMagnetics({ wellName: wellNameRef.current })

    if (response?.error) return
    if (!_isMounted.current) return
    setLoading(false)
    setMagneticsData(Array.isArray(response.data) ? response.data : [])
  }

  const fetchMagneticsModels = async () => {
    const response = await getMagneticsModels()
    if (response?.error) return
    magModels.current = Array.isArray(response.data) ? response.data : []
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    loadingOverlayComponent: CustomLoadingOverlay,
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  let reqFields = useMemo(() => ['totalField', 'dec', 'dip', 'date', 'model'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    if (!inputRow.current.hasOwnProperty('model')) return false
    if (!inputRow.current.hasOwnProperty('date')) return false
    if (inputRow.current.model === 'User Defined') {
      if (!inputRow.current.hasOwnProperty('dip')) return false
      if (!inputRow.current.hasOwnProperty('dec')) return false
      if (!inputRow.current.hasOwnProperty('totalField')) return false
    }

    return true
  }, [])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [centerAlignCell, isEmptyPinnedCell, createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('magneticsGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    if (!wellNameRef.current) return
    inputRow.current.wellName = wellNameRef.current
    inputRow.current = htmlSymbolHandling(inputRow.current)

    isAdding.current = true
    let res = await addMagnetics({ ...inputRow.current })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res.data) return

    if (!res?.error) {
      inputRow.current = res.data
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [addMagnetics, isPinnedRowDataCompleted])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.rowNum)
    }
  }, [])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('rowNum')) return

      if (isUpdating.current) return

      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateMagnetics({ ...data })
      isUpdating.current = false

      if (!_isMounted.current) return
      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!Array.isArray(res.data)) return
      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: res.data,
        })
      }
    },
    [updateMagnetics],
  )

  const handleDelete = useCallback(
    async (data) => {
      if (!data) return
      if (isDeleting.current) return
      isDeleting.current = true
      let res = await deleteMagnetics({ ...data, wellName: wellNameRef.current })
      isDeleting.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          remove: [data],
        })
      }
    },
    [deleteMagnetics],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
            size='large'>
            {params.data?.rowNum !== 0 ? (
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            ) : (
              <Box sx={{ width: '15px', height: '15px' }} />
            )}
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    value += 'T00:00:01'

    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'date',
        colId: 'date',
        headerName: 'Date',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return true
          if (params?.node?.rowIndex === 0) return false
          return true
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Date...'
          return dateTimeFormatter(params?.data?.date)
        },
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
      },
      {
        field: 'model',
        colId: 'model',
        headerName: 'Model',
        editable: (params) => params.node?.rowPinned === 'bottom' || params.data?.rowNum > 0,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(magModels.current) ? magModels.current : ['User Defined'],
          }
        },
      },
      {
        field: 'dec',
        colId: 'dec',
        headerName: 'Declination',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') {
            if (inputRow.current.model !== 'User Defined') return false
          }

          if (params.data?.rowNum === 0) return false
          return params.data?.model === 'User Defined'
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Declination...'
          return numberWithCommasDecimals(params.value, 3)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -180,
          max: 180,
          precision: 3,
        },
      },
      {
        field: 'dip',
        colId: 'dip',
        headerName: 'Dip',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') {
            if (inputRow.current.model !== 'User Defined') return false
          }

          if (params.data?.rowNum === 0) return false
          return params.data?.model === 'User Defined'
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Dip...'
          return numberWithCommasDecimals(params.value, 3)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -180,
          max: 180,
          precision: 3,
        },
      },
      {
        field: 'totalField',
        colId: 'totalField',
        headerName: 'Total Field',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') {
            if (inputRow.current.model !== 'User Defined') return false
          }

          if (params.data?.rowNum === 0) return false
          return params.data?.model === 'User Defined'
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'TotalField...'
          return numberWithCommasDecimals(params.value, 3)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 80000,
          precision: 3,
        },
      },
      {
        field: 'isActive',
        colId: 'isActive',
        headerName: 'Active',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
    ],
    [actionIconRenderer, isEmptyPinnedCell, dateTimeFormatter],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box sx={verticalTextStyle}>Magnetics</Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={magneticsData}
          columnDefs={sortColDefs(columnDefs, 'magneticsGrid')}
          defaultColDef={defaultColDef}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          rowSelection={'single'}
          headerHeight={30}
          onGridReady={onGridReady}
          getRowId={getRowId}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default MagneticsGrid
