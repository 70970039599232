import React, { useRef, useMemo, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import floatingRigImage from 'assets/DepthDatums/floater.bmp'
import jackupRigImage from 'assets/DepthDatums/jackup.bmp'
import landRigImage from 'assets/DepthDatums/landrig.bmp'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const DepthReferenceGrid = ({ wellData, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [depthDatumData, setDepthDatumDataData] = useState([])
  const depthDatumDataRef = useRef([])
  const { getAgGridTheme, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  const getDepthDatums = useInnovaAxios({
    url: '/well/getDepthDatums',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDepthDatums()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchDepthDatums()
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  function sortDepthDatums(a, b) {
    // Move 'default' to the front
    if (a.name === 'default') return -1
    if (b.name === 'default') return 1

    // Sort alphabetically by name
    return a.name.localeCompare(b.name)
  }

  const fetchDepthDatums = async () => {
    if (isLoading) return
    if (!wellData) return
    if (!wellData.wellName) setLoading(true)

    const response = await getDepthDatums({ wellName: wellData.wellName })

    if (response?.error) return
    if (!_isMounted.current) return

    depthDatumDataRef.current = Array.isArray(response.data) ? response.data.sort(sortDepthDatums) : []
    setDepthDatumDataData(Array.isArray(response.data) ? response.data.sort(sortDepthDatums) : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      let newData = { datumName: event.data.value, datumElevation: 0 }

      let datumData = Array.isArray(depthDatumDataRef.current)
        ? depthDatumDataRef.current.find((datum) => datum.name === event.data.value)
        : null

      if (!datumData) return
      newData.datumElevation = datumData.elevation

      handleUpdate(newData)
    },
  }

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 200,
        cellDataType: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => params?.data.editable,
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'bool') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, 2)}`
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getDepthReferenceData = (data) => {
    let depthReference = [
      {
        label: `Name`,
        value: data?.datumName,
        id: 0,
        type: 'dropDown',
        tag: `datumName`,
        dropDownValues: Array.isArray(depthDatumDataRef.current)
          ? depthDatumDataRef.current.map((datum) => datum.name)
          : [],
        editable: true,
      },
    ]

    if (!data?.datumName) return depthReference
    if (typeof data.datumName !== 'string') return depthReference
    if (data.datumName === '') return depthReference

    let datumData = Array.isArray(depthDatumDataRef.current)
      ? depthDatumDataRef.current.find((datum) => datum.name === data.datumName)
      : null
    if (!datumData) return depthReference

    depthReference.push({
      label: `Rig`,
      value: unescapeHtml(datumData.rig),
      id: 1,
      type: 'text',
      tag: `rig`,
      editable: false,
    })
    depthReference.push({
      label: `Elevation`,
      value: datumData.elevation,
      id: 2,
      type: 'number',
      tag: `elevation`,
      editable: false,
    })
    depthReference.push({
      label: `Offshore`,
      value: datumData.offshoreCheck,
      id: 3,
      type: 'bool',
      tag: `offshoreCheck`,
      editable: false,
    })
    depthReference.push({
      label: `Air Gap`,
      value: datumData.airGap,
      id: 5,
      type: 'number',
      tag: `airGap`,
      editable: false,
    })
    depthReference.push({
      label: datumData.offshoreCheck ? `Water Depth` : 'Ground Level',
      value: datumData.waterDepthGl,
      id: 6,
      type: 'number',
      tag: `waterDepthGl`,
      editable: false,
    })

    if (datumData.offshoreCheck && datumData.subseaCheck) {
      depthReference.push({
        label: `Wellhead Elevation`,
        value: datumData.wellheadElev,
        id: 7,
        type: 'number',
        tag: `wellheadElev`,
        editable: false,
      })
    }

    return depthReference
  }

  const DatumImage = () => {
    let datumData = Array.isArray(depthDatumData)
      ? depthDatumData.find((datum) => datum.name === wellData.datumName)
      : null

    let src = landRigImage
    if (datumData?.offshoreCheck) {
      src = jackupRigImage
      if (datumData?.subseaCheck) {
        src = floatingRigImage
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '25%',
          height: '100%',
          padding: '5px',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box component='img' src={src} alt='NONE' sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </Box>
    )
  }

  return (
    <Box
      sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor(), display: 'flex', flexDirection: 'row' }}>
      <Box sx={verticalTextStyle}>Depth Datum</Box>
      <Box
        sx={{
          width: 'calc(100% - 25px)',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}>
        <div style={{ height: '100%', width: '75%' }} className={getAgGridTheme()}>
          <AgGridReact
            loading={isLoading}
            className='ag-grid-theme-dark'
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            rowData={getDepthReferenceData(wellData)}
            headerHeight={0}
            onFirstDataRendered={onFirstDataRendered}
            getRowId={getRowId}
          />
        </div>
        <DatumImage />
      </Box>
    </Box>
  )
}

export default DepthReferenceGrid
