import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useInnovaAuth()
  let location = useLocation()

  return isAuthenticated === true ? children : <Navigate to='/' state={{ from: location }} replace/>
}

export default PrivateRoute
