import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import AntiCollisionDashStatusGrid from 'components/Admin/AntiCollisionDashboardStatus/AntiCollisionDashStatusGrid'
import AntiCollisionDashBarChart from 'components/Admin/AntiCollisionDashboardStatus/AntiCollisionDashBarChart'
import AntiCollisionDashRedisGrid from 'components/Admin/AntiCollisionDashboardStatus/AntiCollisionDashRedisGrid'

const AntiCollisionDashStatusPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [showChart, setShowChart] = useState(true)
  const [filteredData, setFilteredData] = useState([])
  const [redisData, setRedisData] = useState(null)

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminAcDashKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SplitPane
      split='horizontal'
      defaultSize={'50%'}
      size={showChart ? '50%' : 0}
      style={{
        marginTop: '64px',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        minHeight: 'calc(100vh - 64px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}
      allowResize={true}>
      <SplitPane
        style={{ width: '100%', height: '100%' }}
        split='vertical'
        defaultSize={'75%'}
        size={showChart ? '75%' : 0}
        allowResize={true}>
        <AntiCollisionDashBarChart data={filteredData} />
        <AntiCollisionDashRedisGrid data={redisData} />
      </SplitPane>

      <AntiCollisionDashStatusGrid
        showChart={showChart}
        setShowChart={setShowChart}
        setFilteredData={setFilteredData}
        setRedisData={setRedisData}
      />
    </SplitPane>
  )
}

export default AntiCollisionDashStatusPage
