import WellHead from "./WellHead"

const OffsetWellheads = ({ offsetData, display, offsetsOn, scale, pumpJackModel }) => {
  if (!display) return null
  if (!offsetsOn) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null

  return offsetData.map((osWell, index) => (
    <WellHead pumpJackModel={pumpJackModel} scale={scale} wellData={osWell} key={`${osWell.name}wellhead${index}`} />
  ))
}

export default OffsetWellheads
