import React, { useRef, useMemo, useEffect, useCallback } from 'react'
import { Box, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PolygonCoordsGrid = ({ selectedTarget, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const selectedTargetRef = useRef(selectedTarget)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    selectedTargetRef.current = cloneDeep(selectedTarget)
  }, [selectedTarget])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const handleUpdateFunc = useCallback(
    async (data) => {
      let gridData = []
      if (gridApi.current) {
        gridApi.current.forEachNode((node) => {
          if (node?.data && node?.rowPinned !== 'bottom') {
            let dataCopy = { ...node.data }
            if (dataCopy.uid === data.uid) dataCopy = data
            gridData.push(dataCopy)
          }
        })
      }

      handleUpdate({ tag: 'polygonPoints', value: gridData })
    },
    [handleUpdate],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdateFunc(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const isPinnedRowDataCompleted = useCallback(() => {
    if (inputRow.current?.hasOwnProperty('localNorth') && inputRow.current?.hasOwnProperty('localEast')) return true
    if (inputRow.current?.hasOwnProperty('gridNorth') && inputRow.current?.hasOwnProperty('gridEast')) return true
    return false
  }, [])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('polygonCoordsGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return

    let gridData = []
    if (gridApi.current) {
      gridApi.current.forEachNode((node) => {
        if (node?.data && node?.rowPinned !== 'bottom') gridData.push(node.data)
      })
    }

    inputRow.current.uid = gridData.length
    gridData.push(inputRow.current)

    handleUpdate({ tag: 'polygonPoints', value: gridData })

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [isPinnedRowDataCompleted, handleUpdate])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const handleDelete = useCallback(
    async (data) => {
      if (!data) return

      let gridData = []
      if (gridApi.current) {
        gridApi.current.forEachNode((node) => {
          if (node?.data && node?.rowPinned !== 'bottom') {
            if (node.data.uid !== data.uid) {
              let dataCopy = { ...node.data }
              gridData.push(dataCopy)
            }
          }
        })
      }

      handleUpdate({ tag: 'polygonPoints', value: gridData })
    },
    [handleUpdate],
  )

  const getContextMenuItems = (params) => {
    return ['copy', 'copyWithHeaders', 'export', 'autoSizeAll']
  }

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        hide: selectedTargetRef.current.polygonProperties.inputMethMap,
        field: 'localNorth',
        colId: 'localNorth',
        headerName: 'Local NS',
        editable: (params) => !selectedTargetRef.current.polygonProperties.inputMethMap,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'LocalNs...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        hide: selectedTargetRef.current.polygonProperties.inputMethMap,
        field: 'localEast',
        colId: 'localEast',
        headerName: 'Local EW',
        editable: (params) => !selectedTargetRef.current.polygonProperties.inputMethMap,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'LocalEw...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        hide: !selectedTargetRef.current.polygonProperties.inputMethMap,
        field: 'gridNorth',
        colId: 'gridNorth',
        headerName: `Grid NS`,
        editable: (params) => selectedTargetRef.current.polygonProperties.inputMethMap,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'GridNs...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        hide: !selectedTargetRef.current.polygonProperties.inputMethMap,
        field: 'gridEast',
        colId: 'gridEast',
        headerName: `Grid EW`,
        editable: (params) => selectedTargetRef.current.polygonProperties.inputMethMap,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'GridEw...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        hide: !selectedTargetRef.current.polygonProperties.individualThickness,
        field: 'thicknessUp',
        colId: 'thicknessUp',
        headerName: 'Thick Up',
        editable: (params) => !selectedTargetRef.current.polygonProperties.individualThickness,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'ThickUp...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        hide: !selectedTargetRef.current.polygonProperties.individualThickness,
        field: 'thicknessDown',
        colId: 'thicknessDown',
        headerName: 'Thick Dn',
        editable: (params) => !selectedTargetRef.current.polygonProperties.individualThickness,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'ThickDn...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        field: 'bearing',
        colId: 'bearing',
        headerName: 'Brg',
        editable: false,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          return numberWithCommasDecimals(params.value, 2)
        },
      },
      {
        field: 'distance',
        colId: 'distance',
        headerName: 'Dist',
        editable: false,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          return numberWithCommasDecimals(params.value, 2)
        },
      },
    ],
    [actionIconRenderer, isEmptyPinnedCell],
  )

  const getGridData = () => {
    if (!selectedTarget) return []
    if (!Array.isArray(selectedTarget?.polygonProperties?.rawPolygonPoints)) return []

    return selectedTarget?.polygonProperties?.rawPolygonPoints.map((point, index) => {
      let pointCopy = { ...point }
      pointCopy.uid = index

      return pointCopy
    })
  }

  function isValidNumber(input) {
    if (typeof input !== 'string' && typeof input !== 'number') return false
    if (typeof input === 'string' && input === '') return false
    if (typeof input === 'string') input = Number(input)
    return typeof input === 'number' && !isNaN(input)
  }

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null
    if (!selectedTargetRef.current) return

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let item = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          item.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        rowsToUpdate.push(cloneDeep(item.data))
        curRowIndex++
      }
    }

    let gridData = []
    if (gridApi.current) {
      gridApi.current.forEachNode((node) => {
        if (node?.data && node?.rowPinned !== 'bottom') gridData.push(node.data)
      })
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newItem = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newItem[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!selectedTargetRef.current.polygonProperties.inputMethMap) {
          if (!isValidNumber(newItem.localNorth) || !isValidNumber(newItem.localEast)) continue
          newItem.localNorth = Number(newItem.localNorth)
          newItem.localEast = Number(newItem.localEast)
        }

        if (selectedTargetRef.current.polygonProperties.inputMethMap) {
          if (!isValidNumber(newItem.gridNorth) || !isValidNumber(newItem.gridEast)) continue
          newItem.gridNorth = Number(newItem.gridNorth)
          newItem.gridEast = Number(newItem.gridEast)
        }

        if (selectedTargetRef.current.polygonProperties.individualThickness) {
          if (!isValidNumber(newItem.thicknessUp) || !isValidNumber(newItem.thicknessDown)) continue
          newItem.thicknessUp = Number(newItem.thicknessUp)
          newItem.thicknessDown = Number(newItem.thicknessDown)
        }

        newItem.uid = gridData.length + rowsToAdd.length
        rowsToAdd.push(newItem)
      }
    }

    if (rowsToAdd.length > 0) {
      gridData.push(...rowsToAdd.reverse())
      gridApi.current.applyTransaction({
        add: rowsToAdd.reverse(),
      })
    }

    if (rowsToUpdate.length > 0) {
      for (let i = 0; i < gridData.length; i++) {
        let index = gridData.findIndex((item) => item.uid === rowsToUpdate[i].uid)

        if (index >= 0) {
          if (!selectedTargetRef.current.polygonProperties.inputMethMap) {
            gridData[i].localNorth = rowsToUpdate[i].localNorth
            gridData[i].localEast = rowsToUpdate[i].localEast
          }

          if (selectedTargetRef.current.polygonProperties.inputMethMap) {
            gridData[i].gridNorth = rowsToUpdate[i].gridNorth
            gridData[i].gridEast = rowsToUpdate[i].gridEast
          }

          if (selectedTargetRef.current.polygonProperties.individualThickness) {
            gridData[i].thicknessUp = rowsToUpdate[i].thicknessUp
            gridData[i].thicknessDown = rowsToUpdate[i].thicknessDown
          }
        }
      }

      gridApi.current.applyTransaction({
        update: rowsToUpdate,
      })
    }

    if (rowsToAdd.length > 0 || rowsToUpdate.length > 0) {
      handleUpdate({ tag: 'polygonPoints', value: gridData })
    }

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={sortColDefs(columnDefs, 'polygonCoordsGrid')}
        rowData={getGridData()}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        rowSelection={'single'}
        headerHeight={30}
        onGridReady={onGridReady}
        getRowId={getRowId}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        processDataFromClipboard={processDataFromClipboard}
      />
    </div>
  )
}

export default PolygonCoordsGrid
