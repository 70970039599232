import React, { useEffect, useRef, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import MenuButton from 'components/common/MenuButton'
import SplitPane from 'components/common/SplitPane'
import { Box } from '@mui/material'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import useInventory from 'components/common/hooks/useInventory'
import InventoryGrid from './InventoryGrid'
import InventoryChart from './InventoryChart'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import { checkFeature } from '../../userPermissions'
import OasisToolImportModal from './OasisToolImportModal'
import SureShopToolImportModal from './SureShopToolImportModal'

const InventoryPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const importTools = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [showOasisModal, setShowOasisModal] = useState(false)
  const [showSureShopModal, setShowSureShopModal] = useState(false)
  const {
    loading: isLoading,
    fetchInventory,
    fetchInventoryData,
    onXlsxExport,
    getDropDowns,
    getPdfData,
    currentWell,
  } = useInventory()
  const [inventoryData, setInventoryData] = useState([])
  const [filteredInventory, setFilteredInventory] = useState([])
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.inventoryKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onPdfExport = async () => {
    const pdfDoc = getPdfData()
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  useEffect(() => {
    if (!isLoading) setInventoryData(fetchInventoryData())
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: fetchInventory,
      },
      {
        icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
        name: 'Export to XLSX',
        onClick: onXlsxExport,
      },
      { icon: <PictureAsPdfIcon />, name: 'PDF', onClick: onPdfExport },
    ]
    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Charts',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Charts',
        onClick: () => handleShowChart(),
      })
    }

    if (checkFeature(12, userRole?.roleAttributes?.featureId)) {
      actions.push({
        name: 'Get Oasis tools',
        onClick: () => {
          setShowOasisModal(true)
        },
        icon: <Iconify icon='fa6-solid:o' style={{ color: '#4BB2F9', width: 28, height: 28 }} />,
      })
    }

    if (checkFeature(47, userRole?.roleAttributes?.featureId)) {
      actions.push({
        name: 'Get SureShop tools',
        onClick: () => {
          setShowSureShopModal(true)
        },
        icon: <Iconify icon='noto:wrench' style={{ width: 28, height: 28 }} />,
      })
    }

    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const handleOasisImport = (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return
    let toolsToImport = []

    for (let i = 0; i < data.length; i++) {
      let toolType = 'Sub'

      if (typeof data[i].data.description === 'string') {
        let descLwr = data[i].data.description
        descLwr = descLwr.toLowerCase()

        if (descLwr.includes('motor')) toolType = 'Motor'
        if (descLwr.includes('stabilizer')) toolType = 'Stabilizer'
        if (descLwr.includes('stab')) toolType = 'Stabilizer'
        if (descLwr.includes('drill pipe')) toolType = 'Drill Pipe/HWDP'
        if (descLwr.includes('drill bit')) toolType = 'Drill Bit'
        if (descLwr.includes('mwd')) toolType = 'MWD/LWD'
        if (descLwr.includes('rss')) toolType = 'RSS'
      }

      if (typeof data[i].data.toolTypeName === 'string') {
        let typeLwr = data[i].data.toolTypeName
        typeLwr = typeLwr.toLowerCase()
        typeLwr = typeLwr.trim()
        toolType = 'Sub'
        if (typeLwr.includes('drill bit')) toolType = 'Drill Bit'
        if (typeLwr.includes('hole opener')) toolType = 'Hole Opener'
        if (typeLwr.includes('circ sub')) toolType = 'Circ Sub'
        if (typeLwr.includes('cross over')) toolType = 'Cross Over'
        if (typeLwr.includes('rss')) toolType = 'RSS'
        if (typeLwr.includes('lwd')) toolType = 'MWD/LWD'
        if (typeLwr.includes('motor')) toolType = 'Motor'
        if (typeLwr.includes('turbine')) toolType = 'Turbine'
        if (typeLwr.includes('stabilizer')) toolType = 'Stabilizer'
        if (typeLwr.includes('drill collar')) toolType = 'Drill Collar'
        if (typeLwr.includes('drill pipe')) toolType = 'Drill Pipe/HWDP'
        if (typeLwr.includes('jar')) toolType = 'Jar'
        if (typeLwr.includes('accelerator')) toolType = 'Accelerator'
        if (typeLwr.includes('liner')) toolType = 'Liner'
        if (typeLwr.includes('casing')) toolType = 'Casing/Tubing'
        if (typeLwr.includes('agitator')) toolType = 'Agitator'
        if (typeLwr.includes('reamer')) toolType = 'Reamer'
        if (typeLwr.includes('pulser')) toolType = 'Pulser'
        if (typeLwr.includes('battery')) toolType = 'Battery'
        if (typeLwr.includes('directional')) toolType = 'Directional Module'
        if (typeLwr.includes('driver')) toolType = 'Driver'
        if (typeLwr.includes('interconnect')) toolType = 'Interconnect'
        if (typeLwr.includes('em')) toolType = 'EM'
        if (typeLwr.includes('gamma')) toolType = 'Gamma'
        if (typeLwr.includes('resistivity')) toolType = 'Resistivity'
        if (typeLwr.includes('helix')) toolType = 'Helix'
        if (typeLwr.includes('laptop')) toolType = 'MWD Laptop'
        if (typeLwr.includes('depth tracking')) toolType = 'MWD Depth Tracking'
        if (typeLwr.includes('decoder')) toolType = 'MWD Decoder'
        if (typeLwr.includes('hasp')) toolType = 'HASP Key'
        if (typeLwr.includes('barrier box')) toolType = 'Barrier Box'
        if (typeLwr.includes('transducer')) toolType = 'Transducer'
        if (typeLwr.includes('aggregator')) toolType = 'Aggregator'
        if (typeLwr.includes('receiver')) toolType = 'Surface Receiver'
        if (typeLwr.includes('rfd')) toolType = 'MWD RFD'
        if (typeLwr.includes('shock absorber')) toolType = 'Shock Absorber'
        if (typeLwr.includes('circ sub')) toolType = 'Circ Sub'
      }

      toolsToImport.push({
        assetId: typeof data[i].data.itemID === 'number' ? `${parseInt(data[i].data.itemID)}` : data[i].data.itemID,
        toolType: toolType,
        serialNum: data[i].data.serialNumber,
        description: data[i].data.description,
        dateOn: data[i].data.onJobDate,
        dateOff: data[i].data.offJobDate,
        make: data[i].data.make,
        model: data[i].data.model,
        od: data[i].data.od,
        id: data[i].data.id,
        length: data[i].data.length,
        weight: data[i].data.weight,
        comments: data[i].data.comments,
        connectionTop: data[i].data.cnxTop,
        connectionBottom: data[i].data.cnxBtm,
      })
    }

    if (importTools.current) importTools.current(toolsToImport)
  }

  const handleSureShopImport = (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return
    let toolsToImport = []

    for (let i = 0; i < data.length; i++) {
      let toolType = 'Sub'

      if (typeof data[i].data.description === 'string') {
        let descLwr = data[i].data.description
        descLwr = descLwr.toLowerCase()

        if (descLwr.includes('motor')) toolType = 'Motor'
        if (descLwr.includes('stabilizer')) toolType = 'Stabilizer'
        if (descLwr.includes('stab')) toolType = 'Stabilizer'
        if (descLwr.includes('drill pipe')) toolType = 'Drill Pipe/HWDP'
        if (descLwr.includes('drill bit')) toolType = 'Drill Bit'
        if (descLwr.includes('mwd')) toolType = 'MWD/LWD'
        if (descLwr.includes('rss')) toolType = 'RSS'
      }

      if (typeof data[i].data.tooltype === 'string') {
        let typeLwr = data[i].data.tooltype
        typeLwr = typeLwr.toLowerCase()

        if (typeLwr.includes('motor')) toolType = 'Motor'
        if (typeLwr.includes('stabilizer')) toolType = 'Stabilizer'
        if (typeLwr.includes('stab')) toolType = 'Stabilizer'
        if (typeLwr.includes('drill pipe')) toolType = 'Drill Pipe/HWDP'
        if (typeLwr.includes('drill bit')) toolType = 'Drill Bit'
        if (typeLwr.includes('mwd')) toolType = 'MWD/LWD'
        if (typeLwr.includes('rss')) toolType = 'RSS'
      }

      toolsToImport.push({
        assetId: typeof data[i].data.uid === 'number' ? `${parseInt(data[i].data.uid)}` : data[i].data.uid,
        serialNum: data[i].data.description,
        toolType: toolType,
        description: data[i].data.tooltype,
        dateOn: new Date(Date.now()).toISOString().substring(0, 10),
        od: parseFloat(data[i].data.od),
        length: parseFloat(data[i].data.length),
      })
    }

    if (importTools.current) importTools.current(toolsToImport)
  }

  return (
    <React.Fragment>
      {showOasisModal ? (
        <OasisToolImportModal
          wellName={currentWell}
          onClose={(data) => {
            setShowOasisModal(false)
            handleOasisImport(data)
          }}
        />
      ) : null}
      {showSureShopModal ? (
        <SureShopToolImportModal
          wellName={currentWell}
          onClose={(data) => {
            setShowSureShopModal(false)
            handleSureShopImport(data)
          }}
        />
      ) : null}
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={paneSize}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <InventoryChart inventoryData={filteredInventory} />
        <InventoryGrid
          data={inventoryData}
          loading={isLoading}
          setFilteredData={setFilteredInventory}
          getDropDowns={getDropDowns}
          onXlsxExport={onXlsxExport}
          importTools={importTools}
        />
      </SplitPane>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
    </React.Fragment>
  )
}

export default InventoryPage
