import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import useUnits from 'components/common/hooks/useUnits'
import { Avatar } from '@mui/material'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getInitialGroupRows = () => {
  let storedValue = getItemFromLS('unitsGridGroupRows', 'groupRows')
  if (storedValue === null || storedValue === undefined) return true
  return storedValue
}

const UnitsPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [unitsData, setUnitsData] = useState([])
  const [orgList, setOrgList] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const { getCompanyIcon } = useOrgIcons()
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { dropDowns } = useUnits()
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const [groupRows, setGroupRows] = useState(getInitialGroupRows())

  const getData = useInnovaAxios({
    url: '/units/getUnitSetsForOrg',
  })

  const addData = useInnovaAxios({
    url: '/units/addUnitSet',
  })

  const updateData = useInnovaAxios({
    url: '/units/updateUnitSet',
  })

  const deleteData = useInnovaAxios({
    url: '/units/deleteUnitSet',
  })

  const getOrgData = useInnovaAxios({
    url: '/admin/getOrgListByUser',
  })

  useEffect(() => {
    setTimeout(() => {
      //Timeout is required to stop a flush sync error
      if (gridApi.current) gridApi.current.refreshCells()
    }, 100)
  }, [getCompanyIcon]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminUnitsKey)
    fetchData()
    fetchOrganizationsList()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchOrganizationsList = async () => {
    const response = await getOrgData()
    if (response.error && _isMounted.current) {
      return
    }

    if (_isMounted.current && Array.isArray(response.data)) {
      let orgs = [...response.data]
      orgs.sort()

      setOrgList(orgs)
    }
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData()
    if (response.error && _isMounted.current) {
      setLoading(false)
      return
    }

    if (_isMounted.current) {
      setUnitsData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate(event.data)
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onExpandOrCollapseAll: () => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'organization' && colDef.field !== 'name') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    expandAllNodes('expand')
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('unitsGrid', 'colLayout', colLayout)
    }
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('unitsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('unitsGrid', 'colLayout', null)
          saveItemToLS('unitsGridGroupRows', 'groupRows', !groupRows)
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'UnitData.xlsx',
            sheetName: 'Units',
            columnKeys: [
              'organization',
              'name',
              'depth',
              'diameter',
              'dogleg',
              'flow',
              'pressure',
              'volume',
              'mud',
              'weight',
              'unitWeight',
              'cost',
              'area',
              'temp',
              'torque',
              'latLong',
              'bendingMoment',
              'force',
            ],
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    let requiredFields = ['organization', 'name']
    for (let i = 0; i < requiredFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredFields[i])) return false
    }

    return true
  }

  const handleDelete = async () => {
    if (!confirm.data) return
    if (!confirm.data.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true

    let res = await deleteData(confirm.data)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [confirm.data],
      })
    }
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    inputRow.current.depth = 'usft'
    inputRow.current.diameter = 'inches'
    inputRow.current.dogleg = '100'
    inputRow.current.flow = 'gpm'
    inputRow.current.pressure = 'pressure'
    inputRow.current.volume = 'volume'
    inputRow.current.mud = 'ppg'
    inputRow.current.weight = 'klbs'
    inputRow.current.unitWeight = 'ppf'
    inputRow.current.cost = '$'
    inputRow.current.area = 'sqin'
    inputRow.current.temp = 'degf'
    inputRow.current.torque = 'kftlbs'
    inputRow.current.latLong = 'deg'
    inputRow.current.bendingMoment = 'ftlbf'
    inputRow.current.force = 'lbf'

    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addData(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return

    data = htmlSymbolHandling(data)

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateData(data)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' && !groupRows ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '25px' }}>
              {params.node.rowIndex + 1}
            </Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const getDropDown = useCallback(
    (paramName) => {
      if (!paramName) return []
      if (typeof paramName !== 'string') return []
      if (paramName === '') return []

      if (typeof dropDowns !== 'object') return []
      if (!dropDowns.hasOwnProperty(paramName)) return []
      if (!Array.isArray(dropDowns[paramName])) return []

      return dropDowns[paramName].map((unit) => unit.label)
    },
    [dropDowns],
  )

  const GroupRowInnerRenderer = (props) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}>
          <Avatar
            alt={props.node.key}
            sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
            src={getCompanyIcon(props.node.key)}
          />
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
      </Box>
    )
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    checkbox: false,
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'icon',
        colId: 'icon',
        headerName: '',
        width: 60,
        hide: groupRows,
        resizable: false,
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: (params) => {
          if (!params) return null
          if (!params.data) return null
          if (typeof params.data?.organization !== 'string') return null

          return (
            <Avatar
              alt={params?.data?.organization}
              sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
              src={getCompanyIcon(params?.data?.organization)}
            />
          )
        },
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
        rowGroup: groupRows,
        editable: (params) => params?.node?.rowPinned === 'bottom',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(orgList) ? orgList : [],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        valueGetter: (params) => unescapeHtml(params.data?.name),
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'depth',
        colId: 'depth',
        headerName: 'Depth',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('depth'),
          }
        },
      },
      {
        field: 'diameter',
        colId: 'diameter',
        headerName: 'Diameter',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('diameter'),
          }
        },
      },
      {
        field: 'dogleg',
        colId: 'dogleg',
        headerName: 'Dogleg',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('dogleg'),
          }
        },
      },
      {
        field: 'flow',
        colId: 'flow',
        headerName: 'Flow',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('flow'),
          }
        },
      },
      {
        field: 'pressure',
        colId: 'pressure',
        headerName: 'Pressure',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('pressure'),
          }
        },
      },
      {
        field: 'volume',
        colId: 'volume',
        headerName: 'Volume',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('volume'),
          }
        },
      },
      {
        field: 'mud',
        colId: 'mud',
        headerName: 'Mud',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('mud'),
          }
        },
      },
      {
        field: 'weight',
        colId: 'weight',
        headerName: 'Weight',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('weight'),
          }
        },
      },
      {
        field: 'unitWeight',
        colId: 'unitWeight',
        headerName: 'Unit Weight',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('unitWeight'),
          }
        },
      },
      {
        field: 'cost',
        colId: 'cost',
        headerName: 'Cost',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('cost'),
          }
        },
      },
      {
        field: 'area',
        colId: 'area',
        headerName: 'Area',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('area'),
          }
        },
      },
      {
        field: 'temp',
        colId: 'temp',
        headerName: 'Temperature',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('temp'),
          }
        },
      },
      {
        field: 'torque',
        colId: 'torque',
        headerName: 'Torque',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('torque'),
          }
        },
      },
      {
        field: 'latLong',
        colId: 'latLong',
        headerName: 'Lat/Long',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('latLong'),
          }
        },
      },
      {
        field: 'bendingMoment',
        colId: 'bendingMoment',
        headerName: 'Bending Moment',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('bendingMoment'),
          }
        },
      },
      {
        field: 'force',
        colId: 'force',
        headerName: 'Force',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDown('force'),
          }
        },
      },
    ],
    [orgList, getCompanyIcon, getDropDown, groupRows], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
    setConfirm({
      show: true,
      title: 'Delete UnitSet',
      text: `Are you sure you want to delete ${data.name}?`,
      data: data,
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={unitsData}
            columnDefs={sortColDefs(columnDefs, 'unitsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            groupRowRendererParams={groupRowRendererParams}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default UnitsPage
