import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const SurveyTieOnGrid = ({
  tieOnData,
  handleUpdate,
  units,
  tieOnSurvey = null,
  deltaElevation,
  localCoordOffset,
  sideTrackDepth = 0,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getAgGridTheme, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'md',
        colId: 'md',
        headerName: `MD (${units})`,
        valueFormatter: (params) => numberWithCommasDecimals(params?.value, 2),
        editable: (params) => tieOnData?.inputMeth === 'SURVEY' || tieOnData?.inputMeth === 'USER',
        cellEditorParams: {
          min: sideTrackDepth,
          max: 100000,
          precision: 2,
        },
      },
      {
        field: 'inc',
        colId: 'inc',
        headerName: 'INC',
        cellEditorParams: {
          min: 0,
          max: 120,
          precision: 2,
        },
        valueFormatter: (params) => numberWithCommasDecimals(params?.value, 2),
        editable: (params) => tieOnData?.inputMeth === 'SURFACE' || tieOnData?.inputMeth === 'USER',
      },
      {
        field: 'azi',
        colId: 'azi',
        headerName: 'AZI',
        cellEditorParams: {
          min: 0,
          max: 360,
          precision: 2,
        },
        valueFormatter: (params) => numberWithCommasDecimals(params?.value, 2),
        editable: (params) => tieOnData?.inputMeth === 'SURFACE' || tieOnData?.inputMeth === 'USER',
      },
      {
        field: 'tvd',
        colId: 'tvd',
        headerName: `TVD(${units})`,
        valueFormatter: (params) => numberWithCommasDecimals(params?.value, 2),
        editable: (params) => tieOnData?.inputMeth === 'USER',
      },
      {
        field: 'ns',
        colId: 'ns',
        headerName: `NS (${units})`,
        valueFormatter: (params) => {
          if (!params.value) return `0.00`
          let ns = params?.value
          if (tieOnData?.inputMeth !== 'USER') ns += localCoordOffset?.localNorth ? localCoordOffset?.localNorth : 0
          return numberWithCommasDecimals(ns, 3)
        },
        editable: (params) => tieOnData?.inputMeth === 'USER',
      },
      {
        field: 'ew',
        colId: 'ew',
        headerName: `EW (${units})`,
        valueFormatter: (params) => {
          if (!params.value) return `0.00`
          let ew = params?.value
          if (tieOnData?.inputMeth !== 'USER') ew += localCoordOffset?.localEast ? localCoordOffset?.localEast : 0
          return numberWithCommasDecimals(ew, 3)
        },
        editable: (params) => tieOnData?.inputMeth === 'USER',
      },
    ],
    [tieOnData, units, localCoordOffset, sideTrackDepth],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: true,
      suppressHeaderMenuButton: false,
      cellStyle: centerAlignCell,
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
      headerClass: 'header-no-padding',
    }
  }, [centerAlignCell])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = () => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getTieOnData = (data) => {
    let tieOn = [
      {
        id: 0,
        md: data?.md,
        inc: data?.inc,
        azi: data?.azi,
        tvd: data?.tvd,
        ns: data?.ns,
        ew: data?.ew,
      },
    ]

    return tieOn
  }

  const getTieOnSurveyText = () => {
    if (!tieOnSurvey) return ''
    return `Well: ${tieOnSurvey?.wellName}, Survey: ${tieOnSurvey?.survey}, Depth: ${numberWithCommasDecimals(
      tieOnSurvey?.depthFrom + deltaElevation,
      2,
    )} - ${numberWithCommasDecimals(tieOnSurvey?.depthTo + deltaElevation, 2)} ${units}`
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box sx={verticalTextStyle}>Survey Tie On</Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div ref={containerRef} style={{ height: 'calc(100% - 25px)', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            className='ag-grid-theme-dark'
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            rowData={getTieOnData(tieOnData)}
            headerHeight={30}
            onFirstDataRendered={onFirstDataRendered}
            getRowId={getRowId}
          />
        </div>
        <Box
          sx={{
            width: '100%',
            height: '25px',
            borderLeft: '1px solid gray',
            borderRight: '1px solid gray',
            backgroundColor: getWindowBarColor(),
            color: 'white',
            paddingLeft: '10px',
          }}>
          {getTieOnSurveyText()}
        </Box>
      </Box>
    </Box>
  )
}

export default SurveyTieOnGrid
