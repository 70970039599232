// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react'
import { atom, useRecoilState } from 'recoil'
import { Buffer } from 'buffer'

import { multiParse } from 'utils'
import useInnovaAxios from './useInnovaAxios'
import { removeFileExt } from 'utils/stringFunctions'

export const weatherBackgroundsAtom = atom({
  key: 'weatherBackgroundsAtomKey',
  default: [],
})

var _isInitialized = false // there can be only one

const useWeatherBackgrounds = () => {
  const _isMounted = useRef(false)
  const [weatherImgs, setWeatherImgs] = useRecoilState(weatherBackgroundsAtom)

  const getAllWeatherImgs = useInnovaAxios({
    url: '/assets/getWeatherBackgrounds',
  })

  useEffect(() => {
    _isMounted.current = true
    if (!_isInitialized) {
      _isInitialized = true
      fetchWeatherImgs()
    }
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWeatherImgs = async () => {
    const response = await getAllWeatherImgs()
    if (response?.data) {
      const mfpBoundary = response.data.substring(2, response.data.search('Content')).trim()
      let parts = multiParse(Buffer.from(response.data), mfpBoundary)
      let allWeatherImgs = parts.map((part) => {
        let imgObj = { ...part }
        imgObj.data = 'data:image/*;base64,' + part.data
        imgObj.filename = removeFileExt(part.filename)
        return imgObj
      })
      if (_isMounted.current) {
        setWeatherImgs(allWeatherImgs)
      }
    } else {
      _isInitialized = false
    }
  }

  const getWeatherImage = (weatherType) => {
    if (_isInitialized && weatherImgs && Array.isArray(weatherImgs)) {
      let weatherIdx = weatherImgs.findIndex((element) => element.filename === weatherType)
      return weatherImgs[weatherIdx]?.data
    } else {
      return ''
    }
  }

  return { getWeatherImage }
}

export default useWeatherBackgrounds
