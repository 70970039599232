// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import { Buffer } from 'buffer'
import { multiParse } from 'utils'
import useInnovaAxios from './useInnovaAxios'
import BlankImage from 'assets/NoImage.png'
import { cloneDeep } from 'lodash'

const uploadUrl = '/well/drillString/uploadMotorReportImages'
const deleteUrl = '/well/drillString/deleteMotorReportImages'

const BLANK_IMAGE_OBJ = {
  file: new File([''], 'empty', { type: 'text/plain' }),
  previewImage: BlankImage,
  isBlank: true,
  fileName: ''
}

const useMotorReportImages = (bha, well, numImages = 10) => {
  const bhaNumRef = useRef(bha)
  const wellNameRef = useRef(well)
  const [bitImages, setBitImages] = useState([])
  const _isMounted = useRef(false)
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  const isFetchingImages = useRef(false)
  const isUploadingImages = useRef(false)
  const isDeletingImages = useRef(false)
  const [loading, setLoading] = useState(false)
  const [imageObjects, setImageObjects] = useState(
    new Array(numImages).fill(cloneDeep(BLANK_IMAGE_OBJ)),
  )

  const getMotorReportImages = useInnovaAxios({
    url: '/well/drillString/getMotorReportImages',
  })

  const getBitImages = useInnovaAxios({
    url: '/well/drillString/getBitImageFileNames',
  })

  const toggleBitImages = useInnovaAxios({
    url: '/well/drillString/toggleSetImageAsBit',
  })


  useEffect(() => {
    _isMounted.current = true
    fetchBitImages(bhaNumRef.current, wellNameRef.current)
    fetchImages()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBitImages = async (bhaNum, wellName) => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (typeof bhaNum !== 'number') return

    const res = await getBitImages({
      wellName: wellName,
      bhaNum: bhaNum,
    })

    if (res?.error) {
      setBitImages([])
      return
    }

    if (!Array.isArray(res.data)) {
      setBitImages([])
      return
    }

    setBitImages(cloneDeep(res.data))
  }

  const setBitImage = async (bhaNum, wellName, fileName, isBitImage) => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (!fileName) return
    if (typeof fileName !== 'string') return
    if (wellName === '') return
    if (typeof bhaNum !== 'number') return

    const res = await toggleBitImages({
      wellName: wellName,
      bhaNum: bhaNum,
      fileName: fileName,
      isBitImage: isBitImage
    })

    if (res?.error) {
      setBitImages([])
      return
    }

    if (!Array.isArray(res.data)) {
      setBitImages([])
      return
    }

    setBitImages(cloneDeep(res.data))
  }

  const fetchImagesManual = async (bhaNum, wellName) => {
    if (!wellName) return []
    if (typeof wellName !== 'string') return []
    if (wellName === '') return []
    if (typeof bhaNum !== 'number') return []
    if (isFetchingImages.current) return []

    isFetchingImages.current = true
    setLoading(true)
    const res = await getMotorReportImages({
      wellName: wellName,
      bhaNum: bhaNum,
    })

    isFetchingImages.current = false
    if (_isMounted.current) setLoading(false)

    if (res.error) return []
    if (!res.data) return []

    const mfpBoundary = res.data.substring(2, res.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(res.data), mfpBoundary)

    if (!Array.isArray(parts)) return []
    return parts.map((part) => {
      let imageObj = { ...part }
      imageObj.data = 'data:image/*;base64,' + part.data

      return imageObj
    })
  }

  const fetchImages = async () => {
    let images = await fetchImagesManual(bhaNumRef.current, wellNameRef.current)

    let newImages = []
    let numDownloadedImages = 0
    if (Array.isArray(images)) {
      numDownloadedImages = images.length
      images.forEach((image) => {
        newImages.push({
          file: new File([image.data], image.filename, { type: image.type }),
          previewImage: image.data,
          fileName: image?.filename,
          isBlank: false
        })
      })
    }

    for (let i = numDownloadedImages; i < numImages; i++) {
      newImages.push(cloneDeep(BLANK_IMAGE_OBJ))
    }

    setImageObjects(newImages)
  }

  const uploadImages = async (replaceFileNames, files) => {
    if (isUploadingImages.current) return false
    if (!files && !Array.isArray(files)) return false
    let formData = new FormData()

    isUploadingImages.current = true
    setLoading(true)
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: uploadUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('wellName', wellNameRef.current)
    formData.append('bhaNum', bhaNumRef.current)
    formData.append('replacedFiles', replaceFileNames)

    files.forEach((file) => {
      formData.append('file', file)
    })

    const response = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    isUploadingImages.current = false

    if (response.error) {
      if (_isMounted.current) setLoading(false)
      return false
    }

    if (_isMounted.current) setLoading(false)
    return true
  }

  const deleteImages = async (deletedFileNames) => {
    if (isDeletingImages.current) return
    if (!deletedFileNames) return
    if (!Array.isArray(deletedFileNames)) return
    if (deletedFileNames.length === 0) return
    let formData = new FormData()

    isDeletingImages.current = true
    setLoading(true)
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: deleteUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('wellName', wellNameRef.current)
    formData.append('bhaNum', bhaNumRef.current)
    formData.append('deletedFiles', deletedFileNames)

    const response = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    isDeletingImages.current = false

    if (response.error) {
      if (_isMounted.current) setLoading(false)
      return false
    }

    await fetchBitImages(bhaNumRef.current, wellNameRef.current)
    await refreshImages()
    if (_isMounted.current) setLoading(false)

    return true
  }

  const refreshImages = async () => {
    await fetchImages()
  }

  return {
    refreshImages,
    fetchImagesManual,
    uploadImages,
    deleteImages,
    imageObjects,
    loading,
    setBitImage,
    bitImages
  }
}

export default useMotorReportImages
