import React, { useRef } from 'react'
import Multiselect from 'multiselect-react-dropdown'
import { saveItemToLS } from 'utils/localStorage'

const MultiSelectEditor = (props) => {
  const refInput = useRef(null)

  return (
    <div className='ag-theme-alpine-dark' style={{ width: '320px', height: '32px' }}>
      <Multiselect
        ref={refInput}
        options={props.data}
        singleSelect={false}
        selectedValues={props.selectedOffsets}
        hideSelectedList
        placeholder={props.selectedOffsets.length > 0 ? `${props.selectedOffsets.length} ${props.selectedOffsets.length === 1 ? 'Offset' : 'Offsets'} Selected` : 'No Offsets Selected'}
        onSelect={(data) => {
            props.setSelectedOffsets(data)
            saveItemToLS(props.wellName, 'selectedOffsets', data)
        }}
        onRemove={(data) => {
            props.setSelectedOffsets(data)
            saveItemToLS(props.wellName, 'selectedOffsets', data)
        }}
        displayValue={'label'}
        showCheckbox={true}
        style={{
          chips: {
            background: '#274260',
            borderRadius: '5px',
          },
          multiselectContainer: { width: '100%' },
          option: {
            backgroundColor: '#2d2d2d',
          },
        }}
        showArrow={true}
      />
    </div>
  )
}

export default MultiSelectEditor
