import React, { useEffect, useRef, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DataAcqHealthDetailsGrid = ({ id, data }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS(id ? id : 'dataCacheDetail', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS(id ? id : 'dataCacheDetail', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DataCacheTimestampDetail.xlsx',
            sheetName: 'Querys',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ];
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        colId: id ? `${id}detailId` : 'detailId',
        headerName: '#',
        sortable: false,
        filter: null,
        valueGetter: (params) => params?.node?.rowIndex + 1,
      },
      {
        colId: id ? `${id}rawDetail` : 'rawDetail',
        headerName: 'Raw Cache',
        children: [
          {
            headerName: 'Time Stamp',
            colId: 'rawCacheTimeStamp',
            field: 'rawCache.timeStamp',
            cellStyle: centerAlignCell,
            filter: null,
          },
          {
            headerName: 'Duration',
            colId: 'rawCacheElapsedTime',
            field: 'rawCache.elapsedTime',
            cellStyle: centerAlignCell,
          },
        ],
        minWidth: 100,
      },
      {
        colId: id ? `${id}dec02Detail` : 'dec02Detail',
        headerName: '02 Mins',
        cellStyle: centerAlignCell,
        children: [
          {
            headerName: 'Time Stamp',
            colId: 'dec02CacheTimeStamp',
            field: 'dec02Cache.timeStamp',
            cellStyle: centerAlignCell,
            filter: null,
          },
          {
            headerName: 'Duration',
            colId: 'dec02CacheElapsedTime',
            field: 'dec02Cache.elapsedTime',
            cellStyle: centerAlignCell,
          },
        ],
        minWidth: 100,
      },
      {
        colId: id ? `${id}dec05Detail` : 'dec05Detail',
        headerName: '05 Mins',
        cellStyle: centerAlignCell,
        children: [
          {
            headerName: 'Time Stamp',
            colId: 'dec05CacheTimeStamp',
            field: 'dec05Cache.timeStamp',
            cellStyle: centerAlignCell,
            filter: null,
          },
          {
            headerName: 'Duration',
            colId: 'dec05CacheElapsedTime',
            field: 'dec05Cache.elapsedTime',
            cellStyle: centerAlignCell,
          },
        ],
        minWidth: 100,
      },
      {
        colId: id ? `${id}dec10Detail` : 'dec10Detail',
        headerName: '10 Mins',
        cellStyle: centerAlignCell,
        children: [
          {
            headerName: 'Time Stamp',
            colId: 'dec10CacheTimeStamp',
            field: 'dec10Cache.timeStamp',
            cellStyle: centerAlignCell,
            filter: null,
          },
          {
            headerName: 'Duration',
            colId: 'dec10CacheElapsedTime',
            field: 'dec10Cache.elapsedTime',
            cellStyle: centerAlignCell,
          },
        ],
        minWidth: 100,
      },
    ],
    [centerAlignCell, id],
  )

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        
        rowData={Array.isArray(data) ? data : []}
        columnDefs={sortColDefs(columnDefs, id ? id : 'dataCacheDetail')}
        defaultColDef={defaultColDef}
        animateRows={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default DataAcqHealthDetailsGrid
