import React, { useState, useMemo } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'
import TextField from '@mui/material/TextField'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import DropDownPicker from 'components/common/DropDownPicker'
import { appColors } from 'utils'

const IPMRenameModal = ({ isOpen, onClose, onRename, ipm, ipms }) => {
  const [newName, setNewName] = useState('')
  const handleRename = () => {
    onRename(ipm.fileName, newName)
    onClose()
  }
  const { theme, getAgGridTheme } = useInnovaTheme()

  const textFieldStyle = useMemo(
    () => ({
      height: '50px',
      margin: '5px',
      backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
      width: '100%',
      maxWidth: '400px',
    }),
    [theme],
  )

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        PaperProps={{
          sx: {
            height: '300px',
            width: '450px',
            backgroundColor: 'itemBackground',
          },
        }}
        open={isOpen}
        onClose={onClose}>
        <DialogTitle>Swap IPM</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              className: getAgGridTheme(),
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              height: '100%',
              color: theme === 'dark' ? appColors.headerTextColor : '#000',
            }}>
            Name
            <TextField sx={textFieldStyle} fullWidth value={ipm.fileName} />
            New Name
            <Box
              sx={{
                width: '400px',
                marginBottom: '10px',
                marginLeft: '5px',
                paddingTop: '5px',
              }}>
              <DropDownPicker
                listOptions={ipms?.map((ipm) => {
                  return { label: ipm.fileName, value: ipm.fileName }
                })}
                value={newName}
                onChange={(value) => setNewName(value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={onClose} color='secondary'>
            Cancel
          </Button>
          <Button variant='outlined' onClick={handleRename} color='primary'>
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default IPMRenameModal
