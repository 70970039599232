import React, { useEffect, useState, useRef } from 'react'
import { appColors } from 'utils'
import { Box, CircularProgress, Checkbox } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { TreeView } from '@mui/lab'
import { SvgIcon } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import wellIcon from 'assets/wellScreenIcons/wellHeadGrey.png'
import wellBoreIcon from 'assets/wellScreenIcons/schematicGrey.png'
import trajIcon from 'assets/wellScreenIcons/surveys-grey.png'
import logIcon from 'assets/wellScreenIcons/edr-grey.png'
import { cloneDeep } from 'lodash'
import TreeNode from './TreeNode'
import testTreeData from './testWitsmlData'

const TREE_ICON_SIZE = 20
const TREE_ICON_PADDING = 10
const USE_TEST_DATA = false

const MinusSquare = (props) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  )
}

const PlusSquare = (props) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  )
}

const bfsSearch = (graph, targetId) => {
  const queue = [...graph]

  while (queue.length > 0) {
    const currNode = queue.shift()
    if (currNode.nodeId === targetId) {
      return currNode
    }
    if (currNode.children) {
      queue.push(...currNode.children)
    }
  }
  return []
}

const WitsmlServerTree = ({
  witsmlServer,
  setStatus,
  selectedNodes,
  setSelectedNodes,
  setSelectedLog,
  selectedConfig,
  setSelectedCurveInfo,
  selectedCurveInfo,
}) => {
  const _isMounted = useRef(false)
  const initialSelectionSet = useRef(false)
  const isLoadingRef = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState(USE_TEST_DATA ? testTreeData : [])
  const [expandedNodes, setExpandedNodes] = useState([witsmlServer ? witsmlServer.witsmlServer : 'WITSML server'])

  const getWitsmlWells = useInnovaAxios({
    url: '/dataAcq/witsml/getWells',
  })

  const getWitsmlWellBore = useInnovaAxios({
    url: '/dataAcq/witsml/getWellBores',
  })

  const getWitsmlLogs = useInnovaAxios({
    url: '/dataAcq/witsml/getLogs',
  })

  const getWitsmlTraj = useInnovaAxios({
    url: '/dataAcq/witsml/getTrajectorys',
  })

  useEffect(() => {
    _isMounted.current = true
    if (!USE_TEST_DATA) fetchWitsmlWells()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const setInitialSelection = () => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return
    if (!selectedConfig) return

    initialSelectionSet.current = true

    let initalSelection = []
    let initialExpanded = [getRootNodeId()]

    let wellNodeId = ''
    if (selectedConfig?.witsmlWell?.uuid && selectedConfig?.witsmlWell?.uuid !== '') {
      wellNodeId = selectedConfig?.witsmlWell?.uuid + '-well'
      let node = bfsSearch(data, wellNodeId)
      if (!node?.uuid) return

      initalSelection.push({
        nodeId: wellNodeId,
        uuid: selectedConfig?.witsmlWell?.uuid,
        level: 'well',
        name: selectedConfig?.witsmlWell?.wellName,
      })
    }

    if (!selectedConfig?.witsmlWellBore?.uuid && !selectedConfig?.witsmlWellBore?.uuid === '') {
      setSelectedNodes(initalSelection)
      setExpandedNodes(initialExpanded)
    }

    let treeData = cloneDeep(data)
    let wellBoreNodeId = selectedConfig?.witsmlWellBore?.uuid + '-wellBore'

    initalSelection.push({
      nodeId: wellBoreNodeId,
      uuid: selectedConfig?.witsmlWellBore?.uuid,
      level: 'wellBore',
      name: selectedConfig?.witsmlWellBore?.wellBoreName,
    })

    initialExpanded.push(wellNodeId)

    let parentWellIndex = treeData.findIndex((n) => n.nodeId === wellNodeId)
    if (parentWellIndex < 0) return

    treeData[parentWellIndex].children.push({
      children: [],
      level: 'wellBore',
      name: selectedConfig?.witsmlWellBore.wellBoreName,
      uuid: selectedConfig?.witsmlWellBore.uuid,
      parentUuid: selectedConfig?.witsmlWell?.uuid,
      parentNodeId: selectedConfig?.witsmlWell?.uuid + '-well',
      nodeId: wellBoreNodeId,
    })

    let hasLog = false
    let hasTraj = false
    if (selectedConfig?.witsmlLogs?.uuid && selectedConfig?.witsmlLogs?.uuid !== '') {
      hasLog = true
    }

    if (selectedConfig?.witsmlTrajectory?.uuid && selectedConfig?.witsmlTrajectory?.uuid !== '') {
      hasTraj = true
    }

    if (hasLog) {
      let logNodeId = selectedConfig?.witsmlLogs?.uuid + '-log'
      initalSelection.push({
        nodeId: logNodeId,
        uuid: selectedConfig?.witsmlLogs?.uuid,
        level: 'log',
        name: selectedConfig?.witsmlLogs?.logName,
      })
    }

    if (hasTraj) {
      let trajNodeId = selectedConfig?.witsmlTrajectory?.uuid + '-traj'
      initalSelection.push({
        nodeId: trajNodeId,
        uuid: selectedConfig?.witsmlTrajectory?.uuid,
        level: 'traj',
        name: selectedConfig?.witsmlTrajectory?.name,
      })
    }

    setSelectedNodes(initalSelection, false)
    setExpandedNodes(initialExpanded)
    setData(treeData)
  }

  useEffect(() => {
    if (!initialSelectionSet.current) setInitialSelection()
    if (!selectedCurveInfo) {
      let selectedLogIndex = selectedNodes.findIndex((n) => n.level === 'log')
      if (selectedLogIndex < 0) return
      setCurveInfo(selectedNodes[selectedLogIndex].nodeId)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const showError = (msg) => {
    setStatus({
      show: true,
      severity: 'error',
      message: `${msg}`,
    })
  }

  const setCurveInfo = (nodeId) => {
    if (nodeId === undefined || nodeId === null) {
      setSelectedCurveInfo(null)
    }

    let node = bfsSearch(data, nodeId)
    if (node?.uuid === undefined || node?.uuid === null) {
      setSelectedCurveInfo(null)
      return
    }

    setSelectedCurveInfo(node)
  }

  const setLogInfo = (nodeId) => {
    if (nodeId === undefined || nodeId === null) {
      let index = selectedNodes.findIndex((n) => n.level === 'log')
      if (index < 0) {
        setSelectedLog(null)
        return
      }

      nodeId = selectedNodes[index].nodeId
    }

    let node = bfsSearch(data, nodeId)
    if (node?.uuid === undefined || node?.uuid === null) {
      setSelectedLog(null)
      return
    }

    setSelectedLog(node)
  }

  const fetchWitsmlWells = async () => {
    if (!_isMounted.current) return
    if (!witsmlServer) return
    if (isLoadingRef.current) return
    setLoading(true)

    isLoadingRef.current = true
    let res = await getWitsmlWells(witsmlServer)
    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      setData([])
      return
    }

    if (!res.data) return
    if (!res.data?.data?.success) {
      showError(res.data.data.errors)
      setData([])
      return
    }

    let treeData = []
    const { wells } = res.data.data
    if (!Array.isArray(wells)) return
    for (let i = 0; i < wells.length; i++) {
      if (wells[i].wellName === '') continue
      treeData.push({
        children: [],
        level: 'well',
        name: wells[i].wellName,
        uuid: wells[i].uuid,
        nodeId: wells[i].uuid + '-well',
      })
    }

    treeData.sort((a, b) => a.name.localeCompare(b.name))
    setData(treeData)
  }

  const fetchWitsmlWellBore = async (nodeId) => {
    if (!_isMounted.current) return
    if (!witsmlServer) return
    if (isLoadingRef.current) return

    let node = bfsSearch(data, nodeId)
    if (node?.uuid === undefined) return

    setLoading(true)

    isLoadingRef.current = true
    let res = await getWitsmlWellBore({ ...witsmlServer, wellUuid: node.uuid })
    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      showError(res?.error?.response?.data?.error)

      return
    }

    if (!res.data) return
    if (!res.data?.data?.success) {
      showError(res.data.data.errors)
      return
    }

    let treeData = cloneDeep(data)
    const { wellBores } = res.data.data

    if (!Array.isArray(wellBores)) return

    let parentIndex = treeData.findIndex((n) => n.nodeId === nodeId)
    if (parentIndex < 0) return

    for (let i = 0; i < wellBores.length; i++) {
      if (wellBores[i].wellBoreName === '') continue
      treeData[parentIndex].children.push({
        children: [],
        level: 'wellBore',
        name: wellBores[i].wellBoreName,
        uuid: wellBores[i].uuid,
        parentUuid: wellBores[i].wellUuid,
        parentNodeId: wellBores[i].wellUuid + '-well',
        nodeId: wellBores[i].uuid + '-wellBore',
      })
    }

    setData(treeData)
  }

  const fetchWitsmlTrajLogs = async (nodeId) => {
    if (!_isMounted.current) return
    if (!witsmlServer) return
    if (isLoadingRef.current) return

    let node = bfsSearch(data, nodeId)
    if (node?.uuid === undefined) return
    setLoading(true)

    isLoadingRef.current = true
    let payload = { ...witsmlServer, wellUuid: node.parentUuid, wellBoreUuid: node.uuid }

    let resLog = await getWitsmlLogs(payload)
    let resTraj = await getWitsmlTraj(payload)
    isLoadingRef.current = false

    if (!_isMounted.current) return
    if (resLog?.error) {
      showError(resLog?.error?.response?.data?.error)
      return
    }

    if (resTraj?.error) {
      showError(resTraj?.error?.response?.data?.error)
      return
    }

    setLoading(false)

    if (!resLog.data?.data?.success) {
      showError(resLog.data.data.errors)
      return
    }

    if (!resTraj.data?.data?.success) {
      showError(resTraj.data.data.errors)
      return
    }

    let treeData = cloneDeep(data)
    let wellIndex = treeData.findIndex((n) => n.nodeId === node.parentNodeId)
    if (wellIndex < 0) return

    let wellBoreIndex = treeData[wellIndex].children.findIndex((n) => n.nodeId === nodeId)
    if (wellBoreIndex < 0) return

    const { logs } = resLog.data.data
    const { trajectories } = resTraj.data.data

    if (Array.isArray(logs)) {
      for (let i = 0; i < logs.length; i++) {
        if (logs[i].logName === '') continue
        treeData[wellIndex].children[wellBoreIndex].children.push({
          children: [],
          level: 'log',
          name: logs[i].logName,
          parentUuid: logs[i].wellBoreUuid,
          parentNodeId: logs[i].wellBoreUuid + '-wellBore',
          nodeId: `${logs[i].uuid}-log`,
          ...cloneDeep(logs[i]),
        })
      }
    }

    if (Array.isArray(trajectories)) {
      for (let i = 0; i < trajectories.length; i++) {
        if (trajectories[i].name === '') continue
        treeData[wellIndex].children[wellBoreIndex].children.push({
          children: [],
          level: 'traj',
          name: trajectories[i].name,
          uuid: trajectories[i].uuid,
          parentUuid: trajectories[i].wellBoreUuid,
          parentNodeId: trajectories[i].wellBoreUuid + '-wellBore',
          nodeId: `${trajectories[i].uuid}-traj`,
        })
      }
    }

    setData(treeData)
  }

  const handleToggleNodeExpanded = (nodeId) => {
    let newNodes = [...expandedNodes]
    if (newNodes.includes(nodeId)) {
      newNodes.splice(newNodes.indexOf(nodeId), 1)
    } else {
      newNodes.push(nodeId)
    }

    setExpandedNodes(newNodes)
  }

  function getAllIds(node, idList = []) {
    idList.push(node.uuid)
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList))
    }
    return idList
  }

  const getAllChildNodes = (id) => {
    return getAllIds(bfsSearch(data, id))
  }

  const getParentNodes = (id, list = []) => {
    const node = bfsSearch(data, id)
    if (node.parentNodeId !== undefined && node.parentNodeId !== null && node.parentNodeId !== '') {
      list.push(node.parentNodeId)
      return getParentNodes(node.parentNodeId, list)
    }

    return list
  }

  const isNodeChecked = (nodeId) => {
    if (!Array.isArray(selectedNodes)) return false
    return selectedNodes.findIndex((n) => n.nodeId === nodeId) >= 0
  }

  const isNodeCheckedAtLevel = (level) => {
    if (!Array.isArray(selectedNodes)) return false
    return selectedNodes.findIndex((n) => n.level === level) >= 0
  }

  const getChildLevels = (level) => {
    if (level === 'well') return ['well', 'wellBore', 'traj', 'log']
    if (level === 'wellBore') return ['wellBore', 'traj', 'log']
    if (level === 'traj') return ['traj']
    if (level === 'log') return ['log']
    return []
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation()

    //If node is checked unselect node and all children
    if (isNodeChecked(nodeId)) {
      let prevSelectedNodes = cloneDeep(selectedNodes)
      if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

      let index = prevSelectedNodes.findIndex((n) => n.nodeId === nodeId)
      if (index >= 0) prevSelectedNodes.splice(index, 1)

      const childNodes = getAllChildNodes(nodeId)
      for (let i = 0; i < childNodes.length; i++) {
        if (!isNodeChecked(childNodes[i])) continue
        let index = prevSelectedNodes.findIndex((n) => n.nodeId === childNodes[i])
        if (index < 0) continue
        prevSelectedNodes.splice(index, 1)
      }

      setSelectedNodes(prevSelectedNodes)
      return
    }

    //If node is unchecked the check all parents
    let node = bfsSearch(data, nodeId)
    if (node?.nodeId === undefined || node?.nodeId === null || node?.nodeId === '') return

    let prevSelectedNodes = cloneDeep(selectedNodes)
    if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

    //If node alread selectd at current level uncheck and uncheck all its children
    if (isNodeCheckedAtLevel(node.level)) {
      let levelsToRemove = getChildLevels(node.level)
      prevSelectedNodes = prevSelectedNodes.filter((n) => levelsToRemove.findIndex((l) => l === n.level) < 0)
    }

    prevSelectedNodes.push({ nodeId: node.nodeId, uuid: node.uuid, level: node.level, name: node.name })

    const parentNodes = getParentNodes(nodeId)
    for (let i = 0; i < parentNodes.length; i++) {
      let node = bfsSearch(data, parentNodes[i])
      if (!node) continue
      if (prevSelectedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0) continue

      prevSelectedNodes.push({ nodeId: node.nodeId, uuid: node.uuid, level: node.level, name: node.name })
    }

    setSelectedNodes(prevSelectedNodes)
  }

  const getRootNodeId = () => {
    return witsmlServer ? witsmlServer.witsmlServer : 'WITSML server'
  }

  const isNodeExpanded = (id) => {
    return expandedNodes.includes(id)
  }

  const TreeNodeIcon = ({ nodeId, icon }) => {
    return (
      <React.Fragment>
        <Checkbox
          sx={{ margin: 0, padding: 0 }}
          checked={selectedNodes.findIndex((n) => n.nodeId === nodeId) >= 0}
          tabIndex={-1}
          disableRipple
          onClick={(event) => {
            if (!isNodeChecked(nodeId) && nodeId.includes('-log')) {
              setCurveInfo(nodeId)
            }

            if (isNodeChecked(nodeId) && nodeId.includes('-log')) {
              setCurveInfo(null)
            }

            handleNodeSelect(event, nodeId)
          }}
        />
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${TREE_ICON_SIZE}px`,
            height: `${TREE_ICON_SIZE}px`,
            paddingRight: `${TREE_ICON_PADDING}px`,
          }}
        />
      </React.Fragment>
    )
  }

  const TrajectoryNodes = ({ wellBoreChildren }) => {
    return Array.isArray(wellBoreChildren)
      ? wellBoreChildren.map((child) => {
          return child.level === 'traj' ? (
            <TreeNode
              key={`${child.nodeId}`}
              nodeId={`${child.nodeId}`}
              labelText={child.name}
              labelIcon={<TreeNodeIcon nodeId={child.nodeId} icon={trajIcon} />}
              onLabelClick={() => setLogInfo(null)}
            />
          ) : null
        })
      : null
  }

  const LogNodes = ({ wellBoreChildren }) => {
    return Array.isArray(wellBoreChildren)
      ? wellBoreChildren.map((child) => {
          return child.level === 'log' ? (
            <TreeNode
              key={`${child.nodeId}`}
              nodeId={`${child.nodeId}`}
              labelText={child.name}
              labelIcon={<TreeNodeIcon nodeId={child.nodeId} icon={logIcon} />}
              onLabelClick={() => setLogInfo(child.nodeId)}
            />
          ) : null
        })
      : null
  }

  const WellBoreNodes = ({ wellBores }) => {
    return Array.isArray(wellBores)
      ? wellBores.map((wellbore) => (
          <TreeNode
            key={`${wellbore.nodeId}`}
            nodeId={`${wellbore.nodeId}`}
            endIcon={<PlusSquare />}
            labelText={wellbore.name}
            labelIcon={<TreeNodeIcon nodeId={wellbore.nodeId} icon={wellBoreIcon} />}
            onLabelClick={() => setLogInfo(null)}
            onIconClick={async (event) => {
              event.stopPropagation()
              if (!isNodeExpanded(`${wellbore.nodeId}`) && !USE_TEST_DATA) {
                await fetchWitsmlTrajLogs(wellbore.nodeId)
              }

              handleToggleNodeExpanded(`${wellbore.nodeId}`)
            }}>
            <TrajectoryNodes wellBoreChildren={wellbore.children} />
            <LogNodes wellBoreChildren={wellbore.children} />
          </TreeNode>
        ))
      : null
  }

  const WellNodes = ({ wells }) => {
    return Array.isArray(wells)
      ? wells.map((well) => (
          <TreeNode
            key={`${well.nodeId}`}
            nodeId={`${well.nodeId}`}
            endIcon={<PlusSquare />}
            labelText={well.name}
            labelIcon={<TreeNodeIcon nodeId={well.nodeId} icon={wellIcon} />}
            onLabelClick={() => setLogInfo(null)}
            onIconClick={async (event) => {
              event.stopPropagation()
              if (!isNodeExpanded(`${well.nodeId}`) && !USE_TEST_DATA) {
                await fetchWitsmlWellBore(well.nodeId)
              }

              handleToggleNodeExpanded(`${well.nodeId}`)
            }}>
            <WellBoreNodes wellBores={well.children} />
          </TreeNode>
        ))
      : null
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}>
      <TreeView
        aria-label='controlled'
        expanded={expandedNodes}
        sx={{ overflow: 'auto', width: '100%', height: '100%' }}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}>
        <TreeNode
          labelText={getRootNodeId()}
          labelIcon={
            <Iconify
              icon='eos-icons:database'
              style={{
                width: `${TREE_ICON_SIZE}px`,
                height: `${TREE_ICON_SIZE}px`,
                paddingRight: `${TREE_ICON_PADDING}px`,
                color: appColors.headerTextColor,
              }}
            />
          }
          nodeId={getRootNodeId()}
          onLabelClick={() => setLogInfo(null)}
          onIconClick={async (event) => {
            event.stopPropagation()
            if (!isNodeExpanded(getRootNodeId()) && !USE_TEST_DATA) {
              await fetchWitsmlWells()
            }

            handleToggleNodeExpanded(getRootNodeId())
          }}>
          <WellNodes wells={data} />
        </TreeNode>
      </TreeView>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
    </Box>
  )
}

export default WitsmlServerTree
