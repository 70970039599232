import React, { useEffect, useRef, useState, useMemo } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CrsPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [crsData, setCrsData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const pastedRows = useRef([])
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getData = useInnovaAxios({
    url: '/crs/getCrsData',
  })

  const addData = useInnovaAxios({
    url: '/crs/addCrs',
  })

  const updateData = useInnovaAxios({
    url: '/crs/updateMultiCrs',
  })

  const deleteData = useInnovaAxios({
    url: '/crs/deleteCrs',
  })

  const requiredFields = [
    'name',
    'fe',
    'fn',
    'latOrg',
    'longOrg',
    'projectionType',
    'uom',
    'semiMajM',
    'inverseFlattening',
  ]

  const notRequiredFields = ['areaOfUse', 'system', 'scaleFactor', 'sp1', 'sp2']

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.crsPageKey)
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.name)
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData()
    if (_isMounted.current) {
      setCrsData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate([event.data])
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    if (end - start > 1) return

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    handleUpdate(itemsToUpdate)
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (notRequiredFields.findIndex((field) => field === colDef.field) >= 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('crsGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('crsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'CrsData.xlsx',
            sheetName: 'CrsData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    return requiredFields.every((field) => {
      return inputRow.current[field]
    })
  }

  const handleDelete = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('name')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteData(data)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [data],
      })
    }
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    inputRow.current = htmlSymbolHandling(inputRow.current)
    isAdding.current = true
    let res = await addData(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i].fe = parseFloat(data[i].fe)
      data[i].fn = parseFloat(data[i].fn)
      data[i].inverseFlattening = parseFloat(data[i].inverseFlattening)
      data[i].latOrg = parseFloat(data[i].latOrg)
      data[i].longOrg = parseFloat(data[i].longOrg)
      data[i].scaleFactor = parseFloat(data[i].scaleFactor)
      data[i].semiMajM = parseFloat(data[i].semiMajM)
      data[i].semiMajUom = parseFloat(data[i].semiMajUom)
      data[i].sp1 = parseFloat(data[i].sp1)
      data[i].sp2 = parseFloat(data[i].sp2)
      data[i] = htmlSymbolHandling(data[i])
    }

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateData({ crsData: JSON.stringify(data) })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '30px' }}>
              {params.node.rowIndex + 1}
            </Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  let columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 110,
      headerName: '',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      cellRenderer: actionIconRenderer,
    },
    {
      field: 'name',
      colId: 'name',
      headerName: 'Name',
      valueGetter: (params) => unescapeHtml(params.data?.name),
    },
    {
      field: 'areaOfUse',
      colId: 'areaOfUse',
      headerName: 'Area of Use',
      cellEditor: 'agLargeTextCellEditor',
      maxWidth: 300,
      valueGetter: (params) => unescapeHtml(params.data?.areaOfUse),
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 100,
        rows: 10,
        cols: 50,
      },
    },
    {
      field: 'system',
      colId: 'system',
      headerName: 'System',
      valueGetter: (params) => unescapeHtml(params.data?.system),
      editable: (params) => params?.node?.rowPinned !== 'bottom',
    },
    {
      field: 'fe',
      colId: 'fe',
      sortable: false,
      filter: null,
      headerName: 'False Easting',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.fe), 2),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -100000000,
        max: 100000000,
        precision: 2,
      },
    },
    {
      field: 'fn',
      colId: 'fn',
      sortable: false,
      filter: null,
      headerName: 'False Northing',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.fn), 2),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -100000000,
        max: 100000000,
        precision: 2,
      },
    },
    {
      field: 'latOrg',
      colId: 'latOrg',
      sortable: false,
      filter: null,
      headerName: 'Origin Lat.',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.latOrg), 6),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -90,
        max: 90,
        precision: 6,
      },
    },
    {
      field: 'longOrg',
      colId: 'longOrg',
      sortable: false,
      filter: null,
      headerName: 'Origin Long.',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.longOrg), 6),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -180,
        max: 180,
        precision: 6,
      },
    },
    {
      field: 'projectionType',
      colId: 'projectionType',
      headerName: 'Projection',
      cellEditor: 'agSelectCellEditor',
      cellStyle: centerAlignCell,
      cellEditorParams: (params) => {
        return {
          values: ['TM', '1SP', '2SP', 'STEREO'],
        }
      },
    },
    {
      field: 'uom',
      colId: 'uom',
      headerName: 'Units',
      cellEditor: 'agSelectCellEditor',
      cellStyle: centerAlignCell,
      cellEditorParams: (params) => {
        return {
          values: [
            'foot',
            'US survey foot',
            'British yard (Sears 1922)',
            'Gold Coast foot',
            'Clarkes foot',
            'Indian yard',
            'German legal metre',
            'metre',
          ],
        }
      },
    },
    {
      field: 'semiMajM',
      colId: 'semiMajM',
      sortable: false,
      filter: null,
      headerName: 'Semi Maj (m)',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.semiMajM), 4),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 10000000,
        precision: 6,
      },
    },
    {
      field: 'inverseFlattening',
      colId: 'inverseFlattening',
      sortable: false,
      filter: null,
      headerName: '1/f',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.inverseFlattening), 4),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 350,
        precision: 6,
      },
    },
    {
      field: 'scaleFactor',
      colId: 'scaleFactor',
      sortable: false,
      filter: null,
      headerName: 'Scale Factor',
      cellStyle: centerAlignCell,
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.scaleFactor), 6),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 2,
        precision: 6,
      },
    },
    {
      field: 'sp1',
      colId: 'sp1',
      sortable: false,
      filter: null,
      headerName: 'SP1',
      cellStyle: centerAlignCell,
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.sp1), 6),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -180,
        max: 180,
        precision: 6,
      },
    },
    {
      field: 'sp2',
      colId: 'sp2',
      sortable: false,
      filter: null,
      headerName: 'SP2',
      cellStyle: centerAlignCell,
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueFormatter: (params) =>
        isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : numberWithCommasDecimals(parseFloat(params.data?.sp2), 6),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: -180,
        max: 180,
        precision: 6,
      },
    },
  ]

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }
    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const focusedCell = params.api.getFocusedCell()
    const focusedIndex = focusedCell.rowIndex

    pastedRows.current = []
    gridApi.current?.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (index >= focusedIndex && index <= focusedIndex + data.length - 1) {
        pastedRows.current.push(rowNode.data)
      }
    })

    return data
  }

  function onPasteEnd(params) {
    if (!Array.isArray(pastedRows.current)) return
    if (pastedRows.current.length === 0) return
    handleUpdate(cloneDeep(pastedRows.current))
    pastedRows.current = []
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={crsData}
            columnDefs={sortColDefs(columnDefs, 'crsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={processDataFromClipboard}
            onPasteEnd={onPasteEnd}
            headerHeight={30}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default CrsPage
