import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import BarChart from 'components/common/BarChartCjs'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { isoStringToDateString, appColors } from 'utils'
import { numberWithCommas } from 'utils/stringFunctions'
import DDRDonutChartJs from './DonutChartJs'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledDonutContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  height: '50%',
})

const DailyCostCharts = ({ filteredDailyCosts }) => {
  const _isMounted = useRef(false)
  const { getUnitsText } = useUnits()
  const { theme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const chartOptions = useMemo(() => {
    return {
      plugins: {
        GradientPlugIn: {
          showGradient: true,
          theme: theme,
        },
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: function (tooltipItem) {
              return isoStringToDateString(tooltipItem[0].label)
            },
            label: function (context) {
              return `${context.dataset.label} - ${getUnitsText(UNITS_FOR.Cost)}${numberWithCommas(context.parsed.y)}`
            },
          },
          filter: function (tooltipItem, index) {
            return index > 0 ? false : true
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      scales: {
        y2: {
          position: 'right',
          title: {
            display: true,
            text: 'Cumulative Cost',
            color: appColors.itemTextColor,
          },
          ticks: {
            color: appColors.headerTextColor,
          },
          stacked: true,
        },
      },
    }
  }, [getUnitsText, theme])

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const parseDate = useCallback((date) => {
    if (!isValidDate(date)) return ''
    date = date.replace(/Z/g, '')
    return new Date(Date.parse(date + 'T00:00:01')).toLocaleDateString('default', {
      day: 'numeric',
      year: 'numeric',
      month: 'short',
    })
  }, [])

  const createCostCodeLabel = (code, desc) => {
    return `${code}: ${desc}`
  }

  const getDatesBetween = useCallback((start, end) => {
    if (!isValidDate(start)) return []
    if (!isValidDate(end)) return []

    const startDate = new Date(Date.parse(start + 'T00:00:00'))
    const endDate = new Date(Date.parse(end + 'T00:00:00'))
    const dates = []

    // Iterate through each day between the start and end dates
    for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      dates.push(formattedDate)
    }

    return dates
  }, [])

  const createChartData = useCallback(
    (data) => {
      let output = {
        labels: [],
        datasets: [
          {
            type: 'line',
            label: 'Cumulative Cost',
            yAxisID: 'y2',
            backgroundColor: appColors.slideColor,
            borderColor: appColors.slideColor,
            categoryPercentage: 0.5,
            maxBarThickness: 24,
            data: [],
          },
        ],
      }

      if (!Array.isArray(data)) return output
      if (data.length === 0) return output

      output.labels = getDatesBetween(data[data.length - 1].date, data[0].date)
      output.datasets[0].data = output.labels.map(() => 0)

      for (let i = 0; i < data.length; i++) {
        if (!Array.isArray(data[i]?.costs)) continue
        let dateIndex = output.labels.findIndex((element) => element === data[i].date)
        if (dateIndex < 0) continue

        for (let j = 0; j < data[i].costs.length; j++) {
          let codeIndex = output.datasets.findIndex(
            (element) => element.label === createCostCodeLabel(data[i].costs[j].costCode, data[i].costs[j].description),
          )
          if (codeIndex < 0) {
            output.datasets.push({
              label: createCostCodeLabel(data[i].costs[j].costCode, data[i].costs[j].description),
              yAxisID: 'y',
              backgroundColor: data[i].costs[j].color,
              categoryPercentage: 0.5,
              maxBarThickness: 24,
              data: output.labels.map(() => 0),
            })

            codeIndex = output.datasets.length - 1
          }

          output.datasets[codeIndex].data[dateIndex] += data[i].costs[j].totalValue
        }

        output.datasets[0].data[dateIndex] += data[i].totalCost
      }

      for (let i = 0; i < output.labels.length; i++) {
        output.labels[i] = parseDate(output.labels[i])

        if (i > 0) {
          output.datasets[0].data[i] += output.datasets[0].data[i - 1]
        }
      }

      output.labels.forEach((label) => {
        label += 'T00:01:00'
      })

      return output
    },
    [getDatesBetween, parseDate],
  )

  const totalCostDonut = (data) => {
    if (!Array.isArray(data)) return []

    let costCodeTotals = []
    for (let i = 0; i < data.length; i++) {
      if (!Array.isArray(data[i].costs)) continue
      for (let j = 0; j < data[i].costs.length; j++) {
        let index = costCodeTotals.findIndex((item) => item.costCode === data[i].costs[j].costCode)
        if (index >= 0) {
          costCodeTotals[index].value += data[i].costs[j].totalValue
          continue
        }

        costCodeTotals.push({
          label: `${data[i].costs[j].costCode}: ${data[i].costs[j].description}`,
          costCode: data[i].costs[j].costCode,
          value: data[i].costs[j].totalValue,
          color: data[i].costs[j].color,
        })
      }
    }

    return costCodeTotals
  }

  const lastDaysCostDonut = (data) => {
    if (!Array.isArray(data) || data.length === 0) return []
    if (!Array.isArray(data[0]?.costs)) return []

    let costCodeTotals = []
    data[0]?.costs.forEach((cost) => {
      costCodeTotals.push({
        label: `${cost.costCode}: ${cost.description}`,
        costCode: cost.costCode,
        value: cost.totalValue,
        color: cost.color,
      })
    })

    return costCodeTotals
  }

  return useMemo(
    () => (
      <Box
        sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%', background: getChartBackColor() }}>
        {' '}
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 4 }}>
          <BarChart
            wellData={createChartData(filteredDailyCosts)}
            units={getUnitsText(UNITS_FOR.Depth)}
            title={'Cost Per Day'}
            xAxisTitle='Day'
            yAxisTitle={`Cost (${getUnitsText(UNITS_FOR.Cost)})`}
            chartOptions={chartOptions}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            flex: 1,
            width: '100%',
          }}>
          <StyledDonutContainer>
            {Array.isArray(filteredDailyCosts) ? (
              <DDRDonutChartJs
                data={totalCostDonut(filteredDailyCosts)}
                title={'Total Well Cost'}
                legendLocation={'none'}
                maxTooltipLength={15}
                showLegend={false}
                showTitle={true}
                showGradient={true}
                innerRadius='65%'
                padding='30%'
                thousands={true}
                prefix={true}
              />
            ) : null}
          </StyledDonutContainer>
          <StyledDonutContainer>
            {Array.isArray(filteredDailyCosts) ? (
              <DDRDonutChartJs
                data={lastDaysCostDonut(filteredDailyCosts)}
                title={`Last Daily Cost: ${filteredDailyCosts.length > 0 ? parseDate(filteredDailyCosts[0].date) : ''}`}
                legendLocation={'none'}
                maxTooltipLength={15}
                showLegend={false}
                showTitle={true}
                showGradient={true}
                innerRadius='65%'
                padding='30%'
                thousands={true}
                prefix={true}
              />
            ) : null}
          </StyledDonutContainer>
        </Box>
      </Box>
    ),
    [filteredDailyCosts, getUnitsText, createChartData, chartOptions, parseDate, getChartBackColor],
  )
}

export default DailyCostCharts
