import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { throttle } from 'lodash'
import ConfirmDialog from 'components/common/ConfirmDialog'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { selectedTemplateAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const TemplatesListGrid = forwardRef(({ isVisible, showHidePane, facility }, ref) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [isLoading, setLoading] = useState(false)
  const isUpdating = useRef(false)
  const selectedTemplateRef = useRef(null)
  const { getUnitsText } = useUnits()
  const [selectedTemplate, setSelectedTemplate] = useRecoilState(selectedTemplateAtom)
  const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  const getTemplates = useInnovaAxios({
    url: '/template/getTemplate',
  })

  const deleteTemplate = useInnovaAxios({
    url: '/template/deleteTemplate',
  })

  const createTemplate = useInnovaAxios({
    url: '/template/addTemplate',
  })

  const copyTemplate = useInnovaAxios({
    url: '/template/copyTemplate',
  })

  const updateTemplate = useInnovaAxios({
    url: '/template/updateTemplate',
  })

  const calcTemplate = useInnovaAxios({
    url: '/template/calculateTemplate',
  })

  useEffect(() => {
    selectedTemplateRef.current = cloneDeep(selectedTemplate)
  }, [selectedTemplate])

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const fetchTemplates = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getTemplates({
      facility: facility,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    if (gridApi.current) {
      gridApi.current?.setGridOption('rowData', res.data)
    }
  }

  const throttledSetQuickFilter = throttle((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    throttledSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchTemplates()
  }

  function onSelectionChanged() {
    const selectedRows = gridApi.current.getSelectedRows()

    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      setSelectedTemplate(null)
      return
    }

    if (selectedTemplateRef.current && gridApi.current) {
      gridApi.current.applyTransaction({
        update: [selectedTemplateRef.current.orgData],
      })
    }

    setSelectedTemplate({ orgData: cloneDeep(selectedRows[0]), data: cloneDeep(selectedRows[0]) })
  }

  function isPinnedRowDataCompleted() {
    if (!inputRow.current?.hasOwnProperty('templateName')) return false
    return true
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddTarget(event.data)
      }

      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate(event.data, false)
      }
    },
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onSelectionChanged: onSelectionChanged,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()

    params.api.forEachNode((node) => {
      if (node.data && node?.rowPinned !== 'bottom') {
        node.rowIndex === 0 ? node.setSelected(true) : node.setSelected(false)
      }
    })
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('templateListGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('templateListGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>', //ask dan
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Templates.xlsx',
            sheetName: 'Template',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Refresh data',
        disabled: false,
        action: () => {
          fetchTemplates()
        },
        icon: '<span class="iconify" data-icon="material-symbols:refresh" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const onClickDelete = useCallback(
    (data) => {
      setSelectedTemplate({ orgData: cloneDeep(data), data: cloneDeep(data) })

      setConfirm({
        show: true,
        title: 'Delete Template',
        text: `Are you sure you want to delete ${data.templateName}?`,
      })
    },
    [setSelectedTemplate],
  )

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'templateName') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const handleAddTarget = async (data) => {
    if (!isPinnedRowDataCompleted()) return

    let res = await createTemplate({
      templateName: data.templateName,
      facility: facility,
    })

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
      return
    }

    gridApi.current.applyTransaction({
      add: [res.data],
    })

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleDelete = async () => {
    if (!selectedTemplateRef.current?.orgData) return
    let res = await deleteTemplate({
      templateName: selectedTemplateRef.current.orgData.templateName,
      facility: facility,
    })
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [selectedTemplateRef.current.orgData],
      })

      setSelectedTemplate(null)
    }
  }

  const handleDuplicateTemplate = useCallback(
    async (data) => {
      if (!data) return
      let res = await copyTemplate({
        templateName: data.templateName,
        facility: facility,
      })

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          add: [res.data],
        })
      }
    },
    [copyTemplate, facility],
  )

  const handleUpdate = useCallback(
    async (data, save) => {
      if (!data) return

      if (isUpdating.current) return
      isUpdating.current = true
      setLoading(true)

      let payload = { templateData: JSON.stringify(htmlSymbolHandling(data)) }
      let res = save ? await updateTemplate(payload) : await calcTemplate(payload)

      isUpdating.current = false

      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      if (!selectedTemplateRef.current) return

      if (!save) {
        let templateData = cloneDeep(selectedTemplateRef.current)
        templateData.data = cloneDeep(res.data)
        setSelectedTemplate(templateData)
      }

      if (save) {
        let templateData = cloneDeep(selectedTemplateRef.current)
        templateData.orgData = cloneDeep(res.data)
        templateData.data = cloneDeep(res.data)
        setSelectedTemplate(templateData)
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          update: [res.data],
        })
      }
    },

    [updateTemplate, calcTemplate, setSelectedTemplate],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'duplicate') {
        handleDuplicateTemplate(data)
      }
    },
    [onClickDelete, handleDuplicateTemplate],
  )

  let columnDefs = useMemo(() => {
    let cols = [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Duplicate',
                action: 'duplicate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='grommet-icons:copy' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'templateName',
        colId: 'templateName',
        headerName: 'Name',
        pinned: 'left',
        lockPosition: 'left',
        editable: true,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return 'Name...'
          return unescapeHtml(params.value)
        },
      },
      {
        colId: 'geometry',
        headerName: `Geometry`,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          return true
        },
        valueGetter: (params) => {
          if (params?.data?.geometry === 'RECT') return 'RECTANGLE'
          if (params?.data?.geometry === 'CIRC') return 'CIRCLE'
          return params.data?.geometry
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          if (params.newValue === params.oldValue) return false

          params.data.geometry = params.newValue

          if (params.data?.geometry === 'RECTANGLE') params.data.geometry = 'RECT'
          if (params.data?.geometry === 'CIRCLE') params.data.geometry = 'CIRC'

          if (params.data?.geometry === 'CIRC') {
            params.data.circData.numSlots = 10
            params.data.circData.rotation = 0
            params.data.circleProperties.radius = 50
          }

          if (params.data?.geometry === 'RECT') {
            params.data.rectData.topLeftEast = 0
            params.data.rectData.topLeftNorth = 0
            params.data.rectData.rows = 5
            params.data.rectData.columns = 5
            params.data.rectData.rotation = 0
            params.data.rectData.slotNumbering = 'ROW'
            params.data.rectData.xSpacing = 5
            params.data.rectData.ySpacing = 5
          }

          return true
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: ['RECTANGLE', 'CIRCLE', 'SINGLE'],
          }
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'slotPrefix',
        colId: 'slotPrefix',
        headerName: `Slot Prefix`,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          return true
        },
        valueFormatter: (params) => {
          return unescapeHtml(params.value)
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'offsetNorth',
        colId: 'offsetNorth',
        headerName: `NS ${getUnitsText(UNITS_FOR.Depth)}`,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          return true
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 2)
        },
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        field: 'offsetEast',
        colId: 'offsetEast',
        headerName: `EW ${getUnitsText(UNITS_FOR.Depth)}`,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          return true
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 2)
        },
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000000,
          max: 100000000,
          precision: 3,
        },
      },
    ]

    return cols
  }, [getUnitsText, centerAlignCell, handleMenuClick])

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const navigateToNextCell = (params) => {
    if (params.key !== 'ArrowDown' && params.key !== 'ArrowUp') {
      return params.nextCellPosition
    }

    params.api.forEachNode((node) => {
      if (node.rowIndex === params.nextCellPosition.rowIndex) {
        node.setSelected(true)
      }
    })

    return params.nextCellPosition
  }

  useImperativeHandle(ref, () => {
    return {
      discardChanges() {
        if (!selectedTemplateRef.current) return
        if (!gridApi.current) return

        setSelectedTemplate({
          orgData: cloneDeep(selectedTemplateRef.current.orgData),
          data: cloneDeep(selectedTemplateRef.current.orgData),
        })

        gridApi.current.applyTransaction({
          update: [selectedTemplateRef.current.orgData],
        })
      },
      commitChanges() {
        if (!selectedTemplateRef.current) return
        handleUpdate(selectedTemplateRef.current?.data, true)
      },
      calculateTemplate(data) {
        handleUpdate(data, false)
      },
    }
  })

  const getFacilityText = () => {
    return `Local coords relative to: ${facility}`
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: getWindowBarColor(),
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: isVisible ? 'flex' : 'none',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(3)}
        />
      </Box>
      <Box
        sx={{
          display: isVisible ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 90px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'templateListGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            rowSelection={'single'}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            navigateToNextCell={navigateToNextCell}
          />
        </div>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </Box>
      {isVisible ? (
        <Box
          sx={{
            width: '100%',
            height: '25px',
            borderLeft: '1px solid gray',
            borderRight: '1px solid gray',
            backgroundColor: getWindowBarColor(),
            color: 'white',
            paddingLeft: '10px',
          }}>
          {getFacilityText()}
        </Box>
      ) : null}
      {!isVisible ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Templates List
          </Box>
        </Box>
      ) : null}
    </Box>
  )
})

export default TemplatesListGrid
