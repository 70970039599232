import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, dateComparator, isDateLessThan } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InvoiceQcCostsModal = ({ onClose, data }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('invoiceQcCostsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('invoiceQcCostsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    value += 'T00:00:01Z'
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const dateFilterComparator = useCallback(dateComparator, [])

  let columnDefs = useMemo(
    () => [
      {
        colId: 'rowNum',
        headerName: '#',
        valueGetter: (params) => params.node.rowIndex + 1,
        cellStyle: centerAlignCell,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'date',
        colId: 'date',
        headerName: 'Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return `${dateTimeFormatter(params.value)}`
        },
      },
      {
        field: 'costCode',
        colId: 'costCode',
        headerName: 'Cost Code',
        cellStyle: leftAlignCell,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        cellStyle: leftAlignCell,
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        field: 'quantity',
        colId: 'quantity',
        headerName: 'Qty',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'value',
        colId: 'value',
        headerName: 'Value',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'totalValue',
        colId: 'totalValue',
        headerName: 'Total Value',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
    ],
    [dateFilterComparator, centerAlignCell, leftAlignCell, dateTimeFormatter],
  )

  const getTotalCost = (data) => {
    let total = 0
    if (Array.isArray(data)) {
      data.forEach((row) => {
        total += row.totalValue
      })
    }
    return total
  }

  return (
    <Dialog
      maxWidth='md'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Well Costs - ${numberWithCommasDecimals(getTotalCost(data), 2)}`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              rowData={Array.isArray(data) ? data : []}
              columnDefs={sortColDefs(columnDefs, 'invoiceQcCostsGrid')}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvoiceQcCostsModal
