import React, { useState, useRef } from 'react'
import { Box } from '@mui/material'
import useMapMarkers from 'components/common/hooks/useMapMarkers'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { APIProvider, Map, AdvancedMarker, useAdvancedMarkerRef, InfoWindow } from '@vis.gl/react-google-maps'
import { uuidv4 } from 'utils/stringFunctions'
import { styled } from '@mui/styles'
import { unescapeHtml } from 'utils/htmlSymbolHandling'

const StyledMarkerText = styled(Box)({
  fontSize: 10,
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
})

const WellSeekerMarker = ({ item, i, sanitized }) => {
  const { getMapMarker } = useMapMarkers()
  const [refCallback, marker] = useAdvancedMarkerRef()
  const [infowindowOpen, setInfowindowOpen] = useState(false)

  return (
    <React.Fragment>
      <AdvancedMarker
        key={i}
        position={{
          lat: item.lat,
          lng: item.lon,
        }}
        size={20}
        ref={refCallback}
        onClick={() => setInfowindowOpen(true)}
        icon={sanitized ? null : getMapMarker(item.companyID, true)}>
        {!sanitized ? (
          <Box
            component='img'
            src={getMapMarker(item.companyID, true)}
            alt='NO LOGO'
            sx={{ width: '100%', height: 'calc(100% - 45px)', paddingBottom: '15px', objectFit: 'contain' }}
          />
        ) : null}
      </AdvancedMarker>
      {infowindowOpen ? (
        <InfoWindow disableAutoPan={true} anchor={marker} maxWidth={200} onCloseClick={() => setInfowindowOpen(false)}>
          <Box
            sx={{
              background: `white`,
              border: `1px solid #ccc`,
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: ' flex-start',
              position: 'relative',
              maxWidth: '400px',
              minWidth: '200px',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <StyledMarkerText>IP Address:</StyledMarkerText>
                <StyledMarkerText>Software ID:</StyledMarkerText>
                <StyledMarkerText>Company name:</StyledMarkerText>
                <StyledMarkerText>User Name:</StyledMarkerText>
                <StyledMarkerText>Comp Code:</StyledMarkerText>
                <StyledMarkerText>City:</StyledMarkerText>
                <StyledMarkerText>Region:</StyledMarkerText>
                <StyledMarkerText>Country:</StyledMarkerText>
                <StyledMarkerText>Latitude:</StyledMarkerText>
                <StyledMarkerText>Longitude:</StyledMarkerText>
                <StyledMarkerText>Version:</StyledMarkerText>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '6px' }}>
                <StyledMarkerText>{item.ipAddress !== '' ? item.ipAddress : '-'}</StyledMarkerText>
                <StyledMarkerText>{item.companyID !== '' ? item.companyID : '-'}</StyledMarkerText>
                <StyledMarkerText>
                  {item.companyName?.trim() !== '' ? unescapeHtml(item.companyName) : '-'}
                </StyledMarkerText>
                <StyledMarkerText>
                  {item.userName !== null && item.userName?.trim() !== '' ? item.userName : '-'}
                </StyledMarkerText>
                <StyledMarkerText>{item.computerCode?.trim() !== '' ? item.computerCode : '-'}</StyledMarkerText>
                <StyledMarkerText>{item.city !== '' ? item.city : '-'}</StyledMarkerText>
                <StyledMarkerText>{item.regionName !== '' ? item.regionName : '-'}</StyledMarkerText>
                <StyledMarkerText>{item.country !== '' ? item.country : '-'}</StyledMarkerText>
                <StyledMarkerText>{item.lat}</StyledMarkerText>
                <StyledMarkerText>{item.lon}</StyledMarkerText>
                <StyledMarkerText>{item.wsVersion !== '' ? item.wsVersion : '-'}</StyledMarkerText>
              </Box>
            </Box>
          </Box>
        </InfoWindow>
      ) : null}
    </React.Fragment>
  )
}

const WellSeekersMap = ({ wellSeekerUsers }) => {
  const [sanitizeMap, setSanitizeMap] = useState(false)
  const id = useRef(uuidv4())

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLEMAP_API_KEY}>
        <Map
          defaultCenter={{ lat: 29.7604, lng: -95.3698 }}
          defaultZoom={5}
          zoomControl={true}
          disableDefaultUI={true}
          gestureHandling={'greedy'}
          mapId={id.current}
          mapTypeId={'hybrid'}>
          {Array.isArray(wellSeekerUsers)
            ? wellSeekerUsers.map((marker, i) => (
                <WellSeekerMarker item={marker} i={i} key={`${i}`} sanitized={sanitizeMap} />
              ))
            : null}
        </Map>
      </APIProvider>
      <Box sx={{ position: 'absolute', top: '20px', right: '20px' }}>
        <MenuButton
          direction={'left'}
          actions={[
            {
              icon: <Iconify icon={'tabler:map-pin-filled'} style={{ color: 'tomato', width: 28, height: 28 }} />,
              name: 'Sanitize',
              onClick: () => {
                setSanitizeMap((prev) => !prev)
              },
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default React.memo(WellSeekersMap)
