export function getTargets3D(targets, scale) {
  if (!targets) targets = []
  if (Array.isArray(targets) === false) targets = []

  let output = []
  targets.forEach((tgt) => {
    if (tgt.geometry === 'POINT') processPoint3D(output, tgt, scale)
    if (tgt.geometry === 'POLYGON') processPolygon3D(output, tgt, scale)
    if (tgt.geometry === 'CIRCLE') processCircle3D(output, tgt, scale)
    if (tgt.geometry === 'ELLIPSE') processEllipse3D(output, tgt, scale)
    if (tgt.geometry === 'RECTANGLE') processRectangle3D(output, tgt, scale)

    if (Array.isArray(tgt?.drillingTargetPoints) && tgt.drillingTargetPoints.length > 0) {
      output[output.length - 1].drillersTargetPoints = []
      output[output.length - 1].hasDrillersTarget = true
      output[output.length - 1].drillersTargetText = `${tgt.targetName} - Drillers Tgt @(${tgt.drillersTargetConfidence}%)`

      //Drillers target does not have a top / bottom
      //Only adding here so the existing polygon draw code will work
      //TVD is offset by 0.1 so it does not draw straigt on top of the existing target
      tgt?.drillingTargetPoints.forEach((pt) => {
        output[output.length - 1].drillersTargetPoints.push({
          top: {
            x: pt.localNorth * scale,
            y: -(pt.tvd * scale) + 0.1,
            z: pt.localEast * scale,
          },
          bottom: {
            x: pt.localNorth * scale,
            y: -(pt.tvd * scale) + 0.1,
            z: pt.localEast * scale,
          },
        })
      })
    }
  })

  return output
}

function checkValid(input) {
  if (!input) return false
  if (!input.hasOwnProperty('points')) return false
  if (!Array.isArray(input.points)) return false
  if (input.points.length === 0) return false

  return true
}

function processPolygon3D(output, tgt, scale) {
  if (!Array.isArray(tgt.polygonPoints)) return
  if (tgt.polygonPoints.length === 0) return

  let newTarget = createTarget3D(tgt, 'tomato', 'polygon', scale)

  if (tgt.isLeaseLine === true || tgt.isHardLine === true) {
    newTarget.x = tgt.polygonPoints[0].top.localNorth * scale
    newTarget.y = -tgt.polygonPoints[0].top.tvd * scale
    newTarget.z = tgt.polygonPoints[0].top.localEast * scale
    newTarget.isLeaseLine = true
  }

  tgt.polygonPoints.forEach((pt) => {
    newTarget.points.push({
      top: {
        x: pt.top.localNorth * scale,
        y: -pt.top.tvd * scale,
        z: pt.top.localEast * scale,
      },
      bottom: {
        x: pt.bottom.localNorth * scale,
        y: -pt.bottom.tvd * scale,
        z: pt.bottom.localEast * scale,
      },
    })
  })

  output.push(newTarget)
}

function processPoint3D(output, tgt, scale) {
  output.push(createTarget3D(tgt, 'tomato', 'point', scale))
}

function processCircle3D(output, tgt, scale) {
  if (!checkValid(tgt.circleProperties)) return

  let deg2Rad = Math.PI / 180.0
  let newTarget = createTarget3D(tgt, 'tomato', 'circle', scale)
  newTarget.radius = tgt.circleProperties.radius * scale

  //Three js default zero is 3 o clock position subtracting PI / 2 puts it at the 12 o clock
  newTarget.arcStart = tgt.circleProperties.arcStart * deg2Rad - Math.PI / 2.0
  newTarget.arcLength = Math.abs(tgt.circleProperties.arcEnd - tgt.circleProperties.arcStart) * deg2Rad
  output.push(newTarget)
}

function processEllipse3D(output, tgt, scale) {
  if (!checkValid(tgt.ellipseProperties)) return

  let deg2Rad = Math.PI / 180.0
  let newTarget = createTarget3D(tgt, 'tomato', 'ellipse', scale)
  newTarget.semiMajor = tgt.ellipseProperties.semiMajor * scale
  newTarget.semiMinor = tgt.ellipseProperties.semiMinor * scale
  //Three js default zero is 3 o clock position subtracting PI / 2 puts it at the 12 o clock
  newTarget.arcStart = tgt.ellipseProperties.arcStart * deg2Rad - Math.PI / 2.0
  newTarget.arcLength = Math.abs(tgt.ellipseProperties.arcEnd - tgt.ellipseProperties.arcStart) * deg2Rad
  output.push(newTarget)
}

function processRectangle3D(output, tgt, scale) {
  if (!checkValid(tgt.rectProperties)) return

  let newTarget = createTarget3D(tgt, 'tomato', 'rectangle', scale)
  newTarget.width = tgt.rectProperties.width * scale
  newTarget.length = tgt.rectProperties.length * scale
  output.push(newTarget)
}

function createTarget3D(tgt, color, geometry, scale) {
  let deg2Rad = Math.PI / 180.0

  return {
    color: tgt.color || color,
    x: (geometry === 'point' ? tgt.targetCenter.localNorth : tgt.geometryCenter.localNorth) * scale,
    y: (geometry === 'point' ? tgt.targetCenter.tvd : tgt.geometryCenter.tvd) * -scale,
    z: (geometry === 'point' ? tgt.targetCenter.localEast : tgt.geometryCenter.localEast) * scale,
    thickness: (tgt.thicknessUp + tgt.thicknessDn) * scale,
    dipAngle: tgt.dipAngle * deg2Rad,
    dipAzi: tgt.dipAzi * deg2Rad,
    rotation: -tgt.rotation * deg2Rad,
    text: tgt.targetName,
    drillersTargetText: '',
    isLeaseLine: false,
    geometry,
    points: [],
    drillersTargetPoints: [],
    hasDrillersTarget: false
  }
}
