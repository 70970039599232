import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom, analyticsFilterDefsAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { round } from 'utils/numberFunctions'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommas } from 'utils/stringFunctions'
import { appColors } from 'utils'
import {
  sortColDefs,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const PhaseKpiGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [groupRows, setGroupRows] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const recoilFilterDefs = useRecoilValue(analyticsFilterDefsAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/kpi/phaseKpi',
    timeout: 600000,
  })

  const getSelectedValues = (param) => {
    if (!param) return ''
    if (!Array.isArray(recoilFilterDefs)) return ''
    for (let i = 0; i < recoilFilterDefs.length; i++) {
      if (recoilFilterDefs[i].text !== param) continue
      if (!recoilFilterDefs[i].isActive) return ''
      if (Array.isArray(recoilFilterDefs[i].value)) {
        if (recoilFilterDefs[i].value.length === 0) return ''
        if (recoilFilterDefs[i].value.length === 1) return recoilFilterDefs[i].value[0]
        return recoilFilterDefs[i].value.join('|')
      }

      return recoilFilterDefs[i].value
    }
  }

  const dateFilterComparator = useCallback(dateComparator, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('phaseKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.phaseKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    const payload = {
      wellList: array2pipestr(selectedWells),
      phase: getSelectedValues('Phase'),
      getTvds: getSelectedValues('Get TVD') === 'Yes' ? true : false,
    }

    setLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let dataCopy = cloneDeep(data)
    if (!Array.isArray(dataCopy)) return

    for (let i = 0; i < dataCopy.length; i++) {
      if (!Array.isArray(dataCopy[i].multipleHoleSizes)) {
        continue
      }

      let holeSizeStr = ''
      for (let j = 0; j < dataCopy[i].multipleHoleSizes.length; j++) {
        if (dataCopy[i].multipleHoleSizes[j] <= 0) continue
        if (holeSizeStr !== '') holeSizeStr += ' / '

        holeSizeStr += `${round(dataCopy[i].multipleHoleSizes[j], 3)}`
      }

      dataCopy[i].holeSize = holeSizeStr
    }

    let phaseWS = XLSX.utils.json_to_sheet(dataCopy)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, phaseWS, 'Phase Kpi')
    XLSX.writeFile(wb, 'phaseKpi.xlsx')
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    expandAllNodes('expand')
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = (first, second) => {
    let largestAmount = 0
    if (data?.length <= 0) return 1
    data.forEach((item) => {
      if (item[first] > largestAmount) {
        largestAmount = item[first]
      }
      if (item[second] > largestAmount) {
        largestAmount = item[second]
      }
    })

    return largestAmount
  }

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const threeValColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'multipleHoleSizes',
        pinned: 'left',
        lockPosition: 'left',
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: {
          justifyContent: 'center',
        },
      },
      {
        headerName: '',
        colId: 'avatar',
        width: 100,
        pinned: 'left',
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                margin: 'auto',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.status),
                borderRadius: '50%',
              }}>
              {params?.data.hasOwnProperty('status') ? (
                <Avatar
                  alt={params?.data?.status}
                  src={getStatusAvatar(params?.data?.status)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        rowGroup: groupRows,
        hide: groupRows,
        minWidth: 200,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.operator)
        },
      },
      {
        headerName: 'Well',
        field: 'well',
        colId: 'well',
        pinned: 'left',
        rowGroup: groupRows,
        hide: groupRows,
        minWidth: 300,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Job #',
        field: 'jobNum',
        colId: 'jobNum',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.rig)
        },
      },
      {
        headerName: 'State',
        field: 'state',
        colId: 'state',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Status',
        field: 'status',
        colId: 'status',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: GetActivityColor(params.data?.status),
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.status}
            </span>
          )
        },
      },
      {
        headerName: `Latitude`,
        field: 'lat',
        colId: 'lat',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.lat, 6)
        },
      },
      {
        headerName: `Longitude`,
        field: 'long',
        colId: 'long',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return round(params.data?.long, 6)
        },
      },
      {
        headerName: 'Phase',
        field: 'phase',
        colId: 'phase',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Formation',
        field: 'formation',
        colId: 'formation',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Start Date',
        field: 'startDateTime',
        colId: 'startDateTime',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.startDateTime)
        },
      },
      {
        headerName: 'End Date',
        field: 'endDateTime',
        colId: 'endDateTime',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.endDateTime)
        },
      },
      {
        headerName: 'Start Drilling',
        field: 'startDrillingDateTime',
        colId: 'startDrillingDateTime',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.startDrillingDateTime)
        },
      },
      {
        headerName: 'End Drilling',
        field: 'stopDrillingDateTime',
        colId: 'stopDrillingDateTime',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.stopDrillingDateTime)
        },
      },
      {
        headerName: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'startDepth',
        colId: 'startDepth',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.startDepth, 2))
        },
      },
      {
        headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'endDepth',
        colId: 'endDepth',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.endDepth, 2))
        },
      },
      {
        headerName: `TVD In (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'tvdStart',
        colId: 'tvdStart',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.tvdStart, 2))
        },
      },
      {
        headerName: `TVD Out (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'tvdEnd',
        colId: 'tvdEnd',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.tvdEnd, 2))
        },
      },
      {
        headerName: `Num BHA`,
        field: 'numBhas',
        colId: 'numBhas',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.data?.numBhas || ''
        },
      },
      {
        headerName: `Avg Drilled (${getUnitsText(UNITS_FOR.Depth)}/Day)`,
        field: 'avgDrilledPerDay',
        colId: 'avgDrilledPerDay',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.avgDrilledPerDay, 2))
        },
      },

      {
        headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'totalDrilled',
        colId: 'totalDrilled',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.totalDrilled, 2))
        },
      },
      {
        headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'footage',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotDrilled')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideDrilled],
            ['Rotate', params.data?.rotDrilled],
          ]
        },
      },
      {
        headerName: `1st ft-Last ft (Hrs)`,
        field: 'sectionHrs',
        colId: 'sectionHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.sectionHrs, 2))
        },
      },
      {
        headerName: `Total Hrs`,
        field: 'totalHrs',
        colId: 'totalHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.totalHrs, 2))
        },
      },
      {
        headerName: `BRT (Hrs)`,
        field: 'brtHrs',
        colId: 'brtHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.brtHrs, 2))
        },
      },
      {
        headerName: `Drilling (Hrs)`,
        field: 'drillingHrs',
        colId: 'drillingHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.drillingHrs, 2))
        },
      },
      {
        headerName: `Total D&C (Hrs)`,
        field: 'drillingAndCircHrs',
        colId: 'drillingAndCircHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.drillingAndCircHrs, 2))
        },
      },
      {
        headerName: `Drilling Hrs`,
        colId: 'footage',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideHrs', 'rotHrs')],
            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slideHrs],
            ['Rotate', params.data?.rotHrs],
          ]
        },
      },
      {
        headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        colId: 'rop',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            paddingOuter: 0.5,
            type: 'bar',
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: threeValColumnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slideRop', 'rotRop')],
            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide ROP', params.data?.slideRop],
            ['Rotate ROP', params.data?.rotRop],
            ['Avg ROP', params.data?.avgRop],
          ]
        },
      },
      {
        headerName: `Section Rop (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        field: 'sectionRop',
        colId: 'sectionRop',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.sectionRop, 2))
        },
      },
      {
        headerName: `Slide/Rot %`,
        colId: 'footage',
        autoHeight: true,
        sortable: false,
        width: 140,
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: {
          sparklineOptions: {
            type: 'bar',
            paddingOuter: 0.25,
            tooltip: {
              xOffset: 20,
              yOffset: -20,
              renderer: tooltipRenderer,
            },
            formatter: columnFormatter,
            label: {
              enabled: true,
              placement: 'outsideEnd',
              color: 'white',
              fontSize: 9,
            },
            stroke: '#91cc75',
            highlightStyle: {
              fill: '#fac858',
            },
            valueAxisDomain: [0, getHighestValue('slidePerc', 'rotPerc')],

            padding: {
              top: 0,
              bottom: 0,
              right: 30,
            },
            axis: {
              strokeWidth: 0,
            },
          },
        },
        valueGetter: (params) => {
          return [
            ['Slide', params.data?.slidePerc],
            ['Rotate', params.data?.rotPerc],
          ]
        },
      },
      {
        headerName: `Max Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
        field: 'maxBht',
        colId: 'maxBht',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommas(round(params.data?.maxBht, 2))
        },
      },
      {
        headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'holeSize',
        colId: 'holeSize',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          if (!Array.isArray(params.data?.multipleHoleSizes)) return round(params.data?.holeSize, 3)
          let holeSizeStr = ''
          for (let i = 0; i < params.data?.multipleHoleSizes.length; i++) {
            if (params.data?.multipleHoleSizes[i] <= 0) continue
            if (holeSizeStr !== '') holeSizeStr += ' / '

            holeSizeStr += `${round(params.data?.multipleHoleSizes[i], 3)}`
          }

          return holeSizeStr
        },
      },
    ],
    [groupRows, getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('phaseKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'PhaseKpi.xlsx',
            sheetName: 'PhaseKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getStatsByHoleSize = async (wellName, phase, successCallBack) => {
    const payload = {
      wellList: wellName,
      groupByHoleSize: true,
      phase: phase,
    }

    const response = await getKpiData(payload)
    successCallBack(response?.error ? [] : response.data)
  }

  const detailCellRendererParams = useMemo(
    () => {
      return {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Phase',
              field: 'phase',
              colId: 'phase',
              cellStyle: centerAlignCell,
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
            },
            {
              headerName: 'Start Date',
              field: 'startDateTime',
              colId: 'startDateTime',
              minWidth: 120,
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: dateFilterComparator,
              },
              valueFormatter: (params) => {
                return dateTimeFormatter(params.data?.startDateTime)
              },
            },
            {
              headerName: 'End Date',
              field: 'endDateTime',
              colId: 'endDateTime',
              minWidth: 120,
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: dateFilterComparator,
              },
              valueFormatter: (params) => {
                return dateTimeFormatter(params.data?.endDateTime)
              },
            },
            {
              headerName: 'Start Drilling',
              field: 'startDrillingDateTime',
              colId: 'startDrillingDateTime',
              minWidth: 120,
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: dateFilterComparator,
              },
              valueFormatter: (params) => {
                return dateTimeFormatter(params.data?.startDrillingDateTime)
              },
            },
            {
              headerName: 'End Drilling',
              field: 'stopDrillingDateTime',
              colId: 'stopDrillingDateTime',
              minWidth: 120,
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: dateFilterComparator,
              },
              valueFormatter: (params) => {
                return dateTimeFormatter(params.data?.stopDrillingDateTime)
              },
            },
            {
              headerName: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`,
              field: 'startDepth',
              colId: 'startDepth',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.startDepth, 2))
              },
            },
            {
              headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
              field: 'endDepth',
              colId: 'endDepth',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.endDepth, 2))
              },
            },
            {
              headerName: `Num BHA`,
              field: 'numBhas',
              colId: 'numBhas',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return params.data?.numBhas || ''
              },
            },
            {
              headerName: `Avg Drilled (${getUnitsText(UNITS_FOR.Depth)}/Day)`,
              field: 'avgDrilledPerDay',
              colId: 'avgDrilledPerDay',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.avgDrilledPerDay, 2))
              },
            },
            {
              headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
              field: 'totalDrilled',
              colId: 'totalDrilled',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.totalDrilled, 2))
              },
            },
            {
              headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
              colId: 'footage',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  type: 'bar',
                  paddingOuter: 0.25,
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: columnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotDrilled')],

                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide', params.data?.slideDrilled],
                  ['Rotate', params.data?.rotDrilled],
                ]
              },
            },
            {
              headerName: `1st ft-Last ft (Hrs)`,
              field: 'totalHrs',
              colId: 'totalHrs',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.totalHrs, 2))
              },
            },
            {
              headerName: `BRT (Hrs)`,
              field: 'brtHrs',
              colId: 'brtHrs',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.brtHrs, 2))
              },
            },
            {
              headerName: `Drilling (Hrs)`,
              field: 'drillingHrs',
              colId: 'drillingHrs',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.drillingHrs, 2))
              },
            },
            {
              headerName: `Total D&C (Hrs)`,
              field: 'drillingAndCircHrs',
              colId: 'drillingAndCircHrs',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.drillingAndCircHrs, 2))
              },
            },
            {
              headerName: `Drilling Hrs`,
              colId: 'footage',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  type: 'bar',
                  paddingOuter: 0.25,
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: columnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slideHrs', 'rotHrs')],
                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide', params.data?.slideHrs],
                  ['Rotate', params.data?.rotHrs],
                ]
              },
            },
            {
              headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
              colId: 'rop',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  paddingOuter: 0.5,
                  type: 'bar',
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: threeValColumnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slideRop', 'rotRop')],
                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide ROP', params.data?.slideRop],
                  ['Rotate ROP', params.data?.rotRop],
                  ['Avg ROP', params.data?.avgRop],
                ]
              },
            },
            {
              headerName: `Section Rop (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
              field: 'sectionRop',
              colId: 'sectionRop',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.sectionRop, 2))
              },
            },
            {
              headerName: `Slide/Rot %`,
              colId: 'footage',
              autoHeight: true,
              sortable: false,
              width: 140,
              filterParams: {
                excelMode: 'windows',
              },
              cellRenderer: 'agSparklineCellRenderer',
              cellRendererParams: {
                sparklineOptions: {
                  type: 'bar',
                  paddingOuter: 0.25,
                  tooltip: {
                    xOffset: 20,
                    yOffset: -20,
                    renderer: tooltipRenderer,
                  },
                  formatter: columnFormatter,
                  label: {
                    enabled: true,
                    placement: 'outsideEnd',
                    color: 'white',
                    fontSize: 9,
                  },
                  stroke: '#91cc75',
                  highlightStyle: {
                    fill: '#fac858',
                  },
                  valueAxisDomain: [0, getHighestValue('slidePerc', 'rotPerc')],

                  padding: {
                    top: 0,
                    bottom: 0,
                    right: 30,
                  },
                  axis: {
                    strokeWidth: 0,
                  },
                },
              },
              valueGetter: (params) => {
                return [
                  ['Slide', params.data?.slidePerc],
                  ['Rotate', params.data?.rotPerc],
                ]
              },
            },
            {
              headerName: `Max Temp (${getUnitsText(UNITS_FOR.Temperature)})`,
              field: 'maxBht',
              colId: 'maxBht',
              cellStyle: centerAlignCell,
              valueFormatter: (params) => {
                return numberWithCommas(round(params.data?.maxBht, 2))
              },
            },
            {
              headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
              field: 'holeSize',
              colId: 'holeSize',
              cellStyle: centerAlignCell,
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
              valueFormatter: (params) => {
                if (!Array.isArray(params.data?.multipleHoleSizes)) return round(params.data?.holeSize, 3)
                let holeSizeStr = ''
                for (let i = 0; i < params.data?.multipleHoleSizes.length; i++) {
                  if (params.data?.multipleHoleSizes[i] <= 0) continue
                  if (holeSizeStr !== '') holeSizeStr += ' / '

                  holeSizeStr += `${round(params.data?.multipleHoleSizes[i], 3)}`
                }

                return holeSizeStr
              },
            },
          ],
          defaultColDef: {
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            sortable: false,
            resizable: true,
            editable: false,
          },
        },
        getDetailRowData: (params) => {
          getStatsByHoleSize(params?.data?.well, params?.data?.phase, params.successCallback)
        },
      }
    },
    [getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const isRowMaster = useMemo(() => {
    return (data) => {
      if (!Array.isArray(data?.multipleHoleSizes)) return false
      return data ? data?.multipleHoleSizes?.length > 1 : false
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={data}
            columnDefs={sortColDefs(columnDefs, 'phaseKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
            isRowMaster={isRowMaster}
            headerHeight={30}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
            {
              icon: <Iconify icon='mdi:arrow-vertical-collapse' style={{ width: 28, height: 28 }} />,
              name: 'Collapse All',
              onClick: () => {
                expandAllNodes('collapse')
              },
            },
            {
              icon: <Iconify icon='material-symbols:expand' style={{ width: 28, height: 28 }} />,
              name: 'Expand All',
              onClick: () => {
                expandAllNodes('expand')
              },
            },
            {
              icon: <Iconify icon='mdi:format-list-group' style={{ width: 28, height: 28 }} />,
              name: groupRows ? 'Ungroup Rows' : 'Group Rows',
              onClick: () => {
                setGroupRows(!groupRows)
              },
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default PhaseKpiGrid
