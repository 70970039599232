import React, { useEffect, useRef, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { currentInfrastructureRdsSelector } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MemoryUtilizationCard = () => {
  const _isMounted = useRef(false)
  const infrastructureRdsData = useRecoilValue(currentInfrastructureRdsSelector)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('memoryUtilizationGrid', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('memoryUtilizationGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'MemoryUtilization.xlsx',
            sheetName: 'Memory',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'param',
        colId: 'param',
        cellStyle: leftAlignCell,
        minWidth: 100,
      },
      {
        field: 'value',
        colId: 'value',
        cellStyle: leftAlignCell,
        minWidth: 300,
      },
    ],
    [leftAlignCell],
  )

  const getTotalConnections = (connInfo) => {
    if (!connInfo) return 0
    if (Array.isArray(connInfo) === false) return 0
    if (connInfo.length === 0) return 0

    let totalConnections = 0
    for (let i = 0; i < connInfo.length; i++) {
      totalConnections += connInfo[i].connectionCount
    }

    return totalConnections
  }

  const createData = () => {
    return [
      { param: 'Available(mb)', value: `${infrastructureRdsData?.memoryUtilization?.availableMB}` },
      { param: 'Physical(mb)', value: `${infrastructureRdsData?.memoryUtilization?.physicalMB}` },
      { param: 'Mem State', value: `${infrastructureRdsData?.memoryUtilization?.systemMemoryState}` },
      { param: 'Page File Size', value: `${infrastructureRdsData?.memoryUtilization?.pageFilePhysicalSizeMB}` },
      { param: 'Page File Limit', value: `${infrastructureRdsData?.memoryUtilization?.pageFileCommitLimitMB}` },
      { param: 'Page Available', value: `${infrastructureRdsData?.memoryUtilization?.pageFileAvailableMB}` },
      { param: 'System Cache', value: `${infrastructureRdsData?.memoryUtilization?.systemCacheMB}` },
      { param: '# Connections', value: `${getTotalConnections(infrastructureRdsData?.connectionInfo)}` },
      {
        param: '# Applications',
        value: Array.isArray(infrastructureRdsData?.connectionInfo)
          ? `${infrastructureRdsData?.connectionInfo.length}`
          : '0',
      },
    ]
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={createData()}
        columnDefs={sortColDefs(columnDefs, 'memoryUtilizationGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={0}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default MemoryUtilizationCard
