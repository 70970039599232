import React, { useMemo } from 'react'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import UsersDonutChartJs from './UsersDonutChartJs'
import { daysSinceActive } from 'utils'

const StyledDonutContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  minWidth: '100px',
})

const UserCharts = ({ userData }) => {
  const usersPerCompany = (data) => {
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let orgList = []
    data.forEach((user) => {
      let index = orgList.findIndex((org) => org.label === user.organization)
      if (index >= 0) {
        orgList[index].value++
      }

      if (index < 0) {
        orgList.push({ label: user?.organization, value: 1 })
      }
    })
    return orgList
  }

  const activeUsersCount = (data) => {
    let activityCount = [
      { label: 'Active', type: 'activeUsersCount', value: 0 },
      { label: 'Inactive', type: 'activeUsersCount', value: 0 },
    ]

    if (!Array.isArray(data)) return activityCount
    if (data.length === 0) return activityCount

    data.forEach((user) => {
      if (user.active) activityCount[0].value += 1
      if (!user.active) activityCount[1].value += 1
    })

    return activityCount
  }

  const getActiveToday = (data) => {
    let activeToday = [
      { label: 'Today', totalUsers: 0, value: 0 },
      { label: 'Previously', value: 0 },
    ]
    if (!Array.isArray(data)) return activeToday
    if (data.length === 0) return activeToday

    data.forEach((user) => {
      let deltaDays = daysSinceActive(user.lastLogin)
      if (deltaDays === 0 || deltaDays === -1) {
        activeToday[0].value++
      } else {
        activeToday[1].value++
      }
    })

    return activeToday
  }

  const todaysActiveOrgs = (data) => {
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let orgUsersActivity = []
    data.forEach((user) => {
      let foundOrg = orgUsersActivity.find((org) => org.label === user.organization)
      let deltaDays = daysSinceActive(user.lastLogin)

      if (deltaDays === 0 || deltaDays === -1) {
        if (foundOrg) foundOrg.value++
        if (!foundOrg) orgUsersActivity.push({ label: user.organization, value: 1 })
      }
    })

    orgUsersActivity.sort((a, b) => {
      return b.value - a.value
    })

    return orgUsersActivity.length > 10 ? orgUsersActivity.slice(0, 10) : orgUsersActivity
  }

  const ActiveUsersDonut = ({ data }) => {
    let chartData = activeUsersCount(userData)

    return (
      <UsersDonutChartJs
        data={chartData}
        title={'Active Users'}
        legendLocation={'none'}
        showLegend={false}
        showTitle={true}
        showGradient={true}
        innerRadius='65%'
        padding='30%'
        centerText={chartData[0].value + '/' + (Array.isArray(data) ? data.length : 0)}
      />
    )
  }

  const ActiveTodayDonut = ({ data }) => {
    let chartData = getActiveToday(userData)

    return (
      <UsersDonutChartJs
        data={chartData}
        title={`Users Today`}
        legendLocation={'none'}
        showLegend={false}
        showTitle={true}
        showGradient={true}
        innerRadius='65%'
        padding='30%'
        centerText={chartData[0].value + '/' + (Array.isArray(data) ? data.length : 0)}
      />
    )
  }

  const MostActiveOrgsDonut = ({ data }) => {
    let chartData = todaysActiveOrgs(userData)

    let mostActiveOrg = { org: '', users: 0 }

    if (Array.isArray(chartData)) {
      for (let i = 0; i < chartData.length; i++) {
        if (chartData[i].value > mostActiveOrg.users) {
          mostActiveOrg.org = chartData[i].label
          mostActiveOrg.users = chartData[i].value
        }
      }
    }

    if (mostActiveOrg.org.length > 5) {
      mostActiveOrg.org = mostActiveOrg.org.slice(0, 5)
    }

    return (
      <UsersDonutChartJs
        data={chartData}
        title={`Most Active Org`}
        legendLocation={'none'}
        showLegend={false}
        showTitle={true}
        showGradient={true}
        innerRadius='65%'
        padding='30%'
        centerText={mostActiveOrg.org}
      />
    )
  }

  return useMemo(
    () => (
      <Box
        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '250px', width: '100%' }}>
        <StyledDonutContainer>
          <UsersDonutChartJs
            data={usersPerCompany(userData)}
            title={'Users Per Org'}
            legendLocation={'none'}
            showLegend={false}
            showTitle={true}
            showGradient={true}
            innerRadius='65%'
            padding='30%'
          />
        </StyledDonutContainer>
        <StyledDonutContainer>
          <ActiveUsersDonut data={userData} />
        </StyledDonutContainer>
        <StyledDonutContainer>
          <MostActiveOrgsDonut data={userData} />
        </StyledDonutContainer>
        <StyledDonutContainer>
          <ActiveTodayDonut data={userData} />
        </StyledDonutContainer>
      </Box>
    ),
    [userData],
  )
}

export default UserCharts
