import React from 'react'
import DonutChartJs from 'components/common/DonutChartJs'
import { Box } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { footagePerRigAtom } from 'atoms'
import FootageDashbaord from './FootageDashboard'

const getFootageData = (data) => {
  if (!data) return []
  if (!data.hasOwnProperty('drillingTotals')) return []

  return [
    { value: data.drillingTotals.slideFootage, color: '#F59B00', label: 'Slide' },
    { value: data.drillingTotals.rotateFootage, color: '#59FF00', label: 'Rotate' },
  ]
}

const getHoursData = (data) => {
  if (!data) return []
  if (!data.hasOwnProperty('drillingTotals')) return []

  return [
    { value: data.drillingTotals.slideHours, color: '#F59B00', label: 'Slide' },
    { value: data.drillingTotals.rotateHours, color: '#59FF00', label: 'Rotate' },
    { value: data.drillingTotals.offBTmCircHrs, color: '#FF00FF', label: 'Off Btm Circ' },
  ]
}

const getRopData = (data) => {
  if (!data) return []
  if (!data.hasOwnProperty('drillingTotals')) return []

  return [
    { value: data.drillingTotals.slideRop, color: '#F59B00', label: 'Slide' },
    { value: data.drillingTotals.rotateRop, color: '#59FF00', label: 'Rotate' },
    { value: data.drillingTotals.avgRop, color: '#FF00FF', label: 'Avg' },
  ]
}

const FootageDashboardPage = ({ filteredWells }) => {
  const footageData = useRecoilValue(footagePerRigAtom)

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ width: '85%', height: '100%' }}>
        <FootageDashbaord />
      </Box>
      <Box sx={{ width: '15%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: `${100 / 3}%`, width: '100%' }}>
          <DonutChartJs
            title={'Footage'}
            data={getFootageData(footageData)}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
        <Box sx={{ height: `${100 / 3}%`, width: '100%' }}>
          <DonutChartJs
            title={'Hours'}
            data={getHoursData(footageData)}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
        <Box sx={{ height: `${100 / 3}%`, width: '100%' }}>
          <DonutChartJs
            title={'ROP'}
            data={getRopData(footageData)}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default FootageDashboardPage
