import React, { useRef, useEffect, useState, useCallback } from 'react'
import { AdvancedMarker, useMap, useAdvancedMarkerRef, InfoWindow, Pin } from '@vis.gl/react-google-maps'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { isEqual } from 'lodash'
import Box from '@mui/material/Box'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const InfoBoxText = ({ text, isHeader = false }) => {
  return (
    <Box
      sx={{
        fontSize: 10,
        fontWeight: isHeader ? 'bold' : 'normal',
        whiteSpace: 'nowrap',
      }}>
      {text ? text : '-'}
    </Box>
  )
}

const CustomMarker = ({ well, clusterer, modifyMarkerRef, onMarkerRender }) => {
  const [refCallback, marker] = useAdvancedMarkerRef()
  const [infowindowOpen, setInfowindowOpen] = useState(false)

  useEffect(() => {
    if (!clusterer || !marker) return
    modifyMarkerRef(marker, well.id, 'add')
    onMarkerRender('add')

    return () => {
      onMarkerRender('remove')
      modifyMarkerRef(marker, well.id, 'remove')
    }
  }, [clusterer, marker]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPinColor = () => {
    if (well.hasOwnProperty('score')) {
      if (well.score < 0.5) return 'red'
      if (well.score < 0.7) return 'yellow'
      return 'green'
    }

    return 'red'
  }

  const pinColor = getPinColor()

  return (
    <React.Fragment>
      <AdvancedMarker
        position={{ lat: well.latitude, lng: well.longitude }}
        key={well.id}
        size={20}
        onClick={() => setInfowindowOpen(true)}
        ref={refCallback}>
        {pinColor !== 'red' ? <Pin background={pinColor} glyphColor={pinColor} borderColor={pinColor} /> : null}
      </AdvancedMarker>
      {infowindowOpen && well?.actualWell !== '' ? (
        <InfoWindow disableAutoPan={true} anchor={marker} maxWidth={200} onCloseClick={() => setInfowindowOpen(false)}>
          <Box
            sx={{
              background: 'white',
              border: `1px solid #ccc`,
              padding: '15px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: ' flex-start',
              position: 'relative',
            }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <InfoBoxText text={'Operator:'} isHeader={true} />
                <InfoBoxText text={'Well:'} isHeader={true} />
                <InfoBoxText text={'Job #:'} isHeader={true} />
                <InfoBoxText text={'Rig:'} isHeader={true} />
                <InfoBoxText text={'Region:'} isHeader={true} />
                <InfoBoxText text={'Status:'} isHeader={true} />
                <InfoBoxText text={'Lat:'} isHeader={true} />
                <InfoBoxText text={'Lng:'} isHeader={true} />
                <InfoBoxText text={'Depth:'} isHeader={true} />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '6px' }}>
                <InfoBoxText text={well.operator} isHeader={false} />
                <InfoBoxText text={well.actualWell} isHeader={false} />
                <InfoBoxText text={well.jobNum} isHeader={false} />
                <InfoBoxText text={well.rig} isHeader={false} />
                <InfoBoxText text={well.state + '/' + well.county} isHeader={false} />
                <InfoBoxText text={well.wellStatus} isHeader={false} />
                <InfoBoxText text={numberWithCommasDecimals(well.latitude, 3)} isHeader={false} />
                <InfoBoxText text={numberWithCommasDecimals(well.longitude, 3)} isHeader={false} />
                <InfoBoxText
                  text={well.currentDepth === '' ? '' : numberWithCommasDecimals(well.currentDepth, 2)}
                  isHeader={false}
                />
              </Box>
            </Box>
          </Box>
        </InfoWindow>
      ) : null}
    </React.Fragment>
  )
}

const Markers = ({ wells }) => {
  const map = useMap()
  const clusterer = useRef(null)
  const markerRef = useRef({})
  const [markersRendered, setMarkersRendered] = useState(0)
  const previousWellsRef = useRef(wells)

  useEffect(() => {
    if (!map) return
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map })
    }
  }, [map])

  useEffect(() => {
    if (clusterer.current && markersRendered === wells.length) {
      clusterer.current?.clearMarkers()
      clusterer.current?.addMarkers(Object.values(markerRef.current))
    }
  }, [markersRendered, wells.length])

  const modifyMarkerRef = useCallback((marker, key, action) => {
    if (!marker) return
    if (action === 'add') {
      markerRef.current[key] = marker
    } else {
      delete markerRef.current[key]
    }
  }, [])

  const handleMarkerRender = useCallback((action) => {
    if (action === 'add') setMarkersRendered((prev) => prev + 1)
    if (action === 'remove') setMarkersRendered((prev) => prev - 1)
  }, [])

  useEffect(() => {
    if (isEqual(previousWellsRef.current, wells)) {
      previousWellsRef.current = wells
      setMarkersRendered(0)
      markerRef.current = {}
    }
  }, [wells])

  return (
    <React.Fragment>
      {Array.isArray(wells)
        ? wells.map((well) => (
            <CustomMarker
              onMarkerRender={handleMarkerRender}
              modifyMarkerRef={modifyMarkerRef}
              key={well.id}
              well={well}
              clusterer={clusterer.current}
            />
          ))
        : null}
    </React.Fragment>
  )
}

export default Markers
