export function checkDateRange(jobStart, jobEnd, filterStart, filterEnd) {
  if (jobEnd < filterStart || jobStart > filterEnd) return 0
  if (jobStart <= filterStart && jobEnd >= filterEnd) return filterEnd - filterStart
  if (jobStart >= filterStart && jobEnd <= filterEnd) return jobEnd - jobStart
  if (jobStart <= filterStart && jobEnd >= filterStart && jobEnd <= filterEnd) return jobEnd - filterStart
  if (jobStart >= filterStart && jobStart <= filterEnd && jobEnd >= filterEnd) return filterEnd - jobStart

  return 0
}

export function isoStringToNumeric(isoDateTime) {
  if (!isoDateTime) return 0
  let dt = new Date(isoDateTime)
  return dt.getTime()
}

export function numberToTimeString(timeMilisecs) {
  if (!timeMilisecs) return '00:00'
  const dt = new Date(timeMilisecs)
  return dt.toLocaleTimeString()
}

export function isoStringToDateString(isoDateTime) {
  if (isNaN(Date.parse(isoDateTime))) return ''
  let dt = new Date(isoDateTime)
  let output = dt.toLocaleDateString(undefined, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  return output
}

export function isDateValid(isoDateString) {
  if (!isoDateString) return false
  if (typeof isoDateString !== 'string') return false
  if (isoDateString === '') return false
  if (isoStringToDateString(isoDateString).indexOf('Invalid') >= 0) return false
  return true
}

export function IsoDateTimeStringToDateString(isoDateTime) {
  if (!isDateValid(isoDateTime)) return '1900-01-01'
  return new Date(Date.parse(isoDateTime)).toISOString().substring(0, 10)
}

export function createserialTime(timeIn) {
  if (typeof timeIn !== 'string') return 0
  if (timeIn.length < 5) return 0
  if (timeIn.length > 5) timeIn = timeIn.substring(0, 5)
  if (timeIn.indexOf(':') < 0) return 0

  let hours = parseFloat(timeIn.substring(0, 2))
  let mins = parseFloat(timeIn.substring(3, 5))
  return hours + mins / 60
}

export function getDeltaTimeSecondsFromCurrent(t2) {
  let currentDate = new Date(Date.now()).toISOString()
  return getDeltaIsoTime(t2, currentDate, true) * 3600
}

export function isoStringToTimeString(isoDateTime) {
  if (typeof isoDateTime !== 'string' && typeof isoDateTime !== 'object') return null
  if (isNaN(Date.parse(isoDateTime))) return ''

  let dt = new Date(Date.parse(isoDateTime.replace('Z', '')))
  return getTimeString(dt.getHours()) + ':' + getTimeString(dt.getMinutes())
}

export function getDeltaIsoTime(t1, t2, returnDecimal) {
  let d1 = new Date(Date.parse(t1))
  let d2 = new Date(Date.parse(t2))

  if (d1 && d2) {
    let d3 = new Date(d2 - d1)
    let d0 = new Date(0)

    let deltaHours = 0
    let deltaMins = 0
    let deltaSecs = 0

    if (d0 && d3) {
      deltaHours = d3.getHours() - d0.getHours()
      deltaMins = d3.getMinutes() - d0.getMinutes()
      deltaSecs = d3.getSeconds() - d0.getSeconds()

      if (deltaHours < 0) {
        deltaHours = 24 + (deltaHours + deltaMins / 60)
        deltaMins = (deltaHours - Math.floor(deltaHours)) * 60
        deltaHours = Math.floor(deltaHours)
      }
    }

    if (returnDecimal === true) return deltaHours + deltaMins / 60 + deltaSecs / 3600

    return (
      getTimeString(Math.round(deltaHours)) +
      ':' +
      getTimeString(Math.round(deltaMins) + ':' + getTimeString(Math.round(deltaSecs)))
    )
  }

  if (returnDecimal === true) return 0

  return '00:00'
}

export function getDeltaIsoDays(t1, t2) {
  let d1 = new Date(t1)
  let d2 = new Date(t2)

  if (d1 && d2) {
    let diffTime = d2 - d1
    let diffDays = diffTime / (1000 * 60 * 60 * 24)
    return diffDays
  }

  return 0
}

export function getDeltaTime(t1, t2, returnDecimal) {
  let d1 = new Date(Date.parse('2020-01-02T' + t1))
  let d2 = new Date(Date.parse('2020-01-02T' + t2))

  if (d1 && d2) {
    let d3 = new Date(d2 - d1)
    let d0 = new Date(0)

    let deltaHours = 0
    let deltaMins = 0
    let deltaDays = 0

    if (d0 && d3) {
      deltaDays = d3.getDay() - d0.getDay()
      deltaHours = d3.getHours() - d0.getHours()
      deltaMins = d3.getMinutes() - d0.getMinutes()
      if (deltaHours === 0 && deltaDays > 0) deltaHours += 24
      if (deltaHours < 0) {
        deltaHours = 24 + (deltaHours + deltaMins / 60)
        deltaMins = (deltaHours - Math.floor(deltaHours)) * 60
        deltaHours = Math.floor(deltaHours)
      }
    }

    if (returnDecimal === true) return deltaHours + deltaMins / 60
    return getTimeString(Math.round(deltaHours)) + ':' + getTimeString(Math.round(deltaMins))
  } else {
    if (returnDecimal === true) return 0

    return '00:00'
  }
}

function getTimeString(timeIn) {
  if (timeIn < 10) return '0' + timeIn
  return timeIn
}

export function getCurrentIsoDateString(deltaDays, dateOnly) {
  let dt = new Date()
  if (deltaDays) {
    dt = new Date(Date.now() + parseFloat(deltaDays) * 24 * 60 * 60 * 1000)
  }

  if (dateOnly === true) return dt.toISOString().substring(0, 10)
  return dt.toISOString()
}

export function timeSince(dateMs) {
  var seconds = Math.floor((new Date() - dateMs) / 1000)

  var interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + 'Y'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + 'M'
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + 'D'
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + 'h'
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + 'm'
  }
  return Math.floor(seconds) + 's'
}

export function isoDateFromUnkn(inputStr, units) {
  let splitStr = inputStr.split('/')

  let output = { day: -1, month: -1, year: -1 }
  let dayIndex = 0
  let monthIndex = 1

  if (units === 'US') {
    dayIndex = 1
    monthIndex = 0
  }

  splitStr.forEach((val, i) => {
    if (i === dayIndex) output.day = parseInt(val)
    if (i === monthIndex) output.month = parseInt(val)
    if (i === 2) output.year = parseInt(val)
  })

  return `${output.year}-${getTimeString(output.month)}-${getTimeString(output.day)}`
}

export function getBHAdeltaHours(dateIn, dateOut, timeIn, timeOut, units) {
  let output = { dateIn: '', dateOut: '', deltaHrs: 0 }
  if (!dateIn || !dateOut) return output
  if (dateIn.indexOf('/') < 0) return output
  if (dateOut.indexOf('/') < 0) return output
  if (!timeIn) timeIn = '00:00'
  if (!timeOut) timeOut = '00:00'

  let dateInStr = `${isoDateFromUnkn(dateIn, units)}T${timeIn}`
  let dateOutStr = `${isoDateFromUnkn(dateOut, units)}T${timeOut}`

  let d1 = new Date(Date.parse(dateInStr))
  let d2 = new Date(Date.parse(dateOutStr))

  output.dateIn = `${d1.toLocaleDateString()}-${timeIn}`
  output.dateOut = `${d2.toLocaleDateString()}-${timeOut}`
  output.deltaHrs = getDeltaIsoDays(dateInStr, dateOutStr) * 24
  return output
}

export function daysSinceActive(dateIn) {
  let d = new Date()
  let dtOffset = d.getTimezoneOffset() * 60 * 1000
  let curTimeTicks = d.getTime() - dtOffset
  d = new Date(curTimeTicks)
  d.setUTCMinutes(0, 0)
  d.setUTCHours(0)

  let dateNow = d.toUTCString()
  let deltaHours = getDeltaIsoDays(dateIn, dateNow, true)
  return Math.round(deltaHours)
}

export function isoDateAddition(dateIn, days, dateOnly) {
  if (!dateIn || typeof dateIn !== 'string') return ''
  if (!days || typeof days !== 'number') return dateIn

  let startDate = new Date(Date.parse(dateIn))
  let msPerDay = 86400000
  let deltaDaysMs = msPerDay * days
  let newDateMs = startDate.getTime() + deltaDaysMs
  let newDate = new Date(newDateMs)

  if (dateOnly) return newDate.toISOString().substring(0, 10)
  return newDate.toISOString()
}

export function getDaysText(daysIn, alwaysPos) {
  if (daysIn === null || daysIn === undefined) return ''
  if (typeof daysIn === 'string') daysIn = parseFloat(daysIn)
  let weeks = Math.abs(daysIn) / 7
  let years = Math.abs(daysIn) / 365

  if (daysIn < 0) {
    daysIn = Math.abs(daysIn)
    if (weeks >= 1 && weeks <= 2) return '(' + Math.round(weeks).toString() + ' week)'
    if (weeks >= 1 && years < 1) return '(' + Math.round(weeks).toString() + ' weeks)'
    if (years >= 1 && years <= 2) return '(' + Math.round(years).toString() + ' year)'
    if (years > 2) return '(' + Math.round(years).toString() + ' years)'
    if (daysIn >= 1 && daysIn < 2) return '(' + Math.round(daysIn).toString() + ' day)'
    return '(' + Math.round(daysIn).toString() + ' days)'
  }

  if (weeks < 1) {
    if (daysIn < 1) return '(Today)'
    if (daysIn >= 1 && daysIn <= 2) return `${alwaysPos !== true ? '(Yesterday)' : '(1 day)'}`
    if (daysIn > 2) return `(${daysIn} days ${alwaysPos !== true ? 'ago' : ''})`
  }

  if (weeks >= 1 && years < 1) {
    if (weeks < 2) return `${alwaysPos !== true ? '(last week)' : '(1 week)'}`
    if (weeks >= 2) return `(${Math.round(weeks)} weeks ${alwaysPos !== true ? 'ago' : ''})`
  }

  if (years >= 1) {
    if (years < 2) return `${alwaysPos !== true ? '(1 year ago)' : '(1 year)'}`
    if (years >= 2) return `(${Math.round(years)} years ${alwaysPos !== true ? 'ago' : ''})`
  }

  if (daysIn > 7 && daysIn < 365) return `(${Math.round(daysIn / 7)} weeks ${alwaysPos !== true ? 'ago' : ''})`
  if (daysIn > 365) return
}

export function extractDateFromISOString(isoDateString) {
  const dateObj = new Date(isoDateString)

  if (isNaN(dateObj.getTime())) {
    return '1900-01-01'
  }

  const year = dateObj.getFullYear()
  const month = String(dateObj.getMonth() + 1).padStart(2, '0')
  const day = String(dateObj.getDate()).padStart(2, '0')
  const extractedDate = `${year}-${month}-${day}`
  return extractedDate
}

export function createDateArray(minDate, maxDate) {
  if (typeof minDate !== 'string') return []
  if (typeof maxDate !== 'string') return []
  let d1 = new Date(Date.parse(minDate + 'T00:00:01'))
  let d2 = new Date(Date.parse(maxDate + 'T00:00:01'))
  if (d1 > d2) {
    let d = d1
    d1 = d2
    d2 = d
  }

  let dateArray = []
  for (let d = d1; d <= d2; d.setDate(d.getDate() + 1)) {
    let newDateString = new Date(d).toISOString()
    dateArray.push(newDateString.substring(0, 10))
  }

  return dateArray
}

export function dateAdd(date, interval, units) {
  if (!(date instanceof Date)) return undefined
  var ret = new Date(date) //don't change original date
  var checkRollover = function () {
    if (ret.getDate() !== date.getDate()) ret.setDate(0)
  }
  switch (String(interval).toLowerCase()) {
    case 'year':
      ret.setFullYear(ret.getFullYear() + units)
      checkRollover()
      break
    case 'quarter':
      ret.setMonth(ret.getMonth() + 3 * units)
      checkRollover()
      break
    case 'month':
      ret.setMonth(ret.getMonth() + units)
      checkRollover()
      break
    case 'week':
      ret.setDate(ret.getDate() + 7 * units)
      break
    case 'day':
      ret.setDate(ret.getDate() + units)
      break
    case 'hour':
      ret.setTime(ret.getTime() + units * 3600000)
      break
    case 'minute':
      ret.setTime(ret.getTime() + units * 60000)
      break
    case 'second':
      ret.setTime(ret.getTime() + units * 1000)
      break
    default:
      ret = undefined
      break
  }
  return ret
}

export function isoStringToLocaleStr(input, is24Hr = true, incTime = true, incSeconds = false) {
  if (!input) return ''
  if (typeof input !== 'string') return ''

  let dateStr = new Date(Date.parse(input)).toLocaleDateString('en-US')
  let timeStr = new Date(Date.parse(input)).toLocaleTimeString('en-US')

  if (!incTime) return dateStr
  if (!incSeconds) {
    timeStr = new Date(Date.parse(input)).toLocaleTimeString('en-US', { timeStyle: 'short', hour12: !is24Hr })
  }

  return `${dateStr} ${timeStr}`
}

export function getDayOfWeekText(nDay, short = false) {
  if (typeof nDay !== 'number') return ''
  if (nDay < 0 || nDay > 6) return ''
  if (short) {
    switch (nDay) {
      case 0:
        return 'Sun'
      case 1:
        return 'Mon'
      case 2:
        return 'Tue'
      case 3:
        return 'Wed'
      case 4:
        return 'Thur'
      case 5:
        return 'Fri'
      case 6:
        return 'Sat'

      default:
        return ''
    }
  }

  if (!short) {
    switch (nDay) {
      case 0:
        return 'Sunday'
      case 1:
        return 'Monday'
      case 2:
        return 'Tuesday'
      case 3:
        return 'Wednesday'
      case 4:
        return 'Thursday'
      case 5:
        return 'Friday'
      case 6:
        return 'Saturday'

      default:
        return ''
    }
  }

  return ''
}

export function isoToTimeDayString(inputStr) {
  if (typeof inputStr !== 'string') return ''
  let deltaDays = daysSinceActive(inputStr)
  if (deltaDays === 0) return isoStringToTimeString(inputStr)
  let d = new Date(Date.parse(inputStr))
  if (deltaDays <= 7) return getDayOfWeekText(d.getDay(), true)

  return d.toLocaleDateString()
}

export function isoStringToLocaleTimeString(isoDateTime) {
  if (typeof isoDateTime !== 'string') return null
  let dt = new Date(Date.parse(isoDateTime.replace('', '')))
  return dt.toLocaleTimeString()
}

export function isoDateTimeToDateTimeString(isoDateTime) {
  let dt = isoStringToDateString(isoDateTime)
  if (dt === null) return ''
  let tm = isoStringToLocaleTimeString(isoDateTime)
  return `${dt} ${tm}`
}
