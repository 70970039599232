import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useInnovaTheme from './hooks/useInnovaTheme'

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, onNo = null } = props
  const { getTextColor } = useInnovaTheme()

  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ color: getTextColor() }}>{children}</DialogContent>
      <DialogActions>
        <Button
          variant='outlined'
          onClick={() => {
            setOpen(false)
            if (onNo) onNo()
          }}
          color='secondary'>
          No
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
          color='primary'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog
