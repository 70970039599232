import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import GridLines from 'components/ThreeDeeView/ChartComponents/Grid'
import Targets from 'components/ThreeDeeView/ChartComponents/Targets'
import { DrillersTargets } from 'components/ThreeDeeView/ChartComponents/Targets'
import OrientationCompass from 'components/ThreeDeeView/Controls/OrientationCompass'
import { OrbitControls } from '@react-three/drei'
import { getTargets3D } from 'components/ThreeDeeView/Utils/targets3d'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3 } from 'three'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SCALE = 0.1
const LABEL_COLOR = '#909090'
const INITIAL_CAM_OFFSET = 30

function ChartControls({ target, cameraTarget }) {
  const { camera } = useThree()
  const ref = useRef(null)

  const changeCamPostion = () => {
    let newCamTarget = new Vector3(0, 0, 0)

    if (target.current) {
      newCamTarget = new Vector3(target.current.x, target.current.y, target.current.z)
    }

    let camOffset = camera.position.clone().sub(cameraTarget.current)
    let newCamPosition = camOffset.clone().add(newCamTarget)

    cameraTarget.current = cloneDeep(newCamTarget)

    camera.lookAt(newCamTarget)
    camera.position.x = newCamPosition.x
    camera.position.y = newCamPosition.y
    camera.position.z = newCamPosition.z
    ref.current.target = newCamTarget
    ref.current.target0 = newCamTarget
    ref.current.position0 = newCamPosition
    ref.current.update()
  }

  useFrame(() => {
    changeCamPostion()
  })

  return <OrbitControls ref={ref} args={[camera]} />
}

const TargetsThreeDeeChart = ({ targetData, isLoading = false }) => {
  const cameraTargetRef = useRef(new Vector3(0, 0, 0))
  const targetDataRef = useRef([])
  const [chartData, setChartData] = useState([])
  const { getBackColor, theme } = useInnovaTheme()

  useEffect(() => {
    if (!targetData) return
    let calcTargets = getTargets3D([targetData], SCALE)

    targetDataRef.current = Array.isArray(calcTargets) && calcTargets.length > 0 ? cloneDeep(calcTargets[0]) : null
    setChartData(calcTargets)
  }, [targetData]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Canvas
        style={{ width: '100%', height: '100%', background: getBackColor() }}
        flat
        camera={{ position: [-INITIAL_CAM_OFFSET, INITIAL_CAM_OFFSET / 2, 5], fov: 24, near: 0.1, far: 5000 }}
        onCreated={({ camera }) => {
          camera.lookAt(0, 0, 0)
        }}>
        <ChartControls target={targetDataRef} cameraTarget={cameraTargetRef} />
        <OrientationCompass cameraTarget={cameraTargetRef} />
        <ambientLight color={'#FFFFFF'} intensity={1} />
        <pointLight color={0xffffbb} intensity={5} distance={100} position={[-1.5, 2.5, 0]} />
        <pointLight color={0xffffbb} intensity={5} distance={100} position={[1.5, 2.5, 0]} />
        <GridLines
          scale={SCALE}
          tgtData={targetDataRef.current}
          labelColor={theme === 'dark' ? 0x909090 : 0x000000}
          gridColor={theme === 'dark' ? 0x909090 : 0x000000}
        />
        <Targets labelColor={LABEL_COLOR} targets={chartData} display={true} />
        <DrillersTargets labelColor={LABEL_COLOR} targets={chartData} display={true} />
      </Canvas>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
    </div>
  )
}

export default TargetsThreeDeeChart
