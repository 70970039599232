import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { Box, IconButton, Snackbar } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, htmlSymbolHandling, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals, uuidv4 } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const OperatorWarningGrid = ({ operatorName, operatorDropDownData, gridType }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const inputRow = useRef({})
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme } = useInnovaTheme()

  const getWarningGrid = useInnovaAxios({
    url: '/operator/getWarningGrid',
  })

  const updateWarningGrid = useInnovaAxios({
    url: '/operator/updateWarningGrid',
  })

  const deleteWarningGrid = useInnovaAxios({
    url: '/operator/deleteWarningGrid',
  })

  const addWarningGrid = useInnovaAxios({
    url: '/operator/addWarningGrid',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const fetchWarningGridData = async () => {
    if (isLoading) return
    setLoading(true)

    const response = await getWarningGrid({ operator: operatorName })

    if (response?.error) return
    if (!_isMounted.current) return
    if (!Array.isArray(response.data)) return

    for (let i = 0; i < response.data.length; i++) {
      response.data[i].uid = uuidv4()
    }

    gridApi.current.setGridOption('rowData', Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchWarningGridData()
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.node, event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const reqFields = useMemo(() => ['name', 'ratio'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [centerAlignCell, isEmptyPinnedCell, createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('operatorWarningGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    if (inputRow.current.name === '') return
    inputRow.current.active = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    isAdding.current = true
    let res = await addWarningGrid({ ...inputRow.current, operator: operatorName })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [operatorName, addWarningGrid, isPinnedRowDataCompleted])

  const handleUpdate = async (node, data) => {
    if (!node) return
    if (!data) return
    if (!data.hasOwnProperty('name')) return
    if (!node.hasOwnProperty('rowIndex')) return

    if (isUpdating.current) return
    data = htmlSymbolHandling(data)

    isUpdating.current = true
    let res = await updateWarningGrid({ ...data, operator: operatorName, rowNum: node.rowIndex })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res?.error) return
    if (!gridApi.current) return

    gridApi.current.applyTransaction({ update: [data] })
  }

  const handleDelete = useCallback(
    async (node, data) => {
      if (!data) return
      if (!node.hasOwnProperty('rowIndex')) return
      if (isDeleting.current) return
      isDeleting.current = true
      let res = await deleteWarningGrid({ operator: operatorName, rowNum: node.rowIndex })
      isDeleting.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          remove: [data],
        })
      }
    },
    [deleteWarningGrid, operatorName],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() =>
              params.node?.rowPinned !== 'bottom' ? handleDelete(params.node, params.data) : handleAddRow()
            }
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  const getTypeDropDowns = useCallback(() => {
    const { warningTypes } = operatorDropDownData
    return Array.isArray(warningTypes) ? warningTypes : []
  }, [operatorDropDownData])

  let columnDefs = useMemo(() => {
    let cols = [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        valueGetter: (params) => unescapeHtml(params.data?.name),
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
      },
      {
        field: 'ratio',
        colId: 'ratio',
        headerName: 'Ratio',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Ratio...'
          return params.value ? numberWithCommasDecimals(params.value, 2) : ''
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 5,
          precision: 3,
        },
      },
    ]

    if (gridType === 'Rules Based') {
      cols.push(
        {
          field: 'maxRadius',
          colId: 'maxRadius',
          headerName: 'Max Radius',
          valueFormatter: (params) => (params.value ? numberWithCommasDecimals(params.value, 2) : ''),
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            min: 0,
            max: 20000,
            precision: 2,
          },
        },
        {
          headerName: 'Type',
          colId: 'type',
          field: 'type',
          cellEditor: 'agRichSelectCellEditor',
          cellEditorPopup: true,
          cellEditorParams: {
            values: [...getTypeDropDowns()],
          },
          valueGetter: (params) => {
            if (!params.data?.type) return 'Error Ratio'
            return params.data.type
          },
        },
        {
          field: 'active',
          colId: 'active',
          headerName: 'Active',
          cellEditor: 'agCheckboxCellEditor',
          cellRenderer: 'agCheckboxCellRenderer',
        },
      )
    }

    return cols
  }, [getTypeDropDowns, actionIconRenderer, gridType, isEmptyPinnedCell])

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: '100%',
        marginLeft: 0,
        width: '100%',
        maxWidth: `100%`,
        maxHeight: `100%`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}></Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'operatorWarningGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            getRowId={getRowId}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default OperatorWarningGrid
