import React, { useEffect, useState, useCallback, useMemo } from 'react'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout'
import { useResizeDetector } from 'react-resize-detector'
import { Icon as Iconify } from '@iconify/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import DashboardComponent from './DashboardComponent'
import MenuButton from 'components/common/MenuButton'
import SimplePickListDialog from 'components/common/SimplePickListDialog'
import useWellData from 'components/common//hooks/useWellData'
import useInnovaTheme from '../hooks/useInnovaTheme'

const layoutIds = ['xl', 'lg', 'md', 'sm', 'xs', 'xxs']

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const ResponsiveDashboard = ({
  uid,
  defaultLayout,
  components,
  showMenu = true,
  columns = { xl: 8, lg: 8, md: 4, sm: 2, xs: 2, xxs: 2 },
}) => {
  const [layouts, setLayouts] = useState(getItemFromLS(uid, 'layouts') || defaultLayout)
  // eslint-disable-next-line no-unused-vars
  const [breakpoint, setBreakpoint] = useState('lg')
  const { width: gridWidth, ref: dashRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 })
  const [renderedComponents, setRenderedComponents] = useState()
  const [showCardsModal, setShowCardsModal] = useState(false)
  const { refreshWellData } = useWellData()
  const { theme, getBackColor } = useInnovaTheme()

  useEffect(() => {
    refreshWellData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Array.isArray(components) || components.length === 0) return
    setRenderedComponents(components)
    setLayouts(getItemFromLS(uid, 'layouts') || defaultLayout)
  }, [components, uid, defaultLayout])

  const handleBreakpointChange = (breakpoint) => {
    setBreakpoint(breakpoint)
  }

  const handleLayoutChange = useCallback(
    (currentLayout, allLayouts) => {
      if (!Array.isArray(currentLayout) || currentLayout.length === 0) return
      saveItemToLS(uid, 'layouts', allLayouts)
    },
    [uid],
  )

  const getCompIndexInLayout = useCallback(
    (key) => {
      let index = -1
      layoutIds.forEach((size) => {
        if (layouts[size]) {
          let currentLayout = layouts[size]
          const idx = currentLayout.findIndex((component) => component.i === key)
          if (idx >= 0) {
            index = idx
          }
        }
      })
      return index
    },
    [layouts],
  )

  // callback for a card to tell it's been closed
  const onComponentClosed = useCallback(
    (key) => {
      if (!key) return
      if (key?.length < 1) return
      let newLayout = {}
      layoutIds.forEach((size) => {
        if (layouts[size]) {
          let currentLayout = layouts[size]
          const idx = currentLayout.findIndex((component) => component.i === key)
          if (idx >= 0) {
            currentLayout.splice(idx, 1)
            newLayout[size] = [...currentLayout]
          }
        }
      })
      let idx = renderedComponents.findIndex((component) => component.key === key)
      if (idx >= 0) {
        renderedComponents[idx].visible = false
      }

      saveItemToLS(uid, 'layouts', newLayout)
      setLayouts(newLayout)
    },
    [layouts, renderedComponents, uid],
  )

  const resetLayout = () => {
    saveItemToLS(uid, 'layouts', defaultLayout)
    setLayouts(defaultLayout)
  }

  const getCompIndexByTitle = (title) => {
    let index = -1

    const comp = components.find((comp) => comp.title === title)
    if (!comp) {
      return index
    }

    layoutIds.forEach((size) => {
      if (layouts[size]) {
        let currentLayout = layouts[size]
        const idx = currentLayout.findIndex((element) => element.i === comp.key)
        if (idx >= 0) {
          index = idx
        }
      }
    })
    return index
  }

  const applySelectCards = async (selectedCards) => {
    if (!selectedCards || !Array.isArray(selectedCards) || selectedCards.legnth < 1) {
      return
    }

    let newLayouts = { ...layouts }

    //Add new cards to layout
    selectedCards.forEach((card) => {
      const idx = getCompIndexByTitle(card)
      if (idx === -1) {
        // card not displayed, so add to layout
        const comp = components.find((comp) => comp.title === card)

        let idx = renderedComponents.findIndex((component) => component.key === comp.key)
        if (idx >= 0) {
          renderedComponents[idx].visible = true
        }

        layoutIds.forEach((size) => {
          if (layouts[size]) {
            newLayouts[size] = newLayouts[size].concat({
              i: comp.key,
              x: 0,
              y: Infinity,
              w: 2,
              h: 2,
            })
          }
        })
      }
    })

    //Remove cards not displayed
    components.forEach((comp) => {
      let shouldBeVisible = selectedCards.findIndex((card) => card === comp.title)
      let isVisible = getCompIndexByTitle(comp.title)

      if (shouldBeVisible < 0 && isVisible >= 0) {
        //Card is visible hide card
        let idx = renderedComponents.findIndex((component) => component.key === comp.key)
        if (idx >= 0) {
          renderedComponents[idx].visible = false
        }

        layoutIds.forEach((size) => {
          if (layouts[size]) {
            const idxLayout = newLayouts[size].findIndex((element) => element.i === comp.key)
            newLayouts[size].splice(idxLayout, 1)
          }
        })
      }
    })

    saveItemToLS(uid, 'layouts', newLayouts)
    setLayouts(newLayouts)
  }

  const getAllCards = () => {
    let cards = []
    components.forEach((component) => {
      cards.push(component.title)
    })
    return cards
  }

  const getRenderedCards = () => {
    let cards = []
    renderedComponents.forEach((component) => {
      if (getCompIndexInLayout(component.key) >= 0) {
        cards.push(component.title)
      }
    })
    return cards
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <Iconify icon='dashicons:menu-alt3' />,
        name: 'Select Cards',
        onClick: () => setShowCardsModal(!showCardsModal),
      },
      {
        icon: <Iconify icon='lucide:layout-dashboard' />,
        name: 'Reset Layout',
        onClick: () => resetLayout(),
      },
      { icon: <RefreshIcon />, name: 'Refresh', onClick: refreshWellData },
    ]
    return actions
  }

  const GridCards = useMemo(() => {
    if (!Array.isArray(renderedComponents)) return null

    return renderedComponents.map((comp) => {
      if (getCompIndexInLayout(comp.key) >= 0) {
        return (
          <Box
            key={comp.key}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: getBackColor(),
              border: '1px solid #000',
            }}>
            <DashboardComponent
              compkey={comp.key}
              title={comp.title}
              TitleComponent={comp.TitleComponent}
              titleLine2={comp.titleLine2}
              titleLine3={comp.titleLine3}
              Component={comp.Component}
              customprops={comp?.customprops || {}}
              titleprops={comp?.titleprops || {}}
              onClose={onComponentClosed}
              showCloseButton={comp?.hasOwnProperty('showCloseButton') ? comp?.showCloseButton : true}
            />
          </Box>
        )
      } else {
        return null
      }
    })
  }, [getCompIndexInLayout, renderedComponents, onComponentClosed, getBackColor])

  const validateLayouts = (layoutsIn) => {
    let newLayouts = { ...layoutsIn }
    layoutIds.forEach((size) => {
      if (layoutsIn[size]) {
        let currentLayout = layoutsIn[size]
        currentLayout.forEach((component) => {
          if (typeof component.x !== 'number') {
            component.x = 0
          }

          if (typeof component.y !== 'number') {
            component.y = 0
          }

          if (typeof component.w !== 'number') {
            component.w = 0
          }

          if (typeof component.h !== 'number') {
            component.h = 0
          }

          if (component.x < 0) {
            component.x = 0
          }

          if (component.y < 0) {
            component.y = 0
          }

          if (component.w < 1) {
            component.w = 1
          }

          if (component.h < 1) {
            component.h = 1
          }
        })
        newLayouts[size] = [...currentLayout]
      }
    })
    return newLayouts
  }

  return (
    <Box
      sx={{ display: 'flex', width: '100%', height: '100%', backgroundColor: theme === 'dark' ? '#111' : '#fff' }}
      ref={dashRef}>
      {showCardsModal ? (
        <SimplePickListDialog
          title='Select Dashboard Cards'
          sectionHeader='Cards'
          open={showCardsModal}
          setOpen={() => setShowCardsModal(false)}
          onApply={applySelectCards}
          items={getAllCards()}
          initSelItems={getRenderedCards()}
        />
      ) : null}
      <ResponsiveGridLayout
        className='layout'
        width={gridWidth ? gridWidth : 800}
        layouts={validateLayouts(layouts)}
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={handleBreakpointChange}
        autoSize={true}
        isDraggable
        isRearrangble
        isResizeable
        useCSSTransforms
        rowHeight={325}
        breakpoints={{ xl: 2560, lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
        cols={columns}
        draggableHandle={'.dragHandle'}
        margin={[4, 4]}>
        {GridCards}
      </ResponsiveGridLayout>
      {showMenu ? (
        <StyledMenuIcon>
          <MenuButton actions={getMenuItems()} />
        </StyledMenuIcon>
      ) : null}
    </Box>
  )
}

export default ResponsiveDashboard
