import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { uuidv4 } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import { debounce } from 'lodash'
import useInnovaInstallTypes from 'components/common/hooks/useInnovaInstallTypes'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InnovaSoftwareDetailsPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [softwareDetailsData, setSoftwareDetailsData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedSoftwareDetail = useRef(null)
  const rawData = useRef([]) //required because data doesnt have a uid
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dropDowns] = useInnovaAdminDropDowns(false)
  const [installTypes] = useInnovaInstallTypes(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)

  const getData = useInnovaAxios({
    url: '/admin/softwareDetails/getSoftwareDetails',
  })

  const deleteItem = useInnovaAxios({
    url: '/admin/softwareDetails/deleteSoftwareDetail',
  })

  const addItem = useInnovaAxios({
    url: '/admin/softwareDetails/createSoftwareDetail',
  })

  const updateItem = useInnovaAxios({
    url: '/admin/softwareDetails/updateSoftwareDetail',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaSoftwareDetailsPageKey)
    fetchSoftwareDetailsData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchSoftwareDetailsData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData()

    if (response?.error) return
    if (!_isMounted.current) return

    if (!Array.isArray(response.data)) return

    let dataCopy = cloneDeep(response.data)
    for (let i = 0; i < dataCopy.length; i++) {
      dataCopy[i].uid = uuidv4()
    }

    dataCopy.sort((a, b) => {
      return a.company.localeCompare(b.company)
    })

    rawData.current = dataCopy //Keeps a copy of the raw data for updates
    setSoftwareDetailsData(dataCopy)
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onExpandOrCollapseAll: () => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    const reqFields = ['company', 'product', 'paymentSchedule', 'licenseType', 'installType', 'billingStartDate']
    if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('innovaSoftwareDetailsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaSoftwareDetailsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'SoftwareBilling.xlsx',
            sheetName: 'Billing',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    const reqFields = ['company', 'product', 'paymentSchedule', 'licenseType', 'installType', 'billingStartDate']
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current.active = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addItem(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = uuidv4()
      rawData.current.push(cloneDeep(inputRow.current))
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return
    if (!Array.isArray(rawData.current)) return

    let index = rawData.current.findIndex((item) => item.uid === data.uid)
    if (index < 0) return

    if (isUpdating.current) return
    data = htmlSymbolHandling(data)

    //Required as raw data has no uid
    data.orgCompanyId = rawData.current[index].company
    data.orgProductId = rawData.current[index].product

    isUpdating.current = true
    let res = await updateItem(data)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      delete data.orgCompanyId
      delete data.orgProduct
      rawData.current[index] = cloneDeep(data)

      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
    selectedSoftwareDetail.current = cloneDeep(data)

    setConfirm({
      show: true,
      title: 'Delete Software Details',
      text: `Are you sure you want to delete ${unescapeHtml(data.company)} ${data.product}?`,
      onConfirm: handleDelete,
    })
  }

  const handleDelete = async () => {
    if (!selectedSoftwareDetail.current) return
    if (!selectedSoftwareDetail.current.hasOwnProperty('uid')) return
    if (!Array.isArray(rawData.current)) return
    let index = rawData.current.findIndex((item) => item.uid === selectedSoftwareDetail.current.uid)
    if (index < 0) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteItem(selectedSoftwareDetail.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      rawData.current.splice(index, 1)
      gridApi.current.applyTransaction({
        remove: [selectedSoftwareDetail.current],
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params?.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params?.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params?.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    value += 'T00:00:01'
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'company',
        colId: 'company',
        headerName: 'Company',
        valueGetter: (params) => unescapeHtml(params.data?.company),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          let currency = Array.isArray(dropDowns.companys) ? dropDowns?.companys : []
          return {
            values: currency.map((val) => val.label),
          }
        },
      },
      {
        colId: 'product',
        field: 'product',
        headerName: 'Product',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let currency = Array.isArray(dropDowns.product) ? dropDowns?.product : []
          return {
            values: currency.map((val) => val.label),
          }
        },
      },
      {
        colId: 'paymentSchedule',
        field: 'paymentSchedule',
        headerName: 'Payment Schedule',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let currency = Array.isArray(dropDowns.paymentSchedule) ? dropDowns?.paymentSchedule : []
          return {
            values: currency.map((val) => val.label),
          }
        },
      },
      {
        colId: 'licenseType',
        field: 'licenseType',
        headerName: 'License Type',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let currency = Array.isArray(dropDowns.licenseTypes) ? dropDowns?.licenseTypes : []
          return {
            values: currency.map((val) => val.label),
          }
        },
      },
      {
        colId: 'installType',
        field: 'installType',
        headerName: 'Install Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          let currency = Array.isArray(installTypes) ? installTypes : []
          return {
            values: currency.map((val) => val.label),
          }
        },
      },
      {
        field: 'billingStartDate',
        colId: 'billingStartDate',
        headerName: 'Billing Start Date',
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        valueFormatter: (params) => dateTimeFormatter(params.data?.billingStartDate),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
      },
      {
        field: 'maxUsers',
        colId: 'maxUsers',
        headerName: 'Max Users',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 0,
        },
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
    ],
    [dateTimeFormatter, installTypes, dropDowns], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={softwareDetailsData}
            columnDefs={sortColDefs(columnDefs, 'innovaSoftwareDetailsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchSoftwareDetailsData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InnovaSoftwareDetailsPage
