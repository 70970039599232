import React, { useRef, useMemo, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { currentWellSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Snackbar, Alert } from '@mui/material'
import useDailyReports from 'components/common/hooks/useDailyReports'
import usePersonnel from 'components/common/hooks/usePersonnel'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PersonnelGridModal = ({ setOpen, dailyReportId, updateParentGrid }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const currentWell = useRecoilValue(currentWellSelector).wellName
  const currentWellRef = useRef(currentWell)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getDropDowns } = useDailyReports()
  const { handleUpdatePersonnel, fetchPersonnelData, isLoading } = usePersonnel()
  const [personnelData, setPersonnelData] = useState([])

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    getPersonnelData()
  }, [dailyReportId, currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPersonnelData = async () => {
    let personnelData = await fetchPersonnelData(dailyReportId)
    if (_isMounted.current) setPersonnelData(personnelData)
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const columnDefs = [
    { field: 'position', editable: false, cellStyle: { paddingRight: '10px' } },
    {
      field: 'name',
      cellStyle: { textAlign: 'start', paddingLeft: '5px' },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { personnelNames } = getDropDowns()
        let filteredNames = Array.isArray(personnelNames)
          ? personnelNames.filter((item) => item.position === params.data?.dropDownFilter)
          : []
        return { values: ['', ...filteredNames.map((item) => item.name)] }
      },
      valueGetter: (params) => {
        return unescapeHtml(params.data?.name)
      },
    },
    {
      headerName: 'Time On',
      field: 'timeOn',
      cellStyle: centerAlignCell,
      valueSetter: (params) => {
        if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(params.newValue)) {
          if (params.newValue > params.data?.timeOff) {
            setStatus({ show: true, severity: 'error', message: 'Time On must be less than Time Off' })
            return false
          }
          let paramsDataCopy = { ...params.data }
          paramsDataCopy.timeOn = params.newValue
          params.api.applyTransaction({ update: [paramsDataCopy] })
          return true
        } else {
          setStatus({ show: true, severity: 'error', message: 'Must be "HH:MM" format' })
          return false
        }
      },
    },
    {
      headerName: 'Time Off',
      field: 'timeOff',
      cellStyle: centerAlignCell,
      valueSetter: (params) => {
        if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(params.newValue)) {
          if (params.newValue < params.data?.timeOn) {
            setStatus({ show: true, severity: 'error', message: 'Time Off must be greater than Time On' })
            return false
          }
          let paramsDataCopy = { ...params.data }
          paramsDataCopy.timeOff = params.newValue
          params.api.applyTransaction({ update: [paramsDataCopy] })
          return true
        } else {
          setStatus({ show: true, severity: 'error', message: 'Must be "HH:MM" format' })
          return false
        }
      },
    },
    {
      headerName: 'Relief',
      field: 'relief',
      cellStyle: { textAlign: 'start', paddingLeft: '5px' },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        const { personnelNames } = getDropDowns()
        let filteredNames = Array.isArray(personnelNames)
          ? personnelNames.filter((item) => item.position === params.data?.dropDownFilter)
          : []
        return { values: ['', ...filteredNames.map((item) => item.name)] }
      },
      valueGetter: (params) => {
        return unescapeHtml(params.data?.relief)
      },
    },
    {
      headerName: 'Time On',
      field: 'reliefTimeOn',
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'Time Off',
      field: 'reliefTimeOff',
      cellStyle: centerAlignCell,
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) {
      return
    }

    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []
    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })

      itemsToUpdate.push(data)
    }

    gridApi.applyTransaction({ update: itemsToUpdate })
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }

        return false
      },
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      cellStyle: { textAlign: 'right', padding: 0 },
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    onCellEditingStopped: (params) => {
      if (!params?.valueChanged) return
      handleGridUpdate(cloneDeep(params.data))
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const handleGridUpdate = async (data) => {
    let response = await handleUpdatePersonnel(data)
    if (response.isError) {
      setStatus({ show: true, severity: 'error', message: response.message })
      return
    }

    gridApi.current.applyTransaction({
      update: [cloneDeep(data)],
    })

    if (data.tag === 'dayDd' || data.tag === 'nightDd' || data.tag === 'dayCoMan')
      updateParentGrid(data, 'personnelData')
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      PaperProps={{
        sx: {
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}
      open={true}
      onClose={() => setOpen(false)}>
      <DialogTitle>Personnel Data</DialogTitle>
      <DialogContent>
        <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
          <AgGridReact
            loading={isLoading}
            rowData={personnelData}
            columnDefs={columnDefs}
            headerHeight={30}
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            animateRows={true}
            getRowId={getRowId}
          />
        </div>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default PersonnelGridModal
