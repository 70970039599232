import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { Box, Tooltip, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { cloneDeep } from 'lodash'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ToolOrderDetailsGrid = ({ toolOrderId, getDropDowns, setStatus }) => {
  const _isMounted = useRef(false)
  const [toolDetails, setToolDetails] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const inputRow = useRef({})
  const isAdding = useRef(false)
  const gridApi = useRef(null)
  const isUpdating = useRef(false)
  const selectedComponent = useRef({})
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const isDeleting = useRef(false)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const getToolDetail = useInnovaAxios({
    url: '/toolOrders/getTools',
  })

  const addToolDetail = useInnovaAxios({
    url: '/toolOrders/addTool',
  })

  const updateToolDetail = useInnovaAxios({
    url: '/toolOrders/updateTool',
  })

  const deleteToolDetail = useInnovaAxios({
    url: '/toolOrders/deleteTool',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchToolDetails()
    return () => {
      _isMounted.current = false
    }
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const fetchToolDetails = async () => {
    if (toolOrderId === null || toolOrderId === undefined) return
    if (typeof toolOrderId !== 'number') return
    if (isLoading) return

    setIsLoading(true)
    const res = await getToolDetail({ toolOrderId: toolOrderId })

    if (res?.error) return
    if (!_isMounted.current) return

    setToolDetails(res.data)
    setIsLoading(false)
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onColumnVisible: (params) => {
      saveColumnState()
    },
    onDragStopped: (params) => {
      saveColumnState()
    },
    onCellEditingStopped: (params) => {
      if (params.node?.rowPinned === 'bottom') return handleAddRow()
      if (params?.newValue === params?.oldValue) return
      handleUpdate(params.data)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('toolOrderDetailsGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = async () => {
    if (!inputRow.current || !inputRow.current.hasOwnProperty('description')) return
    if (!toolOrderId) return
    if (typeof toolOrderId !== 'number') return
    if (isAdding.current) return
    if (!gridApi.current) return

    inputRow.current.toolOrderId = toolOrderId
    inputRow.current.quantity = 1

    isAdding.current = true
    setIsLoading(true)
    let res = await addToolDetail(inputRow.current)
    isAdding.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res?.data) {
      inputRow.current.toolId = res.data?.toolId
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('toolOrderId')) return
      if (!data.hasOwnProperty('description')) return
      if (!data.hasOwnProperty('toolId')) return
      if (data?.toolOrderId <= 0) return
      if (isUpdating.current) return
      if (!gridApi.current) return

      isUpdating.current = true
      let res = await updateToolDetail(htmlSymbolHandling(data))
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      gridApi.current.applyTransaction({
        update: [data],
      })

      autoSizeColumns()
    },
    [updateToolDetail, setStatus],
  )

  const getDropDownList = useCallback(
    (field) => {
      switch (field) {
        case 'toolType':
          return getDropDowns('TOOL_TYPE')
        case 'topConnection':
          return getDropDowns('CONNECTION_TYPE')
        case 'bottomConnection':
          return getDropDowns('CONNECTION_TYPE')
        case 'lobe':
          return getDropDowns('LOBES')
        case 'stage':
          return getDropDowns('STAGES')
        case 'housingType':
          return getDropDowns('HOUSING_TYPE')
        case 'rubberType':
          return getDropDowns('RUBBER_TYPE')
        case 'rotorType':
          return getDropDowns('ROTOR_TYPE')
        case 'bendType':
          return getDropDowns('BEND_TYPE')
        case 'primaryVendor':
          return getDropDowns('VENDORS')
        case 'secondaryVendor':
          return getDropDowns('VENDORS')
        case 'boredForFloat':
          return getDropDowns('BORED_FOR_FLOAT')
        case 'thHillRequired':
          return getDropDowns('TH_HILL_REQUIRED')
        case 'dynoRequired':
          return getDropDowns('DYNO_REQUIRED')
        default:
          return []
      }
    },
    [getDropDowns],
  )

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const leftAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  })

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      editable: (params) => {
        return params?.node?.rowPinned === 'bottom' ? (params?.colDef?.field === 'description' ? true : false) : true
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return { values: getDropDownList(params?.colDef?.field) }
      },
    }
  }, [getDropDownList, handleUpdate])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (!gridApi.current) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onClickDelete = (data) => {
    selectedComponent.current = cloneDeep(data)
    setConfirm({
      show: true,
      title: 'Delete Tool',
      text: `Are you sure you want to delete ${data?.description}?`,
    })
  }

  const actionIconRenderer = useCallback((params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => {
                if (!params?.node?.hasOwnProperty('rowPinned') || params.node?.rowPinned !== 'bottom')
                  onClickDelete(params?.data)
              }}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }, [])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const createPinnedCellPlaceholder = ({ colDef }) => {
    if (colDef?.field === 'description') return colDef.headerName + '...'
  }

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'actions',
        colId: 'actions',
        width: 70,
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        sortable: false,
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: 'Description',
        field: 'description',
        colId: 'description',
        cellEditor: 'agTextCellEditor',
        cellEditorParams: null,
        cellStyle: leftAlignCell,
        valueFormatter: (params) =>
          isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : unescapeHtml(params?.value),
      },
      {
        headerName: 'Quantity',
        field: 'quantity',
        colId: 'quantity',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 0,
        },
      },
      { headerName: 'Type', field: 'toolType', colId: 'toolType' },
      {
        field: 'od',
        colId: 'od',
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 2) : ''),
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) < parseFloat(params?.data?.id)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `OD must be greater than ID`,
            })
            return false
          }

          let data = { ...params.data }
          data.od = params.newValue

          handleUpdate(data)
          return true
        },
      },
      {
        field: 'id',
        colId: 'id',
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 3) : ''),
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          if (parseFloat(params.newValue) > parseFloat(params?.data?.od)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `ID must be less than OD`,
            })
            return false
          }

          let data = { ...params.data }
          data.id = params.newValue
          handleUpdate(data)
          return true
        },
      },
      { headerName: 'CNX Top', field: 'topConnection', colId: 'topConnection' },
      { headerName: 'CNX Bottom', field: 'bottomConnection', colId: 'bottomConnection' },
      { headerName: 'Lobe', field: 'lobe', colId: 'lobe' },
      { headerName: 'Stage', field: 'stage', colId: 'stage' },
      { headerName: 'Housing', field: 'housingType', colId: 'housingType' },
      { headerName: 'Rubber', field: 'rubberType', colId: 'rubberType' },
      { headerName: 'Rotor', field: 'rotorType', colId: 'rotorType' },
      { headerName: 'Bend', field: 'bendType', colId: 'bendType' },
      {
        headerName: 'Stab',
        field: 'stabOd',
        colId: 'stabOd',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 2) : ''),
      },
      {
        headerName: 'BFF',
        field: 'boredForFloat',
        colId: 'boredForFloat',
      },
      {
        headerName: 'Fit',
        field: 'fit',
        colId: 'fit',
        cellStyle: leftAlignCell,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: null,
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        headerName: 'SN',
        field: 'serialNum',
        colId: ' serialNum',
        cellStyle: leftAlignCell,
        cellEditor: 'agTextCellEditor',
        cellEditorParams: null,
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        headerName: 'Length',
        field: 'length',
        colId: 'length',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 2,
        },
        valueFormatter: (params) => (params.value > 0 ? numberWithCommasDecimals(params.value, 2) : ''),
      },
      {
        headerName: 'TH Hill',
        field: 'thHillRequired',
        colId: 'thHillRequired',
      },
      {
        headerName: 'Dyno Req',
        field: 'dynoRequired',
        colId: 'dynoRequired',
      },
      {
        headerName: 'Temp Range',
        field: 'tempRange',
        colId: 'tempRange',
        cellEditor: 'agTextCellEditor',
        cellEditorParams: null,
        cellStyle: leftAlignCell,
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      { headerName: 'Vendor', field: 'primaryVendor', colId: 'primaryVendor', cellStyle: leftAlignCell },
      { headerName: 'Alt Vendor', field: 'secondaryVendor', colId: 'secondaryVendor', cellStyle: leftAlignCell },
    ],
    [actionIconRenderer, getUnitsText, handleUpdate, setStatus],
  )

  const handleDelete = async () => {
    if (!selectedComponent.current) return
    if (!selectedComponent.current.hasOwnProperty('toolId')) return
    if (typeof selectedComponent.current?.toolId !== 'number') return
    if (!toolOrderId || typeof toolOrderId !== 'number') return
    if (isDeleting.current) return
    if (!gridApi.current) return

    isDeleting.current = true
    setIsLoading(true)
    let res = await deleteToolDetail({ toolOrderId: toolOrderId, toolId: selectedComponent.current?.toolId })
    isDeleting.current = false

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedComponent.current],
      })
    }
    selectedComponent.current = null
    return
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.toolId)
    }
  }, [])

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('toolOrderDetailsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ToolOrderDetails.xlsx',
            sheetName: 'ToolOrderDetails',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={toolDetails}
          columnDefs={sortColDefs(columnDefs, 'toolOrderDetailsGrid')}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          getRowId={getRowId}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
    </React.Fragment>
  )
}

export default ToolOrderDetailsGrid
