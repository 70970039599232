import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import {
  sortColDefs,
  relativeTime,
  isValidDate,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
} from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DatabaseVersionPage = () => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [databaseVersionData, setDatabaseVersionData] = useState([])
  const { getAgGridTheme } = useInnovaTheme()

  const getVersionData = useInnovaAxios({
    url: '/admin/database/getVersionInfo',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchVersionData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const fetchVersionData = async () => {
    if (isLoading) return
    setLoading(true)

    const response = await getVersionData()

    if (response?.error) return
    if (!_isMounted.current) return

    setDatabaseVersionData(Array.isArray(response.data) ? response.data.reverse() : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!isValidDate(value)) return ''
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    value += 'T00:00:01Z'
    if (isDateLessThan(value, '1990-01-01')) return ''

    const date = new Date(value)
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }

    return `${date.toLocaleDateString('default', options)}.${date.getMilliseconds()}`
  }, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('databaseVersionGrid', 'colLayout', colLayout)
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'date',
        colId: 'date',
        headerName: 'Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) =>
          `${dateTimeFormatter(params.data?.date)} [${relativeTimeFormatter(params.data?.date)}]`,
      },
      {
        field: 'version',
        colId: 'version',
        headerName: 'Version',
        cellStyle: centerAlignCell,
      },
      {
        field: 'updateProgram',
        colId: 'updateProgram',
        headerName: 'UpdatedBy',
        width: 400,
      },
    ],
    [dateFilterComparator, dateTimeFormatter, relativeTimeFormatter, centerAlignCell],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: '100%',
        marginLeft: 0,
        width: '100%',
        maxWidth: `100%`,
        maxHeight: `100%`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}></Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            
            loading={isLoading}
            rowData={databaseVersionData}
            columnDefs={sortColDefs(columnDefs, 'databaseVersionGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </Box>
    </Box>
  )
}

export default DatabaseVersionPage
