import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, relativeTime, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DatabaseRestoreStatusPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [statusData, setStatusData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getDatabaseStatusData = useInnovaAxios({
    url: '/admin/database/getDatabaseBackupStatus',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.databaseRestoreStatusPageKey)
    fetchStatusData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.taskId)
    }
  }, [])

  const fetchStatusData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getDatabaseStatusData()

    if (response?.error) return
    if (!_isMounted.current) return

    setStatusData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      wrapText: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('databaseRestoreStatusGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('databaseRestoreStatusGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'RestoreStatus.xlsx',
            sheetName: 'Status',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'details',
        pinned: 'left',
        lockPosition: 'left',
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: {
          justifyContent: 'center',
        },
      },
      {
        field: 'taskType',
        colId: 'taskType',
        headerName: 'Task Type',
        cellStyle: centerAlignCell,
      },
      {
        field: 'databaseName',
        colId: 'databaseName',
        headerName: 'Database',
      },
      {
        field: 'percentComplete',
        colId: 'percentComplete',
        headerName: 'Complete (%)',
        cellStyle: (params) => {
          let color = 'red'
          if (parseFloat(params.value) === 100) {
            color = 'green'
          }

          return {
            ...centerAlignCell,
            color: color,
          }
        },
      },
      {
        field: 'durationMinutes',
        colId: 'durationMinutes',
        headerName: 'Time (mins)',
        cellStyle: centerAlignCell,
      },
      {
        field: 'lifecycle',
        colId: 'lifecycle',
        headerName: 'Status',
        cellStyle: (params) => {
          let color = 'orange'
          if (params.value === 'SUCCESS') {
            color = 'green'
          }

          if (params.value === 'ERROR') {
            color = 'red'
          }

          return {
            ...centerAlignCell,
            color: color,
          }
        },
      },
      {
        field: 'createdAt',
        colId: 'createdAt',
        headerName: 'Started',
        valueFormatter: (params) => dateTimeFormatter(params?.value),
      },
      {
        field: 'lastUpdated',
        colId: 'lastUpdated',
        headerName: 'Last Update',
        valueFormatter: (params) => `${dateTimeFormatter(params?.value)} [${relativeTimeFormatter(params?.value)}]`,
      },
    ],
    [dateTimeFormatter, relativeTimeFormatter, centerAlignCell],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const detailCellRendererParams = useMemo(
    () => {
      return {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Time',
              field: 'dateTime',
              colId: 'dateTime',
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
              valueFormatter: (params) => {
                if (!params.value) return ''
                if (typeof params.value !== 'string') return ''
                if (params.value === '') return ''
                if (isDateLessThan(params.value, '1990-01-01')) return ''
                params.value = params.value.replace(/Z/g, '')
                return new Date(Date.parse(params.value)).toLocaleDateString('default', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                })
              },
              width: 300,
            },
            {
              headerName: 'Task',
              field: 'task',
              colId: 'task',
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
              width: 800,
            },
          ],
          defaultColDef: {
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            sortable: false,
            resizable: true,
            editable: false,
          },
        },
        getDetailRowData: (params) => {
          const splitTaskInfo = params?.data?.taskInfo.split('\n')
          const taskData = []

          for (let i = 0; i < splitTaskInfo.length; i++) {
            const task = splitTaskInfo[i].trim()
            if (task.length === 0) continue

            const splitTask = splitTaskInfo[i].split(']')
            if (splitTask.length < 2) continue

            taskData.push({
              uid: i + 1,
              dateTime: splitTask[0].replace('[', '').trim().replace(' ', 'T'),
              task: splitTask[1].trim(),
            })
          }

          params.successCallback(taskData)
        },
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={statusData}
            columnDefs={sortColDefs(columnDefs, 'databaseRestoreStatusGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchStatusData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DatabaseRestoreStatusPage
