import { Vector3 } from 'three'
import TextLabel from './TextLabel'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const SurveyLabels = ({ refData, display, labelColor }) => {
  if (!display) return null
  if (!Array.isArray(refData)) return null
  if (refData.length === 0) return null
  if (!Array.isArray(refData[0].svyData)) return null
  if (refData[0].svyData.length === 0) return null

  return refData[0].data.map((svy, index) => {
    let svyLabel = refData[0].svyData[index]

    return (
      <TextLabel
        label={`MD: ${numberWithCommasDecimals(svyLabel.md, 2)}\nINC: ${numberWithCommasDecimals(
          svyLabel.inc,
          2,
        )}\nAZI: ${numberWithCommasDecimals(svyLabel.azi, 2)}\nTVD: ${numberWithCommasDecimals(
          svyLabel.tvd,
          2,
        )}\nDLS: ${numberWithCommasDecimals(svyLabel.dls, 2)}`}
        size={1}
        color={labelColor}
        position={new Vector3(svy.x + 5, svy.y, svy.z)}
        key={`${svyLabel.md}svyLabel${index}`}
      />
    )
  })
}

export default SurveyLabels
