import React, { useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import { appColors } from 'utils'

const UploadImageFileDialog = (props) => {
  const { title, open, setOpen, onConfirm, onUpload } = props
  const [currentFile, setCurrentFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const onSelectUploadFile = (event) => {
    setCurrentFile(event.target.files[0])
    setPreviewImage(URL.createObjectURL(event.target.files[0]))
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '600px',
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ margin: '20px' }}>
          <label htmlFor='btn-upload'>
            <input
              id='btn-upload'
              name='btn-upload'
              style={{ display: 'none' }}
              type='file'
              accept='image/png'
              onChange={onSelectUploadFile}
            />
            <Button
              component='span'
              sx={{
                marginRight: '10px !important',
                color: appColors.headerTextColor,
                border: '1px solid #C0C0C0',
                '&:hover': {
                  borderColor: '#FFF',
                },
              }}
              variant='outlined'>
              Choose image
            </Button>
          </label>
          <Box
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'inline-block',
              maxWidth: '300px',
              verticalAlign: 'middle',
            }}>
            {currentFile ? currentFile.name : null}
          </Box>
          <Button
            sx={{
              position: 'absolute !important',
              right: '10px',
              border: '1px solid #C0C0C0',
              '&:hover': {
                borderColor: appColors.accentColor,
              },
            }}
            color='primary'
            variant='contained'
            component='span'
            disabled={!currentFile}
            onClick={() => onUpload(currentFile)}>
            Upload
          </Button>
          {previewImage && (
            <Box>
              <Box
                component='img'
                sx={{ marginTop: '20px', marginBottom: '20px', maxWidth: '200px' }}
                src={previewImage}
                alt=''
              />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
          color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default UploadImageFileDialog
