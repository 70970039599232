import React, { useEffect, useRef, useState } from 'react'

import { styled } from '@mui/styles'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { currentWellAtom, currentPageAtom, actionBarWidthAtom } from 'atoms'
import { Box } from '@mui/material'
import { CircularProgress } from '@mui/material'
import useInterval from 'components/common/hooks/useInterval'
import WellLog, {
  WL_ACTION_SCROLL_BCK,
  WL_ACTION_SCROLL_FWD,
  WL_ACTION_ZOOM,
  WL_ACTION_DELETE_CURVE,
  WL_ACTION_ADD_CURVE,
  WL_ACTION_MOVE_CURVE,
  WL_ACTION_DELETE_TRACK,
  WL_ACTION_ADD_TRACK,
  WL_ACTION_MOVE_TRACK,
} from './WellLog'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { plotDefs, shadeType } from './curveDefs'
import cloneDeep from 'lodash/cloneDeep'
import UpdateCurveSettingsModal from './UpdateCurveModal/UpdateCurveSettingsModal'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import SplitPane from 'components/common/SplitPane'
import EdrPageLeftPane from './LeftPane/EdrPageLeftPane'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import * as XLSX from '@sheet/core'
import { curveOperations } from './curveDefs'
import useCurveDefinitions from 'components/common/hooks/useCurveDefinitions'
import { chartSeriesColors } from 'utils'
import FileSaver from 'file-saver'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const DEC_LEVELS = {
  raw: 1,
  pointPer02_5Min: 150,
  pointPer05Min: 300,
  pointPer10Min: 600,
}

const NUM_TOOL_FACES = 10
const UPDATE_RATE = 5000
const UPDATE_RATE_SURVEY = 60000
const UPDATE_RATE_MINIMAP = 120000 //60000 * 10 * 2 //20 minutes

const getLocalStorageKey = (wellName) => {
  return `edr-curve-defs-${removeSpecialSymbols(wellName) || '-1'}`
}

const saveCurveDefs = (wellName, crvDefs) => {
  if (!wellName || !crvDefs) return
  saveItemToLS(getLocalStorageKey(wellName), 'crvDef', cloneDeep(crvDefs))
}

const loadCurveDefs = (wellName) => {
  if (!wellName) return null
  return getItemFromLS(getLocalStorageKey(wellName), 'crvDef')
}

const EdrPage = () => {
  const _isMounted = useRef(false)
  const [leftPaneVisible, setLeftPaneVisible] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const isLoadingRef = useRef(false)
  const isLoadingMiniMapRef = useRef(false)
  const maxTimeStamp = useRef(0)
  const logData = useRef([])
  const alarms = useRef([{ id: 'washout', desc: 'Washout', value: false, suppress: false }])
  const curValues = useRef(null)
  const toolFaceBuffer = useRef({ mtf: [], gtf: [], svyInc: 0 })
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const fileInputRef = useRef(null)
  const tenMinDecData = useRef([])
  const fiveMinDecData = useRef([])
  const selectedCurve = useRef(null)
  const selectedTrack = useRef(null)
  const wellLogRef = useRef(null)
  const tenMinDecDataLastLineRef = useRef(null)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const curveDefsRef = useRef(loadCurveDefs(currentWell) || plotDefs)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [showCurveSetupModal, setShowCurveSetupModal] = useState(false)
  const diagData = useRef(null)
  const [redrawAlarms, setRedrawAlarms] = useState(false)
  const { theme } = useInnovaTheme()
  const { curveMnemonics } = useCurveDefinitions()

  const getData = useAxiosGzip({
    url: '/dataAcq/getDataObjGz',
  })

  const getLastSurveyInc = useInnovaAxios({
    url: '/well/getLastSurveyInc',
  })

  const getLatestMiniMapData = useAxiosGzip({
    url: '/dataAcq/getDec10DataGz',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.edrPageKey)
    fetchData()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    currentWellRef.current = currentWell
    logData.current = []
    curValues.current = null
    fetchData()
    curveDefsRef.current = loadCurveDefs(currentWellRef.current) || plotDefs
    for (let i = 0; i < curveDefsRef.current.length; i++) {
      if (!Array.isArray(curveDefsRef.current[i].curves)) continue
      for (let j = 0; j < curveDefsRef.current[i].curves.length; j++) {
        updateCurveDefs(curveDefsRef.current[i].curves[j])
      }
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(() => {
    //Only fetch new data if at the bottom of the max data ever fetched

    if (!wellLogRef.current) return
    let dataMaxTimeStamp = -1
    if (Array.isArray(logData.current) && logData.current.length > 0) {
      dataMaxTimeStamp = logData.current[logData.current.length - 1].convertedTimestamp
    }

    if (maxTimeStamp.current <= dataMaxTimeStamp && wellLogRef.current.getDragging() === null) {
      fetchData(false)
    }
  }, UPDATE_RATE)

  useInterval(() => {
    if (!wellLogRef.current) return

    fetchDataMiniMap()
  }, UPDATE_RATE_MINIMAP)

  useInterval(() => {
    fetchLastSurveyInc()
  }, UPDATE_RATE_SURVEY)

  const updateCurveDefs = (curveDef) => {
    if (!curveDef.hasOwnProperty('calculated')) {
      curveDef.calculated = false
    }
    if (!curveDef.hasOwnProperty('shaded')) {
      curveDef.shaded = false
    }
    if (!curveDef.hasOwnProperty('operations')) {
      curveDef.operations = [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }]
    }
    if (!curveDef.hasOwnProperty('shading')) {
      curveDef.shading = [
        {
          shadeType: shadeType[0],
          shadeValue: 0,
          shadeColor: chartSeriesColors[0],
          shadeCurve: 'none',
        },
      ]
    }
  }

  const getYAxisLengthSeconds = () => {
    if (!wellLogRef.current) {
      return 0
    }

    const logMinMax = wellLogRef.current?.getMinMax()

    if (logMinMax?.viewMaxY === undefined) {
      return 0
    }

    let axisLenMs = logMinMax?.viewMaxY - logMinMax?.viewMinY
    let axisLenSeconds = axisLenMs / 1000
    if (axisLenSeconds >= 1000000000000) axisLenSeconds = 0
    return axisLenSeconds > 0 ? axisLenSeconds : 0
  }

  function convertTimeStamp(timeStamp) {
    if (!isValidDate(timeStamp)) return -999.25
    return Math.round(new Date(Date.parse(timeStamp)))
  }

  const fetchDataMiniMap = async () => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoadingMiniMapRef.current) return
    if (!_isMounted.current) return

    isLoadingMiniMapRef.current = true
    const res = await getLatestMiniMapData({ wellName: currentWellRef.current, numRecs: 5 })
    isLoadingMiniMapRef.current = false

    if (!_isMounted.current) return
    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    if (Array.isArray(tenMinDecData.current) && tenMinDecData.current.length > 0) {
      tenMinDecData.current[tenMinDecData.current.length - 1] = tenMinDecDataLastLineRef.current
    } else {
      tenMinDecData.current = []
    }

    for (let i = 0; i < res.data.length; i++) {
      res.data[i].convertedTimestamp = convertTimeStamp(res.data[i].timeStamp)

      if (tenMinDecData.current.length === 0) {
        tenMinDecData.current.push(res.data[i])
        continue
      }

      if (res.data[i].convertedTimestamp > tenMinDecData.current[tenMinDecData.current.length - 1].convertedTimestamp) {
        tenMinDecData.current.push(res.data[i])
      }
    }

    if (tenMinDecData.current.length > 0) {
      tenMinDecDataLastLineRef.current = cloneDeep(tenMinDecData.current[tenMinDecData.current.length - 1])
    }
  }

  const updateAlarms = (id, value) => {
    if (!Array.isArray(alarms.current)) return
    let index = alarms.current.findIndex((alarm) => alarm.id === id)
    if (index === -1) return
    alarms.current[index].value = value
    if (!value && alarms.current[index].suppress) alarms.current[index].suppress = false
  }

  const fetchData = async (showLoading) => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoadingRef.current) return
    if (!_isMounted.current) return

    if (showLoading || logData.current?.length === 0) setLoading(true)

    isLoadingRef.current = true
    const res = await getData(
      logData.current?.length
        ? { wellName: currentWellRef.current, numRecs: 10 }
        : { wellName: currentWellRef.current, numRecs: 5000, incDec: true },
    )

    isLoadingRef.current = false

    if (!_isMounted.current) return
    if (showLoading || logData.current?.length === 0) setLoading(false)
    if (!Array.isArray(res.data?.data)) return
    if (res.data.data.length === 0) return

    for (let i = 0; i < res.data.data.length; i++) {
      res.data.data[i].convertedTimestamp = convertTimeStamp(res.data.data[i].timeStamp)
    }

    curValues.current = res.data.curValues
    updateAlarms('washout', res.data?.procValues?.washoutDetected)
    setRedrawAlarms(!redrawAlarms) //Required to make suire the tabs flashing updates

    diagData.current = res.data?.procDiag

    if (logData.current?.length === 0) {
      //Initial update
      tenMinDecData.current = Array.isArray(res.data.data10min) ? res.data.data10min : []
      for (let i = 0; i < tenMinDecData.current.length; i++) {
        tenMinDecData.current[i].convertedTimestamp = convertTimeStamp(tenMinDecData.current[i].timeStamp)
      }

      if (tenMinDecData.current.length > 0) {
        tenMinDecDataLastLineRef.current = cloneDeep(tenMinDecData.current[tenMinDecData.current.length - 1])
      }

      fiveMinDecData.current = Array.isArray(res.data.data05min) ? res.data.data05min : []
      for (let i = 0; i < fiveMinDecData.current.length; i++) {
        fiveMinDecData.current[i].convertedTimestamp = convertTimeStamp(fiveMinDecData.current[i].timeStamp)
      }

      logData.current = res.data.data
      maxTimeStamp.current = res.data.data[res.data.data.length - 1].convertedTimestamp

      toolFaceBuffer.current = { mtf: [], gtf: [], svyInc: 0 }
      for (let i = res.data.data.length - 1; i >= res.data.data.length - NUM_TOOL_FACES; i--) {
        if (res.data.data[i] && res.data.data[i].hasOwnProperty('avg')) {
          toolFaceBuffer.current.mtf.push(res.data.data[i].avg.mtf)
          toolFaceBuffer.current.gtf.push(res.data.data[i].avg.gtf)
        }
      }

      return
    }

    if (res.data.data[res.data.data.length - 1].convertedTimestamp > maxTimeStamp.current) {
      //Handle append data
      logData.current = [...logData.current, ...res.data.data]
      maxTimeStamp.current = res.data.data[res.data.data.length - 1].convertedTimestamp

      if (Array.isArray(tenMinDecData.current) && tenMinDecData.current.length > 0) {
        tenMinDecData.current[tenMinDecData.current.length - 1].convertTimeStamp = maxTimeStamp.current
        tenMinDecData.current[tenMinDecData.current.length - 1].holeDepth =
          res.data.data[res.data.data.length - 1].holeDepth
      }

      if (!Array.isArray(toolFaceBuffer.current?.mtf)) {
        toolFaceBuffer.current = { mtf: [], gtf: [], svyInc: 0 }
      }

      if (toolFaceBuffer.current.mtf.length >= NUM_TOOL_FACES) {
        toolFaceBuffer.current.mtf.splice(0, 1)
        toolFaceBuffer.current.gtf.splice(0, 1)
      }

      toolFaceBuffer.current.mtf.push(curValues.current.mtf)
      toolFaceBuffer.current.gtf.push(curValues.current.gtf)
    }
  }

  const fetchLastSurveyInc = async () => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    const res = await getLastSurveyInc({ wellName: currentWellRef.current })
    if (!_isMounted.current) return
    if (!res.data) return

    let inc = 5.0
    if (res.data.hasOwnProperty('inc') && res.data.inc && res.data.inc.length > 0) {
      inc = parseFloat(res.data.inc)
    }

    if (!Array.isArray(toolFaceBuffer.current?.mtf)) {
      toolFaceBuffer.current = { mtf: [], gtf: [], svyInc: 0 }
    }

    toolFaceBuffer.current.svyInc = inc
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const getDecimationLevel = (timeScaleSec) => {
    if (!timeScaleSec) return DEC_LEVELS.raw
    if (typeof timeScaleSec !== 'number') return DEC_LEVELS.raw

    let numRawPointsRequested = timeScaleSec / 1.0 //Should probably be a constant but front end does not know update rate
    let pointsPerDecLevel = [
      DEC_LEVELS.raw,
      DEC_LEVELS.pointPer02_5Min,
      DEC_LEVELS.pointPer05Min,
      DEC_LEVELS.pointPer10Min,
    ]

    for (let i = 0; i < pointsPerDecLevel.length; i++) {
      let pointsReturnedForDecLevel = numRawPointsRequested / pointsPerDecLevel[i]

      if (pointsReturnedForDecLevel < 10000) {
        return pointsPerDecLevel[i]
      }
    }

    return DEC_LEVELS.pointPer10Min
  }

  const fetchPrevData = async (action, dateFromStr, dateToStr) => {
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (!isValidDate(dateFromStr)) return
    if (isLoading) return
    if (!_isMounted.current) return

    let curMinimumTimeMs = 0
    let curMaxTimeMs = 0
    let dateFromMs = ''
    let dateToMs = ''

    switch (action) {
      case WL_ACTION_SCROLL_BCK:
        curMinimumTimeMs = new Date(Date.parse(dateFromStr)).getTime()
        dateFromMs = curMinimumTimeMs - 1 * getYAxisLengthSeconds() * 1000 // subtract 1 axis lengths from start
        dateToMs = new Date(Date.parse(dateToStr)).getTime()
        dateFromStr = new Date(dateFromMs).toISOString()
        dateToStr = new Date(dateToMs).toISOString()
        break
      case WL_ACTION_SCROLL_FWD:
        curMaxTimeMs = new Date(Date.parse(dateToStr)).getTime()
        dateFromMs = new Date(Date.parse(dateFromStr)).getTime()
        dateToMs = curMaxTimeMs + 1 * getYAxisLengthSeconds() * 1000 // add 1 axis lengths to end
        dateFromStr = new Date(dateFromMs).toISOString()
        dateToStr = new Date(dateToMs).toISOString()
        break
      case WL_ACTION_ZOOM:
        // pass through from and to datetimes without change (if replacing entire data set)
        // otherwise, need to determine if we need data for the start and data for the end
        break
      default:
        break
    }

    //check to see if you can use 10 min dec data or 5 min dec data
    if (action === WL_ACTION_ZOOM) {
      let fromMs = new Date(Date.parse(dateFromStr)).getTime() / 1000
      let toMs = new Date(Date.parse(dateToStr)).getTime() / 1000

      let timeSecs = toMs - fromMs
      if (timeSecs < 0) timeSecs = 0

      if (getDecimationLevel(timeSecs) === DEC_LEVELS.pointPer10Min) {
        logData.current = cloneDeep(tenMinDecData.current)
        return
      }

      if (getDecimationLevel(timeSecs) === DEC_LEVELS.pointPer05Min) {
        logData.current = cloneDeep(fiveMinDecData.current)
        return
      }
    }

    setLoading(true)
    const res = await getData({
      wellName: currentWellRef.current,
      dateFrom: dateFromStr,
      dateTo: dateToStr,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (!Array.isArray(res.data.data)) return
    if (res.data.data.length === 0) return

    for (let i = 0; i < res.data.data.length; i++) {
      res.data.data[i].convertedTimestamp = convertTimeStamp(res.data.data[i].timeStamp)
    }

    switch (action) {
      case WL_ACTION_SCROLL_BCK:
        logData.current = res.data.data
        break
      case WL_ACTION_SCROLL_FWD:
        logData.current = res.data.data
        break

      case WL_ACTION_ZOOM:
        logData.current = res.data.data
        break
      default:
        break
    }
  }

  const getCurveData = (uid) => {
    let curveData = {
      uid: uid,
      label: '',
      mnemonic: '',
      scaleMin: 0,
      scaleMax: 100,
      color: '#ffffff',
      calculated: false,
      operations: [],
      shading: [],
    }

    if (typeof uid !== 'number') return curveData
    if (!Array.isArray(curveDefsRef.current) || curveDefsRef.current.length === 0) return curveData

    for (let i = 0; i < curveDefsRef.current.length; i++) {
      if (!Array.isArray(curveDefsRef.current[i].curves)) continue
      for (let j = 0; j < curveDefsRef.current[i].curves.length; j++) {
        if (curveDefsRef.current[i].curves[j].uid !== uid) continue
        return cloneDeep(curveDefsRef.current[i].curves[j])
      }
    }

    return curveData
  }

  const getCurvesOnTrack = (uid) => {
    if (typeof uid !== 'number') return []
    if (!Array.isArray(curveDefsRef.current) || curveDefsRef.current.length === 0) return []
    for (let i = 0; i < curveDefsRef.current.length; i++) {
      if (!Array.isArray(curveDefsRef.current[i].curves)) continue
      for (let j = 0; j < curveDefsRef.current[i].curves.length; j++) {
        if (curveDefsRef.current[i].curves[j].uid !== uid) continue

        let otherCrvs = []
        for (let k = 0; k < curveDefsRef.current[i].curves.length; k++) {
          if (curveDefsRef.current[i].curves[k].uid !== uid) {
            otherCrvs.push(curveDefsRef.current[i].curves[k])
          }
        }

        return otherCrvs
      }
    }

    return []
  }

  const updateCurveData = (crvDefs, newValues) => {
    if (!newValues || !newValues.hasOwnProperty('uid')) return
    if (!crvDefs || !Array.isArray(crvDefs) || crvDefs.length === 0) return

    for (let i = 0; i < crvDefs.length; i++) {
      if (!Array.isArray(crvDefs[i].curves)) continue
      for (let j = 0; j < crvDefs[i].curves.length; j++) {
        if (crvDefs[i].curves[j].uid !== newValues.uid) continue
        crvDefs[i].curves[j].label = newValues.label
        crvDefs[i].curves[j].mnemonic = curveMnemonics?.find((mnemonic) => mnemonic.label === newValues.label)?.value
          ? curveMnemonics?.find((mnemonic) => mnemonic.label === newValues.label)?.value
          : 'None'
        crvDefs[i].curves[j].scaleMin = parseFloat(newValues.scaleMin)
        crvDefs[i].curves[j].scaleMax = parseFloat(newValues.scaleMax)
        crvDefs[i].curves[j].color = newValues.color
        crvDefs[i].curves[j].calculated = newValues.calculated
        crvDefs[i].curves[j].shaded = newValues.shaded
        crvDefs[i].curves[j].operations = newValues?.operations
        crvDefs[i].curves[j].shading = newValues?.shading
        break
      }
    }
  }

  const handleShowUpdatecurveModal = (uid) => {
    if (typeof uid !== 'number') return
    selectedCurve.current = getCurveData(uid)
    selectedTrack.current = getCurvesOnTrack(uid)
    setShowCurveSetupModal(true)
  }

  const handleChangeCurve = (data) => {
    setShowCurveSetupModal(false)
    updateCurveData(curveDefsRef.current, data)
    saveCurveDefs(currentWellRef.current, cloneDeep(curveDefsRef.current))
  }

  const handleResetCurves = () => {
    if (currentWellRef.current) {
      curveDefsRef.current = cloneDeep(plotDefs)
      saveCurveDefs(currentWellRef.current, cloneDeep(curveDefsRef.current))
    }
  }

  const getNewCurveUid = () => {
    if (!Array.isArray(curveDefsRef.current)) return 0
    let maxUid = 0
    for (let i = 0; i < curveDefsRef.current.length; i++) {
      if (!Array.isArray(curveDefsRef.current[i].curves)) continue
      for (let j = 0; j < curveDefsRef.current[i].curves.length; j++) {
        if (curveDefsRef.current[i].curves[j].uid > maxUid) maxUid = curveDefsRef.current[i].curves[j].uid
      }
    }

    return maxUid + 1
  }

  const handleAddDeleteMoveTrack = (data) => {
    if (!Array.isArray(curveDefsRef.current)) return
    if (typeof data?.trackIndex !== 'number') return
    if (data.trackIndex < 0) return

    if (data.action === WL_ACTION_MOVE_TRACK) {
      if (data.trackIndex >= curveDefsRef.current.length) return

      let trackToMove = curveDefsRef.current.splice(data.trackIndex, 1)
      curveDefsRef.current.splice(data.dropPosition.trackIndex, 0, trackToMove[0])
    }

    if (data.action === WL_ACTION_ADD_TRACK) {
      if (data.trackIndex >= curveDefsRef.current.length) return
      curveDefsRef.current.splice(data.trackIndex, 0, { curves: [] })
    }

    if (data.action === WL_ACTION_DELETE_TRACK) {
      if (curveDefsRef.current.length === 1) return
      if (data.trackIndex >= curveDefsRef.current.length) return
      curveDefsRef.current.splice(data.trackIndex, 1)
    }

    saveCurveDefs(currentWellRef.current, cloneDeep(curveDefsRef.current))
  }

  const handleAddDeleteMoveCurve = (data) => {
    if (!Array.isArray(curveDefsRef.current)) return
    if (typeof data?.trackIndex !== 'number') return
    if (typeof data?.curveIndex !== 'number') return
    if (data.trackIndex < 0) return
    if (data.curveIndex < 0) return

    if (data.action === WL_ACTION_ADD_CURVE) {
      if (data.trackIndex >= curveDefsRef.current.length) return
      if (!Array.isArray(curveDefsRef.current[data.trackIndex].curves)) return

      curveDefsRef.current[data.trackIndex].curves.splice(data.curveIndex, 0, {
        uid: getNewCurveUid(),
        mnemonic: 'torque',
        label: 'Torque',
        scaleMin: 0,
        scaleMax: 100,
        color: chartSeriesColors[0],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [
          {
            shadeType: shadeType[0],
            shadeValue: 0,
            shadeColor: chartSeriesColors[0],
            shadeCurve: 'none',
          },
        ],
      })
    }

    if (data.action === WL_ACTION_DELETE_CURVE) {
      if (data.trackIndex >= curveDefsRef.current.length) return
      if (!Array.isArray(curveDefsRef.current[data.trackIndex].curves)) return
      if (data.curveIndex >= curveDefsRef.current[data.trackIndex].curves.length) return
      curveDefsRef.current[data.trackIndex].curves.splice(data.curveIndex, 1)
    }

    if (data.action === WL_ACTION_MOVE_CURVE) {
      if (data.trackIndex >= curveDefsRef.current.length) return
      if (!Array.isArray(curveDefsRef.current[data.trackIndex].curves)) return
      if (data.curveIndex >= curveDefsRef.current[data.trackIndex].curves.length) return

      if (typeof data?.dropPosition?.trackIndex !== 'number') return
      if (typeof data?.dropPosition?.curveIndex !== 'number') return
      if (data.dropPosition.trackIndex < 0) return
      if (data.dropPosition.curveIndex < 0) return

      if (data.dropPosition.trackIndex >= curveDefsRef.current.length) return

      let curveToMove = curveDefsRef.current[data.trackIndex].curves.splice(data.curveIndex, 1)
      curveDefsRef.current[data.dropPosition.trackIndex].curves.splice(data.dropPosition.curveIndex, 0, curveToMove[0])
    }

    saveCurveDefs(currentWellRef.current, cloneDeep(curveDefsRef.current))
  }

  const handleExportToExcel = () => {
    if (!Array.isArray(logData.current)) return
    const logMinMax = wellLogRef.current?.getMinMax()
    if (!logMinMax) return

    let data = []
    for (let i = 0; i < logData.current.length; i++) {
      if (logData.current[i].convertedTimestamp < logMinMax.viewMinY) continue
      if (logData.current[i].convertedTimestamp > logMinMax.viewMaxY) continue

      let newDataPoint = {
        ...logData.current[i].avg,
      }

      newDataPoint.timeStamp = logData.current[i].timeStamp
      newDataPoint.holeDepth = logData.current[i].holeDepth
      newDataPoint.bitDepth = logData.current[i].bitDepth
      newDataPoint.uid = logData.current[i].uid

      data.push(newDataPoint)
    }

    let ws = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'data')
    XLSX.writeFile(wb, 'edrData.xlsx')
  }

  const handleExportCrvDefs = () => {
    let blob = new Blob([JSON.stringify(curveDefsRef.current)], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, `edrLayout.txt`)
  }

  const handleImportCrvDefs = (e) => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()

    reader.onload = (event) => {
      const content = event.target.result
      const newSearchParams = JSON.parse(content)
      if (!newSearchParams) return
      curveDefsRef.current = cloneDeep(newSearchParams)
      saveCurveDefs(currentWellRef.current, cloneDeep(curveDefsRef.current))
    }

    reader.readAsText(file)
    e.target.value = null
  }

  return (
    <React.Fragment>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type='file'
        accept='.txt'
        onChange={(event) => {
          handleImportCrvDefs(event)
        }}
      />
      {showCurveSetupModal ? (
        <UpdateCurveSettingsModal
          title={'Update Curve Settings'}
          open={showCurveSetupModal}
          setOpen={setShowCurveSetupModal}
          data={selectedCurve.current}
          track={selectedTrack.current}
          submitFunction={handleChangeCurve}
        />
      ) : null}
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999,
          }}
        />
      ) : null}
      <SplitPane
        split='vertical'
        defaultSize={'calc(100% - 25px)'}
        size={leftPaneVisible ? '80%' : 'calc(100% - 25px)'}
        allowResize={leftPaneVisible}
        primary='second'
        style={{
          height: 'calc(100vh - 64px)',
          marginLeft: leftPos,
          width: `calc(100%-${leftPos}px`,
          maxWidth: `calc(100%-${leftPos}px)`,
        }}>
        <EdrPageLeftPane
          showHidePane={() => setLeftPaneVisible(!leftPaneVisible)}
          paneVisible={leftPaneVisible}
          wellName={currentWellRef.current}
          edrData={curValues}
          alarms={alarms}
          toolFaceData={toolFaceBuffer}
          diagData={diagData}
        />
        <WellLog
          curveDefs={curveDefsRef}
          logData={logData}
          miniMapData={tenMinDecData}
          handleChangeCurveProperties={handleShowUpdatecurveModal}
          handleLoadData={fetchPrevData}
          handleAddDeleteMoveCurve={handleAddDeleteMoveCurve}
          handleAddDeleteMoveTrack={handleAddDeleteMoveTrack}
          ref={wellLogRef}
          backGroundColor={theme === 'dark' ? 'rgba(32,32,32,0.5)' : '#FFF'}
          tickColor={theme === 'dark' ? '#606060' : '#000'}
          outlineColor={theme === 'dark' ? '#A0A0A0' : '#303030'}
        />
      </SplitPane>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon={'file-icons:microsoft-excel'} style={{ color: 'green', width: 28, height: 28 }} />,
              name: 'Export to excel',
              onClick: () => {
                handleExportToExcel()
              },
            },
            {
              icon: <Iconify icon={'carbon:reset'} style={{ color: 'blue', width: 28, height: 28 }} />,
              name: 'Reset Curves',
              onClick: () => {
                handleResetCurves()
              },
            },
            {
              icon: <Iconify icon={'mdi:export'} style={{ color: 'blue', width: 28, height: 28 }} />,
              name: 'Export Layout',
              onClick: () => {
                handleExportCrvDefs()
              },
            },
            {
              icon: <Iconify icon={'icons8:import'} style={{ color: 'blue', width: 28, height: 28 }} />,
              name: 'Import Layout',
              onClick: () => {
                if (!fileInputRef.current) return
                fileInputRef.current.click()
              },
            },
          ]}
        />
      </StyledMenuIcon>
    </React.Fragment>
  )
}

export default EdrPage
