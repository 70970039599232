import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep} from 'lodash'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import BitGradeEditor from 'components/common/CellEditors/BitGradeEditor'
import JetsEditor from 'components/common/CellEditors/JetsEditor'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DrillStringComponentPropertiesGrid = ({
  componentData,
  visibleColumns = [],
  handleUpdateParentGrid,
  dropDownLists,
  masterGridApi,
  getCellStyle,
  checks,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      if (masterGridApi) {
        masterGridApi.removeDetailGridInfo(`PROP_GRID-${componentData.uid}`)
      }

      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (masterGridApi) {
      let detailId = `PROP_GRID-${componentData.uid}`
      const detailGridInfo = {
        id: detailId,
        api: params.api,
      }

      masterGridApi.addDetailGridInfo(detailId, detailGridInfo)
    }
  }

  const handleUpdate = useCallback(
    async (data) => {
      handleUpdateParentGrid(cloneDeep(data))

      gridApi.current.applyTransaction({
        update: [data],
      })

      autoSizeColumns()
    },
    [handleUpdateParentGrid],
  )

  const isTimeStringValid = useCallback((time) => {
    return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)
  }, [])

  const isPlugDateValid = useCallback((plugIn, plugOut) => {
    if (!isValidDate(plugIn) || !isValidDate(plugOut)) return true
    if (isDateLessThan(plugIn, '1990-01-01') || isDateLessThan(plugOut, '1990-01-01')) return true

    const parsedPlugInDate = new Date(Date.parse(plugIn)).getTime()
    const parsedPlugOutDate = new Date(Date.parse(plugOut)).getTime()

    return parsedPlugInDate <= parsedPlugOutDate
  }, [])

  const compareTimes = useCallback(
    (plugIn, plugOut) => {
      if (!isTimeStringValid(plugIn) || !isTimeStringValid(plugOut)) return false

      const [plugInHours, plugInMinutes] = plugIn.split(':').map(Number)
      const [plugOutHours, plugOutMinutes] = plugOut.split(':').map(Number)

      const plugInDate = new Date()
      plugInDate.setHours(plugInHours)
      plugInDate.setMinutes(plugInMinutes)

      const plugOutDate = new Date()
      plugOutDate.setHours(plugOutHours)
      plugOutDate.setMinutes(plugOutMinutes)

      return plugOutDate.getTime() - plugInDate.getTime() > 0 ? true : false
    },
    [isTimeStringValid],
  )

  const gridOptions = useMemo(() => {
    return {
      onCellEditingStopped: (params) => {
        //Hack as the value setter is not being fired so calling the setter logic here???
        if (params.colDef.field === 'gradeIn' || params.colDef.field === 'gradeOut') {
          handleUpdate(params?.data)
          return
        }

        if (params.colDef.field === 'tfa') {
          params.data.tfa = params.newValue
          params.data.bitJets = []
          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'bitJets') {
          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'flowRange') {
          params.data[params.colDef.field] = params?.newValue
          if (!/^[-\d\s]+(\.\d+)?$/.test(params.newValue)) {
            params.data[params.colDef.field] = params?.oldValue
          }

          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'stopCollarDesc') {
          let stopCollarIndex = dropDownLists?.stopCollars.findIndex(
            (stopCollar) => stopCollar.desc === params.newValue,
          )

          params.data.stopCollarDesc = stopCollarIndex >= 0 ? params.newValue : ''
          params.data.stopCollarUid = stopCollarIndex >= 0 ? dropDownLists?.stopCollars[stopCollarIndex].uid : -1
          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'centralizerDesc') {
          let centralizerIndex = dropDownLists?.casingCentralizers.findIndex(
            (casingCentralizer) => casingCentralizer.desc === params.newValue,
          )

          params.data.centralizerDesc = centralizerIndex >= 0 ? params.newValue : ''
          params.data.centralizerUid =
            centralizerIndex >= 0 ? dropDownLists?.casingCentralizers[centralizerIndex].uid : -1
          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'matGrade') {
          if (params.newValue === params.oldValue) return false
          params.data.matGrade = params.newValue
          params.data.materialStrength = getMaterialStrengthFromGrade(
            dropDownLists.materialGrades,
            params.data.matGrade,
            params.data.type,
          )

          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'battPlugInDate' || params.colDef.field === 'battPlugOutDate') {
          params.data[params.colDef.field] = params?.newValue
          if (!isValidDate(params.newValue) || isDateLessThan(params.newValue, '1990-01-01')) {
            params.data[params.colDef.field] = params?.oldValue
          }

          if (!isPlugDateValid(params.data.battPlugInDate, params.data.battPlugOutDate)) {
            params.data[params.colDef.field] = params?.oldValue
          }

          handleUpdate(params.data)
          return
        }

        if (params.colDef.field === 'battPlugInTime' || params.colDef.field === 'battPlugOutTime') {
          params.data[params.colDef.field] = params?.newValue
          if (!isTimeStringValid(params.newValue)) {
            params.data[params.colDef.field] = params.oldValue
          }

          let isValidTimeDiff = compareTimes(params.data?.battPlugInTime, params.data?.battPlugOutTime)
          if (params.data?.battPlugInDate === params.data?.battPlugOutDate && isValidTimeDiff) {
            params.data[params.colDef.field] = params.oldValue
          }

          handleUpdate(params.data)
          return
        }

        if (!params?.valueChanged) return
        handleUpdate(params?.data)
      },
      getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
      suppressRowClickSelection: true,
      popupParent: document.querySelector('body'),
    }
  }, [handleUpdate, isPlugDateValid, compareTimes, isTimeStringValid, dropDownLists])

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''

            if (params?.column?.colDef.colId === 'centralizerDesc') {
              params.data.centralizerUid = -1
            }

            if (params?.column?.colDef.colId === 'stopCollarDesc') {
              params.data.stopCollarUid = -1
            }

            handleUpdate(cloneDeep(params.data))
          }
        }

        //This block allows the user to tab through the custom cell editor cells
        if (
          params.editing &&
          (params.colDef.colId === 'bitJets' || params.colDef.colId === 'gradeIn' || params.colDef.colId === 'gradeOut')
        ) {
          let isEnterKey = params.event.keyCode === 13
          let isTabKey = params.event.keyCode === 9

          if (isEnterKey || isTabKey) {
            return true
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: (params) => {
        return getCellStyle(params, checks, 'center')
      },
    }
  }, [handleUpdate, getCellStyle, checks])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(), 100)
  }

  const getContextMenuItems = (params) => {
    return [
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'BhaComponent.xlsx',
            sheetName: 'Details',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    value += 'T00:00:01'
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const getMaterialStrengthFromGrade = (list, matGrade, compType) => {
    if (typeof compType !== 'string') return 0
    if (typeof matGrade !== 'string') return 0
    if (!Array.isArray(list)) return 0
    if (compType !== 'Casing/Tubing' && compType !== 'Liner' && compType !== 'Drill Pipe/HWDP') return 0

    let strength = 0
    let foundMaterial = list.find((material) => material.type === compType && material.matGrade === matGrade)
    if (foundMaterial) strength = foundMaterial.strength
    return strength
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const columnDefs = useMemo(
    () => [
      {
        field: 'sn',
        colId: 'sn',
        headerName: 'Serial #',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'cnxTop',
        colId: 'cnxTop',
        headerName: 'Connection Top',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.connectionTypes }
        },
      },
      {
        field: 'cnxBtm',
        colId: 'cnxBtm',
        headerName: 'Connection Bottom',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.connectionTypes }
        },
      },
      {
        field: 'boxPinTop',
        colId: 'boxPinTop',
        headerName: 'Box/Pin Top',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: ['BOX', 'PIN'] }
        },
      },
      {
        field: 'boxPinBtm',
        colId: 'boxPinBtm',
        headerName: 'Box/Pin Bottom',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: ['BOX', 'PIN'] }
        },
      },
      {
        field: 'fishNeck',
        colId: 'fishNeck',
        headerName: 'Fish Neck Length',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'fishNeckOd',
        colId: 'fishNeckOd',
        headerName: 'Fish Neck OD',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'make',
        colId: 'make',
        headerName: 'Make',
        cellEditorSelector: (params) => {
          if (
            params.data?.type === 'Drill Bit' ||
            params.data?.type === 'Motor' ||
            params.data?.type === 'RSS' ||
            params.data?.type === 'Vibration Mitigation'
          ) {
            let values = []
            if (params?.data?.type === 'Drill Bit') values = dropDownLists.bitVendors
            if (params?.data?.type === 'Motor') values = dropDownLists.motorVendors
            if (params?.data?.type === 'RSS') values = dropDownLists.rssMakes
            if (params?.data?.type === 'Vibration Mitigation') values = dropDownLists.vibrationToolMakes

            if (!Array.isArray(values)) values = []

            return {
              component: 'agRichSelectCellEditor',
              params: {
                values: values,
              },
              popup: true,
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
      },
      {
        field: 'model',
        colId: 'model',
        headerName: 'Model',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditorSelector: (params) => {
          if (!Array.isArray(dropDownLists.toolModels)) {
            return {
              component: 'agTextCellEditor',
            }
          }

          let filteredModels = dropDownLists.toolModels.filter(
            (model) => model.make === params.data?.make && model.toolType === params.data?.type,
          )

          if (filteredModels.length === 0) {
            return {
              component: 'agTextCellEditor',
            }
          }

          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: filteredModels.map((model) => model.model),
            },
            popup: true,
          }
        },
      },
      {
        field: 'burst',
        colId: 'burst',
        headerName: 'Burst Pressure',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'collapse',
        colId: 'collapse',
        headerName: 'Collapse Pressure',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'tensileYield',
        colId: 'tensileYield',
        headerName: 'Tensile Yield',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'torsionalYield',
        colId: 'torsionalYield',
        headerName: 'Torsional Yield',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'bitType',
        colId: 'bitType',
        headerName: 'Bit Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.bitTypes }
        },
      },
      {
        field: 'bitNum',
        colId: 'bitNum',
        headerName: 'Bit #',
      },
      {
        field: 'tfa',
        colId: 'tfa',
        headerName: 'TFA',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 4,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 4) : ''),
      },
      {
        field: 'bitJets',
        colId: 'bitJets',
        headerName: 'Jets',
        cellEditor: JetsEditor,
        cellEditorPopup: true,
        valueGetter: (params) => params?.data?.bitJets,
        valueFormatter: (params) => {
          if (Array.isArray(params.data?.bitJets) && params.data?.bitJets.length > 0) return '...'
          return 'None'
        },
      },
      {
        field: 'gradeIn',
        colId: 'gradeIn',
        headerName: 'Grade In',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: BitGradeEditor,
        cellEditorPopup: true,
      },
      {
        field: 'gradeOut',
        colId: 'gradeOut',
        headerName: 'Grade Out',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: BitGradeEditor,
        cellEditorPopup: true,
      },
      {
        field: 'gaugeOd',
        colId: 'gaugeOd',
        headerName: 'Gauge OD',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'formationIndex',
        colId: 'formationIndex',
        headerName: 'Formation Index',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1,
          precision: 0,
        },
      },
      {
        field: 'bearingGap',
        colId: 'bearingGap',
        headerName: 'Motor Bearing Gap',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'bendAngle',
        colId: 'bendAngle',
        headerName: 'Motor Bend Angle',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.motorBends }
        },
      },
      {
        field: 'bit2bend',
        colId: 'bit2bend',
        headerName: 'Bit To Bend',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'dynoHp',
        colId: 'dynoHp',
        headerName: 'Dyno HP',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'kickPadOd',
        colId: 'kickPadOd',
        headerName: 'Kick Pad OD',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'diffPress',
        colId: 'diffPress',
        headerName: 'Differential Pressure',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'housingType',
        colId: 'housingType',
        headerName: 'Motor Housing Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.motorHousing }
        },
      },
      {
        field: 'flowRange',
        colId: 'flowRange',
        headerName: 'Motor Flow Range',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'maxDiffP',
        colId: 'maxDiffP',
        headerName: 'Max Differential Pressure',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'maxFlow',
        colId: 'maxFlow',
        headerName: 'Max Flow',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'maxRpm',
        colId: 'maxRpm',
        headerName: 'Max RPM',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 0,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 0) : ''
        },
      },
      {
        field: 'maxTorque',
        colId: 'maxTorque',
        headerName: 'Max Torque',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'motorYield',
        colId: 'motorYield',
        headerName: 'Motor Yield',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 200,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'motorBearing',
        colId: 'motorBearing',
        headerName: 'Motor Bearing Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'motorLobes',
        colId: 'motorLobes',
        headerName: 'Motor Lobes',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.motorLobes }
        },
      },
      {
        field: 'motorStages',
        colId: 'motorStages',
        headerName: 'Motor Stages',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.motorStages }
        },
      },
      {
        field: 'revPerGal',
        colId: 'revPerGal',
        headerName: 'Rev Per Gal',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'statorFit',
        colId: 'statorFit',
        headerName: 'Stator Fit',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'statorType',
        colId: 'statorType',
        headerName: 'Stator Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'statorVendor',
        colId: 'statorVendor',
        headerName: 'Stator Vendor',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'reRun',
        colId: 'reRun',
        headerName: 'Rerun',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.yesNoList }
        },
      },
      {
        field: 'directBill',
        colId: 'directBill',
        headerName: 'Direct Bill',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.yesNoList }
        },
      },
      {
        field: 'rssForce',
        colId: 'rssForce',
        headerName: 'RSS Steer Force',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueGetter: (params) => parseFloat(params?.data?.rssForce),
        valueFormatter: (params) => numberWithCommasDecimals(parseFloat(params?.value), 3),
      },
      {
        field: 'stabBc',
        colId: 'stabBc',
        headerName: 'Stab Blade Count',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 0,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 0) : ''
        },
      },
      {
        field: 'stabBl',
        colId: 'stabBl',
        headerName: 'Stab Blade Length',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'stabBw',
        colId: 'stabBw',
        headerName: 'Stab Blade Width',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'stabDfb',
        colId: 'stabDfb',
        headerName: 'Stab Blade Center to Pin Shoulder',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'stabOd',
        colId: 'stabOd',
        headerName: 'Stab OD',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'surveyOffset',
        colId: 'surveyOffset',
        headerName: 'Survey Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'gammaOffset',
        colId: 'gammaOffset',
        headerName: 'Gamma Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'resOffset',
        colId: 'resOffset',
        headerName: 'Resistivity Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'fmtOffset',
        colId: 'fmtOffset',
        headerName: 'Formation Tester Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'gyroOffset',
        colId: 'gyroOffset',
        headerName: 'Gyro Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'nbAziOffset',
        colId: 'nbAziOffset',
        headerName: 'Near Bit Azi Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'nbIncOffset',
        colId: 'nbIncOffset',
        headerName: 'Near Bit Inc Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'nbGammaOffset',
        colId: 'nbGammaOffset',
        headerName: 'Near Bit Gamma Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'neutronOffset',
        colId: 'neutronOffset',
        headerName: 'Neutron Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'densOffset',
        colId: 'densOffset',
        headerName: 'Density Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'pwdOffset',
        colId: 'pwdOffset',
        headerName: 'PWD Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'sonicOffset',
        colId: 'sonicOffset',
        headerName: 'Sonic Sensor Offset',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'classPipe',
        colId: 'classPipe',
        headerName: 'Pipe Class',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.pipeClass }
        },
      },
      {
        field: 'ffReduction',
        colId: 'ffReduction',
        headerName: 'Friction Factor Reduction %',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'drillPipeRange',
        colId: 'drillPipeRange',
        headerName: 'Drill Pipe Range',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.drillPipeRange }
        },
      },
      {
        field: 'materialName',
        colId: 'materialName',
        headerName: 'Material Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.materialTypes }
        },
      },
      {
        field: 'materialStrength',
        colId: 'materialStrength',
        headerName: 'Material Strength',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'matGrade',
        colId: 'matGrade',
        headerName: 'Material Grade',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          let filteredMaterials = dropDownLists?.materialGrades.filter(
            (material) => material.type === params.data?.type,
          )
          return {
            values: filteredMaterials.map((item) => item.matGrade),
          }
        },
      },
      {
        field: 'floatTfa',
        colId: 'floatTfa',
        headerName: 'Float TFA',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'bowspring',
        colId: 'bowspring',
        headerName: 'Bow Spring Restore Force',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => numberWithCommasDecimals(params?.value, 3),
      },
      {
        field: 'centSpacing',
        colId: 'centSpacing',
        headerName: 'Centralizer Spacing',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'casingMaterial',
        colId: 'casingMaterial',
        headerName: 'Material',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.casingMaterials }
        },
      },
      {
        field: 'inPosCalc',
        colId: 'inPosCalc',
        headerName: 'In Position Fluids',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.standOffCalcMethod }
        },
      },
      {
        field: 'runningCalc',
        colId: 'runningCalc',
        headerName: 'Running Fluids',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.standOffCalcMethod }
        },
      },
      {
        field: 'avgJointLength',
        colId: 'avgJointLength',
        headerName: 'Avg Joint Length',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'centSpacingSelection',
        colId: 'centSpacingSelection',
        headerName: 'Cent Spacing Selection',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            values: ['1 per joint', '2 per joint', '3 per 2 joints'],
          }
        },
      },
      {
        field: 'stopCollarQty',
        colId: 'stopCollarQty',
        headerName: 'Stop Collar Qty',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10,
          precision: 0,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 0) : ''
        },
      },
      {
        field: 'stopCollarDesc',
        colId: 'stopCollarDesc',
        headerName: 'Stop Collar Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          if (!Array.isArray(dropDownLists?.stopCollars)) return { values: [] }
          let filteredStopCollars = dropDownLists?.stopCollars.filter(
            (stopCollar) => Math.abs(stopCollar.size - params.data?.od) < 0.01,
          )

          return {
            values: filteredStopCollars.map((stopCollar) => stopCollar.desc),
          }
        },
      },
      {
        field: 'centralizerDesc',
        colId: 'centralizerDesc',
        headerName: 'Centralizer Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          if (!Array.isArray(dropDownLists?.casingCentralizers)) return { values: [] }

          let filteredCentralizers = dropDownLists?.casingCentralizers.filter(
            (centralizer) => Math.abs(centralizer.size - params.data?.od) < 0.01,
          )

          return {
            values: filteredCentralizers.map((casingCentralizer) => casingCentralizer.desc),
          }
        },
      },
      {
        field: 'hasFloat',
        colId: 'hasFloat',
        headerName: 'Has Float',
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        field: 'intFluidWt',
        colId: 'intFluidWt',
        headerName: 'Internal Fluid Weight',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'impulse',
        colId: 'impulse',
        headerName: 'Jar Impulse',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'latchDown',
        colId: 'latchDown',
        headerName: 'Jar Latch Down',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'latchUp',
        colId: 'latchUp',
        headerName: 'Jar Latch Up',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'battery1Sn',
        colId: 'battery1Sn',
        headerName: 'Battery 1 Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.batterySn }
        },
      },
      {
        field: 'battery2Sn',
        colId: 'battery2Sn',
        headerName: 'Battery 2 Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.batterySn }
        },
      },
      {
        field: 'battery3Sn',
        colId: 'battery3Sn',
        headerName: 'Battery 3 Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.batterySn }
        },
      },
      {
        field: 'batteryType',
        colId: 'batteryType',
        headerName: 'Battery Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'battPlugInDate',
        colId: 'battPlugInDate',
        headerName: 'Battery Plug In Date',
        valueFormatter: (params) => dateTimeFormatter(params?.value),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '2000-01-01',
          max: '2050-01-01',
        },
      },
      {
        field: 'battPlugInTime',
        colId: 'battPlugInTime',
        headerName: 'Battery Plug In Time',
      },
      {
        field: 'battPlugOutDate',
        colId: 'battPlugOutDate',
        headerName: 'Battery Plug Out Date',
        valueFormatter: (params) => dateTimeFormatter(params?.value),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '2000-01-01',
          max: '2050-01-01',
        },
      },
      {
        field: 'battPlugOutTime',
        colId: 'battPlugOutTime',
        headerName: 'Battery Plug Out Time',
      },
      {
        field: 'dao',
        colId: 'dao',
        headerName: 'DAO Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'directionalSn',
        colId: 'directionalSn',
        headerName: 'Directional Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.directionalSn }
        },
      },
      {
        field: 'shockToolSn',
        colId: 'shockToolSn',
        headerName: 'Shock Tool Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.shockToolSn }
        },
      },
      {
        field: 'dirType',
        colId: 'dirType',
        headerName: 'Directional Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'dirVer',
        colId: 'dirVer',
        headerName: 'Directional Version',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'emFreq',
        colId: 'emFreq',
        headerName: 'EM Frequency',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'emPower',
        colId: 'emPower',
        headerName: 'EM Power',
        cellEditor: 'agNumberCellEditor',
        cellDataType: false,
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'emSn',
        colId: 'emSn',
        headerName: 'EM Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.emSn }
        },
      },
      {
        field: 'gammaType',
        colId: 'gammaType',
        headerName: 'Gamma Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'gammaVer',
        colId: 'gammaVer',
        headerName: 'Gamma Version',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'gammaCorFac',
        colId: 'gammaCorFac',
        headerName: 'Gamma Correction Factor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'gammaSn',
        colId: 'gammaSn',
        headerName: 'Gamma Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.gammaSn }
        },
      },
      {
        field: 'helixSn',
        colId: 'helixSn',
        headerName: 'Helix Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.helixSn }
        },
      },
      {
        field: 'helixType',
        colId: 'helixType',
        headerName: 'Helix Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'maxAxial',
        colId: 'maxAxial',
        headerName: 'Max Axial Vibration',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 0,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 0) : ''),
      },
      {
        field: 'maxLateral',
        colId: 'maxLateral',
        headerName: 'Max Lateral Vibration',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 0,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 0) : ''),
      },
      {
        field: 'mwdType',
        colId: 'mwdType',
        headerName: 'MWD Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'mwdDataRate',
        colId: 'mwdDataRate',
        headerName: 'Mwd Data Rate',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'mwdFinSize',
        colId: 'mwdFinSize',
        headerName: 'MWD Fin Size',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'mwdOrificeType',
        colId: 'mwdOrificeType',
        headerName: 'MWD Orifice Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'orifice',
        colId: 'orifice',
        headerName: 'Orifice',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'mwdPrimary',
        colId: 'mwdPrimary',
        headerName: 'MWD Primary',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.yesNoList }
        },
      },
      {
        field: 'mwdPulseWidth',
        colId: 'mwdPulseWidth',
        headerName: 'MWD Pulse Width',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'mwdRetre',
        colId: 'mwdRetre',
        headerName: 'MWD Retrievable',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.yesNoList }
        },
      },
      {
        field: 'pinToSetScrew',
        colId: 'pinToSetScrew',
        headerName: 'Pin To Set Screw',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'poppet',
        colId: 'poppet',
        headerName: 'Poppet',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditorSelector: (params) => {
          if (!Array.isArray(dropDownLists?.poppet)) {
            return {
              component: 'agTextCellEditor',
            }
          }

          if (dropDownLists?.poppet.length === 0) {
            return {
              component: 'agTextCellEditor',
            }
          }

          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: dropDownLists?.poppet,
            },
            popup: true,
          }
        },
      },
      {
        field: 'pressureDrop',
        colId: 'pressureDrop',
        headerName: 'Pressure Drop',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000000,
          precision: 3,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''),
      },
      {
        field: 'pulserType',
        colId: 'pulserType',
        headerName: 'Pulser Type',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'pulserVer',
        colId: 'pulserVer',
        headerName: 'Pulser Version',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'pulserDriverSn',
        colId: 'pulserDriverSn',
        headerName: 'Pulser Driver Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.driverSn }
        },
      },
      {
        field: 'mwdControllerSn',
        colId: 'mwdControllerSn',
        headerName: 'Mwd Controller Sn',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'babelFishSn',
        colId: 'babelFishSn',
        headerName: 'Babel Fish Sn',
        valueFormatter: (params) => unescapeHtml(params?.value),
      },
      {
        field: 'pulserSn',
        colId: 'pulserSn',
        headerName: 'Pulser Serial#',
        valueFormatter: (params) => unescapeHtml(params?.value),
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.pulserSn }
        },
      },
      {
        field: 'stickUp',
        colId: 'stickUp',
        headerName: 'Stick Up',
        cellDataType: false,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'mwdCollarType',
        colId: 'mwdCollarType',
        headerName: 'Collar Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(dropDownLists?.mwdCollarTypes) ? dropDownLists?.mwdCollarTypes : [],
          }
        },
      },
      {
        field: 'mwdTelemType',
        colId: 'mwdTelemType',
        headerName: 'Telem Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(dropDownLists?.mwdTelemTypes) ? dropDownLists?.mwdTelemTypes : [],
          }
        },
      },
      {
        field: 'rssType',
        colId: 'rssType',
        headerName: 'Rss Type',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return { values: dropDownLists?.rssTypes }
        },
      },
      {
        field: 'rssOperatingFlowRate',
        colId: 'rssOperatingFlowRate',
        headerName: 'Operating Flow Rate',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 5000,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'numBlades',
        colId: 'numBlades',
        headerName: '#Blades',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 20,
          precision: 0,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 0) : ''),
      },
      {
        field: 'iadcCode',
        colId: 'iadcCode',
        headerName: 'IADC Code',
      },
      {
        field: 'rssMaxDls',
        colId: 'rssMaxDls',
        headerName: 'Max DLS',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 50,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'maxWob',
        colId: 'maxWob',
        headerName: 'Max WOB',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'stabWrapAngle',
        colId: 'stabWrapAngle',
        headerName: 'Stab Wrap Angle',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'stabTaperAngle',
        colId: 'stabTaperAngle',
        headerName: 'Stab Taper Angle',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'stabTransitionRadius',
        colId: 'stabTransitionRadius',
        headerName: 'Stab Transition Radius',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'stabFlowbyPass',
        colId: 'stabFlowbyPass',
        headerName: 'Stab Flow Bypass (%)',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''),
      },
      {
        field: 'stabFabricationType',
        colId: 'stabFabricationType',
        headerName: 'Stab Fabrication Type',
      },
      {
        field: 'stabHardFacingType',
        colId: 'stabHardFacingType',
        headerName: 'Stab Hard Facing Type',
      },
      {
        field: 'statorCutBack',
        colId: 'statorCutBack',
        headerName: 'Stator Cutback',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
      },
      {
        field: 'restrictorSize',
        colId: 'restrictorSize',
        headerName: 'restrictorSize',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
      },
      {
        field: 'rotorEccentricity',
        colId: 'rotorEccentricity',
        headerName: 'Rotor Eccentricity',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 2) : ''
        },
      },
      {
        field: 'stabGaugeOut',
        colId: 'stabGaugeOut',
        headerName: 'Stab Gauge Out',
        cellEditorParams: {
          min: 0,
          max: 5000,
          precision: 3,
        },
        valueFormatter: (params) => {
          return params?.value > 0 ? numberWithCommasDecimals(params?.value, 3) : ''
        },
      },
      {
        field: 'elastomerType',
        colId: 'elastomerType',
        headerName: 'Elastomer Type',
      },
      {
        field: 'rotorBaseMaterial',
        colId: 'rotorBaseMaterial',
        headerName: 'Rotor Base Material',
      },
      {
        field: 'rotorCoating',
        colId: 'rotorCoating',
        headerName: 'Rotor Coating',
      },
      {
        field: 'hasRotorCatch',
        colId: 'hasRotorCatch',
        headerName: 'Rotor Catch',
        minWidth: 100,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
    ],
    [dateTimeFormatter, dropDownLists],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={componentData ? [componentData] : []}
        columnDefs={columnDefs.filter((def) => visibleColumns.find((col) => col === def.field))}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default DrillStringComponentPropertiesGrid
