import React, { useRef, useEffect, useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import { cloneDeep } from 'lodash'
import { camelCaseToLabel } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const DEFAULT_REPORT_SETTINGS = {
  primaryColor: '#D8F2FF',
  secondaryColor: '#D8F2FF',
  borderColor: '#000000',
  textColor: '#000000',
  alternatingRowColor: '#EFEFEF',
}

const ReportSettingsModal = ({ reportSettings, onClose }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const getContextMenuItems = (params) => {
    return ['copy']
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (!reportSettings) return
    let rowData = []
    for (let key in reportSettings) {
      let defaultValue = DEFAULT_REPORT_SETTINGS[key]

      rowData.push({
        id: key,
        label: camelCaseToLabel(key),
        value: reportSettings[key],
        type: 'color',
        defaultValue: defaultValue,
      })
    }

    params.api.setGridOption('rowData', rowData)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        headerName: 'Label',
        editable: false,
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        minWidth: 350,
        editable: true,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'color') {
            return {
              component: ColorPickerCellEditor,
              params: {
                color: params.value,
                defaultColor: params.data?.defaultValue,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                precision: 3,
              },
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
      },
    ],
    [],
  )

  const handleSave = async () => {
    let newReportSettings = cloneDeep(DEFAULT_REPORT_SETTINGS)

    gridApi.current.forEachNode((node) => {
      if (!node.data) return
      newReportSettings[node.data.id] = node.data.value
    })

    onClose(newReportSettings)
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='xl'
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Report Settings`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={0}
              getRowId={getRowId}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handleSave()} color='primary'>
          Apply
        </Button>
        <Button variant='outlined' onClick={() => onClose(null)} color='secondary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReportSettingsModal
