import { useEffect, useRef } from 'react'

import useInnovaAxios from './useInnovaAxios'

function useOrganizationsDropDowns() {
  const _isMounted = useRef(true)
  const dropDownData = useRef(null)
  const isLoading = useRef(false)

  const getData = useInnovaAxios({
    url: '/admin/getOrganizationsDropDowns',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDowns()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDowns = async () => {
    if (isLoading.current) return
    isLoading.current = true

    const response = await getData()

    if (_isMounted.current) {
      dropDownData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const getAwsRegions = () => {
    if (!dropDownData.current) return []
    if (!dropDownData.current.hasOwnProperty('awsRegions')) return []
    if (!Array.isArray(dropDownData.current.awsRegions)) return []

    let awsRegions = []
    for (let i = 0; i < dropDownData.current.awsRegions.length; i++) {
      awsRegions.push(dropDownData.current.awsRegions[i].awsRegion)
    }

    return awsRegions
  }

  const getProductFeatureList = () => {
    if (!dropDownData.current) return []
    if (!Array.isArray(dropDownData.current?.productFeatures)) return []
    if (!Array.isArray(dropDownData.current?.productKeys)) return []

    let output = dropDownData.current.productKeys.map((productKey) => {
      return {
        header: productKey.description,
        productKey: productKey.key,
        data: [],
      }
    })

    for (let i = 0; i < dropDownData.current.productFeatures.length; i++) {
      if (!dropDownData.current.productFeatures[i].hasOwnProperty('productKey')) continue
      if (!Array.isArray(dropDownData.current.productFeatures[i].data)) continue

      let productIndex = output.findIndex((element) => dropDownData.current.productFeatures[i].productKey === element.productKey)
      if (productIndex < 0) return
      for (let j = 0; j < dropDownData.current.productFeatures[i].data.length; j++) {
        output[productIndex].data.push({
          id: dropDownData.current.productFeatures[i].data[j].id,
          desc: dropDownData.current.productFeatures[i].data[j].featureDescription,
        })
      }
    }


    return output
  }

  const getDbCredsForAwsRegion = (region) => {
    if (!region) return null
    if (typeof region !== 'string') return null
    if (region === '') return null
    if (!dropDownData.current) return null
    if (!dropDownData.current.hasOwnProperty('awsRegions')) return null
    if (!Array.isArray(dropDownData.current.awsRegions)) return null

    for (let i = 0; i < dropDownData.current.awsRegions.length; i++) {
      if (region === dropDownData.current.awsRegions[i].awsRegion) {
        return dropDownData.current.awsRegions[i]
      }
    }

    return null
  }

  return { getAwsRegions, getProductFeatureList, fetchDropDowns, getDbCredsForAwsRegion }
}

export default useOrganizationsDropDowns
