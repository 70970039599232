import React from 'react'
import useWellData from 'components/common/hooks/useWellData'
import PhaseFootageChart from 'components/WellPages/WellDetailsDashboard/cards/PhaseFootageChart'

const PhaseStatDrilledCard = () => {
  const { wellData } = useWellData()

  return <PhaseFootageChart data={wellData} />
}

export default PhaseStatDrilledCard
