import React, { useState } from 'react'

import { Avatar, Drawer, Tooltip, Box } from '@mui/material'
import { styled } from '@mui/styles'
import { GetActivityColor, GetActivityText, getStatusAvatar } from '../common/activitySelector'
import { useRecoilValue } from 'recoil'
import { currentWellDetailsSelector } from 'atoms'
import StyledBadge from './StyledBadge'
import SimpleWellList from './SimpleWellList'

const StyledAvatarContainer = styled(Box)({
  margin: '10px',
  marginRight: '15px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'lightgrey',
  borderRadius: '50%',
  zIndex: 10,
  cursor: 'pointer',
})

const WellDrawer = ({ selectorToggle, setSelectorToggle }) => {
  const onCloseDrawer = (event) => {
    setSelectorToggle(false)
  }

  return (
    <React.Fragment>
      <Drawer
        anchor='right'
        disableEnforceFocus
        open={selectorToggle}
        onClose={onCloseDrawer}
        PaperProps={{ sx: { backgroundColor: 'itemBackground' } }}>
        <SimpleWellList />
      </Drawer>
    </React.Fragment>
  )
}

const WellSelector = () => {
  const currentWellDetails = useRecoilValue(currentWellDetailsSelector)
  const [selectorToggle, setSelectorToggle] = useState(false)

  const onSelectWell = () => {
    setSelectorToggle(!selectorToggle)
  }

  return (
    <React.Fragment>
      <Tooltip
        title={'Select well'}
        placement='bottom'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <StyledAvatarContainer onClick={() => onSelectWell()}>
          <StyledBadge
            badgecolor={GetActivityColor(currentWellDetails.wellStatus)}
            badgeContent={GetActivityText(currentWellDetails.wellStatus)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Avatar
              alt='Well Icon'
              src={getStatusAvatar(currentWellDetails.wellStatus)}
              style={{ height: '30px', width: '30px' }}></Avatar>
          </StyledBadge>
        </StyledAvatarContainer>
      </Tooltip>
      <WellDrawer selectorToggle={selectorToggle} setSelectorToggle={setSelectorToggle} />
    </React.Fragment>
  )
}

export default WellSelector
