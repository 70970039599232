import React, { useEffect, useMemo, useRef, useState } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Box, Tooltip, CircularProgress } from '@mui/material'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import { isEqual } from 'lodash'
import { htmlSymbolHandling ,getStringId } from 'components/common/AgGridUtils'
import { refreshSurveyProgramGridAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const SideTrackGrid = ({ wellName, isPlan, setStatus }) => {
  const _isMounted = useRef(false)  
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [wellData, setWellData] = useState(null)
  const [isUpdating, setUpdating] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const sideTrackParentsRef = useRef([])
  const welldataRef = useRef(null)
  const orgDataRef = useRef(null)
  const isUpdatingRef = useRef(false)
  const { getUnitsText } = useUnits()
  const setRefreshSurveyProgram = useSetRecoilState(refreshSurveyProgramGridAtom)
  const { getAgGridTheme, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  const getWellData = useInnovaAxios({
    url: isPlan ? '/plannedWell/getPlannedWells' : '/well/getActualWells',
  })

  const updateWellSideTrackData = useInnovaAxios({
    url: isPlan ? '/plannedWell/createSideTrack' : '/well/createSideTrack',
  })

  const getSideTrackParentWells = useInnovaAxios({
    url: isPlan ? '/plannedWell/getSidetrackWellList' : '/well/getSidetrackWellList',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchSideTrackParents()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSideTrackParents = async () => {
    const res = await getSideTrackParentWells({ planName: wellName, wellName: wellName })
    if (!_isMounted.current) return
    if (res?.error) return

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    let data = []
    res.data.forEach((item) => {
      data.push({
        wellName: item.actualWell,
        isPlan: item.isPlan,
        datumElevation: item.datumElevation,
        depthFrom: item.tieOn.depthFrom,
        depthTo: item.tieOn.depthTo,
      })
    })

    sideTrackParentsRef.current = cloneDeep(data)
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getWellData({ planName: wellName, actualWell: wellName })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    let data = {
      wellName: res.data[0].wellName,
      actualWellName: res.data[0].actualWellName,
      principal: res.data[0].principal,
      description: res.data[0].description,
      locked: res.data[0].locked,
      datumName: res.data[0].depthReference.Name,
      datumElevation: res.data[0].depthReference.Elevation,
      VsAzi: res.data[0].verticalSection.VsAzi,
      VsOrgNorth: res.data[0].verticalSection.VsOrgNorth,
      VsOrgEast: res.data[0].verticalSection.VsOrgEast,
      VsOrigin: res.data[0].verticalSection.VsOrigin,
      sideTrackParentWell: res.data[0].sidetrackData.parentWell,
      sideTrackDepth: res.data[0].sidetrackData.Depth,
      sideTrackErrorsStart: res.data[0].sidetrackData.sideTrackErrorsStart,
      isSideTrack: res.data[0].sidetrackData.isSidetrack,
      md: res.data[0].tieOnData.tieOn.md,
      inc: res.data[0].tieOnData.tieOn.inc,
      azi: res.data[0].tieOnData.tieOn.azi,
      tvd: res.data[0].tieOnData.tieOn.tvd,
      ns: res.data[0].tieOnData.tieOn.ns,
      ew: res.data[0].tieOnData.tieOn.ew,
      inputMeth: res.data[0].tieOnData.inputMeth,
      parentSurveyWell: res.data[0].tieOnData.parentSurveyWell,
      parentTieOn: res.data[0].tieOnData.parentTieOn,
      orgPlannedWellName: res.data[0].actualWellName,
      isParentWellPlan: false,
    }

    welldataRef.current = cloneDeep(data)
    orgDataRef.current = cloneDeep(data)
    setWellData(data)
  }

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        cellDataType: false,
        minWidth: 400,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => true,
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'bool') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: 0,
                max: 100000,
                precision: 2,
              },
            }
          }

          return null
        },
        valueGetter: (params) => {
          if (params?.data?.type === 'text') {
            return unescapeHtml(params?.data?.value)
          }

          if (params?.data?.type === 'number') {
            return parseFloat(params?.data?.value)
          }

          return params?.data?.value
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, 2)}`
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      updateWellData(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getGridData = () => {
    if (!welldataRef.current) return []

    let gridData = [
      {
        label: isPlan ? 'Plan Tied On' : 'Is Sidetrack',
        value: welldataRef.current?.isSideTrack,
        id: 0,
        type: 'bool',
        tag: `isSideTrack`,
        editable: true,
      },
    ]

    if (welldataRef.current?.isSideTrack) {
      gridData.push(
        {
          label: isPlan ? `Parent Well / Plan` : 'Parent Well',
          value: welldataRef.current?.sideTrackParentWell,
          id: 1,
          type: 'dropDown',
          tag: `sideTrackParentWell`,
          editable: welldataRef.current?.isSideTrack,
          dropDownValues: sideTrackParentsRef.current.map((item) => item.wellName),
        },
        {
          label: `Errors Start`,
          value: welldataRef.current?.sideTrackErrorsStart === 'ST_POINT' ? 'ST Point' : 'Surface',
          id: 2,
          type: 'dropDown',
          tag: `sideTrackErrorsStart`,
          editable: welldataRef.current?.isSideTrack,
          dropDownValues: ['ST Point', 'Surface'],
        },
        {
          label: isPlan ? `Tie On/ST Depth` : 'ST Depth',
          value: welldataRef.current?.sideTrackDepth,
          id: 3,
          type: 'number',
          tag: `sideTrackDepth`,
          editable: welldataRef.current?.isSideTrack,
        },
      )
    }

    return gridData
  }

  const getDepthDatumText = () => {
    if (!welldataRef.current) return ''
    return `Depths relative to: ${welldataRef.current?.datumName} @ ${numberWithCommasDecimals(
      welldataRef.current?.datumElevation,
      2,
    )}${getUnitsText(UNITS_FOR.Depth)}`
  }

  const getDeltaElevation = (sideTrackParentWell) => {
    if (!welldataRef.current?.isSideTrack) return 0
    let parent = sideTrackParentsRef.current.find((item) => item.wellName === sideTrackParentWell)
    if (!parent) return 0

    return welldataRef.current?.datumElevation - parent.datumElevation
  }

  const updateWellData = (data) => {
    if (!data) return

    let newData = cloneDeep(welldataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    if (newData.sideTrackErrorsStart === 'Surface') {
      newData.sideTrackErrorsStart = 'SURFACE'
    }

    if (newData.sideTrackErrorsStart === 'ST Point') {
      newData.sideTrackErrorsStart = 'ST_POINT'
    }

    if (!newData.isSideTrack) {
      newData.sideTrackParentWell = ''
      newData.sideTrackDepth = 0
      newData.sideTrackErrorsStart = 'ST_POINT'
    }

    if (newData.isSideTrack && sideTrackParentsRef.current?.length === 0) {
      newData.isSideTrack = false
      newData.sideTrackDepth = 0
      newData.sideTrackErrorsStart = 'ST_POINT'

      setStatus({
        show: true,
        severity: 'warning',
        message: `No parent wells found`,
      })
    }

    if (newData.isSideTrack && sideTrackParentsRef.current?.length > 0 && !welldataRef.current?.isSideTrack) {
      newData.sideTrackErrorsStart = 'ST_POINT'
      newData.sideTrackParentWell = sideTrackParentsRef.current[0].wellName

      let deltaElev = welldataRef.current?.datumElevation - sideTrackParentsRef.current[0].datumElevation
      newData.sideTrackDepth = sideTrackParentsRef.current[0].depthTo + deltaElev
    }

    if (newData.isSideTrack && sideTrackParentsRef.current?.length > 0) {
      let parent = sideTrackParentsRef.current.find((item) => item.wellName === newData.sideTrackParentWell)

      let deltaElevation = getDeltaElevation(newData.sideTrackParentWell)
      let adjustedSideTrackDepth = newData.sideTrackDepth - deltaElevation

      if (parent && (adjustedSideTrackDepth < parent.depthFrom || adjustedSideTrackDepth > parent.depthTo)) {
        setStatus({
          show: true,
          severity: 'warning',
          message: `Side track depth should be between ${parent.depthFrom + deltaElevation} and ${
            parent.depthTo + deltaElevation
          }`,
        })

        if (adjustedSideTrackDepth < parent.depthFrom) {
          newData.sideTrackDepth = parent.depthFrom + deltaElevation
        }

        if (adjustedSideTrackDepth > parent.depthTo) {
          newData.sideTrackDepth = parent.depthTo + deltaElevation
        }
      }

      if (!parent) {
        setStatus({
          show: true,
          severity: 'warning',
          message: `Could not find parent well`,
        })
      }

      if (parent) {
        newData.isParentWellPlan = parent.isPlan
      }
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    welldataRef.current = cloneDeep(newData)
    setWellData(newData)
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    let res = await updateWellSideTrackData(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setUpdating(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
    setRefreshSurveyProgram(true)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => handleUpdate(welldataRef.current)}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          welldataRef.current = cloneDeep(orgDataRef.current)
          setDataChanged(false)
          setWellData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: getChartBackColor(),
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Box sx={verticalTextStyle}>Sidetrack / Tie On Details</Box>
        <Box sx={{ width: '100%', height: '100%' }}>
          <div style={{ height: 'calc(100% - 25px)', width: '100%' }} className={getAgGridTheme()}>
            <AgGridReact
              className='ag-grid-theme-dark'
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              rowData={getGridData(wellData)}
              headerHeight={0}
              onFirstDataRendered={onFirstDataRendered}
              getRowId={getRowId}
            />
          </div>
          <Box
            sx={{
              width: '100%',
              height: '25px',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              backgroundColor: getWindowBarColor(),
              color: 'white',
              paddingLeft: '10px',
            }}>
            {getDepthDatumText()}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default SideTrackGrid
