import { Box } from '@mui/material'
import WellSchematic from 'components/EDR/LeftPane/WellSchematic'
import SectionViewChart from 'components/WellPages/SectionView/SectionViewChart'
import PlanViewChart from 'components/WellPages/PlanView/PlanViewChart'
import { forwardRef, useImperativeHandle, useRef } from 'react'

const WellCharts = forwardRef((props, ref) => {
  const chartSectionViewRef = useRef(null)
  const chartPlanViewRef = useRef(null)
  const chartWellSchematicRef = useRef(null)
  const chartSecionPlanViewRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      getChartType(chartType) {
        if (chartType === 'sectionView') return chartSectionViewRef
        if (chartType === 'planView') return chartPlanViewRef
        if (chartType === 'wellSchematic') return chartWellSchematicRef
        if (chartType === 'sectionPlanView') return chartSecionPlanViewRef
        return null
      },
    }
  })

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box ref={chartSectionViewRef} sx={{ width: '100%', height: '100%' }}>
        <SectionViewChart data={props.props.wellData} printing={true} />
      </Box>
      <Box ref={chartPlanViewRef} sx={{ width: '100%', height: '100%' }}>
        <PlanViewChart data={props.props.wellData} printing={true} />
      </Box>
      <Box ref={chartWellSchematicRef} sx={{ width: '25%', height: '200%' }}>
        <WellSchematic casingData={props.props.casingData} backGroundGradient={true} printing={true} />
      </Box>
      <Box ref={chartSecionPlanViewRef} sx={{ width: '65%', height: '200%' }}>
        <Box sx={{ width: '100%', height: '50%' }}>
          <SectionViewChart data={props.props.wellData} printing={true} />
        </Box>
        <Box sx={{ width: '100%', height: '50%' }}>
          <PlanViewChart data={props.props.wellData} printing={true} />
        </Box>
      </Box>
    </Box>
  )
})

export default WellCharts
