import React from 'react'
import { Bar } from 'react-chartjs-2'
import { merge as _merge } from 'lodash'
import { Box } from '@mui/material'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BarChartCjs = ({ wellData, title, xAxisTitle, yAxisTitle, chartOptions, backgroundColor = null }) => {
  const { theme, getChartBackColor } = useInnovaTheme()

  if (!backgroundColor) backgroundColor = getChartBackColor()

  const defaultOptions = {
    layout: {
      padding: {
        right: 20,
        top: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: true,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisTitle,
          color: appColors.itemTextColor,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        grid: {
          color: appColors.headerTextColor,
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: yAxisTitle,
          color: appColors.itemTextColor,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        grid: {
          color: appColors.headerTextColor,
        },
        stacked: true,
      },
    },
  }

  const options = _merge(defaultOptions, chartOptions)

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        display: 'flex',
        width: '99.9%',
        height: '100%',
        overflow: 'hidden',
      }}>
      <Bar options={options} data={wellData} />
    </Box>
  )
}

export default BarChartCjs
