import { useEffect, useRef } from 'react'

import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'

function usePumpData() {
  const _isMounted = useRef(true)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)

  const getPumpData = useInnovaAxios({
    url: '/well/dailyReports/getPumpData',
  })

  useEffect(() => {
    if (!currentWell || !_isMounted.current) return
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const fetchPumpData = async (dailyReportId) => {
    if (!dailyReportId || typeof dailyReportId !== 'number' || dailyReportId?.length <= 0) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return

    const response = await getPumpData({ dailyReportId: dailyReportId, wellName: currentWellRef.current })
    if (_isMounted.current && response?.data) {
      return response?.data ? response.data : []
    }
  }

  return {
    fetchPumpData,
  }
}

export default usePumpData
