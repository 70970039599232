import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, relativeTime, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { Icon as Iconify } from '@iconify/react'
import { styled } from '@mui/styles'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIcon = styled(Iconify)({
  color: 'gray',
  width: '25px',
  height: '25px',
  '&:hover': {
    cursor: 'pointer',
  },
})

const AuditDataPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [auditData, setAuditData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const { getAgGridTheme, searchBarStyle, getWindowBarColor } = useInnovaTheme()

  const pageSize = 1000

  const getData = useInnovaAxios({
    url: '/admin/getAuditData',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.auditDataPageKey)
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [currentPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData({ startRecord: currentPage * pageSize, numRecords: pageSize })
    if (_isMounted.current) {
      setAuditData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('auditDataGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('auditDataGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AuditData.xlsx',
            sheetName: 'AuditData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const relativeTimeFormatter = useCallback(relativeTime, [])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useMemo(
    () => (value) => {
      if (!value) return ''
      if (typeof value !== 'string') return ''
      if (value === '') return ''
      if (isDateLessThan(value, '1990-01-01')) return ''
      value = value.replace(/Z/g, '')

      return new Date(Date.parse(value)).toLocaleDateString('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        colId: 'rowNum',
        headerName: '#',
        valueGetter: (params) => currentPage * pageSize + params.node.rowIndex + 1,
        cellStyle: centerAlignCell,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'isoTimeStamp',
        colId: 'isoTimeStamp',
        headerName: 'Time',
        valueGetter: (params) =>
          `${dateTimeFormatter(params.data?.isoTimeStamp)} [${relativeTimeFormatter(params.data?.isoTimeStamp)}]`,
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'Username',
      },
      {
        field: 'objectName',
        colId: 'objectName',
        headerName: 'Object',
        valueGetter: (params) => unescapeHtml(params.data?.objectName),
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
      },
    ],
    [centerAlignCell, dateTimeFormatter, currentPage, relativeTimeFormatter],
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      <Box
        sx={{
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={auditData}
            columnDefs={sortColDefs(columnDefs, 'auditDataGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: getWindowBarColor(),
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          height: '50px',
        }}>
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
          }}>
          <Box
            sx={{
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>{`${currentPage * pageSize + 1} to ${(currentPage + 1) * pageSize} of ${
            (currentPage + 1) * pageSize
          }`}</Box>
          <StyledIcon icon={'cil:media-step-backward'} onClick={() => setCurrentPage(0)} />
          <StyledIcon icon={'ion:chevron-back-sharp'} onClick={() => setCurrentPage(currentPage - 1)} />
          <Box
            sx={{
              marginLeft: '10px',
              marginRight: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>{`Page ${currentPage + 1} of more`}</Box>
          <StyledIcon icon={'ion:chevron-forward-sharp'} onClick={() => setCurrentPage(currentPage + 1)} />
          <StyledIcon icon={'cil:media-step-forward'} />
        </Box>
      </Box>
    </Box>
  )
}

export default AuditDataPage
