import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from './useInnovaAxios'

function useAddInstantWellDropDowns(fetchData) {
  const _isMounted = useRef(true)
  const [dropDowns, setDropDowns] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [hasDistricts, setHasDistricts] = useState(false)
  const [hasCountrys, setHasCountrys] = useState(false)
  const [rawData, setRawData] = useState()

  const getData = useInnovaAxios({
    url: '/well/getInstantActualDropDowns',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getDropDowns()
  }, [rawData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fetchData) fetchRawData()
  }, [fetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRawData = async () => {
    const response = await getData()

    if (_isMounted.current && response?.data) {
      setRawData(response.data)
    }
  }

  function makeDropDownListCounty(input) {
    if (!input) return []
    if (!Array.isArray(input)) return []
    if (input.length === 0) return []

    let outputList = []
    for (let i = 0; i < input.length; i++) {
      if (!input[i].hasOwnProperty('countys')) continue
      if (!Array.isArray(input[i].countys)) continue

      for (let j = 0; j < input[i].countys.length; j++) {
        outputList.push({ label: input[i].countys[j], value: input[i].countys[j], state: input[i].stateName })
      }
    }

    return outputList
  }

  function makeDropDownListStates(input) {
    if (!input) return []
    if (!Array.isArray(input)) return []
    if (input.length === 0) return []

    let outputList = []
    input.forEach((item) => outputList.push({ label: item.stateName, value: item.stateName }))
    return outputList
  }

  function makeDropDownList(input) {
    if (!input) return []
    if (!Array.isArray(input)) return []
    if (input.length === 0) return []

    let outputList = []
    input.forEach((item) => outputList.push({ label: item, value: item }))
    return outputList
  }

  function makeDropDownListTreeItem(input, labelName) {
    if (!input) return []
    if (!Array.isArray(input)) return []
    if (input.length === 0) return []

    let outputList = []
    input.forEach((item) => {
      let newListItem = { label: item.name, value: item.name }
      newListItem[labelName] = item.parent
      outputList.push(newListItem)
    })
    return outputList
  }

  async function getDropDowns() {
    if (_isMounted.current === true) setIsLoading(true)
    if (!rawData) return

    let newDropDowns = {
      operators: [],
      fields: [],
      facilitys: [],
      wells: [],
      actualWells: [],
      northRef: [],
      rigNames: [],
      statesList: [],
      countyList: [],
      district: [],
      countrys: []
    }

    if (rawData) {
      newDropDowns.operators = makeDropDownList(rawData.operators)
      newDropDowns.fields = makeDropDownListTreeItem(rawData.fields, 'operator')
      newDropDowns.facilitys = makeDropDownListTreeItem(rawData.facilitys, 'field')
      newDropDowns.wells = makeDropDownListTreeItem(rawData.wells, 'facility')
      newDropDowns.northRef = makeDropDownList(rawData.northRef)
      newDropDowns.rigNames = makeDropDownList(rawData.rigNames)
      newDropDowns.statesList = makeDropDownListStates(rawData.states)
      newDropDowns.countyList = makeDropDownListCounty(rawData.states)
      newDropDowns.district = makeDropDownList(rawData.district)
      newDropDowns.countrys = makeDropDownList(rawData.countrys)
      newDropDowns.provinces = Array.isArray(rawData?.provinces) ? makeDropDownList(rawData.provinces) : []
    }

    if (_isMounted.current === true) {
      if (newDropDowns.district.length > 0) setHasDistricts(true)
      if (newDropDowns.statesList.length === 0) setHasCountrys(true)

      setDropDowns(newDropDowns)
      setIsLoading(false)
    }
  }
  return [dropDowns, isLoading, hasCountrys, hasDistricts]
}

export default useAddInstantWellDropDowns
