import React, { useEffect, useRef, useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import BarChart from 'components/common/BarChartCjs'
import { chartSeriesColors, isoStringToDateString, appColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import { Icon as Iconify } from '@iconify/react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export function ChartButton({ onClick, icon, toolTipText, color, width, height, group }) {
  return onClick ? (
    <Box
      style={{
        position: group ? 'relative' : 'absolute',
        top: 0,
        left: 0,
        marginTop: group ? '0px' : '10px',
        marginLeft: group ? '5px' : '10px',
        padding: '5px',
        borderRadius: '5px',
        backgroundColor: 'black',
        border: '1px solid gray',
      }}>
      <Tooltip
        title={toolTipText}
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Iconify
          icon={icon}
          color={color || 'gray'}
          onClick={() => onClick()}
          style={{
            width: width || '28px',
            height: height || '28px',
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    </Box>
  ) : null
}

const IncidentHistoryChart = ({ filteredIncidents }) => {
  const _isMounted = useRef(false)
  const [chartType, setChartType] = useState('incidentType')
  const [incUnplannedTrips, setIncUnplannedTrips] = useState(false)
  const { theme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const isIncident = (data) => {
    if (data.rssFailure) return true
    if (data.motorFailure) return true
    if (data.mwdFailure) return true
    if (data.failed) return true
    if (data.catastrophicFailure) return true
    if (incUnplannedTrips && data.unplannedTrip) return true
    return false
  }

  const numberOfIncidentsPerRun = (data) => {
    let numIncidents = 0
    if (data.rssFailure) numIncidents++
    if (data.motorFailure) numIncidents++
    if (data.mwdFailure) numIncidents++
    if (data.catastrophicFailure) numIncidents++
    return numIncidents
  }

  const getIncidentTypeString = (data) => {
    if (numberOfIncidentsPerRun(data) > 1) return 'Multiple'
    if (data.rssFailure) return 'RSS'
    if (data.motorFailure) return 'Motor'
    if (data.mwdFailure) return 'MWD'
    if (data.catastrophicFailure) return 'Catastrophic'
    if (incUnplannedTrips && data.unplannedTrip) return 'Unplanned Trip'
    return 'Unknown'
  }

  function getMinMaxDates(datesArray) {
    const dates = datesArray.map((date) => new Date(date))
    const minDate = new Date(Math.min.apply(null, dates))
    const maxDate = new Date(Math.max.apply(null, dates))

    return {
      minDate: minDate.toISOString().split('T')[0],
      maxDate: maxDate.toISOString().split('T')[0],
    }
  }

  function generateDates(startDate, endDate) {
    const startMonth = new Date(Date.parse(startDate)).getUTCMonth()
    const startYear = new Date(Date.parse(startDate)).getUTCFullYear()

    const endDateObject = new Date(Date.parse(endDate))
    endDateObject.setMonth(endDateObject.getMonth() + 1)
    endDate = endDateObject.toISOString()

    const endMonth = new Date(Date.parse(endDate)).getUTCMonth()
    const endYear = new Date(Date.parse(endDate)).getUTCFullYear()

    const uniqueMonths = []

    for (let year = startYear; year <= endYear; year++) {
      const monthStart = year === startYear ? startMonth : 0
      const monthEnd = year === endYear ? endMonth : 11

      for (let month = monthStart; month <= monthEnd; month++) {
        const formattedMonth = String(month + 1).padStart(2, '0')
        const isoDate = `${year}-${formattedMonth}-01`
        uniqueMonths.push(isoDate)
      }
    }

    return uniqueMonths
  }

  const getSeriesColor = (index) => {
    if (typeof index !== 'number') getRandomColor()
    if (index < chartSeriesColors.length) return chartSeriesColors[index]
    return getRandomColor()
  }

  const createChartData = () => {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!filteredIncidents) return output
    if (!Array.isArray(filteredIncidents)) return output
    if (filteredIncidents.length === 0) return output

    if (chartType === 'operator') {
      for (let i = 0; i < filteredIncidents.length; i++) {
        let index = output.datasets.findIndex((op) => op.label === filteredIncidents[i].operator)
        if (index >= 0) continue

        output.datasets.push({
          label: filteredIncidents[i].operator,
          backgroundColor: getSeriesColor(output.datasets.length),
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        })
      }
    }

    if (chartType === 'incidentType') {
      let incidentTypes = ['Motor', 'MWD', 'RSS', 'Catastrophic', 'Multiple', 'Unknown']
      if (incUnplannedTrips) incidentTypes.push('Unplanned Trip')

      for (let i = 0; i < incidentTypes.length; i++) {
        output.datasets.push({
          label: incidentTypes[i],
          backgroundColor: getSeriesColor(output.datasets.length),
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        })
      }
    }

    let datesArray = []
    for (let i = 0; i < filteredIncidents.length; i++) {
      if (!isIncident(filteredIncidents[i])) continue
      let dateObj = new Date(Date.parse(filteredIncidents[i].bhaPickUpDateTime))
      if (isNaN(dateObj.getTime())) continue

      datesArray.push(filteredIncidents[i].bhaPickUpDateTime)
    }

    if (datesArray.length === 0) return output

    const { minDate, maxDate } = getMinMaxDates(datesArray)
    output.labels = generateDates(minDate, maxDate)

    if (output.labels.length === 0) return output
    for (let i = 0; i < output.datasets.length; i++) {
      for (let j = 0; j < output.labels.length; j++) {
        output.datasets[i].data.push(0)
      }
    }

    for (let i = 0; i < filteredIncidents.length; i++) {
      if (!isIncident(filteredIncidents[i])) continue
      let dt = new Date(Date.parse(filteredIncidents[i].bhaPickUpDateTime))

      let pointIndex = output.labels.findIndex((dateStr) => {
        let seriesDate = new Date(Date.parse(dateStr))
        if (seriesDate.getFullYear() === dt.getFullYear() && seriesDate.getMonth() === dt.getMonth()) {
          return true
        }

        return false
      })

      let dataIndex = -1
      if (chartType === 'operator') {
        dataIndex = output.datasets.findIndex((op) => op.label === filteredIncidents[i].operator)
      }

      if (chartType === 'incidentType') {
        dataIndex = output.datasets.findIndex((type) => type.label === getIncidentTypeString(filteredIncidents[i]))
      }

      if (pointIndex >= 0 && dataIndex >= 0) output.datasets[dataIndex].data[pointIndex] += 1
    }

    for (let i = 0; i < output.labels.length; i++) {
      let dt = new Date(Date.parse(output.labels[i]))
      output.labels[i] = dt.toLocaleDateString('default', { year: 'numeric', month: 'long' })
    }

    return output
  }

  const chartOptions = {
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return isoStringToDateString(tooltipItem[0].label)
          },
          label: function (context) {
            return `${context.parsed.y} - ${context.dataset.label}`
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          marginLeft: '5px',
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <ChartButton
          onClick={() => setChartType('incidentType')}
          icon={'gis:globe-options'}
          toolTipText={'Incident Type'}
          color={chartType === 'incidentType' ? appColors.itemTextColor : 'gray'}
          width={20}
          height={20}
          group={true}
        />
        <ChartButton
          onClick={() => setChartType('operator')}
          icon={'mdi:barrel'}
          toolTipText={'Operator'}
          color={chartType === 'operator' ? appColors.itemTextColor : 'gray'}
          width={20}
          height={20}
          group={true}
        />
        <ChartButton
          onClick={() => setIncUnplannedTrips(!incUnplannedTrips)}
          icon={'carbon:warning'}
          toolTipText={'Include unplanned trips'}
          color={incUnplannedTrips ? appColors.itemTextColor : 'gray'}
          width={20}
          height={20}
          group={true}
        />
      </Box>
      <BarChart
        wellData={createChartData()}
        units={''}
        title={'Incidents'}
        xAxisTitle='Date'
        yAxisTitle={`#Incidents`}
        chartOptions={chartOptions}
      />
    </Box>
  )
}

export default IncidentHistoryChart
