export function unescapeHtml(inputStr) {
  if (typeof inputStr !== 'string') return inputStr

  inputStr = inputStr.replaceAll('&dq;', '"')
  inputStr = inputStr.replaceAll('&dq', '"')
  inputStr = inputStr.replaceAll('&sq;', "'")
  inputStr = inputStr.replaceAll('&gt;', '>')
  inputStr = inputStr.replaceAll('&lt;', '<')
  inputStr = inputStr.replaceAll('&amp;', '&')
  //inputStr = inputStr.replaceAll('?', '°')
  return inputStr
}

export function escapeHtml(inputStr) {
  if (typeof inputStr !== 'string') return inputStr

  //inputStr = inputStr.replace('&', '&amp;')
  inputStr = inputStr.replaceAll('“', '&dq;')
  inputStr = inputStr.replaceAll('”', '&dq;')
  inputStr = inputStr.replaceAll('"', '&dq;')
  inputStr = inputStr.replaceAll("'", '&sq;')
  inputStr = inputStr.replaceAll('>', '&gt;')
  inputStr = inputStr.replaceAll('<', '&lt;')
  inputStr = inputStr.replaceAll('¾', '.75')
  inputStr = inputStr.replaceAll('¼', '.25')
  inputStr = inputStr.replaceAll('½', '.5')
  inputStr = inputStr.replaceAll('⅝', '.625')
  inputStr = inputStr.replaceAll('°', 'deg')
  inputStr = inputStr.replaceAll('⅞', '.875')
  inputStr = inputStr.replaceAll('⅛', '.125')
  inputStr = inputStr.replaceAll('’’', '&dq;')
  return inputStr
}

export function escapeHtmlObj(input) {
  if (input === null || input === undefined) return
  if (typeof input !== 'object') return

  for (const property in input) {
    if (typeof input[property] === 'string') input[property] = escapeHtml(input[property])
  }
}

export function unescapeHtmlObj(input) {
  if (input === null || input === undefined) return
  if (typeof input !== 'object') return

  for (const property in input) {
    if (typeof input[property] === 'string') input[property] = unescapeHtml(input[property])
  }
}
