import React, { useState, useRef, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import { cloneDeep } from 'lodash'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SureShopJobInfoModal = ({ wellData, onClose }) => {
  const _isMounted = useRef()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [sureShopJobData, setSureShopJobData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [selectedJob, setSelectedJob] = useState(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const [searchText, setSearchText] = useState('')

  const getSureShopJobList = useInnovaAxios({
    url: '/sureShop/getJobList',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchSureShopJobData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSureShopJobData = async () => {
    if (!wellData?.actualWell) return
    if (wellData?.actualWell === '') return

    if (isLoading) return
    setLoading(true)
    const res = await getSureShopJobList()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }

    if (!Array.isArray(res.data)) return
    setSureShopJobData(res.data)
  }

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        checkboxSelection: true,
        headerName: 'Job ID',
        field: 'jobid',
        colId: 'jobid',
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
      },
      {
        headerName: 'Job#',
        field: 'jobnumber',
        colId: 'jobnumber',
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
      },
      {
        headerName: 'Well',
        field: 'wellname',
        colId: 'wellname',
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
      },
      {
        headerName: 'Company',
        field: 'company',
        colId: 'company',
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
      },
    ],
    [],
  )

  const onClickUpdate = async () => {
    let newData = cloneDeep(wellData)
    newData.jobNum = selectedJob.jobnumber
    onClose(newData)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseDialog = async () => {
    onClose(null)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    if (!_isMounted.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      setSelectedJob(null)
    }

    if (selectedRows.length > 0) {
      setSelectedJob(selectedRows[0]?.data)
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  return (
    <>
      <Dialog
        maxWidth='xl'
        PaperProps={{
          sx: {
            width: '70vw',
            height: '70vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={handleCloseDialog}>
        <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <DialogTitle>SureShop Job List</DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              height: '50px',
            }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle()}
            />
          </Box>
          <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 50px)' }}>
            <AgGridReact
              rowData={sureShopJobData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              enableBrowserTooltips={true}
              headerHeight={30}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
              gridOptions={gridOptions}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {selectedJob ? (
            <Button color='primary' variant='contained' onClick={onClickUpdate}>
              Update job info
            </Button>
          ) : null}
          <Button color='primary' variant='contained' onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default SureShopJobInfoModal
