import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import * as d3 from 'd3'
import { normalize } from 'utils/numberFunctions'

function useWellLogs() {
  const nullValue = -999.25
  const { getUnitsText } = useUnits()
  const MINIMAP_WIDTH_PERCENTAGE = 0.2
  const MAX_MINIMAP_WIDTH = 150
  const Y_SCALE_WIDTH_PERCENTAGE = 0.15
  const MAX_SCALE_WIDTH = 100

  function performOperation(operation, value1, value2) {
    if (value1 === nullValue || value2 === nullValue) return nullValue
    if (operation === '+') return value1 + value2
    if (operation === '-') return value1 - value2
    if (operation === '*') return value1 * value2
    if (operation === '/' && value2 !== 0) return value1 / value2
    return nullValue
  }

  const getCurveUnitsText = (mneomonic) => {
    if (mneomonic === 'bitDepth') return getUnitsText(UNITS_FOR.Depth)
    if (mneomonic === 'holeDepth') return getUnitsText(UNITS_FOR.Depth)
    if (mneomonic === 'blockPosition') return getUnitsText(UNITS_FOR.Depth)
    if (mneomonic === 'rop') return `${getUnitsText(UNITS_FOR.Depth)}/hr`
    if (mneomonic === 'wob') return getUnitsText(UNITS_FOR.Weight)
    if (mneomonic === 'hkld') return getUnitsText(UNITS_FOR.Weight)
    if (mneomonic === 'torque') return getUnitsText(UNITS_FOR.Torque)
    if (mneomonic === 'pressure') return getUnitsText(UNITS_FOR.Pressure)
    if (mneomonic === 'diffPressure') return getUnitsText(UNITS_FOR.Pressure)
    if (mneomonic === 'flow') return getUnitsText(UNITS_FOR.FlowRate)
    return ''
  }

  function getRigStates(data, startTime, endTime) {
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let dataMinY = data[0].convertedTimestamp
    let dataMaxY = data[data.length - 1].convertedTimestamp

    if (!startTime) startTime = dataMinY
    if (!endTime) endTime = dataMaxY

    if (startTime > dataMaxY) return []
    if (endTime < dataMinY) return []

    let seriesData = []
    let lastIndex = -1

    let straddleDataPointBottomIndex = -1
    let straddleDataPointTopIndex = -1

    for (let i = 0; i < data.length; i++) {
      if (!data[i].hasOwnProperty('rigState')) continue
      if (!data[i].hasOwnProperty('convertedTimestamp')) continue

      let rigState = data[i].rigState
      let serialtimeStamp = data[i].convertedTimestamp
      if (serialtimeStamp === nullValue) continue

      if (serialtimeStamp < startTime) straddleDataPointTopIndex = i
      if (serialtimeStamp > endTime) straddleDataPointBottomIndex = i

      if (serialtimeStamp > endTime) break
      if (serialtimeStamp < startTime) continue

      let addData = true
      if (seriesData.length > 0) {
        if (serialtimeStamp < seriesData[seriesData.length - 1].y) addData = false
      }

      if (addData) {
        //Add first data point
        if (seriesData.length === 0 && i > 0) {
          seriesData.push({
            x: rigState,
            y: startTime,
          })
        }

        if (seriesData.length === 1) {
          seriesData.push({
            x: rigState,
            y: serialtimeStamp,
          })
        }

        if (seriesData.length > 1) {
          if (rigState === seriesData[seriesData.length - 1].x) {
            seriesData[seriesData.length - 1].y = serialtimeStamp
          }

          if (rigState !== seriesData[seriesData.length - 1].x) {
            seriesData.push({
              x: rigState,
              y: serialtimeStamp,
            })
          }
        }

        lastIndex = i
      }
    }

    //Ensure there is no gap at the bottom
    if (lastIndex >= 0 && lastIndex < data.length - 1) {
      seriesData.push({
        x: data[lastIndex].avg.rigState,
        y: endTime,
      })
    }

    //No data points within range but data points straddle visible range
    if (seriesData.length === 0 && straddleDataPointTopIndex >= 0 && straddleDataPointBottomIndex >= 0) {
      seriesData.push({
        x: data[straddleDataPointTopIndex].avg.rigState,
        y: startTime,
      })

      seriesData.push({
        x: data[straddleDataPointBottomIndex].avg.rigState,
        y: endTime,
      })
    }

    return seriesData
  }

  function getCalculatedSeriesValues(calculatedSelection, min, max, logData, curveMnemonics, chartMinMax) {
    if (!Array.isArray(calculatedSelection)) return []
    if (calculatedSelection.length === 0) return []
    if (!Array.isArray(logData)) return []
    if (logData.length === 0) return []
    let mnemonic1 = curveMnemonics.find((curve) => curve.label === calculatedSelection[0].curve1)?.value
    if (mnemonic1 === null || mnemonic1 === undefined) return []
    if (typeof mnemonic1 !== 'string') return []
    if (mnemonic1 === '') return []
    let mnemonic2 = curveMnemonics.find((curve) => curve.label === calculatedSelection[0].curve2)?.value
    if (mnemonic2 === null || mnemonic2 === undefined) return []
    if (typeof mnemonic2 !== 'string') return []
    if (mnemonic2 === '') return []
    let operation = calculatedSelection[0].operation?.value
    if (operation === null || operation === undefined) return []
    if (typeof operation !== 'string') return []
    if (operation === '') return []

    if (chartMinMax.viewMinY > chartMinMax.dataMaxY) return []
    if (chartMinMax.viewMaxY < chartMinMax.dataMinY) return []

    let seriesData = []

    let lastIndex = -1

    let straddleDataPointBottomIndex = -1
    let straddleDataPointTopIndex = -1

    for (let i = 0; i < logData.length; i++) {
      if (!logData[i].hasOwnProperty('avg')) continue
      if (!logData[i].avg.hasOwnProperty(mnemonic1)) continue
      if (!logData[i].avg.hasOwnProperty(mnemonic2)) continue
      if (!logData[i].hasOwnProperty('convertedTimestamp')) continue
      if (typeof logData[i].avg[mnemonic1] !== 'number') continue
      if (logData[i].avg[mnemonic1] === nullValue) continue
      if (typeof logData[i].avg[mnemonic2] !== 'number') continue
      if (logData[i].avg[mnemonic2] === nullValue) continue

      let serialtimeStamp = logData[i].convertedTimestamp
      if (serialtimeStamp === nullValue) continue

      if (serialtimeStamp < chartMinMax.viewMinY) straddleDataPointTopIndex = i
      if (serialtimeStamp > chartMinMax.viewMaxY) straddleDataPointBottomIndex = i

      if (serialtimeStamp > chartMinMax.viewMaxY) break
      if (serialtimeStamp < chartMinMax.viewMinY) continue

      let addData = true
      if (seriesData.length > 0) {
        if (serialtimeStamp < seriesData[seriesData.length - 1].y) addData = false
      }

      if (addData) {
        //Add first data point
        if (seriesData.length === 0 && i > 0) {
          let previousDataPoint = performOperation(
            operation,
            logData[i - 1].avg[mnemonic1],
            logData[i - 1].avg[mnemonic2],
          )
          let dataPoint = performOperation(operation, logData[i].avg[mnemonic1], logData[i].avg[mnemonic2])

          let linearScale = d3
            .scaleLinear()
            .domain([logData[i - 1].convertedTimestamp, logData[i].convertedTimestamp])
            .range([previousDataPoint, dataPoint])

          let x = linearScale(chartMinMax.viewMinY)

          seriesData.push({
            x: normalize(x, min, max),
            y: chartMinMax.viewMinY,
          })
        }

        let dataPoint = performOperation(operation, logData[i].avg[mnemonic1], logData[i].avg[mnemonic2])
        seriesData.push({
          x: normalize(dataPoint, min, max),
          y: serialtimeStamp,
        })

        //Decimated Data
        if (logData[i].min && logData[i].max) {
          seriesData.push({
            x: normalize(dataPoint, min, max),
            y: serialtimeStamp,
          })

          seriesData.push({
            x: normalize(dataPoint, min, max),
            y: serialtimeStamp,
          })

          seriesData.push({
            x: normalize(dataPoint, min, max),
            y: serialtimeStamp,
          })
        }

        lastIndex = i
      }
    }

    //Ensure there is no gap at the bottom
    if (lastIndex >= 0 && lastIndex < logData.length - 1) {
      let previousDataPoint = performOperation(
        operation,
        logData[lastIndex].avg[mnemonic1],
        logData[lastIndex].avg[mnemonic2],
      )
      let dataPoint = performOperation(
        operation,
        logData[lastIndex + 1].avg[mnemonic1],
        logData[lastIndex + 1].avg[mnemonic2],
      )
      let linearScale = d3
        .scaleLinear()
        .domain([logData[lastIndex].convertedTimestamp, logData[lastIndex + 1].convertedTimestamp])
        .range([previousDataPoint, dataPoint])

      let x = linearScale(chartMinMax.viewMaxY)

      seriesData.push({
        x: normalize(x, min, max),
        y: chartMinMax.viewMaxY,
      })
    }

    //No data points within range but data points straddle visible range
    if (seriesData.length === 0 && straddleDataPointTopIndex >= 0 && straddleDataPointBottomIndex >= 0) {
      let previousDataPoint = performOperation(
        operation,
        logData[straddleDataPointTopIndex].avg[mnemonic1],
        logData[straddleDataPointTopIndex].avg[mnemonic2],
      )
      let dataPoint = performOperation(
        operation,
        logData[straddleDataPointBottomIndex].avg[mnemonic1],
        logData[straddleDataPointBottomIndex].avg[mnemonic2],
      )

      let linearScale = d3
        .scaleLinear()
        .domain([
          logData[straddleDataPointTopIndex].convertedTimestamp,
          logData[straddleDataPointBottomIndex].convertedTimestamp,
        ])
        .range([previousDataPoint, dataPoint])

      let xTop = linearScale(chartMinMax.viewMinY)
      seriesData.push({
        x: normalize(xTop, min, max),
        y: chartMinMax.viewMinY,
      })

      let xBottom = linearScale(chartMinMax.viewMaxY)
      seriesData.push({
        x: normalize(xBottom, min, max),
        y: chartMinMax.viewMaxY,
      })
    }

    return seriesData
  }

  const calcMiniMapRect = (width, height) => {
    return {
      top: 0,
      left: 0,
      right: Math.min(width * MINIMAP_WIDTH_PERCENTAGE, MAX_MINIMAP_WIDTH),
      height: height,
      bottom: height,
      width: Math.min(width * MINIMAP_WIDTH_PERCENTAGE, MAX_MINIMAP_WIDTH),
    }
  }

  function calcYpixelMinimap(yValue, miniMapData, height) {
    if (yValue === null || yValue === undefined) return 0
    let normVal = normalize(
      yValue,
      miniMapData[0].convertedTimestamp,
      miniMapData[miniMapData.length - 1].convertedTimestamp,
    )
    return height * normVal + 1 //Offset by 1 pixel to stop overlapping the outline
  }

  const calcMmSliderRect = (miniMapData, width, height, chartMinMax) => {
    let output = {
      mmSliderRect: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: 0,
        height: 0,
      },
      topSliderRect: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: 0,
        height: 0,
      },
      btmSliderRect: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: 0,
        height: 0,
      },
    }
    if (!miniMapData) return output
    if (!Array.isArray(miniMapData)) return output
    if (!miniMapData.length > 0) return output
    if (!miniMapData[0].hasOwnProperty('convertedTimestamp')) return output

    const miniMapRect = calcMiniMapRect(width, height)
    const mmSliderTop = calcYpixelMinimap(chartMinMax.viewMinY, miniMapData, height)
    const mmSliderBtm = calcYpixelMinimap(chartMinMax.viewMaxY, miniMapData, height)
    const MINI_MAP_SLIDER_WIDTH = 80
    const MINI_MAP_SLIDER_HEIGHT = 16

    output = {
      mmSliderRect: {
        left: miniMapRect.left + 2,
        right: miniMapRect.right - 2,
        top: mmSliderTop,
        bottom: mmSliderBtm,
        width: miniMapRect.width - 4,
        height: mmSliderBtm - mmSliderTop,
      },
      topSliderRect: {
        left: miniMapRect.right - MINI_MAP_SLIDER_WIDTH - 2,
        right: miniMapRect.right - 2,
        top: mmSliderTop - MINI_MAP_SLIDER_HEIGHT,
        bottom: mmSliderTop,
        width: MINI_MAP_SLIDER_WIDTH,
        height: MINI_MAP_SLIDER_HEIGHT,
      },
      btmSliderRect: {
        left: miniMapRect.right - MINI_MAP_SLIDER_WIDTH - 2,
        right: miniMapRect.right - 2,
        top: mmSliderBtm,
        bottom: mmSliderBtm + MINI_MAP_SLIDER_HEIGHT,
        width: MINI_MAP_SLIDER_WIDTH,
        height: MINI_MAP_SLIDER_HEIGHT,
      },
    }

    return output
  }

  function getCurrentSeriesValue(mneomonic, logData) {
    if (typeof mneomonic !== 'string') return nullValue
    if (!Array.isArray(logData)) return nullValue
    if (logData.length === 0) return nullValue
    if (!logData[logData.length - 1].hasOwnProperty('avg')) return nullValue
    if (!logData[logData.length - 1].avg.hasOwnProperty(mneomonic)) return nullValue
    return { value: logData[logData.length - 1].avg[mneomonic] }
  }

  const formatMiniMapSliderTime = (timeMs) => {
    let date = new Date(timeMs).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    })

    let time = new Date(timeMs).toLocaleTimeString('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    })

    return `${date} ${time}`
  }

  const validateText = (input) => {
    if (input === 'Invalid Date') return ''
    if (input === nullValue) return ''
    return input
  }

  const getMinMaxValues = (data, propName) => {
    if (typeof propName !== 'string') return { min: 0, max: 0 }
    if (!Array.isArray(data)) return { min: 0, max: 0 }

    let output = { min: 9999999, max: 0 }
    for (let i = 0; i < data.length; i++) {
      if (!data[i].hasOwnProperty(propName)) continue
      if (data[i][propName] > output.max) output.max = data[i][propName]
      if (data[i][propName] < output.min) output.min = data[i][propName]
    }

    output.max *= 1.1
    return output
  }

  const pointInRect = (rect, point) => {
    if (!rect) return false
    if (!point) return false
    if (point.x < rect.left) return false
    if (point.x > rect.right) return false
    if (point.y < rect.top) return false
    if (point.y > rect.bottom) return false
    return true
  }

  function getMiniMapTimeDepth(yPos, miniMapData, width, height) {
    if (typeof yPos !== 'number' || !Array.isArray(miniMapData) || miniMapData.length === 0) {
      return { depth: nullValue, time: nullValue }
    }

    const miniMapRect = calcMiniMapRect(width, height)
    let ch = miniMapRect.height
    yPos = Math.min(Math.max(yPos, miniMapRect.top), miniMapRect.bottom)

    const mmData = miniMapData

    let axisLength = mmData[mmData.length - 1].convertedTimestamp - mmData[0].convertedTimestamp

    let normalizedPosition = yPos / ch
    let toolTipDate = normalizedPosition * axisLength + mmData[0].convertedTimestamp
    let startIndex = Math.max(Math.floor(mmData.length * normalizedPosition) - 10, 0)

    let index = -1
    let minDelta = Number.MAX_SAFE_INTEGER
    for (let i = startIndex; i < mmData.length; i++) {
      let delta = Math.abs(mmData[i].convertedTimestamp - toolTipDate)
      if (delta < minDelta) {
        index = i
        minDelta = delta
      }
    }

    if (index < 0 || index >= mmData.length || !mmData[index].hasOwnProperty('holeDepth')) {
      return { depth: nullValue, time: Math.round(toolTipDate) }
    }

    return { depth: mmData[index].holeDepth, time: Math.round(toolTipDate) }
  }

  function getToolTipTimeDepth(yPos, logData, chartMinMax, headerRect, scaleRect,  mnemonic = null) {
    let output = { depth: nullValue, time: nullValue, rigState: 'UNKNOWN' }
    if (typeof yPos !== 'number') return output
    if (!Array.isArray(logData)) return output
    if (logData.length === 0) return output

    let ch = scaleRect.height
    if (yPos < headerRect.bottom) yPos = 0
    if (yPos > scaleRect.bottom) yPos = ch

    let axisLength = chartMinMax.viewMaxY - chartMinMax.viewMinY
    let normalizedPosition = (yPos - headerRect.height) / ch
    let toolTipDate = normalizedPosition * axisLength + chartMinMax.viewMinY

    let index = -1
    let minDelta = Number.MAX_SAFE_INTEGER
    for (let i = 0; i < logData.length; i++) {
      let dt = Math.round(logData[i].convertedTimestamp)
      let delta = Math.abs(dt - toolTipDate)
      if (delta < minDelta) {
        index = i
        minDelta = delta
      }
    }

    output.time = new Date(toolTipDate).toISOString()

    if (index < 0 || index >= logData.length) return output
    if (!logData[index].hasOwnProperty('holeDepth')) return output

    let mnemonicValue = nullValue
    if (logData[index]?.avg.hasOwnProperty(mnemonic)) {
      mnemonicValue = logData[index].avg[mnemonic]
    }

    return {
      depth: logData[index].holeDepth,
      time: new Date(toolTipDate).toISOString(),
      value: mnemonicValue,
      rigState: logData[index].rigState,
    }
  }

  function getCalculatedToolTipTimeDepth(yPos, logData, chartMinMax, headerRect, scaleRect, curveMnemonics, calculatedSelection = []) {
    let output = { depth: nullValue, time: nullValue, rigState: 'UNKNOWN' }
    if (typeof yPos !== 'number') return output
    if (!Array.isArray(logData)) return output
    if (logData.length === 0) return output

    let ch = scaleRect.height
    if (yPos < headerRect.bottom) yPos = 0
    if (yPos > scaleRect.bottom) yPos = ch

    let axisLength = chartMinMax.viewMaxY - chartMinMax.viewMinY
    let normalizedPosition = (yPos - headerRect.height) / ch
    let toolTipDate = normalizedPosition * axisLength + chartMinMax.viewMinY

    let index = -1
    let minDelta = Number.MAX_SAFE_INTEGER
    for (let i = 0; i < logData.length; i++) {
      let dt = Math.round(logData[i].convertedTimestamp)
      let delta = Math.abs(dt - toolTipDate)
      if (delta < minDelta) {
        index = i
        minDelta = delta
      }
    }

    output.time = new Date(toolTipDate).toISOString()

    if (index < 0 || index >= logData.length) return output
    if (!logData[index].hasOwnProperty('holeDepth')) return output

    let mnemonicValue = nullValue
    if(Array.isArray(calculatedSelection) && calculatedSelection.length > 0) {
      let mnemonic1 = curveMnemonics.find((curve) => curve.label === calculatedSelection[0]?.curve1)?.value
      let mnemonic2 = curveMnemonics.find((curve) => curve.label === calculatedSelection[0]?.curve2)?.value

      mnemonicValue = performOperation(calculatedSelection[0]?.operation?.value, logData[index].avg[mnemonic1], logData[index].avg[mnemonic2])
    }

    return {
      depth: logData[index].holeDepth,
      time: new Date(toolTipDate).toISOString(),
      value: mnemonicValue,
      rigState: logData[index].rigState,
    }
  }

  const relativeTimeFormatter = (timeDiffInMs) => {
    const timeMins = Math.floor(timeDiffInMs / (1000 * 60))

    const DIVISIONS = [
      { amount: 60, name: 'seconds' },
      { amount: 60, name: 'minutes' },
      { amount: 24, name: 'hours' },
      { amount: 7, name: 'days' },
      { amount: 4.34524, name: 'weeks' },
      { amount: 12, name: 'months' },
      { amount: Number.POSITIVE_INFINITY, name: 'years' },
    ]

    let duration = timeMins * 60

    for (let i = 0; i < DIVISIONS.length; i++) {
      const division = DIVISIONS[i]
      if (Math.abs(duration) < division.amount) {
        return `${Math.round(duration)} ${division.name}`
      }
      duration /= division.amount
    }
  }

  function getCurrentSeriesCalculatedValue(calculatedSelection, logData,curveMnemonics) {

    if (!Array.isArray(calculatedSelection)) return nullValue
    if (calculatedSelection.length === 0) return nullValue
    if (!Array.isArray(logData)) return nullValue
    if (logData.length === 0) return nullValue
    let mnemonic1 = curveMnemonics.find((curve) => curve.label === calculatedSelection[0]?.curve1)?.value
    if (mnemonic1 === null || mnemonic1 === undefined) return nullValue
    if (typeof mnemonic1 !== 'string') return nullValue
    if (mnemonic1 === '') return nullValue
    let mnemonic2 = curveMnemonics.find((curve) => curve.label === calculatedSelection[0]?.curve2)?.value
    if (mnemonic2 === null || mnemonic2 === undefined) return nullValue
    if (typeof mnemonic2 !== 'string') return nullValue
    if (mnemonic2 === '') return nullValue
    let operation = calculatedSelection[0]?.operation?.value
    if (operation === null || operation === undefined) return nullValue
    if (typeof operation !== 'string') return nullValue
    if (operation === '') return nullValue
    if (!logData[logData.length - 1].hasOwnProperty('avg')) return nullValue
    if (!logData[logData.length - 1].avg.hasOwnProperty(mnemonic1)) return nullValue
    if (!logData[logData.length - 1].avg.hasOwnProperty(mnemonic2)) return nullValue

    return { value: performOperation(operation, logData[logData.length - 1].avg[mnemonic1], logData[logData.length - 1].avg[mnemonic2]) }
  }

  const calcHeaderTopBottom = (height) => {
    const HEADER_HEIGHT_PERCENTAGE = 0.2
    const MAX_HEADER_HEIGHT = 150
    const top = 0
    const bottom = Math.min(height * HEADER_HEIGHT_PERCENTAGE, MAX_HEADER_HEIGHT)

    return { top, bottom }
  }

  const calcYscaleRect = (width, height) => {
    const { top: headerTop, bottom: headerBottom } = calcHeaderTopBottom(height)
    const { right: minimapRight } = calcMiniMapRect(width, height)

    const scaleLeft = minimapRight
    const scaleRight = minimapRight + Math.min(width * Y_SCALE_WIDTH_PERCENTAGE, MAX_SCALE_WIDTH)

    return {
      header: {
        top: headerTop,
        bottom: headerBottom,
        left: scaleLeft,
        right: scaleRight,
        width: scaleRight - scaleLeft,
        height: headerBottom - headerTop,
      },
      scale: {
        top: headerBottom,
        bottom: height,
        left: scaleLeft,
        right: scaleRight,
        width: scaleRight - scaleLeft,
        height: height - headerBottom,
      },
    }
  }

  const calcTrackRect = (index, width, height, numTracks) => {
    const { header: scaleRect } = calcYscaleRect(width, height)
    const { width: miniMapWidth } = calcMiniMapRect(width, height)

    const trackWidth = (width - scaleRect.width - miniMapWidth) / numTracks
    const trackLeft = scaleRect.right + index * trackWidth
    const trackRight = trackLeft + trackWidth

    return {
      header: {
        top: scaleRect.top,
        bottom: scaleRect.bottom,
        left: trackLeft,
        right: trackRight,
        width: trackWidth,
        height: scaleRect.height,
      },
      track: {
        top: scaleRect.bottom,
        bottom: height,
        left: trackLeft,
        right: trackRight,
        width: trackWidth,
        height: height - scaleRect.bottom,
      },
    }
  }

  const getFontString = (size, width) => {
    let scale = getFontScale(width)
    return size * scale
  }

  const getFontScale = (width) => {
    const BASE_LINE_WIDTH = 600
    if (width >= BASE_LINE_WIDTH) return 1
    return Math.max(0.1, width / BASE_LINE_WIDTH)
  }

  const getNumCurves = (trackIndex, curveDefs) => {
    if (!Array.isArray(curveDefs)) return 0
    if (trackIndex >= curveDefs.length) return 0
    if (!Array.isArray(curveDefs[trackIndex].curves)) return 0
    return curveDefs[trackIndex].curves.length
  }

  const getCurveProperty = (trackIndex, curveIndex, property, curveDefs) => {
    if (typeof property !== 'string') return null
    if (!curveDefs) return null
    if (!Array.isArray(curveDefs)) return null
    if (trackIndex >= curveDefs.length) return null
    if (!Array.isArray(curveDefs[trackIndex].curves)) return null
    if (curveIndex >= curveDefs[trackIndex].curves.length) return null
    if (!curveDefs[trackIndex].curves[curveIndex].hasOwnProperty(property)) return null
    return curveDefs[trackIndex].curves[curveIndex][property]
  }

  function calcYpixel(yValue, height, chartMinMax) {
    const { bottom } = calcHeaderTopBottom(height)
    if (yValue === null || yValue === undefined) return 0
    let normVal = normalize(yValue, chartMinMax.viewMinY, chartMinMax.viewMaxY)
    return bottom + (height - bottom) * normVal + 1 //Offset by 1 pixel to stop overlapping the outline
  }

  function calcXpixel(xValue, rectTrack, min, max) {
    if (xValue === null || xValue === undefined) return 0
    let normVal = normalize(xValue, min, max)
    return rectTrack.left + (rectTrack.width) * normVal //Offset by 1 pixel to stop overlapping the outline
  }

  const getCurveHeaderRect = (trackIndex, curveIndex, curveDefs, width, height, numTracks) => {
    const { header: headerRect } = calcTrackRect(trackIndex, width, height, numTracks)

    const numCurves = getNumCurves(trackIndex, curveDefs)
    const headerTrackHeight = numCurves > 0 ? headerRect.height / numCurves : headerRect.height


    return {
      left: headerRect.left,
      right: headerRect.right,
      top: headerTrackHeight * curveIndex,
      bottom: headerTrackHeight * curveIndex + headerTrackHeight,
      width: headerRect.right - headerRect.left,
      height: headerTrackHeight,
    }
  }

  return {
    performOperation,
    getCurveUnitsText,
    getRigStates,
    getCalculatedSeriesValues,
    calcMiniMapRect,
    getCurrentSeriesValue,
    formatMiniMapSliderTime,
    calcYpixelMinimap,
    calcMmSliderRect,
    validateText,
    getMinMaxValues,
    pointInRect,
    getMiniMapTimeDepth,
    getToolTipTimeDepth,
    getCalculatedToolTipTimeDepth,
    relativeTimeFormatter,
    getCurrentSeriesCalculatedValue,
    getCurveProperty,
    getNumCurves,
    getFontString,
    getCurveHeaderRect,
    calcYscaleRect,
    calcYpixel,
    calcXpixel,
    calcTrackRect,
  }
}

export default useWellLogs
