import React, { useEffect, useRef, useState, useCallback } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { isEqual } from 'lodash'
import SplitPane from 'components/common/SplitPane'
import { useRecoilState } from 'recoil'
import { targetsLayoutAtom, selectedTargetAtom } from 'atoms'
import { Box, Tooltip, Alert, Snackbar } from '@mui/material'
import CoordinatesGrid from 'components/DatabaseTree/common/CoordinatesGrid'
import { Icon as Iconify } from '@iconify/react'
import TargetsListGrid from './TargetsListGrid'
import TargetsThreeDeeChart from './TargetsThreeDeeChart'
import TargetGeometryGrid from './TargetGeometryGrid'
import PolygonCoordsGrid from './PolygonCoordsGrid'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const TargetsPage = ({ field, facility, well, actual, plan }) => {
  const _isMounted = useRef(false)
  const [pageLayout, setPageLayout] = useRecoilState(targetsLayoutAtom)
  const [dataChanged, setDataChanged] = useState(false)
  const resetCoordinates = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [selectedTarget, setSelectedTarget] = useRecoilState(selectedTargetAtom)
  const selectedTargetRef = useRef(selectedTarget)
  const targetsList = useRef(null)
  const localCoordOffset = useRef({ localNorth: 0, localEast: 0 })
  const [selectedPane, setSelectedPane] = useState('CENTER')
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  const getLocalOffset = useInnovaAxios({
    url: '/coordinateConv/getLocalOffset',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchLocalOffset()
    return () => {
      setSelectedTarget(null)
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLocalOffset = useCallback(async () => {
    localCoordOffset.current = { localNorth: 0, localEast: 0 }
    if (!actual && !plan) return
    let wellName = actual ? actual : plan
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    const res = await getLocalOffset({ wellName: wellName, isPlan: plan ? true : false })

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!res.data) return
    localCoordOffset.current = cloneDeep(res.data)
  }, [actual, plan, getLocalOffset])

  useEffect(() => {
    fetchLocalOffset()
  }, [actual, plan, fetchLocalOffset])

  useEffect(() => {
    let orgSelectedTarget = cloneDeep(selectedTargetRef.current)

    if (!selectedTarget) {
      resetCoordinates.current = true
      selectedTargetRef.current = null
      if (selectedPane === 'POLYGON_POINTS') setSelectedPane('CENTER')

      setDataChanged(false)
    }

    if (selectedTarget) {
      if (orgSelectedTarget?.orgData?.uid !== selectedTarget?.orgData?.uid) {
        resetCoordinates.current = true
      }

      selectedTargetRef.current = cloneDeep(selectedTarget)

      if (selectedPane === 'POLYGON_POINTS' && selectedTargetRef.current.data.geometry !== 'POLYGON') {
        setSelectedPane('CENTER')
      }

      setDataChanged(!isEqual(selectedTarget.orgData, selectedTarget.data))
    }
  }, [selectedTarget, setDataChanged, selectedPane])

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '30%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showTargetsList, setShowTargetsList] = useState(getPaneVisible(0))

  const updateTargetCoords = (data) => {
    if (!data) return
    if (!selectedTargetRef.current) return
    if (!selectedTargetRef.current.data) return

    selectedTargetRef.current.data.targetCenter.latitude = data.latDeg
    selectedTargetRef.current.data.targetCenter.longitude = data.longDeg
    selectedTargetRef.current.data.targetCenter.gridNorth = data.gridNs
    selectedTargetRef.current.data.targetCenter.gridEast = data.gridEw
    selectedTargetRef.current.data.targetCenter.localNorth = data.localNs
    selectedTargetRef.current.data.targetCenter.localEast = data.localEw
    selectedTargetRef.current.data.targetCenter.gridConvergence = data.gridConv

    if (data.inputMeth === 'dms' || data.inputMeth === 'deg') {
      selectedTargetRef.current.data.inputMeth = 'GEO'
    }

    if (data.inputMeth === 'grid') {
      selectedTargetRef.current.data.inputMeth = 'MAP'
    }

    if (data.inputMeth === 'local') {
      selectedTargetRef.current.data.inputMeth = 'LOCAL'
    }

    setSelectedTarget(cloneDeep(selectedTargetRef.current))
  }

  const updateTargetGemetry = (data) => {
    if (!data) return
    if (!selectedTargetRef.current) return
    if (!selectedTargetRef.current.data) return

    if (!selectedTargetRef.current.data.geometry === 'POINT') return

    if (data.tag === 'dipAngle') selectedTargetRef.current.data.dipAngle = data.value
    if (data.tag === 'dipAzi') selectedTargetRef.current.data.dipAzi = data.value
    if (data.tag === 'thicknessUp') selectedTargetRef.current.data.thicknessUp = data.value
    if (data.tag === 'thicknessDn') selectedTargetRef.current.data.thicknessDn = data.value
    if (data.tag === 'nsOffset') selectedTargetRef.current.data.nsOffset = data.value
    if (data.tag === 'ewOffset') selectedTargetRef.current.data.ewOffset = data.value
    if (data.tag === 'rotation') selectedTargetRef.current.data.rotation = data.value
    if (data.tag === 'radius') selectedTargetRef.current.data.circleProperties.radius = data.value
    if (data.tag === 'semiMajor') selectedTargetRef.current.data.ellipseProperties.semiMajor = data.value
    if (data.tag === 'semiMinor') selectedTargetRef.current.data.ellipseProperties.semiMinor = data.value
    if (data.tag === 'length') selectedTargetRef.current.data.rectProperties.length = data.value
    if (data.tag === 'width') selectedTargetRef.current.data.rectProperties.width = data.value

    if (selectedTargetRef.current.data.geometry === 'CIRCLE') {
      if (data.tag === 'arcStart') selectedTargetRef.current.data.circleProperties.arcStart = data.value
      if (data.tag === 'arcEnd') selectedTargetRef.current.data.circleProperties.arcEnd = data.value
    }

    if (selectedTargetRef.current.data.geometry === 'ELLIPSE') {
      if (data.tag === 'arcStart') selectedTargetRef.current.data.ellipseProperties.arcStart = data.value
      if (data.tag === 'arcEnd') selectedTargetRef.current.data.ellipseProperties.arcEnd = data.value
    }

    if (data.tag === 'inputMethMap') {
      selectedTargetRef.current.data.polygonProperties.inputMethMap = data.value === 'MAP'
    }
    if (data.tag === 'individualThickness') {
      selectedTargetRef.current.data.polygonProperties.individualThickness = data.value
    }
    if (data.tag === 'polygonPoints') selectedTargetRef.current.data.polygonProperties.rawPolygonPoints = data.value

    targetsList?.current?.calculateTarget(selectedTargetRef.current.data)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => {
          targetsList?.current?.commitChanges()
        }}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          resetCoordinates.current = true
          targetsList?.current?.discardChanges()
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showTargetsList)
    setShowTargetsList(!showTargetsList)
  }

  return (
    <React.Fragment>
      {dataChanged ? <SaveButton /> : null}
      {dataChanged ? <DiscardButton /> : null}
      <SplitPane
        split='vertical'
        size={showTargetsList ? getInitialPaneSize(0) : '25px'}
        allowResize={showTargetsList}
        minSize={'10%'}
        defaultSize={getInitialPaneSize(0)}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <TargetsListGrid
          isVisible={showTargetsList}
          showHidePane={showHidePane}
          field={field}
          facility={facility}
          well={well}
          actual={actual}
          plan={plan}
          ref={targetsList}
        />
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          minSize={'10%'}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}>
          <TargetsThreeDeeChart targetData={selectedTarget ? selectedTarget?.data : null} />
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: getChartBackColor(),
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box
              sx={{
                width: '24px',
                height: '100%',
                backgroundColor: getWindowBarColor(),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                border: '1px solid gray',
                marginLeft: '1px',
              }}>
              <Box
                sx={{
                  ...verticalTextStyle,
                  fontWeight: selectedPane === 'CENTER' ? 'bold' : '400',
                  color: selectedPane === 'CENTER' ? '#429ceb' : '#f0f0f0',
                }}
                onClick={() => {
                  setSelectedPane('CENTER')
                }}>
                Center
              </Box>
              {selectedTarget?.data?.geometry !== 'POINT' ? (
                <Box
                  sx={{
                    ...verticalTextStyle,
                    fontWeight: selectedPane === 'GEOMETRY' ? 'bold' : '400',
                    color: selectedPane === 'GEOMETRY' ? '#429ceb' : '#f0f0f0',
                  }}
                  onClick={() => {
                    setSelectedPane('GEOMETRY')
                  }}>
                  Geometry
                </Box>
              ) : null}
              {selectedTarget?.data?.geometry === 'POLYGON' ? (
                <Box
                  sx={{
                    ...verticalTextStyle,
                    fontWeight: selectedPane === 'POLYGON_POINTS' ? 'bold' : '400',
                    color: selectedPane === 'POLYGON_POINTS' ? '#429ceb' : '#f0f0f0',
                  }}
                  onClick={() => {
                    setSelectedPane('POLYGON_POINTS')
                  }}>
                  Points
                </Box>
              ) : null}
            </Box>
            {selectedPane === 'CENTER' ? (
              <CoordinatesGrid
                targetParentData={{ field, facility, well, actual, plan }}
                resetCoordinates={resetCoordinates}
                localCoordOffset={localCoordOffset.current}
                coords={
                  selectedTarget?.data
                    ? {
                        latDeg: selectedTarget.data?.targetCenter.latitude,
                        longDeg: selectedTarget.data?.targetCenter.longitude,
                        gridNs: selectedTarget.data?.targetCenter.gridNorth,
                        gridEw: selectedTarget.data?.targetCenter.gridEast,
                        localNs: selectedTarget.data?.targetCenter.localNorth,
                        localEw: selectedTarget.data?.targetCenter.localEast,
                        gridConv: selectedTarget.data?.targetCenter.gridConvergence,
                      }
                    : null
                }
                options={{
                  dms: true,
                  deg: true,
                  grid: true,
                  local: true,
                  input:
                    selectedTarget?.data?.inputMeth === 'GEO'
                      ? 'dms'
                      : selectedTarget?.data?.inputMeth === 'LOCAL'
                      ? 'local'
                      : 'grid',
                }}
                crs={selectedTarget?.data?.crsName}
                updateData={updateTargetCoords}
              />
            ) : null}
            {selectedPane === 'GEOMETRY' ? (
              <TargetGeometryGrid selectedTarget={selectedTarget?.data} handleUpdate={updateTargetGemetry} />
            ) : null}
            {selectedPane === 'POLYGON_POINTS' ? (
              <PolygonCoordsGrid selectedTarget={selectedTarget?.data} handleUpdate={updateTargetGemetry} />
            ) : null}
          </Box>
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default TargetsPage
