import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import AnalyticsBarChart from './AnalyticsBarChart'
import { appColors, array2pipestr, getXlsxColumnLetter } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const SlideRotateFootageChart = () => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const { getUnitsText } = useUnits()
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.slideRotateFootageKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [
        {
          label: 'Slide',
          backgroundColor: appColors.slideColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
        {
          label: 'Rotate',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!data || !data.footage) return output

    for (let i = 0; i < data.footage.length; i++) {
      output.labels.push(data.footage[i].actualWell)
      output.datasets[0].data.push(parseInt(data.footage[i].slideFootage))
      output.datasets[1].data.push(parseInt(data.footage[i].rotateFootage))
    }

    return output
  }

  const isPhaseFilterActive = (phase) => {
    if (Array.isArray(phase) && phase.length > 0) return true
    return false
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      footage: 'true',
    }

    let phaseFilter = getParameter('Phase', searchParams)
    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }

    payload.dateTo = dateTo
    payload.dateFrom = dateFrom
    payload.phase = ''

    if (isPhaseFilterActive(phaseFilter)) {
      payload.phase = phaseFilter.join('|')
    }

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let colIndex = 1
    let totalDrilled = 0.0
    let totalSlide = 0.0
    let totalRotate = 0.0
    let slidePct = 0.0
    let rotatePct = 0.0

    let phaseFilter = getParameter('Phase', searchParams)
    let ws = XLSX.utils.aoa_to_sheet(
      [
        [`Slide Footage (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Rotate Footage (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Total Footage (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Rotate ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
        [`Slide ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
        [`ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
        [`Phase ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
        [`# BHA`],
      ],
      {
        origin: 'A2',
      },
    )

    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [`Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Total Slide (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Total Rotate (${getUnitsText(UNITS_FOR.Depth)})`],
        ['Slide %'],
        ['Rotate %'],
        [''],
        ['Phase'],
      ],
      { origin: 'A11' },
    )
    if (data && data.footage) {
      data.footage.forEach((well) => {
        let phaseRop = 0
        let phaseHours = parseFloat(well.rotateHours) + parseFloat(well.slideHours)
        let phaseFootage = parseFloat(well.rotateFootage) + parseFloat(well.slideFootage)
        if (phaseHours > 0.01 && isPhaseFilterActive(phaseFilter)) {
          phaseRop = phaseFootage / phaseHours
        }
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [`${well.rig} - ${well.actualWell}`],
            [parseFloat(well.slideFootage)],
            [parseFloat(well.rotateFootage)],
            [parseFloat(well.totalFootage)],
            [parseFloat(well.rotateRop)],
            [parseFloat(well.slideRop)],
            [parseFloat(well.avgRop)],
            [+phaseRop.toFixed(2)],
          ],
          { origin: `${getXlsxColumnLetter(colIndex)}1` },
        )

        totalSlide += parseFloat(well.slideFootage)
        totalRotate += parseFloat(well.rotateFootage)
        colIndex += 1
      })
      totalDrilled = totalSlide + totalRotate
      if (totalDrilled > 0.01) {
        slidePct = (totalSlide / totalDrilled) * 100
        rotatePct = (totalRotate / totalDrilled) * 100
      }

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [+totalDrilled.toFixed(2)],
          [+totalSlide.toFixed(2)],
          [+totalRotate.toFixed(2)],
          [+slidePct.toFixed(2)],
          [+rotatePct.toFixed(2)],
        ],
        { origin: 'B11' },
      )
    }

    if (isPhaseFilterActive(phaseFilter)) {
      let phaseStr = phaseFilter.join(', ').slice(0, 27) + (phaseFilter.join(', ').length > 30 ? '...' : '')
      XLSX.utils.sheet_add_aoa(ws, [[phaseStr]], { origin: 'B17' })
    } else {
      XLSX.utils.sheet_add_aoa(ws, [['Well']], { origin: 'B17' })
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Slide Rotate Footage-Well')
    XLSX.writeFile(wb, 'Footage and ROP.xlsx')
  }

  const getTitle = () => {
    let title = 'Slide / Rotate Footage'
    let phaseFilter = getParameter('Phase', searchParams)
    if (!phaseFilter) return title
    if (!Array.isArray(phaseFilter)) return title
    if (phaseFilter.length === 0) return title
    return `${title} - ${phaseFilter.join(',')}`
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={getUnitsText(UNITS_FOR.Depth)}
      title={getTitle()}
      xAxisTitle='Wells'
      yAxisTitle={`Footage (${getUnitsText(UNITS_FOR.Depth)})`}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default SlideRotateFootageChart
