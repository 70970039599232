import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  sortColDefs,
  htmlSymbolHandling,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import FileSaver from 'file-saver'
import AddIcon from '@mui/icons-material/Add'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import PickListVirtualDialog from 'components/common/PickListVirtualDialog'
import ObjectValidationTemplatesModal from 'components/ObjectValidation/ObjectValidationTemplatesModal'
import { getStringId } from 'components/common/AgGridUtils'
import ObjectQcDropDownsModal from 'components/ObjectValidation/ObjectQcDropDownsModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const ObjectValidationPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const containerRef = useRef(null)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const selectedQc = useRef(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [isLoading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showPickOperators, setShowPickOperators] = useState(false)
  const [showTemplatesModal, setShowTemplatesModal] = useState(false)
  const [showDropDownsModal, setShowDropDownsModal] = useState(false)
  const [operatorList, setOperatorList] = useState([])
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getObjectQcData = useInnovaAxios({
    url: '/objectQc/getObjectQc',
  })

  const deleteObjectQc = useInnovaAxios({
    url: '/objectQc/deleteObjectQc',
  })

  const addObjectQc = useInnovaAxios({
    url: '/objectQc/addObjectQc',
  })

  const updateObjectQc = useInnovaAxios({
    url: '/objectQc/updateObjectQc',
  })

  const getOperators = useInnovaAxios({
    url: '/operator/getOperator',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.objectValidationKey)
    fetchOperatorsList()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchObjectQc = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getObjectQcData()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }

    if (!gridApi.current) return
    gridApi.current.setGridOption('rowData', Array.isArray(res.data) ? res.data : [])

    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchObjectQc()
  }

  const gridOptions = {
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('objectQcGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('objectQcGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          if (!params.node?.data) return
          let blob = new Blob([JSON.stringify(params.node.data)], { type: 'text/plain;charset=utf-8' })
          FileSaver.saveAs(blob, `${removeSpecialSymbols(params.node.description)}.txt`)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleAddRow = async (data) => {
    if (isAdding.current) return

    let objectData = []
    if (data) objectData.push(data)

    let res = await addObjectQc({ objectData: JSON.stringify(objectData) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({ add: res.data })
    }
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (isUpdating.current) return
      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateObjectQc({ objectData: JSON.stringify([data]) })
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!Array.isArray(res.data)) return

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: res.data,
        })
      }
    },
    [updateObjectQc],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedQc.current) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteObjectQc({ objectData: JSON.stringify([selectedQc.current]) })
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedQc.current],
      })
    }

    selectedQc.current = null
  }, [deleteObjectQc])

  const onClickDelete = useCallback(
    (data) => {
      selectedQc.current = data
      setConfirm({
        show: true,
        title: 'Delete Object Validation',
        text: `Are you sure you want to delete ${data.description}?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
  }, [])

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedQc.current = data

      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'operators') {
        setShowPickOperators(true)
      }

      if (action === 'dropdowns') {
        setShowDropDownsModal(true)
      }
    },
    [onClickDelete],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'checkDetail',
        colId: 'checkDetail',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 60,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: '',
        colId: 'actions',
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Operator Access',
                action: 'operators',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:barrel' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Drop Downs',
                action: 'dropdowns',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:form-dropdown' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:delete' color={'red'} />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        field: 'scope',
        colId: 'scope',
        headerName: 'Scope',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: ['ALL', 'OPERATOR'],
          }
        },
        cellStyle: centerAlignCell,
      },
      {
        colId: 'opNames',
        headerName: 'Operators',
        editable: false,
        cellStyle: centerAlignCell,
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => {
          return Array.isArray(params.data?.operators) && params.data?.operators.length > 0
        },
      },
      {
        field: 'updatedBy',
        colId: 'updatedBy',
        headerName: 'Updated By',
        editable: false,
        valueFormatter: (params) => {
          return getUserNameFromEmail(params.value)
        },
      },
      {
        field: 'updatedDate',
        colId: 'updatedDate',
        headerName: 'Updated Date',
        editable: false,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.value)
        },
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        cellStyle: centerAlignCell,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
    ],
    [centerAlignCell, dateFilterComparator, dateTimeFormatter, handleMenuClick],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleUpdateChecks = useCallback(
    async (data) => {
      if (!data) return
      if (!selectedQc.current) return
      if (!Array.isArray(selectedQc.current.checks)) return

      for (let i = 0; i < selectedQc.current.checks.length; i++) {
        if (selectedQc.current.checks[i].uid === data.uid) {
          selectedQc.current.checks[i].active = data.active
          break
        }
      }

      handleUpdate(selectedQc.current)
    },
    [handleUpdate],
  )

  const detailCellRendererParams = useMemo(() => {
    return {
      refreshStrategy: 'nothing',
      detailGridOptions: {
        groupDisplayType: 'groupRows',
        onCellEditingStopped: (event) => {
          handleUpdateChecks(event.data)
        },
        columnDefs: [
          {
            headerName: 'Description',
            field: 'uid',
            colId: 'uid',
          },
          {
            colId: 'group',
            field: 'group',
            rowGroup: true,
            hide: true,
          },
          {
            headerName: 'Warning',
            field: 'warning',
            colId: 'warning',
          },
          {
            headerName: 'Active',
            field: 'active',
            colId: 'active',
            editable: true,
            cellStyle: centerAlignCell,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
          },
        ],
        defaultColDef: {
          suppressHeaderMenuButton: true,
          suppressHeaderFilterButton: true,
          sortable: false,
          resizable: true,
          editable: false,
          filter: 'agSetColumnFilter',
          filterParams: {
            excelMode: 'windows',
          },
        },
      },
      getDetailRowData: (params) => {
        selectedQc.current = params.data
        params.successCallback(Array.isArray(params.data?.checks) ? params.data?.checks : [])
      },
    }
  }, [centerAlignCell, handleUpdateChecks])

  const fetchOperatorsList = async () => {
    let res = await getOperators()
    if (!_isMounted.current) return
    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get operators: ${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return

    let newOpList = []
    for (let i = 0; i < res.data.length; i++) {
      newOpList.push({ id: res.data[i].opName, desc: res.data[i].opName })
    }

    setOperatorList(newOpList)
  }

  const updateOperatorSelection = async (selectedOperators) => {
    setShowPickOperators(false)
    if (!selectedQc.current) return
    if (!Array.isArray(selectedOperators)) return

    selectedQc.current.operators = selectedOperators
    handleUpdate(selectedQc.current)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {showDropDownsModal ? (
        <ObjectQcDropDownsModal
          objectQc={selectedQc.current}
          onClose={(data) => {
            handleUpdate(data)
            setShowDropDownsModal(false)
          }}
        />
      ) : null}
      {showTemplatesModal ? (
        <ObjectValidationTemplatesModal
          onClose={(newTemplate) => {
            if (newTemplate) handleAddRow(newTemplate)
            setShowTemplatesModal(false)
          }}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showPickOperators ? (
        <PickListVirtualDialog
          title='Select Operators'
          open={showPickOperators}
          setOpen={() => setShowPickOperators(false)}
          onApply={updateOperatorSelection}
          items={Array.isArray(operatorList) ? operatorList.sort((a, b) => a.desc.localeCompare(b.desc)) : []}
          initSelItems={Array.isArray(selectedQc.current.operators) ? selectedQc.current.operators : []}
        />
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box ref={containerRef} sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            columnDefs={sortColDefs(columnDefs, 'objectQcGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                handleAddRow(null)
              },
            },
            {
              icon: (
                <Iconify icon={'tabler:template'} style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />
              ),
              name: 'Add Template',
              onClick: () => {
                setShowTemplatesModal(true)
              },
            },
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchObjectQc()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default ObjectValidationPage
