import React, { useState, useEffect, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const BulkEditGridControl = ({ rowData, columnDefs, style, gridCategory, stageWarningModal }) => {
  const _isMounted = useRef(false)
  const [bulkEditData, setBulkEditData] = useState([])
  const { getAgGridTheme } = useInnovaTheme()
  let newRowData = [...rowData]

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateCellValue = (event) => {
    if (!event.hasOwnProperty('newValue')) return
    if (event.newValue === '') {
      stageWarningModal(false)
      return
    }

    setBulkEditData([
      ...bulkEditData,
      { uid: gridCategory, oldValue: event.node.data.currVal, newValue: event.newValue },
    ])
    stageWarningModal(true)
  }

  return (
    <>
      <Box className={getAgGridTheme()} style={style}>
        <AgGridReact
          rowData={newRowData}
          columnDefs={columnDefs}
          stopEditingWhenGridLosesFocus={true}
          headerHeight={30}
          onCellValueChanged={(e) => {
            handleUpdateCellValue(e)
          }}
          onGridReady={(params) => params.api.sizeColumnsToFit()}
          suppressScrollOnNewData = {true}
        />
      </Box>
    </>
  )
}
