import { useState } from 'react'
import { useEffect, useRef } from 'react'
import { currentWellAtom, currentWellDetailsAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import WeatherViewControl from './WeatherViewControl'
import { round } from 'utils/numberFunctions'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'

const WeatherViewCard = () => {
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellDetail = useRecoilValue(currentWellDetailsAtom)
  const lat = round(currentWellDetail.lat, 3)
  const lng = round(currentWellDetail.lng, 3)
  const _isMounted = useRef(false)
  const [weather, setWeather] = useState()
  const getWeatherData = useInnovaAxios({
    url: `/well/getWeather`,
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current && currentWell?.length > 0) {
      getWeather()
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getWeather = async () => {
    const response = await getWeatherData({
      wellName: currentWell,
    })
    if (_isMounted.current && response?.data) {
      setWeather(response.data)
    }
  }

  if (!weather) {
    return null
  }

  return <WeatherViewControl weather={weather} lat={lat} lng={lng} />
}

export default WeatherViewCard
