import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ObjectQcDropDownsModal = ({ objectQc, onClose }) => {
  const _isMounted = useRef()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dataSources, setDataSources] = useState([])
  const [isLoading, setLoading] = useState(false)
  const gridApi = useRef(null)
  const [searchText, setSearchText] = useState('')
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getDropDownDataSources = useInnovaAxios({
    url: '/objectQc/getObjectQcDropDownSources',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDownDataSources()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDownDataSources = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getDropDownDataSources()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }

    if (!Array.isArray(res.data)) return
    setDataSources(res.data)
  }

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getDropDownValues = useCallback(
    (tag, column) => {
      if (!Array.isArray(dataSources)) return []
      const dataSource = dataSources.filter((ds) => ds.tag === tag)
      if (!Array.isArray(dataSource)) return []

      return dataSource.map((item) => {
        return item[column]
      })
    },
    [dataSources],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Drop Down Name',
        field: 'tag',
        colId: 'tag',
        editable: false,
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        colId: 'description',
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDownValues(params.data.tag, 'description'),
          }
        },
        valueSetter: (params) => {
          let selectedSource = dataSources.find(
            (ds) => ds.tag === params.data.tag && ds.description === params.newValue,
          )

          if (!selectedSource) {
            params.data.description = ''
            params.data.application = ''
            params.data.uid = -1
            return true
          }

          params.data.description = selectedSource.description
          params.data.application = selectedSource.application
          params.data.uid = selectedSource.uid
          return true
        },
      },
      {
        headerName: 'Application',
        field: 'application',
        colId: 'application',
        valueFormatter: (params) => {
          return unescapeHtml(params?.value)
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDownValues(params.data.tag, 'application'),
          }
        },
        valueSetter: (params) => {
          let selectedSource = dataSources.find(
            (ds) => ds.tag === params.data.tag && ds.application === params.newValue,
          )

          if (!selectedSource) {
            params.data.description = ''
            params.data.application = ''
            params.data.uid = -1
            return true
          }

          params.data.description = selectedSource.description
          params.data.application = selectedSource.application
          params.data.uid = selectedSource.uid
          return true
        },
      },
      {
        headerName: 'Uid',
        field: 'uid',
        colId: 'uid',
        valueFormatter: (params) => {
          return params.data.application === '' ? '' : params.value
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: getDropDownValues(params.data.tag, 'uid'),
          }
        },
        valueSetter: (params) => {
          let selectedSource = dataSources.find(
            (ds) => ds.tag === params.data.tag && ds.application === params.newValue,
          )

          if (!selectedSource) {
            params.data.description = ''
            params.data.application = ''
            params.data.uid = -1
            return true
          }

          params.data.description = selectedSource.description
          params.data.application = selectedSource.application
          params.data.uid = selectedSource.uid
          return true
        },
      },
    ],
    [getDropDownValues, dataSources],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseDialog = async () => {
    gridApi.current.forEachNode((node) => {
      objectQc.dropDownLists[node.data.tag].description = node.data.description
      objectQc.dropDownLists[node.data.tag].application = node.data.application
      objectQc.dropDownLists[node.data.tag].uid = node.data.uid
    })

    onClose(objectQc)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  const getDropDownRows = () => {
    if (!objectQc) return []
    return Object.keys(objectQc.dropDownLists).map((key) => {
      return {
        ...objectQc.dropDownLists[key],
        tag: key,
      }
    })
  }

  return (
    <>
      <Dialog
        maxWidth='lg'
        PaperProps={{
          sx: {
            width: '70vw',
            height: '50vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={handleCloseDialog}>
        <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <DialogTitle>Drop Downs</DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              height: '50px',
            }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle()}
            />
          </Box>
          <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 50px)' }}>
            <AgGridReact
              rowData={getDropDownRows()}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              enableBrowserTooltips={true}
              headerHeight={30}
              onGridReady={onGridReady}
              gridOptions={gridOptions}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default ObjectQcDropDownsModal
