import React from 'react'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

function isNumberOrStringNumber(value) {
  // Check if the value is a number
  if (typeof value === 'number' && !isNaN(value)) {
    return true
  }

  // Check if the value is a string representation of a number
  if (typeof value === 'string' && !isNaN(parseFloat(value))) {
    return true
  }

  return false
}

const TextWidget = ({ title, value, unit }) => {
  const { getBackColor } = useInnovaTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: getBackColor(),
        border: '1px solid black',
        boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
        padding: '8px',
      }}>
      <Box sx={{ color: 'rgba(192,192,192,1.0)' }}>{title}</Box>
      <Box>
        <Box component='span' sx={{ color: 'rgba(52,201,235,1.0)', fontSize: '2em', fontWeight: 'bold' }}>
          {isNumberOrStringNumber(value) ? numberWithCommasDecimals(value, 2) : value}
        </Box>
        <Box component='span' sx={{ color: 'rgba(192,192,192,1.0)', fontSize: '1em', marginLeft: '0.5em' }}>
          {unit}
        </Box>
      </Box>
    </Box>
  )
}

export default TextWidget
