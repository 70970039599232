import React, { useEffect, useRef, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SplitPane from 'components/common/SplitPane'
import { Box, CircularProgress, Tooltip, Alert, Snackbar } from '@mui/material'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import { isEqual } from 'lodash'
import SurveyPropertiesGrid from './SurveyPropertiesGrid'
import SurveyTieOnGrid from './SurveyTieOnGrid'
import { useRecoilState } from 'recoil'
import { surveyPropertiesLayoutAtom, dateBaseTreeUpdateNameAtom } from 'atoms'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

const SurveyPropertiesPage = ({ surveyName, wellName, nodeId, canChangePage }) => {
  const _isMounted = useRef(false)
  const [isUpdating, setUpdating] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [surveyData, setSurveyData] = useState(null)
  const surveyDataRef = useRef(null)
  const [dataChanged, setDataChanged] = useState(false)
  const isUpdatingRef = useRef(false)
  const isInterpolating = useRef(false)
  const orgDataRef = useRef(surveyData)
  const [errorModels, setErrorModels] = useState([])
  const [tieOnSurveyHeaders, setTieOnSurveyHeaders] = useState([])
  const tieOnSurveyHeadersRef = useRef([])
  const wellNameRef = useRef(wellName)
  const localCoordOffset = useRef({ localNorth: 0, localEast: 0 })
  const [pageLayout, setPageLayout] = useRecoilState(surveyPropertiesLayoutAtom)
  const [databaseTreeUpdateName, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const { getUnitsText } = useUnits()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getLocalOffset = useInnovaAxios({
    url: '/coordinateConv/getLocalOffset',
  })

  const getSurvey = useInnovaAxios({
    url: '/survey/getSurveyHeader',
  })

  const updateSurvey = useInnovaAxios({
    url: '/survey/updateSurveyHeader',
  })

  const getErrorModels = useInnovaAxios({
    url: '/well/getErrorModelsList',
  })

  const getAvailableSurveyHeaders = useInnovaAxios({
    url: '/survey/getAvailableSurveyHeadersForWell',
  })

  const interpMd = useInnovaAxios({
    url: '/survey/interpMd',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchErrorModels()
    fetchTieOnSurveyHeaders()
    fetchLocalOffset()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    wellNameRef.current = wellName
    fetchData()
    fetchErrorModels()
    fetchTieOnSurveyHeaders()
    fetchLocalOffset()
  }, [wellName, surveyName]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!surveyData) return
    if (data.caller === 'UI') return
    if (data.nodeId !== nodeId) return

    let newData = cloneDeep(surveyDataRef.current)
    newData.survey = data.name
    surveyDataRef.current.survey = data.name
    orgDataRef.current.survey = data.name
    setSurveyData(newData)
    setDataBaseTreeNameUpdate(null)
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '50%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const fetchErrorModels = async () => {
    const res = await getErrorModels()

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    setErrorModels(res.data)
  }

  const interpSurvey = async (data) => {
    if (!data) return null
    if (!data.parentSurveyWell) return null
    if (!data.parentTieOn) return null
    if (data.parentSurveyWell === '') return null
    if (data.parentTieOn === '') return null
    if (isInterpolating.current) return null

    setLoading(true)
    isInterpolating.current = true
    const res = await interpMd({ wellName: data.parentSurveyWell, surveyName: data.parentTieOn, md: data.md })
    isInterpolating.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return null
    }

    if (!res.data) return null
    return res.data
  }

  const fetchLocalOffset = async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (!surveyName) return
    if (typeof surveyName !== 'string') return
    if (surveyName === '') return

    const res = await getLocalOffset({ wellName: wellName })

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!res.data) return
    localCoordOffset.current = cloneDeep(res.data)
  }

  const fetchTieOnSurveyHeaders = async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    const res = await getAvailableSurveyHeaders({ wellName: wellName })

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    let svyHeaders = []

    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].survey === surveyName && res.data[i].actualWell === wellName) continue

      svyHeaders.push({
        survey: res.data[i].survey,
        wellName: res.data[i].actualWell,
        depthFrom: res.data[i].tieOn.depthFrom,
        depthTo: res.data[i].tieOn.depthTo,
        datumElevation: res.data[i].datumElevation,
      })
    }

    svyHeaders.sort((a, b) => {
      if (a.depthFrom < b.depthFrom) return -1
      if (a.depthFrom > b.depthFrom) return 1
      return 0
    })

    tieOnSurveyHeadersRef.current = cloneDeep(svyHeaders)
    setTieOnSurveyHeaders(svyHeaders)
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return
    if (!surveyName) return
    if (typeof surveyName !== 'string') return
    if (surveyName === '') return

    setLoading(true)
    const res = await getSurvey({ wellName: wellName, surveyName: surveyName })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    //Need to flatten the object to allow update and passing to grids
    let svyData = {
      uid: res.data[0].uid,
      survey: res.data[0].survey,
      actualWell: res.data[0].actualWell,
      ipm: res.data[0].ipm,
      inputMeth: res.data[0].tieOn.inputMeth,
      parentTieOn: res.data[0].tieOn.parentTieOn,
      parentSurveyWell: res.data[0].tieOn.parentSurveyWell,
      depthFrom: res.data[0].tieOn.depthFrom,
      depthTo: res.data[0].tieOn.depthTo,
      datumName: res.data[0].datumName,
      datumElevation: res.data[0].datumElevation,
      md: res.data[0].tieOn.tieOn.md,
      inc: res.data[0].tieOn.tieOn.inc,
      azi: res.data[0].tieOn.tieOn.azi,
      tvd: res.data[0].tieOn.tieOn.tvd,
      ns: res.data[0].tieOn.tieOn.ns,
      ew: res.data[0].tieOn.tieOn.ew,
      isSideTrack: res.data[0].isSideTrack,
      sideTrackDepth: res.data[0].sideTrackDepth,
    }

    surveyDataRef.current = cloneDeep(svyData)
    orgDataRef.current = cloneDeep(svyData)
    setSurveyData(cloneDeep(svyData))
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    canChangePage.current = false
    let res = await updateSurvey(data)
    isUpdatingRef.current = false

    if (!_isMounted.current) {
      canChangePage.current = true
      return
    }

    setUpdating(false)

    if (res?.error) {
      canChangePage.current = true
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (orgDataRef.current.survey !== data.survey) {
      setDataBaseTreeNameUpdate({ name: data.survey, nodeId: nodeId, caller: 'UI' })
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
    canChangePage.current = true
  }

  const getDeltaElevation = (tieOnWell, tieOnSurvey) => {
    if (
      !tieOnWell ||
      !tieOnSurvey ||
      !Array.isArray(tieOnSurveyHeadersRef.current) ||
      tieOnSurveyHeadersRef.current.length === 0
    )
      return 0
    if (typeof tieOnWell !== 'string') return 0
    if (typeof tieOnSurvey !== 'string') return 0
    if (tieOnWell === '') return 0
    if (tieOnSurvey === '') return 0

    let tieOnSvyHeader = tieOnSurveyHeadersRef.current.find(
      (svy) => svy.wellName === tieOnWell && svy.survey === tieOnSurvey,
    )
    if (!tieOnSvyHeader) return 0

    return tieOnSvyHeader.datumElevation - surveyDataRef.current.datumElevation
  }

  function roundToNearestWholeIfClose(number) {
    if (Math.abs(number - Math.round(number)) < 0.01) {
      return Math.round(number)
    } else {
      return number
    }
  }

  const updateSurveyData = async (data) => {
    if (!data) return

    let newData = cloneDeep(surveyDataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    if (newData.inputMeth === 'Survey') newData.inputMeth = 'SURVEY'
    if (newData.inputMeth === 'Surface') newData.inputMeth = 'SURFACE'
    if (newData.inputMeth === 'User Defined') newData.inputMeth = 'USER'

    if (newData.inputMeth !== 'SURVEY') {
      newData.parentTieOn = ''
      newData.parentSurveyWell = ''
    }

    if (newData.inputMeth === 'SURVEY' && tieOnSurveyHeadersRef.current.length > 0) {
      if (newData.parentTieOn === '') {
        newData.parentTieOn = tieOnSurveyHeadersRef.current[tieOnSurveyHeadersRef.current.length - 1].survey
      }

      if (newData.parentSurveyWell === '') {
        newData.parentSurveyWell = tieOnSurveyHeadersRef.current[tieOnSurveyHeadersRef.current.length - 1].wellName
      }
    }

    if (newData.inputMeth === 'SURFACE') {
      newData.md = 0
      newData.inc = 0
      newData.azi = 0
      newData.tvd = 0
      newData.ns = 0
      newData.ew = 0
    }

    if (newData.inputMeth === 'SURVEY') {
      let svyHeader = tieOnSurveyHeadersRef.current.find(
        (svy) => svy.wellName === newData.parentSurveyWell && svy.survey === newData.parentTieOn,
      )

      if (svyHeader) {
        let deltaElevation = getDeltaElevation(newData.parentSurveyWell, newData.parentTieOn)
        newData.md = svyHeader.depthTo + deltaElevation

        let interpData = await interpSurvey(newData)
        if (interpData) {
          newData.md = roundToNearestWholeIfClose(interpData.md - deltaElevation)
          newData.inc = interpData.inc
          newData.azi = interpData.azi
          newData.tvd = interpData.tvd
          newData.ns = interpData.ns
          newData.ew = interpData.ew
          newData.depthFrom = newData.md
          if (newData.depthFrom > newData.depthTo) newData.depthTo = newData.md
          if (orgDataRef.current.depthFrom === orgDataRef.current.depthTo) newData.depthTo = newData.md
        }
      }
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    surveyDataRef.current = newData
    setSurveyData(newData)
  }

  const updateTieOnData = async (data) => {
    let newData = cloneDeep(surveyDataRef.current)
    if (surveyDataRef.current.inputMeth === 'SURFACE') {
      newData.md = 0
      newData.inc = data.inc
      newData.azi = data.azi
      newData.tvd = 0
      newData.ns = 0
      newData.ew = 0
    }

    if (surveyDataRef.current.inputMeth === 'USER') {
      newData.md = data.md
      newData.inc = data.inc
      newData.azi = data.azi
      newData.tvd = data.tvd
      newData.ns = data.ns - localCoordOffset.current.localNorth
      newData.ew = data.ew - localCoordOffset.current.localEast
    }

    if (surveyDataRef.current.inputMeth === 'SURVEY') {
      let svyHeader = tieOnSurveyHeadersRef.current.find(
        (svy) =>
          svy.wellName === surveyDataRef.current.parentSurveyWell && svy.survey === surveyDataRef.current.parentTieOn,
      )

      if (svyHeader) {
        let deltaElevation = getDeltaElevation(newData.parentSurveyWell, newData.parentTieOn)

        newData.md = data.md + deltaElevation
        let interpData = await interpSurvey(newData)

        if (interpData) {
          newData.md = roundToNearestWholeIfClose(interpData.md - deltaElevation)
          newData.inc = interpData.inc
          newData.azi = interpData.azi
          newData.tvd = interpData.tvd
          newData.ns = interpData.ns
          newData.ew = interpData.ew

          newData.depthFrom = newData.md
          if (newData.depthFrom > newData.depthTo) newData.depthTo = newData.md
          if (orgDataRef.current.depthFrom === orgDataRef.current.depthTo) newData.depthTo = newData.md
        }
      }
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    surveyDataRef.current = newData
    setSurveyData(newData)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => handleUpdate(surveyDataRef.current)}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          surveyDataRef.current = cloneDeep(orgDataRef.current)
          setDataChanged(false)
          setSurveyData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const getTieOnSvy = () => {
    if (!surveyDataRef.current) return null
    if (surveyDataRef.current.inputMeth !== 'SURVEY') return null
    if (!Array.isArray(tieOnSurveyHeadersRef.current)) return null
    return tieOnSurveyHeadersRef.current.find(
      (svy) =>
        svy.survey === surveyDataRef.current.parentTieOn && svy.wellName === surveyDataRef.current.parentSurveyWell,
    )
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(0)}
        allowResize={true}
        defaultSize={getInitialPaneSize(0)}
        minSize={'10%'}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SurveyPropertiesGrid
          errorModels={errorModels}
          tieOnSurveyHeaders={tieOnSurveyHeaders}
          surveyData={surveyData}
          handleUpdate={updateSurveyData}
          units={getUnitsText(UNITS_FOR.Depth)}
        />
        <SurveyTieOnGrid
          sideTrackDepth={surveyDataRef.current?.isSideTrack ? surveyDataRef.current?.sideTrackDepth : 0}
          tieOnData={surveyData}
          handleUpdate={updateTieOnData}
          units={getUnitsText(UNITS_FOR.Depth)}
          tieOnSurvey={getTieOnSvy()}
          localCoordOffset={localCoordOffset.current}
          deltaElevation={getDeltaElevation(
            surveyDataRef.current?.parentSurveyWell,
            surveyDataRef.current?.parentTieOn,
          )}
        />
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default SurveyPropertiesPage
