import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Box, Tooltip, Alert, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { Icon as Iconify } from '@iconify/react'
import cloneDeep from 'lodash/cloneDeep'
import { getEngParamDefs, getGroupIcon, getGridData, getId } from './EngineeringParamDefs'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useEngDropDowns from 'components/common/hooks/Engineering/useEngDropDowns'
import useEngineering from 'components/common/hooks/Engineering/useEngineering'
import useDrillString from 'components/common/hooks/useDrillString/useDrillString'
import {
  surgeAndSwabMinMaxEmw,
  selectedEngineeringCaseAtom,
  selectedEngineeringWellboreAtom,
  currentBhaAtom,
  currentWellAtom,
} from 'atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import ConfirmDialog from 'components/common/ConfirmDialog'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import DrillStringImportModal from 'components/WellPages/DrillStringPages/DrillStringImportModal'
import EngineeringCaseImportModal from 'components/WellPages/EngineeringDashboard/EngineeringCaseImportModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const defaultColDef = {
  resizable: true,
  sortable: false,
  autoHeight: true,
  editable: false,
  headerClass: 'header-no-padding',
  flex: 1,
}

const EngineeringParametersGrid = ({ showHidePane, showControls }) => {
  const _isMounted = useRef(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const setMinMaxSsEmw = useSetRecoilState(surgeAndSwabMinMaxEmw)

  const gridApi = useRef(null)

  const setInitialCase = useRef(true)
  const [currentBha, setCurrentBha] = useRecoilState(currentBhaAtom)
  const [selEngWellbore, setSelEngWellbore] = useRecoilState(selectedEngineeringWellboreAtom)
  const { getUnitsText } = useUnits()
  const { getAgGridTheme, getChartBackColor, getWindowBarColor } = useInnovaTheme()
  const {
    dropDownsLoading,
    fetchEngDropDowns,
    getDropDownValues,
    getDropDownValue,
    getDropDownLabel,
    getWellGeoIds,
    getBhaIds,
    getWellGeoLabel,
    getEngCaseLabel,
    getBhaLabel,
  } = useEngDropDowns()
  const {
    getEngCase,
    addEngCase,
    deleteEngCase,
    getInitialCaseId,
    initialLoadComplete,
    getEngCaseIds,
    updateCase,
    updateFluid,
    addWellGeoHeader,
    deleteWellGeoHeader,
    updateCaseDesc,
    updateWellGeoHeader,
    copyEngCase,
  } = useEngineering()
  const { deleteBha, addBha, copyBha, updateBhaDesc } = useDrillString()
  const [selectedEngCaseId, setSelectedEngCaseId] = useState(-1)
  const [showDelete, setShowDelete] = useState({ show: false, type: '' })
  const [showEdit, setShowEdit] = useState({ show: false, type: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [isLoading, setLoading] = useState(false)
  const [selectedEngCase, setSelectedEngCase] = useRecoilState(selectedEngineeringCaseAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const engParams = useRef(cloneDeep(getEngParamDefs(selectedEngCase)))
  const [showImportBhaModal, setShowImportBhaModal] = useState(false)
  const [showImportEngCaseModal, setShowImportEngCaseModal] = useState(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const setInitialEngCase = () => {
    if (_isMounted.current && gridApi.current && initialLoadComplete && setInitialCase.current) {
      let initialCaseId = getInitialCaseId()
      setInitialCase.current = false
      setSelectedEngCaseId(initialCaseId)
    }
  }

  useEffect(() => {
    setInitialCase.current = true
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialEngCase()
  }, [dropDownsLoading, initialLoadComplete]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteCasePressed = () => {
    if (!showDelete?.show) return
    setShowDelete({ show: false, type: '' })

    if (selectedEngCase === null) return
    setConfirm({
      show: true,
      title: 'Delete Engineering Case',
      text: `Are you sure you want to delete ${selectedEngCase.caseDesc}?`,
      onConfirm: removeEngCase,
    })
  }

  const handleDeleteWellGeoPressed = () => {
    if (!showDelete?.show) return
    setShowDelete({ show: false, type: '' })

    if (selEngWellbore === null) return
    if (selEngWellbore < 0) return
    setConfirm({
      show: true,
      title: 'Delete Wellbore',
      text: `Are you sure you want to delete ${getWellGeoLabel(selEngWellbore)}?`,
      onConfirm: removeWellGeoHeader,
    })
  }

  const handleDeleteBhaPressed = () => {
    if (!showDelete?.show) return
    setShowDelete({ show: false, type: '' })

    if (currentBha?.bhaNum === null) return
    if (currentBha?.bhaNum < 0) return
    setConfirm({
      show: true,
      title: 'Delete Bha',
      text: `Are you sure you want to the selected bha ${getBhaLabel(currentBha?.bhaNum)}?`,
      onConfirm: removeBha,
    })
  }

  useEffect(() => {
    if (showDelete?.type === 'CASE') handleDeleteCasePressed()
    if (showDelete?.type === 'WELLBORE') handleDeleteWellGeoPressed()
    if (showDelete?.type === 'BHA') handleDeleteBhaPressed()
  }, [showDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  const createEngCase = async () => {
    if (isLoading) return
    let orgEngIds = getEngCaseIds()

    setLoading(true)
    let res = await addEngCase()
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Add case failed:${res.message}` : 'Case added',
    })

    if (res.isError) return

    await fetchEngDropDowns()
    let newEngIds = getEngCaseIds()
    setLoading(false)

    let newId = -1
    for (let i = 0; i < newEngIds.length; i++) {
      let index = orgEngIds.findIndex((id) => id === newEngIds[i])
      if (index < 0) {
        newId = newEngIds[i]
        break
      }
    }

    if (newId < 0) return
    setSelectedEngCaseId(newId)
  }

  const createBha = async () => {
    if (isLoading) return
    let orgBhaIds = getBhaIds()

    setLoading(true)
    let res = await addBha('New BHA')
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Add BHA failed:${res.message}` : 'BHA added',
    })

    if (res.isError) return

    await fetchEngDropDowns()
    let newBhaIds = getBhaIds()
    setLoading(false)

    let newId = -1
    for (let i = 0; i < newBhaIds.length; i++) {
      let index = orgBhaIds.findIndex((id) => id === newBhaIds[i])
      if (index < 0) {
        newId = newBhaIds[i]
        break
      }
    }

    if (newId < 0) return
    onCellEditingStopped({ label: 'BHA', value: newId, id: getId(engParams.current, 'BHA') })
    setCurrentBha({ bhaNum: newId })
  }

  const createWellbore = async () => {
    if (isLoading) return
    let orgGeoIds = getWellGeoIds()

    setLoading(true)
    let res = await addWellGeoHeader('New Wellbore')
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Add geometry failed:${res.message}` : 'Geometry added',
    })

    if (res.isError) return

    await fetchEngDropDowns()
    setLoading(false)

    let newGeoIds = getWellGeoIds()
    let newId = -1
    for (let i = 0; i < newGeoIds.length; i++) {
      let index = orgGeoIds.findIndex((id) => id === newGeoIds[i])
      if (index < 0) {
        newId = newGeoIds[i]
        break
      }
    }

    if (newId < 0) return
    onCellEditingStopped({ label: 'Wellbore', value: newId, id: getId(engParams.current, 'Wellbore') })
    setSelEngWellbore(newId)
  }

  const removeBha = async () => {
    if (isLoading) return
    if (currentBha === null) return
    if (currentBha?.bhaNum === null) return
    if (currentBha?.bhaNum < 0) return

    let orgBhaIds = getBhaIds()
    let newId = -1
    let index = orgBhaIds.findIndex((id) => id === currentBha?.bhaNum)

    if (orgBhaIds.length > 1) {
      if (index === 0) newId = orgBhaIds[1]
      if (index === orgBhaIds.length - 1) newId = orgBhaIds[0]
      if (index > 0 && index < orgBhaIds.length - 1) newId = orgBhaIds[index + 1]
    }

    setLoading(true)
    let res = await deleteBha(currentBha?.bhaNum)
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Delete BHA failed:${res.message}` : 'BHA deleted',
    })

    await fetchEngDropDowns()
    setLoading(false)

    onCellEditingStopped({ label: 'BHA', value: newId, id: getId(engParams.current, 'BHA') })
    setCurrentBha({
      bhaNum: newId,
    })
  }

  const removeWellGeoHeader = async () => {
    if (isLoading) return
    if (selEngWellbore === null) return
    let wellBoreId = typeof selEngWellbore === 'string' ? parseInt(selEngWellbore) : selEngWellbore

    let orgGeoIds = getWellGeoIds()
    let newId = -1
    let index = orgGeoIds.findIndex((id) => id === wellBoreId)

    if (orgGeoIds.length > 1) {
      if (index === 0) newId = orgGeoIds[1]
      if (index === orgGeoIds.length - 1) newId = orgGeoIds[0]
      if (index > 0 && index < orgGeoIds.length - 1) newId = orgGeoIds[index + 1]
    }

    setLoading(true)
    let res = await deleteWellGeoHeader(wellBoreId)
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Delete geometry failed:${res.message}` : 'Geometry deleted',
    })

    await fetchEngDropDowns()
    setLoading(false)

    onCellEditingStopped({ label: 'Wellbore', value: newId, id: getId(engParams.current, 'Wellbore') })
    setSelEngWellbore(newId)
  }

  const removeEngCase = async () => {
    if (isLoading) return
    if (selectedEngCase === null) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return

    let orgEngIds = getEngCaseIds()
    let newId = -1
    let index = orgEngIds.findIndex((id) => id === selectedEngCase.recordId)

    if (orgEngIds.length > 1) {
      if (index === 0) newId = orgEngIds[1]
      if (index === orgEngIds.length - 1) newId = orgEngIds[0]
      if (index > 0 && index < orgEngIds.length - 1) newId = orgEngIds[index + 1]
    }

    setLoading(true)
    let res = await deleteEngCase(selectedEngCase.recordId)
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Delete case failed:${res.message}` : 'Case deleted',
    })

    await fetchEngDropDowns()
    setLoading(false)

    setSelectedEngCaseId(newId)
  }

  const doesBhaNumExist = () => {
    let engCase = getEngCase(selectedEngCaseId)
    if (engCase === null) return false
    if (engCase.bhaNum < 0) return false

    let drillStrings = getDropDownValues('drillString')
    if (!Array.isArray(drillStrings)) return false
    for (let i = 0; i < drillStrings.length; i++) {
      if (drillStrings[i].value === engCase.bhaNum) return true
    }

    return false
  }

  const handleEngCaseChanged = async () => {
    if (!_isMounted.current) return
    let engCase = getEngCase(selectedEngCaseId)

    setCurrentBha(
      engCase !== null
        ? {
            bhaNum: doesBhaNumExist() ? engCase.bhaNum : -1,
          }
        : { bhaNum: -1, bhaNumForPdf: null, pdfDocData: null, pdfFileName: null },
    )
    setSelEngWellbore(engCase !== null ? engCase.geoId : -1)
    setSelectedEngCase(engCase !== null ? cloneDeep(engCase) : null)
    setMinMaxSsEmw({ minEmw: engCase ? engCase.ssMinEmw : -1, maxEmw: engCase ? engCase.ssMaxEmw : -1 })
    expandFirstNode()
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  useEffect(() => {
    handleEngCaseChanged()
  }, [selectedEngCaseId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      engParams.current = cloneDeep(getEngParamDefs(selectedEngCase))
      if (gridApi.current) gridApi.current?.setGridOption('rowData', getGridData(engParams.current))
    }
  }, [selectedEngCase]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = [
    {
      headerName: '',
      field: 'group',
      rowGroup: true,
      hide: true,
    },
    {
      headerName: 'Parameter',
      field: 'label',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      maxWidth: 200,
      minWidth: 20,
    },
    {
      headerName: 'Value',
      field: 'value',
      editable: true,
      cellDataType: false,
      cellEditorSelector: (params) => {
        if (params.data?.type === 'text') {
          return {
            component: 'agTextCellEditor',
            popup: false,
          }
        }

        if (params.data?.type === 'numeric') {
          return {
            component: 'agNumberCellEditor',
            params: {
              min: params.data?.minVal,
              max: params.data?.maxVal,
              precision: params.data?.precision,
            },
          }
        }

        if (params.data?.type === 'boolean') {
          return {
            component: 'agCheckboxCellEditor',
            popup: false,
          }
        }

        if (params.data?.type === 'dropDownSimple') {
          let dropDownValues = []
          if (params.data?.hasOwnProperty('dropDownField')) {
            let simpleDropDownValues = []
            dropDownValues = getDropDownValues(params.data?.dropDownField)

            for (let i = 0; i < dropDownValues.length; i++) {
              simpleDropDownValues.push(dropDownValues[i].label)
            }

            dropDownValues = [...simpleDropDownValues]
          }

          return {
            component: 'agSelectCellEditor',
            params: {
              values: dropDownValues,
            },
            popup: false,
          }
        }

        if (params.data?.type === 'dropDown') {
          let dropDownValues = []
          if (params.data?.hasOwnProperty('dropDownField')) {
            dropDownValues = getDropDownValues(params.data?.dropDownField)
          }

          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: dropDownValues.map((item) => item.label),
            },
            popup: true,
          }
        }

        return undefined
      },
      cellRendererSelector: (params) => {
        if (params.node.data.type === 'boolean') {
          return { component: 'agCheckboxCellRenderer' }
        }
        return null
      },
      valueFormatter: (params) => {
        if (!params.data) return ''

        if (params.data?.type === 'numeric') {
          return numberWithCommasDecimals(params.value, params.data?.precision)
        }

        if (params.data?.type === 'dropDown') {
          return getDropDownLabel(params)
        }

        return params.value
      },
    },
    {
      headerName: 'Units',
      field: 'units',
      sortable: false,
      maxWidth: 160,
      minWidth: 20,
      valueGetter: (params) => {
        if (!params.data?.hasOwnProperty('units')) return ''
        if (typeof params.data?.units !== 'string') return ''
        if (params.data?.units === '') return ''
        if (params.data?.units === 'ROP') return `${getUnitsText(UNITS_FOR.Depth)}/hr`
        if (params.data?.units === 'REAMING') return `${getUnitsText(UNITS_FOR.Depth)}/min`
        if (params.data?.units === 'DEPTH') return getUnitsText(UNITS_FOR.Depth)
        if (params.data?.units === 'WEIGHT') return getUnitsText(UNITS_FOR.Weight)
        if (params.data?.units === 'TORQUE') return getUnitsText(UNITS_FOR.Torque)
        if (params.data?.units === 'MUD') return getUnitsText(UNITS_FOR.MudWeight)
        if (params.data?.units === 'PRESSURE') return getUnitsText(UNITS_FOR.Pressure)
        if (params.data?.units === 'VOLUME') return getUnitsText(UNITS_FOR.Volume)
        if (params.data?.units === 'PERCENT') return '%'
        if (params.data?.units === 'PRESS/FT')
          return `${getUnitsText(UNITS_FOR.Pressure)}/${getUnitsText(UNITS_FOR.Depth)}`
      },
      cellRenderer: (params) => {
        if (params.node.data.units === 'BUTTON_GROUP') {
          return (
            <AddDeleteButtonGroup
              addFunc={getAddFunc(params.data?.label)}
              deleteFunc={getDeleteFunc(params.data?.label)}
              editFunc={getEditFunc(params.data?.label)}
              copyFunc={getCopyFunc(params.data?.label)}
            />
          )
        }

        return params.value
      },
    },
  ]

  const getCopyFunc = (param) => {
    if (param === 'Selected Case') return () => setShowImportEngCaseModal(true)
    if (param === 'BHA') return () => setShowImportBhaModal(true)
    return null
  }

  const getAddFunc = (param) => {
    if (param === 'Selected Case') return createEngCase
    if (param === 'BHA') return createBha
    if (param === 'Wellbore') return createWellbore
    return null
  }

  const getEditFunc = (param) => {
    if (param === 'Selected Case')
      return () =>
        setShowEdit({
          show: true,
          type: 'CASE',
          title: 'Engineering Case',
          value: getEngCaseLabel(selectedEngCase?.recordId),
        })
    if (param === 'BHA') return () => setShowEdit({ show: true, type: 'BHA', title: 'BHA', value: 'BHA' })
    if (param === 'Wellbore')
      return () =>
        setShowEdit({ show: true, type: 'WELLBORE', title: 'Wellbore', value: getWellGeoLabel(selEngWellbore) })
    return null
  }

  const getDeleteFunc = (param) => {
    if (param === 'Selected Case') return () => setShowDelete({ show: true, type: 'CASE' })
    if (param === 'BHA') return () => setShowDelete({ show: true, type: 'BHA' })
    if (param === 'Wellbore') return () => setShowDelete({ show: true, type: 'WELLBORE' })
    return null
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const AddDeleteButtonGroup = ({ addFunc, deleteFunc, editFunc, copyFunc }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Tooltip
          title={'Add new'}
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Box
            onClick={() => {
              if (addFunc) addFunc()
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid gray',
              cursor: 'pointer',
            }}>
            <Iconify icon={'mdi:add-bold'} width='25' height='25' color='green' />
          </Box>
        </Tooltip>
        <Tooltip
          title={'Delete'}
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Box
            onClick={() => {
              if (deleteFunc) deleteFunc(true)
            }}
            sx={{
              marginLeft: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid gray',
              cursor: 'pointer',
            }}>
            <Iconify icon={'material-symbols:delete-outline'} width='25' height='25' color='red' />
          </Box>
        </Tooltip>
        <Tooltip
          title={'Edit'}
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Box
            onClick={() => {
              if (editFunc) editFunc()
            }}
            sx={{
              marginLeft: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid gray',
              cursor: 'pointer',
            }}>
            <Iconify icon={'material-symbols:edit'} width='25' height='25' color='gray' />
          </Box>
        </Tooltip>
        {copyFunc ? (
          <Tooltip
            title={'Copy'}
            placement='right'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <Box
              onClick={() => {
                if (editFunc) copyFunc()
              }}
              sx={{
                marginLeft: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid gray',
                cursor: 'pointer',
              }}>
              <Iconify icon={'gg:copy'} width='25' height='25' color='white' />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
    )
  }

  const GroupRowInnerRenderer = (props) => {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'row', padding: '10px', alignItems: 'start', justifyContent: 'center' }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={getGroupIcon(props.node.key)} width='35' height='35' color='lightgrey' />
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
      </Box>
    )
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Expand All',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="ri:expand-up-down-fill" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse All',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="ion:chevron-collapse-sharp" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    expandFirstNode()
    setInitialEngCase()
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const expandFirstNode = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    let count = 0
    gridApi.current?.forEachNodeAfterFilterAndSort((node) => {
      if (node.group) {
        if (count === 0) node.setExpanded(true)
        count++
      }
    })
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    suppressCount: true,
    checkbox: false,
  }

  const onCellEditingStopped = async (data) => {
    if(data.type === 'dropDown') {
      data.value = getDropDownValue(data)
    }

    if (data.label === 'Selected Case') {
      setSelectedEngCaseId(data.value)
      return
    }

    if (data.label === 'BHA') {
      setCurrentBha({
        bhaNum: data.value,
      })
    }

    if (data.label === 'Wellbore') {
      setSelEngWellbore(data.value)
    }

    for (let i = 0; i < engParams.current.length; i++) {
      if (engParams.current[i].label !== data.label) continue
      if (engParams.current[i].type === 'dropDown') engParams.current[i].value = data.value
      if (engParams.current[i].type === 'dropDownSimple') engParams.current[i].value = data.value
      if (engParams.current[i].type === 'numeric' || engParams.current[i].type === 'text') {
        engParams.current[i].value = data.value
      }

      if (engParams.current[i].type === 'boolean') {
        engParams.current[i].value = data.value
      }
      break
    }

    if (gridApi.current) gridApi.current?.setGridOption('rowData', getGridData(engParams.current))
    if (data.group !== 'Mud') await updateCase(engParams.current)
    if (data.group === 'Mud') await updateFluid(engParams.current)

    if (data.label === 'Description' || data.label === 'Active') {
      await fetchEngDropDowns()
    }

    let minEmwIndex = engParams.current.findIndex((param) => param.tag === 'ssMinEmw')
    let maxEmwIndex = engParams.current.findIndex((param) => param.tag === 'ssMaxEmw')
    if (minEmwIndex >= 0 && maxEmwIndex >= 0) {
      setMinMaxSsEmw({ minEmw: engParams.current[minEmwIndex].value, maxEmw: engParams.current[maxEmwIndex].value })
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onCellEditingStopped: (event) => {
      onCellEditingStopped(event?.data)
    },
    headerHeight: 0,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const closeEditModal = () => {
    setShowEdit({ show: false, type: '' })
  }

  const handleSubmitEdit = async (data) => {
    let updateRes = null
    if (showEdit.type === 'WELLBORE') {
      updateRes = await updateWellGeoHeader({ typeDesc: data.newName, recordId: selEngWellbore })
    }

    if (showEdit.type === 'CASE') {
      updateRes = await updateCaseDesc({ caseDesc: data.newName, recordId: selectedEngCaseId })
    }

    if (showEdit.type === 'BHA') {
      updateRes = await updateBhaDesc({ bhaDesc: data.newName, bhaNum: currentBha.bhaNum })
    }

    if (updateRes !== null && updateRes !== undefined) {
      setStatus({
        show: true,
        severity: updateRes?.isError ? 'error' : 'success',
        message: updateRes?.isError ? `Update failed:${updateRes.message}` : 'Update success',
      })
    }

    closeEditModal()
    await fetchEngDropDowns()
  }

  const EditModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({
      newName: yup.string().required(),
    })

    let formData = [
      {
        tag: 'newName',
        value: data,
        text: 'Description',
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={showEdit?.show}
        onClose={cancelFunction}
        title={showEdit?.title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleImportEngCase = async (data) => {
    setShowImportEngCaseModal(false)
    if (isLoading) return
    let orgEngIds = getEngCaseIds()

    setLoading(true)
    let res = await copyEngCase(data)
    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Copy case failed:${res.message}` : 'Case copied',
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (res.isError) return

    await fetchEngDropDowns()
    let newEngIds = getEngCaseIds()

    let newId = -1
    for (let i = 0; i < newEngIds.length; i++) {
      let index = orgEngIds.findIndex((id) => id === newEngIds[i])
      if (index < 0) {
        newId = newEngIds[i]
        break
      }
    }

    if (newId < 0) return
    setSelectedEngCaseId(newId)
  }

  const handleImportBha = async (data) => {
    setShowImportBhaModal(false)
    if (isLoading) return
    let orgBhaIds = getBhaIds()

    setLoading(true)
    let res = await copyBha(data)
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Copy BHA failed:${res.message}` : 'BHA added',
    })

    if (res.isError) return

    await fetchEngDropDowns()
    let newBhaIds = getBhaIds()
    setLoading(false)

    let newId = -1
    for (let i = 0; i < newBhaIds.length; i++) {
      let index = orgBhaIds.findIndex((id) => id === newBhaIds[i])
      if (index < 0) {
        newId = newBhaIds[i]
        break
      }
    }

    if (newId < 0) return
    onCellEditingStopped({ label: 'BHA', value: newId, id: getId(engParams.current, 'BHA') })
    setCurrentBha({ bhaNum: newId })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirm.onConfirm()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showEdit?.show ? (
        <EditModal data={showEdit?.value} submitFunction={handleSubmitEdit} cancelFunction={closeEditModal} />
      ) : null}
      {showImportBhaModal ? (
        <DrillStringImportModal onClose={() => setShowImportBhaModal(false)} onApply={handleImportBha} />
      ) : null}
      {showImportEngCaseModal ? (
        <EngineeringCaseImportModal onClose={() => setShowImportEngCaseModal(false)} onApply={handleImportEngCase} />
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          display: showControls ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 25px)',
          backgroundColor: getChartBackColor(),
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={getGridData(engParams.current)}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            groupDisplayType={'groupRows'}
            onGridReady={onGridReady}
            getContextMenuItems={getContextMenuItems}
            groupRowRendererParams={groupRowRendererParams}
            groupRowsSticky={true}
            gridOptions={gridOptions}
          />
        </div>
      </Box>
      {!showControls ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Engineering Parameters
          </Box>
        </Box>
      ) : null}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={status?.show}
        autoHideDuration={4000}
        onClose={handleCloseStatus}>
        <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
          {status.message}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default EngineeringParametersGrid
