import React from 'react'
import { appColors } from 'utils'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const StyledTextItem = styled(Box)({
  color: appColors.itemTextColor,
  fontWeight: 'bold',
})

const StyledTextHeader = styled(Box)({
  color: '#FFF',
  fontWeight: 'bold',
})

const BhaInfoOverlay = ({ bhaPoint, showControls = true, leftOffset }) => {
  return showControls ? (
    <Box
      sx={{
        position: 'absolute',
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'flex-start',
        alignSelf: 'start',
        pointerEvents: 'none',
        marginLeft: '0',
        top: '15px',
        left: leftOffset,
      }}>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <StyledTextHeader>Component:</StyledTextHeader>
        <StyledTextHeader>Dist From Bit:</StyledTextHeader>
        <StyledTextHeader>HS Deflection:</StyledTextHeader>
        <StyledTextHeader>RS Deflection: </StyledTextHeader>
        <StyledTextHeader>Tension: </StyledTextHeader>
        <StyledTextHeader>Torque: </StyledTextHeader>
        <StyledTextHeader>Contact Force: </StyledTextHeader>
        <StyledTextHeader>Bending Moment: </StyledTextHeader>
        <StyledTextHeader>Shear Load: </StyledTextHeader>
        <StyledTextHeader>OD: </StyledTextHeader>
        <StyledTextHeader>ID: </StyledTextHeader>
        <StyledTextHeader>INC: </StyledTextHeader>
        <StyledTextHeader>AZI: </StyledTextHeader>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '10px' }}>
        <StyledTextItem>{bhaPoint ? bhaPoint.description : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.distanceFromBit, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.hsDef, 6) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.rsDef, 6) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.tension, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.torque, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.totalContactForce, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.totalBendingMoment, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.totalShearLoad, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.od, 3) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.id, 3) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.surveyStation.inc, 2) : '-'}</StyledTextItem>
        <StyledTextItem>{bhaPoint ? numberWithCommasDecimals(bhaPoint.surveyStation.azi, 2) : '-'}</StyledTextItem>
      </Box>
    </Box>
  ) : null
}

export default BhaInfoOverlay
