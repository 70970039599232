
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { dateTimeFormatter, getStabToBit, getBhaTotalWeight, getJarsDryWt, getPlugInHrs, getStatorData } from './utils'
import { UNITS_FOR } from 'components/common/hooks/useUnits'

export const generateMotorReportPdfDocument = (bhaData,
  motorImages = null,
  motorReportData,
  wellInfoRef,
  currentWellRef,
  getCurrentOrgIcon,
  getUnitsText) => {
  if (!Array.isArray(motorReportData?.motorReports) || motorReportData?.motorReports.length === 0) return null
  if (bhaData === null || bhaData === undefined) return null
  if (!bhaData.hasOwnProperty('bhaComponents')) return null
  if (!Array.isArray(bhaData.bhaComponents)) return null
  if (bhaData.bhaComponents.length === 0) return null

  let motorData = bhaData.bhaComponents.find((component) => component.type === 'Motor')
  let bitData = bhaData.bhaComponents.find((component) => component.type === 'Drill Bit')
  let mwdData = bhaData.bhaComponents.find((component) => component.type === 'MWD/LWD')

  for (let i = 0; i < bhaData.bhaComponents.length; i++) {
    if (bhaData.bhaComponents[i].type !== 'MWD/LWD') continue

    if (mwdData.surveyOffset <= 0 && bhaData.bhaComponents[i].surveyOffset > 0) mwdData.surveyOffset = bhaData.bhaComponents[i].surveyOffset
    if (mwdData.gammaOffset <= 0 && bhaData.bhaComponents[i].gammaOffset > 0) mwdData.gammaOffset = bhaData.bhaComponents[i].gammaOffset
    if (mwdData.resOffset <= 0 && bhaData.bhaComponents[i].resOffset > 0) mwdData.resOffset = bhaData.bhaComponents[i].resOffset
    if (mwdData.pwdOffset <= 0 && bhaData.bhaComponents[i].pwdOffset > 0) mwdData.pwdOffset = bhaData.bhaComponents[i].pwdOffset
    if (mwdData.densOffset <= 0 && bhaData.bhaComponents[i].densOffset > 0) mwdData.densOffset = bhaData.bhaComponents[i].densOffset
    if (mwdData.neutronOffset <= 0 && bhaData.bhaComponents[i].neutronOffset > 0) mwdData.neutronOffset = bhaData.bhaComponents[i].neutronOffset
    if (mwdData.sonicOffset <= 0 && bhaData.bhaComponents[i].sonicOffset > 0) mwdData.sonicOffset = bhaData.bhaComponents[i].sonicOffset
    if (mwdData.nbIncOffset <= 0 && bhaData.bhaComponents[i].nbIncOffset > 0) mwdData.nbIncOffset = bhaData.bhaComponents[i].nbIncOffset
    if (mwdData.nbAziOffset <= 0 && bhaData.bhaComponents[i].nbAziOffset > 0) mwdData.nbAziOffset = bhaData.bhaComponents[i].nbAziOffset
    if (mwdData.gyroOffset <= 0 && bhaData.bhaComponents[i].gyroOffset > 0) mwdData.gyroOffset = bhaData.bhaComponents[i].gyroOffset
  }

  let motorReport = motorReportData.motorReports.find(
    (report) => parseInt(report.bhaNum) === parseInt(bhaData.bhaNum),
  )

  if (!motorReport) return null
  let docData = [
    {
      showTitle: true,
      title: `${wellInfoRef.current.operator} / ${currentWellRef.current} - Motor Performance Report`,
      showLogo: true,
      logo: getCurrentOrgIcon(),
      logoWidth: '25%',
      manualWidth: true,
      columnWidths: ['15%', '20%', '15%', '50%'],
      data: [
        [
          { text: 'Job #', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.jobNum, textAlign: 'left' },
          { text: 'Operator', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.operator, textAlign: 'left' },
        ],
        [
          { text: 'API Job#', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.apiNum, textAlign: 'left' },
          { text: 'Field', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.field, textAlign: 'left' },
        ],
        [
          { text: 'Latitude', isBold: true, textAlign: 'right', isHeader: true },
          { text: numberWithCommasDecimals(wellInfoRef.current.lat, 6), textAlign: 'left' },
          { text: 'Well', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.parentWell, textAlign: 'left' },
        ],
        [
          { text: 'Longitude', isBold: true, textAlign: 'right', isHeader: true },
          { text: numberWithCommasDecimals(wellInfoRef.current.lon, 6), textAlign: 'left' },
          { text: 'State', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.state, textAlign: 'left' },
        ],
        [
          { text: 'Rig', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.rig, textAlign: 'left' },
          { text: 'County', isBold: true, textAlign: 'right', isHeader: true },
          { text: wellInfoRef.current.county, textAlign: 'left' },
        ],
        [
          { text: 'Reason POOH', isBold: true, textAlign: 'right', isHeader: true },
          { text: motorReport.reasonPooh, textAlign: 'left' },
          { text: 'BHA #', isBold: true, textAlign: 'right', isHeader: true },
          { text: bhaData.bhaNumRep, textAlign: 'left' },
        ],
        [
          { text: 'Lead DD', isBold: true, textAlign: 'right', isHeader: true },
          { text: motorReport.leadDD, textAlign: 'left' },
          { text: 'Company Man', isBold: true, textAlign: 'right', isHeader: true },
          { text: motorReport.companyMan, textAlign: 'left' },
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: ['16.5%', '17%', '16.5%', '17%', '16.5%', '16.5%'],
      data: [
        [{ text: 'Motor Performance Report', isHeader: true, width: '100%' }],
        [
          { text: 'Run Data', isHeader: true, columnSpan: 2 },
          { text: 'Motor Data', isHeader: true, columnSpan: 2 },
          { text: 'Drilling Parameters', isHeader: true, columnSpan: 2 },
        ],
        [
          { text: 'Date In', isBold: true, textAlign: 'right' },
          { text: dateTimeFormatter(motorReport.dateIn + 'T' + motorReport.timeIn) },
          { text: 'SN', isBold: true, textAlign: 'right' },
          { text: motorData?.sn },
          { text: `Temp (${getUnitsText(UNITS_FOR.Temperature)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'temperature', 0) },
        ],
        [
          { text: 'Date Out', isBold: true, textAlign: 'right' },
          { text: dateTimeFormatter(motorReport.dateOut + 'T' + motorReport.timeOut) },
          { text: 'Description', isBold: true, textAlign: 'right' },
          { text: bhaData.motorData?.description },
          { text: `WOB Rotate (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'wob', 0) },
        ],
        [
          { text: `Depth In (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: `${numberWithCommasDecimals(bhaData.depthIn, 2)}` },
          { text: 'Make', isBold: true, textAlign: 'right' },
          { text: `${bhaData.motorData?.make}` },
          { text: `WOB Slide (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'wobSlide', 0) },
        ],
        [
          { text: `Depth Out (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: `${numberWithCommasDecimals(bhaData.depthOut, 2)}` },
          { text: 'Model', isBold: true, textAlign: 'right' },
          { text: `${bhaData.motorData?.model}` },
          { text: 'RPM', isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'rpm', 0) },
        ],
        [
          { text: `Inc In`, isBold: true, textAlign: 'right' },
          { text: `${numberWithCommasDecimals(motorReport.IncIn, 2)}` },
          { text: 'Re-Run', isBold: true, textAlign: 'right' },
          { text: `${motorData?.reRun === '' ? 'NO' : motorData?.reRun}` },
          { text: `Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'flowRate', 0) },
        ],
        [
          { text: `Inc Out`, isBold: true, textAlign: 'right' },
          { text: `${numberWithCommasDecimals(motorReport.IncOut, 2)}` },
          { text: 'Direct Bill', isBold: true, textAlign: 'right' },
          { text: `${motorData?.directBill === '' ? 'NO' : motorData?.directBill}` },
          { text: `Off Btm Press (${getUnitsText(UNITS_FOR.Pressure)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'offBtmPress', 0) },
        ],
        [
          { text: `Azi In`, isBold: true, textAlign: 'right' },
          { text: `${numberWithCommasDecimals(motorReport.AziIn, 2)}` },
          { text: 'Lobes', isBold: true, textAlign: 'right' },
          { text: motorData?.motorLobes },
          { text: `On Btm Press (${getUnitsText(UNITS_FOR.Pressure)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'onBtmPress', 0) },
        ],
        [
          { text: 'Azi Out', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorReport.AziOut, 2) },
          { text: 'Stages', isBold: true, textAlign: 'right' },
          { text: motorData?.motorStages },
          { text: `On Btm Torque (${getUnitsText(UNITS_FOR.Torque)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'onBtmTq', 0) },
        ],
        [
          { text: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.totalDrilled, 2) },
          { text: 'Bend Angle', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.bendAngle, 2) },
          { text: `Off Btm Torque (${getUnitsText(UNITS_FOR.Torque)})`, isBold: true, textAlign: 'right' },
          { text: '0' },
        ],
        [
          { text: '%Slide (Ftg)', isBold: true, textAlign: 'right' },
          { text: bhaData.totalDrilled > 0 ? numberWithCommasDecimals((bhaData.slideDrilled / bhaData.totalDrilled) * 100, 2) : 0 },
          { text: `Bit to Bend (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.bit2bend, 2) },
          { text: `Diff Press (${getUnitsText(UNITS_FOR.Pressure)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'diffPress', 0) },
        ],
        [
          { text: '%Slide (Time)', isBold: true, textAlign: 'right' },
          {
            text: bhaData.totalDrillingHours > 0 ? numberWithCommasDecimals((bhaData.slideHours / bhaData.totalDrillingHours) * 100, 2) : 0,
          },
          { text: `Sleeve Stab OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.stabOd, 2) },
          { text: `PU WT (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'pickUpWeight', 0) },
        ],
        [
          { text: `Slide Drilled (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.slideDrilled, 2) },
          { text: `Bearing Gap (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.bearingGap, 3) },
          { text: `ROT WT (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'rotateWeight', 0) },
        ],
        [
          { text: 'Slide Hours', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.slideHours, 2) },
          { text: `Stab to Bit (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.stabDfb, 2) },
          { text: `SO WT (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true, textAlign: 'right' },
          { text: getMinMaxAvg(motorReport, 'slackOffWeight', 0) },
        ],
        [
          { text: '%Rotary (Ftg)', isBold: true, textAlign: 'right' },
          { text: bhaData.totalDrilled > 0 ? numberWithCommasDecimals((bhaData.rotateDrilled / bhaData.totalDrilled) * 100, 2) : 0 },
          { text: `Kickpad OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.kickPadOd, 3, true) },
          { text: 'Bit SN', isBold: true, textAlign: 'right' },
          { text: bhaData.bitData?.sn },
        ],
        [
          { text: '%Rotary (Time)', isBold: true, textAlign: 'right' },
          {
            text: bhaData.totalDrillingHours > 0 ? numberWithCommasDecimals((bhaData.rotateHours / bhaData.totalDrillingHours) * 100, 2) : 0,
          },
          { text: `Body OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.od, 3) },
          { text: 'Bit Make/Model', isBold: true, textAlign: 'right' },
          { text: bhaData.bitData?.make + ' / ' + bhaData.bitData?.model },
        ],
        [
          { text: `Rotary Drilled (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.rotateDrilled, 2, true) },
          { text: `Fishneck OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.fishNeckOd, 3, true) },
          { text: `Bit OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bitData?.od, 3) },
        ],
        [
          { text: 'Rotary Hours', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.rotateHours, 2, true) },
          { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.length, 2) },
          { text: 'Bit TFA', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bitData?.tfa, 3) },
        ],
        [
          { text: 'Total Drlg Hrs', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.slideHours + bhaData.rotateHours, 2) },
          { text: 'Integral Float', isBold: true, textAlign: 'right' },
          { text: motorData?.hasFloat ? 'Yes' : 'No' },
          { text: 'Bit #/K Revs', isBold: true, textAlign: 'right' },
          { text: bitData?.bitNum + '/' + numberWithCommasDecimals(bhaData?.krevs, 0) },
        ],
        [
          { text: 'Total Circ/BRT Hrs', isBold: true, textAlign: 'right' },
          {
            text: `${numberWithCommasDecimals(bhaData.circHours + bhaData.totalDrillingHours, 2, false)} / ${numberWithCommasDecimals(
              bhaData.brtHours,
              2,
              true,
            )}`,
          },
          { text: 'Rev per Gal:', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorData?.revPerGal, 2) },
          { text: 'Bit Grade In', isBold: true, textAlign: 'right' },
          { text: bitData?.gradeIn },
        ],
        [
          { text: `Slide ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.slideRop, 2) },
          { text: '#Stalls / Pressure', isBold: true, textAlign: 'right' },
          {
            text: `${numberWithCommasDecimals(motorReport?.numstalls, 0)} / ${numberWithCommasDecimals(
              motorReport?.stallPressure,
              2,
              true,
            )}`,
          },
          { text: 'Bit Grade Out', isBold: true, textAlign: 'right' },
          { text: bitData?.gradeOut },
        ],
        [
          { text: `Rot ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorReport.footageAndHours.rotateRop, 2) },
          { text: 'Stator Make/Type/Fit', isBold: true, textAlign: 'right' },
          {
            text: motorData?.statorBendor
              ? `${motorData?.statorVendor} / ${motorData?.statorType} / ${numberWithCommasDecimals(
                motorData?.statorFit,
                4,
              )}`
              : null,
          },
          { text: 'Drill/Circ Hrs', isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(bhaData.slideHours + bhaData.rotateHours, 2) + '/' + numberWithCommasDecimals(bhaData.circHours, 2) },
        ],
        [
          { text: `Avg ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`, isBold: true, textAlign: 'right' },
          { text: numberWithCommasDecimals(motorReport.footageAndHours.avgRop, 2) },
          { text: 'Pre-Run Dyno HP%', isBold: true, textAlign: 'right' },
          { text: motorData?.dynoHp },
          { text: 'IADC Code/#Blades', isBold: true, textAlign: 'right' },
          { text: bitData?.iadcCode + '/' + numberWithCommasDecimals(bitData?.numBlades, 0) },
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: [
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '4%',
        '9.5%',
        '9.5%',
        '9.5%',
        '9.5%',
        '9.5%',
        '9.5%',
        '9.5%',
        '9.5%',
      ],
      data: [
        [{ text: 'Mud Data', isHeader: true, columnSpan: 14 }],
        [
          { text: '600', isHeader: true },
          { text: '300', isHeader: true },
          { text: '200', isHeader: true },
          { text: '100', isHeader: true },
          { text: '6', isHeader: true },
          { text: '3', isHeader: true },
          { text: 'PV / YP', isHeader: true },
          { text: 'Oil/Water', isHeader: true },
          { text: 'Wt (ppg)', isHeader: true },
          { text: 'Chlorides', isHeader: true },
          { text: 'Type', isHeader: true },
          { text: '%Solids', isHeader: true },
          { text: '%Sand', isHeader: true },
          { text: `Temp (${getUnitsText(UNITS_FOR.Temperature)}))`, isHeader: true },
        ],
        [
          { text: numberWithCommasDecimals(motorReport.mudProperties.dialReadings.sixHundred, 0) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.dialReadings.threeHundred, 0) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.dialReadings.twoHundred, 0) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.dialReadings.onehundred, 0) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.dialReadings.six, 0) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.dialReadings.three, 0) },
          {
            text: `${numberWithCommasDecimals(motorReport.mudProperties.pv, 0)} / ${numberWithCommasDecimals(
              motorReport.mudProperties.yp,
              0
            )}`,
          },
          { text: motorReport.mudProperties.oilwaterRatio },
          { text: numberWithCommasDecimals(motorReport.mudProperties.mudWeight, 2) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.chlorides, 2) },
          { text: motorReport.mudProperties.description },
          { text: numberWithCommasDecimals(motorReport.mudProperties.solids, 2) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.sand, 2) },
          { text: numberWithCommasDecimals(motorReport.mudProperties.fluidTemperature, 2) },
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: ['15%', '15%', '15%', '15%', '20%', '20%'],
      data: [
        [{ text: 'Sensor Offsets / Directional Performance', isHeader: true, columnSpan: 6 }],
        [
          { text: `Svy (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Gam (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Res (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `PWD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Dens (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Neu (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        ],
        [
          { text: numberWithCommasDecimals(mwdData?.surveyOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.gammaOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.resOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.pwdOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.densOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.neutronOffset, 2,) },
        ],
        [
          { text: `Sonic (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `NB Inc (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `NB Azi (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Gyro (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
          { text: `Plan DLS (${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true },
          { text: `Act/Max DLS (${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true },
        ],
        [
          { text: numberWithCommasDecimals(mwdData?.sonicOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.nbIncOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.nbAziOffset, 2) },
          { text: numberWithCommasDecimals(mwdData?.gyroOffset, 2) },
          { text: numberWithCommasDecimals(motorReport.plannedDls, 2) },
          {
            text: `${numberWithCommasDecimals(motorReport.actualDls, 2,)} / ${numberWithCommasDecimals(
              motorReport.maxDls,
              2)}`,
          },
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: ['47%', '7%', '5%', '5%', '5%', '5%', '8%', '8%', '10%'],
      data: getMotorReportBhaDetails(bhaData?.bhaComponents, getUnitsText),
    },
    {
      alternatingColors: false,
      sectionAfter: motorReport.troubleshooting ? 5 : 0,
      data: motorReport.troubleshooting
        ? [[{ text: 'Troubleshooting', isHeader: true, }], [{ text: motorReport.troubleshooting, textAlign: 'left' }]]
        : null,
    },
    {
      alternatingColors: false,
      sectionAfter: motorReport.shopFindings ? 5 : 0,
      data: motorReport.shopFindings
        ? [[{ text: 'Shop Findings', isHeader: true }], [{ text: motorReport.shopFindings, textAlign: 'left' }]]
        : null,
    },
    {
      alternatingColors: false,
      sectionAfter: motorReport.evaluation ? 5 : 0,
      data: motorReport.evaluation
        ? [[{ text: 'Evaluation', isHeader: true }], [{ text: motorReport.evaluation, textAlign: 'left' }]]
        : null,
    },
    {
      alternatingColors: false,
      sectionAfter: motorReport.failureInfo ? 5 : 0,
      data: motorReport.failureInfo
        ? [[{ text: 'Failure Info', isHeader: true }], [{ text: motorReport.failureInfo, textAlign: 'left' }]]
        : null,
    },
    {
      alternatingColors: false,
      sectionAfter: motorReport.comments ? 5 : 0,
      data: motorReport.comments ? [[{ text: 'Comments', isHeader: true }], [{ text: motorReport.comments, textAlign: 'left' }]] : null,
    },
    {
      alternatingColors: false, sectionAfter: motorReport.troubleshooting ? 5 : 0,
      pageBreakAfter: motorImages ? true : false,
      data: motorReport.additionalComments
        ? [[{ text: 'Additional Comments', isHeader: true }], [{ text: motorReport.additionalComments, textAlign: 'left' }]]
        : null,
    },
  ]

  let motorImageData = GetMotorImagesTable(motorImages, bhaData)
  if (motorImageData) docData.push(motorImageData)

  return docData
}

const getMinMaxAvg = (motorReport, param, decPl = 2) => {
  if (!motorReport) return ''
  if (typeof param !== 'string') return ''
  if (!motorReport.hasOwnProperty('drillingParameters')) return ''
  if (!motorReport.drillingParameters.hasOwnProperty('minMaxAvg')) return ''
  if (!motorReport.drillingParameters.minMaxAvg.hasOwnProperty(param)) return ''
  const { min, max, avg } = motorReport.drillingParameters.minMaxAvg[param]
  return `${numberWithCommasDecimals(min < 999999.25 ? min : 0, decPl)} / ${numberWithCommasDecimals(avg, decPl)} / ${numberWithCommasDecimals(max > -999999.25 ? max : 0, decPl)}`
}

const GetMotorImagesTable = (motorImages, bhaData) => {
  if (!Array.isArray(motorImages) || motorImages.length === 0 || !bhaData) return null

  let images = motorImages.filter((img) => parseInt(img?.additionalInfo) === bhaData.bhaNum)
  if (images.length === 0) return null

  let motorImageData =
  {
    sectionAfter: 5,
    manualWidth: true,
    columnWidths: ['50%', '50%'],
    data: [[{ text: 'BHA Images', isHeader: true, width: '100%' }]],
  }


  let curImageData = []
  for (let i = 0; i < images.length; i++) {
    curImageData.push({ text: images[i].data, isImage: true, height: 200 })

    if (curImageData.length === 2) {
      motorImageData.data.push(curImageData)
      curImageData = []
    }
  }

  if (curImageData.length > 0) {
    curImageData.push({ text: '' })
    motorImageData.data.push(curImageData)
  }

  return motorImageData
}


const generateBhaData = (bhaData, bhaImages, getUnitsText) => {
  let bhaDoc = [
    [
      { text: '', isHeader: true },
      { text: '#', isHeader: true },
      { text: 'SN', isHeader: true },
      { text: 'Description', isHeader: true },
      { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `FN OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `FN (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      { text: 'Cnx Up', isHeader: true },
      { text: 'Cnx Dn', isHeader: true },
      { text: `Unit Wt (lbs/ft)`, isHeader: true },
      { text: `Comp Wt (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
      { text: `Total Wt  (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
      { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
    ],
  ]

  let totalCompWeight = 0

  for (let i = 0; i < bhaData.length; i++) {
    totalCompWeight = totalCompWeight + bhaData[i].weight * bhaData[i].length

    let image = bhaImages.getPrintImage(bhaData[i], false)

    bhaDoc.push([
      {
        text: image,
        isImage: true,
        height: 31,
        rotate: false,
      },
      { text: bhaData[i].sequenceNo + 1 },
      { text: bhaData[i].sn },
      { text: bhaData[i].description.replace('&dq;', '"') },
      { text: numberWithCommasDecimals(bhaData[i].od, 3) },
      { text: numberWithCommasDecimals(bhaData[i].id, 3) },
      { text: numberWithCommasDecimals(bhaData[i].fishNeckOd, 3) },
      { text: numberWithCommasDecimals(bhaData[i].fishNeck, 2) },
      { text: bhaData[i].cnxTop },
      { text: bhaData[i].cnxBtm },
      { text: numberWithCommasDecimals(bhaData[i].weight, 3) },
      { text: numberWithCommasDecimals((bhaData[i].weight * bhaData[i].length) / 1000, 2) },
      { text: numberWithCommasDecimals(totalCompWeight / 1000, 2) },
      { text: numberWithCommasDecimals(bhaData[i].length, 2) },
      { text: numberWithCommasDecimals(bhaData[i].cumulativeLength, 2) },
    ])
  }
  return bhaDoc
}

const getStabilizerData = (bhaData, stabilizerData, getUnitsText) => {
  let stabilizerHeaderData = [
    [{ text: 'Stabilizer Data', isHeader: true, columnSpan: 7 }],
    [
      { text: 'Component Number', isHeader: true },
      { text: 'Description', isHeader: true },
      { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Blade Length (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Blade Width (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: 'Blade Count', isHeader: true },
      { text: `Stab->Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
    ],
  ]

  for (let i = 0; i < stabilizerData.length; i++) {
    stabilizerHeaderData.push([
      { text: stabilizerData[i].sequenceNo + 1 },
      { text: stabilizerData[i].description.replace('&dq;', '"') },
      { text: numberWithCommasDecimals(stabilizerData[i].stabOd, 3) },
      { text: numberWithCommasDecimals(stabilizerData[i].stabBl, 3) },
      { text: numberWithCommasDecimals(stabilizerData[i].stabBw, 3) },
      { text: numberWithCommasDecimals(stabilizerData[i].stabBc, 2) },
      { text: numberWithCommasDecimals(getStabToBit(bhaData, stabilizerData, i), 2) },
    ])
  }

  return stabilizerHeaderData
}

const getMotorReportBhaDetails = (bhaData, getUnitsText) => {
  let data = [
    [{ text: 'BHA Details', isHeader: true, columnSpan: 9 }],
    [
      { text: 'Description', isHeader: true },
      { text: 'SN', isHeader: true },
      { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `TJ OD(${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `TJ ID(${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Weight (lbs/ft)`, isHeader: true },
      { text: `Length (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
    ],
  ]
  if (!Array.isArray(bhaData)) return data

  for (let i = 0; i < bhaData.length; i++) {
    data.push([
      { text: bhaData[i]?.description },
      { text: bhaData[i]?.sn },
      { text: numberWithCommasDecimals(bhaData[i]?.od, 3) },
      { text: numberWithCommasDecimals(bhaData[i]?.id, 3) },
      { text: numberWithCommasDecimals(bhaData[i]?.tjOd, 3) },
      { text: numberWithCommasDecimals(bhaData[i]?.tjId, 3) },
      { text: numberWithCommasDecimals(bhaData[i]?.weight, 2) },
      { text: numberWithCommasDecimals(bhaData[i]?.length, 2) },
      { text: numberWithCommasDecimals(bhaData[i]?.cumulativeLength, 2) },
    ])
  }

  return data
}

export const generateBhaPdfDocument = (bhaNum,
  bhaData,
  bhaImages,
  drillStringData,
  getCurrentOrgIcon,
  wellInfoRef,
  getUnitsText) => {
  if (!Array.isArray(bhaData)) return null
  if (bhaData.length === 0) return null
  if (!Array.isArray(drillStringData.current)) return null
  if (drillStringData.current.length === 0) return null

  let bhaIndex = drillStringData.current.findIndex((bha) => {
    return bha.bhaNum === bhaNum
  })

  if (bhaIndex < 0) return null

  let motorData = {}
  let bitData = {}
  let offsetData = {}
  let mwdProbeOrber = []
  let jarData = {}
  let stabilizerData = []

  bhaData.forEach((component) => {
    if (component.type === 'Motor') motorData = component
    if (component.type === 'Drill Bit') bitData = component
    if (component.type === 'MWD/LWD') {
      offsetData = component
      if (Array.isArray(component.mwdOrder) && component.mwdOrder.length > mwdProbeOrber) {
        mwdProbeOrber = component.mwdOrder
      }
    }
    if (component.type === 'Jar') jarData = component
    if (component.type === 'Stabilizer' || component.stabOd > 0) stabilizerData.push(component)
  })

  let bhaTotalWeight = getBhaTotalWeight(bhaData)
  let jarsDryWt = getJarsDryWt(bhaData)

  let docData = [
    {
      showTitle: true,
      title: `${drillStringData.current[bhaIndex].bhaNum}: ${drillStringData.current[bhaIndex].bhaDescription}`,
      showLogo: true,
      logo: getCurrentOrgIcon(),
      logoWidth: '25%',
      logoHeight: 80,
      manualWidth: true,
      columnWidths: ['10%', '40%', '10%', '10%', '20%', '10%'],
      data: [
        [
          { text: 'Job#', isBold: true },
          { text: wellInfoRef.current.jobNum },
          { text: 'Rig', isBold: true },
          { text: wellInfoRef.current.rig },
          { text: `BHA Length (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(bhaData[bhaData.length - 1].cumulativeLength, 2) },
        ],
        [
          { text: 'Operator', isBold: true },
          { text: wellInfoRef.current.operator },
          { text: 'BHA #', isBold: true },
          { text: drillStringData.current[bhaIndex].bhaNumRep },
          { text: `BHA Weight dry (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true },
          { text: numberWithCommasDecimals(bhaTotalWeight / 1000, 2) },
        ],
        [
          { text: 'Well', isBold: true },
          { text: wellInfoRef.current.wellName },
          { text: 'Bit #', isBold: true },
          { text: bitData?.bitNum },
          { text: `BHA Weight Bouyed (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true },
          {
            text: numberWithCommasDecimals(
              (((65.5 - drillStringData.current[bhaIndex].mudWeight) / 65.5) * bhaTotalWeight) / 1000,
              2,
            ),
          },
        ],
        [
          { text: 'Field', isBold: true },
          { text: wellInfoRef.current.field },
          { text: `Depth In (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(drillStringData.current[bhaIndex].depthIn, 2) },
          { text: `Wt. Below Jars dry (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true },
          { text: jarsDryWt ? numberWithCommasDecimals(jarsDryWt, 2) : '' },
        ],
        [
          { text: 'Date In', isBold: true },
          {
            text: new Date(Date.parse(drillStringData.current[bhaIndex].dateIn)).toLocaleDateString('en-US', {
              dateStyle: 'short',
            }),
          },
          { text: `Depth Out(${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(drillStringData.current[bhaIndex].depthOut, 2) },
          { text: `Wt. Below Jars Bouyed (${getUnitsText(UNITS_FOR.Weight)})`, isBold: true },
          {
            text: jarsDryWt
              ? numberWithCommasDecimals(((65.5 - drillStringData.current[bhaIndex].mudWeight) / 65.5) * jarsDryWt, 2)
              : '',
          },
        ],
        [
          { text: 'Date Out', isBold: true },
          {
            text: new Date(Date.parse(drillStringData.current[bhaIndex].dateOut)).toLocaleDateString('en-US', {
              dateStyle: 'short',
            }),
          },
          { text: `Drilled(${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(drillStringData.current[bhaIndex].totalDrilled, 2) },
          { text: 'Drilling / Circ Hours', isBold: true },
          {
            text: `${numberWithCommasDecimals(drillStringData.current[bhaIndex].totalDrillingHours, 2)} / ${numberWithCommasDecimals(
              drillStringData.current[bhaIndex].circHours,
              2,
            )}`,
          },
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: ['20%', '14%', '20%', '14%', '20%', '12%'],
      data: [
        [{ text: 'Sensor Offsets', isHeader: true, columnSpan: 6 }],
        [
          { text: 'Survey Offset' },
          { text: offsetData?.surveyOffset ? offsetData.surveyOffset : 'N/A' },
          { text: 'Gamma Offset' },
          { text: offsetData?.gammaOffset ? offsetData.gammaOffset : 'N/A' },
          { text: 'Gyro Offset' },
          { text: offsetData?.gyroOffset ? offsetData.gyroOffset : 'N/A' },
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: ['3%', '2%', '8%', '23%', '4%', '4%', '6%', '8%', '8%', '8%', '5%', '5%', '5%', '6%', '5%'],
      data: generateBhaData(bhaData, bhaImages, getUnitsText),
    },
    {
      sectionAfter: 5,
      data: [
        [{ text: 'Comments', isHeader: true }],
        [{ text: drillStringData.current[bhaIndex].comments, textAlign: 'left' }],
      ],
    },
    {
      sectionAfter: 5,
      data: [
        [
          { text: 'Bit Data', isHeader: true, columnSpan: 2 },
          { text: 'Motor Data', isHeader: true, columnSpan: 2 },
          jarData ? { text: 'Jar Data', isHeader: true, columnSpan: 2 } : null,
        ],
        [
          { text: 'SN', isBold: true },
          { text: bitData?.sn },
          { text: 'SN', isBold: true },
          { text: motorData?.sn },
          jarData ? { text: 'SN' } : null,
          jarData ? { text: jarData.sn } : null,
        ],
        [
          { text: `Size (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true },
          { text: numberWithCommasDecimals(bitData?.od, 3) },
          { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true },
          { text: numberWithCommasDecimals(motorData?.od, 3) },
          jarData ? { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})` } : null,
          jarData ? { text: numberWithCommasDecimals(jarData.od, 3) } : null,
        ],
        [
          { text: 'Type', isBold: true },
          { text: bitData?.bitType },
          { text: 'Description', isBold: true },
          { text: motorData?.description?.replace('&dq;', '"') },
          jarData ? { text: 'Description' } : null,
          jarData ? { text: jarData?.description?.replace('&dq;', '"') } : null,
        ],
        [
          { text: 'Description', isBold: true },
          { text: bitData?.description },
          { text: 'Make/Model', isBold: true },
          { text: motorData?.make && motorData?.model ? motorData?.make + ' / ' + motorData?.model : '/' },
          jarData ? { text: 'Make' } : null,
          jarData ? { text: jarData.make } : null,
        ],
        [
          { text: 'Make', isBold: true },
          { text: bitData?.make },
          { text: `Bit to Bend (${getUnitsText(UNITS_FOR.Depth)}) / Angle`, isBold: true },
          { text: numberWithCommasDecimals(motorData?.bit2bend, 2) + ' / ' + numberWithCommasDecimals(motorData?.bendAngle, 2) },
          jarData ? { text: 'Model' } : null,
          jarData ? { text: jarData.model } : null,
        ],
        [
          { text: 'Model', isBold: true },
          { text: bitData?.model },
          { text: `Stab / Kick Pad OD (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true },
          {
            text: `${numberWithCommasDecimals(motorData?.stabOd, 3)} / ${numberWithCommasDecimals(motorData?.kickPadOd, 3)}`,
          },
          jarData ? { text: `Latch Up (${getUnitsText(UNITS_FOR.Weight)})` } : null,
          jarData ? { text: numberWithCommasDecimals(jarData.latchUp, 2) } : null,
        ],
        [
          { text: 'TFA', isBold: true },
          { text: bitData?.tfa },
          { text: 'Stator Vendor/Type/Fit', isBold: true },
          {
            text: getStatorData(motorData),
          },
          jarData ? { text: `Latch Down (${getUnitsText(UNITS_FOR.Weight)})` } : null,
          jarData ? { text: numberWithCommasDecimals(jarData.latchDown, 2) } : null,
        ],
        [
          { text: 'Grade In', isBold: true },
          { text: bitData?.gradeIn },
          { text: 'Pre Run Dyno HP%', isBold: true },
          { text: motorData?.dynoHp },
          jarData ? { text: `Impulse (${getUnitsText(UNITS_FOR.Weight)})` } : null,
          jarData ? { text: numberWithCommasDecimals(jarData.impulse, 2) } : null,
        ],
        [
          { text: 'Grade Out', isBold: true },
          { text: bitData?.gradeOut },
          { text: 'Lobes', isBold: true },
          { text: motorData?.motorLobes },
          jarData ? { text: 'Cnx Top' } : null,
          jarData ? { text: jarData.cnxTop } : null,
        ],
        [
          { text: `Drilled (${getUnitsText(UNITS_FOR.Depth)})`, isBold: true },
          { text: numberWithCommasDecimals(drillStringData.current[bhaIndex].totalDrilled, 2) },
          { text: 'Stages', isBold: true },
          { text: motorData?.motorStages },
          jarData ? { text: 'Cnx Btm' } : null,
          jarData ? { text: jarData.cnxBtm } : null,
        ],
        [
          { text: '#Blades', isBold: true },
          { text: bitData?.numBlades },
          { text: 'Rev/Gal', isBold: true },
          { text: motorData?.revPerGal },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: 'IADC Code', isBold: true },
          { text: bitData?.iadcCode },
          { text: `Diff Press.(${getUnitsText(UNITS_FOR.Pressure)})`, isBold: true },
          { text: numberWithCommasDecimals(motorData?.diffPress, 2) },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: 'K Revs', isBold: true },
          { text: numberWithCommasDecimals(drillStringData.current[bhaIndex].krevs, 0) },
          { text: `Press. Drop(${getUnitsText(UNITS_FOR.Pressure)})`, isBold: true },
          { text: numberWithCommasDecimals(motorData?.pressureDrop, 1) },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: '', isBold: true },
          { text: '' },
          { text: `Max Torque(${getUnitsText(UNITS_FOR.Torque)})`, isBold: true },
          { text: numberWithCommasDecimals(motorData?.maxTorque, 1) },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: '', isBold: true },
          { text: '' },
          { text: 'Max RPM', isBold: true },
          { text: motorData?.maxRpm },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: '', isBold: true },
          { text: '' },
          { text: `Flow Range(${getUnitsText(UNITS_FOR.FlowRate)})`, isBold: true },
          { text: motorData?.flowRange },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: '', isBold: true },
          { text: '' },
          { text: 'Re-Run', isBold: true },
          { text: motorData?.reRun },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
        [
          { text: '', isBold: true },
          { text: '' },
          { text: 'Direct Bill', isBold: true },
          { text: motorData?.directBill },
          jarData ? { text: '' } : null,
          jarData ? { text: '' } : null,
        ],
      ],
    },
    {
      sectionAfter: 5,
      manualWidth: true,
      columnWidths: ['13.5%', '11.5%', '13.5%', '11.5%', '12.5%', '11.5%', '13.5%', '12.5%'],
      data: [
        [{ text: 'MWD Data', isHeader: true, columnSpan: 8 }],
        [
          { text: 'MWD Type', isBold: true },
          { text: offsetData?.mwdType },
          { text: 'Pulser SN', isBold: true },
          { text: offsetData?.pulserSn },
          { text: 'Gamma SN', isBold: true },
          { text: offsetData?.gammaSn },
          { text: 'Plug In Date', isBold: true },
          {
            text: new Date(Date.parse(offsetData?.battPlugInDate)).toLocaleDateString('en-US', {
              dateStyle: 'short',
            }),
          },
        ],
        [
          { text: 'Orifice', isBold: true },
          { text: offsetData?.orifice },
          { text: 'Driver SN', isBold: true },
          { text: offsetData?.pulserDriverSn },
          { text: 'EM SN', isBold: true },
          { text: offsetData?.emSn },
          { text: 'Plug In Time', isBold: true },
          { text: offsetData?.battPlugInTime },
        ],
        [
          { text: 'Orifice Type', isBold: true },
          { text: offsetData?.mwdOrificeType },
          { text: 'Dir. Module SN', isBold: true },
          { text: offsetData?.directionalSn },
          { text: 'Pulse Width', isBold: true },
          { text: offsetData?.mwdPulseWidth ? numberWithCommasDecimals(parseFloat(offsetData?.mwdPulseWidth), 2) : '' },
          { text: 'Plug Out Date', isBold: true },
          {
            text: new Date(Date.parse(offsetData?.battPlugOutDate)).toLocaleDateString('en-US', {
              dateStyle: 'short',
            }),
          },
        ],
        [
          { text: 'Poppet', isBold: true },
          { text: offsetData?.poppet },
          { text: 'Battery 1 SN', isBold: true },
          { text: offsetData?.battery1Sn },
          { text: 'Data Rate', isBold: true },
          { text: offsetData?.mwdDataRate ? numberWithCommasDecimals(parseFloat(offsetData?.mwdDataRate), 2) : '' },
          { text: 'Plug Out Time', isBold: true },
          { text: offsetData?.battPlugOutTime },
        ],
        [
          { text: 'Make', isBold: true },
          { text: offsetData?.make },
          { text: 'Battery 2 SN', isBold: true },
          { text: offsetData?.battery2Sn },
          { text: 'EM Power', isBold: true },
          { text: offsetData?.emPower ? numberWithCommasDecimals(parseFloat(offsetData?.emPower), 2) : '' },
          { text: 'Plug In Hrs', isBold: true },
          { text: getPlugInHrs(offsetData) },
        ],
        [
          { text: 'Model', isBold: true },
          { text: offsetData?.model },
          { text: 'Battery 3 SN', isBold: true },
          { text: offsetData?.battery3Sn },
          { text: 'EM Freq', isBold: true },
          { text: offsetData?.emFreq ? numberWithCommasDecimals(parseFloat(offsetData?.emFreq), 2) : '' },
          { text: 'Shock Tool SN', isBold: true },
          { text: offsetData.shockToolSn },
        ],
        [
          { text: `Fin Size (${getUnitsText(UNITS_FOR.Diameter)})`, isBold: true },
          { text: offsetData?.mwdFinSize },
          { text: 'Helix SN', isBold: true },
          { text: offsetData?.helixSn },
          { text: 'Retrievable', isBold: true },
          { text: offsetData?.mwdRetre },
          { text: '', isBold: true },
          { text: '' },
        ],
      ],
    },
  ]

  if (mwdProbeOrber.length > 0) {
    docData.push({
      sectionAfter: 5,
      data: [
        [{ text: 'MWD Probe Order', isHeader: true }],
        [{ text: mwdProbeOrber.join('|'), textAlign: 'left' }],
      ],
    })
  }

  if (stabilizerData && Array.isArray(stabilizerData) && stabilizerData.length > 0) {
    docData.push({
      sectionAfter: 5,
      data: getStabilizerData(bhaData, stabilizerData, getUnitsText),
    })
  }

  return docData
}


