import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'
import { cloneDeep } from 'lodash'
import StylesGrid from './StylesGrid'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { gridDataToStyles } from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/propertyUtils'
import ImageGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ImageGrid'
import ChartOptionsGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartOptionsGrid'
import ChartScalesGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartScalesGrid'
import ChartSeriesStyleGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartSeriesStyleGrid'
import ChartAxesGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartAxesGrid'
import ChartLabelsGrid from './ChartLabelsGrid'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const STYLE_TAB = 'style'
const CONTEXT_TAB = 'context'

const ElementPropertyModal = ({ onClose, onApply, elementStyle, contextData, elemType, elemSubType }) => {
  const gridRef = React.useRef(null)
  const dataRef = React.useRef({ style: cloneDeep(elementStyle), context: cloneDeep(contextData) })
  const [tabSelection, setTabSelection] = React.useState(STYLE_TAB)
  const { theme } = useInnovaTheme()

  const handleUpdate = (updatedData, sourceType) => {
    if (!updatedData) return
    switch (sourceType) {
      case elementType.text:
        dataRef.current = cloneDeep(updatedData)
        break
      case elementType.image:
        break
      default:
        break
    }
  }

  const getGridData = () => {
    let data = {}

    // get the data from the current active grid
    getTabGridData()

    // perform any conversions needed
    data[STYLE_TAB] = gridDataToStyles(dataRef.current[STYLE_TAB])
    data[CONTEXT_TAB] = dataRef.current[CONTEXT_TAB]

    return data
  }

  const getTabGridData = () => {
    let gridData = []

    if (gridRef.current) gridData = gridRef.current.getData()
    let data = {}
    for (let i = 0; i < gridData.length; i++) {
      if (gridData[i].hasOwnProperty('tag') && gridData[i].hasOwnProperty('value')) {
        data[gridData[i].tag] = gridData[i].value
      }
    }
    if (gridData.hasOwnProperty('series')) {
      data.series = [...gridData.series]
    }
    if (gridData.hasOwnProperty('labels')) {
      data.labels = gridData.labels
    }
    // this isn't ideal--all non-style data is stored in the 'context' property, despite the tabSelection
    dataRef.current[tabSelection === STYLE_TAB ? STYLE_TAB : CONTEXT_TAB] = {
      ...dataRef.current[tabSelection === STYLE_TAB ? STYLE_TAB : CONTEXT_TAB],
      ...data,
    }
  }

  const TabHeader = ({ tabData }) => {
    if (!tabData) return null
    if (!tabData.visible) return null
    const backColorActive = theme === 'dark' ? '#FFFFFF' : '#222222'
    const colorActive = theme === 'dark' ? '#181d1f' : '#FFFFFF'
    const backColor = theme === 'dark' ? '#181d1f' : '#DDDDDD'
    const color = theme === 'dark' ? '#FFFFFF' : '#181d1f'

    return (
      <Box
        sx={{
          maxWidth: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '5px',
          paddingRight: '10px',
          fontWeight: tabSelection === tabData.tag ? 'bold' : 'normal',
          backgroundColor: tabSelection === tabData.tag ? backColorActive : backColor,
          color: tabSelection === tabData.tag ? colorActive : color,
          '&:hover': {
            cursor: 'pointer',
          },
        }}
        onClick={tabData.onClick}>
        {tabData.label}
      </Box>
    )
  }

  const handleTabClick = (selectedTab) => {
    gridRef.current.stopEditing()
    getTabGridData()
    setTabSelection(selectedTab)
  }

  const getTabs = (type) => {
    let tabs = [
      {
        label: 'Style',
        onClick: () => {
          handleTabClick(STYLE_TAB)
        },
        tag: STYLE_TAB,
        visible: true,
      },
    ]
    if (type === elementType.image) {
      tabs.push({
        label: 'Image',
        onClick: () => {
          handleTabClick(CONTEXT_TAB)
        },
        tag: CONTEXT_TAB,
        visible: contextData ? true : false,
      })
    }
    if (type === elementType.chart) {
      tabs.push({
        label: 'Options',
        onClick: () => {
          handleTabClick('options')
        },
        tag: 'options', // CONTEXT_TAB,
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Scales',
        onClick: () => {
          handleTabClick('scales')
        },
        tag: 'scales',
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Series',
        onClick: () => {
          handleTabClick('series')
        },
        tag: 'series',
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Axes',
        onClick: () => {
          handleTabClick('axes')
        },
        tag: 'axes',
        visible: contextData ? true : false,
      })
      tabs.push({
        label: 'Labels',
        onClick: () => {
          handleTabClick('labels')
        },
        tag: 'labels',
        visible: contextData ? true : false,
      })
    }

    return tabs
  }

  const getContextTabGrid = (type) => {
    switch (type) {
      case elementType.text:
        return null
      case elementType.image:
        return <ImageGrid imageData={dataRef.current.context} handleUpdate={handleUpdate} ref={gridRef} />
      default:
        return null
    }
  }

  const ActiveTab = ({ activeTab }) => {
    if (!activeTab) return null
    if (activeTab === STYLE_TAB)
      return <StylesGrid elementStyle={elementStyle} handleUpdate={handleUpdate} ref={gridRef} />
    if (activeTab === CONTEXT_TAB) return getContextTabGrid(elemType)
    if (activeTab === 'options')
      return (
        <ChartOptionsGrid
          chartData={dataRef.current.context}
          handleUpdate={handleUpdate}
          ref={gridRef}
          chartType={elemSubType}
        />
      )
    if (activeTab === 'scales')
      return <ChartScalesGrid chartData={dataRef.current.context} handleUpdate={handleUpdate} ref={gridRef} />
    if (activeTab === 'series')
      return (
        <ChartSeriesStyleGrid
          chartData={dataRef.current.context}
          handleUpdate={handleUpdate}
          ref={gridRef}
          chartType={elemSubType}
        />
      )
    if (activeTab === 'axes') return <ChartAxesGrid chartData={dataRef.current.context} ref={gridRef} />
    if (activeTab === 'labels')
      return <ChartLabelsGrid chartData={dataRef.current.context} ref={gridRef} chartType={elemSubType} />
    return null
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '400px',
          maxWidth: '800px',
          width: '60vw',
          minHeight: '600px',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Properties</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            height: '32px',
            width: '100%',
          }}>
          {getTabs(elemType).map((tab, index) => (
            <TabHeader key={index} tabData={tab} />
          ))}
        </Box>
        <ActiveTab activeTab={tabSelection} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={() => {
            // ensure color picker change is applied
            gridRef.current.stopEditing()
            onApply(getGridData())
          }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ElementPropertyModal
