import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useInterval from 'components/common/hooks/useInterval'
import { Box, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'

const RunningQueriesCard = () => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const [runningQueries, setRunningQueries] = useState([])
  const isLoadingRef = useRef(false)

  const getRunningQueries = useInnovaAxios({
    url: '/admin/getCurrentRunningQueries',
  })

  const killQuery = useInnovaAxios({
    url: '/admin/killQueriesById',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.sessionId)
    }
  }, [])

  const fetchCurrentRunningQueries = useCallback( async () => {
    if (!_isMounted.current) return
    if (isLoadingRef.current) return
    isLoadingRef.current = true

    const res = await getRunningQueries()
    if (!_isMounted.current) return
    isLoadingRef.current = false

    if (res?.error) {
      return
    }

    setRunningQueries(res.data)
  }, [getRunningQueries])

  const handleKillQuery = useCallback(async (queryIds) => {
    if (!queryIds) return
    if (!Array.isArray(queryIds)) return
    if (queryIds.length === 0) return
    if (!_isMounted.current) return
    if (isLoadingRef.current) return
    isLoadingRef.current = true

    const res = await killQuery({ queries: JSON.stringify(queryIds) })

    if (!_isMounted.current) return
    isLoadingRef.current = false

    if (res?.error) {
      return
    }

    if (!res?.error) {
        fetchCurrentRunningQueries()
    }
  }, [killQuery, fetchCurrentRunningQueries])

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchCurrentRunningQueries()
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('runningQueriesGrid', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('runningQueriesGrid', 'colLayout', [])
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const actionIconRenderer = useCallback((params) => {
    return (
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <IconButton
          style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => handleKillQuery([params.data])}
          size='large'>
          <Iconify color={'red'} icon={'fa-regular:trash-alt'} fontSize={16} />
        </IconButton>
      </Box>
    )
  }, [handleKillQuery])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        cellStyle: centerAlignCell,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'sessionId',
        colId: 'sessionId',
        headerName: 'Session ID',
        cellStyle: leftAlignCell,
        minWidth: 100,
      },
      {
        field: 'elapsedMinutes',
        colId: 'elapsedMinutes',
        headerName: 'Elapsed Minutes',
        cellStyle: centerAlignCell,
        minWidth: 100,
      },
      {
        field: 'cpuElapsedTime',
        colId: 'cpuElapsedTime',
        headerName: 'CPU Elapsed Time',
        cellStyle: centerAlignCell,
        minWidth: 100,
      },
      {
        field: 'programName',
        colId: 'programName',
        headerName: 'Program Name',
        cellStyle: centerAlignCell,
        minWidth: 200,
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
        cellStyle: centerAlignCell,
        minWidth: 200,
      },
      {
        field: 'stateText',
        colId: 'stateText',
        headerName: 'Query',
        cellStyle: leftAlignCell,
        minWidth: 200,
      },
    ],
    [centerAlignCell, leftAlignCell, actionIconRenderer],
  )

  useInterval(() => {
    if (!isLoadingRef.current) {
      fetchCurrentRunningQueries()
    }
  }, 30000)

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={runningQueries}
        columnDefs={sortColDefs(columnDefs, 'runningQueriesGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getRowNodeId={getRowId}
        gridOptions={gridOptions}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default RunningQueriesCard
