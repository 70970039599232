import React, { useRef, useEffect, useState, useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button, Snackbar, Alert } from '@mui/material'
import { Box } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { AgGridReact } from 'ag-grid-react'
import { CustomLoadingOverlay } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ObjectValidationTemplatesModal = ({ onClose }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme } = useInnovaTheme()

  const getTemplates = useInnovaAxios({
    url: '/objectQc/getObjectQcTemplates',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const gridOptions = {
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getContextMenuItems = (params) => {
    return ['copy']
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  const fetchTemplates = async () => {
    if (isLoading) return
    setLoading(true)

    const res = await getTemplates()
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!Array.isArray(res?.data)) return
    if (!gridApi.current) return
    gridApi.current.setGridOption('rowData', res.data)

    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchTemplates()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  let columnDefs = useMemo(
    () => [
      {
        checkboxSelection: true,
        field: 'description',
        colId: 'description',
        headerName: 'Description',
      },
    ],
    [],
  )

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    if (!_isMounted.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (!Array.isArray(selectedRows)) {
      setSelectedRow(null)
      return
    }

    let newSelection = []

    for (let i = 0; i < selectedRows.length; i++) {
      newSelection.push({ ...selectedRows[i].data })
    }

    if (newSelection.length === 0) {
      setSelectedRow(null)
      return
    }

    setSelectedRow(newSelection[0])
  }

  return (
    <Dialog
      maxWidth='sm'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '50vw',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Object QC Templates`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              loading={isLoading}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              rowSelection={'single'}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </Box>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        {selectedRow ? (
          <Button variant='outlined' onClick={() => onClose(selectedRow)} color='primary'>
            Create
          </Button>
        ) : null}
        <Button variant='outlined' onClick={() => onClose(null)} color='secondary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ObjectValidationTemplatesModal
