import { useEffect, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'

function useDrillStringDropDowns(wellName = '') {
  const _isMounted = useRef(true)
  const dropDownData = useRef()
  const isLoading = useRef(false)

  const getDropDowns = useInnovaAxios({
    url: '/well/drillString/getDropDownLists',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDowns = async () => {
    if (isLoading.current) return
    const res = await getDropDowns({ wellName: wellName })
    isLoading.current = false
    if (res?.error) return
    if (!res?.data) return

    dropDownData.current = res.data
  }

  const getCompTypesList = () => {
    if (isLoading.current) return []
    if (!dropDownData.current) return []
    if (!dropDownData.current.hasOwnProperty('toolTypes')) return []
    if (!Array.isArray(dropDownData.current.toolTypes)) return []

    let toolTypes = []
    for (let i = 0; i < dropDownData.current.toolTypes.length; i++) {
      if (dropDownData.current.toolTypes[i].enum >= 17 && dropDownData.current.toolTypes[i].enum <= 37) continue
      if (dropDownData.current.toolTypes[i].enum < 0) continue
      toolTypes.push(dropDownData.current.toolTypes[i].description)
    }

    return toolTypes
  }

  const getToolTypeEnumFromDesc = (desc) => {
    if (isLoading.current) return -1
    if (!dropDownData.current) return -1
    if (!dropDownData.current.hasOwnProperty('toolTypes')) return -1
    if (!Array.isArray(dropDownData.current.toolTypes)) return -1
    if (!desc) return -1
    if (typeof desc !== 'string') return -1

    let tool = dropDownData.current.toolTypes.find((type) => type.description === desc)
    if (!tool) return -1
    return tool.enum
  }

  const hasToolJoint = (typeDesc) => {
    if (isLoading.current) return false
    if (!dropDownData.current) return false
    if (!dropDownData.current.hasOwnProperty('fieldMasks')) return false
    if (!Array.isArray(dropDownData.current.fieldMasks)) return false
    if (!typeDesc) return false
    if (typeof typeDesc !== 'string') return false

    let tool = dropDownData.current.fieldMasks.find((type) => type.typeDescription === typeDesc)
    if (!tool) return false

    if (!Array.isArray(tool.columnMasks)) return false
    return tool.columnMasks.findIndex((mask) => mask === 'tjOd') >= 0
  }

  let uiColMasks = [
    {
      field: 'sn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'cnxTop',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'cnxBtm',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'fishNeck',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'fishNeckOd',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'make',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'model',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'burst',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'collapse',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'tensileYield',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'torsionalYield',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'bitType',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'bitNum',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'tfa',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'gradeIn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gradeOut',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gaugeOd',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'formationIndex',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'bearingGap',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'bendAngle',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'bit2bend',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'dynoHp',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'kickPadOd',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'diffPress',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'housingType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'flowRange',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxDiffP',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxFlow',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxRpm',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxTorque',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'motorYield',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'motorBearing',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'motorLobes',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'motorStages',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'revPerGal',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'statorFit',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'statorType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'statorVendor',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'reRun',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'directBill',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rssForce',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'stabBc',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'stabBl',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'stabBw',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'stabDfb',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'stabOd',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'surveyOffset',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'gammaOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'resOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'fmtOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gyroOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'nbAziOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'nbIncOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'nbGammaOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'neutronOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'densOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'pwdOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'sonicOffset',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'classPipe',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'ffReduction',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'drillPipeRange',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'materialName',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'materialStrength',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'matGrade',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'floatTfa',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'bowspring',
      engineering: false,
      drillStringEditor: false,
    },

    {
      field: 'centSpacing',
      engineering: false,
      drillStringEditor: false,
    },
    {
      field: 'hasFloat',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'intFluidWt',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'impulse',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'latchDown',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'latchUp',
      engineering: true,
      drillStringEditor: false,
    },
    {
      field: 'battery1Sn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'battery2Sn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'battery3Sn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'batteryType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'battPlugInDate',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'battPlugOutDate',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'battPlugInTime',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'battPlugOutTime',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'dao',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'directionalSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'dirType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'dirVer',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'emFreq',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'emPower',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'emSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gammaType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gammaVer',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gammaCorFac',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'gammaSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'helixSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'helixType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxAxial',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxLateral',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdDataRate',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdFinSize',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdOrificeType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'orifice',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdPrimary',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdPulseWidth',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdRetre',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'pinToSetScrew',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'poppet',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'pressureDrop',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'pulserType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'pulserVer',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'shockToolSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'pulserDriverSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'pulserSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stickUp',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'bitJets',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'shockToolSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stopCollarQty',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'centralizerDesc',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'stopCollarDesc',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'inPosCalc',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'runningCalc',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'centSpacingSelection',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'casingMaterial',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'avgJointLength',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'mwdCollarType',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'mwdTelemType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rssType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rssOperatingFlowRate',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rssMaxDls',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'maxWob',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabWrapAngle',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabTaperAngle',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabTransitionRadius',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabFlowbyPass',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'statorCutBack',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'mwdControllerSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'babelFishSn',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'restrictorSize',
      engineering: true,
      drillStringEditor: true,
    },
    {
      field: 'boxPinTop',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'boxPinBtm',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rotorEccentricity',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabGaugeOut',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabFabricationType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'stabHardFacingType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'elastomerType',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rotorBaseMaterial',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'rotorCoating',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'hasRotorCatch',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'numBlades',
      engineering: false,
      drillStringEditor: true,
    },
    {
      field: 'iadcCode',
      engineering: false,
      drillStringEditor: true,
    },
  ]

  const getVisibleColumns = (typeDesc, isEngineering) => {
    if (isLoading.current) return []
    if (!dropDownData.current) return []
    if (!dropDownData.current.hasOwnProperty('fieldMasks')) return []
    if (!Array.isArray(dropDownData.current.fieldMasks)) return []
    if (!typeDesc) return []
    if (typeof typeDesc !== 'string') return []

    let tool = dropDownData.current.fieldMasks.find((type) => type.typeDescription === typeDesc)
    if (!tool) return []

    if (!Array.isArray(tool.columnMasks)) return []

    let visibleColumns = []
    for (let i = 0; i < tool.columnMasks.length; i++) {
      for (let j = 0; j < uiColMasks.length; j++) {
        if (uiColMasks[j].field !== tool.columnMasks[i]) continue
        if (isEngineering && uiColMasks[j].engineering) {
          visibleColumns.push(tool.columnMasks[i])
          break
        }

        if (!isEngineering && uiColMasks[j].drillStringEditor) {
          visibleColumns.push(tool.columnMasks[i])
          break
        }
      }
    }

    return visibleColumns
  }

  const makeDropDownLists = (inputListData, outputListdata, tagName) => {
    if (!inputListData.hasOwnProperty(tagName)) return
    if (!outputListdata.hasOwnProperty(tagName)) return
    if (!inputListData[tagName]) return
    if (!outputListdata[tagName]) return

    inputListData[tagName].forEach((item) => {
      outputListdata[tagName].push(`${item}`)
    })
  }

  const makeMaterialGradesDropDown = (inputListData, outputListdata) => {
    if (!inputListData.hasOwnProperty('casingMaterials')) return
    if (!outputListdata.hasOwnProperty('materialGrades')) return

    inputListData.casingMaterials.forEach((item) => {
      outputListdata.materialGrades.push({
        matGrade: `${item.name}`,
        strength: item.strength,
        type: 'Casing/Tubing',
      })
    })

    inputListData.casingMaterials.forEach((item) => {
      outputListdata.materialGrades.push({
        matGrade: `${item.name}`,
        strength: item.strength,
        type: 'Liner',
      })
    })

    if (!inputListData.hasOwnProperty('drillPipeMaterials')) return
    if (!outputListdata.hasOwnProperty('materialGrades')) return

    inputListData.drillPipeMaterials.forEach((item) => {
      outputListdata.materialGrades.push({
        matGrade: `${item.name}`,
        strength: item.strength,
        type: 'Drill Pipe/HWDP',
      })
    })
  }

  const getDropDownLists = () => {
    if (!dropDownData.current) return null

    let newDropDownLists = {
      connectionTypes: [],
      bitTypes: [],
      materialTypes: [],
      drillPipeRange: [],
      pipeClass: [],
      materialGrades: [],
      motorBends: [],
      motorLobes: [],
      motorStages: [],
      motorVendors: [],
      bitVendors: [],
      motorHousing: [],
      mwdCollarTypes: [],
      mwdTelemTypes: [],
      helixSn: [],
      pulserSn: [],
      batterySn: [],
      directionalSn: [],
      driverSn: [],
      emSn: [],
      gammaSn: [],
      rssTypes: [],
      shockToolSn: [],
      rssMakes: [],
      vibrationToolMakes: [],
      standOffCalcMethod: [
        'Mud Inside/Mud Outside',
        'Mud Inside/Cement Outside',
        'Cement Inside/Mud Outside',
        'Cement Inside/Cement Outside',
        'Air Inside/Mud Outside',
      ],
      casingMaterials: ['Steel', 'Stainless Steel', 'Aluminium', 'Composite'],
      stopCollars: Array.isArray(dropDownData.current.stopCollars) ? dropDownData.current.stopCollars : [],
      casingCentralizers: Array.isArray(dropDownData.current.casingCentralizers)
        ? dropDownData.current.casingCentralizers
        : [],
      toolModels: Array.isArray(dropDownData.current.toolModels) ? dropDownData.current.toolModels : [],
      formations:[],
      poppet: [],
    }

    makeMaterialGradesDropDown(dropDownData.current, newDropDownLists)
    makeDropDownLists(dropDownData.current, newDropDownLists, 'motorVendors')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'bitVendors')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'connectionTypes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'bitTypes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'materialTypes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'drillPipeRange')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'pipeClass')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'motorLobes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'motorStages')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'motorHousing')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'mwdCollarTypes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'rssMakes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'mwdTelemTypes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'helixSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'pulserSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'batterySn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'directionalSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'driverSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'emSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'gammaSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'shockToolSn')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'rssTypes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'vibrationToolMakes')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'poppet')
    makeDropDownLists(dropDownData.current, newDropDownLists, 'formations')

    if (Array.isArray(dropDownData.current?.motorBends)) {
      newDropDownLists.motorBends = []
      for (let i = 0; i < dropDownData.current.motorBends.length; i++) {
        if (dropDownData.current.motorBends[i] === '') continue
        if (isNaN(parseFloat(dropDownData.current.motorBends[i]))) continue

        newDropDownLists.motorBends.push(parseFloat(dropDownData.current.motorBends[i]))
      }
    }

    newDropDownLists.yesNoList = ['NO', 'YES']
    return newDropDownLists
  }

  return { getVisibleColumns, getCompTypesList, getToolTypeEnumFromDesc, hasToolJoint, getDropDownLists }
}

export default useDrillStringDropDowns
