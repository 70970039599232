import React, { useEffect, useRef } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'
import CloudImageGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/CloudImageGrid'
import BlankImage from 'assets/NoImage.png'
import useWallPlotComposerFiles from 'components/common/hooks/WallPlotComposer/useWallPlotComposerFiles'

const ClampedImage = ({ src }) => {
  return (
    <Box
      component='img'
      src={src !== 'data:image/*;base64,' ? src : BlankImage}
      alt='No Image Selected'
      sx={{ width: '100%', height: 'calc(100% - 45px)', paddingBottom: '15px', objectFit: 'contain' }}
    />
  )
}

const CloudImageModal = ({ onClose, onApply, wellName, title }) => {
  const _isMounted = useRef(false)
  const gridRef = useRef(null)
  const [previewImage, setPreviewImage] = React.useState('')
  const { imageList, fetchImageList, setWpWellName, deleteImages } = useWallPlotComposerFiles(wellName)

  useEffect(() => {
    _isMounted.current = true
    if (!wellName || wellName.length < 1) return
    fetchImageList()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      setWpWellName(wellName)
      fetchImageList()
    }
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleApply = () => {
    const id = gridRef.current.getSelRow()
    // onApply(id)
    onApply({ fileName: imageList[id].fileName, description: imageList[id].description })
  }

  const handlePreview = (fileData) => {
    if (!fileData) {
      setPreviewImage('')
      return
    }
    setPreviewImage(fileData?.data)
  }

  const getS3FileName = (id) => {
    if (!imageList) return ''
    return imageList[id]?.fileName
  }

  const handleDelete = (params) => {
    deleteImages(getS3FileName(params?.id))
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '400px',
          width: '50vw',
          minHeight: '600px',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CloudImageGrid
          ref={gridRef}
          files={imageList}
          wellName={wellName}
          onPreview={handlePreview}
          onDelete={handleDelete}
          onUpdate={handleApply}
        />
        <div
          style={{
            minHeight: '100px',
            maxHeight: '400px',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            alignContent: 'center',
          }}>
          <ClampedImage src={previewImage} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button variant='contained' color='primary' type='submit' onClick={handleApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CloudImageModal
