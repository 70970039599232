import { numberToString } from 'utils/numberFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import * as XLSX from '@sheet/image'
import {
  getGroupedByDateInvoiceData,
  getGroupedInvoiceData,
  getFieldInvoiceData,
  DAILY_COST_REPORT,
  GROUPED_COST_REPORT,
  DATE_RANGE_COST_REPORT,
  ALL_COSTS_REPORT,
  isValidDate,
} from 'components/common/hooks/useDailyCosts/useDailyCosts' // totally not a circular dependency
import {
  getStyles,
  mergeCells,
  emptyCells,
  makeTableHeaderData,
  makeMergedCells,
  getLastCol,
  generateXlsColumnWidth,
} from 'components/common/ExcelReports/ExcelReportFunctions'
import { DEFAULT_REPORT_SETTINGS } from 'components/Admin/Organizations/ReportSettingsModal'


//This is the main function call
export const generateDailyCostXlsReport = (
  costDataRef = null,
  startDate = null,
  endDate = null,
  exportType,
  costUnits,
  diamUnits,
  depthUnits,
  getCurrentOrgIcon,
  wellName,
  reportSettings = DEFAULT_REPORT_SETTINGS
) => {
  let grouped = true
  if (exportType === DAILY_COST_REPORT || exportType === ALL_COSTS_REPORT) grouped = false

  if (!costDataRef) return null
  if (startDate && !isValidDate(startDate)) return null
  if (endDate && !isValidDate(endDate)) return null
  if (typeof exportType !== 'string') return null

  const styles = getStyles(reportSettings.primaryColor)

  let worksheetName = 'Field Invoice'
  let fileName = `${wellName} Cost Well.xlsx`

  let wb = XLSX.utils.book_new()
  let worksheet = XLSX.utils.aoa_to_sheet([[]], {
    origin: 'B2',
  })

  worksheet['!merges'] = []
  worksheet['!cols'] = []

  let startCol = 1
  let startRow = 1
  let curRow = startRow

  //--------------------------------------HEADER---------------------------------------
  let headerData = generateXlsHeader(costDataRef, grouped, startDate, endDate, costUnits, diamUnits, depthUnits)

  let headerColDef = [
    { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
    { colSpan: 3, headerStyle: styles.centertttt },
    { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
    { colSpan: 3, headerStyle: styles.centertttt },
  ]

  XLSX.utils.sheet_add_aoa(worksheet, headerData, {
    origin: 'F2',
  })

  worksheet['!merges'].push(...makeMergedCells(headerColDef, startRow, 5, headerData.length))
  worksheet['!images'] = [
    {
      '!pos': {
        c: 1,
        r: 1,
        x: 1,
        y: 1,
        w: 110,
        h: 110,
      },
      '!datatype': 'base64',
      '!data': getCurrentOrgIcon(),
    },
  ]

  curRow += headerData.length + 1

  //--------------------------------------DATA TABLE HEADERS----------------------------------
  const NON_GROUPED_COL_DEFS = [
    {
      label: 'Description',
      colSpan: 3,
      headerStyle: styles.fillCenterBoldtttt,
      colWidth: 30,
      style: styles.leftttt,
    },
    {
      label: 'Date',
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: 'Serial #',
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
      colWidth: 12,
      style: styles.leftttt,
    },
    {
      label: 'Cost Code',
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: `Unit Cost (${costUnits})`,
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.righttttt,
    },
    {
      label: `Qty`,
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: `Total Cost (${costUnits})`,
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
      colWidth: 35,
      style: styles.righttttt,
    },
  ]

  const GROUPED_COL_DEFS = [
    {
      label: 'Item#',
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: 'Description',
      colSpan: 3,
      headerStyle: styles.fillCenterBoldtttt,
      colWidth: 30,
      style: styles.leftttt,
    },
    {
      label: 'Start Date',
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: 'End Date',
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: 'Serial #',
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
      colWidth: 12,
      style: styles.leftttt,
    },
    {
      label: `Unit Cost (${costUnits})`,
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.righttttt,
    },
    {
      label: `Qty`,
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
    },
    {
      label: `Total Cost (${costUnits})`,
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
      colWidth: 35,
      style: styles.righttttt,
    },
  ]

  let colDef = grouped ? GROUPED_COL_DEFS : NON_GROUPED_COL_DEFS
  let lastCol = getLastCol(startCol, colDef)

  let tableTitleData = [[], [`${costDataRef.wellName} - Field Invoice`]]
  let tableTitleMergedCells = makeMergedCells(
    [{ colSpan: lastCol - startCol + 1, headerStyle: styles.fillCenterBoldtttt }],
    curRow,
    startCol,
    1,
  )

  XLSX.utils.sheet_add_aoa(worksheet, tableTitleData, {
    origin: `B${curRow}`,
  })

  worksheet['!merges'].push(...tableTitleMergedCells)
  curRow += 2

  let tableHeaderData = makeTableHeaderData(colDef)
  let tableHeaderMergedCells = makeMergedCells(colDef, curRow - 1, startCol, 1)

  XLSX.utils.sheet_add_aoa(worksheet, tableHeaderData, {
    origin: `B${curRow}`,
  })

  worksheet['!merges'].push(...tableHeaderMergedCells)
  curRow++

  //--------------------------------------DATA TABLE DATA----------------------------------
  let xlsDailyCostData = []
  let tableDataOrigin = `B${curRow}`

  switch (exportType) {
    case DATE_RANGE_COST_REPORT:
      xlsDailyCostData = getGroupedByDateInvoiceData(costDataRef, startDate, endDate, false, costUnits)
      worksheetName = `${startDate} to ${endDate}`
      fileName = `${costDataRef.wellName} Cost Range.xlsx`
      break

    case GROUPED_COST_REPORT:
      xlsDailyCostData = getGroupedInvoiceData(costDataRef.wellName, costDataRef.dailyCosts, false, costUnits)
      worksheetName = 'Grouped Invoice'
      fileName = `${wellName} Grouped Cost Report.xlsx`
      break

    case DAILY_COST_REPORT:
      xlsDailyCostData = getSingleDayCostReport(costDataRef, startDate, costUnits)
      worksheetName = `${formatDateString(startDate)} Daily Cost`
      fileName = `${wellName} Cost Day.xlsx`
      break

    default:
      xlsDailyCostData = getFieldInvoiceData(costDataRef, false, costUnits)
  }

  let tableData = []
  let tableMergeCells = []
  let totalCost = '0.00'

  for (let i = 0; i < xlsDailyCostData.length; i++) {
    const rowData = []
    let curCol = startCol

    for (let j = 0; j < colDef.length; j++) {
      if (j >= xlsDailyCostData[i].length) {
        console.error('xlsDailyCostData out of range')
        continue
      }

      if (i === xlsDailyCostData.length - 1) {
        totalCost = xlsDailyCostData[i][xlsDailyCostData[i].length - 1]?.text
        break
      }

      rowData.push(xlsDailyCostData[i][j].text)
      if (colDef[j]?.colSpan > 1) {
        rowData.push(...emptyCells(colDef[j].colSpan - 1))
      }

      tableMergeCells.push(
        mergeCells({
          row: curRow - 1,
          col: curCol,
          colEnd: curCol + colDef[j].colSpan - 1,
          style: colDef[j].style || styles.centertttt,
        }),
      )

      curCol += colDef[j].colSpan
    }

    curRow++
    tableData.push(rowData)
  }

  XLSX.utils.sheet_add_aoa(worksheet, tableData, {
    origin: tableDataOrigin,
  })

  worksheet['!merges'].push(...tableMergeCells)

  //--------------------------------------FOOTER----------------------------------
  let totalCostRow = curRow - 1
  let footerOrigin = `B${totalCostRow}`
  let footerData = [
    ['Customer Signature / Stamp', ...emptyCells(7), `Total Cost (${costUnits})`, ...emptyCells(3), totalCost],
    [...emptyCells(8), 'Address', ...emptyCells(5)],
  ]

  XLSX.utils.sheet_add_aoa(worksheet, footerData, {
    origin: footerOrigin,
  })

  XLSX.utils.sheet_add_aoa(worksheet, [[...emptyCells(8), 'Field Representative - Signature', ...emptyCells(4)]], {
    origin: `B${totalCostRow + 7}`,
  })

  XLSX.utils.sheet_add_aoa(worksheet, [['icpwebportal']], {
    origin: `B${totalCostRow + 12}`,
  })

  totalCostRow--

  worksheet['!merges'].push(
    //Customer Signature header
    mergeCells({
      row: totalCostRow,
      col: startCol,
      rowEnd: totalCostRow + 1,
      colEnd: startCol + 7,
      style: styles.fillCenterBoldtttt,
    }),
    //Customer Signature blank box
    mergeCells({
      row: totalCostRow + 2,
      col: startCol,
      rowEnd: totalCostRow + 11,
      colEnd: startCol + 7,
      style: styles.centertttt,
    }),
    //Total Cost header
    mergeCells({
      row: totalCostRow,
      col: startCol + 8,
      colEnd: 12,
      style: styles.fillCenterBoldtttt,
    }),
    //Total Cost data
    mergeCells({
      row: totalCostRow,
      col: 13,
      colEnd: lastCol,
      style: styles.righttttt,
    }),
    //Address header
    mergeCells({
      row: totalCostRow + 1,
      col: startCol + 8,
      colEnd: lastCol,
      style: styles.fillCenterBoldtttt,
    }),
    //Address blank box
    mergeCells({
      row: totalCostRow + 2,
      col: startCol + 8,
      rowEnd: totalCostRow + 6,
      colEnd: lastCol,
      style: styles.centertttt,
    }),
    //Field Rep Signature header
    mergeCells({
      row: totalCostRow + 7,
      col: startCol + 8,
      colEnd: lastCol,
      style: styles.fillCenterBoldtttt,
    }),
    //Field Rep Sig blank box
    mergeCells({
      row: totalCostRow + 8,
      col: startCol + 8,
      rowEnd: totalCostRow + 11,
      colEnd: lastCol,
      style: styles.centertttt,
    }),
    //icpwebportal box
    mergeCells({
      row: totalCostRow + 12,
      col: startCol,
      colEnd: lastCol,
      style: styles.rightBoldtttt,
    }),
  )

  //--------------------------------------COLUMN WIDTHS----------------------------------
  let curCol = startCol
  for (let i = 0; i < colDef.length; i++) {
    if (colDef[i]?.colWidth) {
      generateXlsColumnWidth(worksheet, curCol, colDef[i].colWidth)
    }
    curCol += colDef[i].colSpan
  }

  addStylesToWorksheet(worksheet, true)
  XLSX.utils.book_append_sheet(wb, worksheet, worksheetName)
  XLSX.writeFile(wb, fileName, { cellStyles: true, bookImages: true })
}

const generateXlsHeader = (
  costDataRef = null,
  grouped = false,
  startDate = null,
  endDate = null,
  costUnitsText,
  diamUnitsText,
  deptUnitsText,
) => {
  if (!costDataRef) return null
  if (!costDataRef.hasOwnProperty('dailyCosts')) return null
  if (startDate && !isValidDate(startDate)) return null
  if (endDate && !isValidDate(endDate)) return null

  let maxDate = new Date(Date.now())
  if (Array.isArray(costDataRef.dailyCosts) && costDataRef.dailyCosts.length > 0) {
    maxDate = new Date(Date.parse(costDataRef.dailyCosts[costDataRef.dailyCosts.length - 1].date + 'T00:00:01'))
  }

  let headerData = [
    [
      'Job #',
      ...emptyCells(1),
      costDataRef?.jobNumber,
      ...emptyCells(2),
      'Operator:',
      ...emptyCells(1),
      costDataRef?.operator,
      ...emptyCells(2),
    ],
    [
      'API Job #',
      ...emptyCells(1),
      costDataRef?.apiJobNum,
      ...emptyCells(2),
      'Field:',
      ...emptyCells(1),
      costDataRef?.field,
      ...emptyCells(2),
    ],
    [
      'Rig:',
      ...emptyCells(1),
      costDataRef?.rig,
      ...emptyCells(2),
      'Well:',
      ...emptyCells(1),
      costDataRef?.actualWellName,
      ...emptyCells(2),
    ],
    [
      'State:',
      ...emptyCells(1),
      costDataRef?.state,
      ...emptyCells(2),
      'Date:',
      ...emptyCells(1),
      maxDate,
      ...emptyCells(2),
    ],
    [
      'County:',
      ...emptyCells(1),
      costDataRef?.county,
      ...emptyCells(2),
      `Well Total (${costUnitsText}):`,
      ...emptyCells(1),
      numberWithCommasDecimals(costDataRef?.totalCost, 2),
      ...emptyCells(2),
    ],
  ]

  if (costDataRef?.linkedBidDescription) {
    headerData.push([
      'Bid ID:',
      ...emptyCells(1),
      costDataRef.linkedBidId,
      ...emptyCells(2),
      `Bid`,
      ...emptyCells(1),
      costDataRef.linkedBidDescription,
      ...emptyCells(2),
    ])
  }

  if (grouped) {
    const { dailyCosts } = costDataRef
    const { startDateStr, endDateStr } = validateStartEndDate(startDate, endDate, dailyCosts)
    let groupedHeaderData = [
      [
        `Start Hole Size: (${diamUnitsText})`,
        ...emptyCells(1),
        numberToString(costDataRef?.startHoleSize, 2),
        ...emptyCells(2),
        `End Hole Size: (${diamUnitsText})`,
        ...emptyCells(1),
        numberToString(costDataRef?.endHoleSize, 2),
        ...emptyCells(2),
      ],
      [
        `Start Depth: (${deptUnitsText})`,
        ...emptyCells(1),
        numberWithCommasDecimals(costDataRef?.startDepth, 2),
        ...emptyCells(2),
        `End Depth: (${deptUnitsText})`,
        ...emptyCells(1),
        numberWithCommasDecimals(costDataRef?.endDepth, 2),
        ...emptyCells(2),
      ],
      [
        'Start Date:',
        ...emptyCells(1),
        startDateStr,
        ...emptyCells(2),
        'End Date:',
        ...emptyCells(1),
        endDateStr,
        ...emptyCells(2),
      ],
      [
        'DD Engineers:',
        ...emptyCells(1),
        getPersonnelNames(costDataRef?.directionalDrillers),
        ...emptyCells(2),
        'MWD Engineers:',
        ...emptyCells(1),
        getPersonnelNames(costDataRef?.mwdEngineers),
        ...emptyCells(2),
      ],
      [
        'AFE #',
        ...emptyCells(1),
        costDataRef?.afeNum,
        ...emptyCells(2),
        'PO #',
        ...emptyCells(1),
        costDataRef?.poNum,
        ...emptyCells(2),
      ],
      ['Mud Type:', ...emptyCells(1), costDataRef?.mudType, ...emptyCells(2)],
    ]

    for (let i = 0; i < groupedHeaderData.length; i++) {
      headerData.push(groupedHeaderData[i])
    }
  }

  return headerData
}

const validateStartEndDate = (startDate, endDate, dailyCosts) => {
  let startDateOut = ''
  let endDateOut = ''

  if (isValidDate(startDate)) startDateOut = new Date(Date.parse(startDate + 'T00:01:00')).toLocaleDateString()
  if (isValidDate(endDate)) endDateOut = new Date(Date.parse(endDate + 'T00:01:00')).toLocaleDateString()

  if (Array.isArray(dailyCosts) && dailyCosts.length > 0) {
    if (!isValidDate(startDate) && isValidDate(dailyCosts[0]?.date)) {
      startDateOut = new Date(Date.parse(dailyCosts[0]?.date + 'T00:01:00')).toLocaleDateString()
    }

    if (!isValidDate(endDate) && isValidDate(dailyCosts[dailyCosts.length - 1]?.date)) {
      endDateOut = new Date(Date.parse(dailyCosts[dailyCosts.length - 1].date + 'T00:01:00')).toLocaleDateString()
    }
  }

  return { startDateStr: startDateOut, endDateStr: endDateOut }
}

const getPersonnelNames = (personnelNames) => {
  if (!personnelNames || !Array.isArray(personnelNames)) return ''
  if (personnelNames.length === 0) return ''

  let joinedNamesStr = ''
  for (let i = 0; i < personnelNames.length; i++) {
    if (personnelNames[i] === '') continue
    if (joinedNamesStr !== '') joinedNamesStr += ' / '
    joinedNamesStr += personnelNames[i]
  }
  return joinedNamesStr
}

const getSingleDayCostReport = (costDataRef, date, costUnitsText) => {
  if (!date) return null
  if (typeof date !== 'string') return null
  if (!isValidDate(date)) return null
  if (!costDataRef) return null
  if (!costDataRef.hasOwnProperty('dailyCosts')) return null
  if (!Array.isArray(costDataRef.dailyCosts)) return null
  let invoiceData = []

  let reportIdx = costDataRef.dailyCosts.findIndex((item) => item.date === date)
  if (reportIdx < 0) return null

  for (let i = 0; i < costDataRef.dailyCosts[reportIdx].costs.length; i++) {
    let cost = costDataRef.dailyCosts[reportIdx].costs[i]
    invoiceData.push([
      { text: cost.description },
      { text: new Date(Date.parse(date + 'T00:00:01')).toLocaleDateString() },
      { text: cost.serialNum },
      { text: cost.costCode },
      { text: numberWithCommasDecimals(cost.value, 2) },
      { text: cost.quantity },
      { text: numberWithCommasDecimals(cost.totalValue, 2) },
    ])
  }

  invoiceData.push([
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: `Total Cost (${costUnitsText})` },
    { text: numberWithCommasDecimals(costDataRef.dailyCosts[reportIdx].totalCost, 2) },
  ])
  return invoiceData
}

const formatDateString = (dateStr) => {
  if (!isValidDate(dateStr)) return ''
  let splitDate = dateStr.split('-')
  let formattedDateStr = `${splitDate[1]}_${splitDate[2]}_${splitDate[0]}`
  return formattedDateStr
}

const addStylesToWorksheet = (ws, mergedCellStyles = false) => {
  if (!ws) return
  if (mergedCellStyles && !ws.hasOwnProperty('!merges')) return

  if (!ws.hasOwnProperty('!cols')) ws['!cols'] = []
  if (mergedCellStyles) {
    ws['!merges'].forEach((cellAddresses) => {
      const { s, e, style } = cellAddresses
      let startAddress = XLSX.utils.encode_cell(s)
      let endAddress = XLSX.utils.encode_cell(e)
      let cellRange = `${startAddress}:${endAddress}`

      if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
    })
  }
}
