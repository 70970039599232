import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const TemplateGeometryGrid = ({ selectedTemplate, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [selectedTemplate]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 200,
        cellDataType: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: true,
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'boolean') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params?.data?.type === 'boolean') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params?.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params?.data?.type === 'dropdown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return numberWithCommasDecimals(params.value, params.data?.precision)
          }
          return params.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      if (handleUpdate) handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getGeometryData = (data) => {
    if (!data) return []

    if (data.geometry === 'SINGLE') {
      return []
    }

    if (data.geometry === 'RECT') {
      return [
        {
          label: `Offset NS`,
          value: data?.rectData?.topLeftNorth,
          id: 0,
          type: 'number',
          tag: `topLeftNorth`,
          precision: 3,
          min: -100000000,
          max: 100000000,
        },
        {
          label: `Offset EW`,
          value: data?.rectData?.topLeftEast,
          id: 1,
          type: 'number',
          tag: `topLeftEast`,
          precision: 3,
          min: -100000000,
          max: 100000000,
        },
        {
          label: `Rows`,
          value: data?.rectData?.rows,
          id: 2,
          type: 'number',
          tag: `rows`,
          precision: 0,
          min: 0,
          max: 100,
        },
        {
          label: `Columns`,
          value: data?.rectData?.columns,
          id: 3,
          type: 'number',
          tag: `columns`,
          precision: 0,
          min: 0,
          max: 100,
        },
        {
          label: `Rotation`,
          value: data?.rectData?.rotation,
          id: 4,
          type: 'number',
          tag: `rotation`,
          precision: 2,
          min: 0,
          max: 360,
        },
        {
          label: `X Spacing`,
          value: data?.rectData?.xSpacing,
          id: 5,
          type: 'number',
          tag: `xSpacing`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Y Spacing`,
          value: data?.rectData?.ySpacing,
          id: 6,
          type: 'number',
          tag: `ySpacing`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Slot Numbering`,
          value: data?.rectData?.slotNumbering ? 'ROW' : 'COLUMN',
          id: 7,
          type: 'dropdown',
          tag: `slotNumbering`,
          dropDownValues: ['ROW', 'COLUMN'],
        },
      ]
    }

    if (data.geometry === 'CIRC') {
      return [
        {
          label: `#Slots`,
          value: data?.circData?.numSlots,
          id: 0,
          type: 'number',
          tag: `numSlots`,
          precision: 0,
          min: 0,
          max: 1000,
        },
        {
          label: `Rotation`,
          value: data?.circData?.rotation,
          id: 1,
          type: 'number',
          tag: `rotation`,
          precision: 3,
          min: 0,
          max: 360,
        },
        {
          label: `Radius`,
          value: data?.circData?.radius,
          id: 6,
          type: 'number',
          tag: `radius`,
          precision: 2,
          min: 0,
          max: 100000,
        },
      ]
    }
    return []
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getGeometryData(selectedTemplate)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default TemplateGeometryGrid
