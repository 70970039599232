import React, { useRef, useEffect } from 'react'
import { appColors } from 'utils'
import moment from 'moment'
import { currentInfrastructureLogSelector, currentInfrastructureChartRangeAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Bar } from 'react-chartjs-2'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const ApiLast24HrsChart = ({ showTitle }) => {
  const _isMounted = useRef(false)
  const data = useRecoilValue(currentInfrastructureLogSelector)
  const chartRef = useRef()
  const chartDateRange = useRecoilValue(currentInfrastructureChartRangeAtom)
  let thirtyDaysAgo = moment().subtract(30, 'd').startOf('minute').format('YYYY-MM-DDThh:mm:ss')
  const { theme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    renderChartScales()
  }, [chartDateRange, data]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderChartScales = () => {
    if (_isMounted.current && chartRef.current !== undefined) {
      if (chartDateRange) {
        chartRef.current.config._config.options.scales.x.min = thirtyDaysAgo
        chartRef.current.config._config.options.plugins.zoom.limits.x = {
          min: moment(thirtyDaysAgo).valueOf(),
          max: moment().valueOf(),
        }
        chartRef.current.config._config.options.scales.x.time = {
          unit: 'day',
          displayFormats: {
            hour: 'h A',
          },
        }
      } else {
        chartRef.current.config._config.options.scales.x.min = rangeStart
        chartRef.current.config._config.options.plugins.zoom.limits.x = {
          min: moment(rangeStart).valueOf(),
          max: moment().valueOf(),
        }
        chartRef.current.config._config.options.scales.x.time = {
          unit: 'hour',
          displayFormats: {
            hour: 'h A',
          },
        }
      }
      chartRef.current.config._config.options.scales.x.ticks.autoSkipPadding = 0
      chartRef.current.update()
    }
  }

  var rangeStart = moment().subtract(1, 'd').startOf('hour').format('YYYY-MM-DDTHH:mm:ss')
  var rangeEnd = moment().startOf('hour').format('YYYY-MM-DDTHH:mm:ss')

  const xAxisRange = useRef({
    min: rangeStart,
    max: rangeEnd,
  })

  const chartOptions = {
    onResize: (chart) => {
      renderChartScales()
    },
    layout: {
      padding: {
        right: 20,
      },
    },
    animation: false,
    hover: {
      animationDuration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    type: 'bar',
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: showTitle,
        text: 'API Errors (Last 24 Hrs)',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      legend: { display: false },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 15,
          threshold: 5,
        },
        limits: {
          x: {
            min: new Date(xAxisRange.current.min).valueOf(),
            max: new Date(xAxisRange.current.max).valueOf(),
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          color: (context) => {
            if (context.tick.label === '12 AM') {
              return appColors.slideColor
            } else {
              return 'rgba(102, 102, 102, 1)'
            }
          },
        },
        min: xAxisRange.current.min,
        max: xAxisRange.current.max,
        type: 'time',
        time: {
          unit: 'hour',
          displayFormats: {
            hour: 'h A',
          },
        },
      },
      y: {
        stacked: true,
        ticks: {
          autoSkip: false,
          precision: 0,
        },
        title: {
          display: true,
          text: 'Count',
          font: {
            size: 20,
            weight: 600,
          },
          color: 'rgba(102, 102, 102, 1)',
        },
      },
    },
  }

  const getDatasetIndex = (logItemLevel, logItemType) => {
    switch (logItemType) {
      case 'api':
        if (logItemLevel === 'error') return 0
        if (logItemLevel === 'warn') return 1
        break
      case 'model':
        if (logItemLevel === 'error') return 2
        if (logItemLevel === 'warn') return 3
        break
      case 'store':
        if (logItemLevel === 'error') return 4
        if (logItemLevel === 'warn') return 5
        break
      case 'chat':
        if (logItemLevel === 'error') return 6
        if (logItemLevel === 'warn') return 7
        break
      case 'icds':
        if (logItemLevel === 'error') return 8
        if (logItemLevel === 'warn') return 9
        break
      case 'other':
      default:
        if (logItemLevel === 'error') return 10
        if (logItemLevel === 'warn') return 11
        return -1
    }
  }

  const createChartData = () => {
    const chartJsData = {
      datasets: [
        {
          label: 'Api-Errors',
          data: [],
          borderColor: '#59FF00CF',
          backgroundColor: '#59FF00CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Api-Warnings',
          data: [],
          borderColor: '#389F00CF',
          backgroundColor: '#389F00CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Model-Errors',
          data: [],
          borderColor: '#00A3FFCF',
          backgroundColor: '#00A3FFCF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Model-Warnings',
          data: [],
          borderColor: '#00669FCF',
          backgroundColor: '#00669FCF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Store-Errors',
          data: [],
          borderColor: '#F800FFCF',
          backgroundColor: '#F800FFCF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Store-Warnings',
          data: [],
          borderColor: '#9B009FCF',
          backgroundColor: '#9B009FCF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Chat-Errors',
          data: [],
          borderColor: '#FFFF00CF',
          backgroundColor: '#FFFF00CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Chat-Warnings',
          data: [],
          borderColor: '#9F9D00CF',
          backgroundColor: '#9F9D00CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Icds-Errors',
          data: [],
          borderColor: '#FF0000CF',
          backgroundColor: '#FF0000CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Icds-Warnings',
          data: [],
          borderColor: '#9F0000CF',
          backgroundColor: '#9F0000CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Other-Errors',
          data: [],
          borderColor: '#FF9900CF',
          backgroundColor: '#FF9900CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
        {
          label: 'Other-Warnings',
          data: [],
          borderColor: '#9F6000CF',
          backgroundColor: '#9F6000CF',
          barThickness: 4,
          maxBarThickness: 24,
        },
      ],
    }

    if (Array.isArray(data)) {
      let isoToday = new Date().toISOString()
      let reducedData = []
      let thirtyDaysAgo = moment().subtract(30, 'days').format('YYYY-MM-DD')

      reducedData = data.filter((i) => {
        let newDate = i.ts.slice(0, 10)
        if (moment(newDate).isBetween(thirtyDaysAgo, isoToday)) {
          if (i.level === 'warn') {
            return true
          } else if (i.level === 'error') {
            return true
          }
        }
        return false
      })

      reducedData.forEach((item) => {
        if (item.level === 'error' || item.level === 'warn') {
          const gmtDateTime = item.ts
          const localDateTime = moment(gmtDateTime).local().startOf('hour').format('YYYY-MM-DDTHH:mm:ss')

          const dsIdx = getDatasetIndex(item.level, item.type)

          if (dsIdx > 0) {
            if (chartJsData.datasets[dsIdx].data[chartJsData.datasets[dsIdx].data.length - 1]?.x === localDateTime) {
              chartJsData.datasets[dsIdx].data[chartJsData.datasets[dsIdx].data.length - 1].y += 1
            } else {
              chartJsData.datasets[dsIdx].data.push({
                x: localDateTime,
                y: 1,
              })
            }
          }
        }
      })
    }

    return chartJsData
  }

  return <Bar ref={chartRef} options={chartOptions} data={createChartData()} />
}

export default ApiLast24HrsChart
