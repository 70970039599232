import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'

import { SOLO_CLOUD_TREE_LEVELS } from './SoloCloudTree'
import {
  DEFAULT_COL_DEF,
  COL_DEF_PROJECT,
  COL_DEF_WELL,
  COL_DEF_WELL_DATA,
  COL_DEF_TOP_SET,
  COL_DEF_TARGET_LINE_DATA,
  COL_DEF_PLAN_DATA,
  COL_DEF_TOP_HEADER,
  COL_DEF_TARGET_LINE_HEADER,
  COL_DEF_PLAN_HEADER,
} from './DetailGridColumnDefs'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SoloCloudDetailsGrid = ({ data, selectedNode }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getColDefs = useMemo(() => {
    if (!selectedNode) return []

    if (selectedNode === SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD) {
      return COL_DEF_PROJECT
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.PROJECT) {
      return COL_DEF_WELL
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.WELL) {
      return COL_DEF_WELL_DATA
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.TOP_HEADER) {
      return COL_DEF_TOP_HEADER
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.TOP_SET) {
      return COL_DEF_TOP_SET
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.TARGET_LINE_HEADER) {
      return COL_DEF_TARGET_LINE_HEADER
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.TARGET_LINE) {
      return COL_DEF_TARGET_LINE_DATA
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.PLAN_HEADER) {
      return COL_DEF_PLAN_HEADER
    }

    if (selectedNode?.level === SOLO_CLOUD_TREE_LEVELS.PLAN) {
      return COL_DEF_PLAN_DATA
    }
  }, [selectedNode])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  useEffect(() => {
    setTimeout(() => {
      if (gridApi.current) {
        gridApi.current?.autoSizeAllColumns()
      }
    }, 200)
  }, [data])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uuid)
    }
  }, [])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className={getAgGridTheme()}>
      <AgGridReact
        rowData={data ? data : []}
        columnDefs={getColDefs}
        getRowId={getRowId}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDefs={useMemo(() => DEFAULT_COL_DEF, [])}
      />
    </div>
  )
}

export default SoloCloudDetailsGrid
