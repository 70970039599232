import { useRef } from 'react'
import { useRecoilState } from 'recoil'

import { userRoleSelector } from 'atoms'
import useInnovaAuth from './useInnovaAuth'
import useInnovaAxios from './useInnovaAxios'

const useUserRole = () => {
  const isFetching = useRef(false)
  const { user } = useInnovaAuth()
  const fetch = useInnovaAxios({
    url: '/user/role',
  })
  const [userRole, setUserRole] = useRecoilState(userRoleSelector)

  const getUserRole = async (userName) => {
    if (!userName) return
    if (!userName.hasOwnProperty('name')) return
    if (typeof userName.name !== 'string') return
    if (userName.name === '') return
    if (isFetching.current) return

    isFetching.current = true
    const res = await fetch()
    isFetching.current = false
    if (res?.error) return

    if (res?.data) {
      setUserRole(res.data)
    }
  }

  const getRole = () => {
    getUserRole(user)
  }

  return { user, userRole, getRole }
}

export default useUserRole
