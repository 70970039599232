import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'

const ContextMenuItemIcon = ({ icon, color, isImage }) => {
  if (isImage) {
    return (
      <img
        alt={'Icon'}
        src={icon}
        style={{
          width: `15px`,
          height: `15px`,
        }}
      />
    )
  }

  return <Iconify icon={icon} width={'15px'} height={'15px'} color={color || '#a9abac'} />
}

export const getContextMenuItems = (
  selectedUids,
  handleZIndex,
  viewRef,
  actions,
  showImageMenuItems,
  showTableMenuItems,
  elementType,
  tableType,
  setShowImageMenuItems,
  setShowTableMenuItems,
  showChartMenuItems,
  chartType,
  setShowChartMenuItems,
  showElementProperties,
  containerRefs,
  units,
  zoomScale,
  convertPixelsToUser,
) => {
  const makeSameWidth = () => {
    if (selectedUids.current && selectedUids.current.length >= 2) {
      const lastSelectedUid = selectedUids.current[0]
      let width = parseFloat(containerRefs.current[lastSelectedUid].current.style.width)

      for (let i = 1; i < selectedUids.current.length; i++) {
        let height = parseFloat(containerRefs.current[selectedUids.current[i]].current.style.height)

        actions.updateElement(selectedUids.current[i], 'resize', {
          width: convertPixelsToUser(width, units, zoomScale),
          height: convertPixelsToUser(height, units, zoomScale),
        })
      }
    }
  }

  const makeSameHeight = () => {
    if (selectedUids.current && selectedUids.current.length >= 2) {
      const lastSelectedUid = selectedUids.current[0]
      let height = parseFloat(containerRefs.current[lastSelectedUid].current.style.height)

      for (let i = 1; i < selectedUids.current.length; i++) {
        let width = parseFloat(containerRefs.current[selectedUids.current[i]].current.style.width)

        actions.updateElement(selectedUids.current[i], 'resize', {
          width: convertPixelsToUser(width, units, zoomScale),
          height: convertPixelsToUser(height, units, zoomScale),
        })
      }
    }
  }

  const alignLeft = () => {
    if (selectedUids.current && selectedUids.current.length >= 2) {
      const lastSelectedUid = selectedUids.current[0]
      for (let i = 1; i < selectedUids.current.length; i++) {
        const moveDistPixels =
          parseFloat(containerRefs.current[lastSelectedUid].current.style.left) -
          parseFloat(containerRefs.current[selectedUids.current[i]].current.style.left)
        containerRefs.current[selectedUids.current[i]].current.style.left =
          containerRefs.current[lastSelectedUid].current.style.left
        const moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
        actions.moveElement(selectedUids.current[i], moveDist, 0)
      }
    }
  }

  const alignRight = () => {
    if (selectedUids.current && selectedUids.current.length >= 2) {
      const lastSelectedUid = selectedUids.current[0]
      for (let i = 1; i < selectedUids.current.length; i++) {
        const moveDistPixels =
          parseFloat(containerRefs.current[lastSelectedUid].current.style.left) +
          parseFloat(containerRefs.current[lastSelectedUid].current.style.width) -
          (parseFloat(containerRefs.current[selectedUids.current[i]].current.style.left) +
            parseFloat(containerRefs.current[selectedUids.current[i]].current.style.width))
        containerRefs.current[selectedUids.current[i]].current.style.left = `${
          parseFloat(containerRefs.current[selectedUids.current[i]].current.style.left) + moveDistPixels
        }px`

        const moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
        actions.moveElement(selectedUids.current[i], moveDist, 0)
      }
    }
  }

  const alignTop = () => {
    if (selectedUids.current && selectedUids.current.length >= 2) {
      const lastSelectedUid = selectedUids.current[0]
      for (let i = 1; i < selectedUids.current.length; i++) {
        const moveDistPixels =
          parseFloat(containerRefs.current[lastSelectedUid].current.style.top) -
          parseFloat(containerRefs.current[selectedUids.current[i]].current.style.top)
        containerRefs.current[selectedUids.current[i]].current.style.top =
          containerRefs.current[lastSelectedUid].current.style.top
        const moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
        actions.moveElement(selectedUids.current[i], 0, moveDist)
      }
    }
  }

  const alignBottom = () => {
    if (selectedUids.current && selectedUids.current.length >= 2) {
      const lastSelectedUid = selectedUids.current[0]
      for (let i = 0; i < selectedUids.current.length; i++) {
        const moveDistPixels =
          parseFloat(containerRefs.current[lastSelectedUid].current.style.top) +
          parseFloat(containerRefs.current[lastSelectedUid].current.style.height) -
          (parseFloat(containerRefs.current[selectedUids.current[i]].current.style.top) +
            parseFloat(containerRefs.current[selectedUids.current[i]].current.style.height))
        containerRefs.current[selectedUids.current[i]].current.style.top = `${
          parseFloat(containerRefs.current[selectedUids.current[i]].current.style.top) + moveDistPixels
        }px`

        const moveDist = convertPixelsToUser(moveDistPixels, units, zoomScale)
        actions.moveElement(selectedUids.current[i], 0, moveDist)
      }
    }
  }

  let menuItems = []
  if (selectedUids.current && selectedUids.current.length > 0) {
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'carbon:bring-to-front'} color={'#000000'} />,
      label: `Bring to Front`,
      onClick: () => {
        if (selectedUids.current && selectedUids.current.length > 0) handleZIndex(selectedUids.current, 'front')
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'carbon:send-to-back'} color={'#000000'} />,
      label: 'Send to Back',
      onClick: () => {
        if (selectedUids.current && selectedUids.current.length >= 0) handleZIndex(selectedUids.current, 'back')
        return true
      },
    })
    menuItems.push({
      isDivider: true,
    })
  }
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
    label: 'Add text component',
    onClick: () => {
      if (!viewRef.current) return
      actions.addElement(elementType.text)
      return true
    },
  })
  menuItems.push({
    isDivider: true,
  })
  menuItems.push({
    isCollapsible: true,
    icon: <ContextMenuItemIcon icon={'icon-park:add-one'} color={'#000000'} />,
    endIcon: showImageMenuItems ? (
      <ContextMenuItemIcon icon={'carbon:chevron-up'} color={'#000000'} />
    ) : (
      <ContextMenuItemIcon icon={'carbon:chevron-down'} color={'#000000'} />
    ),
    label: 'Add image components ',
    onClick: () => {
      setShowImageMenuItems((prevValue) => !prevValue)
      return false
    },
  })
  if (showImageMenuItems) {
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Org Logo',
      onClick: () => {
        actions.addElement(elementType.imageOrgLogo)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Operator Logo (primary)',
      onClick: () => {
        actions.addElement(elementType.imageOperLogo)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Operator Logo (secondary)',
      onClick: () => {
        actions.addElement(elementType.imageOperLogo2)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'User Image',
      onClick: () => {
        actions.addElement(elementType.image)
        return true
      },
    })
  }
  menuItems.push({
    isDivider: true,
  })
  menuItems.push({
    isCollapsible: true,
    icon: <ContextMenuItemIcon icon={'icon-park:add-one'} color={'#000000'} />,
    endIcon: showTableMenuItems ? (
      <ContextMenuItemIcon icon={'carbon:chevron-up'} color={'#000000'} />
    ) : (
      <ContextMenuItemIcon icon={'carbon:chevron-down'} color={'#000000'} />
    ),
    label: 'Add table component ',
    onClick: () => {
      setShowTableMenuItems((prevValue) => !prevValue)
      return false
    },
  })
  if (showTableMenuItems) {
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Project Detail Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.projectDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'References Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.references)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Field Details Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.fieldDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Facility Details Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.facilityDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Well Details Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.wellDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Wellbore Details Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.wellboreDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Survey Program Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.surveyProgram)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Casing Details Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.casingDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Lithology Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.lithology)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Target Details Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.targetDetails)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Summary Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.summary)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Approvals',
      onClick: () => {
        actions.addElement(elementType.table, tableType.approvals)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Lease Lines Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.leaseLine)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Annotations Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.annotations)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Mapping Grid Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.mappingGrid)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Title Bar',
      onClick: () => {
        actions.addElement(elementType.table, tableType.titleBar)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      submenu: true,
      label: 'Datum Elevation Table',
      onClick: () => {
        actions.addElement(elementType.table, tableType.datumElevation)
        return true
      },
    })
  }
  menuItems.push({
    isDivider: true,
  })
  menuItems.push({
    isCollapsible: true,
    icon: <ContextMenuItemIcon icon={'icon-park:add-one'} color={'#000000'} />,
    endIcon: showChartMenuItems ? (
      <ContextMenuItemIcon icon={'carbon:chevron-up'} color={'#000000'} />
    ) : (
      <ContextMenuItemIcon icon={'carbon:chevron-down'} color={'#000000'} />
    ),
    label: 'Add chart component ',
    onClick: () => {
      setShowChartMenuItems((prevValue) => !prevValue)
      return false
    },
  })
  if (showChartMenuItems) {
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'bi:graph-down'} color={'#000000'} />,
      submenu: true,
      label: 'Plan View',
      onClick: () => {
        actions.addElement(elementType.chart, chartType.planView)
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'bi:graph-down'} color={'#000000'} />,
      submenu: true,
      label: 'Section View',
      onClick: () => {
        actions.addElement(elementType.chart, chartType.sectionView)
        return true
      },
    })
  }
  if (selectedUids.current && selectedUids.current.length > 0) {
    menuItems.push({
      isDivider: true,
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'fa-regular:trash-alt'} color={'#C00000'} />,
      label: 'Delete component...',
      onClick: () => {
        actions.deleteElements(selectedUids.current)
        return true
      },
    })
  }
  if (selectedUids.current && selectedUids.current.length > 1) {
    menuItems.push({
      isDivider: true,
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'gravity-ui:objects-align-left'} color={appColors.itemTextColor} />,
      label: 'Align left',
      onClick: () => {
        alignLeft()
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'gravity-ui:objects-align-right'} color={appColors.itemTextColor} />,
      label: 'Align right',
      onClick: () => {
        alignRight()
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'gravity-ui:objects-align-top'} color={appColors.itemTextColor} />,
      label: 'Align top',
      onClick: () => {
        alignTop()
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'gravity-ui:objects-align-bottom'} color={appColors.itemTextColor} />,
      label: 'Align bottom',
      onClick: () => {
        alignBottom()
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'ri:expand-width-fill'} color={appColors.itemTextColor} />,
      label: 'Make Same Width',
      onClick: () => {
        makeSameWidth()
        return true
      },
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'ri:expand-height-fill'} color={appColors.itemTextColor} />,
      label: 'Make Same Height',
      onClick: () => {
        makeSameHeight()
        return true
      },
    })
  }
  if (selectedUids.current && selectedUids.current.length === 1) {
    menuItems.push({
      isDivider: true,
    })
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'material-symbols:settings-outline'} color={appColors.itemTextColor} />,
      label: 'Properties...',
      onClick: () => {
        showElementProperties()
        return true
      },
    })
  }
  return menuItems
}
