import React from 'react'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material'

const BulkEditWarningModal = ({ title, message, callback, open }) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ color: 'red' }}>{message}</DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => callback(false)} color='secondary'>
          Discard
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            callback(true)
          }}
          color='primary'>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BulkEditWarningModal
