import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { throttle } from 'lodash'
import ConfirmDialog from 'components/common/ConfirmDialog'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import DropDownPicker from 'components/common/DropDownPicker'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { selectedTargetAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import TargetImportModal from './TargetImportModal'
import CreateDrillersTargetModal from './CreateDrillersTargetModal'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const TargetsListGrid = forwardRef(
  ({ isVisible, showHidePane, field = '', facility = '', well = '', actual = '', plan = '' }, ref) => {
    const _isMounted = useRef(false)
    const gridApi = useRef(null)
    const inputRow = useRef({})
    const [searchText, setSearchText] = useState('')
    const [resetCols, setResetCols] = useState(false)
    const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
    const [confirm, setConfirm] = useState({ show: false, title: '' })
    const [targetParentData, setTargetParentData] = useState(null)
    const targetParentDataRef = useRef(null)
    const [isLoading, setLoading] = useState(false)
    const isUpdating = useRef(false)
    const selectedTargetRef = useRef(null)
    const { getUnitsText } = useUnits()
    const [selectedTarget, setSelectedTarget] = useRecoilState(selectedTargetAtom)
    const [createTargetModalVisible, setCreateTargetModalVisible] = useState(false)
    const [importTargetModalVisible, setImportTargetModalVisible] = useState(false)
    const [shrinkPolygonModalVisible, setShrinkPolygonModalVisible] = useState(false)
    const [createDrillersTargetModalVisible, setCreateDrillersTargetModalVisible] = useState(false)
    const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor } = useInnovaTheme()

    const getTargetParentData = useInnovaAxios({
      url: '/targets/getParentData',
    })

    const shrinkPolygon = useInnovaAxios({
      url: '/targets/shrinkPolygon',
    })

    const getTargets = useInnovaAxios({
      url: '/targets/getTargets',
    })

    const deleteTarget = useInnovaAxios({
      url: '/targets/deleteTarget',
    })

    const createTarget = useInnovaAxios({
      url: '/targets/addTarget',
    })

    const copyTarget = useInnovaAxios({
      url: '/targets/copyTarget',
    })

    const updateTarget = useInnovaAxios({
      url: '/targets/updateTarget',
    })

    const calcTarget = useInnovaAxios({
      url: '/targets/calculateTarget',
    })

    const importTargets = useInnovaAxios({
      url: '/targets/importTargets',
    })

    const createDrillersTarget = useInnovaAxios({
      url: '/targets/calculateDrillersTarget',
    })

    useEffect(() => {
      selectedTargetRef.current = cloneDeep(selectedTarget)
    }, [selectedTarget])

    const getLevel = useCallback(() => {
      if (field !== '') return 'FIELD'
      if (facility !== '') return 'FACILITY'
      if (well !== '') return 'WELL'
      if (actual !== '') return 'ACTUAL'
      if (plan !== '') return 'PLAN'
      return 'UNKNOWN'
    }, [field, facility, well, actual, plan])

    const [levelFilter, setLevelFilter] = useState(getLevel())
    const levelFilterRef = useRef(getLevel())

    useEffect(() => {
      _isMounted.current = true
      fetchTargetParentData()
      return () => {
        _isMounted.current = false
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchTargetParentData = async () => {
      const res = await getTargetParentData({
        field: field,
        facility: facility,
        well: well,
        actualWell: actual,
        plan: plan,
      })

      if (!_isMounted.current) return

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      if (!res.data) return
      setTargetParentData(res.data)
      targetParentDataRef.current = cloneDeep(res.data)
    }

    const fetchTargets = async () => {
      if (isLoading) return
      setLoading(true)
      const res = await getTargets({
        field: field,
        facility: facility,
        well: well,
        actualWell: actual,
        plan: plan,
        getAll: true,
      })

      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      if (!Array.isArray(res.data)) return
      if (res.data.length === 0) return

      if (gridApi.current) {
        gridApi.current?.setGridOption('rowData', res.data)
      }
    }

    const throttledSetQuickFilter = throttle((text) => {
      if (gridApi.current) {
        gridApi.current.setGridOption('quickFilterText', text)
      }
    }, 300)

    useEffect(() => {
      throttledSetQuickFilter(searchText)
    }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

    const getRowId = useMemo(() => {
      return (params) => {
        return getStringId(params.data?.uid)
      }
    }, [])

    const onGridReady = (params) => {
      gridApi.current = params.api

      fetchTargets()
    }

    function onSelectionChanged() {
      const selectedRows = gridApi.current.getSelectedRows()

      if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
        setSelectedTarget(null)
        return
      }

      if (selectedTargetRef.current && gridApi.current) {
        gridApi.current.applyTransaction({
          update: [selectedTargetRef.current.orgData],
        })
      }

      setSelectedTarget({ orgData: cloneDeep(selectedRows[0]), data: cloneDeep(selectedRows[0]) })
    }

    function isPinnedRowDataCompleted() {
      if (!inputRow.current?.hasOwnProperty('targetName')) return false
      return true
    }

    const gridOptions = {
      pinnedBottomRowData: [inputRow.current],
      onCellEditingStopped: (event) => {
        if (event.node?.rowPinned === 'bottom') {
          handleAddTarget(event.data)
        }

        if (event.node?.rowPinned !== 'bottom') {
          let isInUseColumnUpdate = event.column.colId.includes('InUse')
          let isTargetNameUpdate = event.column.colId === 'targetName'
          let isTargetColorUpdate = event.column.colId === 'color'
          handleUpdate(event.data, isTargetNameUpdate, isTargetColorUpdate, isInUseColumnUpdate, false)
        }
      },
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
        ],
        defaultToolPanel: '',
        position: 'left',
      },
      onDragStopped: () => {
        saveColumnState()
      },
      onColumnVisible: () => {
        saveColumnState()
      },
      onSelectionChanged: onSelectionChanged,
      loadingOverlayComponent: CustomLoadingOverlay,
    }

    const defaultColDef = useMemo(() => {
      return {
        resizable: true,
        sortable: true,
        editable: false,
        autoHeight: true,
        headerClass: 'header-no-padding',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
      }
    }, [])

    const onFirstDataRendered = (params) => {
      if (gridApi.current) {
        gridApi.current.onFilterChanged()
      }

      autoSizeColumns()

      params.api.forEachNode((node) => {
        if (node.data && node?.rowPinned !== 'bottom') {
          node.rowIndex === 0 ? node.setSelected(true) : node.setSelected(false)
        }
      })
    }

    const autoSizeColumns = () => {
      if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
      gridApi.current?.autoSizeAllColumns()
    }

    const saveColumnState = () => {
      if (!gridApi.current) return

      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('targetsListGrid', 'colLayout', colLayout)
    }

    const getContextMenuItems = (params) => {
      return [
        {
          name: 'Reset columns',
          disabled: false,
          action: () => {
            gridApi.current.resetColumnState()
            saveItemToLS('targetsListGrid', 'colLayout', null)
            setResetCols(!resetCols)
          },
          icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>', //ask dan
          cssClasses: ['leftAlign'],
        },
        {
          name: 'Reset filters',
          disabled: false,
          action: () => {
            gridApi.current.setFilterModel(null)
          },
          icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        },
        'copy',
        {
          name: 'Export',
          disabled: false,
          action: () => {
            gridApi.current.exportDataAsExcel({
              fileName: 'Targets.xlsx',
              sheetName: 'Targets',
            })
          },
          icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        },
        {
          name: 'Refresh data',
          disabled: false,
          action: () => {
            fetchTargets()
          },
          icon: '<span class="iconify" data-icon="material-symbols:refresh" data-width="20" style="color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        },
        {
          name: 'Create tgt distance & azi',
          disabled: false,
          action: () => {
            setCreateTargetModalVisible(true)
          },
          icon: '<span class="iconify" data-icon="material-symbols:target" data-width="20" style="color:#00FF00"></span>',
          cssClasses: ['leftAlign'],
        },
        {
          name: 'Import Targets',
          disabled: false,
          action: () => {
            setImportTargetModalVisible(true)
          },
          icon: '<span class="iconify" data-icon="mdi:table-import" data-width="20" style="color:#00FF00"></span>',
          cssClasses: ['leftAlign'],
        },
      ]
    }

    const onClickDelete = useCallback(
      (data) => {
        setSelectedTarget({ orgData: cloneDeep(data), data: cloneDeep(data) })

        setConfirm({
          show: true,
          title: 'Delete Target',
          text: `Are you sure you want to delete ${data.targetName}?`,
        })
      },
      [setSelectedTarget],
    )

    function createPinnedCellPlaceholder({ colDef }) {
      if (colDef.field !== 'targetName') return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    }

    function isEmptyPinnedCell({ node, value }) {
      return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
    }

    const handleAddTarget = async (data) => {
      if (!isPinnedRowDataCompleted()) return

      let res = await createTarget({
        targetName: data.targetName,
        field: field,
        facility: facility,
        well: well,
        actualWell: actual,
        plan: plan,
        distance: 0,
        bearing: 0,
      })

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      gridApi.current.applyTransaction({
        add: [res.data],
      })

      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    const handleShrinkPolygonTarget = async (data) => {
      setShrinkPolygonModalVisible(false)

      let res = await shrinkPolygon({
        targetName: selectedTargetRef.current.data.targetName,
        field: field,
        facility: facility,
        well: well,
        actualWell: actual,
        plan: plan,
        distance: parseFloat(data.distance),
      })

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      gridApi.current.applyTransaction({
        add: [res.data],
      })
    }

    const handleCreateTargetFromDistAndAzi = async (data) => {
      setCreateTargetModalVisible(false)

      let res = await createTarget({
        targetName: data.targetName,
        field: field,
        facility: facility,
        well: well,
        actualWell: actual,
        plan: plan,
        distance: parseFloat(data.distance),
        bearing: parseFloat(data.bearing),
      })

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      gridApi.current.applyTransaction({
        add: [res.data],
      })
    }

    const handleDelete = async () => {
      if (!selectedTargetRef.current?.orgData) return
      let res = await deleteTarget({ targetName: selectedTargetRef.current.orgData.targetName })
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          remove: [selectedTargetRef.current.orgData],
        })

        setSelectedTarget(null)
      }
    }

    const handleDuplicateTarget = useCallback(
      async (data) => {
        if (!data) return

        let res = await copyTarget({
          targetName: data.targetName,
          field: targetParentDataRef.current.field,
          facility: targetParentDataRef.current.facility,
          well: targetParentDataRef.current.well,
          actualWell: targetParentDataRef.current.actual,
          plan: targetParentDataRef.current.plan,
        })

        if (res?.error) {
          setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
          return
        }

        if (gridApi.current) {
          gridApi.current.applyTransaction({
            add: [res.data],
          })
        }
      },
      [copyTarget],
    )

    const handleCreateDrillersTarget = async (wellboreNode, confidence) => {
      setCreateDrillersTargetModalVisible(false)
      if (!wellboreNode || !confidence) return
      if (!selectedTargetRef.current) return
      let res = await createDrillersTarget({
        targetName: selectedTargetRef.current.data.targetName,
        wellName: wellboreNode.name,
        confidence: confidence,
        isPlan: wellboreNode.level === 'PLANNEDWELL' || wellboreNode.level === 'PLANNEDWELL_PRINCIPAL',
      })

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      let tgtData = cloneDeep(selectedTargetRef.current)
      tgtData.data.drillingTargetPoints = Array.isArray(res.data?.drillingTargetPoints)
        ? res.data?.drillingTargetPoints
        : []
      tgtData.data.drillersTargetConfidence = res.data?.drillersTargetConfidence
      setSelectedTarget(tgtData)
    }

    //This function could do with cleaning up to better handle the different update options
    const handleUpdate = useCallback(
      async (data, saveName, saveColor, saveInUse, saveAll) => {
        if (!data) return

        if (isUpdating.current) return
        isUpdating.current = true
        setLoading(true)

        let payload = { targetData: JSON.stringify(htmlSymbolHandling(data)) }
        let res =
          saveInUse || saveAll || saveName || saveColor ? await updateTarget(payload) : await calcTarget(payload)

        isUpdating.current = false

        if (!_isMounted.current) return
        setLoading(false)

        if (res?.error) {
          setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
          return
        }

        if (!selectedTargetRef.current) return

        if (!saveInUse && !saveAll && !saveName) {
          let tgtData = cloneDeep(selectedTargetRef.current)
          tgtData.data.drillingTargetPoints = [] //Drillers target is reset every time target changes
          tgtData.data = cloneDeep(res.data)
          setSelectedTarget(tgtData)
        }

        if (saveInUse && !saveAll && !saveName) {
          let tgtData = cloneDeep(selectedTargetRef.current)
          tgtData.orgData.fieldInUse = cloneDeep(data.fieldInUse)
          tgtData.orgData.facilityInUse = cloneDeep(data.facilityInUse)
          tgtData.orgData.wellInUse = cloneDeep(data.wellInUse)
          tgtData.orgData.actualInUse = cloneDeep(data.actualInUse)
          tgtData.orgData.planInUse = cloneDeep(data.planInUse)

          tgtData.data.fieldInUse = cloneDeep(data.fieldInUse)
          tgtData.data.facilityInUse = cloneDeep(data.facilityInUse)
          tgtData.data.wellInUse = cloneDeep(data.wellInUse)
          tgtData.data.actualInUse = cloneDeep(data.actualInUse)
          tgtData.data.planInUse = cloneDeep(data.planInUse)
          setSelectedTarget(tgtData)
        }

        if (saveName) {
          let tgtData = cloneDeep(selectedTargetRef.current)
          tgtData.orgData.targetName = data.targetName
          tgtData.data.targetName = data.targetName
          setSelectedTarget(tgtData)
        }

        if (saveColor) {
          let tgtData = cloneDeep(selectedTargetRef.current)
          tgtData.orgData.color = data.color
          tgtData.data.color = data.color
          setSelectedTarget(tgtData)
        }

        if (saveAll) {
          let tgtData = cloneDeep(selectedTargetRef.current)
          tgtData.orgData = cloneDeep(res.data)
          tgtData.data = cloneDeep(res.data)
          setSelectedTarget(tgtData)
        }

        if (gridApi.current) {
          gridApi.current.applyTransaction({
            update: [res.data],
          })
        }
      },

      [updateTarget, calcTarget, setSelectedTarget],
    )

    const centerAlignCell = useMemo(
      () => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }),
      [],
    )

    const handleClearDrillersTarget = useCallback(() => {
      if (!selectedTargetRef.current) return
      let tgtData = cloneDeep(selectedTargetRef.current)
      tgtData.data.drillingTargetPoints = []
      setSelectedTarget(tgtData)
    }, [setSelectedTarget])

    const handleMenuClick = useCallback(
      (action, data) => {
        if (typeof action !== 'string') return
        if (!data) return

        if (action === 'delete') {
          onClickDelete(data)
        }

        if (action === 'duplicate') {
          handleDuplicateTarget(data)
        }

        if (action === 'drillersTarget') {
          setCreateDrillersTargetModalVisible(true)
        }

        if (action === 'clearDrillersTarget') {
          handleClearDrillersTarget(data)
        }

        if (action === 'shrinkPolygon') {
          setShrinkPolygonModalVisible(true)
        }
      },
      [
        onClickDelete,
        handleDuplicateTarget,
        setCreateDrillersTargetModalVisible,
        handleClearDrillersTarget,
        setShrinkPolygonModalVisible,
      ],
    )

    let columnDefs = useMemo(() => {
      let cols = [
        {
          headerName: '',
          colId: 'actions',
          cellStyle: centerAlignCell,
          cellRenderer: PopupCellRenderer,
          cellRendererParams: (params) => {
            let menuItems = [
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Duplicate',
                action: 'duplicate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='grommet-icons:copy' />
                  </StyledIconContainer>
                ),
              },
            ]

            if (!params.data?.isLeaseLine) {
              if (params.data?.geometry !== 'POINT') {
                menuItems.push({
                  label: 'Create Drillers Target',
                  action: 'drillersTarget',
                  onClick: handleMenuClick,
                  icon: () => (
                    <StyledIconContainer>
                      <StyledPopupMenuIcon icon='mingcute:target-fill' color='green' />
                    </StyledIconContainer>
                  ),
                })
              }

              if (Array.isArray(params.data?.drillingTargetPoints) && params.data?.drillingTargetPoints.length > 0) {
                menuItems.push({
                  label: 'Clear Drillers Target',
                  action: 'clearDrillersTarget',
                  onClick: handleMenuClick,
                  icon: () => (
                    <StyledIconContainer>
                      <StyledPopupMenuIcon icon='mingcute:target-fill' color='red' />
                    </StyledIconContainer>
                  ),
                })
              }
            }

            if (params.data?.geometry === 'POLYGON' || params.data?.geometry === 'LEASE LINE') {
              menuItems.push({
                label: 'Shrink Polygon Target',
                action: 'shrinkPolygon',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='ph:polygon-light' color='white' />
                  </StyledIconContainer>
                ),
              })
            }

            return {
              menuItems: menuItems,
            }
          },
          pinned: 'left',
          lockPosition: 'left',
          editable: false,
          suppressHeaderMenuButton: true,
          suppressHeaderFilterButton: true,
          resizable: false,
          filter: null,
          width: 70,
        },
        {
          field: 'targetName',
          colId: 'targetName',
          headerName: 'Name',
          pinned: 'left',
          lockPosition: 'left',
          editable: true,
          valueFormatter: (params) => {
            if (params.node?.rowPinned === 'bottom') return 'Name...'
            return unescapeHtml(params.value)
          },
        },
        {
          colId: 'type',
          headerName: `Type`,
          editable: (params) => {
            if (params.node?.rowPinned === 'bottom') return false
            return true
          },
          valueGetter: (params) => {
            if (params.node?.rowPinned === 'bottom') return ''
            if ((params.data?.isLeaseLine || params.data?.isHardLine) && params.data?.geometry === 'POLYGON') {
              return 'LEASE LINE'
            }
            return params.data?.geometry
          },
          valueSetter: (params) => {
            if (params.node?.rowPinned === 'bottom') return false
            if (params.newValue === params.oldValue) return false

            params.data.geometry = params.newValue

            if (params.newValue === 'LEASE LINE') {
              params.data.isLeaseLine = true
              params.data.geometry = 'POLYGON'
            }

            if (params.newValue === 'POLYGON') {
              params.data.isLeaseLine = false
            }

            params.data.thicknessUp = 0
            params.data.thicknessDn = 0

            if (params.data.geometry === 'CIRCLE') {
              params.data.circleProperties.arcStart = 0
              params.data.circleProperties.arcEnd = 360
              params.data.circleProperties.radius = 50
            }

            if (params.data.geometry === 'ELLIPSE') {
              params.data.ellipseProperties.arcStart = 0
              params.data.ellipseProperties.arcEnd = 360
              params.data.ellipseProperties.semiMajor = 50
              params.data.ellipseProperties.semiMinor = 20
            }

            if (params.data?.geometry === 'RECTANGLE') {
              params.data.rectProperties.length = 50
              params.data.rectProperties.width = 25
            }

            return true
          },
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: (params) => {
            return {
              values: ['POINT', 'RECTANGLE', 'CIRCLE', 'ELLIPSE', 'POLYGON', 'LEASE LINE'],
            }
          },
          cellStyle: centerAlignCell,
        },
        {
          colId: 'tvd',
          headerName: `TVD (${getUnitsText(UNITS_FOR.Depth)})`,
          editable: (params) => {
            if (params.node?.rowPinned === 'bottom') return false
            return true
          },
          valueGetter: (params) => {
            if (params.node?.rowPinned === 'bottom') return ''
            return params.data?.targetCenter.tvd
          },
          valueFormatter: (params) => {
            if (params.node?.rowPinned === 'bottom') return ''
            return numberWithCommasDecimals(params.value, 2)
          },
          valueSetter: (params) => {
            if (params.node?.rowPinned === 'bottom') return false
            if (params.newValue === params.oldValue) return false
            params.data.targetCenter.tvd = parseFloat(params.newValue)
            return true
          },
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            min: -10000,
            max: 100000,
            precision: 2,
          },
          cellStyle: centerAlignCell,
          suppressHeaderMenuButton: true,
          suppressHeaderFilterButton: true,
        },
        {
          field: 'color',
          colId: 'color',
          headerName: 'Color',
          cellEditor: ColorPickerCellEditor,
          cellEditorPopup: true,
          cellEditorParams: (params) => {
            return {
              color: params.value,
            }
          },
          editable: (params) => {
            if (params.node?.rowPinned === 'bottom') return false
            //if (params.data?.isLeaseLine) return false
            return true
          },
          cellStyle: (params) => {
            if (params.node?.rowPinned === 'bottom') return null
            return {
              ...centerAlignCell,
              backgroundColor: params.value || '#FF6347',
            }
          },
        },
        {
          colId: 'fieldInUse',
          headerName: 'Field',
          editable: false,
          valueGetter: (params) => {
            if (params.node?.rowPinned === 'bottom') return ''
            return true
          },
          cellRendererSelector: (params) => {
            if (params.node?.rowPinned === 'bottom') return null
            return {
              component: 'agCheckboxCellRenderer',
            }
          },
          cellStyle: centerAlignCell,
          suppressHeaderMenuButton: true,
          suppressHeaderFilterButton: true,
        },
      ]

      if (getLevel() === 'FIELD') return cols
      if (!targetParentData?.hasOwnProperty('field')) return

      cols.push({
        colId: 'facilityInUse',
        headerName: 'Facility',
        editable: true,
        valueGetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (!Array.isArray(params.data?.facilityInUse)) return false
          return params.data?.facilityInUse.findIndex((fac) => fac === targetParentData.facility) >= 0
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          if (params.newValue === params.oldValue) return false

          if (params.newValue) {
            params.data.facilityInUse = [...new Set([...params.data?.facilityInUse, targetParentData.facility])]
          }

          if (!params.newValue) {
            params.data.facilityInUse = params.data?.facilityInUse.filter((fac) => fac !== targetParentData.facility)
            params.data.wellInUse = params.data?.wellInUse.filter((well) => well !== targetParentData.well)
            params.data.actualInUse = params.data?.actualInUse.filter((act) => act !== targetParentData.actual)
            params.data.planInUse = params.data?.planInUse.filter((plan) => plan !== targetParentData.plan)
          }

          return true
        },
        cellRendererSelector: (params) => {
          if (params.node?.rowPinned === 'bottom') return null
          return {
            component: 'agCheckboxCellRenderer',
          }
        },
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      })

      if (getLevel() === 'FACILITY') return cols

      cols.push({
        colId: 'wellInUse',
        headerName: 'Well',
        editable: true,
        valueGetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (!Array.isArray(params.data?.wellInUse)) return false
          return params.data?.wellInUse.findIndex((well) => well === targetParentData.well) >= 0
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          if (params.newValue === params.oldValue) return false

          if (params.newValue) {
            params.data.facilityInUse = [...new Set([...params.data?.facilityInUse, targetParentData.facility])]
            params.data.wellInUse = [...new Set([...params.data?.wellInUse, targetParentData.well])]
          }

          if (!params.newValue) {
            params.data.wellInUse = params.data?.wellInUse.filter((well) => well !== targetParentData.well)
            params.data.actualInUse = params.data?.actualInUse.filter((act) => act !== targetParentData.actual)
            params.data.planInUse = params.data?.planInUse.filter((plan) => plan !== targetParentData.plan)
          }

          return true
        },
        cellRendererSelector: (params) => {
          if (params.node?.rowPinned === 'bottom') return null
          return {
            component: 'agCheckboxCellRenderer',
          }
        },
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      })

      if (getLevel() === 'WELL') return cols

      cols.push({
        colId: getLevel() === 'ACTUAL' ? 'actualInUse' : 'planInUse',
        headerName: getLevel() === 'ACTUAL' ? 'Actual' : 'Plan',
        editable: true,
        valueGetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (!Array.isArray(getLevel() === 'ACTUAL' ? params.data?.actualInUse : params.data?.planInUse)) return false
          if (getLevel() === 'ACTUAL')
            return params.data?.actualInUse.findIndex((act) => act === targetParentData.actual) >= 0
          return params.data?.planInUse.findIndex((plan) => plan === targetParentData.plan) >= 0
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          if (params.newValue === params.oldValue) return false

          if (params.newValue) {
            params.data.facilityInUse = [...new Set([...params.data?.facilityInUse, targetParentData.facility])]
            params.data.wellInUse = [...new Set([...params.data?.wellInUse, targetParentData.well])]

            if (getLevel() === 'ACTUAL') {
              params.data.actualInUse = [...new Set([...params.data?.actualInUse, targetParentData.actual])]
            }

            if (getLevel() === 'PLAN') {
              params.data.planInUse = [...new Set([...params.data?.planInUse, targetParentData.plan])]
            }
          }

          if (!params.newValue) {
            if (getLevel() === 'ACTUAL') {
              params.data.actualInUse = params.data?.actualInUse.filter((act) => act !== targetParentData.actual)
            }

            if (getLevel() === 'PLAN') {
              params.data.planInUse = params.data?.planInUse.filter((plan) => plan !== targetParentData.plan)
            }
          }

          return true
        },
        cellRendererSelector: (params) => {
          if (params.node?.rowPinned === 'bottom') return null
          return {
            component: 'agCheckboxCellRenderer',
          }
        },
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      })

      return cols
    }, [getUnitsText, centerAlignCell, getLevel, targetParentData, handleMenuClick])

    const handleCloseStatus = () => {
      setStatus({ show: false, severity: 'info', message: '' })
    }

    const getFilterOptions = () => {
      let options = [{ label: 'FIELD', value: 'FIELD' }]
      if (getLevel() === 'FIELD') return options
      options.push({ label: 'FACILITY', value: 'FACILITY' })
      if (getLevel() === 'FACILITY') return options
      options.push({ label: 'WELL', value: 'WELL' })
      if (getLevel() === 'WELL') return options

      if (getLevel() === 'PLAN') {
        options.push({ label: 'PLAN', value: 'PLAN' })
        return options
      }

      if (getLevel() === 'ACTUAL') {
        options.push({ label: 'ACTUAL', value: 'ACTUAL' })
        return options
      }

      return options
    }

    const isExternalFilterPresent = useCallback(() => {
      return true
    }, [])

    const doesExternalFilterPass = useCallback((node) => {
      if (node.data && targetParentDataRef.current) {
        if (levelFilterRef.current === 'FIELD') return true
        if (levelFilterRef.current === 'FACILITY') {
          return node.data.facilityInUse?.findIndex((fac) => fac === targetParentDataRef.current.facility) >= 0
        }
        if (levelFilterRef.current === 'WELL') {
          return node.data.wellInUse?.findIndex((well) => well === targetParentDataRef.current.well) >= 0
        }

        if (levelFilterRef.current === 'PLAN') {
          return node.data.planInUse?.findIndex((plan) => plan === targetParentDataRef.current.plan) >= 0
        }

        if (levelFilterRef.current === 'ACTUAL') {
          return node.data.actualInUse?.findIndex((act) => act === targetParentDataRef.current.actual) >= 0
        }

        return false
      }
      return false
    }, [])

    const navigateToNextCell = (params) => {
      if (params.key !== 'ArrowDown' && params.key !== 'ArrowUp') {
        return params.nextCellPosition
      }

      params.api.forEachNode((node) => {
        if (node.rowIndex === params.nextCellPosition.rowIndex) {
          node.setSelected(true)
        }
      })

      return params.nextCellPosition
    }

    useImperativeHandle(ref, () => {
      return {
        discardChanges() {
          if (!selectedTargetRef.current) return
          if (!gridApi.current) return

          setSelectedTarget({
            orgData: cloneDeep(selectedTargetRef.current.orgData),
            data: cloneDeep(selectedTargetRef.current.orgData),
          })

          gridApi.current.applyTransaction({
            update: [selectedTargetRef.current.orgData],
          })
        },
        commitChanges() {
          if (!selectedTargetRef.current) return
          handleUpdate(selectedTargetRef.current?.data, false, false, false, true)
        },
        calculateTarget(data) {
          handleUpdate(data, false, false, false, false)
        },
      }
    })

    const getDatumText = () => {
      if (!targetParentDataRef.current) return ''
      let localRef = targetParentDataRef.current.field
      if (getLevel() === 'FACILITY') localRef = targetParentDataRef.current.facility
      if (getLevel() === 'WELL') localRef = targetParentDataRef.current.well
      if (getLevel() === 'ACTUAL') localRef = targetParentDataRef.current.actual
      if (getLevel() === 'PLAN') localRef = targetParentDataRef.current.plan

      return `TVDs relative to ${targetParentDataRef.current?.datumName} @ ${numberWithCommasDecimals(
        targetParentDataRef.current?.datumElevation,
        2,
      )} (${getUnitsText(UNITS_FOR.Depth)}), Local coords relative to: ${localRef}`
    }

    const CreateTargetFromDistBearingModal = () => {
      let formValidationSchema = yup.object({
        targetName: yup.string().required(),
        distance: yup
          .string()
          .required()
          .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
        bearing: yup
          .string()
          .required()
          .test('numericTest', 'only numeric values between 0 and 360 are allowed', function (data) {
            let input = data
            if (typeof input === 'string') input = Number(input)
            if (isNaN(input)) return false
            if (input < 0 || input > 360) return false
            return true
          }),
      })

      let formData = [
        {
          tag: 'targetName',
          value: 'New Target',
          text: 'Name',
          inputType: 'text',
        },
        {
          tag: 'distance',
          value: 0,
          text: 'Distance',
          inputType: 'text',
        },
        {
          tag: 'bearing',
          value: 0,
          text: 'Azimuth',
          inputType: 'text',
        },
      ]

      return (
        <InputModal
          open={createTargetModalVisible}
          onClose={() => setCreateTargetModalVisible(false)}
          title={'Target Details'}
          formData={formData}
          submitForm={handleCreateTargetFromDistAndAzi}
          cancelFunction={() => setCreateTargetModalVisible(false)}
          validationSchema={formValidationSchema}
        />
      )
    }

    const ShrinkPolygonModal = () => {
      let formValidationSchema = yup.object({
        distance: yup
          .string()
          .required()
          .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      })

      let formData = [
        {
          tag: 'distance',
          value: 0,
          text: 'Distance',
          inputType: 'text',
        },
      ]

      return (
        <InputModal
          open={shrinkPolygonModalVisible}
          onClose={() => setShrinkPolygonModalVisible(false)}
          title={'Shrink Polygon Target'}
          formData={formData}
          submitForm={handleShrinkPolygonTarget}
          cancelFunction={() => setShrinkPolygonModalVisible(false)}
          validationSchema={formValidationSchema}
        />
      )
    }

    const handleImport = async (targets, useLocalCoords, useTvdSs) => {
      setImportTargetModalVisible(false)

      if (!Array.isArray(targets) || targets.length === 0) return
      if (isUpdating.current) return
      isUpdating.current = true
      setLoading(true)

      for (let i = 0; i < targets.length; i++) {
        targets[i] = htmlSymbolHandling(targets[i])
      }

      let res = await importTargets({
        targetData: JSON.stringify(targets),
        field: field,
        facility: facility,
        well: well,
        actualWell: actual,
        plan: plan,
        inputMeth: useLocalCoords ? 'LOCAL' : 'MAP',
        useTvdSs: useTvdSs,
      })

      isUpdating.current = false
      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res?.error?.response?.data?.error })
        return
      }

      if (!Array.isArray(res.data) || res.data.length === 0) return

      gridApi.current.applyTransaction({
        add: res.data,
      })
    }

    return (
      <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
        {confirm.show ? (
          <ConfirmDialog
            title={confirm?.title}
            open={confirm?.show}
            setOpen={() => setConfirm({ show: false })}
            onConfirm={() => handleDelete()}>
            {confirm?.text}
          </ConfirmDialog>
        ) : null}
        {createTargetModalVisible ? <CreateTargetFromDistBearingModal /> : null}
        {importTargetModalVisible ? (
          <TargetImportModal handleImport={handleImport} onCancel={() => setImportTargetModalVisible(false)} />
        ) : null}
        {createDrillersTargetModalVisible ? (
          <CreateDrillersTargetModal
            handleCreate={handleCreateDrillersTarget}
            onCancel={() => setCreateDrillersTargetModalVisible(false)}
            field={targetParentDataRef.current?.field}
          />
        ) : null}
        {shrinkPolygonModalVisible ? <ShrinkPolygonModal /> : null}
        <Box
          sx={{
            width: '100%',
            height: '25px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: getWindowBarColor(),
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              marginLeft: 'auto',
              width: '25px',
              height: '25px',
              backgroundColor: '#222628',
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '40px',
            paddingTop: '5px',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: getWindowBarColor(),
            display: isVisible ? 'flex' : 'none',
          }}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle(3)}
          />
          <Box
            sx={{
              width: '200px',
              height: '50px',
              marginLeft: '5px',
              marginRight: '5px',
            }}>
            <DropDownPicker
              backgroundColor='#111'
              listOptions={getFilterOptions()}
              value={levelFilter}
              onChange={(newValue) => {
                levelFilterRef.current = newValue
                setLevelFilter(newValue)
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: isVisible ? 'flex' : 'none',
            flexDirection: 'row',
            width: '100%',
            height: 'calc(100% - 90px)',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              loading={isLoading}
              columnDefs={sortColDefs(columnDefs, 'targetsListGrid')}
              defaultColDef={defaultColDef}
              getRowId={getRowId}
              animateRows={true}
              enableBrowserTooltips={true}
              gridOptions={gridOptions}
              rowSelection={'single'}
              headerHeight={30}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
              isExternalFilterPresent={isExternalFilterPresent}
              doesExternalFilterPass={doesExternalFilterPass}
              navigateToNextCell={navigateToNextCell}
            />
          </div>
          {status?.show ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={status?.show}
              autoHideDuration={2000}
              onClose={handleCloseStatus}>
              <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
                {status.message}
              </Alert>
            </Snackbar>
          ) : null}
        </Box>
        {isVisible ? (
          <Box
            sx={{
              width: '100%',
              height: '25px',
              borderLeft: '1px solid gray',
              borderRight: '1px solid gray',
              backgroundColor: getWindowBarColor(),
              color: 'white',
              paddingLeft: '10px',
            }}>
            {getDatumText()}
          </Box>
        ) : null}
        {!isVisible ? (
          <Box
            sx={{
              width: '25px',
              height: '100%',
              backgroundColor: getWindowBarColor(),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box
              onClick={showHidePane}
              sx={{
                transform: 'rotate(90deg)',
                color: '#f0f0f0',
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                '&:hover': {
                  color: '#429ceb',
                },
              }}>
              Targets List
            </Box>
          </Box>
        ) : null}
      </Box>
    )
  },
)

export default TargetsListGrid
