import React, { useRef, useMemo, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { focusedSurveySelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import { uuidv4 } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { leaseLineProximity } from 'utils/threeDeeScan'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const RealTimeAc = ({ wellName, principalPlanName, projections, leaseLines }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const isLoadingRef = useRef(false)
  const [acData, setAcData] = useState([])
  const focusedSurvey = useRecoilValue(focusedSurveySelector)
  const { getUnitConversion } = useUnits()
  const lastCalculationMd = useRef(-1)
  const { getAgGridTheme } = useInnovaTheme()

  const getAcData = useAxiosGzip({
    url: '/antiCollision/getWellAntiCollisionGz',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchAcData()
  }, [focusedSurvey, wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lastCalculationMd.current !== focusedSurvey?.md && lastCalculationMd.current >= 0) {
      fetchAcData()
    }
  }, [acData]) // eslint-disable-line react-hooks/exhaustive-deps

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const centerAlignCellOrange = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'orange',
    color: 'white',
  })

  const centerAlignCellRed = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
    color: 'white',
  })

  const fetchAcData = async () => {
    if (isLoading) return
    if (isLoadingRef.current) return
    if (_isMounted.current) setLoading(true)

    if (!focusedSurvey) {
      setAcData([])
      setLoading(false)
      return
    }

    isLoadingRef.current = true
    lastCalculationMd.current = focusedSurvey?.md
    const res = await getAcData({
      projections: Array.isArray(projections) ? JSON.stringify(projections) : '',
      wellName: wellName,
      pointsOfInterest: JSON.stringify([focusedSurvey?.md - 0.1]),
      calcSeperationFactor: true,
    })

    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setAcData([])
      return
    }

    if (!Array.isArray(res.data.wells)) {
      setAcData([])
      return
    }

    let rtAcOutput = []
    for (let i = 0; i < res.data.wells.length; i++) {
      if (!Array.isArray(res.data.wells[i].acResults)) continue
      let wellName = res.data.wells[i].wellName

      res.data.wells[i].acResults.forEach((station) => {
        let newStation = { ...station }
        newStation.offsetName = wellName
        newStation.refMd = newStation.refStation.md
        newStation.refInc = newStation.refStation.inc
        newStation.refAzi = newStation.refStation.azi
        newStation.offMd = newStation.offStation.md
        newStation.offInc = newStation.offStation.inc
        newStation.offAzi = newStation.offStation.azi
        newStation.offNS = newStation.offStation.ns
        newStation.offEW = newStation.offStation.ew
        newStation.offTVD = newStation.offStation.tvd
        newStation.isLeaseLine = false
        rtAcOutput.push({ ...newStation })
      })
    }

    if (Array.isArray(leaseLines) && leaseLines.length > 0) {
      for (let i = 0; i < leaseLines.length; i++) {
        if (!Array.isArray(leaseLines[i].polygonPoints)) continue
        if (leaseLines[i].polygonPoints.length === 0) continue

        let points = leaseLines[i].polygonPoints.map((pt) => pt.top)
        let leaseLineAcResult = leaseLineProximity(focusedSurvey, points, true)
        if (leaseLineAcResult.dist < 0) continue
        leaseLineAcResult.offsetName = leaseLines[i].targetName
        rtAcOutput.push(leaseLineAcResult)
      }
    }

    for (let i = 0; i < rtAcOutput.length; i++) {
      rtAcOutput[i].uid = uuidv4()
    }

    rtAcOutput.sort((a, b) => {
      if (a.C2C < b.C2C) return -1
      if (a.C2C > b.C2C) return 1
      return 0
    })

    setAcData(rtAcOutput)
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      filter: 'agSetColumnFilter',
      headerClass: 'header-no-padding',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('rtAcGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('rtAcGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AcData.xlsx',
            sheetName: 'AcData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const columnDefs = [
    {
      headerName: 'Type',
      colId: 'type',
      field: 'type',
      wrapText: true,
      autoHeight: true,
      cellStyle: (params) => {
        if (params.data?.offsetName === principalPlanName) {
          return { alignItem: 'end', border: '2px solid blue' }
        }

        if (params.data?.isLeaseLine) {
          return { alignItem: 'end', border: '2px solid cyan' }
        }

        return { alignItem: 'end' }
      },
      pinned: 'left',
      lockPosition: 'left',
      valueGetter: (params) => {
        if (params?.data?.isLeaseLine) return 'Lease Line'
        if (params.data?.offsetName === principalPlanName) return 'Wellplan'
        return 'Offset'
      },
      minWidth: 110,
    },
    {
      headerName: 'Name',
      colId: 'offsetName',
      field: 'offsetName',
      wrapText: true,
      autoHeight: true,
      cellStyle: (params) => {
        return { alignItem: 'end' }
      },
      maxWidth: 500,
      minWidth: 300,
      pinned: 'left',
      lockPosition: 'left',
    },
    {
      headerName: `C2C`,
      colId: 'c2c',
      field: 'C2C',
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: (params) => {
        if (params.data?.offsetName === principalPlanName) return centerAlignCell
        if (params.data?.C2C > 150 * getUnitConversion(UNITS_FOR.Depth, true)) return centerAlignCell
        if (params.data?.C2C > 50 * getUnitConversion(UNITS_FOR.Depth, true)) return centerAlignCellOrange
        return centerAlignCellRed
      },
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(params.data?.C2C, 2)}`
      },
    },
    {
      headerName: `SF`,
      colId: 'sf',
      field: 'SF',
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: (params) => {
        if (params.data?.offsetName === principalPlanName) return centerAlignCell
        if (params.data?.isLeaseLine) return centerAlignCell
        if (params.data?.SF > 3 || params.data?.SF === -999.0) return centerAlignCell
        if (params.data?.SF > 1.5) return centerAlignCellOrange
        return centerAlignCellRed
      },
      valueFormatter: (params) => {
        if (params.data?.SF === -999.0) return ''
        if (params.data?.isLeaseLine) return ''
        if (params.data?.offsetName === principalPlanName) return ''
        return `${numberWithCommasDecimals(params.data?.SF, 2)}`
      },
    },
    {
      headerName: 'Warning',
      colId: 'warning',
      field: 'warning',
      cellStyle: { alignItem: 'end' },
      valueFormatter: (params) => {
        if (params.data?.offsetName === principalPlanName) return ''
        if (params.data?.isLeaseLine) return ''
        return params.value
      },
    },
    {
      headerName: `ES`,
      colId: 'es',
      field: 'ES',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.offsetName === principalPlanName) return ''
        if (params.data?.isLeaseLine) return ''
        if (params.data?.ES === -999.0) return ''
        return `${numberWithCommasDecimals(params.data?.ES, 2)}`
      },
    },
    {
      headerName: `Ref Ellipse`,
      colId: 'refEllip',
      field: 'refEllip',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.offsetName === principalPlanName) return ''
        if (params.data?.isLeaseLine) return ''
        return `${numberWithCommasDecimals(params.data?.refEllip, 2)}`
      },
    },
    {
      headerName: `Off Ellipse`,
      colId: 'offEllip',
      field: 'offEllip',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.offsetName === principalPlanName) return ''
        if (params.data?.isLeaseLine) return ''
        return `${numberWithCommasDecimals(params.data?.offEllip, 2)}`
      },
    },
    {
      headerName: `Ref MD`,
      colId: 'refMd',
      field: 'refMd',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(params.data?.refMd, 2)}`
      },
    },
    {
      headerName: `Off MD`,
      colId: 'offMd',
      field: 'offMd',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.isLeaseLine) return ''
        return `${numberWithCommasDecimals(params.data?.offMd, 2)}`
      },
    },
    {
      headerName: `Off TVD`,
      colId: 'offTVD',
      field: 'offTVD',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.isLeaseLine) return ''
        return `${numberWithCommasDecimals(params.data?.offTVD, 2)}`
      },
    },
    {
      headerName: `Off NS`,
      colId: 'offNS',
      field: 'offNS',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(params.data?.offNS, 2)}`
      },
    },
    {
      headerName: `Off EW`,
      colId: 'offEW',
      field: 'offEW',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(params.data?.offEW, 2)}`
      },
    },
    {
      headerName: `Ref Csg OD`,
      colId: 'refCasingOd',
      field: 'refCasingOd',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.isLeaseLine) return ''
        return `${numberWithCommasDecimals(params.data?.refCasingOd, 3)}`
      },
    },
    {
      headerName: `Off Csg OD`,
      colId: 'offCasingOd',
      field: 'offCasingOd',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.data?.isLeaseLine) return ''
        return `${numberWithCommasDecimals(params.data?.offCasingOd, 3)}`
      },
    },
  ]

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onRowDataUpdated: (event) => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={acData}
        loading={isLoading}
        columnDefs={sortColDefs(columnDefs, 'rtAcGrid')}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        onGridReady={onGridReady}
        groupRowsSticky={true}
        gridOptions={gridOptions}
        getContextMenuItems={getContextMenuItems}
        onFirstDataRendered={onFirstDataRendered}
        rowDragManaged={true}
        headerHeight={30}
      />
    </div>
  )
}

export default RealTimeAc
