import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Tooltip } from '@mui/material'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { currentWellDetailsSelector } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import GlobalPrefs from 'components/NavBar/GlobalPrefs'
import SearchBar from 'components/common/SearchBar'
import {
  sortColDefs,
  dateComparator,
  relativeTime,
  CustomLoadingOverlay,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MultiDbWellListGrid = ({ wells, isLoading, setFilteredData, setSelectedWells }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const [groupRows, setGroupRows] = useState(false)
  const [searchText, setSearchText] = useState('')
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsSelector)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    if (!_isMounted.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (!Array.isArray(selectedRows)) {
      setSelectedWells([])
    }

    let newSelection = []
    for (let i = 0; i < selectedRows.length; i++) {
      newSelection.push({ ...selectedRows[i].data })
    }

    if (_isMounted.current) {
      setSelectedWells(newSelection)
    }
  }

  useEffect(() => {
    if (groupRows) expandAllNodes('expand')
  }, [groupRows]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
    if (gridApi.current) gridApi.current.onFilterChanged()
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [wells]) // eslint-disable-line react-hooks/exhaustive-deps

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('multiDbWellListGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('multiDbWellListGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WellsData.xlsx',
            sheetName: 'Wells',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const dateFilterComparator = useCallback(dateComparator, [])
  const relativeTimeFormatter = useCallback(relativeTime, [])

  const getTimeDurationColor = (timeSinceMins) => {
    if (timeSinceMins === null || timeSinceMins === undefined) return 'tomato'
    if (typeof timeSinceMins === 'string' && timeSinceMins === '') return 'tomato'
    if (typeof timeSinceMins === 'string') timeSinceMins = parseFloat(timeSinceMins)

    if (timeSinceMins > 240) return 'tomato'
    if (timeSinceMins > 60) return 'orange'
    return 'green'
  }

  const dateTimeFormatter = useMemo(
    () => (value) => {
      if (!value) return ''
      if (typeof value !== 'string') return ''
      if (value === '') return ''
      if (isDateLessThan(value, '1990-01-01')) return ''
      value = value.replace(/Z/g, '')

      return new Date(Date.parse(value)).toLocaleDateString('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        width: 60,
        colId: 'avatar',
        pinned: 'left',
        lockPosition: 'left',
        filter: null,
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
                '&:hover': { cursor: 'pointer' },
              }}
              onClick={(event) => {
                event.stopPropagation()
                setCurrentWellDetails({
                  actualWell: params.data?.actualWell,
                  wellStatus: params.data?.wellStatus,
                  operator: params.data?.operator,
                  rig: params.data?.rig,
                  jobNum: params.data?.jobNum,
                  lat: params.data?.latitude,
                  lng: params.data?.longitude,
                })
              }}>
              <Tooltip
                title='Select on map'
                placement='right'
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                    },
                  },
                }}>
                {params?.data.hasOwnProperty('wellStatus') ? (
                  <Avatar
                    alt={params?.data?.wellStatus}
                    src={getStatusAvatar(params?.data?.wellStatus)}
                    style={{ width: 35, height: 35 }}
                  />
                ) : null}
              </Tooltip>
            </Box>
          )
        },
      },
      {
        headerName: 'Organization',
        field: 'organization',
        colId: 'organization',
        rowGroup: groupRows,
        hide: groupRows,
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        rowGroup: groupRows,
        hide: groupRows,
        minWidth: 200,
        maxWidth: 400,
        valueGetter: (params) => {
          return unescapeHtml(params.data?.operator)
        },
      },
      {
        headerName: 'Well',
        field: 'actualWell',
        colId: 'well',
        minWidth: 300,
        maxWidth: 400,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                '&:hover': { cursor: 'pointer' },
              }}>
              {params.data?.actualWell}
            </Box>
          )
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return unescapeHtml(params.data?.rig)
        },
      },
      {
        headerName: 'State',
        field: 'state',
        colId: 'state',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        cellStyle: centerAlignCell,
        cellEditor: 'agRichSelectCellEditor',
      },
      {
        headerName: 'Status',
        field: 'wellStatus',
        colId: 'status',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: GetActivityColor(params.data?.wellStatus),
          }
        },
      },
      {
        headerName: 'Start Date',
        field: 'startDate',
        colId: 'startDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.startDate)
        },
      },
      {
        headerName: 'End Date',
        field: 'endDate',
        colId: 'endDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.endDate)
        },
      },
      {
        headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'currentDepth',
        colId: 'currentDepth',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.currentDepth, 2)
        },
      },
      {
        headerName: 'DEX',
        field: 'icdsMinsSinceUpdate',
        colId: 'icdsUpdate',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getTimeDurationColor(params.data?.icdsMinsSinceUpdate),
          }
        },
        valueGetter: (params) => {
          if (!params.data) return 99999999
          if (!params.data?.hasOwnProperty('icdsMinsSinceUpdate')) return 99999999
          if (params.data?.icdsMinsSinceUpdate === null || params.data?.icdsMinsSinceUpdate === undefined) {
            return 99999999
          }
          if (typeof params.data?.icdsMinsSinceUpdate === 'string' && params.data?.icdsMinsSinceUpdate === '') {
            return 99999999
          }

          return parseFloat(params.data?.icdsMinsSinceUpdate)
        },
        valueFormatter: (params) => relativeTimeFormatter(params.data?.icdsMinsSinceUpdate),
      },
    ],
    [getUnitsText, groupRows, dateFilterComparator, dateTimeFormatter, setCurrentWellDetails, relativeTimeFormatter],
  )

  const gridOptions = {
    detailRowHeight: 200,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current && setFilteredData) {
      setFilteredData(filteredNodes)
    }
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
    expandAllNodes('expand')
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const doesExternalFilterPass = useCallback((node) => {
    if (node.data) {
      let validStatus = ['active', 'standby', 'td', 'upcoming', 'batch']
      let wellStatus = node.data.wellStatus
      if (typeof wellStatus !== 'string') wellStatus = ''
      wellStatus = wellStatus.toLowerCase()

      return validStatus.findIndex((status) => status === wellStatus) >= 0
    }
    return true
  }, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent' },
      ],
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
        <GlobalPrefs isMultiDb={true} />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={wells}
          columnDefs={sortColDefs(columnDefs, 'multiDbWellListGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          masterDetail={true}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          onSelectionChanged={onSelectionChanged}
          doesExternalFilterPass={doesExternalFilterPass}
          statusBar={statusBar}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}

export default MultiDbWellListGrid
