import React, { useRef, useEffect, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom, databaseTreeLayoutAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { Snackbar } from '@mui/material'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import cloneDeep from 'lodash/cloneDeep'
import Alert from '@mui/material/Alert'
import { Box } from '@mui/material'
import DatabaseTree from './DatabaseTree/DatabaseTree'
import OperatorPage from './Operator/OperatorPage'
import WellPage from './Well/WellPage'
import FieldPage from './Field/FieldPage'
import FacilityPage from './Facility/FacilityPage'
import SurveysGridChartPage from './SurveyGridChart/SurveyGridChartPage'
import DatabaseVersionPage from './DatabaseVersionPage'
import SurveyPage from './Survey/SurveyPage'
import ActualWellPage from './ActualWell/ActualWellPage'
import PlannedWellPage from './PlannedWell/PlannedWellPage'

const DatabaseTreePage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [pageLayout, setPageLayout] = useRecoilState(databaseTreeLayoutAtom)
  const [selectedNode, setSelectedNode] = useState({ level: 'DATABASE' })

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showDatabaseTree, setShowDatabaseTree] = useState(getPaneVisible(0))

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.dbTreePageKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '30%' : '30%'
    if (index >= pageLayout.length) return index === 0 ? '30%' : '30%'
    return pageLayout[index].size
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showDatabaseTree)
    setShowDatabaseTree(!showDatabaseTree)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const PropertiesPane = () => {
    if (selectedNode?.level === 'DATABASE' || !selectedNode) {
      return <DatabaseVersionPage />
    }

    if (selectedNode?.level === 'OPERATOR') {
      return <OperatorPage node={selectedNode} />
    }

    if (selectedNode?.level === 'FIELD') {
      return <FieldPage node={selectedNode} />
    }

    if (selectedNode?.level === 'FACILITY') {
      return <FacilityPage node={selectedNode} />
    }

    if (selectedNode?.level === 'WELL') {
      return <WellPage node={selectedNode} />
    }

    if (selectedNode?.level === 'ACTUALWELL') {
      return <ActualWellPage node={selectedNode} />
    }

    if (selectedNode?.level === 'PLANNEDWELL' || selectedNode?.level === 'PLANNEDWELL_PRINCIPAL') {
      return <PlannedWellPage node={selectedNode} />
    }

    if (selectedNode?.level === 'SURVEY') {
      return <SurveyPage node={selectedNode} />
    }

    if (selectedNode?.level === 'ACTUAL_WELLBORE') {
      return (
        <SurveysGridChartPage
          wellName={selectedNode?.name.replace('-Actual Wellbore', '')}
          isWellbore={true}
          isPlan={false}
        />
      )
    }

    if (selectedNode?.level === 'PLANNED_WELLBORE') {
      return (
        <SurveysGridChartPage
          isPlan={true}
          wellName={selectedNode?.name.replace('-Planned Wellbore', '')}
          isWellbore={true}
        />
      )
    }

    return <Box sx={{ width: '100%', height: '100%', backgroundColor: 'blue' }} />
  }

  return (
    <React.Fragment>
      <SplitPane
        split='vertical'
        defaultSize={getInitialPaneSize(0)}
        size={showDatabaseTree ? getInitialPaneSize(0) : '25px'}
        allowResize={showDatabaseTree}
        style={{
          height: 'calc(100vh - 64px)',
          marginLeft: leftPos,
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
        }}>
        <DatabaseTree
          setSelectedNode={setSelectedNode}
          selectedNode={selectedNode}
          showHideTree={showHidePane}
          treeVisible={showDatabaseTree}
          setStatus={setStatus}
        />
        <PropertiesPane />
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DatabaseTreePage
