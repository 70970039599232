import React from 'react'
import { Bar } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import { appColors } from 'utils'
import { useRecoilValue } from 'recoil'
import { currentInfrastructureAcDashSelector } from 'atoms'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const AcDashOrgBarChart = () => {
  const data = useRecoilValue(currentInfrastructureAcDashSelector)
  const { theme, getChartBackColor } = useInnovaTheme()

  const options = {
    layout: {
      padding: {
        right: 20,
        left: 20,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: false,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return `${context.parsed.y} - ${context.dataset.label}`
          },
        },
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(140, 140, 140)',
        },
        ticks: {
          color: appColors.headerTextColor,
        },
      },
      y: {
        grid: {
          color: 'rgba(140, 140, 140)',
        },
        ticks: {
          color: appColors.headerTextColor,
        },
      },
    },
  }

  const createChartData = () => {
    const chartData = {
      labels: [],
      datasets: [
        {
          label: 'Wells',
          data: [],
          borderColor: '#5cdb2a',
          backgroundColor: '#5cdb2a',
          maxBarThickness: 24,
        },
        {
          label: 'Errors',
          data: [],
          borderColor: '#db502a',
          backgroundColor: '#db502a',
          maxBarThickness: 24,
        },
      ],
    }

    if (!Array.isArray(data?.organizations)) return chartData
    chartData.labels = Array.from(new Set(data.organizations.map((item) => item.organization)))

    for (let i = 0; i < chartData.datasets.length; i++) {
      chartData.datasets[i].data = Array(chartData.labels.length).fill(0)
    }

    data?.organizations.forEach((item) => {
      const seriesIndex = chartData.labels.indexOf(item.organization)
      chartData.datasets[0].data[seriesIndex] = item.numWells
      chartData.datasets[1].data[seriesIndex] = Array.isArray(item.errors) ? item.errors.length : 0
    })

    return chartData
  }

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: getChartBackColor(),
      }}>
      <Bar type='bar' options={options} data={createChartData()} />
    </Box>
  )
}

export default AcDashOrgBarChart
