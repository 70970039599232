import React from 'react'
import { round } from 'utils/numberFunctions'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SurveyWidget = ({ svy1, svy2 }) => {
  const { getTreeBackColor, theme } = useInnovaTheme()

  const WidgetHeader = ({ children }) => {
    return (
      <Box
        sx={{
          color: theme === 'dark' ? 'rgba(192, 192, 192, 1.0)' : '#000',
          fontSize: '0.85em',
        }}>
        {children}
      </Box>
    )
  }

  const WidgetValue = ({ children }) => {
    return (
      <Box
        sx={{
          color: 'rgba(52,201,235,1.0)',
          fontSize: '0.85em',
          fontWeight: 'bold',
          marginLeft: '5px',
          marginRight: '5px',
        }}>
        {children}
      </Box>
    )
  }

  const Container = ({ children }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flex: '1',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}>
        {children}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: getTreeBackColor(),
        border: '1px solid black',
        boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
        padding: '4px',
      }}>
      <Container>
        <WidgetHeader>Type</WidgetHeader>
        <WidgetHeader>Svy</WidgetHeader>
        <WidgetHeader>Bit</WidgetHeader>
      </Container>
      <Container>
        <WidgetHeader>MD</WidgetHeader>
        <WidgetValue>{round(svy1?.md, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.md, 2)}</WidgetValue>
      </Container>
      <Container>
        <WidgetHeader>INC</WidgetHeader>
        <WidgetValue>{round(svy1?.inc, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.inc, 2)}</WidgetValue>
      </Container>
      <Container>
        <WidgetHeader>AZI</WidgetHeader>
        <WidgetValue>{round(svy1?.azi, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.azi, 2)}</WidgetValue>
      </Container>
      <Container>
        <WidgetHeader>TVD</WidgetHeader>
        <WidgetValue>{round(svy1?.tvd, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.tvd, 2)}</WidgetValue>
      </Container>
      <Container>
        <WidgetHeader>DLS</WidgetHeader>
        <WidgetValue>{round(svy1?.dls, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.dls, 2)}</WidgetValue>
      </Container>
      <Container>
        <WidgetHeader>UD</WidgetHeader>
        <WidgetValue>{round(svy1?.acScan?.UD, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.acScan?.UD, 2)}</WidgetValue>
      </Container>
      <Container>
        <WidgetHeader>LR</WidgetHeader>
        <WidgetValue>{round(svy1?.acScan?.LR, 2)}</WidgetValue>
        <WidgetValue>{round(svy2?.acScan?.LR, 2)}</WidgetValue>
      </Container>
    </Box>
  )
}

export default SurveyWidget
