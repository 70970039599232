import { Box } from '@mui/material'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import EngineeringChart from './EngineeringChart'

const TndChartsReport = forwardRef((props, ref) => {
  const drillersHookloadsRef = useRef(null)
  const calculatedFrictionFactorsRef = useRef(null)
  const drillersTorquesRef = useRef(null)
  const tensionSnapshotRef = useRef(null)
  const tensionOffBtmSnapshotRef = useRef(null)
  const sideForceRef = useRef(null)
  const appWobRef = useRef(null)
  const wrapsRef = useRef(null)
  const stretchRef = useRef(null)
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => {
    return {
      getChartType(chartType) {
        if (chartType === 'drillersHookloads') return drillersHookloadsRef
        if (chartType === 'calculatedFrictionFactors') return calculatedFrictionFactorsRef
        if (chartType === 'drillersTorques') return drillersTorquesRef
        if (chartType === 'tensionSnapshot') return tensionSnapshotRef
        if (chartType === 'tensionOffBtmSnapshot') return tensionOffBtmSnapshotRef
        if (chartType === 'sideForce') return sideForceRef
        if (chartType === 'appWob') return appWobRef
        if (chartType === 'wraps') return wrapsRef
        if (chartType === 'stretch') return stretchRef
        return null
      },
    }
  })

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box ref={drillersHookloadsRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.drillersHookloadsData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Drillers Hookloads'}
          xAxisLabel={'Hookload'}
          units={getUnitsText(UNITS_FOR.Weight)}
          printing={true}
        />
      </Box>
      <Box ref={calculatedFrictionFactorsRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.calculatedFrictionFactorData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Calculated Friction Factors'}
          xAxisLabel={'Friction Factor'}
          units={''}
          printing={true}
        />
      </Box>
       <Box ref={drillersTorquesRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.drillersTorquesData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Drillers Torques'}
          xAxisLabel={'Torque'}
          units={getUnitsText(UNITS_FOR.Torque)}
          printing={true}
        />
      </Box>
      <Box ref={tensionSnapshotRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.tensionSnapshotData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'On Bottom Tension Snapshot'}
          xAxisLabel={'Tension'}
          units={getUnitsText(UNITS_FOR.Weight)}
          printing={true}
        />
      </Box>
      <Box ref={tensionOffBtmSnapshotRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.tensionOffBtmSnapshotData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Off Bottom Tension Snapshot'}
          xAxisLabel={'Tension'}
          units={getUnitsText(UNITS_FOR.Weight)}
          printing={true}
        />
      </Box>
      <Box ref={sideForceRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.sideForceData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Side Force'}
          xAxisLabel={'Side Force'}
          units={props.props.sideForceUnits}
          printing={true}
        />
      </Box>
      <Box ref={appWobRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.appWobData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={`Apparent WOB - ${props.props.wobText}`}
          xAxisLabel={'Apparent WOB'}
          units={getUnitsText(UNITS_FOR.Weight)}
          printing={true}
        />
      </Box>
      <Box ref={wrapsRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.wrapsData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Pipe Twist'}
          xAxisLabel={'Wraps'}
          units={''}
          printing={true}
        />
      </Box>
      <Box ref={stretchRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.stretchData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Pipe Stretch'}
          xAxisLabel={'Stretch'}
          units={'in'}
          printing={true}
        />
      </Box> 
    </Box>
  )
})

export default TndChartsReport
