import React, { useState } from 'react'

import Dialog from '@mui/material/Dialog'
import { HexColorPicker } from 'react-colorful'
import { DialogContent, DialogActions, Button } from '@mui/material'

const ColorPickerModal = ({ open, onClose, color, onChange = null }) => {
  const [selectedColor, setSelectedColor] = useState(color)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '250px',
          height: '300px',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogContent style={{ overflow: 'auto' }}>
        <HexColorPicker color={selectedColor} onChange={(e) => setSelectedColor(e)} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
        {onChange !== null ? (
          <Button
            variant='contained'
            onClick={() => {
              onChange(selectedColor)
              onClose()
            }}
            color='primary'>
            Apply
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

export default ColorPickerModal
