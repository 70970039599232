import React, { useEffect, useRef } from 'react'
import { getRandomColor } from 'utils/colorFunctions'
import { Bar } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import { appColors, chartSeriesColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const EndpointUsageBarChart = ({ data }) => {
  const _isMounted = useRef(false)
  const chartRef = useRef()
  const { theme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const chartOptions = {
    layout: {
      padding: {
        right: 20,
        left: 20,
        top: 20,
        bottom: 20,
      },
    },
    animation: false,
    hover: {
      animationDuration: 0,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: appColors.headerTextColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: appColors.headerTextColor,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  const createChartData = () => {
    const chartData = {
      labels: [],
      datasets: [],
    }

    if (!Array.isArray(data)) return chartData
    let uniquePaths = Array.from(new Set(data.map((item) => item.path)))
    let pathTotals = uniquePaths.map((path) => {
      return data.filter((item) => item.path === path).length
    })

    chartData.labels = uniquePaths.filter((path, index) => pathTotals[index] > 1000)
    const users = Array.from(new Set(data.map((item) => item.user)))

    for (let i = 0; i < users.length; i++) {
      chartData.datasets.push({
        label: users[i],
        data: chartData.labels.map((label) => {
          return { x: label, y: 0 }
        }),
        backgroundColor: i < chartSeriesColors.length - 1 ? chartSeriesColors[i] : getRandomColor(),
      })
    }

    for (let i = 0; i < data.length; i++) {
      const seriesIndex = chartData.labels.indexOf(data[i].path)
      const userIndex = users.indexOf(data[i].user)
      if (seriesIndex === -1 || userIndex === -1) continue

      chartData.datasets[userIndex].data[seriesIndex].y++
    }

    return chartData
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '99.9%',
        overflow: 'hidden',
        backgroundColor: getChartBackColor(),
      }}>
      <Bar ref={chartRef} type='bar' options={chartOptions} data={createChartData()} />
    </Box>
  )
}

export default EndpointUsageBarChart
