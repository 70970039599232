import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import { appColors } from 'utils'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import WallPlotChart from 'components/WellPages/WallplotComposer/Elements/ChartElements/WallPlotChart'
import { cloneDeep } from 'lodash'
import {
  LABEL_CATEGORIES as labelCategories,
  generateAllLabels,
} from 'components/WellPages/WallplotComposer/Elements/ChartElements/labelDefinitions'

export const getChartElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    type: elementType.table,
    chartSettings: {
      majorTicks: 10,
      minorTicks: 0,
      gridBackgroundColor: '#FFF',
      majorGridLineColor: '#404040',
      minorGridLineColor: '#404040',
      showXAxisMajorGridLines: true,
      showXAxisMinorGridLines: false,
      showYAxisMajorGridLines: true,
      showYAxisMinorGridLines: false,
      showXAxisBottom: true,
      showXAxisTop: false,
      showYAxisLeft: true,
      showYAxisRight: false,
      showScales: true,
      fixedScaleCm: false,
      fixedScaleEnabled: false,
      fixedScaleValue: 1,
      xAxisUserDefined: false,
      xMin: 0,
      xMax: 100,
      xAxisScale: -1,
      yAxisUserDefined: false,
      yMin: 0,
      yMax: 100,
      yAxisScale: -1,
      labels: {
        categories: [],
        categoryOptions: [
          {
            category: labelCategories.surveyPts,
            options: {
              mdTvd: 'MD',
              inc: false,
              azi: false,
              interval: 100,
              atSurvey: true,
              range: false,
              startDepth: 0,
              endDepth: 99999,
            },
          },
        ],
        labelData: [
          //uniqueness...
          // type: wellname, rownum, (depth)
          // position
          // stles
        ],
      },
    },
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 3,
    height: 3,
    zIndex: zIndex,
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      opacity: 1,
      cursor: 'pointer',
    },
  }
}

const ChartElement = forwardRef(({ id, element, wellData, actions, updateContainer, scale }, ref) => {
  const [showProperties, setShowProperties] = useState(false)
  const [rerender, setRerender] = useState(false) // eslint-disable-line
  const currentContentRef = useRef({ style: element.style, chartSettings: element.chartSettings })
  const wallPlotChartRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
      getSettings() {
        return currentContentRef.current
      },
    }
  })

  const handleModalChange = (newData) => {
    let newContent = {
      style: { ...currentContentRef.current.style, ...newData.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...newData.context },
      // compile error // need to merge labelData in above chartSettings assignement. the above statement replaces the
      // the label data in currentContentRef.current.chartSettings with the empty labelData array from newData, which
      // we don't want to do. We want to merge the labelData arrays.
    }
    // currentContentRef.current = newContent
    // process label categories into chart data here...

    if (newContent.chartSettings.hasOwnProperty('labels')) {
      // remove offset label categories if showOffsets is false
      if (!newContent.chartSettings.showOffsets) {
        newContent.chartSettings.labels.categories = newContent.chartSettings.labels.categories.filter(
          (cat) => cat.category !== labelCategories.offsetWellNames && cat.category !== labelCategories.offsetSurveyPts,
        )
      }
      if (!newContent.chartSettings.showTargets) {
        newContent.chartSettings.labels.categories = newContent.chartSettings.labels.categories.filter(
          (cat) => cat.category !== labelCategories.targets,
        )
      }

      newContent.chartSettings.labels.labelData = generateAllLabels(
        newContent,
        currentContentRef.current.chartSettings,
        wellData,
      )
    }
    currentContentRef.current = newContent

    updateContainer({ ...newContent.style })
    actions.update(id, 'update', newContent)
    setShowProperties(false)
    setRerender((prev) => !prev)
  }

  const handleChartUpdate = (changeData) => {
    let newContent = {
      style: { ...currentContentRef.current.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...changeData },
    }
    actions.update(id, 'update', newContent)
    currentContentRef.current = newContent
    setRerender((prev) => !prev)
  }

  const handleChartUpdateNoRender = (changeData) => {
    let newContent = {
      style: { ...currentContentRef.current.style },
      chartSettings: { ...currentContentRef.current.chartSettings, ...cloneDeep(changeData) },
    }
    currentContentRef.current = newContent // significant functionality change!!!
    actions.update(id, 'updateSettings', newContent)
  }

  const getChartSettings = () => {
    let chartSettings = {
      ...(wallPlotChartRef.current?.getSettings() || {}),
      ...currentContentRef.current.chartSettings,
    }

    if (!wellData) return chartSettings

    // if there are no settings predefined, add the main well (0th offset well)
    if (!chartSettings.hasOwnProperty('series') || !chartSettings.series) {
      chartSettings.series = [
        {
          wellName: wellData.actualWellData.actualWellName,
          color:
            wellData.actualWellData.isPlan === false ? appColors.surveyTrajectoryColor : appColors.planTrajectoryColor,
          lineStyle: 'solid',
          lineThickness: 2,
          type: wellData.actualWellData.isPlan === false ? 'Actual' : 'Plan',
        },
      ]

      // add the offset wells
      if (wellData.offsetWells?.length > 2) {
        for (let i = 1; i < wellData.offsetWells.length; i++) {
          chartSettings.series.push({
            wellName: wellData.offsetWells[i].offsetWellbore,
            color:
              wellData.offsetWells[i].wellStatus === 'Actual'
                ? appColors.surveyTrajectoryColor
                : appColors.planTrajectoryColor,
            lineStyle: 'solid',
            lineThickness: 1,
            type: wellData.offsetWells[i].wellStatus,
          })
        }
      }
    }

    return cloneDeep(chartSettings)
  }

  return (
    <>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentContentRef.current.style}
          contextData={getChartSettings()}
          onApply={handleModalChange}
          elemType={elementType.chart}
          elemSubType={element.chartSettings.chartType}
        />
      )}
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          position: 'absolute',
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          setShowProperties(true)
        }}>
        <Box
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            color: currentContentRef.current.style.color,
            padding: '0 8px',
            opacity: 1,
            cursor: currentContentRef.current.style.cursor,
          }}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <WallPlotChart
              ref={wallPlotChartRef}
              data={wellData}
              refWellSurveys={wellData?.surveys}
              isPlan={false}
              chartType={element.chartSettings.chartType}
              onUpdate={handleChartUpdate}
              onUpdateNoRender={handleChartUpdateNoRender}
              chartSettings={getChartSettings()}
            />
          </Box>
        </Box>
      </div>
    </>
  )
})

export default ChartElement
