// from: https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Checkbox.tsx
// not using the npm lib because want to avoid version issues--should change later

import * as React from 'react'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import { FieldProps } from 'formik'
// import invariant from 'tiny-warning'

type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>

export interface CheckboxProps
  extends FieldProps,
    Omit<
      MuiCheckboxProps,
      | 'name'
      | 'value'
      | 'error'
      | 'form'
      | 'checked'
      | 'defaultChecked'
      // Excluded for conflict with Field type
      | 'type'
    > {
  type?: string
}

export function fieldToCheckbox({
  disabled,
  field: { onBlur: fieldOnBlur, ...field },
  form: { isSubmitting },
  type,
  onBlur,
  ...props
}: CheckboxProps): MuiCheckboxProps {
  const indeterminate = !Array.isArray(field.value) && field.value == null

  // if (process.env.NODE_ENV !== 'production') {
  //   invariant(
  //     type === 'checkbox',
  //     `property type=checkbox is missing from field ${field.name}, this can caused unexpected behaviour`,
  //   )
  // }

  return {
    disabled: disabled ?? isSubmitting,
    indeterminate,
    onBlur:
      onBlur ??
      function (e) {
        fieldOnBlur(e ?? field.name)
      },
    ...field,
    ...props,
  }
}

export function Checkbox(props: CheckboxProps) {
  return <MuiCheckbox {...fieldToCheckbox(props)} />
}

Checkbox.displayName = 'FormikMUICheckbox'
