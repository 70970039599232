import React, { useRef, useEffect, useState, useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MwdProbeOrderModal = ({ wellName, bhaNum, onClose }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isFetching = useRef(false)
  const isSaving = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const [resetCols, setResetCols] = useState(false)

  const getProbeOrder = useInnovaAxios({
    url: '/well/drillString/getMwdProbeOrder',
  })

  const saveProbeOrder = useInnovaAxios({
    url: '/well/drillString/updateMwdProbeOrder',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('mwdProbeOrderGrid', 'colLayout', colLayout)
    }
  }

  const fetchData = async () => {
    if (isFetching.current) return
    isFetching.current = true
    let res = await getProbeOrder({ wellName: wellName, bhaNum: bhaNum })
    if (res?.error) {
      console.error(res.error)
      return
    }

    if (!Array.isArray(res.data)) return
    gridApi.current.setGridOption('rowData', res.data)
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('mwdProbeOrderGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.sequence)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchData()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        colId: 'dragHandle',
        headerName: '',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 20,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        rowDrag: true,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        editable: true,
        valueSetter: (params) => {
          if (params.newValue === '' || !params.newValue) {
            params.newValue = params.oldValue
          }

          return true
        },
      },
      {
        field: 'active',
        colId: 'active',
        editable: true,
        headerName: 'Active',
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
      },
    ],
    [centerAlignCell],
  )

  const getGridData = () => {
    if (!gridApi.current) return

    const rowData = []
    gridApi.current.forEachNode((node) => {
      rowData.push(node.data)
    })

    return rowData
  }

  const handleSave = async () => {
    if (!isSaving.current) {
      let probeOrder = getGridData()
      let res = await saveProbeOrder({ wellName: wellName, bhaNum: bhaNum, probeOrder: JSON.stringify(probeOrder) })
      if (res?.error) {
        console.error(res.error)
      }
    }

    onClose()
  }

  return (
    <Dialog
      maxWidth='md'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`MWD Probe Order`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              columnDefs={sortColDefs(columnDefs, 'mwdProbeOrderGrid')}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              getRowId={getRowId}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
              rowDragManaged={true}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => handleSave()} color='primary'>
          Apply
        </Button>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MwdProbeOrderModal
