import React, { useRef, useEffect, useState, useMemo, useCallback, useImperativeHandle, forwardRef } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  sortColDefs,
  htmlSymbolHandling,
  relativeTime,
  isValidDate,
  CustomLoadingOverlay,
  isDateLessThan,
  dateComparator,
  getStringId,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import QuoteDetailsGrid from './QuoteDetailsGrid'
import { cloneDeep, debounce } from 'lodash'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import { isoDateAddition, getDeltaIsoDays } from 'utils/dateTimeFunctions'
import { round } from 'utils/numberFunctions'
import { numberWithCommasDecimals, removeSymbolsFromName } from 'utils/stringFunctions'
import { createInvoiceHtml } from 'components/InnovaInvoices/createInvoiceHtml'
import HtmlPdf from 'components/common/PDFGen/HtmlStringPdf'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const InnovaQuotesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const containerRef = useRef(null)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [quoteData, setQuoteData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedQuote = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dropDowns] = useInnovaAdminDropDowns(false)
  const [bankAccounts, setBankAccounts] = useState([])
  const htmlPdfData = useRef('')
  const [showPdfComponent, setShowPdfComponent] = useState(false)
  const [forceRePrint, setForceRePrint] = useState(0)
  const { user } = useInnovaAuth()
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getQuotes = useInnovaAxios({
    url: '/admin/quotes/getInnovaQuotes',
  })

  const getBankAccounts = useInnovaAxios({
    url: '/admin/bank/getAccounts',
  })

  const deleteQuote = useInnovaAxios({
    url: '/admin/quotes/deleteInnovaQuote',
  })

  const addQuote = useInnovaAxios({
    url: '/admin/quotes/createInnovaQuote',
  })

  const updateQuote = useInnovaAxios({
    url: '/admin/quotes/updateInnovaQuote',
  })

  const convertQuoteToInvoice = useInnovaAxios({
    url: '/admin/quotes/convertQuoteToInvoice',
  })

  const printQuote = useInnovaAxios({
    url: '/admin/quotes/getInnovaQuotes',
  })

  const copyQuote = useInnovaAxios({
    url: '/admin/quotes/duplicateQuote',
  })

  const addQuoteLineItem = useInnovaAxios({
    url: '/admin/quotes/addInnovaQuoteDetail',
  })

  const updateQuoteLineItem = useInnovaAxios({
    url: '/admin/quotes/updateInnovaQuoteDetail',
  })

  const deleteQuoteLineItem = useInnovaAxios({
    url: '/admin/quotes/deleteInnovaQuoteDetail',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaQuotesPageKey)
    fetchQuotes()
    fetchBankAccounts()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  const fetchBankAccounts = async () => {
    let res = await getBankAccounts()

    if (!_isMounted.current) return
    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    setBankAccounts(Array.isArray(res.data) ? res.data : [])
  }

  const fetchQuotes = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getQuotes()

    if (response?.error) return
    if (!_isMounted.current) return

    setQuoteData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const reqFields = useMemo(() => ['company', 'validFor', 'allocationCountry', 'currency'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (
      (node?.rowPinned === 'bottom' && value == null) ||
      (node?.rowPinned === 'bottom' && value === '') ||
      (node?.rowPinned === 'bottom' && value === undefined)
    )
  }

  const convertDateToDateStr = useCallback((date) => {
    if (!date) return ''
    if (typeof date !== 'string') return ''
    if (date === '') return ''
    if (!isValidDate(date)) return ''
    let dateStr = new Date(Date.parse(date)).toISOString().substring(0, 10)
    return dateStr
  }, [])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return
      if (isUpdating.current) return

      //This is deliberate issue date is required by the http form but doesnt exist on the object
      data.issueDate = convertDateToDateStr(data.createdDate)

      if (!data.sentBy || data.sentBy === '') data.sentDate = ''
      data.sentDate = convertDateToDateStr(data.sentDate)
      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateQuote(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        data = res.data
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [convertDateToDateStr, updateQuote],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder, handleUpdate])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('innovaQuotesGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaQuotesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Quotes.xlsx',
            sheetName: 'Quotes',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true

    inputRow.current.createdDate = new Date(Date.now()).toISOString().substring(0, 10)
    inputRow.current.issueDate = new Date(Date.now()).toISOString().substring(0, 10)
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addQuote(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current = res.data
      gridApi.current.applyTransaction({
        add: [inputRow.current],
        addIndex: 0,
      })

      setStatus({
        show: true,
        severity: 'success',
        message: `${'Quote created'}`,
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleDelete = async () => {
    if (!selectedQuote.current) return
    if (!selectedQuote.current.hasOwnProperty('recordId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteQuote(selectedQuote.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedQuote.current],
      })
    }
  }

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (value === '-1') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    if (value.indexOf('T') < 0) value += 'T00:00:01'
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const onClickDelete = useCallback((data) => {
    setConfirm({
      show: true,
      title: 'Delete Quote',
      text: `Are you sure you want to delete ${data.invoiceNum}?`,
      action: 'delete',
    })
  }, [])

  const onClickConvert = useCallback((data) => {
    setConfirm({
      show: true,
      title: 'Convert quote to invoice',
      text: `Are you sure you want to convert ${data.invoiceNum} - {${unescapeHtml(data.company)}} to an invoice?`,
      action: 'convert',
    })
  }, [])

  const convertToInvoice = async () => {
    if (!selectedQuote.current) return
    if (isLoading) return

    setLoading(true)
    const res = await convertQuoteToInvoice(selectedQuote.current)
    if (!_isMounted.current) return
    setLoading(false)

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }
  }

  const onPrint = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return
      if (isLoading) return

      setLoading(true)
      let printResponse = await printQuote({ recordId: data.recordId })
      if (!_isMounted.current) return
      setLoading(false)

      if (printResponse.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${printResponse?.error?.response?.data?.error}`,
        })

        return
      }

      let selectedBankAccount = null
      if (
        Array.isArray(bankAccounts) &&
        bankAccounts.length > 0 &&
        typeof printResponse.data[0].bankAccount === 'string'
      ) {
        for (let i = 0; i < bankAccounts.length; i++) {
          if (bankAccounts[i].bank === printResponse.data[0].bankAccount) {
            selectedBankAccount = bankAccounts[i]
            break
          }
        }
      }

      if (Array.isArray(printResponse.data) && printResponse.data.length > 0) {
        htmlPdfData.current = {
          html: createInvoiceHtml(printResponse.data[0], true, selectedBankAccount),
          fileName: removeSymbolsFromName(
            `Quote-${printResponse.data[0].invoiceNum}-${unescapeHtml(printResponse.data[0].company)}`,
          ),
        }

        setForceRePrint((prevVal) => prevVal + 1)
        setShowPdfComponent(true)
      }
    },
    [bankAccounts, printQuote, isLoading],
  )

  const duplicateQuote = useCallback(
    async (data) => {
      if (!data) return
      if (isLoading) return

      setLoading(true)
      const res = await copyQuote(data)
      if (!_isMounted.current) return
      setLoading(false)

      if (res.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (gridApi.current && res.data) {
        gridApi.current.applyTransaction({
          add: [res.data],
          addIndex: 0,
        })

        setStatus({
          show: true,
          severity: 'info',
          message: `${'Quote duplicated'}`,
        })
      }
    },
    [copyQuote, isLoading],
  )

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedQuote.current = cloneDeep(data)
      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'duplicate') {
        duplicateQuote(data)
      }

      if (action === 'convert') {
        onClickConvert(data)
      }

      if (action === 'print') {
        onPrint(cloneDeep(data))
      }
    },
    [onClickDelete, onPrint, onClickConvert, duplicateQuote],
  )

  const getValidUntilDate = (issueDate, validFor) => {
    if (!validFor) validFor = 0
    if (typeof validFor === 'string') validFor = parseInt(validFor)
    if (typeof validFor !== 'number') validFor = 0
    if (validFor < 0) validFor = 0
    if (!issueDate) return null
    return isoDateAddition(issueDate, validFor, true)
  }

  const leftAlignedCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }
  }, [])

  const getExpiryDays = useCallback((issueDate, validFor) => {
    let expiryDate = getValidUntilDate(issueDate, validFor)
    if (!isValidDate(expiryDate)) return 0
    expiryDate = expiryDate.substring(0, 10) + 'T00:00:01'
    let currentDate = new Date(Date.now()).toISOString().substring(0, 10) + 'T00:00:01'

    return round(getDeltaIsoDays(currentDate, expiryDate), 0)
  }, [])

  const dateFilterComparator = useCallback(dateComparator, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'quoteData',
        colId: 'quoteData',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 50,
        cellStyle: centerAlignCell,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Duplicate',
                action: 'duplicate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='akar-icons:copy' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Convert to invoice',
                action: 'convert',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='arcticons:unitconverterultimate' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Print',
                action: 'print',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fluent:print-24-filled' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'invoiceNum',
        colId: 'invoiceNum',
        headerName: 'Quote#',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
      },
      {
        field: 'company',
        colId: 'company',
        headerName: 'Company',
        pinned: 'left',
        lockPosition: 'left',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        valueGetter: (params) => unescapeHtml(params.data?.company),
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.companys) ? dropDowns.companys : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'validFor',
        colId: 'validFor',
        headerName: 'Valid (days)',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) {
            return 'ValidFor...'
          }

          return params?.data?.validFor
        },
        cellEditorParams: {
          min: 1,
          max: 360,
          precision: 0,
        },
      },
      {
        field: 'totalValue',
        colId: 'totalValue',
        headerName: 'Total Value',
        valueFormatter: (params) => {
          if (parseFloat(params?.data?.totalValue) === 0) return ''
          return numberWithCommasDecimals(params?.data?.totalValue, 2)
        },
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'expiresIn',
        colId: 'expiresIn',
        headerName: 'Expires',
        editable: false,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (getExpiryDays(params?.data?.createdDate, params?.data?.validFor) <= 0) return 'Expired'
          return relativeTimeFormatter(getValidUntilDate(params?.data?.createdDate, params?.data?.validFor))
        },
        cellStyle: (params) => {
          let expiryDays = getExpiryDays(params?.data?.createdDate, params?.data?.validFor)
          let color = '#59FF00CF'
          if (expiryDays <= 30 && expiryDays >= 5) color = 'orange'
          if (expiryDays < 5) color = 'red'
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            color: color,
          }
        },
      },
      {
        field: 'allocationCountry',
        colId: 'allocationCountry',
        headerName: 'Allocation',
        cellStyle: centerAlignCell,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.allocationCountry) ? dropDowns.allocationCountry : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'currency',
        colId: 'currency',
        headerName: 'Currency',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellStyle: centerAlignCell,
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.currency) ? dropDowns.currency : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'exchangeRate',
        colId: 'exchangeRate',
        headerName: 'Exchange Rate',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        valueFormatter: (params) => (parseFloat(params?.data?.exchangeRate) > 0 ? params?.data?.exchangeRate : ''),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 4,
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'customerRef',
        colId: 'customerRef',
        headerName: 'Customer Ref',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params.data?.customerRef),
      },
      {
        field: 'createdDate',
        colId: 'createdDate',
        headerName: 'Issue Date',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.createdDate),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        field: 'sentDate',
        colId: 'sentDate',
        headerName: 'Sent Date',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          if (!params?.data.sentBy || params?.data.sentBy === '') return false
          return true
        },
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.sentDate),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueSetter: (params) => {
          if (!params.newValue) params.data.sentDate = ''
          if (params.newValue) params.data.sentDate = params.newValue

          if (isDateLessThan(params.data?.sentDate, params.data?.createdDate)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `Sent date cant be before issue date`,
            })

            if (!params?.data?.sentBy || params?.data?.sentBy === '') params.data.sentBy = user.name
            params.data.sentDate = params.data?.createdDate
          }

          handleUpdate(params.data)
        },
      },
      {
        field: 'sentBy',
        colId: 'sentBy',
        headerName: 'Sent By',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        valueGetter: (params) => getUserNameFromEmail(params?.data?.sentBy),
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.users) ? dropDowns.users : []
          return {
            values: dropDown.map((value) => getUserNameFromEmail(value.label)),
          }
        },
        valueSetter: (params) => {
          if (!params.newValue || params.newValue === '') {
            params.data.sentBy = ''
            params.data.sentDate = ''
          } else {
            params.data.sentBy = params.newValue
            if (!params.oldValue || params.oldValue === '') {
              params.data.sentDate = new Date(Date.now()).toISOString().substring(0, 10)
            }
          }

          if (isDateLessThan(params.data?.sentDate, params.data?.createdDate)) {
            params.data.sentDate = params.data?.createdDate
          }

          handleUpdate(params.data)
        },
      },
      {
        field: 'modifiedDate',
        colId: 'modifiedDate',
        headerName: 'Modified Date',
        valueFormatter: (params) => dateTimeFormatter(params?.data?.modifiedDate),
        editable: false,
      },
      {
        field: 'modifiedBy',
        colId: 'modifiedBy',
        headerName: 'Modified By',
        valueFormatter: (params) => getUserNameFromEmail(params?.data?.modifiedBy),
        editable: false,
      },
      {
        field: 'comments',
        colId: 'comments',
        headerName: 'Comments',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: leftAlignedCell,
        maxWidth: 600,
        valueGetter: (params) => unescapeHtml(params?.data?.comments),
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
      },
    ],
    [
      dateFilterComparator,
      dateTimeFormatter,
      relativeTimeFormatter,
      dropDowns,
      leftAlignedCell,
      centerAlignCell,
      getExpiryDays,
      handleMenuClick,
      handleUpdate,
      user,
    ],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleUpdateQuoteDetails = async (data) => {
    if (!data) return
    data = htmlSymbolHandling(data)

    let res = null
    data.data.rowNum = data.data.sequenceNum
    data.data.recordId = data.recordId
    if (!data.data.qty) data.data.qty = 0
    if (!data.data.value) data.data.value = 0

    const orgData = gridApi.current.getRowNode(data.recordId)
    if (!orgData) return
    let orgLineItems = Array.isArray(orgData.data.invoiceDetails) ? cloneDeep(orgData.data.invoiceDetails) : []

    let returnData = null
    if (data.action === 'delete') {
      if (isDeleting.current) return
      isDeleting.current = true
      res = await deleteQuoteLineItem(data.data)
      isDeleting.current = false
      returnData = cloneDeep(data.data)
    }

    if (data.action === 'add') {
      if (isAdding.current) return
      isAdding.current = true
      res = await addQuoteLineItem(data.data)
      isAdding.current = false

      if (Array.isArray(res?.data?.invoiceDetails)) {
        const { invoiceDetails } = res.data

        for (let i = 0; i < invoiceDetails.length; i++) {
          let foundItem = orgLineItems.find((item) => item.sequenceNum === invoiceDetails[i].sequenceNum)
          if (!foundItem) {
            returnData = cloneDeep(invoiceDetails[i])
            break
          }
        }
      }
    }

    if (data.action === 'update') {
      if (isUpdating.current) return
      isUpdating.current = true
      res = await updateQuoteLineItem(data.data)
      isUpdating.current = false

      if (Array.isArray(res?.data?.invoiceDetails)) {
        const { invoiceDetails } = res.data
        let lineItem = invoiceDetails.find((item) => item.sequenceNum === data.data.sequenceNum)
        if (lineItem) returnData = cloneDeep(lineItem)
      }
    }

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res.data) {
      gridApi.current.applyTransaction({
        update: [res.data],
      })

      return returnData
    }

    return null
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        {<QuoteDetailsGrid quoteData={params.data} handleUpdate={handleUpdateQuoteDetails} />}
      </Box>
    )
  })

  const afterPdfPrint = () => {
    setShowPdfComponent(false)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => (confirm?.action === 'delete' ? handleDelete() : convertToInvoice())}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box ref={containerRef} sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={quoteData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'innovaQuotesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchQuotes()
              },
            },
          ]}
        />
      </Box>
      {showPdfComponent ? (
        <Box
          style={{
            display: 'none',
          }}>
          <HtmlPdf
            htmlString={htmlPdfData.current?.html}
            fileName={htmlPdfData.current?.fileName}
            cleanUpFunction={afterPdfPrint}
            forcePrint={forceRePrint}
          />
        </Box>
      ) : null}
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InnovaQuotesPage
