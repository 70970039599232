import React, { useRef, useEffect } from 'react'
import 'components/common/SplitPane/splitPaneResizer.css'
import { Box } from '@mui/material'
import ShippingAddressGrid from './ShippingAddressGrid'
import ShippingInventoryGrid from './ShippingInventoryGrid'
import ShippingTicketCommentsGrid from './ShippingTicketCommentsGrid'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import { shippingTicketAddressesLayoutAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import cloneDeep from 'lodash/cloneDeep'

const ShippingTicketDetailsPane = ({
  selectedTicket,
  updateTicket,
  addresses,
  updateTicketTools,
  setStatus,
  setSelectedTicket,
}) => {
  const _isMounted = useRef(false)
  const [pageLayout, setPageLayout] = useRecoilState(shippingTicketAddressesLayoutAtom)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '50%' : '50%'
    if (index >= pageLayout.length) return index === 0 ? '50%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContnet: 'center',
        width: '100%',
        height: '100%',
      }}>
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(1)}
        allowResize={true}
        defaultSize={getInitialPaneSize(1)}
        onDragFinished={(params) => onDragFinished(params, 1)}>
        <SplitPane
          split='vertical'
          onDragFinished={(params) => onDragFinished(params, 0)}
          defaultSize={getInitialPaneSize(0)}
          size={getInitialPaneSize(0)}
          allowResize={true}
          style={{
            height: 'calc(100%)',
            width: `calc(100%)`,
            maxWidth: `calc(100%)`,
          }}>
          <ShippingAddressGrid
            key={'from'}
            ticketData={selectedTicket}
            addressType='From'
            handleUpdate={updateTicket}
            addressesList={addresses}
          />
          <ShippingAddressGrid
            key={'to'}
            ticketData={selectedTicket}
            addressType='To'
            handleUpdate={updateTicket}
            addressesList={addresses}
          />
        </SplitPane>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(2)}
          allowResize={true}
          defaultSize={getInitialPaneSize(2)}
          onDragFinished={(params) => onDragFinished(params, 2)}>
          <ShippingInventoryGrid
            inventoryList={Array.isArray(selectedTicket?.tools) ? selectedTicket?.tools : []}
            updateTicketTools={updateTicketTools}
            setStatus={setStatus}
            selectedTicket={selectedTicket}
            isSelectedToolsGrid={true}
            setSelectedTicket={setSelectedTicket}
          />
          <ShippingTicketCommentsGrid ticketData={selectedTicket} updateTicket={updateTicket} />
        </SplitPane>
      </SplitPane>
    </Box>
  )
}

export default ShippingTicketDetailsPane
