import React, { useEffect, useRef, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { currentWellAtom } from 'atoms'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useUpdateWellInfo from 'components/common/hooks/useUpdateWellInfo'

const WellSummaryGrid = () => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const [data, setData] = useState([])
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const { getAgGridTheme } = useInnovaTheme()
  const { handleUpdate: updateWellInfo } = useUpdateWellInfo()
  const wellInfoRef = useRef(null)


  const getWellSummary = useInnovaAxios({ url: 'well/summary' })

  const getWellInfo = useInnovaAxios({ url: 'well/wellInfo/getWellInfo' })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current && currentWell?.length > 0) {
      getData(currentWell)
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    if (!currentWell) return
    if (typeof currentWell !== 'string') return

    const res = await getWellSummary({ wellName: currentWell })
    if (!_isMounted.current) return
    if (res.error) return

    const resWellInfo = await getWellInfo({wellName: currentWell})
    if(!_isMounted.current) return
    if(resWellInfo.error) return

    wellInfoRef.current = resWellInfo.data

    let rowData = [
      { label1: 'Operator', value1: res?.data?.operator, label2: 'Coordinator', value2: res?.data?.coordinator },
      { label1: 'Well', value1: res?.data?.actualWell, label2: 'Day DD', value2: res?.data?.dayDD },
      { label1: 'State', value1: res?.data?.state, label2: 'Night DD', value2: res?.data?.nightDD },
      { label1: 'County', value1: res?.data?.county, label2: 'Day MWD', value2: res?.data?.dayMwd },
      { label1: 'Job#', value1: res?.data?.jobNum, editable1: true,  label2: 'Night MWD', value2: res?.data?.nightMwd },
      { label1: 'Rig', value1: res?.data?.rig, label2: 'Summary', value2: res?.data?.summary },
      { label1: 'Status', value1: res?.data?.wellStatus, label2: 'Lookahead', value2: res?.data?.lookAhead },
      { label1: 'Depth', value1: res?.data?.currentDepth, label2: 'BHA', value2: res?.data?.currentBha },
      { label1: 'Api Job#', value1: resWellInfo?.data?.apiNum, editable1: true, label2: 'Target Formation', value2: resWellInfo?.data?.formation, editable2: true}, 
    ]

    setData(rowData)
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('wellSummaryGrid', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellSummaryGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WellSummary.xlsx',
            sheetName: 'Summary',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ];
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'label1',
        colId: 'label1',
        minWidth: 100,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          color: appColors.itemTextColor,
        },
      },
      {
        field: 'value1',
        colId: 'value1',
        minWidth: 100,
        editable: (params) => params.data.editable1 === true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        valueSetter: (params) => {
          if(params.data.label1 === 'Api Job#') {
            wellInfoRef.current.apiNum = params.newValue
          }
          if (params.data.label1 === 'Job#') {
            wellInfoRef.current.jobNum = params.newValue
          }
          updateWellInfo(wellInfoRef.current)
        }
      },
      {
        field: 'label2',
        colId: 'label2',
        minWidth: 100,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
          color: appColors.itemTextColor,
        },
      },
      {
        field: 'value2',
        colId: 'value2',
        minWidth: 100,
        editable: (params) => params.data.editable2 === true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        valueSetter: (params) => {
          if(params.data.label2 === 'Target Formation') {
            wellInfoRef.current.formation = params.newValue
          }
          updateWellInfo(wellInfoRef.current)
        }
      },
    ],
    [updateWellInfo],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        
        rowData={data}
        columnDefs={sortColDefs(columnDefs, 'wellSummaryGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={0}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default WellSummaryGrid
