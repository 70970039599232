import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ShippingAddressGrid = ({ ticketData, addressType, handleUpdate, addressesList }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateFunc = async (data) => {
    if (!gridApi.current) return
    if (!Array.isArray(data)) return

    let newData = cloneDeep(ticketData)

    for (let i = 0; i < data.length; i++) {
      if (newData.hasOwnProperty(data[i].tag) && data[i].type !== 'dropDown') {
        newData[data[i].tag] = data[i].value
      }
    }

    let res = await handleUpdate(newData)
    if (res?.error) return
  }

  const columnDefs = [
    {
      headerName: '',
      field: 'label',
      colId: 'label',
      minWidth: 150,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    {
      headerName: '',
      field: 'value',
      colId: 'value',
      minWidth: 350,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
      editable: true,
      cellEditorSelector: (params) => {
        if (params.data?.type === 'dropDown') {
          return {
            component: 'agRichSelectCellEditor',
            params: {
              values: params.data?.dropDownValues,
            },
            popup: true,
          }
        }

        return {
          component: 'agTextCellEditor',
        }
      },
      valueSetter: (params) => {
        if (params.oldValue === params.newValue) return

        let gridData = []
        gridApi.current.forEachNode((node) => {
          if (node.data) gridData.push(node.data)
        })

        if (params.data?.type === 'text') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index >= 0) gridData[index].value = params.newValue
        }

        if (params.data?.type === 'dropDown' && Array.isArray(addressesList)) {
          let foundAddress = addressesList.find((addr) => addr.company === params.newValue)
          gridData[0].value = params.newValue
          gridData[1].value = foundAddress ? foundAddress.company : ''
          gridData[2].value = foundAddress ? foundAddress.address : ''
          gridData[3].value = foundAddress ? foundAddress.contact : ''
          gridData[4].value = foundAddress ? foundAddress.email : ''
          gridData[5].value = foundAddress ? foundAddress.phone : ''
        }

        handleUpdateFunc(gridData)
      },
    },
  ]

  const getAddressData = (data) => {
    let address = [
      { label: `Select Address ${addressType}`, value: '', id: 0, type: 'dropDown', tag: '', dropDownValues: [] },
      { label: `Company`, value: '', id: 1, type: 'text', tag: `company${addressType}` },
      { label: `Address`, value: '', id: 2, type: 'text', tag: `address${addressType}` },
      { label: `Contact`, value: '', id: 3, type: 'text', tag: `contact${addressType}` },
      { label: `Email`, value: '', id: 4, type: 'text', tag: `email${addressType}` },
      { label: `Phone Number`, value: '', id: 5, type: 'text', tag: `phone${addressType}` },
    ]

    if (!data) return address
    for (let i = 0; i < address.length; i++) {
      if (address[i].tag === '') continue
      if (!data.hasOwnProperty(address[i].tag)) continue
      if (!data[address[i].tag]) continue
      address[i].value = data[address[i].tag]
    }

    if (Array.isArray(addressesList)) {
      address[0].dropDownValues = addressesList.map((addr) => addr.company)
      let foundCompany = address[0].dropDownValues.find((name) => name === address[1].value)
      if (foundCompany) address[0].value = foundCompany
    }
    return address
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getAddressData(ticketData)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default ShippingAddressGrid
