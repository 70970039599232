import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { debounce } from 'lodash'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import { cloneDeep } from 'lodash'
import DataAcqRestoreArchiveModal from './DataAcqRestoreModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const DataAckFileStatusPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gettingLink = useRef(false)
  const gridApi = useRef(null)
  const selectedRecord = useRef(null)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [fileStatus, setFileStatus] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showRestore, setShowRestore] = useState(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getFileStatus = useInnovaAxios({
    url: '/dataAcq/getUploadQueue',
  })

  const getDownloadLink = useInnovaAxios({
    url: '/dataAcq/getDownloadLink',
  })

  const deleteArchive = useInnovaAxios({
    url: '/dataAcq/deleteWellArchive',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.dataAckFileStatusKey)
    fetchFileData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchFileData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getFileStatus({ incAll: true })

    if (response?.error) return
    if (!_isMounted.current) return

    setFileStatus(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('dataAckFileStatusGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dataAckFileStatusGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DataAckFileStatus.xlsx',
            sheetName: 'FileStatus',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleDownload = useCallback(
    async (data) => {
      if (gettingLink.current) return false
      if (!data) return false

      gettingLink.current = true
      let res = await getDownloadLink(data)
      gettingLink.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      if (res?.data?.status !== 'OK') {
        setStatus({
          show: true,
          severity: 'error',
          message: `failed to get link`,
        })

        return false
      }

      window.open(res?.data?.fileLink, '_blank', 'noreferrer')
      return true
    },
    [getDownloadLink],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedRecord.current) return
    if (!selectedRecord.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true

    let res = await deleteArchive(selectedRecord.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedRecord.current],
      })
    }

    selectedRecord.current = null
  }, [deleteArchive])

  const onClickDelete = useCallback(
    (data) => {
      selectedRecord.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete data archive',
        text: `Are you sure you want to delete archive?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'download') {
        handleDownload(data)
      }

      if (action === 'restore') {
        selectedRecord.current = cloneDeep(data)
        setShowRestore(true)
      }

      if (action === 'delete') {
        selectedRecord.current = cloneDeep(data)
        onClickDelete(data)
      }
    },
    [handleDownload, onClickDelete],
  )

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'details',
        pinned: 'left',
        lockPosition: 'left',
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: {
          justifyContent: 'center',
        },
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          let menuItems = []

          let downloadStatus = ['EXPORT_READY', 'ARCHIVE_COMPLETE', 'RESTORE_COMPLETE'] // EXPORT_COMPLETE is a historical status, _READY is for fetching the file...
          if (downloadStatus.find((s) => s === params.data?.status)) {
            menuItems.push({
              label: 'Download File',
              action: 'download',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='material-symbols:download' />
                </StyledIconContainer>
              ),
            })
          }
          if (params.data?.status === 'ARCHIVE_COMPLETE') {
            menuItems.push({
              label: 'Restore Archive',
              action: 'restore',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='ph:upload' />
                </StyledIconContainer>
              ),
            })
          }

          if (params.data?.status === 'ARCHIVE_COMPLETE') {
            menuItems.push({
              label: 'Delete Archive',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='material-symbols:delete' color={'red'} />
                </StyledIconContainer>
              ),
            })
          }

          return {
            menuItems: menuItems,
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'wellName',
        colId: 'wellName',
        headerName: 'Wellname',
      },
      {
        field: 'status',
        colId: 'status',
        headerName: 'Status',
        valueFormatter: (params) => {
          if (!params.value) return
          if (params.value === 'IMPORT_QUEUED') return 'Import queued'
          if (params.value === 'IMPORT_INPROGRESS') return 'Import in progress'
          if (params.value === 'IMPORT_COMPLETE') return 'Import complete'
          if (params.value === 'IMPORT_FAILED') return 'Import failed'
          if (params.value === 'ARCHIVE_QUEUED') return 'Archive queued'
          if (params.value === 'ARCHIVE_INPROGRESS') return 'Archive in progress'
          if (params.value === 'ARCHIVE_COMPLETE') return 'Archive complete'
          if (params.value === 'ARCHIVE_FAILED') return 'Archive failed'
          if (params.value === 'EXPORT_QUEUED') return 'Export queued'
          if (params.value === 'EXPORT_INPROGRESS') return 'Export in progress'
          if (params.value === 'EXPORT_READY') return 'Export ready'
          if (params.value === 'EXPORT_COMPLETE') return 'Export complete'
          if (params.value === 'EXPORT_FAILED') return 'Export failed'
          if (params.value === 'RESTORE_QUEUED') return 'Restore queued'
          if (params.value === 'RESTORE_INPROGRESS') return 'Restore in progress'
          if (params.value === 'RESTORE_COMPLETE') return 'Restore complete'
          if (params.value === 'RESTORE_FAILED') return 'Restore failed'
          if (params.value === 'PROCESSING_COMPLETE') return 'Re-Processing complete'
          if (params.value === 'PROCESSING_INPROGRESS') return 'Re-Processing in progress'
          if (params.value === 'ARCHIVE_DELETED') return 'Archive deleted'
        },
        cellStyle: (params) => {
          let color = 'white'
          if (params.value === 'IMPORT_INPROGRESS') color = 'orange'
          if (params.value === 'IMPORT_COMPLETE') color = 'green'
          if (params.value === 'IMPORT_FAILED') color = 'red'
          if (params.value === 'ARCHIVE_INPROGRESS') color = 'orange'
          if (params.value === 'ARCHIVE_COMPLETE') color = 'green'
          if (params.value === 'ARCHIVE_FAILED') color = 'red'
          if (params.value === 'EXPORT_INPROGRESS') color = 'orange'
          if (params.value === 'EXPORT_READY') color = 'green'
          if (params.value === 'EXPORT_FAILED') color = 'red'
          if (params.value === 'RESTORE_INPROGRESS') color = 'orange'
          if (params.value === 'RESTORE_COMPLETE') color = 'green'
          if (params.value === 'RESTORE_FAILED') color = 'red'
          if (params.value === 'ARCHIVE_DELETED') color = 'red'
          if (params.value === 'PROCESSING_COMPLETE') color = 'green'
          return { ...centerAlignCell, color: color }
        },
      },
      {
        field: 'fileName',
        colId: 'fileName',
        headerName: 'Filename',
      },
      {
        field: 'actionDate',
        colId: 'actionDate',
        headerName: 'Date',
        valueFormatter: (params) => dateTimeFormatter(params.data?.actionDate),
      },
      {
        field: 'actionBy',
        colId: 'actionBy',
        headerName: 'Username',
        valueGetter: (params) => getUserNameFromEmail(params.data?.actionBy),
      },
    ],
    [centerAlignCell, handleMenuClick, dateTimeFormatter],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const detailCellRendererParams = useMemo(
    () => {
      return {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: 'Time',
              field: 'dateTime',
              colId: 'dateTime',
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
              valueFormatter: (params) => {
                if (!params.value) return ''
                if (typeof params.value !== 'string') return ''
                if (params.value === '') return ''
                if (isDateLessThan(params.value, '1990-01-01')) return ''
                return new Date(Date.parse(params.value)).toLocaleDateString('default', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                })
              },
              width: 300,
            },
            {
              headerName: 'Task',
              field: 'task',
              colId: 'task',
              filter: 'agSetColumnFilter',
              filterParams: {
                excelMode: 'windows',
              },
              width: 800,
            },
          ],
          defaultColDef: {
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            sortable: false,
            resizable: true,
            editable: false,
          },
        },
        getDetailRowData: (params) => {
          const splitTaskInfo = params?.data?.taskInfo.split('\n')
          const taskData = []

          for (let i = 0; i < splitTaskInfo.length; i++) {
            const task = splitTaskInfo[i].trim()
            if (task.length === 0) continue

            const splitTask = splitTaskInfo[i].split(']')
            if (splitTask.length < 2) continue

            taskData.push({
              uid: i + 1,
              dateTime: splitTask[0].replace('[', '').trim().replace(' ', 'T'),
              task: splitTask[1].trim(),
            })
          }

          params.successCallback(taskData)
        },
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {showRestore ? (
        <DataAcqRestoreArchiveModal
          selectedFile={selectedRecord.current}
          onClose={() => {
            setShowRestore(false)
            fetchFileData()
          }}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={fileStatus}
          columnDefs={sortColDefs(columnDefs, 'dataAckFileStatusGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          masterDetail={true}
          detailCellRendererParams={detailCellRendererParams}
        />
      </div>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchFileData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DataAckFileStatusPage
