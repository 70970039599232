import React, { useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DmsEditor = (props) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const orgVal = useRef(null)

  const getOrgValDmsStr = () => {
    if (!orgVal.current) return ''
    return `${orgVal.current.dd}° ${orgVal.current.mm}' ${orgVal.current.ss}"${orgVal.current.val}`
  }

  const getValue = useCallback(() => {
    if (!gridApi.current) return getOrgValDmsStr()

    let data = []
    gridApi.current.forEachNode((node) => {
      if (node.data) data.push(node.data)
    })

    if (data.length === 0) return getOrgValDmsStr()

    data[0].dd = Number(data[0].dd)
    data[0].mm = Number(data[0].mm)
    data[0].ss = Number(data[0].ss)
    return `${data[0].dd.toFixed(0)}° ${data[0].mm.toFixed(0)}' ${data[0].ss.toFixed(3)}"${data[0].val}`
  }, [])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = useCallback((params) => {
    gridApi.current = params.api
  }, [])

  const onGridPreDestroyed = useCallback(() => {
    props.onValueChange(getValue())
    if (props.coordType === 'lat') {
      props.data.value1 = getValue()
    }
    if (props.coordType === 'long') {
      props.data.value2 = getValue()
    }
  }, [props, getValue])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])

  let columnDefs = [
    {
      field: 'dd',
      colId: 'dd',
      headerName: 'DD',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 180,
        precision: 0,
      },
    },
    {
      field: 'mm',
      colId: 'mm',
      headerName: 'MM',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 60,
        precision: 0,
      },
    },
    {
      field: 'ss',
      colId: 'ss',
      headerName: 'SS',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100,
        precision: 3,
      },
    },
    {
      field: 'val',
      colId: 'val',
      headerName: 'VAL',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
          values: props.coordType === 'lat' ? ['N', 'S'] : ['E', 'W'],
        }
      },
    },
  ]

  const gridOptions = {
    onCellDoubleClicked: (params) => {},
    rowHeight: 35,
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const getVal = (decVal, coordType) => {
    if (coordType === 'lat') {
      if (decVal < 0.0) {
        return 'S'
      } else {
        return 'N'
      }
    }

    if (decVal < 0.0) {
      return 'W'
    }
    return 'E'
  }

  const getData = () => {
    if (props.degVal === null || props.degVal === undefined) return []
    if (typeof props.degVal !== 'number') return []

    const positiveVal = Math.abs(props.degVal)
    let degrees = Math.trunc(positiveVal)
    let minutes = Math.trunc((positiveVal - degrees) * 60)
    let seconds = positiveVal * 3600.0 - minutes * 60.0 - degrees * 3600.0

    if (Math.abs(60.0 - seconds) < 0.0001) {
      seconds = 0
      minutes++
    }

    if (Math.abs(60.0 - minutes) < 0.0001) {
      minutes = 0
      degrees++
    }

    orgVal.current = {
      dd: Number(degrees.toFixed(0)),
      mm: Number(minutes.toFixed(0)),
      ss: Number(seconds.toFixed(3)),
      val: getVal(props.degVal, props.coordType),
    }

    return [orgVal.current]
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '320px', height: '60px' }}>
      <AgGridReact
        rowData={getData()}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={0}
        onGridReady={onGridReady}
        rowSelection='single'
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={(params) => []}
        getRowId={getRowId}
        onGridPreDestroyed={onGridPreDestroyed}
      />
    </div>
  )
}

export default DmsEditor
