import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '@mui/material/Dialog'
import InputForm from './InputForm'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'

const InputModal = ({ open, onClose, title, formData, submitForm = null, cancelFunction, validationSchema }) => {
  const [formDataState, setFormDataState] = useState(formData) // eslint-disable-line no-unused-vars

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <InputForm
          formId={`${title}-inputForm`}
          inputData={formDataState}
          submitForm={submitForm}
          validationSchema={validationSchema}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => cancelFunction()} color='secondary'>
          {submitForm !== null ? 'Cancel' : 'Close'}
        </Button>
        {submitForm !== null ? (
          <Button variant='contained' color='primary' type='submit' form={`${title}-inputForm`}>
            Submit
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

InputModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  formData: PropTypes.array.isRequired,
  submitForm: PropTypes.func.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
}

export default InputModal
