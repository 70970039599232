import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import * as jszip from 'jszip'

const uploadUrl = '/files/addExternalFiles'

const useExternalFiles = () => {
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()

  const uploadExternalFiles = async (files, filePath, wellName) => {
    if (!files && !Array.isArray(files)) return { response: false }

    const zip = new jszip()

    files.forEach((file) => {
      zip.file(file.name, file)
    })

    let zipFile = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })

    if (zipFile.size > 20000000) {
      return { response: false, error: 'Upload size exceeded 20MB' }
    }
    let formData = new FormData()

    const accessToken = await getAccessTokenSilently()

    let splitFolderPath = filePath.split('/')
    let subFolder = splitFolderPath[splitFolderPath.length - 1]
    if (subFolder === wellName) {
      subFolder = ''
    }

    let options = {
      url: uploadUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('folder', wellName)
    formData.append('subFolder', subFolder)
    formData.append('file', zipFile)

    const res = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (res.error) {
      return { response: false, error: `${res?.error?.response?.data?.error}` }
    }

    return { response: true }
  }

  return {
    uploadExternalFiles,
  }
}

export default useExternalFiles
