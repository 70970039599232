import { searchFunction } from './searchFunctions'

export const reduceWellData = (data, searchText) => {
  if (!Array.isArray(data)) return []
  return data.reduce((output, well) => {
    if (
      searchFunction(well, searchText, ['actualWell', 'jobNum', 'operator', 'state', 'county', 'rig', 'wellStatus']) ===
      false
    ) {
      return output
    }
    let index = output.findIndex((element) => element.operator === well.operator)
    if (index < 0) {
      output.push({
        header: well.operator,
        operator: well.operator,
        data: [
          {
            id: well.actualWell,
            desc: well.actualWell,
            ...well,
          },
        ],
      })
    }
    if (index >= 0) {
      output[index].data.push({
        id: well.actualWell,
        desc: well.actualWell,
        ...well,
      })
    }

    return output
  }, [])
}

export const reduceVirtualizedWellData = (
  data,
  searchText,
  allowedStatus,
  objectsOfInterest,
  onlyShowObjOfInterest,
) => {
  if (!Array.isArray(data)) return []

  let hasObjectsOfInterest = false

  if (
    objectsOfInterest &&
    objectsOfInterest.hasOwnProperty('rigs') &&
    objectsOfInterest.hasOwnProperty('districts') &&
    objectsOfInterest.hasOwnProperty('operators') &&
    Array.isArray(objectsOfInterest.rigs) &&
    Array.isArray(objectsOfInterest.districts) &&
    Array.isArray(objectsOfInterest.operators)
  ) {
    for (let i = 0; i < data.length; i++) {
      let opIndex = objectsOfInterest.operators.findIndex((element) => element === data[i].operator)
      let districtIndex = objectsOfInterest.districts.findIndex((element) => element === data[i].district)
      let rigIndex = objectsOfInterest.rigs.findIndex((element) => element === data[i].rig)
      if (opIndex >= 0 || rigIndex >= 0 || districtIndex >= 0) {
        hasObjectsOfInterest = true
        break
      }
    }
  }

  let allowedFollowingStatus = ['Active', 'Upcoming', 'Standby', 'TD']
  let opOrderedData = data.reduce((output, well) => {
    if (
      searchFunction(well, searchText, ['actualWell', 'jobNum', 'operator', 'state', 'county', 'rig', 'wellStatus']) ===
      false
    ) {
      return output
    }

    if (allowedStatus && Array.isArray(allowedStatus) && allowedStatus.length > 0) {
      if (allowedStatus.findIndex((status) => well.wellStatus === status) < 0) return output
    }

    let isObjectOfInterest = false
    if (hasObjectsOfInterest) {
      let index = output.findIndex((element) => element.operator === 'Following')
      if (index < 0) {
        output.push({
          header: 'Following',
          operator: 'Following',
          data: [],
        })
      }

      let opIndex = objectsOfInterest.operators.findIndex((element) => element === well.operator)
      let districtIndex = objectsOfInterest.districts.findIndex((element) => element === well.district)
      let rigIndex = objectsOfInterest.rigs.findIndex((element) => element === well.rig)
      if (opIndex >= 0 || rigIndex >= 0 || districtIndex >= 0) {
        isObjectOfInterest = true

        if (!allowedFollowingStatus.find((status) => well.wellStatus === status)) {
          isObjectOfInterest = false
        }
      }
    }

    if (isObjectOfInterest) {
      let index = output.findIndex((element) => element.operator === 'Following')
      if (index >= 0) {
        output[index].data.push({
          ...well,
        })
      }
      return output
    }

    let index = output.findIndex((element) => element.operator === well.operator)
    if (index < 0) {
      output.push({
        header: well.operator,
        operator: well.operator,
        data: [
          {
            ...well,
          },
        ],
      })
    }
    if (index >= 0) {
      output[index].data.push({
        ...well,
      })
    }
    return output
  }, [])

  if (onlyShowObjOfInterest && hasObjectsOfInterest) {
    let followIndex = opOrderedData.findIndex((op) => op.header === 'Following')
    if (followIndex >= 0) {
      opOrderedData = opOrderedData.slice(followIndex, followIndex + 1)
    }
  }

  let flatList = []
  opOrderedData.forEach((item) => {
    flatList.push({
      header: item.header,
      operator: item.operator,
      ...item.data,
    })
    item.data.forEach((dataItem) => {
      flatList.push({ ...dataItem })
    })
  })

  return flatList
}
