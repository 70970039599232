import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import * as XLSX from '@sheet/core'
import { Box } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

import Carousel from 'react-material-ui-carousel'
import LogisticsGrid from './LogisticsGrid'
import SearchBar from 'components/common/SearchBar'
import CircularProgress from '@mui/material/CircularProgress'
import { searchFunction } from 'components/common/searchFunctions'
import MapView from 'components/common/MapView/MapView'
import LogisticsChart from './LogisticsChart'
import { useSetRecoilState } from 'recoil'
import { currentPageAtom, wellListAtom, actionBarWidthAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import MenuButton from 'components/common/MenuButton'
import { appColors } from 'utils'
import useLogisticsOverview from 'components/common/hooks/useLogisticsOverview'
import GlobalPrefs from 'components/NavBar/GlobalPrefs'
import { useRecoilValue } from 'recoil'
import SplitPane from 'components/common/SplitPane'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContents: 'center', margin: '5px' }}>
      <CircularProgress />
    </Box>
  )
}

const LogisticsPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [filteredLogisticsData, setFilteredLogisticsData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [showChart, setShowChart] = useState(true)
  const allWells = useRecoilValue(wellListAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [logisticsData, isLoading, fetchLogisticsData] = useLogisticsOverview(allWells)
  const [mapPins, setMapPins] = useState([])
  const { searchBarStyle, getBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.logisticsKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSearch()
  }, [logisticsData, searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = () => {
    if (!Array.isArray(logisticsData)) {
      if (_isMounted.current) setFilteredLogisticsData([])
      return
    }

    let filteredData = logisticsData.reduce((output, invItem) => {
      if (
        !searchFunction(invItem, searchText, [
          'actualWell',
          'jobNum',
          'operator',
          'state',
          'county',
          'toolType',
          'compTypeText',
          'serialNum',
          'make',
          'model',
          'rig',
          'description',
          'od',
          'wellStatus',
          'cnxTop',
          'cnxBtm',
          'length',
        ])
      ) {
        return output
      }

      output.push({ ...invItem })
      return output
    }, [])

    const myMap = {}
    for (let i = 0; i < filteredData.length; i++) {
      let item = filteredData[i]
      myMap[item.actualWell] = {
        id: item.actualWell,
        actualWell: item.actualWell,
        jobNum: item.jobNum,
        operator: item.operator,
        state: item.state,
        county: item.county,
        rig: item.rig,
        wellStatus: item.wellStatus,
        latitude: parseFloat(item.lat),
        longitude: parseFloat(item.long),
      }
    }

    if (!_isMounted.current) return
    setFilteredLogisticsData(filteredData)
    setMapPins(Object.values(myMap))
  }

  const onXlsxExport = () => {
    if (!Array.isArray(filteredLogisticsData)) return
    if (filteredLogisticsData.length === 0) return

    let logisticsWs = XLSX.utils.json_to_sheet(filteredLogisticsData)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, logisticsWs, 'Logistics')
    XLSX.writeFile(wb, 'logistics.xlsx')
  }

  const getMenuItems = () => {
    let actions = [
      { icon: <RefreshIcon />, name: 'Refresh', onClick: () => fetchLogisticsData() },
      {
        icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
        name: 'Export to XLSX',
        onClick: onXlsxExport,
      },
    ]
    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Carousel',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Carousel',
        onClick: () => handleShowChart(),
      })
    }
    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  return (
    <SplitPane
      split='horizontal'
      defaultSize={'50%'}
      size={showChart ? '50%' : 0}
      style={{
        marginTop: '64px',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        minHeight: 'calc(100vh - 64px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}
      allowResize={true}>
      <Carousel autoPlay={false} height={'100%'} sx={{ width: '100%' }} swipe={false} fullHeightHover={false}>
        <MapView wells={mapPins} />
        <LogisticsChart logisticsData={filteredLogisticsData} />
      </Carousel>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}>
        <Box
          sx={{
            backgroundColor: getBackColor(),
            padding: '2px',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle()}
          />
          {isLoading ? <CircularIndeterminate /> : null}
          <GlobalPrefs />
        </Box>
        <LogisticsGrid invListData={filteredLogisticsData} searchText={searchText} />
        <StyledMenuIcon>
          <MenuButton actions={getMenuItems()} />
        </StyledMenuIcon>
      </Box>
    </SplitPane>
  )
}

export default LogisticsPage
