import React, { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import * as XLSX from '@sheet/core'
import { Icon as Iconify } from '@iconify/react'
import { Button, ButtonGroup, Box } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useSetRecoilState } from 'recoil'
import { footagePerRigAtom } from 'atoms'
import 'chartjs-adapter-moment'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledDateRangeBtn = styled(Button)({
  fontSize: '15px',
  fontWeight: 400,
  width: '55px',
  height: '40px',
})

const StyledDatePickerContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  display: 'flex',
  maxWidth: '350px',
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  },
}))

const StyledButtonGroup = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: '10px',
  right: '10px',
  alignSelf: 'end',
  [theme.breakpoints.down('md')]: {
    alignSelf: 'center',
  },
}))

const StyledRangePickerContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  left: 0,
  bottom: 0,
  display: 'flex',
  width: '100%',
  height: '100px',
  justifyContent: 'space-between',
}))

const getDateRange = (days) => {
  let dateTo = new Date(Date.now())
  let dateFrom = new Date(Date.parse('1900-01-01'))

  dateFrom = new Date(Date.now())
  dateFrom.setDate(dateFrom.getDate() - days)

  let dateFromStr = dateFrom.toISOString()
  dateFromStr = dateFromStr.substring(0, 10)
  dateFrom = new Date(Date.parse(dateFromStr + 'T00:00:01'))

  let dateToStr = dateTo.toISOString()
  dateToStr = dateToStr.substring(0, 10)
  dateTo = new Date(Date.parse(dateToStr + 'T23:59:59'))

  return { dateTo, dateFrom }
}

const FootageDashbaord = () => {
  const _isMounted = useRef(false)
  const [dateRangeSelection, setDateRangeSelection] = useState(7)
  const [dateRange, setDateRange] = useState(getDateRange(7))
  const [data, setData] = useState([])
  const { getUnitsText } = useUnits()
  const chartRef = useRef()
  const setFootageData = useSetRecoilState(footagePerRigAtom)
  const { theme, getTextColor } = useInnovaTheme()

  const getData = useInnovaAxios({
    url: '/kpi/footagePerRig',
  })

  const getFootageData = async () => {
    let payload = {
      incDailyActivity: false,
      dateFrom: new Date(Date.parse(dateRange.dateFrom)).toISOString(),
      dateTo: new Date(Date.parse(dateRange.dateTo)).toISOString(),
    }

    let response = await getData(payload)
    if (response?.data) {
      setData(response.data)
      setFootageData(response.data)
    } else {
      setData([])
      setFootageData([])
    }
  }

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getFootageData()
  }, [dateRange]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDateToggle = (days) => {
    setDateRangeSelection(days)
    setDateRange(getDateRange(days))
  }

  const options = {
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: true,
        text: 'Footage Dashboard',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
    },
    layout: {
      padding: {
        bottom: 30,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: '',
          color: appColors.headerTextColor,
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        stacked: true,
        position: 'left',
        ticks: {
          autoSkip: false,
          precision: 0,
        },
      },
      yAxisLine: {
        title: {
          display: true,
          text: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        stacked: false,
        position: 'right',
        ticks: {
          autoSkip: false,
          precision: 0,
          count: (context) => {
            return context.chart.scales.y.ticks.length
          },
        },
      },
    },
  }

  const createChartData = (data) => {
    if (!data || typeof data !== 'object' || data === null || data.length === 0)
      return { labels: ['No Data'], datasets: [] }

    let output = {
      labels: [],
      datasets: [
        {
          label: 'Slide',
          backgroundColor: appColors.slideColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'y',
        },
        {
          label: 'Rotate',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'y',
        },
        {
          type: 'line',
          label: 'Slide ROP',
          backgroundColor: appColors.itemTextColor,
          borderColor: appColors.itemTextColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'yAxisLine',
        },
        {
          type: 'line',
          label: 'Rotate ROP',
          backgroundColor: appColors.dirNptColor,
          borderColor: appColors.dirNptColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'yAxisLine',
        },
        {
          type: 'line',
          label: 'Avg ROP',
          backgroundColor: appColors.avgColor,
          borderColor: appColors.avgColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'yAxisLine',
        },
      ],
    }

    if (!data || !data.rigData) {
      return output
    }

    for (let i = 0; i < data.rigData.length; i++) {
      output.labels.push(data.rigData[i].rig)
      output.datasets[0].data.push(parseInt(data.rigData[i].drillingTotals.slideFootage))
      output.datasets[1].data.push(parseInt(data.rigData[i].drillingTotals.rotateFootage))
      output.datasets[2].data.push(parseInt(data.rigData[i].drillingTotals.slideRop))
      output.datasets[3].data.push(parseInt(data.rigData[i].drillingTotals.rotateRop))
      output.datasets[4].data.push(parseInt(data.rigData[i].drillingTotals.avgRop))
    }

    return output
  }

  const onXlsxExport = () => {
    if (!data) return
    if (!Array.isArray(data.rigData)) return
    if (data.rigData.length === 0) return

    let headers = []
    headers.push('Rig')
    headers.push('#Wells')
    headers.push('Total Drilled')
    headers.push('Slide Drilled')
    headers.push('Rotate Drilled')
    headers.push('Total Drilling Hrs')
    headers.push('Total D&C Hrs')
    headers.push('Slide Hrs')
    headers.push('Rotate Hrs')
    headers.push('Avg ROP')
    headers.push('Slide ROP')
    headers.push('Rotate ROP')

    const wb = XLSX.utils.book_new()
    let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })
    let dataArray = []
    let row = []

    data.rigData.forEach((dataset, i) => {
      row = []
      row.push(dataset.rig)
      row.push(dataset.numWells)
      row.push(dataset.drillingTotals.totalFootage)
      row.push(dataset.drillingTotals.slideFootage)
      row.push(dataset.drillingTotals.rotateFootage)
      row.push(dataset.drillingTotals.totalDrillingHours)
      row.push(dataset.drillingTotals.totalDandCHours)
      row.push(dataset.drillingTotals.slideHours)
      row.push(dataset.drillingTotals.rotateHours)
      row.push(dataset.drillingTotals.avgRop)
      row.push(dataset.drillingTotals.slideRop)
      row.push(dataset.drillingTotals.rotateRop)
      dataArray.push(row)
    })

    row = []
    dataArray.push([])

    row.push('TOTAL')
    row.push(data.numWells)
    row.push(data.drillingTotals.totalFootage)
    row.push(data.drillingTotals.slideFootage)
    row.push(data.drillingTotals.rotateFootage)
    row.push(data.drillingTotals.totalDrillingHours)
    row.push(data.drillingTotals.totalDandCHours)
    row.push(data.drillingTotals.slideHours)
    row.push(data.drillingTotals.rotateHours)
    row.push(data.drillingTotals.avgRop)
    row.push(data.drillingTotals.slideRop)
    row.push(data.drillingTotals.rotateRop)
    dataArray.push(row)

    XLSX.utils.sheet_add_aoa(ws, dataArray, { origin: `A2` })
    XLSX.utils.book_append_sheet(wb, ws, 'Footage Per Rig')
    XLSX.writeFile(wb, 'footagePerRig.xlsx')
  }

  return (
    <Box
      sx={{
        flexDirection: 'column',
        backgroundColor: appColors.itemBackColor,
        padding: '5px 10px 0px 10px',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        width: '99.9%',
        height: '100%',
      }}>
      <Bar ref={chartRef} options={options} data={createChartData(data)} />
      <StyledRangePickerContainer>
        <StyledDatePickerContainer>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateFrom}
              onChange={(date) => {
                let dt = new Date(Date.parse(date))
                setDateRangeSelection(-1)
                setDateRange({ dateFrom: dt.toISOString(), dateTo: dateRange.dateTo })
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
            />
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateTo}
              onChange={(date) => {
                let dt = new Date(Date.parse(date))
                setDateRangeSelection(-1)
                setDateRange({ dateFrom: dateRange.dateFrom, dateTo: dt.toISOString() })
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
            />
          </LocalizationProvider>
        </StyledDatePickerContainer>
        <StyledButtonGroup>
          <ButtonGroup
            variant='contained'
            sx={{
              '& .MuiButtonGroup-grouped:not(:last-of-type)': { borderRight: '0px', borderColor: 'black' },
            }}>
            <StyledDateRangeBtn
              startIcon={<Iconify icon='healthicons:excel-logo' />}
              sx={{
                color: '#1D6F42',
                borderBottom: '2px solid black',
                backgroundColor: '#111',
              }}
              onClick={(e) => {
                e.stopPropagation()
                onXlsxExport()
              }}></StyledDateRangeBtn>
            <StyledDateRangeBtn
              sx={{
                color: dateRangeSelection === 30 ? appColors.itemTextColor : 'rgba(102, 102, 102, 1)',
                borderBottom: dateRangeSelection === 30 ? '2px solid black' : '',
                backgroundColor: dateRangeSelection === 30 ? '#252525' : '#111',
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleDateToggle(30)
              }}>
              30d
            </StyledDateRangeBtn>
            <StyledDateRangeBtn
              sx={{
                color: dateRangeSelection === 7 ? appColors.itemTextColor : 'rgba(102, 102, 102, 1)',
                borderRight: dateRangeSelection === 7 ? '2px solid black' : '',
                borderBottom: dateRangeSelection === 7 ? '2px solid black' : '',
                backgroundColor: dateRangeSelection === 7 ? '#252525' : '#111',
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleDateToggle(7)
              }}>
              7d
            </StyledDateRangeBtn>
            <StyledDateRangeBtn
              sx={{
                color: dateRangeSelection === 1 ? appColors.itemTextColor : 'rgba(102, 102, 102, 1)',
                borderRight: dateRangeSelection === 1 ? '2px solid black' : '',
                borderBottom: dateRangeSelection === 1 ? '2px solid black' : '',
                backgroundColor: dateRangeSelection === 1 ? '#252525' : '#111',
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleDateToggle(1)
              }}>
              1D
            </StyledDateRangeBtn>
          </ButtonGroup>
        </StyledButtonGroup>
      </StyledRangePickerContainer>
    </Box>
  )
}

export default FootageDashbaord
