import React from 'react'
import BarChart from 'components/common/BarChartCjs'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InventoryChart = ({ inventoryData }) => {
  const { theme } = useInnovaTheme()
  
  const chartOptions = {
    animation: {
      duration: 0,
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (content) => {
            if (!inventoryData) return content.label
            return content[0].label
          },
        },
      },
    },
  }

  const createChartData = (data) => {
    if (!Array.isArray(data)) return { labels: [], datasets: [] }

    let chartLabels = [...new Set(data.map((comp) => comp.toolType))]
    let output = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Clean',
          backgroundColor: appColors.rotateColor,
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Dirty',
          backgroundColor: appColors.slideColor,
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Failed',
          backgroundColor: 'red',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
      ],
    }

    data.forEach((comp) => {
      let toolTypeIndex = output.labels.indexOf(comp.toolType)
      let seriesIndex = 0
      if (comp.dirty) seriesIndex = 1
      if (comp.failed) seriesIndex = 2

      output.datasets[seriesIndex].data[toolTypeIndex]++
    })

    return output
  }

  return <BarChart wellData={createChartData(inventoryData)} title='' chartOptions={chartOptions} />
}

export default InventoryChart
