import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { appColors, array2pipestr } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import AnalyticsBarChart from './AnalyticsBarChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getXlsxColumnLetter } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CostPerFootChart = () => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const { getUnitsText } = useUnits()
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { theme } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  const chartOptions = {
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.costPerFootKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [
        {
          backgroundColor: appColors.rotateColor,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!data || !data.costPerFt || !Array.isArray(data.costPerFt.wellCosts)) return output
    data.costPerFt.wellCosts.forEach((well) => {
      output.labels.push(well.actualWell)
      let wellCostPerFt = 0
      if (typeof well.costPerFt === 'number') {
        wellCostPerFt = well.costPerFt
      }
      output.datasets[0].data.push(wellCostPerFt)
    })

    return output
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      costPerFt: 'true',
    }

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet(
      [
        [`Well`],
        [`Operator`],
        [`JobNum`],
        [`Rig`],
        [`State`],
        [`County`],
        [`Well Cost`],
        [`Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Cost/ft`],
      ],
      {
        origin: 'A1',
      },
    )

    XLSX.utils.sheet_add_aoa(
      ws,
      [[`Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`], [`Total Cost`], [`Avg Cost Per Ft`]],
      { origin: 'A11' },
    )

    let colIndex = 1
    if (data && data.costPerFt && Array.isArray(data.costPerFt.wellCosts)) {
      data.costPerFt.wellCosts.forEach((well) => {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [`${well.actualWell}`],
            [`${well.operator}`],
            [`${well.jobNum}`],
            [`${well.rig}`],
            [`${well.state}`],
            [`${well.county}`],
            [well.wellCost],
            [well.totalDrilled],
            [well.costPerFt],
          ],
          { origin: `${getXlsxColumnLetter(colIndex)}1` },
        )
        colIndex += 1
      })

      XLSX.utils.sheet_add_aoa(
        ws,
        [[data.costPerFt?.totalDrilled], [data.costPerFt?.totalCost], [data.costPerFt?.avgCostPerFt]],
        { origin: 'B11' },
      )
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Cost Per Ft')
    XLSX.writeFile(wb, 'Cost Per Ft.xlsx')
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={getUnitsText(UNITS_FOR.Depth)}
      title={`Cost Per Ft - Avg: ${numberWithCommasDecimals(data?.costPerFt?.avgCostPerFt, 2)}`}
      xAxisTitle={'Well'}
      yAxisTitle={`Cost (${getUnitsText(UNITS_FOR.Cost)})`}
      chartOptions={chartOptions}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default CostPerFootChart
