import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Box } from '@mui/material'

function updateDisplaySettings(
  property,
  setDisplaySettings,
  displaySettings,
) {
  let newSettings = { ...displaySettings }
  if (!newSettings.hasOwnProperty(property)) return

  newSettings[property] = !newSettings[property]
  setDisplaySettings(newSettings)
}

function ControlsCheckBox({ label, value, propName, setDisplaySettings, displaySettings }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={() => {
            updateDisplaySettings(propName, setDisplaySettings, displaySettings)
          }}
          name={propName}
          style={{ color: '#FFF' }}
        />
      }
      label={<Typography style={{ color: '#FFF' }}>{label}</Typography>}></FormControlLabel>
  )
}

const ChartOptions = ({
  displaySettings,
  setDisplaySettings,
  minDataColor,
  midDataColor,
  maxDataColor,
  minMaxValues,
}) => {
  return (
    <FormGroup style={{ flexDirection: 'row' }}>
      <ControlsCheckBox
        label={'Wellbore'}
        value={displaySettings.showWellbore}
        propName={'showWellbore'}
        setDisplaySettings={setDisplaySettings}
        displaySettings={displaySettings}
      />
      <ControlsCheckBox
        label={'Overlay'}
        value={displaySettings.showOverlay}
        propName={'showOverlay'}
        setDisplaySettings={setDisplaySettings}
        displaySettings={displaySettings}
      />
      <ControlsCheckBox
        label={'Contact Force'}
        value={displaySettings.showContactForce}
        propName={'showContactForce'}
        setDisplaySettings={setDisplaySettings}
        displaySettings={displaySettings}
      />
      <ControlsCheckBox
        label={'Vertical Scale'}
        value={displaySettings.showVerticalScale}
        propName={'showVerticalScale'}
        setDisplaySettings={setDisplaySettings}
        displaySettings={displaySettings}
      />
      <ControlsCheckBox
        label={'Color by Bending Moment'}
        value={displaySettings.colorByBendingMoment}
        propName={'colorByBendingMoment'}
        setDisplaySettings={setDisplaySettings}
        displaySettings={displaySettings}
      />
      <ControlsCheckBox
        label={'Worksight'}
        value={displaySettings.showWorkSight}
        propName={'showWorkSight'}
        setDisplaySettings={setDisplaySettings}
        displaySettings={displaySettings}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '25px',
          color: '#FFFFFF',
          fontSize: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {numberWithCommasDecimals(minMaxValues?.minColorVal, 0)}
        <Box
          sx={{
            marginLeft: '5px',
            marginRight: '5px',
            width: `150px`,
            height: `20px`,
            background: `linear-gradient(to right, ${minDataColor}, ${midDataColor}, ${maxDataColor})`,
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        />
        {numberWithCommasDecimals(minMaxValues?.maxColorVal, 0)}
      </Box>
    </FormGroup>
  )
}

export default ChartOptions
