import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { uuidv4 } from 'utils/stringFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import BidDetailsGrid from './BidDetailsGrid'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getRows = (params) => {
  if (!params.node.data.hasOwnProperty('details')) return []
  if (!Array.isArray(params.node.data.details)) return []

  const rows = [
    {
      outlineLevel: 1,
      cells: [
        cell(''),
        cell('Description', 'header'),
        cell('Code', 'header'),
        cell('Value', 'header'),
        cell('Schedule', 'header'),
      ],
    },
  ].concat(
    ...params.node.data?.details?.map((record) => [
      {
        outlineLevel: 1,
        cells: [
          cell(''),
          cell(record.description, 'body'),
          cell(record.costCode, 'body'),
          cell(record.cost, 'body'),
          cell(record.costSchedule, 'body'),
        ],
      },
    ]),
  )
  return rows
}

const cell = (text, styleId) => {
  return {
    styleId: styleId,
    data: {
      type: /^\d+$/.test(text) ? 'Number' : 'String',
      value: String(text),
    },
  }
}

const BidCategoryGrid = ({ bidData, handleUpdate, editable }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const containerRef = useRef(null)
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleUpdateFunc(inputRow.current, 'add')
      }

      handleUpdateFunc(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const reqFields = useMemo(() => ['description', 'costCode'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: editable,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder, editable])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('bidCategoryGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('bidCategoryGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            columnKeys: ['description', 'costCode', 'cost', 'costSchedule'],
            getCustomContentBelowRow: (params) => getRows(params),
            fileName: 'BidCategory.xlsx',
            sheetName: 'BidCategorys',
            columnWidth: 120,
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleUpdateFunc = (data, action) => {
    if (!data) return
    if (!bidData.current) return
    if (!Array.isArray(bidData.current?.bidDetails)) {
      bidData.current.bidDetails = []
    }

    if (!isPinnedRowDataCompleted() && action === 'add') return

    if (action === 'update') {
      for (let i = 0; i < bidData.current.bidDetails.length; i++) {
        if (bidData.current.bidDetails[i].uid === data.uid) {
          bidData.current.bidDetails[i].description = data.description
          bidData.current.bidDetails[i].costCode = data.costCode
          bidData.current.bidDetails[i].cost = data.cost
          bidData.current.bidDetails[i].costSchedule = data.costSchedule
          break
        }
      }

      gridApi.current.applyTransaction({
        update: [data],
      })
    }

    if (action === 'add') {
      inputRow.current.uid = uuidv4()
      inputRow.current.bidDetails = []
      inputRow.current.cost = 0
      inputRow.current.costSchedule = 'EACH'
      bidData.current.bidDetails.push(inputRow.current)

      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })

      inputRow.current = {}
      if (gridApi.current) {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }
    }

    if (action === 'delete') {
      for (let i = 0; i < bidData.current.bidDetails.length; i++) {
        if (bidData.current.bidDetails[i].uid === data.uid) {
          bidData.current.bidDetails.splice(i, 1)
          break
        }
      }

      gridApi.current.applyTransaction({
        remove: [data],
      })
    }

    handleUpdate(bidData.current)
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom'
                  ? handleUpdateFunc(params.data, 'delete')
                  : handleUpdateFunc(inputRow.current, 'add')
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'bidDetails',
        colId: 'bidDetails',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 60,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'actions',
        colId: 'actions',
        width: 100,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        field: 'costCode',
        colId: 'costCode',
        headerName: 'Code',
        valueGetter: (params) => unescapeHtml(params.data?.costCode),
      },
      {
        field: 'cost',
        colId: 'cost',
        headerName: 'Value',
        valueFormatter: (params) => numberWithCommasDecimals(params.data?.cost, 2),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'costSchedule',
        colId: 'costSchedule',
        headerName: 'Schedule',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [
              'EACH',
              'DAY',
              'MILE',
              'RUN',
              'HOUR',
              'INCLUDED',
              'COST + 30',
              'COST + 25',
              'COST + 20',
              'COST + 15',
              'COST + 10',
              'COST + 5',
              'FOOTAGE',
            ],
          }
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          const detailGrid = gridApi.current.getDetailGridInfo(`BID_DETAIL_GRID-${params.data?.uid}`)
          if (!detailGrid) return true

          if (!Array.isArray(bidData.current?.bidDetails)) return true
          let rowIndex = bidData.current.bidDetails.findIndex((row) => row.uid === params.data?.uid)
          if (rowIndex < 0) return true
          if (!Array.isArray(bidData.current.bidDetails[rowIndex]?.details)) return true

          detailGrid.api?.setGridOption('rowData', bidData.current.bidDetails[rowIndex]?.details)
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        <BidDetailsGrid
          bidData={bidData}
          parentRowUid={params.data?.uid}
          handleUpdate={handleUpdate}
          masterGridApi={gridApi.current}
          editable={editable}
        />
      </Box>
    )
  })

  const excelStyles = useMemo(() => {
    return [
      {
        id: 'header',
        interior: {
          color: '#aaaaaa',
          pattern: 'Solid',
        },
      },
      {
        id: 'body',
        interior: {
          color: '#dddddd',
          pattern: 'Solid',
        },
      },
    ]
  }, [])

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    if (!bidData.current) return
    if (!Array.isArray(bidData.current?.bidDetails)) {
      bidData.current.bidDetails = []
    }

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let item = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          item.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        rowsToUpdate.push(cloneDeep(item.data))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newItem = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newItem[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!newItem.description) continue
        if (typeof newItem.description !== 'string') continue
        if (newItem.description === '') continue

        if (newItem.costCode === null || newItem.costCode === undefined) continue

        if (!newItem.costSchedule) newItem.costSchedule = 'DAY'
        if (typeof newItem.costSchedule !== 'string') newItem.costSchedule = 'DAY'
        if (newItem.costSchedule === '') newItem.costSchedule = 'DAY'

        newItem.uid = uuidv4()
        newItem.cost = Number(newItem.cost)

        rowsToAdd.push(newItem)
      }
    }

    if (rowsToAdd.length > 0) {
      bidData.current.bidDetails.push(...rowsToAdd.reverse())
      gridApi.current.applyTransaction({
        add: rowsToAdd.reverse(),
      })
    }

    if (rowsToUpdate.length > 0) {
      for (let i = 0; i < bidData.current.bidDetails.length; i++) {
        let rowIndex = rowsToUpdate.findIndex((row) => row.uid === bidData.current.bidDetails[i].uid)

        if (rowIndex >= 0) {
          bidData.current.bidDetails[i].description = rowsToUpdate[rowIndex].description
          bidData.current.bidDetails[i].costCode = rowsToUpdate[rowIndex].costCode
          bidData.current.bidDetails[i].cost = rowsToUpdate[rowIndex].cost
          bidData.current.bidDetails[i].costSchedule = rowsToUpdate[rowIndex].costSchedule
        }
      }

      gridApi.current.applyTransaction({
        update: rowsToUpdate,
      })
    }

    if (rowsToAdd.length > 0 || rowsToUpdate.length > 0) {
      handleUpdate(bidData.current)
    }

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  return (
    <React.Fragment>
      <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={!Array.isArray(bidData.current?.bidDetails) ? [] : bidData.current?.bidDetails}
          columnDefs={sortColDefs(columnDefs, 'bidCategoryGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
          excelStyles={excelStyles}
          processDataFromClipboard={processDataFromClipboard}
        />
      </div>
    </React.Fragment>
  )
}

export default BidCategoryGrid
