import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'

function useInnovaInstallTypes(refetchData) {
  const _isMounted = useRef(true)
  const [dropDowns, setDropDowns] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rawData, setRawData] = useState()

  const getData = useInnovaAxios({
    url: '/admin/licenses/getLicenseCompanys',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getDropDowns()
  }, [rawData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [refetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    const response = await getData()
    if (_isMounted.current && response?.data) {
      setRawData(response.data)
    }

  }

  async function getDropDowns() {
    if (_isMounted.current === true) setIsLoading(true)
    if (!rawData) return

    let newDropDowns = []
    if (Array.isArray(rawData.wellSeeker)) {
      rawData.wellSeeker.forEach((val) => {
        newDropDowns.push({ label: val.name, value: val.name, product: 'Well Seeker' })
      })
    }

    if (Array.isArray(rawData.engineering)) {
      rawData.engineering.forEach((val) => {
        newDropDowns.push({ label: val.name, value: val.name, product: 'Engineering' })
      })
    }

    if (_isMounted.current === true) {
      setDropDowns(newDropDowns)
      setIsLoading(false)
    }
  }

  return [dropDowns, isLoading]
}

export default useInnovaInstallTypes
