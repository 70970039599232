import React, { useState, useEffect, useRef, useMemo } from 'react'
import { appColors } from 'utils'
import { round } from 'utils/numberFunctions'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SummaryGrid = ({ data, isLoading }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const [groupRows, setGroupRows] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (groupRows) expandAllNodes('expand')
  }, [groupRows]) // eslint-disable-line react-hooks/exhaustive-deps

  const getWarningStr = (item) => {
    let warningStr = ''
    if (item?.minC2c) warningStr += 'Min C2C'
    if (item?.minEs) {
      if (warningStr !== '') warningStr += ', '
      warningStr += 'Min ES'
    }

    if (item?.minSf) {
      if (warningStr !== '') warningStr += ', '
      warningStr += 'Min SF'
    }

    return warningStr
  }

  const getSFcolor = (sf) => {
    if (!sf) return appColors.itemTextColor
    if (typeof sf !== 'number') return appColors.itemTextColor
    if (sf <= 1) return 'red'
    if (sf <= 3) return 'orange'
    return 'green'
  }

  const getC2Ccolor = (c2c) => {
    if (!c2c) return appColors.itemTextColor
    if (typeof c2c !== 'number') return appColors.itemTextColor
    if (c2c <= 50) return 'red'
    if (c2c <= 100) return 'orange'
    return 'green'
  }

  const isDataNull = (val) => {
    if (typeof val === 'string') val = parseFloat(val)
    if (Math.abs(val - -999.25) < 0.01) return ''

    return round(val, 2)
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    setTimeout(() => {
      autoSizeColumns()
    }, 100)

    expandAllNodes('expand')
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    params.api.sizeColumnsToFit()
    expandAllNodes('expand')
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const columnDefs = [
    {
      headerName: 'Well',
      field: 'offsetName',
      colId: 'offsetName',
      rowGroup: groupRows,
      hide: groupRows,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Ref MD',
      field: 'refMd',
      colId: 'refMd',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return isDataNull(params.value)
      },
    },
    {
      headerName: 'ES',
      field: 'ES',
      colId: 'ES',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return isDataNull(params.value)
      },
    },
    {
      headerName: 'C2C',
      field: 'C2C',
      colId: 'C2C',
      cellStyle: (params) => {
        return {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: getC2Ccolor(params.data?.C2C),
        }
      },
      valueFormatter: (params) => {
        return isDataNull(params.value)
      },
    },
    {
      headerName: 'SF',
      field: 'SF',
      colId: 'SF',
      cellStyle: (params) => {
        return {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: getSFcolor(params.data?.SF),
        }
      },
      valueFormatter: (params) => {
        return isDataNull(params.value)
      },
    },
    {
      headerName: 'Warning',
      field: 'warning',
      colId: 'warning',
      valueFormatter: (params) => {
        return getWarningStr(params.data)
      },
    },
  ]

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.itemIndex)
    }
  }, [])

  const groupRowRendererParams = {
    suppressCount: true,
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('acSummaryList', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Expand All',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="ri:expand-up-down-fill" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse All',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="ion:chevron-collapse-sharp" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('acSummaryList', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AntiColSummaryData.xlsx',
            sheetName: 'ACSummary',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleData = (data) => {
    if (!data || data?.length <= 0 || !Array.isArray(data)) return []
    let gridData = []
    for (let i = 0; i < data.length; i++) {
      if (data[i]?.minSf || data[i]?.minEs || data[i]?.minC2c) {
        gridData.push(data[i])
      }
    }

    return gridData
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        loading={isLoading}
        rowData={handleData(data)}
        columnDefs={sortColDefs(columnDefs, 'acSummaryList')}
        defaultColDef={defaultColDef}
        animateRows={true}
        headerHeight={30}
        getRowId={getRowId}
        rowSelection='multiple'
        enableRangeSelection='true'
        enableBrowserTooltips={true}
        groupDisplayType={'groupRows'}
        groupRowsSticky={true}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        groupRowRendererParams={groupRowRendererParams}
        getContextMenuItems={getContextMenuItems}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default SummaryGrid
