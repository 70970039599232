export function numberWithCommas(x) {
  if (typeof x !== 'number') return ''
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function numberWithCommasDecimals(x, dec = 2) {
  if (typeof x === 'string') x = parseFloat(x)
  if (isNaN(x)) return ''
  if (typeof x !== 'number' || typeof dec !== 'number') return ''
  if (Math.abs(x) < 0.00001) x = 0

  let parts = x.toFixed(dec).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function replaceEscapeSymbols(str) {
  if (!str) return ''
  if (typeof str !== 'string') return ''
  str = str.replace('&dq;', '"')
  str = str.replace('&sq;', "'")
  str = str.replace('&amp;', '&')
  str = str.replace('&gt;', '>')
  str = str.replace('&lt;', '<')
  str = str.replace('?', '')
  return str
}

export function camelCaseToLabel(camelCaseString) {
  if (typeof camelCaseString !== 'string') return ''
  if (camelCaseString === '') return ''
  camelCaseString = camelCaseString.trim()

  let label = camelCaseString.replace(/([A-Z])/g, ' $1').toLowerCase()
  label = label.charAt(0).toUpperCase() + label.slice(1)
  return label
}

export function removeSpecialSymbols(str) {
  if (!str) return ''
  if (typeof str !== 'string') return ''
  str = str.replace("'", '')
  str = str.replace('"', '')
  str = str.replace('\\', '')
  str = str.replace(':', '')
  str = str.replace('#', '')
  return str
}

export function splitDateString(inputStr, delimiter) {
  let splitStr = inputStr.split(delimiter)

  let output = { day: -1, month: -1, year: -1 }
  splitStr.forEach((val, i) => {
    if (val.substring(0, 1) === '0') val = val.substring(1)

    if (i === 0) output.year = val
    if (i === 1) output.month = val
    if (i === 2) output.day = val
  })

  return output
}

export function createShortDate(inputDate, delimiter, usFormat) {
  let output = ''
  if (inputDate !== '') {
    let dateByComponents = splitDateString(inputDate, delimiter)
    if (dateByComponents.day > 0 && dateByComponents.month > 0) {
      if (usFormat === true) {
        output = dateByComponents.month + '/' + dateByComponents.day
      } else {
        output = dateByComponents.year + '/' + dateByComponents.month
      }
    }
  }

  return output
}

export function toQueryString(params) {
  return (
    '?' +
    Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
  )
}

export function base64ToArrayBuffer(base64) {
  if (!base64) return null
  if (typeof base64 !== 'string') return null
  base64 = base64.replace('data:image/*;base64,', '')
  let binary_string = window.atob(base64)
  let len = binary_string.length
  let bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export function removeSymbolsFromName(input) {
  if (!input) return ''
  if (typeof input !== 'string') return ''

  input = input.replace('/', '')
  input = input.replace('\\', '')
  input = input.replace('"', '')
  input = input.replace("'", '')
  input = input.replace('#', '')
  input = input.replace(':', '')

  return input
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export function removeFileExt(filename) {
  const lastDotPosition = filename.lastIndexOf('.') // Find the position of the last dot in the filename

  // If there's no dot or it's the first character, return the original filename
  if (lastDotPosition === -1 || lastDotPosition === 0) {
    return filename
  }

  return filename.substring(0, lastDotPosition)
}
