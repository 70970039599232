import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import CrsSelectorEditor from 'components/common/CellEditors/CrsSelectorEditor'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const FieldGrid = ({ fieldData, dropDowns, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [fieldData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => {
          if (params?.data.tag !== 'systemDatumName' && params?.data.tag !== 'systemElevation') return true

          if (params?.data.tag === 'systemDatumName') {
            let isUserDefined = false
            params.api.forEachNodeAfterFilter((node) => {
              if (node.data.tag === 'systemDatum') {
                if (node.data.value === 'User Defined') isUserDefined = true
              }
            })

            return isUserDefined
          }

          if (params?.data.tag === 'systemElevation') {
            let isMsl = false
            params.api.forEachNodeAfterFilter((node) => {
              if (node.data.tag === 'systemDatum') {
                if (node.data.value === 'MSL') isMsl = true
              }
            })

            return !isMsl
          }
        },
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'bool') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'crs') {
            return {
              component: CrsSelectorEditor,
              params: {
                crsList: Array.isArray(params.data?.dropDownValues)
                  ? params.data?.dropDownValues.map((crs, index) => {
                      return { crs: crs, uid: index }
                    })
                  : [],
                maxWidth: containerRef.current ? containerRef.current.clientWidth : 100,
                maxHeight: containerRef.current ? containerRef.current.clientHeight : 100,
                orgCrs: params.data?.value,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: -1000000,
                max: 1000000,
                precision: 7,
              },
            }
          }

          return null
        },
        valueGetter: (params) => {
          if (params?.data?.type === 'text') {
            return unescapeHtml(params?.data?.value)
          }

          if (params?.data?.type === 'number') {
            return parseFloat(params?.data?.value)
          }

          return params?.data?.value
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, 2)}`
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getFieldData = (data) => {
    const { crsList, localCoordRef, northRef, systemDatum } = dropDowns

    let field = [
      { label: `Field Name`, value: '', id: -1, type: 'text', tag: `field` },
      {
        label: `Mapping Grid`,
        value: '',
        id: -1,
        type: 'crs',
        tag: `crs`,
        dropDownValues: Array.isArray(crsList) ? crsList : [],
      },
      {
        label: `Azimuth North Reference`,
        value: 'Grid',
        id: -1,
        type: 'dropDown',
        tag: `northRef`,
        dropDownValues: Array.isArray(northRef) ? northRef : [],
      },
      {
        label: `Local Coordinate System`,
        value: 'Well',
        id: -1,
        type: 'dropDown',
        tag: `localCoordRef`,
        dropDownValues: Array.isArray(localCoordRef) ? localCoordRef : [],
      },
      { label: `Apply Scale Factor`, value: false, id: -1, type: 'bool', tag: `applySf` },
      {
        label: `System Vertical Datum`,
        value: 'MSL',
        id: -1,
        type: 'dropDown',
        tag: `systemDatum`,
        dropDownValues: Array.isArray(systemDatum) ? systemDatum : [],
      },
      { label: `Elevation Above MSL`, value: 0, id: -1, type: 'number', tag: `systemElevation` },
      { label: `Datum Name`, value: 'Mean Sea Level', id: -1, type: 'text', tag: `systemDatumName` },
    ]

    for (let i = 0; i < field.length; i++) {
      field[i].id = i
      if (field[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(field[i].tag)) continue
      if (!data[field[i].tag]) continue
      field[i].value = data[field[i].tag]
    }

    return field
  }

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getFieldData(fieldData)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default FieldGrid
