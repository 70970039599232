import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import WellDepthTimeChart from 'components/WellPages/DailyReportsPages/CarouselItems/WellDepthTimeChart'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { createChartDataMultiWell } from 'components/common/depthVtimeFunctions'
import OverlayPrefs from './OverlayPrefs'
import { GetRigStateColor } from 'components/common/activitySelector'
import { chartSeriesColors } from 'utils'

const DailyReportDepthTimeChart = ({
  showSlideRotData = false,
  filteredDailyReportData = [],
  phaseFilter = [],
  witsData = [],
}) => {
  const _isMounted = useRef(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const { getUnitsText } = useUnits()
  const [filteredData, setFilteredData] = useState({
    slideRotateData: [],
    depthTimeData: [],
    wellData: [],
    witsData: [],
  })
  const [showHoleDepth, setShowHoleDepth] = React.useState(false)
  const [showBitDepth, setShowBitDepth] = React.useState(false)
  const [colorScheme, setColorScheme] = React.useState('activity')

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getChartData()
  }, [phaseFilter, filteredDailyReportData, colorScheme]) // eslint-disable-line react-hooks/exhaustive-deps

  const getDepthTimeData = (slideRotateData) => {
    const sortedArray = slideRotateData.sort((a, b) => {
      const dateA = new Date(a.isoDateTime)
      const dateB = new Date(b.isoDateTime)
      return dateA - dateB
    })

    let wellDepthVDaysData = {
      depthVDays: [],
      totalHours: 0,
      actualWell: currentWell,
    }

    let previousActivityEndTime = null
    let cumulativeDays = 0
    for (let i = 0; i < sortedArray.length; i++) {
      let isoStartTime = null
      let isoEndTime = null

      if (typeof sortedArray[i].isoDateTime === 'string' && sortedArray[i].isoDateTime.length >= 10) {
        let dateStr = sortedArray[i].isoDateTime.substring(0, 10)
        isoStartTime = `${dateStr}T${sortedArray[i].startTime}:00`
        isoEndTime = `${dateStr}T${sortedArray[i].endTime}:00`
      }

      if (!isoStartTime || !isoEndTime) continue

      let intervalTimeDays = sortedArray[i].hours / 24

      //Check for time gap between activites
      if (previousActivityEndTime) {
        if (previousActivityEndTime !== isoStartTime) {
          const diffInMilliseconds = new Date(Date.parse(isoStartTime)) - new Date(Date.parse(previousActivityEndTime))
          const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24)
          intervalTimeDays += diffInDays
        }
      }

      cumulativeDays += intervalTimeDays
      previousActivityEndTime = isoEndTime

      wellDepthVDaysData.depthVDays.push({
        activity: sortedArray[i].activity,
        bha: sortedArray[i].bha,
        bhaDesc: sortedArray[i].bhaDesc,
        bhaNumRep: sortedArray[i].bhaNumRep,
        phase: sortedArray[i].phase,
        depth: sortedArray[i].endDepth,
        days: cumulativeDays,
        isoDateTime: sortedArray[i].isoDateTime?.slice(0, 19) + 'Z',
      })
    }

    wellDepthVDaysData.totalHours = cumulativeDays * 24

    return wellDepthVDaysData
  }

  const getWitsDepthDaysData = (witsData, startDate, wellData, dailyActivityData) => {
    const sortedArray = witsData.sort((a, b) => {
      const dateA = new Date(a.timeStamp)
      const dateB = new Date(b.timeStamp)
      return dateA - dateB
    })

    let wellDepthVDaysData = []

    // daylight savings time offset -- often data is collected in standard time (no DST applied)
    // but the JS Date object always assumes DST is on. Use Jan 1st timezone offset to determine if DST should be backed out from startDate...
    const janDate = new Date('2020-01-01T00:00:00.000Z')
    const janOffset = janDate.getTimezoneOffset()
    const stOffset = startDate.getTimezoneOffset()
    const dstOriginOffset = (janOffset - stOffset) / (60 * 24)

    for (let i = 0; i < sortedArray.length; i++) {
      const datumDate = new Date(sortedArray[i].timeStamp)
      if (datumDate < startDate) continue
      let days = (datumDate - startDate) / (1000 * 60 * 60 * 24) + dstOriginOffset

      //use wellData to set phase, bha, and activity colors for each datum
      let lastWellDataIndex = 0
      for (let j = lastWellDataIndex; j < wellData[0].dataVals.length; j++) {
        if (wellData[0].dataVals[j].x > days) {
          lastWellDataIndex = j - 1
          break
        }
      }
      if (lastWellDataIndex < 0) lastWellDataIndex = 0

      let color = 'grey'
      if (colorScheme === 'phase') {
        color = wellData[0].dataVals[lastWellDataIndex].color
      } else if (colorScheme === 'bha') {
        if (wellData[0].dataVals[lastWellDataIndex].bhaNumRep >= 0) {
          color = chartSeriesColors[wellData[0].dataVals[lastWellDataIndex].bhaNumRep]
        }
      } else if (colorScheme === 'activity') {
        color = GetRigStateColor(sortedArray[i].rigState)
      }

      wellDepthVDaysData.push({
        bitDepth: sortedArray[i].bitDepth,
        holeDepth: sortedArray[i].holeDepth,
        days: days,
        isoDateTime: sortedArray[i].timeStamp?.slice(0, 19) + 'Z',
        phase: wellData[0].dataVals[lastWellDataIndex].phase,
        bha: wellData[0].dataVals[lastWellDataIndex].bha,
        bhaNumRep: wellData[0].dataVals[lastWellDataIndex].bhaNumRep,
        bhaDesc: dailyActivityData[lastWellDataIndex].bhaDesc,
        activity: wellData[0].dataVals[lastWellDataIndex].activity,
        color: color,
      })
    }

    return wellDepthVDaysData
  }

  const getChartData = () => {
    if (!Array.isArray(filteredDailyReportData) || filteredDailyReportData?.length <= 0) return []

    let dailyActivityData = []

    for (let i = 0; i < filteredDailyReportData.length; i++) {
      if (!filteredDailyReportData[i].hasOwnProperty('dailyActivity')) continue
      if (!Array.isArray(filteredDailyReportData[i].dailyActivity)) continue

      for (let j = 0; j < filteredDailyReportData[i].dailyActivity.length; j++) {
        if (Array.isArray(phaseFilter) && phaseFilter.length > 0) {
          let phaseIndex = phaseFilter.findIndex((phase) => phase === filteredDailyReportData[i].dailyActivity[j].phase)
          if (phaseIndex < 0) continue
        }

        dailyActivityData.push(filteredDailyReportData[i].dailyActivity[j])
      }
    }

    if (dailyActivityData.length === 0) {
      return
    }

    const sortedArray = dailyActivityData.sort((a, b) => {
      if (!a.isoDateTime || !b.isoDateTime) return 0

      const dateA = new Date(a.isoDateTime)
      const dateB = new Date(b.isoDateTime)
      return dateA - dateB
    })

    let witsDataArray = []
    const startDate = new Date(sortedArray[0].isoDateTime)
    const endDate = new Date(sortedArray[dailyActivityData.length - 1].isoDateTime)
    for (let i = 0; i < witsData.length; i++) {
      const witsDate = new Date(witsData[i].timeStamp)
      if (witsDate >= startDate && witsDate <= endDate) {
        witsDataArray.push(witsData[i])
      }
    }

    if (_isMounted.current) {
      const deptTimeData = getDepthTimeData(dailyActivityData)
      const wellData = createChartDataMultiWell([deptTimeData])

      // need wellData to set phase, bha, and activity colors for witsData
      // need dailyActivityData to get bhaDesc
      const witsData = getWitsDepthDaysData(witsDataArray, startDate, wellData, sortedArray)

      setFilteredData({
        slideRotateData: dailyActivityData,
        depthTimeData: [deptTimeData],
        wellData: wellData,
        witsData: witsData,
      })
    }
  }

  return (
    <React.Fragment>
      <WellDepthTimeChart
        style={{ width: '100%' }}
        xAxisTitle={'Days'}
        yAxisTitle={`Depth (${getUnitsText(UNITS_FOR.Depth)})`}
        wellData={filteredData.wellData}
        rawData={filteredData.depthTimeData}
        slideRotData={filteredData.slideRotateData}
        showSlideRotData={showSlideRotData}
        witsData={filteredData.witsData}
        showHoleDepth={showHoleDepth}
        showBitDepth={showBitDepth}
      />
      <OverlayPrefs
        showHoleDepth={showHoleDepth}
        showBitDepth={showBitDepth}
        setShowHoleDepth={setShowHoleDepth}
        setShowBitDepth={setShowBitDepth}
        colorScheme={colorScheme}
        setColorScheme={setColorScheme}
      />
    </React.Fragment>
  )
}

export default DailyReportDepthTimeChart
