import { pointInterp, pointInterpTVD } from 'utils/surveyFunctions'
import { getTargets3D } from './targets3d'
import { appColors } from 'utils'
import { Vector3 } from 'three'
import { threeDeeScan } from 'utils/threeDeeScan'

export function validateWellboreSurveyIndexValue(wellData, index) {
    if (!Array.isArray(wellData)) return 0
    if (wellData.length === 0) return 0
    if (!Array.isArray(wellData[0]?.data)) return 0
    if (wellData[0].data.length === 0) return 0
    if (index >= wellData[0].data.length) return 0
    if (index < 0) return wellData[0].data.length - 1
    return index
}

export function isArrayValid(wellData) {
    if (!Array.isArray(wellData)) return false
    if (wellData.length === 0) return false
    if (!Array.isArray(wellData[0]?.data)) return false
    if (wellData[0].data.length === 0) return false
    return true
}

function checkArrayDataValid(inputData) {
    if (!inputData) return false
    if (inputData === null) return false
    if (!Array.isArray(inputData)) return false
    if (inputData.length === 0) return false

    return true
}

function getWellColor(wellType) {
    if (wellType === 'Planned') return appColors.planTrajectoryColor
    return appColors.surveyTrajectoryColor
}

function getAnnotations(outputArray, refWellData, annotations, scale) {
    annotations.forEach((annot) => {
        const { md, ns, ew, tvd } = pointInterp(refWellData, annot.md)

        if (md >= 0) {
            outputArray.push({
                x: ns * scale,
                y: -tvd * scale,
                z: ew * scale,
                name: annot.annotation,
            })
        }
    })
}

function getFormations(outputArray, refWellData, formations, scale) {
    for (let i = 0; i < formations.length - 1; i++) {
        const { md, ns, ew } = pointInterpTVD(refWellData, formations[i].tvd)

        outputArray.push({
            x: md >= 0 ? ns * scale : 0,
            y: -parseFloat(formations[i].tvd) * scale,
            z: md >= 0 ? ew * scale : 0,
            name: formations[i + 1].formation,
            found: md >= 0 ? true : false,
        })
    }
}

function getCasings(outputArray, refWellData, casing, scale) {
    let csgLen = 100 //This is the length of the casing interval to be displayed
    casing.forEach((csg) => {
        const { md, ns, ew, tvd } = pointInterp(refWellData, csg.MD)
        const interpPoint2 = pointInterp(refWellData, csg.MD - csgLen > 0 ? csg.MD - csgLen : 0)

        if (md >= 0 && interpPoint2.md >= 0) {
            outputArray.push({
                x: ns * scale,
                y: -tvd * scale,
                z: ew * scale,
                x2: interpPoint2.ns * scale,
                y2: -interpPoint2.tvd * scale,
                z2: interpPoint2.ew * scale,
                name: `${csg.OD} ${csg.Type}`,
                od: csg.OD,
            })
        }
    })
}

function createWellData(surveyData, name, color, scale, isPrincipal = false) {
    if (!Array.isArray(surveyData)) return {}

    let wellData = {
        isPrincipalPlan: isPrincipal,
        name,
        color,
        data: [],
        svyData: [],
    }

    surveyData.forEach((svy) => {
        wellData.data.push(new Vector3(svy.ns * scale, -svy.tvd * scale, svy.ew * scale))
        wellData.svyData.push({ ...svy })
    })

    return wellData
}

export function createChartData(wellData, wellName, refWellSurveys, scale, projections, isPlan) {
    let chartData = { refData: [], offsetData: [], formations: [], annotations: [], casing: [], targets: [], geosteering: [] }

    let svyCopy = []
    if (checkArrayDataValid(wellData?.wellPlan?.surveyData) && checkArrayDataValid(wellData?.surveys)) {
        wellData.surveys.forEach((svy) => {
            svyCopy.push(threeDeeScan({ ...svy, tf: svy.tfo }, wellData.wellPlan.surveyData))
        })
    } else {
        svyCopy = wellData?.surveys
    }

    if (Array.isArray(refWellSurveys) && refWellSurveys.length > 0) {
        svyCopy = []

        refWellSurveys.forEach((svy) => {
            svyCopy.push(threeDeeScan({ ...svy }, Array.isArray(wellData?.wellPlan?.surveyData) ? wellData.wellPlan.surveyData : []))
        })
    }

    if (Array.isArray(projections) && projections.length > 1) {
        //Ignore the first survey as this will be a duplicate of the last survey ref well surveys
        for (let i = 1; i < projections.length; i++) {
            let newProj = threeDeeScan({ ...projections[i] }, wellData.wellPlan.surveyData)
            newProj.isProjection = true
            svyCopy.push(newProj)
        }
    }


    if (checkArrayDataValid(svyCopy)) chartData.refData.push(createWellData(svyCopy, wellName, getWellColor(isPlan ? 'Planned' : 'Actual'), scale))

    if (checkArrayDataValid(wellData?.wellPlan?.surveyData)) {
        chartData.offsetData.push(
            createWellData(wellData.wellPlan.surveyData, wellData.wellPlan.offsetWellbore, getWellColor('Planned'), scale, true),
        )
    }

    if (checkArrayDataValid(wellData?.offsetWells)) {
        wellData.offsetWells.forEach((well) => {
            if (well.isPrincipal === true) return

            //Adds error ellipses to ref well
            if (well.offsetWellbore === wellName) {
                if (!Array.isArray(chartData.refData)) return
                if (chartData.refData.length === 0) return
                if (!Array.isArray(chartData.refData[0]?.svyData)) return
                if (!Array.isArray(well.surveyData)) return

                for (let i = 0; i < chartData.refData[0].svyData.length; i++) {
                    let index = well.surveyData.findIndex((x) => Math.abs(x.md - chartData.refData[0].svyData[i].md) < 0.1)
                    if (index < 0) continue
                    chartData.refData[0].svyData[i].semiMajor = well.surveyData[index].semiMajor
                    chartData.refData[0].svyData[i].semiMinor = well.surveyData[index].semiMinor
                }

                return
            }
            chartData.offsetData.push(createWellData(well.surveyData, well.offsetWellbore, getWellColor(well.wellStatus), scale))
        })
    }

    if (checkArrayDataValid(wellData?.surveys)) {
        if (checkArrayDataValid(wellData.actualWellData?.casing))
            getCasings(chartData.casing, wellData.surveys, wellData.actualWellData.casing, scale)
        if (checkArrayDataValid(wellData.actualWellData?.annotations))
            getAnnotations(chartData.annotations, wellData.surveys, wellData.actualWellData.annotations, scale)
        if (checkArrayDataValid(wellData.actualWellData?.lithologies))
            getFormations(chartData.formations, wellData.surveys, wellData.actualWellData.lithologies, scale)
    }

    if (checkArrayDataValid(wellData.targets)) chartData.targets = getTargets3D(wellData.targets, scale)

    return chartData
}

