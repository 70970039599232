import React, { useEffect, useRef } from 'react'
import BhaStatisticsChartHours from 'components/WellPages/DrillStringPages/CarouselItems/BhaStatisticsChartHours'
import useWellData from 'components/common/hooks/useWellData'

const BhaStatHoursCard = () => {
  const _isMounted = useRef(false)
  const { wellData } = useWellData()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  return <BhaStatisticsChartHours data={wellData} />
}

export default BhaStatHoursCard
