import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { stylesToGridData } from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/propertyUtils'
import CloudImageModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/CloudImageModal'
import useWallPlotComposerFiles from 'components/common/hooks/WallPlotComposer/useWallPlotComposerFiles'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ImageGrid = forwardRef(({ imageData, handleUpdate }, ref) => {
  const gridApi = useRef(null)
  const [cloudImageModalOpen, setCloudImageModalOpen] = useState(false)
  const { uploadImages } = useWallPlotComposerFiles(imageData?.wellName)
  const suppressChangeDetection = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      return gridData
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    gridApi.current.setGridOption('rowData', getPropertyData(imageData))

    setTimeout(() => {
      autoSizeColumns()
    }, 100)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
      cellDataType: false,
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const selectIconRenderer = useCallback((params) => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}>
        <IconButton
          style={{ padding: '1px', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => handleOpenCloudImageModal()}
          size='large'>
          <Iconify color={'#00AAFF'} icon={'radix-icons:button'} fontSize={32} />
        </IconButton>
      </Box>
    )
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        flex: 1,
        editable: true,

        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          if (params.data?.type === 'button') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index].value = params.newValue

          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }
          if (params.data?.type === 'button') {
            return {
              component: selectIconRenderer,
            }
          }
          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, params.data?.precision || 0)}`
          }
          if (params?.data?.type === 'fileInput') {
            return params?.data?.value.name
          }
          return params?.data?.value
        },
      },
    ],
    [selectIconRenderer],
  )

  const getPropertyData = (data) => {
    let propertyData = []
    if (data.imageType === 'image') {
      propertyData.push({
        label: `Image Source`,
        type: 'dropDown', // 'fileInput',
        value: 'default', // will be set below
        tag: `imageSrc`,
        dropDownValues: ['local', 'cloud'],
      })
      propertyData.push({
        label: `Image Desc`,
        value: data?.fileDesc ? data.fileDesc : 'default',
        type: 'text',
        tag: `fileDesc`,
      })
      propertyData.push({
        label: `Image File`,
        value: data?.fileName ? data.fileName : 'default',
        tag: 'fileName',
      })
      propertyData.push({
        label: 'Select File',
        value: 'select...',
        tag: 'selectFile',
        type: 'button',
      })
    }
    propertyData.push({
      label: `Maintain Aspect Ratio`,
      value: false,
      type: 'bool',
      tag: `aspectRatio`,
    })
    propertyData.push({
      label: `Resize with Container`,
      value: false,
      type: 'bool',
      tag: `resizeContainer`,
    })

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    if (!data) return propertyData
    const gridData = stylesToGridData(data)

    for (let i = 0; i < propertyData.length; i++) {
      if (propertyData[i].tag === '') continue
      if (propertyData[i].tag === 'imageSrc') {
        propertyData[i].value = data?.fileName === '' ? 'select...' : 'cloud' //prob cloud as we've already uploaded the local file
        continue
      }
      if (!gridData.hasOwnProperty(propertyData[i].tag)) continue
      propertyData[i].value = gridData[propertyData[i].tag]
    }

    return propertyData
  }

  const handleOpenFileDialog = (params) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.onchange = async (e) => {
      const file = e.target.files[0]
      gridApi.current.forEachNode((node) => {
        if (node.data && node.data.tag === 'fileDesc') node.setDataValue('value', file.name)
      })
      const result = await uploadImages([file.name], [file])
      if (!result || result.length < 1) {
        // report error
        return
      }
      // set Image source to 'cloud'
      // show the filename in the Image File cell
      suppressChangeDetection.current = true
      gridApi.current.forEachNode((node) => {
        if (node.data && node.data.tag === 'imageSrc') node.setDataValue('value', 'cloud')
        // if (node.data && node.data.tag === 'fileDesc') node.setDataValue('value', file.name)
        if (node.data && node.data.tag === 'fileName') node.setDataValue('value', result[0])
      })
    }
    input.click()
  }

  const handleOpenCloudImageModal = () => {
    setCloudImageModalOpen(true)
  }

  const handleCloudImageSelection = (fileInfo) => {
    if (!fileInfo) return // make user cancel if no selection
    gridApi.current.forEachNode((node) => {
      if (node.data && node.data.tag === 'fileName') node.setDataValue('value', fileInfo.fileName)
      if (node.data && node.data.tag === 'fileDesc') node.setDataValue('value', fileInfo.description)
    })
    setCloudImageModalOpen(false)
  }

  const onCellValueChanged = (params) => {
    if (suppressChangeDetection.current) {
      // if we are suppressing the next change, reset the suppress flag and return
      suppressChangeDetection.current = false
      return
    }
    if (params.data?.tag === 'imageSrc') {
      const selectedValue = params.newValue
      switch (selectedValue) {
        case 'local':
          handleOpenFileDialog(params)
          break
        case 'cloud':
          handleOpenCloudImageModal()
          break
        default:
          break
      }
    }
  }

  // using external filter to always hide the fileName row
  const doesExternalFilterPass = (node) => {
    if (node.data.tag === 'fileName') return false
    if (node.data.tag === 'selectFile') {
      let showSelectFile = false
      gridApi.current.forEachNode((node) => {
        if (node.data && node.data.tag === 'imageSrc' && node.data.value === 'cloud') showSelectFile = true
      })
      return showSelectFile
    }
    return true
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      {cloudImageModalOpen && (
        <CloudImageModal
          wellName={imageData?.wellName}
          onClose={() => setCloudImageModalOpen(false)}
          onDelete={() => {}}
          onApply={handleCloudImageSelection}
          title={'Select Image from Cloud'}
        />
      )}
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
        onCellValueChanged={onCellValueChanged}
        isExternalFilterPresent={() => {
          return true
        }}
        doesExternalFilterPass={doesExternalFilterPass}
      />
    </div>
  )
})

export default ImageGrid
