import React, { useEffect, useRef, useState } from 'react'

import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@mui/material'
import { Typography } from '@mui/material'
import { Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'

import { appColors } from 'utils'

const PickListSection = ({ itemList, selItems, setSelItems }) => {
  const _isMounted = useRef(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selItems]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true) setSelItems(newSelectedItems)
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true) setSelItems(newSelectedItems)
    }
  }

  const getChecked = (id) => {
    if (!selItems) return false
    if (Array.isArray(selItems) === false) return false
    if (selItems.includes(id) === true) return true

    return false
  }

  return (
    <React.Fragment>
      <List dense sx={{ width: '100%', maxWidth: 360, backgroundColor: 'itemBackground' }}>
        {itemList?.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`
          return (
            <ListItem key={value} button>
              <ListItemText id={labelId} primary={`${value}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge='end'
                  onChange={(event) => onItemSelect(event, value)}
                  checked={getChecked(value)}
                  inputProps={{ 'aria-labelledby': labelId }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </React.Fragment>
  )
}

const SimplePickListDialog = (props) => {
  const { title, sectionHeader, items, open, setOpen, onApply, initSelItems } = props
  const [selectedItems, setSelectedItems] = useState(Array.isArray(initSelItems) ? initSelItems : [])

  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {sectionHeader !== '' ? (
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: '800',
              color: appColors.borderColor,
              backgroundColor: appColors.itemTextColor,
              py: '4px',
              px: 1,
            }}>
            {sectionHeader}
          </Typography>
        ) : null}
        <PickListSection itemList={items} selItems={selectedItems} setSelItems={setSelectedItems} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onApply(selectedItems)
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimplePickListDialog
