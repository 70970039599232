import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Snackbar, Button } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, CustomLoadingOverlay, relativeTimeMins, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import InvoiceQcErrorsGrid from 'components/InvoiceQc/InvoiceQcErrorsGrid'
import Avatar from '@mui/material/Avatar'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import InvoiceQcSettings from 'components/InvoiceQc/InvoiceQcSettings'
import InvoiceQcCostsModal from 'components/InvoiceQc/InvoiceQcCostsModal'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const getTimeDurationColor = (timeSinceMins) => {
  if (timeSinceMins === null || timeSinceMins === undefined) return 'tomato'
  if (typeof timeSinceMins === 'string' && timeSinceMins === '') return 'tomato'
  if (typeof timeSinceMins === 'string') timeSinceMins = parseFloat(timeSinceMins)

  if (timeSinceMins > 240) return 'tomato'
  if (timeSinceMins > 60) return 'orange'
  return 'green'
}

const InvoiceQcPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle, getWindowBarColor, getTextColor } = useInnovaTheme()
  const gridApi = useRef(null)
  const selectedRow = useRef(null)
  const [showCostsModal, setShowCostsModal] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [wellData, setWellData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dateRange, setDateRange] = useState({
    dateTo: new Date().toISOString(),
    dateFrom: new Date(Date.now() - 28 * 24 * 60 * 60 * 1000).toISOString(),
  })
  const containerRef = useRef(null)

  const getData = useInnovaAxios({
    url: '/admin/invoiceQc/invoiceQc',
  })

  function isValidDateTime(dateTime) {
    if (dateTime === null) return false
    const dateObj = new Date(dateTime)
    return dateObj instanceof Date && !isNaN(dateObj)
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.invoiceQcPageKey)
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  function validateDates(dates) {
    const { dateTo, dateFrom } = dates

    let newDates = { ...dates }
    if (new Date(Date.parse(dateFrom)).getTime() > new Date(Date.parse(dateTo)).getTime()) {
      newDates.dateFrom = dateTo
      newDates.dateTo = dateFrom
    }

    return newDates
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)

    let dates = validateDates(dateRange)
    const response = await getData({
      dateTo: dates.dateTo ? dates.dateTo : null,
      dateFrom: dates.dateFrom ? dates.dateFrom : null,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (response.error) {
      setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
      return
    }

    setWellData(Array.isArray(response.data) ? response.data : [])
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('invoiceQcGrid', 'colLayout', colLayout)
    }
  }

  const relativeTimeFormatter = useCallback(relativeTimeMins, [])

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('invoiceQcGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InvoiceQc.xlsx',
            sheetName: 'InvoiceQc',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleMenuClick = useCallback((action, data) => {
    if (typeof action !== 'string') return
    if (!data) return
    selectedRow.current = data

    if (action === 'wellCosts') {
      setShowCostsModal(true)
    }
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 35,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'View Well Costs',
                action: 'wellCosts',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='solar:dollar-bold' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        headerName: '',
        width: 60,
        colId: 'avatar',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
                '&:hover': { cursor: 'pointer' },
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        field: 'jobNum',
        colId: 'jobNum',
        headerName: 'Job #',
        valueGetter: (params) => unescapeHtml(params.data?.jobNum),
        editable: true,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'operator',
        colId: 'operator',
        headerName: 'Operator',
        valueGetter: (params) => unescapeHtml(params.data?.operator),
      },
      {
        field: 'wellName',
        colId: 'wellName',
        headerName: 'Well Name',
        valueGetter: (params) => unescapeHtml(params.data?.wellName),
      },
      {
        field: 'rig',
        colId: 'rig',
        headerName: 'Rig',
        valueGetter: (params) => unescapeHtml(params.data?.rig),
      },
      {
        field: 'ddCoordinator',
        colId: 'ddCoordinator',
        headerName: 'DD Coordinator',
        valueGetter: (params) => unescapeHtml(params.data?.ddCoordinator),
      },
      {
        headerName: 'DEX',
        field: 'icdsMinsSinceUpdate',
        colId: 'icdsUpdate',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: getTimeDurationColor(params.data?.icdsMinsSinceUpdate),
          }
        },
        valueGetter: (params) => {
          if (!params.data) return 99999999
          if (!params.data?.hasOwnProperty('icdsMinsSinceUpdate')) return 99999999
          if (params.data?.icdsMinsSinceUpdate === null || params.data?.icdsMinsSinceUpdate === undefined) {
            return 99999999
          }
          if (typeof params.data?.icdsMinsSinceUpdate === 'string' && params.data?.icdsMinsSinceUpdate === '') {
            return 99999999
          }

          return parseFloat(params.data?.icdsMinsSinceUpdate)
        },
        valueFormatter: (params) => relativeTimeFormatter(params.data?.icdsMinsSinceUpdate),
      },
      {
        field: 'wellStatus',
        colId: 'wellStatus',
        headerName: 'Well Status',
      },
      {
        field: 'numErrors',
        colId: 'numErrors',
        headerName: 'Total Errors',
        cellStyle: (params) => {
          return { ...centerAlignCell, color: params.data?.numErrors > 0 ? 'red' : '#FFFF' }
        },
      },
      {
        colId: 'totalCost',
        headerName: 'Total Cost',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.costs)) return 0
          let total = 0
          params.data?.costs.forEach((cost) => {
            total += cost.quantity * cost.value
          })

          return total
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.days',
        colId: 'days',
        headerName: 'Total Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.operatingDays',
        colId: 'operatingDays',
        headerName: 'Operating Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.operatingDaysMotor',
        colId: 'operatingDaysMotor',
        headerName: 'Motor Operating Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.operatingDaysRss',
        colId: 'operatingDaysRss',
        headerName: 'RSS Operating Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.operatingDaysMwd',
        colId: 'operatingDaysMwd',
        headerName: 'MWD Operating Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.standbyDays',
        colId: 'standbyDays',
        headerName: 'Standby Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.standbyDaysMotor',
        colId: 'standbyDaysMotor',
        headerName: 'Motor Standby Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.standbyDaysRss',
        colId: 'standbyDaysRss',
        headerName: 'RSS Standby Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.standbyDaysMWd',
        colId: 'standbyDaysMwd',
        headerName: 'MWD Standby Days',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.footage',
        colId: 'footage',
        headerName: 'Footage',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.motorFootage',
        colId: 'motorFootage',
        headerName: 'Motor Footage',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.rssFootage',
        colId: 'rssFootage',
        headerName: 'RSS Footage',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.mwdFootage',
        colId: 'mwdFootage',
        headerName: 'MWD Footage',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.motorRuns',
        colId: 'motorRuns',
        headerName: '#Motor Runs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.mwdRuns',
        colId: 'mwdRuns',
        headerName: '#MWD Runs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        field: 'stats.rssRuns',
        colId: 'rssRuns',
        headerName: '#RSS Runs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
    ],
    [centerAlignCell, handleMenuClick, relativeTimeFormatter],
  )

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const DetailCellRenderer = (params) => {
    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.7
    }

    return (
      <Box sx={{ height: height }}>
        <InvoiceQcErrorsGrid data={Array.isArray(params.data?.failedChecks) ? params.data?.failedChecks : []} />
      </Box>
    )
  }

  const updateDateRange = (value, newDate) => {
    let dateFrom = dateRange.dateFrom
    let dateTo = dateRange.dateTo

    if (value === 'dateFrom') {
      dateFrom = newDate
    }
    if (value === 'dateTo') {
      dateTo = newDate
    }

    const fromDate = new Date(dateFrom)
    const toDate = new Date(dateTo)
    const diffDays = Math.ceil((toDate - fromDate) / (1000 * 60 * 60 * 24))

    if (diffDays > 90 || diffDays < 0) {
      if (value === 'dateFrom') {
        toDate.setTime(fromDate.getTime() + 90 * 24 * 60 * 60 * 1000)
        dateTo = toDate.toISOString().split('T')[0]
      } else {
        fromDate.setTime(toDate.getTime() - 90 * 24 * 60 * 60 * 1000)
        dateFrom = fromDate.toISOString().split('T')[0]
      }
    }

    setDateRange({ dateTo, dateFrom })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {showSettingsModal ? <InvoiceQcSettings onClose={() => setShowSettingsModal(false)} /> : null}
      {showCostsModal ? (
        <InvoiceQcCostsModal
          onClose={() => setShowCostsModal(false)}
          data={Array.isArray(selectedRow.current?.costs) ? selectedRow.current.costs : []}
        />
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ flex: 0.5, marginRight: '8px' }}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateFrom}
              onChange={(date) => {
                if (!isValidDateTime(date)) {
                  setDateRange({ dateFrom: '', dateTo: dateRange.dateTo })
                  return
                }

                let dt = new Date(Date.parse(date))
                updateDateRange('dateFrom', dt.toISOString().substring(0, 10))
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
            />
          </Box>
          <Box sx={{ flex: 0.5, marginRight: '8px' }}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateTo}
              onChange={(date) => {
                if (!isValidDateTime(date)) {
                  setDateRange({ dateFrom: dateRange.dateFrom, dateTo: '' })
                  return
                }

                let dt = new Date(Date.parse(date))
                updateDateRange('dateTo', dt.toISOString().substring(0, 10))
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
              sx={{ width: '150px' }}
            />
          </Box>
        </LocalizationProvider>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            fetchData()
          }}>
          Update
        </Button>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(3)}
        />
      </Box>
      <Box ref={containerRef} sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={wellData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'invoiceQcGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            getRowId={getRowId}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
            {
              icon: (
                <Iconify
                  icon={'fluent:settings-24-filled'}
                  style={{ color: '#2e6da4', height: '35px', width: '35px' }}
                />
              ),
              name: 'Settings',
              onClick: () => {
                setShowSettingsModal(true)
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InvoiceQcPage
