import { numberWithCommasDecimals } from 'utils/stringFunctions'
import * as XLSX from '@sheet/image'

import { isValidDate } from 'components/common/hooks/useDailyCosts/useDailyCosts'

import {
  getStyles,
  mergeCells,
  emptyCells,
  generateXlsColumnWidth,
  makeMergedCells,
  makeTableHeaderData
} from 'components/common/ExcelReports/ExcelReportFunctions'
import { DEFAULT_REPORT_SETTINGS } from 'components/Admin/Organizations/ReportSettingsModal'

export const generateCostTrackingXls = (
  costDataRef,
  startDate,
  endDate,
  unitTextCost,
  getCurrentOrgIcon,
  wellName,
  reportSettings = DEFAULT_REPORT_SETTINGS
) => {
  if (!isValidDate(startDate) || !isValidDate(endDate)) return null
  if (!costDataRef) return null
  if (!costDataRef.hasOwnProperty('dailyCosts')) return null
  if (!Array.isArray(costDataRef.dailyCosts)) return null
  if (costDataRef.dailyCosts.length <= 0) return null

  const styles = getStyles(reportSettings.primaryColor)

  let wb = XLSX.utils.book_new()
  let ws = XLSX.utils.aoa_to_sheet([[]], {
    origin: 'B2',
  })

  ws['!rows'] = [...emptyCells(7), { hpx: 100 }]
  ws['!merges'] = []
  ws['!cols'] = []
  generateXlsColumnWidth(ws, 12, 15)

  //-------------------------HEADER-----------------------------------
  XLSX.utils.sheet_add_aoa(ws, getCostTrackerHeaderData(costDataRef), {
    origin: 'I2',
  })

  let headerColDef = [
    { colSpan: 5, headerStyle: styles.right },
    { colSpan: 8, headerStyle: styles.leftttt },
    { colSpan: 5, headerStyle: styles.right },
    { colSpan: 6, headerStyle: styles.leftttt },
    { colSpan: 4, headerStyle: styles.right },
    { colSpan: 10, headerStyle: styles.leftttt },
  ]

  ws['!merges'].push(...makeMergedCells(headerColDef, 1, 8, 5))

  ws['!images'] = [
    {
      '!pos': {
        c: 1,
        r: 1,
        x: 15,
        y: 5,
        w: 120,
        h: 120,
      },
      '!datatype': 'base64',
      '!data': getCurrentOrgIcon(),
    },
  ]

  //-------------------------DISCLAIMER-----------------------------------
  XLSX.utils.sheet_add_aoa(ws, [[
    'This document is a FIELD ESTIMATE ONLY. Prices and terms are subject to change once reviewed by management',
  ]], {
    origin: 'P7',
  })

  ws['!merges'].push(...makeMergedCells([{ colSpan: 31, headerStyle: styles.leftBold }], 6, 15, 1))

  //-------------------------COST TRACKER DATA-----------------------------------
  const summaryColDef = [
    {
      label: 'Description',
      colSpan: 7,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.righttttt
    },
    {
      label: 'Cost Code',
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.centertttt
    },
    {
      label: 'Per',
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.centertttt
    },
    {
      label: `Cost (${unitTextCost})`,
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.righttttt
    },
    {
      label: `Qty`,
      colSpan: 1,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.centertttt
    },
    {
      label: `Line Totals (${unitTextCost})`,
      colSpan: 2,
      headerStyle: styles.fillCenterBoldtttt,
      style: styles.righttttt
    },
  ]

  XLSX.utils.sheet_add_aoa(ws, makeTableHeaderData(summaryColDef), {
    origin: `B9`,
  })

  ws['!merges'].push(...makeMergedCells(summaryColDef, 8, 1, 1))

  //-------------------------COST TRACKER SUMMARY DATA-----------------------------------
  let xlsCostTrackerData = getCostTrackerData(costDataRef)
  const { xlsData, mergedCells } = getCostTrackerSummaryData(xlsCostTrackerData, summaryColDef, styles)

  ws['!merges'].push(...mergedCells)
  XLSX.utils.sheet_add_aoa(ws, xlsData, { origin: 'B10' })
  XLSX.utils.sheet_add_aoa(ws, [['Note: TRUCKING, INSPECTION, REPAIRS & ANY THIRD PARTY CHARGES TO FOLLOW']], { origin: 'B14' })

  //-------------------------COST TRACKER TOTALS-----------------------------------
  XLSX.utils.sheet_add_aoa(ws, getCostTrackerDailyData(costDataRef, startDate, endDate, xlsData), { origin: 'P8' })
  XLSX.utils.book_append_sheet(wb, ws, 'Cost Tracking')

  addStyles(ws, styles)
  XLSX.utils.sheet_set_range_style(ws, `B2:${ws['!ref'].split(':')[1]}`, {
    top: { style: 'thin' },
    right: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
  })

  XLSX.writeFile(wb, `${wellName} Cost Tracking.xlsx`, { cellStyles: true, bookImages: true })
}

const getCostTrackerHeaderData = (costDataRef) => {
  return [
    [
      'Operator:',
      ...emptyCells(4),
      costDataRef.operator,
      ...emptyCells(7),
      'Job #:',
      ...emptyCells(4),
      costDataRef.jobNumber,
      ...emptyCells(5),
      'State / County:',
      ...emptyCells(3),
      `${costDataRef?.state} / ${costDataRef?.county}`,
    ],
    [
      'Well Name:',
      ...emptyCells(4),
      costDataRef?.actualWellName,
      ...emptyCells(7),
      'Day DD:',
      ...emptyCells(4),
      Array.isArray(costDataRef?.directionalDrillers) && costDataRef?.directionalDrillers.length > 0 ? costDataRef?.directionalDrillers[0] : '',
      ...emptyCells(5),
      'API #:',
      ...emptyCells(3),
      costDataRef?.apiJobNum,
    ],
    [
      'AFE #:',
      ...emptyCells(4),
      costDataRef?.afeNum,
      ...emptyCells(7),
      'Night DD:',
      ...emptyCells(4),
      Array.isArray(costDataRef?.directionalDrillers) && costDataRef?.directionalDrillers.length > 1 ? costDataRef?.directionalDrillers[1] : '',
      ...emptyCells(5),
      'Company Man:',
      ...emptyCells(3),
      Array.isArray(costDataRef?.companyMan) && costDataRef?.companyMan.length > 0 ? costDataRef?.companyMan[0] : ''
    ],
    [
      'PO #:',
      ...emptyCells(4),
      costDataRef?.poNum,
      ...emptyCells(7),
      'Day MWD:',
      ...emptyCells(4),
      Array.isArray(costDataRef?.mwdEngineers) && costDataRef?.mwdEngineers.length > 0 ? costDataRef?.mwdEngineers[0] : '',
      ...emptyCells(5),
      'Company Rep',
      ...emptyCells(3),
      ''
    ],
    ['Rig:',
      ...emptyCells(4),
      costDataRef?.rig,
      ...emptyCells(7),
      'Night MWD',
      ...emptyCells(4),
      Array.isArray(costDataRef?.directionalDrillers) && costDataRef?.directionalDrillers.length > 0 ? costDataRef?.directionalDrillers[0] : '',
      ...emptyCells(5),
      'Signature:',
      ...emptyCells(3), ''],
  ]
}

const getCostTrackerSummaryData = (data, colDef, styles) => {
  let curRow = 10
  let startCol = 1
  let xlsData = []
  let mergedCells = []
  let totalCost = '0.00'

  for (let i = 0; i < data.length; i++) {
    const rowData = []
    let curCol = startCol

    for (let j = 0; j < colDef.length; j++) {
      if (j >= data[i].length) {
        console.error('data out of range')
        continue
      }

      if (i === data.length - 1) {
        totalCost = data[i][data[i].length - 1]
        break
      }

      rowData.push(data[i][j])
      if (colDef[j]?.colSpan > 1) {
        rowData.push(...emptyCells(colDef[j].colSpan - 1))
      }

      mergedCells.push(
        mergeCells({
          row: curRow - 1,
          col: curCol,
          colEnd: curCol + colDef[j].colSpan - 1,
          style: colDef[j].style,
        }),
      )

      curCol += colDef[j].colSpan
    }

    if (rowData.length > 0) {
      curRow++
      xlsData.push(rowData)
    }
  }

  curRow--

  xlsData.push([...emptyCells(7), 'Total', ...emptyCells(4), totalCost])
  mergedCells.push(mergeCells({ row: curRow, col: 1, colEnd: 7, style: styles.centertttt })) //blank Description col
  mergedCells.push(mergeCells({ row: curRow, col: 8, colEnd: 12, style: styles.centertttt })) // Total
  mergedCells.push(mergeCells({ row: curRow, col: 13, colEnd: 14, style: styles.righttttt })) // Total Cost

  return { xlsData, mergedCells }
}

const getCostSchedule = (costCode, costCodesRef) => {
  if (!costCodesRef) return ''
  if (!Array.isArray(costCodesRef)) return ''
  if (!costCode) return ''
  let index = costCodesRef.findIndex((code) => code.costCode === costCode)
  if (index < 0) return ''
  return costCodesRef[index]?.costTypeDesc
}

const getCostTrackerData = (costDataRef) => {
  const { dailyCosts, costCodes } = costDataRef

  if (!Array.isArray(dailyCosts)) return null

  let costTrackerSummaryData = []

  let totalCost = 0
  let output = []
  for (let i = 0; i < dailyCosts.length; i++) {
    if (!Array.isArray(dailyCosts[i].costs)) continue

    for (let j = 0; j < dailyCosts[i].costs.length; j++) {
      if (!dailyCosts[i].costs[j].costCode) continue
      let index = output.findIndex((item) => item.costCode === dailyCosts[i].costs[j].costCode)
      totalCost += dailyCosts[i].costs[j].quantity * dailyCosts[i].costs[j].value

      if (index < 0) {
        output.push({ ...dailyCosts[i].costs[j] })
        continue
      }

      output[index].quantity += dailyCosts[i].costs[j].quantity
    }
  }

  output.forEach((item) =>
    costTrackerSummaryData.push([
      item.description,
      item.costCode,
      getCostSchedule(item.costCode, costCodes),
      numberWithCommasDecimals(item.value, 2),
      item.quantity,
      numberWithCommasDecimals(item.value * item.quantity, 2),
    ]),
  )

  costTrackerSummaryData.sort((a, b) => {
    return parseInt(a[1]) - parseInt(b[1])
  })

  costTrackerSummaryData.push([...emptyCells(1), 'Total', ...emptyCells(3), numberWithCommasDecimals(totalCost, 2, 2)])
  return costTrackerSummaryData
}

const getCostTrackerDailyData = (costDataRef, startDate, endDate, xlsData) => {
  if (!isValidDate(startDate) || !isValidDate(endDate)) return null
  if (!Array.isArray(xlsData) || xlsData?.length <= 0) return null

  let dailyData = []
  let dateLabels = []
  let startDateTime = new Date(startDate).getTime()
  let endDateTime = new Date(endDate).getTime()
  let oneDay = 24 * 60 * 60 * 1000

  if (endDateTime - startDateTime < oneDay * 30) endDateTime = startDateTime + oneDay * 30

  for (let time = startDateTime; time <= endDateTime; time += oneDay) {
    const currentDate = new Date(time)
    dateLabels.push(currentDate.toISOString().split('T')[0])
  }

  dailyData.push(dateLabels)

  let trimmedXlsData = xlsData.map((arr) => arr.filter((item) => item !== ''))
  for (let i = 0; i < trimmedXlsData.length + 2; i++) {
    dailyData.push([...Array(dateLabels.length).fill('')])
  }

  for (let i = 0; i < dailyData[0].length; i++) {
    let foundDailyCosts = costDataRef.dailyCosts.find((item) => item.date === dailyData[0][i])
    if (!foundDailyCosts) {
      dailyData[dailyData.length - 2][i] = '0.00'
      continue
    }

    foundDailyCosts.costs.forEach((cost) => {
      let foundIndex = trimmedXlsData.findIndex((arr) => arr[7] === cost.costCode) //arr[7] is costCode idx from trimmedXlsData

      if (foundIndex > -1) {
        const dataIndex = foundIndex + 2 //+2 accounts for date labels and blank row

        dailyData[dataIndex][i] = (dailyData[dataIndex][i] || 0) + parseFloat(cost.quantity)
      }
    })

    dailyData[dailyData.length - 2][i] = numberWithCommasDecimals(parseFloat(foundDailyCosts.totalCost), 2)
  }

  return dailyData
}

const addStyles = (ws, styles) => {
  if (!ws) return
  let finalRow = XLSX.utils.decode_cell(ws['!ref'].split(':')[1]).r

  for (const key in ws) {
    let decodedAddress = XLSX.utils.decode_cell(key)
    //date cell
    if (decodedAddress?.r === 7 && decodedAddress?.c > 14) {
      ws[key].s = styles.centerVerttttt
      ws['!cols'][decodedAddress.c] = { wch: 3 }

      //generate and style the cell below it:
      let nextRowAddress = XLSX.utils.encode_cell({ r: decodedAddress.r + 1, c: decodedAddress.c })
      ws[nextRowAddress].s = styles.fillCentertttt
    }

    //cost data header row
    if (decodedAddress.r === 8) {
      ws[key].s = styles.fillCenterBoldtttt
    }

    if (decodedAddress.r > 8 && decodedAddress.r < finalRow) {
      ws[key].s = decodedAddress.r === finalRow - 1 && decodedAddress.c > 14 ? styles.centerVerttttt : styles.centertttt
    }
  }

  ws['!merges'].forEach((cellAddresses) => {
    const { s, e, style } = cellAddresses

    let startAddress = XLSX.utils.encode_cell(s)
    let endAddress = XLSX.utils.encode_cell(e)
    let cellRange = `${startAddress}:${endAddress}`

    if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
  })

}
