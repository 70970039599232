import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import { styled } from '@mui/styles'
import { userUserRoleAtom } from 'atoms'
import axios from 'axios'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { Avatar } from '@mui/material'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useMapMarkers from 'components/common/hooks/useMapMarkers'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import UploadImageFileDialog from 'components/common/UploadImageFileDialog'
import { checkPermission } from 'components/userPermissions'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useOrganizationsDropDowns from 'components/common/hooks/useOrganizationsDropDowns'
import { debounce } from 'lodash'
import fileDownload from 'js-file-download'
import useUnits from 'components/common/hooks/useUnits'
import ProductFeaturesModal from 'components/Admin/Organizations/ProductFeatureModal'
import ReportSettingsModal from './ReportSettingsModal'
import WellSeekerSettingsModal from './WellSeekerSettingsModal'
import DirectionalScoreCardSettingsModal from './DirectionalScoreCardSettingsModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledReportIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const StyledImage = styled('img')(({ theme }) => ({
  height: '48px',
  borderRadius: '5px',
  backgroundColor: '#84d1df',
  display: 'flex',
}))

const OrganizationsPage = () => {
  const { refreshUnits } = useUnits()
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isImageUploading = useRef(false)
  const schemaUpdating = useRef(false)
  const isBackingUp = useRef(false)
  const isCleaning = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const selectedOrg = useRef(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getCompanyIcon, getCompanyLogo, refresh: refreshIcons } = useOrgIcons()
  const [dropDowns] = useInnovaAdminDropDowns(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showLogoUpload, setShowLogoUpload] = useState(false)
  const [showReportSettingsModal, setShowReportSettingsModal] = useState(false)
  const [showWellSeekerSettingsModal, setShowWellSeekerSettingsModal] = useState(false)
  const [showDirectionalScoreCardSettingsModal, setShowDirectionalScoreCardSettingsModal] = useState(false)
  const [showTitleLogoUpload, setShowTitleLogoUpload] = useState(false)
  const [showMapMarkerUpload, setShowMapMarkerUpload] = useState(false)
  const [showPickFeatures, setShowPickFeatures] = useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const { refreshMapMarkers } = useMapMarkers()
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  const { getAwsRegions, getProductFeatureList, getDbCredsForAwsRegion } = useOrganizationsDropDowns()

  const getData = useInnovaAxios({
    url: '/admin/getOrgList',
  })

  const addData = useInnovaAxios({
    url: '/admin/createOrg',
  })

  const updateData = useInnovaAxios({
    url: '/admin/updateOrg',
  })

  const deleteData = useInnovaAxios({
    url: '/admin/deleteOrg',
  })

  const updateSchema = useInnovaAxios({
    url: '/admin/updateSchema',
  })

  const saveFeatureAccess = useInnovaAxios({
    url: '/admin/replacePFAccess',
  })

  const cleanDatabase = useInnovaAxios({
    url: '/admin/cleanDatabase',
    timeout: 300000,
  })

  const createBackup = useInnovaAxios({
    url: '/admin/database/backupDatabase',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminInnovaOrgsKey)
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.organization)
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getData()
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!Array.isArray(res.data)) return
    gridApi.current.setGridOption(
      'rowData',
      res.data.sort((a, b) => a.organization.localeCompare(b.organization)),
    )
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchData()
  }

  const validateData = useCallback(
    (data) => {
      if (!data) return
      if (data.hasOwnProperty('innovaHosted')) {
        if (!data.innovaHosted) {
          data.icdsAllowActiveWellPush = false
          data.s3Bucket = ''
          data.awsRegion = ''
        }

        if (data.innovaHosted && typeof data.organization === 'string' && data.organization !== '') {
          data.s3Bucket = data.organization.toLowerCase() + 'externalfiles'
          data.databaseName = data.organization.toLowerCase() + 'WsDb'

          if (!data.hasOwnProperty('icdsPassword')) {
            data.icdsPassword = data.organization.toUpperCase()
          }

          if (!data.hasOwnProperty('awsRegion') || data?.awsRegion === '') data.awsRegion = 'us-east-2'

          data.icdsIp = 'www.innova-icds.com'
          if (data.awsRegion === 'eu-west-3') data.icdsIp = 'eu.innova-icds.com'
          if (data.awsRegion === 'me-central-1') data.icdsIp = 'uae.innova-icds.com'

          let awsDetails = getDbCredsForAwsRegion(data.awsRegion)
          if (awsDetails) {
            data.databaseAddress = awsDetails.dbServer
            data.databasePort = awsDetails.dbPort
            data.databaseUser = awsDetails.dbUser
            data.databasePass = awsDetails.dbPass
          }
        }
      }

      if (data.hasOwnProperty('authType')) {
        if (data.authType === 'Innova') {
          data.clientId = ''
          data.clientSecret = ''
          data.appId = ''
          data.tenantId = ''
          data.domainName = ''
        }
      }

      return data
    },
    [getDbCredsForAwsRegion],
  )

  const handleUpdate = useCallback(async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('organization')) return
    let payload = cloneDeep(data)
    payload.reportSettings = null
    payload = htmlSymbolHandling(payload)
    payload.reportSettings = data.reportSettings ? JSON.stringify(data.reportSettings) : null
    if (isUpdating.current) return
    isUpdating.current = true
    setLoading(true)
    let res = await updateData(payload)
    isUpdating.current = false
    if (!_isMounted.current) return
    setLoading(false)
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }
    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
      setStatus({ show: true, severity: 'success', message: `${data.organization} updated` })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

   const handleAddRow = useCallback(async () => {
     if (isAdding.current) return
     if (!isPinnedRowDataCompleted()) return
     inputRow.current.active = true
     inputRow.current.wellStatusStaleTime = 30
     inputRow.current.newWellStatus = 'Complete'
     inputRow.current.runWellStatusBot = false
     inputRow.current.icdsAllowActiveWellPush = false
     inputRow.current.mfaEnabled = false
     inputRow.current.icdsPort = getFreeIcdsPort()
     inputRow.current = htmlSymbolHandling(inputRow.current)
     setLoading(true)
     isAdding.current = true
     let res = await addData(inputRow.current)
     isAdding.current = false
     if (_isMounted.current) setLoading(false)
     if (res?.error) {
       setStatus({
         show: true,
         severity: 'error',
         message: `${res?.error?.response?.data?.error}`,
       })
       return
     }
     if (!res?.error) {
       inputRow.current.uid = res.data.uid
       gridApi.current.applyTransaction({
         add: [inputRow.current],
       })
       await refreshUnits()
       setStatus({ show: true, severity: 'success', message: `${inputRow.current.organization} added` })
     }
     inputRow.current = {}
     if (gridApi.current) {
       gridApi.current.setPinnedBottomRowData([inputRow.current])
       gridApi.current.refreshCells()
     }
   }, [refreshUnits, addData])

  const gridOptions = useMemo(() => {
    return {
      pinnedBottomRowData: [inputRow.current],
      onCellEditingStopped: (event) => {
        if (event.node?.rowPinned === 'bottom') {
          inputRow.current = validateData(event.data)
          gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
          gridApi.current.refreshCells()
          handleAddRow()
        }
        if (event.node?.rowPinned !== 'bottom') {
          handleUpdate(validateData(cloneDeep(event.data)))
        }
      },
      getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
      suppressRowClickSelection: true,
      sideBar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
          },
        ],
        defaultToolPanel: '',
        position: 'left',
      },
      onDragStopped: () => {
        saveColumnState()
      },
      onColumnVisible: () => {
        saveColumnState()
      },
      loadingOverlayComponent: CustomLoadingOverlay,
    }
  }, [handleUpdate, handleAddRow, validateData])

  const createPinnedCellPlaceholder = useCallback(({ colDef }) => {
    if (typeof colDef.field != 'string') return ''
    if (colDef.field.length === 0) return ''
    if (colDef.field === 'icdsPort') return ''
    if (colDef.field === 'newWellStatus') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }, [])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: false, //setting this to false improves scroll performance, image height slows this down
      autoHeaderHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueGetter: (params) => {
        if (params.node?.rowPinned !== 'bottom') return params.data[params.colDef.field]
        if (inputRow.current.hasOwnProperty(params.colDef.field)) return inputRow.current[params.colDef.field]
        return ''
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [isEmptyPinnedCell, createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(), 100)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('organizationsGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('organizationsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Organizations.xlsx',
            sheetName: 'Organizations',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    let requiredFields = [
      'organization',
      'databaseAddress',
      'databasePort',
      'databaseName',
      'databaseUser',
      'databasePass',
      'authType',
      'icdsIp',
      'icdsPassword',
      'innovaHosted',
    ]

    for (let i = 0; i < requiredFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredFields[i])) return false
    }

    if (inputRow.current.innovaHosted) {
      if (!inputRow.current.hasOwnProperty('awsRegion')) return false
      if (!inputRow.current.hasOwnProperty('s3Bucket')) return false
    }

    if (inputRow.current.authType !== 'Innova') {
      if (!inputRow.current.hasOwnProperty('clientId')) return false
      if (!inputRow.current.hasOwnProperty('clientSecret')) return false
      if (!inputRow.current.hasOwnProperty('tenantId')) return false
    }

    return true
  }

  const handleSchemaUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('organization')) return
      if (schemaUpdating.current) return

      setLoading(true)
      schemaUpdating.current = true
      const res = await updateSchema({ organization: data.organization })
      schemaUpdating.current = false
      if (_isMounted.current) setLoading(false)

      setStatus({
        show: true,
        severity: !res.error ? 'success' : 'info',
        message: !res.error ? 'Schema updated' : 'Schema already up to date',
      })
    },
    [updateSchema],
  )

  const handleCleanDatabase = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('organization')) return
      if (isCleaning.current) return

      setLoading(true)
      isCleaning.current = true
      const res = await cleanDatabase({ organization: data.organization })
      isCleaning.current = false
      if (_isMounted.current) setLoading(false)

      if (!res?.error && Array.isArray(res.data)) {
        let dateTime = new Date(Date.now()).toISOString().split('.')[0].replaceAll(':', '-')
        let filename = 'DatabaseClean-' + dateTime + '.log'
        fileDownload(res.data.join('\n'), filename)
      }

      setStatus({
        show: true,
        severity: !res.error ? 'success' : 'info',
        message: !res.error ? 'Database Cleaned' : `${res?.error?.response?.data?.error}`,
      })
    },
    [cleanDatabase],
  )

  const handleCreateBackup = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('organization')) return
      if (isBackingUp.current) return

      setLoading(true)
      isBackingUp.current = true
      const res = await createBackup({ organization: data.organization })
      isBackingUp.current = false
      if (_isMounted.current) setLoading(false)

      setStatus({
        show: true,
        severity: !res.error ? 'success' : 'error',
        message: !res.error ? 'Backup process started' : `${res?.error?.response?.data?.error}`,
      })
    },
    [createBackup],
  )

  const handleDelete = useCallback(async () => {
    if (!confirm.data) return
    if (!confirm.data.hasOwnProperty('organization')) return

    if (isDeleting.current) return
    setLoading(true)
    isDeleting.current = true
    let res = await deleteData(confirm.data)
    isDeleting.current = false
    if (_isMounted.current) setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      setStatus({ show: true, severity: 'success', message: `${confirm.data.organization} deleted` })
      gridApi.current.applyTransaction({
        remove: [confirm.data],
      })
    }
  }, [confirm]) // eslint-disable-line react-hooks/exhaustive-deps

 

  const onImageUpload = async (file) => {
    if (uploadImageFile(file, '/admin/uploadOrgIcon')) {
      setShowLogoUpload(false)
      await refreshIcons()
      if (gridApi.current) gridApi.current.refreshCells()
    }
  }

  const onMarkerUpload = async (file) => {
    if (uploadImageFile(file, '/admin/uploadOrgMarker')) {
      setShowMapMarkerUpload(false)
      await refreshMapMarkers()
      if (gridApi.current) gridApi.current.refreshCells()
    }
  }

  const onTitleLogoUpload = async (file) => {
    if (uploadImageFile(file, '/admin/uploadOrgLogo')) {
      setShowTitleLogoUpload(false)
      await refreshIcons()
      if (gridApi.current) gridApi.current.refreshCells()
    }
  }

  const uploadImageFile = async (file, url) => {
    if (!selectedOrg.current) return
    if (!selectedOrg.current.hasOwnProperty('organization')) return
    if (isImageUploading.current) return

    let formData = new FormData()
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: url,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('organization', selectedOrg.current.organization.toLowerCase())
    formData.append('file', file)

    setLoading(true)
    isImageUploading.current = true
    const res = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    isImageUploading.current = false
    setLoading(false)

    setStatus({
      show: true,
      severity: res.error ? 'error' : 'success',
      message: res.error ? `${res?.error?.response?.data?.error}` : 'image uploaded',
    })

    if (res.error) return
  }

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return
      selectedOrg.current = cloneDeep(data)

      if (action === 'delete') {
        if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
        setConfirm({
          show: true,
          title: 'Delete Organization',
          text: `Are you sure you want to delete ${data.organization}?`,
          data: data,
        })
      }

      if (action === 'directionalScoreCardSettings') {
        setShowDirectionalScoreCardSettingsModal(true)
      }

      if (action === 'wellSeekerSettings') {
        setShowWellSeekerSettingsModal(true)
      }

      if (action === 'dbSchema') {
        handleSchemaUpdate(data)
      }

      if (action === 'productsFeatures') {
        setShowPickFeatures(true)
      }

      if (action === 'logo') {
        setShowLogoUpload(true)
      }

      if (action === 'titlelogo') {
        setShowTitleLogoUpload(true)
      }

      if (action === 'mapMarker') {
        setShowMapMarkerUpload(true)
      }

      if (action === 'clean') {
        handleCleanDatabase(data)
      }

      if (action === 'backup') {
        handleCreateBackup(data)
      }

      if (action === 'reportSettings') {
        setShowReportSettingsModal(true)
      }
    },
    [handleCreateBackup, handleCleanDatabase, handleSchemaUpdate, userRole],
  )

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const isIcdsPortFree = (port) => {
    if (port === null || port === undefined) return false
    if (typeof port !== 'string' && typeof port !== 'number') return false
    if (typeof port === 'string') port = parseInt(port)
    if (!gridApi.current) return false

    let icdsPortFound = false
    gridApi.current.forEachNode((rowNode) => {
      if (rowNode.data.hasOwnProperty('icdsPort')) {
        if (parseInt(rowNode.data.icdsPort) === port) {
          icdsPortFound = true
        }
      }
    })

    return icdsPortFound
  }

  const getFreeIcdsPort = () => {
    if (!gridApi.current) return false

    let usedPorts = [42010, 42014]
    gridApi.current.forEachNode((rowNode) => {
      if (rowNode.data.hasOwnProperty('icdsPort')) {
        usedPorts.push(parseInt(rowNode.data.icdsPort))
      }
    })

    for (let i = 42000; i <= 42100; i++) {
      let index = usedPorts.findIndex((port) => port === i)
      if (index < 0) return i
    }

    return -1
  }

  const orgCellRenderer = useCallback(
    (params) => {
      if (!params) return null
      if (!params.data) return null
      if (typeof params.data?.organization !== 'string') return null

      return (
        <Avatar
          alt={params?.data?.organization}
          sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
          src={getCompanyIcon(params?.data?.organization)}
        />
      )
    },
    [getCompanyIcon],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: '',
        colId: 'rowNumber',
        headerName: '#',
        pinned: 'left',
        lockPosition: 'left',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (params.node?.rowPinned !== 'bottom') {
            return params.node.rowIndex + 1
          }

          return ''
        },
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: {
          menuItems: [
            {
              label: 'Delete',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <Iconify
                    icon='fa-regular:trash-alt'
                    style={{ color: 'red', height: '21px', width: '21px', padding: '0px' }}
                  />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Products & Features',
              action: 'productsFeatures',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='fluent-mdl2:product' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Update database schema',
              action: 'dbSchema',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='bi:database-up' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Organization avatar',
              action: 'logo',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='ri:image-fill' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Organization logo',
              action: 'titlelogo',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='ri:image-fill' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Organization map marker',
              action: 'mapMarker',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='ri:map-pin-fill' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Clean database',
              action: 'clean',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='carbon:clean' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Create backup',
              action: 'backup',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='clarity:backup-restore-line' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Report Colors',
              action: 'reportSettings',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='unjs:theme-colors' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Well Seeker Settings',
              action: 'wellSeekerSettings',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='material-symbols:component-exchange' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Dir. Scorecard Settings',
              action: 'directionalScoreCardSettings',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledReportIcon icon='iconoir:medal' />
                </StyledIconContainer>
              ),
            },
          ],
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'icon',
        colId: 'icon',
        headerName: '',
        width: 60,
        resizable: false,
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        cellRenderer: orgCellRenderer,
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => params?.node?.rowPinned === 'bottom',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned !== 'bottom') return
          if (typeof params.newValue !== 'string') {
            inputRow.current[params.colDef.field] = ''
          }

          if (!/^[a-zA-Z0-9_]*$/.test(params.newValue)) {
            inputRow.current[params.colDef.field] = ''
            return true
          }
          inputRow.current[params.colDef.field] = params.newValue.toUpperCase()
          return true
        },
      },
      {
        field: 'innovaHosted',
        colId: 'innovaHosted',
        headerName: 'Innova Hosted',
        width: 180,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'manageWellSeekerAccess',
        colId: 'manageWellSeekerAccess',
        headerName: 'Manage WS Access',
        width: 200,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        width: 120,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'titlelogo',
        colId: 'titlelogo',
        headerName: 'Org Logo',
        width: 200,
        resizable: true,
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          if (!params) return null
          if (!params.data) return null
          if (typeof params.data?.organization !== 'string') return null
          return getCompanyLogo(params?.data?.organization) ? (
            <StyledImage alt={`${params?.data?.organization}-logo`} src={getCompanyLogo(params?.data?.organization)} />
          ) : null
        },
      },
      {
        field: 'awsRegion',
        colId: 'awsRegion',
        headerName: 'AWS Region',
        editable: (params) => {
          if (params.node?.rowPinned !== 'bottom') return false
          if (!params.data?.hasOwnProperty('innovaHosted')) return false
          return params.data?.innovaHosted
        },
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let awsRegions = getAwsRegions()
          return {
            values: Array.isArray(awsRegions) ? awsRegions : [],
          }
        },
      },
      {
        field: 's3Bucket',
        colId: 's3Bucket',
        headerName: 'S3 Bucket',
        editable: (params) => {
          if (params.node?.rowPinned !== 'bottom') return false
          if (!params.data?.hasOwnProperty('innovaHosted')) return false
          return params.data?.innovaHosted
        },
      },
      {
        field: 'databaseName',
        colId: 'databaseName',
        headerName: 'Database Name',
      },
      {
        field: 'databaseAddress',
        colId: 'databaseAddress',
        headerName: 'Database Address',
      },
      {
        field: 'databasePort',
        colId: 'databasePort',
        headerName: 'Database Port',
        cellStyle: centerAlignCell,
      },
      {
        field: 'databaseUser',
        colId: 'databaseUser',
        headerName: 'DB Username',
      },
      {
        field: 'databasePass',
        colId: 'databasePass',
        headerName: 'DB Password',
        valueFormatter: (params) => {
          if (typeof params.data?.databasePass !== 'string') return ''
          if (params.data?.databasePass === '') return ''
          return '************'
        },
      },
      {
        field: 'authType',
        colId: 'authType',
        headerName: 'Authentication',
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(dropDowns.authTypes) ? dropDowns.authTypes.map((authType) => authType.label) : [],
          }
        },
      },
      {
        field: 'tenantId',
        colId: 'tenantId',
        headerName: 'Tenant Id',
        editable: (params) => {
          if (!params.data?.hasOwnProperty('authType')) return false
          return params.data?.authType !== 'Innova'
        },
      },
      {
        field: 'clientId',
        colId: 'clientId',
        headerName: 'Client Id',
        editable: (params) => {
          if (!params.data?.hasOwnProperty('authType')) return false
          return params.data?.authType !== 'Innova'
        },
      },
      {
        field: 'clientSecret',
        colId: 'clientSecret',
        headerName: 'Client Secret',
        editable: (params) => {
          if (!params.data?.hasOwnProperty('authType')) return false
          return params.data?.authType !== 'Innova'
        },
        valueFormatter: (params) => {
          if (params.data?.authType !== 'Innova') return ''
          if (typeof params.data?.clientSecret !== 'string') return ''
          if (params.data?.clientSecret === '') return ''
          return '************'
        },
      },
      {
        field: 'appId',
        colId: 'appId',
        headerName: 'App Id',
        editable: (params) => {
          if (!params.data?.hasOwnProperty('authType')) return false
          return params.data?.authType !== 'Innova'
        },
      },
      {
        field: 'domainName',
        colId: 'domainName',
        headerName: 'Domain',
        editable: (params) => {
          if (!params.data?.hasOwnProperty('authType')) return false
          return params.data?.authType !== 'Innova'
        },
      },
      {
        field: 'mfaEnabled',
        colId: 'mfaEnabled',
        headerName: 'MFA Enabled',
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'icdsIp',
        colId: 'icdsIp',
        headerName: 'ICDS Address',
      },
      {
        field: 'icdsPort',
        colId: 'icdsPort',
        headerName: 'ICDS Port',
        cellStyle: centerAlignCell,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          return true
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 42000,
          max: 42100,
          precision: 0,
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return false
          let icdsPort = params.newValue
          if (typeof icdsPort === 'string') icdsPort = parseInt(icdsPort)

          if (!isIcdsPortFree(icdsPort)) return false
          let dataCopy = cloneDeep(params.data)
          dataCopy.icdsPort = icdsPort

          return true
        },
      },
      {
        field: 'icdsPassword',
        colId: 'icdsPassword',
        headerName: 'ICDS Password',
      },
      {
        field: 'icdsAllowActiveWellPush',
        colId: 'icdsAllowActiveWellPush',
        headerName: 'Active Well Push',
        width: 200,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'runWellStatusBot',
        colId: 'runWellStatusBot',
        headerName: 'Well Status Bot',
        width: 200,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'wellStatusStaleTime',
        colId: 'wellStatusStaleTime',
        headerName: 'Stale Time (Days)',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 1,
          max: 360,
          precision: 0,
        },
      },
      {
        field: 'newWellStatus',
        colId: 'newWellStatus',
        headerName: 'New Well Status',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: ['Complete', 'Standby'],
          }
        },
      },
      {
        field: 'useBggmErrorEstimates',
        colId: 'useBggmErrorEstimates',
        headerName: 'Bggm Errors Estimates',
        width: 220,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
      {
        field: 'acDashEnabled',
        colId: 'acDashEnabled',
        headerName: 'AC Dashboard',
        width: 200,
        resizable: false,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        cellStyle: centerAlignCell,
      },
    ],
    [orgCellRenderer, dropDowns.authTypes, getAwsRegions, handleMenuClick, getCompanyLogo],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const applyPickFeatures = async (selectedFeatures) => {
    setShowPickFeatures(false)

    if (!selectedOrg.current) return
    if (!selectedOrg.current.hasOwnProperty('organization')) return
    if (!selectedFeatures) return false
    if (!Array.isArray(selectedFeatures)) return

    let strArray = ''
    selectedFeatures.forEach((feature) => {
      if (feature === '') return
      if (strArray !== '') strArray += ','
      strArray += feature
    })

    const res = await saveFeatureAccess({
      organization: selectedOrg.current.organization,
      features: strArray,
    })

    setStatus({
      show: true,
      severity: res.error ? 'error' : 'success',
      message: res.error ? `${res?.error?.response?.data?.error}` : 'Features updated',
    })

    if (gridApi.current) {
      selectedOrg.current.features = cloneDeep(selectedFeatures)
      gridApi.current.applyTransaction({
        update: [cloneDeep(selectedOrg.current)],
      })
    }

    selectedOrg.current = null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {showReportSettingsModal ? (
        <ReportSettingsModal
          reportSettings={selectedOrg.current?.reportSettings}
          onClose={(newSettings) => {
            setShowReportSettingsModal(false)
            if (!newSettings) {
              selectedOrg.current = null
              return
            }

            selectedOrg.current.reportSettings = newSettings
            handleUpdate(validateData(cloneDeep(selectedOrg.current)))

            selectedOrg.current = null
          }}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showWellSeekerSettingsModal ? (
        <WellSeekerSettingsModal
          onClose={() => setShowWellSeekerSettingsModal(false)}
          organization={selectedOrg.current?.organization}
        />
      ) : null}
      {showDirectionalScoreCardSettingsModal ? (
        <DirectionalScoreCardSettingsModal
          onClose={() => setShowDirectionalScoreCardSettingsModal(false)}
          organization={selectedOrg.current?.organization}
        />
      ) : null}
      {showPickFeatures ? (
        <ProductFeaturesModal
          onCancel={() => setShowPickFeatures(false)}
          onApply={applyPickFeatures}
          allFeatures={getProductFeatureList()}
          selectedFeatures={selectedOrg.current.features}
        />
      ) : null}
      {showLogoUpload && (
        <UploadImageFileDialog
          title='Upload Organization Avatar'
          open={showLogoUpload}
          setOpen={() => setShowLogoUpload(false)}
          onConfirm={() => {}}
          onUpload={onImageUpload}
        />
      )}
      {showTitleLogoUpload && (
        <UploadImageFileDialog
          title='Upload Organization Logo'
          open={showTitleLogoUpload}
          setOpen={() => setShowTitleLogoUpload(false)}
          onConfirm={() => {}}
          onUpload={onTitleLogoUpload}
        />
      )}
      {showMapMarkerUpload && (
        <UploadImageFileDialog
          title='Upload Organization Map Marker'
          open={showMapMarkerUpload}
          setOpen={() => setShowMapMarkerUpload(false)}
          onConfirm={() => {}}
          onUpload={onMarkerUpload}
        />
      )}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'organizationsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default OrganizationsPage
