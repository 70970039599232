import React, { useRef, useEffect, useMemo } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import EngineeringReportDetailsGrid from './EngineeringReportDetailsGrid'

const EngineeringReportGrid = ({engineeringDashboardData, selectedChart}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo (() => {
    return (params) => {
        return params.data?.reportName
    }
  }, [])

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const columnDefs = [
    {
      headerName: '',
      field: '',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      sortable: false,
      editable: false,
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: 'agGroupCellRenderer',
      cellStyle: centerAlignCell,
      width: 50,
    },
    {
      headerName: 'Report Type',
      field: 'reportName',
      colId: 'reportName',
      minWidth: 150,
    },
  ]

  const DetailCellRenderer = (params) => {
    return (
      <EngineeringReportDetailsGrid engineeringDashboardDetailsData={params.data?.reportDetails} />
    )
  }

  const isGroupOpenByDefault = (params) => {
    
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
      }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={engineeringDashboardData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          isGroupOpenByDefault={isGroupOpenByDefault}
        />
      </div>
    </Box>
  )
}

export default EngineeringReportGrid
