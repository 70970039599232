import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import AnalyticsBarChart from './AnalyticsBarChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { getDailyActivityColor } from 'utils/colorFunctions'

const ActivitySummaryChart = () => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/dailyActivityKpi',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.activitySummary)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const createActivityKey = (code, desc) => {
    return `${code}: ${desc}`
  }

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!Array.isArray(data) || data.length === 0) return output

    let distinctActivitys = new Set()
    data.forEach((well) => {
      if (!Array.isArray(well) || well.length === 0) return
      well.forEach((activity) => {
        distinctActivitys.add(createActivityKey(activity.code, activity.description))
      })
    })

    let activitys = Array.from(distinctActivitys)
    for (let i = 0; i < activitys.length; i++) {
      let splitCode = activitys[i].split(':')
      let code = ''
      if (splitCode.length > 1) {
        code = splitCode[0].trim()
      }

      let color = getDailyActivityColor(i, code)

      output.datasets.push({
        label: activitys[i],
        backgroundColor: color,
        categoryPercentage: 0.5,
        maxBarThickness: 24,
        data: Array(data.length).fill(0),
      })
    }

    for (let i = 0; i < data.length; i++) {
      if (!Array.isArray(data[i]) || data[i].length === 0) continue
      data[i].forEach((activity) => {
        let activityIndex = activitys.indexOf(createActivityKey(activity.code, activity.description))
        if (activityIndex > -1) {
          output.datasets[activityIndex].data[i] = parseFloat(activity.totalHours)
        }
      })
      output.labels.push(data[i][0].actualWell)
    }

    return output
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setIsLoading(true)
    const dataResponse = await getKpiData({
      wellList: array2pipestr(selectedWells),
      footage: 'true',
    })

    if (!_isMounted.current) return
    setIsLoading(false)
    setData(dataResponse?.data ? dataResponse.data : [])
  }

  const onXlsxExport = () => {
    if (!Array.isArray(data)) return

    let ws = XLSX.utils.aoa_to_sheet([['Well', 'Operator', 'Activity', 'Description', 'Hrs']], {
      origin: 'A1',
    })

    let rowNum = 2
    data.forEach((well) => {
      if (!Array.isArray(well) || well.length === 0) return
      well.forEach((activity) => {
        XLSX.utils.sheet_add_aoa(
          ws,
          [[well[0].actualWell, well[0].operator, activity.code, activity.description, activity.totalHours]],
          { origin: `A${rowNum}` },
        )

        rowNum++
      })
    })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Activity Summary')
    XLSX.writeFile(wb, 'Activity Summary.xlsx')
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={'Hrs'}
      title={`Activity Summary`}
      xAxisTitle='Wells'
      yAxisTitle={`Hours`}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
      showDataLabels={false}
      showLegend={false}
    />
  )
}

export default ActivitySummaryChart
