import { Box, CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { Canvas } from '@react-three/fiber'
import WorkSight from 'components/ThreeDeeView/ChartComponents/WorkSight'
import { createChartData } from 'components/ThreeDeeView/Utils/ThreeDeeChartUtils'
import WellSpheres from 'components/ThreeDeeView/WellboreComponents/WellSpheres'
import Wellbore from 'components/ThreeDeeView/WellboreComponents/Wellbore'
import OffsetWellbores from 'components/ThreeDeeView/WellboreComponents/OffsetWellbores'
import OffsetWellboreSpheres from 'components/ThreeDeeView/WellboreComponents/OffsetWellboreSpheres'
import OffsetErrorEllipses from 'components/ThreeDeeView/WellboreComponents/OffsetErrorEllipses'
import ErrorEllipse from 'components/ThreeDeeView/WellboreComponents/ErrorEllipse'
import OffsetWellheads from 'components/ThreeDeeView/Models/OffsetWellheads'
import Rig from 'components/ThreeDeeView/Models/Rig'
import GridLines from 'components/ThreeDeeView/ChartComponents/Grid'
import WellNames from 'components/ThreeDeeView/ChartComponents/WellNames'
import Annotations from 'components/ThreeDeeView/ChartComponents/Annotations'
import Casing from 'components/ThreeDeeView/ChartComponents/Casing'
import Formations from 'components/ThreeDeeView/ChartComponents/Formations'
import SurveyLabels from 'components/ThreeDeeView/ChartComponents/SurveyLabels'
import Targets from 'components/ThreeDeeView/ChartComponents/Targets'
import { DrillersTargets } from 'components/ThreeDeeView/ChartComponents/Targets'
import ChartOptions from 'components/ThreeDeeView/Controls/ChartOptions'
import ChartControls from 'components/ThreeDeeView/Controls/ChartControls'
import GeoSteering from 'components/ThreeDeeView/ChartComponents/Geosteering'
import FocusedSurveyInfo from 'components/ThreeDeeView/FocusedSurveyInfo'
import OrientationCompass from 'components/ThreeDeeView/Controls/OrientationCompass'
import { threeDeeScan } from 'utils/threeDeeScan'
import { focusedSurveySelector } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { debounce } from 'lodash'
import ThreeDeeViewPrefs from './ThreeDeeViewPrefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SCALE = 0.1
const LABEL_COLOR = '#909090'
const INITIAL_CAM_OFFSET = 30
const CAM_SPEED = 0.25

const ThreeDeeView = ({
  wellName,
  showControls = true,
  refWellSurveys,
  wellData,
  isLoading = false,
  projections = [],
  refGeosteering,
  isPlan = false,
  toggleErrorEllipses = null,
}) => {
  const focusedSurveyIndexRef = useRef(0)
  const cameraTargetRef = useRef(null)
  const [focusedSurveyIndex, setFocusedSurveyIndex] = useState(0)
  const [selectedOffset, setSelectedOffset] = useState({ name: '-', index: -1 })
  const closestApproachRef = useRef(null)
  const setFocusedSurvey = useSetRecoilState(focusedSurveySelector)
  const { getBackColor, theme } = useInnovaTheme()

  const [displaySettings, setDisplaySettings] = useState({
    surveyLabels: false,
    annotations: false,
    formations: false,
    targets: true,
    drillersTarget: true,
    casing: false,
    showWellLabels: true,
    showOffsets: true,
    showGeoSteering: false,
    showWellHeads: true,
    showRig: true,
    showSpheres: true,
    showErrorEllipses: false,
    workSightRadius: 50,
  })

  const [chartData, setChartData] = useState({
    refData: [],
    offsetData: [],
    formations: [],
    annotations: [],
    casing: [],
    targets: [],
  })

  useEffect(() => {
    if (toggleErrorEllipses && displaySettings?.showErrorEllipses) {
      toggleErrorEllipses(displaySettings?.showErrorEllipses)
    }
  }, [displaySettings, toggleErrorEllipses])

  const getFocusedSurveyStation = (index) => {
    if (!Array.isArray(chartData?.refData)) return null
    if (chartData.refData.length === 0) return null
    if (!Array.isArray(chartData.refData[0]?.svyData)) return null
    if (!chartData.refData[0].svyData.length === 0) return null
    if (index < 0) return chartData.refData[0].svyData[0]
    if (index >= chartData.refData[0].svyData.length) {
      return chartData.refData[0].svyData[chartData.refData[0].svyData.length - 1]
    }

    let svy = chartData.refData[0].svyData[index]

    if (
      Array.isArray(chartData?.offsetData) &&
      selectedOffset.index >= 0 &&
      selectedOffset.index < chartData?.offsetData.length
    ) {
      svy = threeDeeScan({ ...svy }, chartData?.offsetData[selectedOffset.index].svyData)
      closestApproachRef.current = svy
    } else {
      closestApproachRef.current = null
    }

    return svy
  }

  useEffect(() => {
    if (!wellData) return
    let newData = createChartData(wellData, wellName, refWellSurveys, SCALE, projections, isPlan)
    setChartData(newData)

    let offsetCopy = { ...selectedOffset }
    if (!offsetCopy.index >= 0) {
      if (Array.isArray(newData?.offsetData) && newData?.offsetData.length > 0) {
        let index = newData.offsetData.findIndex((well) => well.name === selectedOffset.name)
        if (index < 0) offsetCopy = { name: '-', index: -1 }
      }
    }

    if (offsetCopy.name === '-' && Array.isArray(newData?.offsetData) && newData?.offsetData.length > 0) {
      for (let i = 0; i < newData.offsetData.length; i++) {
        if (!newData.offsetData[i].isPrincipalPlan) continue
        offsetCopy = { name: newData.offsetData[i].name, index: i }
      }

      if (!offsetCopy.name === '-') {
        offsetCopy = { name: newData.offsetData[0].name, index: 0 }
      }
    }

    if (!Array.isArray(newData?.offsetData) || newData?.offsetData.length === 0) offsetCopy = { name: '-', index: -1 }

    setSelectedOffset(offsetCopy)
    handleFocusedSurveyIndexChange(focusedSurveyIndexRef.current)
  }, [wellData, refWellSurveys, projections]) // eslint-disable-line react-hooks/exhaustive-deps

  const changeOffsetWellIndex = (change) => {
    if (!Array.isArray(chartData?.offsetData)) {
      setSelectedOffset({ name: '-', index: -1 })
      return
    }

    if (chartData.offsetData.length === 0) {
      setSelectedOffset({ name: '-', index: -1 })
      return
    }

    let newIndex = selectedOffset.index + change
    if (newIndex < 0) newIndex = chartData.offsetData.length - 1
    if (newIndex >= chartData.offsetData.length) newIndex = 0

    setSelectedOffset({ name: chartData.offsetData[newIndex].name, index: newIndex })
  }

  const debounceFocusedSurveyIndexChange = debounce((newIndex) => {
    setFocusedSurveyIndex(newIndex)
    setFocusedSurvey(getFocusedSurveyStation(newIndex)) //Required for RT AC
  }, 100)

  const handleFocusedSurveyIndexChange = useCallback(
    (newIndex) => {
      debounceFocusedSurveyIndexChange(newIndex)
    },
    [debounceFocusedSurveyIndexChange],
  )

  useEffect(() => {
    handleFocusedSurveyIndexChange(focusedSurveyIndexRef.current)
  }, [focusedSurveyIndexRef, handleFocusedSurveyIndexChange])

  return (
    <div
      id='threeDeeChartContainer'
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Canvas
        style={{ width: '100%', height: '100%', background: getBackColor() }}
        flat
        camera={{ position: [-INITIAL_CAM_OFFSET, INITIAL_CAM_OFFSET / 2, 5], fov: 24, near: 0.1, far: 5000 }}
        onCreated={({ camera }) => {
          camera.lookAt(0, 0, 0)
        }}>
        <ChartControls
          chartData={chartData}
          focusedSurveyIndex={focusedSurveyIndexRef}
          camSpeed={CAM_SPEED}
          setFocusedSurveyIndex={handleFocusedSurveyIndexChange}
          changeOffsetWellIndex={changeOffsetWellIndex}
          cameraTarget={cameraTargetRef}
        />
        <OrientationCompass cameraTarget={cameraTargetRef} />
        <ambientLight color={'#FFFFFF'} intensity={1} />
        <pointLight color={0xffffbb} intensity={5} distance={100} position={[-1.5, 2.5, 0]} />
        <pointLight color={0xffffbb} intensity={5} distance={100} position={[1.5, 2.5, 0]} />
        <GridLines
          refData={chartData.refData}
          offsetData={chartData.offsetData}
          scale={SCALE}
          labelColor={theme === 'dark' ? 0x909090 : 0x000000}
          gridColor={theme === 'dark' ? 0x909090 : 0x000000}
        />
        <Wellbore wellData={chartData.refData[0]} />
        <WellSpheres wellData={chartData.refData[0]} display={displaySettings.showSpheres} />
        <ErrorEllipse wellData={chartData.refData[0]} display={displaySettings.showErrorEllipses} scale={SCALE} />
        <Rig wellData={chartData.refData[0]} isOffshore={false} display={displaySettings.showRig} scale={SCALE} />
        <OffsetWellbores offsetData={chartData.offsetData} display={displaySettings.showOffsets} />
        <OffsetWellboreSpheres
          offsetData={chartData.offsetData}
          offsetsOn={displaySettings.showOffsets}
          display={displaySettings.showSpheres}
        />
        <OffsetErrorEllipses
          offsetData={chartData.offsetData}
          offsetsOn={displaySettings.showOffsets}
          display={displaySettings.showErrorEllipses}
          scale={SCALE}
        />
        <OffsetWellheads
          offsetData={chartData.offsetData}
          offsetsOn={displaySettings.showOffsets}
          display={displaySettings.showWellHeads}
          scale={SCALE}
        />
        <WorkSight
          radius={displaySettings.workSightRadius}
          chartData={chartData}
          offsetsOn={displaySettings.showOffsets}
          scale={SCALE}
          focusedSurveyIndex={focusedSurveyIndexRef}
          closestApproachSvy={closestApproachRef}
        />
        <WellNames
          refData={chartData.refData}
          offsetData={chartData.offsetData}
          display={displaySettings.showWellLabels}
          offsetsOn={displaySettings.showOffsets}
          labelColor={LABEL_COLOR}
        />
        <SurveyLabels labelColor={LABEL_COLOR} refData={chartData.refData} display={displaySettings.surveyLabels} />
        <Annotations
          labelColor={LABEL_COLOR}
          annotations={chartData.annotations}
          display={displaySettings.annotations}
        />
        <Casing labelColor={LABEL_COLOR} casing={chartData.casing} display={displaySettings.casing} />
        <Formations
          labelColor={LABEL_COLOR}
          formations={chartData.formations}
          display={displaySettings.formations}
          refData={chartData.refData}
          offsetData={chartData.offsetData}
        />
        <Targets labelColor={LABEL_COLOR} targets={chartData.targets} display={displaySettings.targets} />
        <DrillersTargets
          labelColor={LABEL_COLOR}
          targets={chartData.targets}
          display={displaySettings.drillersTarget}
        />
        <GeoSteering
          geosteering={refGeosteering?.current ? refGeosteering.current : []}
          display={displaySettings?.showGeoSteering}
          scale={SCALE}
        />
      </Canvas>
      <FocusedSurveyInfo
        well={wellName}
        focusedSurveyStation={getFocusedSurveyStation(focusedSurveyIndex)}
        showControls={showControls}
        selectedOffset={selectedOffset}
        closestApproach={closestApproachRef}
      />
      {showControls ? (
        <Box sx={{ position: 'absolute', display: 'flex', bottom: '5px', left: '10px' }}>
          <ChartOptions displaySettings={displaySettings} setDisplaySettings={setDisplaySettings} />
        </Box>
      ) : null}
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      {!isLoading ? (
        <ThreeDeeViewPrefs
          showStatusPrefs={showControls}
          displaySettings={displaySettings}
          setDisplaySettings={setDisplaySettings}
        />
      ) : null}
    </div>
  )
}

export default ThreeDeeView
