import React, { useRef, useState, useMemo, useCallback } from 'react'
import { Box, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import Alert from '@mui/material/Alert'
import cloneDeep from 'lodash/cloneDeep'
import useGeosteering from 'components/common/hooks/useGeosteering'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const GeosteeringPage = ({ wellName, shouldUpdate = null }) => {
  const { isLoading, addGeoSteering, updateGeoSteering, deleteGeoSteering, fetchGeoSteeringData } =
    useGeosteering(wellName)

  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { getUnitsText } = useUnits()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const fetchGridData = async () => {
    if (!gridApi.current) return

    let res = await fetchGeoSteeringData()

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    gridApi.current.setGridOption('rowData', Array.isArray(res?.data) ? res?.data : [])
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchGridData()
  }

  const dataVerification = (event) => {
    if (!event) return
    if (!event.data) return

    if (event.colDef.colId === 'thicknessTop' || event.colDef.colId === 'tvd') {
      event.data.topTvd = event.data.tvd - event.data.thicknessTop
    }

    if (event.colDef.colId === 'thicknessBottom' || event.colDef.colId === 'tvd') {
      event.data.bottomTvd = event.data.tvd + event.data.thicknessBottom
    }

    if (event.colDef.colId === 'topTvd' || event.colDef.colId === 'tvd') {
      event.data.thicknessTop = event.data.tvd - event.data.topTvd
    }

    if (event.colDef.colId === 'bottomTvd' || event.colDef.colId === 'tvd') {
      event.data.thicknessBottom = event.data.bottomTvd - event.data.tvd
    }

    return event.data
  }

  function isValidNumber(input) {
    if (typeof input !== 'string' && typeof input !== 'number') return false
    if (typeof input === 'string' && input === '') return false
    if (typeof input === 'string') input = Number(input)
    return typeof input === 'number' && !isNaN(input)
  }

  const handleUpdate = useCallback(
    async (data, action) => {
      if (!Array.isArray(data)) return
      if (data.length === 0) return

      let rowsToAdd = []
      let rowsToUpdate = []
      let rowsToDelete = []

      for (let i = 0; i < data.length; i++) {
        data[i].actualWell = wellName
      }

      if (action === 'add') {
        data.forEach((item) => {
          if (!item.hasOwnProperty('thicknessTop')) item.thicknessTop = 0
          if (!item.hasOwnProperty('thicknessBottom')) item.thicknessBottom = 0
          item.topTvd = item.tvd - item.thicknessTop
          item.bottomTvd = item.tvd + item.thicknessBottom
        })
      }

      let res = null
      if (action === 'add') res = await addGeoSteering(data)
      if (action === 'update') res = await updateGeoSteering(data)
      if (action === 'delete') res = await deleteGeoSteering(data)

      if (res.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      rowsToAdd = action === 'add' ? res.data : []
      rowsToUpdate = action === 'update' ? res.data : []
      rowsToDelete = action === 'delete' ? res.data : []

      if (rowsToAdd.length === 0 && rowsToUpdate.length === 0 && rowsToDelete.length === 0) return
      if (!gridApi.current) return

      gridApi.current.applyTransaction({
        add: rowsToAdd,
        update: rowsToUpdate,
        remove: rowsToDelete,
      })

      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])

      if (shouldUpdate) {
        shouldUpdate()
      }
    },
    [addGeoSteering, updateGeoSteering, deleteGeoSteering, wellName, shouldUpdate],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        if (!isPinnedRowDataCompleted()) return
        handleUpdate([event.data], 'add')
        return
      }
      handleUpdate([dataVerification(event)], 'update')
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push({ ...data, rowNum: i, column: 'NONE' })
    }

    updateGeoSteering(itemsToUpdate)
  }

  let reqFields = useMemo(() => ['md', 'tvd', 'dip'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    if (!inputRow.current.hasOwnProperty('md')) return false
    if (!inputRow.current.hasOwnProperty('tvd')) return false
    if (!inputRow.current.hasOwnProperty('dip')) return false
    if (!inputRow.current.hasOwnProperty('thicknessTop')) return false
    if (!inputRow.current.hasOwnProperty('thicknessBottom')) return false

    return true
  }, [])

  const isPinnedRowDataRequired = useCallback(() => {
    if (!inputRow.current.hasOwnProperty('md')) return false
    if (!inputRow.current.hasOwnProperty('tvd')) return false
    if (!inputRow.current.hasOwnProperty('dip')) return false

    return true
  }, [])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('casingGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('casingGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Casing.xlsx',
            sheetName: 'Casing',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Delete selection',
        disabled: false,
        action: () => {
          onClickDelete()
        },
        icon: '<span class="iconify" data-icon="fa-regular:trash-alt" data-width="20" style="color:#FF0000"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Refresh Data',
        disabled: false,
        action: () => {
          fetchGridData()
        },
        icon: '<span class="iconify" data-icon="ic:twotone-refresh" data-width="20" style="color:#00FF00"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => {
              if (params.node?.rowPinned !== 'bottom') {
                handleUpdate([params.node?.data], 'delete')
              }
              if (params.node?.rowPinned === 'bottom' && isPinnedRowDataRequired()) {
                handleUpdate([params.node?.data], 'add')
              }
            }}
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleUpdate, isPinnedRowDataRequired],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'md',
        colId: 'md',
        headerName: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Md...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'topTvd',
        colId: 'topTvd',
        headerName: `Top TVD (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        editable: false,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return ''
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'tvd',
        colId: 'tvd',
        headerName: `TVD (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Target TVD...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'bottomTvd',
        colId: 'bottomTvd',
        editable: false,
        headerName: `Bottom TVD (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return ''
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'thicknessTop',
        colId: 'thicknessTop',
        headerName: `Thickness Top (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Thickness Top...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'thicknessBottom',
        colId: 'thicknessBottom',
        headerName: `Thickness Bottom (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Thickness Bottom...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'dip',
        colId: 'dip',
        headerName: `Dip Angle (°)`,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Dip Angle...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'vs',
        colId: 'vs',
        headerName: `VS (${getUnitsText(UNITS_FOR.Depth)})`,
        cellStyle: centerAlignCell,
        editable: false,
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return ''
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -10000,
          max: 100000000,
          precision: 2,
        },
      },
    ],
    [centerAlignCell, actionIconRenderer, getUnitsText],
  )

  const onClickDelete = useCallback(
    (data) => {
      setConfirm({
        show: true,
        title: 'Delete selection',
        text: `Are you sure you want to delete selected rows?`,
        onConfirm: handleUpdate([data], 'delete'),
      })
    },
    [handleUpdate],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }
    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let geosteeringData = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          geosteeringData.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!isValidNumber(geosteeringData.data.md)) continue
        if (!isValidNumber(geosteeringData.data.tvd)) continue
        if (!isValidNumber(geosteeringData.data.dip)) continue
        if (!isValidNumber(geosteeringData.data.thicknessTop)) {
          geosteeringData.data.thicknessTop = 0.0
        }
        if (!isValidNumber(geosteeringData.data.thicknessBottom)) {
          geosteeringData.data.thicknessBottom = 0.0
        }
        if (!isValidNumber(geosteeringData.data.vs)) {
          geosteeringData.data.vs = 0.0
        }
        if (!isValidNumber(geosteeringData.data.topTvd)) {
          geosteeringData.data.topTvd = 0.0
        }
        if (!isValidNumber(geosteeringData.data.bottomTvd)) {
          geosteeringData.data.bottomTvd = 0.0
        }

        geosteeringData.data.md = Number(geosteeringData.data.md)
        geosteeringData.data.tvd = Number(geosteeringData.data.tvd)
        geosteeringData.data.dip = Number(geosteeringData.data.dip)
        geosteeringData.data.thicknessTop = Number(geosteeringData.data.thicknessTop)
        geosteeringData.data.thicknessBottom = Number(geosteeringData.data.thicknessBottom)
        geosteeringData.data.vs = Number(geosteeringData.data.vs)
        geosteeringData.data.topTvd = Number(geosteeringData.data.topTvd)
        geosteeringData.data.bottomTvd = Number(geosteeringData.data.bottomTvd)

        rowsToUpdate.push(cloneDeep(geosteeringData.data))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newGeosteering = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newGeosteering[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!newGeosteering.md) continue
        if (!newGeosteering.tvd) continue
        if (!newGeosteering.dip) continue

        if (!isValidNumber(newGeosteering.md)) continue
        if (!isValidNumber(newGeosteering.tvd)) continue
        if (!isValidNumber(newGeosteering.dip)) continue
        if (!isValidNumber(newGeosteering.thicknessTop)) {
          newGeosteering.thicknessTop = 0.0
        }
        if (!isValidNumber(newGeosteering.thicknessBottom)) {
          newGeosteering.thicknessBottom = 0.0
        }

        newGeosteering.md = Number(newGeosteering.md)
        newGeosteering.tvd = Number(newGeosteering.tvd)
        newGeosteering.dip = Number(newGeosteering.dip)
        newGeosteering.thicknessTop = Number(newGeosteering.thicknessTop)
        newGeosteering.thicknessBottom = Number(newGeosteering.thicknessBottom)

        rowsToAdd.push(newGeosteering)
      }
    }

    if (rowsToUpdate.length !== 0) handleUpdate(rowsToUpdate, 'update')
    if (rowsToAdd.length !== 0) handleUpdate(rowsToAdd, 'add')

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: `100%`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'geosteeringGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            getRowId={getRowId}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={processDataFromClipboard}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default GeosteeringPage
