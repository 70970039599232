import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const FacilityGrid = ({ facilityData, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [facilityData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => params?.data.editable,
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'bool') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: 0,
                max: 10000000,
                precision: 3,
              },
            }
          }

          if (params.data?.type === 'longString') {
            return {
              component: 'agLargeTextCellEditor',
              popup: true,
              params: {
                maxLength: 2000,
                rows: 10,
                cols: 50,
              },
            }
          }

          return null
        },
        valueGetter: (params) => {
          if (params?.data?.type === 'text' || params?.data?.type === 'longString') {
            return unescapeHtml(params?.data?.value)
          }

          if (params?.data?.type === 'number') {
            return parseFloat(params?.data?.value)
          }

          return params?.data?.value
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, 3)}`
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getFacilityData = (data) => {
    let facility = [
      { label: `Facility Name`, value: '', id: -1, type: 'text', tag: `facility`, editable: true },
      {
        label: `Mapping Grid`,
        value: '',
        id: -1,
        type: 'text',
        tag: `crs`,
        editable: false,
      },
      {
        label: `Azimuth North Reference`,
        value: 'Grid',
        id: -1,
        type: 'text',
        tag: `northRef`,
        editable: false,
      },
      {
        label: `Local Coordinate System`,
        value: 'Well',
        id: -1,
        type: 'text',
        tag: `localCoordRef`,
        editable: false,
      },
      { label: `Apply Scale Factor`, value: false, id: -1, type: 'bool', tag: `applySf`, editable: false },
      { label: `Horizontal Uncertanty`, value: 0, id: -1, type: 'number', tag: `horzUncert`, editable: true },
      { label: `Vertical Uncertanty`, value: 0, id: -1, type: 'number', tag: `vertUncert`, editable: true },
      { label: `Slot Radius`, value: 0, id: -1, type: 'number', tag: `slotRadius`, editable: true },
      { label: `Rig Directions`, value: '', id: -1, type: 'longString', tag: `rigDirections`, editable: true },
    ]

    for (let i = 0; i < facility.length; i++) {
      facility[i].id = i
      if (facility[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(facility[i].tag)) continue
      if (!data[facility[i].tag]) continue
      facility[i].value = data[facility[i].tag]
    }

    return facility
  }

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getFacilityData(facilityData)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default FacilityGrid
