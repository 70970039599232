import React, { useEffect, useRef, useState } from 'react'

import { ListItem } from '@mui/material'
import { Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { FixedSizeList } from 'react-window'
import SearchBar from 'components/common/SearchBar'
import { searchFunction } from './searchFunctions'
import AutoSizer from 'react-virtualized-auto-sizer'
import { filter as _filter } from 'lodash'
import { Skeleton, Box } from '@mui/material'
import { appColors } from 'utils'
import * as yup from 'yup'
import InputModal from 'components/common/InputModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PickValuesVirtualDialog = (props) => {
  const { title, items, open, setOpen, onApply, initSelItems, singleItemSelect, showSearch } = props
  const _isMounted = useRef(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [showDataValueModal, setShowDataValueModal] = useState({ show: false, data: {} })
  const { searchBarStyle, theme, getLinearGradient } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    handleInitSelItems()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText, items]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleInitSelItems = () => {
    if (Array.isArray(initSelItems) && Array.isArray(items)) {
      let initSelList = []
      initSelItems?.forEach((initSelItem) => {
        let foundItem = items?.find((item) => item.value === initSelItem.valueIndex)
        if (foundItem) {
          initSelList.push(foundItem)
        }
      })
      setSelectedItems(initSelList)
    }
  }

  const onItemSelect = (event, item) => {
    let newSelectedItems = [...selectedItems]

    let singleSelect = singleItemSelect
    if (singleItemSelect === null || singleItemSelect === undefined) singleSelect = false

    if (getChecked(item.value) === false) {
      newSelectedItems.push(item)
      if (_isMounted.current === true && singleSelect === false) setSelectedItems(newSelectedItems)
      if (_isMounted.current === true && singleSelect === true) setSelectedItems([item.uid])
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.map((i) => i.value).indexOf(item.value)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }
      if (_isMounted.current === true && !singleSelect === true) setSelectedItems(newSelectedItems)
      if (_isMounted.current === true && singleSelect === true) setSelectedItems([item.uid])
    }
  }

  const getChecked = (value) => {
    if (!selectedItems) return false
    if (Array.isArray(selectedItems) === false) return false
    if (selectedItems.map((i) => i.value).includes(value)) return true

    return false
  }

  const handleSearch = (text) => {
    if (text && typeof text === 'string' && text !== '') {
      const filterList = _filter(items, (item) => {
        return searchFunction(item, text, ['label'])
      })
      return filterList
    } else {
      let newArr = [...items]

      newArr.forEach((i) => {
        if (initSelItems?.length > 0) {
          let idx = initSelItems.findIndex((el) => el.valueIndex === i.value)
          if (idx > -1) {
            i.dataValue = initSelItems[idx].dataValue
          } else {
            i.dataValue = 0
          }
        } else {
          i.dataValue = 0
        }
      })
      return newArr
    }
  }

  const renderVirtualRow = (props) => {
    const { index, style } = props
    const item = filteredList[index]

    return (
      <ListItem style={style} disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '4px',
            borderRadius: '5px',
            marginLeft: '8px',
            marginRight: '8px',
            background: getLinearGradient(),
            marginBottom: '1px',
            width: 'calc(100% - 20px)', // 8px for marginLeft, 2px for left & right border
            border: `1px solid`,
            borderColor: appColors.itemBackColor,
            backgroundColor: theme === ' dark' ? appColors.itemBackColor : '#FFF',
            '&:hover': {
              borderColor: appColors.accentColor,
            },
          }}>
          <Box
            sx={{
              fontSize: 18,
              color: appColors.headerTextColor,
              border: '',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              flex: 3,
            }}>
            {item.label}:
          </Box>
          <Button
            variant='outlined'
            sx={{ maxHeight: '20px', flex: 2 }}
            onClick={() => setShowDataValueModal({ show: true, data: item })}>
            <Box
              sx={{
                fontSize: 18,
                color: appColors.itemTextColor,
                border: '',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                flex: 1,
              }}>
              ${item.dataValue}
            </Box>
          </Button>

          <Box sx={{ marginLeft: 'auto', marginRight: '10px', flex: 1, textAlign: 'end' }}>
            <Checkbox
              edge='end'
              onChange={(event) => onItemSelect(event, item)}
              checked={getChecked(item.value)}
              inputProps={{ 'aria-labelledby': `${index}` }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
              indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </Box>
        </Box>
      </ListItem>
    )
  }

  const DataValueModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data?.dataValue) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('value') === false) useInputData = false
    }
    const formValidationSchema = yup.object({
      dataValue: yup
        .string()
        .matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Value is required'),
    })

    let formData = [
      {
        tag: 'uid',
        value: data?.value,
        text: 'UID',
        inputType: '-',
      },
      {
        tag: 'dataValue',
        value: data?.dataValue,
        text: 'Value',
        inputType: 'text',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' value for: ' + data.label
    return (
      <InputModal
        open={showDataValueModal.show}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const submitFunction = (data) => {
    setShowDataValueModal({ show: false, data: data })
    let idx = filteredList.map((e) => e.value).indexOf(data.uid)
    let newList = [...filteredList]
    newList[idx].dataValue = Number(data.dataValue)
    setFilteredList(newList)
  }

  const handleCloseAmountModal = () => {
    setShowDataValueModal({ show: false, data: {} })
  }

  const handleClose = () => {
    if (initSelItems?.length > 0) {
      initSelItems.forEach((obj) => {
        let foundItem = filteredList?.find((item) => item.value === obj.valueIndex)
        if (foundItem && foundItem.hasOwnProperty('dataValue')) {
          if (foundItem.dataValue !== obj.value) {
            foundItem.dataValue = obj.value
          }
        }
      })
    }
    setFilteredList(items)
    setOpen(false)
  }

  return (
    <>
      {showDataValueModal.show ? (
        <DataValueModal
          data={showDataValueModal.data?.hasOwnProperty('label') ? showDataValueModal.data : {}}
          submitFunction={submitFunction}
          cancelFunction={handleCloseAmountModal}
        />
      ) : null}
      <Dialog
        PaperProps={{
          sx: {
            width: '600px',
            backgroundColor: 'itemBackground',
          },
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent style={{ paddingTop: '0px' }}>
          {showSearch === true ? (
            <Box sx={{ padding: '8px', marginBottom: '5px' }}>
              <SearchBar
                value={searchText}
                onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
                onCancelSearch={() => setSearchText('')}
                style={searchBarStyle()}
              />
            </Box>
          ) : null}
          {items.length > 0 ? (
            <Box
              sx={{
                display: 'flex',
                overflowY: 'hidden',
                overflowX: 'hidden',
                height: '50vh',
                width: 'calc(100% - 4px)',
                borderRadius: '4px',
                border: '1px solid #000',
              }}>
              <AutoSizer>
                {({ height, width }) => (
                  <FixedSizeList
                    className={theme === 'dark' ? 'customScrollBarDark' : ''}
                    style={{ display: 'flex', borderRadius: '4px', overflow: 'auto' }}
                    height={height}
                    width={width}
                    itemCount={filteredList.length}
                    itemSize={50}>
                    {renderVirtualRow}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Box>
          ) : null}
          {items.length === 0 ? (
            <Skeleton variant='rectangular' width={'100%'} height={'50vh'} animation='wave' />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => handleClose()} color='secondary'>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              setOpen(false)
              onApply(selectedItems)
            }}
            color='primary'>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PickValuesVirtualDialog
