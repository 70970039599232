import { Document, Page, View, StyleSheet, Text } from '@react-pdf/renderer'
import PdfHeader from './PdfHeader'
import PdfTable from './PdfTable'
import { Font } from '@react-pdf/renderer'
import robotoRegular from 'assets/Fonts/Roboto/Roboto-Regular.ttf'
import robotoBold from 'assets/Fonts/Roboto/Roboto-Bold.ttf'
import robotoLight from 'assets/Fonts/Roboto/Roboto-Light.ttf'
import { DEFAULT_REPORT_SETTINGS } from 'components/Admin/Organizations/ReportSettingsModal'

Font.register({
  family: 'Roboto',
  fonts: [
    { src: robotoRegular, fontWeight: 'normal' },
    { src: robotoBold, fontWeight: 'bold' },
    { src: robotoLight, fontWeight: 'light' },
  ],
})

const styles = StyleSheet.create({
  body: {
    padding: 25,
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  section: {
    margin: 2,
    padding: 2,
    flexGrow: 1,
  },
  footer: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'left',
    marginTop: 10,
  },
  pageNumber: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'center',
    marginTop: 10,
  },
  footerText: {
    fontSize: 8,
    fontFamily: 'Roboto',
    textAlign: 'right',
    marginTop: 10,
  },
})

const GetDate = () => {
  const currentDate = new Date()
  const day = `0${currentDate.getDate()}`.slice(-2)
  const month = `0${currentDate.getMonth() + 1}`.slice(-2)
  const year = currentDate.getFullYear()
  return `Printed: ${day}/${month}/${year}`
}

const GetPage = (pageNum, totalPages) => {
  return `Page: ${pageNum} of ${totalPages}`
}

const PdfDocument = ({
  data,
  multiDocument,
  pageOrientation,
  showHeader = true,
  reportSettings = DEFAULT_REPORT_SETTINGS,
  landscapeTableIndex = -1,
  footerText = null,
}) => {
  if (!data) return null
  if (multiDocument && !Array.isArray(data)) return null
  let docData = multiDocument ? data : [data]

  return (
    <Document>
      {docData.map((item, i) => {
        let tableData = showHeader ? item.slice(1) : [item[0]]

        return (
          <Page
            orientation={i === landscapeTableIndex ? 'landscape' : pageOrientation ? pageOrientation : 'portrait'}
            style={styles.body}
            fixed
            key={i}>
            <View style={styles.section}>
              {showHeader ? <PdfHeader headerData={item[0]} reportSettings={reportSettings} /> : null}
            </View>
            <View style={styles.section}>
              {tableData.map((tableItem, index) => {
                return <PdfTable data={tableItem} headerData={item[0]} key={index} reportSettings={reportSettings} />
              })}
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} fixed>
              <Text style={styles.footer}>{GetDate()}</Text>
              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => GetPage(pageNumber, totalPages)}
              />
              {footerText ? <Text style={styles.footerText}>{footerText}</Text> : null}
            </View>
          </Page>
        )
      })}
    </Document>
  )
}

export default PdfDocument
