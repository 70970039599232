import { SphereGeometry, Vector3 } from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'

const ErrorEllipse = ({ wellData, display, scale, segments = 16, interval = 1 }) => {
  if (!display) return null
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (!Array.isArray(wellData.svyData)) return null

  let ellipses = []
  wellData.data.forEach((pt, index) => {
    if (index % interval !== 0 && index !== wellData.data.length - 1) return null
    if (wellData.svyData[index].semiMajor <= 0) return null
    if (wellData.svyData[index].semiMinor <= 0) return null

    let ratio = wellData.svyData[index].semiMajor / wellData.svyData[index].semiMinor

    let angle = 0
    if (index > 0) {
      let pt2 = new Vector3(
        wellData.svyData[index - 1].ns * scale,
        wellData.svyData[index - 1].tvd * scale,
        wellData.svyData[index - 1].ew * scale,
      )

      let deltaX = pt2.x - pt.x
      let deltaY = pt2.z - pt.z

      angle = Math.atan2(deltaX, deltaY) * (180 / Math.PI)
      //Angle is not the same as azi for some reason
      //Probably because NS is equivelant to the x axis and EW is the z axis
      //Compute the angle from the position vectors

      angle *= Math.PI / 180
    }

    const ellipsoidGeometry = new SphereGeometry(wellData.svyData[index].semiMinor * scale, 32, segments)
    ellipsoidGeometry.scale(ratio, 1, 1)
    ellipsoidGeometry.rotateY(angle)
    ellipsoidGeometry.translate(pt)
    ellipses.push(ellipsoidGeometry)
  })

  if(ellipses.length === 0) return null
  const combinedGeometry = ellipses.length === 1 ? ellipses[0] : BufferGeometryUtils.mergeGeometries(ellipses)
  
  return (
    <mesh>
      <primitive object={combinedGeometry} attach='geometry' />
      <meshBasicMaterial attach='material' color={0xff0000} transparent={true} opacity={0.5} />
    </mesh>
  )
}

export default ErrorEllipse
