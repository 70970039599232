import React, {useState } from 'react'
import { Box, Slider } from '@mui/material'

const SliderCellEditor = (props) => {
  const [newValue, setNewValue] = useState(props.value || 0)

  const handleChange = (event, newVal) => {
    setNewValue(newVal)
    props.onValueChange(newVal)
  }

  return (
    props.value != null && (
      <Box
        sx={{
          height: '10px',
          width: `calc(100% - 20px)`,
          padding: '0 10px',
        }}>
        <Slider
          value={newValue}
          onChange={handleChange}
          aria-labelledby='input-slider'
          min={props.min || 0}
          max={props.max || 10}
          step={props.step || 1}
          size='small'
        />
      </Box>
    )
  )
}

export default SliderCellEditor
