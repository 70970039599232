import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useAnalyticsDropDowns from 'components/common/hooks/useAnalyticsDropDowns'
import { Icon as Iconify } from '@iconify/react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { analyticsRadiusFilterAtom, analyticsFilterDefsAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import cloneDeep from 'lodash/cloneDeep'
import { filterDefs } from './FilterDefs'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import FileSaver from 'file-saver'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AnalyticsSearchGrid = ({ setFilteredWellNames, showHideFilterPane, showFilterControls }) => {
  const _isMounted = useRef(false)
  const fileInputRef = useRef(null)
  const { getAgGridTheme, getWindowBarColor } = useInnovaTheme()
  const gridApi = useRef(null)
  const shouldUpdateRadiusFilter = useRef(false)
  const shouldFetchSearchData = useRef(false)
  const isSearching = useRef(false)
  const [recoilFilterDefs, setRecoilFilterDefs] = useRecoilState(analyticsFilterDefsAtom)

  const getInitialSearchParams = () => {
    let newSearchParams = cloneDeep(recoilFilterDefs)
    let savedParams = getItemFromLS('mapSearch', 'searchParams')
    if (!savedParams || !Array.isArray(savedParams)) return newSearchParams

    for (let i = 0; i < savedParams.length; i++) {
      let index = newSearchParams.findIndex((param) => param.text === savedParams[i].text)
      if (index < 0) continue

      newSearchParams[index].value = cloneDeep(savedParams[i].value)
      newSearchParams[index].isActive = savedParams[i].isActive
    }

    return newSearchParams
  }

  const [searchParams, setSearchParams] = useState(getInitialSearchParams())
  const searchParamsRef = useRef(getInitialSearchParams())
  const [searchInProgress, setSearchInProgress] = useState(false)
  const [dropDowns] = useAnalyticsDropDowns()
  const setRadiusFilter = useSetRecoilState(analyticsRadiusFilterAtom)
  const radiusFilter = useRecoilValue(analyticsRadiusFilterAtom)
  const { getObjectValues } = useObjectsOfInterest()

  const wellSearch = useInnovaAxios({
    url: '/kpi/wellSearch',
    timeout: 600000,
  })

  const getSelectedValues = (param) => {
    if (!param) return ''
    if (!Array.isArray(searchParamsRef.current)) return ''
    for (let i = 0; i < searchParamsRef.current.length; i++) {
      if (searchParamsRef.current[i].text !== param) continue
      if (!searchParamsRef.current[i].isActive) return ''
      if (Array.isArray(searchParamsRef.current[i].value)) {
        if (searchParamsRef.current[i].value.length === 0) return ''
        if (searchParamsRef.current[i].value.length === 1) return searchParamsRef.current[i].value[0]
        return searchParamsRef.current[i].value.join('|')
      }

      return searchParamsRef.current[i].value
    }
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const fetchWellSearch = async () => {
    if (isSearching.current) return
    if (!setFilteredWellNames) return
    if (!Array.isArray(searchParams)) return

    let filtersActive = false
    for (let i = 0; i < searchParams.length; i++) {
      if (searchParams[i].isActive) {
        filtersActive = true
        break
      }
    }

    if (!filtersActive) {
      if (_isMounted.current) setFilteredWellNames([])
      return
    }

    let data = {
      mudType: getSelectedValues('Mud Type'),
      operator: getSelectedValues('Operator'),
      state: getSelectedValues('State'),
      county: getSelectedValues('County'),
      rig: getSelectedValues('Rig'),
      wellStatus: getSelectedValues('Well Status'),
      jobNum: getSelectedValues('Job#'),
      directionalCompany: getSelectedValues('Dir Co.'),
      formation: getSelectedValues('Formation'),
      holeSize: getSelectedValues('Hole Size'),
      isLih: getSelectedValues('LIH') === 'Yes' ? true : '',
      phase: getSelectedValues('Phase'),
      district: getSelectedValues('District'),
      dateFrom: getSelectedValues('Start Date'),
      dateTo: getSelectedValues('End Date'),
      failure: getSelectedValues('Failure'),
      motorSn: getSelectedValues('Motor SN'),
      motorMake: getSelectedValues('Motor Make'),
      bitMake: getSelectedValues('Bit Make'),
      mwdType: getSelectedValues('MWD Type'),
      driveType: getSelectedValues('Drive Type'),
      motorLobes: getSelectedValues('Motor Lobes'),
      motorStages: getSelectedValues('Motor Stages'),
      serialNum: getSelectedValues('Serial #'),
      incOffsets: getSelectedValues('Inc Offsets') === 'Yes' ? true : false,
    }

    if (!isValidDate(data.dateTo)) {
      data.dateTo = ''
    }

    if (!isValidDate(data.dateFrom)) {
      data.dateFrom = ''
    }

    if (data.dateTo === data.dateFrom) {
      data.dateTo = ''
      data.dateFrom = ''
    }

    if (data.dateFrom !== '' || data.dateTo !== '') {
      if (data.dateFrom !== '' && data.dateTo === '')
        data.dateTo = new Date(Date.parse('2200-01-01T00:00:00.000Z')).toISOString()
      if (data.dateFrom === '' && data.dateTo !== '')
        data.dateFrom = new Date(Date.parse('1900-01-01T00:00:00.000Z')).toISOString()
    }

    if (_isMounted.current) setSearchInProgress(true)
    isSearching.current = true
    const response = await wellSearch(data)

    //const response = null
    isSearching.current = false
    if (!_isMounted.current) return

    setSearchInProgress(false)

    if (response?.data && Array.isArray(response.data)) {
      setFilteredWellNames(response.data.length > 0 ? response.data : ['-999.25'])
      return
    }

    setFilteredWellNames([])
  }

  useEffect(() => {
    _isMounted.current = true
    fetchWellSearch()
    return () => {
      setRecoilFilterDefs(cloneDeep(searchParamsRef.current))
      saveItemToLS('mapSearch', 'searchParams', cloneDeep(searchParamsRef.current))
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateRadiusParams()
  }, [radiusFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateRadiusParams = () => {
    if (!Array.isArray(searchParams)) return
    if (!radiusFilter) return
    if (!radiusFilter.hasOwnProperty('radius')) return

    for (let i = 0; i < searchParams.length; i++) {
      if (searchParams[i].text === 'Radius (miles)' && !isNaN(radiusFilter.radius)) {
        searchParams[i].value = [`${(radiusFilter.radius / 1000 / 1.60934).toFixed(2)}`]
      }

      if (searchParams[i].text === 'Lat' && !isNaN(radiusFilter.center.lat)) {
        searchParams[i].value = [`${radiusFilter.center.lat.toFixed(10)}`]
      }

      if (searchParams[i].text === 'Long' && !isNaN(radiusFilter.center.lat)) {
        searchParams[i].value = [`${radiusFilter.center.lng.toFixed(10)}`]
      }
    }

    shouldUpdateRadiusFilter.current = false
    shouldFetchSearchData.current = false
    if (_isMounted.current) {
      setSearchParams([...searchParams])
      searchParamsRef.current = cloneDeep(searchParams)
      setRecoilFilterDefs(cloneDeep(searchParams))
    }
  }

  const getSearchRadiusKm = () => {
    let gridVal = getSelectedValues('Radius (miles)')
    if (typeof gridVal !== 'string') return 40
    let gridValNumeric = parseFloat(gridVal)
    if (gridValNumeric === 0) return 40
    return gridValNumeric * 1.60934 //Miles to KM Conversion
  }

  const getSearchRadiusActive = () => {
    if (!Array.isArray(searchParams)) return false
    for (let i = 0; i < searchParams.length; i++) {
      if (!searchParams[i].hasOwnProperty('group')) continue
      if (searchParams[i].group !== 'radius') continue
      return searchParams[i].isActive
    }

    return false
  }

  useEffect(() => {
    if (shouldFetchSearchData.current) fetchWellSearch()
    if (shouldUpdateRadiusFilter.current) {
      shouldUpdateRadiusFilter.current = false
      if (_isMounted.current) {
        setRadiusFilter({
          isActive: getSearchRadiusActive(),
          radius: getSearchRadiusKm() * 1000, //Need to convert to meters for google maps
          center: { lat: parseFloat(getSelectedValues('Lat')), lng: parseFloat(getSelectedValues('Long')) },
        })
      }
    }
  }, [searchParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const getDropDownValues = (param) => {
    if (!dropDowns) return []
    if (!param) return []
    if (typeof param !== 'string') return []
    if (param === '') return []

    for (const property in dropDowns) {
      if (!Array.isArray(dropDowns[property])) continue
      if (param !== property) continue
      return [...dropDowns[property]]
    }

    return []
  }

  const columnDefs = [
    {
      headerName: '',
      field: 'text',
      sortable: false,
    },
    {
      headerName: 'Filter',
      field: 'value',
      sortable: false,
      flex: 1,
      cellStyle: { paddingLeft: '5px', borderRight: '1px solid gray', borderLeft: '1px solid gray' },
      valueFormatter: (params) => {
        if (params.data?.type === 'date') {
          if (!isValidDate(params.data?.value)) return ''
          let date = params.data?.value
          if (date.indexOf('T') < 0) {
            date += 'T00:01:00'
          }
          return new Date(Date.parse(date)).toLocaleDateString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        }

        if (Array.isArray(params.value)) {
          if (params.value.length === 0) return ''
          return params.value.join(', ')
        }

        return params?.value
      },
      valueParser: (params) => {
        if (params.data?.type === 'dropDown') {
          let simpleDropDown = false
          if (params.data?.hasOwnProperty('dropDownField') && params.data?.dropDownField === 'yesNo') {
            simpleDropDown = true
          }

          if (params.data?.hasOwnProperty('dropDownField') && params.data?.dropDownField === 'rentalJobs') {
            simpleDropDown = true
          }

          if (simpleDropDown) return params.value
          if (!simpleDropDown) return params.newValue.split(',')
        }

        return params.newValue
      },
      editable: (params) => {
        return params.data?.isActive
      },
      cellEditorSelector: (params) => {
        if (params.data?.type === 'dropDown') {
          let simpleDropDown = false
          if (params.data?.hasOwnProperty('dropDownField') && params.data?.dropDownField === 'yesNo') {
            simpleDropDown = true
          }

          if (params.data?.hasOwnProperty('dropDownField') && params.data?.dropDownField === 'rentalJobs') {
            simpleDropDown = true
          }

          let dropDownValues = []
          if (params.data?.hasOwnProperty('dropDownField')) {
            let simpleDropDownValues = []
            dropDownValues = getDropDownValues(params.data?.dropDownField)
            if (simpleDropDown) {
              for (let i = 0; i < dropDownValues.length; i++) {
                simpleDropDownValues.push(dropDownValues[i].label)
              }

              dropDownValues = [...simpleDropDownValues]
            }
          }

          return {
            component: !simpleDropDown ? 'agRichSelectCellEditor' : 'agSelectCellEditor',
            params: {
              values: !simpleDropDown ? dropDownValues.map((item) => item.value) : dropDownValues,
              multiSelect: !simpleDropDown,
              searchType: 'matchAny',
              filterList: !simpleDropDown,
              highlightMatch: !simpleDropDown,
            },
            popup: !simpleDropDown ? true : false,
          }
        }

        if (params.data?.type === 'date') {
          return {
            component: 'agDateStringCellEditor',
            params: {
              min: '1950-01-01',
              max: '2200-01-01',
            },
          }
        }

        if (params.data?.type === 'numeric' || params.data?.type === 'text') {
          return {
            component: 'agTextCellEditor',
            popup: false,
          }
        }

        return undefined
      },
    },
    {
      headerName: '',
      field: 'isActive',
      editable: false,
      sortable: false,
      minWidth: 60,
      maxWidth: 60,
      cellRenderer: (params) => {
        return (
          <input
            type={'checkbox'}
            checked={params.value}
            onChange={() => {
              shouldFetchSearchData.current = true
              for (let i = 0; i < searchParams.length; i++) {
                if (searchParams[i].id === params.node.data.id) {
                  searchParams[i].isActive = !params.value
                  if (searchParams[i].hasOwnProperty('group')) {
                    setGroupActive(searchParams[i].group, searchParams[i].isActive)
                    if (searchParams[i].group === 'radius') {
                      shouldUpdateRadiusFilter.current = true
                      shouldFetchSearchData.current = false
                    }
                  }

                  if (_isMounted.current) {
                    setSearchParams(cloneDeep(searchParams))
                    searchParamsRef.current = cloneDeep(searchParams)
                    setRecoilFilterDefs(cloneDeep(searchParams))
                  }
                  break
                }
              }
            }}
          />
        )
      },
      flex: 0.5,
    },
  ]

  const setGroupActive = (groupName, active) => {
    if (!Array.isArray(searchParams)) return
    if (typeof groupName !== 'string') return

    for (let i = 0; i < searchParams.length; i++) {
      if (searchParams[i].hasOwnProperty('group') && searchParams[i].group === groupName) {
        searchParams[i].isActive = active
      }
    }
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const defaultColDef = {
    suppressKeyboardEvent: (params) => {
      if (!params.editing) {
        let isBackspaceKey = params.event.keyCode === 8
        let isDeleteKey = params.event.keyCode === 46

        if (isBackspaceKey || isDeleteKey) {
          const focusedCell = params.api.getFocusedCell()
          if (!focusedCell?.column?.colDef.editable) return false
          let data = params.data
          data[focusedCell?.column?.colDef?.field] = ''
          updateSearchParams(cloneDeep(data))
        }
      }
      return false
    },
    resizable: true,
    sortable: true,
    autoHeight: true,
    editable: false,
    flex: 1,
  }

  const updateSearchParams = (gridData) => {
    if (!gridData) return
    if (!gridData.hasOwnProperty('value')) return
    if (!gridData.hasOwnProperty('id')) return

    for (let i = 0; i < searchParamsRef.current.length; i++) {
      if (searchParamsRef.current[i].id === gridData.id) {
        if (searchParamsRef.current[i].type === 'dropDown') {
          if (searchParamsRef.current[i].dropDownField !== 'yesNo') {
            searchParamsRef.current[i].value = [...gridData.value]
          }

          if (
            searchParamsRef.current[i].dropDownField === 'yesNo' ||
            searchParamsRef.current[i].dropDownField === 'rentalJobs'
          ) {
            searchParamsRef.current[i].value = [gridData.value]
          }
        }

        if (searchParamsRef.current[i].type === 'date') {
          searchParamsRef.current[i].value = gridData.value
        }

        if (searchParamsRef.current[i].type === 'numeric' || searchParamsRef.current[i].type === 'text') {
          if (
            searchParamsRef.current[i].hasOwnProperty('regEx') &&
            searchParamsRef.current[i].regEx instanceof RegExp
          ) {
            if (searchParamsRef.current[i].regEx.test(gridData.value)) {
              searchParamsRef.current[i].value = [gridData.value]
            }

            if (!searchParamsRef.current[i].regEx.test(gridData.value)) {
              searchParamsRef.current[i].value = ['0']
              if (searchParamsRef.current[i].hasOwnProperty('defValue')) {
                searchParamsRef.current[i].value = [searchParamsRef.current[i].defValue]
              }
            }
          } else {
            searchParamsRef.current[i].value = [gridData.value]
          }
        }

        shouldFetchSearchData.current = true
        if (searchParamsRef.current[i].hasOwnProperty('group') && searchParamsRef.current[i].group === 'radius') {
          shouldUpdateRadiusFilter.current = true
          shouldFetchSearchData.current = false
        }

        if (_isMounted.current) {
          setSearchParams(cloneDeep(searchParamsRef.current))
          setRecoilFilterDefs(cloneDeep(searchParamsRef.current))
        }
        break
      }
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          params.api?.setGridOption('rowData', cloneDeep(filterDefs))
          shouldFetchSearchData.current = true
          setSearchParams(cloneDeep(filterDefs))
          searchParamsRef.current = cloneDeep(filterDefs)
          setRecoilFilterDefs(cloneDeep(filterDefs))
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset layout',
        disabled: false,
        action: () => {
          let newLayout = [
            { size: '20%', visible: true },
            { size: '70%', visible: true },
            { size: '70%', visible: true },
          ]

          saveItemToLS('mapSearchPageLayout', 'pageLayout', cloneDeep(newLayout))
          window.location.reload()
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Select Following',
        disabled: false,
        action: () => {
          for (let i = 0; i < searchParams.length; i++) {
            if (searchParams[i].text === 'Rig') {
              searchParams[i].value = getObjectValues('rigs')
              if (Array.isArray(searchParams[i].value) && searchParams[i].value.length > 0) {
                searchParams[i].isActive = true
              }
            }

            if (searchParams[i].text === 'District') {
              searchParams[i].value = getObjectValues('districts')
              if (Array.isArray(searchParams[i].value) && searchParams[i].value.length > 0) {
                searchParams[i].isActive = true
              }
            }

            if (searchParams[i].text === 'Operator') {
              searchParams[i].value = getObjectValues('operators')
              if (Array.isArray(searchParams[i].value) && searchParams[i].value.length > 0) {
                searchParams[i].isActive = true
              }
            }
          }

          params.api?.setGridOption('rowData', [...searchParams])
          shouldFetchSearchData.current = true
          setSearchParams([...searchParams])
          searchParamsRef.current = cloneDeep(searchParams)
          setRecoilFilterDefs(cloneDeep(searchParams))
        },
        icon: '<span class="iconify" data-icon="ic:outline-star" data-width="20" style="color:#FFD700"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export search',
        disabled: false,
        action: () => {
          let blob = new Blob([JSON.stringify(searchParamsRef.current)], { type: 'text/plain;charset=utf-8' })
          FileSaver.saveAs(blob, `analyticsSearchTemplate.txt`)
        },
        icon: '<span class="iconify" data-icon="ph:export-bold" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Import search',
        disabled: false,
        action: () => {
          if (!fileInputRef.current) return
          fileInputRef.current.click()
        },
        icon: '<span class="iconify" data-icon="pajamas:import" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const handleImportSearchFilters = (e) => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()

    reader.onload = (event) => {
      const content = event.target.result
      const newSearchParams = JSON.parse(content)

      if (!newSearchParams) return

      gridApi.current?.setGridOption('rowData', cloneDeep(newSearchParams))
      shouldFetchSearchData.current = true
      setSearchParams(cloneDeep(newSearchParams))
      searchParamsRef.current = cloneDeep(newSearchParams)
      setRecoilFilterDefs(cloneDeep(newSearchParams))
    }

    reader.readAsText(file)
    e.target.value = null
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <input
        ref={fileInputRef}
        style={{ display: 'none' }}
        type='file'
        accept='.txt'
        onChange={(event) => {
          handleImportSearchFilters(event)
        }}
      />
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHideFilterPane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          display: showFilterControls ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 25px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={searchInProgress}
            rowData={searchParams}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            onGridReady={onGridReady}
            gridOptions={{
              popupParent: document.querySelector('body'),
              onCellEditingStopped: (event) => {
                updateSearchParams(event.data)
              },
              headerHeight: 0,
              loadingOverlayComponent: CustomLoadingOverlay,
            }}
          />
        </div>
      </Box>
      {!showFilterControls ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHideFilterPane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Filters
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default AnalyticsSearchGrid
