import React from 'react'
import MapView from 'components/common/MapView/MapView'
import DonutChartJs from 'components/common/DonutChartJs'
import { GetActivityColor } from 'components/common/activitySelector'
import { Box } from '@mui/material'
import { getWellstatusList } from 'components/common/activitySelector'

const WellsMapPage = ({ filteredWells }) => {
  const getDonutChartData = (property, allowedValues) => {
    if (!allowedValues) return []
    if (!Array.isArray(allowedValues)) return []

    if (!filteredWells) return []
    if (!Array.isArray(filteredWells)) return []
    if (filteredWells.length === 0) return []

    let wellCounts = []
    for (let i = 0; i < filteredWells.length; i++) {
      let wellProp = filteredWells[i]?.hasOwnProperty(property) ? filteredWells[i][property] : 'N/A'
      if (typeof wellProp !== 'string') wellProp = 'N/A'

      let index = wellCounts.findIndex((stat) => stat.tag === wellProp.toLowerCase())

      if (index >= 0) {
        wellCounts[index].count++
      }

      if (index < 0) {
        wellCounts.push({ label: wellProp, tag: wellProp.toLowerCase(), count: 1 })
      }
    }

    if (allowedValues.length === 0) {
      return wellCounts.map((stat) => {
        return {
          value: stat.count,
          label: stat.label,
        }
      })
    }

    return allowedValues.map((val) => {
      let index = wellCounts.findIndex((count) => count.tag === val.toLowerCase())

      return {
        value: index >= 0 ? wellCounts[index].count : 0,
        color: GetActivityColor(val),
        label: val,
      }
    })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ width: '85%', height: '100%' }}>
        <MapView wells={filteredWells} />
      </Box>
      <Box sx={{ width: '15%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ height: `${100 / 3}%`, width: '100%' }}>
          <DonutChartJs
            title={'Active wells'}
            data={getDonutChartData('wellStatus', ['Active', 'Standby', 'Upcoming', 'TD'])}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
        <Box sx={{ height: `${100 / 3}%`, width: '100%' }}>
          <DonutChartJs
            title={'All wells'}
            data={getDonutChartData('wellStatus', getWellstatusList())}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
        <Box sx={{ height: `${100 / 3}%`, width: '100%' }}>
          <DonutChartJs
            title={'Operators'}
            data={getDonutChartData('operator', [])}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default WellsMapPage
