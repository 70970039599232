import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ShippingTicketCommentsGrid = ({ ticketData, updateTicket }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        colId: 'comments',
        field: 'comments',
        headerName: 'Comments',
        editable: true,
        minWidth: 400,
        valueGetter: (params) => unescapeHtml(params.data?.comments),
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
      },
    ],
    [],
  )

  const handleUpdate = async (data) => {
    let res = await updateTicket(data)
    if (res?.error) return

    gridApi.current.applyTransaction({
      update: [data],
    })

    if (gridApi.current) gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      wrapText: true,
      resizable: false,
      sortable: false,
      autoHeight: true,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current?.autoSizeAllColumns()
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={ticketData ? [ticketData] : []}
        columnDefs={columnDefs}
        defaultColDefs={defaultColDef}
        getRowId={getRowId}
        gridOptions={gridOptions}
        rowSelection={'single'}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default ShippingTicketCommentsGrid
