import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAuth from './useInnovaAuth'
import { useRecoilState } from 'recoil'
import { objInterestSelector } from 'atoms'

var _isObjInterestInitialized = false

const useObjectsOfInterest = () => {
  const _isMounted = useRef(false)
  const [objectsOfInterest, setObjectsOfInterest] = useRecoilState(objInterestSelector)
  const [dropDowns, setDropDowns] = useState(null)
  const { user, isAuthenticated } = useInnovaAuth()

  useEffect(() => {
    if (_isMounted.current) {
      if (isAuthenticated && user?.name?.length > 1) {
        getObjectsOfInterest()
      }
    }
  }, [user?.name, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    if (!_isObjInterestInitialized) {
      _isObjInterestInitialized = true
      getObjectsOfInterest()
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDowns = useInnovaAxios({
    url: '/user/getObjectsOfInterestDropDowns',
  })

  const fetchObjectsOfInterest = useInnovaAxios({
    url: '/user/getObjectsOfInterest',
  })

  const updateObjectsOfInterest = useInnovaAxios({
    url: '/user/setObjectsOfInterest',
  })

  const getDropDowns = async () => {
    const response = await fetchDropDowns()
    if (response?.error) {
      return
    }

    if (_isMounted.current && response?.data) {
      let newDropDowns = {}

      for (const property in response?.data) {
        newDropDowns[property] = []
        if (Array.isArray(response?.data[property])) {
          response?.data[property].forEach((val) => {
            if (typeof val === 'object') {
              newDropDowns[property].push(val)
            } else {
              newDropDowns[property].push({ label: unescapeHtml(val), value: unescapeHtml(val) })
            }
          })
        }
      }

      setDropDowns(newDropDowns)
    }
  }

  const getObjectsOfInterest = async () => {
    const response = await fetchObjectsOfInterest()
    if (response?.error) {
      _isObjInterestInitialized = false
      return
    }
    if (_isMounted.current && response?.data) {
      setObjectsOfInterest(response?.data)
    }
  }

  const refreshObjectsOfInterest = async () => {
    await getObjectsOfInterest()
  }

  const updateUserObjectsOfInterest = async (newObjects) => {
    if (!newObjects) return
    if (!_isMounted.current) return
    if (!newObjects.hasOwnProperty('rigs')) return
    if (!newObjects.hasOwnProperty('districts')) return
    if (!newObjects.hasOwnProperty('operators')) return
    if (!Array.isArray(newObjects.rigs)) return
    if (!Array.isArray(newObjects.districts)) return
    if (!Array.isArray(newObjects.operators)) return

    let payload = {
      rigs: newObjects.rigs.join('|'),
      operators: newObjects.operators.join('|'),
      districts: newObjects.districts.join('|'),
    }

    const response = await updateObjectsOfInterest(payload)
    if (response?.error) {
      return response
    }

    if (_isMounted.current) {
      setObjectsOfInterest(newObjects)
    }

    return response
  }

  const hasObjectsOfInterest = () => {
    if (!objectsOfInterest) return false
    if (
      objectsOfInterest.hasOwnProperty('districts') &&
      Array.isArray(objectsOfInterest.districts) &&
      objectsOfInterest.districts.length > 0
    ) {
      return true
    }

    if (
      objectsOfInterest.hasOwnProperty('rigs') &&
      Array.isArray(objectsOfInterest.rigs) &&
      objectsOfInterest.rigs.length > 0
    ) {
      return true
    }

    if (
      objectsOfInterest.hasOwnProperty('operators') &&
      Array.isArray(objectsOfInterest.operators) &&
      objectsOfInterest.operators.length > 0
    ) {
      return true
    }

    return false
  }

  const getObjectValues = (param) => {
    if (!param) return []
    if (typeof param !== 'string') return []
    if (param === '') return []
    if (!objectsOfInterest) return []
    if (!objectsOfInterest.hasOwnProperty(param)) return []
    if (!Array.isArray(objectsOfInterest[param])) return []
    return objectsOfInterest[param]
  }

  const isObjectOfInterest = (rig, operator, district) => {
    if (!hasObjectsOfInterest()) return false
    if (typeof operator !== 'string') operator = ''
    if (typeof rig !== 'string') rig = ''
    if (typeof district !== 'string') district = ''
    if (operator === '' && rig === '' && district === '') return false

    let operators = getObjectValues('operators')
    if (operators.findIndex((op) => op === operator) >= 0) return true

    let rigs = getObjectValues('rigs')
    if (rigs.findIndex((r) => r === rig) >= 0) return true

    let districts = getObjectValues('districts')
    if (districts.findIndex((d) => d === districts) >= 0) return true
    return false
  }

  return {
    dropDowns,
    objectsOfInterest,
    getDropDowns,
    updateUserObjectsOfInterest,
    refreshObjectsOfInterest,
    hasObjectsOfInterest,
    getObjectValues,
    isObjectOfInterest,
  }
}

export default useObjectsOfInterest
