import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Box, IconButton, Snackbar, Checkbox } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { Icon as Iconify } from '@iconify/react'
import { cloneDeep } from 'lodash'
import { uuidv4 } from 'utils/stringFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const TargetImportModal = ({ handleImport, onCancel }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const [useLocalCoords, setUseLocalCoords] = useState(false)
  const [useTvdSs, setUseTvdSs] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleDelete = useCallback((data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return

    gridApi.current.applyTransaction({
      remove: [data],
    })
  }, [])

  const getExistingTargetNames = useCallback(() => {
    const existingTargetNames = []
    gridApi.current.forEachNode((node) => {
      if (node.data?.hasOwnProperty('targetName')) {
        existingTargetNames.push(node.data.targetName)
      }
    })
    return existingTargetNames
  }, [])

  const isPinnedRowDataCompleted = useCallback(() => {
    if (!inputRow.current.hasOwnProperty('targetName')) return false
    if (inputRow.current.targetName === '') return false
    if (!inputRow.current.hasOwnProperty('tvd')) return false

    const existingTargetNames = getExistingTargetNames()
    if (existingTargetNames.includes(inputRow.current.targetName)) return false

    if (inputRow.current.hasOwnProperty('ns') && inputRow.current.hasOwnProperty('ew')) return true
    return false
  }, [getExistingTargetNames])

  const handleAddRow = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return

    inputRow.current.uid = uuidv4()
    gridApi.current.applyTransaction({
      add: [inputRow.current],
    })

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [isPinnedRowDataCompleted])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
            size='large'>
            {params.data?.name !== 'Default' ? (
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            ) : (
              <Box sx={{ width: '15px', height: '15px' }} />
            )}
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'targetName',
        colId: 'targetName',
        headerName: 'Name',
        editable: true,
        valueSetter: (params) => {
          if (params.newValue === '') return false
          if (params.newValue === params.oldValue) return false

          const existingNames = getExistingTargetNames()
          if (existingNames.includes(params.newValue)) return false

          params.data.targetName = params.newValue
          return true
        },
      },
      {
        field: 'tvd',
        colId: 'tvd',
        headerName: useTvdSs ? 'TVDSS' : 'TVD',
        editable: true,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Tvd...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'ns',
        colId: 'ns',
        headerName: useLocalCoords ? ' Local NS' : 'Grid NS',
        editable: true,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Ns...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'ew',
        colId: 'ew',
        headerName: useLocalCoords ? ' Local EW' : 'Grid EW',
        editable: true,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Ew...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
        cellStyle: centerAlignCell,
      },
    ],
    [getExistingTargetNames, actionIconRenderer, isEmptyPinnedCell, useLocalCoords, centerAlignCell, useTvdSs],
  )

  const onGridReady = (params) => {
    gridApi.current = params.api

    params.api.setGridOption('rowData', [])
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  function createPinnedCellPlaceholder({ colDef }) {
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }

      if (event.node?.rowPinned !== 'bottom') {
        autoSizeColumns()
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
  }

  function isValidNumber(input) {
    if (typeof input !== 'string' && typeof input !== 'number') return false
    if (typeof input === 'string' && input === '') return false
    if (typeof input === 'string') input = Number(input)
    return typeof input === 'number' && !isNaN(input)
  }

  const processDataFromClipboard = useCallback(
    (params) => {
      const data = cloneDeep(params.data)
      const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1

      if (emptyLastRow) {
        data.splice(data.length - 1, 1)
      }

      let existingTargetNames = getExistingTargetNames()

      const rowsToAdd = []
      const lastIndex = gridApi.current.getDisplayedRowCount() - 1
      const focusedCell = gridApi.current.getFocusedCell()
      let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
      if (startPasteIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = startPasteIndex + (data.length - 1)
        const numRowsToAdd = resultLastIndex - lastIndex

        let index = data.length - 1
        for (let i = 0; i < numRowsToAdd; i++) {
          const row = data.slice(index, index + 1)[0]
          index--

          if (!Array.isArray(row)) continue
          const newTgt = {}
          let currentColumn = focusedCell.column
          if (!currentColumn) continue

          for (let j = 0; j < row.length; j++) {
            newTgt[currentColumn.colDef.field] = row[j]
            currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
          }

          if (!isValidNumber(newTgt.tvd) && !isValidNumber(newTgt.ns) && !isValidNumber(newTgt.ew)) {
            continue
          }

          if (!newTgt.targetName) continue
          if (typeof newTgt.targetName !== 'string') continue
          if (newTgt.targetName === '') continue

          newTgt.uid = uuidv4()
          newTgt.tvd = Number(newTgt.tvd)
          newTgt.ns = Number(newTgt.ns)
          newTgt.ew = Number(newTgt.ew)

          if (existingTargetNames.includes(newTgt.targetName)) continue
          if (rowsToAdd.findIndex((tgt) => tgt.targetName === newTgt.targetName) >= 0) continue
          rowsToAdd.push(newTgt)
        }

        gridApi.current.applyTransaction({ add: rowsToAdd.reverse() })
      }

      if (focusedCell?.rowPinned === 'bottom') {
        inputRow.current = {}
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }

      return data
    },
    [getExistingTargetNames],
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  function onPasteEnd(params) {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleImportFunc = () => {
    if (!gridApi.current) return []
    let targets = []
    gridApi.current.forEachNode((node) => {
      if (node.data?.hasOwnProperty('targetName')) {
        targets.push({
          targetName: node.data.targetName,
          targetCenter: {
            tvd: node.data.tvd,
            localNorth: node.data.ns,
            localEast: node.data.ew,
            gridNorth: node.data.ns,
            gridEast: node.data.ew,
          },
        })
      }
    })

    handleImport(targets, useLocalCoords, useTvdSs)
  }

  const getContextMenuItems = (params) => {
    return ['copy', 'copyWithHeaders', 'excelExport']
  }

  return (
    <Dialog
      maxWidth='xl'
      open={true}
      onClose={onCancel}
      PaperProps={{
        sx: {
          width: '60vw',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Targets Import</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          style={{
            maxWidth: '100%',
            height: '10%',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: getWindowBarColor(),
            marginBottom: '5px',
            border: '1px solid #808080',
            alignItems: 'center',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100px' }}>
            TVD SS
            <Checkbox
              edge='end'
              onChange={(event) => {
                setUseTvdSs(!useTvdSs)
              }}
              checked={useTvdSs}
              inputProps={{ 'aria-labelledby': 0 }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'green' }} />}
              indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '150px' }}>
            Local Coords
            <Checkbox
              edge='end'
              onChange={(event) => {
                setUseLocalCoords(!useLocalCoords)
              }}
              checked={useLocalCoords}
              inputProps={{ 'aria-labelledby': 0 }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'green' }} />}
              indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </Box>
        </Box>
        <Box className={getAgGridTheme()} style={{ maxWidth: '100%', height: 'calc(90% - 6px)' }}>
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            gridOptions={gridOptions}
            headerHeight={30}
            processDataFromClipboard={processDataFromClipboard}
            getRowId={getRowId}
            onPasteEnd={onPasteEnd}
            getContextMenuItems={getContextMenuItems}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ margin: '5px' }}
          variant='contained'
          type='button'
          color='primary'
          onClick={() => handleImportFunc()}>
          Import Targets
        </Button>
        <Button sx={{ margin: '5px' }} variant='contained' type='button' color='primary' onClick={() => onCancel()}>
          Cancel
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default TargetImportModal
