import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ConnectionStatusGrid = ({ connectionData, numConnections, setNumConnections }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const dataRef = useRef(connectionData)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    dataRef.current = connectionData

    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', [
        { id: 0, numConnections: `Last ${numConnections} connections`, date: '', holeSize: '' },
      ])

      setTimeout(() => {
        autoSizeColumns()
      }, 200)
    }
  }, [connectionData]) // eslint-disable-line react-hooks/exhaustive-deps

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }, [])

  function extractNumberFromString(inputString) {
    const match = inputString.match(/\d+/)

    if (match) {
      const number = parseInt(match[0], 10)
      return number
    }

    return 999999
  }

  const columnDefs = useMemo(
    () => [
      {
        field: 'numConnections',
        colId: 'numConnections',
        editable: true,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        minWidth: 200,
        cellEditorParams: (params) => {
          return {
            values: [
              'Last 5 Connections',
              'Last 10 Connections',
              'Last 15 Connections',
              'Last 20 Connections',
              'Last 25 Connections',
              'All',
            ],
          }
        },
        valueGetter: (params) => (numConnections === 999999 ? 'All' : `Last ${numConnections} Connections`),
      },
      {
        field: 'holeSize',
        colId: 'holeSize',
        editable: false,
        minWidth: 200,
        valueGetter: (params) => {
          if (!Array.isArray(dataRef.current) || dataRef.current.length === 0) return ''
          if (!Array.isArray(dataRef.current[0].connections) || dataRef.current[0].connections.length === 0) return ''
          return `Hole Size ${numberWithCommasDecimals(
            dataRef.current[0].connections[dataRef.current[0].connections.length - 1].holeSize,
            3,
          )}`
        },
      },
      {
        field: 'date',
        colId: 'date',
        editable: false,
        minWidth: 300,
        flex: 1,
        valueGetter: (params) => {
          if (!Array.isArray(dataRef.current) || dataRef.current.length === 0) return ''
          if (!Array.isArray(dataRef.current[0].connections) || dataRef.current[0].connections.length === 0) return ''
          let lastDate = dateTimeFormatter(
            dataRef.current[0].connections[dataRef.current[0].connections.length - 1]?.timeStamp,
          )

          let firstIndex = dataRef.current[0].connections.length - 1 - numConnections
          if (firstIndex < 0) firstIndex = 0

          let firstDate = dateTimeFormatter(dataRef.current[0].connections[firstIndex]?.timeStamp)

          return `${firstDate} - ${lastDate}`
        },
      },
    ],
    [dateTimeFormatter, numConnections],
  )

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
      cellStyle: centerAlignCell,
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      if (!event.data) return
      let numberofConnections = extractNumberFromString(event.newValue)
      setNumConnections(numberofConnections)
    },
    popupParent: document.querySelector('body'),
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={[{ id: 0, numConnections: `Last ${numConnections} connections`, date: '', holeSize: '' }]}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default ConnectionStatusGrid
