import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material'

const NewLayoutCheckModal = ({ onClose, onApply }) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '400px',
          width: '50vw',
          minHeight: '225px',
          height: '15vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Work in Progress</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Typography variant='h6'>There are edits outstanding, do you want to save?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button variant='outlined' onClick={() => onApply(false)} color='secondary'>
          Discard
        </Button>
        <Button
          variant='contained'
          color='primary'
          type='submit'
          onClick={() => {
            onApply(true)
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewLayoutCheckModal
