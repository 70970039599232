import { Vector3 } from 'three'
import { getMinMaxData } from './Grid'
import { Box } from '@react-three/drei'
import { chartSeriesColors } from 'utils'
import TextLabel from './TextLabel'

function Formations({ formations, refData, offsetData, display, labelColor }) {
  if (!display) return null
  if (!Array.isArray(formations)) return null
  if (formations.length === 0) return null

  const minMaxValues = {
    minX: 0,
    maxX: 100,
    minY: 0,
    maxY: 100,
    minZ: 0,
    maxZ: 100,
    xLen: 100,
    yLen: 100,
    zLen: 100,
  }

  getMinMaxData(minMaxValues, refData, offsetData)

  return formations.map((form, i) => {
    let color = i < chartSeriesColors.length ? chartSeriesColors[i] : 0x6495ed
    return (
      <group key={`formation${i}`}>
        <TextLabel
          label={`${form.name}`}
          size={1}
          color={labelColor}
          position={new Vector3(form.x + 5, form.y, form.z + 5)}
        />
        <Box
          args={[
            minMaxValues.xLen * (form.found === true ? 0.1 : 1),
            0,
            minMaxValues.zLen * (form.found === true ? 0.1 : 1),
          ]}
          position={
            new Vector3(
              form.found === true ? form.x : minMaxValues.xOffsets.offset,
              parseFloat(form.y),
              form.found === true ? form.z : minMaxValues.zOffsets.offset,
            )
          }>
          <meshBasicMaterial attach='material' color={color} transparent={true} opacity={0.2} />
        </Box>
      </group>
    )
  })
}

export default Formations
