import React, { useMemo, useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Button } from '@mui/material'
import SearchBar from 'components/common/SearchBar'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { debounce } from 'lodash'

const MultiSelectGridEditor = (props) => {
  const gridApi = useRef(null)
  const [searchText, setSearchText] = useState('')
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getValue = () => {
    if (!gridApi.current) return ''
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows)) return []

    if (selectedRows.length === 0) return []
    return selectedRows.map((row) => row.code)
  }

  const onFirstDataRendered = (params) => {
    if (!Array.isArray(props.currentValues)) return

    const selectedNodes = []
    gridApi.current.forEachNode((node) => {
      if (node.data && props.currentValues.includes(node.data.code)) {
        selectedNodes.push(node)
      }
    })

    gridApi.current.setNodesSelected({
      nodes: selectedNodes,
      newValue: true,
    })

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 200)
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])

  let columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      field: 'code',
      colId: 'code',
      headerName: 'Code',
      editable: false,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'start' },
    },
    {
      field: 'description',
      colId: 'description',
      headerName: 'Description',
      editable: false,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'start' },
      valueFormatter: (params) => unescapeHtml(params.value),
    },
  ]

  const gridOptions = {
    onCellDoubleClicked: (params) => {
      props.stopEditing()
    },
    rowHeight: 35,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  return (
    <Box sx={{ height: '800px', width: '600px' }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
        <Box
          sx={{
            width: '100px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              props.onValueChange(getValue())
              props.stopEditing()
            }}>
            Select
          </Button>
        </Box>
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={Array.isArray(props.values) ? props.values.filter((val) => val.code !== '') : []}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          rowSelection='multiple'
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={(params) => []}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default MultiSelectGridEditor
