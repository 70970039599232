import React from 'react'
import { Box } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { round } from 'utils/numberFunctions'
import CircularProgress from '@mui/material/CircularProgress'
import { AnalyticsChartButton } from './AnalyticsBarChart'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AnalyticsHorizontalBarChart = ({
  data,
  title = '',
  param = '',
  label = '',
  isLoading = true,
  onXlsxExport = null,
}) => {
  const { theme } = useInnovaTheme()

  const createChartData = () => {
    if (!data) return { labels: [], datasets: [] }
    if (!Array.isArray(data)) return { labels: [], datasets: [] }
    if (data.length === 0) return { labels: [], datasets: [] }

    const chartData = {
      labels: data.map((well) => well.actualWell),
      datasets: [
        {
          data: data.map((well) => round(well[param], 2)),
          backgroundColor: appColors.rotateColor,
          label: label,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    indexAxis: 'y',
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: title.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          color: '#FFF',
        },
      },
      datalabels: {
        labels: {
          value: {
            font: {
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#FFF',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: '#FFF',
        },
      },
    },
    layout: {
      padding: {
        right: 20,
        top: 20,
      },
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: appColors.itemBackColor,
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <Bar type='bar' options={chartOptions} data={createChartData()} plugins={[ChartDataLabels]} />
      {onXlsxExport ? (
        <AnalyticsChartButton
          onClick={onXlsxExport}
          icon={'file-icons:microsoft-excel'}
          toolTipText={'Export to Excel'}
          color={'green'}
        />
      ) : null}
    </Box>
  )
}

export default AnalyticsHorizontalBarChart
