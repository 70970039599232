import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ConnectionSummaryGrid = ({ connectionData }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) {
      setTimeout(() => {
        autoSizeColumns()
      }, 200)
    }
  }, [connectionData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
      },
      {
        field: 'total',
        colId: 'total',
        headerName: 'Total',
        valueGetter: (params) => numberWithCommasDecimals(params?.data?.total?.avg, 2),
      },
      {
        field: 'day',
        colId: 'day',
        headerName: 'Day',
        valueGetter: (params) => numberWithCommasDecimals(params?.data?.day?.avg, 2),
      },
      {
        field: 'night',
        colId: 'night',
        headerName: 'Night',
        valueGetter: (params) => numberWithCommasDecimals(params?.data?.night?.avg, 2),
      },
      {
        field: 'rig',
        colId: 'rig',
        headerName: 'Rig',
        valueGetter: (params) => numberWithCommasDecimals(params?.data?.rig?.avg, 2),
      },
      {
        field: 'fleet',
        colId: 'fleet',
        headerName: 'Fleet',
        valueGetter: (params) => numberWithCommasDecimals(params?.data?.fleet?.avg, 2),
      },
      {
        field: 'trend',
        colId: 'trend',
        headerName: 'Trends',
        cellRenderer: 'agSparklineCellRenderer',
        minWidth: 300,
        cellRendererParams: {
          sparklineOptions: {
            type: 'area',
            fill: '#0a7474',
            line: {
              stroke: '#01e6e6',
            },
            axis: {
              stroke: '#808080',
            },
            highlightStyle: {
              size: 10,
              fill: 'cyan',
              stroke: 'cyan',
            },
          },
        },
      },
    ],
    [],
  )

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
      cellStyle: centerAlignCell,
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const updateAvgValue = (orgData, newValue) => {
    if (typeof newValue !== 'number') return { ...orgData }
    if (newValue <= 0) return { ...orgData }
    orgData.total += newValue
    orgData.divisor++

    if (orgData.total > 0 && orgData.divisor > 0) orgData.avg = orgData.total / orgData.divisor
    return { ...orgData }
  }

  const getGridData = (data) => {
    let gridData = [
      {
        label: 'Connection',
        tag: `connection`,
        total: { total: 0, divisor: 0, avg: 0 },
        day: { total: 0, divisor: 0, avg: 0 },
        night: { total: 0, divisor: 0, avg: 0 },
        rig: { total: 0, divisor: 0, avg: 0 },
        fleet: { total: 0, divisor: 0, avg: 0 },
        trend: [],
        id: 0,
      },
      {
        label: 'Btm to Slip',
        tag: `btm2slp`,
        total: { total: 0, divisor: 0, avg: 0 },
        day: { total: 0, divisor: 0, avg: 0 },
        night: { total: 0, divisor: 0, avg: 0 },
        rig: { total: 0, divisor: 0, avg: 0 },
        fleet: { total: 0, divisor: 0, avg: 0 },
        trend: [],
        id: 1,
      },
      {
        label: 'Slip to Slip',
        tag: `slp2slp`,
        total: { total: 0, divisor: 0, avg: 0 },
        day: { total: 0, divisor: 0, avg: 0 },
        night: { total: 0, divisor: 0, avg: 0 },
        rig: { total: 0, divisor: 0, avg: 0 },
        fleet: { total: 0, divisor: 0, avg: 0 },
        trend: [],
        id: 2,
      },
      {
        label: 'Slip to Btm',
        tag: `slp2btm`,
        total: { total: 0, divisor: 0, avg: 0 },
        day: { total: 0, divisor: 0, avg: 0 },
        night: { total: 0, divisor: 0, avg: 0 },
        rig: { total: 0, divisor: 0, avg: 0 },
        fleet: { total: 0, divisor: 0, avg: 0 },
        trend: [],
        id: 3,
      },
    ]

    if (!Array.isArray(data) || data.length <= 0) return data
    if (!Array.isArray(data[0].connections) || data[0].connections.length === 0) return data
    let refRig = data[0].rig

    for (let i = 0; i < data.length; i++) {
      if (!Array.isArray(data[i].connections)) continue
      let conn = data[i].connections

      for (let j = 0; j < conn.length; j++) {
        let btm2slp = conn[j].wt2WtTime - conn[j].surveyTime - conn[j].slp2SlpTime
        if (btm2slp < 0) btm2slp = 0
        let slp2btm = conn[j].surveyTime
        if (slp2btm < 0) slp2btm = 0
        let connData = [
          { tag: 'btm2slp', value: btm2slp / 60 },
          { tag: 'slp2slp', value: conn[j].slp2SlpTime / 60 },
          {
            tag: 'slp2btm',
            value: slp2btm / 60,
          },
          { tag: 'connection', value: conn[j].connectionTime / 60 },
        ]

        for (let k = 0; k < gridData.length; k++) {
          let dataVal = connData.find((x) => x.tag === gridData[k].tag)
          if (!dataVal) continue

          if (i === 0) {
            gridData[k]['total'] = updateAvgValue(gridData[k]['total'], dataVal.value)
            gridData[k]['day'] = updateAvgValue(gridData[k]['day'], conn[j].dayShift ? dataVal.value : 0)
            gridData[k]['night'] = updateAvgValue(gridData[k]['night'], conn[j].dayShift ? 0 : dataVal.value)
            gridData[k]['trend'].push(dataVal.value)
          }

          if (data[i].rig === refRig) gridData[k]['rig'] = updateAvgValue(gridData[k]['rig'], dataVal.value)
          gridData[k]['fleet'] = updateAvgValue(gridData[k]['fleet'], dataVal.value)
        }
      }
    }

    return gridData
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getGridData(connectionData)}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default ConnectionSummaryGrid
