import React, { useRef, useEffect } from 'react'

import Canvas from 'components/common/Canvas'
import { round } from 'utils/numberFunctions'
import { chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import { uuidv4 } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const deg2Rad = Math.PI / 180.0

const getTextWidth = (text, font) => {
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  var context = canvas.getContext('2d')
  context.font = font
  var metrics = context.measureText(text)
  return Math.ceil(metrics.width)
}

const TravellingCylinder = ({
  h,
  w,
  northRef = 'HS',
  data = null,
  drawDepthLabels = true,
  zoomPercentage = 1,
  drawWellNames = true,
  tvdSliceMin = 0,
  tvdSliceMax = 50000,
  id = null,
}) => {
  let centerPtX = w / 2
  let centerPtY = h / 2
  const numCircles = 10
  const maxDistance = useRef(50)
  const { theme, getChartBackColor, getTextColor } = useInnovaTheme()

  useEffect(() => {
    maxDistance.current = getMaxDistance()
  }, [data, zoomPercentage]) // eslint-disable-line react-hooks/exhaustive-deps

  const drawCircles = (context, circleRadiusPixels) => {
    let distanceBetweenCircles = circleRadiusPixels / numCircles

    context.lineWidth = 1
    for (let i = 1; i <= numCircles; i++) {
      if (i % 2 === 0) {
        context.strokeStyle = '#B0B0B0'
      } else {
        context.strokeStyle = '#606060'
      }
      context.beginPath()
      context.arc(centerPtX, centerPtY, distanceBetweenCircles * i, 0, 2 * Math.PI)
      context.stroke()
    }
  }

  const drawRadialLines = (context, circleRadiusPixels) => {
    let distanceBetweenCircles = circleRadiusPixels / numCircles
    let xStart, yStart, xEnd, yEnd
    context.strokeStyle = '#606060'
    context.lineWidth = 1
    for (let angle = 0; angle < 360; angle += 30) {
      context.beginPath()

      if (angle % 90 === 0) {
        context.strokeStyle = '#B0B0B0'
      } else {
        context.strokeStyle = '#606060'
      }

      xStart = distanceBetweenCircles * 2 * Math.sin((angle / 180) * Math.PI)
      yStart = distanceBetweenCircles * 2 * Math.cos((angle / 180) * Math.PI)
      xEnd = circleRadiusPixels * Math.sin((angle / 180) * Math.PI)
      yEnd = circleRadiusPixels * Math.cos((angle / 180) * Math.PI)
      context.moveTo(centerPtX + xStart, centerPtY + yStart)
      context.lineTo(centerPtX + xEnd, centerPtY + yEnd)
      context.stroke()
    }
  }

  const normalizeTF = (tf) => {
    if (!tf) return 0
    if (typeof tf !== 'number') return 0
    if (tf < 0.0) tf += 360.0
    if (tf > 360.0) tf -= 360.0

    return tf
  }

  const drawRadialLabels = (context, circleRadiusPixels) => {
    let xEnd, yEnd
    let angleOutput = ''
    context.fillStyle = '#B0B0B0'
    context.font = '1em sans-serif'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    for (let angle = 0; angle < 360; angle += 30) {
      xEnd = (circleRadiusPixels + 20) * Math.sin(-(angle - 180) * deg2Rad)
      yEnd = (circleRadiusPixels + 20) * Math.cos(-(angle - 180) * deg2Rad)
      angleOutput = angle.toString()
      context.fillText(angleOutput, centerPtX + xEnd, centerPtY + yEnd)
    }
  }

  const drawDot = (context, dist, angle, color, scale) => {
    if (!context || !dist || !angle || !color) return
    if (typeof dist !== 'number') return
    if (typeof angle !== 'number') return
    if (dist < 0) return

    const dotRadius = 5
    angle = normalizeTF(angle)

    context.fillStyle = color + '70'
    context.strokeStyle = color
    let x = dist * scale * Math.sin(angle * deg2Rad)
    let y = dist * scale * Math.cos(angle * deg2Rad)
    context.beginPath()
    context.arc(centerPtX + x, centerPtY - y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()
  }

  const drawLine = (context, p1, p2, color, scale) => {
    if (!context || !p1 || !p2 || !color) return
    if (typeof p1.dist !== 'number') return
    if (typeof p1.angle !== 'number') return
    if (typeof p2.dist !== 'number') return
    if (typeof p2.angle !== 'number') return
    if (p1.dist < 0 || p2.dist < 0) return

    p1.angle = normalizeTF(p1.angle)
    p2.angle = normalizeTF(p2.angle)

    context.strokeStyle = color

    let x1 = p1.dist * scale * Math.sin(p1.angle * deg2Rad)
    let y1 = p1.dist * scale * Math.cos(p1.angle * deg2Rad)
    let x2 = p2.dist * scale * Math.sin(p2.angle * deg2Rad)
    let y2 = p2.dist * scale * Math.cos(p2.angle * deg2Rad)

    context.moveTo(centerPtX + x1, centerPtY - y1)
    context.lineTo(centerPtX + x2, centerPtY - y2)
    context.stroke()
  }

  const getAngle = (point) => {
    if (!point) return 0
    if (!point.hasOwnProperty('tfo')) return 0
    return northRef === 'HS' ? point.tfo : point.tfn
  }

  const drawWells = (context, distanceToPixels) => {
    if (!data) return
    if (!Array.isArray(data.wells)) return

    let dotColor = '#ff0000'
    let lineColor = '#ff0000'

    data.wells.forEach((well, index) => {
      if (Array.isArray(well.acResults)) {
        if (index < chartSeriesColors.length) {
          lineColor = chartSeriesColors[index]
        } else {
          lineColor = getRandomColor()
        }

        dotColor = lineColor

        for (let i = 0; i < well.acResults.length; i++) {
          if (well.acResults[i].C2C < 0) continue
          if (well.acResults[i].C2C > maxDistance.current) continue
          if (well.acResults[i].refTvd < tvdSliceMin) continue
          if (well.acResults[i].refTvd > tvdSliceMax) continue
          drawDot(context, well.acResults[i].C2C, getAngle(well.acResults[i]), dotColor, distanceToPixels)

          if (drawDepthLabels) {
            let label = `MD: ${round(well.acResults[i].refMd, 2)}`
            drawText(
              context,
              label,
              well.acResults[i].C2C,
              getAngle(well.acResults[i]),
              '#ffffff',
              '#2d2d2d',
              distanceToPixels,
            )

            label = `TVD: ${round(well.acResults[i].refTvd, 2)}`
            drawText(
              context,
              label,
              well.acResults[i].C2C,
              getAngle(well.acResults[i]),
              '#ffffff',
              '#2d2d2d',
              distanceToPixels,
              20,
            )
          }

          if (i > 0) {
            if (well.acResults[i - 1].C2C > maxDistance.current) continue
            if (well.acResults[i - 1].refTvd < tvdSliceMin) continue
            if (well.acResults[i - 1].refTvd > tvdSliceMax) continue

            drawLine(
              context,
              {
                dist: well.acResults[i - 1].C2C,
                angle: getAngle(well.acResults[i - 1]),
              },
              {
                dist: well.acResults[i].C2C,
                angle: getAngle(well.acResults[i]),
              },
              lineColor,
              distanceToPixels,
            )
          }
        }
      }
    })
  }

  const drawWellsNames = (context, distanceToPixels) => {
    if (!drawWellNames) return
    if (!data) return
    if (!Array.isArray(data.wells)) return

    data.wells.forEach((well, index) => {
      if (Array.isArray(well.acResults)) {
        let lastPoint = -1
        let maxC2C = 0
        for (let i = 0; i < well.acResults.length; i++) {
          if (well.acResults[i].C2C < 0) continue
          if (well.acResults[i].C2C > maxDistance.current) continue
          if (well.acResults[i].C2C > maxC2C) {
            lastPoint = i
            maxC2C = well.acResults[i].C2C
          }
        }

        if (lastPoint >= 0) {
          drawText(
            context,
            well.wellName,
            well.acResults[lastPoint].C2C,
            getAngle(well.acResults[lastPoint]),
            getTextColor(),
            getTextColor(),
            distanceToPixels,
          )
        }
      }
    })
  }

  const drawText = (context, text, dist, angle, fill, stroke, scale, yOffset) => {
    if (!dist || !angle || !text) return
    if (!fill && !stroke) {
      fill = 'rgba(56, 219, 255, 1.0)'
      stroke = '#2d2d2d'
    }

    if (!fill && stroke) fill = stroke
    if (!stroke && fill) stroke = fill
    if (!yOffset) yOffset = 0

    angle = normalizeTF(angle)
    let x = dist * scale * Math.sin(angle * deg2Rad)
    let y = dist * scale * Math.cos(angle * deg2Rad)

    context.strokeStyle = stroke
    context.fillStyle = fill
    context.font = 'bold 0.75em sans-serif'
    context.textAlign = 'left'
    context.textBaseline = 'middle'
    context.fillText(text, centerPtX + x, centerPtY - y + yOffset)
  }

  const getWidthHeight = (context) => {
    return {
      width: w,
      height: h,
    }
  }

  const getMaxDistance = () => {
    if (!data) return 50
    if (!Array.isArray(data.wells)) return 50

    let maxDist = 50
    data.wells.forEach((well) => {
      if (Array.isArray(well.acResults)) {
        for (let i = 0; i < well.acResults.length; i++) {
          if (well.acResults[i].C2C > maxDist) maxDist = well.acResults[i].C2C
        }
      }
    })

    maxDist = (Math.floor(maxDist / 50) + 1) * 50

    if (maxDist * zoomPercentage > 1) {
      maxDist *= zoomPercentage
    } else {
      maxDist = 1
    }

    return maxDist
  }

  const drawBackGroundGradient = (context, circleRadiusPixels) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = getChartBackColor()
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    context.strokeStyle = 'rgba(0,0,0,1)'
    context.strokeRect(0, 0, context.canvas.width, context.canvas.height)

    let gradient = context.createRadialGradient(centerPtX, centerPtY, 0, centerPtX, centerPtY, circleRadiusPixels)
    gradient.addColorStop(0, theme === 'dark' ? '#404040' : '#ffffff')
    gradient.addColorStop(1, theme === 'dark' ? '#101010' : '#c0c0c0')
    context.fillStyle = gradient
    context.beginPath()
    context.arc(centerPtX, centerPtY, circleRadiusPixels, 0, 2 * Math.PI)
    context.fill()
  }

  const drawDistanceScale = (context, distanceToPixels) => {
    context.fillStyle = '#B0B0B0'
    context.font = '14px sans-serif'
    context.textAlign = 'right'
    context.textBaseline = 'middle'
    context.fillText(
      maxDistance.current.toFixed(0),
      centerPtX + maxDistance.current * distanceToPixels - 5,
      centerPtY + 12,
    )
    context.fillText(
      maxDistance.current.toFixed(0) / 2,
      centerPtX + (maxDistance.current / 2) * distanceToPixels - 5,
      centerPtY + 12,
    )
  }

  const draw = (context) => {
    const { width, height } = getWidthHeight(context)
    centerPtX = width / 2
    centerPtY = height / 2

    let circleDiameterPixels = width > height ? height : width
    let margin = circleDiameterPixels >= 400 ? getTextWidth('90°R', '1em sans-serif') * 2 : 8
    let circleRadiusPixels = circleDiameterPixels / 2 - margin
    const distanceToPixels = circleRadiusPixels / maxDistance.current

    if (circleRadiusPixels < 0) return

    drawBackGroundGradient(context, circleRadiusPixels)
    drawCircles(context, circleRadiusPixels)
    drawRadialLines(context, circleRadiusPixels)
    drawWells(context, distanceToPixels)
    drawDistanceScale(context, distanceToPixels)
    drawWellsNames(context, distanceToPixels)
    if (circleDiameterPixels >= 400) drawRadialLabels(context, circleRadiusPixels)
  }

  return (
    <Canvas square={false} id={id ? id : uuidv4()} draw={draw} width={w} height={h} style={{ width: w, height: h }} />
  )
}

export default TravellingCylinder
