export const getJarsDryWt = (bhaData) => {
  if (!bhaData || !Array.isArray(bhaData)) return ''
  let jarWeight = 0
  for (let i = 0; i < bhaData.length; i++) {
    if (bhaData[i].type === 'Jar') {
      break // Exit the loop when the first Jar is found
    }
    if (bhaData[i].type !== 'Drill Pipe/HWDP') jarWeight += bhaData[i].weight * bhaData[i].length
  }
  return jarWeight / 1000
}

export const getStatorData = (motorData) => {
  if (!motorData || typeof motorData !== 'object') return 'blah'
  let statorArray = [
    motorData.statorVendor,
    motorData.statorType,
    motorData.statorFit === '0.00000' ? '' : motorData.statorFit,
  ]
  let statorString = ''
  let first = true
  for (let i = 0; i < statorArray.length; i++) {
    if (statorArray[i]) {
      if (i !== 0 && !first) {
        statorString = statorString + ' / '
      }
      first = false
      statorString = statorString + statorArray[i]
    }
  }

  return statorString
}

export const getStabToBit = (bhaData, stabilizerData, index) => {
  let stabToBit = stabilizerData[index].stabDfb
  for (let i = 0; i < stabilizerData[index].sequenceNo; i++) {
    stabToBit += bhaData[i].length
  }
  return stabToBit
}

export const getBhaTotalWeight = (bhaData) => {
  let totalWeight = 0
  for (let i = 0; i < bhaData.length; i++) {
    if (bhaData[i].type !== 'Drill Pipe/HWDP') totalWeight += bhaData[i].weight * bhaData[i].length
  }

  return totalWeight
}

export const getPlugInHrs = (offsetData) => {
  if (!offsetData || typeof offsetData !== 'object') return ''
  const plugInDate = new Date(Date.parse(offsetData.battPlugInDate + ':' + offsetData.battPlugInTime))
  const plugOutDate = new Date(Date.parse(offsetData.battPlugOutDate + ':' + offsetData.battPlugOutTime))
  return Math.abs(plugOutDate.getTime() - plugInDate.getTime()) / 36e5
}

function isDateLessThan(date1, date2) {
  const time1 = new Date(date1).getTime()
  const time2 = new Date(date2).getTime()

  return time1 < time2
}

const isValidDate = (value) => {
  return value instanceof Date || !isNaN(Date.parse(value))
}

export const dateTimeFormatter = (value) => {
  if (!value) return ''
  if (typeof value !== 'string') return ''
  if (value === '') return ''
  if (!isValidDate(value)) return
  if (isDateLessThan(value, '1990-01-01')) return ''
  value = value.replace(/Z/g, '')
  return new Date(Date.parse(value)).toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  })
}