import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { Scatter } from 'react-chartjs-2'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { appColors, isoStringToNumeric } from 'utils'
import { currentInfrastructureRdsSelector } from 'atoms'

export const RdsUtilizationChart = ({ showTitle }) => {
  const data = useRecoilValue(currentInfrastructureRdsSelector)
  const { theme } = useInnovaTheme()

  useEffect(() => {
    getChartData()
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const options = {
    layout: {
      padding: {
        right: 20,
      },
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      annotation: {},
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: showTitle,
        text: 'RDS CPU Usage',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return [
              `${new Date(context.parsed.x).toLocaleTimeString('en-US')} Date/Time`,
              ` ${context.parsed.y} CPU(%)`,
            ]
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        title: {
          display: false,
        },
        grid: {
          color: '#404040',
        },
        ticks: {
          callback: function (value) {
            return new Date(value).toLocaleTimeString('en-US')
          },
        },
      },
      y: {
        beginAtZero: true,
        reverse: false,
        title: {
          display: true,
          text: 'CPU (%)',
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        min: 0,
        max: 100,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  }

  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  function getLineColor(inputVal, baseColor) {
    if (inputVal > 80) return 'tomato'
    if (inputVal > 50) return 'orange'

    return baseColor
  }

  const getChartData = () => {
    const chartJsData = {
      datasets: [
        {
          type: 'line',
          label: 'Other',
          data: [],
          borderColor: 'cyan',
          backgroundColor: 'cyan',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, 'cyan'),
            borderWidth: 3,
          },
        },
        {
          type: 'line',
          label: 'SQL',
          data: [],
          borderColor: '#59FF00CF',
          backgroundColor: '#59FF00CF',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, '#59FF00CF'),
            borderWidth: 3,
          },
        },
      ],
    }

    if (Array.isArray(data?.cpuUtilization)) {
      for (let i = data.cpuUtilization.length - 1; i >= 0; i--) {
        const element = data.cpuUtilization[i]
        chartJsData.datasets[0].data.push({
          x: parseFloat(isoStringToNumeric(element.eventTime)),
          y: element.other,
          color: getLineColor(element.other, 'cyan'),
        })

        chartJsData.datasets[1].data.push({
          x: parseFloat(isoStringToNumeric(element.eventTime)),
          y: element.sql,
          color: getLineColor(element.sql, '#59FF00CF'),
        })
      }
    }

    return chartJsData
  }

  return <Scatter options={options} data={getChartData()} />
}

export default RdsUtilizationChart
