import HeatMapChart from 'components/common/Heat Map/HeatMapChart'
import useDrillingParams from 'components/common/hooks/Engineering/useDrillingParams'

const HeatMapCard = () => {
  const { getSlideSheetData } = useDrillingParams()
  const data = getSlideSheetData('heatMap')
  
  const getBhaNums = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let bhaNums = []
    data.forEach((item, index) => {
      item.data.forEach((d, i) => {
        const existingBhaNum = bhaNums.find((bha) => bha.bhaNum === d.bhaNum)
        if (!existingBhaNum) {
          bhaNums.push({
            bhaNum: d.bhaNum,
          })
        }
      })
    })
    
    return bhaNums
  }

  const getMaxBhaNum = () => {
    if (!data) return -1
    if (!Array.isArray(data)) return -1
    if (data.length === 0) return -1

    let maxBhaNum = -1
    data.forEach((item, index) => {
      item.data.forEach((d, i) => {
        if (d.bhaNum > maxBhaNum) {
          maxBhaNum = d.bhaNum
        }
      })
    })

    return maxBhaNum
  }

  return <HeatMapChart data={data} showTitle={false} bhas ={getBhaNums()} bhaNum = {getMaxBhaNum()} />
}

export default HeatMapCard
