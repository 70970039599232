import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  sortColDefs,
  htmlSymbolHandling,
  relativeTime,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { throttle } from 'lodash'
import { styled } from '@mui/styles'
import ConfirmDialog from 'components/common/ConfirmDialog'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const ShippingTicketListGrid = ({
  shippingTickets,
  isLoading,
  isVisible,
  showHidePane,
  refreshData,
  addNewTicket,
  updateTicket,
  deleteTicket,
  setSelectedTicket,
  gridApi,
  handlePrintTicket,
}) => {
  const _isMounted = useRef(false)
  const selectedTicket = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const throttledSetQuickFilter = throttle((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    throttledSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  function onSelectionChanged() {
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows)) setSelectedTicket(null)
    if (selectedRows.length === 0) setSelectedTicket(null)
    setSelectedTicket(selectedRows[0])
  }

  const gridOptions = {
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onSelectionChanged: onSelectionChanged,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()

    let firstTicket = null
    params.api.forEachNode((node) => {
      if (!firstTicket && node.data) firstTicket = cloneDeep(node.data)
    })

    setSelectedTicket(firstTicket)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('shippingTicketsListGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('shippingTicketsListGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>', //ask dan
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ShippingTickets.xlsx',
            sheetName: 'Tickets',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const onClickDelete = (data) => {
    selectedTicket.current = cloneDeep(data)
    setConfirm({
      show: true,
      title: 'Delete Shipping Ticket',
      text: `Are you sure you want to delete ticket ${data.ticketNum}?`,
    })
  }

  const handleAddTicket = async () => {
    let res = await addNewTicket()
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        add: [res.data],
        addIndex: 0,
      })
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return
    let res = await updateTicket(htmlSymbolHandling(data))
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: [res.data],
      })
    }

    refreshData()
  }

  const handleDelete = async () => {
    if (!selectedTicket.current) return
    let res = await deleteTicket(selectedTicket.current)
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [res.data],
      })
    }

    selectedTicket.current = null
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={'Delete'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => onClickDelete(params.data)}
              size='large'>
              <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
            </IconButton>
          </Tooltip>
        </Box>
      </React.Fragment>
    )
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 100,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'ticketNum',
        colId: 'ticketNum',
        headerName: 'Ticket#',
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'date',
        colId: 'date',
        headerName: 'Date',
        valueFormatter: (params) =>
          `${dateTimeFormatter(params?.data?.date)} [${relativeTimeFormatter(params.data?.date)}]`,
      },
      {
        field: 'companyTo',
        colId: 'companyTo',
        headerName: 'Shipped To',
        editable: false,
        valueGetter: (params) => unescapeHtml(params.data?.companyTo),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onClickPrintTicket = async () => {
    await handlePrintTicket()
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: isVisible ? 'flex' : 'none',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(3)}
        />
      </Box>
      <Box
        sx={{
          display: isVisible ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 65px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={shippingTickets}
            columnDefs={sortColDefs(columnDefs, 'shippingTicketsListGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            rowSelection={'single'}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
        <StyledMenuIcon>
          <MenuButton
            actions={[
              {
                icon: <RefreshIcon />,
                name: 'Refresh',
                onClick: () => {
                  refreshData()
                },
              },
              {
                icon: <AddIcon />,
                name: 'Add new ticket',
                onClick: () => {
                  handleAddTicket()
                },
              },
              {
                icon: <Iconify icon='fa6-solid:file-pdf' style={{ fontSize: '30px', color: 'red' }} />,
                name: 'Print ticket',
                onClick: onClickPrintTicket,
              },
            ]}
          />
        </StyledMenuIcon>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </Box>
      {!isVisible ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Ticket List
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default ShippingTicketListGrid
