import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Box, CircularProgress } from '@mui/material'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WellSeekerDeltaUsersChart = ({ userData, isLoading }) => {
  const { theme, getChartBackColor } = useInnovaTheme()

  const chartOptions = {
    layout: {
      padding: {
        right: 20,
        left: 20,
        top: 20,
        bottom: 20,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: appColors.headerTextColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: appColors.headerTextColor,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
    },
  }

  const createChartData = () => {
    const chartData = {
      labels: [],
      datasets: [
        {
          label: 'Delta Users',
          data: [],
          backgroundColor: [],
        },
      ],
    }

    if (!Array.isArray(userData.companyValues)) return chartData
    if (userData.companyValues.length === 0) return chartData

    for (let i = 0; i < userData.companyValues.length; i++) {
      if (userData.companyValues[i].length === 0) continue

      let currentValue = userData.companyValues[i][userData.companyValues[i].length - 1]
      let prevValue =
        userData.companyValues[i].length > 1 ? userData.companyValues[i][userData.companyValues[i].length - 2] : 0

      if (currentValue > 0 || prevValue > 0) {
        chartData.labels.push(userData.seriesLabels[i])
        chartData.datasets[0].data.push(currentValue - prevValue)
        chartData.datasets[0].backgroundColor.push(currentValue - prevValue >= 0 ? '#00ff00' : '#ff0000')
      }
    }

    return chartData
  }

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        display: 'flex',
        width: '99.9%',
        height: '100%',
        overflow: 'hidden',
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <Bar type='bar' options={chartOptions} data={createChartData()} />
    </Box>
  )
}

export default WellSeekerDeltaUsersChart
