import React, { useRef, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WitsmlSelectionGrid = ({ selectedNodes, witsmlVersion }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const getGridData = () => {
    let data = [
      {
        id: 0,
        label: 'WITSML Version',
        level: 'version',
        name: witsmlVersion.message,
        uuid: '',
      },
      { id: 1, label: 'Well', level: 'well', name: '', uuid: '' },
      { id: 2, label: 'Wellbore', level: 'wellBore', name: '', uuid: '' },
      { id: 3, label: 'Trajectory', level: 'traj', name: '', uuid: '' },
      { id: 4, label: 'Log', level: 'log', name: '', uuid: '' },
    ]

    if (Array.isArray(selectedNodes)) {
      for (let i = 0; i < data.length; i++) {
        let index = selectedNodes.findIndex((n) => n.level === data[i].level)
        if (index < 0) continue
        data[i].name = unescapeHtml(selectedNodes[index].name)
        data[i].uuid = selectedNodes[index].uuid
      }
    }

    return data
  }

  const updateGridData = () => {
    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: getGridData(),
      })
    }

    autoSizeColumns()
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    updateGridData()
  }, [selectedNodes, witsmlVersion]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  let columnDefs = [
    {
      field: 'label',
      colId: 'level',
      headerName: 'Level',
      minWidth: 200,
      cellStyle: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
    },
    {
      field: 'name',
      colId: 'name',
      headerName: 'Name',
      minWidth: 200,
      cellStyle: (params) => {
        if (params?.data?.id > 0) return { display: 'flex', justifyContent: 'start', alignItems: 'center' }
        return {
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          backgroundColor: witsmlVersion.success ? 'green' : 'red',
        }
      },
    },
    {
      field: 'uuid',
      colId: 'uuid',
      headerName: 'UUID',
      minWidth: 150,
      cellStyle: { display: 'flex', justifyContent: 'start', alignItems: 'center' },
    },
  ]

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={getGridData()}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={0}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default WitsmlSelectionGrid
