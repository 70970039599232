export const getFontWeightText = (fontWeight) => {
  switch (fontWeight) {
    case '100':
      return '100 - lightest'
    case '400':
      return '400 - normal'
    case '700':
      return '700 - bold'
    case '900':
      return '900 - boldest'
    default:
      return fontWeight
  }
}

export const getVertAlignText = (align) => {
  switch (align) {
    case 'start':
      return 'top'
    case 'center':
      return 'middle'
    case 'end':
      return 'bottom'
    default:
      return 'middle'
  }
}

export const getVertAlignStyle = (align) => {
  switch (align) {
    case 'top':
      return 'start'
    case 'middle':
      return 'center'
    case 'bottom':
      return 'end'
    default:
      return 'center'
  }
}

export const getHorzAlignText = (align) => {
  switch (align) {
    case 'flex-start':
      return 'left'
    case 'center':
      return 'center'
    case 'flex-end':
      return 'right'
    default:
      return 'center'
  }
}

export const getHorzAlignStyle = (align) => {
  switch (align) {
    case 'left':
      return 'flex-start'
    case 'center':
      return 'center'
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}

export const stylesToGridData = (style) => {
  let properties = { ...style }
  if (style.borderStyle === 'hidden') properties.borderStyle = 'none' // 'hidden' is not a valid border style, so we use 'none' instead
  properties.borderWidth = parseFloat(style.borderWidth)
  properties.fontSize = parseFloat(style.fontSize)
  properties.fontWeight = getFontWeightText(style.fontWeight)
  properties.fontStyle = style.fontStyle === 'italic'
  properties.horizontalAlign = getHorzAlignText(style.justifyContent)
  properties.verticalAlign = getVertAlignText(style.alignItems)
  return properties
}

export const gridDataToStyles = (properties) => {
  let style = { ...properties }
  // when we shift to tag-based properties, need to make sure a property is being used (exists) before setting it
  if (properties.borderStyle && properties.borderStyle === 'none') style.borderStyle = 'hidden' // 'none' is not a valid border style, so we use 'hidden' instead
  if (properties.borderWidth) style.borderWidth = `${properties.borderWidth}px`
  if (properties.fontSize) style.fontSize = `${properties.fontSize}px`
  if (properties.fontWeight) style.fontWeight = properties.fontWeight.split(' ')[0]
  if (properties.hasOwnProperty('fontStyle')) style.fontStyle = properties.fontStyle ? 'italic' : 'normal'
  if (properties.verticalAlign) style.alignItems = getVertAlignStyle(properties.verticalAlign)
  if (properties.horizontalAlign) style.justifyContent = getHorzAlignStyle(properties.horizontalAlign)
  return style
}

