import React, { useRef, useEffect } from 'react'
import { appColors } from 'utils'
import { Bar } from 'react-chartjs-2'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const EndpointUsageRequestsPerSecondChart = ({ data }) => {
  const _isMounted = useRef(false)
  const chartRef = useRef()
  const { theme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const DayLabelsPlugin = {
    id: 'DayLabelsPlugin',
    beforeDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { left, bottom },
      } = chart

      if (!ctx) return

      ctx.save()

      const drawDayLabel = (text, x) => {
        ctx.font = '12px roboto'
        ctx.fillStyle = appColors.headerTextColor
        ctx.fillText(text, x + left, bottom + 75)
      }

      if (!chart.scales.x._labelItems?.length > 0) return
      for (let i = 0; i < chart?.scales?.x?.ticks?.length; i++) {
        if (chart.scales?.x.ticks[i].label < chart.scales?.x.ticks[i - 1]?.label || i === 0) {
          let msTimeStamp = chartRef.current.config._config.data.labels[chart.scales?.x.ticks[i].value]

          if (!chart?.scales?.x._labelItems) continue
          if (!Array.isArray(chart.scales.x._labelItems)) continue
          if (i >= chart.scales.x._labelItems.length) continue
          if (!chart.scales.x._labelItems[i].translation) continue
          if (!chart.scales.x._labelItems[i].translation[0]) continue

          let pixelLocation = chart.scales.x._labelItems[i].translation[0] - chart.scales.x.left
          drawDayLabel(new Date(msTimeStamp).toLocaleDateString('default'), pixelLocation)
        }
      }
    },
  }

  const chartOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        right: 20,
        left: 20,
        top: 20,
        bottom: 30,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          callback: (value) => {
            if (!chartRef.current) return
            let date = chartRef.current.config._config.data.labels[value]
            return new Date(date).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' })
          },

          color: appColors.headerTextColor,
        },
      },
      y: {
        ticks: {
          color: appColors.headerTextColor,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return new Date(parseFloat(tooltipItem[0].label)).toLocaleString('default', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })
          },
          label: function (context) {
            return `Avg Hits: ${numberWithCommasDecimals(context.parsed.y, 2)}`
          },
        },
        mode: 'nearest',
        intersect: false,

        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
    },
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  function createTwoMinuteIntervals(startDateTime, endDateTime) {
    if (!isValidDate(startDateTime) || !isValidDate(endDateTime)) return []
    const intervals = []
    const interval = 2 * 60 * 1000
    let currentDateTime = startDateTime.getTime()

    while (currentDateTime <= endDateTime) {
      intervals.push(currentDateTime)
      currentDateTime += interval
    }

    return intervals
  }

  const createChartData = () => {
    const chartData = {
      labels: [],
      datasets: [
        {
          type: 'line',
          label: 'Avg Hits',
          data: [],
          borderColor: '#59FF00CF',
          backgroundColor: '#59FF00CF',
        },
      ],
    }

    if (!data || !Array.isArray(data)) return chartData

    let startDateTime = new Date(data[0]?.ts)
    let endDateTime = new Date(data[data.length - 1]?.ts)
    startDateTime.setSeconds(0)
    startDateTime.setMilliseconds(0)
    endDateTime.setSeconds(0)
    endDateTime.setMilliseconds(0)

    const labels = createTwoMinuteIntervals(startDateTime, endDateTime)
    const labelMap = new Map()

    labels.forEach((label, index) => {
      const oneMinuteOffset = label + 60000
      labelMap.set(label, index)
      labelMap.set(oneMinuteOffset, index)
    })

    const chartDataArray = new Array(labels.length).fill(0)
    chartData.labels = labels

    for (let i = 0; i < data.length; i++) {
      let currentTimeStamp = new Date(data[i]?.ts)
      currentTimeStamp.setSeconds(0)
      currentTimeStamp.setMilliseconds(0)

      const foundLabel = labelMap.get(currentTimeStamp.getTime())
      if (!foundLabel) continue

      chartDataArray[foundLabel] += 1 / 120
    }

    chartData.datasets[0].data = chartDataArray
    return chartData
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '99.9%',
        overflow: 'hidden',
        backgroundColor: getChartBackColor(),
      }}>
      <Bar ref={chartRef} options={chartOptions} data={createChartData()} plugins={[DayLabelsPlugin]} />
    </Box>
  )
}

export default EndpointUsageRequestsPerSecondChart
