import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import { HexColorPicker } from 'react-colorful'

const ColorPickerCellEditor = (props) => {
  const [selectedColor, setSelectedColor] = useState(props.color)
  const [hexValue, setHexValue] = useState(selectedColor)

  const handleHexInputChange = (e) => {
    const inputValue = e.target.value
    setHexValue(inputValue)

    if (/^#[0-9A-Fa-f]{6}$/.test(inputValue)) {
      setSelectedColor(inputValue)
    }

    props.onValueChange(inputValue)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <HexColorPicker
        color={selectedColor}
        onChange={(e) => {
          setSelectedColor(e)
          setHexValue(e)
          props.onValueChange(e || '#FF6347')
        }}
      />
      <input
        type='text'
        value={hexValue}
        onChange={handleHexInputChange}
        style={{ margin: '5px', width: '90%', padding: '5px' }}
        placeholder='Enter Hex Color'
      />
      <Button
        variant='outlined'
        color={'success'}
        sx={{ margin: '5px' }}
        onClick={() => {
          let defaultColor = props.defaultColor
          if (!props.defaultColor) {
            defaultColor = props.color
          }

          setSelectedColor(defaultColor)
          setHexValue(defaultColor)
          props.onValueChange(defaultColor)
        }}>
        'RESET'
      </Button>
    </Box>
  )
}

export default ColorPickerCellEditor
