import React, { useEffect, useMemo, useRef, useState } from 'react'
import { styled } from '@mui/styles'
import { useSetRecoilState } from 'recoil'
import { Box, Snackbar, Alert } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { currentPageAtom, actionBarWidthAtom, currentWellAtom } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import AddIcon from '@mui/icons-material/Add'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import useDrillString from 'components/common/hooks/useDrillString/useDrillString'
import { useRecoilValue } from 'recoil'
import { appColors } from 'utils'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DrillStringGrid from './DrillStringGrid'
import DrillStringChartsCarousel from './CarouselItems/DrillStringChartsCarousel'
import useMotorReportImages from 'components/common/hooks/useMotorReportImages'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const DrillStringPage = () => {
  const _isMounted = useRef(false)
  const [drillStringData, setDrillStringData] = useState([])
  const setActivePage = useSetRecoilState(currentPageAtom)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [refresh, setRefresh] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const {
    loading: isLoading,
    getDrillStringHeader,
    getBhaReportPdfData,
    getMotorReportPdfData,
    fetchBhaHeaders: refreshBhaHeaders,
    addBha,
    getMotorPerformanceXlsExport,
    getBhaXlsExport,
    fetchBhaIaddExport,
    getBhaIaddXlsExport,
  } = useDrillString()
  const [filteredData, setFilteredData] = useState([])
  const { fetchImagesManual } = useMotorReportImages()
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const showChartRef = useRef(showChart)

  useEffect(() => {
    showChartRef.current = showChart
  }, [showChart])

  useEffect(() => {
    if (_isMounted.current) {
      if (!isLoading) setDrillStringData(getDrillStringHeader(-1))
    }
  }, [isLoading, currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.drillStringKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onBhaPdfExport = async (bhaNum) => {
    let pdfDoc = await getBhaReportPdfData(bhaNum)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onMotorReportPdfExport = async (bhaNum) => {
    if (bhaNum === null || bhaNum === undefined) return

    let motorImages = await fetchImagesManual(bhaNum, currentWell)
    let pdfDoc = await getMotorReportPdfData(bhaNum, motorImages)
    if (!pdfDoc) return

    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const handleAddNewBha = async () => {
    let res = await addBha('New BHA')
    setStatus({
      show: true,
      severity: res.isError ? 'error' : 'success',
      message: res.isError ? `Add BHA failed:${res.message}` : 'BHA added',
    })

    if (res.isError) return
    refreshBhaHeaders()
  }

  const onXlsExport = (bhaNum, exportType) => {
    if (exportType === 'performanceExport') getMotorPerformanceXlsExport(bhaNum)
    if (exportType === 'bhaExport') getBhaXlsExport(bhaNum)
    return
  }

  const onIaddExport = async (bhaNum) => {
    let bhaJson = await fetchBhaIaddExport(bhaNum)
    if (!bhaJson) return
    let blob = new Blob([JSON.stringify(bhaJson)], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, `iaddBhaExport.txt`)
  }

  const onIaddXlsExport = async (bhaNum) => {
    getBhaIaddXlsExport(bhaNum)
    return
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <AddIcon />,
        name: 'Add new BHA',
        onClick: () => {
          handleAddNewBha()
        },
      },
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          setRefresh((prev) => !prev)
          refreshBhaHeaders()

        },
      },
      {
        icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
        name: 'Export all Performance Reports',
        onClick: () => onXlsExport(-1, 'performanceExport'),
      },
      {
        icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
        name: 'Export all BHAs',
        onClick: () => onXlsExport(-1, 'bhaExport'),
      },
      {
        icon: <PictureAsPdfIcon />,
        name: 'Print all bhas',
        onClick: () => onBhaPdfExport(-1),
      },
      {
        icon: <Iconify icon='game-icons:oil-pump' style={{ color: 'red', width: 28, height: 28 }} />,
        name: 'Print all motor reports',
        onClick: () => onMotorReportPdfExport(-1),
      },
    ]
    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Chart',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Chart',
        onClick: () => handleShowChart(),
      })
    }
    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const DrillString = useMemo(
    () => (
      <DrillStringGrid
        gridData={drillStringData}
        isLoading={isLoading}
        setFilteredData={setFilteredData}
        onBhaPdfExport={onBhaPdfExport}
        onMotorReportPdfExport={onMotorReportPdfExport}
        setStatus={setStatus}
        setShowChart={setShowChart}
        showChart={showChartRef}
        wellName={currentWell}
        onXlsExport={onXlsExport}
        onIaddExport={onIaddExport}
        onIaddXlsExport={onIaddXlsExport}
        refresh={refresh}
      />
    ),
    [drillStringData, isLoading, currentWell], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <React.Fragment>
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={paneSize}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <DrillStringChartsCarousel filteredData={filteredData} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          {DrillString}
        </Box>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <StyledMenuIcon>
        <MenuButton actions={getMenuItems()} />
      </StyledMenuIcon>
    </React.Fragment>
  )
}

export default DrillStringPage
