import React, { useEffect, useRef, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { currentInfrastructureRdsSelector } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DatabaseConnectionsCard = () => {
  const _isMounted = useRef(false)
  const infrastructureRdsData = useRecoilValue(currentInfrastructureRdsSelector)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('databaseConnectionsGrid', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('databaseConnectionsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DatabaseConnections.xlsx',
            sheetName: 'Connections',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'clientNetAddress',
        colId: 'clientNetAddress',
        headerName: 'IP Address',
        cellStyle: leftAlignCell,
        minWidth: 200,
      },
      {
        field: 'applicationName',
        colId: 'applicationName',
        headerName: 'Application',
        cellStyle: leftAlignCell,
        minWidth: 200,
      },
      {
        field: 'loginName',
        colId: 'loginName',
        headerName: 'Login Name',
        cellStyle: leftAlignCell,
        minWidth: 200,
      },
      {
        field: 'connectionCount',
        colId: 'connectionCount',
        headerName: 'Connections#',
        cellStyle: centerAlignCell,
        minWidth: 200,
      },
    ],
    [centerAlignCell, leftAlignCell],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={Array.isArray(infrastructureRdsData.connectionInfo) ? infrastructureRdsData.connectionInfo : []}
        columnDefs={sortColDefs(columnDefs, 'databaseConnectionsGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default DatabaseConnectionsCard
