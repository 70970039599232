import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { useRecoilValue } from 'recoil'
import { currentInfrastructureAcDashSelector } from 'atoms'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AcDashRedisGrid = () => {
  const data = useRecoilValue(currentInfrastructureAcDashSelector)
  const _isMounted = useRef(false)
  const gridApi = React.useRef(null)
  const { getAgGridTheme, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const columnDefs = [
    {
      headerName: '',
      field: 'label',
      colId: 'label',
      minWidth: 200,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    {
      headerName: '',
      field: 'value',
      colId: 'value',
      minWidth: 200,
      editable: false,
      cellDataType: false,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
      valueFormatter: (params) => {
        if (typeof params.value === 'number') {
          return `${numberWithCommasDecimals(params.value, 0)}`
        }
        return params.data?.value
      },
      cellRendererSelector: (params) => {
        if (Array.isArray(params.value)) {
          return {
            component: 'agSparklineCellRenderer',
            params: {
              sparklineOptions: {
                type: 'area',
                highlightStyle: {
                  fill: 'rgb(143,185,77)',
                },
                axis: {
                  stroke: '#c1d2f2',
                },
                tooltip: {
                  renderer: (params) => {
                    return {
                      backgroundColor: 'black',
                      opacity: 0.9,
                      color: 'white',
                    }
                  },
                },
              },
            },
          }
        }
        return null
      },
    },
  ]

  const getRowData = (data) => {
    if (!data) return []

    const result = []
    for (const key in data?.redisInfo) {
      if (key === 'used_memory_rss') continue
      if (key === 'used_memory') continue
      if (key === 'used_memory_peak') continue
      if (key === 'used_cpu_sys') continue
      if (key === 'used_cpu_user') continue

      if (data?.redisInfo?.hasOwnProperty(key)) {
        const niceFormatKey = key
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
          .replace('Human', '')

        result.push({ label: niceFormatKey, value: data?.redisInfo[key] })
      }
    }

    result.push({
      label: 'CPU Usage',
      value: Array.isArray(data?.redisCpuUsage) ? data?.redisCpuUsage.map((pt) => pt.value) : [],
    })
    result.push({
      label: 'Mem Usage',
      value: Array.isArray(data?.redisMemoryUsage) ? data?.redisMemoryUsage.map((pt) => pt.value) : [],
    })
    return result
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.label)
    }
  }, [])

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          backgroundColor: getWindowBarColor(),
          height: '25px',
          width: 'calc(100% - 6px)',
          color: '#FFF',
          fontWeight: 'bold',
          textAlign: 'center',
          border: '1px solid gray',
        }}>
        REDIS STAUS
      </Box>
      <div style={{ height: 'calc(100% - 30px)', width: 'calc(100% - 4px)' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getRowData(data)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default AcDashRedisGrid
