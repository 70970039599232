import * as XLSX from '@sheet/core'

function usePersonnelUtilizationXlsExport() {
  const getXlsFileName = () => {
    return 'PersonnelReport.xlsx'
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const sortDateHeaders = (dateArray) => {
    if (!dateArray || !Array.isArray(dateArray)) return []
    let msTimeArray = dateArray.map((dateString) => {
      if (isValidDate(dateString)) return new Date(Date.parse(dateString + 'T00:01:00')).getTime()
      return ''
    })

    msTimeArray.sort((a, b) => a - b)

    let startDate = msTimeArray[0]
    let endDate = msTimeArray[msTimeArray.length - 1]
    let dateRange = []
    let currentDate = new Date(startDate)

    // fill empty dates in range
    while (currentDate <= endDate) {
      dateRange.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    let formattedDates = dateRange.map((date) => {
      let year = date.getFullYear()
      let month = String(date.getMonth() + 1).padStart(2, '0')
      let day = String(date.getDate()).padStart(2, '0')
      return getStyledCellObject(`${year}-${month}-${day}`, true, 90)
    })

    return formattedDates
  }

  const makeEmptyArray = (size) => {
    return Array(size).fill(undefined)
  }

  const getCellStyle = (
    fgColor = '',
    horizontalAlign = 'center',
    verticalAlign = 'center',
    bold = false,
    top = '',
    right = '',
    bottom = '',
    left = '',
    textOrientation = 0,
    wrap = false,
  ) => {
    return {
      fgColor: { rgb: fgColor },
      alignment: {
        horizontal: horizontalAlign,
        vertical: verticalAlign,
        textRotation: textOrientation,
        wrapText: wrap,
      },
      bold: bold,
      top: { style: top },
      right: { style: right },
      bottom: { style: bottom },
      left: { style: left },
    }
  }

  const generateGanttCells = (headers, data) => {
    let xlsData = []

    for (let i = 0; i < data.length; i++) {
      let rowData = [
        data[i].name,
        data[i].position,
        data[i].employment,
        data[i].coordinator,
        data[i].operator,
        data[i].rig,
        data[i].jobNum,
        data[i].wellName,
        {
          //only days column needs a (border) style applied
          v: `${data[i].days.length}`,
          t: 's',
          s: { alignment: { horizontal: 'right' }, right: { style: 'thin' } },
        },
        ...makeEmptyArray(headers.length - 8),
      ]

      for (let j = 0; j < data[i].days.length; j++) {
        let index = headers.findIndex((day) => day.v === data[i].days[j])
        if (index < 0) continue
        rowData[index] = {
          v: '',
          t: 's',
          s: getCellStyle('#CCFFCC', 'center', 'center', false, 'thin', 'thin', 'thin', 'thin'),
        }
      }
      xlsData.push(rowData)
    }

    return xlsData
  }

  const getStyledCellObject = (cellValue = '', isHeader = false, orientation = 0) => {
    if (typeof cellValue !== 'string') return ''

    let backgroundColor = '#FFFFFF'
    let bold = isHeader

    return {
      v: cellValue,
      t: 's',
      s: getCellStyle(backgroundColor, 'left', 'bottom', bold, 'thin', 'thin', 'thin', 'thin', orientation, true),
    }
  }

  const createXlsWorksheet = (data, selectedNames) => {
    if (!Array.isArray(data)) return null
    if (data?.length <= 0) return null
    if (!Array.isArray(selectedNames)) return null

    let personnelHeaders = [
      getStyledCellObject('Name', true),
      getStyledCellObject('Position', true),
      getStyledCellObject('Employment', true),
      getStyledCellObject('Coordinator', true),
      getStyledCellObject('Operator', true),
      getStyledCellObject('Rig', true),
      getStyledCellObject('Job #', true),
      getStyledCellObject('Wellname', true),
      getStyledCellObject('Days', true),
    ]
    let dateHeaders = new Set()

    let ganttData = []

    for (let i = 0; i < data.length; i++) {
      if (selectedNames.length > 0 && !selectedNames.includes(data[i]?.name)) continue
      if (data[i]?.hasOwnProperty('wells') && data[i]?.wells?.length > 0) {
        for (let j = 0; j < data[i].wells.length; j++) {
          if (
            data[i].wells[j].hasOwnProperty('days') &&
            Array.isArray(data[i].wells[j]?.days) &&
            data[i].wells[j]?.days?.length > 0
          ) {
            data[i].wells[j]?.days.forEach((day) => dateHeaders.add(day?.date))
          }

          ganttData.push({
            name: data[i]?.name,
            position: data[i]?.position || '',
            employment: data[i]?.employment || '',
            coordinator: data[i]?.wells[j]?.ddCoordinator || '',
            operator: data[i]?.wells[j]?.operator || '',
            rig: data[i]?.wells[j]?.rig || '',
            jobNum: data[i]?.wells[j]?.jobNum || '',
            wellName: data[i]?.wells[j]?.wellName || '',
            days: data[i].wells[j]?.days?.map((day) => day?.date),
          })
        }
      }
    }

    let sortedDateHeaders = sortDateHeaders([...dateHeaders])
    let headerData = [...personnelHeaders, ...sortedDateHeaders]

    let xlsData = generateGanttCells(headerData, ganttData)

    return { headerData, xlsData }
  }

  const handleXlsExport = (data, selectedNames) => {
    if (!Array.isArray(data)) return null
    if (data?.length <= 0) return null
    if (!Array.isArray(selectedNames)) return null

    data.sort((a, b) =>
      a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : 0,
    )

    let wb = XLSX.utils.book_new()

    const { headerData, xlsData } = createXlsWorksheet(data, selectedNames)

    let ws = XLSX.utils.aoa_to_sheet([[...headerData]], {
      origin: 'A1',
    })
    XLSX.utils.sheet_add_aoa(ws, xlsData, { origin: 'A2' })

    ws['!rows'] = [{ hpx: 85 }]

    ws['!cols'] = [...makeEmptyArray(headerData.length)]
    // column A
    ws['!cols'][0] = { wch: 20 }
    //column B
    ws['!cols'][1] = { wch: 15 }
    //column C
    ws['!cols'][2] = { wch: 15 }
    //column D
    ws['!cols'][3] = { wch: 15 }
    //column E
    ws['!cols'][4] = { wch: 25 }
    //column F
    ws['!cols'][5] = { wch: 25 }
    //column G
    ws['!cols'][6] = { wch: 25 }
    //column H
    ws['!cols'][7] = { wch: 30 }
    //column I
    ws['!cols'][8] = { wch: 10 }

    for (let i = 9; i < headerData.length; i++) {
      ws['!cols'][i] = { wch: 3 }
    }

    //add border around entire sheet
    let cellAddresses = ws['!ref'].split(':')
    XLSX.utils.sheet_set_range_style(ws, `A1:${cellAddresses[1]}`, {
      top: { style: 'thick' },
      right: { style: 'thick' },
      bottom: { style: 'thick' },
      left: { style: 'thick' },
    })

    XLSX.utils.book_append_sheet(wb, ws, 'Personnel')
    XLSX.writeFile(wb, getXlsFileName(), { cellStyles: true })
  }

  return {
    handleXlsExport,
  }
}

export default usePersonnelUtilizationXlsExport
