import React from 'react'

const FontFamilyCellRenderer = (props) => {
  return (
    <div>
      <div style={{ fontFamily: props.value, fontSize: '14px', lineHeight: '28px' }}>{props.value}</div>
    </div>
  )
}

export default FontFamilyCellRenderer
