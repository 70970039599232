import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InvoiceDetailsGrid = ({ invoiceData, handleUpdate, dropDowns }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const isAdding = useRef(false)
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.sequenceNum)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdateFunc(event.data)
      autoSizeColumns()
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const reqFields = useMemo(() => ['description', 'qty', 'value', 'allocation', 'taxRate'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const handleUpdateFunc = useCallback(
    async (data) => {
      if (!data) return
      let updatedRow = await handleUpdate({ action: 'update', data: data, recordId: invoiceData.recordId })
      if (!updatedRow) return

      gridApi.current.applyTransaction({
        update: [updatedRow],
      })
    },
    [handleUpdate, invoiceData],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdateFunc({ ...params.data })
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: invoiceData?.status === 'Draft',
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder, handleUpdateFunc, invoiceData])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('invoiceDetailsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('invoiceDetailsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InvoiceDetails.xlsx',
            sheetName: 'Details',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return

    let newRow = await handleUpdate({ action: 'add', data: inputRow.current, recordId: invoiceData.recordId })
    if (!newRow) return

    gridApi.current.applyTransaction({
      add: [newRow],
    })

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleDelete = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('sequenceNum')) return

    let rowToDelete = await handleUpdate({ action: 'delete', data: data, recordId: invoiceData.recordId })
    if (!rowToDelete) return

    gridApi.current.applyTransaction({
      remove: [rowToDelete],
    })
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const rightAlignedCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
    }
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 100,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        maxWidth: 600,
        wrapText: true,
        valueGetter: (params) => unescapeHtml(params?.data?.description),
      },
      {
        field: 'qty',
        colId: 'qty',
        headerName: 'Quantity',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -1000000,
          max: 10000000,
          precision: 0,
        },
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) {
            return 'Qty....'
          }
          return numberWithCommasDecimals(params?.value, 2)
        },
      },
      {
        field: 'value',
        colId: 'value',
        headerName: 'Value',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -1000000,
          max: 10000000,
          precision: 2,
        },
        cellStyle: rightAlignedCell,
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) {
            return 'Value....'
          }
          return numberWithCommasDecimals(params?.value, 2)
        },
      },
      {
        field: 'totalValue',
        colId: 'totalValue',
        headerName: 'Total Value',
        editable: false,
        cellStyle: rightAlignedCell,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (!params.data?.qty || !params.data?.value) return 0
          return numberWithCommasDecimals(params.data?.qty * params.data?.value, 2)
        },
      },
      {
        field: 'taxRate',
        colId: 'taxRate',
        headerName: 'Tax Rate(%)',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Tax...' : numberWithCommasDecimals(params?.value, 2)),
      },
      {
        field: 'totalTax',
        colId: 'totalTax',
        headerName: 'Total Tax',
        editable: false,
        cellStyle: rightAlignedCell,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (!params.data?.qty || !params.data?.value || !params.data?.taxRate) return 0
          return numberWithCommasDecimals(params.data?.qty * params.data?.value * (params.data?.taxRate / 100.0), 2)
        },
      },
      {
        field: 'total',
        colId: 'total',
        headerName: 'Line Total',
        editable: false,
        cellStyle: rightAlignedCell,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''
          if (!params.data?.qty || !params.data?.value) return 0
          let totalValue = params.data?.qty * params.data?.value
          let totalTax = totalValue * (params.data?.taxRate / 100.0)
          return numberWithCommasDecimals(totalValue + totalTax, 2)
        },
      },
      {
        field: 'allocation',
        colId: 'allocation',
        headerName: 'Allocation',
        cellStyle: centerAlignCell,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        editable: true,
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.revenueCategory) ? dropDowns.revenueCategory : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={Array.isArray(invoiceData?.invoiceDetails) ? invoiceData.invoiceDetails : []}
        columnDefs={sortColDefs(columnDefs, 'invoiceDetailsGrid')}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default InvoiceDetailsGrid
