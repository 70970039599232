import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const OperatorAntiCollissionSettingsGrid = ({ operatorData, operatorDropDownData, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateFunc = useCallback(
    async (data) => {
      if (!gridApi.current) return
      if (!operatorData) return
      if (!Array.isArray(data)) return

      let newData = cloneDeep(operatorData)
      for (let i = 0; i < data.length; i++) {
        if (newData.hasOwnProperty(data[i].tag)) {
          newData[data[i].tag] = data[i].value
        }

        if (!newData.hasOwnProperty('wptsData') || !newData.wptsData)
          newData.wptsData = { useWpts: false, surfaceMargin: 0, projectionUncert: 0 }
        if (data[i].tag === 'useWpts') {
          newData.wptsData.useWpts = data[i].value
        }

        if (data[i].tag === 'surfaceMargin') {
          newData.wptsData.surfaceMargin = data[i].value
        }

        if (data[i].tag === 'projectionUncert') {
          newData.wptsData.projectionUncert = data[i].value
        }

        if (!newData.hasOwnProperty('acAlerts') || !newData.acAlerts) {
          newData.acAlerts = { surfaceMargin: 0, coneOfExpansion: 5 }
        }

        if (data[i].tag === 'coneOfExpansion') {
          newData.acAlerts.coneOfExpansion = data[i].value
        }

        if (data[i].tag === 'minSep') {
          newData.acAlerts.surfaceMargin = data[i].value
        }
      }

      await handleUpdate(newData)
    },
    [operatorData, handleUpdate],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        minWidth: 350,
        cellDataType: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => {
          if (params.data?.tag !== 'incCasing') return true
          let incCasing = 'No'

          params.api.forEachNode((node) => {
            if (node.data?.tag === 'casing') incCasing = node.data?.value
          })

          return incCasing === 'Yes'
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agRichSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
              popup: true,
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agNumberCellEditor',
            params: {
              min: 0,
              max: 20,
              precision: 3,
            },
          }
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params.data?.type === 'bool') return params.value
          if (params.data?.type === 'number') return numberWithCommasDecimals(params.value, 3)
          return params.value
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index >= 0) gridData[index].value = params.newValue

          handleUpdateFunc(gridData)
        },
      },
    ],
    [handleUpdateFunc],
  )

  const getAntiCollisionData = (data) => {
    const { scanMeth, errorSurface, warningTypes, casingMeth, errorsStart } = operatorDropDownData
    const filteredWarningTypes = Array.isArray(warningTypes) ? warningTypes.filter((item) => !item.includes('C2C')) : []

    let anticollision = [
      {
        label: `Scan Method`,
        value: '',
        id: 0,
        type: 'dropDown',
        tag: `scanMeth`,
        dropDownValues: Array.isArray(scanMeth) ? scanMeth : [],
      },
      {
        label: `Error Surface`,
        value: '',
        id: 1,
        type: 'dropDown',
        tag: `errorSurface`,
        dropDownValues: Array.isArray(errorSurface) ? errorSurface : [],
      },
      {
        label: `Warning Type`,
        value: '',
        id: 2,
        type: 'dropDown',
        tag: `warningType`,
        dropDownValues: Array.isArray(filteredWarningTypes) ? filteredWarningTypes : [],
      },
      { label: `Errors Evaluated @ Sigma`, value: '', id: 3, type: 'number', tag: `errorOutput` },
      { label: `Errors Output @ Sigma`, value: '', id: 4, type: 'number', tag: `sigmaReports` },
      { label: `Include Casing`, value: '', id: 5, type: 'dropDown', tag: `casing`, dropDownValues: ['Yes', 'No'] },
      {
        label: `Casings Method`,
        value: '',
        id: 6,
        type: 'dropDown',
        tag: `incCasing`,
        dropDownValues: Array.isArray(casingMeth) ? casingMeth : [],
      },
      {
        label: `Errors Calculated From`,
        value: '',
        id: 7,
        type: 'dropDown',
        tag: `errorCalcFrom`,
        dropDownValues: Array.isArray(errorsStart) ? errorsStart : [],
      },
      { label: `TC Plot Safety Factor`, value: '', id: 8, type: 'number', tag: `tcSf` },
      { label: `Use WPTS Seperation Rule`, value: data?.wptsData?.useWpts, id: 9, type: 'bool', tag: `useWpts` },
      { label: `Surface Margin`, value: data?.wptsData?.surfaceMargin, id: 10, type: 'number', tag: `surfaceMargin` },
      {
        label: `Project Ahead Uncert`,
        value: data?.wptsData?.projectionUncert,
        id: 11,
        type: 'number',
        tag: `projectionUncert`,
      },
      {
        label: `Plus Cone of Expansion`,
        value: data?.acAlerts?.coneOfExpansion,
        id: 12,
        type: 'number',
        tag: `coneOfExpansion`,
      },
    ]

    if (!data) return anticollision
    for (let i = 0; i < anticollision.length; i++) {
      if (!data.hasOwnProperty(anticollision[i].tag)) continue
      if (!data[anticollision[i].tag]) continue
      anticollision[i].value = data[anticollision[i].tag]

      if (anticollision[i].tag === 'errorSurface' && anticollision[i].value === 'Elliptical Conic') {
        anticollision[i].value = 'Pedal Curve'
      }
    }

    if (!data.wptsData.useWpts) {
      anticollision.splice(10, 2)
      anticollision.push({
        label: `Minimum Separation`,
        value: data?.acAlerts?.surfaceMargin,
        id: 13,
        type: 'number',
        tag: `minSep`,
      })
    }

    return anticollision
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getAntiCollisionData(operatorData)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default OperatorAntiCollissionSettingsGrid
