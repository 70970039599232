import { getItemFromLS } from 'utils/localStorage'
import { escapeHtml, unescapeHtml } from 'utils/htmlSymbolHandling'
import { CircularProgress, Box } from '@mui/material'
import { uuidv4 } from 'utils/stringFunctions'

function sortDefs(colDef, colLayout) {
  if (!Array.isArray(colDef) || !Array.isArray(colLayout)) return

  const colIdIndexMap = {}
  colLayout.forEach((obj, index) => {
    colIdIndexMap[obj.colId] = index
  })

  colDef.sort((a, b) => {
    const indexA = colIdIndexMap[a.colId]
    const indexB = colIdIndexMap[b.colId]

    if (indexA === undefined) return 1
    if (indexB === undefined) return 1

    return indexA - indexB
  })

  for (let i = 0; i < colDef.length; i++) {
    let index = colLayout.findIndex((elem) => elem.colId === colDef[i].colId)
    if (index >= 0 && colLayout[index].hide) {
      colDef[i].hide = true
    }
  }
}

export const sortColDefs = (colDefs, localStorageName) => {
  const colLayout = getItemFromLS(localStorageName, 'colLayout')
  if (!colLayout) return colDefs
  if (!Array.isArray(colLayout)) return colDefs

  sortDefs(colDefs, colLayout)

  for (let i = 0; i < colDefs.length; i++) {
    if (Array.isArray(colDefs[i]?.children)) {
      sortDefs(colDefs[i].children, colLayout)
    }
  }

  return colDefs
}

export const htmlSymbolHandling = (data) => {
  if (!data) return null
  if (typeof data !== 'object') return data

  let output = {}

  for (const [key, value] of Object.entries(data)) {
    let newValue = value
    if (typeof value === 'string') {
      newValue = unescapeHtml(value)
      newValue = escapeHtml(newValue)
    }

    output[key] = newValue
  }

  return output
}

export const showHideDetailGrid = (
  params,
  uidField,
  allowMultiNodes = false,
  setShowChart = null,
  chartVisible = false,
) => {
  if (!params) return
  if (!uidField) return
  if (typeof uidField !== 'string') return
  if (!params?.data) return
  if (!params.data?.hasOwnProperty(uidField)) return

  let nodesToChange = []
  let numNodesExpanded = 0

  params.api.forEachNode((node) => {
    if (node.data[uidField] !== params.data[uidField] && node.expanded && !allowMultiNodes) nodesToChange.push(node)
    if (node.data[uidField] !== params.data[uidField] && node.expanded && allowMultiNodes) numNodesExpanded++

    if (node.data[uidField] === params.data[uidField]) {
      if (node.expanded) nodesToChange.push(node)
      if (!node.expanded) {
        nodesToChange.push(node)
        numNodesExpanded++
      }
    }
  })

  if (chartVisible && numNodesExpanded > 0) {
    if (setShowChart) setShowChart(false)

    setTimeout(() => {
      for (let i = 0; i < nodesToChange.length; i++) {
        params.api.setRowNodeExpanded(nodesToChange[i], !nodesToChange[i].expanded)
      }
    }, 500)
  }

  if (!chartVisible && numNodesExpanded === 0) {
    for (let i = 0; i < nodesToChange.length; i++) {
      params.api.setRowNodeExpanded(nodesToChange[i], !nodesToChange[i].expanded)
    }

    if (setShowChart) {
      setTimeout(() => {
        setShowChart(true)
      }, 500)
    }
  }

  if (!chartVisible && numNodesExpanded > 0) {
    for (let i = 0; i < nodesToChange.length; i++) {
      params.api.setRowNodeExpanded(nodesToChange[i], !nodesToChange[i].expanded)
    }

    params.api.redrawRows({ rowNodes: nodesToChange })
  }
}

export const isValidDate = (value) => {
  return value instanceof Date || !isNaN(Date.parse(value))
}

export const relativeTime = (isoDateTime) => {
  if (!isValidDate(isoDateTime)) return ''
  const targetTime = new Date(Date.parse(isoDateTime))
  const now = new Date()
  const timeDiffInMs = now - targetTime
  const timeMins = Math.floor(timeDiffInMs / (1000 * 60))

  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: 'auto',
  })

  const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ]

  let duration = timeMins * -60

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export const relativeTimeMins = (timeMins) => {
  if (timeMins === null || timeMins === undefined) return ''
  if (typeof timeMins === 'string' && timeMins === '') return ''
  if (typeof timeMins === 'string') timeMins = parseFloat(timeMins)

  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: 'auto',
  })

  const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' },
  ]

  let duration = timeMins * -60

  for (let i = 0; i < DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export const CustomLoadingOverlay = () => {
  return (
    <CircularProgress
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  )
}

export const isDateLessThan = (date1, date2) => {
  const time1 = new Date(date1).getTime()
  const time2 = new Date(date2).getTime()

  return time1 < time2
}

export const dateComparator = (filterDate, cellDate) => {
  if (!isValidDate(cellDate)) return 0

  const parsedDate = new Date(Date.parse(cellDate))
  const localMidnight = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate())

  const parsedFilterDate = new Date(Date.UTC(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate()))
  parsedFilterDate.setHours(0, 0, 0, 0)

  if (localMidnight.getTime() === parsedFilterDate.getTime()) return 0
  return localMidnight.getTime() < filterDate.getTime() ? -1 : 1
}

export const getStringId = (id) => {
  if (id === undefined || id === null) {
    return uuidv4()
  }
  return String(id)
}

export const CustomHeader = (props) => {
  return (
    <Box
      style={{
        padding: '2px',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Box style={{ flex: 1 }}>{props.displayName}</Box>
      <Box style={{ flex: 1, fontWeight: 400 }}>{props?.units ? `(${props.units})` : null}</Box>
    </Box>
  )
}
