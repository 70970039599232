import { useEffect, useRef, useState } from 'react'
import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import { cloneDeep } from 'lodash'

function useMud() {
  const _isMounted = useRef(true)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const [isLoading, setIsLoading] = useState(false)
  const mudData = useRef(null)
  const isUpdating = useRef(false)

  const getDailyReport = useInnovaAxios({
    url: '/well/dailyReports/getReportHeaders',
  })

  const updateMud = useInnovaAxios({
    url: '/well/dailyReports/updateMudData',
  })

  useEffect(() => {
    if (!currentWell || !_isMounted.current) return
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleMudData = (mudData, dailyReportId) => {
    if (!mudData) return []
    let categories = [
      { tag: 'fluidType', label: 'Type', value: mudData.fluidType, dailyReportId },
      { tag: 'sixHun', label: '600', value: mudData.sixHun, dailyReportId },
      { tag: 'threeHun', label: '300', value: mudData.threeHun, dailyReportId },
      { tag: 'twoHun', label: '200', value: mudData.twoHun, dailyReportId },
      { tag: 'oneHun', label: '100', value: mudData.oneHun, dailyReportId },
      { tag: 'sixDial', label: '6', value: mudData.sixDial, dailyReportId },
      { tag: 'threeDial', label: '3', value: mudData.threeDial, dailyReportId },
      { tag: 'pv', label: 'PV', value: mudData.pv, dailyReportId },
      { tag: 'yp', label: 'YP', value: mudData.yp, dailyReportId },
      { tag: 'mudWeight', label: 'Weight (ppg)', value: mudData.mudWeight, dailyReportId },
      { tag: 'viscosity', label: 'Viscosity', value: mudData.viscosity, dailyReportId },
      { tag: 'fluidTemp', label: 'Temp (degF)', value: mudData.fluidTemp, dailyReportId },
      { tag: 'gel', label: '10s Gel', value: mudData.gel, dailyReportId },
      { tag: 'solids', label: 'Solids %', value: mudData.solids, dailyReportId },
      { tag: 'sand', label: 'Sand %', value: mudData.sand, dailyReportId },
      { tag: 'chlorides', label: 'Chlorides', value: mudData.chlorides, dailyReportId },
      { tag: 'fluidLoss', label: 'API Fluid Loss', value: mudData.fluidLoss, dailyReportId },
      { tag: 'ph', label: 'PH', value: mudData.ph, dailyReportId },
      { tag: 'oilWaterRatio', label: 'Oil/Water', value: mudData.oilWaterRatio, dailyReportId },
      { tag: 'lgs', label: 'LGS', value: mudData.lgs, dailyReportId },
      { tag: 'mf', label: 'Gas %', value: mudData.mf, dailyReportId },
    ]

    return categories
  }

  const fetchMudData = async (dailyReportId) => {
    if (dailyReportId === null || dailyReportId === undefined) return []
    if (typeof dailyReportId !== 'number') return []
    if (typeof currentWellRef.current !== 'string') return []
    if (currentWellRef.current === '') return []
    if (isLoading) return []

    if (_isMounted.current) setIsLoading(true)
    const response = await getDailyReport({ dailyReportId: dailyReportId, wellName: currentWellRef.current })
    if (_isMounted.current) setIsLoading(false)

    if (!response?.data) return []
    if (!response.data.hasOwnProperty('dailyReports')) return []
    if (response.data?.dailyReports?.length === 0) return []

    mudData.current = response.data.dailyReports[0]?.mudData
    return handleMudData(mudData.current, dailyReportId)
  }

  const handleUpdateMud = async (newData) => {
    if (typeof currentWellRef.current !== 'string') return { isError: true, message: 'no current well' }
    if (currentWellRef.current === '') return { isError: true, message: 'no current well' }
    if (isUpdating.current) return { isError: true, message: 'update in progress' }
    if (!newData) return { isError: true, message: 'no data' }
    if (!newData.hasOwnProperty('tag')) return { isError: true, message: 'no tag' }
    if (!mudData.current) return { isError: true, message: 'no category data' }

    mudData.current[newData.tag] = newData.value
    let newMudData = cloneDeep(mudData.current)
    isUpdating.current = true
    const response = await updateMud({
      dailyReportId: newData.dailyReportId,
      wellName: currentWellRef.current,
      ...newMudData,
    })
    isUpdating.current = false

    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  return {
    fetchMudData,
    handleUpdateMud,
    isLoading,
  }
}

export default useMud
