import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { debounce } from 'lodash'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import { cloneDeep } from 'lodash'
import ConfirmDialog from 'components/common/ConfirmDialog'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const DatabaseBackupFilesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const selectedFile = useRef(null)
  const isDeleting = useRef(false)
  const isRestoring = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [fileNames, setFileNames] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showRestoreDatabaseModal, setShowRestoreDatabaseModal] = useState(false)
  const [orgList, setOrgList] = useState([])
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getBackupFiles = useInnovaAxios({
    url: '/admin/database/getBackupFileNames',
  })

  const deleteDatabaseBackup = useInnovaAxios({
    url: '/admin/database/deleteDatabaseBackup',
  })

  const restoreDatabaseBackup = useInnovaAxios({
    url: '/admin/database/restoreDatabase',
  })

  const getOrgData = useInnovaAxios({
    url: '/admin/getOrgListByUserAuthType',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.daqtabaseBackupFilesPageKey)
    fetchBackupFileData()
    fetchOrganizationsList()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchOrganizationsList = async () => {
    const response = await getOrgData()
    if (response.error && _isMounted.current) {
      return
    }

    if (_isMounted.current && Array.isArray(response.data)) {
      let orgs = []
      for (let i = 0; i < response.data.length; i++) {
        orgs.push({ label: response.data[i].organization, value: response.data[i].organization })
      }

      orgs.sort((firstEl, secondEl) => firstEl.label.localeCompare(secondEl.label))
      setOrgList(orgs)
    }
  }

  const fetchBackupFileData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getBackupFiles()

    if (response?.error) return
    if (!_isMounted.current) return

    if (!Array.isArray(response.data)) return

    let files = []
    for (let i = 0; i < response.data.length; i++) {
      files.push({ uid: i, fileName: response.data[i] })
    }

    setFileNames(files)
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      wrapText: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('databaseBackupFilesGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('databaseBackupFilesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'BackupFiles.xlsx',
            sheetName: 'Files',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedFile.current) return
    if (!selectedFile.current.hasOwnProperty('fileName')) return

    if (isDeleting.current) return
    isDeleting.current = true

    let res = await deleteDatabaseBackup(selectedFile.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedFile.current],
      })
    }

    selectedFile.current = null
  }, [deleteDatabaseBackup])

  const handleRestore = async (data) => {
    setShowRestoreDatabaseModal(false)

    if (!data) return
    if (!data.hasOwnProperty('organization')) return

    if (isRestoring.current) return
    isRestoring.current = true

    let res = await restoreDatabaseBackup(data)
    isRestoring.current = false

    setStatus({
      show: true,
      severity: res?.error ? 'error' : 'success',
      message: res?.error ? `${res?.error?.response?.data?.error}` : 'Restrore started',
    })
  }

  const onClickDelete = useCallback(
    (data) => {
      selectedFile.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete backup',
        text: `Are you sure you want to delete config for ${data.fileName}?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const onClickRestoreDatabase = useCallback((data) => {
    selectedFile.current = cloneDeep(data)
    setShowRestoreDatabaseModal(true)
  }, [])

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'restore') {
        onClickRestoreDatabase(data)
      }
    },
    [onClickDelete, onClickRestoreDatabase],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Restore',
                action: 'restore',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledPopupMenuIcon icon='eos-icons:database' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'fileName',
        colId: 'fileName',
        headerName: 'Filename',
      },
    ],
    [centerAlignCell, handleMenuClick],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const RestoreDatabaseModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({})

    let formData = [
      {
        tag: 'backupName',
        value: selectedFile.current.fileName,
        inputType: '-',
      },
      {
        tag: 'organization',
        value: 'INNOVA',
        text: 'Organization',
        inputType: 'dropdown',
        dropDownValues: orgList,
        useLabel: true,
      },
      {
        tag: 'restoreDbName',
        value: 'restoredDatabase',
        text: 'Restored database name',
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={showRestoreDatabaseModal}
        onClose={cancelFunction}
        title={'Restore Options'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showRestoreDatabaseModal ? (
        <RestoreDatabaseModal
          data={null}
          submitFunction={handleRestore}
          cancelFunction={() => {
            setShowRestoreDatabaseModal(false)
          }}
        />
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={fileNames}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'databaseBackupFilesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchBackupFileData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DatabaseBackupFilesPage
