import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import * as jszip from 'jszip'
import { currentAwsRegionAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import Alert from '@mui/material/Alert'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import moment from 'moment'
import { Icon as Iconify } from '@iconify/react'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const IcdsLogGrid = ({ icdsName, height }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const [logData, setLogData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const currentAwsRegion = useRecoilValue(currentAwsRegionAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [resetCols, setResetCols] = useState(false)

  const getLogData = useInnovaAxios({
    url: '/admin/logs/icds',
    responseType: 'blob', // critically important to properly retrieve the zip file
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    fetchLogData()
  }, [currentAwsRegion]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLogData = async () => {
    if (!icdsName) return
    if (typeof icdsName !== 'string') return
    if (icdsName === '') return

    if (typeof currentAwsRegion !== 'string') return
    if (currentAwsRegion === '') return
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const response = await getLogData({
      params: 'last',
      icdsInstanceName: icdsName,
      awsRegion: currentAwsRegion,
    })

    if (response.error && _isMounted.current) {
      setStatus({ show: true, severity: 'error', message: `${response?.error?.response?.data?.error}` })
      setIsLoading(false)
      return
    }

    if (response?.data) {
      // process zip file
      let blobData = new Blob([response.data])
      let zip = await jszip.loadAsync(blobData, { base64: true })
      let jsonData = await zip.files['jsonLog.json'].async('string')
      let parsedData = JSON.parse(jsonData.replace(/\0/g,''))
      if (Array.isArray(parsedData)) {
        let fieldsToCheck = ['cat', 'err', 'func', 'level', 'stacktrace', 'type']
        for (let i = 0; i < parsedData.length; i++) {
          for (let j = 0; j < fieldsToCheck.length; j++) {
            if (!parsedData[i].hasOwnProperty(fieldsToCheck[j])) {
              parsedData[i][fieldsToCheck[j]] = ''
              continue
            }

            if (typeof parsedData[i][fieldsToCheck[j]] !== 'string') {
              parsedData[i][fieldsToCheck[j]] = ''
              continue
            }
          }
        }
      }

      if (_isMounted.current) setLogData(parsedData)
    }

    if (_isMounted.current) {
      setIsLoading(false)
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeColumns(['timestamp', 'caller', 'msg', 'rowNum', 'level'])
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('icdsLogsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('icdsLogsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'IcdsLogs.xlsx',
            sheetName: 'IcdsLogs',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ];
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const formatTimestamp = (ts) => {
    let timeString = moment(ts).format('ddd, MMM DD, YYYY, hh:mm:ss')
    let timeSince = moment(timeString).fromNow(true)
    return `${timeString} [${timeSince}]`
  }

  let columnDefs = useMemo(
    () => [
      {
        colId: 'rowNum',
        headerName: '#',
        valueGetter: (params) => params.node.rowIndex + 1,
        cellStyle: centerAlignCell,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'level',
        colId: 'level',
        headerName: 'Level',
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          let icon = 'carbon:unknown-filled'
          let color = 'gray'

          switch (params.data?.level) {
            case 'info':
              icon = 'bi:info-circle-fill'
              color = 'blue'
              break
            case 'error':
              icon = 'codicon:error'
              color = 'red'
              break
            case 'warn':
              icon = 'clarity:warning-solid'
              color = 'yellow'
              break
            default:
              icon = 'carbon:unknown-filled'
              color = 'gray'
              break
          }

          return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
              <Iconify icon={icon} width='25' height='25' color={color} />
            </Box>
          )
        },
      },
      {
        field: 'timestamp',
        colId: 'timestamp',
        headerName: 'Time',
        valueFormatter: (params) => formatTimestamp(params.data?.timestamp),
      },
      {
        field: 'caller',
        colId: 'caller',
        headerName: 'Caller',
      },
      {
        field: 'stacktrace',
        colId: 'stacktrace',
        headerName: 'Stack Trace',
        width: 200,
      },
      {
        field: 'msg',
        colId: 'msg',
        headerName: 'Message',
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <React.Fragment>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchText) => setSearchText(newSearchText)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: `${height - 64}px` }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={logData}
            columnDefs={sortColDefs(columnDefs, 'icdsLogsGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default IcdsLogGrid
