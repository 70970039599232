import React, { useRef, useMemo, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getComponentColor } from 'components/common/drillStringFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInventory from 'components/common/hooks/useInventory'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InventoryCatalog = ({ wellName }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { loading: isLoading, fetchInventoryData } = useInventory(wellName)
  const [searchText, setSearchText] = useState('')

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('inventoryCatalogGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('inventoryCatalogGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InventoryData.xlsx',
            sheetName: 'Inventory',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const columnDefs = [
    {
      field: 'uid',
      colId: 'uid',
      headerName: '',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      resizable: false,
      filter: null,
      width: 30,
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: centerAlignCell,
      dndSource: true,
      valueFormatter: (params) => '',
    },
    {
      headerName: 'SN',
      colId: 'serialNum',
      field: 'serialNum',
      pinned: 'left',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.serialNum),
    },
    {
      headerName: 'Description',
      colId: 'description',
      field: 'description',
      pinned: 'left',
      cellStyle: { alignItem: 'end' },
      valueGetter: (params) => unescapeHtml(params.data?.description),
    },
    {
      headerName: 'Tool Type',
      colId: 'toolType',
      field: 'toolType',
      pinned: 'left',
      cellStyle: (params) => {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: getComponentColor(params.value),
        }
      },
    },
    {
      headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'od',
      field: 'od',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.od, 3)}`
      },
    },
    {
      headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'id',
      field: 'id',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.id, 3)}`
      },
    },
    {
      headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'length',
      field: 'length',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.length, 2)}`
      },
    },
    {
      headerName: 'Cnx Top',
      colId: 'connectionTop',
      field: 'connectionTop',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.connectionTop),
    },
    {
      headerName: 'Cnx Bottom',
      colId: 'connectionBottom',
      field: 'connectionBottom',
      cellStyle: centerAlignCell,
      valueGetter: (params) => unescapeHtml(params.data?.connectionBottom),
    },
    {
      headerName: 'On Location',
      colId: 'onLocation',
      field: 'onLocation',
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'Failed',
      colId: 'failed',
      field: 'failed',
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'In Hole',
      colId: 'inHole',
      field: 'inHole',
      editable: false,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'Dirty',
      colId: 'dirty',
      field: 'dirty',
      valueGetter: (params) => params.data?.totalToolHours > 0,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
  ]

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridDragOver = (event) => {
    const dragSupported = event.dataTransfer.types.length

    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }} onDragOver={gridDragOver}>
        <AgGridReact
          loading={isLoading}
          rowData={fetchInventoryData(false)}
          columnDefs={sortColDefs(columnDefs, 'inventoryCatalogGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          rowDragManaged={true}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}

export default InventoryCatalog
