import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, currentWellDetailsAtom, userUserRoleAtom } from 'atoms'
import { getRandomColor } from 'utils/colorFunctions'
import { appColors, chartSeriesColors } from 'utils'
import { cloneDeep } from 'lodash'
import { round, numberToString } from 'utils/numberFunctions'
import useOrgIcons from './useOrgIcons'
import { base64ToArrayBuffer, removeSpecialSymbols } from 'utils/stringFunctions'
import PdfDocument from 'components/common/PDFGen/PdfDocument'

function useDailyReports() {
  const _isMounted = useRef(true)
  const dailyReportData = useRef([])
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const currentWellDetails = useRecoilValue(currentWellDetailsAtom)
  const currentOperatorRef = useRef(currentWellDetails)
  const dropDownDataRaw = useRef([])
  const isDropDownsLoading = useRef(false)
  const currRunningTotalCost = useRef(0)
  const [wellInfo, setWellInfo] = useState()
  const { getCurrentOrgIcon } = useOrgIcons()
  const userRole = useRecoilValue(userUserRoleAtom)

  const lat = round(currentWellDetails.lat, 3)
  const lng = round(currentWellDetails.lng, 3)

  const getWellInfo = useInnovaAxios({
    url: '/well/wellInfo/getWellInfo',
  })

  const getDailyReports = useInnovaAxios({
    url: '/well/dailyReports/getReportHeaders',
  })

  const getActivityDropDowns = useInnovaAxios({
    url: '/well/dailyReports/getDropDownLists',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    currentOperatorRef.current = currentWellDetails
    fetchDailyReports()
    fetchDropDownDataRaw()
    fetchWellInfo()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDailyReports = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getDailyReports({ getActivity: true, wellName: currentWellRef.current })

    if (_isMounted.current) {
      dailyReportData.current = response?.data ? response.data : null
      setLoading(false)
    }

    isLoading.current = false
  }

  const fetchDailyReport = async (reportId) => {
    if (typeof reportId !== 'number') return null
    if (typeof currentWellRef.current !== 'string') return null
    if (currentWellRef.current === '') return null
    if (isLoading.current) return null
    isLoading.current = true

    const response = await getDailyReports({
      getActivity: true,
      wellName: currentWellRef.current,
      dailyReportId: reportId,
    })
    isLoading.current = false

    if (response.error) return null
    if (!response.data) return null
    if (!response.data.hasOwnProperty('dailyReports')) return null
    if (!Array.isArray(response.data.dailyReports)) return null
    if (response.data.dailyReports.length === 0) return null
    return response.data.dailyReports[0]
  }

  const fetchDropDownDataRaw = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isDropDownsLoading.current) return
    isDropDownsLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getActivityDropDowns({ wellName: currentWellRef.current })
    if (_isMounted.current) {
      dropDownDataRaw.current = response?.data ? response.data : null
    }

    isDropDownsLoading.current = false
  }

  const fetchWellInfo = async () => {
    if (currentWell?.length < 1) return
    const payload = {
      wellName: currentWell,
    }
    const response = await getWellInfo(payload)
    if (_isMounted.current && response?.data) {
      setWellInfo(response.data)
    }
  }

  const getActivityColor = (code, idx) => {
    if (idx < 0) return getRandomColor()
    if (code === '2J') return appColors.slideColor
    if (code === '2K') return appColors.rotateColor
    if (idx < chartSeriesColors.length) return chartSeriesColors[idx]
    return getRandomColor()
  }

  const getDropDowns = () => {
    let newDropDowns = {
      activityCodes: [],
      bhaCodes: [],
      phaseCodes: [],
      personnelNames: [],
      driveType: [],
      mwdType: [],
    }

    if (!dropDownDataRaw.current) return newDropDowns
    if (!dropDownDataRaw.current.hasOwnProperty('activityCodes')) return newDropDowns
    if (!dropDownDataRaw.current.hasOwnProperty('bha')) return newDropDowns
    if (!dropDownDataRaw.current.hasOwnProperty('phaseCodes')) return newDropDowns
    if (!Array.isArray(dropDownDataRaw.current.activityCodes))
      return newDropDowns
    const { bha, phaseCodes, personnelNames, driveType, mwdType } = dropDownDataRaw.current
    for (let i = 0; i < dropDownDataRaw.current.activityCodes.length; i++) {
      newDropDowns.activityCodes.push({
        ...dropDownDataRaw.current.activityCodes[i],
        color: getActivityColor(dropDownDataRaw.current.activityCodes[i].code, i),
      })
    }

    let bhasArr = cloneDeep(bha)
    bhasArr.push({ description: 'none', bhaNum: '-1', bhaNumRep: '-1' })
    newDropDowns.bhaCodes = bhasArr

    newDropDowns.phaseCodes = phaseCodes
    newDropDowns.personnelNames = personnelNames
    newDropDowns.driveType = driveType
    newDropDowns.mwdType = mwdType
    return newDropDowns
  }

  const sortDate = (a, b) => {
    if (typeof a?.date !== 'string') return -1
    if (typeof b?.date !== 'string') return -1
    let d1 = new Date(Date.parse(a.date + 'T00:00:01'))
    let d2 = new Date(Date.parse(b.date + 'T00:00:01'))
    return d1 < d2 ? 1 : d1 > d2 ? -1 : 0
  }

  const fetchDailyReportData = () => {
    if (!dailyReportData.current) return {}
    if (!dailyReportData.current.hasOwnProperty('dailyReports')) return {}
    if (!Array.isArray(dailyReportData.current.dailyReports)) return {}

    let sortedData = cloneDeep(dailyReportData.current)

    const { activityCodes } = getDropDowns()
    currRunningTotalCost.current = 0
    for (let i = 0; i < sortedData.dailyReports.length; i++) {
      if (!Array.isArray(sortedData.dailyReports[i].dailyActivity)) continue
      if (sortedData.dailyReports[i].dailyActivity.length === 0) continue

      sortedData.dailyReports[i].dailyActivity.sort((a, b) => a.sequenceNo - b.sequenceNo)

      for (let j = 0; j < sortedData.dailyReports[i].dailyActivity.length; j++) {
        let foundActivity = activityCodes.find(
          (activity) => activity.code === sortedData.dailyReports[i].dailyActivity[j].activity,
        )
        sortedData.dailyReports[i].dailyActivity[j].color = foundActivity ? foundActivity.color : getRandomColor()
      }

      sortedData.dailyReports[i].activitySummary = []

      const activityMap = new Map()
      for (const obj of sortedData.dailyReports[i].dailyActivity) {
        const { activity, hours, activityCodeDesc, color } = obj

        if (activityMap.has(activity)) {
          activityMap.get(activity).hours += hours
        } else {
          activityMap.set(activity, { activityCode: activity, hours, activityCodeDesc, color })
        }
      }
      const result = []
      // eslint-disable-next-line
      for (const [activity, data] of activityMap) {
        result.push({ ...data })
      }
      sortedData.dailyReports[i].activitySummary = result
      currRunningTotalCost.current += sortedData.dailyReports[i].dailyCost
      sortedData.dailyReports[i].runningTotal = currRunningTotalCost.current
    }
    sortedData.dailyReports.sort(sortDate)
    return sortedData
  }

  const getPdfData = async (reportIds) => {
    if (!Array.isArray(reportIds)) return null
    await fetchDailyReports()

    if (!wellInfo || !dailyReportData.current) return null
    if (!dailyReportData.current.hasOwnProperty('dailyReports')) return null
    if (!Array.isArray(dailyReportData.current?.dailyReports)) return null

    let pdfData = reportIds.map((id) => generatePdfDocument(id, dailyReportData.current))

    return ({
      fileName: `Daily Report - ${removeSpecialSymbols(currentWell)}`,
      data: <PdfDocument data={pdfData} multiDocument={true} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings} />
    })
  }

  const parseDate = (date) => {
    if (!date) return ''
    if (typeof date !== 'string') return ''
    date += 'T23:59:00'
    return new Date(Date.parse(date)).toLocaleDateString('en-US', { dateStyle: 'short' })
  }

  const getActivityCodeDescription = (code) => {
    if (!dropDownDataRaw.current.hasOwnProperty('activityCodes')) return ''
    if (!Array.isArray(dropDownDataRaw.current.activityCodes)) return ''

    let activityCode = dropDownDataRaw.current.activityCodes.find((activity) => activity.code === code)
    if (!activityCode) return ''
    return activityCode.description
  }

  const addActivities = (dailyActivity, date) => {
    if (!dailyActivity) return []
    if (!Array.isArray(dailyActivity)) return []

    let activities = []
    activities.push(
      [{ text: 'Daily Activity', isHeader: true, columnSpan: 11 }],
      [
        { text: 'Date', isHeader: true },
        { text: 'From', isHeader: true },
        { text: `To`, isHeader: true },
        { text: `Hrs`, isHeader: true },
        { text: 'Start (USft)', isHeader: true },
        { text: 'End (Usft)', isHeader: true },
        { text: 'CL', isHeader: true },
        { text: 'Phase', isHeader: true },
        { text: 'BHA', isHeader: true },
        { text: 'Code', isHeader: true },
        { text: 'Description', isHeader: true },
      ],
    )
    for (let i = 0; i < dailyActivity.length; i++) {
      activities.push([
        { text: parseDate(date) },
        { text: dailyActivity[i].startTime },
        { text: dailyActivity[i].endTime },
        { text: numberToString(dailyActivity[i].hours, 2) },
        { text: numberToString(dailyActivity[i].startDepth, 2) },
        { text: numberToString(dailyActivity[i].endDepth, 2) },
        { text: numberToString(dailyActivity[i].cl, 2) },
        { text: dailyActivity[i].phase },
        { text: dailyActivity[i].bhaNumRep < 0 ? '' : `${dailyActivity[i].bhaNumRep}:${dailyActivity[i].bhaDesc}` },
        {
          text: `${dailyActivity[i].activity}: ${getActivityCodeDescription(dailyActivity[i].activity)}`
        },
        { text: dailyActivity[i].description, textAlign: 'left' },
      ])
    }
    return activities
  }

  const getReportTime = (dailyActivity) => {
    if (!dailyActivity || !Array.isArray(dailyActivity) || dailyActivity.length === 0) return ''
    return `${dailyActivity[0].startTime} - ${dailyActivity[dailyActivity.length - 1].endTime}`
  }

  const generatePdfDocument = (reportId, pdfReportData) => {
    if (reportId === null || reportId === undefined) return null
    const { dailyReports } = pdfReportData
    let reportIndex = dailyReports.findIndex((daily) => daily.recordId === reportId)
    if (reportIndex < 0) return null

    const { dailyStats } = dailyReports[reportIndex]
    let bhaIndex =
      Array.isArray(dailyStats.bhaStats) && dailyStats.bhaStats.length > 0 ? dailyStats.bhaStats.length - 1 : -1
    let currentBha = bhaIndex >= 0 ? dailyStats.bhaStats[bhaIndex] : null

    const { mudData, dailyDrillingParams: drillingParams, casingData, personnelData } = dailyReports[reportIndex]

    let dailyBhaStr = ''
    if (bhaIndex >= 0) {
      dailyStats.bhaStats.forEach((bha) => {
        if (dailyBhaStr !== '') dailyBhaStr += ','
        dailyBhaStr += bha.bhaNumRep
      })
    }

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: `${wellInfo.operator} / ${currentWell} - Daily Drilling Report ${dailyReports[reportIndex].date}`,
        showLogo: true,
        logo: base64ToArrayBuffer(getCurrentOrgIcon()),
        logoWidth: '40%',
        columnWidths: ['16%', '16%', '12.5%', '55.5%'],
        fontSize: 6,
        data: [
          [
            { text: 'Job#', isHeader: true },
            { text: wellInfo.jobNum, textAlign: 'left' },
            { text: 'Operator', isHeader: true },
            { text: wellInfo.operator, textAlign: 'left' },
          ],
          [
            { text: 'API Job#/AFE#', isHeader: true },
            { text: wellInfo.apiNum + ' / ' + wellInfo.afeNum, textAlign: 'left' },
            { text: 'Field', isHeader: true },
            { text: wellInfo.field, textAlign: 'left' },
          ],
          [
            { text: 'Report #', isHeader: true },
            { text: dailyReports[reportIndex].repNum, textAlign: 'left' },
            { text: 'Well', isHeader: true },
            { text: wellInfo.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Latitude', isHeader: true },
            { text: lat, textAlign: 'left' },
            { text: 'Longitude', isHeader: true },
            { text: lng, textAlign: 'left' },
          ],
          [
            { text: 'Rig#', isHeader: true },
            { text: wellInfo.rig, textAlign: 'left' },
            { text: 'County/State', isHeader: true },
            { text: wellInfo.county + '/' + wellInfo.state, textAlign: 'left' },
          ],
          [
            { text: 'Date', isHeader: true },
            { text: parseDate(dailyReports[reportIndex].date), textAlign: 'left' },
            { text: 'Report Time', isHeader: true },
            { text: getReportTime(dailyReports[reportIndex].dailyActivity), textAlign: 'left' },
          ],
          [
            { text: 'Daily Costs($)', isHeader: true },
            { text: numberToString(dailyReports[reportIndex].dailyCost, 2, true), textAlign: 'left' },
            { text: 'Well Costs($)', isHeader: true },
            { text: numberToString(dailyReports[reportIndex].wellCost, 2, true), textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [{ text: '24Hr Summary', isHeader: true }],
          [{ text: dailyReports[reportIndex].summary, textAlign: 'left' }],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [{ text: '24Hr Lookahead', isHeader: true }],
          [{ text: dailyReports[reportIndex].lookahead, textAlign: 'left' }],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['18%', '13%', '13%', '12%', '16%', '6%', '6%', '10%', '6%'],
        data: [
          [
            { text: 'Drilling Summary', isHeader: true },
            { text: `24Hr (BHA ${dailyBhaStr})`, isHeader: true },
            { text: `Run (BHA ${bhaIndex >= 0 ? currentBha.bhaNumRep : ''})`, isHeader: true },
            { text: 'Casing Data', isHeader: true, columnSpan: 2 },
            { text: 'Fluids', isHeader: true, columnSpan: 4 },
          ],
          [
            { text: 'Start Depth (Usft)', isBold: true },
            { text: numberToString(dailyStats.startDepth, 2, false) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.startDepth, 2, true) : '' },
            { text: 'Csg Shoe MD(Usft)', isBold: true },
            { text: numberToString(casingData.casingMd, 2, true) },
            { text: '600', isBold: true },
            { text: numberToString(mudData.sixHun, 0, true) },
            { text: '%Sand', isBold: true },
            { text: numberToString(mudData.sand, 2, true) },
          ],
          [
            { text: 'End Depth (Usft)', isBold: true },
            { text: numberToString(dailyStats.endDepth, 2, false) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.endDepth, 2, true) : '' },
            { text: 'OD (in)', isBold: true },
            { text: numberToString(casingData.casingOd, 3, true) },
            { text: '300', isBold: true },
            { text: numberToString(mudData.threeHun, 0, true) },
            { text: '%Solids', isBold: true },
            { text: numberToString(mudData.solids, 2, true) },
          ],
          [
            { text: 'Total Drilled (Usft)', isBold: true },
            { text: numberToString(dailyStats.totalDrilled, 2, true) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.drilled, 2, true) : '' },
            { text: 'Weight (ft/lbs)', isBold: true },
            { text: numberToString(casingData.casingWt, 2, true) },
            { text: '200', isBold: true },
            { text: numberToString(mudData.twoHun, 0, true) },
            { text: 'Weight (ppg)', isBold: true },
            { text: numberToString(mudData.mudWeight, 2, true) },
          ],
          [
            { text: 'Slide Drilled (Usft)', isBold: true },
            { text: numberToString(dailyStats.slideDrilled, 2, true) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.slideDrilled, 2, true) : '' },
            { text: 'Bit Data', isBold: true },
            { text: '' },
            { text: '100', isBold: true },
            { text: numberToString(mudData.oneHun, 0, true) },
            { text: 'Fluid Loss', isBold: true },
            { text: numberToString(mudData.fluidLoss, 2, true) },
          ],
          [
            { text: 'Rotary Drilled (Usft)', isBold: true },
            { text: numberToString(dailyStats.rotateDrilled, 2, true) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.rotateDrilled, 2, true) : '' },
            { text: 'SN', isBold: true },
            { text: bhaIndex >= 0 ? currentBha.bitSerialNum : '' },
            { text: '6', isBold: true },
            { text: numberToString(mudData.sixDial, 0, true) },
            { text: 'PH', isBold: true },
            { text: numberToString(mudData.ph, 2, true) },
          ],
          [
            { text: 'Drlg/Circ Hrs', isBold: true },
            {
              text:
                numberToString(dailyStats.totalDrillingHours, 2, true) +
                ' / ' +
                numberToString(dailyStats.circHours, 2, true),
            },
            {
              text:
                bhaIndex >= 0
                  ? numberToString(currentBha.drillingHours, 2, true) +
                  ' / ' +
                  numberToString(currentBha.circHours, 2, true)
                  : '',
            },
            { text: 'Bit #', isBold: true },
            { text: bhaIndex >= 0 ? currentBha.bitNum : '' },
            { text: '3', isBold: true },
            { text: numberToString(mudData.threeDial, 0, true) },
            { text: 'Mud Type', isBold: true },
            { text: mudData.fluidType },
          ],
          [
            { text: 'Slide Drilling Hrs', isBold: true },
            { text: numberToString(dailyStats.slideHours, 2, true) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.slideHours, 2, true) : '' },
            { text: 'OD (in)', isBold: true },
            { text: bhaIndex >= 0 ? numberToString(currentBha.bitOd, 3, true) : '' },
            { text: '10s Gel', isBold: true },
            { text: numberToString(mudData.gel, 2, true) },
            { text: 'Oil/Water', isBold: true },
            { text: mudData.oilWaterRatio },
          ],
          [
            { text: 'Rotary Drilling Hrs', isBold: true },
            { text: numberToString(dailyStats.rotateHours, 2, true) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.rotateHours, 2, true) : '' },
            { text: 'TFA', isBold: true },
            { text: bhaIndex >= 0 ? numberToString(currentBha.bitTfa, 3, true) : '' },
            { text: 'Temp(F)', isBold: true },
            { text: numberToString(mudData.fluidTemp, 2, true) },
            { text: 'LGS', isBold: true },
            { text: numberToString(mudData.lgs, 2, true) },
          ],
          [
            { text: 'ROP Rot/Slide (Usft/hr)', isBold: true },
            {
              text:
                dailyStats.rotateRop > 0 || dailyStats.slideRop > 0
                  ? `${numberToString(dailyStats.rotateRop, 2, true)} / ${numberToString(dailyStats.slideRop, 2, true)}`
                  : '',
            },
            {
              text:
                bhaIndex >= 0 && (currentBha.rotateRop > 0 || currentBha.slideRop > 0)
                  ? `${numberToString(currentBha.rotateRop, 2, true)} / ${numberToString(currentBha.slideRop, 2, true)}`
                  : '',
            },
            { text: 'Grade In', isBold: true },
            { text: bhaIndex >= 0 ? currentBha.bitGradeIn : '' },
            { text: 'PV', isBold: true },
            { text: numberToString(mudData.pv, 0, true) }, //data from endpoint different than example pdf
            { text: 'Gas %', isBold: true },
            { text: numberToString(mudData.mf, 2, true) },
          ],
          [
            { text: 'BRT Hrs', isBold: true },
            { text: numberToString(dailyStats.brtHrs, 2, true) },
            { text: bhaIndex >= 0 ? numberToString(currentBha.brtHrs, 2, true) : '' },
            { text: 'Grade Out', isBold: true },
            { text: bhaIndex >= 0 ? currentBha.bitGradeOut : '' },
            { text: 'YP', isBold: true },
            { text: numberToString(mudData.yp, 0, true) }, //data from endpoint different than example pdf
            { text: '' },
            { text: '' },
          ],
          [
            { text: '%Slide', isBold: true },
            {
              text:
                dailyStats.totalDrilled > 0
                  ? numberToString((dailyStats.slideDrilled / dailyStats.totalDrilled) * 100, 2, true)
                  : '',
            },
            { text: bhaIndex >= 0 ? numberToString(currentBha.slidePerc * 100, 2, true) : '' },
            { text: 'Make', isBold: true },
            { text: bhaIndex >= 0 ? currentBha.bitMake : '' },
            { text: 'Viscosity', isBold: true },
            { text: numberToString(mudData.viscosity, 2, true) },
            { text: '' },
            { text: '' },
          ],
          [
            { text: '%Rotary', isBold: true },
            {
              text:
                dailyStats.totalDrilled > 0
                  ? numberToString((dailyStats.rotateDrilled / dailyStats.totalDrilled) * 100, 2, true)
                  : '',
            },
            { text: bhaIndex >= 0 ? numberToString(currentBha.rotatePerc * 100, 2, true) : '' },
            { text: 'Model', isBold: true },
            { text: bhaIndex >= 0 ? currentBha.bitModel : '' },
            { text: 'Chlorides', isBold: true },
            { text: numberToString(mudData.chlorides, 2, true) },
            { text: '' },
            { text: '' },
          ],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '7%', '7%', '7%', '7%'],
        data: [
          [{ text: 'Personnel', isHeader: true, columnSpan: 13 }],
          [
            { text: 'Co Man', isHeader: true },
            { text: 'DD', isHeader: true },
            { text: 'MWD', isHeader: true },
            { text: 'Drilling Engineer', isHeader: true },
            { text: 'Mud Engineer', isHeader: true },
            { text: 'Geologist', isHeader: true },
            { text: 'Tool Pusher', isHeader: true },
            { text: 'Trainee 1', isHeader: true },
            { text: 'Trainee 2', isHeader: true },
            { text: 'RSS Eng. 1', isHeader: true },
            { text: 'Rss Eng. 2', isHeader: true },
            { text: 'Third Man', isHeader: true },
            { text: 'Fourth Man', isHeader: true },
          ],
          [
            { text: personnelData.dayCoMan },
            { text: personnelData.dayDd },
            { text: personnelData.dayMwd },
            { text: personnelData.drillingEng },
            { text: personnelData.mudEng },
            { text: personnelData.geologist },
            { text: personnelData.dayPusher },
            { text: personnelData.trainee1 },
            { text: personnelData.trainee2 },
            { text: personnelData.rsshand1 },
            { text: personnelData.rsshand2 },
            { text: personnelData.thirdman },
            { text: personnelData.fourthman },
          ],
          [
            { text: personnelData.nightCoMan },
            { text: personnelData.nightDd },
            { text: personnelData.nightMwd },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: personnelData.nightPusher },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
            { text: '' },
          ],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['7%', '7%', '7%', '7%', '7%', '7%', '8%', '8%', '7%', '7%', '7%', '7%', '7%', '7%'],
        data: [
          [{ text: 'Drilling Parameters', isHeader: true, columnSpan: 14 }],
          [
            { text: '', isHeader: true },
            { text: 'ROP (Usft/hr)', isHeader: true },
            { text: 'WOB (klbs)', isHeader: true },
            { text: 'RPM', isHeader: true },
            { text: 'RPM DH', isHeader: true },
            { text: 'FLOW (gpm)', isHeader: true },
            { text: 'SPP ON (psi)', isHeader: true },
            { text: 'SPP OFF (psi)', isHeader: true },
            { text: 'SPM', isHeader: true },
            { text: 'PU (klbs)', isHeader: true },
            { text: 'ROT (klbs)', isHeader: true },
            { text: 'SO (klbs)', isHeader: true },
            { text: 'TQ ON (kftlbs)', isHeader: true },
            { text: 'TQ OFF (kftlbs)', isHeader: true },
          ],
          [
            { text: '24 Hrs' },
            { text: numberToString(drillingParams.rop, 2, true) },
            { text: numberToString(drillingParams.wob, 2, true) },
            { text: numberToString(drillingParams.rpm, 2, true) },
            { text: numberToString(drillingParams.rpmDown, 2, true) },
            { text: numberToString(drillingParams.flow, 2, true) },
            { text: numberToString(drillingParams.sppOn, 2, true) },
            { text: numberToString(drillingParams.sppOff, 2, true) },
            { text: numberToString(drillingParams.spm, 2, true) },
            { text: numberToString(drillingParams.pu, 2, true) },
            { text: numberToString(drillingParams.rot, 2, true) },
            { text: numberToString(drillingParams.so, 2, true) },
            { text: numberToString(drillingParams.tqOn, 2, true) },
            { text: numberToString(drillingParams.tqOff, 2, true) },
          ],
          [
            { text: 'Run' },
            { text: numberToString(drillingParams.ropTot, 2, true) },
            { text: numberToString(drillingParams.wobTot, 2, true) },
            { text: numberToString(drillingParams.rpmTot, 2, true) },
            { text: numberToString(drillingParams.rpmDownTot, 2, true) },
            { text: numberToString(drillingParams.flowTot, 2, true) },
            { text: numberToString(drillingParams.sppOnTot, 2, true) },
            { text: numberToString(drillingParams.sppOffTot, 2, true) },
            { text: numberToString(drillingParams.spmTot, 2, true) },
            { text: numberToString(drillingParams.puTot, 2, true) },
            { text: numberToString(drillingParams.rotTot, 2, true) },
            { text: numberToString(drillingParams.soTot, 2, true) },
            { text: numberToString(drillingParams.tqOnTot, 2, true) },
            { text: numberToString(drillingParams.tqOffTot, 2, true) },
          ],
        ],
      },
      {
        fontSize: 6,
        sectionAfter: 5,
        data: [[{ text: 'Comments', isHeader: true }], [{ text: dailyReports[reportIndex].comments }]],
      },
      {
        fontSize: 6,
        fixedHeaders: 2,
        manualWidth: true,
        columnWidths: ['7%', '4%', '4%', '4%', '7%', '7%', '4%', '4%', '7%', '12%', '40%'],
        data: addActivities(dailyReports[reportIndex].dailyActivity, dailyReports[reportIndex].date),
      },
    ]

    return docData
  }

  const sortActivityByDateTime = (a, b) => {
    if (typeof a?.isoDateTime !== 'string') return 0
    if (typeof b?.isoDateTime !== 'string') return 0
    let d1 = new Date(Date.parse(a.isoDateTime))
    let d2 = new Date(Date.parse(b.isoDateTime))
    return d1 < d2 ? -1 : d1 > d2 ? 1 : 0
  }

  const getDailyActivity = () => {
    if (!dailyReportData.current) return []
    if (!dailyReportData.current.hasOwnProperty('dailyReports')) return []
    if (!Array.isArray(dailyReportData.current.dailyReports)) return []

    const { dailyReports } = dailyReportData.current

    let dailyActivity = []
    for (let i = 0; i < dailyReports.length; i++) {
      if (!dailyReports[i].hasOwnProperty('dailyActivity')) continue
      if (!Array.isArray(dailyReports[i].dailyActivity)) continue
      for (let j = 0; j < dailyReports[i].dailyActivity.length; j++) {
        dailyActivity.push(cloneDeep(dailyReports[i].dailyActivity[j]))
      }
    }

    dailyActivity.sort(sortActivityByDateTime)
    return dailyActivity
  }

  const getDailyReportHeaderIds = () => {
    if (!dailyReportData.current) return []
    if (!dailyReportData.current.hasOwnProperty('dailyReports')) return []
    if (!Array.isArray(dailyReportData.current.dailyReports)) return []

    const { dailyReports } = dailyReportData.current

    let headerData = []
    for (let i = 0; i < dailyReports.length; i++) {
      headerData.push({ recordId: dailyReports[i].recordId, date: dailyReports[i].date })
    }

    return headerData
  }

  return {
    loading,
    fetchDailyReports,
    fetchDailyReport,
    fetchDailyReportData,
    getDailyActivity,
    getDropDowns,
    getPdfData,
    getDailyReportHeaderIds,
  }
}

export default useDailyReports
