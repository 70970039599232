import React, { useEffect, useState, useRef, useCallback } from 'react'
import { appColors } from 'utils'
import { Box, CircularProgress } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { TreeView } from '@mui/lab'
import { SvgIcon, Typography, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import wellIcon from 'assets/wellScreenIcons/wellHeadGrey.png'
import surveyIcon from 'assets/wellScreenIcons/surveys-grey.png'
import actualWellIcon from 'assets/wellScreenIcons/actualWell.png'
import plannedWellIcon from 'assets/wellScreenIcons/schematicBlue.png'
import fieldIcon from 'assets/wellScreenIcons/fieldGrey.png'
import facilityIcon from 'assets/wellScreenIcons/facilityGrey.png'
import wellboreIcon from 'assets/wellScreenIcons/wellboreIcon.png'
import operatorIcon from 'assets/wellScreenIcons/operatorGrey.png'
import databaseIcon from 'assets/wellScreenIcons/database-grey.png'
import TreeNode from './TreeNode'
import SearchBar from 'components/common/SearchBar'
import { cloneDeep, debounce } from 'lodash'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import ConfirmDialog from 'components/common/ConfirmDialog'
import MenuButton from 'components/common/MenuButton'
import { styled } from '@mui/styles'
import RefreshIcon from '@mui/icons-material/Refresh'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { useRecoilState, useRecoilValue } from 'recoil'
import { dateBaseTreeUpdateNameAtom, userUserRoleAtom } from 'atoms'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import CreateOffsetPlanModal from 'components/WellPages/SurveyPage/CreateOffsetPlanModal'
import { checkFeature } from 'components/userPermissions'
import OasisJobInfoModal from 'components/WellList/OasisJobInfoModal'
import SapJobInfoModal from 'components/WellList/SapJobInfoModal'
import SureShopJobInfoModal from 'components/WellList/SureShopJobInfoModal'
import OracleJobInfoModal from 'components/WellList/OracleJobInfoModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

export const TREE_ICON_SIZE = 20
export const TREE_ICON_PADDING = 10

export const MinusSquare = (props) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14, color: appColors.headerTextColor }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  )
}

export const PlusSquare = (props) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14, color: appColors.headerTextColor }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  )
}

const bfsSearch = (graph, targetId) => {
  const queue = [graph]

  while (queue.length > 0) {
    const currNode = queue.shift()
    if (currNode.nodeId === targetId) {
      return currNode
    }
    if (currNode.children) {
      queue.push(...currNode.children)
    }
  }
  return []
}

function searchNodes(node, searchText, level = '', result = [], parentFound = false) {
  if (parentFound) {
    result.push(node)
  }

  if (!parentFound && node.name.toLowerCase().includes(searchText)) {
    if (level === '' || level === node.level) {
      result.push(node)
      parentFound = true
    }
  }

  let initialLength = result.length
  if (Array.isArray(node.children)) {
    for (const child of node.children) {
      searchNodes(child, searchText, level, result, parentFound)
    }
  }

  if (result.length > initialLength) {
    result.push(node)
  }

  return result
}

const DatabaseTree = ({ setStatus, showHideTree, treeVisible, setSelectedNode, selectedNode }) => {
  const _isMounted = useRef(false)
  const isLoadingRef = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const rawTreeData = useRef({ nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] })
  const flattenedNodes = useRef([])
  const [showTransferReportingModal, setShowTransferReportingModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState({ show: false, title: '' })
  const [confirmTransfer, setConfirmTransfer] = useState({ show: false, title: '' })
  const [filteredNodes, setFilteredNodes] = useState([])
  const [expandedNodes, setExpandedNodes] = useState([])
  const expandedNodesRef = useRef([])
  const [searchText, setSearchText] = useState('')
  const clickedNode = useRef(null)
  const isRenaming = useRef(false)
  const isDeleting = useRef(false)
  const isCreatingOracleBid = useRef(false)
  const isUpdatingWellInfo = useRef(false)
  const cutNode = useRef(null)
  const selectedActualWell = useRef(null)
  const expandedNodesLoaded = useRef(false)
  const [dataBaseTreeNameUpdate, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const [availableWells, setAvailableWells] = useState([]) //Used for report transfer
  const [showCreateOffsetPlan, setShowCreateOffsetPlan] = useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [showOasisModal, setShowOasisModal] = useState(false)
  const [showSapModal, setShowSapModal] = useState(false)
  const [showOracleModal, setShowOracleModal] = useState(false)
  const [showSureShopModal, setShowSureShopModal] = useState(false)
  const { searchBarStyle, getWindowBarColor, getTreeBackColor, theme } = useInnovaTheme()

  const getTreeData = useAxiosGzip({
    url: '/databaseTree/getDatabaseTreeGz',
  })

  const getAvailableWellsForTransfer = useInnovaAxios({
    url: '/well/getActualWells',
  })

  const checkWellHasReportingData = useInnovaAxios({
    url: '/well/hasReportingData',
  })

  const transferReportingData = useInnovaAxios({
    url: '/well/transferReportingData',
  })

  const renameNode = useInnovaAxios({
    url: '/databaseTree/renameItem',
  })

  const deleteNode = useInnovaAxios({
    url: '/databaseTree/deleteItem',
  })

  const addInstantSurvey = useInnovaAxios({
    url: '/databaseTree/addInstantSurvey',
  })

  const addInstantPlan = useInnovaAxios({
    url: '/databaseTree/addInstantPlan',
  })

  const cutPasteItem = useInnovaAxios({
    url: '/databaseTree/cutPasteItem',
  })

  const addItem = useInnovaAxios({
    url: '/databaseTree/addItem',
  })

  const createOffsetPlan = useInnovaAxios({
    url: '/plannnedWell/createOffsetPlan',
  })

  const createOracleBid = useInnovaAxios({
    url: '/oracle/createBid',
  })

  const updateWellInfo = useInnovaAxios({
    url: '/well/wellInfo/updateWellInfoErpSystem',
  })

  const debounceSearchTree = debounce((text) => {
    const filtered = searchNodes(rawTreeData.current, text.toLowerCase().trim(), '')

    setFilteredNodes(Array.isArray(filtered) ? filtered : [])
  }, 300)

  useEffect(() => {
    if (_isMounted.current) debounceSearchTree(searchText)
  }, [searchText, expandedNodes]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    fetchTreeData(true)
    return () => {
      _isMounted.current = false
      saveExpandedNodes()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateTreeName = async (data) => {
    if (!data) return
    if (isRenaming.current) return
    if (!_isMounted.current) return
    if (data.caller === 'TREE') return

    setLoading(true)
    isRenaming.current = true
    let updateSuccess = updateNodeName(data)
    isRenaming.current = false

    setLoading(false)
    if (!updateSuccess) {
      showError('could not find node to rename')
    }

    let n = bfsSearch(rawTreeData.current, data.nodeId)
    if (n) {
      let dataCopy = { ...data, parentNodeId: n.parentNodeId }
      updatePrincipalIcon(dataCopy)
    }

    if (selectedNode.nodeId === data.nodeId) {
      let newSelectedNode = { ...selectedNode }
      newSelectedNode.name = data.name

      if (newSelectedNode.level === 'PLANNEDWELL_PRINCIPAL' || newSelectedNode.level === 'PLANNEDWELL') {
        newSelectedNode.level = data.isPrincipal ? 'PLANNEDWELL_PRINCIPAL' : 'PLANNEDWELL'
      }
      setSelectedNode(newSelectedNode)
    }

    setDataBaseTreeNameUpdate(null)
  }

  useEffect(() => {
    handleUpdateTreeName(dataBaseTreeNameUpdate)
  }, [dataBaseTreeNameUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const saveExpandedNodes = () => {
    if (!Array.isArray(expandedNodesRef.current) || !Array.isArray(flattenedNodes.current)) {
      saveItemToLS('databaseTree', 'expandedNodes', ['DATABASE'])
      return
    }

    const expandedNodeNames = new Set(['DATABASE'])
    for (let i = 0; i < expandedNodesRef.current.length; i++) {
      let node = flattenedNodes.current.find((n) => n.nodeId === expandedNodesRef.current[i])
      if (!node) continue
      expandedNodeNames.add(node.name)
    }

    saveItemToLS('databaseTree', 'expandedNodes', Array.from(expandedNodeNames))
  }

  const loadExpandedNodes = () => {
    let expandedNodeNames = getItemFromLS('databaseTree', 'expandedNodes')
    if (!Array.isArray(expandedNodeNames)) return

    let newExpandedNodes = []
    for (let i = 0; i < expandedNodeNames.length; i++) {
      let node = flattenedNodes.current.find((n) => n.name === expandedNodeNames[i])
      if (!node) continue
      newExpandedNodes.push(node.nodeId)
    }

    expandedNodesRef.current = newExpandedNodes
    setExpandedNodes(newExpandedNodes)
  }

  const updatePrincipalIcon = (node) => {
    function updatePrincipalIcons(currentNode, nodeIdToFind) {
      if (!Array.isArray(currentNode.children)) return false
      if (currentNode.children.length === 0) return false

      for (const child of currentNode.children) {
        if (child.nodeId === nodeIdToFind) {
          if (!Array.isArray(child.children)) return false
          if (child.children.length === 0) return false

          for (const childNode of child.children) {
            if (childNode.level !== 'PLANNEDWELL_PRINCIPAL' && childNode.level !== 'PLANNEDWELL') continue

            if (childNode.nodeId === node.nodeId) {
              childNode.level = node?.isPrincipal ? 'PLANNEDWELL_PRINCIPAL' : 'PLANNEDWELL'
            }

            if (childNode.nodeId !== node.nodeId && node?.isPrincipal && childNode.level === 'PLANNEDWELL_PRINCIPAL') {
              childNode.level = 'PLANNEDWELL'
            }
          }

          return true
        }

        if (updatePrincipalIcons(child, nodeIdToFind)) return true
      }

      return false
    }

    if (node.level !== 'PLANNEDWELL_PRINCIPAL' && node.level !== 'PLANNEDWELL') return false
    return updatePrincipalIcons(rawTreeData.current, node.parentNodeId)
  }

  const updateNodeName = (node) => {
    function updateChildNodeNames(currentNode, nodeIdToFind) {
      if (currentNode.nodeId === nodeIdToFind) {
        currentNode.name = node.name
        return true
      }

      if (!Array.isArray(currentNode.children)) return false
      if (currentNode.children.length === 0) return false

      for (const child of currentNode.children) {
        if (child.nodeId === nodeIdToFind) {
          child.name = node.name
          return true
        }

        if (updateChildNodeNames(child, nodeIdToFind)) return true
      }

      return false
    }

    return updateChildNodeNames(rawTreeData.current, node.nodeId)
  }

  const addNode = (newNode, parentNode) => {
    function addNodeRecursive(currentNode, nodeIdToFind) {
      if (currentNode.nodeId === nodeIdToFind) {
        if (!Array.isArray(currentNode.children)) {
          currentNode.children = []
        }

        currentNode.children.push(cloneDeep(newNode))
        return true
      }

      if (!Array.isArray(currentNode.children)) return false
      if (currentNode.children.length === 0) return false

      for (const child of currentNode.children) {
        if (addNodeRecursive(child, nodeIdToFind)) return true
      }

      return false
    }

    return addNodeRecursive(rawTreeData.current, parentNode.nodeId)
  }

  function findAndDeleteNode(tree, nodeId) {
    if (!tree) return null
    if (tree.nodeId === nodeId) return null

    // Recursively search and delete the node in children
    if (tree.children && tree.children.length > 0) {
      tree.children = tree.children.map((child) => findAndDeleteNode(child, nodeId))
      tree.children = tree.children.filter((child) => child !== null)
      if (tree.children.length === 0) tree.children = null
    }

    return tree
  }

  const handleDeleteTreeItem = async () => {
    setConfirmDelete(false)

    if (!clickedNode.current) return
    if (isDeleting.current) return
    if (!_isMounted.current) return

    setLoading(true)
    isDeleting.current = true

    let res = await deleteNode(clickedNode.current)
    isDeleting.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      clickedNode.current = null
      showError(res?.error?.response?.data?.error)
      return
    }

    if (selectedNode) {
      let selectedNodeFound = bfsSearch(clickedNode.current, selectedNode.nodeId)
      if (selectedNodeFound) {
        setSelectedNode(null)
      }
    }

    rawTreeData.current = findAndDeleteNode(rawTreeData.current, clickedNode.current.nodeId)
    clickedNode.current = null
  }

  const handleRenameTreeItem = async (data) => {
    setShowRenameModal(false)

    if (!data) return
    if (isRenaming.current) return
    if (!_isMounted.current) return

    if (!data.hasOwnProperty('newName')) return
    data.orgName = unescapeHtml(data.orgName)
    data.newName = unescapeHtml(data.newName)
    data.newName = data.newName.replace(/[|#?']/g, '')

    setLoading(true)
    isRenaming.current = true
    let res = await renameNode(data)
    isRenaming.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      return
    }

    clickedNode.current.name = data.newName
    let updateSuccess = updateNodeName(clickedNode.current)
    if (!updateSuccess) {
      showError('could not find node to rename')
    }

    setDataBaseTreeNameUpdate({
      name: data.newName,
      orgName: data.orgName,
      nodeId: clickedNode.current.nodeId,
      caller: 'TREE',
      level: clickedNode.current.level,
    })
    clickedNode.current = null
  }

  const handleTransferReportingData = async (data) => {
    let res = await transferReportingData(data)
    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Data transferred from ${data.orgWellName} to ${data.newWellName}`,
    })
  }

  const handleCheckTransferReportingData = async (data) => {
    setShowTransferReportingModal(false)
    if (!data) return

    let res = await checkWellHasReportingData({ wellName: data.newWellName })
    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      return
    }

    if (res.data) {
      setConfirmTransfer({
        show: true,
        title: `Transfer Reporting`,
        text: `Well ${data.newWellName} already has reporting data do you want to overwrite?`,
        data: { ...data },
      })

      return
    }

    await handleTransferReportingData(data)
  }

  const RenameTreeItemModal = ({ node }) => {
    const formValidationSchema = yup.object({
      newName: yup.string().required(),
    })

    let formData = [
      {
        tag: 'parentName',
        value: node.parentName,
        inputType: '-',
      },
      {
        tag: 'level',
        value: node.level,
        inputType: '-',
      },
      {
        tag: 'orgName',
        value: node.name,
        inputType: '-',
      },
      {
        tag: 'newName',
        value: node.name,
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={true}
        onClose={() => setShowRenameModal(false)}
        title={`Rename ${unescapeHtml(node.name)}`}
        formData={formData}
        submitForm={handleRenameTreeItem}
        cancelFunction={() => setShowRenameModal(false)}
        validationSchema={formValidationSchema}
      />
    )
  }

  const showError = useCallback(
    (msg) => {
      setStatus({
        show: true,
        severity: 'error',
        message: `${msg}`,
      })
    },
    [setStatus],
  )

  const fetchAvailableWellsReportTransfer = async (n) => {
    if (!n) return
    if (!_isMounted.current) return
    let res = await getAvailableWellsForTransfer({ wellName: n.parentName })

    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      return
    }

    if (!Array.isArray(res.data)) return
    let wells = []
    for (let i = 0; i < res.data.length; i++) {
      if (res.data[i].actualWellName === n.name) {
        continue
      }

      wells.push({ label: res.data[i].actualWellName, value: res.data[i].actualWellName })
    }

    setAvailableWells(wells)
  }

  const handleShowTransferReportingModal = async () => {
    await fetchAvailableWellsReportTransfer(clickedNode.current)
    setShowTransferReportingModal(true)
  }

  const TransferReportingDataModal = ({ node }) => {
    const formValidationSchema = yup.object({
      newWellName: yup.string().required(),
    })

    let formData = [
      {
        tag: 'newWellName',
        value: Array.isArray(availableWells) && availableWells.length > 0 ? availableWells[0].label : '',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(availableWells) ? availableWells : [],
        useLabel: true,
      },
      {
        tag: 'orgWellName',
        value: node.name,
        inputType: '-',
      },
    ]

    return (
      <InputModal
        open={true}
        onClose={() => setShowTransferReportingModal(false)}
        title={`Select well`}
        formData={formData}
        submitForm={handleCheckTransferReportingData}
        cancelFunction={() => setShowTransferReportingModal(false)}
        validationSchema={formValidationSchema}
      />
    )
  }

  function flattenTree(node) {
    flattenedNodes.current = []

    function flattenedTreeRecursive(currentNode) {
      flattenedNodes.current.push({
        level: currentNode.level,
        name: currentNode.name,
        nodeId: currentNode.nodeId,
        parentNodeId: currentNode.parentNodeId,
      })

      if (!Array.isArray(currentNode.children)) return
      if (currentNode.children.length === 0) return

      for (const child of currentNode.children) {
        flattenedTreeRecursive(child)
      }
    }

    flattenedTreeRecursive(node)
  }

  function getChildNodes(node, childNodes) {
    if (!Array.isArray(childNodes)) return

    function getChildNodesRecursive(currentNode, children) {
      children.push(currentNode.nodeId)

      if (!Array.isArray(currentNode.children)) return
      if (currentNode.children.length === 0) return

      for (const child of currentNode.children) {
        getChildNodesRecursive(child, children)
      }
    }

    getChildNodesRecursive(node, childNodes)
  }

  const fetchTreeData = async (getTopLevelOnly) => {
    if (!_isMounted.current) return
    if (isLoadingRef.current) return
    setLoading(true)

    isLoadingRef.current = true
    let res = await getTreeData({ topLevelOnly: getTopLevelOnly })
    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      rawTreeData.current = { nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] }
      setFilteredNodes([])
      return
    }

    if (!res.data) return
    rawTreeData.current = res?.data?.hasOwnProperty('nodeId')
      ? res.data
      : { nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] }
    flattenTree(rawTreeData.current)
    setFilteredNodes(flattenedNodes.current)
    if (!expandedNodes.includes(getRootNodeId())) handleToggleNodeExpanded(getRootNodeId())

    if (!expandedNodesLoaded.current) {
      loadExpandedNodes()
      expandedNodesLoaded.current = true
    }
  }

  const fetchOperatorChildNodes = async (nodeId) => {
    let node = bfsSearch(rawTreeData.current, nodeId)
    if (node?.level !== 'OPERATOR') return

    if (!_isMounted.current) return
    if (isLoadingRef.current) return
    setLoading(true)

    isLoadingRef.current = true
    let res = await getTreeData({ parentNodeName: node.name, parentLevel: 'OPERATOR' })
    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      return
    }

    if (!res.data) return

    //Find the node which was expended in the retruned data result will be an array
    const parentNode = searchNodes(res.data, node.name.toLowerCase().trim(), 'OPERATOR')

    //Search nodes always returns the root node as well as the operator so result should be array of length 2
    let operatorNodeWithChildren = parentNode?.find((item) => item.level === 'OPERATOR' && item.name === node.name)
    if (!operatorNodeWithChildren) return

    if (Array.isArray(operatorNodeWithChildren.children)) {
      for (let i = 0; i < operatorNodeWithChildren.children.length; i++) {
        operatorNodeWithChildren.children[i].parentNodeId = node.nodeId
      }
    }

    //Objects are passed by ref so adding children to node will add them to the raw tree
    node.children = operatorNodeWithChildren.children

    flattenTree(rawTreeData.current)
    setFilteredNodes(flattenedNodes.current)
  }

  const handleToggleNodeExpanded = async (nodeId) => {
    let newNodes = [...expandedNodes]
    let isActionExpand = false
    if (newNodes.includes(nodeId)) {
      newNodes.splice(newNodes.indexOf(nodeId), 1)
    } else {
      isActionExpand = true
      newNodes.push(nodeId)
    }

    //Handle load child nodes for operators
    if (isActionExpand) {
      await fetchOperatorChildNodes(nodeId)
    }

    expandedNodesRef.current = newNodes
    setExpandedNodes(newNodes)
  }

  const handleExpandCollapseChildren = (node, action) => {
    if (!node) return

    let nodes = [node.nodeId]
    if (node.level !== 'DATABASE') getChildNodes(node, nodes)

    if (action === 'EXPAND') {
      const newNodes = new Set([...expandedNodes, ...nodes])
      expandedNodesRef.current = Array.from(newNodes)
      setExpandedNodes(Array.from(newNodes))
    }

    if (action === 'COLLAPSE') {
      const newNodes = expandedNodes.filter((item) => !nodes.includes(item))
      expandedNodesRef.current = Array.from(newNodes)
      setExpandedNodes(Array.from(newNodes))
    }
  }

  const getRootNodeId = () => {
    if (!rawTreeData.current) return '99999'
    return rawTreeData.current?.nodeId
  }

  function isNodeVisible(node) {
    if (!Array.isArray(filteredNodes)) return true
    return filteredNodes.findIndex((item) => item.nodeId === node.nodeId) >= 0
  }

  function convertToLowerCase(inputString) {
    if (typeof inputString !== 'string') return ''
    if (inputString.length === 0) return ''

    const firstChar = inputString.charAt(0).toUpperCase()
    const restOfString = inputString.slice(1).toLowerCase()

    return firstChar + restOfString
  }

  const handlePaste = async (node) => {
    if (!node) return
    if (!cutNode.current) return

    if (!_isMounted.current) return
    if (isLoadingRef.current) return

    isLoadingRef.current = true
    setLoading(true)

    let res = await cutPasteItem({ name: cutNode.current.name, level: cutNode.current.level, newParentName: node.name })

    if (!_isMounted.current) return
    isLoadingRef.current = false

    if (res?.error) {
      setLoading(false)
      showError(res?.error?.response?.data?.error)
      return
    }

    cutNode.current.parentNodeId = node.nodeId
    rawTreeData.current = findAndDeleteNode(rawTreeData.current, cutNode.current.nodeId)

    addNode(cutNode.current, node)
    setLoading(false)
  }

  const handleAddNewNode = async (parentNode, level) => {
    if (!parentNode) return
    if (typeof level !== 'string') return
    if (level === '') return
    if (!_isMounted.current) return
    if (isLoadingRef.current) return

    isLoadingRef.current = true
    setLoading(true)

    let res = await addItem({ level: level, parentName: parentNode.name })

    if (!_isMounted.current) return
    isLoadingRef.current = false

    if (res?.error) {
      setLoading(false)
      showError(res?.error?.response?.data?.error)
      return
    }

    res.data.parentNodeId = parentNode.nodeId
    addNode(res.data, parentNode)
    flattenedNodes.current.push(res.data)
    setFilteredNodes([...flattenedNodes.current, res.data])

    const newNodes = new Set([...expandedNodes, parentNode.nodeId])
    expandedNodesRef.current = Array.from(newNodes)
    setExpandedNodes(Array.from(newNodes))

    if (res.data?.level === 'SURVEY') {
      res.data.parentName = parentNode.name
    }

    setSelectedNode(res.data)
    setLoading(false)
  }

  const handleAddInstant = async (level) => {
    if (typeof level !== 'string') return
    if (!_isMounted.current) return
    if (isLoadingRef.current) return

    isLoadingRef.current = true
    setLoading(true)

    let res = null
    if (level === 'SURVEY') {
      res = await addInstantSurvey()
    }

    if (level === 'PLAN') {
      res = await addInstantPlan()
    }

    if (!_isMounted.current) return
    isLoadingRef.current = false
    setLoading(false)

    if (!res) {
      return
    }

    if (res?.error) {
      showError(res?.error?.response?.data?.error)
      return
    }

    if (!res.data) {
      showError('No data returned')
      return
    }

    if (!rawTreeData.current) return
    if (!Array.isArray(rawTreeData.current.children)) return

    res.data.parentNodeId = rawTreeData.current.nodeId
    rawTreeData.current.children.push(cloneDeep(res.data))
    flattenTree(rawTreeData.current)

    let newNodes = [res.data.nodeId]
    getChildNodes(res.data, newNodes)
    setFilteredNodes([...flattenedNodes.current, ...newNodes])
    handleExpandCollapseChildren(res.data, 'EXPAND')
  }

  const handleCloseCreateOffsetPlan = () => {
    setShowCreateOffsetPlan(false)
  }

  const handleCreateOffsetPlan = async (data) => {
    if (!data) return
    if (!_isMounted.current) return
    if (isLoadingRef.current) return

    isLoadingRef.current = true
    setLoading(true)

    let res = await createOffsetPlan({
      params: JSON.stringify(data.offSetPlan),
      planName: data.wellPlan.actualWellName,
    })

    if (!_isMounted.current) return
    isLoadingRef.current = false

    if (res?.error) {
      setLoading(false)
      showError(res?.error?.response?.data?.error)
      return
    }

    let parentNode = bfsSearch(rawTreeData.current, clickedNode.current?.parentNodeId)

    res.data.parentNodeId = parentNode.nodeId
    addNode(res.data, parentNode)
    flattenedNodes.current.push(res.data)
    setFilteredNodes([...flattenedNodes.current, res.data])

    const newNodes = new Set([...expandedNodes, parentNode.nodeId])
    expandedNodesRef.current = Array.from(newNodes)
    setExpandedNodes(Array.from(newNodes))

    setSelectedNode(res.data)
    setLoading(false)
  }

  const ContextMenuItemIcon = ({ icon, color, isImage }) => {
    if (isImage) {
      return (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `15px`,
            height: `15px`,
          }}
        />
      )
    }

    return <Iconify icon={icon} width={'15px'} height={'15px'} color={color || '#a9abac'} />
  }

  const getExpandCollapseAllContextMenuItems = () => {
    return [
      {
        icon: <ContextMenuItemIcon icon={'mdi:expand-all'} />,
        label: 'Expand all',
        onClick: (node) => {
          handleExpandCollapseChildren(node, 'EXPAND')
        },
      },
      {
        icon: <ContextMenuItemIcon icon={'mdi:collapse-all'} />,
        label: 'Collapse all',
        onClick: (node) => {
          handleExpandCollapseChildren(node, 'COLLAPSE')
        },
      },
    ]
  }

  const handleUpdateWellInfo = async (data) => {
    if (!data) return
    if (isUpdatingWellInfo.current) return
    isUpdatingWellInfo.current = true
    let res = await updateWellInfo({ ...data, wellName: data.actualWell })
    isUpdatingWellInfo.current = false

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: res?.error?.response?.data?.error,
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: 'Well info updated',
    })
  }

  const handleCreateOracleBid = async (data) => {
    if (isCreatingOracleBid.current) return
    isCreatingOracleBid.current = true
    let res = await createOracleBid({ wellName: data.actualWell })
    isCreatingOracleBid.current = false

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: res?.error?.response?.data?.error,
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: 'Bid synced with Oracle',
    })
  }

  const getContextMenuItems = (node) => {
    if (!node) return []
    if (node.level === 'ACTUAL_WELLBORE' || node.level === 'PLANNED_WELLBORE') return []

    let menuItems = []
    if (node.level === 'DATABASE') {
      menuItems.push({
        icon: <ContextMenuItemIcon icon={operatorIcon} isImage={true} />,
        label: `Insert new Operator`,
        onClick: (node) => {
          handleAddNewNode(node, 'OPERATOR')
        },
      })

      menuItems.push({
        isDivider: true,
      })

      menuItems.push({
        icon: <ContextMenuItemIcon icon={surveyIcon} isImage={true} />,
        label: 'New instant survey',
        onClick: (node) => {
          handleAddInstant('SURVEY')
        },
      })

      menuItems.push({
        icon: <ContextMenuItemIcon icon={plannedWellIcon} isImage={true} />,
        label: 'New instant plan',
        onClick: (node) => {
          handleAddInstant('PLAN')
        },
      })

      menuItems.push(...getExpandCollapseAllContextMenuItems())
      return menuItems
    }

    if (node.level !== 'SURVEY' && node.level !== 'PLANNEDWELL_PRINCIPAL' && node.level !== 'PLANNEDWELL') {
      let name = 'Field'
      let newNodeLevel = 'FIELD'
      let icon = fieldIcon

      if (node.level === 'FIELD') {
        name = 'Facility'
        newNodeLevel = 'FACILITY'
        icon = facilityIcon
      }

      if (node.level === 'FACILITY') {
        name = 'Well'
        newNodeLevel = 'WELL'
        icon = wellIcon
      }

      if (node.level === 'WELL') {
        name = 'Actual well'
        newNodeLevel = 'ACTUALWELL'
        icon = actualWellIcon

        menuItems.push({
          icon: <ContextMenuItemIcon icon={plannedWellIcon} isImage={true} />,
          label: `Insert new Plan`,
          onClick: (node) => {
            handleAddNewNode(node, 'PLANNEDWELL')
          },
        })
      }

      if (node.level === 'ACTUALWELL') {
        name = 'Survey'
        newNodeLevel = 'SURVEY'
        icon = surveyIcon
      }

      menuItems.push({
        icon: <ContextMenuItemIcon icon={icon} isImage={true} />,
        label: `Insert new ${name}`,
        onClick: (node) => {
          handleAddNewNode(node, newNodeLevel)
        },
      })
    }

    if (node.level === 'PLANNEDWELL_PRINCIPAL' || node.level === 'PLANNEDWELL') {
      if (menuItems.length > 0) {
        menuItems.push({
          isDivider: true,
        })
      }

      menuItems.push({
        icon: <ContextMenuItemIcon icon={'flat-color-icons:plus'} />,
        label: 'Create Offset Plan',
        onClick: (node) => {
          clickedNode.current = { ...node }
          setShowCreateOffsetPlan(true)
        },
      })
    }

    if (node.level !== 'SURVEY') {
      if (menuItems.length > 0) {
        menuItems.push({
          isDivider: true,
        })
      }

      menuItems.push(...getExpandCollapseAllContextMenuItems())
    }

    if (node.level === 'SURVEY') {
      let parentNodes = bfsSearch(rawTreeData.current, node.parentNodeId)
      if (parentNodes?.length === 0) return []
      node.parentName = parentNodes.name
    }

    if (node.level === 'FIELD' || node.level === 'FACILITY' || node.level === 'WELL') {
      if (menuItems.length > 0) {
        menuItems.push({
          isDivider: true,
        })
      }

      menuItems.push({
        icon: <ContextMenuItemIcon icon={'material-symbols:cut'} />,
        label: 'Cut',
        onClick: (node) => {
          cutNode.current = cloneDeep(node)
        },
      })
    }

    if (cutNode.current) {
      let canPaste = false
      if (cutNode.current.level === 'FIELD' && node.level === 'OPERATOR') {
        canPaste = true
      }

      if (cutNode.current.level === 'FACILITY' && node.level === 'FIELD') {
        canPaste = true
      }

      if (cutNode.current.level === 'WELL' && node.level === 'FACILITY') {
        canPaste = true
      }

      if (canPaste) {
        menuItems.push({
          icon: <ContextMenuItemIcon icon={'fa6-regular:paste'} />,
          label: 'Paste',
          onClick: (node) => {
            handlePaste(node)
          },
        })
      }
    }

    if (menuItems.length > 0) {
      menuItems.push({
        isDivider: true,
      })
    }

    menuItems.push({
      icon: <ContextMenuItemIcon icon={'fluent:rename-28-regular'} />,
      label: 'Rename',
      onClick: (node) => {
        clickedNode.current = { ...node }
        setShowRenameModal(true)
      },
    })

    menuItems.push({
      isDivider: true,
    })

    menuItems.push({
      icon: <ContextMenuItemIcon icon={'fluent:delete-28-regular'} color={'red'} />,
      label: 'Delete',
      onClick: (node) => {
        clickedNode.current = { ...node }
        setConfirmDelete({
          show: true,
          title: `Delete ${convertToLowerCase(node.level)}`,
          text: `Are you sure you want to delete ${node.name}?`,
        })
      },
    })

    if (node.level === 'ACTUALWELL') {
      menuItems.push({
        isDivider: true,
      })

      menuItems.push({
        icon: <ContextMenuItemIcon icon={'bx:transfer'} />,
        label: `Transfer reporting data`,
        onClick: (node) => {
          let parentNode = bfsSearch(rawTreeData.current, node.parentNodeId)
          if (!parentNode) {
            showError('Could not find parent node')
            return
          }

          clickedNode.current = { ...node }
          clickedNode.current.parentName = parentNode.name

          handleShowTransferReportingModal()
        },
      })

      let oasisEnabled = checkFeature(12, userRole?.roleAttributes?.featureId)
      let sapEnabled = checkFeature(14, userRole?.roleAttributes?.featureId)
      let sureShopEnabled = checkFeature(47, userRole?.roleAttributes?.featureId)
      let oracleEnabled = checkFeature(49, userRole?.roleAttributes?.featureId)

      if (oasisEnabled || sapEnabled || sureShopEnabled || oracleEnabled) {
        menuItems.push({
          isDivider: true,
        })

        if (oasisEnabled) {
          menuItems.push({
            icon: <ContextMenuItemIcon icon={'fa6-solid:o'} />,
            label: `Get Oasis job info`,
            onClick: (node) => {
              selectedActualWell.current = { actualWell: node.name }
              setShowOasisModal(true)
            },
          })
        }

        if (sapEnabled) {
          menuItems.push({
            icon: <ContextMenuItemIcon icon={'logos:sap'} />,
            label: `Get SAP job info`,
            onClick: (node) => {
              selectedActualWell.current = { actualWell: node.name }
              setShowSapModal(true)
            },
          })
        }

        if (sureShopEnabled) {
          menuItems.push({
            icon: <ContextMenuItemIcon icon={'noto:wrench'} />,
            label: `Get SureShop job info`,
            onClick: (node) => {
              selectedActualWell.current = { actualWell: node.name }
              setShowSureShopModal(true)
            },
          })
        }

        if (oracleEnabled) {
          menuItems.push({
            icon: <ContextMenuItemIcon icon={'logos:oracle'} />,
            label: `Get Oracle job info`,
            onClick: (node) => {
              selectedActualWell.current = { actualWell: node.name }
              setShowOracleModal(true)
            },
          })

          menuItems.push({
            icon: <ContextMenuItemIcon icon={'solar:dollar-bold'} />,
            label: `Sync bid with Oracle`,
            onClick: (node) => {
              selectedActualWell.current = { actualWell: node.name }
              handleCreateOracleBid(true)
            },
          })
        }
      }
    }

    return menuItems
  }

  const TreeNodeIcon = ({ icon, node }) => {
    const [contextMenu, setContextMenu] = useState(null)

    let style = {
      width: `${TREE_ICON_SIZE}px`,
      height: `${TREE_ICON_SIZE}px`,
      marginRight: `${TREE_ICON_PADDING}px`,
      padding: '2px',
    }

    if (node.level === 'PLANNEDWELL_PRINCIPAL') {
      style.border = '1px solid red'
    }

    const handleClose = () => {
      setContextMenu(null)
    }

    const handleContextMenu = (event) => {
      event.preventDefault()
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : null,
      )
    }

    let contextMenuItems = getContextMenuItems(node)

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: selectedNode?.nodeId === node.nodeId ? (theme === 'dark' ? '#224865' : '#C3d2e1') : '',
          borderRadius: '5px',
          paddingRight: '5px',
        }}
        onContextMenu={handleContextMenu}
        onClick={() => setSelectedNode(node)}>
        <img alt={'Icon'} src={icon} style={style} />
        <Typography
          sx={{
            color: appColors.itemTextColor,
          }}>
          {unescapeHtml(node.name)}
        </Typography>
        {Array.isArray(contextMenuItems) && contextMenuItems.length > 0 ? (
          <Menu
            sx={{
              mt: '1px',
              '& .MuiMenu-paper': { backgroundColor: getWindowBarColor(), border: '1px solid gray' },
            }}
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference='anchorPosition'
            anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}>
            {contextMenuItems.map((item, index) => {
              if (item.isDivider) {
                return <Divider key={index} sx={{ bgcolor: 'secondary.light' }} />
              }

              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    if (item.onClick) item.onClick(node)
                    setContextMenu(null)
                  }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: '#a9abac' }}>{item.label}</ListItemText>
                </MenuItem>
              )
            })}
          </Menu>
        ) : null}
      </Box>
    )
  }

  const SurveyNodes = ({ surveys }) => {
    if (!Array.isArray(surveys)) return null
    surveys = surveys.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return surveys.map((survey) => (
      <TreeNode
        key={`${survey.nodeId}`}
        nodeId={`${survey.nodeId}`}
        labelIcon={<TreeNodeIcon icon={surveyIcon} node={survey} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
        }}
      />
    ))
  }

  const ActualWellboreNodes = ({ wellData }) => {
    if (!Array.isArray(wellData)) return null
    wellData = wellData.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return wellData.map((well) => (
      <TreeNode
        key={`${well.nodeId}`}
        nodeId={`${well.nodeId}`}
        labelIcon={
          <TreeNodeIcon
            icon={wellboreIcon}
            node={{
              name: well.name + '-Actual Wellbore',
              level: 'ACTUAL_WELLBORE',
              nodeId: well.nodeId + '-Actual Wellbore',
              parentNodeId: well.parentNodeId,
            }}
          />
        }
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
        }}
      />
    ))
  }

  const ActualWellNodes = ({ actualWells }) => {
    if (!Array.isArray(actualWells)) return null
    actualWells = actualWells.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return actualWells.map((actualWell) => {
      return actualWell.level === 'ACTUALWELL' ? (
        <TreeNode
          key={`${actualWell.nodeId}`}
          nodeId={`${actualWell.nodeId}`}
          endIcon={<PlusSquare />}
          labelIcon={<TreeNodeIcon icon={actualWellIcon} node={actualWell} />}
          onLabelClick={() => {}}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(`${actualWell.nodeId}`)
          }}>
          <ActualWellboreNodes wellData={[actualWell]} />
          <SurveyNodes surveys={actualWell.children} />
        </TreeNode>
      ) : null
    })
  }

  const PlannedWellboreNodes = ({ wellData }) => {
    if (!Array.isArray(wellData)) return null
    wellData = wellData.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return wellData.map((well) => (
      <TreeNode
        key={`${well.nodeId}`}
        nodeId={`${well.nodeId}`}
        labelIcon={
          <TreeNodeIcon
            icon={wellboreIcon}
            node={{
              name: well.name + '-Planned Wellbore',
              level: 'PLANNED_WELLBORE',
              nodeId: well.nodeId + '-Planned Wellbore',
              parentNodeId: well.parentNodeId,
            }}
          />
        }
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
        }}
      />
    ))
  }

  const PlannedWellNodes = ({ plannedWells }) => {
    if (!Array.isArray(plannedWells)) return null

    plannedWells = plannedWells.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return plannedWells.map((plannedWell) => {
      return plannedWell.level === 'PLANNEDWELL' || plannedWell.level === 'PLANNEDWELL_PRINCIPAL' ? (
        <TreeNode
          key={`${plannedWell.nodeId}`}
          nodeId={`${plannedWell.nodeId}`}
          endIcon={<PlusSquare />}
          labelIcon={<TreeNodeIcon icon={plannedWellIcon} node={plannedWell} />}
          onLabelClick={() => {}}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(`${plannedWell.nodeId}`)
          }}>
          <PlannedWellboreNodes wellData={[plannedWell]} />
        </TreeNode>
      ) : null
    })
  }

  const WellNodes = ({ wells }) => {
    if (!Array.isArray(wells)) return null
    wells = wells.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return wells.map((well) => (
      <TreeNode
        key={`${well.nodeId}`}
        nodeId={`${well.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={wellIcon} node={well} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${well.nodeId}`)
        }}>
        <ActualWellNodes actualWells={well.children} />
        <PlannedWellNodes plannedWells={well.children} />
      </TreeNode>
    ))
  }

  const FacilityNodes = ({ facilities }) => {
    if (!Array.isArray(facilities)) return null
    facilities = facilities.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return facilities.map((facility) => (
      <TreeNode
        key={`${facility.nodeId}`}
        nodeId={`${facility.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={facilityIcon} node={facility} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${facility.nodeId}`)
        }}>
        <WellNodes wells={facility.children} />
      </TreeNode>
    ))
  }

  const FieldNodes = ({ fields }) => {
    if (!Array.isArray(fields)) return null
    fields = fields.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return fields.map((field) => (
      <TreeNode
        key={`${field.nodeId}`}
        nodeId={`${field.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={fieldIcon} node={field} />}
        onLabelClick={(event) => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${field.nodeId}`)
        }}>
        <FacilityNodes facilities={field.children} />
      </TreeNode>
    ))
  }

  const OperatorNodes = ({ operators }) => {
    if (!Array.isArray(operators)) return null
    operators = operators.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))

    return operators.map((op) => (
      <TreeNode
        key={`${op.nodeId}`}
        nodeId={`${op.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={operatorIcon} node={op} />}
        onIconClick={async (event) => {
          handleToggleNodeExpanded(`${op.nodeId}`)
        }}>
        <FieldNodes fields={op.children} />
      </TreeNode>
    ))
  }

  const handleRefresh = () => {
    saveExpandedNodes()
    expandedNodesLoaded.current = null
    setExpandedNodes([])

    fetchTreeData(false)

    let newSelectedNode = null
    if (selectedNode) {
      let node = flattenedNodes.current.find((n) => n.name === selectedNode.name && n.level === selectedNode.level)
      if (node) {
        newSelectedNode = cloneDeep(selectedNode)
        newSelectedNode.nodeId = node.nodeId
      }
    }

    setSelectedNode(newSelectedNode)
  }

  const getMenuItems = () => {
    return [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: handleRefresh,
      },
    ]
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getTreeBackColor() }}>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHideTree}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: treeVisible ? 'flex' : 'none',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(3)}
        />
      </Box>
      {showOasisModal ? (
        <OasisJobInfoModal
          wellData={selectedActualWell.current}
          onClose={(data) => {
            setShowOasisModal(false)
            handleUpdateWellInfo(data)
          }}
        />
      ) : null}
      {showSapModal ? (
        <SapJobInfoModal
          wellData={selectedActualWell.current}
          onClose={(data) => {
            setShowSapModal(false)
            handleUpdateWellInfo(data)
          }}
        />
      ) : null}
      {showSureShopModal ? (
        <SureShopJobInfoModal
          wellData={selectedActualWell.current}
          onClose={(data) => {
            setShowSureShopModal(false)
            handleUpdateWellInfo(data)
          }}
        />
      ) : null}
      {showOracleModal ? (
        <OracleJobInfoModal
          wellData={selectedActualWell.current}
          onClose={(data) => {
            setShowOracleModal(false)
            handleUpdateWellInfo(data)
          }}
        />
      ) : null}
      {showRenameModal ? <RenameTreeItemModal node={clickedNode.current} /> : null}
      {showCreateOffsetPlan ? (
        <CreateOffsetPlanModal
          node={clickedNode.current}
          open={showCreateOffsetPlan}
          onClose={handleCloseCreateOffsetPlan}
          handleCreateOffsetPlan={handleCreateOffsetPlan}
        />
      ) : null}
      {showTransferReportingModal ? <TransferReportingDataModal node={clickedNode.current} /> : null}
      {confirmDelete.show ? (
        <ConfirmDialog
          title={confirmDelete?.title}
          open={true}
          setOpen={() => setConfirmDelete({ show: false })}
          onConfirm={() => handleDeleteTreeItem()}>
          {confirmDelete?.text}
        </ConfirmDialog>
      ) : null}
      {confirmTransfer.show ? (
        <ConfirmDialog
          title={confirmTransfer?.title}
          open={true}
          setOpen={() => setConfirmTransfer({ show: false })}
          onConfirm={() => handleTransferReportingData(confirmTransfer.data)}>
          {confirmTransfer?.text}
        </ConfirmDialog>
      ) : null}
      {treeVisible ? (
        <TreeView
          aria-label='controlled'
          expanded={expandedNodes}
          sx={{
            overflow: 'auto',
            width: '100%',
            height: 'calc(100% - 83px)',
            marginTop: '10px',
          }}
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}>
          <TreeNode
            labelIcon={<TreeNodeIcon icon={databaseIcon} node={rawTreeData.current} />}
            nodeId={getRootNodeId()}
            onIconClick={async (event) => {
              handleToggleNodeExpanded(getRootNodeId())
            }}>
            <OperatorNodes operators={rawTreeData.current?.children} />
          </TreeNode>
        </TreeView>
      ) : null}
      <StyledMenuIcon>
        <MenuButton actions={getMenuItems()} />
      </StyledMenuIcon>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      {!treeVisible ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHideTree}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Object Explorer
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default DatabaseTree
