import React, { useRef, useMemo, forwardRef, useImperativeHandle, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { getStringId } from 'components/common/AgGridUtils'
import { Box } from '@mui/material'
import IPMRenameModal from './IPMRenameModal'

const IPMMissingModal = ({ ipmData, onCancel, onSwapIPM, ipms }) => {
  const gridApi = useRef(null)
  const gridDetailsApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const containerRef = useRef(null)
  const [showIPMSwapModal, setShowIPMSwapModal] = useState(false)
  const swapIPMRef = useRef(null)


  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getDetailsRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const gridOptions = {
    onCellEditingStopped: (params) => {},
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'wells',
        colId: 'wells',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 60,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'fileName',
        colId: 'fileName',
        headerName: 'Name',
        cellStyle: centerAlignCell,
      },
      {
        field: 'numberOfWells',
        colId: 'numberOfWells',
        headerName: '# Actuals',
        cellStyle: centerAlignCell,
      },
      {
        field: 'numberOfPlans',
        colId: 'numberOfPlans',
        headerName: '# Plans',
        cellStyle: centerAlignCell,
      },
    ],
    [centerAlignCell],
  )

  let detailColumnDefs = useMemo(
    () => [
      {
        field: 'wellName',
        colId: 'wellName',
        headerName: 'Well Name',
        cellStyle: centerAlignCell,
      },
      {
        field: 'isPlan',
        colId: 'isPlan',
        headerName: 'Plan',
        cellRenderer: 'agCheckboxCellRenderer',
      },
    ],
    [centerAlignCell],
  )

  const onFirstDataRendered = (params) => {
    autoSizeColumns(params)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(), 100)
  }

  const onDetailsFirstDataRendered = (params) => {
    autoSizeDetailsColumns(params)
  }

  const autoSizeDetailsColumns = () => {
    if (gridDetailsApi.current === null) return
    setTimeout(() => gridDetailsApi.current?.autoSizeAllColumns(), 100)
  }

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onDetailsGridReady = (params) => {
    gridDetailsApi.current = params.api
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 100
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.5
    }

    return (
      <Box sx={{ height: height }}>
        <AgGridReact
          rowData={params.data?.wellDetails ? params.data?.wellDetails : []}
          columnDefs={detailColumnDefs}
          defaultColDef={defaultColDef}
          getRowId={getDetailsRowId}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onDetailsGridReady}
          onFirstDataRendered={onDetailsFirstDataRendered}
        />
      </Box>
    )
  })

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Swap IPM',
        disabled: false,
        action: () => {
          swapIPMRef.current = params.node.data
          setShowIPMSwapModal(true)
        },
        icon: '<span class="iconify" data-icon="bx:rename" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      }
    ]
  }

  return (
    <React.Fragment>
      {showIPMSwapModal ? (
        <IPMRenameModal isOpen={showIPMSwapModal} onClose={() => setShowIPMSwapModal(false)} ipm = {swapIPMRef.current} ipms ={ipms} onRename={onSwapIPM} />
      ) : null}
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        PaperProps={{
          sx: {
            height: '80vh',
            width: '80vw',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => onCancel()}>
        <DialogTitle>Missing IPM</DialogTitle>
        <DialogContent>
          <Box ref={containerRef} sx={{ display: 'flex', width: '100%', height: '100%' }}>
            <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
              <AgGridReact
                rowData={ipmData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
                gridOptions={gridOptions}
                headerHeight={30}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                masterDetail={true}
                detailCellRenderer={DetailCellRenderer}
                detailRowAutoHeight={true}
                getContextMenuItems={getContextMenuItems}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => onCancel()} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default IPMMissingModal
