import React, { useEffect, useRef, useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import * as XLSX from '@sheet/core'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import {
  surgeAndSwabMinMaxEmw,
  currentEngBhaHeadersAtom,
  currentSelEngBhaHeadersAtom,
  currentBhaAtom,
  engineeringDashboardSelectedChartAtom,
  selectedEngineeringCaseAtom,
  currentWellAtom,
} from 'atoms'
import { useRecoilValue, useRecoilState } from 'recoil'
import useCasingStandOff from 'components/common/hooks/Engineering/useCasingStandOff'
import useDrillingParams from 'components/common/hooks/Engineering/useDrillingParams'
import useHydraulics from 'components/common/hooks/Engineering/useHydraulics'
import useTorqueAndDrag from 'components/common/hooks/Engineering/useTorqueAndDrag'
import useBhaAnalysis from 'components/common/hooks/Engineering/useBhaAnalysis'
import EngineeringChart from './EngineeringChart'
import BhaTendencyChart from './BhaTendencyChart'
import BhaCriticalRpmChart from './BhaCriticalRpmChart'
import BhaModeShapesChart from './BhaModeShapesChart'
import SimplePickListDialog from 'components/common/SimplePickListDialog'
import useWitsDrillingData from 'components/common/hooks/Engineering/useWitsDrillingData'
import * as yup from 'yup'
import InputModal from 'components/common/InputModal'
import EngineeringToolbar from '../EngineeringToolBar'
import BhaAnalysisChartsPage from 'components/WellPages/EngineeringDashboard/BhaAnalysis/BhaAnalysisChartsPage'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import StandOffChartsReport from './StandOffChartsReport'
import WellCharts from './WellCharts'
import useWellData from 'components/common/hooks/useWellData'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import BhaChartsReport from './BhaChartsReport'
import HydraulicsChartsReport from './HydraulicsChartsReport'
import BhaAnalysis3dChartsPage from '../BhaAnalysis/ThreeDeeChart/BhaAnalysis3dChartsPage'
import TndChartsReport from './TndChartsReport'
import EngineeringReportSettingsModal from '../EngineeringReportSettingsModal'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'

const EngineeringDashboardChartsPane = () => {
  const _isMounted = useRef(false)
  const [toolbarExpanded, setToolbarExpanded] = useState(false)
  const { getUnitsText } = useUnits()
  const curEngBhaHeaders = useRecoilValue(currentEngBhaHeadersAtom)
  const currentBha = useRecoilValue(currentBhaAtom)
  const chartRef = useRef(null)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const [selectedChart, setSelectedChart] = useRecoilState(engineeringDashboardSelectedChartAtom)
  const [showSelectBhas, setShowSelectBhas] = useState(false)
  const [showLegend, setShowLegend] = useState(true)
  const [shadeBuckling, setShadeBuckling] = useState(false)
  const [curSelEngBhaHeaders, setCurSelEngBhaHeaders] = useRecoilState(currentSelEngBhaHeadersAtom)
  const [showSlideSheetParams, setShowSlideSheetParams] = useState(false)
  const { loading: slideSheetLoading, getSlideSheetData, fetchSlideSheetData } = useDrillingParams()
  const [showCasing, setShowCasing] = useState(false)
  const [showWitsData, setShowWitsData] = useState(false)
  const { loading: witsDrillingDataLoading, getWitsDrillingData, fetchWitsDrillingData } = useWitsDrillingData()
  const [showSelectWitsDepthRange, setShowSelectWitsDepthRange] = useState(false)
  const witsDepthRef = useRef({ startDepth: 0, endDepth: 50000 })
  const minMaxSsEmw = useRecoilValue(surgeAndSwabMinMaxEmw)
  const chartStandoffRef = useRef(null)
  const chartWellRef = useRef(null)
  const chartBhaRef = useRef(null)
  const chartHydraulicsRef = useRef(null)
  const chartTndRef = useRef(null)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const [printing, setPrinting] = useState(false)
  const { getChartBackColor } = useInnovaTheme()
  const [showEngineeringReportSettings, setShowEngineeringReportSettings] = useState(false)

  const {
    loading: bhaLoading,
    fetchBhaAnalysisData,
    fetchBhaTendencyData,
    fetchTrajectoryData,
    getBhaAnalysisData,
    getBhaPdfData,
    getBhaAnalysisErrors,
    getBhaAnalysisWarnings,
    getBhaTendencyData,
    getTrajectoryData,
    getBhaTendencyUnits,
    getBhaTendencyVariable,
    getBhaTendencyErrors,
    getBhaTendencyWarnings,
    fetchBhaCriticalRPMData,
    getBhaModeShapesData,
    getBhaCriticalRPMData,
    getBhaCriticalRPMUnits,
    getBhaCriticalRPMVariable,
    getBhaCriticalRPMErrors,
    getBhaCriticalRPMWarnings,
    getBhaCasing,
    getReportDetails,
  } = useBhaAnalysis()

  const {
    loading: hydLoading,
    fetchHydraulics,
    getPumpPressureData,
    getEcdData,
    getGeometryAnnotations,
    getHydWarnings,
    getSurgeAndSwabData,
    getMinMaxTripSpeedData,
    getHydPdfData,
    getHydErrors,
    getEcdSnapShotData,
    getMinFlowData,
    getKickToleranceData,
  } = useHydraulics()

  const {
    loading: tndLoading,
    fetchTnd,
    getTndWarnings,
    getDrillersHookloadsData,
    getDrillersTorquesData,
    getTensionSnapshotData,
    getTndPdfData,
    getTndErrors,
    getTensionOffBtmSnapshotData,
    getSideForceData,
    getAppWobData,
    getWrapsData,
    getStretchData,
    getSideForceUnits,
    getWOBtext,
    getCalculatedFrictionFactorData,
  } = useTorqueAndDrag()

  const {
    loading: standOffLoading,
    fetchStandOff,
    getStandOffData,
    getStandOffGeometryAnnotations,
    getStandOffWarnings,
    getStandOffErrors,
    getStandOffPdfData,
    getStandOffHookloadData,
    getStandOffTorqueData,
    getStandOffCentSpacingData,
    getDLSData,
    getStandOffCasing,
  } = useCasingStandOff()

  const { wellData } = useWellData(currentWell)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (isChartCasingStandoff()) {
      fetchStandOff()
      return
    }

    if (isChartBhaAnalysis()) {
      fetchBhaAnalysisData()
      return
    }

    if (isChartBhaTendency()) {
      fetchTrajectoryData()
      fetchBhaTendencyData()
      return
    }

    if (isChartBhaCriticalRPM() || isChartModeShapes()) {
      fetchBhaCriticalRPMData()
      return
    }

    if (isChartHydraulics()) {
      fetchHydraulics()
      return
    }

    fetchTnd()
  }, [selectedChart, selectedEngCase, currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isChartBhaTendency()) {
      fetchTrajectoryData()
    }
  }, [currentBha]) // eslint-disable-line react-hooks/exhaustive-deps

  const getWarnings = () => {
    if (isChartBhaAnalysis()) return getBhaAnalysisWarnings()
    if (isChartBhaTendency()) return getBhaTendencyWarnings()
    if (isChartBhaCriticalRPM() || isChartModeShapes()) return getBhaCriticalRPMWarnings()
    if (isChartCasingStandoff()) return getStandOffWarnings()
    if (isChartHydraulics()) return getHydWarnings()
    return getTndWarnings()
  }

  const getErrors = () => {
    if (isChartCasingStandoff()) return getStandOffErrors()
    if (isChartBhaAnalysis()) return getBhaAnalysisErrors()
    if (isChartBhaCriticalRPM() || isChartModeShapes()) return getBhaCriticalRPMErrors()
    if (isChartBhaTendency()) return getBhaTendencyErrors()
    return isChartHydraulics() ? getHydErrors() : getTndErrors()
  }

  const toggleCasing = () => {
    setShowCasing(!showCasing)
  }

  const toggleLegend = () => {
    setShowLegend(!showLegend)
  }

  const toggleShadebucking = () => {
    setShadeBuckling(!shadeBuckling)
  }

  const toggleSlidesheetParams = () => {
    setShowSlideSheetParams(!showSlideSheetParams)
  }

  const toggleWitsData = () => {
    setShowWitsData(!showWitsData)
  }

  const toggleShowSelectBhas = () => {
    setShowSelectBhas(!showSelectBhas)
  }

  const toggleShowSelectWitsDepthRange = () => {
    setShowSelectWitsDepthRange(!showSelectWitsDepthRange)
  }

  const isChartHydraulics = () => {
    if (selectedChart === 'pumpPressure') return true
    if (selectedChart === 'ecd') return true
    if (selectedChart === 'surgeAndSwab') return true
    if (selectedChart === 'minMaxTripSpeed') return true
    if (selectedChart === 'ecdSnapshot') return true
    if (selectedChart === 'minFlow') return true
    if (selectedChart === 'kickTolerance') return true
    return false
  }

  const isChartBhaAnalysis = () => {
    if (selectedChart === 'forcesAndDeflections') return true
    if (selectedChart === 'forcesAndDeflectionsContour') return true
    return false
  }

  const isChartBhaTendency = () => {
    return selectedChart === 'directionalTendencyChart'
  }

  const isChartBhaCriticalRPM = () => {
    return selectedChart === 'criticalRpmChart'
  }

  const isChartModeShapes = () => {
    return selectedChart === 'modeShapesChart'
  }

  const isChartBha = () => {
    if (isChartBhaAnalysis()) return true
    if (isChartBhaAnalysis3D()) return true
    if (isChartBhaTendency()) return true
    if (isChartBhaCriticalRPM()) return true
    if (isChartModeShapes()) return true
    return false
  }

  const isChartCasingStandoff = () => {
    if (selectedChart === 'casingStandOff') return true
    if (selectedChart === 'standOffHookloads') return true
    if (selectedChart === 'standOffTorque') return true
    if (selectedChart === 'standOffCentSpacing') return true
    if (selectedChart === 'standOffDLS') return true
    return false
  }

  const WitsDepthRangeModal = ({ data, submitFunction }) => {
    if (!data) return null
    const formValidationSchema = yup.object({
      startDepth: yup.number().typeError('Start Depth must be a number').required('Start Depth is required'),
      endDepth: yup.number().typeError('End Depth must be a number').required('End Depth is required'),
    })

    let formData = [
      {
        tag: 'startDepth',
        value: data.startDepth,
        text: 'Start Depth',
        inputType: 'text',
      },
      {
        tag: 'endDepth',
        value: data.endDepth,
        text: 'End Depth',
        inputType: 'text',
      },
    ]

    const title = `Select WITS Depth Range`

    return (
      <InputModal
        open={showSelectWitsDepthRange}
        onClose={() => setShowSelectWitsDepthRange(false)}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={() => setShowSelectWitsDepthRange(false)}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleWitsDepthRangeUpdate = async (data) => {
    if (!data || !witsDepthRef.current) return
    if (!data.hasOwnProperty('startDepth')) return
    witsDepthRef.current.startDepth = data.startDepth
    witsDepthRef.current.endDepth = data.endDepth
    setShowSelectWitsDepthRange(false)
  }

  const onXlsxExport = () => {
    if (isChartBhaAnalysis()) {
      const ws = XLSX.utils.json_to_sheet(getBhaAnalysisData()?.results)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, selectedChart)
      XLSX.writeFile(wb, `${selectedChart}.xlsx`)
      return
    }

    let chartData = []
    if (selectedChart === 'pumpPressure') chartData = getPumpPressureData()
    if (selectedChart === 'ecd') chartData = getEcdData()
    if (selectedChart === 'surgeAndSwab') chartData = getSurgeAndSwabData()
    if (selectedChart === 'minMaxTripSpeed') {
      chartData = getMinMaxTripSpeedData(minMaxSsEmw?.minEmw, minMaxSsEmw?.maxEmw)
    }
    if (selectedChart === 'ecdSnapshot') chartData = getEcdSnapShotData()
    if (selectedChart === 'minFlow') chartData = getMinFlowData()
    if (selectedChart === 'drillersHookloads') chartData = getDrillersHookloadsData()
    if (selectedChart === 'drillersTorques') chartData = getDrillersTorquesData()
    if (selectedChart === 'tensionSnapshot') chartData = getTensionSnapshotData(false)
    if (selectedChart === 'tensionOffBtmSnapshot') chartData = getTensionOffBtmSnapshotData()
    if (selectedChart === 'sideForce') chartData = getSideForceData()
    if (selectedChart === 'appWob') chartData = getAppWobData()
    if (selectedChart === 'wraps') chartData = getWrapsData()
    if (selectedChart === 'stretch') chartData = getStretchData()
    if (selectedChart === 'casingStandOff') chartData = getStandOffData()
    if (selectedChart === 'standOffHookloads') chartData = getStandOffHookloadData()
    if (selectedChart === 'standOffTorque') chartData = getStandOffTorqueData()
    if (selectedChart === 'standOffCentSpacing') chartData = getStandOffCentSpacingData()
    if (selectedChart === 'standOffDLS') chartData = getDLSData()

    if (selectedChart === 'calculatedFrictionFactors') {
      let slideSheetData = getSlideSheetData('drillersHookloads')
      let witsData = getWitsDrillingData('drillersHookloads', witsDepthRef.current)

      chartData = getCalculatedFrictionFactorData(slideSheetData, witsData)
    }

    if (!chartData.hasOwnProperty('datasets')) return
    if (!Array.isArray(chartData.datasets)) return
    if (chartData?.datasets.length === 0) return

    let headers = [`Depth (${getUnitsText(UNITS_FOR.Depth)})`]
    for (let i = 0; i < chartData.datasets.length; i++) {
      let label = chartData.datasets[i].label
      if (selectedChart === 'pumpPressure') {
        label = label
          .replace('(gpm)', 'gpm')
          .replace('(lpm)', 'lpm')
          .replace('SPP @', `SPP (${getUnitsText(UNITS_FOR.Pressure)}) @`)
      }

      if (selectedChart === 'ecd') {
        label = label
          .replace('(gpm)', 'gpm')
          .replace('(lpm)', 'lpm')
          .replace('ECD @', `ECD (${getUnitsText(UNITS_FOR.MudWeight)}) @`)
      }

      headers.push(label)
    }

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    let xlExport = []
    for (let i = 0; i < chartData.datasets[0].data.length; i++) {
      let newRow = [chartData.datasets[0].data[i].y]
      for (let j = 0; j < chartData.datasets.length; j++) {
        newRow.push(chartData.datasets[j].data[i].x)
      }
      xlExport.push(newRow)
    }

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, selectedChart)

    XLSX.writeFile(wb, `${selectedChart}.xlsx`)
  }

  const isChartBhaAnalysis3D = () => {
    if (selectedChart === 'forcesAndDeflections3d') return true
    return false
  }

  const onPdfExport = async () => {
    let pdfDoc = null

    setPrinting(true)

    //Casing Standoff
    if (isChartCasingStandoff()) {
      pdfDoc = await getStandOffPdfData(chartStandoffRef.current, chartWellRef.current)
    }

    //BHA Analysis Charts
    if (isChartBha()) {
      const reportDetails = getReportDetails()
      const bhaAnalysisTendencies = reportDetails.find((detail) => detail.reportType === 'Directional Tendency')
      const bhaCriticalRpm = reportDetails.find((detail) => detail.reportType === 'Critical RPM & Mode Shape')

      await fetchBhaAnalysisData()
      if (
        bhaAnalysisTendencies &&
        (bhaAnalysisTendencies?.charts || bhaAnalysisTendencies?.summaryData || bhaAnalysisTendencies?.calculatedData)
      ) {
        await fetchBhaTendencyData()
        await fetchTrajectoryData()
      }
      if (bhaCriticalRpm && (bhaCriticalRpm?.charts || bhaCriticalRpm?.summaryData || bhaCriticalRpm?.calculatedData)) {
        await fetchBhaCriticalRPMData()
      }
      pdfDoc = await getBhaPdfData(chartBhaRef.current, chartWellRef.current)
    }

    //Hydraulics Charts
    if (isChartHydraulics()) {
      pdfDoc = await getHydPdfData(chartHydraulicsRef.current, chartWellRef.current, selectedChart)
    }

    //TND Charts
    if (!isChartBha() && !isChartCasingStandoff() && !isChartHydraulics()) {
        let slideSheetData = getSlideSheetData('drillersHookloads')
        let witsData = getWitsDrillingData('drillersHookloads', witsDepthRef.current)
      pdfDoc = await getTndPdfData(chartTndRef.current, chartWellRef.current, slideSheetData, witsData)
    }

    if (_isMounted.current) {
      setPrinting(false)
    }

    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const getCurrentBhaList = () => {
    if (curEngBhaHeaders === null || curEngBhaHeaders === undefined) return []
    if (!Array.isArray(curEngBhaHeaders)) return []

    let output = []
    for (let i = 0; i < curEngBhaHeaders.length; i++) {
      output.push(`${curEngBhaHeaders[i].bhaNumRep}:${curEngBhaHeaders[i].description}`)
    }

    return output
  }

  const getCurrentSelectedBhaList = () => {
    if (curEngBhaHeaders === null || curEngBhaHeaders === undefined) return []
    if (!Array.isArray(curEngBhaHeaders)) return []
    if (curSelEngBhaHeaders === null || curSelEngBhaHeaders === undefined) return []
    if (!Array.isArray(curSelEngBhaHeaders)) return []

    let output = []
    for (let i = 0; i < curSelEngBhaHeaders.length; i++) {
      let index = curEngBhaHeaders.findIndex((bha) => bha.bhaNum === curSelEngBhaHeaders[i])
      if (index < 0) continue

      output.push(`${curEngBhaHeaders[index].bhaNumRep}:${curEngBhaHeaders[index].description}`)
    }

    if (currentBha === null || currentBha === undefined) return output
    let index = curEngBhaHeaders.findIndex((bha) => bha.bhaNum === currentBha?.bhaNum)
    if (index >= 0) {
      output.push(`${curEngBhaHeaders[index].bhaNumRep}:${curEngBhaHeaders[index].description}`)
    }

    return output
  }

  const updateBhaSelection = (selectedBhas) => {
    if (selectedBhas === null || selectedBhas === undefined) return []
    if (!Array.isArray(selectedBhas)) return []

    let output = []
    for (let i = 0; i < selectedBhas.length; i++) {
      let index = curEngBhaHeaders.findIndex((bha) => `${bha.bhaNumRep}:${bha.description}` === selectedBhas[i])
      if (index < 0) continue
      if (curEngBhaHeaders[index].bhaNum === currentBha?.bhaNum) continue
      output.push(curEngBhaHeaders[index].bhaNum)
    }

    setCurSelEngBhaHeaders(output)
  }

  const getChartType = (chart) => {
    if (chart === 'pumpPressure') return 'Hydraulics'
    if (chart === 'ecd') return 'Hydraulics'
    if (chart === 'surgeAndSwab') return 'Hydraulics'
    if (chart === 'minMaxTripSpeed') return 'Hydraulics'
    if (chart === 'drillersHookloads') return 'Torque & Drag'
    if (chart === 'calculatedFrictionFactors') return 'Torque & Drag'
    if (chart === 'drillersTorques') return 'Torque & Drag'
    if (chart === 'tensionSnapshot') return 'Torque & Drag'
    if (chart === 'minFlow') return 'Hydraulics'
    if (chart === 'ecdSnapshot') return 'Hydraulics'
    if (chart === 'kickTolerance') return 'Hydraulics'
    if (chart === 'tensionOffBtmSnapshot') return 'Torque & Drag'
    if (chart === 'sideForce') return 'Torque & Drag'
    if (chart === 'appWob') return 'Torque & Drag'
    if (chart === 'wraps') return 'Torque & Drag'
    if (chart === 'stretch') return 'Torque & Drag'
    if (chart === 'forcesAndDeflections') return 'Bha Analysis'
    if (chart === 'forcesAndDeflectionsContour') return 'Bha Analysis'
    if (chart === 'forcesAndDeflections3d') return 'Bha Analysis'
    if (chart === 'directionalTendencyChart') return 'Bha Tendency'
    if (chart === 'criticalRpmChart') return 'Critical RPM'
    if (chart === 'modeShapesChart') return 'Mode Shapes'
    if (chart === 'casingStandOff') return 'Casing Standoff'
    if (chart === 'standOffHookloads') return 'Hookloads'
    if (chart === 'standOffTorque') return 'Torque'
    if (chart === 'standOffCentSpacing') return 'Centralizer Spacing'
    if (chart === 'standOffDLS') return 'DLS'
    return ''
  }

  const getReportData = () => {
    if (getItemFromLS('engineeringReportDetails', 'engineeringReportDetails'))
      return getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')

    let reportTypes = []
    let reportDetails = []
    reportDetails.push({ reportType: 'Pump Pressure', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'ECD', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'ECD Snapshot', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Surge and Swab', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Tripping Speeds', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Minimum Flow', charts: true, summaryData: true, calculatedData: true })
    reportTypes.push({ reportName: 'Hydraulics', reportDetails: reportDetails })

    reportDetails = []
    reportDetails.push({ reportType: 'Drillers Hookloads', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({
      reportType: 'Calculated Friction Factor',
      charts: true,
      summaryData: true,
      calculatedData: true,
    })
    reportDetails.push({ reportType: 'Driller Torques', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'On Btm Tension Snapshot', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({
      reportType: 'Off Btm Tension Snapshot',
      charts: true,
      summaryData: true,
      calculatedData: true,
    })
    reportDetails.push({ reportType: 'Side Force', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Apparent WOB', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Pipe Twist', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Pipe Stretch', charts: true, summaryData: true, calculatedData: true })
    reportTypes.push({ reportName: 'Torque & Drag', reportDetails: reportDetails })

    reportDetails = []
    reportDetails.push({ reportType: 'Kick Tolerance', charts: true, summaryData: true, calculatedData: true })
    reportTypes.push({ reportName: 'Well Control', reportDetails: reportDetails })

    reportDetails = []
    reportDetails.push({ reportType: 'BHA Analysis Stresses', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({ reportType: 'Directional Tendency', charts: true, summaryData: true, calculatedData: true })
    reportDetails.push({
      reportType: 'Critical RPM & Mode Shape',
      charts: true,
      summaryData: true,
      calculatedData: true,
    })
    reportTypes.push({ reportName: 'Bha Analysis', reportDetails: reportDetails })

    saveItemToLS('engineeringReportDetails', 'engineeringReportDetails', reportTypes)
    return reportTypes
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      {showSelectBhas ? (
        <SimplePickListDialog
          title='Select BHA'
          sectionHeader='BHA'
          open={showSelectBhas}
          setOpen={() => setShowSelectBhas(false)}
          onApply={updateBhaSelection}
          items={getCurrentBhaList()}
          initSelItems={getCurrentSelectedBhaList()}
        />
      ) : null}
      {showSelectWitsDepthRange ? (
        <WitsDepthRangeModal data={witsDepthRef.current} submitFunction={handleWitsDepthRangeUpdate} />
      ) : null}
      <EngineeringToolbar
        toggleToolbarExpanded={() => setToolbarExpanded(!toolbarExpanded)}
        toolbarExpanded={toolbarExpanded}
        selectedChart={selectedChart}
        setSelectedChart={setSelectedChart}
        onXlsxExport={onXlsxExport}
        onPdfExport={onPdfExport}
        toggleCasing={toggleCasing}
        toggleLegend={toggleLegend}
        showLegend={showLegend}
        showCasing={showCasing}
        toggleShadebucking={toggleShadebucking}
        shadeBuckling={shadeBuckling}
        toggleSlidesheetParams={toggleSlidesheetParams}
        toggleWitsData={toggleWitsData}
        toggleShowSelectBhas={toggleShowSelectBhas}
        toggleShowSelectWitsDepthRange={toggleShowSelectWitsDepthRange}
        showSlideSheetParams={showSlideSheetParams}
        showWitsData={showWitsData}
        getErrors={getErrors}
        getWarnings={getWarnings}
        chartType={getChartType(selectedChart)}
        setShowEngineeringReportSettings={setShowEngineeringReportSettings}
      />
      <Box ref={chartRef} sx={{ width: '100%', height: '100%' }}>
        {selectedChart === 'pumpPressure' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getPumpPressureData()}
            loading={hydLoading || slideSheetLoading || witsDrillingDataLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Pump Pressure'}
            xAxisLabel={'Pressure'}
            units={getUnitsText(UNITS_FOR.Pressure)}
            xBeginAtZero={false}
            slideSheetData={showSlideSheetParams ? getSlideSheetData(selectedChart) : []}
            witsData={showWitsData ? getWitsDrillingData(selectedChart, witsDepthRef.current) : []}
          />
        )}
        {selectedChart === 'ecd' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getEcdData()}
            loading={hydLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'ECD'}
            xAxisLabel={'ECD'}
            units={getUnitsText(UNITS_FOR.MudWeight)}
            xBeginAtZero={false}
          />
        )}
        {selectedChart === 'surgeAndSwab' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getSurgeAndSwabData()}
            loading={hydLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Surge & Swab'}
            xAxisLabel={'EMW'}
            units={getUnitsText(UNITS_FOR.MudWeight)}
            xBeginAtZero={false}
          />
        )}
        {selectedChart === 'minMaxTripSpeed' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getMinMaxTripSpeedData(minMaxSsEmw?.minEmw, minMaxSsEmw?.maxEmw)}
            loading={hydLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={`Trip Speed Limits (Min EMW: ${minMaxSsEmw?.minEmw}, Max EMW: ${minMaxSsEmw?.maxEmw}) `}
            xAxisLabel={'Trip Speed'}
            units={`${getUnitsText(UNITS_FOR.Depth)}/min`}
            xBeginAtZero={false}
          />
        )}
        {selectedChart === 'drillersHookloads' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getDrillersHookloadsData(shadeBuckling)}
            loading={tndLoading || slideSheetLoading || witsDrillingDataLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Drillers Hookloads'}
            xAxisLabel={'Hookload'}
            units={getUnitsText(UNITS_FOR.Weight)}
            slideSheetData={showSlideSheetParams ? getSlideSheetData(selectedChart) : []}
            witsData={showWitsData ? getWitsDrillingData(selectedChart, witsDepthRef.current) : []}
          />
        )}
        {selectedChart === 'calculatedFrictionFactors' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getCalculatedFrictionFactorData(
              getSlideSheetData('drillersHookloads'),
              getWitsDrillingData('drillersHookloads', witsDepthRef.current),
            )}
            loading={tndLoading || slideSheetLoading || witsDrillingDataLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Calculated Friction Factors'}
            xAxisLabel={'Friction Factor'}
            units={''}
          />
        )}
        {selectedChart === 'drillersTorques' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getDrillersTorquesData()}
            loading={tndLoading || slideSheetLoading || witsDrillingDataLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Drillers Torques'}
            xAxisLabel={'Torque'}
            units={getUnitsText(UNITS_FOR.Torque)}
            slideSheetData={showSlideSheetParams ? getSlideSheetData(selectedChart) : []}
            witsData={showWitsData ? getWitsDrillingData(selectedChart, witsDepthRef.current) : []}
          />
        )}
        {selectedChart === 'tensionSnapshot' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getTensionSnapshotData(shadeBuckling)}
            loading={tndLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'On Bottom Tension Snapshot'}
            xAxisLabel={'Tension'}
            units={getUnitsText(UNITS_FOR.Weight)}
          />
        )}
        {selectedChart === 'minFlow' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getMinFlowData()}
            loading={hydLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Min Flow For Hole Cleaning'}
            xAxisLabel={'Flow Rate'}
            units={getUnitsText(UNITS_FOR.FlowRate)}
            xBeginAtZero={false}
            slideSheetData={showSlideSheetParams ? getSlideSheetData(selectedChart) : []}
          />
        )}
        {selectedChart === 'ecdSnapshot' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getEcdSnapShotData()}
            loading={hydLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'ECD Snapshot'}
            xAxisLabel={'ECD'}
            units={getUnitsText(UNITS_FOR.MudWeight)}
            xBeginAtZero={false}
          />
        )}
        {selectedChart === 'kickTolerance' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getKickToleranceData(shadeBuckling)}
            loading={hydLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Kick Tolerance'}
            xAxisLabel={'Kick Tolerance'}
            units={getUnitsText(UNITS_FOR.Volume)}
            xBeginAtZero={true}
          />
        )}
        {selectedChart === 'tensionOffBtmSnapshot' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getTensionOffBtmSnapshotData(shadeBuckling)}
            loading={tndLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Off Bottom Tension Snapshot'}
            xAxisLabel={'Tension'}
            units={getUnitsText(UNITS_FOR.Weight)}
          />
        )}
        {selectedChart === 'sideForce' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getSideForceData()}
            loading={tndLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={'Sideforce'}
            xAxisLabel={'Sideforce'}
            units={`${getSideForceUnits()}`}
          />
        )}
        {selectedChart === 'appWob' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getAppWobData()}
            loading={tndLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={`Apparent WOB - ${getWOBtext()}`}
            xAxisLabel={'Weight'}
            units={getUnitsText(UNITS_FOR.Weight)}
            slideSheetData={showSlideSheetParams ? getSlideSheetData(selectedChart) : []}
          />
        )}
        {selectedChart === 'wraps' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getWrapsData()}
            loading={tndLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={`Pipe Twist`}
            xAxisLabel={'Wraps'}
            units={''}
          />
        )}
        {selectedChart === 'stretch' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getStretchData()}
            loading={tndLoading || slideSheetLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getGeometryAnnotations()}
            title={`Pipe Stretch`}
            xAxisLabel={'Stretch'}
            units={'in'}
          />
        )}
        {selectedChart === 'casingStandOff' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getStandOffData()}
            loading={standOffLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getStandOffGeometryAnnotations()}
            title={'Casing Stand Off'}
            xAxisLabel={'Stand Off'}
            units={'%'}
            xBeginAtZero={true}
          />
        )}
        {selectedChart === 'standOffHookloads' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getStandOffHookloadData()}
            loading={standOffLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getStandOffGeometryAnnotations()}
            title={'Hookloads'}
            xAxisLabel={'Weight'}
            units={getUnitsText(UNITS_FOR.Weight)}
            xBeginAtZero={true}
          />
        )}
        {selectedChart === 'standOffTorque' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getStandOffTorqueData()}
            loading={standOffLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getStandOffGeometryAnnotations()}
            title={'Torque'}
            xAxisLabel={'Torque'}
            units={getUnitsText(UNITS_FOR.Torque)}
            xBeginAtZero={true}
          />
        )}
        {selectedChart === 'standOffCentSpacing' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getStandOffCentSpacingData()}
            loading={standOffLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getStandOffGeometryAnnotations()}
            title={'Centralizer Spacing'}
            xAxisLabel={'Spacing'}
            units={getUnitsText(UNITS_FOR.Depth)}
            xBeginAtZero={true}
          />
        )}
        {selectedChart === 'standOffDLS' && (
          <EngineeringChart
            isToolbarExpanded={toolbarExpanded}
            chartData={getDLSData()}
            loading={standOffLoading || printing}
            showLegend={showLegend}
            showCasing={showCasing}
            casingAnnotations={getStandOffGeometryAnnotations()}
            title={'DLS'}
            xAxisLabel={'DLS'}
            units={getUnitsText(UNITS_FOR.Dogleg)}
            xBeginAtZero={true}
          />
        )}
        {isChartBhaAnalysis() && (
          <BhaAnalysisChartsPage
            isToolbarExpanded={toolbarExpanded}
            chartType={selectedChart}
            isLoading={bhaLoading || printing}
            data={bhaLoading ? [] : getBhaAnalysisData()}
          />
        )}
        {isChartBhaAnalysis3D() && (
          <BhaAnalysis3dChartsPage
            isToolbarExpanded={toolbarExpanded}
            chartType={selectedChart}
            isLoading={bhaLoading || printing}
            data={bhaLoading ? [] : getBhaAnalysisData()}
          />
        )}
        {selectedChart === 'directionalTendencyChart' && (
          <BhaTendencyChart
            isToolbarExpanded={toolbarExpanded}
            chartData={
              bhaLoading
                ? {
                    labels: ['x', 'y'],
                    datasets: [],
                  }
                : getBhaTendencyData()
            }
            loading={bhaLoading || printing}
            showLegend={showLegend}
            trajectoryData={showSlideSheetParams ? getTrajectoryData() : []}
            units={getBhaTendencyUnits()}
            variable={getBhaTendencyVariable()}
          />
        )}
        {selectedChart === 'criticalRpmChart' && (
          <BhaCriticalRpmChart
            isToolbarExpanded={toolbarExpanded}
            chartData={
              bhaLoading
                ? {
                    labels: ['x', 'y'],
                    datasets: [],
                  }
                : getBhaCriticalRPMData()
            }
            chartUnits={getBhaCriticalRPMUnits()}
            loading={bhaLoading || printing}
            showLegend={showLegend}
            slideSheetData={showSlideSheetParams ? getSlideSheetData(selectedChart) : []}
            witsData={showWitsData ? getWitsDrillingData(selectedChart, witsDepthRef.current) : []}
          />
        )}
        {selectedChart === 'modeShapesChart' && (
          <BhaModeShapesChart
            isToolbarExpanded={toolbarExpanded}
            chartData={
              bhaLoading
                ? {
                    labels: ['x', 'y'],
                    datasets: [],
                  }
                : getBhaModeShapesData()
            }
            chartTitle={getBhaCriticalRPMVariable()}
            loading={bhaLoading || printing}
            showLegend={showLegend}
          />
        )}
        {showEngineeringReportSettings ? (
          <EngineeringReportSettingsModal
            onClose={setShowEngineeringReportSettings}
            reportData={getReportData()}
            selectedChart={selectedChart}
          />
        ) : null}
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {isChartCasingStandoff() && (
          <StandOffChartsReport
            props={{
              standOffData: getStandOffData(),
              standOffHookloadData: getStandOffHookloadData(),
              standOffTorqueData: getStandOffTorqueData(),
              standOffCentSpacingData: getStandOffCentSpacingData(),
              standOffDLSData: getDLSData(),
            }}
            ref={chartStandoffRef}
          />
        )}
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {isChartCasingStandoff() && (
          <WellCharts props={{ casingData: getStandOffCasing(), wellData: wellData }} ref={chartWellRef} />
        )}
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {isChartBha() && (
          <BhaChartsReport
            props={{
              analysisData: getBhaAnalysisData(),
              tendencyData: getBhaTendencyData(),
              tendencyUnits: getBhaTendencyUnits(),
              tendencyVariable: getBhaTendencyVariable(),
              criticalRpmData: getBhaCriticalRPMData(),
              criticalRpmUnits: getBhaCriticalRPMUnits(),
              modeShapesData: getBhaModeShapesData(),
              modeShapesTitle: getBhaCriticalRPMVariable(),
            }}
            ref={chartBhaRef}
          />
        )}
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {isChartBha() && <WellCharts props={{ casingData: getBhaCasing(), wellData: wellData }} ref={chartWellRef} />}
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {isChartHydraulics() && (
          <HydraulicsChartsReport
            props={{
              pumpPressureData: getPumpPressureData(),
              ecdData: getEcdData(),
              surgeAndSwabData: getSurgeAndSwabData(),
              minMaxTripSpeedData: getMinMaxTripSpeedData(minMaxSsEmw?.minEmw, minMaxSsEmw?.maxEmw),
              minEmw: minMaxSsEmw?.minEmw,
              maxEmw: minMaxSsEmw?.maxEmw,
              ecdSnapshotData: getEcdSnapShotData(),
              minFlowData: getMinFlowData(),
              kickToleranceData: getKickToleranceData(shadeBuckling),
              geometryAnnotations: getGeometryAnnotations(),
            }}
            ref={chartHydraulicsRef}
          />
        )}
      </Box>
      <Box sx={{ width: '100%', height: '100%' }}>
        {!isChartBha() && !isChartCasingStandoff() && !isChartHydraulics() && (
          <TndChartsReport
            props={{
              drillersHookloadsData: getDrillersHookloadsData(shadeBuckling),
              calculatedFrictionFactorData: getCalculatedFrictionFactorData(
                getSlideSheetData('drillersHookloads'),
                getWitsDrillingData('drillersHookloads', witsDepthRef.current),
              ),
              drillersTorquesData: getDrillersTorquesData(),
              tensionSnapshotData: getTensionSnapshotData(shadeBuckling),
              tensionOffBtmSnapshotData: getTensionOffBtmSnapshotData(shadeBuckling),
              sideForceData: getSideForceData(),
              sideForceUnits: getSideForceUnits(),
              appWobData: getAppWobData(),
              wobText: getWOBtext(),
              wrapsData: getWrapsData(),
              stretchData: getStretchData(),
              geometryAnnotations: getGeometryAnnotations(),
            }}
            ref={chartTndRef}
          />
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          backgroundColor: '#429ceb',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Tooltip
          title={'Calculate'}
          placement='bottom-start'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify
            icon={'fluent:settings-24-filled'}
            style={{ color: 'white', height: '40px', width: '40px', cursor: 'pointer' }}
            onClick={() => {
              if (isChartBhaAnalysis()) {
                fetchBhaAnalysisData()
                return
              }

              if (isChartHydraulics()) {
                fetchHydraulics()
                return
              }

              if (isChartBhaTendency()) {
                fetchBhaTendencyData()
                return
              }

              if (isChartBhaCriticalRPM() || isChartModeShapes()) {
                fetchBhaCriticalRPMData()
                return
              }

              if (isChartCasingStandoff()) {
                fetchStandOff()
                return
              }

              fetchTnd()
            }}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 70,
          right: 10,
          backgroundColor: '#429ceb',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Tooltip
          title={'Refresh Drilling Params'}
          placement='bottom-start'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify
            icon={'material-symbols:refresh'}
            style={{ color: 'white', height: '40px', width: '40px', cursor: 'pointer' }}
            onClick={() => {
              fetchSlideSheetData()
              fetchWitsDrillingData()
              fetchTrajectoryData()
            }}
          />
        </Tooltip>
      </Box>
    </Box>
  )
}

export default EngineeringDashboardChartsPane
