import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, dateComparator, isDateLessThan ,getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const DaysKpiGrid = () => {
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)

  const getKpiData = useInnovaAxios({
    url: '/kpi/daysKpi',
    timeout: 60000,
  })

  const dateFilterComparator = useCallback(dateComparator, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('daysKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.daysKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const dataResponse = await getKpiData({
      wellList: array2pipestr(selectedWells),
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let motorWS = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, motorWS, 'Days Kpi')
    XLSX.writeFile(wb, 'daysKpi.xlsx')
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    value += 'T00:00:01'
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'avatar',
        width: 100,
        pinned: 'left',
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                margin: 'auto',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.operator)
        },
      },
      {
        headerName: 'Well',
        field: 'parentWell',
        colId: 'parentWell',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Actual Well',
        field: 'wellName',
        colId: 'wellName',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Job #',
        field: 'jobNum',
        colId: 'jobNum',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.rig)
        },
      },
      {
        headerName: 'State',
        field: 'state',
        colId: 'state',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Status',
        field: 'wellStatus',
        colId: 'wellStatus',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: GetActivityColor(params.data?.wellStatus),
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.wellStatus}
            </span>
          )
        },
      },
      {
        headerName: 'DD Coordinator',
        field: 'ddCoordinator',
        colId: 'ddCoordinator',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Date',
        field: 'date',
        colId: 'date',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.date)
        },
      },
      {
        headerName: `Daily Cost (${getUnitsText(UNITS_FOR.Cost)})`,
        field: 'cost',
        colId: 'cost',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.cost, 2)
        },
      },
      {
        headerName: `Daily Footage (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'drilled',
        colId: 'drilled',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.drilled, 2)
        },
      },
      {
        headerName: 'Has Activitys',
        field: 'hasActivity',
        colId: 'hasActivity',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
    ],
    [getUnitsText, dateFilterComparator, dateTimeFormatter],
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('daysKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'DaysKpi.xlsx',
            sheetName: 'DaysKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            
            loading={isLoading}
            rowData={data}
            columnDefs={sortColDefs(columnDefs, 'daysKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            masterDetail={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default DaysKpiGrid
