import React, { useEffect, useRef, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import SplitPane from 'components/common/SplitPane'
import DepthDatumsPage from './DepthDatumsPage'
import MapView from 'components/common/MapView/MapView'
import { Box, CircularProgress, Tooltip, Snackbar, Alert } from '@mui/material'
import CoordinatesGrid from 'components/DatabaseTree/common/CoordinatesGrid'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import WellGrid from './WellGrid'
import { isEqual } from 'lodash'
import { useRecoilState } from 'recoil'
import { dateBaseTreeUpdateNameAtom, wellPropertiesLayoutAtom } from 'atoms'

const WellPropertiesPage = ({ wellName, nodeId, canChangePage }) => {
  const _isMounted = useRef(false)
  const [isUpdating, setUpdating] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [wellData, setWellData] = useState(null)
  const welldataRef = useRef(null)
  const [dataChanged, setDataChanged] = useState(false)
  const isUpdatingRef = useRef(false)
  const orgDataRef = useRef(wellData)
  const [pageLayout, setPageLayout] = useRecoilState(wellPropertiesLayoutAtom)
  const [databaseTreeUpdateName, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const resetCoordinates = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [slots, setSlots] = useState([])
  const slotsRef = useRef(slots)
  const isInitialUpdate = useRef(true)

  const getWell = useInnovaAxios({
    url: '/well/getWell',
  })

  const getSlots = useInnovaAxios({
    url: '/template/getSlots',
  })

  const updateWell = useInnovaAxios({
    url: '/well/updateWell',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchSlots()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!wellData) return
    if (data.caller === 'UI') return
    if (data.nodeId !== nodeId) return

    let newData = cloneDeep(wellData)
    newData.well = data.name
    orgDataRef.current.well = data.name
    welldataRef.current.well = data.name
    setWellData(newData)
    setDataBaseTreeNameUpdate(null)
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '65%' : '65%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSlots = async () => {
    const res = await getSlots({ wellName: wellName })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return
    slotsRef.current = cloneDeep(res.data)
    setSlots(res.data)
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getWell({ wellName: wellName })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    welldataRef.current = res.data[0]
    orgDataRef.current = cloneDeep(res.data[0])
    setWellData(res.data[0])
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    canChangePage.current = false
    isUpdatingRef.current = true
    let res = await updateWell(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) {
      canChangePage.current = true
      return
    }

    setUpdating(false)

    if (res?.error) {
      canChangePage.current = true
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (orgDataRef.current.well !== data.well) {
      setDataBaseTreeNameUpdate({ name: data.well, nodeId: nodeId, caller: 'UI' })
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
    canChangePage.current = true
  }

  const updateWellData = (data) => {
    if (!data) return

    let newData = cloneDeep(welldataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    welldataRef.current = cloneDeep(newData)
    setWellData(newData)
  }

  const updateWellDataCoords = (data) => {
    if (!data) return

    let newData = cloneDeep(welldataRef.current)
    newData.latDeg = data.latDeg
    newData.longDeg = data.longDeg
    newData.gridNorth = data.gridNs
    newData.gridEast = data.gridEw
    newData.gridConv = data.gridConv
    newData.localNorth = data.localNs
    newData.localEast = data.localEw
    newData.slot = data.slotName

    if (data.inputMeth === 'dms' || data.inputMeth === 'deg') {
      newData.inputMeth = 'Lat Long'
    }

    if (data.inputMeth === 'grid') {
      newData.inputMeth = 'Map Coordinates'
    }

    if (data.inputMeth === 'local') {
      newData.inputMeth = 'Local Coordinates'
    }

    if (data.inputMeth === 'slot') {
      newData.inputMeth = 'Slot'
    }

    if(!isInitialUpdate.current) {
      setDataChanged(!isEqual(newData, orgDataRef.current))
    }
    welldataRef.current = newData
    setWellData(newData)
  }

  const getWellArray = () => {
    return wellData
      ? [{ id: 0, actualWell: wellData.well, latitude: wellData.latDeg, longitude: wellData.longDeg }]
      : []
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => handleUpdate(welldataRef.current)}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          welldataRef.current = cloneDeep(orgDataRef.current)
          resetCoordinates.current = true
          setDataChanged(false)
          setWellData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(0)}
        allowResize={true}
        defaultSize={getInitialPaneSize(0)}
        minSize={'10%'}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}
          minSize={'10%'}>
          <SplitPane
            split='horizontal'
            size={getInitialPaneSize(2)}
            allowResize={true}
            defaultSize={getInitialPaneSize(2)}
            onDragFinished={(params) => onDragFinished(params, 2)}
            minSize={'10%'}>
            <WellGrid wellData={wellData} handleUpdate={updateWellData} />
            <CoordinatesGrid
              slotsRef={slotsRef}
              slotName={welldataRef.current?.slot}
              resetCoordinates={resetCoordinates}
              facilityName={welldataRef.current?.facility}
              coords={
                welldataRef.current
                  ? {
                      latDeg: welldataRef.current?.latDeg,
                      longDeg: welldataRef.current?.longDeg,
                      gridNs: welldataRef.current?.gridNorth,
                      gridEw: welldataRef.current?.gridEast,
                      localNs: welldataRef.current?.localNorth,
                      localEw: welldataRef.current?.localEast,
                      gridConv: welldataRef.current?.gridConv,
                    }
                  : null
              }
              options={{
                slot: true,
                dms: true,
                deg: true,
                grid: true,
                local: true,
                input:
                  welldataRef.current?.inputMeth === 'Lat Long'
                    ? 'dms'
                    : welldataRef.current?.inputMeth === 'Local Coordinates'
                    ? 'local'
                    : welldataRef.current?.inputMeth === 'Slot'
                    ? 'slot'
                    : 'grid',
              }}
              crs={welldataRef.current ? welldataRef.current?.crs : null}
              updateData={updateWellDataCoords}
            />
          </SplitPane>
          <DepthDatumsPage wellName={wellName} />
        </SplitPane>
        {wellData ? (
          <MapView
            wells={getWellArray()}
            center={{ lat: wellData.latDeg, lng: wellData.longDeg }}
          />
        ) : (
          <Box />
        )}
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default WellPropertiesPage
