import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { selectedEngineeringCaseAtom, selectedEngineeringWellboreAtom } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { Tooltip, Box, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledDeleteIcon = styled(Iconify)({
  color: '#C00000',
  fontSize: '16px',
})

const StyledAddIcon = styled(Iconify)({
  color: '#00C000',
  fontSize: '16px',
})

const StyledIconHeader = styled(IconButton)({
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'center',
})

const WellBoreEditor = () => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const { getUnitsText } = useUnits()
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const selectedEngWellbore = useRecoilValue(selectedEngineeringWellboreAtom)
  const inputRow = useRef({ actualWell: selectedEngCase?.actualwell, recordId: selectedEngWellbore })
  const { getAgGridTheme } = useInnovaTheme()

  const getData = useInnovaAxios({
    url: '/engineering/getWellGeo',
  })

  const addData = useInnovaAxios({
    url: '/engineering/addWellGeo',
  })

  const updateData = useInnovaAxios({
    url: '/engineering/updateWellGeo',
  })

  const deleteData = useInnovaAxios({
    url: '/engineering/deleteWellGeo',
  })

  const isCurEngCaseValid = () => {
    if (selectedEngCase === null || selectedEngCase === undefined) return false
    if (!selectedEngCase.hasOwnProperty('recordId')) return false
    if (!selectedEngCase.hasOwnProperty('actualwell')) return false
    if (typeof selectedEngCase.actualwell !== 'string') return false
    if (selectedEngCase.actualwell === '') return false
    return true
  }

  const handleDelete = useCallback(
    async (sequenceNumbers) => {
      if (!Array.isArray(sequenceNumbers)) return
      if (sequenceNumbers.length === 0) return
      if (!gridApi.current) return
      if (selectedEngCase === null || selectedEngCase === undefined) return
      if (!selectedEngCase.hasOwnProperty('actualwell')) return
      if (typeof selectedEngCase.actualwell !== 'string') return
      if (selectedEngCase.actualwell === '') return

      if (selectedEngWellbore === null || selectedEngWellbore === undefined) return
      if (typeof selectedEngWellbore === 'string' && parseInt(selectedEngWellbore) < 0) return
      if (typeof selectedEngWellbore === 'number' && selectedEngWellbore < 0) return

      gridApi.current.applyTransaction({ remove: sequenceNumbers })

      let seqNumStr = ''
      for (let i = 0; i < sequenceNumbers.length; i++) {
        if (seqNumStr !== '') seqNumStr += '|'
        seqNumStr += sequenceNumbers[i].sequenceno
      }

      let payload = {
        actualWell: selectedEngCase.actualwell,
        recordId: selectedEngWellbore,
        sequenceno: seqNumStr,
      }

      setLoading(true)
      await deleteData(payload)
      if (_isMounted.current) {
        setLoading(false)
      }
    },
    [deleteData, selectedEngCase, selectedEngWellbore],
  )

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('sequenceno')) return
    if (isLoading) return

    let payload = {
      actualWell: data.actualwell,
      recordId: data.recordId,
      sequenceno: data.sequenceno,
      compType: data.compType,
      md: data.md,
      od: data.od,
      tjOd: data.od,
      id: data.id,
    }

    setLoading(true)
    await updateData(payload)
    if (_isMounted.current) {
      setLoading(false)
    }

    setTimeout(() => {
      autoSizeColumns()
    }, 100)
  }

  const handleFailedFetch = () => {
    if (_isMounted.current) setData([])
  }

  const fetchData = async () => {
    if (isLoading) return
    if (selectedEngCase === null || selectedEngCase === undefined) {
      handleFailedFetch()
      return
    }

    if (!selectedEngCase.hasOwnProperty('actualwell')) {
      handleFailedFetch()
      return
    }

    if (typeof selectedEngCase.actualwell !== 'string') {
      handleFailedFetch()
      return
    }

    if (selectedEngCase.actualwell === '') {
      handleFailedFetch()
      return
    }

    if (selectedEngWellbore === null || selectedEngWellbore === undefined) {
      handleFailedFetch()
      return
    }

    if (typeof selectedEngWellbore === 'string' && parseInt(selectedEngWellbore) < 0) {
      handleFailedFetch()
      return
    }

    if (typeof selectedEngWellbore === 'number' && selectedEngWellbore < 0) {
      handleFailedFetch()
      return
    }

    let payload = { wellName: selectedEngCase.actualwell, wellboreId: selectedEngWellbore }
    setLoading(true)
    const response = await getData(payload)
    if (_isMounted.current) {
      setData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    inputRow.current = { actualWell: selectedEngCase?.actualwell, recordId: selectedEngWellbore }

    return () => {
      _isMounted.current = false
    }
  }, [selectedEngCase, selectedEngWellbore]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.sequenceno)
    }
  }, [])

  let reqFields = useMemo(() => ['compType', 'md', 'od', 'id'], [])

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const handleAddRow = useCallback(async () => {
    if (isLoading) return
    if (!isPinnedRowDataCompleted()) return
    setLoading(true)

    inputRow.current.tjOd = inputRow.current.od
    let res = await addData(inputRow.current)
    if (_isMounted.current) {
      setLoading(false)
    }

    if (!res?.error) {
      inputRow.current.sequenceno = res.data.sequenceno
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = { actualWell: selectedEngCase?.actualwell, recordId: selectedEngWellbore }
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [addData, isLoading, isPinnedRowDataCompleted, selectedEngCase, selectedEngWellbore])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <React.Fragment>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip
              title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
              placement='left'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <StyledIconHeader
                onClick={() =>
                  params.node?.rowPinned !== 'bottom'
                    ? handleDelete([{ sequenceno: params.data?.sequenceno }])
                    : handleAddRow()
                }
                size='large'>
                {params.node?.rowPinned !== 'bottom' ? (
                  <StyledDeleteIcon icon='fa-regular:trash-alt' />
                ) : (
                  <StyledAddIcon icon='fluent:add-12-filled' />
                )}
              </StyledIconHeader>
            </Tooltip>
            {params.node?.rowPinned !== 'bottom' ? (
              <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
            ) : null}
          </Box>
        </React.Fragment>
      )
    },
    [handleAddRow, handleDelete],
  )

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []

    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    gridApi.applyTransaction({ update: itemsToUpdate })
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      autoHeight: true,
      flex: 1,
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [isEmptyPinnedCell, createPinnedCellPlaceholder])

  const getRowData = useCallback((data, rowToGet) => {
    if (typeof rowToGet !== 'string') rowToGet = 'AFTER'
    if (!gridApi.current) return null
    if (!data) return null
    let sequenceNo = 999999999
    if (data.hasOwnProperty('sequenceno')) sequenceNo = data.sequenceno

    let node = null
    gridApi.current.forEachNode((rowNode) => {
      if (rowNode.data.sequenceno < sequenceNo && rowToGet === 'BEFORE') node = { ...rowNode.data }
      if (rowNode.data.sequenceno > sequenceNo && rowToGet === 'AFTER') node = { ...rowNode.data }
    })

    if (node === null) {
      node = { sequenceno: -1, od: -1, id: -1, md: -1, compType: 'unknown' }
    }

    return node
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        cellRendererSelector: () => {
          return {
            component: actionIconRenderer,
          }
        },
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '4px !important',
          paddingRight: '4px !important',
          borderRight: '1px solid gray !important',
        },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: `Comp Type`,
        field: 'compType',
        cellStyle: centerAlignCell,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Open Hole', 'Casing', 'Liner', 'Air Gap', 'Open Water', 'Riser'],
        },
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom' && params.newValue === '') {
            inputRow.current[params.colDef.field] = ''
          }

          if (params.newValue !== '') {
            let data = { ...params.data }
            data.compType = params.newValue

            const { compType: compBefore } = getRowData(data, 'BEFORE')
            const { compType: compAfter } = getRowData(data, 'AFTER')

            if (data.compType === 'Air Gap' && compBefore !== 'unknown') data.compType = params.oldValue
            if (data.compType === 'Open Water' && compBefore !== 'Air Gap') data.compType = params.oldValue
            if (data.compType === 'Riser' && compBefore !== 'unknown') data.compType = params.oldValue
            if (data.compType === 'Liner' && compBefore !== 'unknown') data.compType = params.oldValue
            if (data.compType === 'Open Hole' && compAfter !== 'unknown') data.compType = params.oldValue

            if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
            if (params.node?.rowPinned === 'bottom') {
              inputRow.current[params.colDef.field] = data.compType
            }
          }
          return true
        },
      },
      {
        headerName: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'md',
        cellStyle: centerAlignCell,
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom' && params.newValue === '') {
            inputRow.current[params.colDef.field] = ''
          }

          if (/^(?:\d{1,5}(?:\.\d{1,2})?|100000(?:\.0{1,2})?)$/.test(params.newValue)) {
            let data = { ...params.data }
            data.md = parseFloat(params.newValue)

            const { md: mdBefore } = getRowData(data, 'BEFORE')
            const { md: mdAfter } = getRowData(data, 'AFTER')

            if (data.md < mdBefore && mdBefore >= 0) data.md = params.oldValue
            if (data.md > mdAfter && mdAfter >= 0) data.md = params.oldValue

            if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
            if (params.node?.rowPinned === 'bottom') {
              inputRow.current[params.colDef.field] = data.md
            }
          }
          return true
        },
        editable: true,
      },
      {
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'od',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'Od...'
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.od, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 3,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false

          if (params.node?.rowPinned === 'bottom' && params.newValue === '') {
            inputRow.current[params.colDef.field] = ''
          }

          if (/^\d+(\.\d{1,3})?$/.test(params.newValue)) {
            let data = { ...params.data }
            let od = parseFloat(params.newValue)
            if (od >= 500) return false

            data.od = parseFloat(params.newValue)
            if (data.compType === 'Open Hole') data.id = data.od
            if (data.hasOwnProperty('id') && od < parseFloat(data.id)) data.od = data.id

            if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
            if (params.node?.rowPinned === 'bottom') {
              inputRow.current[params.colDef.field] = data.od
              if (data.compType === 'Open Hole') inputRow.current.id = data.id
            }
          }

          return true
        },
        editable: true,
      },
      {
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'id',
        cellStyle: centerAlignCell,
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom' && params.newValue === '') {
            inputRow.current[params.colDef.field] = ''
            return true
          }

          if (/^\d+(\.\d{1,3})?$/.test(params.newValue)) {
            let data = { ...params.data }
            let id = parseFloat(params.newValue)
            if (id >= 500) return false

            data.id = parseFloat(params.newValue)
            if (data.compType === 'Open Hole') data.od = data.id
            if (data.hasOwnProperty('od') && id > parseFloat(data.od)) data.id = data.od

            if (params.node?.rowPinned !== 'bottom') params.api.applyTransaction({ update: [data] })
            if (params.node?.rowPinned === 'bottom') {
              inputRow.current[params.colDef.field] = data.id
              if (data.compType === 'Open Hole') inputRow.current.od = data.id
              return false
            }
          }

          return true
        },
        editable: true,
      },
    ],
    [isEmptyPinnedCell, actionIconRenderer, centerAlignCell, getRowData, getUnitsText],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }
      if (event.node?.rowPinned !== 'bottom') handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    setTimeout(() => {
      autoSizeColumns()
    }, 100)
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 25px)' }}>
      {isCurEngCaseValid() ? (
        <AgGridReact
          rowData={data}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          groupDisplayType={'groupRows'}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          headerHeight={30}
        />
      ) : null}
    </div>
  )
}

export default WellBoreEditor
