import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import DonutChartJs from 'components/common/DonutChartJs'
import { chartSeriesColors } from 'utils'
import Box from '@mui/material/Box'
import useWellData from 'components/common/hooks/useWellData'

const ToolInventoryDonutChart = () => {
  const { wellData } = useWellData()

  function countToolTypes(inv) {
    if (!inv) return []
    if (inv.length <= 0) return []

    let toolTypes = []

    inv.forEach((item) => {
      if (item.dateOff === '') {
        let searchItem = item.toolType
        if (searchItem.search('MWD ') >= 0) searchItem = 'MWD Probe'
        if (searchItem === 'Motor') searchItem = item.toolType + '-' + item.dirty
        if (searchItem === '') searchItem = 'Other'

        let foundToolType = toolTypes.find((element) => element.label === searchItem)
        if (foundToolType === undefined) {
          let col = chartSeriesColors[0]
          if (toolTypes.length <= chartSeriesColors.length) col = chartSeriesColors[toolTypes.length]

          toolTypes.push({ label: searchItem, value: 1, color: col })
        } else {
          foundToolType.value++
        }
      }
    })

    return toolTypes
  }

  const getDonutChartData = (inventory) => {
    if (!inventory) return []
    if (!Array.isArray(inventory)) return []
    if (inventory.length === 0) return []

    const inventoryChart = countToolTypes(inventory)

    return inventoryChart.map((inventory, i) => {
      return {
        value: Number(inventory.value),
        color: inventory.color,
        label: inventory.label,
      }
    })
  }

  return wellData?.inventory ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0c0c0c',
        height: '100%',
        width: '100%',
      }}>
      <DonutChartJs
        data={getDonutChartData(wellData.inventory)}
        legendLocation={'bottom'}
        showLegend={true}
        showTitle={false}
      />
    </Box>
  ) : (
    <Skeleton height={'80vh'} variant='rectangular' animation='wave' />
  )
}

export default ToolInventoryDonutChart
