import React, { useEffect, useRef, useState } from 'react'

import { ListItem } from '@mui/material'
import { Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { FixedSizeList } from 'react-window'
import SearchBar from 'components/common/SearchBar'
import { searchFunction } from './searchFunctions'
import AutoSizer from 'react-virtualized-auto-sizer'
import { filter as _filter } from 'lodash'
import { Skeleton, Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { appColors } from 'utils'

const PickListVirtualDialog = (props) => {
  const { title, items, open, setOpen, onApply, initSelItems, singleItemSelect, showSearch, rowHeight } = props
  const _isMounted = useRef(false)
  const [selectedItems, setSelectedItems] = useState(Array.isArray(initSelItems) === true ? initSelItems : [])
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const { searchBarStyle, getLinearGradient } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText, items]) // eslint-disable-line react-hooks/exhaustive-deps

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selectedItems]

    let singleSelect = singleItemSelect
    if (singleItemSelect === null || singleItemSelect === undefined) singleSelect = false

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true && singleSelect === false) setSelectedItems(newSelectedItems)
      if (_isMounted.current === true && singleSelect === true) setSelectedItems([id])
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true && !singleSelect === true) setSelectedItems(newSelectedItems)
      if (_isMounted.current === true && singleSelect === true) setSelectedItems([id])
    }
  }

  const setAllChecked = () => {
    let allSelected = []

    if (Array.isArray(items) && items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        allSelected.push(items[i].id)
      }
    }

    setSelectedItems(allSelected)
  }

  const getChecked = (id) => {
    if (!selectedItems) return false
    if (Array.isArray(selectedItems) === false) return false
    if (selectedItems.includes(id) === true) return true
    return false
  }

  const handleSearch = (text) => {
    if (text && typeof text === 'string' && text !== '') {
      const filterList = _filter(items, (item) => {
        return searchFunction(item, text, ['desc'])
      })
      return filterList
    } else {
      return items
    }
  }

  const renderVirtualRow = (props) => {
    const { index, style } = props
    const item = filteredList[index]

    return (
      <ListItem style={style} disableGutters={true}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '4px',
            borderRadius: '5px',
            marginLeft: '8px',
            marginRight: '8px',
            background: getLinearGradient(),
            marginBottom: '1px',
            width: 'calc(100% - 20px)', // 8px for marginLeft, 2px for left & right border
            border: `1px solid`,
            borderColor: appColors.itemBackColor,
            backgroundColor: appColors.itemBackColor,
            '&:hover': {
              borderColor: appColors.accentColor,
            },
          }}>
          <Box
            sx={{
              fontSize: 18,
              color: appColors.itemTextColor,
              border: '',
              alignItems: 'center',
              //whiteSpace: 'nowrap',
            }}>
            {item.desc}
          </Box>
          <Box sx={{ marginLeft: 'auto', marginRight: '10px' }}>
            <Checkbox
              edge='end'
              onChange={(event) => onItemSelect(event, item.id)}
              checked={getChecked(item.id)}
              inputProps={{ 'aria-labelledby': `${index}` }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
              indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </Box>
        </Box>
      </ListItem>
    )
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '600px',
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ paddingTop: '0px' }}>
        {showSearch === true ? (
          <Box sx={{ padding: '8px', marginBottom: '5px' }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle()}
            />
          </Box>
        ) : null}
        {items.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              overflowY: 'hidden',
              overflowX: 'hidden',
              height: '50vh',
              width: 'calc(100% - 4px)',
              borderRadius: '4px',
              border: '1px solid #000',
            }}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className='innovaScrollBar'
                  style={{ display: 'flex', borderRadius: '4px', overflow: 'auto' }}
                  height={height}
                  width={width}
                  itemCount={filteredList.length}
                  itemSize={rowHeight ? rowHeight : 50}>
                  {renderVirtualRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Box>
        ) : null}
        {items.length === 0 ? <Skeleton variant='rectangular' width={'100%'} height={'50vh'} animation='wave' /> : null}
      </DialogContent>
      <DialogActions>
        {!singleItemSelect ? (
          <Button variant='outlined' onClick={() => setAllChecked()} color='secondary'>
            Check all
          </Button>
        ) : null}
        <Button variant='outlined' onClick={() => setSelectedItems([])} color='secondary'>
          Uncheck all
        </Button>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onApply(selectedItems)
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PickListVirtualDialog
