import React, { useEffect, useRef, useState, useMemo } from 'react'
import { appColors } from 'utils'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { currentAwsRegionAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import Alert from '@mui/material/Alert'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import { round } from 'utils/numberFunctions'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const IcdsDiagnosticsGrid = ({ organization, height }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const [diagData, setDiagData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const currentAwsRegion = useRecoilValue(currentAwsRegionAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [resetCols, setResetCols] = useState(false)

  const getDiagnosticsData = useInnovaAxios({
    url: '/admin/diag/icds',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDiagData()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortDate = (a, b) => {
    let first = a.lastTimestamp
    let next = b.lastTimestamp

    if (typeof first !== 'string') return -1
    if (typeof next !== 'string') return -1
    let d1 = new Date(first)
    let d2 = new Date(next)

    if (d1 < d2) return 1
    if (d1 > d2) return -1
    return 0
  }

  const getReqDesc = (requestType) => {
    if (requestType === 'E01') return 'E01 - Push well data'
    if (requestType === 'E02') return 'E02 - Push replicate operator'
    if (requestType === 'E03') return 'E03 - Pull well data'
    if (requestType === 'E04') return 'E04 - Filtered select'
    if (requestType === 'E05') return 'E05 - Send email alert'
    if (requestType === 'E06') return 'E06 - Execute SQL'
    if (requestType === 'E07') return 'E07 - Push files'
    if (requestType === 'E08') return 'E08 - Pull files'
    if (requestType === 'E09') return 'E09 - Delete files'
    if (requestType === 'E10') return 'E10 - Get Well Seeker updates list'
    if (requestType === 'E0B') return 'E0B - Execute SQL return data'
    if (requestType === 'E0D') return 'E0D - Get Well Seeker update'
    if (requestType === 'E0E') return 'E0E - Upload Well Seeker Update'
    if (requestType === 'E0F') return 'E0F - Delete Well Seeker update'
    if (requestType === 'EFE') return 'EFE - Get ICDS diagnostics'
  }

  const fetchDiagData = async () => {
    if (!organization) return
    if (typeof organization !== 'string') return
    if (organization === '') return

    if (typeof currentAwsRegion !== 'string') return
    if (currentAwsRegion === '') return
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const response = await getDiagnosticsData({
      awsRegion: currentAwsRegion,
    })

    if (_isMounted.current) setIsLoading(false)

    if (response.error && _isMounted.current) {
      setStatus({ show: true, severity: 'error', message: `${response?.error?.response?.data?.error}` })
      return
    }

    if (!Array.isArray(response.data) || response.data.length === 0) {
      return
    }

    let foundOrg = response.data.find((item) => item.org === organization)
    if (!foundOrg) return

    let diagData = []

    for (let i = 0; i < foundOrg.diagnostics.length; i++) {
      if (!Array.isArray(foundOrg.diagnostics[i].requestStats)) continue
      for (let j = 0; j < foundOrg.diagnostics[i].requestStats.length; j++) {
        let newDiagnostic = cloneDeep(foundOrg.diagnostics[i].requestStats[j])
        newDiagnostic.wellName = foundOrg.diagnostics[i].wellName
        if (newDiagnostic.wellName === 'no well') newDiagnostic.wellName = 'System Command'
        newDiagnostic.Counter = foundOrg.diagnostics[i].Counter
        newDiagnostic.pushCount = foundOrg.diagnostics[i].pushCount
        newDiagnostic.pullCount = foundOrg.diagnostics[i].pullCount
        newDiagnostic.reqType = getReqDesc(newDiagnostic.reqType)
        diagData.push(newDiagnostic)
      }
    }

    diagData.sort(sortDate)
    if (_isMounted.current) setDiagData(diagData)
  }

  const GroupRowInnerRenderer = (props) => {
    return <Box sx={{ color: appColors.itemTextColor }}>{props.node.key}</Box>
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
  }

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    expandAllNodes('expand')
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('icdsDiagGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('icdsDiagGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand All',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="ri:expand-up-down-fill" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse All',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="ion:chevron-collapse-sharp" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'IcdsDiagnostics.xlsx',
            sheetName: 'IcdsDiagnostics',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const formatTimestamp = (ts) => {
    let timeString = moment(ts).format('ddd, MMM DD, YYYY, hh:mm:ss')
    let timeSince = moment(timeString).fromNow(true)
    return `${timeString} [${timeSince}]`
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  let columnDefs = useMemo(
    () => [
      {
        colId: 'wellName',
        field: 'wellName',
        rowGroup: true,
        hide: true,
      },
      {
        field: 'lastTimestamp',
        colId: 'lastTimestamp',
        headerName: 'Time',
        valueFormatter: (params) => formatTimestamp(params.data?.lastTimestamp),
        width: 400,
      },
      {
        field: 'reqType',
        colId: 'reqType',
        headerName: 'Req Type',
        width: 200,
      },
      {
        field: 'numRequests',
        colId: 'numRequests',
        headerName: 'Total',
        cellStyle: centerAlignCell,
        width: 100,
      },
      {
        field: 'rejected',
        colId: 'rejected',
        headerName: 'Rejected',
        cellStyle: (params) => {
          return { ...centerAlignCell, color: params.data?.rejected === 0 ? 'white' : 'red' }
        },
        width: 100,
      },
      {
        field: 'failed',
        colId: 'failed',
        headerName: 'Failed',
        cellStyle: (params) => {
          return { ...centerAlignCell, color: params.data?.failed === 0 ? 'white' : 'red' }
        },
        width: 100,
      },
      {
        field: 'lastPayloadSize',
        colId: 'lastPayloadSize',
        headerName: 'Last P/L (mb)',
        cellStyle: (params) => {
          let color = 'white'
          let payloadMb = typeof params.data?.lastPayloadSize === 'number' ? params.data?.lastPayloadSize / 1e6 : 0
          if (payloadMb > 10) color = 'orange'
          if (payloadMb > 30) color = 'red'

          return { ...centerAlignCell, color: color }
        },
        width: 100,
        valueFormatter: (params) =>
          typeof params.data?.lastPayloadSize === 'number' ? round(params.data?.lastPayloadSize / 1e6, 2) : '',
      },
      {
        field: 'maxPayloadSize',
        colId: 'maxPayloadSize',
        headerName: 'Max P/L (mb)',
        cellStyle: (params) => {
          let color = 'white'
          let payloadMb = typeof params.data?.maxPayloadSize === 'number' ? params.data?.maxPayloadSize / 1e6 : 0
          if (payloadMb > 10) color = 'orange'
          if (payloadMb > 30) color = 'red'

          return { ...centerAlignCell, color: color }
        },
        width: 100,
        valueFormatter: (params) =>
          typeof params.data?.maxPayloadSize === 'number' ? round(params.data?.maxPayloadSize / 1e6, 2) : '',
      },
      {
        field: 'avgPayloadSize',
        colId: 'avgPayloadSize',
        headerName: 'Avg P/L (mb)',
        cellStyle: (params) => {
          let color = 'white'
          let payloadMb = typeof params.data?.avgPayloadSize === 'number' ? params.data?.avgPayloadSize / 1e6 : 0
          if (payloadMb > 10) color = 'orange'
          if (payloadMb > 30) color = 'red'

          return { ...centerAlignCell, color: color }
        },
        width: 100,
        valueFormatter: (params) =>
          typeof params.data?.avgPayloadSize === 'number' ? round(params.data?.avgPayloadSize / 1e6, 2) : '',
      },
      {
        field: 'lastElapsed',
        colId: 'lastElapsed',
        headerName: 'Last ET (s)',
        cellStyle: (params) => {
          let color = 'white'
          let time = typeof params.data?.lastElapsed === 'number' ? params.data?.lastElapsed : 0
          if (time > 1) color = 'orange'
          if (time > 5) color = 'red'

          return { ...centerAlignCell, color: color }
        },
        width: 100,
        valueFormatter: (params) =>
          typeof params.data?.lastElapsed === 'number' ? round(params.data?.lastElapsed, 2) : '',
      },
      {
        field: 'maxElapsed',
        colId: 'maxElapsed',
        headerName: 'Max ET (s)',
        cellStyle: (params) => {
          let color = 'white'
          let time = typeof params.data?.maxElapsed === 'number' ? params.data?.maxElapsed : 0
          if (time > 1) color = 'orange'
          if (time > 5) color = 'red'

          return { ...centerAlignCell, color: color }
        },
        width: 100,
        valueFormatter: (params) =>
          typeof params.data?.maxElapsed === 'number' ? round(params.data?.maxElapsed, 2) : '',
      },
      {
        field: 'avgElapsed',
        colId: 'avgElapsed',
        headerName: 'Avg ET (s)',
        cellStyle: (params) => {
          let color = 'white'
          let time = typeof params.data?.avgElapsed === 'number' ? params.data?.avgElapsed : 0
          if (time > 1) color = 'orange'
          if (time > 5) color = 'red'

          return { ...centerAlignCell, color: color }
        },
        width: 100,
        valueFormatter: (params) =>
          typeof params.data?.avgElapsed === 'number' ? round(params.data?.avgElapsed, 2) : '',
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <React.Fragment>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchText) => setSearchText(newSearchText)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: `${height - 64}px` }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={diagData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'icdsDiagGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            groupDisplayType={'groupRows'}
            groupRowRendererParams={groupRowRendererParams}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default IcdsDiagnosticsGrid
