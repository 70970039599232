import React, { useEffect, useRef, useMemo, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AlarmsGrid = ({ alarms }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('edrAlarmsGrid', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('edrAlarmsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: 'Name',
        field: 'desc',
        colId: 'desc',
        cellStyle: leftAlignCell,
        minWidth: 100,
      },
      {
        headerName: 'Status',
        field: 'value',
        colId: 'value',
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: params?.data?.value ? '#FF0000' : '#00FF00',
            color: params?.data?.value ? '#FFFFFF' : '#333333',
          }
        },
        valueFormatter: (params) => {
          return params?.data?.value ? 'Active' : 'OK'
        },
      },
      {
        headerName: 'Suppress',
        field: 'suppress',
        colId: 'suppress',
        editable: true,
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }
        },
        valueSetter: (params) => {
          params.data.suppress = params.newValue
          let index = alarms.current.findIndex((alarm) => alarm.id === params.data?.id)
          if (index < 0) return false
          alarms.current[index].suppress = params.newValue
          return true
        },
      },
    ],
    [leftAlignCell, alarms],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={Array.isArray(alarms?.current) ? alarms.current : []}
        columnDefs={sortColDefs(columnDefs, 'edrAlarmsGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default AlarmsGrid
