import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { uuidv4 } from 'utils/stringFunctions'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BidDetailsGrid = ({ bidData, parentRowUid, handleUpdate, masterGridApi, editable }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
      if (masterGridApi && masterGridApi?.gridOptions?.api) {
        masterGridApi.removeDetailGridInfo(`BID_DETAIL_GRID-${parentRowUid}`)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (masterGridApi) {
      let detailId = `BID_DETAIL_GRID-${parentRowUid}`
      const detailGridInfo = {
        id: detailId,
        api: params.api,
      }

      masterGridApi.addDetailGridInfo(detailId, detailGridInfo)
    }
  }

  const handleUpdateFunc = (data, action) => {
    if (!data) return
    if (!bidData.current) return
    if (!Array.isArray(bidData.current?.bidDetails)) {
      bidData.current.bidDetails = []
    }
    if (!isPinnedRowDataCompleted() && action === 'add') return
    let rowIndex = bidData.current.bidDetails.findIndex((row) => row.uid === parentRowUid)
    if (rowIndex < 0) return

    if (!Array.isArray(bidData.current.bidDetails[rowIndex].details)) {
      bidData.current.bidDetails[rowIndex].details = []
    }

    if (action === 'update') {
      for (let i = 0; i < bidData.current.bidDetails[rowIndex].details.length; i++) {
        if (bidData.current.bidDetails[rowIndex].details[i].uid === data.uid) {
          bidData.current.bidDetails[rowIndex].details[i].description = data.description
          bidData.current.bidDetails[rowIndex].details[i].costCode = data.costCode
          bidData.current.bidDetails[rowIndex].details[i].cost = data.cost
          bidData.current.bidDetails[rowIndex].details[i].costSchedule = data.costSchedule
          break
        }
      }

      gridApi.current.applyTransaction({
        update: [data],
      })
    }

    if (action === 'add') {
      inputRow.current.uid = uuidv4()
      inputRow.current.cost = 0
      inputRow.current.costSchedule = 'EACH'
      bidData.current.bidDetails[rowIndex].details.push(inputRow.current)

      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })

      inputRow.current = {}
      if (gridApi.current) {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }
    }

    if (action === 'delete') {
      for (let i = 0; i < bidData.current.bidDetails[rowIndex].details.length; i++) {
        if (bidData.current.bidDetails[rowIndex].details[i].uid === data.uid) {
          bidData.current.bidDetails[rowIndex].details.splice(i, 1)
          break
        }
      }

      gridApi.current.applyTransaction({
        remove: [data],
      })
    }

    handleUpdate(bidData.current)
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleUpdateFunc(inputRow.current, 'add')
        return
      }

      handleUpdateFunc(event.data, 'update')
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const reqFields = useMemo(() => ['description'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: editable,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder, editable])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('bidDetailsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('bidDetailsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom'
                  ? handleUpdateFunc(params.data, 'delete')
                  : handleUpdateFunc(inputRow.current, 'add')
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'bidDetails',
        colId: 'bidDetails',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 60,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'actions',
        colId: 'actions',
        width: 100,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        field: 'costCode',
        colId: 'costCode',
        headerName: 'Code',
        valueGetter: (params) => unescapeHtml(params.data?.costCode),
      },
      {
        field: 'cost',
        colId: 'cost',
        headerName: 'Value',
        valueFormatter: (params) => numberWithCommasDecimals(params.data?.cost, 2),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'costSchedule',
        colId: 'costSchedule',
        headerName: 'Schedule',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: [
              'EACH',
              'DAY',
              'MILE',
              'RUN',
              'HOUR',
              'INCLUDED',
              'COST + 30',
              'COST + 25',
              'COST + 20',
              'COST + 15',
              'COST + 10',
              'COST + 5',
              'FOOTAGE',
            ],
          }
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getParentRowIndex = () => {
    if (!Array.isArray(bidData.current?.bidDetails)) return []
    return bidData.current.bidDetails.findIndex((row) => row.uid === parentRowUid)
  }

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    if (!bidData.current) return
    if (!Array.isArray(bidData.current?.bidDetails)) {
      bidData.current.bidDetails = []
    }

    let rowIndex = bidData.current.bidDetails.findIndex((row) => row.uid === parentRowUid)
    if (rowIndex < 0) return

    if (!Array.isArray(bidData.current.bidDetails[rowIndex].details)) {
      bidData.current.bidDetails[rowIndex].details = []
    }

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let item = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          item.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        rowsToUpdate.push(cloneDeep(item.data))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newItem = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newItem[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        if (!newItem.description) continue
        if (typeof newItem.description !== 'string') continue
        if (newItem.description === '') continue

        if (newItem.costCode === null || newItem.costCode === undefined) {
          newItem.costCode = ''
        }

        if (!newItem.costSchedule) newItem.costSchedule = 'DAY'
        if (typeof newItem.costSchedule !== 'string') newItem.costSchedule = 'DAY'
        if (newItem.costSchedule === '') newItem.costSchedule = 'DAY'

        newItem.uid = uuidv4()
        newItem.cost = Number(newItem.cost)

        rowsToAdd.push(newItem)
      }
    }

    if (rowsToAdd.length > 0) {
      bidData.current.bidDetails[rowIndex].details.push(...rowsToAdd.reverse())
      gridApi.current.applyTransaction({
        add: rowsToAdd.reverse(),
      })
    }

    if (rowsToUpdate.length > 0) {
      for (let i = 0; i < bidData.current.bidDetails[rowIndex].details.length; i++) {
        let rowIndex = rowsToUpdate.findIndex((row) => row.uid === bidData.current.bidDetails[rowIndex].details[i].uid)
        if (rowIndex >= 0) {
          bidData.current.bidDetails[rowIndex].details[i].description = rowsToUpdate[rowIndex].description
          bidData.current.bidDetails[rowIndex].details[i].costCode = rowsToUpdate[rowIndex].costCode
          bidData.current.bidDetails[rowIndex].details[i].cost = rowsToUpdate[rowIndex].cost
          bidData.current.bidDetails[rowIndex].details[i].costSchedule = rowsToUpdate[rowIndex].costSchedule
        }
      }

      gridApi.current.applyTransaction({
        update: rowsToUpdate,
      })
    }

    if (rowsToAdd.length > 0 || rowsToUpdate.length > 0) {
      handleUpdate(bidData.current)
    }

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={getParentRowIndex() < 0 ? [] : bidData.current.bidDetails[getParentRowIndex()].details}
        columnDefs={sortColDefs(columnDefs, 'bidDetailsGrid')}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        processDataFromClipboard={processDataFromClipboard}
      />
    </div>
  )
}

export default BidDetailsGrid
