import React from 'react'
import PlanViewChart from 'components/WellPages/PlanView/PlanViewChart'
import useWellData from 'components/common/hooks/useWellData'

const PlanViewDashCard = () => {
  const { wellData, isLoading, toggleErrorEllipses } = useWellData()

  return <PlanViewChart data={wellData} isLoading={isLoading} toggleErrorEllipses={toggleErrorEllipses} />
}

export default PlanViewDashCard
