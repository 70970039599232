import React from 'react'
import { TextField } from '@mui/material'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DigitalTextDisplay = ({
  title,
  value,
  unit,
  color = null,
  displayTitle = true,
  displayUnits = true,
  isEditable = false,
  setterFunc = null,
  textColor = null,
}) => {
  const { getTreeBackColor, theme } = useInnovaTheme()

  if (!textColor) {
    textColor = theme === 'dark' ? 'rgba(192, 192, 192, 1.0)' : '#000'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: getTreeBackColor(),
        border: '1px solid black',
        boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
        padding: '4px',
      }}>
      {displayTitle ? <Box sx={{ color: textColor, fontSize: '1em' }}>{title}</Box> : null}
      <Box>
        {!isEditable ? (
          <Box
            component='span'
            sx={{ color: color || 'rgba(52,201,235,1.0)', fontSize: '1em', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
            {value}
          </Box>
        ) : null}
        {isEditable ? (
          <TextField
            value={value}
            size='medium'
            margin='none'
            onChange={(e) => {
              let newVal = e.target.value
              setterFunc(newVal)
            }}
            sx={{ width: '30px', height: '0.725em', borderWidth: '0px', margin: '0px' }}
            inputProps={{
              style: {
                textAlign: 'center',
                fontSize: '1em',
                fontWeight: 'bold',
                padding: '0px',
                height: '1em',
                color: color || 'rgba(52,201,235,1.0)',
              },
            }}
          />
        ) : null}
        {displayUnits ? (
          <Box component='span' sx={{ color: textColor, fontSize: '0.75em', marginLeft: '0.25em' }}>
            {unit}
          </Box>
        ) : null}
      </Box>
    </Box>
  )
}

export default DigitalTextDisplay
