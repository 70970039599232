import React, { useEffect, useRef, useState, useMemo } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { timeSince } from 'utils'
import { currentAwsRegionAtom } from 'atoms'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { Icon as Iconify } from '@iconify/react'
import * as yup from 'yup'
import InputModal from 'components/common/InputModal'
import { styled } from '@mui/styles'
import IcdsLogGrid from './IcdsLogGrid'
import IcdsDiagnosticsGrid from './IcdsDiagnosticsGrid'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledListIcon = styled(Iconify)({
  color: '#C0C0C0',
  fontSize: 30,
})

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const IcdsAdminPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [icdsProcessing, setIcdsProcessing] = useState(false)
  const [icdsData, setIcdsData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [awsRegions, setRegions] = useState([])
  const [version, setVersion] = useState('0.0.0')
  const [currentAwsRegion, setAwsRegion] = useRecoilState(currentAwsRegionAtom)
  const [showAwsRegionSelector, setShowAwsRegionSelector] = useState(false)
  const [detailGridType, setDetailGridType] = useState({ data: null, type: null })
  const containerRef = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getIcdsVersion = useInnovaAxios({
    url: '/admin/icds/version',
  })

  const getData = useInnovaAxios({
    url: '/admin/icds/processList',
  })

  const getAwsRegions = useInnovaAxios({
    url: '/admin/getAwsRegions',
  })

  const startAll = useInnovaAxios({
    url: '/admin/icds/startAll',
  })

  const stopAll = useInnovaAxios({
    url: '/admin/icds/stopAll',
  })

  const refreshAll = useInnovaAxios({
    url: '/admin/icds/refreshAll',
  })

  const restartIcds = useInnovaAxios({
    url: '/admin/icds/refresh',
  })

  const stopIcds = useInnovaAxios({
    url: '/admin/icds/stop',
  })

  const startIcds = useInnovaAxios({
    url: '/admin/icds/start',
  })

  const fetchAwsRegions = async () => {
    const response = await getAwsRegions()
    if (response?.error) return

    if (!Array.isArray(response.data)) return
    if (!_isMounted.current) return

    let awsList = []
    response.data.forEach((region) => {
      awsList.push({ label: region, value: region })
    })

    setRegions(awsList)
  }

  const fetchIcdsVersion = async () => {
    if (!currentAwsRegion) return
    if (typeof currentAwsRegion !== 'string') return
    if (currentAwsRegion === '') return

    const response = await getIcdsVersion({
      awsRegion: currentAwsRegion,
    })

    if (response.error) return
    setVersion(response.data ? response.data : '0.0.0')
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminInnovaIcdsKey)
    fetchIcdsVersion()
    fetchAwsRegions()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [currentAwsRegion]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.name)
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData({ awsRegion: currentAwsRegion })
    if (_isMounted.current) {
      setIcdsData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('icdsAdminGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('icdsAdminGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'IcdsData.xlsx',
            sheetName: 'IcdsData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleIcdsProcess = async (data, command) => {
    if (!data) return
    if (!data.hasOwnProperty('organization')) return
    if (!currentAwsRegion) return
    if (typeof currentAwsRegion !== 'string') return
    if (currentAwsRegion === '') return
    if (icdsProcessing) return
    if (!command) return
    if (typeof command !== 'string') return
    if (command === '') return

    let payload = {
      orgName: data.organization,
      awsRegion: currentAwsRegion,
    }

    let res = null
    let successMessage = ''
    if (_isMounted.current) setIcdsProcessing(true)
    if (command === 'refresh') {
      res = await restartIcds(payload)
      successMessage = 'icdsServer restarted successfully'
    }

    if (command === 'start') {
      res = await startIcds(payload)
      successMessage = 'icdsServer started successfully'
    }

    if (command === 'stop') {
      res = await stopIcds(payload)
      successMessage = 'icdsServer stopped successfully'
    }

    if (_isMounted.current) setIcdsProcessing(false)
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setStatus({ show: true, severity: 'success', message: successMessage })
    fetchData()
  }

  const handleMenuClick = (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'stop' || action === 'start' || action === 'refresh') {
      handleIcdsProcess(data, action)
    }

    if (action === 'logs') {
      showIcdsDetail(data, 'logs')
    }

    if (action === 'diagnostics') {
      showIcdsDetail(data, 'diagnostics')
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        colId: 'rowNum',
        headerName: '#',
        valueGetter: (params) => params.node.rowIndex + 1,
        cellStyle: centerAlignCell,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Restart',
                action: 'refresh',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:restart' />
                  </StyledIconContainer>
                ),
              },
              {
                label: params.data?.pm2_env.status === 'online' ? 'Stop' : 'Start',
                action: params.data?.pm2_env.status === 'online' ? 'stop' : 'start',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon
                      icon={params.data?.pm2_env.status === 'online' ? 'fa6-solid:circle-stop' : 'el:play-alt'}
                      color={params.data?.pm2_env.status === 'online' ? 'red' : 'green'}
                    />
                  </StyledIconContainer>
                ),
              },
              {
                label:
                  detailGridType.data?.name === params.data?.name && detailGridType.type === 'logs'
                    ? 'Hide logs'
                    : 'View logs',
                action: 'logs',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='icon-park:log' />
                  </StyledIconContainer>
                ),
              },
              {
                label:
                  detailGridType.data?.name === params.data?.name && detailGridType.type === 'diagnostics'
                    ? 'Hide diagnostics'
                    : 'View diagnostics',
                action: 'diagnostics',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='ic:baseline-troubleshoot' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
      },
      {
        field: 'name',
        colId: 'icdsName',
        headerName: 'Name',
      },
      {
        colId: 'version',
        valueGetter: (params) => version,
        headerName: 'Version',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'pid',
        field: 'pid',
        headerName: 'Process ID',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'status',
        headerName: 'Status',
        valueGetter: (params) => params.data?.pm2_env.status,
        cellStyle: (params) => {
          return {
            ...centerAlignCell,
            color: params.data?.pm2_env.status === 'online' ? '#66FF00' : 'red',
          }
        },
      },
      {
        colId: 'uptime',
        headerName: 'Uptime',
        valueGetter: (params) => timeSince(params.data?.pm2_env.pm_uptime),
        cellStyle: centerAlignCell,
      },
      {
        colId: 'cpu',
        headerName: 'CPU (%)',
        valueGetter: (params) => params.data?.monit.cpu,
        cellStyle: (params) => {
          let cpu = params.data?.monit.cpu
          let color = '#66FF00'
          if (cpu > 10 && cpu <= 25) color = 'orange'
          if (cpu > 25) color = 'red'
          return {
            ...centerAlignCell,
            color: color,
          }
        },
      },
      {
        colId: 'memory',
        headerName: 'Memory (Mb)',
        valueGetter: (params) => Math.round(params.data?.monit.memory / 1e6),
        cellStyle: (params) => {
          let memoryMb = Math.round(params.data?.monit.memory / 1e6)
          let color = '#66FF00'
          if (memoryMb > 50 && memoryMb <= 100) color = 'orange'
          if (memoryMb > 100) color = 'red'
          return {
            ...centerAlignCell,
            color: color,
          }
        },
      },
      {
        headerName: '#Instances',
        valueGetter: (params) => params.data?.pm2_env.instances,
        cellStyle: centerAlignCell,
      },
      {
        headerName: '#Restarts',
        valueGetter: (params) => params.data?.pm2_env.restart_time,
        cellStyle: centerAlignCell,
      },
    ],
    [centerAlignCell, version, detailGridType], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const AwsRegionModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({})

    let formData = [
      {
        tag: 'awsRegion',
        value: currentAwsRegion,
        text: 'AWS Region',
        inputType: 'dropdown',
        dropDownValues: awsRegions,
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={showAwsRegionSelector}
        onClose={cancelFunction}
        title={'Select AWS Region'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const changeAwsRegion = async (data) => {
    setShowAwsRegionSelector(false)
    setAwsRegion(data.awsRegion)
    return true
  }

  const handleIcdsProcessAll = async (command) => {
    if (!command) return
    if (typeof command !== 'string') return
    if (command === '') return
    if (icdsProcessing) return

    if (!currentAwsRegion) return
    if (typeof currentAwsRegion !== 'string') return
    if (currentAwsRegion === '') return

    const payload = {
      awsRegion: currentAwsRegion,
    }

    if (_isMounted.current) setIcdsProcessing(true)
    let res = null
    let successMessage = ''
    if (command === 'start') {
      res = await startAll(payload)
      successMessage = 'All icdsServers started successfully'
    }

    if (command === 'stop') {
      res = await stopAll(payload)
      successMessage = 'All icdsServers stopped successfully'
    }

    if (command === 'refresh') {
      res = await refreshAll(payload)
      successMessage = 'All icdsServers restarted successfully'
    }

    if (!_isMounted.current) return
    setIcdsProcessing(false)

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setStatus({ show: true, severity: 'success', message: successMessage })
    fetchData()
  }

  const DetailCellRenderer = () => {
    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        {detailGridType.type === 'logs' ? <IcdsLogGrid icdsName={detailGridType.data?.name} height={height} /> : null}
        {detailGridType.type === 'diagnostics' ? (
          <IcdsDiagnosticsGrid organization={detailGridType.data?.organization} height={height} />
        ) : null}
      </Box>
    )
  }

  const showIcdsDetail = (data, type) => {
    if (!gridApi.current) return

    let shouldRefresh = false
    gridApi.current.forEachNode((node) => {
      if (node.data.name !== data.name) gridApi.current.setRowNodeExpanded(node, false)

      if (node.data.name === data.name) {
        let shouldExpand = detailGridType.data?.name !== data.name || detailGridType.type !== type

        setDetailGridType({ data: shouldExpand ? data : null, type: shouldExpand ? type : null })
        gridApi.current.setRowNodeExpanded(node, shouldExpand)

        if (shouldExpand) shouldRefresh = true
      }
    })

    if (shouldRefresh) gridApi.current.refreshCells()
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      {showAwsRegionSelector ? (
        <AwsRegionModal
          data={null}
          submitFunction={changeAwsRegion}
          cancelFunction={() => {
            setShowAwsRegionSelector(false)
          }}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => {}}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading || icdsProcessing}
            rowData={
              Array.isArray(icdsData) ? icdsData.sort((a, b) => a.organization.localeCompare(b.organization)) : []
            }
            columnDefs={sortColDefs(columnDefs, 'icdsAdminGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='logos:aws' />,
              name: 'Select AWS region',
              onClick: () => setShowAwsRegionSelector(true),
            },
            {
              icon: <StyledListIcon icon='bi:play-circle-fill' color='green' />,
              name: 'Start All',
              onClick: () => handleIcdsProcessAll('start'),
            },
            {
              icon: <StyledListIcon icon='bi:stop-circle-fill' color='red' />,
              name: 'Stop All',
              onClick: () => handleIcdsProcessAll('stop'),
            },
            {
              icon: <StyledListIcon icon='ic:twotone-replay-circle-filled' color='orange' />,
              name: 'Restart All',
              onClick: () => handleIcdsProcessAll('refresh'),
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default IcdsAdminPage
