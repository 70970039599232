import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import BlankImage from 'assets/NoImage.png'
import ElementPropertyModal from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ElementPropertyModal'
import { ELEMENT_TYPES as elementType } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { cloneDeep } from 'lodash'
import useWallPlotComposerFiles from 'components/common/hooks/WallPlotComposer/useWallPlotComposerFiles'

const ContainedImage = ({ id, src, keepAspectRatio, resizeWithContainer }) => {
  const imgRef = useRef(null)

  const getObjectFitStyle = (keepAspectRatio, resizeWithContainer) => {
    if (keepAspectRatio) {
      return resizeWithContainer ? 'contain' : 'cover'
    }
    return resizeWithContainer ? 'fill' : 'none'
  }

  return (
    <Box
      component='img'
      ref={imgRef}
      src={src}
      alt={`imageElement - ${id}`}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: getObjectFitStyle(keepAspectRatio, resizeWithContainer),
      }}
    />
  )
}

export const getImageElementDefaults = ({ pageLayout, zIndex }) => {
  return {
    uid: uuidv4(),
    imageSettings: {
      aspectRatio: true,
      fileName: '',
      fileDesc: '<no image>',
      imageType: 'image',
      resizeContainer: true,
    },
    data: {
      imageSrc: BlankImage,
    },
    left: pageLayout.marginLeft,
    top: pageLayout.marginTop,
    width: 2,
    height: 1,
    zIndex: zIndex,
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#333',
      backgroundColor: '#fff',
      opacity: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
}

const ImageElement = forwardRef(({ id, element, wellData, actions, updateContainer }, ref) => {
  const [showProperties, setShowProperties] = useState(false)
  const [currentContent, setCurrentContent] = useState({ ...element })
  const [imageData, setImageData] = useState(null)
  const { fetchImages, images } = useWallPlotComposerFiles(wellData?.wellName)
  useImperativeHandle(ref, () => {
    return {
      showProperties() {
        setShowProperties(true)
      },
    }
  })

  useEffect(() => {
    if (!images || images.length < 1) return
    setImageData(images[0].data)
  }, [images])

  useEffect(() => {
    fetchImages(currentContent.imageSettings.fileName)
    // intentionally not including fetchImages in the dependency array
  }, [currentContent]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (newData) => {
    let newContent = cloneDeep(currentContent)
    newContent.imageSettings.aspectRatio = newData.context.aspectRatio
    newContent.imageSettings.resizeContainer = newData.context.resizeContainer
    if (newData.context.hasOwnProperty('fileName')) {
      newContent.imageSettings.fileDesc = newData.context.fileDesc // imageFile.description
      newContent.imageSettings.fileName = newData.context.fileName // imageFile.name
      fetchImages(newData.context.fileName)
    }

    actions.update(id, 'update', newContent)
    updateContainer(newContent.style)
    setCurrentContent(newContent)
    setShowProperties(false)
  }

  const getImage = () => {
    switch (currentContent.imageSettings.imageType) {
      case 'asset':
        if (currentContent.data.imageSrc === '') {
          return BlankImage
        }
        return currentContent.data.imageSrc
      case 'image':
        if (!imageData || imageData === '') {
          return BlankImage
        }
        return imageData
      default:
        return ''
    }
  }

  return (
    <>
      {showProperties && (
        <ElementPropertyModal
          onClose={() => setShowProperties(false)}
          elementStyle={currentContent.style}
          contextData={{ ...currentContent.imageSettings, ...currentContent.data, wellName: wellData?.wellName }}
          onApply={handleChange}
          elemType={elementType.image}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
        onDoubleClick={(e) => {
          e.stopPropagation()
          setShowProperties(true)
        }}>
        <ContainedImage
          id={id}
          src={getImage()}
          maxHeight={300}
          keepAspectRatio={currentContent.imageSettings.aspectRatio}
          resizeWithContainer={currentContent.imageSettings.resizeContainer}
        />
      </div>
    </>
  )
})

export default ImageElement
