import { useState, useRef, useEffect } from 'react'
import useInnovaAxios from './useInnovaAxios'

function useAlerts(byUser, byRole, userName, roleId) {
  const _isMounted = useRef(false)
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const [alertData, setAlertData] = useState(null)

  const getAlertsList = useInnovaAxios({
    url: '/alerts/getAlertsList',
  })

  const updateRoleAlerts = useInnovaAxios({
    url: '/alerts/updateRoleAlerts',
  })

  const updateUserAlerts = useInnovaAxios({
    url: '/alerts/updateUserAlerts',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchAlertsList()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAlertsList = async () => {
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)
    const response = await getAlertsList({ roleId: roleId, user: userName, byRole: byRole, byUser: byUser })
    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (response?.error) {
      return
    }

    setAlertData(response.data || [])
  }

  const updateAlertsByRole = async (alerts, headers) => {
    if (!alerts) return
    if (!Array.isArray(alerts)) return
    if (alerts.length === 0) return
    if (!headers) return
    if (!Array.isArray(headers)) return
    if (headers.length === 0) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    for (let i = 0; i < alerts.length; i++) {
      if (alerts[i].useMax) {
        alerts[i].max = parseFloat(alerts[i].max)
      }
      if (alerts[i].useMin) {
        alerts[i].min = parseFloat(alerts[i].min)
      }
      alerts[i].source = 'role'
    }

    const response = await updateRoleAlerts({
      notifications: JSON.stringify(headers),
      alertData: JSON.stringify(alerts),
      roleId: roleId,
    })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (response?.error) {
      return
    }

    fetchAlertsList(false, true)
  }

  const updateAlertsByUser = async (alerts, headers) => {
    if (!alerts) return
    if (!Array.isArray(alerts)) return
    if (alerts.length === 0) return
    if (!headers) return
    if (!Array.isArray(headers)) return
    if (headers.length === 0) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    for (let i = 0; i < alerts.length; i++) {
      if (alerts[i].useMax) {
        alerts[i].max = parseFloat(alerts[i].max)
      }
      if (alerts[i].useMin) {
        alerts[i].min = parseFloat(alerts[i].min)
      }
      alerts[i].source = 'user'
    }

    const response = await updateUserAlerts({
      notifications: JSON.stringify(headers),
      alertData: JSON.stringify(alerts),
      user: userName,
    })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (response?.error) {
      return
    }

    fetchAlertsList(true, true)
  }

  return {
    loading,
    alertData,
    updateAlertsByRole,
    updateAlertsByUser,
  }
}

export default useAlerts
