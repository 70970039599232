import React, { useMemo } from 'react'
import { shadeType } from '../curveDefs'
import { AgGridReact } from 'ag-grid-react'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useCurveDefinitions from 'components/common/hooks/useCurveDefinitions'

const CurveSettingsGrid = ({ data, handleUpdate, track }) => {
  const gridApi = React.useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { curveMnemonics } = useCurveDefinitions()

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const columnDefs = [
    {
      headerName: '',
      field: 'label',
      colId: 'label',
      minWidth: 150,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    {
      headerName: '',
      field: 'value',
      colId: 'value',
      minWidth: 350,
      editable: true,
      cellStyle: (params) => {
        if (params.data?.type === 'color') {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        }
        return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
      },
      cellEditorSelector: (params) => {
        if (params.data?.type === 'dropDown') {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: params.data?.dropDownValues,
            },
          }
        }

        if (params.data?.type === 'color') {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
            },
            popup: true,
          }
        }

        if (params.data?.type === 'number') {
          return {
            component: 'agTextCellEditor',
            params: {
              precision: 3,
            },
          }
        }

        return {
          component: 'agTextCellEditor',
        }
      },
      valueSetter: (params) => {
        if (params.oldValue === params.newValue) return

        let gridData = []
        gridApi.current.forEachNode((node) => {
          if (node.data) gridData.push(node.data)
        })

        if (params.data?.type === 'color' || params.data?.type === 'text' || params.data?.type === 'dropDown') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index >= 0) {
            gridData[index].value = params.newValue
          }
        }

        if (params?.data?.type === 'number') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (params.newValue === null || params.newValue === undefined) params.newValue = 0
          if (index >= 0) gridData[index].value = parseFloat(params.newValue)
        }
      },
      valueFormatter: (params) => {
        if (params?.data?.type === 'number') {
          if (Number.MAX_SAFE_INTEGER - Math.abs(params?.data?.value) < 1) {
            return ''
          }

          return `${numberWithCommasDecimals(params?.data?.value, 3)}`
        }
        return params?.data?.value
      },
    },
  ]

  const getCurveData = (data) => {
    let curvedata = [
      { label: `Method`, value: '', id: 0, type: 'dropDown', tag: `shadeType`, dropDownValues: [] },
      { label: `Color`, value: '', id: 1, type: 'color', tag: `shadeColor` },
      { label: `Threshold`, value: '', id: 2, type: 'number', tag: `shadeValue` },
    ]

    if (!data) return curvedata
    if (!data.hasOwnProperty('shading')) return curvedata
    if (!Array.isArray(data.shading)) return curvedata
    if (data.shading.length === 0) return curvedata

    if (data.shading[0]?.shadeType?.value === shadeType[2].value) {
      curvedata.push({ label: `Curve`, value: '', id: 3, type: 'dropDown', tag: `shadeCurve`, dropDownValues: [] })
    }
    for (let i = 0; i < curvedata.length; i++) {
      if (curvedata[i].tag === '') continue
      if (!data.shading[0].hasOwnProperty(curvedata[i].tag)) continue
      if (curvedata[i].tag === 'shadeType') {
        curvedata[i].value = data.shading[0][curvedata[i].tag].label
      } else {
        curvedata[i].value = data.shading[0][curvedata[i].tag]
      }
    }

    curvedata[0].dropDownValues = Array.isArray(shadeType) ? shadeType.map((type) => type.label) : []
    if (data.shading[0]?.shadeType?.value === shadeType[2].value) {
      curvedata[3].dropDownValues = Array.isArray(track) ? track.map((mnem) => mnem?.label) : []

      if (curveMnemonics.find((mnem) => mnem.value === curvedata[3].value)) {
        curvedata[3].value = curveMnemonics.find((mnem) => mnem.value === curvedata[3].value).label
      }
    }

    return curvedata
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      if (handleUpdate) handleUpdate(event.data, 'shading')
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getCurveData(data)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default CurveSettingsGrid
