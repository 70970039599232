import * as THREE from 'three'

import { DoubleSide } from 'three'
import React, { useRef } from 'react'
import { useFrame } from '@react-three/fiber'
import { rotateAroundWorldAxis } from 'components/ThreeDeeView/ChartComponents/WorkSight'

const WorkSight = ({ focusedSurveyIndex, bhaData, scale, od }) => {
  const ref = useRef()

  const moveWorkSight = () => {
    if (!Array.isArray(bhaData?.results)) return null
    if (bhaData.results.length === 0) return

    let index = 0
    if (focusedSurveyIndex.current >= 0 && focusedSurveyIndex.current < bhaData.results.length) {
      index = focusedSurveyIndex.current
    }

    let newPosition = new THREE.Vector3(
      bhaData.results[index].surveyStation.ns,
      -bhaData.results[index].surveyStation.tvd,
      bhaData.results[index].surveyStation.ew,
    )

    ref.current.position.set(newPosition.x, newPosition.y, newPosition.z)

    let incRad = bhaData.results[index].surveyStation.inc * (Math.PI / 180)
    let aziRad = bhaData.results[index].surveyStation.azi * (Math.PI / 180)

    let newRot = new THREE.Vector3(0, -aziRad + (Math.PI / 2), incRad)
    let rotOrder = ['z', 'y']

    ref.current.rotation.set(0, 0, 0)
    ref.current.updateMatrix()
    rotOrder.forEach((ax) => rotateAroundWorldAxis(ref.current, ax, newRot))
  }

  useFrame(() => {
    moveWorkSight()
  })

  return (
    <group ref={ref} position={[0, 0, 0]}>
      <mesh>
        <circleGeometry args={[od * scale * 1.5, 48]} />
        <meshBasicMaterial color={'#00ff00'} transparent={true} opacity={0.5} side={DoubleSide} />
      </mesh>
    </group>
  )
}

export default WorkSight
