import React, { useRef, useEffect, useState, useMemo } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import SectionPickListDialog from 'components/common/SectionPickListDialog'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { cloneDeep, debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const InnovaAdminUsersPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isUpdating = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [innovaAdminUserData, setInnovaAdminUserData] = useState([])
  const [notificationOptions, setNotificationOptions] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const selectedUser = useRef(null)
  const [showEmailPerfs, setShowEmailPerfs] = useState(false)
  const [showPushPerfs, setShowPushPerfs] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getInnovaAdminUsers = useInnovaAxios({
    url: '/admin/innovaAdmin/getInnovaAdminUsers',
  })

  const updateEmailPrefs = useInnovaAxios({
    url: '/admin/innovaAdmin/updateInnovaAdminUserEmailNotifications',
  })

  const updatePushPrefs = useInnovaAxios({
    url: '/admin/innovaAdmin/updateInnovaAdminUserPushNotifications',
  })

  const getNotificationOptions = useInnovaAxios({
    url: '/admin/innovaAdmin/getNotificationOptions',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaAdminUsersKey)
    fetchInnovaAdminUsers()
    fetchNotificationOptions()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.userName)
    }
  }, [])

  const fetchInnovaAdminUsers = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getInnovaAdminUsers()

    if (res?.error) return
    if (!_isMounted.current) return

    setInnovaAdminUserData(Array.isArray(res.data) ? res.data : [])
    setLoading(false)
  }

  const fetchNotificationOptions = async () => {
    const res = await getNotificationOptions()

    if (res?.error) return
    if (!Array.isArray(res.data)) return

    let options = [{ header: 'Notifications', data: [] }]

    res.data.forEach((val) => {
      options[0].data.push({ id: val.id, desc: val.description })
    })

    if (_isMounted.current) setNotificationOptions(options)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('innovaAdminUsersGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaAdminUsersGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InnovaAdminUsers.xlsx',
            sheetName: 'Users',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleMenuClick = (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'email') {
      let selectedItems = []
      if (typeof data.emailOptionsStr === 'string') {
        let splitStr = data.emailOptionsStr.split(',')
        splitStr.forEach((strObj) => {
          selectedItems.push(parseInt(strObj))
        })
      }

      selectedUser.current = { userName: data.userName, emailPerfs: selectedItems, pushPerfs: [] }
      setShowEmailPerfs(true)
    }

    if (action === 'sms') {
      let selectedItems = []
      if (typeof data.pushOptionsStr === 'string') {
        let splitStr = data.pushOptionsStr.split(',')
        splitStr.forEach((strObj) => {
          selectedItems.push(parseInt(strObj))
        })
      }

      selectedUser.current = { userName: data.userName, emailPerfs: [], pushPerfs: selectedItems }
      setShowPushPerfs(true)
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Email Notifications',
                action: 'email',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='eva:email-outline' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Mobile App Notifications',
                action: 'sms',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fxemoji:cellphone' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
      },
      {
        field: 'pushToken',
        colId: 'pushToken',
        headerName: 'Expo Push Token',
      },
    ],
    [centerAlignCell],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const applyEmailPerfs = async (selectedPrefs) => {
    setShowEmailPerfs(false)
    if (!selectedUser.current?.userName) return
    if (selectedUser.current?.userName === '') return
    if (!selectedPrefs) return
    if (!Array.isArray(selectedPrefs)) return
    if (isUpdating.current) return

    let strArray = ''
    selectedPrefs.forEach((pref) => {
      if (pref === '') return
      if (strArray !== '') strArray += ','
      strArray += pref
    })

    isUpdating.current = true
    const res = await updateEmailPrefs({
      userNameToUpdate: selectedUser.current.userName,
      emailNotifications: strArray,
    })
    isUpdating.current = false

    if (!_isMounted.current) return
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setStatus({ show: true, severity: 'success', message: 'Email preferences updated' })

    if (gridApi.current) {
      const rowNode = gridApi.current.getRowNode(selectedUser.current.userName)
      if (!rowNode) return
      let newData = cloneDeep(rowNode.data)
      newData.emailOptionsStr = strArray

      gridApi.current.applyTransaction({
        update: [newData],
      })
    }
  }

  const applyPushPerfs = async (selectedPrefs) => {
    setShowPushPerfs(false)
    if (!selectedUser.current?.userName) return
    if (selectedUser.current?.userName === '') return
    if (!selectedPrefs) return
    if (!Array.isArray(selectedPrefs)) return
    if (isUpdating.current) return

    let strArray = ''
    selectedPrefs.forEach((pref) => {
      if (pref === '') return
      if (strArray !== '') strArray += ','
      strArray += pref
    })

    isUpdating.current = true
    const res = await updatePushPrefs({
      userNameToUpdate: selectedUser.current.userName,
      pushNotifications: strArray,
    })
    isUpdating.current = false

    if (!_isMounted.current) return
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setStatus({ show: true, severity: 'success', message: 'Push notice preference update success' })
    if (gridApi.current) {
      const rowNode = gridApi.current.getRowNode(selectedUser.current.userName)
      if (!rowNode) return
      let newData = cloneDeep(rowNode.data)
      newData.pushOptionsStr = strArray

      gridApi.current.applyTransaction({
        update: [newData],
      })
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {showEmailPerfs ? (
        <SectionPickListDialog
          title='Select Email Preferences'
          open={showEmailPerfs}
          setOpen={() => setShowEmailPerfs(false)}
          onApply={applyEmailPerfs}
          items={notificationOptions}
          initSelItems={Array.isArray(selectedUser.current.emailPerfs) ? selectedUser.current.emailPerfs : []}
        />
      ) : null}
      {showPushPerfs ? (
        <SectionPickListDialog
          title='Select Push Notification Preferences'
          open={showPushPerfs}
          setOpen={() => setShowPushPerfs(false)}
          onApply={applyPushPerfs}
          items={notificationOptions}
          initSelItems={Array.isArray(selectedUser.current.pushPerfs) ? selectedUser.current.pushPerfs : []}
        />
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={innovaAdminUserData}
            columnDefs={sortColDefs(columnDefs, 'innovaAdminUsersGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchNotificationOptions()
                fetchInnovaAdminUsers()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InnovaAdminUsersPage
