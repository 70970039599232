import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import LineCellRenderer from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/LineCellRenderer'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ChartSeriesStyleGrid = forwardRef(({ chartData }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      chartData.series.forEach((series) => {
        let data = gridData.find((data) => data.wellName === series.wellName)
        if (data) {
          data.type = series.type
        } else {
          gridData.push(series)
        }
      })
      return { series: gridData }
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Well',
        field: 'wellName',
        colId: 'wellName',
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 72,
        width: 72,
        maxWidth: 72,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultValue,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Line',
        field: 'lineStyle',
        colId: 'lineStyle',
        width: 100,
        flex: 1,
        editable: true,
        cellDataType: false,

        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: 'agRichSelectCellEditor',
            popup: true,
            params: {
              cellRenderer: LineCellRenderer,
              values: ['solid', 'dotted', 'dashed'],
              valueListMaxHeight: 125,
            },
          }
        },
        cellRendererSelector: (params) => {
          return {
            component: LineCellRenderer,
          }
        },
      },
      {
        headerName: 'Thickness',
        field: 'lineThickness',
        colId: 'lineThickness',
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
    ],
    [],
  )

  const getRowData = (data) => {
    let rowData = []
    if (!data) return rowData

    rowData.push({
      id: 0,
      wellName: data.series[0].wellName,
      color: data.series[0].color ? data.series[0].color : '#008800',
      lineStyle: data.series[0].lineStyle ? data.series[0].lineStyle : 'solid',
      lineThickness: data.series[0].lineThickness ? data.series[0].lineThickness : 1,
    })

    if (data.showOffsets) {
      for (let i = 1; i < data.series.length; i++) {
        rowData.push({
          id: i,
          wellName: data.series[i].wellName,
          color: data.series[i].color ? data.series[i].color : '#008800',
          lineStyle: data.series[i].lineStyle ? data.series[i].lineStyle : 'solid',
          lineThickness: data.series[i].lineThickness ? data.series[i].lineThickness : 1,
        })
      }
    }

    return rowData
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getRowData(chartData)}
        headerHeight={30}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
})

export default ChartSeriesStyleGrid
