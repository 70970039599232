import React, { useEffect, useMemo, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import { Box } from '@mui/material'
import AntiCollisionDashboardGrid from 'components/AntiCollisionDashboard/AntiCollisionDashboardGrid'
import AnalyticsHorizontalBarChart from 'components/AntiCollisionDashboard/AntiCollisionDashboardChart'

const AntiCollisionDashPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [showChart, setShowChart] = useState(true)
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.antiCollisionDashboardPageKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const AntiColGrid = useMemo(
    () => (
      <AntiCollisionDashboardGrid setFilteredData={setFilteredData} showChart={showChart} setShowChart={setShowChart} />
    ),
    [showChart, setShowChart, setFilteredData],
  )

  return (
    <SplitPane
      split='horizontal'
      defaultSize={'50%'}
      size={showChart ? '50%' : 0}
      style={{
        marginTop: '64px',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 64px)',
        maxHeight: 'calc(100vh - 64px)',
        minHeight: 'calc(100vh - 64px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}
      allowResize={true}>
      <Box sx={{ width: '99.9%', height: '100%', display: 'flex', flexDirection: 'row' }}>
        <AnalyticsHorizontalBarChart data={filteredData} title={''} />
      </Box>
      {AntiColGrid}
    </SplitPane>
  )
}

export default AntiCollisionDashPage
