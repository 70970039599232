import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { LABEL_CATEGORIES as labelCategories } from '../ChartElements/labelDefinitions'
import ColorPickerCellEditor from 'components/common/CellEditors/ColorPickerCellEditor'
import { getStringId } from 'components/common/AgGridUtils'
import { CHART_TYPES as chartTypes } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Box } from '@mui/material'
import ChartDetailsPropertyGrid from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/ChartDetailsPropertyGrid'

const getDepthLabelDefault = () => {
  return {
    mdTvd: 'MD',
    inc: true,
    azi: true,
    interval: 100,
    atSurvey: true,
    range: false,
    startDepth: 0,
    endDepth: 0,
  }
}

const ChartLabelsGrid = forwardRef(({ chartData, chartType }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let categoryData = []
      let categoryOptions = []

      const rows = gridApi.current?.getSelectedRows()
      rows.forEach((row) => {
        categoryData.push({
          category: row.category,
          enabled: true,
          defaultColor: row.defaultColor,
          defaultColorEnabled: row.defaultColorEnabled,
          color: row.color,
          size: row.size,
          bold: row.bold,
          hasTether: row.hasTether,
        })
        if (row.category === labelCategories.surveyPts || row.category === labelCategories.offsetSurveyPts) {
          categoryOptions.push({
            category: row.category,
            options: row.options,
          })
        }
      })

      return {
        labels: {
          categories: categoryData,
          categoryOptions: categoryOptions,
          labelData: [], // probably need to populate this here eventually,
          // if a separate labels style grid is implemented. (so we can switch between grids)
        },
      }
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData())
    // // set rows as selected if enabled
    gridApi.current.forEachNode((node) => {
      node.setSelected(node.data.enabled)
    })
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    popupParent: document.querySelector('chartLabelDiv'),
    onCellEditingStopped: (params) => {
      if (
        (params.node.data?.category === labelCategories.surveyPts ||
          params.node.data?.category === labelCategories.offsetSurveyPts) &&
        params.colDef?.colId === 'options'
      ) {
        params.api.applyTransaction({ update: [params.node.data] })
      }
    },
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRendererSelector: (params) => {
          if (params.data?.category === labelCategories.surveyPts) return { component: 'agGroupCellRenderer' }
          if (params.data?.category === labelCategories.offsetSurveyPts) return { component: 'agGroupCellRenderer' }
          return null
        },
        width: 50,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Category',
        headerCheckboxSelection: false,
        checkboxSelection: true,
        field: 'category',
        colId: 'category',
        minWidth: 100,
        width: 200,
        maxWidth: 250,
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Def Color',
        field: 'defaultColorEnabled',
        colId: 'defaultColorEnabled',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.newValue === true) {
            params.data.color = '#FFFFFF'
          }
          params.data.defaultColorEnabled = params.newValue
          return true
        },
      },
      {
        headerName: 'Color',
        field: 'color',
        colId: 'color',
        minWidth: 80,
        width: 80,
        maxWidth: 80,
        flex: 1,
        editable: (params) => {
          return params.data?.defaultColorEnabled === false
        },
        cellDataType: false,
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            backgroundColor: params.value,
            color: params.value,
          }
        },
        cellEditorSelector: (params) => {
          return {
            component: ColorPickerCellEditor,
            params: {
              color: params.value,
              defaultColor: params.data?.defaultValue,
            },
            popup: true,
          }
        },
      },
      {
        headerName: 'Size',
        field: 'size',
        colId: 'size',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Bold',
        field: 'bold',
        colId: 'bold',
        flex: 1,
        editable: true,
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
      {
        headerName: 'Has Tether',
        field: 'hasTether',
        colId: 'hasTether',
        flex: 1,
        editable: (params) => {
          if (params.data?.category === labelCategories.casing) return false
          return true
        },
        cellStyle: centerAlignCell,
        cellEditorSelector: (params) => {
          return {
            component: 'agCheckboxCellEditor',
          }
        },
      },
    ],
    [centerAlignCell],
  )

  const getRowData = (data) => {
    let rowData = []
    // if (!data) return rowData // enable when data is attached to layout template

    rowData.push({
      category: labelCategories.surveyPts,
      defaultColorEnabled: true,
      color: '#FFFFFF',
      defaultColor: '#a961fa',
      size: 9,
      bold: false,
      hasTether: true,
      options: getDepthLabelDefault(),
    })
    rowData.push({
      category: labelCategories.wellNames,
      defaultColorEnabled: true,
      color: '#FFFFFF',
      defaultColor: '#a961fa',
      size: 12,
      bold: true,
      hasTether: true,
    })
    rowData.push({
      category: labelCategories.casing,
      defaultColorEnabled: true,
      color: '#FFFFFF',
      defaultColor: '#a961fa',
      size: 10,
      bold: false,
      hasTether: false,
    })
    rowData.push({
      category: labelCategories.annotations,
      defaultColorEnabled: true,
      defaultColor: '#a961fa',
      color: '#FFFFFF',
      size: 9,
      bold: false,
      hasTether: true,
    })
    if (chartData.showTargets) {
      rowData.push({
        category: labelCategories.targets,
        defaultColorEnabled: true,
        color: '#FFFFFF',
        defaultColor: '#a961fa',
        size: 10,
        bold: true,
        hasTether: true,
      })
    }
    if (chartType === chartTypes.sectionView) {
      rowData.push({
        category: labelCategories.lithologies,
        defaultColorEnabled: true,
        color: '#FFFFFF',
        defaultColor: '#a961fa',
        size: 12,
        bold: true,
        hasTether: true,
      })
    }
    if (chartData.showOffsets) {
      rowData.push({
        category: labelCategories.offsetWellNames,
        defaultColorEnabled: true,
        color: '#FFFFFF',
        defaultColor: '#a961fa',
        size: 12,
        bold: true,
        hasTether: true,
      })
      rowData.push({
        category: labelCategories.offsetSurveyPts,
        defaultColorEnabled: true,
        color: '#FFFFFF',
        defaultColor: '#a961fa',
        size: 12,
        bold: true,
        hasTether: true,
        options: getDepthLabelDefault(),
      })
    }

    rowData.forEach((row, index) => {
      row.enabled = false
      const idx = chartData.labels.categories.findIndex((cat) => cat.category === row.category)
      if (idx >= 0) {
        row.enabled = chartData.labels.categories[idx].enabled
        row.defaultColorEnabled = chartData.labels.categories[idx].defaultColorEnabled
        row.defaultColor = chartData.labels.categories[idx].defaultColor
        row.color = chartData.labels.categories[idx].color
        if (typeof row.defaultColorEnabled === 'boolean' && row.defaultColorEnabled && row.defaultColor !== '') {
          row.color = '#FFFFFF'
        }
        row.size = chartData.labels.categories[idx].size
        row.bold = chartData.labels.categories[idx].bold
        row.hasTether = chartData.labels.categories[idx].hasTether
        if (row.category === labelCategories.surveyPts) {
          row.options = chartData.labels.categoryOptions.find(
            (opt) => opt.category === labelCategories.surveyPts,
          )?.options
        }
        if (row.category === labelCategories.offsetSurveyPts) {
          row.options = chartData.labels.categoryOptions.find(
            (opt) => opt.category === labelCategories.offsetSurveyPts,
          )?.options
        }
      }
      row.id = index
    })

    return rowData
  }

  const detailUpdateFunc = (id, params) => {
    const gridData = []
    gridApi.current.forEachNode((node) => {
      if (node.data) gridData.push(node.data)
    })

    const index = gridData.findIndex((data) => data.id === id)
    if (index < 0) return

    gridData[index].options[params.tag] = params.value

    gridApi.current.applyTransaction({ update: gridData })
  }

  const DetailCellRenderer = (params) => {
    if (
      params.data?.category !== labelCategories.surveyPts &&
      params.data?.category !== labelCategories.offsetSurveyPts
    )
      return null
    let options = params.data.options ? params.data.options : getDepthLabelDefault()
    let detailRowData = []

    detailRowData.push({
      label: 'MD/TVD',
      value: options.mdTvd,
      type: 'dropDown',
      tag: 'mdTvd',
      dropDownValues: ['MD', 'TVD'],
    })
    detailRowData.push({
      label: 'Inc',
      value: options.inc,
      type: 'bool',
      tag: 'inc',
    })
    detailRowData.push({
      label: 'Azi',
      value: options.azi,
      type: 'bool',
      tag: 'azi',
    })
    detailRowData.push({
      label: 'At Survey',
      value: options.atSurvey,
      type: 'bool',
      tag: 'atSurvey',
    })
    detailRowData.push({
      label: 'Interval',
      value: options.interval,
      type: 'number',
      tag: 'interval',
      min: 1,
      max: 1000,
      precision: 0,
    })
    detailRowData.push({
      label: 'Range',
      value: options.range,
      type: 'bool',
      tag: 'range',
    })
    detailRowData.push({
      label: 'Start Depth',
      value: options.startDepth,
      type: 'number',
      tag: 'startDepth',
      min: 1,
      max: 1000,
      precision: 0,
    })
    detailRowData.push({
      label: 'End Depth',
      value: options.endDepth,
      type: 'number',
      tag: 'endDepth',
      min: 1,
      max: 99999,
      precision: 0,
    })

    detailRowData.forEach((row, index) => {
      row.id = index
    })

    return (
      // <Box sx={{ height: '70px' }}>
      <Box sx={{ height: `${50 * detailRowData.length}px` }}>
        <ChartDetailsPropertyGrid
          id={params.data.id}
          data={detailRowData}
          handleUpdateFunc={detailUpdateFunc}
          units={getUnitsText(UNITS_FOR.Depth)}
        />
      </Box>
    )
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()} id='chartLabelDiv'>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        headerHeight={30}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
        rowSelection='multiple'
        masterDetail={true}
        detailCellRenderer={DetailCellRenderer}
        detailRowAutoHeight={true}
      />
    </div>
  )
})

export default ChartLabelsGrid
