import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Box, Input, Typography, Checkbox } from '@mui/material'
import { appColors } from 'utils'
import { TreeView } from '@mui/lab'
import wellIcon from 'assets/wellScreenIcons/wellHeadGrey.png'
import actualWellIcon from 'assets/wellScreenIcons/actualWell.png'
import plannedWellIcon from 'assets/wellScreenIcons/schematicBlue.png'
import fieldIcon from 'assets/wellScreenIcons/fieldGrey.png'
import facilityIcon from 'assets/wellScreenIcons/facilityGrey.png'
import operatorIcon from 'assets/wellScreenIcons/operatorGrey.png'
import databaseIcon from 'assets/wellScreenIcons/database-grey.png'
import TreeNode from 'components/DatabaseTree/DatabaseTree/TreeNode'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import {
  MinusSquare,
  PlusSquare,
  TREE_ICON_SIZE,
  TREE_ICON_PADDING,
} from 'components/DatabaseTree/DatabaseTree/DatabaseTree'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CreateDrillersTargetModal = ({ field, handleCreate, onCancel }) => {
  const _isMounted = useRef(false)
  const [confidence, setConfidence] = useState(95)
  const [expandedNodes, setExpandedNodes] = useState([])
  const expandedNodesRef = useRef([])
  const [selectedNode, setSelectedNode] = useState(null)
  const [selectedNodes, setSelectedNodes] = useState([])
  const [isLoading, setLoading] = useState(false)
  const { getWindowBarColor } = useInnovaTheme()
  const rawTreeData = useRef({ nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] })

  const getTreeData = useAxiosGzip({
    url: '/databaseTree/getDatabaseTreeGz',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchTreeData()
    return () => {
      _isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTreeData = async () => {
    if (!_isMounted.current) return
    if (isLoading) return
    setLoading(true)

    let res = await getTreeData({ parentLevel: 'FIELD', parentNodeName: field })

    if (!_isMounted.current) return

    if (res?.error) {
      rawTreeData.current = { nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] }
      setLoading(false)
      return
    }

    if (!res.data) return
    rawTreeData.current = res?.data?.hasOwnProperty('nodeId')
      ? res.data
      : { nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] }

    if (!expandedNodes.includes(getRootNodeId())) handleToggleNodeExpanded(getRootNodeId())
    setLoading(false)
  }

  const handleCreateFunc = () => {
    let wellbore = getSelectedWellbore()
    handleCreate(wellbore, parseFloat(confidence))
  }

  const getRootNodeId = () => {
    if (!rawTreeData.current) return '99999'
    return rawTreeData.current?.nodeId
  }

  const handleToggleNodeExpanded = (nodeId) => {
    let newNodes = [...expandedNodes]
    if (newNodes.includes(nodeId)) {
      newNodes.splice(newNodes.indexOf(nodeId), 1)
    } else {
      newNodes.push(nodeId)
    }

    expandedNodesRef.current = newNodes
    setExpandedNodes(newNodes)
  }

  function getAllIds(node, idList = []) {
    idList.push(node.nodeId)
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList))
    }
    return idList
  }

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph]

    while (queue.length > 0) {
      const currNode = queue.shift()
      if (currNode.nodeId === targetId) {
        return currNode
      }
      if (currNode.children) {
        queue.push(...currNode.children)
      }
    }
    return []
  }

  const isNodeChecked = (nodeId) => {
    if (!Array.isArray(selectedNodes)) return false
    return selectedNodes.findIndex((n) => n.nodeId === nodeId) >= 0
  }

  const getAllChildNodes = (id) => {
    return getAllIds(bfsSearch([rawTreeData.current], id))
  }

  const getParentNodes = (id, list = []) => {
    const node = bfsSearch([rawTreeData.current], id)

    if (node.parentNodeId !== undefined && node.parentNodeId !== null && node.parentNodeId !== '') {
      list.push(node.parentNodeId)
      return getParentNodes(node.parentNodeId, list)
    }

    return list
  }

  const isNodeCheckedAtLevel = (level) => {
    if (!Array.isArray(selectedNodes)) return false
    if (selectedNodes.findIndex((n) => n.level === level) >= 0) return true

    if (level === 'ACTUALWELL') {
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL') >= 0) return true
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL_PRINCIPAL') >= 0) return true
    }

    if (level === 'PLANNEDWELL') {
      if (selectedNodes.findIndex((n) => n.level === 'ACTUALWELL') >= 0) return true
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL_PRINCIPAL') >= 0) return true
    }

    if (level === 'PLANNEDWELL_PRINCIPAL') {
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL') >= 0) return true
      if (selectedNodes.findIndex((n) => n.level === 'ACTUALWELL') >= 0) return true
    }
  }

  const getChildLevels = (level) => {
    if (level === 'OPERATOR') {
      return ['OPERATOR', 'FIELD', 'FACILITY', 'WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    }
    if (level === 'FIELD') return ['FIELD', 'FACILITY', 'WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'FACILITY') return ['FACILITY', 'WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'WELL') return ['WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'ACTUALWELL') return ['ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'PLANNEDWELL') return ['ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'PLANNEDWELL_PRINCIPAL') return ['ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    return []
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation()

    //If node is checked unselect node and all children
    if (isNodeChecked(nodeId)) {
      let prevSelectedNodes = cloneDeep(selectedNodes)
      if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

      let index = prevSelectedNodes.findIndex((n) => n.nodeId === nodeId)
      if (index >= 0) prevSelectedNodes.splice(index, 1)

      const childNodes = getAllChildNodes(nodeId)
      for (let i = 0; i < childNodes.length; i++) {
        if (!isNodeChecked(childNodes[i])) continue
        let index = prevSelectedNodes.findIndex((n) => n.nodeId === childNodes[i])
        if (index < 0) continue
        prevSelectedNodes.splice(index, 1)
      }

      setSelectedNodes(prevSelectedNodes)
      return
    }

    //If node is unchecked then check all parents
    let node = bfsSearch([rawTreeData.current], nodeId)
    if (node?.nodeId === undefined || node?.nodeId === null || node?.nodeId === '') return

    let prevSelectedNodes = cloneDeep(selectedNodes)
    if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

    //If node already selected at current level uncheck and uncheck all its children
    if (isNodeCheckedAtLevel(node.level)) {
      let levelsToRemove = getChildLevels(node.level)
      prevSelectedNodes = prevSelectedNodes.filter((n) => levelsToRemove.findIndex((l) => l === n.level) < 0)
    }

    prevSelectedNodes.push({ nodeId: node.nodeId, level: node.level, name: node.name })

    const parentNodes = getParentNodes(nodeId)
    for (let i = 0; i < parentNodes.length; i++) {
      let node = bfsSearch([rawTreeData.current], parentNodes[i])
      if (!node) continue
      if (prevSelectedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0) continue

      prevSelectedNodes.push({ nodeId: node.nodeId, level: node.level, name: node.name })
    }

    setSelectedNodes(prevSelectedNodes)
  }

  const TreeNodeIcon = ({ icon, node }) => {
    let style = {
      width: `${TREE_ICON_SIZE}px`,
      height: `${TREE_ICON_SIZE}px`,
      marginRight: `${TREE_ICON_PADDING}px`,
      padding: '2px',
    }

    if (node.level === 'PLANNEDWELL_PRINCIPAL') {
      style.border = '1px solid red'
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: selectedNode?.nodeId === node.nodeId ? '#224865' : '',
          borderRadius: '5px',
          paddingRight: '5px',
        }}
        onClick={() => setSelectedNode(node)}>
        <Checkbox
          sx={{ margin: 0, padding: 0 }}
          checked={selectedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0}
          tabIndex={-1}
          disableRipple
          onClick={(event) => {
            handleNodeSelect(event, node.nodeId)
          }}
        />
        <img alt={'Icon'} src={icon} style={style} />
        <Typography
          sx={{
            color: appColors.itemTextColor,
          }}>
          {node.name}
        </Typography>
      </Box>
    )
  }

  const ActualWellNodes = ({ actualWells }) => {
    if (!Array.isArray(actualWells)) return null
    return actualWells.map((actualWell) => {
      return actualWell.level === 'ACTUALWELL' ? (
        <TreeNode
          key={`${actualWell.nodeId}`}
          nodeId={`${actualWell.nodeId}`}
          labelIcon={<TreeNodeIcon icon={actualWellIcon} node={actualWell} />}
          onLabelClick={() => {}}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(`${actualWell.nodeId}`)
          }}></TreeNode>
      ) : null
    })
  }

  const PlannedWellNodes = ({ plannedWells }) => {
    if (!Array.isArray(plannedWells)) return null

    return plannedWells.map((plannedWell) => {
      return plannedWell.level === 'PLANNEDWELL' || plannedWell.level === 'PLANNEDWELL_PRINCIPAL' ? (
        <TreeNode
          key={`${plannedWell.nodeId}`}
          nodeId={`${plannedWell.nodeId}`}
          labelIcon={<TreeNodeIcon icon={plannedWellIcon} node={plannedWell} />}
          onLabelClick={() => {}}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(`${plannedWell.nodeId}`)
          }}></TreeNode>
      ) : null
    })
  }

  const WellNodes = ({ wells }) => {
    if (!Array.isArray(wells)) return null

    return wells.map((well) => (
      <TreeNode
        key={`${well.nodeId}`}
        nodeId={`${well.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={wellIcon} node={well} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${well.nodeId}`)
        }}>
        <ActualWellNodes actualWells={well.children} />
        <PlannedWellNodes plannedWells={well.children} />
      </TreeNode>
    ))
  }

  const FacilityNodes = ({ facilities }) => {
    if (!Array.isArray(facilities)) return null

    return facilities.map((facility) => (
      <TreeNode
        key={`${facility.nodeId}`}
        nodeId={`${facility.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={facilityIcon} node={facility} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${facility.nodeId}`)
        }}>
        <WellNodes wells={facility.children} />
      </TreeNode>
    ))
  }

  const FieldNodes = ({ fields }) => {
    if (!Array.isArray(fields)) return null

    return fields.map((field) => (
      <TreeNode
        key={`${field.nodeId}`}
        nodeId={`${field.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={fieldIcon} node={field} />}
        onLabelClick={(event) => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${field.nodeId}`)
        }}>
        <FacilityNodes facilities={field.children} />
      </TreeNode>
    ))
  }

  const OperatorNodes = ({ operators }) => {
    if (!Array.isArray(operators)) return null

    return operators.map((op) => (
      <TreeNode
        key={`${op.nodeId}`}
        nodeId={`${op.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={operatorIcon} node={op} />}
        onIconClick={async (event) => {
          handleToggleNodeExpanded(`${op.nodeId}`)
        }}>
        <FieldNodes fields={op.children} />
      </TreeNode>
    ))
  }

  const getSelectedWellbore = () => {
    if (!Array.isArray(selectedNodes)) return null
    if (selectedNodes.length === 0) return null

    for (let i = 0; i < selectedNodes.length; i++) {
      if (
        selectedNodes[i].level === 'ACTUALWELL' ||
        selectedNodes[i].level === 'PLANNEDWELL' ||
        selectedNodes[i].level === 'PLANNEDWELL_PRINCIPAL'
      ) {
        return selectedNodes[i]
      }
    }

    return null
  }

  const isCurrentDataValid = () => {
    if (!getSelectedWellbore()) return false
    let confidenceCopy = confidence
    if (typeof confidenceCopy === 'string') confidenceCopy = parseFloat(confidenceCopy)
    if (isNaN(confidenceCopy)) return false
    if (confidenceCopy <= 0 || confidenceCopy >= 100) return false
    return true
  }

  return (
    <Dialog
      maxWidth='xl'
      open={true}
      onClose={onCancel}
      PaperProps={{
        sx: {
          width: '60vw',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Create Drillers Target</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          style={{
            maxWidth: '100%',
            height: '10%',
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: getWindowBarColor(),
            marginBottom: '5px',
            border: '1px solid #808080',
            alignItems: 'center',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '250px' }}>
            Confidence (%):
            <Input
              sx={{ marginLeft: '10px' }}
              inputProps={{
                style: {
                  textAlign: 'center',
                  paddingTop: '5px',
                  backgroundColor: 'black',
                  width: '50px',
                },
              }}
              value={`${confidence}`}
              onChange={(event) => {
                const newValue = event.target.value

                let parsedValue = parseFloat(newValue)
                if (isNaN(parsedValue)) {
                  parsedValue = 0
                } else {
                  parsedValue = Math.min(Math.max(parsedValue, 0), 99)
                }

                setConfidence(parsedValue)
              }}
            />
          </Box>
        </Box>
        <Box
          style={{
            maxWidth: '100%',
            height: 'calc(90% - 8px)',
            backgroundColor: getWindowBarColor(),
            border: '1px solid #808080',
          }}>
          <TreeView
            aria-label='controlled'
            expanded={expandedNodes}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}>
            <TreeNode
              labelIcon={<TreeNodeIcon icon={databaseIcon} node={rawTreeData.current} />}
              nodeId={getRootNodeId()}
              onIconClick={async (event) => {
                handleToggleNodeExpanded(getRootNodeId())
              }}>
              <OperatorNodes operators={rawTreeData.current?.children} />
            </TreeNode>
          </TreeView>
        </Box>
      </DialogContent>
      <DialogActions>
        {isCurrentDataValid() ? (
          <Button
            sx={{ margin: '5px' }}
            variant='contained'
            type='button'
            color='primary'
            onClick={() => handleCreateFunc()}>
            Create
          </Button>
        ) : null}
        <Button sx={{ margin: '5px' }} variant='contained' type='button' color='primary' onClick={() => onCancel()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateDrillersTargetModal
