import { useEffect, useRef, useState } from 'react'
import useInnovaAxios from './useInnovaAxios'

const useCurveDefinitions = () => {
  const _isMounted = useRef(true)
  const [curveDefs, setCurveDefs] = useState([])
  const isLoadingDefs = useRef(false)

  const getCurveDefs = useInnovaAxios({
    url: '/dataAcq/getCurveDefinitions',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchCurveDefs()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCurveDefs = async () => {
    if (!_isMounted.current) return
    if (isLoadingDefs.current) return

    isLoadingDefs.current = true
    try {
      const res = await getCurveDefs()
      isLoadingDefs.current = false
      if (res?.error) return
      if (!res.data) return
      setCurveDefs(res.data)
    } catch (error) {
      console.error('Error fetching curve definitions:', error)
    }
  }

  const getCurveMnemonics = () => {
    if (isLoadingDefs.current) return []
    if (!curveDefs) return []
    if (!Array.isArray(curveDefs)) return []
    return curveDefs
  }

  return { getCurveMnemonics, curveMnemonics: curveDefs }
}

export default useCurveDefinitions
