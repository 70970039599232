import { useEffect, useState, useRef, useCallback } from 'react'
import useInnovaAxios from '../useInnovaAxios'
import html2canvas from 'html2canvas'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { numberWithCommasDecimals, removeSpecialSymbols, replaceEscapeSymbols } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { useRecoilValue } from 'recoil'
import {
  currentWellAtom,
  selectedEngineeringCaseAtom,
  userUserRoleAtom,
  bhaTendencyAnalysisParamsAtom,
  currentBhaAtom,
  bhaCriticalRPMAnalysisParamsAtom,
} from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'
import useBhaImages from '../useBhaImages'
import { getItemFromLS } from 'utils/localStorage'

function useBhaAnalysis() {
  const _isMounted = useRef(true)
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const isLoadingTrajectory = useRef(false)
  const [loadingTrajectory, setLoadingTrajectory] = useState(false)
  const bhaAnalysisData = useRef({})
  const tendencyData = useRef({})
  const trajectoryData = useRef({})
  const criticalRPMData = useRef({})
  const bhaTendencyParams = useRecoilValue(bhaTendencyAnalysisParamsAtom)
  const bhaCriticalRPMParams = useRecoilValue(bhaCriticalRPMAnalysisParamsAtom)
  const [criticalRPMDataUnits, setCriticalRPMDataUnits] = useState({ variable: 'WOB', units: UNITS_FOR.Weight })
  const [criticalRPMMinVal, setCriticalRPMMinVal] = useState(0)
  const currentBha = useRecoilValue(currentBhaAtom)
  const [bhaDepths, setBhaDepths] = useState({ startDepth: 0, endDepth: 100000 })
  const [bhaAnalysisWarnings, setBhaAnalysisWarnings] = useState([])
  const [bhaAnalysisErrors, setBhaAnalysisErrors] = useState([])
  const [bhaTendencyErrors, setBhaTendencyErrors] = useState(null)
  const [bhaTendencyWarnings, setBhaTendencyWarnings] = useState(null)
  const [bhaCriticalRPMErrors, setBhaCriticalRPMErrors] = useState(null)
  const [bhaCriticalRPMWarnings, setBhaCriticalRPMWarnings] = useState(null)
  const { getUnitsText } = useUnits()
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [logos, setLogos] = useState([])
  const { user } = useInnovaAuth()
  const bhaImages = useBhaImages()

  const getBhaAnalysis = useInnovaAxios({
    url: '/engineering/bhaAnalysis',
  })

  const getBhaTendency = useInnovaAxios({
    url: '/engineering/bhaDirectionalTendency',
    timeout: 10 * 60 * 1000,
  })

  const getBhaCriticalRPM = useInnovaAxios({
    url: '/engineering/bhaCriticalRPM',
    timeout: 3 * 60 * 1000,
  })

  const getTrajectory = useInnovaAxios({
    url: '/well/getTrajectory',
  })

  const getAllBha = useInnovaAxios({
    url: '/well/drillString/getAllBha',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const fetchBhaAnalysisData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getBhaAnalysis({ wellName: currentWellRef.current, engCase: selectedEngCase?.recordId })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (response?.error) {
      bhaAnalysisData.current = null
      let errors = []
      if (response?.error?.response?.data?.error) {
        errors.push(response?.error?.response?.data?.error)
      }
      if (response?.error?.message) {
        errors.push(response?.error?.message)
      }
      setBhaAnalysisErrors(errors)
      setBhaAnalysisWarnings(null)
      return
    }

    if (!response) return
    if (!response.data.hasOwnProperty('results')) {
      return
    }

    if (Array.isArray(response.data?.results)) {
      bhaAnalysisData.current = response.data
      setBhaAnalysisWarnings(response.data?.warnings)
      setBhaAnalysisErrors(null)
    }

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: bhaAnalysisData.current.jobDetails?.operator })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const fetchBhaTendencyData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    setLoading(true)
    isLoading.current = true

    const response = await getBhaTendency({
      wellName: currentWellRef.current,
      engCase: selectedEngCase?.recordId,
      directionalTendencyInputs: JSON.stringify(bhaTendencyParams),
    })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (response?.error) {
      tendencyData.current = null
      let errors = []
      if (response?.error?.response?.data?.error) {
        errors.push(response?.error?.response?.data?.error.split(':').pop())
      }
      if (response?.error?.message) {
        errors.push(response?.error?.message)
      }

      setBhaTendencyErrors(errors)
      setBhaTendencyWarnings(null)
      return
    }

    if (!response) return

    tendencyData.current = response?.data ? response.data : null
    setBhaTendencyWarnings(Array.isArray(response?.data) ? response?.data[0]?.warnings : null)
    setBhaTendencyErrors(null)
  }

  const fetchBhaCriticalRPMData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getBhaCriticalRPM({
      wellName: currentWellRef.current,
      engCase: selectedEngCase?.recordId,
      criticalRPMInputs: JSON.stringify(bhaCriticalRPMParams),
    })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (response?.error) {
      criticalRPMData.current = null
      let errors = []
      if (response?.error?.response?.data?.error) {
        errors.push(response?.error?.response?.data?.error)
      }
      if (response?.error?.message) {
        errors.push(response?.error?.message)
      }
      setBhaCriticalRPMErrors(errors)
      setBhaCriticalRPMWarnings(null)
      return
    }

    for (let i = 0; i < response?.data.length; i++) {
      if (response?.data[i].hasOwnProperty('errors')) {
        if (response.data.errors != null && response.data.errors.length > 0) {
          setBhaCriticalRPMErrors(response?.data[i].errors)
          setBhaCriticalRPMWarnings(null)
          criticalRPMData.current = null
          return
        }
      }
    }

    let units = getUnitsText(UNITS_FOR.Weight)
    if (bhaCriticalRPMParams.criticalRPMVariable === 'INC') {
      units = '°'
    }

    setCriticalRPMDataUnits({ variable: bhaCriticalRPMParams.criticalRPMVariable, units: units })

    if (!response) return

    criticalRPMData.current = response?.data ? response.data : null
    setBhaCriticalRPMWarnings(Array.isArray(response?.data) ? response?.data[0]?.warnings : null)
    setBhaCriticalRPMErrors(null)

    if (bhaCriticalRPMParams.criticalRPMVariable === 'WOB') {
      setCriticalRPMMinVal(bhaCriticalRPMParams.startWob)
      if (bhaCriticalRPMParams.endWob < bhaCriticalRPMParams.startWob) {
        setCriticalRPMMinVal(bhaCriticalRPMParams.endWob)
      }
    }

    if (bhaCriticalRPMParams.criticalRPMVariable === 'INC') {
      setCriticalRPMMinVal(bhaCriticalRPMParams.startInc)
      if (bhaCriticalRPMParams.endInc < bhaCriticalRPMParams.startInc) {
        setCriticalRPMMinVal(bhaCriticalRPMParams.endInc)
      }
    }
  }

  const fetchTrajectoryData = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoadingTrajectory.current) return
    if (!currentBha) return
    if (currentBha.bhaNum === null || currentBha.bhaNum === undefined) return
    if (currentBha.bhaNum === '') return
    isLoadingTrajectory.current = true
    if (_isMounted.current) setLoadingTrajectory(true)

    const responseBha = await getAllBha({
      wellName: currentWellRef.current,
      bhaNum: currentBha.bhaNum,
    })

    if (responseBha?.data?.length > 0) {
      setBhaDepths({
        startDepth: responseBha.data[0]?.depthIn ? responseBha.data[0].depthIn : 0,
        endDepth: responseBha.data[0]?.depthOut ? responseBha.data[0].depthOut : 100000,
      })
    }

    const response = await getTrajectory({ wellName: currentWellRef.current })

    if (_isMounted.current) {
      setLoadingTrajectory(false)
      trajectoryData.current = response?.data?.surveys ? response.data.surveys : null
    }

    isLoadingTrajectory.current = false
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']
    let cyanPalette = ['#49ecf5', '#226f73']
    let purplePalette = ['#e300b2', '#bc6bdb']
    let yellowPalette = ['#fffb05', '#ffa505']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    if (pallete === 'cyan') {
      startColor = cyanPalette[0]
      endColor = cyanPalette[1]
    }

    if (pallete === 'purple') {
      startColor = purplePalette[0]
      endColor = purplePalette[1]
    }

    if (pallete === 'yellow') {
      startColor = yellowPalette[0]
      endColor = yellowPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  function adjustHexOpacity(hexColor, alpha) {
    // Remove the leading '#' if present
    const sanitizedColor = hexColor.replace('#', '')

    // Convert the hex color to RGBA
    const red = parseInt(sanitizedColor.substring(0, 2), 16)
    const green = parseInt(sanitizedColor.substring(2, 4), 16)
    const blue = parseInt(sanitizedColor.substring(4, 6), 16)

    // Convert the alpha value from a range of 0-100 to a range of 0-255
    const alphaValue = Math.round((alpha / 100) * 255)

    // Convert RGBA values to a new hex string
    const updatedHex = `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}${alphaValue.toString(
      16,
    )}`

    return updatedHex
  }

  const getStabToBit = (bhaData, stabilizerData, index) => {
    let stabToBit = stabilizerData[index].stabDfb
    for (let i = 0; i < stabilizerData[index].sequenceNo; i++) {
      stabToBit += bhaData[i].length
    }
    return stabToBit
  }

  const getBhaTendencySeriesValues = (data, value) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      output.push({
        x: pt.hasOwnProperty('inc') ? pt.inc : 0,
        y: pt.hasOwnProperty(value) ? pt[value] : 0,
      })
    })

    return output
  }

  const getCriticalRPMSeriesValues = (data, value, offset, minVal) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      if (output.length === 0) {
        output.push({
          x: pt.hasOwnProperty('rpm') ? pt.rpm + offset : offset,
          y: minVal,
        })
      }

      output.push({
        x: pt.hasOwnProperty('rpm') ? pt.rpm + offset : offset,
        y: pt.hasOwnProperty(value) ? pt[value] : minVal,
      })
    })

    return output
  }

  const getModeShapeSeriesValues = (data, value) => {
    if (!data) return []
    let output = []
    if (
      data.hasOwnProperty('distanceFromBit') &&
      data.hasOwnProperty('frequency') &&
      data.hasOwnProperty('description')
    ) {
      for (let i = 0; i < data.distanceFromBit.length; i++) {
        output.push({
          x: data.distanceFromBit[i],
          y: data.frequency[i],
          desc: data.description[i],
        })
      }
    }

    return output
  }

  const getBhaAnalysisData = () => {
    return bhaAnalysisData.current
  }

  const getTrajectoryValues = (data, variable) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.sort((a, b) => a.inc - b.inc)

    data.forEach((pt) => {
      if (pt.hasOwnProperty('motorYield') && pt.hasOwnProperty('inc') && pt.hasOwnProperty('md')) {
        if (
          variable === 'Sliding' &&
          pt.motorYield !== 0 &&
          pt.motorYield < 50.0 &&
          pt.md >= bhaDepths.startDepth &&
          pt.md <= bhaDepths.endDepth &&
          pt.inc >= bhaTendencyParams.startInc &&
          pt.inc <= bhaTendencyParams.endInc
        ) {
          output.push({
            x: pt.inc,
            y: pt.motorYield,
          })
        }
        if (
          variable === 'Rotating' &&
          pt.motorYield === 0 &&
          pt.md >= bhaDepths.startDepth &&
          pt.md <= bhaDepths.endDepth &&
          pt.hasOwnProperty('dls') &&
          pt.inc >= bhaTendencyParams.startInc &&
          pt.inc <= bhaTendencyParams.endInc
        ) {
          output.push({
            x: pt.inc,
            y: pt.dls,
          })
        }
      }
    })

    return output
  }

  const getBhaTendencyData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!tendencyData.current) return chartData
    if (!Array.isArray(tendencyData.current)) return chartData
    if (tendencyData.current.length === 0) return chartData

    let title = tendencyData.current[0].tendencyVariable
    let rotating = tendencyData.current[0].isRotating
    let variable = 'wob'
    let variableName = 'WOB'
    let units = getUnitsText(UNITS_FOR.Weight)
    if (title === 'Motor Bend') {
      variable = 'motorBend'
      units = '°'
      variableName = 'Bend'
    }

    let uniqueVar = []
    for (let i = 0; i < tendencyData.current.length; i++) {
      if (!uniqueVar.includes(tendencyData.current[i][variable])) {
        uniqueVar.push(tendencyData.current[i][variable])
      }
    }

    uniqueVar.sort((a, b) => a - b)

    tendencyData.current.sort((a, b) => a.inc - b.inc)

    let series = []
    for (let i = 0; i < uniqueVar.length; i++) {
      let varSeries = []
      for (let j = 0; j < tendencyData.current.length; j++) {
        if (tendencyData.current[j][variable] === uniqueVar[i]) {
          varSeries.push(tendencyData.current[j])
        }
      }
      series.push(varSeries)
    }

    let showSliding = true

    if (
      bhaAnalysisData.current &&
      (bhaAnalysisData.current.params?.bitType === 'RSS' || bhaAnalysisData.current.params?.bitType === 'Rotary')
    ) {
      showSliding = false
    }

    if (showSliding) {
      for (let i = 0; i < series.length; i++) {
        let curSeries = series[i]

        chartData.datasets.push({
          label: `${title}: ${numberWithCommasDecimals(series[i][0][variable], 2)} ${units} -Sliding `,
          borderColor: interpChartColor('red', i, uniqueVar.length),
          backgroundColor: interpChartColor('red', i, uniqueVar.length),
          data: getBhaTendencySeriesValues(curSeries, 'buildRateSliding'),
          showShading: false,
          color: null,
          units: units,
          variable: variableName,
        })
      }
    }

    if (rotating) {
      for (let i = 0; i < series.length; i++) {
        let curSeries = series[i]

        if (bhaTendencyParams.hasOwnProperty('incRotating')) {
          chartData.datasets.push({
            label: `${title}: ${numberWithCommasDecimals(series[i][0][variable], 2)} ${units} -Rotating `,
            borderColor: interpChartColor('blue', i, uniqueVar.length),
            backgroundColor: interpChartColor('blue', i, uniqueVar.length),
            data: getBhaTendencySeriesValues(curSeries, 'buildRateRotating'),
            showShading: false,
            color: null,
            units: units,
            variable: variableName,
          })
        }
      }
    }

    return chartData
  }

  const getTrajectoryData = () => {
    let chartData = []

    if (!trajectoryData.current) return chartData
    if (!Array.isArray(trajectoryData.current)) return chartData
    if (trajectoryData.current.length === 0) return chartData
    if (!tendencyData.current) return chartData
    let rotating = tendencyData.current[0]?.isRotating

    chartData.push({
      label: `Motor Yield (Slide Sheet)`,
      borderColor: interpChartColor('green', 0, 1),
      backgroundColor: interpChartColor('green', 0, 1),
      pointBorderColor: adjustHexOpacity(interpChartColor('green', 0, 1), 50),
      pointBackgroundColor: adjustHexOpacity(interpChartColor('green', 0, 1), 50),
      showLine: false,
      pointStyle: 'circle',
      pointRadius: 3,
      data: getTrajectoryValues(trajectoryData.current, 'Sliding'),
    })

    if (rotating) {
      chartData.push({
        label: `Rotary DLS (Slide Sheet)`,
        borderColor: interpChartColor('purple', 0, 1),
        backgroundColor: interpChartColor('purple', 0, 1),
        pointBorderColor: adjustHexOpacity(interpChartColor('purple', 0, 1), 50),
        pointBackgroundColor: adjustHexOpacity(interpChartColor('purple', 0, 1), 50),
        showLine: false,
        pointStyle: 'circle',
        pointRadius: 3,
        data: getTrajectoryValues(trajectoryData.current, 'Rotating'),
      })
    }

    return chartData
  }

  const getBhaCriticalRPMData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!criticalRPMData.current) return chartData
    if (!Array.isArray(criticalRPMData.current)) return chartData
    if (criticalRPMData.current.length === 0) return chartData
    if (!bhaCriticalRPMParams) return chartData

    let title = criticalRPMDataUnits.variable
    let variable = 'wob'
    if (title === 'INC') variable = 'inc'

    let uniqueMode = []
    for (let i = 0; i < criticalRPMData.current.length; i++) {
      if (!uniqueMode.includes(criticalRPMData.current[i].node)) {
        uniqueMode.push(criticalRPMData.current[i].node)
      }
    }

    uniqueMode.sort((a, b) => a - b)

    let series = getCriticalRPMSeriesData(criticalRPMData.current, criticalRPMDataUnits)

    for (let i = 0; i < series.length; i++) {
      let curSeries = series[i]

      if (curSeries.length === 0) continue
      if (curSeries.length === 1) continue

      chartData.datasets.push({
        type: 'scatter',
        showLine: false,
        label: `Mode: ${uniqueMode[i]} #####`,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: interpChartColor('red', i, uniqueMode.length),
        backgroundColor: interpChartColor('red', i, uniqueMode.length),
        fill: false,
        data: getCriticalRPMSeriesValues(curSeries, variable, -5.0, criticalRPMMinVal),
        fillBetweenSeries: chartData.datasets.length + 1,
        fillBetweenColor: 'rgba(255,0,0, 0.2)',
        hidden: false,
      })

      chartData.datasets.push({
        label: `Mode: ${uniqueMode[i]}`,
        borderColor: interpChartColor('red', i, uniqueMode.length),
        backgroundColor: interpChartColor('red', i, uniqueMode.length),
        pointBorderColor: adjustHexOpacity(interpChartColor('red', i, uniqueMode.length), 50),
        pointBackgroundColor: adjustHexOpacity(interpChartColor('red', i, uniqueMode.length), 50),
        showLine: true,
        pointStyle: 'circle',
        data: getCriticalRPMSeriesValues(curSeries, variable, 0.0, criticalRPMMinVal),
        fillBetweenSeries: chartData.datasets.length + 1,
        fillBetweenColor: 'rgba(255,0,0, 0.2)',
        hidden: false,
      })

      chartData.datasets.push({
        type: 'scatter',
        showLine: false,
        label: `Mode: ${uniqueMode[i]} #####`,
        borderWidth: 1,
        pointRadius: 0,
        borderColor: interpChartColor('red', i, uniqueMode.length),
        backgroundColor: interpChartColor('red', i, uniqueMode.length),
        data: getCriticalRPMSeriesValues(curSeries, variable, 5.0, criticalRPMMinVal),
        hidden: false,
      })
    }

    return chartData
  }

  const getBhaModeShapesData = () => {
    let chartData = {
      labels: ['x', 'y', 'desc'],
      datasets: [],
    }

    if (!criticalRPMData.current) return chartData
    if (!Array.isArray(criticalRPMData.current)) return chartData
    if (criticalRPMData.current.length === 0) return chartData

    let uniqueMode = []
    for (let i = 0; i < criticalRPMData.current.length; i++) {
      if (!uniqueMode.includes(criticalRPMData.current[i].node)) {
        uniqueMode.push(criticalRPMData.current[i].node)
      }
    }

    uniqueMode.sort((a, b) => a - b)

    let series = getCriticalRPMSeriesData(criticalRPMData.current, criticalRPMDataUnits)

    let colors = ['blue', 'red', 'green', 'cyan', 'purple', 'yellow']

    for (let i = 0; i < series.length; i++) {
      let curSeries = series[i]
      let color = colors[i % colors.length]

      for (let j = 0; j < 1; j++) {
        if (curSeries[j].hasOwnProperty('dof')) {
          if (!Array.isArray(curSeries[j].dof)) continue
          if (curSeries[j].dof.length < 5) continue
          let data = curSeries[j]

          chartData.datasets.push({
            label: `Mode: ${uniqueMode[i]} - RPM: ${numberWithCommasDecimals(data.rpm, 2)}`,
            borderColor: interpChartColor(color, i, uniqueMode.length),
            backgroundColor: interpChartColor(color, i, uniqueMode.length),
            pointBorderColor: adjustHexOpacity(interpChartColor(color, i, uniqueMode.length), 50),
            pointBackgroundColor: adjustHexOpacity(interpChartColor(color, i, uniqueMode.length), 50),
            showLine: true,
            pointStyle: 'circle',
            data: getModeShapeSeriesValues(data.dof[4]),
          })
        }
      }
    }

    return chartData
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const generatePdfDocument = async (
    bendingMomentImage,
    contactForceImage,
    sheerLoadImage,
    forcesImage,
    wellSchematicImage,
    sectionPlanImage,
    imageHeight,
    tendencyImage,
    criticalRPMImage,
  ) => {
    if (!bhaAnalysisData.current) return
    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')

    let reportDetails = getReportDetails()

    let bitToBendText = `Bit to Bend (${getUnitsText(UNITS_FOR.Diameter)})`
    let bitToBend = bhaAnalysisData.current.params?.bitToBend
    let bendAngleText = `Bend Angle (°)`
    let bendAngle = bhaAnalysisData.current.params?.bendAngle
    if (bhaAnalysisData.current.params?.bitType === 'RSS') {
      bendAngleText = `RSS Steer Force (${getUnitsText(UNITS_FOR.Force)})`
      bendAngle = bhaAnalysisData.current.params?.rssSteerForce
      bitToBendText = `Bit to Steering Unit Center (${getUnitsText(UNITS_FOR.Depth)})`
    }

    if (bhaAnalysisData.current.params?.bitType === 'Rotary') {
      bitToBendText = ''
      bendAngleText = ''
    }

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: 'BHA Analysis Report',
        showLogo: true,
        logo: getLogo('Primary'),
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['50%', '50%'],
        data: [
          [
            { text: 'Well:', isHeader: true },
            { text: currentWellRef.current, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [
            { text: 'Client:', isHeader: true },
            { text: bhaAnalysisData.current.jobDetails?.operator, textAlign: 'left' },
            { text: 'Field:', isHeader: true },
            { text: bhaAnalysisData.current.jobDetails?.field, textAlign: 'left' },
            { text: 'Well:', isHeader: true },
            { text: bhaAnalysisData.current.jobDetails?.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
            { text: 'Prepared By:', isHeader: true },
            { text: getUserNameFromEmail(user?.name), textAlign: 'left' },
            { text: 'Date:', isHeader: true },
            { text: dateTimeFormatter(new Date(Date.now()).toISOString()), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [{ text: 'Calculation Parameters', columnSpan: 6 }],
          [
            { text: `Calculation Depth (${getUnitsText(UNITS_FOR.Depth)}):`, isHeader: true },
            { text: numberWithCommasDecimals(bhaAnalysisData.current.params?.calcDepth, 2), textAlign: 'left' },
            { text: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)}):`, isHeader: true },
            { text: numberWithCommasDecimals(bhaAnalysisData.current.params?.wellboreId, 2), textAlign: 'left' },
            { text: `Mud Weight (${getUnitsText(UNITS_FOR.MudWeight)}):`, isHeader: true },
            { text: numberWithCommasDecimals(bhaAnalysisData.current.params?.mudWeight, 2), textAlign: 'left' },
          ],
          [
            { text: `Weight on Bit (${getUnitsText(UNITS_FOR.Weight)}):`, isHeader: true },
            { text: numberWithCommasDecimals(bhaAnalysisData.current.params?.wob, 2), textAlign: 'left' },
            { text: `Bit Torque (${getUnitsText(UNITS_FOR.Torque)}):`, isHeader: true },
            { text: numberWithCommasDecimals(bhaAnalysisData.current.params?.torqueOnBit, 2), textAlign: 'left' },
            { text: `Bit Steerability`, isHeader: true },
            { text: numberWithCommasDecimals(bhaAnalysisData.current.params?.bSteer, 2), textAlign: 'left' },
          ],
          [
            { text: bendAngleText, isHeader: true },
            {
              text: bhaAnalysisData.current.params?.bitType === 'Rotary' ? '' : numberWithCommasDecimals(bendAngle, 2),
              textAlign: 'left',
            },
            { text: bitToBendText, isHeader: true },
            {
              text: bhaAnalysisData.current.params?.bitType === 'Rotary' ? '' : numberWithCommasDecimals(bitToBend, 2),
              textAlign: 'left',
            },
            { text: `Type`, isHeader: true },
            { text: bhaAnalysisData.current.params?.bitType, textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        wrap: false,
        data: addWellboreGeometry(bhaAnalysisData.current.wellGeometry),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['4%', '4%', '12%', '11%', '11%', '11%', '11%', '11%', '11%', '14%'],
        wrap: false,
        data: addDrillStrings(bhaAnalysisData.current.drillStrings, bhaAnalysisData.current.params?.calcDepth),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        wrap: false,
        data: addStabData(bhaAnalysisData.current.drillString),
      },
      {
        breakHeader: true,
        sectionAfter: 5,
        columnWidths: ['34%', '33%', '33%'],
        manualWidth: true,
        data: [
          [
            { text: wellSchematicImage, isImage: true, width: '100%', height: imageHeight * 2 },
            { text: sectionPlanImage, isImage: true, width: '100%', height: imageHeight * 2, columnSpan: 2 },
          ],
        ],
      },
    ]

    let summaryBreakHeader = true
    const bhaAnalysisStresses = reportDetails.find((detail) => detail.reportType === 'BHA Analysis Stresses')
    if (bhaAnalysisStresses && bhaAnalysisStresses?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        data: addBhaAnalysisSummaryResults(bhaAnalysisData.current.results),
      })
      summaryBreakHeader = false
    }

    const bhaAnalysisTendencies = reportDetails.find((detail) => detail.reportType === 'Directional Tendency')
    if (bhaAnalysisTendencies && bhaAnalysisTendencies?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['25%', '25%', '25%', '25%'],
        data: addBhaTendencySummaryResults(
          tendencyData.current,
          bhaTendencyParams.tendencyVariable,
          bhaAnalysisData.current.params?.bitType,
        ),
      })
      summaryBreakHeader = false
    }

    const bhaCriticalRpm = reportDetails.find((detail) => detail.reportType === 'Critical RPM & Mode Shape')
    if (bhaCriticalRpm && bhaCriticalRpm?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        data: addBhaCriticalRPMSummaryResults(getCriticalRPMSeriesData(criticalRPMData.current, criticalRPMDataUnits)),
      })
      summaryBreakHeader = false
    }

    let images = getImagesForChart(
      bendingMomentImage,
      contactForceImage,
      sheerLoadImage,
      forcesImage,
      tendencyImage,
      criticalRPMImage,
      imageHeight,
    )

    for (let i = 0; i < images.length; i++) {
      docData.push(images[i])
    }

    if (bhaAnalysisStresses && bhaAnalysisStresses?.calculatedData) {
      docData.push(
        {
          breakHeader: true,
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          multiPage: true,
          multiPageRow: 2,
          columnWidths: [
            '6%',
            '4%',
            '4%',
            '5%',
            '5%',
            '5%',
            '6%',
            '6%',
            '6%',
            '5%',
            '6%',
            '6%',
            '6%',
            '6%',
            '5%',
            '5%',
            '15%',
          ],
          data: addBhaResultsHSData(bhaAnalysisData.current.results),
        },
        {
          breakHeader: true,
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          multiPage: true,
          multiPageRow: 2,
          columnWidths: [
            '6%',
            '4%',
            '4%',
            '5%',
            '5%',
            '5%',
            '6%',
            '6%',
            '6%',
            '5%',
            '6%',
            '6%',
            '6%',
            '6%',
            '5%',
            '5%',
            '15%',
          ],
          data: addBhaResultsRSData(bhaAnalysisData.current.results),
        },
      )
    }

    let breakHeader = true

    if (bhaAnalysisTendencies && bhaAnalysisTendencies?.calculatedData) {
      docData.push({
        breakHeader: breakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        data: addBhaTendencyResultsData(tendencyData.current, bhaTendencyParams.tendencyVariable),
      })
      breakHeader = false
    }

    if (bhaCriticalRpm && bhaCriticalRpm?.calculatedData) {
      docData.push({
        breakHeader: breakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['12%', '11%', '11%', '11%', '11%', '11%', '11%', '11%', '11%'],
        data: addBhaCriticalRPMResultsData(
          getCriticalRPMSeriesData(criticalRPMData.current, criticalRPMDataUnits),
          criticalRPMDataUnits.variable,
        ),
      })
    }

    return docData
  }

  const addBhaCriticalRPMResultsData = (results, variable) => {
    if (!results) return []
    if (!Array.isArray(results)) return []
    if (results.length === 0) return []
    if (!Array.isArray(results[0])) return []
    if (results[0].length === 0) return []
    if (!variable) return []

    let data = []
    data.push(
      [{ text: 'Critical RPM & Mode Shape Results', columnSpan: 9 }],
      [
        { text: '', isHeader: true },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0][0].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Inc ${numberWithCommasDecimals(results[0][0].inc, 2)} °`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0][1].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Inc ${numberWithCommasDecimals(results[0][1].inc, 2)} °`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0][2].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Inc ${numberWithCommasDecimals(results[0][2].inc, 2)} °`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0][3].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Inc ${numberWithCommasDecimals(results[0][3].inc, 2)} °`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0][4].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Inc ${numberWithCommasDecimals(results[0][4].inc, 2)} °`,
          isHeader: true,
        },
        { text: `Vib Max Displacement`, isHeader: true },
        { text: `Distance from Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'Component', isHeader: true },
      ],
    )

    for (let i = 0; i < results.length; i++) {
      const modeData = results[i]
      if (!modeData) continue
      if (!Array.isArray(modeData)) continue
      if (modeData.length === 0) continue

      let row = []
      row.push({ text: `Mode: ${modeData[0].node}`, isHeader: true })

      let maxFrequency = null
      let maxDistanceFromBit = null
      let maxComponent = null

      if (modeData.length < 5) {
        row.push({ text: numberWithCommasDecimals(modeData[0].rpm, 2) })
      }

      for (let j = 0; j < modeData.length; j++) {
        if (!modeData[j].hasOwnProperty('dof')) continue
        if (!modeData[j].dof) continue
        if (!Array.isArray(modeData[j].dof)) continue
        if (modeData[j].dof.length < 5) continue
        const dof = modeData[j].dof[4]
        if (!dof) continue
        if (!dof.hasOwnProperty('frequency')) continue
        if (!dof.hasOwnProperty('distanceFromBit')) continue
        if (!dof.hasOwnProperty('description')) continue
        if (!Array.isArray(dof.frequency)) continue
        if (!Array.isArray(dof.distanceFromBit)) continue
        if (!Array.isArray(dof.description)) continue
        if (dof.frequency.length === 0) continue
        if (dof.distanceFromBit.length !== dof.frequency.length) continue
        if (dof.description.length !== dof.frequency.length) continue

        for (let k = 0; k < dof.frequency.length; k++) {
          if (!maxFrequency || Math.abs(dof.frequency[k]) > Math.abs(maxFrequency)) {
            maxFrequency = dof.frequency[k]
            maxDistanceFromBit = dof.distanceFromBit[k]
            maxComponent = dof.description[k]
          }
        }

        row.push({ text: numberWithCommasDecimals(modeData[j].rpm, 2) })
      }

      row.push(
        { text: numberWithCommasDecimals(maxFrequency, 9) },
        { text: numberWithCommasDecimals(maxDistanceFromBit, 2) },
        { text: maxComponent },
      )
      data.push(row)
    }

    return data
  }

  const addBhaTendencyResultsData = (results, variable) => {
    if (!results) return []
    if (!Array.isArray(results)) return []
    if (results.length === 0) return []
    if (results.length % 3 !== 0) return []

    let data = []
    data.push(
      [{ text: 'BHA Directional Tendency Results', columnSpan: 7 }],
      [
        { text: '', isHeader: true },
        { text: `Slide Build Rate (${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true, columnSpan: 3 },
        { text: `Rotary BuildRate (${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true, columnSpan: 3 },
      ],
      [
        { text: '', isHeader: true },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Motor Bend ${numberWithCommasDecimals(results[0].motorBend, 2)} (°)`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[2].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Motor Bend ${numberWithCommasDecimals(results[2].motorBend, 2)} (°)`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[1].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Motor Bend ${numberWithCommasDecimals(results[1].motorBend, 2)} (°)`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[0].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Motor Bend ${numberWithCommasDecimals(results[0].motorBend, 2)} °`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[2].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Motor Bend ${numberWithCommasDecimals(results[2].motorBend, 2)} °`,
          isHeader: true,
        },
        {
          text:
            variable === 'WOB'
              ? `WOB ${numberWithCommasDecimals(results[1].wob, 2)} (${getUnitsText(UNITS_FOR.Weight)})`
              : `Motor Bend ${numberWithCommasDecimals(results[1].motorBend, 2)} °`,
          isHeader: true,
        },
      ],
    )

    for (let i = 0; i < results.length; i += 3) {
      data.push([
        { text: `Inc: ${results[i].inc}°`, isHeader: true },
        { text: numberWithCommasDecimals(results[i].buildRateSliding, 2) },
        { text: numberWithCommasDecimals(results[i + 2].buildRateSliding, 2) },
        { text: numberWithCommasDecimals(results[i + 1].buildRateSliding, 2) },
        { text: numberWithCommasDecimals(results[i].buildRateRotating, 2) },
        { text: numberWithCommasDecimals(results[i + 2].buildRateRotating, 2) },
        { text: numberWithCommasDecimals(results[i + 1].buildRateRotating, 2) },
      ])
    }

    return data
  }

  const getCriticalRPMSeriesData = (results, settings) => {
    if (!results) return []
    if (!Array.isArray(results)) return []
    if (results.length === 0) return []
    if (!settings) return []

    let title = settings.variable
    let variable = 'wob'
    if (title === 'INC') variable = 'inc'

    let uniqueNode = []
    for (let i = 0; i < results.length; i++) {
      if (!uniqueNode.includes(results[i].node)) {
        uniqueNode.push(results[i].node)
      }
    }

    uniqueNode.sort((a, b) => a - b)

    results.sort((a, b) => a[variable] - b[variable])

    let series = []
    for (let i = 0; i < uniqueNode.length; i++) {
      let varSeries = []
      for (let j = 0; j < results.length; j++) {
        if (results[j].node === uniqueNode[i]) {
          varSeries.push(results[j])
        }
      }
      series.push(varSeries)
    }

    return series
  }

  const addBhaCriticalRPMSummaryResults = (results) => {
    if (!results) return []
    if (!Array.isArray(results)) return []
    if (results.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Critical RPM & Mode Shape Results', columnSpan: 5 }],
      [
        { text: '', isHeader: true },
        { text: 'RPM', isHeader: true },
        { text: `Vib Max Displacement`, isHeader: true },
        { text: `Distance From Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'Component', isHeader: true },
      ],
    )

    for (let i = 0; i < results.length; i++) {
      if (!Array.isArray(results[i])) continue
      if (results[i].length === 0) continue
      if (!results[i][0].hasOwnProperty('dof')) continue
      if (!Array.isArray(results[i][0].dof)) continue
      if (results[i][0].dof.length < 5) continue
      const dof = results[i][0].dof[4]
      if (!dof.hasOwnProperty('frequency')) continue
      if (!dof.hasOwnProperty('distanceFromBit')) continue
      if (!dof.hasOwnProperty('description')) continue
      if (!Array.isArray(dof.frequency)) continue
      if (!Array.isArray(dof.distanceFromBit)) continue
      if (!Array.isArray(dof.description)) continue
      if (dof.frequency.length === 0) continue
      if (dof.distanceFromBit.length === 0) continue
      if (dof.description.length === 0) continue
      if (dof.frequency.length !== dof.distanceFromBit.length) continue
      if (dof.frequency.length !== dof.description.length) continue

      let index = -1
      let maxFrequency = null
      for (let j = 0; j < dof.frequency.length; j++) {
        if (maxFrequency == null || Math.abs(dof.frequency[j]) > Math.abs(maxFrequency)) {
          maxFrequency = dof.frequency[j]
          index = j
        }
      }

      if (index === -1) continue

      data.push([
        { text: `Mode: ${results[i][0].node}`, isHeader: true },
        { text: numberWithCommasDecimals(results[i][0].rpm, 2) },
        { text: numberWithCommasDecimals(results[i][0].dof[4].frequency[index], 9) },
        { text: numberWithCommasDecimals(results[i][0].dof[4].distanceFromBit[index], 2) },
        { text: results[i][0].dof[4].description[index] },
      ])
    }

    return data
  }

  const addBhaTendencySummaryResults = (results, variable, type) => {
    if (!results) return []
    if (!Array.isArray(results)) return []
    if (results.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Directional Tendency Results', columnSpan: 4 }],
      [
        { text: '', isHeader: true },
        { text: `Build Rate (${getUnitsText(UNITS_FOR.Dogleg)})`, isHeader: true },
        { text: `Inc (°)`, isHeader: true },
        {
          text: variable === 'WOB' ? `WOB (${getUnitsText(UNITS_FOR.Weight)})` : `Motor Bend (°)`,
          isHeader: true,
        },
      ],
    )

    let maxBuildRateSliding = null
    let minBuildRateSliding = null
    let maxBuildRateRotating = null
    let minBuildRateRotating = null

    for (let i = 0; i < results.length; i++) {
      if (maxBuildRateSliding == null || results[i].buildRateSliding > maxBuildRateSliding.buildRateSliding) {
        maxBuildRateSliding = results[i]
      }
      if (minBuildRateSliding == null || results[i].buildRateSliding < minBuildRateSliding.buildRateSliding) {
        minBuildRateSliding = results[i]
      }
      if (maxBuildRateRotating == null || results[i].buildRateRotating > maxBuildRateRotating.buildRateRotating) {
        maxBuildRateRotating = results[i]
      }
      if (minBuildRateRotating == null || results[i].buildRateRotating < minBuildRateRotating.buildRateRotating) {
        minBuildRateRotating = results[i]
      }
    }

    if (type !== 'Rotary' && type !== 'RSS') {
      data.push([
        { text: 'Slide Max Build Rate', isHeader: true },
        { text: numberWithCommasDecimals(maxBuildRateSliding?.buildRateSliding, 2) },
        { text: numberWithCommasDecimals(maxBuildRateSliding?.inc, 2) },
        {
          text:
            variable === 'WOB'
              ? numberWithCommasDecimals(maxBuildRateSliding?.wob, 2)
              : numberWithCommasDecimals(maxBuildRateSliding?.motorBend, 2),
        },
      ])

      data.push([
        { text: 'Slide Min Build Rate', isHeader: true },
        { text: numberWithCommasDecimals(minBuildRateSliding?.buildRateSliding, 2) },
        { text: numberWithCommasDecimals(minBuildRateSliding?.inc, 2) },
        {
          text:
            variable === 'WOB'
              ? numberWithCommasDecimals(minBuildRateSliding?.wob, 2)
              : numberWithCommasDecimals(minBuildRateSliding?.motorBend, 2),
        },
      ])
    }

    data.push([
      { text: 'Rotary Max Build Rate', isHeader: true },
      { text: numberWithCommasDecimals(maxBuildRateRotating?.buildRateRotating, 2) },
      { text: numberWithCommasDecimals(maxBuildRateRotating?.inc, 2) },
      {
        text:
          variable === 'WOB'
            ? numberWithCommasDecimals(maxBuildRateRotating?.wob, 2)
            : numberWithCommasDecimals(maxBuildRateRotating?.motorBend, 2),
      },
    ])

    data.push([
      { text: 'Rotary Min Build Rate', isHeader: true },
      { text: numberWithCommasDecimals(minBuildRateRotating?.buildRateRotating, 2) },
      { text: numberWithCommasDecimals(minBuildRateRotating?.inc, 2) },
      {
        text:
          variable === 'WOB'
            ? numberWithCommasDecimals(minBuildRateRotating?.wob, 2)
            : numberWithCommasDecimals(minBuildRateRotating?.motorBend, 2),
      },
    ])

    return data
  }

  const addBhaAnalysisSummaryResults = (results) => {
    if (!results) return []
    if (!Array.isArray(results)) return []
    if (results.length === 0) return []

    let data = []
    data.push(
      [{ text: 'BHA Analysis Summary Results', columnSpan: 7 }],
      [
        { text: '', isHeader: true },
        { text: 'Maximum Value', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Inc (°)`, isHeader: true },
        { text: `Azi (°)`, isHeader: true },
        { text: `Distance From Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'Component', isHeader: true },
      ],
    )

    let maxTotalContactForce = null
    let maxTotalBendingMoment = null
    let maxTotalShearLoad = null
    let maxHSDeflection = null
    let maxRSDeflection = null
    let maxHSRot = null
    let maxRSRot = null
    let maxAxialDef = null
    let maxTorsion = null
    let maxTension = null
    let maxTorque = null

    for (let i = 0; i < results.length; i++) {
      if (maxTotalContactForce == null || results[i].totalContactForce > maxTotalContactForce.totalContactForce) {
        maxTotalContactForce = results[i]
      }
      if (maxTotalBendingMoment == null || results[i].totalBendingMoment > maxTotalBendingMoment.totalBendingMoment) {
        maxTotalBendingMoment = results[i]
      }
      if (maxTotalShearLoad == null || results[i].totalShearLoad > maxTotalShearLoad.totalShearLoad) {
        maxTotalShearLoad = results[i]
      }
      if (maxHSDeflection == null || results[i].hsDef > maxHSDeflection.hsDe) {
        maxHSDeflection = results[i]
      }
      if (maxRSDeflection == null || results[i].rsDef > maxRSDeflection.rsDef) {
        maxRSDeflection = results[i]
      }
      if (maxHSRot == null || results[i].hsRot > maxHSRot.hsRot) {
        maxHSRot = results[i]
      }
      if (maxRSRot == null || results[i].rsRot > maxRSRot.rsRot) {
        maxRSRot = results[i]
      }
      if (maxAxialDef == null || results[i].axial > maxAxialDef.axial) {
        maxAxialDef = results[i]
      }
      if (maxTorsion == null || results[i].torsion > maxTorsion.torsion) {
        maxTorsion = results[i]
      }
      if (maxTension == null || results[i].tension > maxTension.tension) {
        maxTension = results[i]
      }
      if (maxTorque == null || results[i].torque > maxTorque.torque) {
        maxTorque = results[i]
      }
    }

    data.push([
      { text: `Total Contact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxTotalContactForce?.totalContactForce, 2) },
      { text: numberWithCommasDecimals(maxTotalContactForce?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxTotalContactForce?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxTotalContactForce?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxTotalContactForce?.distanceFromBit, 2) },
      { text: maxTotalContactForce?.description },
    ])

    data.push([
      { text: `Total Bending Moment (${getUnitsText(UNITS_FOR.BendingMoment)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxTotalBendingMoment?.totalBendingMoment, 2) },
      { text: numberWithCommasDecimals(maxTotalBendingMoment?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxTotalBendingMoment?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxTotalBendingMoment?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxTotalBendingMoment?.distanceFromBit, 2) },
      { text: maxTotalBendingMoment?.description },
    ])

    data.push([
      { text: `Total Shear Load (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxTotalShearLoad?.totalShearLoad, 2) },
      { text: numberWithCommasDecimals(maxTotalShearLoad?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxTotalShearLoad?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxTotalShearLoad?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxTotalShearLoad?.distanceFromBit, 2) },
      { text: maxTotalShearLoad?.description },
    ])

    data.push([
      { text: `HS Deflection (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxHSDeflection?.hsDef, 2) },
      { text: numberWithCommasDecimals(maxHSDeflection?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxHSDeflection?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxHSDeflection?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxHSDeflection?.distanceFromBit, 2) },
      { text: maxHSDeflection?.description },
    ])

    data.push([
      { text: `RS Deflection (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxRSDeflection?.rsDef, 2) },
      { text: numberWithCommasDecimals(maxRSDeflection?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxRSDeflection?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxRSDeflection?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxRSDeflection?.distanceFromBit, 2) },
      { text: maxRSDeflection?.description },
    ])

    data.push([
      { text: `HS Rotation (°)`, isHeader: true },
      { text: numberWithCommasDecimals(maxHSRot?.hsRot, 2) },
      { text: numberWithCommasDecimals(maxHSRot?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxHSRot?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxHSRot?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxHSRot?.distanceFromBit, 2) },
      { text: maxHSRot?.description },
    ])

    data.push([
      { text: `RS Rotation (°)`, isHeader: true },
      { text: numberWithCommasDecimals(maxRSRot?.rsRot, 2) },
      { text: numberWithCommasDecimals(maxRSRot?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxRSRot?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxRSRot?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxRSRot?.distanceFromBit, 2) },
      { text: maxRSRot?.description },
    ])

    data.push([
      { text: `Axial Deflection (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxAxialDef?.axial, 2) },
      { text: numberWithCommasDecimals(maxAxialDef?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxAxialDef?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxAxialDef?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxAxialDef?.distanceFromBit, 2) },
      { text: maxAxialDef?.description },
    ])

    data.push([
      { text: `Torsion (°)`, isHeader: true },
      { text: numberWithCommasDecimals(maxTorsion?.torsion, 2) },
      { text: numberWithCommasDecimals(maxTorsion?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxTorsion?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxTorsion?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxTorsion?.distanceFromBit, 2) },
      { text: maxTorsion?.description },
    ])

    data.push([
      { text: `Tension (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxTension?.tension, 2) },
      { text: numberWithCommasDecimals(maxTension?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxTension?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxTension?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxTension?.distanceFromBit, 2) },
      { text: maxTension?.description },
    ])

    data.push([
      { text: `Torque (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
      { text: numberWithCommasDecimals(maxTorque?.torque, 2) },
      { text: numberWithCommasDecimals(maxTorque?.surveyStation.md, 2) },
      { text: numberWithCommasDecimals(maxTorque?.surveyStation.inc, 2) },
      { text: numberWithCommasDecimals(maxTorque?.surveyStation.azi, 2) },
      { text: numberWithCommasDecimals(maxTorque?.distanceFromBit, 2) },
      { text: maxTorque?.description },
    ])

    return data
  }

  const addStabData = (drillString) => {
    if (!drillString) return []
    if (!Array.isArray(drillString)) return []
    if (drillString.length === 0) return []

    let stabData = []
    drillString.forEach((component) => {
      if (component.type === 'Stabilizer' || component.stabOd > 0) stabData.push(component)
    })

    if (stabData.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Stabilizer Data', columnSpan: 7 }],
      [
        { text: 'Component Number', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Blade Length (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Blade Width (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: 'Blade Count', isHeader: true },
        { text: `Stab->Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < stabData.length; i++) {
      data.push([
        { text: stabData[i].sequenceNo + 1 },
        { text: stabData[i].description.replace('&dq;', '"') },
        { text: numberWithCommasDecimals(stabData[i].stabOd, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBl, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBw, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBc, 0) },
        { text: numberWithCommasDecimals(getStabToBit(drillString, stabData, i), 2) },
      ])
    }

    return data
  }

  const addWellboreGeometry = (wellGeometry) => {
    if (!wellGeometry) return []
    if (!Array.isArray(wellGeometry)) return []

    let data = []
    data.push(
      [{ text: 'Wellbore Geometry', columnSpan: 5 }],
      [
        { text: 'Component Type', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `TVD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < wellGeometry.length; i++) {
      data.push([
        { text: unescapeHtml(wellGeometry[i].compType) },
        { text: numberWithCommasDecimals(wellGeometry[i].md, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].tvd, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].od, 3) },
        { text: numberWithCommasDecimals(wellGeometry[i].id, 3) },
      ])
    }

    return data
  }

  const addDrillStrings = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Data', columnSpan: 10 }],

      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: 'Type', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Unit Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCompWeight = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      totalCompWeight = totalCompWeight + drillStrings[i].casingWeight * drillStrings[i].length
      cumLength += drillStrings[i].length

      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length
      let image = bhaImages.getPrintImage(drillStrings[i], false)

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].type.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeOd, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeId, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].casingWeight, 2) },
        { text: numberWithCommasDecimals(drillStrings[i].length, 2) },
        { text: numberWithCommasDecimals(cumLength, 2) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    return data
  }

  const addBhaResultsHSData = (results) => {
    if (!results) return []
    if (!Array.isArray(results)) return []

    let data = []
    data.push(
      [{ text: 'BHA Results HS', columnSpan: 17, backgroundColor: '#FFFFFF' }],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Inc (°)`, isHeader: true },
        { text: `Azi (°)`, isHeader: true },
        { text: `Dist. from Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `HS Def. (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `HS rot/Sag (°)`, isHeader: true },
        { text: `HS Contact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `HS Bending Moment (${getUnitsText(UNITS_FOR.BendingMoment)})`, isHeader: true },
        { text: `HS Shear Load (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Axial Def. (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Torsion (°)`, isHeader: true },
        { text: `Tot. Contact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Tot. Bending Moment (${getUnitsText(UNITS_FOR.BendingMoment)})`, isHeader: true },
        { text: `Tot. Shear Load (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Tension (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Torque (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Component Label`, isHeader: true },
      ],
    )

    for (let i = 0; i < results.length; i++) {
      data.push([
        { text: numberWithCommasDecimals(results[i].surveyStation.md, 2) },
        { text: numberWithCommasDecimals(results[i].surveyStation.inc, 2) },
        { text: numberWithCommasDecimals(results[i].surveyStation.azi, 2) },
        { text: numberWithCommasDecimals(results[i].distanceFromBit, 2) },
        { text: numberWithCommasDecimals(results[i].hsDef, 3) },
        { text: numberWithCommasDecimals(results[i].hsRot, 2) },
        { text: numberWithCommasDecimals(results[i].hsContactForce, 2) },
        { text: numberWithCommasDecimals(results[i].hsBendingMoment, 2) },
        { text: numberWithCommasDecimals(results[i].hsShearLoad, 2) },
        { text: numberWithCommasDecimals(results[i].axial, 3) },
        { text: numberWithCommasDecimals(results[i].torsion, 2) },
        { text: numberWithCommasDecimals(results[i].totalContactForce, 2) },
        { text: numberWithCommasDecimals(results[i].totalBendingMoment, 2) },
        { text: numberWithCommasDecimals(results[i].totalShearLoad, 2) },
        { text: numberWithCommasDecimals(results[i].tension, 2) },
        { text: numberWithCommasDecimals(results[i].torque, 2) },
        { text: unescapeHtml(results[i].description.replace('&dq;', '"')) },
      ])
    }

    return data
  }

  const addBhaResultsRSData = (results) => {
    if (!results) return []
    if (!Array.isArray(results)) return []

    let data = []
    data.push(
      [{ text: 'BHA Results RS', columnSpan: 17, backgroundColor: '#FFFFFF' }],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Inc (°)`, isHeader: true },
        { text: `Azi (°)`, isHeader: true },
        { text: `Dist. from Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `RS Def. (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `RS rot/Sag (°)`, isHeader: true },
        { text: `RS Contact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `RS Bending Moment (${getUnitsText(UNITS_FOR.BendingMoment)})`, isHeader: true },
        { text: `RS Shear Load (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Axial Def. (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Torsion (°)`, isHeader: true },
        { text: `Tot. Contact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Tot. Bending Moment (${getUnitsText(UNITS_FOR.BendingMoment)})`, isHeader: true },
        { text: `Tot. Shear Load (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: `Tension (${getUnitsText(UNITS_FOR.Weight)})`, isHeader: true },
        { text: `Torque (${getUnitsText(UNITS_FOR.Torque)})`, isHeader: true },
        { text: `Component Label`, isHeader: true },
      ],
    )

    for (let i = 0; i < results.length; i++) {
      data.push([
        { text: numberWithCommasDecimals(results[i].surveyStation.md, 2) },
        { text: numberWithCommasDecimals(results[i].surveyStation.inc, 2) },
        { text: numberWithCommasDecimals(results[i].surveyStation.azi, 2) },
        { text: numberWithCommasDecimals(results[i].distanceFromBit, 2) },
        { text: numberWithCommasDecimals(results[i].rsDef, 3) },
        { text: numberWithCommasDecimals(results[i].rsRot, 2) },
        { text: numberWithCommasDecimals(results[i].rsContactForce, 2) },
        { text: numberWithCommasDecimals(results[i].rsBendingMoment, 2) },
        { text: numberWithCommasDecimals(results[i].rsShearLoad, 2) },
        { text: numberWithCommasDecimals(results[i].axial, 3) },
        { text: numberWithCommasDecimals(results[i].torsion, 2) },
        { text: numberWithCommasDecimals(results[i].totalContactForce, 2) },
        { text: numberWithCommasDecimals(results[i].totalBendingMoment, 2) },
        { text: numberWithCommasDecimals(results[i].totalShearLoad, 2) },
        { text: numberWithCommasDecimals(results[i].tension, 2) },
        { text: numberWithCommasDecimals(results[i].torque, 2) },
        { text: unescapeHtml(results[i].description.replace('&dq;', '"')) },
      ])
    }

    return data
  }

  const getReportDetails = () => {
    const reportDetails = getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')
    if (!reportDetails) return null

    let reportName = 'Bha Analysis'

    if (!Array.isArray(reportDetails)) return null
    const reportDetail = reportDetails.find((detail) => detail.reportName === reportName)
    if (!reportDetail) return null
    if (!Array.isArray(reportDetail.reportDetails)) return null

    return reportDetail.reportDetails
  }

  const getImagesForChart = (
    bendingMomentImage,
    contactForceImage,
    sheerLoadImage,
    forcesImage,
    tendencyImage,
    criticalRPMImage,
    imageHeight,
  ) => {
    let images = []
    const reportDetails = getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')
    if (!Array.isArray(reportDetails)) return images
    const reportDetail = reportDetails.find((detail) => detail.reportName === 'Bha Analysis')
    if (!reportDetail) return images
    if (!reportDetail.hasOwnProperty('reportDetails')) return images
    if (!Array.isArray(reportDetail.reportDetails)) return images
    for (let i = 0; i < reportDetail.reportDetails.length; i++) {
      if (!reportDetail.reportDetails[i].hasOwnProperty('charts')) continue
      if (!reportDetail.reportDetails[i].hasOwnProperty('reportType')) continue
      if (reportDetail.reportDetails[i].charts) {
        if (reportDetail.reportDetails[i].reportType === 'BHA Analysis Stresses') {
          images.push({
            breakHeader: true,
            sectionAfter: 5,
            data: [[{ text: bendingMomentImage, isImage: true, width: '100%', height: imageHeight * 2 }]],
          })
          images.push({
            breakHeader: true,
            sectionAfter: 5,
            data: [[{ text: contactForceImage, isImage: true, width: '100%', height: imageHeight * 2 }]],
          })
          images.push({
            breakHeader: true,
            sectionAfter: 5,
            data: [[{ text: sheerLoadImage, isImage: true, width: '100%', height: imageHeight * 2 }]],
          })
          images.push({
            breakHeader: true,
            sectionAfter: 5,
            data: [[{ text: forcesImage, isImage: true, width: '100%', height: imageHeight * 2 }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Directional Tendency') {
          images.push({
            breakHeader: true,
            sectionAfter: 5,
            data: [[{ text: tendencyImage, isImage: true, width: '100%', height: imageHeight * 2 }]],
          })
        }
        if (reportDetail.reportDetails[i].reportType === 'Critical RPM & Mode Shape') {
          images.push({
            breakHeader: true,
            sectionAfter: 5,
            data: [[{ text: criticalRPMImage, isImage: true, width: '100%', height: imageHeight * 2 }]],
          })
        }
      }
    }

    return images
  }

  const getBhaPdfData = async (chartElement, wellElement) => {
    return new Promise(async (resolve) => {
      if (typeof currentWellRef.current !== 'string') return
      if (currentWellRef.current === '') return
      if (!selectedEngCase) return
      if (!selectedEngCase.hasOwnProperty('recordId')) return

      let title = 'BHA Analysis Report'

      let bendingMomentElement = chartElement.getChartType('bendingMoment')
      const bendingMomentCanvas = await html2canvas(bendingMomentElement.current)
      const bendingMomentImage = bendingMomentCanvas.toDataURL('image/png', 1.0)

      let contactForceElement = chartElement.getChartType('contactForce')
      const contactForceCanvas = await html2canvas(contactForceElement.current)
      const contactForceImage = contactForceCanvas.toDataURL('image/png', 1.0)

      let sheerLoadElement = chartElement.getChartType('sheerLoad')
      const sheerLoadCanvas = await html2canvas(sheerLoadElement.current)
      const sheerLoadImage = sheerLoadCanvas.toDataURL('image/png', 1.0)

      let forcesElement = chartElement.getChartType('forces')
      const forcesCanvas = await html2canvas(forcesElement.current)
      const forcesImage = forcesCanvas.toDataURL('image/png', 1.0)

      let wellSchematicElement = wellElement.getChartType('wellSchematic')
      const wellSchematicCanvas = await html2canvas(wellSchematicElement.current)
      const wellSchematicImage = wellSchematicCanvas.toDataURL('image/png', 1.0)

      let sectionPlanViewElement = wellElement.getChartType('sectionPlanView')
      const sectionPlanViewCanvas = await html2canvas(sectionPlanViewElement.current)
      const sectionPlanViewImage = sectionPlanViewCanvas.toDataURL('image/png', 1.0)

      let tendencyElment = chartElement.getChartType('tendency')
      const tendencyCanvas = await html2canvas(tendencyElment.current)
      const tendencyImage = tendencyCanvas.toDataURL('image/png', 1.0)

      let criticalRPMElement = chartElement.getChartType('criticalRPM')
      const criticalRPMCanvas = await html2canvas(criticalRPMElement.current)
      const criticalRPMImage = criticalRPMCanvas.toDataURL('image/png', 1.0)

      const pdfDoc = await generatePdfDocument(
        bendingMomentImage,
        contactForceImage,
        sheerLoadImage,
        forcesImage,
        wellSchematicImage,
        sectionPlanViewImage,
        300,
        tendencyImage,
        criticalRPMImage,
      )

      resolve({
        fileName: `${title} - ${replaceEscapeSymbols(removeSpecialSymbols(currentWellRef.current))}`,
        data: (
          <PdfDocument
            data={pdfDoc}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  const getBhaTendencyUnits = () => {
    if (!tendencyData.current) return ''
    if (!Array.isArray(tendencyData.current)) return ''
    let title = tendencyData.current[0]?.tendencyVariable
    let units = getUnitsText(UNITS_FOR.Weight)
    if (title === 'Motor Bend') {
      units = '°'
    }

    return units
  }

  const getBhaTendencyVariable = () => {
    if (!tendencyData.current) return ''
    if (!Array.isArray(tendencyData.current)) return ''
    let title = tendencyData.current[0]?.tendencyVariable
    let variableName = 'WOB'
    if (title === 'Motor Bend') {
      variableName = 'Motor Bend'
    }

    return variableName
  }

  const getBhaCriticalRPMUnits = () => {
    return criticalRPMDataUnits
  }

  const getBhaCriticalRPMVariable = () => {
    return bhaCriticalRPMParams.criticalRPMVariable
  }

  const getBhaAnalysisWarnings = () => {
    if (bhaAnalysisWarnings) {
      return bhaAnalysisWarnings
    }
    return null
  }

  const getBhaAnalysisErrors = () => {
    if (bhaAnalysisErrors) {
      return bhaAnalysisErrors
    }
    return null
  }

  const getBhaTendencyErrors = () => {
    if (bhaTendencyErrors) {
      return bhaTendencyErrors
    }
    return null
  }

  const getBhaTendencyWarnings = () => {
    if (bhaTendencyWarnings) {
      return bhaTendencyWarnings
    }
    return null
  }

  const getBhaCriticalRPMErrors = () => {
    if (bhaCriticalRPMErrors) {
      return bhaCriticalRPMErrors
    }
    return null
  }

  const getBhaCriticalRPMWarnings = () => {
    if (bhaCriticalRPMWarnings) {
      return bhaCriticalRPMWarnings
    }
    return null
  }

  const getBhaCasing = () => {
    if (!bhaAnalysisData.current) return null
    if (!bhaAnalysisData.current.hasOwnProperty('wellGeometry')) return null
    if (!Array.isArray(bhaAnalysisData.current.wellGeometry)) return null

    return bhaAnalysisData.current.wellGeometry.map((geometry) => ({
      MD: geometry.md,
      OD: geometry.od,
      HoleSize: geometry.id,
      Type: geometry.compType,
    }))
  }

  return {
    fetchBhaAnalysisData,
    fetchBhaTendencyData,
    fetchTrajectoryData,
    fetchBhaCriticalRPMData,
    loading,
    getBhaAnalysisData,
    getBhaPdfData,
    getBhaAnalysisWarnings,
    getBhaAnalysisErrors,
    getBhaTendencyData,
    bhaTendencyParams,
    loadingTrajectory,
    getTrajectoryData,
    getBhaTendencyUnits,
    getBhaTendencyVariable,
    getBhaTendencyErrors,
    getBhaTendencyWarnings,
    getBhaCriticalRPMData,
    getBhaCriticalRPMUnits,
    getBhaCriticalRPMVariable,
    bhaCriticalRPMParams,
    getBhaCriticalRPMErrors,
    getBhaCriticalRPMWarnings,
    getBhaModeShapesData,
    getBhaCasing,
    getReportDetails,
  }
}

export default useBhaAnalysis
