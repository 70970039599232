import React, { useRef, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { cloneDeep } from 'lodash'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CurveInfoGrid = ({ witsmlServer, selectedLog, setStatus }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isFetching = useRef(false)
  const selectedLogRef = useRef(selectedLog)
  const witsmlServerRef = useRef(witsmlServer)
  const { getAgGridTheme } = useInnovaTheme()

  const getWitsmlLogData = useInnovaAxios({
    url: '/dataAcq/witsml/getLogData',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    selectedLogRef.current = cloneDeep(selectedLog)
    if (selectedLogRef.current) fetchWitsmlLogData()
  }, [selectedLog]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    witsmlServerRef.current = cloneDeep(witsmlServer)
    if (witsmlServerRef.current) fetchWitsmlLogData()
  }, [witsmlServer]) // eslint-disable-line react-hooks/exhaustive-deps

  const getGridData = () => {
    if (!Array.isArray(selectedLog?.curveData)) return []

    const uniqueMnemonicValues = new Set()
    const curveData = []

    for (const item of selectedLog?.curveData) {
      if (!uniqueMnemonicValues.has(item.mnemonic)) {
        uniqueMnemonicValues.add(item.mnemonic)
        curveData.push(item)
      }
    }

    return curveData
  }

  const fetchWitsmlLogData = async () => {
    if (!_isMounted.current) return
    if (!selectedLogRef.current) return
    if (!witsmlServerRef.current) return
    if (isFetching.current) return

    isFetching.current = true
    let payload = {
      ...witsmlServerRef.current,
      wellUuid: selectedLogRef.current.wellUuid,
      wellBoreUuid: selectedLogRef.current.wellBoreUuid,
      logUuid: selectedLogRef.current.uuid,
    }

    let res = await getWitsmlLogData(payload)
    isFetching.current = false

    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data?.data?.logData)) return
    let logData = res.data.data.logData
    if (!gridApi.current) return

    let gridData = []
    gridApi.current.forEachNode((node) => {
      if (node.data) gridData.push(node.data)
    })

    for (let i = 0; i < gridData.length; i++) {
      for (let j = 0; j < logData.length; j++) {
        if (gridData[i].mnemonic === logData[j].mnemonic) {
          gridData[i].curValue = logData[j].value
        }
      }
    }

    gridApi.current.applyTransaction({
      update: gridData,
    })

    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const updateGridData = () => {
    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: getGridData(),
      })
    }

    autoSizeColumns()
  }

  useEffect(() => {
    updateGridData()
  }, [selectedLog]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.mnemonic)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const leftAlignCell = useMemo(() => ({ display: 'flex', justifyContent: 'start', alignItems: 'center' }), [])

  let columnDefs = [
    {
      field: 'mnemonic',
      colId: 'mnemonic',
      headerName: 'Mnemonic',
      minWidth: 200,
      cellStyle: leftAlignCell,
    },
    {
      field: 'unit',
      colId: 'unit',
      headerName: 'Units',
    },
    {
      field: 'description',
      colId: 'description',
      headerName: 'Description',
      minWidth: 200,
      cellStyle: leftAlignCell,
    },
    {
      field: 'curValue',
      colId: 'curValue',
      headerName: 'Value',
      minWidth: 200,
      cellStyle: leftAlignCell,
    },
  ]

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Get current values',
        disabled: false,
        action: () => {
          fetchWitsmlLogData()
        },
        icon: '<span class="iconify" data-icon="carbon:fetch-upload-cloud" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'CurveMnemonics.xlsx',
            sheetName: 'CurveInfo',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={getGridData()}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default CurveInfoGrid
