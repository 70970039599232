import React, { useEffect, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Box, Typography, Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import { appColors } from 'utils'
import { TreeView } from '@mui/lab'
import wellIcon from 'assets/wellScreenIcons/wellHeadGrey.png'
import actualWellIcon from 'assets/wellScreenIcons/actualWell.png'
import plannedWellIcon from 'assets/wellScreenIcons/schematicBlue.png'
import fieldIcon from 'assets/wellScreenIcons/fieldGrey.png'
import facilityIcon from 'assets/wellScreenIcons/facilityGrey.png'
import operatorIcon from 'assets/wellScreenIcons/operatorGrey.png'
import databaseIcon from 'assets/wellScreenIcons/database-grey.png'
import TreeNode from 'components/DatabaseTree/DatabaseTree/TreeNode'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import {
  MinusSquare,
  PlusSquare,
  TREE_ICON_SIZE,
  TREE_ICON_PADDING,
} from 'components/DatabaseTree/DatabaseTree/DatabaseTree'
import { cloneDeep, debounce } from 'lodash'
import SearchBar from 'components/common/SearchBar'
import useWellData from 'components/common/hooks/useWellData'
import TextField from '@mui/material/TextField'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SelectOffsetsTreeModal = ({
  title,
  open,
  setOpen,
  singleItemSelect = false,
  showSearch = true,
  wellName,
  useOnApplyCallback = false,
  onApply,
}) => {
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const rawTreeData = useRef({ nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] })
  const flattenedNodes = useRef([])
  const expandedNodesRef = useRef([])
  const [searchText, setSearchText] = useState('')
  const [expandedNodes, setExpandedNodes] = useState([])
  const [filteredNodes, setFilteredNodes] = useState([])
  const [selectedNode, setSelectedNode] = useState(null)
  const [selectedNodes, setSelectedNodes] = useState([])
  const [selectedWellNodes, setSelectedWellNodes] = useState([])
  const { refreshWellData } = useWellData()
  const [showActualWells, setShowActualWells] = useState(true)
  const [showPlannedWells, setShowPlannedWells] = useState(true)
  const [showPlannedWellsPrincipal, setShowPlannedWellsPrincipal] = useState(true)
  const [offsetRadius, setOffsetRadius] = useState(5)
  const { searchBarStyle, getTreeBackColor } = useInnovaTheme()

  const getAvailableOffsetsTreeData = useInnovaAxios({
    url: '/well/getAvailableOffsetsTree',
  })

  const getSelectedOffsetsData = useInnovaAxios({
    url: '/well/getOffsetWells',
  })

  const updateSelectedOffsets = useInnovaAxios({
    url: '/well/selectOffsetWells',
  })

  const getOffsetsWithinRadius = useInnovaAxios({
    url: '/well/getOffsetWellsC2C',
    timeout: 600000,
  })

  function flattenTree(node) {
    flattenedNodes.current = []

    function flattenedTreeRecursive(currentNode) {
      flattenedNodes.current.push({
        level: currentNode.level,
        name: currentNode.name,
        nodeId: currentNode.nodeId,
        parentNodeId: currentNode.parentNodeId,
      })

      if (!Array.isArray(currentNode.children)) return
      if (currentNode.children.length === 0) return

      for (const child of currentNode.children) {
        flattenedTreeRecursive(child)
      }
    }

    flattenedTreeRecursive(node)
  }

  const debounceSearchTree = debounce((text) => {
    const filtered = searchTree(text)

    setFilteredNodes(Array.isArray(filtered) ? filtered : [])
  }, 300)

  function searchNodes(node, searchText, result = []) {
    if (node.name.toLowerCase().includes(searchText)) {
      result.push(node)
    }

    let initialLength = result.length
    if (Array.isArray(node.children)) {
      for (const child of node.children) {
        searchNodes(child, searchText, result)
      }
    }

    if (result.length > initialLength) {
      result.push(node)
    }

    return result
  }

  const searchTree = (searchText) => {
    return searchNodes(rawTreeData.current, searchText.toLowerCase().trim())
  }

  useEffect(() => {
    if (_isMounted.current) debounceSearchTree(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    fetchTreeData()
    return () => {
      _isMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTreeData = async () => {
    if (!_isMounted.current) return
    if (isLoading) return
    setLoading(true)

    let responseAvailable = await getAvailableOffsetsTreeData({ wellName: wellName })
    let responseSelected = await getSelectedOffsetsData({ wellName: wellName })

    if (!Array.isArray(responseSelected.data)) responseSelected.data = []

    if (!_isMounted.current) return

    if (responseAvailable?.error) {
      rawTreeData.current = { nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] }
      setFilteredNodes([])
      setLoading(false)
      return
    }

    if (!responseAvailable.data) return
    rawTreeData.current = responseAvailable?.data?.hasOwnProperty('nodeId')
      ? responseAvailable.data
      : { nodeId: '99999', name: 'DATABASE', level: 'DATABASE', children: [] }
    flattenTree(rawTreeData.current)
    setFilteredNodes(flattenedNodes.current)
    if (!expandedNodes.includes(getRootNodeId())) handleToggleNodeExpanded(getRootNodeId())
    setLoading(false)

    checkSelectedNodes(responseSelected.data)
  }

  const getRootNodeId = () => {
    if (!rawTreeData.current) return '99999'
    return rawTreeData.current?.nodeId
  }

  function isNodeVisible(node) {
    if (!Array.isArray(filteredNodes)) return true
    return filteredNodes.findIndex((item) => item.nodeId === node.nodeId) >= 0
  }

  const handleToggleNodeExpanded = (nodeId) => {
    let newNodes = [...expandedNodes]
    if (newNodes.includes(nodeId)) {
      newNodes.splice(newNodes.indexOf(nodeId), 1)
    } else {
      newNodes.push(nodeId)
    }

    expandedNodesRef.current = newNodes
    setExpandedNodes(newNodes)
  }

  function getAllIds(node, idList = []) {
    idList.push(node.nodeId)
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList))
    }
    return idList
  }

  const checkSelectedNodes = (data) => {
    if (!data) return
    if (!Array.isArray(data)) return
    let selectedNodes = []
    let selectedWellNodes = []
    data.forEach((node) => {
      if (!showActualWells && !node.isPlan) return

      let foundNodes = searchTreeForNode(flattenedNodes.current, node)
      if (foundNodes.length > 0) {
        for (let i = 0; i < foundNodes.length; i++) {
          if (!showPlannedWells && foundNodes.level === 'PLANNEDWELL') return
          if (!showPlannedWellsPrincipal && foundNodes.level === 'PLANNEDWELL_PRINCIPAL') return

          selectedWellNodes.push(foundNodes[i])
          selectedNodes.push(foundNodes[i])

          const parentNodes = getParentNodes(foundNodes[i]?.nodeId)
          for (let i = 0; i < parentNodes.length; i++) {
            let parent = bfsSearch([rawTreeData.current], parentNodes[i])
            if (!parent) continue
            if (selectedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0) continue
            selectedNodes.push(parent)
          }
        }
      }
    })

    setSelectedNodes(selectedNodes)
    setSelectedWellNodes(selectedWellNodes)
  }

  const searchTreeForNode = (graph, node) => {
    if (!node) return
    const queue = [...graph]
    let searchText = node?.wellName?.toLowerCase().trim()
    const foundNodes = []

    for (const n of queue) {
      let name = n.name?.toLowerCase().trim()
      if (
        name === searchText &&
        (n.level === 'ACTUALWELL' || n.level === 'PLANNEDWELL' || n.level === 'PLANNEDWELL_PRINCIPAL')
      ) {
        foundNodes.push(n)
      }
    }

    return foundNodes
  }

  const bfsSearch = (graph, targetId) => {
    const queue = [...graph]

    while (queue.length > 0) {
      const currNode = queue.shift()
      if (currNode.nodeId === targetId) {
        return currNode
      }
      if (currNode.children) {
        queue.push(...currNode.children)
      }
    }
    return []
  }

  const isNodeChecked = (nodeId) => {
    if (!Array.isArray(selectedNodes)) return false
    return selectedNodes.findIndex((n) => n.nodeId === nodeId) >= 0
  }

  const getAllChildNodes = (id) => {
    return getAllIds(bfsSearch([rawTreeData.current], id))
  }

  const getParentNodes = (id, list = []) => {
    const node = bfsSearch([rawTreeData.current], id)

    if (node.parentNodeId !== undefined && node.parentNodeId !== null && node.parentNodeId !== '') {
      list.push(node.parentNodeId)
      return getParentNodes(node.parentNodeId, list)
    }

    return list
  }

  const isNodeCheckedAtLevel = (level) => {
    if (!Array.isArray(selectedNodes)) return false
    if (selectedNodes.findIndex((n) => n.level === level) >= 0) return true

    if (level === 'ACTUALWELL') {
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL') >= 0) return true
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL_PRINCIPAL') >= 0) return true
    }

    if (level === 'PLANNEDWELL') {
      if (selectedNodes.findIndex((n) => n.level === 'ACTUALWELL') >= 0) return true
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL_PRINCIPAL') >= 0) return true
    }

    if (level === 'PLANNEDWELL_PRINCIPAL') {
      if (selectedNodes.findIndex((n) => n.level === 'PLANNEDWELL') >= 0) return true
      if (selectedNodes.findIndex((n) => n.level === 'ACTUALWELL') >= 0) return true
    }
  }

  const getChildLevels = (level) => {
    if (level === 'OPERATOR') {
      return ['OPERATOR', 'FIELD', 'FACILITY', 'WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    }
    if (level === 'FIELD') return ['FIELD', 'FACILITY', 'WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'FACILITY') return ['FACILITY', 'WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'WELL') return ['WELL', 'ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'ACTUALWELL') return ['ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'PLANNEDWELL') return ['ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    if (level === 'PLANNEDWELL_PRINCIPAL') return ['ACTUALWELL', 'PLANNEDWELL', 'PLANNEDWELL_PRINCIPAL']
    return []
  }

  const handleShowActualWells = (event) => {
    event.stopPropagation()
    setShowActualWells(!showActualWells)
  }

  const handleShowPlannedWells = (event) => {
    event.stopPropagation()
    setShowPlannedWells(!showPlannedWells)
  }

  const handleShowPlannedWellsPrincipal = (event) => {
    event.stopPropagation()
    setShowPlannedWellsPrincipal(!showPlannedWellsPrincipal)
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation()

    //If node is checked unselect node and all children
    if (isNodeChecked(nodeId)) {
      let prevSelectedNodes = cloneDeep(selectedNodes)
      if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

      let prevSelectedWellNodes = cloneDeep(selectedWellNodes)
      if (!Array.isArray(prevSelectedWellNodes)) prevSelectedWellNodes = []

      let index = prevSelectedNodes.findIndex((n) => n.nodeId === nodeId)
      if (index >= 0) prevSelectedNodes.splice(index, 1)

      let indexWell = prevSelectedWellNodes.findIndex((n) => n.nodeId === nodeId)
      if (indexWell >= 0) prevSelectedWellNodes.splice(indexWell, 1)

      const childNodes = getAllChildNodes(nodeId)
      for (let i = 0; i < childNodes.length; i++) {
        if (!isNodeChecked(childNodes[i])) continue
        let index = prevSelectedNodes.findIndex((n) => n.nodeId === childNodes[i])
        if (index < 0) continue
        prevSelectedNodes.splice(index, 1)

        let indexWell = prevSelectedWellNodes.findIndex((n) => n.nodeId === childNodes[i])
        if (indexWell >= 0) prevSelectedWellNodes.splice(indexWell, 1)
      }

      setSelectedNodes(prevSelectedNodes)
      setSelectedWellNodes(prevSelectedWellNodes)
      return
    }

    //If node is unchecked then check all parents and children
    let node = bfsSearch([rawTreeData.current], nodeId)
    if (node?.nodeId === undefined || node?.nodeId === null || node?.nodeId === '') return

    let prevSelectedNodes = cloneDeep(selectedNodes)
    if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

    let prevSelectedWellNodes = cloneDeep(selectedWellNodes)
    if (!Array.isArray(prevSelectedWellNodes)) prevSelectedWellNodes = []

    if (singleItemSelect) {
      //If node already selected at current level uncheck and uncheck all its children
      if (isNodeCheckedAtLevel(node.level)) {
        let levelsToRemove = getChildLevels(node.level)
        prevSelectedNodes = prevSelectedNodes.filter((n) => levelsToRemove.findIndex((l) => l === n.level) < 0)
      }
    }

    prevSelectedNodes.push({ nodeId: node.nodeId, level: node.level, name: node.name })

    if (node.level === 'ACTUALWELL' || node.level === 'PLANNEDWELL' || node.level === 'PLANNEDWELL_PRINCIPAL') {
      prevSelectedWellNodes.push(node)
    }

    const nodesToSelect = [...getParentNodes(nodeId), ...getAllChildNodes(nodeId)]
    for (let i = 0; i < nodesToSelect.length; i++) {
      let node = bfsSearch([rawTreeData.current], nodesToSelect[i])
      if (!node) continue
      if (prevSelectedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0) continue

      prevSelectedNodes.push({ nodeId: node.nodeId, level: node.level, name: node.name })
      if (node.level === 'ACTUALWELL' || node.level === 'PLANNEDWELL' || node.level === 'PLANNEDWELL_PRINCIPAL') {
        prevSelectedWellNodes.push(node)
      }
    }

    setSelectedNodes(prevSelectedNodes)
    setSelectedWellNodes(prevSelectedWellNodes)
  }

  const TreeNodeIcon = ({ icon, node }) => {
    let style = {
      width: `${TREE_ICON_SIZE}px`,
      height: `${TREE_ICON_SIZE}px`,
      marginRight: `${TREE_ICON_PADDING}px`,
      padding: '2px',
    }

    if (node.level === 'PLANNEDWELL_PRINCIPAL') {
      style.border = '1px solid red'
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: selectedNode?.nodeId === node.nodeId ? '#224865' : '',
          borderRadius: '5px',
          paddingRight: '5px',
        }}
        onClick={() => setSelectedNode(node)}>
        <Checkbox
          sx={{ margin: 0, padding: 0 }}
          checked={selectedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0}
          tabIndex={-1}
          disableRipple
          onClick={(event) => {
            handleNodeSelect(event, node.nodeId)
          }}
        />
        <img alt={'Icon'} src={icon} style={style} />
        <Typography
          sx={{
            color: appColors.itemTextColor,
          }}>
          {node.name}
        </Typography>
      </Box>
    )
  }

  const ActualWellNodes = ({ actualWells }) => {
    if (!Array.isArray(actualWells)) return null

    actualWells = actualWells.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return actualWells.map((actualWell) => {
      if (actualWell.name === wellName) return null
      return showActualWells && actualWell.level === 'ACTUALWELL' ? (
        <TreeNode
          key={`${actualWell.nodeId}`}
          nodeId={`${actualWell.nodeId}`}
          labelIcon={<TreeNodeIcon icon={actualWellIcon} node={actualWell} />}
          onLabelClick={() => {}}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(`${actualWell.nodeId}`)
          }}></TreeNode>
      ) : null
    })
  }

  const PlannedWellNodes = ({ plannedWells }) => {
    if (!Array.isArray(plannedWells)) return null
    plannedWells = plannedWells.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return plannedWells.map((plannedWell) => {
      return (showPlannedWells && plannedWell.level === 'PLANNEDWELL') ||
        (showPlannedWellsPrincipal && plannedWell.level === 'PLANNEDWELL_PRINCIPAL') ? (
        <TreeNode
          key={`${plannedWell.nodeId}`}
          nodeId={`${plannedWell.nodeId}`}
          labelIcon={<TreeNodeIcon icon={plannedWellIcon} node={plannedWell} />}
          onLabelClick={() => {}}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(`${plannedWell.nodeId}`)
          }}></TreeNode>
      ) : null
    })
  }

  const WellNodes = ({ wells }) => {
    if (!Array.isArray(wells)) return null
    wells = wells.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return wells.map((well) => (
      <TreeNode
        key={`${well.nodeId}`}
        nodeId={`${well.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={wellIcon} node={well} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${well.nodeId}`)
        }}>
        <ActualWellNodes actualWells={well.children} />
        <PlannedWellNodes plannedWells={well.children} />
      </TreeNode>
    ))
  }

  const FacilityNodes = ({ facilities }) => {
    if (!Array.isArray(facilities)) return null
    facilities = facilities.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return facilities.map((facility) => (
      <TreeNode
        key={`${facility.nodeId}`}
        nodeId={`${facility.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={facilityIcon} node={facility} />}
        onLabelClick={() => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${facility.nodeId}`)
        }}>
        <WellNodes wells={facility.children} />
      </TreeNode>
    ))
  }

  const FieldNodes = ({ fields }) => {
    if (!Array.isArray(fields)) return null
    fields = fields.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return fields.map((field) => (
      <TreeNode
        key={`${field.nodeId}`}
        nodeId={`${field.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={fieldIcon} node={field} />}
        onLabelClick={(event) => {}}
        onIconClick={async (event) => {
          event.stopPropagation()
          handleToggleNodeExpanded(`${field.nodeId}`)
        }}>
        <FacilityNodes facilities={field.children} />
      </TreeNode>
    ))
  }

  const OperatorNodes = ({ operators }) => {
    if (!Array.isArray(operators)) return null
    operators = operators.filter(isNodeVisible).sort((a, b) => a.name.localeCompare(b.name))
    return operators.map((op) => (
      <TreeNode
        key={`${op.nodeId}`}
        nodeId={`${op.nodeId}`}
        endIcon={<PlusSquare />}
        labelIcon={<TreeNodeIcon icon={operatorIcon} node={op} />}
        onIconClick={async (event) => {
          handleToggleNodeExpanded(`${op.nodeId}`)
        }}>
        <FieldNodes fields={op.children} />
      </TreeNode>
    ))
  }

  const uncheckAll = () => {
    setSelectedNodes([])
    setSelectedWellNodes([])
  }

  const checkAll = () => {
    let prevSelectedNodes = cloneDeep(selectedNodes)
    if (!Array.isArray(prevSelectedNodes)) prevSelectedNodes = []

    let prevSelectedWellNodes = cloneDeep(selectedWellNodes)
    if (!Array.isArray(prevSelectedWellNodes)) prevSelectedWellNodes = []

    let allNodes = cloneDeep(flattenedNodes.current)

    allNodes.forEach((node) => {
      prevSelectedNodes.push({ nodeId: node.nodeId, level: node.level, name: node.name })

      if (node.level === 'ACTUALWELL' || node.level === 'PLANNEDWELL' || node.level === 'PLANNEDWELL_PRINCIPAL') {
        prevSelectedWellNodes.push(node)
      }
    })

    setSelectedNodes(prevSelectedNodes)
    setSelectedWellNodes(prevSelectedWellNodes)
  }

  const handleGetOffsetsWithinRadius = async () => {
    if (isLoading) return
    setSelectedNodes([])
    setSelectedWellNodes([])

    //Convert miles to feet for C2C
    setLoading(true)
    let res = await getOffsetsWithinRadius({ wellName: wellName, c2cDist: offsetRadius * 5280 })
    if (!_isMounted.current) return
    setLoading(false)
    if (res?.error) return
    if (!Array.isArray(res.data)) res.data = []
    checkSelectedNodes(res.data)
  }

  const setSelectedOffsets = async (selectedNodes) => {
    if (!selectedNodes) return false

    let strArray = ''
    selectedNodes.forEach((node) => {
      if (strArray !== '') strArray += '|'
      strArray += node?.name
    })

    let saveResponse = await updateSelectedOffsets({ wellName: wellName, offsetWells: strArray })
    if (_isMounted.current) {
      if (saveResponse?.error) return false
      await refreshWellData()
    }

    return true
  }

  return (
    <Dialog
      maxWidth='lg'
      PaperProps={{
        sx: {
          width: '80vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{`${title}: ${selectedWellNodes?.length}`}</DialogTitle>
      <DialogContent style={{ paddingTop: '0px' }}>
        {isLoading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : null}
        <Box
          sx={{
            marginBottom: '5px',
            border: '1px solid gray',
            borderRadius: '2px',
            display: 'flex',
            flexDirection: 'row',
          }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showActualWells}
                tabIndex={-1}
                disableRipple
                onClick={(event) => {
                  handleShowActualWells(event)
                }}
              />
            }
            label='Actual Wells'
            sx={{
              marginLeft: '5px',
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPlannedWells}
                tabIndex={-1}
                disableRipple
                onClick={(event) => {
                  handleShowPlannedWells(event)
                }}
              />
            }
            sx={{
              marginLeft: '5px',
            }}
            label='Plans'
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showPlannedWellsPrincipal}
                tabIndex={-1}
                disableRipple
                onClick={(event) => {
                  handleShowPlannedWellsPrincipal(event)
                }}
              />
            }
            sx={{
              marginLeft: '5px',
            }}
            label='Principal Plans'
          />
          <Box
            sx={{
              marginLeft: 'auto',
              marginRight: '5px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <FormControlLabel
              control={
                <TextField
                  variant='standard'
                  type='number'
                  placeholder='5'
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                    marginLeft: '5px',
                    marginRight: '5px',
                    width: '60px',
                  }}
                  onChange={(e) => {
                    if (!_isMounted.current) return
                    if (e.target.value < 0) return

                    setOffsetRadius(e.target.value)
                  }}
                />
              }
              labelPlacement='start'
              label='Global Scan (miles)'
            />
            <Box sx={{ marginLeft: '15px' }}>
              <Button variant='contained' color='primary' onClick={() => handleGetOffsetsWithinRadius()}>
                Search
              </Button>
            </Box>
          </Box>
        </Box>
        {showSearch === true ? (
          <Box
            sx={{
              marginBottom: '5px',
              border: '1px solid gray',
              borderRadius: '2px',
            }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle(3)}
            />
          </Box>
        ) : null}
        <Box
          style={{
            display: 'flex',
            overflow: 'auto',
            height: 'calc(100% - 135px)',
            width: 'calc(100% - 4px)',
            borderRadius: '2px',
            maxWidth: '100%',
            backgroundColor: getTreeBackColor(),
            border: '1px solid #808080',
          }}>
          <TreeView
            aria-label='controlled'
            expanded={expandedNodes}
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}>
            <TreeNode
              labelIcon={<TreeNodeIcon icon={databaseIcon} node={rawTreeData.current} />}
              nodeId={getRootNodeId()}
              onIconClick={async (event) => {
                handleToggleNodeExpanded(getRootNodeId())
              }}>
              <OperatorNodes operators={rawTreeData.current?.children} />
            </TreeNode>
          </TreeView>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button sx={{ margin: '5px' }} variant='outlined' type='button' color='secondary' onClick={() => uncheckAll()}>
          Uncheck All
        </Button>
        <Button sx={{ margin: '5px' }} variant='contained' type='button' color='primary' onClick={() => checkAll()}>
          Check All
        </Button>
        <Button
          sx={{ margin: '5px' }}
          variant='outlined'
          type='button'
          color='secondary'
          onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            const status = setSelectedOffsets(selectedWellNodes)
            if (useOnApplyCallback) {
              onApply(status)
            }
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectOffsetsTreeModal
