import cloneDeep from 'lodash/cloneDeep'
import { threeDeeScan } from 'utils/threeDeeScan'
import { round } from 'utils/numberFunctions'
const deg2Rad = Math.PI / 180

export function checkSlideRecord(startDepth, endDepth, slideRecord) {
  if (slideRecord.endDepth <= startDepth || slideRecord.startDepth >= endDepth) return false
  if (slideRecord.startDepth <= startDepth && slideRecord.endDepth >= endDepth) return true
  if (slideRecord.startDepth >= startDepth && slideRecord.endDepth <= endDepth) return true
  if (slideRecord.startDepth <= startDepth && slideRecord.endDepth >= startDepth && slideRecord.endDepth <= endDepth)
    return true
  if (slideRecord.startDepth >= startDepth && slideRecord.startDepth <= endDepth && slideRecord.endDepth >= endDepth)
    return true

  return false
}

export function getSlideRecordsFromSlidesheet(slideSheet, startDepth, endDepth) {
  if (!Array.isArray(slideSheet)) return []

  let output = []
  for (let i = 0; i < slideSheet.length; i++) {
    if (checkSlideRecord(startDepth, endDepth, slideSheet[i]) === true) output.push(cloneDeep(slideSheet[i]))
    if (slideSheet[i].endDepth > endDepth) break
  }

  return output
}

export function projectionCalc(md, tieOn, motorYield, slideSeen, toolFace, slideSheet, plan, dlBase, rotParams) {
  slideSeen = parseFloat(slideSeen)
  md = parseFloat(md)
  if (isNaN(md)) md = tieOn.md

  if (!tieOn) tieOn = { md: 0 }
  if (tieOn?.hasOwnProperty('md') === true) tieOn.md = parseFloat(tieOn.md)

  let projection = {
    md: 0,
    inc: 0,
    azi: 0,
    tvd: 0,
    ns: 0,
    ew: 0,
    vs: 0,
    dls: 0,
    br: 0,
    tr: 0,
    slideSeen: 0,
    my: 0,
    acScan: {
      UD: 0,
      LR: 0,
      dist: 0,
    },
  }

  let slideRecords = []

  if (tieOn !== null) projection = { ...tieOn }
  if (tieOn !== null && slideSheet) {
    if (md < tieOn.md) md = tieOn.md + 1

    projection.md = md

    if (slideSeen < 0) {
      slideRecords = getSlideRecordsFromSlidesheet(slideSheet, tieOn.md, md)

      if (slideRecords.length > 0) {
        slideRecords[0].startDepth = tieOn.md
        slideRecords[slideRecords.length - 1].endDepth = md

        for (let i = 0; i < slideRecords.length; i++) {
          slideRecords[i].cl = slideRecords[i].endDepth - slideRecords[i].startDepth
        }
      }
    } else {
      if (slideSeen > 0) {
        if (tieOn.md + slideSeen > md) slideSeen = tieOn.md - md
        if (slideSeen < 0) slideSeen = 0

        if (slideSeen > 0) {
          slideRecords.push({
            startDepth: tieOn.md,
            endDepth: tieOn.md + slideSeen,
            cl: slideSeen,
            state: 'Slide',
            ttf: toolFace,
            etf: toolFace,
            tfType: 'GRAV',
          })
        }

        slideRecords.push({
          startDepth: tieOn.md,
          endDepth: md,
          cl: md - tieOn.md,
          state: 'Rotate',
          ttf: 0,
          etf: 0,
          tfType: 'GRAV',
        })
      } else {
        slideRecords.push({
          startDepth: tieOn.md,
          endDepth: md,
          cl: md - tieOn.md,
          state: 'Rotate',
          ttf: 0,
          etf: 0,
          tfType: 'GRAV',
        })
      }
    }

    let result = calcProjection(
      tieOn,
      projection,
      slideRecords,
      plan,
      { dlBase: dlBase ? dlBase : 100, motorYield },
      rotParams,
      { vsNS: 0, vsEW: 0, vsAzi: 0 },
    )

    result.slideSeen = getSlideLengthFromSlideRecords(slideRecords)
    slideSeen < 0 ? (result.type = 'Auto Proj.') : (result.type = 'Manual Proj.')

    return result
  }

  slideSeen < 0 ? (projection.type = 'Auto Proj.') : (projection.type = 'Manual Proj.')
  return projection
}

export function getSlideLengthFromSlideRecords(slideRecords) {
  if (!Array.isArray(slideRecords)) return 0

  return slideRecords.reduce((total, slideRec) => {
    return total + (slideRec.state === 'Slide' ? slideRec.endDepth - slideRec.startDepth : 0)
  }, 0)
}

export function getSlideDistFromSlideSheets(slideSheets, startDepth, endDepth) {
  let foundRecords = getSlideRecordsFromSlidesheet(slideSheets, startDepth, endDepth)
  return getSlideLengthFromSlideRecords(foundRecords)
}

export function calcProjection(tieOn, Projection, slideRecords, wellPlan, params, rotParams, vsParams) {
  let orgBitMD = Projection.md
  let rotaryTF = 0.0
  let rotaryDLS = 0.0
  let prevProj = { ...tieOn }

  if (rotParams) {
    if (Math.abs(rotParams.rotDLS) > 0 && rotParams.incRotinProj === true) {
      rotaryDLS = rotParams.rotDLS
      rotaryTF = rotParams.rotTFO
    }
  }

  if (!slideRecords || slideRecords?.length === 0) {
    Projection.inc = tieOn.inc
    Projection.azi = tieOn.azi
    calcSurvey(tieOn, Projection, params.dlBase, vsParams)
  } else {
    Projection.md = tieOn.md
    Projection.inc = tieOn.inc
    Projection.azi = tieOn.azi

    let slidePresent = false
    if (getSlideDistFromSlideSheets(slideRecords, slideRecords[0].startDepth, Projection.md) > 0) {
      slidePresent = true
    }

    for (let i = 0; i < slideRecords.length; i++) {
      let DLS = params.motorYield
      let TF = slideRecords[i].etf
      let CL = slideRecords[i].endDepth - slideRecords[i].startDepth

      if (slideRecords[i].tftype === 'Mag' && prevProj.inc > 0) {
        TF -= prevProj.azi
        if (TF > 360) TF -= 360
      }

      if (slideRecords[i].state !== 'Slide') {
        if (slidePresent === false) {
          DLS = rotaryDLS
          TF = rotaryTF

          //Convert rotary TF back to highside
          if (tieOn.inc <= 5 && prevProj.inc > 0) {
            TF -= prevProj.azi
            if (TF > 360) TF -= 360
          }
        } else {
          DLS = 0
          TF = 0
        }
      }

      Projection = DTF_CL(Projection, DLS, TF, CL, params.dlBase)

      //Calcule NS EW TVD VS DLS TF
      calcSurvey(prevProj, Projection, params.dlBase, vsParams)
      prevProj = { ...Projection }
    }
  }

  Projection.md = orgBitMD
  Projection.cl = orgBitMD - tieOn.md
  calcSurvey(tieOn, Projection, params.dlBase, vsParams)

  //Calculate UD LR
  if (wellPlan?.length > 0) {
    threeDeeScan(Projection, wellPlan)

    if (tieOn.inc < 5) {
      Projection.acScan.UD = Projection.acScan.dist * Math.cos(Projection.acScan.TFN * deg2Rad)
      Projection.acScan.LR = Projection.acScan.dist * Math.sin(Projection.acScan.TFN * deg2Rad)
    }
  }

  return Projection
}

export function DTF_CL(Svy1, DLS, TFO, CL, dlBase) {
  let result = { ...Svy1 }
  let SurInc = Svy1.inc * deg2Rad
  let SurAzi = Svy1.azi * deg2Rad
  TFO *= deg2Rad

  if (CL <= 0) return result

  if (DLS === 0) {
    result.md = Svy1.md + CL
    return result
  }

  let finalAzi = 0
  let verticalFlag = false
  if (SurInc === 0) {
    verticalFlag = true
    finalAzi = TFO
    TFO = 0
  }

  let R = 180 / ((DLS / dlBase) * Math.PI)
  let FinMD = Svy1.md + CL
  let Alpha = CL / R

  let T11 = Math.sin(SurInc) * Math.cos(SurAzi)
  let T12 = Math.sin(SurInc) * Math.sin(SurAzi)
  let T13 = Math.cos(SurInc)

  let H11 = Math.cos(SurInc) * Math.cos(SurAzi)
  let H12 = Math.cos(SurInc) * Math.sin(SurAzi)
  let H13 = -Math.sin(SurInc)

  let R11 = -Math.sin(SurAzi)
  let R12 = Math.cos(SurAzi)
  let R13 = 0

  let T21 = Math.cos(Alpha) * T11 + Math.sin(Alpha) * (Math.cos(TFO) * H11 + Math.sin(TFO) * R11)
  let T22 = Math.cos(Alpha) * T12 + Math.sin(Alpha) * (Math.cos(TFO) * H12 + Math.sin(TFO) * R12)
  let T23 = Math.cos(Alpha) * T13 + Math.sin(Alpha) * (Math.cos(TFO) * H13 + Math.sin(TFO) * R13)

  let TarInc = SurInc
  if (Math.abs(T23) > 0.0) TarInc = Math.atan(Math.sqrt(Math.pow(T21, 2) + Math.pow(T22, 2)) / T23)
  if (TarInc < 0) TarInc += Math.PI

  let TarAzi = SurAzi
  if (verticalFlag === false) {
    if (Math.abs(T22) > 0.0) {
      TarAzi = Math.atan2(T22, T21)
    }
  } else {
    TarAzi = finalAzi
  }

  if (TarAzi < 0) TarAzi += 2 * Math.PI
  if (TarAzi > 2 * Math.PI) TarAzi -= 2 * Math.PI

  TarAzi *= 1 / deg2Rad
  TarInc *= 1 / deg2Rad

  result.md = FinMD
  result.inc = TarInc
  result.azi = TarAzi

  return result
}

export function calcSurvey(Svy1, Svy2, dlBase, vsParams) {
  let degToRad = Math.PI / 180
  let rInc1 = Svy1.inc * degToRad
  let rAzi1 = Svy1.azi * degToRad
  let rInc2 = Svy2.inc * degToRad
  let rAzi2 = Svy2.azi * degToRad

  let sinc1 = Math.sin(rInc1)
  let sinc2 = Math.sin(rInc2)
  let cinc1 = Math.cos(rInc1)
  let cinc2 = Math.cos(rInc2)

  let sazi1 = Math.sin(rAzi1)
  let sazi2 = Math.sin(rAzi2)
  let cazi1 = Math.cos(rAzi1)
  let cazi2 = Math.cos(rAzi2)

  Svy2.cl = Svy2.md - Svy1.md

  if (Svy2.cl === 0) {
    Svy2 = { ...Svy1 }
    return
  }

  let DL = calcDL(Svy1, Svy2)

  if (Svy2.cl > 0.0) {
    if (rInc1 === rInc2 && rAzi1 === rAzi2) {
      Svy2.dls = 0.0
    } else {
      Svy2.dls = (1 / deg2Rad) * ((dlBase / Svy2.cl) * DL)
    }
  }

  let RF = ratioFactor(DL)

  // Method dependent calculations
  let CLxRF = (Svy2.cl * RF) / 2
  let surveyCalcMethod = 0

  switch (surveyCalcMethod) {
    case 0: //Minimum Curvature
      Svy2.ns = Svy1.ns + CLxRF * (sinc1 * cazi1 + sinc2 * cazi2)
      Svy2.ew = Svy1.ew + CLxRF * (sinc1 * sazi1 + sinc2 * sazi2)
      Svy2.tvd = Svy1.tvd + CLxRF * (cinc1 + cinc2)
      break

    case 1: //Radius of curvature
      if (rInc1 === rInc2) {
        Svy2.tvd = Svy1.tvd + Svy2.cl * Math.cos((rInc2 + rInc1) / 2.0)
      } else {
        Svy2.tvd = Svy1.tvd + ((Math.PI * Svy2.cl) / (Math.PI * (rInc2 - rInc1))) * (sinc2 - sinc1)
      }

      if (rInc1 === rInc2 || rAzi1 === rAzi2) {
        Svy2.ns = Svy1.ns + Svy2.cl * Math.cos((rAzi2 + rAzi1) / 2.0) * Math.sin((rInc2 + rInc1) / 2.0)
        Svy2.ew = Svy1.ew + Svy2.cl * (Math.sin((rInc2 + rInc1) / 2.0) * Math.sin((rAzi2 + rAzi1) / 2.0))
      } else {
        Svy2.ns =
          Svy1.ns +
          ((((Math.PI * Svy2.cl) / (Math.PI * (rInc2 - rInc1))) * (cinc2 - cinc1) * Math.PI) /
            (Math.PI * (rAzi2 - rAzi1))) *
            (sazi2 - sazi1)
        Svy2.ew =
          Svy1.ew +
          ((((Math.PI * Svy2.cl) / (Math.PI * (rInc2 - rInc1))) * (cinc2 - cinc1) * Math.PI) /
            (Math.PI * (rAzi2 - rAzi1))) *
            (cazi2 - cazi1)
      }

      break

    case 2: //Balanced tangential
      Svy2.tvd = Svy1.tvd + Svy2.cl * Math.cos((rInc2 + rInc1) / 2.0)
      Svy2.ns = Svy1.ns + Svy2.cl * Math.cos((rAzi2 + rAzi1) / 2.0) * Math.sin((rInc2 + rInc1) / 2.0)
      Svy2.ew = Svy1.ew + Svy2.cl * (Math.sin((rInc2 + rInc1) / 2.0) * Math.sin((rAzi2 + rAzi1) / 2.0))
      break

    case 3: //Tangential
      Svy2.tvd = Svy1.tvd + Svy2.cl * cinc2
      Svy2.ns = Svy1.ns + Svy2.cl * cazi2 * sinc2
      Svy2.ew = Svy1.ew + Svy2.cl * sinc2 * sazi2
      break

    default:
      break
  }

  if (vsParams) Svy2.vs = calcVS(vsParams.vsAzi, Svy2.ns, Svy2.ew, vsParams.vsNS, vsParams.vsEW)

  Svy2.tfo = calcTF(Svy1, Svy2)
  Svy2.tf = Svy2.tfo
  Svy2.br = (Svy2.inc - Svy1.inc) * (dlBase / Svy2.cl)
  Svy2.tr = calcDeltaAzi(Svy1, Svy2) * (dlBase / Svy2.cl)
}

export function calcDeltaAzi(Svy1, Svy2) {
  let deltaAzi = Svy2.azi - Svy1.azi
  let Sign = 0

  if (deltaAzi >= 0) {
    Sign = -1
  } else {
    Sign = 1
  }

  if (Math.abs(deltaAzi) > 180) deltaAzi = (360 - Math.abs(deltaAzi)) * Sign
  if (Svy1.inc === 0) deltaAzi = 0
  if (Svy1.inc < 1.5) deltaAzi = 0

  return deltaAzi
}

export function calcTF(Svy1, Svy2) {
  let inc1 = Svy1.inc * deg2Rad
  let inc2 = Svy2.inc * deg2Rad
  let azi1 = Svy1.azi * deg2Rad
  let azi2 = Svy2.azi * deg2Rad
  let deltaAzi = azi2 - azi1
  let deltaInc = inc2 - inc1

  let cinc1 = Math.cos(inc1)
  let cinc2 = Math.cos(inc2)
  let sinc1 = Math.sin(inc1)
  let sinc2 = Math.sin(inc2)

  let sign = 1.0
  if (deltaAzi <= 0.0) sign = -1.0
  if (Math.abs(deltaAzi) > Math.PI) sign = -sign

  if (Math.abs(deltaInc) < 0.001 && Math.abs(deltaAzi) < 0.001) return 0.0
  if (inc1 === 0.0) return azi2 * (1 / deg2Rad)
  if (deltaAzi === 0.0 && inc2 > inc1) return 0.0
  if (deltaAzi === 0.0 && inc2 <= inc1) return 180.0
  if (deltaInc === 0.0 && inc2 === 0.0 && deltaAzi > 0.0) return 90.0
  if (deltaInc === 0.0 && inc2 === 0.0 && deltaAzi <= 0.0) return 270.0

  let tfCalc = round(
    (cinc1 * Math.cos(Math.acos(Math.cos(deltaAzi) * sinc2 * sinc1 + cinc1 * cinc2)) - cinc2) /
      (sinc1 * Math.sin(Math.acos(Math.cos(deltaAzi) * sinc2 * sinc1 + cinc1 * cinc2))),
    8,
  )

  if (Math.abs(tfCalc - 1) < 0.00000001) return 0.0
  if (Math.abs(tfCalc - 1) < 0.00000001 && tfCalc < 0) return 180.0

  return Math.acos(tfCalc) * sign * (1 / deg2Rad)
}

export function calcVS(vsAzi, NS, EW, vsNs, vsEw) {
  vsAzi *= Math.PI / 180

  let horzDist = Math.sqrt(NS * NS + EW * EW)
  if (NS === 0.0) NS = 0.000000001

  let vsOrg = 0
  let cosVal = 0
  if (NS === 0.0) {
    vsOrg = Math.sqrt(vsNs * vsNs + vsEw * vsEw) * Math.cos(Math.abs(vsAzi + Math.PI))
    cosVal = Math.abs(vsAzi + Math.PI)
  } else {
    vsOrg = Math.sqrt(vsNs * vsNs + vsEw * vsEw) * Math.cos(Math.abs(vsAzi - Math.atan2(vsEw, vsNs) + Math.PI))
    cosVal = Math.abs(vsAzi - Math.atan2(-EW, -NS) + Math.PI)
  }

  return vsOrg + horzDist * Math.cos(cosVal)
}

export function ratioFactor(DL) {
  return DL < 0.02
    ? 1 + ((DL * DL) / 12) * (1 + ((DL * DL) / 10) * (1 + ((DL * DL) / 168) * (1 + (31 * DL * DL) / 18)))
    : Math.tan(DL / 2) / (DL / 2)
}

export function calcDL(Svy1, Svy2) {
  let degToRad = Math.PI / 180
  let dogleg = 0
  let Inc1 = Svy1.inc * degToRad
  let Inc2 = Svy2.inc * degToRad
  let Azi1 = Svy1.azi * degToRad
  let Azi2 = Svy2.azi * degToRad

  if (Math.abs(Inc1 - Inc2) === 0.0 && Math.abs(Azi1 - Azi2) === 0.0) {
    dogleg = 1.0
  } else {
    dogleg = Math.cos(Inc2 - Inc1) - Math.sin(Inc2) * Math.sin(Inc1) * (1.0 - Math.cos(Azi2 - Azi1))
  }

  if (dogleg === 1.0) return 0.0
  return Math.PI / 2 - Math.atan(dogleg / Math.sqrt(1.0 - dogleg * dogleg))
}

export function nudgeTvdIncAzi(svy, tgt, dlBase, wellPlan) {
  let DL = calcDL(svy, tgt)
  let RF = ratioFactor(DL)
  tgt.error = false

  let bottomLine = (Math.cos(tgt.inc * deg2Rad) + Math.cos(svy.inc * deg2Rad)) * RF
  if (bottomLine <= 0.000001) {
    tgt.error = true
    return false
  }

  let CL = 2 * ((tgt.tvd - svy.tvd) / ((Math.cos(tgt.inc * deg2Rad) + Math.cos(svy.inc * deg2Rad)) * RF))
  if (CL < 0) {
    tgt.error = true
    return false
  }

  tgt.md = parseFloat(svy.md) + CL
  calcSurvey(svy, tgt, dlBase)

  if (wellPlan?.length > 0) {
    threeDeeScan(tgt, wellPlan)

    if (svy.inc < 5) {
      tgt.acScan.UD = tgt.acScan.dist * Math.cos(tgt.acScan.TFN * deg2Rad)
      tgt.acScan.LR = tgt.acScan.dist * Math.sin(tgt.acScan.TFN * deg2Rad)
    }
  }
  return true
}

export function findLanding(svys) {
  let landing = {
    md: 0,
    inc: 0,
    azi: 0,
    tvd: 0,
    ns: 0,
    ew: 0,
    vs: 0,
    dls: 0,
    br: 0,
    tr: 0,
    slideSeen: 0,
    my: 0,
    acScan: {
      UD: 0,
      LR: 0,
      dist: 0,
    },
  }

  if (!svys) return landing
  if (!Array.isArray(svys)) return landing
  if (svys.length === 0) return landing
  for (let i = 0; i < svys.length; i++) {
    if (svys[i].inc >= 85) return { ...svys[i] }
  }

  return landing
}
