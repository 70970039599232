import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { Box } from '@mui/system'
import {
  VIEWPORT_PADDING,
  RULER_WIDTH,
  convertUserToPixels,
} from 'components/WellPages/WallplotComposer/Viewport/cssTransformUtils'

const getGridStyle = (showGrid, gridSpacing, units, zoom) => {
  if (!showGrid) return { backgroundSize: '', backgroundImage: '' }

  const gridSize = gridSpacing * zoom
  const unitStr = units === 'm' ? 'cm' : 'in'
  const dotColor = '#BBBBBB'
  const dotSize = 1
  const svgImage = encodeURIComponent(
    `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><rect width='100%' height='100%' fill='none'/><circle cx='23' cy='23' r='${dotSize}' fill='${dotColor}'/></svg>`,
  )
  return {
    backgroundImage: `url("data:image/svg+xml,${svgImage}")`,
    backgroundSize: `${gridSize}${unitStr} ${gridSize}${unitStr}`,
  }
}

const Page = forwardRef(({ children, scale, pageLayout, units, config }, ref) => {
  const pageRef = useRef(null)
  const zoomRef = useRef(scale)
  const gridBoxRef = useRef(null)
  const prePrintStyleRef = useRef({ margin: '', backgroundImage: '' })

  useImperativeHandle(ref, () => {
    return {
      setZoom(zoomLevel) {
        zoomRef.current = zoomLevel
      },
      getPageRef() {
        return pageRef
      },
      prepPrinting() {
        prePrintStyleRef.current = {
          border: gridBoxRef.current.style.border,
          backgroundImage: gridBoxRef.current.style.backgroundImage,
        }
        gridBoxRef.current.style.border = ''
        gridBoxRef.current.style.backgroundImage = ''
      },
      donePrinting() {
        gridBoxRef.current.style.border = prePrintStyleRef.current.border
        gridBoxRef.current.style.backgroundImage = prePrintStyleRef.current.backgroundImage
      },
    }
  })

  const { backgroundSize, backgroundImage } = getGridStyle(
    config?.showGrid,
    pageLayout.gridSpacing,
    units,
    zoomRef.current,
  )

  return (
    <Box
      className='wp-page selecto-area'
      ref={pageRef}
      style={{
        margin: `${VIEWPORT_PADDING + (config?.showRulers ? RULER_WIDTH : 0)}px`,
        width: `${convertUserToPixels(pageLayout.width, units, zoomRef.current)}px`,
        height: `${convertUserToPixels(pageLayout.height, units, zoomRef.current)}px`,
        position: 'relative',
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
      }}>
      <Box
        ref={gridBoxRef}
        style={{
          position: 'relative',
          left: `${convertUserToPixels(pageLayout.marginLeft, units, zoomRef.current)}px`,
          top: `${convertUserToPixels(pageLayout.marginTop, units, zoomRef.current)}px`,
          width: `${
            convertUserToPixels(
              pageLayout.width - pageLayout.marginLeft - pageLayout.marginRight,
              units,
              zoomRef.current,
            ) - 2
          }px`,
          height: `${
            convertUserToPixels(
              pageLayout.height - pageLayout.marginTop - pageLayout.marginBottom,
              units,
              zoomRef.current,
            ) - 2
          }px`,
          border: config.showMargins ? `1px dashed #191970` : '',
          backgroundImage: backgroundImage,
          backgroundSize: backgroundSize,
        }}
      />
      {children}
    </Box>
  )
})

export default Page
