import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { throttle } from 'lodash'
import { styled } from '@mui/styles'
import ConfirmDialog from 'components/common/ConfirmDialog'
import cloneDeep from 'lodash/cloneDeep'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const StyledActionMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const PipeTallyHeaderGrid = ({
  pipeTallys,
  isLoading,
  isVisible,
  showHidePane,
  refreshData,
  addNewTally,
  updateTally,
  deleteTally,
  setSelectedTally,
  selectedTally,
  gridApi,
  handlePrintTally,
  onXlsExport,
}) => {
  const _isMounted = useRef(false)
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const throttledSetQuickFilter = throttle((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    throttledSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.tallyId)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  function onSelectionChanged() {
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows)) setSelectedTally(null)
    if (selectedRows.length === 0) setSelectedTally(null)
    setSelectedTally(selectedRows[0])
  }

  const gridOptions = {
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onSelectionChanged: onSelectionChanged,
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      let res = await updateTally(htmlSymbolHandling(data))
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          update: [res.data],
        })
      }

      setSelectedTally(res.data)
      refreshData()
    },
    [gridApi, refreshData, updateTally, setSelectedTally],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false

            let data = params.data
            data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(data)
          }
        }
        return false
      },
      cellStyle: centerAlignCell,
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell, handleUpdate])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()

    let firstTally = null
    params.api.forEachNode((node) => {
      if (!firstTally && node.data) firstTally = cloneDeep(node.data)
    })

    setSelectedTally(firstTally)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('pipeTallysListGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('pipeTallysListGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'PipeTally.xlsx',
            sheetName: 'Tally',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const onClickDelete = (data) => {
    setConfirm({
      show: true,
      title: 'Delete Pipe Tally',
      text: `Are you sure you want to delete tally ${data.tallyId}?`,
    })
  }

  const handleAddTally = async () => {
    let res = await addNewTally()
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (!gridApi.current) return
    gridApi.current.applyTransaction({
      add: [res.data],
      addIndex: 0,
    })
  }

  const handleDelete = async () => {
    if (!selectedTally) return

    let res = await deleteTally(selectedTally)
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [res.data],
      })
    }
  }

  const handleMenuClick = (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'delete') {
      onClickDelete(data)
    }

    if (action === 'print') {
      handlePrintTally(data)
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Print tally',
                action: 'print',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='ic:baseline-print' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'tallyId',
        colId: 'tallyId',
        headerName: 'Tally#',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        valueGetter: (params) => params.data?.tallyId,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueGetter: (params) => unescapeHtml(params.data?.description),
      },
      {
        field: 'bhaLength',
        colId: 'bhaLength',
        headerName: 'BHA Length',
        valueGetter: (params) => unescapeHtml(params.data?.bhaLength),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 2000,
          precision: 2,
        },
      },
      {
        field: 'numJointsPerStand',
        colId: 'numJointsPerStand',
        headerName: '# Joints Per Stand',
        valueGetter: (params) => unescapeHtml(params.data?.numJointsPerStand),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 4,
          precision: 0,
        },
      },
      {
        field: 'stickUp',
        colId: 'stickUp',
        headerName: 'Stick Up',
        valueGetter: (params) => unescapeHtml(params.data?.stickUp),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
      },
      {
        field: 'surveyOffset',
        colId: 'surveyOffset',
        headerName: 'Survey Offset',
        valueGetter: (params) => unescapeHtml(params.data?.surveyOffset),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 2,
        },
      },
      {
        field: 'gammaOffset',
        colId: 'gammaOffset',
        headerName: 'Gamma Offset',
        valueGetter: (params) => unescapeHtml(params.data?.gammaOffset),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 2,
        },
      },
      {
        field: 'comments',
        colId: 'comments',
        headerName: 'Comments',
        valueGetter: (params) => unescapeHtml(params.data?.comments),
        cellEditor: 'agLargeTextCellEditor',
        maxWidth: 300,
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 500,
          rows: 10,
          cols: 50,
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => handleDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: isVisible ? 'flex' : 'none',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(3)}
        />
      </Box>
      <Box
        sx={{
          display: isVisible ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 65px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={pipeTallys}
            columnDefs={sortColDefs(columnDefs, 'pipeTallysListGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            rowSelection={'single'}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
        <StyledActionMenuIcon>
          <MenuButton
            actions={[
              {
                icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
                name: 'Export all tallies',
                onClick: () => {
                  pipeTallys?.length > 0 && onXlsExport(-1)
                },
              },
              {
                icon: <RefreshIcon />,
                name: 'Refresh',
                onClick: () => {
                  refreshData()
                },
              },
              {
                icon: <AddIcon />,
                name: 'Add new tally',
                onClick: () => {
                  handleAddTally()
                },
              },
            ]}
          />
        </StyledActionMenuIcon>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </Box>
      {!isVisible ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Tally List
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default PipeTallyHeaderGrid
