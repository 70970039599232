import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import { currentAwsRegionAtom } from 'atoms'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import {
  sortColDefs,
  relativeTime,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import { Icon as Iconify } from '@iconify/react'
import * as yup from 'yup'
import InputModal from 'components/common/InputModal'
import { styled } from '@mui/styles'
import AntiCollisionDashStatusLogGrid from './AntiCollisionDashStatusLogGrid'
import AntiCollisionDashErrorsGrid from './AntiCollisionDashErrorsGrid'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const AntiCollisionDashStatusGrid = ({ setFilteredData, showChart, setShowChart, setRedisData }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle, getBackColor } = useInnovaTheme()
  const gridApi = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const statusData = useRef(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [awsRegions, setRegions] = useState([])
  const [currentAwsRegion, setAwsRegion] = useRecoilState(currentAwsRegionAtom)
  const [showAwsRegionSelector, setShowAwsRegionSelector] = useState(false)
  const [detailGridType, setDetailGridType] = useState({ data: null, type: null })
  const containerRef = useRef(null)
  const selectedOrg = useRef(null)

  const clearCacheData = useInnovaAxios({
    url: '/antiCollision/cache/clearCache',
  })

  const clearCacheErrors = useInnovaAxios({
    url: '/antiCollision/cache/clearErrors',
  })

  const getCacheStatus = useInnovaAxios({
    url: '/antiCollision/cache/getStatus',
  })

  const getAwsRegions = useInnovaAxios({
    url: '/admin/getAwsRegions',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchAwsRegions()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [currentAwsRegion]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.organization)
    }
  }, [])

  const fetchAwsRegions = async () => {
    const response = await getAwsRegions()
    if (response?.error) return

    if (!Array.isArray(response.data)) return
    if (!_isMounted.current) return

    let awsList = []
    response.data.forEach((region) => {
      awsList.push({ label: region, value: region })
    })

    setRegions(awsList)
  }

  const clearCache = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    const res = await clearCacheData({ awsRegion: currentAwsRegion })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    fetchData()
  }

  const clearErrors = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    const res = await clearCacheErrors({ awsRegion: currentAwsRegion })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    fetchData()
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    const res = await getCacheStatus({ awsRegion: currentAwsRegion })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', Array.isArray(res?.data?.organizations) ? res.data.organizations : [])

      
        gridApi.current?.autoSizeAllColumns()
        gridApi.current.onFilterChanged()
    }

    statusData.current = res.data
    setRedisData({ info: res?.data?.redisInfo, cpu: res?.data?.redisCpuUsage, mem: res?.data?.redisMemoryUsage })
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchData()
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    setFilteredData(filteredNodes)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 200)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('acDashStatusGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('acDashStatusGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AcDashStatusData.xlsx',
            sheetName: 'AcDashStatusData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell])

  const showDetailGrid = useCallback(
    (data, type) => {
      if (!gridApi.current) return

      let shouldRefresh = false
      gridApi.current.forEachNode((node) => {
        if (node.data.organization !== data.organization) gridApi.current.setRowNodeExpanded(node, false)

        if (node.data.organization === data.organization) {
          let shouldExpand = detailGridType.data?.organization !== data.organization || detailGridType.type !== type

          setDetailGridType({ data: shouldExpand ? data : null, type: shouldExpand ? type : null })
          gridApi.current.setRowNodeExpanded(node, shouldExpand)

          if (shouldExpand) shouldRefresh = true
        }
      })

      if (shouldRefresh) gridApi.current.refreshCells()
    },
    [detailGridType],
  )

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedOrg.current = data

      if (action === 'status') {
        showDetailGrid(data, 'status')
      }

      if (action === 'errors') {
        showDetailGrid(data, 'errors')
      }
    },
    [showDetailGrid],
  )

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
  }, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  let columnDefs = useMemo(
    () => [
      {
        colId: 'rowNum',
        headerName: '#',
        valueGetter: (params) => params.node.rowIndex + 1,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: '',
        colId: 'actions',
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label:
                  detailGridType.data?.name === params.data?.name && detailGridType.type === 'status'
                    ? 'Hide status'
                    : 'View status',
                action: 'status',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='icon-park:log' />
                  </StyledIconContainer>
                ),
              },
              {
                label:
                  detailGridType.data?.name === params.data?.name && detailGridType.type === 'errors'
                    ? 'Hide errors'
                    : 'View errors',
                action: 'errors',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='ic:baseline-troubleshoot' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        minWidth: 70,
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
      },
      {
        field: 'status',
        colId: 'status',
        headerName: 'Status',
        cellStyle: (params) => {
          return {
            ...centerAlignCell,
            color: params?.value === 'READY' ? '#66FF00' : params?.value === 'ERROR' ? 'red' : 'orange',
          }
        },
      },
      {
        field: 'lastUpdate',
        colId: 'lastUpdate',
        headerName: 'Last Update',
        cellStyle: leftAlignCell,
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return `${dateTimeFormatter(params.value)} [${relativeTimeFormatter(params.value)}]`
        },
      },
      {
        field: 'numWells',
        colId: 'numWells',
        headerName: '#Wells',
      },
      {
        field: 'loadTime',
        colId: 'loadTime',
        headerName: 'Load time',
        valueFormatter: (params) => {
          if (typeof params.value !== 'number') return '-'
          let loadTimeSeconds = params.value / 1e9
          return `${loadTimeSeconds.toFixed(3)}s`
        },
      },
      {
        field: 'useBggmErrorEstimates',
        colId: 'useBggmErrorEstimates',
        headerName: 'BGGM Errors Est.',
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        colId: 'hasErrors',
        headerName: 'Errors',
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.errors)) return false
          return params.data?.errors.length > 0
        },
      },
      {
        field: 'statusMessage',
        colId: 'statusMessage',
        headerName: 'Message',
        cellStyle: leftAlignCell,
      },
    ],
    [
      centerAlignCell,
      detailGridType,
      handleMenuClick,
      leftAlignCell,
      dateTimeFormatter,
      relativeTimeFormatter,
      dateFilterComparator,
    ],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const AwsRegionModal = ({ submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({})

    let formData = [
      {
        tag: 'awsRegion',
        value: currentAwsRegion,
        text: 'AWS Region',
        inputType: 'dropdown',
        dropDownValues: awsRegions,
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={showAwsRegionSelector}
        onClose={cancelFunction}
        title={'Select AWS Region'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const changeAwsRegion = async (data) => {
    setShowAwsRegionSelector(false)
    setAwsRegion(data.awsRegion)
    return true
  }

  const DetailCellRenderer = () => {
    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        {detailGridType.type === 'status' ? (
          <AntiCollisionDashStatusLogGrid data={selectedOrg.current?.statusLog} height={height} />
        ) : null}
        {detailGridType.type === 'errors' ? (
          <AntiCollisionDashErrorsGrid data={selectedOrg.current?.errors} height={height} />
        ) : null}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: `100%`,
      }}>
      {showAwsRegionSelector ? (
        <AwsRegionModal
          submitFunction={changeAwsRegion}
          cancelFunction={() => {
            setShowAwsRegionSelector(false)
          }}
        />
      ) : null}
      <Box
        sx={{
          backgroundColor: getBackColor(),
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'acDashStatusGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
            onFilterChanged={onFilterChanged}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='logos:aws' />,
              name: 'Select AWS region',
              onClick: () => setShowAwsRegionSelector(true),
            },
            {
              icon: (
                <Iconify icon='ic:round-refresh' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />
              ),
              name: 'Refresh data',
              onClick: () => fetchData(),
            },
            {
              icon: <Iconify icon='fluent:delete-48-regular' style={{ color: 'tomato', width: 28, height: 28 }} />,
              name: 'Clear cache',
              onClick: () => clearCache(),
            },
            {
              icon: <Iconify icon='mdi:warning' style={{ color: 'orange', width: 28, height: 28 }} />,
              name: 'Clear errors',
              onClick: () => clearErrors(),
            },
            {
              icon: (
                <Iconify
                  icon={showChart ? 'bxs:hide' : 'bxs:show'}
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: showChart ? 'Hide Charts' : 'Show Charts',
              onClick: () => setShowChart(!showChart),
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default AntiCollisionDashStatusGrid
