import React, { useEffect, useRef, useState, useMemo } from 'react'

import { useRecoilValue } from 'recoil'

import { currentWellAtom } from 'atoms'
import WellDepthTimeChart from 'components/WellPages/DailyReportsPages/CarouselItems/WellDepthTimeChart'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { createChartDataMultiWell } from 'components/common/depthVtimeFunctions'
import { Skeleton, Box, TextField } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { appColors, array2pipestr } from 'utils'
import MultiSelectEditor from 'components/common/CellEditors/MultiSelectEditor'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'

const DepthTimeCard = (params) => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const { getUnitsText } = useUnits()
  const { getChartBackColor, theme } = useInnovaTheme()
  const [radius, setRadius] = useState(5)
  const [offsets, setOffsets] = useState([])
  const [selectedOffsets, setSelectedOffsets] = useState([])
  const selectedWellRef = useRef(currentWell)

  const getOffsetsWithinRadius = useInnovaAxios({
    url: '/well/getOffsetWellsC2C',
    timeout: 600000,
  })

  const getKpiData = useInnovaAxios({
    url: '/kpi/depthVdays',
  })

  const textFieldStyle = useMemo(
    () => ({
      height: '30px',
      margin: '5px',
      backgroundColor: theme === 'dark' ? '#2d2d2d !important' : '#f0f0f0 !important',
      width: '60px',
      maxWidth: '400px',
      justifyContent: 'center',
    }),
    [theme],
  )

  useEffect(() => {
    if (_isMounted.current) {
      fetchOffsets()
      fetchData()
    }
  }, [selectedWellRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedOffsets]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchOffsets()
    }
  }, [radius]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    initWellSettings()
    fetchOffsets()
    fetchData()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const initWellSettings = () => {
    if (params.data?.actualWell) {
      selectedWellRef.current = params.data.actualWell
    }
    let offsets = getItemFromLS(selectedWellRef.current, 'selectedOffsets')
    setSelectedOffsets(offsets ? offsets : [])
    let rad = getItemFromLS(selectedWellRef.current, 'radius')
    setRadius(rad ? rad : 5)
  }

  const fetchData = async () => {
    if (selectedWellRef.current.length < 1) {
      setData([])
      return
    }

    let wellList = [selectedWellRef.current]
    if (selectedOffsets.length > 0) {
      wellList = wellList.concat(selectedOffsets.map((offset) => offset.value))
    }

    let payload = {
      wellList: array2pipestr(wellList),
    }

    payload.phaseFilter = ''

    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      if (dataResponse?.data) {
        setData(dataResponse.data)
      } else {
        setData([])
      }
    }
  }

  const fetchOffsets = async () => {
    if (selectedWellRef.current.length < 1) {
      setOffsets([])
      return
    }

    let res = await getOffsetsWithinRadius({ wellName: selectedWellRef.current, c2cDist: radius * 5280, isPlan: false })

    if (_isMounted.current) {
      if (res?.data) {
        res.data = res.data.filter((offset) => offset.isPlan === false && offset.depthVDays === true)
        setOffsets(res.data)
      } else {
        setOffsets([])
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
        minHeight: '80px',
      }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex', marginBottom: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20px',
            paddingLeft: '10px',
            paddingTop: '10px',
            color: appColors.itemTextColor,
          }}>
          Radius:
          <TextField
            sx={textFieldStyle}
            fullWidth
            value={radius}
            onChange={(e) => {
              let newVal = e.target.value
              setRadius(newVal)
              saveItemToLS(selectedWellRef.current, 'radius', newVal)
            }}
          />
          <MultiSelectEditor
            data={offsets.map((offset) => ({ value: offset.wellName, label: offset.wellName }))}
            wellName = {selectedWellRef.current}
            selectedOffsets={selectedOffsets}
            setSelectedOffsets={setSelectedOffsets}
          />
        </Box>
      </Box>
      {Array.isArray(data) && data.length > 0 ? (
        <WellDepthTimeChart
          xAxisTitle={'Days'}
          yAxisTitle={`Depth (${getUnitsText(UNITS_FOR.Depth)})`}
          wellData={createChartDataMultiWell(data)}
          rawData={data}
          hasOffsets={selectedOffsets.length > 0}
        />
      ) : (
        <Skeleton variant='rectangular' width='100%' height='100%' animation='wave' />
      )}
    </Box>
  )
}

export default DepthTimeCard
