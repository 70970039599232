import React, { useEffect, useRef } from 'react'

import { Line } from 'react-chartjs-2'
import { CircularProgress } from '@mui/material'
import { appColors } from 'utils'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BhaTendencyChart = ({
  showLegend,
  chartData,
  loading,
  xBeginAtZero = false,
  isToolbarExpanded,
  trajectoryData = [],
  units,
  variable,
  printing = false,
}) => {
  const _isMounted = useRef(false)
  const { getUnitsText } = useUnits()
  const { theme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getChartOptions = () => {
    return {
      animation: false,
      layout: {
        padding: {
          left: 20 + (isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM),
          bottom: 50,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scaleShowLabels: false,
      elements: {
        point: {
          radius: 0,
          hitRadius: 1,
        },
      },
      interaction: {
        mode: 'y',
        intersect: false,
      },
      plugins: {
        GradientPlugIn: {
          showGradient: !printing,
          theme: theme,
        },
        annotation: {
          annotations: null,
        },
        title: {
          display: true,
          text: 'BHA Directional Tendency',
          color: appColors.itemTextColor,
          font: {
            size: 20,
          },
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: (item) => {
              if (!Array.isArray(item)) return ''
              if (item.length === 0) return ''
              return `Build Rate: ${item[0].formattedValue} ${getUnitsText(UNITS_FOR.Dogleg)}`
            },
            label: (item) => {
              let inc = numberWithCommasDecimals(parseFloat(item?.label), 2)
              let data = item?.dataset?.label
                ?.replace('WOB: ', '')
                .replace('Motor Bend: ', '')
                .replace('-Sliding', '')
                .replace('-Rotating', '')
                .replace('-', '')
                .replace('Motor Yield (Slide Sheet)', '')
                .replace('Rotary DLS (Slide Sheet)', '')
                .split(' ')[0]

              if (data !== '') return `INC: ${inc}°, ${variable}: ${data} ${units}`

              return `INC: ${inc}°`
            },
          },
        },
        legend: {
          display: showLegend,
          position: 'right',
          labels: {
            generateLabels: (chart) => {
              const data = chart.data
              if (data.labels.length && data.datasets.length) {
                return data.datasets.map((dataSet, i) => {
                  let meta = chart.getDatasetMeta(i)

                  let textColor = '#ffffff'
                  if (printing || theme !== 'dark') {
                    textColor = '#000000'
                  }
                  
                  return {
                    text: dataSet.label,
                    fillStyle: dataSet.borderColor,
                    strokeStyle: dataSet.borderColor,
                    hidden: meta?.hidden,
                    fontColor: textColor,
                    datasetIndex: i,
                  }
                })
              }
              return []
            },
          },
          onHover: (event, item, legend) => {
            legend.chart.data.datasets.forEach((dataset, index) => {
              dataset.borderWidth = item.datasetIndex === index ? 5 : 1
            })
            legend.chart.update()
          },
          onLeave: (event, item, legend) => {
            item.lineWidth = 3
            legend.chart.data.datasets.forEach((dataset) => {
              dataset.borderWidth = 3
            })
            legend.chart.update()
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
            y: {
              min: 'original',
              max: 'original',
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        },
        datalabels: {
          labels: {
            value: {
              font: {
                weight: 'bold',
              },
              color: '#000',
            },
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          align: 'center',
          beginAtZero: xBeginAtZero,
          title: {
            display: true,
            text: `INC (°)`,
            font: {
              size: 15,
            },
            color: appColors.itemTextColor,
          },
          ticks: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
          grid: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
        },
        y: {
          type: 'linear',
          beginAtZero: true,
          reverse: false,
          title: {
            display: true,
            text: `Build Rate (${getUnitsText(UNITS_FOR.Dogleg)})`,
            font: {
              size: 15,
            },
            color: appColors.itemTextColor,
          },
          ticks: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
          grid: {
            color: printing ? '#000000' : appColors.headerTextColor,
          },
        },
      },
    }
  }

  return (
    <>
      {loading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            height: '100px',
            width: '100px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 999999,
          }}
        />
      ) : null}
      <Line options={getChartOptions()} 
      data={{ labels: chartData.labels, datasets: chartData.datasets.concat(trajectoryData) }} />
    </>
  )
}

export default BhaTendencyChart
