import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Box } from '@mui/material'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { appColors } from 'utils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { AgGridReact } from 'ag-grid-react'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const Profile = () => {
  const { user } = useInnovaAuth()
  const _isMounted = useRef(false)
  const gridPermsApi = useRef()
  const gridFeatsApi = useRef(null)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const { getCurrentOrgIcon } = useOrgIcons()
  const userRoleRef = useRef({ name: '', organization: '' })
  const [isLoadingPerms, setIsLoadingPerms] = useState(false)
  const [isLoadingFeats, setIsLoadingFeats] = useState(false)
  const [featureColDefs, setFeatureColDefs] = useState([])
  const { getAgGridTheme, getLinearGradient } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.userProfileKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRole = useInnovaAxios({
    url: `/user/role`,
  })

  const getFeatures = useInnovaAxios({
    url: '/admin/getFeaturesByOrgWithDesc',
  })

  const fetchRolePerms = async () => {
    if (isLoadingPerms) return
    setIsLoadingPerms(true)

    const res = await getRole(user)
    if (!_isMounted.current) return
    setIsLoadingPerms(false)

    if (res?.error) return
    userRoleRef.current = res.data

    if (!gridPermsApi.current) return
    gridPermsApi.current.setGridOption('rowData', getPermsData(res.data))
  }

  const fetchRoleFeats = async () => {
    if (isLoadingFeats) return
    setIsLoadingFeats(true)

    const res = await getRole(user)

    if (res?.error) return
    userRoleRef.current.name = res.data.name
    userRoleRef.current.organization = res.data.organization

    if (!gridFeatsApi.current) return

    const resAllFeatures = await getFeatures()

    if (!_isMounted.current) return
    setIsLoadingFeats(false)

    if (resAllFeatures.error) return

    if (!Array.isArray(resAllFeatures.data)) return

    let orgFeatures = resAllFeatures.data.find((orgs) => orgs.organization === userRoleRef.current.organization)
    if (!orgFeatures) return

    let products = [...new Set(orgFeatures.features?.map((item) => item.productDescription))]
    let features = [...new Set(orgFeatures.features?.map((item) => item.description))].sort((a, b) => {
      if (a === 'Base') return -1
      if (b === 'Base') return 1
      return a.localeCompare(b)
    })

    let columnDefsFeats = [
      {
        field: 'feature',
        colId: 'feature',
        cellStyle: leftAlignCell,
      },
    ]

    products.forEach((product) => {
      columnDefsFeats.push({
        field: product,
        colId: product,
        headerName: product,
        minWidth: 50,
        cellStyle: centerAlignCell,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      })
    })

    setFeatureColDefs(columnDefsFeats)

    let rowData = []
    for (let i = 0; i < features.length; i++) {
      let row = { feature: features[i] }

      products.forEach((product) => {
        row[product] = res?.data?.roleAttributes?.features.find(
          (roleFeat) => roleFeat.productDescription === product && roleFeat.description === features[i],
        )
          ? true
          : false
      })
      rowData.push(row)
    }

    gridFeatsApi.current.setGridOption('rowData', rowData)
  }

  const TextField = ({ text, value }) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        <Box
          sx={{
            fontSize: 16,
            fontWeight: '500',
            color: appColors.headerTextColor,
            textAlign: 'right',
            minWidth: '100px',
            paddingRight: '10px',
          }}>
          {text}
        </Box>
        <Box sx={{ fontSize: 16, fontWeight: '500', color: appColors.itemTextColor, border: '' }}>{value}</Box>
      </Box>
    )
  }
  const onGridReadyPerms = (params) => {
    gridPermsApi.current = params.api
    fetchRolePerms()
  }

  const onGridReadyFeats = (params) => {
    gridFeatsApi.current = params.api
    fetchRoleFeats()
  }

  const onFirstDataRenderedPerms = (params) => {
    autoSizePermsColumns()
  }

  const onFirstDataRenderedFeats = (params) => {
    autoSizeFeatsColumns()
  }

  const autoSizePermsColumns = () => {
    if (gridPermsApi.current === null) return
    gridPermsApi.current?.autoSizeAllColumns()
  }

  const autoSizeFeatsColumns = () => {
    if (gridFeatsApi.current === null) return
    gridFeatsApi.current?.autoSizeAllColumns()
  }

  const gridOptionsFeats = {
    suppressRowClickSelection: true,
  }

  const gridOptionsPerms = {
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const defaultColDefPerms = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const defaultColDefFeats = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const columnDefsPerms = [
    {
      headerName: 'Permissions',
      field: '',
      colId: '',
      cellStyle: leftAlignCell,
    },
    {
      headerName: 'Admin',
      field: 'admin',
      colId: 'admin',
      minWidth: 100,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'Approve',
      field: 'canApprove',
      colId: 'canApprove',
      minWidth: 100,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'Create',
      field: 'canCreate',
      colId: 'canCreate',
      minWidth: 100,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'Edit',
      field: 'canEdit',
      colId: 'canEdit',
      minWidth: 100,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
    {
      headerName: 'Delete',
      field: 'canDelete',
      colId: 'canDelete',
      minWidth: 100,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
  ]

  const getPermsData = (data) => {
    let permsData = []
    if (!data?.roleAttributes?.permissions) return permsData
    permsData.push({
      admin: data?.roleAttributes?.permissions?.admin ? true : false,
      canApprove: data?.roleAttributes?.permissions?.canApprove ? true : false,
      canCreate: data?.roleAttributes?.permissions?.canCreate ? true : false,
      canEdit: data?.roleAttributes?.permissions?.canEdit ? true : false,
      canDelete: data?.roleAttributes?.permissions?.canDelete ? true : false,
    })
    return permsData
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 74px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}>
      <Box
        sx={{
          background: getLinearGradient(),
          width: '100%',
          height: '100%',
          padding: '5px',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <img
              style={{
                height: '35px',
                width: '35px',
                borderRadius: '5px',
              }}
              alt={userRoleRef.current?.organization}
              src={getCurrentOrgIcon()}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
              <TextField text={'User Name:'} value={user.name} />
              <TextField text={'Organization:'} value={userRoleRef.current.organization} />
              <TextField text={'Role:'} value={userRoleRef.current.name} />
              <TextField text={'Units:'} value={userRoleRef.current?.userPrefs?.unitPrefs} />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', margin: '10px', width: '100%' }}>
              <div className={getAgGridTheme()} style={{ width: '100%', height: '100px' }}>
                <AgGridReact
                  columnDefs={columnDefsPerms}
                  defaultColDef={defaultColDefPerms}
                  animateRows={true}
                  enableBrowserTooltips={true}
                  gridOptions={gridOptionsPerms}
                  headerHeight={30}
                  onGridReady={onGridReadyPerms}
                  onFirstDataRendered={onFirstDataRenderedPerms}
                />
              </div>
              <div
                className={getAgGridTheme()}
                style={{ marginTop: '5px', width: '100%', height: 'calc(100vh - 380px)' }}>
                <AgGridReact
                  defaultColDef={defaultColDefFeats}
                  columnDefs={featureColDefs}
                  animateRows={true}
                  enableBrowserTooltips={true}
                  gridOptions={gridOptionsFeats}
                  headerHeight={30}
                  onGridReady={onGridReadyFeats}
                  onFirstDataRendered={onFirstDataRenderedFeats}
                />
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Profile
