import React from 'react'
import BarChart from 'components/common/BarChartCjs'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ConnectionAnalysisChart = ({ connectionData, avgValues, showCompleteLabel = true }) => {
  const { theme, getChartBackColor } = useInnovaTheme()

  const chartOptions = {
    animation: {
      duration: 0,
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
      },
      title: {
        display: avgValues?.wt2wt > 0,
        text: avgValues?.wt2wt > 0 ? `Avg Time All - ${numberWithCommasDecimals(avgValues.wt2wt, 2)} (min)` : '',
      },
      tooltip: {
        callbacks: {
          title: (content) => {
            if (!connectionData) return content.label
            return content[0].label
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }

  const createChartData = (data) => {
    if (!Array.isArray(data) || data.length === 0) return { labels: [], datasets: [] }
    let chartLabels = [
      ...new Set(
        data.map(
          (conn) =>
            `${showCompleteLabel ? dateTimeFormatter(conn.timeStamp) : ''} ${numberWithCommasDecimals(
              conn.bitDepth,
              2,
            )}`,
        ),
      ),
    ]
    let output = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Btm to Slip (Day)',
          backgroundColor: '#660066',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Slip to Slip (Day)',
          backgroundColor: '#be29ec',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Slip to Btm (Day)',
          backgroundColor: '#d896ff',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Btm to Slip (Night)',
          backgroundColor: '#0034de',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Slip to Slip (Night)',
          backgroundColor: '#195fff',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
        {
          label: 'Slip to Btm (Night)',
          backgroundColor: '#199bff',
          maxBarThickness: 24,
          data: Array(chartLabels.length).fill(0),
        },
      ],
    }
    if (avgValues.wt2wt > 0) {
      output.datasets.push({
        label: 'Avg Time All (min)',
        type: 'line',
        backgroundColor: '#ff0000',
        borderColor: '#ff0000',
        pointRadius: 0,
        data: Array(chartLabels.length).fill(avgValues.wt2wt),
      })
    }

    data.forEach((conn, index) => {
      let btmToSlip = conn.wt2WtTime - conn.surveyTime - conn.slp2SlpTime
      if (btmToSlip < 0) btmToSlip = 0
      let slipToSlip = conn.slp2SlpTime
      let slipToBtm = conn.surveyTime
      if (slipToBtm < 0) slipToBtm = 0

      let indexChange = conn.dayShift ? 0 : 3

      output.datasets[0 + indexChange].data[index] = btmToSlip / 60
      output.datasets[1 + indexChange].data[index] = slipToSlip / 60
      output.datasets[2 + indexChange].data[index] = slipToBtm / 60
    })

    return output
  }

  return (
    <BarChart
      wellData={createChartData(connectionData)}
      title=''
      chartOptions={chartOptions}
      backgroundColor={getChartBackColor()}
    />
  )
}

export default ConnectionAnalysisChart
