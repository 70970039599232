import React, { useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import DatabaseChangesDetailGrid from './DatabaseChangesDetailGrid'
import { relativeTime, isDateLessThan } from 'components/common/AgGridUtils'
import { GetActivityColor } from 'components/common/activitySelector'


const DatabaseChangesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [isLoading, setLoading] = useState(false)
  const [databaseChanges, setDatabaseChanges] = useState([])

  const getDatabaseChanges = useInnovaAxios({
    url: '/admin/getDatabaseChanges',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.databaseChangesPageKey)
    loadFilterState()
    fetchDatabaseChanges()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDatabaseChanges = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    setLoading(true)

    const response = await getDatabaseChanges()

    setLoading(false)
    if (response?.error) return
    if (!_isMounted.current) return

    setDatabaseChanges(response.data)
  }

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data?.wellName
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    loadFilterState()

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    saveFilterState()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('databaseChangesGrid', 'colLayout', colLayout)
    }
  }

  const saveFilterState = () => {
    if (gridApi.current) {
      const filters = gridApi.current.getFilterModel()
      if (filters) saveItemToLS('databaseChangesGrid', 'filters', filters)
    }
  }

  const loadFilterState = () => {
    if (gridApi.current) {
      const filters = getItemFromLS('databaseChangesGrid', 'filters')
      if (filters) gridApi.current.setFilterModel(filters)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: '',
        autoHeight: true,
        minWidth: 60,
        maxWidth: 60,
        cellRenderer: 'agGroupCellRenderer',
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Well Name',
        field: 'wellName',
        colId: 'wellName',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Well Status',
        field: 'wellStatus',
        colId: 'wellStatus',
        filter: 'agTextColumnFilter',
        cellStyle: (params) =>{
          return{
            display: 'flex',
            alignItmes: 'center',
            justifyContent: 'center',
            color: GetActivityColor(params.data?.wellStatus),
          }
        },
      },
      {
        headerName: 'Job Number',
        field: 'jobNumber',
        colId: 'jobNumber',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Last Update By',
        field: 'latestUser',
        colId: 'latestUser',
        filter: 'agTextColumnFilter',
      },
      {
        headerName: 'Last Update',
        field: 'latestTime',
        colId: 'latestTime',
        filter: 'agDateColumnFilter',
        valueFormatter: (params) =>
            `${dateTimeFormatter(params.data?.latestTime)} [${relativeTimeFormatter(
              params.data?.latestTime,
            )}]`,
      }
    ],
    [dateTimeFormatter, relativeTimeFormatter],
  )

  const DetailCellRenderer = (params) => {
    return (
      <Box sx={{ width: '100%', height: 400, padding: '5px' }}>
        <DatabaseChangesDetailGrid data={params?.data?.change} />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={databaseChanges}
          loading={isLoading}
          columnDefs={sortColDefs(columnDefs, 'databaseChangesGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
          headerHeight={30}
          onFilterChanged={onFilterChanged}
        />
      </div>
    </Box>
  )
}

export default DatabaseChangesPage
