// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import merge from 'lodash/merge'
import axios from 'axios'
import qs from 'qs'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

const request = async (options, getAccessTokenSilently, userName, databaseOrg) => {
  if (userName === '')
    return {
      data: null,
      error: {
        message: 'userName is empty',
      },
    }

  const accessToken = await getAccessTokenSilently()

  try {
    const inputData = options.data
    const data = qs.stringify(
      { ...inputData, userName, databaseOrg, productKey: process.env.REACT_APP_ICP_PRODUCT_KEY },
      { allowDots: true, arrayFormat: 'comma' },
    )
    
    const axiosParams = {
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    const response = await axios(axiosParams)

    return {
      data: response.data,
      error: response.error,
      headers: response.headers,
    }
  } catch (error) {
    return {
      data: null,
      error,
    }
  }
}

const useInnovaAxios = (options) => {
  const controller = new AbortController()
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  options.signal = controller.signal
  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetch = (payload, url, timeout) => {
    if (url && url !== '') options.url = url // url override/replacement
    if (timeout && typeof timeout === 'number') options.timeout = timeout
    const newOptions = merge(options, { data: payload })
    return request(newOptions, getAccessTokenSilently, user?.name || '', getDatabaseOrg())
  }

  return fetch
}

export default useInnovaAxios
