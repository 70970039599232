import React from 'react'
import { Switch, Box } from '@mui/material'
import { useRecoilState } from 'recoil'

import { onlyShowFollowingWellsAtom } from 'atoms'
import { appColors } from 'utils'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const GlobalPrefs = ({ isMultiDb = false, showAllWellsButton = true }) => {
  const { hasObjectsOfInterest } = useObjectsOfInterest()
  const [onlyShowFollowing, setOnlyShowFollowing] = useRecoilState(onlyShowFollowingWellsAtom)
  const {theme} = useInnovaTheme()

  return (
    <React.Fragment>
      {hasObjectsOfInterest() && !isMultiDb ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: theme === 'dark' ? appColors.itemBackColor : '#FFF',
          }}>
          <Box sx={{ fontSize: '14px', marginLeft: '20px', color: theme === 'dark' ? appColors.headerBackgroundColor : '#000' }}>
            Show following
          </Box>
          <Switch
            sx={{ marginRight: '14px', marginLeft: '10px' }}
            color='primary'
            checked={onlyShowFollowing}
            onClick={() => setOnlyShowFollowing(!onlyShowFollowing)}></Switch>
        </Box>
      ) : null}
    </React.Fragment>
  )
}

export default GlobalPrefs
