import React, { useState, useRef, useEffect } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Checkbox, FormControlLabel, FormGroup, Typography, TextField, Box, CircularProgress } from '@mui/material'
import { appColors } from 'utils'
import TravellingCylinder from './TravellingCylinder'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

function TravellingCylinderChart({ antiCollisionData = null, isLoading }) {
  const tcZoom = useRef(1)
  const [tcIsHs, setTcIsHs] = useState(true)
  const [tcShowDepthLabels, setTcShowDepthLabels] = useState(false)
  const [tcShowWellNames, setTcShowWellNames] = useState(true)
  const [tvdSliceMin, setTvdSliceMin] = useState(0)
  const [tvdSliceMax, setTvdSliceMax] = useState(50000)
  const [forceReRender, setForceReRender] = useState(false) // eslint-disable-line no-unused-vars
  const { getChartBackColor } = useInnovaTheme()

  const onWheel = (event) => {
    event.preventDefault()
    let divisor = 10000
    if (tcZoom.current >= 0.1 && tcZoom.current < 0.2) divisor = 12500
    if (tcZoom.current >= 0.05 && tcZoom.current < 0.1) divisor = 35000
    if (tcZoom.current >= 0.025 && tcZoom.current < 0.05) divisor = 250000
    if (tcZoom.current >= 0 && tcZoom.current < 0.025) divisor = 500000

    let newZoom = tcZoom.current + event.deltaY / divisor
    if (newZoom < 0) newZoom = 0
    if (newZoom >= 1) newZoom = 1

    tcZoom.current = newZoom
    setForceReRender((prevVal) => !prevVal)
  }

  useEffect(() => {
    document.getElementById('tcChartContainer').addEventListener('wheel', onWheel)

    return () => {
      document.removeEventListener('wheel', onWheel)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function ControlsCheckBox(label, value, setterFunction) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={() => {
              setterFunction((prevValue) => !prevValue)
            }}
            name='rig'
            sx={{ color: '#A0A0A0' }}
          />
        }
        label={<Typography sx={{ color: '#A0A0A0' }}>{label}</Typography>}></FormControlLabel>
    )
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }} id={'tcChartContainer'}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <Box
        sx={{
          fontSize: 26,
          fontWeight: '500',
          textAlign: 'center',
          padding: '5px',
          color: appColors.itemTextColor,
          backgroundColor: getChartBackColor(),
          whiteSpace: 'nowrap',
        }}>
        {`Travelling Cylinder - ${tcIsHs ? 'High Side' : 'True North'}`}
      </Box>
      {antiCollisionData ? (
        <AutoSizer>
          {({ height, width }) => {
            return (
              <TravellingCylinder
                h={height - 45}
                w={width}
                data={antiCollisionData}
                northRef={tcIsHs ? 'HS' : 'North'}
                drawDepthLabels={tcShowDepthLabels}
                drawWellNames={tcShowWellNames}
                zoomPercentage={tcZoom.current}
                tvdSliceMin={tvdSliceMin}
                tvdSliceMax={tvdSliceMax}
              />
            )
          }}
        </AutoSizer>
      ) : null}
      <Box sx={{display: 'flex', flexGrow: 0 }}>
        <FormGroup
          sx={{
            flexDirection: 'column',
            margin: '20px'
          }}>
          {ControlsCheckBox('HS Ref', tcIsHs, setTcIsHs)}
          {ControlsCheckBox('Depth Labels', tcShowDepthLabels, setTcShowDepthLabels)}
          {ControlsCheckBox('Well Names', tcShowWellNames, setTcShowWellNames)}
          <FormControlLabel
            control={
              <TextField
                value={tvdSliceMin}
                variant='standard'
                onChange={(e) => {
                  let newVal = e.target.value
                  setTvdSliceMin(newVal)
                }}
                name='TVD Slice'
                sx={{ width: 50, height: 20 }}
                inputProps={{ sx: { textAlign: 'center', padding: '0px' } }}
              />
            }
            label={<Typography sx={{ color: '#A0A0A0' }}>TVD Min</Typography>}></FormControlLabel>
          <FormControlLabel
            control={
              <TextField
                value={tvdSliceMax}
                variant='standard'
                onChange={(e) => {
                  let newVal = e.target.value
                  setTvdSliceMax(newVal)
                }}
                name='TVD Slice'
                sx={{ width: 50, height: 20 }}
                inputProps={{ sx: { textAlign: 'center', padding: 0 } }}
              />
            }
            label={<Typography sx={{ color: '#A0A0A0' }}>TVD Max</Typography>}></FormControlLabel>
        </FormGroup>
      </Box>
    </Box>
  )
}

export default TravellingCylinderChart
