import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { Box, Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import DmsEditor from '../../common/CellEditors/DmsEditor'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { cloneDeep } from 'lodash'
import { isEqual } from 'lodash'
import { CustomLoadingOverlay ,getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CoordinatesGrid = ({
  facilityName = null,
  slotName = null,
  slotsRef = [],
  coords = { latDeg: 0, longDeg: 0, gridNs: 0, gridEw: 0, localNs: 0, localEw: 0, gridConv: 0 },
  options = { dms: true, deg: true, grid: true, local: false, slot: false, input: 'deg' },
  crs = null,
  updateData = null,
  resetCoordinates,
  targetParentData = null,
  localCoordOffset = null,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getUnitsText } = useUnits()
  const curInput = useRef(options.input)
  const [isFetching, setFetching] = useState(false)
  const [isConverting, setConverting] = useState(false)
  const isConvertingRef = useRef(false)
  const coordsRef = useRef(coords)
  const localCoordOffsetRef = useRef(localCoordOffset ? localCoordOffset : { localNorth: 0, localEast: 0 })
  const gridConvRow = useRef({
    uid: 'gridConv',
    label1: 'Grid Conv',
    value1: coords?.gridConv,
    units: '',
    label2: '',
    value2: 0,
  })
  const crsUnitsRef = useRef('ft')
  const crsRef = useRef(crs)
  const facilityNameRef = useRef(facilityName)
  const targetParentDataRef = useRef(targetParentData)
  const { getAgGridTheme } = useInnovaTheme()

  const getCrsUnits = useInnovaAxios({
    url: '/well/getCrsUnits',
  })

  const latLongToGrid = useInnovaAxios({
    url: '/coordinateConv/latLongToGrid',
  })

  const gridToLatLong = useInnovaAxios({
    url: '/coordinateConv/gridToLatLong',
  })

  const wellCoordConv = useInnovaAxios({
    url: '/coordinateConv/wellCoordConversion',
  })

  const targetCoordConv = useInnovaAxios({
    url: '/coordinateConv/targetCoordConversion',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!localCoordOffset) {
      localCoordOffsetRef.current = { localNorth: 0, localEast: 0 }
      return
    }

    localCoordOffsetRef.current = cloneDeep(localCoordOffset)
  }, [localCoordOffset])

  const getGridData = useCallback(() => {
    if (!gridApi.current) return
    if (!coords) {
      gridApi.current.setGridOption('rowData',[])
      return
    }

    let offsetNs = 0
    let offsetEw = 0
    if (localCoordOffsetRef?.current) {
      offsetNs = localCoordOffsetRef.current.localNorth
      offsetEw = localCoordOffsetRef.current.localEast
    }

    let gridData = []
    if (options.local) {
      gridData.push({
        uid: 'local',
        label1: 'Local N',
        value1: Math.abs(coords.localNs + offsetNs) < 0.0001 ? 0 : coords.localNs + offsetNs,
        units: getUnitsText(UNITS_FOR.Depth),
        label2: 'Local E',
        value2: Math.abs(coords.localEw + offsetEw) < 0.0001 ? 0 : coords.localEw + offsetEw,
        min: -100000000,
        max: 100000000,
        precision: 3,
      })
    }

    if (options.deg) {
      gridData.push({
        uid: 'deg',
        label1: 'Latitude',
        value1: coords.latDeg,
        units: 'Deg',
        label2: 'Longitude',
        value2: coords.longDeg,
        min: -180,
        max: 180,
        precision: 10,
      })
    }

    if (options.deg) {
      gridData.push({
        uid: 'dms',
        label1: 'Latitude',
        value1: decToSexa(coords.latDeg, 'lat'),
        units: 'DMS',
        label2: 'Longitude',
        value2: decToSexa(coords.longDeg, 'lng'),
        value3: coords.latDeg,
        value4: coords.longDeg,
      })
    }

    if (options.grid) {
      gridData.push({
        uid: 'grid',
        label1: 'Grid North',
        value1: coords.gridNs,
        units: crsUnitsRef.current,
        label2: 'Grid East',
        value2: coords.gridEw,
        min: -100000000,
        max: 100000000,
        precision: 3,
      })
    }

    if (options.slot) {
      gridData.push({
        uid: 'slot',
        label1: 'Slot',
        value1: slotName,
        units: '',
        label2: '',
        value2: '',
        min: 0,
        max: 0,
        precision: 0,
      })
    }

    gridApi.current.setGridOption('rowData',gridData)
    gridConvRow.current = {
      uid: 'gridConv',
      label1: 'Grid Conv',
      value1: coords.gridConv,
      units: '',
      label2: '',
      value2: '',
    }

    gridApi.current.setGridOption('pinnedBottomRowData',[gridConvRow.current])
  }, [getUnitsText, coords, options, slotName])

  useEffect(() => {
    if (resetCoordinates.current) {
      resetCoordinates.current = false
      getGridData()
    }
  }, [getGridData, resetCoordinates])

  const fetchCrsUnits = async () => {
    if (isFetching) return
    if (!crs) return
    if (typeof crs !== 'string') return
    if (crs === '') return

    setFetching(true)
    const res = await getCrsUnits({ crsName: crs })

    if (!_isMounted.current) return
    if (res?.error) return
    setFetching(false)
    crsUnitsRef.current = res.data

    if (!gridApi.current) return

    let dataToUpdate = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data?.uid === 'grid') {
        dataToUpdate.push({ ...node.data })
      }
    })

    if (dataToUpdate.length > 0 && gridApi.current.getDisplayedRowCount() > 0) {
      dataToUpdate[0].units = crsUnitsRef.current
      gridApi.current.applyTransaction({ update: dataToUpdate })
    }
  }

  const convertWellCoords = useCallback(
    async (coords) => {
      if (isConvertingRef.current) return null
      if (!facilityNameRef.current) return null
      if (typeof facilityNameRef.current !== 'string') return null
      if (facilityNameRef.current === '') return null
      if (!coords) return null

      let inputMeth = 'LOCAL'
      if (curInput.current === 'deg' || curInput.current === 'dms') inputMeth = 'LATLONG'
      if (curInput.current === 'grid') inputMeth = 'GRID'

      setConverting(true)
      isConvertingRef.current = true
      const res = await wellCoordConv({
        facilityName: facilityNameRef.current,
        inputMeth: inputMeth,
        lat: coords.latDeg,
        long: coords.longDeg,
        north: coords.gridNorth,
        east: coords.gridEast,
        localNorth: coords.localNs - localCoordOffsetRef.current?.localNorth,
        localEast: coords.localEw - localCoordOffsetRef.current?.localEast,
      })
      isConvertingRef.current = false

      if (!_isMounted.current) return null
      setConverting(false)

      if (res?.error) return null
      if (!res.data) return null
      return res.data
    },
    [wellCoordConv],
  )

  const convertTargetCoords = useCallback(
    async (coords) => {
      if (isConvertingRef.current) return null
      if (!targetParentDataRef.current) return null
      if (!coords) return null

      let inputMeth = 'LOCAL'
      if (curInput.current === 'deg' || curInput.current === 'dms') inputMeth = 'LATLONG'
      if (curInput.current === 'grid') inputMeth = 'GRID'

      setConverting(true)
      isConvertingRef.current = true
      const res = await targetCoordConv({
        field: targetParentDataRef.current.field,
        facility: targetParentDataRef.current.facility,
        well: targetParentDataRef.current.well,
        actualWell: targetParentDataRef.current.actual,
        plan: targetParentDataRef.current.plan,
        inputMeth: inputMeth,
        lat: coords.latDeg,
        long: coords.longDeg,
        north: coords.gridNorth,
        east: coords.gridEast,
        localNorth: coords.localNs - localCoordOffsetRef.current?.localNorth,
        localEast: coords.localEw - localCoordOffsetRef.current?.localEast,
      })
      isConvertingRef.current = false

      if (!_isMounted.current) return null
      setConverting(false)

      if (res?.error) return null
      if (!res.data) return null
      return res.data
    },
    [targetCoordConv],
  )

  const convertLatLongToGrid = useCallback(
    async (coords) => {
      if (isConvertingRef.current) return null
      if (!crsRef.current) return null
      if (typeof crsRef.current !== 'string') return null
      if (crsRef.current === '') return null
      if (!coords) return null

      setConverting(true)
      isConvertingRef.current = true
      const res = await latLongToGrid({ crsName: crsRef.current, lat: coords.latDeg, long: coords.longDeg })
      isConvertingRef.current = false

      if (!_isMounted.current) return null
      setConverting(false)

      if (res?.error) return null
      if (!res.data) return null
      return res.data
    },
    [latLongToGrid],
  )

  const convertGridToLatLong = useCallback(
    async (coords) => {
      if (isConvertingRef.current) return null
      if (!crsRef.current) return null
      if (typeof crsRef.current !== 'string') return null
      if (crsRef.current === '') return null
      if (!coords) return null

      setConverting(true)
      isConvertingRef.current = true
      const res = await gridToLatLong({ crsName: crsRef.current, north: coords.gridNorth, east: coords.gridEast })
      isConvertingRef.current = false

      if (!_isMounted.current) return null
      setConverting(false)

      if (res?.error) return null
      if (!res.data) return null
      return res.data
    },
    [gridToLatLong],
  )

  useEffect(() => {
    fetchCrsUnits()
    crsRef.current = crs
    handleUpdate()
  }, [crs]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    facilityNameRef.current = facilityName
  }, [facilityName])

  useEffect(() => {
    if (!isEqual(coords, coordsRef.current)) {
      coordsRef.current = coords
      getGridData()
    }
  }, [coords]) // eslint-disable-line react-hooks/exhaustive-deps

  const decToSexa = (decVal, coordType) => {
    const positiveVal = Math.abs(decVal)
    let degrees = Math.trunc(positiveVal)
    let minutes = Math.trunc((positiveVal - degrees) * 60)
    let seconds = positiveVal * 3600.0 - minutes * 60.0 - degrees * 3600.0

    if (Math.abs(60.0 - seconds) < 0.0001) {
      seconds = 0
      minutes++
    }

    if (Math.abs(60.0 - minutes) < 0.0001) {
      minutes = 0
      degrees++
    }

    let dms = `${degrees.toFixed(0)}° ${minutes.toFixed(0)}' ${seconds.toFixed(3)}"`

    if (coordType === 'lat') {
      if (decVal < 0.0) {
        dms += 'S'
      } else {
        dms += 'N'
      }
    } else {
      if (decVal < 0.0) {
        dms += 'W'
      } else {
        dms += 'E'
      }
    }

    return dms
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
    getGridData()
  }

  const getUpdateData = () => {
    let output = {
      latDeg: 0,
      longDeg: 0,
      gridNs: 0,
      gridEw: 0,
      localNs: 0,
      localEw: 0,
      gridConv: gridConvRow.current.value1,
      inputMeth: curInput.current,
      slotName: '',
    }

    if (!gridApi.current) return output
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data?.uid === 'grid') {
        output.gridNs = node.data.value1
        output.gridEw = node.data.value2
      }

      if (node.data?.uid === 'deg') {
        output.latDeg = node.data.value1
        output.longDeg = node.data.value2
      }

      if (node.data?.uid === 'local') {
        output.localNs = node.data.value1
        output.localEw = node.data.value2
      }

      if (node.data?.uid === 'slot' && curInput.current === 'slot') {
        output.slotName = node.data.value1
      }
    })

    return output
  }

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (selectedRows.length > 0) {
      curInput.current = selectedRows[0].data.uid
    }

    let selectedRowValid = true
    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      selectedRowValid = false
    }

    if (Array.isArray(selectedRows) && selectedRows.length > 0) {
      if (
        selectedRows[0].data?.uid === 'slot' &&
        (!Array.isArray(slotsRef.current) || slotsRef.current?.length === 0)
      ) {
        selectedRowValid = false
      }
    }

    if (!selectedRowValid) {
      gridApi.current.forEachNode((node) => {
        if (node?.rowPinned === 'bottom') return
        node.setSelected(node.data.uid === curInput.current)
      })
    }

    if (curInput.current !== 'slot') {
      let slotData = null
      gridApi.current.forEachNode((node) => {
        if (node?.rowPinned === 'bottom') return
        if (node.data.uid !== 'slot') return
        slotData = { ...node.data }
        slotData.value1 = ''
      })

      if (slotData) {
        gridApi.current.applyTransaction({ update: [slotData] })
      }
    }

    if (updateData) updateData(getUpdateData())
  }

  const gridOptions = {
    pinnedBottomRowData: [gridConvRow.current],
    loadingOverlayComponent: CustomLoadingOverlay,
    onCellEditingStopped: (event) => {
      handleUpdate()
    },
    onSelectionChanged: onSelectionChanged,
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell])

  const setInitialSelection = () => {
    if (!gridApi.current) return

    gridApi.current.forEachNode((node) => {
      if (node?.rowPinned === 'bottom') return
      node.setSelected(node.data.uid === options.input)
    })
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
    setInitialSelection()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const dmsToDeg = (inputCoord) => {
    if (!inputCoord) return 0
    if (typeof inputCoord !== 'string') return 0
    if (inputCoord === '') return 0

    inputCoord.replace('°', '')
    inputCoord.replace("'", '')

    const parts = inputCoord.split(' ')

    if (parts.length !== 3) {
      return 0
    }

    const degrees = parseFloat(parts[0])
    const minutes = parseFloat(parts[1])

    const secondsParts = parts[2].split('"')
    if (secondsParts.length !== 2) return 0
    const seconds = parseFloat(secondsParts[0])

    if (isNaN(degrees) || isNaN(minutes) || isNaN(seconds)) {
      return 0
    }

    let decDeg = degrees + minutes / 60 + seconds / 3600
    if (secondsParts[1] === 'S' || secondsParts[1] === 'W') decDeg *= -1

    return decDeg
  }

  const handleUpdate = useCallback(async () => {
    let gridData = []
    if (!gridApi.current) return
    gridApi.current.forEachNode((node) => {
      if (node?.rowPinned === 'bottom') return
      if (node.data) gridData.push({ ...node.data })
    })

    let payload = null
    if (curInput.current === 'deg') {
      let degIndex = gridData.findIndex((item) => item.uid === 'deg')
      if (degIndex < 0) return

      payload = {
        latDeg: gridData[degIndex].value1,
        longDeg: gridData[degIndex].value2,
      }
    }

    if (curInput.current === 'dms') {
      let dmsIndex = gridData.findIndex((item) => item.uid === 'dms')
      if (dmsIndex < 0) return

      payload = {
        latDeg: dmsToDeg(gridData[dmsIndex].value1),
        longDeg: dmsToDeg(gridData[dmsIndex].value2),
      }
    }

    if (curInput.current === 'grid') {
      let gridIndex = gridData.findIndex((item) => item.uid === 'grid')
      if (gridIndex < 0) return

      payload = {
        gridNorth: gridData[gridIndex].value1,
        gridEast: gridData[gridIndex].value2,
      }
    }

    if (curInput.current === 'local') {
      let localIndex = gridData.findIndex((item) => item.uid === 'local')
      if (localIndex < 0) return

      payload = {
        localNs: gridData[localIndex].value1,
        localEw: gridData[localIndex].value2,
      }
    }

    if (curInput.current === 'slot') {
      let slotIndex = gridData.findIndex((item) => item.uid === 'slot')
      if (slotIndex < 0) return

      let selectedSlotName = gridData[slotIndex].value1
      let selectedSlot = slotsRef.current.find((item) => item.slotName === selectedSlotName)
      if (!selectedSlot) return

      payload = {
        localNs: selectedSlot.slotPosition.localNorth,
        localEw: selectedSlot.slotPosition.localEast,
      }
    }

    if (!payload) return

    let newCoords = null
    if (facilityNameRef.current === null && targetParentDataRef.current === null) {
      newCoords =
        curInput.current !== 'grid' ? await convertLatLongToGrid(payload) : await convertGridToLatLong(payload)
    }

    if (facilityNameRef.current !== null && targetParentDataRef.current === null) {
      newCoords = await convertWellCoords(payload)
    }

    if (targetParentDataRef.current !== null) {
      newCoords = await convertTargetCoords(payload)
    }

    if (!newCoords) return

    let slotIndex = gridData.findIndex((item) => item.uid === 'slot')
    if (slotIndex >= 0 && curInput.current !== 'slot') {
      gridData[slotIndex].value1 = ''
    }

    let gridIndex = gridData.findIndex((item) => item.uid === 'grid')
    if (gridIndex >= 0) {
      gridData[gridIndex].value1 = newCoords.gridNorth
      gridData[gridIndex].value2 = newCoords.gridEast
    }

    let dmsIndex = gridData.findIndex((item) => item.uid === 'dms')
    if (dmsIndex >= 0) {
      gridData[dmsIndex].value1 = decToSexa(newCoords.latitude, 'lat')
      gridData[dmsIndex].value2 = decToSexa(newCoords.longitude, 'lng')
      gridData[dmsIndex].value3 = newCoords.latitude
      gridData[dmsIndex].value4 = newCoords.longitude
    }

    let degIndex = gridData.findIndex((item) => item.uid === 'deg')
    if (degIndex >= 0) {
      gridData[degIndex].value1 = newCoords.latitude
      gridData[degIndex].value2 = newCoords.longitude
    }

    let localIndex = gridData.findIndex((item) => item.uid === 'local')
    if (localIndex >= 0) {
      gridData[localIndex].value1 = newCoords.localNorth
      gridData[localIndex].value2 = newCoords.localEast
    }

    if (gridApi.current.getDisplayedRowCount() > 0) {
      gridApi.current.applyTransaction({ update: cloneDeep(gridData) })
    }

    let output = {
      latDeg: 0,
      longDeg: 0,
      gridNs: 0,
      gridEw: 0,
      localNs: 0,
      localEw: 0,
      gridConv: newCoords.gridConvergence,
      inputMeth: curInput.current,
      slotName: '',
    }

    gridData.forEach((node) => {
      if (node.uid === 'grid') {
        output.gridNs = node.value1
        output.gridEw = node.value2
      }

      if (node.uid === 'deg') {
        output.latDeg = node.value1
        output.longDeg = node.value2
      }

      if (node.uid === 'local') {
        output.localNs = node.value1
        output.localEw = node.value2
      }

      if (node.uid === 'slot' && curInput.current === 'slot') {
        output.slotName = node.value1
      }
    })

    if (updateData) updateData(output)

    gridConvRow.current = {
      uid: 'gridConv',
      label1: 'Grid Conv',
      value1: newCoords.gridConvergence,
      units: '',
      label2: '',
      value2: '',
    }

    gridApi.current.setGridOption('pinnedBottomRowData',[gridConvRow.current])
  }, [slotsRef, convertTargetCoords, convertWellCoords, convertLatLongToGrid, convertGridToLatLong, updateData])

  let columnDefs = useMemo(
    () => [
      {
        colId: 'selected',
        checkboxSelection: true,
        editable: (params) => false,
      },
      {
        field: 'label1',
        colId: 'label1',
        editable: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
      },
      {
        field: 'value1',
        colId: 'value1',
        cellDataType: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
        editable: (params) => params.node.selected && params.node?.rowPinned !== 'bottom',
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') {
            return `${numberWithCommasDecimals(gridConvRow.current.value1, 3)}`
          }

          if (params?.data?.uid === 'dms' || params?.data?.uid === 'slot') {
            return params.value
          }

          return `${numberWithCommasDecimals(params.value, params.data?.precision)}`
        },
        cellEditorSelector: (params) => {
          if (params.data?.uid === 'dms') {
            return {
              component: DmsEditor,
              params: {
                degVal: params.data?.value3,
                coordType: 'lat',
              },
              popup: true,
            }
          }

          if (params.data?.uid === 'slot') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: Array.isArray(slotsRef.current) ? slotsRef.current.map((item) => item.slotName) : [],
              },
            }
          }

          if (params.data?.uid !== 'dms') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: params?.data?.min ? params?.data?.min : -1000000,
                max: params?.data?.max ? params?.data?.max : 1000000,
                precision: params?.data?.precision ? params?.data?.precision : 2,
              },
            }
          }

          return undefined
        },
        suppressKeyboardEvent: (params) => {
          if (params.data?.uid !== 'dms') return false
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46
          let isTabKey = params.event.keyCode === 9
          let isEnterKey = params.event.keyCode === 13

          const gridShouldDoNothing = params.editing && (isTabKey || isEnterKey || isBackspaceKey || isDeleteKey)
          return gridShouldDoNothing
        },
      },
      {
        field: 'units',
        colId: 'units1',
        editable: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'start' },
      },
      {
        field: 'label2',
        colId: 'label2',
        editable: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
      },
      {
        field: 'value2',
        colId: 'value2',
        cellDataType: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
        editable: (params) =>
          params.node.selected && params.node?.rowPinned !== 'bottom' && params?.data?.uid !== 'slot',
        valueFormatter: (params) => {
          if (params.data?.uid === 'dms') {
            return params.value
          }
          return `${numberWithCommasDecimals(params.value, params.data?.precision)}`
        },
        cellEditorSelector: (params) => {
          if (params.data?.uid === 'dms') {
            return {
              component: DmsEditor,
              params: {
                degVal: params.data?.value4,
                coordType: 'long',
              },
              popup: true,
            }
          }

          if (params.data?.uid !== 'dms') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: params?.data?.min ? params?.data?.min : -1000000,
                max: params?.data?.max ? params?.data?.max : 1000000,
                precision: params?.data?.precision ? params?.data?.precision : 2,
              },
            }
          }

          return undefined
        },
        suppressKeyboardEvent: (params) => {
          if (params.data?.uid !== 'dms') return false
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46
          let isTabKey = params.event.keyCode === 9
          let isEnterKey = params.event.keyCode === 13

          const gridShouldDoNothing = params.editing && (isTabKey || isEnterKey || isBackspaceKey || isDeleteKey)
          return gridShouldDoNothing
        },
      },
      {
        field: 'units',
        colId: 'units2',
        editable: false,
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'start' },
      },
    ],
    [slotsRef],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: '100%',
        marginLeft: 0,
        width: '100%',
        maxWidth: `100%`,
        maxHeight: `100%`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}></Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            
            loading={isConverting}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={0}
            onGridReady={onGridReady}
            getRowId={getRowId}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}></Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default CoordinatesGrid
