import React, { useRef, useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, CircularProgress, Tooltip } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { appColors } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { cloneDeep } from 'lodash'
import { getDailyActivityColor } from 'utils/colorFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { saveItemToLS } from 'utils/localStorage'

const centerAlignCell = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const SummaryGrid = ({ data }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const defaultColDef = {
    filter: null,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
  }

  const HorzBarChartRenderer = (params) => {
    let total = params.data?.value.length > 1 ? params.data?.value[0].value + params.data?.value[1].value : 0
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px',
          width: `calc(${params.column.actualWidth}px-20px)`,
          height: 25,
          border: '1px solid gray',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {params.data?.value.map((val, idx) => {
          let widthPercentage = val.value / total
          let widthPixelCount = widthPercentage * params.column.actualWidth
          return (
            <Tooltip
              key={`${idx}`}
              title={`${val.label} - ${numberWithCommasDecimals(val.value, 2)}`}
              placement='bottom'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <Box
                sx={{
                  width: widthPixelCount,
                  height: '100%',
                  backgroundColor: `${val.color}`,
                  borderTopLeftRadius: idx === 0 ? '1px' : '0px',
                  borderBottomLeftRadius: idx === 0 ? '1px' : '0px',
                }}></Box>
            </Tooltip>
          )
        })}
      </Box>
    )
  }

  const colDefs = [
    { field: 'label' },
    {
      field: 'value',
      cellRendererSelector: (params) => {
        if (!Array.isArray(params.data?.value)) return { component: null }
        return { component: HorzBarChartRenderer }
      },
      valueFormatter: (params) => {
        if (!Array.isArray(params.data?.value)) return params.value
        return ''
      },
      minWidth: 400,
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={data}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        gridOptions={{
          headerHeight: 0,
        }}
      />
    </div>
  )
}

const SurveyGrid = ({ data }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const defaultColDef = {
    filter: null,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
  }

  const colDefs = [
    {
      field: 'md',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.data?.md, 2)
      },
    },
    {
      field: 'inc',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.data?.inc, 2)
      },
    },
    {
      field: 'azi',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.data?.azi, 2)
      },
    },
    {
      field: 'tvd',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.data?.tvd, 2)
      },
    },
    {
      field: 'dls',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.data?.dls, 2)
      },
    },
    {
      field: 'slideSeen',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return params.data?.slideSeen > 0 ? numberWithCommasDecimals(params.data?.slideSeen, 2) : ''
      },
    },
    {
      field: 'slideRemaining',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return params.data?.slideRemaining > 0 ? numberWithCommasDecimals(params.data?.slideRemaining, 2) : ''
      },
    },
    {
      field: 'motorYield',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return params.data?.motorYield > 0 ? numberWithCommasDecimals(params.data?.motorYield, 2) : ''
      },
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={data}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        headerHeight={30}
      />
    </div>
  )
}

const PersonnelGrid = ({ data }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const defaultColDef = {
    filter: null,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
  }

  const colDefs = [
    {
      field: 'position',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    {
      field: 'name',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={data}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        headerHeight={30}
      />
    </div>
  )
}

const BhaGrid = ({ data }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnits()

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const ropColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const getHighestValue = (first, second) => {
    let largestAmount = 0
    if (data?.length <= 0) return 1
    data.forEach((item) => {
      if (item[first] > largestAmount) {
        largestAmount = item[first]
      }
      if (item[second] > largestAmount) {
        largestAmount = item[second]
      }
    })

    return largestAmount
  }

  const defaultColDef = {
    filter: null,
    sortable: false,
    editable: false,
    resizable: true,
    autoHeight: true,
  }

  const colDefs = [
    { headerName: 'BHA#', field: 'bhaNumRep', cellStyle: centerAlignCell, width: 100 },
    {
      headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'footage',
      autoHeight: true,
      sortable: false,
      width: 140,
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          type: 'bar',
          paddingOuter: 0.25,
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],

          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide', params.data?.slideDrilled],
          ['Rotate', params.data?.rotateDrilled],
        ]
      },
    },
    {
      headerName: 'Hours',
      colId: 'hours',
      autoHeight: true,
      sortable: false,
      width: 140,
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideHours', 'rotateHours')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide Hrs', params.data?.slideHrs],
          ['Rotate Hrs', params.data?.rotateHrs],
        ]
      },
    },
    {
      headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
      colId: 'rop',
      autoHeight: true,
      sortable: false,
      width: 140,
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: ropColumnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideRop', 'rotateRop')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide ROP', params.data?.slideRop],
          ['Rotate ROP', params.data?.rotateRop],
          ['Avg ROP', params.data?.avgRop],
        ]
      },
    },
  ]

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowData={data}
        onGridReady={onGridReady}
        headerHeight={30}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

const WellInfo = ({ data, node, api }) => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [wellInfo, setWellInfo] = useState(null)
  const [filteredData, setFilteredData] = useState([])

  const getWellInfo = useInnovaAxios({
    url: '/well/summary',
  })

  const fetchWellInfo = async () => {
    if (isLoading) return
    if (!data) return
    if (!data.hasOwnProperty('actualWell')) return
    if (typeof data.actualWell !== 'string') return
    if (data.actualWell === '') return

    if (_isMounted.current) setIsLoading(true)
    const response = await getWellInfo({ wellName: data.actualWell })
    if (_isMounted.current) {
      setIsLoading(false)
      setWellInfo(response?.data ? response?.data : null)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    fetchWellInfo()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filteredData.length > 0) {
      saveItemToLS('surveysFilteredData', 'filteredData', cloneDeep(filteredData))
    }
  }, [filteredData])

  const createPersonnelData = () => {
    if (!wellInfo) return []
    if (!wellInfo.hasOwnProperty('dayDD')) return []

    return [
      { position: 'Day DD', name: wellInfo.dayDD },
      { position: 'Night DD', name: wellInfo.nightDD },
      { position: 'Day MWD', name: wellInfo.dayMwd },
      { position: 'Night MWD', name: wellInfo.nightMwd },
    ]
  }

  const createSummaryData = () => {
    return [
      {
        label: 'Dist to TD',
        value: [
          { color: 'green', label: 'Drilled', value: wellInfo?.currentDepth ? parseFloat(wellInfo?.currentDepth) : 0 },
          {
            color: 'red',
            label: 'Un-Drilled',
            value: wellInfo?.maxPlanDepth ? wellInfo?.maxPlanDepth - wellInfo?.currentDepth : 0,
          },
        ],
      },
      {
        label: 'Activity',
        value: Array.isArray(wellInfo?.dailyActivitySummary)
          ? wellInfo?.dailyActivitySummary.map((activity, index) => {
              return {
                color: getDailyActivityColor(index, activity.code),
                value: activity.totalHours,
                label: `${activity.code}: ${activity.description}`,
              }
            })
          : [],
      },
      { label: '24Hr Summary', value: wellInfo?.summary },
      { label: '24Hr Lookahead', value: wellInfo?.lookAhead },
      {
        label: '24Hr Footage',
        value: numberWithCommasDecimals(wellInfo?.twentyFourHrFootage ? wellInfo?.twentyFourHrFootage : 0, 2),
      },
    ]
  }

  const getTopGridHeight = () => {
    let summaryGridHeight = 128 //3 Rows no header
    let bhaGridHeight = 50 + (Array.isArray(wellInfo?.bhaData) ? wellInfo?.bhaData.length + 1 : 0) * 41.33
    return bhaGridHeight > summaryGridHeight ? bhaGridHeight : summaryGridHeight
  }

  const getBhaData = (data) => {
    if (!Array.isArray(data)) return []
    let dataCopy = cloneDeep(data)

    for (let i = 0; i < dataCopy.length; i++) {
      dataCopy[i].slideRop = dataCopy[i].slideHrs > 0 ? dataCopy[i].slideDrilled / dataCopy[i].slideHrs : 0
      dataCopy[i].rotateRop = dataCopy[i].rotateHrs > 0 ? dataCopy[i].rotateDrilled / dataCopy[i].rotateHrs : 0
      dataCopy[i].avgRop =
        dataCopy[i].totalDrillingHrs > 0 ? dataCopy[i].totalDrilled / dataCopy[i].totalDrillingHrs : 0
    }

    return dataCopy
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: `${getTopGridHeight()}px`,
        }}>
        <BhaGrid data={getBhaData(wellInfo?.bhaData)} />
        <SummaryGrid data={createSummaryData()} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '340px',
        }}>
        <SurveyGrid data={Array.isArray(wellInfo?.surveys) ? wellInfo?.surveys : []} setFilteredData ={setFilteredData} />
        <PersonnelGrid data={createPersonnelData()} />
      </Box>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
    </Box>
  )
}

export default WellInfo
