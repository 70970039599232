import React from 'react'
import { SpeedDialAction } from '@mui/material'
import { styled } from '@mui/styles'
import SettingsIcon from '@mui/icons-material/Settings'
import { Icon as Iconify } from '@iconify/react'
import SpeedDial from '@mui/material/SpeedDial'
import { Box } from '@mui/system'
import casingRed from 'assets/wellScreenIcons/schematicRed.png'
import casingGreen from 'assets/wellScreenIcons/schematicGreen.png'

const PositionedBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(5.5),
  right: theme.spacing(3),
}))

const SectionViewPrefs = ({ showStatusPrefs, displaySettings, setDisplaySettings }) => {
  const updateDisplaySettings = (status) => () => {
    let newSettings = { ...displaySettings }
    if (!newSettings.hasOwnProperty(status)) return
    newSettings[status] = !newSettings[status]
    setDisplaySettings(newSettings)
  }

  const statusActions = [
    {
      icon: (
        <Iconify icon='gis:offset' style={{ color: displaySettings.showOffsets ? 'green' : 'red', fontSize: 25 }} />
      ),
      name: 'Offset Wells',
      onClick: updateDisplaySettings('showOffsets'),
    },
    {
      icon: (
        <Iconify
          icon='material-symbols:target'
          style={{ color: displaySettings.showTargets ? 'green' : 'red', fontSize: 25 }}
        />
      ),
      name: 'Targets',
      onClick: updateDisplaySettings('showTargets'),
    },
    {
      icon: (
        <Iconify
          icon='ph:target'
          style={{ color: displaySettings.showDrillersTargets ? 'green' : 'red', fontSize: 25 }}
        />
      ),
      name: 'Driller Targets',
      onClick: updateDisplaySettings('showDrillersTargets'),
    },
    {
      icon: (
        <img
          src={displaySettings.showCasing ? casingGreen : casingRed}
          alt='Casing Icon'
          style={{ width: 25, height: 25 }}
        />
      ),
      name: 'Casing',
      onClick: updateDisplaySettings('showCasing'),
    },
    {
      icon: (
        <Iconify
          icon='mdi:tooltip-edit-outline'
          style={{ color: displaySettings.showAnnotations ? 'green' : 'red', fontSize: 25 }}
        />
      ),
      name: 'Annotations',
      onClick: updateDisplaySettings('showAnnotations'),
    },
    {
      icon: (
        <Iconify
          icon='ic:baseline-area-chart'
          style={{ color: displaySettings.showGeosteering ? 'green' : 'red', fontSize: 25 }}
        />
      ),
      name: 'Geosteering',
      onClick: updateDisplaySettings('showGeosteering'),
    },
    {
      icon: (
        <Iconify
          icon='icon-park-outline:five-ellipses'
          style={{ color: displaySettings?.showErrorEllipses ? 'green' : 'red', fontSize: 25 }}
        />
      ),
      name: 'Error Ellipses',
      onClick: updateDisplaySettings('showErrorEllipses'),
    },
  ]

  return (
    <React.Fragment>
      <PositionedBox>
        {showStatusPrefs ? (
          <SpeedDial
            ariaLabel='Status Preferences'
            icon={<SettingsIcon style={{ color: '#ffffff' }} />}
            direction={'left'}
            hidden={false}
            FabProps={{ size: 'small' }}>
            {statusActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement={'bottom'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                      transition: '.5s',
                      '&:hover': {
                        dropShadow: ' 5px 5px 10px 10px grey',
                        color: 'white',
                      },
                    },
                  },
                }}
                onClick={() => action.onClick && action.onClick()}
              />
            ))}
          </SpeedDial>
        ) : null}
      </PositionedBox>
    </React.Fragment>
  )
}

export default SectionViewPrefs
