import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { Box } from '@mui/material'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import DirectionalScoreCardChart from './DirectionalScoreCardChart'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const DirectionalScoreCardKpiGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  const getKpiData = useAxiosGzip({
    url: '/kpi/directionalScoreCardGz',
    timeout: 60000,
  })

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('directionalScoreCardKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.directionalScoreCardKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      gridApi.current.setGridOption('rowData', [])
      return
    }

    if (selectedWells.length < 1) {
      gridApi.current.setGridOption('rowData', [])
      return
    }

    setLoading(true)
    const res = await getKpiData({
      wellList: array2pipestr(selectedWells),
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) return
    gridApi.current.setGridOption('rowData', Array.isArray(res?.data) ? res.data : [])
  }

  const onXlsxExport = () => {
    if (!gridApi.current) return
    gridApi.current.exportDataAsExcel({
      fileName: 'directionalScoreCardKpi.xlsx',
      sheetName: 'DirectionalScoreCardKpi',
    })
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      sortable: true,
    }),
    [],
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(), 100)
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getScoreStyles = (score) => {
    if (typeof score !== 'string') return ''
    if (score === 'Excellent') return '#236533'
    if (score === 'Good') return '#ffa500'
    if (score === 'Not Good') return '#ff6347'
    if (score === 'Unacceptable') return '#8b0000'
    return ''
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Well',
        field: 'wellName',
        colId: 'wellName',
        cellStyle: { textAlign: 'start' },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        cellStyle: { textAlign: 'start' },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        cellStyle: { textAlign: 'start' },
      },
      {
        headerName: 'Job#',
        field: 'jobNum',
        colId: 'jobNum',
        cellStyle: { textAlign: 'start' },
      },
      {
        headerName: 'State',
        field: 'state',
        colId: 'state',
        cellStyle: { textAlign: 'start' },
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        cellStyle: { textAlign: 'start' },
      },
      {
        headerName: `Cost/${getUnitsText(UNITS_FOR.Depth)}`,
        field: 'costPerFt',
        colId: 'costPerFt',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
      },
      {
        headerName: `Total Cost (${getUnitsText(UNITS_FOR.Cost)})`,
        colId: 'totalCost',
        cellStyle: centerAlignCell,
        valueGetter: (params) => params.data?.totalCost,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `${getUnitsText(UNITS_FOR.Depth)}/Day`,
        colId: 'footagePerDay',
        cellStyle: centerAlignCell,
        valueGetter: (params) => params.data?.footage.footagePerDay,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
      },
      {
        headerName: `Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'totalDrilled',
        cellStyle: centerAlignCell,
        valueGetter: (params) => params.data?.footage.totalDrilled,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `BRT (days)`,
        colId: 'brtDays',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.footage?.bhaStats)) return 0
          let totalBrtHrs = params.data?.footage.bhaStats.reduce((acc, curr) => acc + curr.brtHrs, 0)
          return totalBrtHrs / 24
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `Vertical Tort`,
        colId: 'verticalTortuosityScore',
        cellStyle: (params) => {
          let style = { ...centerAlignCell }
          style.backgroundColor = getScoreStyles(params.value)
          style.color = 'white'
          return style
        },
        valueGetter: (params) => params.data?.verticalTortuosityScore?.score,
      },
      {
        headerName: `Tangent Tort`,
        colId: 'tangentTortuosityScore',
        cellStyle: (params) => {
          let style = { ...centerAlignCell }
          style.backgroundColor = getScoreStyles(params.value)
          style.color = 'white'
          return style
        },
        valueGetter: (params) => params.data?.tangentTortuosityScore?.score,
      },
      {
        headerName: `Curve Tort`,
        colId: 'curveQualityScore',
        cellStyle: (params) => {
          let style = { ...centerAlignCell }
          style.backgroundColor = getScoreStyles(params.value)
          style.color = 'white'
          return style
        },
        valueGetter: (params) => params.data?.curveQualityScore?.score,
      },
      {
        headerName: `Lateral Tort`,
        colId: 'lateralTortuosityScore',
        cellStyle: (params) => {
          let style = { ...centerAlignCell }
          style.backgroundColor = getScoreStyles(params.value)
          style.color = 'white'
          return style
        },
        valueGetter: (params) => params.data?.lateralTortuosityScore?.score,
      },
      {
        headerName: `Landing`,
        colId: 'landingScore',
        cellStyle: (params) => {
          let style = { ...centerAlignCell }
          style.backgroundColor = getScoreStyles(params.value)
          style.color = 'white'
          return style
        },
        valueGetter: (params) => params.data?.landingScore?.score,
      },
    ],
    [centerAlignCell, getUnitsText],
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('directionalScoreCardKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'directionalScoreCardKpi.xlsx',
            sheetName: 'DirectionalScoreCardKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const DetailCellRenderer = (params) => {
    return (
      <Box sx={{ width: '100%', height: 400, padding: '5px' }}>
        <DirectionalScoreCardChart wellData={params.data} getUnitsText={getUnitsText} />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'directionalScoreCardKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            masterDetail={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default DirectionalScoreCardKpiGrid
