import React, { useEffect, useRef, useMemo } from 'react'

import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import DataAcqHealthDetailsGrid from './DataAcqHealthDetailsGrid'
import './dataAcqHealthStyles.css'
import { appColors } from 'utils'
import { CustomLoadingOverlay } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DataAcqHealthGrid = ({ id, data, isLoading }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = () => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0px',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 50,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'Well Name',
        field: 'wellName',
        cellStyle: centerAlignCell,
        width: 400,
      },
    ],
    [centerAlignCell],
  )

  const icons = useMemo(() => {
    return {}
  }, [])

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const getElapsedCacheUpdateTime = (prevDate, currentDate) => {
    if (!isValidDate(prevDate) || !isValidDate(currentDate)) return '0s'
    let prevTimeMs = new Date(prevDate).getTime()
    let currentTimeMs = new Date(currentDate).getTime()
    return `${(currentTimeMs - prevTimeMs) / 1000}s`
  }

  const getCacheDetailsData = (input) => {
    const cacheNames = Object.keys(input)

    const maxDataPoints = Math.max(
      ...cacheNames.map((cacheName) => {
        const cache = input[cacheName]
        if (cache && cache?.witsData && Array.isArray(cache?.witsData)) {
          return cache.witsData.length
        }
        return 0
      }),
    )

    const result = []

    for (let i = 0; i < maxDataPoints; i++) {
      const dataPoint = {}

      for (const cacheName of cacheNames) {
        const cache = input[cacheName]
        if (cache && cache.witsData && Array.isArray(cache.witsData) && i < cache.witsData.length) {
          dataPoint[cacheName] = {
            id: i + 1,
            timeStamp: cache.witsData[i].timeStamp,
            elapsedTime: getElapsedCacheUpdateTime(cache.witsData[i - 1]?.timeStamp, cache.witsData[i]?.timeStamp),
          }
        } else {
          dataPoint[cacheName] = { timeStamp: '', elapsedTime: '' }
        }
      }

      result.push(dataPoint)
    }

    return result
  }

  const DetailCellRenderer = (params) => {
    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.7
    }

    const { rawCache, dec02Cache, dec05Cache, dec10Cache } = params.data

    return (
      <Box sx={{ height: height }}>
        <DataAcqHealthDetailsGrid
          id={id}
          data={getCacheDetailsData({ rawCache, dec02Cache, dec05Cache, dec10Cache })}
        />
      </Box>
    )
  }

  const getGridTitle = () => {
    if (!id || typeof id !== 'string') return 'Acq Data'
    if (id === 'wellReadCache') return 'Read Cache'
    return 'Write Cache'
  }

  const autoSizeStrategy = {
    type: 'fitCellContents',
  }

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <div
        style={{
          height: '35px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '18px',
          color: appColors.headerTextColor,
        }}>
        {getGridTitle()}
      </div>
      <AgGridReact
        autoSizeStrategy={autoSizeStrategy}
        loading={isLoading}
        rowData={Array.isArray(data[id]) ? data[id] : []}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        masterDetail={true}
        detailCellRenderer={DetailCellRenderer}
        detailRowAutoHeight={true}
        icons={icons}
        get
      />
    </div>
  )
}

export default DataAcqHealthGrid
