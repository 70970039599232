import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Box, Avatar, Tooltip, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { GetActivityColor } from 'components/common/activitySelector'
import { numberWithCommas } from 'utils/stringFunctions'
import { round } from 'utils/numberFunctions'
import { appColors } from 'utils'
import { Checkbox } from '@mui/material'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import useDrillString from 'components/common/hooks/useDrillString/useDrillString'
import { getComponentColor, getDrillStringIcon } from 'components/common/drillStringFunctions'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import SearchBar from 'components/common/SearchBar'
import DropDownPicker from 'components/common/DropDownPicker'
import {
  sortColDefs,
  CustomLoadingOverlay,
  isDateLessThan,
  dateComparator,
  isValidDate,
  getStringId,
} from 'components/common/AgGridUtils'
import { debounce } from 'lodash'
import MotorReportModal from 'components/WellPages/DrillStringPages/MotorReportModal/MotorReportModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const defaultColDef = {
  resizable: true,
  sortable: true,
  autoHeight: false,
  wrapText: false,
  editable: false,
}

const IncidentsGrid = ({ incidentData, setFilteredIncidentData, isLoading }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [dateFilter, setDateFilter] = useState('Last 6 Months')
  const selectedMotorRepData = useRef(null)
  const [searchText, setSearchText] = useState('')
  const { getUnitsText } = useUnits()
  const { fetchBhas } = useDrillString()
  const [resetCols, setResetCols] = useState(false)
  const [showMotorReport, setShowMotorReport] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, searchBarStyle, getChartBackColor } = useInnovaTheme()

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const getBhaData = async (wellName, bhaNum, successCallBack) => {
    let bhaData = await fetchBhas(wellName, bhaNum)
    let data = []
    if (Array.isArray(bhaData) && bhaData.length > 0) {
      data = bhaData[0].bhaComponents
    }

    successCallBack(data)
  }

  const editMotorReport = async (wellName, bhaNum) => {
    selectedMotorRepData.current = { bhaNum: bhaNum, wellName: wellName }
    setShowMotorReport(true)
  }

  useEffect(() => {
    autoSizeColumns()
  }, [incidentData]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [dateFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const dateFilterComparator = useCallback(dateComparator, [])

  const tooltipRenderer = (params) => {
    const { yValue, xValue } = params
    return `<div class='grid-sparkline-tooltip'>
                <div class='grid-sparkline-tooltip-title'>
                    <div>${xValue}: ${yValue.toFixed(2)}</div>
                </div>
            </div>`
  }

  const columnFormatter = (params) => {
    const { first } = params

    return {
      fill: first ? appColors.slideColor : appColors.rotateColor,
    }
  }

  const ropColumnFormatter = (params) => {
    const { first, last } = params

    return {
      fill: first ? appColors.slideColor : last ? appColors.avgColor : appColors.rotateColor,
    }
  }

  const getHighestValue = (first, second) => {
    let largestAmount = 0
    if (incidentData?.length <= 0) return 1

    let maxVal = 999999999
    if (first === 'slideRop' || second === 'slideRop') maxVal = 5000

    incidentData.forEach((item) => {
      if (item[first] > largestAmount && item[first] < maxVal) {
        largestAmount = item[first]
      }
      if (item[second] > largestAmount && item[second] < maxVal) {
        largestAmount = item[second]
      }
    })

    return largestAmount
  }

  const columnDefs = [
    {
      headerName: '',
      colId: 'motorRep',
      pinned: 'left',
      lockPosition: 'left',
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRenderer: (params) => {
        return (
          <Tooltip
            title='Edit motor report'
            placement='bottom'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <Box
              sx={{
                color: appColors.itemTextColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                margin: '5px',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={(event) => {
                event.stopPropagation()
                editMotorReport(params.data?.wellName, params.data?.bhaNum)
              }}>
              <Iconify width={'30px'} height={'30px'} icon='mdi:comment-edit-outline' />
            </Box>
          </Tooltip>
        )
      },
      width: 70,
      cellStyle: {
        justifyContent: 'center',
      },
    },
    {
      headerName: '',
      colId: 'bhaDetails',
      pinned: 'left',
      lockPosition: 'left',
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellRenderer: 'agGroupCellRenderer',
      width: 70,
      cellStyle: {
        justifyContent: 'center',
      },
    },
    {
      headerName: 'BHA#',
      colId: 'bhaNum',
      field: 'bhaNumRep',
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      maxWidth: 200,
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Operator',
      field: 'operator',
      colId: 'operator',
      pinned: 'left',
      lockPosition: 'left',
      minWidth: 200,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Well',
      field: 'wellName',
      colId: 'wellName',
      pinned: 'left',
      lockPosition: 'left',
      minWidth: 200,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },

    {
      headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'holeSize',
      field: 'holeSize',
      maxWidth: 200,
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => numberWithCommas(round(params.data?.holeSize, 3)),
    },
    {
      headerName: 'Job#',
      field: 'jobNum',
      colId: 'jobNum',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Rig',
      field: 'rig',
      colId: 'rig',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'State',
      field: 'state',
      colId: 'state',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'County',
      field: 'county',
      colId: 'county',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Status',
      field: 'wellStatus',
      colId: 'wellStatus',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: (params) => {
        return (
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: GetActivityColor(params.data?.wellStatus),
            }}>
            <i className='fa fa-check' aria-hidden='true'></i>
            {params.data?.wellStatus}
          </span>
        )
      },
    },
    {
      headerName: 'Run Start Date',
      field: 'bhaPickUpDateTime',
      colId: 'bhaPickUpDateTime',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
      minWidth: 120,
      valueFormatter: (params) => {
        if (!params.data?.bhaPickUpDateTime) return ''
        if (params.data?.bhaPickUpDateTime === '') return ''
        if (isDateLessThan(params.data?.bhaPickUpDateTime, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.bhaPickUpDateTime.replace('Z', ''))).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      },
    },
    {
      headerName: 'Run End Date',
      field: 'bhaLayDownDateTime',
      colId: 'bhaLayDownDateTime',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
      minWidth: 120,
      valueFormatter: (params) => {
        if (!params.data?.bhaLayDownDateTime) return ''
        if (typeof params.data?.bhaLayDownDateTime !== 'string') return ''
        if (params.data?.endDate === '') return ''
        if (isDateLessThan(params.data?.bhaLayDownDateTime, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.bhaLayDownDateTime.replace('Z', ''))).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      },
    },
    {
      headerName: 'BRT Hrs',
      field: 'brtHrs',
      colId: 'brtHrs',
      maxWidth: 200,
      valueFormatter: (params) => numberWithCommas(round(params.data?.brtHrs, 2)),
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Hours',
      colId: 'hours',
      autoHeight: true,
      sortable: false,
      width: 140,
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideHours', 'rotateHours')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide Hrs', params.data?.slideHrs],
          ['Rotate Hrs', params.data?.rotateHrs],
        ]
      },
    },
    {
      headerName: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'startDepth',
      field: 'startDepth',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => numberWithCommas(round(params.data?.startDepth, 2)),
    },
    {
      headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'endDepth',
      field: 'endDepth',
      cellStyle: centerAlignCell,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => numberWithCommas(round(params.data?.endDepth, 2)),
    },
    {
      headerName: `Drilled`,
      colId: 'drilled',
      field: 'totalDrilled',
      cellStyle: centerAlignCell,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => numberWithCommas(round(params.data?.totalDrilled, 2)),
    },
    {
      headerName: `Footage`,
      colId: 'footage',
      autoHeight: true,
      sortable: false,
      width: 140,
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          type: 'bar',
          paddingOuter: 0.25,
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: columnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideDrilled', 'rotateDrilled')],

          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        return [
          ['Slide', params.data?.slideDrilled],
          ['Rotate', params.data?.rotateDrilled],
        ]
      },
    },
    {
      headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
      colId: 'rop',
      autoHeight: true,
      sortable: false,
      width: 200,
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: 'agSparklineCellRenderer',
      cellRendererParams: {
        sparklineOptions: {
          paddingOuter: 0.5,
          type: 'bar',
          tooltip: {
            xOffset: 20,
            yOffset: -20,
            renderer: tooltipRenderer,
          },
          formatter: ropColumnFormatter,
          label: {
            enabled: true,
            placement: 'outsideEnd',
            color: 'white',
            fontSize: 9,
          },
          stroke: '#91cc75',
          highlightStyle: {
            fill: '#fac858',
          },
          valueAxisDomain: [0, getHighestValue('slideRop', 'rotateRop')],
          padding: {
            top: 0,
            bottom: 0,
            right: 30,
          },
          axis: {
            strokeWidth: 0,
          },
        },
      },
      valueGetter: (params) => {
        let tempArr = [
          ['Slide ROP', params.data?.slideRop],
          ['Rotate ROP', params.data?.rotateRop],
          ['Avg ROP', params.data?.avgRop],
        ]
        return tempArr
      },
    },
    {
      headerName: 'NPT Hrs',
      field: 'npt',
      colId: 'npt',
      maxWidth: 200,
      valueFormatter: (params) => (params.data?.npt > 0 ? numberWithCommas(round(params.data?.npt, 2)) : ''),
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Motor Fail',
      colId: 'motorFailure',
      field: 'motorFailure',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'MWD Fail',
      colId: 'mwdFailure',
      field: 'mwdFailure',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'RSS Fail',
      colId: 'rssFailure',
      field: 'rssFailure',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Catas. Fail',
      colId: 'catastrophicFailure',
      field: 'catastrophicFailure',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Unplanned Trip',
      colId: 'unplannedTrip',
      field: 'unplannedTrip',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Trip For Fail',
      colId: 'tripForFailure',
      field: 'tripForFailure',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Motor Cat.',
      field: 'motorCat',
      colId: 'motorCat',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'MWD Cat.',
      field: 'mwdCat',
      colId: 'mwdCat',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Motor Rental',
      colId: 'isMotorRental',
      field: 'isMotorRental',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50px' }}>
            <Checkbox
              edge='end'
              checked={params.value}
              inputProps={{ 'aria-labelledby': params.node.data.uid }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
              indeterminateIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </Box>
        )
      },
    },
    {
      headerName: 'Incident Closed',
      colId: 'incidentClosed',
      field: 'incidentClosed',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: (params) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '50px' }}>
            <Checkbox
              edge='end'
              checked={params.value}
              inputProps={{ 'aria-labelledby': params.node.data.uid }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
              indeterminateIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </Box>
        )
      },
    },
  ]

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) {
      setFilteredIncidentData(filteredNodes)
    }
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('incidentGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const detailCellRendererParams = useMemo(
    () => {
      return {
        detailGridOptions: {
          columnDefs: [
            {
              headerName: '#',
              width: 80,
              colId: 'rowNum',
              valueGetter: (params) => params.node.rowIndex + 1,
              cellStyle: centerAlignCell,
            },
            {
              headerName: '',
              colId: 'avatar',
              width: 60,
              cellStyle: centerAlignCell,
              cellRenderer: (params) => {
                return (
                  <Box
                    sx={{
                      width: 35,
                      height: 35,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'lightgrey',
                      borderRadius: '50%',
                    }}>
                    <Avatar
                      alt={params?.data?.type}
                      src={getDrillStringIcon(params?.data?.type)}
                      style={{ width: 30, height: 30, margin: 'auto' }}></Avatar>
                  </Box>
                )
              },
            },
            {
              headerName: 'Description',
              colId: 'description',
              valueGetter: (params) => unescapeHtml(params.node.data.description),
            },
            {
              headerName: 'Type',
              colId: 'type',
              cellStyle: (params) => () => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: getComponentColor(params.node.data.type),
              }),
              valueGetter: (params) => params.node.data.type,
            },
            {
              headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
              colId: 'od',
              cellStyle: centerAlignCell,
              valueGetter: (params) => numberWithCommas(round(params.node.data.od, 3)),
            },
            {
              headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
              colId: 'id',
              cellStyle: centerAlignCell,
              valueGetter: (params) => numberWithCommas(round(params.node.data.id, 3)),
            },
            {
              headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
              colId: 'length',
              cellStyle: centerAlignCell,
              valueGetter: (params) => numberWithCommas(round(params.node.data.length, 2)),
            },
            {
              headerName: `Cum. Length (${getUnitsText(UNITS_FOR.Depth)})`,
              colId: 'cumLength',
              cellStyle: centerAlignCell,
              valueGetter: (params) => numberWithCommas(round(params.node.data.cumulativeLength, 2)),
            },
            {
              headerName: `SN#`,
              colId: 'sn',
              field: 'sn',
              cellStyle: centerAlignCell,
            },
            {
              headerName: `Make`,
              colId: 'make',
              field: 'make',
              cellStyle: centerAlignCell,
            },
            {
              headerName: `Model`,
              colId: 'model',
              field: 'model',
              cellStyle: centerAlignCell,
            },
          ],
          defaultColDef: {
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            sortable: false,
            resizable: true,
            editable: false,
          },
        },
        getDetailRowData: (params) => {
          getBhaData(params?.data?.wellName, params?.data?.bhaNum, params.successCallback)
        },
      }
    },
    [getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const icons = useMemo(() => {
    return {}
  }, [])

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('incidentGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Incidents.xlsx',
            sheetName: 'Incidents',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getDateFromAndToFilters = useMemo(() => {
    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse('1900-01-01'))

    if (typeof dateFilter === 'string' && dateFilter !== 'All') {
      if (dateFilter === 'Current Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(1)
      }

      if (dateFilter === 'Previous Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 1)
        dateFrom.setDate(1)

        let daysInMonth = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).getDate()
        dateTo = new Date(Date.parse(dateFrom.toISOString()))
        dateTo.setDate(daysInMonth)
        dateTo.setYear(dateFrom.getFullYear() - 1) //Not sure why i need to do this, year is 1 year ahead if not????
      }

      if (dateFilter === 'Last 30 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 30)
      }

      if (dateFilter === 'Last 60 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 60)
      }

      if (dateFilter === 'Last 6 Months') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 6)
      }

      if (dateFilter === 'Current Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      }

      if (dateFilter === 'Last Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear() - 1}-01-01T00:01:00`))
        dateTo = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      } else {
        dateTo.setMonth(dateTo.getMonth() + 12)
      }
    }

    let dateFromStr = dateFrom.toISOString()
    dateFromStr = dateFromStr.substring(0, 10)
    dateFrom = new Date(Date.parse(dateFromStr + 'T00:00:01'))

    let dateToStr = dateTo.toISOString()
    dateToStr = dateToStr.substring(0, 10)
    dateTo = new Date(Date.parse(dateToStr + 'T23:59:59'))

    return { dateTo, dateFrom }
  }, [dateFilter])

  const isExternalFilterPresent = useCallback(() => {
    return dateFilter !== 'All'
  }, [dateFilter])

  const doesExternalFilterPass = useCallback(
    (node) => {
      if (node.data) {
        if (!isValidDate(node.data.bhaPickUpDateTime)) return false
        const { dateFrom, dateTo } = getDateFromAndToFilters

        let dt = new Date(Date.parse(node.data.bhaPickUpDateTime)).getTime()
        if (dt < dateFrom.getTime() || dt > dateTo.getTime()) return false
      }
      return true
    },
    [getDateFromAndToFilters],
  )

  const autoSizeStrategy = {
    type: 'fitCellContents',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
      }}>
      {showMotorReport ? (
        <MotorReportModal
          bhaNum={selectedMotorRepData.current?.bhaNum}
          wellName={selectedMotorRepData.current?.wellName}
          setOpen={() => setShowMotorReport(false)}
          setStatus={setStatus}
        />
      ) : null}
      <Box
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'row',
        }}>
        <Box
          sx={{
            width: '200px',
            marginLeft: '5px',
            marginRight: '5px',
          }}>
          <DropDownPicker
            listOptions={[
              { label: 'All', value: 'All' },
              { label: 'Current Month', value: 'Current Month' },
              { label: 'Previous Month', value: 'Previous Month' },
              { label: 'Last 30 Days', value: 'Last 30 Days' },
              { label: 'Last 60 Days', value: 'Last 60 Days' },
              { label: 'Last 6 Months', value: 'Last 6 Months' },
              { label: 'Current Year', value: 'Current Year' },
              { label: 'Last Year', value: 'Last Year' },
            ]}
            value={dateFilter}
            onChange={setDateFilter}
          />
        </Box>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          autoSizeStrategy={autoSizeStrategy}
          loading={isLoading}
          rowData={Array.isArray(incidentData) ? incidentData : []}
          columnDefs={sortColDefs(columnDefs, 'incidentGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          masterDetail={true}
          detailCellRendererParams={detailCellRendererParams}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItems}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          icons={icons}
          headerHeight={30}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default IncidentsGrid
