import React from 'react'
import { TreeItem, useTreeItem } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTheme } from '@mui/material/styles'
import { PropTypes } from 'prop-types'
import clsx from 'clsx'
import { appColors } from 'utils'
import { SvgIcon } from '@mui/material'

const PlusSquare = (props) => {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  )
}

const useTreeItemStyles = makeStyles((theme) => ({
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
  },
  labelRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    fontSize: 15,
    color: appColors.itemTextColor,
  },
  group: {
    marginLeft: 10,
    paddingLeft: 10,
    borderLeft: `1px dashed gray`,
  },
}))

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    labelText,
    nodeId,
    icon: iconProp,
    expansionIcon,
    labelIcon,
    onLabelClick,
    onIconClick,
    endIcon,
  } = props
  const theme = useTheme()
  const treeItemClasses = useTreeItemStyles()

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection } = useTreeItem(nodeId)

  // the below block determines the icon for the tree, if any. The iconify "blank",
  // is rendered with a color string that has the alpha channel set to 0 to make it invisible
  // but it's needed to hold the proper indentation/spacing in the tree
  const icon = iconProp || expansionIcon || endIcon || <PlusSquare style={{ color: 'rgb(0, 0, 0, 0)' }} />

  const handleMouseDown = (event) => {}

  const handleExpansionClick = (event) => {
    handleExpansion(event)
    if (onIconClick) onIconClick(event)
  }

  const handleSelectionClick = (event) => {
    handleSelection(event)
    if (onLabelClick) onLabelClick(event)
  }

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: theme.spacing(0.5, 0) }}
          onClick={handleExpansionClick}>
          {icon}
        </Box>
        <Box
          onDoubleClick={handleExpansionClick}
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: theme.spacing(0.5, 1) }}>
          {labelIcon}
          <Typography onClick={handleSelectionClick} variant='body2' className={treeItemClasses.labelText}>
            {labelText}
          </Typography>
        </Box>
      </Box>
    </div>
  )
})

CustomContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  labelIcon: PropTypes.node,
  expansionIcon: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.node,
  nodeId: PropTypes.string.isRequired,
  onLabelClick: PropTypes.func,
  onIconClick: PropTypes.func,
}

const TreeNode = (props) => {
  const treeItemClasses = useTreeItemStyles()
  const { labelText, labelIcon, onLabelClick, onIconClick, ...rest } = props
  return (
    <TreeItem
      classes={{
        root: treeItemClasses.root,
        content: treeItemClasses.content,
        expanded: treeItemClasses.expanded,
        group: treeItemClasses.group,
        label: treeItemClasses.label,
      }}
      ContentComponent={CustomContent}
      ContentProps={{ labelText, labelIcon, onLabelClick, onIconClick, ...rest }}
      {...rest}
    />
  )
}

export default TreeNode
