import React from 'react'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import Skeleton from '@mui/material/Skeleton'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getBHAStats } from 'utils/dailyActivityFunctions'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BHAStatisticsChartDistance = ({ data, title = '' }) => {
  const { getUnitsText } = useUnits()
  const { theme, getTextColor } = useInnovaTheme()

  const createChartData = () => {
    if (!data) return { labels: [], datasets: [] }
    if (!Array.isArray(data.dailyActivity)) return { labels: [], datasets: [] }

    let dailyData = getBHAStats(data.dailyActivity)

    const chartData = {
      labels: dailyData.map(
        (day, i) =>
          'BHA #' +
          (parseInt(i) + 1).toString() +
          (' (' + (Math.round(day.rotateDistance) + Math.round(day.slideDistance) + ') ')),
      ),
      datasets: [
        {
          data: dailyData.map((day) => Math.round(day.rotateDistance)),
          backgroundColor: appColors.rotateColor,
          label: `Rotate (${getUnitsText(UNITS_FOR.Depth)})`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => Math.round(day.slideDistance)),
          backgroundColor: appColors.slideColor,
          label: `Slide (${getUnitsText(UNITS_FOR.Depth)})`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    indexAxis: 'y',
    layout: {
      padding: {
        left: 20,
        right: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: title.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          color: getTextColor(),
        },
      },
      datalabels: {
        labels: {
          value: {
            font: {
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: '#404040',
        },
      },
    },
  }

  return data ? (
    <Bar type='bar' options={chartOptions} data={createChartData()} plugins={[ChartDataLabels]} />
  ) : (
    <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation='wave' />
  )
}

export default BHAStatisticsChartDistance
