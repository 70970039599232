import React, { useMemo } from 'react'
import './otpInput.css'

const RE_DIGIT = new RegExp(/^\d+$/)

export type Props = {
  value: string
  valueLength: number
  onChange: (value: string) => void
}

// source: https://github.com/dominicarrojado/react-typescript-otp-input
// youtube: https://www.youtube.com/watch?v=Qpo4gUfv2Fs
//
// focusIndex added to the above source to allow focus to survive parent's state renders

const OtpInput = ({ value, valueLength, onChange }: Props) => {
  const focusIndex = useMemo(() => {
    let fIndex = value?.length
    if (fIndex < 0) fIndex = 0
    return fIndex
  }, [value])

  const valueItems = useMemo(() => {
    const valueArray = value.split('')
    const items: Array<string> = []

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i]
      if (RE_DIGIT.test(char)) {
        items.push(char)
      } else {
        items.push('')
      }
    }
    return items
  }, [value, valueLength])

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const target = e.target
    let targetValue = target.value.trim()
    const isTargetValueDigit = RE_DIGIT.test(targetValue)

    if (!isTargetValueDigit && targetValue !== '') {
      return
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null

    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return
    }

    targetValue = isTargetValueDigit ? targetValue : ' '

    const targetValueLength = targetValue.length
    if (targetValueLength === 1) {
      const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1)

      onChange(newValue.trim())

      if (!isTargetValueDigit) {
        return
      }
    } else if (targetValueLength === valueLength) {
      onChange(targetValue.trim())
    }
  }

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e
    const target = e.target as HTMLInputElement
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault()
      return focusToNextInput(target)
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault()
      return focusToPrevInput(target)
    }

    const targetValue = target.value
    target.setSelectionRange(0, targetValue.length)

    if (key !== 'Backspace' || targetValue !== '') {
      return
    }
    focusToPrevInput(target)
  }

  const focusToNextInput = (target: HTMLInputElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null
    if (nextElementSibling) {
      nextElementSibling.focus()
    }
  }

  const focusToPrevInput = (target: HTMLInputElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null

    if (previousElementSibling) {
      previousElementSibling.focus()
    }
  }

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>, idx: number) => {
    const { target } = e
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null
    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus()
    }
    target.setSelectionRange(0, target.value.length)
  }

  return (
    <div className='otp-group'>
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type='text'
          inputMode='numeric'
          // autoComplete='one-time-code'
          pattern='\d{1}'
          maxLength={valueLength}
          className='otp-input'
          value={digit}
          onChange={(e) => inputOnChange(e, idx)}
          onKeyDown={inputOnKeyDown}
          onFocus={(e) => inputOnFocus(e, idx)}
          autoFocus={idx === focusIndex}
        />
      ))}
    </div>
  )
}

export default OtpInput
