// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react'
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'
import { Buffer } from 'buffer'

import { multiParse } from 'utils'
import { removeFileExt } from 'utils/stringFunctions'
import { userUserRoleAtom } from 'atoms'
import useInnovaAxios from './useInnovaAxios'

export const mapMarkersAtom = atom({
  key: 'mapMarkersAtomKey',
  default: [],
})

export const mapMarkersSelector = selector({
  key: 'mapMarkerSelectorKey',
  get: ({ get }) => {
    const currVal = get(mapMarkersAtom)
    return currVal
  },
  set: ({ set }, newValue) => {
    set(mapMarkersAtom, newValue)
  },
})

var _isMapMarkersInitialized = false // there can be only one

const useMapMarkers = () => {
  const _isMounted = useRef(false)
  const [mapMarkers, setMapMarkers] = useRecoilState(mapMarkersSelector)
  const userRole = useRecoilValue(userUserRoleAtom)

  const getAllMapMarkers = useInnovaAxios({
    url: '/admin/getOrgMarkers',
  })

  useEffect(() => {
    _isMounted.current = true
    if (!_isMapMarkersInitialized && mapMarkers.length < 1) {
      _isMapMarkersInitialized = true
      fetchMapMarkers()
    }
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && userRole && mapMarkers.length < 1 && !_isMapMarkersInitialized) {
      fetchMapMarkers()
    }
  }, [userRole]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMapMarkers = async () => {
    const response = await getAllMapMarkers()
    if (response?.data) {
      const mfpBoundary = response.data.substring(2, response.data.search('Content')).trim()
      let parts = multiParse(Buffer.from(response.data), mfpBoundary)
      let markers = parts.map((part) => {
        let markerObj = { ...part }
        markerObj.data = 'data:image/*;base64,' + part.data
        markerObj.filename = removeFileExt(part.filename)
        return markerObj
      })
      if (_isMounted.current) {
        setMapMarkers(markers)
      }
    } else {
      _isMapMarkersInitialized = false
    }
  }

  const getMapMarker = (orgName, useDefault = false) => {
    if (mapMarkers && Array.isArray(mapMarkers)) {
      const marker = mapMarkers.find((item) => item.filename === orgName.toLowerCase())
      if (marker) {
        return marker.data
      } else {
        // if the organization isn't found, check special cases
        if (orgName.toLowerCase().includes('engineering')) {
          const marker = mapMarkers.find((item) => item.filename === 'engineering')
          if (marker) return marker.data
        }
        // if no special case is found, check for default marker
        if (useDefault) {
          const defaultMarker = mapMarkers.find((item) => item.filename === 'innova')
          if (defaultMarker) {
            return defaultMarker.data
          }
        }
      }
    }
    return ''
  }

  const getMapMarkers = () => {
    if (_isMapMarkersInitialized && mapMarkers && Array.isArray(mapMarkers)) {
      return mapMarkers
    } else {
      return []
    }
  }

  const refreshMapMarkers = () => {
    fetchMapMarkers()
  }

  return { getMapMarker, getMapMarkers, refreshMapMarkers }
}

export default useMapMarkers
