import React, { useState, useEffect, useRef } from 'react'
import { BulkEditGridControl } from './BulkEditGridControl'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import Alert from '@mui/material/Alert'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Snackbar } from '@mui/material'
import DropDownPicker from 'components/common/DropDownPicker'
import BulkEditWarningModal from './BulkEditWarningModal'

const BulkEditDialog = ({ title, open, handleShowBulkEdit }) => {
  const _isMounted = useRef(false)
  const [dropDownData, setDropDownData] = useState([])
  const [data, setData] = useState([])
  const [gridCategory, setGridCategory] = useState('')
  const [sortedData, setSortedData] = useState([])
  const [newValsHasData, setNewValsHasData] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  const storeGridCategory = useRef(-1)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getDropDownData = useInnovaAxios({
    url: '/admin/dbCleaning/getDropDownLists',
  })

  const getGridData = useInnovaAxios({
    url: '/admin/dbCleaning/getCurrentValues',
  })

  const updateGridData = useInnovaAxios({
    url: '/admin/dbCleaning/updateCurrentValues',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDownData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && gridCategory > -1) {
      fetchGridData()
    }
  }, [gridCategory]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && data?.length > 0) {
      processGridData()
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDownData = async () => {
    const response = await getDropDownData()
    if (_isMounted.current && response?.data) {
      setDropDownData(response.data)
    }
  }

  const fetchGridData = async () => {
    if (gridCategory?.length <= 0) return
    let payload = { uid: gridCategory }
    const response = await getGridData(payload)
    if (_isMounted.current) {
      if (response?.data) {
        setData(response.data)
      } else {
        setData([])
      }
    }
  }

  const processGridData = () => {
    let processedData = data.map((item) => {
      return { currVal: item }
    })

    let sortedData = processedData.sort((a, b) => {
      let strA = a.currVal.toLowerCase()
      let strB = b.currVal.toLowerCase()
      if (strA < strB) {
        return -1
      }
      if (strA > strB) {
        return 1
      }
      return 0
    })
    setSortedData(sortedData)
  }

  const fetchUpdateGridValues = async (payload) => {
    if (!payload.hasOwnProperty('uid')) return
    const response = await updateGridData(payload)

    if (response?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Update ${dropDownData[gridCategory]?.description} failed - ${response?.error?.response?.data?.error}`,
      })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: `${dropDownData[gridCategory]?.description} updated` })
    }
    setNewValsHasData(false)
    if (storeGridCategory.current > -1) {
      setGridCategory(storeGridCategory.current)
    }
    fetchGridData()
    return true
  }

  const stageWarningModal = (stageWarning) => {
    if (stageWarning) {
      setNewValsHasData(true)
    } else {
      setNewValsHasData(false)
    }
  }

  const handleGridCategory = (event) => {
    if (newValsHasData) {
      setShowWarningModal(true)
      storeGridCategory.current = event
      return
    } else {
      setGridCategory(event)
    }
  }

  const handleCreatePayload = () => {
    if (!Array.isArray(sortedData)) return
    if (sortedData.length <= 0) return

    let tempOldValues = []
    let tempNewValues = []

    sortedData.forEach((item) => {
      if (item.hasOwnProperty('newVal')) {
        tempOldValues.push(item.currVal)
        tempNewValues.push(item.newVal)
      }
    })

    let pipeDelimitedOldVals = tempOldValues.join('|')
    let pipeDelimitedNewVals = tempNewValues.join('|')

    let payload = {
      uid: gridCategory,
      oldValues: pipeDelimitedOldVals,
      newValues: pipeDelimitedNewVals,
    }
    fetchUpdateGridValues(payload)
  }

  const handleWarningModal = (keepChanges) => {
    if (keepChanges) {
      handleCreatePayload()
      setNewValsHasData(false)
    }
    if (!keepChanges) {
      setNewValsHasData(false)
      setGridCategory(storeGridCategory.current)
      storeGridCategory.current = -1
    }
    setShowWarningModal(false)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  let columnDefs = [
    { headerName: `Current Values`, field: 'currVal', editable: false },
    { headerName: `New Values`, field: 'newVal', editable: true },
  ]

  return (
    <React.Fragment>
      {showWarningModal ? (
        <BulkEditWarningModal
          title={`You are currently making changes to: ${dropDownData[gridCategory]?.description}`}
          message={'Would you like to submit your current changes?'}
          callback={handleWarningModal}
          open={showWarningModal}
        />
      ) : null}
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            height: '60vh',
            width: '50vw',
            backgroundColor: 'itemBackground',
          },
        }}
        open={open}
        onClose={() => handleShowBulkEdit()}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '200px',
              marginBottom: '10px',
            }}>
            <DropDownPicker
              listOptions={dropDownData.map((item) => {
                return {
                  label: item.description,
                  value: item.uid,
                }
              })}
              value={gridCategory}
              onChange={(e) => {
                handleGridCategory(e)
              }}
            />
          </Box>
          <BulkEditGridControl
            rowData={sortedData}
            style={{
              width: '100%',
              height: 'calc(100% - 10px)',
            }}
            columnDefs={columnDefs}
            gridCategory={gridCategory}
            stageWarningModal={stageWarningModal}
          />
          {status?.show ? (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={status?.show}
              autoHideDuration={2000}
              onClose={handleCloseStatus}>
              <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
                {status.message}
              </Alert>
            </Snackbar>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => handleShowBulkEdit()}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleCreatePayload()
            }}
            color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default BulkEditDialog
