import React from 'react'
import { appColors } from 'utils'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const StyledTextItem = styled(Box)({
  color: appColors.itemTextColor,
  fontWeight: 'bold',
})

const StyledTextHeader = styled(Box)({
  color: '#FFF',
  fontWeight: 'bold',
})

const StyledContainer = styled(Box)({
  position: 'absolute',
  textAlign: 'left',
  display: 'box',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'start',
  pointerEvents: 'none',
  marginLeft: '0',
  top: '29px',
  left: '6px',
})

const FocusedSurveyInfo = ({ well, focusedSurveyStation, showControls, selectedOffset, closestApproach }) => {
  return showControls ? (
    <StyledContainer>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <StyledTextHeader>Well:</StyledTextHeader>
        <StyledTextHeader>Offset:</StyledTextHeader>
        <StyledTextHeader>MD: </StyledTextHeader>
        <StyledTextHeader>INC: </StyledTextHeader>
        <StyledTextHeader>AZI: </StyledTextHeader>
        <StyledTextHeader>TVD: </StyledTextHeader>
        <StyledTextHeader>NS: </StyledTextHeader>
        <StyledTextHeader>EW: </StyledTextHeader>
        <StyledTextHeader>DLS: </StyledTextHeader>
        <StyledTextHeader>TF: </StyledTextHeader>
        <StyledTextHeader>UD: </StyledTextHeader>
        <StyledTextHeader>LR: </StyledTextHeader>
        <StyledTextHeader>DIST: </StyledTextHeader>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '10px' }}>
        <StyledTextItem>{well} </StyledTextItem>
        <StyledTextItem>{selectedOffset?.name} </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.md, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.inc, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.azi, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.tvd, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.ns, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.ew, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.dls, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {focusedSurveyStation ? numberWithCommasDecimals(focusedSurveyStation.tf, 2) : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {closestApproach.current?.acScan && closestApproach.current?.acScan.UD !== -9999.25
            ? numberWithCommasDecimals(closestApproach.current.acScan.UD, 2)
            : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {closestApproach.current?.acScan && closestApproach.current?.acScan.LR !== -9999.25
            ? numberWithCommasDecimals(closestApproach.current.acScan.LR, 2)
            : '-'}
        </StyledTextItem>
        <StyledTextItem>
          {closestApproach.current?.acScan && closestApproach.current?.acScan.dist !== -9999.25
            ? numberWithCommasDecimals(closestApproach.current.acScan.dist, 2)
            : '-'}
        </StyledTextItem>
      </Box>
    </StyledContainer>
  ) : null
}

export default FocusedSurveyInfo
