import { FormControlLabel, FormGroup, TextField, Typography } from '@mui/material'

function updateDisplaySettings(property, newValue, setDisplaySettings, displaySettings) {
  let newSettings = { ...displaySettings }
  if (!newSettings.hasOwnProperty(property)) return

  if (property !== 'workSightRadius') newSettings[property] = !newSettings[property]
  if (property === 'workSightRadius') newSettings[property] = newValue
  setDisplaySettings(newSettings)
}

const ChartOptions = ({ displaySettings, setDisplaySettings }) => {
  return (
    <FormGroup style={{ flexDirection: 'row' }}>
      <FormControlLabel
        control={
          <TextField
            value={displaySettings.workSightRadius}
            onChange={(e) => {
              let newVal = e.target.value
              updateDisplaySettings('workSightRadius', newVal, setDisplaySettings, displaySettings)
            }}
            name='workSightRadius'
            style={{ width: 30, height: 18 }}
            inputProps={{ style: { textAlign: 'center', padding: 0 } }}
            variant='standard'
          />
        }
        label={<Typography style={{ color: '#FFF' }}>WorkSight Radius</Typography>}></FormControlLabel>
    </FormGroup>
  )
}

export default ChartOptions
