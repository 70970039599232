import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Tooltip, IconButton } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ErrorTermsGrid = ({ fileName, toolId, setStatus }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [errorTerms, setErrorTerms] = useState([])
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)

  const getErrorTerms = useInnovaAxios({
    url: '/errorModels/getErrorModelTerms',
  })

  const deleteErrorTerms = useInnovaAxios({
    url: '/errorModels/deleteErrorModelTerm',
  })

  const addErrorTerms = useInnovaAxios({
    url: '/errorModels/addErrorModelTerm',
  })

  const updateErrorTerms = useInnovaAxios({
    url: '/errorModels/updateErrorModelTerm',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchErrorModels()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.termId)
    }
  }, [])

  const fetchErrorModels = async () => {
    if (toolId === null || toolId === undefined) return
    if (typeof toolId !== 'number') return

    if (isLoading) return
    setLoading(true)
    const response = await getErrorTerms({ toolId: toolId })

    if (response?.error) return
    if (!_isMounted.current) return

    setErrorTerms(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const reqFields = useMemo(() => ['termName', 'termValue', 'tieOn', 'vector', 'unit', 'formula'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('errorTermsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('errorTermsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ErrorTerms.xlsx',
            sheetName: 'ErrorTerms',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleAddRow = async () => {
    if (toolId === null || toolId === undefined) return
    if (typeof toolId !== 'number') return
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    inputRow.current.active = true
    isAdding.current = true

    inputRow.current.maxInc = 0
    inputRow.current.minInc = 0
    inputRow.current.toolId = toolId
    inputRow.current.fileName = fileName

    if (inputRow.current.vector === 'n') inputRow.current.tieOn = 'n'
    if (inputRow.current.tieOn === 'n') inputRow.current.vector = 'n'

    let res = await addErrorTerms(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.termId = res.data.termId
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })

      setStatus({
        show: true,
        severity: 'info',
        message: `${'Added Error Term'}`,
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (toolId === null || toolId === undefined) return
    if (typeof toolId !== 'number') return
    if (!data) return
    if (!data.hasOwnProperty('termId')) return //why is this missing

    if (isUpdating.current) return

    if (data.vector === 'n') data.tieOn = 'n'
    if (data.tieOn === 'n') data.vector = 'n'

    isUpdating.current = true
    let res = await updateErrorTerms({
      ...data,
      toolId: toolId,
      fileName: fileName,
    })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const handleDelete = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('termId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteErrorTerms({
      ...data,
      toolId: toolId,
      fileName: fileName,
    })
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [data],
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 100,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'termName',
        colId: 'termName',
        headerName: 'Term Name',
      },
      {
        field: 'vector',
        colId: 'vector',
        headerName: 'Vector',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let values = ['i', 'a', 'l', 'e', 'f', 'b', 'n', 'm', 'd']
          return {
            values: [...values],
          }
        },
      },
      {
        field: 'tieOn',
        colId: 'tieOn',
        headerName: 'Tie On',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let values = ['r', 's', 'g', 'w', 'b', 'n']
          return {
            values: [...values],
          }
        },
      },
      {
        field: 'unit',
        colId: 'unit',
        headerName: 'Units',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let values = ['d', 'nt', 'dnt', 'm', 'im', 't', '-']
          return {
            values: [...values],
          }
        },
      },
      {
        field: 'termValue',
        colId: 'termValue',
        headerName: 'Term Value',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000000,
          precision: 10,
        },
      },
      {
        field: 'formula',
        colId: 'formula',
        headerName: 'Formula',
      },
      {
        field: 'minInc',
        colId: 'minInc',
        headerName: 'Min Inc',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
      },
      {
        field: 'maxInc',
        colId: 'maxInc',
        headerName: 'Max Inc',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={errorTerms}
        loading={isLoading}
        columnDefs={sortColDefs(columnDefs, 'errorTermsGrid')}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default ErrorTermsGrid
