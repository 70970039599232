import React, { useState } from 'react'
import ColorPickerModal from 'components/common/ColorPickerModal'
import Box from '@mui/material/Box'

const ColorPicker = ({ color, onChange }) => {
  const [isOpen, toggle] = useState(false)

  return (
    <Box>
      <Box
        sx={{ backgroundColor: color, width: '40px', height: '40px', borderRradius: '8px', border: '1px solid #000', cursor: 'pointer' }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <ColorPickerModal open={isOpen} onClose={() => toggle(false)} color={color} onChange={(e) => onChange(e)} />
      )}
    </Box>
  )
}

export default ColorPicker
