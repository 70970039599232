import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const RigStateThresholdsGrid = ({ data, handleUpdateParentGrid }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    onCellEditingStopped: (params) => {
      if (!params?.valueChanged) return
      handleUpdate(params?.data)
    },
    suppressRowClickSelection: true,
  }

  const handleUpdate = useCallback(
    (data) => {
      handleUpdateParentGrid(cloneDeep(data))

      gridApi.current.applyTransaction({
        update: [data],
      })

      autoSizeColumns()
    },
    [handleUpdateParentGrid],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            let data = params.data
            data[focusedCell?.column?.colDef?.field] = 1
            handleUpdate(cloneDeep(data))
            return true
          }

          return false
        }
      },
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell, handleUpdate])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getContextMenuItems = (params) => {
    return [
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Thresholds.xlsx',
            sheetName: 'Thresholds',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  let columnDefs = [
    {
      colId: 'flow',
      headerName: 'Flow',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 10000,
        precision: 2,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.flow,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.flow = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'hkld',
      headerName: 'Hookload',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 1000000,
        precision: 2,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.hkld,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.hkld = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'onBtmDelta',
      headerName: 'On Btm Delta',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 5,
        precision: 2,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.onBtmDelta,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.onBtmDelta = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'pressure',
      headerName: 'Pressure',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 10000,
        precision: 2,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.pressure,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.pressure = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'rpm',
      headerName: 'Rpm',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 500,
        precision: 0,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.rpm,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.rpm = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'wob',
      headerName: 'WOB',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.wob,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.wob = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'rpmBufferSize',
      headerName: 'RPM Buffer Size',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 30,
        precision: 0,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.rpmBufferSize,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.rpmBufferSize = params.newValue
        handleUpdate(data)
      },
    },
    {
      colId: 'rpmBufferThreshold',
      headerName: 'RPM Buffer Threshold',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 30,
        precision: 0,
      },
      valueGetter: (params) => params?.data?.drillingThresholds?.rpmBufferThreshold,
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return
        let data = cloneDeep(params.data)
        data.drillingThresholds.rpmBufferThreshold = params.newValue
        handleUpdate(data)
      },
    },
  ]

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={[data]}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default RigStateThresholdsGrid
