import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button, Snackbar, Alert } from '@mui/material'
import { Box } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay ,getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DataQcSettings = ({ onClose }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [isLoading, setLoading] = useState(false)
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getSettings = useInnovaAxios({
    url: '/admin/dataQc/getDataQcSettings',
  })

  const updateSettings = useInnovaAxios({
    url: '/admin/dataQc/updateDataQcSettings',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('dataQcSettingsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dataQcSettingsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.name)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const fetchSettings = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getSettings()
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!Array.isArray(res?.data)) return
    if (!gridApi.current) return
    gridApi.current.setGridOption('rowData',res.data)
    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
    fetchSettings()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleUpdate = useCallback(async () => {
    if (isLoading) return
    setLoading(true)

    const rowData = []
    gridApi.current.forEachNode((node) => {
      rowData.push(node.data)
    })

    const res = await updateSettings({ checks: JSON.stringify(rowData) })
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }
  }, [isLoading, updateSettings])

  let columnDefs = useMemo(
    () => [
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        valueFormatter: (params) => {
          if (!params.value) return ''
          if (typeof params.value !== 'string') return ''
          if (!params.value.trim()) return ''

          //Converts camel case variable name to readable string
          let regex = /([a-z])([0-9])(?=[A-Z])/g
          let result = params.value.replace(regex, '$1 $2 ')
          result = result.replace(/([a-z])([A-Z])/g, '$1 $2')
          result = result.charAt(0).toUpperCase() + result.slice(1)

          return result
        },
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
      },
      {
        field: 'active',
        colId: 'active',
        editable: true,
        headerName: 'Active',
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          params.data.active = params.newValue
          handleUpdate()
          return true
        },
      },
    ],
    [centerAlignCell, handleUpdate],
  )

  return (
    <Dialog
      maxWidth='md'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Data QC Settings`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              loading={isLoading}
              columnDefs={sortColDefs(columnDefs, 'dataQcSettingsGrid')}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              getRowId={getRowId}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </Box>

        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DataQcSettings
