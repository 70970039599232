import { useRecoilState, useRecoilValue } from 'recoil'
import { unitSetListSelector, unitSetMapSelector, userPrefsAtom, userUserRoleAtom } from 'atoms'
import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'

export const UNITS_FOR = {
  Depth: 'depth',
  Dogleg: 'dogleg',
  Diameter: 'diameter',
  MudWeight: 'mud',
  FlowRate: 'flow',
  Pressure: 'pressure',
  Temperature: 'temp',
  Torque: 'torque',
  Weight: 'weight',
  Cost: 'cost',
  Volume: 'volume',
  Area: 'area',
  UnitWeight: 'unitWeight',
  LatLong: 'latLong',
  Rop: 'rop',
  BendingMoment: 'bendingMoment',
  Force: 'force',
}

var _isUnitsMapInitialized = false
var _isUnitsListInitialized = false

const useUnits = () => {
  const unitMap = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const userPrefs = useRecoilValue(userPrefsAtom)
  const _isMounted = useRef(false)
  const [unitList, setUnitList] = useState([])
  const [unitListOrg, setUnitListOrg] = useState([])
  const [dropDowns, setDropDowns] = useState({
    depth: [],
    diameter: [],
    dogleg: [],
    flow: [],
    pressure: [],
    volume: [],
    mud: [],
    weight: [],
    torque: [],
    unitWeight: [],
    temp: [],
    cost: [],
    area: [],
    latLong: [],
    bendingMoment: [],
    force: [],
  })

  const [unitMapRaw, setUnitMapRaw] = useRecoilState(unitSetMapSelector)
  const [unitListRaw, setUnitListRaw] = useRecoilState(unitSetListSelector)

  const getUnitSetMap = useInnovaAxios({
    url: '/units/getUnitSetMap',
  })

  const getUnitSetList = useInnovaAxios({
    url: '/units/getUnitSetList',
  })

  useEffect(() => {
    _isMounted.current = true
    if (Object.keys(unitMapRaw).length === 0 && !_isUnitsMapInitialized) {
      _isUnitsMapInitialized = true
      fetchUnitSetMap()
    }
    if (Object.keys(unitListRaw).length === 0 && !_isUnitsListInitialized) {
      _isUnitsListInitialized = true
      fetchUnitSetList()
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDropDowns()
  }, [unitMapRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUnitsList()
  }, [unitListRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchUnitSetList = async () => {
    if (_isMounted.current) {
      const response = await getUnitSetList()
      if (response?.data) {
        setUnitListRaw(response.data)
      } else {
        _isUnitsListInitialized = false // reset if call fails, like when not logged-in
      }
    }
  }

  const fetchUnitSetMap = async () => {
    if (_isMounted.current) {
      const response = await getUnitSetMap()
      if (response?.data) {
        setUnitMapRaw(response.data)
      } else {
        _isUnitsMapInitialized = false // reset if call fails, like when not logged-in
      }
    }
  }

  function getDoglegBase() {
    if (!unitMap.current) return 100
    if (!Array.isArray(unitMap.current[UNITS_FOR.Dogleg])) return 100

    let dlBase = 100
    let unitSelection = userPrefs.unitset[UNITS_FOR.Dogleg]
    unitMap.current[UNITS_FOR.Dogleg].forEach((unit) => {
      if (unit.Name === unitSelection) {
        dlBase = unit.text
      }
    })

    return parseFloat(dlBase)
  }

  function getUnitsText(type) {
    if (!userPrefs) return '-'
    if (!unitMap.current) return '-'
    if (!type) return '-'
    if (!Array.isArray(unitMap.current[type])) return '-'
    if (!userPrefs.hasOwnProperty('unitset')) return '-'
    if (!userPrefs.unitset.hasOwnProperty(type)) return '-'

    let isRop = false
    if (type === UNITS_FOR.Rop) {
      isRop = true
      type = UNITS_FOR.Depth
    }

    let unitSelection = userPrefs.unitset[type]
    let unitText = '-'
    unitMap.current[type].forEach((unit) => {
      if (unit.Name === unitSelection) {
        unitText = unit.text
      }
    })

    if (type === UNITS_FOR.Dogleg) {
      let dlsText = `°/${unitText} ${getUnitsText(UNITS_FOR.Depth)}`
      return dlsText
    }

    if (isRop) {
      return unitText + '/hr'
    }

    return unitText
  }

  async function getUnitsList() {
    if (!unitListRaw) return

    let newUnitList = []
    let newUnitListOrg = []

    if (Array.isArray(unitListRaw)) {
      unitListRaw.forEach((val, index) => {
        newUnitList.push({ label: val.name, value: index, organization: val.organization })
        if (val.organization === userRole.organization) {
          newUnitListOrg.push({ label: val.name, value: index, organization: val.organization })
        }
      })
    }

    if (_isMounted.current === true) {
      setUnitList(newUnitList)
      setUnitListOrg(newUnitListOrg)
    }
  }

  async function getDropDowns() {
    if (!unitMapRaw) return

    let newDropDowns = {}
    for (const property in unitMapRaw) {
      newDropDowns[property] = []

      if (Array.isArray(unitMapRaw[property])) {
        unitMapRaw[property].forEach((val) => {
          newDropDowns[property].push({ label: val.Name, value: val.Name })
        })
      }
    }

    if (_isMounted.current === true) {
      setDropDowns(newDropDowns)
      unitMap.current = unitMapRaw
    }
  }

  const refreshUnits = async () => {
    await fetchUnitSetMap()
    await fetchUnitSetList()
  }

  const getUnitSelectionForUser = (unit) => {
    if (!userPrefs?.hasOwnProperty('unitset')) return null
    if (!userPrefs.unitset?.hasOwnProperty(unit)) return null
    return userPrefs.unitset[unit]
  }

  const getUnitConversion = (unit, dbToUser = true) => {
    if (!unitMapRaw?.hasOwnProperty(unit)) return 1
    if (!Array.isArray(unitMapRaw[unit])) return 1

    let unitSelection = getUnitSelectionForUser(unit)
    if (!unitSelection) return 1

    for (let i = 0; i < unitMapRaw[unit].length; i++) {
      if (unitMapRaw[unit][i].Name === unitSelection) {
        return dbToUser ? unitMapRaw[unit][i].conversion : 1 / unitMapRaw[unit][i].conversion
      }
    }

    return 1
  }

  return { getUnitsText, dropDowns, unitList, unitListOrg, refreshUnits, getUnitConversion, getDoglegBase }
}

export default useUnits
