import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import 'components/common/SplitPane/splitPaneResizer.css'
import DataAcqHealthGrid from './DataAcqHealthGrid'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box, Snackbar, Alert } from '@mui/material'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'


const DataAcqHealthAdminPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [gridData, setGridData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [selectedGrid, setSelectedGrid] = useState('wellDataCache')

  const getAcqCacheData = useInnovaAxios({
    url: '/dataAcq/getCache',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminInnovaDataAcqHealthKey)
    fetchAcqCache()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAcqCache = async () => {
    if (isLoading) return

    setIsLoading(true)
    const res = await getAcqCacheData({})

    if (!_isMounted.current) return

    if (res.error) {
      setIsLoading(false)
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!res.data) {
      setIsLoading(false)
      return
    }

    if (res.data.hasOwnProperty('wellReadCache') && res.data.hasOwnProperty('wellDataCache')) {
      setGridData(res.data)
    }

    setIsLoading(false)
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: fetchAcqCache,
      },
      {
        icon: (
          <Iconify
            icon='ph:read-cv-logo-duotone'
            width='30'
            height='30'
          />
        ),
        name: 'Well Read Cache',
        onClick: () => setSelectedGrid('wellReadCache'),
      },
      {
        icon: (
          <Iconify
            icon='icon-park-outline:write'
            width='30'
            height='30'
          />
        ),
        name: 'Well Data Cache',
        onClick: () => setSelectedGrid('wellDataCache'),
      }
    ]
    return actions
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        marginLeft: `${leftPos}px`,
        height: 'calc(100vh - 64px)',
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
        minWidth: `calc(100% - ${leftPos}px)`,
      }}>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <DataAcqHealthGrid id={selectedGrid} data={gridData} isLoading={isLoading} />
      {/* <DataAcqHealthGrid id={'wellDataCache'} data={gridData} isLoading={isLoading} /> */}
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
    </Box>
  )
}

export default DataAcqHealthAdminPage
