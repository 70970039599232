import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'

import { useRecoilValue } from 'recoil'
import { currentInfrastructureRdsSelector } from 'atoms'
import { AgGridReact } from 'ag-grid-react'
import { sortColDefs, relativeTime, dateComparator, isDateLessThan } from 'components/common/AgGridUtils'
import { saveItemToLS } from 'utils/localStorage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const TopQuerysCard = () => {
  const _isMounted = useRef(false)
  const infrastructureRdsData = useRecoilValue(currentInfrastructureRdsSelector)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('topQueryGrid', 'colLayout', colLayout)
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('topQueryGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'TopQuerys.xlsx',
            sheetName: 'Querys',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        colId: 'elapsedTime',
        headerName: 'Elapsed Time (Min/Avg/Max)',
        cellStyle: centerAlignCell,
        minWidth: 100,
        valueFormatter: (params) =>
          `${params?.data?.minElapsedTime} / ${params?.data?.avgElapsedTime} / ${params?.data?.maxElapsedTime}`,
      },
      {
        colId: 'workerTime',
        headerName: 'Worker Time (Min/Avg/Max)',
        cellStyle: centerAlignCell,
        minWidth: 100,
        valueFormatter: (params) =>
          `${params?.data?.minWorkerTime} / ${params?.data?.avgWorkerTime} / ${params?.data?.maxWorkerTime}`,
      },
      {
        colId: 'logicalReads',
        headerName: 'Logical Reads (Avg/Total)',
        cellStyle: centerAlignCell,
        minWidth: 100,
        valueFormatter: (params) => `${params?.data?.avgLogicalReads} / ${params?.data?.totalLogicalReads}`,
      },
      {
        field: 'avgPhysicalReads',
        minWidth: 100,
        colId: 'avgPhysicalReads',
        headerName: 'Physical Reads (Avg)',
        cellStyle: centerAlignCell,
      },

      {
        field: 'executionCount',
        colId: 'executionCount',
        headerName: 'Execution #',
        cellStyle: centerAlignCell,
        minWidth: 100,
      },
      {
        field: 'hasMissingIndex',
        colId: 'hasMissingIndex',
        headerName: 'Missing Index',
        cellStyle: centerAlignCell,
        minWidth: 100,
      },
      {
        field: 'lastWorkerTime',
        colId: 'lastWorkerTime',
        headerName: 'Last Worker',
        cellStyle: centerAlignCell,
        minWidth: 100,
      },
      {
        field: 'lastExecTime',
        colId: 'lastExecTime',
        headerName: 'Last Execution',
        cellStyle: leftAlignCell,
        minWidth: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) =>
          `${dateTimeFormatter(params.data?.lastExecTime)} [${relativeTimeFormatter(params.data?.lastExecTime)}]`,
      },
      {
        field: 'creationTime',
        colId: 'creationTime',
        headerName: 'Creation',
        cellStyle: leftAlignCell,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) =>
          `${dateTimeFormatter(params.data?.creationTime)} [${relativeTimeFormatter(params.data?.creationTime)}]`,
      },
      {
        field: 'shortQueryText',
        colId: 'shortQueryText',
        headerName: 'Query',
        cellStyle: leftAlignCell,
      },
    ],
    [centerAlignCell, leftAlignCell, dateTimeFormatter, relativeTimeFormatter, dateFilterComparator],
  )

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={Array.isArray(infrastructureRdsData.topQueryStats) ? infrastructureRdsData.topQueryStats : []}
        columnDefs={sortColDefs(columnDefs, 'topQueryGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
      />
    </div>
  )
}

export default TopQuerysCard
