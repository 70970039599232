import React, { useEffect, useRef, useState, useCallback } from 'react'

import { Paper, ListItem, Avatar, Checkbox, Box } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { styled } from '@mui/styles'
import { Icon as Iconify } from '@iconify/react'
import SearchBar from 'components/common/SearchBar'

import { appColors } from 'utils'
import MenuButton from 'components/common/MenuButton'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'

import { useRecoilValue, useRecoilState } from 'recoil'
import {
  analyticsPhaseFilterAtom,
  analyticsSearchParamsAtom,
  multiWellDashboardSelectedWells,
  filterListsAtom,
  wellListAtom,
} from 'atoms'
import { analyticsPickListAtom } from '../../atoms'

import { GetActivityColor, GetActivityText, getStatusAvatar } from 'components/common/activitySelector'
import StyledBadge from 'components/NavBar/StyledBadge'
import { reduceVirtualizedWellData } from 'components/common/wellDataFunctions'
import SectionVirtualList from 'components/common/SectionVirtualList'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledHeaderOperatorText = styled(Box)({
  fontSize: 17,
  fontWeight: '800',
  color: '#FFF',
  lineHeight: 1,
  textAlign: 'left',
  textShadow: '1px 0px #404040',
})

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const StyledAvatarContainer = styled(Box)({
  margin: '5px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'lightgrey',
  borderRadius: '50%',
})

const StyledListItemContentDetails = styled('dt')({
  fontWeight: 'bold',
  color: 'rgb(192,192,192)',
})

const StyledListItemContent = styled('dl')({
  color: appColors.itemTextColorMobile,
  fontSize: '12px',
  marginLeft: '5%',
  marginTop: '4px',
  marginBottom: '4px',
  cursor: 'default',
})

const MultiWellDashboardSearch = ({
  children,
  showPhaseFilter = true,
  showFlatTime,
  flatTimeCB,
  exportXlsxCB = null,
  allowedStatus = [],
  showPhaseColors = false,
  togglePhaseColorCallback,
}) => {
  const _isMounted = useRef(false)
  const [wellListCollapsed, setWellListCollapsed] = useRecoilState(analyticsPickListAtom)
  const wells = useRecoilValue(wellListAtom)
  const [reducedWellList, setReducedWellList] = useState([])
  const [selectedWells, setSelectedWells] = useRecoilState(multiWellDashboardSelectedWells)
  const [searchText, setSearchText] = useState('')
  const [phaseFilter, setPhaseFilter] = useRecoilState(analyticsPhaseFilterAtom)
  const filterLists = useRecoilValue(filterListsAtom)
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [searchParams, setSearchParams] = useRecoilState(analyticsSearchParamsAtom)
  const [removeFlatTime, setRemoveFlatTime] = useState(false)
  const [phaseModalVisible, setPhaseModalVisible] = useState(false)
  const [phaseColorsSelected, setPhaseColorsSelected] = useState(false)
  const { objectsOfInterest } = useObjectsOfInterest()
  const searchParamsRef = useRef(null)
  const { searchBarStyle, getLinearGradient, getBackColor } = useInnovaTheme()

  const wellSearch = useInnovaAxios({
    url: '/kpi/wellSearch',
  })

  useEffect(() => {
    _isMounted.current = true
    setReducedWellList(reduceVirtualizedWellData(wells, searchText, allowedStatus, objectsOfInterest))

    if (selectedWells.length <= 0) {
      setWellListCollapsed(true)
    } else {
      setWellListCollapsed(false)
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (flatTimeCB) {
      flatTimeCB(removeFlatTime)
    }
  }, [removeFlatTime]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReducedWellList(reduceVirtualizedWellData(wells, searchText, allowedStatus, objectsOfInterest))
  }, [searchText, objectsOfInterest]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReducedWellList(reduceVirtualizedWellData(wells, searchText, allowedStatus, objectsOfInterest))
  }, [wells]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseSearch = async () => {
    setShowSearchModal(false)
  }

  const handleClearSearch = () => {
    setSelectedWells([])
    setSearchParams({
      operator: [],
      state: [],
      county: [],
      rig: [],
      wellStatus: [],
      jobNum: [],
      directionalCompany: [],
      formation: [],
      holeSize: [],
      isLih: '',
      phase: [],
      district: [],
      dateFrom: '',
      dateTo: '',
    })
  }

  const submitSearch = async (data) => {
    setShowSearchModal(false)

    if (!data) {
      return
    }

    if (typeof data.dateTo === 'object') {
      data.dateTo = data.dateTo.toISOString()
    }

    if (typeof data.dateFrom === 'object') {
      data.dateFrom = data.dateFrom.toISOString()
    }

    if (data.dateTo === data.dateFrom) {
      data.dateTo = ''
      data.dateFrom = ''
    }

    if (data.dateFrom !== '' || data.dateTo !== '') {
      if (data.dateFrom !== '' && data.dateTo === '')
        data.dateTo = new Date(Date.parse('2200-01-01T00:00:00.000Z')).toISOString()
      if (data.dateFrom === '' && data.dateTo !== '')
        data.dateFrom = new Date(Date.parse('1900-01-01T00:00:00.000Z')).toISOString()
    }

    if (data.isLih === true) data.isLih = 'true'

    let params = {}
    let newSearchParams = { ...searchParamsRef.current }
    for (const property in data) {
      if (typeof data[property] === 'string' && data[property] !== '') {
        params[property] = data[property]
      }

      if (Array.isArray(data[property]) && data[property].length > 0) {
        params[property] = data[property].join('|')
      }

      if (newSearchParams.hasOwnProperty(property)) newSearchParams[property] = data[property]
    }

    if (_isMounted.current === true) setSearchParams(newSearchParams)

    const response = await wellSearch(params)
    if (_isMounted.current) {
      if (response?.data && Array.isArray(response.data)) {
        setSelectedWells(response.data)
      } else {
        setSelectedWells([])
      }
    }
  }

  // SectionPickList support
  const onItemSelect = (id) => {
    let newSelectedItems = [...selectedWells]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true) setSelectedWells(newSelectedItems)
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true) setSelectedWells(newSelectedItems)
    }
  }

  const getChecked = (id) => {
    if (!selectedWells) return false
    if (Array.isArray(selectedWells) === false) return false
    if (selectedWells.includes(id) === true) return true

    return false
  }

  const renderWellSectionListItem = (item, style) => {
    return (
      <ListItem
        key={item.actualWell}
        sx={{
          background: getLinearGradient(),
        }}
        style={style}>
        <StyledAvatarContainer style={{ marginRight: '15px' }}>
          <StyledBadge
            badgecolor={GetActivityColor(item.wellStatus)}
            badgeContent={GetActivityText(item.wellStatus)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Avatar alt='Well Icon' src={getStatusAvatar(item.wellStatus)}></Avatar>
          </StyledBadge>
        </StyledAvatarContainer>
        <StyledListItemContent>
          <StyledListItemContentDetails>{`${item.actualWell}`}</StyledListItemContentDetails>
          <dt>{`${item.operator}`}</dt>
          <dt>{`${item.jobNum} - ${item.state}/${item.county}`}</dt>
        </StyledListItemContent>
        <Box sx={{ marginLeft: 'auto', marginRight: '10px' }}>
          <Checkbox
            edge='end'
            onChange={() => onItemSelect(item.actualWell)}
            checked={getChecked(item.actualWell)}
            checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
            indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          />
        </Box>
      </ListItem>
    )
  }

  const renderVirtualSectionHeader = (item, index, style) => {
    return (
      <ListItem dense disableGutters={true} style={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0px 0px 4px 0px',
            padding: '4px 0px 4px 0px',
            width: '100%',
            background:
              'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
            height: '73px',
            maxHeight: '73px',
          }}>
          <StyledAvatarContainer style={{ maxHeight: '40px', marginRight: '20px' }}>
            {item.operator !== 'Following' ? (
              <Iconify icon='ic:baseline-oil-barrel' width='40' height='40' color='black' />
            ) : null}
            {item.operator === 'Following' ? (
              <Iconify icon='ic:sharp-star-purple500' width='40' height='40' color='gold' />
            ) : null}
          </StyledAvatarContainer>
          <StyledHeaderOperatorText>{item.operator}</StyledHeaderOperatorText>
        </Box>
      </ListItem>
    )
  }

  const renderWellSectionVirtualListItem = (props) => {
    const { index, style } = props
    const item = reducedWellList[index]
    if (item.hasOwnProperty('header')) {
      return renderVirtualSectionHeader(item, index, style)
    }
    return renderWellSectionListItem(item, style)
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <Iconify icon='la:times' />,
        name: 'Clear selection',
        onClick: () => handleClearSearch(),
      },
      {
        icon: <Iconify icon='ant-design:search-outlined' />,
        name: 'Search',
        onClick: () => {
          searchParamsRef.current = cloneDeep(searchParams)
          setShowSearchModal(!showSearchModal)
        },
      },
      {
        icon: <Iconify icon='dashicons:menu-alt3' />,
        name: 'Show well list',
        onClick: () => setWellListCollapsed(!wellListCollapsed),
      },
    ]

    if (exportXlsxCB !== null) {
      actions.push({
        icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
        name: 'Export to XLSX',
        onClick: () => exportXlsxCB(),
      })
    }

    if (showFlatTime) {
      actions.push({
        icon: <Iconify icon='carbon:chart-line' />,
        name: removeFlatTime ? 'Show flat time' : 'Remove flat time',
        onClick: () => setRemoveFlatTime(!removeFlatTime),
      })
    }

    if (showPhaseFilter && filterLists && filterLists.phases && filterLists.phases.length > 0) {
      actions.push({
        icon: <Iconify icon='akar-icons:filter' />,
        name: 'Phase Filter',
        onClick: () => setPhaseModalVisible(!phaseModalVisible),
      })
    }

    if (showPhaseColors) {
      actions.push({
        icon: (
          <Iconify icon='ph:line-segments-fill' color={phaseColorsSelected ? appColors.itemTextColor : ''} rotate={1} />
        ),
        name: `Toggle Phase Color ${phaseColorsSelected ? '(ON)' : '(OFF)'}`,
        onClick: () => {
          setPhaseColorsSelected(!phaseColorsSelected)
          togglePhaseColorCallback()
        },
      })
    }

    return actions
  }

  const getPhasesList = () => {
    if (!filterLists) return []
    if (!Array.isArray(filterLists?.phases)) return []
    return filterLists.phases
  }

  const getPickListObjArray = (objArray) => {
    if (!Array.isArray(objArray)) return []

    let newObjArray = []
    objArray.forEach((item) => {
      if (item.label !== '') {
        newObjArray.push({
          id: item.value,
          desc: item.label,
        })
      }
    })
    return newObjArray
  }

  const getPickListValArray = (objArray) => {
    if (!Array.isArray(objArray)) return []

    let newObjArray = []
    objArray.forEach((item) => {
      newObjArray.push({
        label: item,
        desc: item,
      })
    })
    return newObjArray
  }

  const SearchModal = useCallback(
    ({ submitFunction, cancelFunction }) => {
      const formValidationSchema = yup.object({
        operator: yup.array().nullable(),
        state: yup.array().nullable(),
        county: yup.array().nullable(),
        rig: yup.array().nullable(),
        wellStatus: yup.array().nullable(),
        jobNum: yup.array().nullable(),
        directionalCompany: yup.array().nullable(),
        formation: yup.array().nullable(),
        holeSize: yup.array().nullable(),
        isLih: yup.string().nullable(),
        phase: yup.array().nullable(),
        dateFrom: yup.string().nullable(),
        dateTo: yup.string().nullable(),
      })

      let formData = [
        {
          tag: 'operator',
          value: getPickListValArray(searchParamsRef.current.operator),
          text: 'Operator',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.operators),
          useLabel: true,
        },
        {
          tag: 'state',
          value: getPickListValArray(searchParamsRef.current.state),
          text: 'State',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.states),
          useLabel: true,
        },
        {
          tag: 'county',
          value: getPickListValArray(searchParamsRef.current.county),
          text: 'County',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.countys),
          useLabel: true,
        },
        {
          tag: 'district',
          value: searchParamsRef.current.hasOwnProperty('district')
            ? getPickListValArray(searchParamsRef.current.district)
            : [],
          text: 'District',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.districts),
          useLabel: true,
        },
        {
          tag: 'rig',
          value: getPickListValArray(searchParamsRef.current.rig),
          text: 'Rig',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.rigs),
          useLabel: true,
        },
        {
          tag: 'wellStatus',
          value: getPickListValArray(searchParamsRef.current.wellStatus),
          text: 'Well Status',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.wellStatus),
          useLabel: true,
        },
        {
          tag: 'jobNum',
          value: getPickListValArray(searchParamsRef.current.jobNum),
          text: 'Job #',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.jobNumbers),
          useLabel: true,
        },
        {
          tag: 'directionalCompany',
          value: getPickListValArray(searchParamsRef.current.directionalCompany),
          text: 'Directional Company',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.directionalCompany),
          useLabel: true,
        },
        {
          tag: 'formation',
          value: getPickListValArray(searchParamsRef.current.formation),
          text: 'Formation',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.formations),
          useLabel: true,
        },
        {
          tag: 'holeSize',
          value: getPickListValArray(searchParamsRef.current.holeSize),
          text: 'Hole Size',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.holeSize),
          useLabel: true,
        },
        {
          tag: 'phase',
          value: getPickListValArray(searchParamsRef.current.phase),
          text: 'phase',
          inputType: 'picklist',
          pickListValues: getPickListObjArray(filterLists?.phases),
          useLabel: true,
        },
        {
          tag: 'dateFrom',
          value:
            searchParamsRef.current.dateFrom === ''
              ? new Date(Date.now()).toISOString().split('T')[0]
              : searchParamsRef.dateFrom,
          text: 'Start Date',
          inputType: 'date',
        },
        {
          tag: 'dateTo',
          value:
            searchParamsRef.current.dateTo === ''
              ? new Date(Date.now()).toISOString().split('T')[0]
              : searchParamsRef.current.dateTo,
          text: 'End Date',
          inputType: 'date',
        },
        {
          tag: 'isLih',
          value: searchParamsRef.current.isLih,
          text: 'LIH',
          inputType: 'boolean',
        },
      ]

      return (
        <InputModal
          open={true}
          onClose={cancelFunction}
          title={'Search Wells'}
          formData={formData}
          submitForm={submitFunction}
          cancelFunction={cancelFunction}
          validationSchema={formValidationSchema}
        />
      )
    },
    [filterLists],
  )

  const PhaseFilterModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({
      phase: yup.string().nullable(),
    })

    let formData = [
      {
        tag: 'phase',
        value: phaseFilter,
        text: 'Phase',
        inputType: 'dropdown',
        dropDownValues: getPhasesList(),
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={phaseModalVisible}
        onClose={cancelFunction}
        title={'Select phase'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const updatePhaseFilter = async (data, formikActions) => {
    setPhaseModalVisible(false)

    formikActions?.setSubmitting(false)
    setPhaseFilter(data.phase)
    return true
  }

  return (
    <Paper sx={{ maxWidth: '100%', backgroundColor: getBackColor() }}>
      {showSearchModal ? SearchModal({ cancelFunction: handleCloseSearch, submitFunction: submitSearch }) : null}
      {phaseModalVisible ? (
        <PhaseFilterModal
          data={null}
          submitFunction={updatePhaseFilter}
          cancelFunction={() => setPhaseModalVisible(false)}
        />
      ) : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: 3,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
        }}>
        {React.Children.map(children, (child) => {
          return child
        })}
        <Collapse
          sx={{
            paddingLeft: '5px',
            paddingRight: '5px',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            overflow: 'hidden',
            height: 'calc(100vh - 64px)',
            maxHeight: 'calc(100vh - 64px)',
            maxWidth: '380px',
            minWidth: '265px',
            '& .MuiCollapse-wrapper': {
              height: 'calc(100vh - 64px)',
            },
            '& .MuiCollapse-wrapperInner': {
              flexDirection: 'column',
              display: 'flex',
              height: 'calc(100vh - 64px)',
            },
          }}
          in={wellListCollapsed}
          timeout='auto'
          unmountOnExit>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle()}
          />
          <SectionVirtualList listItems={reducedWellList} renderItem={renderWellSectionVirtualListItem} itemSize={81} />
        </Collapse>
        <StyledMenuIcon>
          <MenuButton actions={getMenuItems()} />
        </StyledMenuIcon>
      </Box>
    </Paper>
  )
}

export default MultiWellDashboardSearch
