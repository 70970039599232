import { Box, Tooltip } from '@mui/material'
import Canvas from 'components/common/Canvas'
import { useResizeDetector } from 'react-resize-detector'
import { useEffect, useRef, useState } from 'react'
import * as d3 from 'd3'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { Icon as Iconify } from '@iconify/react'
import HeatMapSettingsModal from './HeatMapSettingsModal'
import useUnits, { UNITS_FOR } from '../hooks/useUnits'
import { cloneDeep } from 'lodash'
import DropDownPicker from 'components/common/DropDownPicker'
import useInnovaTheme from '../hooks/useInnovaTheme'

const HeatMapChart = ({
  id = 'heatmap-chart',
  backColor = null,
  gridColor = '#808080',
  borderColor = '#404040',
  textColor = null,
  minDataColor = '#00FF00',
  midDataColor = '#FFFF00',
  maxDataColor = '#FF0000',
  backColorPrint = '#FFFFFF',
  textColorPrint = '#000000',
  data,
  bhas,
  showBhaSelector = true,
  toolbarOffset,
  showTitle = true,
  printing = false,
  bhaNum,
}) => {
  const TOOLBAR_OFFSET = toolbarOffset ? toolbarOffset + 15 : 15
  const TITLE_OFFSET = 0.075
  const X_SCALE_OFFSET = 0.15
  const Y_SCALE_OFFSET = 0.1
  const LEGEND_OFFSET = 0.1
  const RIGHT_PADDING = 15
  const { width: chartWidth, height: chartHeight, ref } = useResizeDetector()
  const _isMounted = useRef(false)
  const mousePosition = useRef(null)
  const { getUnitsText } = useUnits()
  const [selectedBha, setSelectedBha] = useState(-1)
  const { getChartBackColor, getTextColor } = useInnovaTheme()

  if (!backColor) backColor = getChartBackColor()
  if (!textColor) {
    textColor = getTextColor()
  }

  const [params, setParams] = useState([
    { axis: 'X', variable: 'RPM', min: 0, max: 100, ticks: 10, autoScale: true, autoTicks: true, id: 0 },
    { axis: 'Y', variable: 'WOB', min: 0, max: 100, ticks: 10, autoScale: true, autoTicks: true, id: 1 },
    { axis: 'Z', variable: 'ROP', min: 0, max: 100, ticks: 10, autoScale: true, autoTicks: true, id: 2 },
  ])

  const filledBinsRef = useRef(null)
  const [showSettings, setShowSettings] = useState(false)

  const getMaxBhaNum = () => {
    if (!bhas) return -1
    if (!Array.isArray(bhas)) return -1
    if (bhas.length === 0) return -1

    let max = -1
    bhas.forEach((bha) => {
      if (bha.bhaNum > max) max = bha.bhaNum
    })

    return max
  }

  const getTitleOffset = () => {
    return showTitle ? TITLE_OFFSET : 0.01
  }

  const getUnitsForVariable = (variable) => {
    if (variable === 'RPM') return getUnitsText(UNITS_FOR.FlowRate)
    if (variable === 'WOB') return getUnitsText(UNITS_FOR.Weight)
    if (variable === 'ROP') return `${getUnitsText(UNITS_FOR.Depth)}/hr`
    if (variable === 'Flow Rate') return getUnitsText(UNITS_FOR.FlowRate)
    if (variable === 'Torque') return getUnitsText(UNITS_FOR.Torque)
    if (variable === 'Diff Press') return getUnitsText(UNITS_FOR.Pressure)
    return ''
  }

  const getIndexAtAxis = (axis) => {
    if (axis === 'X' || axis === 'x') return 0
    if (axis === 'Y' || axis === 'y') return 1
    if (axis === 'Z' || axis === 'z') return 2
    return -1
  }

  const getDataFromParams = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []
    if (!bhas) return []
    if (!Array.isArray(bhas)) return []
    if (bhas.length === 0) return []
    if (selectedBha < 0) {
      setSelectedBha(getMaxBhaNum())
    }

    let X = []
    let Y = []
    let Z = []

    let MD = []
    data.forEach((item, index) => {
      if (item.label === params[getIndexAtAxis('X')].variable) {
        for (let i = 0; i < item.data.length; i++) {
          if (item.data[i].bhaNum === selectedBha) {
            MD.push({
              md: item.data[i].y,
            })
          }
        }
      }
    })

    data.forEach((item, index) => {
      if (item.label === params[getIndexAtAxis('X')].variable) {
        for (let i = 0; i < item.data.length; i++) {
          X.push({
            val: item.data[i].x,
            md: item.data[i].y,
          })
        }
      }
      if (item.label === params[getIndexAtAxis('Y')].variable) {
        for (let i = 0; i < item.data.length; i++) {
          Y.push({
            val: item.data[i].x,
            md: item.data[i].y,
          })
        }
      }
      if (item.label === params[getIndexAtAxis('Z')].variable) {
        for (let i = 0; i < item.data.length; i++) {
          Z.push({
            val: item.data[i].x,
            md: item.data[i].y,
          })
        }
      }
    })

    let returnData = []
    for (let i = 0; i < MD.length; i++) {
      let x = -999.25
      for (let j = 0; j < X.length; j++) {
        if (X[j].md === MD[i].md) {
          x = X[j].val
          break
        }
      }

      let y = -999.25
      for (let j = 0; j < Y.length; j++) {
        if (Y[j].md === MD[i].md) {
          y = Y[j].val
          break
        }
      }

      let z = -999.25
      for (let j = 0; j < Z.length; j++) {
        if (Z[j].md === MD[i].md) {
          z = Z[j].val
          break
        }
      }

      if (x === -999.25 || y === -999.25 || z === -999.25) continue

      returnData.push({
        x: x,
        y: y,
        z: z,
      })
    }

    return returnData
  }

  const chartData = getDataFromParams()

  const handleContextMenu = (e) => {}

  const handleMouseMove = (e) => {
    mousePosition.current = { x: e.offsetX, y: e.offsetY }
  }

  const handleMouseOut = (e) => {
    mousePosition.current = null
  }

  useEffect(() => {
    _isMounted.current = true
    document.getElementById(id).addEventListener('mousemove', handleMouseMove)
    document.getElementById(id).addEventListener('mouseout', handleMouseOut)
    document.getElementById(id).addEventListener('contextmenu', handleContextMenu)

    return () => {
      _isMounted.current = false
      if (document.getElementById(id)) {
        document.getElementById(id)?.removeEventListener('mousemove', handleMouseMove)
        document.getElementById(id)?.removeEventListener('mouseout', handleMouseOut)
        document.getElementById(id)?.removeEventListener('contextmenu', handleContextMenu)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bhaNum == null) return
    if (bhaNum < 0) return
    setSelectedBha(bhaNum)
  }, [bhaNum])

  const getWidth = () => {
    return chartWidth ? chartWidth : 1
  }

  const getHeight = () => {
    return chartHeight ? chartHeight : 1
  }

  const calculateNiceScales = (min, max, numTicks, variable) => {
    if (numTicks === 0) numTicks = 10
    if (params[getIndexAtAxis(variable)].autoTicks) {
      numTicks = 10
    }

    if (min === max) {
      min = min - 10
      max = max + 10
    }

    const range = max - min
    const rawStep = range / numTicks

    const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)))
    const residual = rawStep / magnitude

    let niceStep = magnitude
    if (residual > 5) {
      niceStep = 10 * magnitude
    } else if (residual > 2) {
      niceStep = 5 * magnitude
    } else if (residual > 1) {
      niceStep = 2 * magnitude
    }

    let iterations = 0
    while ((range / niceStep) % 1 !== 0 && iterations < 10) {
      niceStep -= magnitude / 10
      iterations++
    }

    if (!params[getIndexAtAxis(variable)].autoTicks) {
      niceStep = rawStep
    }

    const niceMin = params[getIndexAtAxis(variable)].autoScale ? Math.floor(min / niceStep) * niceStep : min
    const niceMax = params[getIndexAtAxis(variable)].autoScale ? Math.ceil(max / niceStep) * niceStep + niceStep : max

    numTicks = Math.floor((niceMax - niceMin) / niceStep)

    params[getIndexAtAxis(variable)].min = niceMin
    params[getIndexAtAxis(variable)].max = niceMax
    params[getIndexAtAxis(variable)].ticks = numTicks
    params[getIndexAtAxis(variable)].step = niceStep

    return { niceMin, niceMax, niceStep }
  }

  function getMax(atribute) {
    if (!params[getIndexAtAxis(atribute)].autoScale) return params[getIndexAtAxis(atribute)].max
    if (!chartData) return 100
    if (!Array.isArray(chartData)) return 100
    if (chartData.length === 0) return 100
    return d3.max(chartData, (d) => d[atribute])
  }

  function getMin(atribute) {
    if (!params[getIndexAtAxis(atribute)].autoScale) return params[getIndexAtAxis(atribute)].min
    if (!chartData) return 0
    if (!Array.isArray(chartData)) return 0
    if (chartData.length === 0) return 0
    return d3.min(chartData, (d) => d[atribute])
  }

  function getTicks(atribute) {
    if (!params[getIndexAtAxis(atribute)].autoTicks) return params[getIndexAtAxis(atribute)].ticks
    return 10
  }

  const getFontString = (context, fontSize) => {
    let scale = getFontScale(context)
    return fontSize * scale
  }

  const getFontScale = (context) => {
    const { width } = getHeightWidth(context)
    const BASE_LINE_WIDTH = 300
    if (width >= BASE_LINE_WIDTH) return 1
    return Math.max(0.1, width / BASE_LINE_WIDTH)
  }

  const getHeightWidth = (context) => {
    return {
      height: getHeight(),
      width: getWidth(),
    }
  }

  const hitTest = (r, pt) => {
    return pt.x >= r.x && pt.x <= r.x + r.w && pt.y >= r.y && pt.y <= r.y + r.h
  }

  const getInterpolatedCoordinate = (v, minVal, maxVal, minPos, maxPos, axis) => {
    if (v < minVal) return -999.25
    if (v > maxVal) return -999.25
    if (minVal === maxVal) return -999.25
    if (minPos === maxPos) return -999.25
    let percent = (v - minVal) / (maxVal - minVal)
    if (axis === 'x') return minPos + percent * (maxPos - minPos)
    return maxPos - percent * (maxPos - minPos)
  }

  const getNearestPointValFromBin = (
    minXPos,
    maxXPos,
    minXVal,
    maxXVal,
    mouseX,
    stepX,
    minYPos,
    maxYPos,
    minYVal,
    maxYVal,
    mouseY,
    stepY,
  ) => {
    if (!filledBinsRef.current)
      return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    if (!Array.isArray(filledBinsRef.current))
      return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    if (filledBinsRef.current.length === 0)
      return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    if (minXVal === maxXVal) return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    if (minYVal === maxYVal) return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    if (minXPos === maxXPos) return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    if (minYPos === maxYPos) return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }
    let percentX = (mouseX - minXPos) / (maxXPos - minXPos)
    let percentY = (mouseY - minYPos) / (maxYPos - minYPos)

    let xVal = minXVal + percentX * (maxXVal - minXVal)
    let yVal = maxYVal - percentY * (maxYVal - minYVal)

    let xBin = Math.floor((xVal - minXVal) / stepX)
    let yBin = Math.floor((yVal - minYVal) / stepY) + 1

    let bin = filledBinsRef.current.find((bin) => bin.xBin === xBin && bin.yBin === yBin)

    if (!bin) return { valAvg: -999.25, valMin: -999.25, valMax: -999.25, xBin: -999.25, yBin: -999.25 }

    const valAvg = bin.zSum / bin.count
    const valMin = bin.zMin
    const valMax = bin.zMax

    return { valAvg, valMin, valMax, xBin, yBin }
  }

  const calcualteTitleRect = (context) => {
    const { width, height } = getHeightWidth(context)
    let titleRectH = height * getTitleOffset()
    let titleRectW = width - TOOLBAR_OFFSET

    return {
      titleRectH,
      titleRectW,
    }
  }

  const calculateXScaleRect = (context) => {
    const { width, height } = getHeightWidth(context)
    let xScaleH = height * X_SCALE_OFFSET
    let xScaleW = width - TOOLBAR_OFFSET
    let xRectX = Y_SCALE_OFFSET * width + TOOLBAR_OFFSET
    let xRectY = height * getTitleOffset()

    return {
      xScaleH,
      xScaleW,
      xRectX,
      xRectY,
    }
  }

  const calculateYScaleRect = (context) => {
    const { width, height } = getHeightWidth(context)
    let yScaleH = height - height * getTitleOffset() - X_SCALE_OFFSET * height
    let yScaleW = width * Y_SCALE_OFFSET
    let yRectX = TOOLBAR_OFFSET
    let yRectY = height * getTitleOffset()

    return {
      yScaleH,
      yScaleW,
      yRectX,
      yRectY,
    }
  }

  const calculateZScaleRect = (context) => {
    const { width, height } = getHeightWidth(context)
    let zScaleH = height - height * getTitleOffset() - X_SCALE_OFFSET * height
    let zScaleW = width * LEGEND_OFFSET
    let zRectX = width - zScaleW - RIGHT_PADDING
    let zRectY = height * getTitleOffset()

    return {
      zScaleH,
      zScaleW,
      zRectX,
      zRectY,
    }
  }

  const calculateChartRect = (context) => {
    const { width, height } = getHeightWidth(context)
    let chartRectH = height - height * getTitleOffset() - X_SCALE_OFFSET * height
    let chartRectW = width - Y_SCALE_OFFSET * width - TOOLBAR_OFFSET - LEGEND_OFFSET * width - RIGHT_PADDING
    let chartRectX = Y_SCALE_OFFSET * width + TOOLBAR_OFFSET
    let chartRectY = height * getTitleOffset()

    return {
      chartRectH,
      chartRectW,
      chartRectX,
      chartRectY,
    }
  }

  const drawBackGround = (context) => {
    context.clearRect(0, 0, context.canvas.width * 2, context.canvas.height * 2)
    context.fillStyle = backColor
    if (printing) context.fillStyle = backColorPrint

    context.beginPath()
    context.fillRect(0, 0, context.canvas.width * 2, context.canvas.height * 2)
  }

  const drawTitle = (context) => {
    if (!showTitle) return
    const { titleRectH, titleRectW } = calcualteTitleRect(context)

    context.font = `${getFontString(context, 0.95)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = textColor
    context.strokeStyle = textColor

    if (printing) {
      context.fillStyle = textColorPrint
      context.strokeStyle = textColorPrint
    }

    context.beginPath()
    context.fillText(
      `${params[getIndexAtAxis('Z')].variable} Heat Map - BHA ${selectedBha}`,
      TOOLBAR_OFFSET + titleRectW / 2,
      titleRectH / 2,
    )
  }

  const drawXScale = (context, minX, maxX, stepX) => {
    const { xScaleH, xScaleW, xRectY } = calculateXScaleRect(context)
    const { chartRectH, chartRectW, chartRectX } = calculateChartRect(context)
    context.font = `${getFontString(context, 0.65)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'top'
    context.fillStyle = textColor
    context.strokeStyle = textColor

    if (printing) {
      context.fillStyle = textColorPrint
      context.strokeStyle = textColorPrint
    }

    context.beginPath()
    context.fillText(
      `${params[getIndexAtAxis('X')].variable} (${getUnitsForVariable(params[getIndexAtAxis('X')].variable)})`,
      TOOLBAR_OFFSET + xScaleW / 2,
      context.canvas.height - xScaleH / 2,
    )

    let step = stepX

    while (step <= maxX) {
      let x = getInterpolatedCoordinate(step, minX, maxX, chartRectX, chartRectX + chartRectW, 'x')
      context.beginPath()
      context.textAlign = 'center'
      context.textBaseline = 'top'
      context.fillText(`${numberWithCommasDecimals(step, 2)}`, x, xRectY + chartRectH + 2)

      step += stepX
    }
  }

  const drawYScale = (context, minY, maxY, stepY) => {
    const { height } = getHeightWidth(context)
    const { yScaleH, yScaleW, yRectY } = calculateYScaleRect(context)
    context.font = `${getFontString(context, 0.65)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'bottom'
    context.fillStyle = textColor
    context.strokeStyle = textColor

    if (printing) {
      context.fillStyle = textColorPrint
      context.strokeStyle = textColorPrint
    }

    context.save()
    context.translate(TOOLBAR_OFFSET + yScaleW / 2, getTitleOffset() * height + yScaleH / 2)
    context.rotate(-Math.PI / 2)
    context.fillText(
      `${params[getIndexAtAxis('Y')].variable} (${getUnitsForVariable(params[getIndexAtAxis('Y')].variable)})`,
      0,
      0,
    )
    context.restore()

    let step = stepY

    while (step <= maxY) {
      let y = getInterpolatedCoordinate(step, minY, maxY, yRectY, yRectY + yScaleH, 'y')
      context.beginPath()
      context.textAlign = 'right'
      context.textBaseline = 'middle'
      context.fillText(`${numberWithCommasDecimals(step, 2)}`, TOOLBAR_OFFSET + yScaleW - 2, y)

      step += stepY
    }
  }

  const drawZScale = (context, minZ, maxZ, stepZ) => {
    const { zScaleH, zScaleW, zRectX, zRectY } = calculateZScaleRect(context)
    let legendW = zScaleW * 0.4
    let legendH = zScaleH * 0.9
    let offsetX = zRectX + zScaleW * 0.3
    let offsetY = zRectY + zScaleH * 0.05
    context.font = `${getFontString(context, 0.65)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'bottom'
    context.fillStyle = backColor

    if (printing) {
      context.fillStyle = backColorPrint
    }

    let gradient = context.createLinearGradient(zRectX, zRectY, zRectX, zRectY + zScaleH)
    gradient.addColorStop(0, maxDataColor)
    gradient.addColorStop(0.5, midDataColor)
    gradient.addColorStop(1, minDataColor)

    context.fillStyle = gradient
    context.fillRect(offsetX + RIGHT_PADDING * 2, offsetY, legendW - RIGHT_PADDING, legendH)

    context.fillStyle = textColor
    context.strokeStyle = textColor

    if (printing) {
      context.fillStyle = textColorPrint
      context.strokeStyle = textColorPrint
    }

    context.beginPath()
    context.fillText(
      `${numberWithCommasDecimals(minZ, 2)}`,
      RIGHT_PADDING + zRectX + legendW / 4,
      offsetY + legendH + 2,
    )
    context.fillText(
      `${numberWithCommasDecimals(maxZ, 2)}`,
      RIGHT_PADDING + zRectX + legendW / 4,
      offsetY + legendH * 0.02 + 2,
    )
    context.fillText(
      `${numberWithCommasDecimals((maxZ + minZ) / 2, 2)}`,
      RIGHT_PADDING + zRectX + legendW / 4,
      offsetY + legendH / 2 + 2,
    )

    context.fillText(
      `${params[getIndexAtAxis('Z')].variable} (${getUnitsForVariable(params[getIndexAtAxis('Z')].variable)})`,
      RIGHT_PADDING + zRectX + legendW,
      offsetY,
    )
  }

  const drawChartRect = (context, minX, maxX, stepX, minY, maxY, stepY) => {
    const { chartRectH, chartRectW, chartRectX, chartRectY } = calculateChartRect(context)
    let XL = chartRectX
    let XR = chartRectX + chartRectW
    let YT = chartRectY
    let YB = chartRectY + chartRectH

    let gradient = context.createLinearGradient(chartRectX, chartRectY, chartRectW, chartRectH)
    gradient.addColorStop(0, borderColor)
    gradient.addColorStop(1, backColor)

    if (!printing) context.fillStyle = gradient
    if (printing) context.fillStyle = backColorPrint

    context.beginPath()
    context.fillRect(chartRectX, chartRectY, chartRectW, chartRectH)

    context.fillStyle = gridColor
    context.strokeStyle = gridColor
    context.lineWidth = 1

    let step = stepX
    while (step <= maxX - stepX) {
      let x = getInterpolatedCoordinate(step, minX, maxX, XL, XR, 'x')
      context.beginPath()
      context.setLineDash([5, 3])
      context.moveTo(x, YT)
      context.lineTo(x, YB)
      context.stroke()
      step += stepX
    }

    step = minY + stepY
    while (step <= maxY - stepY) {
      let y = getInterpolatedCoordinate(step, minY, maxY, YB, YT, 'y')
      context.beginPath()
      context.moveTo(XL, y)
      context.lineTo(XR, y)
      context.stroke()
      step += stepY
    }

    context.fillStyle = borderColor
    context.strokeStyle = borderColor
    context.lineWidth = 1
    context.beginPath()
    context.setLineDash([0, 0])
    context.moveTo(XL, YT)
    context.lineTo(XR, YT)
    context.lineTo(XR, YB)
    context.lineTo(XL, YB)
    context.lineTo(XL, YT)
    context.stroke()
  }

  const drawData = (context, maxX, minX, stepX, maxY, minY, stepY) => {
    const { chartRectH, chartRectW, chartRectX, chartRectY } = calculateChartRect(context)
    if (!chartData) return
    if (!Array.isArray(chartData)) return

    let allBins = []
    for (let i = 0; i < chartData.length; i++) {
      let dataToDraw = chartData[i]

      if (dataToDraw.x < minX || dataToDraw.x >= maxX) continue
      if (dataToDraw.y < minY || dataToDraw.y >= maxY) continue

      let { xBin, yBin, zBin } = getBin(dataToDraw, minX, stepX, minY, stepY)
      allBins.push({ xBin, yBin, zBin })
    }

    let filledBins = fillBinsWithAverage(allBins, stepX, stepY)
    filledBinsRef.current = filledBins

    let minZ = 999999
    let maxZ = -999999

    for (let i = 0; i < filledBins.length; i++) {
      const { zSum, count } = filledBinsRef.current[i]
      const averageZ = zSum / count

      if (averageZ < minZ) minZ = averageZ
      if (averageZ > maxZ) maxZ = averageZ
    }

    if (params[getIndexAtAxis('Z')].autoScale) {
      params[getIndexAtAxis('Z')].min = minZ
      params[getIndexAtAxis('Z')].max = maxZ
    } else {
      minZ = params[getIndexAtAxis('Z')].min
      maxZ = params[getIndexAtAxis('Z')].max
    }

    for (let i = 0; i < filledBinsRef.current.length; i++) {
      const { xBin, yBin, zSum, count } = filledBinsRef.current[i]
      const averageZ = zSum / count

      fillBin(
        context,
        maxX,
        minX,
        stepX,
        maxY,
        minY,
        stepY,
        maxZ,
        minZ,
        chartRectX,
        chartRectY,
        chartRectH,
        chartRectW,
        xBin,
        yBin,
        averageZ,
      )
    }
  }

  const getBin = (dataToDraw, minX, stepX, minY, stepY) => {
    let xBin = Math.floor((dataToDraw.x - minX) / stepX)
    let yBin = Math.floor((dataToDraw.y - minY) / stepY) + 1
    let zBin = dataToDraw.z

    return { xBin, yBin, zBin }
  }

  const fillBinsWithAverage = (allBins) => {
    const filledBins = []
    const uniqueBins = new Set()

    for (let i = 0; i < allBins.length; i++) {
      const { xBin, yBin, zBin } = allBins[i]
      const binKey = `${xBin}-${yBin}`

      if (uniqueBins.has(binKey)) {
        const existingBin = filledBins.find((bin) => bin.key === binKey)
        if (zBin < existingBin.zMin) existingBin.zMin = zBin
        if (zBin > existingBin.zMax) existingBin.zMax = zBin
        existingBin.zSum += zBin
        existingBin.count++
      } else {
        uniqueBins.add(binKey)
        filledBins.push({
          key: binKey,
          xBin,
          yBin,
          zSum: zBin,
          zMin: zBin,
          zMax: zBin,
          count: 1,
        })
      }
    }

    return filledBins
  }

  const fillBin = (
    context,
    maxX,
    minX,
    stepX,
    maxY,
    minY,
    stepY,
    maxZ,
    minZ,
    chartRectX,
    chartRectY,
    chartRectH,
    chartRectW,
    xBin,
    yBin,
    binValue,
  ) => {
    let x = getInterpolatedCoordinate(minX + xBin * stepX, minX, maxX, chartRectX, chartRectX + chartRectW, 'x')
    let y = getInterpolatedCoordinate(minY + yBin * stepY, minY, maxY, chartRectY, chartRectY + chartRectH, 'y')

    let avgColorNorm = normalize(Math.abs(binValue), minZ, maxZ)

    let avgColor

    if (avgColorNorm <= 0.5) {
      avgColor = lerpColor(minDataColor, midDataColor, avgColorNorm * 2)
    } else {
      avgColor = lerpColor(midDataColor, maxDataColor, (avgColorNorm - 0.5) * 2)
    }

    const rectWidth = (chartRectW / (maxX - minX)) * stepX
    const rectHeight = (chartRectH / (maxY - minY)) * stepY

    context.fillStyle = avgColor
    context.fillRect(x, y, rectWidth, rectHeight)
  }

  const drawToolTipText = (context, minX, maxX, stepX, minY, maxY, stepY) => {
    if (!mousePosition.current) return
    const { chartRectH: h, chartRectW: w, chartRectX: x, chartRectY: y } = calculateChartRect(context)
    if (!hitTest({ x, y, w, h }, mousePosition.current)) return
    let { valAvg, valMin, valMax, xBin, yBin } = getNearestPointValFromBin(
      x,
      x + w,
      minX,
      maxX,
      mousePosition.current.x,
      stepX,
      y,
      y + h,
      minY,
      maxY,
      mousePosition.current.y,
      stepY,
    )

    if (valAvg === -999.25) return

    context.font = `${getFontString(context, 0.65)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = textColor
    context.strokeStyle = textColor

    const textData = `${params[getIndexAtAxis('Z')].variable}(Avg.) : ${numberWithCommasDecimals(
      valAvg,
      2,
    )} (${getUnitsForVariable(params[getIndexAtAxis('Z')].variable)})`

    const textDataMin = `${params[getIndexAtAxis('Z')].variable}(Min.) : ${numberWithCommasDecimals(
      valMin,
      2,
    )} (${getUnitsForVariable(params[getIndexAtAxis('Z')].variable)})`

    const textDataMax = `${params[getIndexAtAxis('Z')].variable}(Max.) : ${numberWithCommasDecimals(
      valMax,
      2,
    )} (${getUnitsForVariable(params[getIndexAtAxis('Z')].variable)})`

    const xL = xBin * stepX + minX
    const xR = xL + stepX
    const textX = `${params[getIndexAtAxis('X')].variable} : ${numberWithCommasDecimals(
      xL,
      1,
    )} - ${numberWithCommasDecimals(xR, 1)} (${getUnitsForVariable(params[getIndexAtAxis('X')].variable)})`

    const yB = (yBin - 1) * stepY + minY
    const yT = yB + stepY
    const textY = `${params[getIndexAtAxis('Y')].variable} : ${numberWithCommasDecimals(
      yB,
      1,
    )} - ${numberWithCommasDecimals(yT, 1)} (${getUnitsForVariable(params[getIndexAtAxis('Y')].variable)})`

    let textMetrics = context.measureText(textData)
    let textWidth = textMetrics.width
    textMetrics = context.measureText(textDataMin)
    if (textMetrics.width > textWidth) textWidth = textMetrics.width
    textMetrics = context.measureText(textDataMax)
    if (textMetrics.width > textWidth) textWidth = textMetrics.width
    textMetrics = context.measureText(textX)
    if (textMetrics.width > textWidth) textWidth = textMetrics.width
    textMetrics = context.measureText(textY)
    if (textMetrics.width > textWidth) textWidth = textMetrics.width
    const textHeight = parseInt(context.font, 10)

    const padding = 5
    const rectX = mousePosition.current.x - textWidth - padding
    const rectY = mousePosition.current.y - 5 * textHeight - padding
    const rectWidth = textWidth + 2 * padding
    const rectHeight = 5 * textHeight + 4 * padding

    context.fillStyle = backColor
    context.fillRect(rectX, rectY, rectWidth, rectHeight)

    context.fillStyle = textColor
    context.fillText(textData, rectX + rectWidth / 2, mousePosition.current.y - 40)
    context.fillText(textDataMin, rectX + rectWidth / 2, mousePosition.current.y - 30)
    context.fillText(textDataMax, rectX + rectWidth / 2, mousePosition.current.y - 20)
    context.fillText(textX, rectX + rectWidth / 2, mousePosition.current.y - 10)
    context.fillText(textY, rectX + rectWidth / 2, mousePosition.current.y)
  }

  const draw = (context) => {
    const {
      niceMin: niceMinX,
      niceMax: niceMaxX,
      niceStep: niceStepX,
    } = calculateNiceScales(getMin('x'), getMax('x'), getTicks('x'), 'x')

    const {
      niceMin: niceMinY,
      niceMax: niceMaxY,
      niceStep: niceStepY,
    } = calculateNiceScales(getMin('y'), getMax('y'), getTicks('y'), 'y')

    drawBackGround(context)
    drawTitle(context)
    drawXScale(context, niceMinX, niceMaxX, niceStepX)
    drawYScale(context, niceMinY, niceMaxY, niceStepY)
    drawChartRect(context, niceMinX, niceMaxX, niceStepX, niceMinY, niceMaxY, niceStepY)
    drawData(context, niceMaxX, niceMinX, niceStepX, niceMaxY, niceMinY, niceStepY)

    const {
      niceMin: niceMinZ,
      niceMax: niceMaxZ,
      niceStep: niceStepZ,
    } = calculateNiceScales(params[getIndexAtAxis('Z')].min, params[getIndexAtAxis('Z')].max, getTicks('Z'), 'z')

    drawZScale(context, niceMinZ, niceMaxZ, niceStepZ)
    drawToolTipText(context, niceMinX, niceMaxX, niceStepX, niceMinY, niceMaxY, niceStepY)
  }

  const handleCloseSettings = () => {
    setShowSettings(false)
  }

  const handleUpdateParams = (newParams) => {
    if (!newParams) return
    if (!Array.isArray(newParams)) return

    setParams(newParams)
  }

  const getParams = () => {
    if (!params) return []
    return cloneDeep(params)
  }

  const getVariableDropDownData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let returnData = []
    data.forEach((item, index) => {
      returnData.push({
        label: item.label,
        value: item.label,
      })
    })

    return returnData
  }

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        display: 'flex',
        height: '100%',
        width: '100%',
      }}
      id={id}
      ref={ref}>
      <Canvas
        square={false}
        draw={draw}
        width='100%'
        height='100%'
        style={{
          width: '100%',
          height: '100%',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          backgroundColor: '#429ceb',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Tooltip
          title={'Settings'}
          placement='bottom-start'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify
            icon={'fluent:settings-24-filled'}
            style={{ color: 'white', height: '40px', width: '40px', cursor: 'pointer' }}
            onClick={() => {
              setShowSettings(true)
            }}
          />
        </Tooltip>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 30,
          left: 10,
          width: '100px',
          height: '10px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {showBhaSelector ? (
          <DropDownPicker
            listOptions={bhas?.map((item) => {
              return {
                label: `BHA ${item?.bhaNum}`,
                value: item?.bhaNum,
              }
            })}
            value={selectedBha >= 0 ? selectedBha : ''}
            onChange={(e) => {
              setSelectedBha(e)
            }}
          />
        ) : null}
      </Box>
      {showSettings ? (
        <HeatMapSettingsModal
          open={showSettings}
          onClose={handleCloseSettings}
          data={getVariableDropDownData()}
          params={getParams()}
          handleUpdateParams={handleUpdateParams}
        />
      ) : null}
    </Box>
  )
}

export default HeatMapChart
