import { useEffect, useState, useRef } from 'react'

import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from './useInnovaAxios'

function useAnalyticsDropDowns(refetchData) {
  const _isMounted = useRef(true)
  const [dropDowns, setDropDowns] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [rawData, setRawData] = useState()

  const getData = useInnovaAxios({
    url: '/filterLists',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getDropDowns()
  }, [rawData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchRawData()
  }, [refetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRawData = async () => {
    if (isLoading) return
    setIsLoading(true)
    const response = await getData()
    if (_isMounted.current && response?.data) {
      setRawData(response.data)
    }
    setIsLoading(false)
  }

  async function getDropDowns() {
    if (!rawData) return
    let newDropDowns = {}

    for (const property in rawData) {
      newDropDowns[property] = []
      if (Array.isArray(rawData[property])) {
        rawData[property].forEach((val) => {
          if (typeof val === 'object') {
            newDropDowns[property].push(val)
          } else {
            newDropDowns[property].push({ label: unescapeHtml(val), value: unescapeHtml(val) })
          }
        })

        let uniqueArray = [...newDropDowns[property]]
        newDropDowns[property] = []

        for (let i = 0; i < uniqueArray.length; i++) {
          let found = false
          for (let j = 0; j < uniqueArray.length; j++) {
            if (uniqueArray[j].value === uniqueArray[i].value && i !== j) {
              found = true
              break
            }
          }

          if (!found) newDropDowns[property].push({ ...uniqueArray[i] })
        }
      }

      newDropDowns[property].sort((a, b) => a.label.localeCompare(b.label))
    }

    newDropDowns.rentalJobs = [{ label: 'Include', value: 'Include' }, { label: 'Exclude', value: 'Exclude' }, { label: 'Only', value: 'Only' }]
    newDropDowns.assemblyType = [{ label: 'All', value: 'All' }, { label: 'Motor', value: 'Motor' }, {label: 'MWD', value: 'MWD'}, { label: 'Rss', value: 'Rss' }, { label: 'Rotary', value: 'Rotary' }]
    newDropDowns.yesNo = [{ label: 'Yes', value: 'Yes' }, { label: 'No', value: 'No' }]

    if (_isMounted.current === true) {
      setDropDowns(newDropDowns)
    }
  }
  return [dropDowns, isLoading, rawData]
}

export default useAnalyticsDropDowns
