import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import AnalyticsBarChart from './AnalyticsBarChart'
import { appColors, array2pipestr } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsFilterDefsAtom, currentPageAtom, analyticsSelectedWells } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { round } from 'utils/numberFunctions'
import { Tooltip } from 'chart.js'
import moment from 'moment'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const RigDaysChart = () => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const { getUnitsText } = useUnits()
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const xlsExportData = useRef([])
  const xAxisRange = useRef({ minScale: '', maxScale: '', minLimit: '', maxLimit: '' })
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { theme } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  Tooltip.positioners.tooltipOnCursor = function (elements, eventPosition) {
    return {
      x: eventPosition.x,
      y: eventPosition.y,
    }
  }

  const chartOptions = {
    scales: {
      x: {
        min: xAxisRange.current.minScale,
        max: xAxisRange.current.maxScale,
        type: 'time',
        time: {
          displayFormats: {
            day: 'DD MMM YYYY',
          },
          unit: 'day',
        },
      },
      y: {
        stacked: false,
        ticks: {
          beginAtZero: true,
          precision: 0,
        },
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      tooltip: {
        position: 'tooltipOnCursor',
        callbacks: {
          title: function (tooltipItem) {
            return `${tooltipItem[0].raw.x}`
          },
          label: function (context) {
            return [`${context.dataset.label}: ${round(context.raw.y, 2)}`]
          },
        },
        intersect: true,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: xAxisRange.current.minLimit,
            max: xAxisRange.current.maxLimit,
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.rigDaysKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    xlsExportData.current = []
    fetchData()
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = ({ rigDays }) => {
    let output = {
      datasets: [
        {
          type: 'bar',
          label: '#Rigs',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
        {
          type: 'line',
          label: '10 Day Avg',
          backgroundColor: appColors.slideColor,
          borderColor: appColors.slideColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
        {
          type: 'line',
          label: '30 Day Avg',
          backgroundColor: appColors.avgColor,
          borderColor: appColors.avgColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!rigDays) return output

    let tenDayAvgValues = []
    let thirtyDayAvgValues = []
    let exportData = []
    let prevDay = ''
    let currentDay = ''

    const getAvgDays = (numRigs) => {
      tenDayAvgValues.push(numRigs)
      if (tenDayAvgValues.length > 10) {
        tenDayAvgValues = tenDayAvgValues.slice(1, tenDayAvgValues.length)
      }

      let tenDayTotal = tenDayAvgValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      let tenDayAvg = tenDayAvgValues.length > 0 ? tenDayTotal / tenDayAvgValues.length : 0

      thirtyDayAvgValues.push(numRigs)
      if (thirtyDayAvgValues.length > 30) {
        thirtyDayAvgValues = thirtyDayAvgValues.slice(1, thirtyDayAvgValues.length)
      }

      let thirtyDayTotal = thirtyDayAvgValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      let thirtyDayAvg = thirtyDayAvgValues.length > 0 ? thirtyDayTotal / thirtyDayAvgValues.length : 0

      return { tenDayAvg: tenDayAvg, thirtyDayAvg: thirtyDayAvg }
    }

    rigDays.forEach((day) => {
      currentDay = day.date
      if (prevDay === '') {
        prevDay = moment(day.date).subtract(1, 'days')
      }

      let daysDiff = moment(currentDay).diff(prevDay, 'days')

      if (daysDiff > 1) {
        for (let i = daysDiff - 1; i > 0; i--) {
          let newDate = moment(day.date).subtract(i, 'days').format('YYYY-MM-DD')
          let { tenDayAvg, thirtyDayAvg } = getAvgDays(0)
          output.datasets[1].data.push({ x: newDate, y: tenDayAvg })
          output.datasets[2].data.push({ x: newDate, y: thirtyDayAvg })
        }
      }
      let { tenDayAvg, thirtyDayAvg } = getAvgDays(day.numRigs)
      output.datasets[0].data.push({ x: day.date, y: day.numRigs })
      output.datasets[1].data.push({ x: day.date, y: tenDayAvg })
      output.datasets[2].data.push({ x: day.date, y: thirtyDayAvg })

      exportData.push({
        date: day.date,
        numRigs: day.numRigs,
        tenDayAvg: round(tenDayAvg, 2),
        thirtyDayAvg: round(thirtyDayAvg, 2),
      })

      prevDay = day.date
    })
    xlsExportData.current = exportData
    return output
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      rigDays: 'true',
    }

    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet([['Date', 'Rig Days', 'Ten Day Avg', 'Thirty Day Avg']], {
      origin: 'A1',
    })

    if (xlsExportData.current?.length > 0) {
      let xlsData = [...xlsExportData.current]
      let { dateFrom, dateTo } = searchParams
      if (dateFrom === '' || !dateFrom) {
        dateFrom = '1900-01-01'
      }
      if (dateTo === '' || !dateTo) {
        dateTo = '2500-01-01'
      }

      let filteredXlsData = []
      xlsData.forEach((item) => {
        if (
          moment(item.date).isSame(dateFrom) ||
          moment(item.date).isSame(dateTo) ||
          moment(item.date).isBetween(dateFrom, dateTo)
        ) {
          filteredXlsData.push(item)
        }
      })

      XLSX.utils.sheet_add_aoa(
        ws,
        filteredXlsData.map((item) => [item.date, item.numRigs, item.tenDayAvg, item.thirtyDayAvg]),
        { origin: 'A2' },
      )
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Rig Days')
    XLSX.writeFile(wb, 'Rig Days.xlsx')
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={getUnitsText(UNITS_FOR.Depth)}
      title={'Rig Days'}
      xAxisTitle='Day'
      yAxisTitle={`Rig Count`}
      chartOptions={chartOptions}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default RigDaysChart
