import React, { useEffect, useRef, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import cloneDeep from 'lodash/cloneDeep'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import SplitPane from 'components/common/SplitPane'
import FacilityGrid from './FacilityGrid'
import { useRecoilState } from 'recoil'
import { isEqual } from 'lodash'
import { facilityPropertiesLayoutAtom, dateBaseTreeUpdateNameAtom } from 'atoms'
import { CircularProgress, Box, Tooltip, Alert, Snackbar } from '@mui/material'
import CoordinatesGrid from 'components/DatabaseTree/common/CoordinatesGrid'
import { Icon as Iconify } from '@iconify/react'
import MapView from 'components/common/MapView/MapView'

const FacilityPropertiesPage = ({ facility, nodeId }) => {
  const _isMounted = useRef(false)
  const isUpdatingRef = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [facilityData, setFacilityData] = useState(null)
  const [pageLayout, setPageLayout] = useRecoilState(facilityPropertiesLayoutAtom)
  const [dataChanged, setDataChanged] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const facilityDataRef = useRef(facilityData)
  const orgDataRef = useRef(facilityData)
  const [databaseTreeUpdateName, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const resetCoordinates = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getFacility = useInnovaAxios({
    url: '/facility/getFacility',
  })

  const updateFacility = useInnovaAxios({
    url: '/facility/updateFacility',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!facilityDataRef.current) return
    if (data.caller === 'UI') return
    if (data.nodeId !== nodeId) return

    let newData = cloneDeep(facilityDataRef.current)
    newData.facility = data.name
    facilityDataRef.current.facility = data.name
    orgDataRef.current.facility = data.name
    setFacilityData(newData)
    setDataBaseTreeNameUpdate(null)
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '70%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getFacility({ facility: facility })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    facilityDataRef.current = cloneDeep(res.data[0])
    orgDataRef.current = cloneDeep(res.data[0])
    setFacilityData(res.data[0])
  }

  const updateFacilityDataCoords = (data) => {
    if (!data) return

    let newData = cloneDeep(facilityDataRef.current)
    newData.latDeg = data.latDeg
    newData.longDeg = data.longDeg
    newData.gridNorth = data.gridNs
    newData.gridEast = data.gridEw
    newData.gridConv = data.gridConv

    if (data.inputMeth === 'dms' || data.inputMeth === 'deg') {
      newData.inputMeth = 'Lat Long'
    }

    if (data.inputMeth === 'grid') {
      newData.inputMeth = 'Map Coordinates'
    }

    setDataChanged(!isEqual(orgDataRef.current, newData))
    facilityDataRef.current = newData
    setFacilityData(newData)
  }

  const updateFacilityData = (data) => {
    if (!data) return

    let newData = cloneDeep(facilityDataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      if (newData[data.tag] === data.value) return
      newData[data.tag] = data.value
    }

    setDataChanged(!isEqual(orgDataRef.current, newData))
    facilityDataRef.current = newData
    setFacilityData(newData)
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    let res = await updateFacility(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setUpdating(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (orgDataRef.current.facility !== data.facility) {
      setDataBaseTreeNameUpdate({ name: data.facility, nodeId: nodeId, caller: 'UI' })
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => handleUpdate(facilityDataRef.current)}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          facilityDataRef.current = cloneDeep(orgDataRef.current)
          resetCoordinates.current = true
          setDataChanged(false)
          setFacilityData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const getFacilityArray = () => {
    return facilityData
      ? [
          {
            id: 0,
            actualWell: facilityData.facility,
            latitude: facilityData.latDeg,
            longitude: facilityData.longDeg,
          },
        ]
      : []
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(0)}
        allowResize={true}
        minSize={'10%'}
        defaultSize={getInitialPaneSize(0)}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          minSize={'10%'}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}>
          <FacilityGrid facilityData={facilityData} handleUpdate={updateFacilityData} />
          <CoordinatesGrid
            resetCoordinates={resetCoordinates}
            coords={
              facilityDataRef.current
                ? {
                    latDeg: facilityDataRef.current?.latDeg,
                    longDeg: facilityDataRef.current?.longDeg,
                    gridNs: facilityDataRef.current?.gridNorth,
                    gridEw: facilityDataRef.current?.gridEast,
                    localNs: 0,
                    localEw: 0,
                    gridConv: facilityDataRef.current?.gridConv,
                  }
                : null
            }
            options={{
              dms: true,
              deg: true,
              grid: true,
              local: false,
              input: facilityDataRef.current?.inputMeth === 'Lat Long' ? 'dms' : 'grid',
            }}
            crs={facilityDataRef.current ? facilityDataRef.current?.crs : null}
            updateData={updateFacilityDataCoords}
          />
        </SplitPane>
        {facilityData ? (
          <MapView
            wells={getFacilityArray()}
            center={{ lat: facilityData.latDeg, lng: facilityData.longDeg }}
          />
        ) : (
          <Box />
        )}
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default FacilityPropertiesPage
