import React, { useEffect, useRef, useState, useMemo } from 'react'
import { currentPageAtom, actionBarWidthAtom } from '../../../atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from '../../ActionBar/pageDefs'
import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import MenuButton from 'components/common/MenuButton'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { useRecoilValue } from 'recoil'
import useSlideSheets from 'components/common/hooks/useSlideSheets'
import SlideSheetGrid from './SlideSheetGrid'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import SplitPane from 'components/common/SplitPane'
import { appColors } from 'utils'
import HeatMapChart from 'components/common/Heat Map/HeatMapChart'
import useDrillingParams from 'components/common/hooks/Engineering/useDrillingParams'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const SlideSheetPage = () => {
  const _isMounted = useRef(false)
  const [slideSheetData, setSlideSheetData] = useState([])
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const {
    fetchSlideSheets,
    fetchSlideSheet,
    fetchSlideSheetData,
    loading: isLoading,
    getSlideSheetPdfData,
    getSlideSheetXlsData,
  } = useSlideSheets()
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const { getSlideSheetData } = useDrillingParams()
  const showChartRef = useRef(showChart)
  const [selectedBha, setSelectedBha] = useState(null)

  useEffect(() => {
    showChartRef.current = showChart
  }, [showChart])

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.slideSheetKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading) {
      setSlideSheetData(fetchSlideSheetData())
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const onPdfExport = async (bhaNums) => {
    if (!Array.isArray(bhaNums)) return
    const pdfDoc = await getSlideSheetPdfData(bhaNums)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onXlsExport = (bhaNums, bhaNumRep) => {
    getSlideSheetXlsData(bhaNums, bhaNumRep)
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          setSlideSheetData([])
          fetchSlideSheets()
        },
      },
      {
        icon: <PictureAsPdfIcon />,
        name: 'PDF',
        onClick: () => {
          if (!slideSheetData || !Array.isArray(slideSheetData)) return
          let bhaNums = []
          for (let i = slideSheetData.length - 1; i >= 0; i--) {
            bhaNums.push(slideSheetData[i].bhaNum)
          }
          onPdfExport(bhaNums)
        },
      },
      {
        icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
        name: 'Export to XLSX',
        onClick: () => getBhaNumsForXlsExport(),
      },
    ]
    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Chart',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Chart',
        onClick: () => handleShowChart(),
      })
    }
    return actions
  }

  const getBhaNums = () => {
    if (!slideSheetData) return
    if (!Array.isArray(slideSheetData)) return
    if (slideSheetData.length <= 0) return

    let bhaNums = []
    slideSheetData.forEach((item, index) => {
      bhaNums.push({
        bhaNum: item.bhaNumRep,
      })
    })

    return bhaNums
  }

  const getBhaNumsForXlsExport = () => {
    if (!slideSheetData) return
    if (!Array.isArray(slideSheetData)) return
    if (slideSheetData.length <= 0) return

    let bhaNumsArray = slideSheetData.map((item) => item?.bhaNum).reverse()
    onXlsExport(bhaNumsArray)
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const SlideSheet = useMemo(
    () => (
      <SlideSheetGrid
        slideSheetData={slideSheetData.length > 0 ? slideSheetData : []}
        isLoading={isLoading}
        fetchSlideSheet={fetchSlideSheet}
        onPdfExport={onPdfExport}
        onXlsExport={onXlsExport}
        setShowChart={setShowChart}
        showChart={showChartRef}
        wellName={slideSheetData.length > 0 ? slideSheetData[0].actualWell : []}
        setSelectedBha={setSelectedBha}
      />
    ),
    [slideSheetData, isLoading], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <React.Fragment>
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={paneSize}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <HeatMapChart data={getSlideSheetData('heatMap')} bhas={getBhaNums()} showBhaSelector ={false} bhaNum = {selectedBha}/>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          {SlideSheet}
        </Box>
      </SplitPane>
      <StyledMenuIcon>
        <MenuButton actions={getMenuItems()} />
      </StyledMenuIcon>
    </React.Fragment>
  )
}

export default SlideSheetPage
