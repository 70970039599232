import React, { useState, useRef, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { Backdrop, CircularProgress, Box } from '@mui/material'
import { cloneDeep } from 'lodash'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const OracleCostImportModal = ({ wellName, onClose }) => {
  const _isMounted = useRef()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [oracleCostCodes, setOracleCostCodes] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [selectedCostCodes, setSelectedCostCodes] = useState(null)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const [searchText, setSearchText] = useState('')

  const getOracleCostCodes = useInnovaAxios({
    url: '/oracle/getCostCodes',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchCostCodes()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCostCodes = async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    if (isLoading) return
    setLoading(true)
    const res = await getOracleCostCodes({ wellName: wellName })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
    }

    if (!Array.isArray(res.data)) return
    setOracleCostCodes(res.data)
  }

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: 'Cost Code',
        field: 'E_TYPE',
        colId: 'E_TYPE',
        valueGetter: (params) => {
          return unescapeHtml(params?.data?.E_TYPE)
        },
      },
      {
        headerName: 'Description',
        field: 'E_TYPE_DESCRIPTION',
        colId: 'E_TYPE_DESCRIPTION',
        valueGetter: (params) => {
          return unescapeHtml(params?.data?.E_TYPE_DESCRIPTION)
        },
      },
      {
        headerName: 'Bid',
        field: 'RATE_SCHEDULE',
        colId: 'RATE_SCHEDULE',
        valueGetter: (params) => {
          return unescapeHtml(params?.data?.RATE_SCHEDULE)
        },
      },
      {
        headerName: 'Rate',
        field: 'RATE',
        colId: 'RATE',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          return numberWithCommasDecimals(parseFloat(params?.data?.RATE), 2)
        },
      },
    ],
    [centerAlignCell],
  )

  const onClickImport = async () => {
    onClose(selectedCostCodes)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseDialog = async () => {
    onClose(null)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    if (!_isMounted.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      setSelectedCostCodes(null)
    }

    if (selectedRows.length > 0) {
      setSelectedCostCodes(cloneDeep(selectedRows))
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            height: '80vh',
            width: '80vw',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={handleCloseDialog}>
        <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <DialogTitle>Oracle Costs</DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              width: '100%',
              height: '50px',
            }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle()}
            />
          </Box>
          <Box
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              height: 'calc(100% - 50px)',
              width: `100%`,
            }}>
            <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
              <AgGridReact
                gridOptions={gridOptions}
                rowData={oracleCostCodes}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                rowSelection='multiple'
                enableBrowserTooltips={true}
                headerHeight={30}
                onGridReady={onGridReady}
                onSelectionChanged={onSelectionChanged}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          {selectedCostCodes ? (
            <Button color='primary' variant='contained' onClick={onClickImport}>
              Import Cost Codes
            </Button>
          ) : null}
          <Button color='primary' variant='contained' onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default OracleCostImportModal
