import React, { useRef, useState } from 'react'
import { Box, Tooltip, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material'

import { Icon as Iconify } from '@iconify/react'
import { uuidv4 } from 'utils/stringFunctions'
import { appColors } from 'utils'
import {
  ELEMENT_TYPES as elementType,
  TABLE_TYPES as tableType,
  CHART_TYPES as chartType,
} from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import PageSizeModal from './PageSizeModal'

const BUTTON_SIZE = 32

const ContextMenuItemIcon = ({ icon, color, isImage }) => {
  if (isImage) {
    return (
      <img
        alt={'Icon'}
        src={icon}
        style={{
          width: `15px`,
          height: `15px`,
        }}
      />
    )
  }

  return <Iconify icon={icon} width={'15px'} height={'15px'} color={color || '#a9abac'} />
}

const ToolbarItemIcon = ({ icon, color, isImage }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
      }}>
      {isImage ? (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${BUTTON_SIZE - 4}px`,
            height: `${BUTTON_SIZE - 4}px`,
          }}
        />
      ) : (
        <Iconify
          icon={icon}
          width={`${BUTTON_SIZE - 4}px`}
          height={`${BUTTON_SIZE - 4}px`}
          color={color || '#a9abac'}
        />
      )}
    </Box>
  )
}

const ToolbarAddElementIconList = ({ icon, color, isImage, action }) => {
  const ref = useRef(null)
  const [showList, setShowList] = useState(false)
  const [showImageMenuItems, setShowImageMenuItems] = useState(false)
  const [showTableMenuItems, setShowTableMenuItems] = useState(false)
  const [showChartMenuItems, setShowChartMenuItems] = useState(false)

  const onClose = () => {
    setShowImageMenuItems(false)
    setShowTableMenuItems(false)
    setShowChartMenuItems(false)
    setShowList(false)
  }

  const getAddElementOptionsList = (action) => {
    let menuItems = []
    menuItems.push({
      icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
      label: 'Text',
      onClick: () => {
        action(elementType.text)
        return true
      },
    })
    menuItems.push({
      isCollapsible: true,
      icon: <ContextMenuItemIcon icon={'icon-park:add-one'} color={'#000000'} />,
      endIcon: showImageMenuItems ? (
        <ContextMenuItemIcon icon={'carbon:chevron-up'} color={'#000000'} />
      ) : (
        <ContextMenuItemIcon icon={'carbon:chevron-down'} color={'#000000'} />
      ),
      label: 'Add image components ',
      onClick: (e) => {
        e.stopPropagation()
        setShowImageMenuItems((prevValue) => !prevValue)
        return false
      },
    })
    if (showImageMenuItems) {
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Org Logo',
        onClick: () => {
          action(elementType.imageOrgLogo)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Operator Logo (primary)',
        onClick: () => {
          action(elementType.imageOperLogo)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Operator Logo (secondary)',
        onClick: () => {
          action(elementType.imageOperLogo2)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'User Image',
        onClick: () => {
          action(elementType.image)
          return true
        },
      })
    }
    menuItems.push({
      isCollapsible: true,
      icon: <ContextMenuItemIcon icon={'icon-park:add-one'} color={'#000000'} />,
      endIcon: showTableMenuItems ? (
        <ContextMenuItemIcon icon={'carbon:chevron-up'} color={'#000000'} />
      ) : (
        <ContextMenuItemIcon icon={'carbon:chevron-down'} color={'#000000'} />
      ),
      label: 'Add table component ',
      onClick: (e) => {
        e.stopPropagation()
        setShowTableMenuItems((prevValue) => !prevValue)
        return false
      },
    })
    if (showTableMenuItems) {
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Project Detail Table',
        onClick: () => {
          action(elementType.table, tableType.projectDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'References Table',
        onClick: () => {
          action(elementType.table, tableType.references)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Field Details Table',
        onClick: () => {
          action(elementType.table, tableType.fieldDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Facility Details Table',
        onClick: () => {
          action(elementType.table, tableType.facilityDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Well Details Table',
        onClick: () => {
          action(elementType.table, tableType.wellDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Wellbore Details Table',
        onClick: () => {
          action(elementType.table, tableType.wellboreDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Survey Program Table',
        onClick: () => {
          action(elementType.table, tableType.surveyProgram)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Casing Details Table',
        onClick: () => {
          action(elementType.table, tableType.casingDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Lithology Table',
        onClick: () => {
          action(elementType.table, tableType.lithology)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Target Details Table',
        onClick: () => {
          action(elementType.table, tableType.targetDetails)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Summary Table',
        onClick: () => {
          action(elementType.table, tableType.summary)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Approvals',
        onClick: () => {
          action(elementType.table, tableType.approvals)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Lease Lines Table',
        onClick: () => {
          action(elementType.table, tableType.leaseLine)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Annotations Table',
        onClick: () => {
          action(elementType.table, tableType.annotations)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Mapping Grid Table',
        onClick: () => {
          action(elementType.table, tableType.mappingGrid)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Title Bar',
        onClick: () => {
          action(elementType.table, tableType.titleBar)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'icon-park:rectangle'} color={'#000000'} />,
        submenu: true,
        label: 'Datum Elevation Table',
        onClick: () => {
          action(elementType.table, tableType.datumElevation)
          return true
        },
      })
    }
    menuItems.push({
      isCollapsible: true,
      icon: <ContextMenuItemIcon icon={'icon-park:add-one'} color={'#000000'} />,
      endIcon: showChartMenuItems ? (
        <ContextMenuItemIcon icon={'carbon:chevron-up'} color={'#000000'} />
      ) : (
        <ContextMenuItemIcon icon={'carbon:chevron-down'} color={'#000000'} />
      ),
      label: 'Add chart component ',
      onClick: (e) => {
        e.stopPropagation()
        setShowChartMenuItems((prevValue) => !prevValue)
        return false
      },
    })
    if (showChartMenuItems) {
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'bi:graph-down'} color={'#000000'} />,
        submenu: true,
        label: 'Plan View',
        onClick: () => {
          action(elementType.chart, chartType.planView)
          return true
        },
      })
      menuItems.push({
        icon: <ContextMenuItemIcon icon={'bi:graph-down'} color={'#000000'} />,
        submenu: true,
        label: 'Section View',
        onClick: () => {
          action(elementType.chart, chartType.sectionView)
          return true
        },
      })
    }
    return menuItems
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
      }}
      onClick={() => {
        setShowList(!showList)
      }}>
      {isImage ? (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${BUTTON_SIZE - 4}px`,
            height: `${BUTTON_SIZE - 4}px`,
          }}
        />
      ) : (
        <Iconify
          icon={icon}
          width={`${BUTTON_SIZE - 4}px`}
          height={`${BUTTON_SIZE - 4}px`}
          color={color || '#a9abac'}
        />
      )}

      {showList ? (
        <Menu
          sx={{
            zIndex: 10001,
            mt: '1px',
            '& .MuiListItemText-root': { padding: 0, color: '#000000' },
            '& .MuiListItemIcon-root': { padding: 0, color: '#000000' },
            '& .MuiMenu-paper': { backgroundColor: '#DDDDDD', border: '1px solid gray' },
          }}
          open={showList}
          onClose={onClose}
          anchorEl={ref.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          {getAddElementOptionsList(action).map((item, index) => {
            if (item.isDivider) {
              return <Divider key={index} sx={{ bgcolor: 'primary.main' }} />
            }
            if (item.isCollapsible) {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    if (item.onClick) {
                      if (item.onClick(e)) onClose()
                    }
                  }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
                  <ListItemIcon>{item.endIcon}</ListItemIcon>
                </MenuItem>
              )
            }
            return (
              <MenuItem
                style={{ marginLeft: `${item.submenu ? '12px' : '0px'}` }}
                key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  if (item.onClick) {
                    if (item.onClick(e)) onClose()
                  }
                }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
              </MenuItem>
            )
          })}
        </Menu>
      ) : null}
    </Box>
  )
}

const ToolbarItemIconList = ({ icon, color, isImage, menuItems }) => {
  const ref = useRef(null)
  const [showList, setShowList] = useState(false)

  const onClose = () => {
    setShowList(false)
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: `${BUTTON_SIZE}px`,
        height: `${BUTTON_SIZE}px`,
      }}
      onClick={() => {
        setShowList(!showList)
      }}>
      {isImage ? (
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${BUTTON_SIZE - 4}px`,
            height: `${BUTTON_SIZE - 4}px`,
          }}
        />
      ) : (
        <Iconify
          icon={icon}
          width={`${BUTTON_SIZE - 4}px`}
          height={`${BUTTON_SIZE - 4}px`}
          color={color || '#a9abac'}
        />
      )}
      {showList ? (
        Array.isArray(menuItems) && menuItems.length > 0 ? (
          <Menu
            sx={{
              zIndex: 10001,
              mt: '1px',
              '& .MuiMenu-paper': { backgroundColor: '#DDDDDD', border: '1px solid gray' },
            }}
            open={showList}
            onClose={onClose}
            anchorEl={ref.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            {menuItems.map((item, index) => {
              if (item.isDivider) {
                return <Divider key={index} sx={{ bgcolor: 'primary.main' }} />
              }
              if (item.isCollapsible) {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      if (item.onClick) {
                        if (item.onClick()) onClose()
                      }
                    }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText sx={{ color: '#000000' }}>{item.label}</ListItemText>
                    <ListItemIcon>{item.endIcon}</ListItemIcon>
                  </MenuItem>
                )
              }
              return (
                <MenuItem
                  key={index}
                  sx={{
                    color: item.selected ? '#DDDDDD' : '#000000',
                    backgroundColor: item.selected ? '#404040' : '#DDDDDD',
                    '&:hover': {
                      backgroundColor: '#808080',
                      color: '#000000',
                    },
                  }}
                  onClick={() => {
                    if (item.onClick) {
                      if (item.onClick()) onClose()
                    }
                  }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              )
            })}
          </Menu>
        ) : null
      ) : null}
    </Box>
  )
}

const getOrientationList = (callback, currentOrientation) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-landscape-20-regular'} color={'#000000'} />,
    label: `Landscape`,
    selected: currentOrientation === 'landscape',
    onClick: () => callback({ orientation: 'landscape' }),
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={'#000000'} />,
    label: 'Portrait',
    selected: currentOrientation === 'portrait',
    onClick: () => callback({ orientation: 'portrait' }),
  })
  return menuItems
}

const getMenuIconColor = (checkSize, currentSize) => {
  return checkSize === currentSize ? appColors.headerTextColor : '#000000'
}

const getPageSizeList = (callback, currentSize, callbackCustomSize) => {
  let menuItems = []
  menuItems.push({
    icon: (
      <ContextMenuItemIcon
        icon={'fluent:document-landscape-20-regular'}
        color={getMenuIconColor(currentSize, 'letter')}
      />
    ),
    label: `Letter 8.5" x 11"`,
    selected: currentSize === 'letter',
    onClick: () => {
      callback({ size: 'letter', width: 8.5, height: 11 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'tabloid')} />,
    label: 'Tabloid 11" x 17"',
    selected: currentSize === 'tabloid',
    onClick: () => {
      callback({ size: 'tabloid', width: 11, height: 17 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'legal')} />,
    label: 'Legal 8.5" x 14"',
    selected: currentSize === 'legal',
    onClick: () => {
      callback({ size: 'legal', width: 8.5, height: 14 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A0')} />,
    label: 'A0 841mm x 1188mm',
    selected: currentSize === 'A0',
    onClick: () => {
      callback({ size: 'A0', width: 841 / 25.4, height: 1188 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A1')} />,
    label: 'A1 594mm x 841mm',
    selected: currentSize === 'A1',
    onClick: () => {
      callback({ size: 'A1', width: 594 / 25.4, height: 841 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A2')} />,
    label: 'A2 420mm x 594mm',
    selected: currentSize === 'A2',
    onClick: () => {
      callback({ size: 'A2', width: 420 / 25.4, height: 594 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A3')} />,
    label: 'A3 297mm x 420mm',
    selected: currentSize === 'A3',
    onClick: () => {
      callback({ size: 'A3', width: 297 / 25.4, height: 420 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A4')} />,
    label: 'A4 210mm x 297mm',
    selected: currentSize === 'A4',
    onClick: () => {
      callback({ size: 'A4', width: 210 / 25.4, height: 297 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'A5')} />,
    label: 'A5 148mm x 210mm',
    selected: currentSize === 'A5',
    onClick: () => {
      callback({ size: 'A5', width: 148 / 25.4, height: 210 / 25.4 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'fluent:document-20-regular'} color={getMenuIconColor(currentSize, 'Custom')} />,
    label: 'Custom',
    selected: currentSize === 'Custom',
    onClick: () => {
      callbackCustomSize(true) // open custom size modal
    },
  })
  return menuItems
}

const getZoomOptionsList = (units, callback) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'material-symbols:percent'} color={'#000000'} />,
    label: `50%`,
    selected: false,
    onClick: () => {
      callback('50%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'material-symbols:percent'} color={'#000000'} />,
    label: `75%`,
    selected: false,
    onClick: () => {
      callback('75%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'material-symbols:percent'} color={'#000000'} />,
    label: `100% (Whole page)`,
    selected: false,
    onClick: () => {
      callback('100%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'material-symbols:percent'} color={'#000000'} />,
    label: `200%`,
    selected: false,
    onClick: () => {
      callback('200%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'material-symbols:percent'} color={'#000000'} />,
    label: `300%`,
    selected: false,
    onClick: () => {
      callback('300%')
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'material-symbols:percent'} color={'#000000'} />,
    label: `Page Width`,
    selected: false,
    onClick: () => {
      callback('pageWidth')
    },
  })
  return menuItems
}

const getMarginOptionsList = (units, callback, currentMargin) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Wide 1${units === 'm' ? 'cm' : 'in'}`,
    selected: currentMargin === 1.0,
    onClick: () => {
      callback({ marginTop: 1, marginBottom: 1, marginLeft: 1, marginRight: 1 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Normal 0.5${units === 'm' ? 'cm' : 'in'}`,
    selected: currentMargin === 0.5,
    onClick: () => {
      callback({ marginTop: 0.5, marginBottom: 0.5, marginLeft: 0.5, marginRight: 0.5 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Narrow 0.25${units === 'm' ? 'cm' : 'in'}`,
    selected: currentMargin === 0.25,
    onClick: () => {
      callback({ marginTop: 0.25, marginBottom: 0.25, marginLeft: 0.25, marginRight: 0.25 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `None`,
    selected: currentMargin === 0.0,
    onClick: () => {
      callback({ marginTop: 0.0, marginBottom: 0.0, marginLeft: 0.0, marginRight: 0.0 })
    },
  })
  return menuItems
}

const getGridOptionsList = (units, callback, currentSpacing) => {
  let menuItems = []
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Wide 1${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 1.0,
    onClick: () => {
      callback({ gridSpacing: 1.0 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Normal 0.5${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.5,
    onClick: () => {
      callback({ gridSpacing: 0.5 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Small 0.25${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.25,
    onClick: () => {
      callback({ gridSpacing: 0.25 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Narrow 0.125${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.125,
    onClick: () => {
      callback({ gridSpacing: 0.125 })
    },
  })
  menuItems.push({
    icon: <ContextMenuItemIcon icon={'radix-icons:margin'} color={'#000000'} />,
    label: `Tight 0.0625${units === 'm' ? 'cm' : 'in'}`,
    selected: currentSpacing === 0.0625,
    onClick: () => {
      callback({ gridSpacing: 0.0625 })
    },
  })
  return menuItems
}

const WpToolbar = ({ actions, selectedUids, config, units, pageLayout }) => {
  const { getWindowBarColor } = useInnovaTheme()

  const [showPageSizeModal, setShowPageSizeModal] = useState(false)
  const getToolBarItems = () => {
    let toolBarItems = [
      {
        icon: <ToolbarItemIcon icon={'pajamas:doc-new'} color={appColors.itemTextColor} />,
        label: 'New layout',
        onClick: () => {
          actions.onNewLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:save-28-regular'} color={'#00AA00'} />,
        label: 'Save layout',
        onClick: () => {
          actions.onSaveLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:save-as-24-regular'} color={'#00AA00'} />,
        label: 'Save layout as...',
        onClick: () => {
          actions.onSaveLayout(true)
        },
      },
      {
        icon: <ToolbarItemIcon icon={'material-symbols:file-open-outline-rounded'} color={'#AAAA00'} />,
        label: 'Load/manage layouts',
        onClick: () => {
          actions.onLoadLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'heroicons-outline:document-duplicate'} color={'#AAAA00'} />,
        label: 'Duplicate layout',
        onClick: () => {
          actions.onDuplicateLayout()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'material-symbols:print-outline'} color={'#FFF'} />,
        label: 'Print',
        onClick: () => {
          actions.onPrint()
        },
      },
      {
        isDivider: true,
      },
      {
        icon: (
          <ToolbarAddElementIconList
            icon={'icon-park:add-one'}
            color={appColors.itemTextColor}
            action={actions.addElement}
          />
        ),
        label: 'Add element',
      },
      {
        icon: <ToolbarItemIcon icon={'fa-regular:trash-alt'} color={'#C00000'} />,
        label: 'Delete elements',
        onClick: () => {
          actions.onDeleteElements()
        },
      },
      {
        isDivider: true,
      },
      {
        icon: <ToolbarItemIcon icon={'material-symbols:settings-outline'} color={appColors.itemTextColor} />,
        label: 'Properties',
        onClick: () => {
          actions.onShowProperties()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:arrow-undo-24-regular'} color={appColors.itemTextColor} />,
        label: 'Undo',
        onClick: () => {
          actions.onUndo()
        },
      },
      {
        icon: <ToolbarItemIcon icon={'fluent:arrow-redo-24-regular'} color={appColors.itemTextColor} />,
        label: 'Redo',
        onClick: () => {
          actions.onRedo()
        },
      },
      {
        isDivider: true,
      },
      {
        icon: <ToolbarItemIcon icon={'carbon:bring-to-front'} color={appColors.itemTextColor} />,
        label: 'Bring to front',
        onClick: () => {
          if (actions.setZIndex) actions.setZIndex(selectedUids, 'front')
        },
      },
      {
        icon: <ToolbarItemIcon icon={'carbon:send-to-back'} color={appColors.itemTextColor} />,
        label: 'Send to back',
        onClick: () => {
          if (actions.setZIndex) actions.setZIndex(selectedUids, 'back')
        },
      },
      {
        isDivider: true,
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'radix-icons:ruler-square'}
            color={config?.showRulers ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Rulers',
        onClick: () => {
          actions.setConfig((oldConfig) => {
            oldConfig.showRulers = !oldConfig.showRulers
            return { ...oldConfig }
          })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'material-symbols-light:background-grid-small-outline'}
            color={config?.showGrid ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Show grid',
        onClick: () => {
          actions.setConfig((oldConfig) => {
            oldConfig.showGrid = !oldConfig.showGrid
            return { ...oldConfig }
          })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'fluent-mdl2:snap-to-grid'}
            color={config?.snapToGrid ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Snap to grid',
        onClick: () => {
          actions.setConfig((oldConfig) => {
            oldConfig.snapToGrid = !oldConfig.snapToGrid
            return { ...oldConfig }
          })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'radix-icons:margin'}
            color={config?.showMargins ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Show margins',
        onClick: () => {
          actions.setConfig((oldConfig) => {
            oldConfig.showMargins = !oldConfig.showMargins
            return { ...oldConfig }
          })
        },
      },
      {
        icon: (
          <ToolbarItemIcon
            icon={'tabler:zoom-reset'}
            color={config?.showMargins ? '#00CC00' : appColors.headerTextColor}
          />
        ),
        label: 'Reset zoom',
        onClick: () => {
          if (actions.setZoom) actions.setZoom(1)
        },
      },
      {
        isDivider: true,
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'fluent:orientation-20-regular'}
            color={appColors.itemTextColor}
            menuItems={getOrientationList((e) => {
              actions.commitPageLayout(e)
            }, pageLayout?.orientation)}
          />
        ),
        label: 'Page orientation',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'fluent-mdl2:size-legacy'}
            color={appColors.itemTextColor}
            menuItems={getPageSizeList(
              (e) => {
                actions.commitPageLayout(e)
              },
              pageLayout?.size,
              setShowPageSizeModal,
            )}
          />
        ),
        label: 'Page size',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'material-symbols-light:background-grid-small-outline'}
            color={appColors.itemTextColor}
            menuItems={getGridOptionsList(
              units,
              (e) => {
                actions.commitPageLayout(e)
              },
              pageLayout?.gridSpacing,
            )}
          />
        ),
        label: 'Grid Spacing',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'radix-icons:margin'}
            color={appColors.itemTextColor}
            menuItems={getMarginOptionsList(
              units,
              (e) => {
                actions.commitPageLayout(e)
              },
              pageLayout?.marginTop,
            )}
          />
        ),
        label: 'Margins',
      },
      {
        icon: (
          <ToolbarItemIconList
            icon={'octicon:zoom-in-16'}
            color={appColors.itemTextColor}
            menuItems={getZoomOptionsList(units, (e) => {
              actions.setZoom(e)
            })}
          />
        ),
        label: 'Zoom',
      },
    ]
    return toolBarItems
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: `${BUTTON_SIZE + 2}px`,
        background: getWindowBarColor(),
        display: 'flex',
        alignItems: 'center',
        padding: '2px',
        border: '1px solid #000',
      }}>
      {showPageSizeModal && (
        <PageSizeModal
          units={units}
          cancelFunction={() => setShowPageSizeModal(false)}
          submitFunction={(e) => {
            actions.commitPageLayout(e)
            setShowPageSizeModal(false)
          }}
          width={pageLayout.width}
          height={pageLayout.height}
        />
      )}
      {getToolBarItems().map((item) =>
        item.isDivider ? (
          <Iconify
            key={uuidv4()}
            icon={'fluent:divider-tall-16-regular'}
            width={`${BUTTON_SIZE - 4}px`}
            height={`${BUTTON_SIZE - 4}px`}
            color={'#a9abac'}
          />
        ) : (
          <Tooltip
            key={uuidv4()}
            title={item.label}
            placement='bottom'
            PopperProps={{
              style: { zIndex: 9999 }, //Required to draw on top of ruler
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <Box
              sx={{
                width: `${BUTTON_SIZE}px`,
                height: `${BUTTON_SIZE}px`,
                color: '#FFFFFF',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'rgba(255,255,255,0.1)',
                },
              }}
              onClick={() => {
                if (item.onClick) item.onClick()
              }}>
              {item.icon}
            </Box>
          </Tooltip>
        ),
      )}
    </Box>
  )
}

export default WpToolbar
