// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { orgIconsAtom } from 'atoms'
import { Buffer } from 'buffer'
import { multiParse } from 'utils'
import { removeFileExt } from 'utils/stringFunctions'
import { userUserRoleAtom } from 'atoms'
import useInnovaAxios from './useInnovaAxios'

var _isOrgIconsInitialized = false

const useOrgIcons = () => {
  const _isMounted = useRef(false)
  const [orgIcons, setOrgIcons] = useRecoilState(orgIconsAtom)
  const userRole = useRecoilValue(userUserRoleAtom)
  const isFetching = useRef(false)

  const getAllOrgIcons = useInnovaAxios({
    url: '/admin/getOrgIcons',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchOrgIcons()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    fetchOrgIcons()
  }, [userRole]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchOrgIcons = async () => {
    if (_isOrgIconsInitialized || (Array.isArray(orgIcons) && orgIcons.length > 0)) return
    _isOrgIconsInitialized = true
    if (isFetching.current) return

    isFetching.current = true
    const res = await getAllOrgIcons()
    isFetching.current = false

    if (res?.error || !res?.data || !_isMounted.current) {
      _isOrgIconsInitialized = false
      return
    }

    const mfpBoundary = res.data.substring(2, res.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(res.data), mfpBoundary)
    setOrgIcons(parts.map((part) => {
      let iconObj = { ...part }
      iconObj.data = 'data:image/*;base64,' + part.data
      iconObj.filename = removeFileExt(part.filename)
      return iconObj
    }))
  }

  const getCompanyIcon = (orgName, useDefault = false) => {
    if (orgIcons && Array.isArray(orgIcons)) {
      const icon = orgIcons.find((item) => item.filename === orgName.toLowerCase())
      if (icon) {
        return icon.data
      } else {
        if (useDefault) {
          const defaultIcon = orgIcons.find((item) => item.filename === 'innova')
          if (defaultIcon) {
            return defaultIcon.data
          }
        }
      }
    }
    return ''
  }

  const getCurrentOrgIcon = () => {
    if (!userRole) return ''
    if (!userRole.hasOwnProperty('organization')) return ''
    if (userRole.organization.length < 0) return ''

    if (orgIcons && Array.isArray(orgIcons)) {
      let icon = orgIcons.find((item) => item.filename === userRole.organization.toLowerCase())

      if (icon) {
        return icon.data
      }
    }
    return ''
  }

  const getCurrentOrgLogo = () => {
    if (!userRole) return ''
    if (!userRole.hasOwnProperty('organization')) return ''
    if (userRole.organization.length < 0) return ''

    if (orgIcons && Array.isArray(orgIcons)) {
      const orgLogoName = userRole.organization.toLowerCase() + '-logo'
      let icon = orgIcons.find((item) => item.filename === orgLogoName)
      if (icon) {
        return icon.data
      }
    }
    return ''
  }

  const getCompanyLogo = (orgName, useDefault = false) => {
    if (orgIcons && Array.isArray(orgIcons)) {
      const orgLogoName = orgName.toLowerCase() + '-logo'
      const icon = orgIcons.find((item) => item.filename === orgLogoName)
      if (icon) {
        return icon.data
      }

      if (useDefault) {
        const defaultIcon = orgIcons.find((item) => item.filename === 'innova-logo')
        if (defaultIcon) {
          return defaultIcon.data
        }
      }
    }
    return ''
  }

  const getOrgIcons = () => {
    if (_isOrgIconsInitialized && orgIcons && Array.isArray(orgIcons)) {
      return orgIcons
    }

    return []
  }

  const refresh = () => {
    fetchOrgIcons()
  }

  return { getCompanyIcon, getOrgIcons, getCurrentOrgIcon, getCompanyLogo, getCurrentOrgLogo, refresh }
}

export default useOrgIcons
