import React from 'react'
import { Bar } from 'react-chartjs-2'
import { chartSeriesColors } from 'utils'
import Skeleton from '@mui/material/Skeleton'
import { getCostsByDate } from 'utils/dailyCostFunctions'
import useWellData from 'components/common/hooks/useWellData'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CostPerDayChart = () => {
  const { wellData } = useWellData()
  const { theme, getTextColor } = useInnovaTheme()

  const createChartData = () => {
    if (!wellData) return { labels: [], datasets: [] }

    let dailyData = getCostsByDate(wellData.costs)

    const chartData = {
      labels: dailyData.map((day) => (Array.isArray(day.date) ? day.date : 'null')),
      datasets: [
        {
          data: dailyData.map((day) => Math.round(day.total)),
          backgroundColor: chartSeriesColors[0],
          label: `Costs`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    color: chartSeriesColors[0],
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: false,
        text: '',
        color: '#FFF',
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: getTextColor(),
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: getTextColor(),
        },
      },
    },
  }

  return wellData?.costs ? (
    <Bar type='bar' options={chartOptions} data={createChartData()} />
  ) : (
    <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation='wave' />
  )
}

export default CostPerDayChart
