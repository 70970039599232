import React, { useEffect, useRef } from 'react'
import { Bar } from 'react-chartjs-2'
import { createShortDate } from 'utils/stringFunctions'
import { appColors } from 'utils'
import { getDailyStats } from 'utils/dailyActivityFunctions'
import Skeleton from '@mui/material/Skeleton'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useWellData from 'components/common/hooks/useWellData'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const FootagePerDayChart = () => {
  const _isMounted = useRef(false)
  const { getUnitsText } = useUnits()
  const { wellData } = useWellData()
  const { theme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const createChartData = () => {
    if (!wellData) return { labels: [], datasets: [] }

    const dailyData = getDailyStats(wellData.dailyActivity)
    const chartData = {
      labels: dailyData.map((day) =>
        Array.isArray(day.dates)
          ? createShortDate(day.dates.length > 0 ? day.dates[0] : '1900-01-01', '-', true)
          : 'null',
      ),
      datasets: [
        {
          data: dailyData.map((day) => day.rotateDistance),
          backgroundColor: appColors.rotateColor,
          label: `Rotate (${getUnitsText(UNITS_FOR.Depth)})`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => day.slideDistance),
          backgroundColor: appColors.slideColor,
          label: `Slide (${getUnitsText(UNITS_FOR.Depth)})`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    layout: {
      padding: {
        right: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  return wellData ? (
    <Bar type='bar' options={chartOptions} data={createChartData()} />
  ) : (
    <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation='wave' />
  )
}

export default FootagePerDayChart
