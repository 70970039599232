import { useEffect, useState, useRef } from 'react'

import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from './useInnovaAxios'

function useInnovaAdminDropDowns(refetchData) {
  const _isMounted = useRef(true)
  const [dropDowns, setDropDowns] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [rawData, setRawData] = useState()

  const getData = useInnovaAxios({
    url: '/admin/getInnovaAdminDropDowns',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getDropDowns()
  }, [rawData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchRawData()
  }, [refetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchRawData = async () => {
    const response = await getData()
    if (_isMounted.current && response?.data) {
      setRawData(response.data)
    }
  }

  async function getDropDowns() {
    if (_isMounted.current === true) setIsLoading(true)
    if (!rawData) return

    let newDropDowns = {}

    for (const property in rawData) {
      newDropDowns[property] = []
      if (Array.isArray(rawData[property])) {
        rawData[property].forEach((val) => {
          if (typeof val === 'object') {
            newDropDowns[property].push(val)
          } else {
            newDropDowns[property].push({ label: unescapeHtml(val), value: unescapeHtml(val) })
          }
        })

        let uniqueArray = [...newDropDowns[property]]
        newDropDowns[property] = []

        for(let i = 0;i<uniqueArray.length;i++){
          let found = false
          for (let j = 0; j < uniqueArray.length; j++) {
            if (uniqueArray[j].value === uniqueArray[i].value && i !== j) {
              found = true
              break
            }
          }

          if(!found) newDropDowns[property].push({ ...uniqueArray[i] })
        }
      }
    }

    if (_isMounted.current === true) {
      setDropDowns(newDropDowns)
      setIsLoading(false)
    }
  }
  return [dropDowns, isLoading]
}

export default useInnovaAdminDropDowns
