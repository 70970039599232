import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, Snackbar } from '@mui/material'
import SaveWpcGrid from 'components/WellPages/WallplotComposer/Templates/SaveWpcGrid'
import useWallPlotComposerTemplates from 'components/common/hooks/WallPlotComposer/useWallPlotComposerTemplates'

const SaveWpcTemplateModal = ({ onClose, onApply, template, wellName }) => {
  const _isMounted = useRef(false)
  const gridRef = useRef(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { wallplotList, fetchWallPlots } = useWallPlotComposerTemplates(wellName)

  useEffect(() => {
    _isMounted.current = true
    fetchWallPlots()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSave = async () => {
    const data = getGridData()
    if (!data.hasOwnProperty('uid')) return
    // check if the template already exists
    const templateExists = wallplotList?.some((template) => template.name === data.name)
    if (templateExists) {
      setStatus({ show: true, severity: 'error', message: 'Template name already in use' })
      return
    }
    onApply(data)
  }

  const getGridData = () => {
    let data = [{}]

    if (gridRef.current) data = gridRef.current.getData()
    return data[0]
  }

  const onSaveLayout = () => {
    gridRef.current.stopEditing()
    handleSave()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{ zIndex: 9999 }}
      PaperProps={{
        sx: {
          minWidth: '400px',
          width: '50vw',
          minHeight: '225px',
          height: '15vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Save WallPlot</DialogTitle>
      <DialogContent
        sx={{
          padding: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <SaveWpcGrid template={template} ref={gridRef} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose} color='secondary'>
          Cancel
        </Button>
        <Button variant='contained' color='primary' type='submit' onClick={onSaveLayout}>
          Save
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={3000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default SaveWpcTemplateModal
