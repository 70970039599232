import React, { useEffect, useMemo, useRef } from 'react'
import SurveyTieOnGrid from 'components/DatabaseTree/Survey/SurveyPropertiesPage/SurveyTieOnGrid'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Box } from '@mui/material'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PlanTieOnPropertiesGrid = ({ surveyData, handleUpdate, tieOnSurveyHeaders, units }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 400,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => {
          if (params.data?.tag === 'parentSurveyWell' || params.data?.tag === 'parentTieOn') {
            let inputMeth = ''
            params.api.forEachNode((node) => {
              if (node.data?.tag === 'inputMeth') inputMeth = node.data?.value
            })

            return inputMeth === 'Survey'
          }

          return params?.data?.editable
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          return null
        },
        valueGetter: (params) => {
          if (params?.data?.type === 'text') {
            return unescapeHtml(params?.data?.value)
          }

          if (params?.data?.type === 'number') {
            return parseFloat(params?.data?.value)
          }

          return params?.data?.value
        },
        valueFormatter: (params) => {
          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getSurveyData = (data) => {
    let svy = [
      {
        label: `Tie On Type`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `inputMeth`,
        editable: true,
        dropDownValues:
          Array.isArray(tieOnSurveyHeaders) && tieOnSurveyHeaders.length > 0
            ? ['Surface', 'Survey', 'User Defined']
            : ['Surface', 'User Defined'],
      },
      {
        label: `Tie On Survey / Plan`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `parentSurveyWell`,
        editable: true,
        dropDownValues: [...new Set(tieOnSurveyHeaders?.map((item) => item.wellName))],
      },
    ]

    for (let i = 0; i < svy.length; i++) {
      svy[i].id = i
      if (svy[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(svy[i].tag)) continue
      if (!data[svy[i].tag]) continue
      svy[i].value = data[svy[i].tag]

      if (svy[i].tag === 'inputMeth') {
        if (data[svy[i].tag] === 'SURVEY') svy[i].value = 'Survey'
        if (data[svy[i].tag] === 'SURFACE') svy[i].value = 'Surface'
        if (data[svy[i].tag] === 'USER') svy[i].value = 'User Defined'
      }
    }

    return svy
  }

  const getDepthDatumText = () => {
    if (!surveyData) return ''
    return `All depths relative to ${surveyData?.datumName} @ ${numberWithCommasDecimals(
      surveyData?.datumElevation,
      2,
    )} (${units})`
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <div ref={containerRef} style={{ height: 'calc(100% - 25px)', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getSurveyData(surveyData)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          borderLeft: '1px solid gray',
          borderRight: '1px solid gray',
          backgroundColor: '#222628',
          color: 'white',
          paddingLeft: '10px',
        }}>
        {getDepthDatumText()}
      </Box>
    </Box>
  )
}

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

export function PlanTieOnGrid({
  plannedWellData,
  updateTieOnData,
  getTieOnSvy,
  getDeltaElevation,
  localCoordOffset,
  plannedWelldataRef,
  tieOnSurveyHeaders,
  updatePlannedWellData,
}) {
  const { getUnitsText } = useUnits()
  const { getChartBackColor } = useInnovaTheme()

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box sx={verticalTextStyle}>Tie On</Box>
      <Box sx={{ height: '100%', width: 'calc(100% - 25px)', display: 'flex', flexDirection: 'row' }}>
        <PlanTieOnPropertiesGrid
          tieOnSurveyHeaders={tieOnSurveyHeaders}
          surveyData={plannedWellData}
          handleUpdate={updatePlannedWellData}
          units={getUnitsText(UNITS_FOR.Depth)}
        />
        <SurveyTieOnGrid
          tieOnData={plannedWellData}
          handleUpdate={updateTieOnData}
          units={getUnitsText(UNITS_FOR.Depth)}
          tieOnSurvey={getTieOnSvy()}
          localCoordOffset={localCoordOffset.current}
          deltaElevation={getDeltaElevation(
            plannedWelldataRef.current?.parentSurveyWell,
            plannedWelldataRef.current?.parentTieOn,
          )}
        />
      </Box>
    </Box>
  )
}
