import React from 'react'

import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import { Tooltip, Box } from '@mui/material'

const StyledCustomTitleBar = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '4px',
  cursor: 'pointer',
})

const StyledTitleBar = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '31px',
  paddingBottom: '4px',
  cursor: 'pointer',
})

const StyledTitle = styled(Box)({
  flexGrow: 0,
  margin: 'auto',
  color: appColors.itemTextColor,
  fontWeight: 500,
})

const DashboardComponent = ({
  compkey,
  title,
  titleLine2 = null,
  titleLine3 = null,
  Component,
  customprops,
  titleprops,
  onClose,
  TitleComponent = null,
  showCloseButton = true,
}) => {

  const getTitleHeight = () => {
    let h = 25
    if (titleLine2 !== null) h += 25
    if (titleLine3 !== null) h += 25

    return `${h}px`
  }

  const handleClose = (event, key) => {
    onClose(key)
  }

  return (
    <React.Fragment>
      {showCloseButton ? (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            top: '0px',
            right: '0px',
            height: '31px',
            width: '31px',
            zIndex: 1,
            cursor: 'pointer',
            color: appColors.headerTextColor,
            '&:hover': {
              color: appColors.accentColor,
            },
          }}
          onClick={(event) => handleClose(event, compkey)}>
          <Tooltip
            title='close'
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <Iconify icon={'eva:close-square-outline'} />
          </Tooltip>
        </Box>
      ) : null}
      {TitleComponent === null ? (
        <StyledTitleBar className={'dragHandle'} style={{ height: getTitleHeight() }}>
          <StyledTitle>{title && title.length > 0 ? title : ''}</StyledTitle>
          {titleLine2 !== null ? (
            <StyledTitle>{titleLine2 && titleLine2.length > 0 ? titleLine2 : ''}</StyledTitle>
          ) : null}
          {titleLine3 !== null ? (
            <StyledTitle>{titleLine3 && titleLine3.length > 0 ? titleLine3 : ''}</StyledTitle>
          ) : null}
        </StyledTitleBar>
      ) : null}
      {TitleComponent !== null ? (
        <StyledCustomTitleBar className={'dragHandle'}>
          <TitleComponent {...titleprops} />
        </StyledCustomTitleBar>
      ) : null}
      <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, overflow: 'hidden' }}>
        <Component {...customprops} />
      </Box>
    </React.Fragment>
  )
}

export default DashboardComponent
