import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CurveMappingGrid = ({ selectedConfig, selectedLog, updateCurveMapping }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getGridData = () => {
    let gridData = [
      {
        tag: 'bitDepth',
        value: selectedConfig?.witsmlMapping?.bitDepth,
        label: 'Bit Depth',
      },
      {
        tag: 'holeDepth',
        value: selectedConfig?.witsmlMapping?.holeDepth,
        label: 'Hole Depth',
      },
      {
        tag: 'wob',
        value: selectedConfig?.witsmlMapping?.wob,
        label: 'WOB',
      },
      {
        tag: 'torque',
        value: selectedConfig?.witsmlMapping?.torque,
        label: 'Torque',
      },
      {
        tag: 'rpm',
        value: selectedConfig?.witsmlMapping?.rpm,
        label: 'RPM',
      },
      {
        tag: 'hkld',
        value: selectedConfig?.witsmlMapping?.hkld,
        label: 'Hookload',
      },
      {
        tag: 'pressure',
        value: selectedConfig?.witsmlMapping?.pressure,
        label: 'Pressure',
      },
      {
        tag: 'diffPressure',
        value: selectedConfig?.witsmlMapping?.diffPressure,
        label: 'Diff Pressure',
      },
      {
        tag: 'flow',
        value: selectedConfig?.witsmlMapping?.flow,
        label: 'Flow',
      },
      {
        tag: 'blockPosition',
        value: selectedConfig?.witsmlMapping?.blockPosition,
        label: 'Block Position',
      },
      {
        tag: 'rop',
        value: selectedConfig?.witsmlMapping?.rop,
        label: 'ROP',
      },
      {
        tag: 'mtf',
        value: selectedConfig?.witsmlMapping?.mtf,
        label: 'MTF',
      },
      {
        tag: 'gtf',
        value: selectedConfig?.witsmlMapping?.gtf,
        label: 'GTF',
      },
      {
        tag: 'contInc',
        value: selectedConfig?.witsmlMapping?.contInc,
        label: 'Cont Inc',
      },
      {
        tag: 'contAzi',
        value: selectedConfig?.witsmlMapping?.contAzi,
        label: 'Cont Azi',
      },
      {
        tag: 'gamma',
        value: selectedConfig?.witsmlMapping?.gamma,
        label: 'Gamma',
      },
      {
        tag: 'temperature',
        value: selectedConfig?.witsmlMapping?.temperature,
        label: 'Temperature',
      },
      {
        tag: 'mwdAxVib',
        value: selectedConfig?.witsmlMapping?.mwdAxVib,
        label: 'MWD Axial Vib',
      },
      {
        tag: 'mwdLatVib',
        value: selectedConfig?.witsmlMapping?.mwdLatVib,
        label: 'MWD Lateral Vib',
      },
    ]

    return gridData
  }

  const updateGridData = () => {
    if (gridApi.current) {
      gridApi.current.applyTransaction({
        update: getGridData(),
      })
    }

    autoSizeColumns()
  }

  useEffect(() => {
    updateGridData()
  }, [selectedLog, selectedConfig]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.tag)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return

      let payload = {
        uid: selectedConfig?.uid,
      }

      gridApi.current.forEachNode((rowNode) => {
        if (rowNode?.data) {
          payload[rowNode?.data?.tag] = rowNode?.data?.value
          selectedConfig.witsmlMapping[rowNode?.data?.tag] = rowNode?.data?.value
        }
      })

      await updateCurveMapping(payload)
    },
    [updateCurveMapping, selectedConfig],
  )

  const gridOptions = {
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
      autoSizeColumns()
    },
    suppressRowClickSelection: true,
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false

            let data = params.data
            data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(data)
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell, handleUpdate])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const rightAlignCell = useMemo(() => ({ display: 'flex', justifyContent: 'end', alignItems: 'center' }), [])

  let columnDefs = [
    {
      field: 'label',
      colId: 'label',
      headerName: '',
      minWidth: 200,
      cellStyle: rightAlignCell,
    },
    {
      field: 'value',
      colId: 'value',
      headerName: 'WITSML Mnemonic',
      minWidth: 200,
      editable: true,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        let values = Array.isArray(selectedLog?.curveData) ? selectedLog.curveData : []
        return {
          values: values.map((curve) => curve.mnemonic),
        }
      },
      cellStyle: (params) => {
        if (
          !params?.value ||
          typeof params?.value !== 'string' ||
          params?.value === '' ||
          !Array.isArray(selectedLog?.curveData)
        ) {
          return centerAlignCell
        }

        let values = Array.isArray(selectedLog?.curveData) ? selectedLog.curveData : []
        let index = values.findIndex((curve) => curve.mnemonic === params?.value)
        if (index >= 0) return centerAlignCell

        return { ...centerAlignCell, backgroundColor: 'red' }
      },
    },
  ]

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={getGridData()}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default CurveMappingGrid
