import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import {
  CRITICAL_COLOR,
  ALERT_COLOR,
  NO_ALERT_COLOR,
} from 'components/AntiCollisionDashboard/AntiCollisionDashboardGrid'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AntiCollisionDashboardWellGrid = ({ data, masterGridApi, wellName, alertDistances, principalPlanName }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const containerRef = useRef(null)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)

  const getAlertColor = useCallback((value, levels) => {
    if (!value) return ''
    if (!Array.isArray(levels)) return ''
    if (typeof value === 'string') value = parseFloat(value)
    if (typeof value !== 'number') return ''

    for (let i = 0; i < levels.length; i++) {
      if (value >= levels[i].min && value <= levels[i].max) {
        return levels[i].color
      }
    }

    return ''
  }, [])

  const getTextColor = useCallback((color) => {
    if (!color) return '#FFFF'
    if (color === NO_ALERT_COLOR) return '#FFFF'
    if (color === CRITICAL_COLOR || color === ALERT_COLOR) return '#FFFF'
    return '#112A46'
  }, [])

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      if (masterGridApi && masterGridApi?.gridOptions?.api) {
        masterGridApi.removeDetailGridInfo(`AC_DASH_WELL_GRID-${wellName}`)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.wellName)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (masterGridApi) {
      let detailId = `AC_DASH_WELL_GRID-${wellName}`
      const detailGridInfo = {
        id: detailId,
        api: params.api,
      }

      masterGridApi.addDetailGridInfo(detailId, detailGridInfo)
    }

    gridApi.current.setGridOption('rowData', data)
  }

  const gridOptions = {
    suppressScrollOnNewData: true,
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('acDashboardWellGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('acDashboardWellGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WellDetails.xlsx',
            sheetName: 'WellDetails',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const getMarkerSize = useCallback((color, value) => {
    if (value <= -9999) return 0
    return color === '#66FF00' ? 3 : color === CRITICAL_COLOR ? 7 : 5
  }, [])

  const markerFormatterSf = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, alertDistances?.sf)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize, alertDistances],
  )

  const markerFormatterC2c = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, alertDistances?.c2c)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize, alertDistances],
  )

  const markerFormatterWellPlan = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, alertDistances?.wellPlan)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize, alertDistances],
  )

  const markerFormatterLeaseLine = useCallback(
    (params) => {
      let color = getAlertColor(params.yValue, alertDistances?.leaseLines)
      if (color === NO_ALERT_COLOR) color = '#66FF00'

      return {
        size: getMarkerSize(color, params.yValue),
        fill: color,
        stroke: color,
      }
    },
    [getAlertColor, getMarkerSize, alertDistances],
  )

  const sparkLineParams = useCallback(
    (alertType) => {
      return {
        sparklineOptions: {
          type: 'area',
          highlightStyle: {
            fill: 'rgb(143,185,77)',
            size: 10,
          },
          axis: {
            strokeWidth: 0,
          },
          marker: {
            formatter:
              alertType === 'sf'
                ? markerFormatterSf
                : alertType === 'wellPlan'
                ? markerFormatterWellPlan
                : alertType === 'leaseLine'
                ? markerFormatterLeaseLine
                : markerFormatterC2c,
          },
          tooltip: {
            renderer: (params) => {
              return {
                backgroundColor: 'black',
                opacity: 0.7,
                color: 'white',
              }
            },
          },
        },
      }
    },
    [markerFormatterSf, markerFormatterC2c, markerFormatterWellPlan, markerFormatterLeaseLine],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'wellDetails',
        pinned: 'left',
        lockPosition: 'left',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellRenderer: 'agGroupCellRenderer',
        width: 35,
        cellStyle: centerAlignCell,
      },
      {
        field: 'wellName',
        colId: 'wellName',
        headerName: 'Name',
        cellStyle: leftAlignCell,
      },
      {
        colId: 'type',
        headerName: 'Type',
        valueGetter: (params) => {
          if (params.data?.isLeaseLine) return 'Lease Line'
          if (params.data?.isPlan) {
            return params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName
              ? 'Principal Plan'
              : 'Plan'
          }

          return 'Actual'
        },
        cellStyle: (params) => {
          let color = '#db502a'
          if (params.data?.isLeaseLine) color = '#a82adb'
          if (params.data?.isPlan) color = '#2ab5db'
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) color = '#5cdb2a'
          return { ...centerAlignCell, color: color, fontWeight: 'bold' }
        },
      },
      {
        colId: 'svyC2c',
        headerName: 'Svy C2C',
        cellStyle: (params) => {
          let backColor = getAlertColor(params.value, alertDistances.c2c)
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) {
            backColor = getAlertColor(params.value, alertDistances.wellPlan)
          }

          let textColor = getTextColor(backColor)
          return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
        },
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.acResults) || params.data?.acResults.length === 0) return ''
          return params.data?.acResults[0].c2c
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        colId: 'svySf',
        headerName: 'Svy SF',
        cellStyle: (params) => {
          let backColor = getAlertColor(params.value, alertDistances.sf)
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) {
            backColor = ''
          }

          let textColor = getTextColor(backColor)
          return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
        },
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.acResults) || params.data?.acResults.length === 0) return ''
          if (params.data?.isLeaseLine) return ''
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) return ''

          return params.data?.acResults[0].sf
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
      },
      {
        colId: 'projC2c',
        headerName: 'Proj C2C',
        cellStyle: (params) => {
          let backColor = getAlertColor(params.value, alertDistances.c2c)
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) {
            backColor = getAlertColor(params.value, alertDistances.wellPlan)
          }

          let textColor = getTextColor(backColor)
          return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
        },
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.acResults) || params.data?.acResults.length === 0) return ''
          return params.data?.acResults[params.data?.acResults.length - 1].c2c
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        colId: 'projSf',
        headerName: 'Proj SF',
        cellStyle: (params) => {
          let backColor = getAlertColor(params.value, alertDistances.sf)
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) {
            backColor = ''
          }

          let textColor = getTextColor(backColor)
          return { ...centerAlignCell, backgroundColor: backColor, color: textColor }
        },
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.acResults) || params.data?.acResults.length === 0) return ''
          if (params.data?.isLeaseLine) return ''
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) return ''

          return params.data?.acResults[params.data?.acResults.length - 1].sf
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
      },
      {
        colId: 'c2cTrend',
        headerName: 'C2C Trend',
        width: 150,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.acResults)) return []
          return params.data?.acResults.map((elem) => {
            return elem.c2c
          })
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: (params) => {
          let type = 'c2c'
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) type = 'wellPlan'
          if (params.data?.isLeaseLine) type = 'leaseLine'
          return sparkLineParams(type)
        },
      },
      {
        colId: 'sfTrend',
        headerName: 'SF Trend',
        width: 150,
        valueGetter: (params) => {
          if (params.data?.isPrincipalPlan && params.data?.wellName === principalPlanName) return []
          if (params.data?.isLeaseLine) return []

          if (!Array.isArray(params.data?.acResults)) return []
          return params.data?.acResults.map((elem) => {
            return elem.sf
          })
        },
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams('sf'),
      },
    ],
    [leftAlignCell, centerAlignCell, principalPlanName, getAlertColor, getTextColor, alertDistances, sparkLineParams],
  )

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        columnDefs: [
          {
            headerName: '#',
            width: 80,
            colId: 'rowNum',
            valueGetter: (params) => params.node.rowIndex + 1,
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Ref MD',
            colId: 'refMd',
            field: 'refMd',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Off MD',
            colId: 'offMd',
            field: 'offMd',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Ref TVD',
            colId: 'refTvd',
            field: 'refTvd',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Off TVD',
            colId: 'offTvd',
            field: 'offTvd',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Ref Semi Maj',
            colId: 'refEllip',
            field: 'refEllip',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Off Semi Maj',
            colId: 'offEllip',
            field: 'offEllip',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Ref OD',
            colId: 'refOd',
            field: 'refOd',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Off OD',
            colId: 'offOd',
            field: 'offOd',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'C2C',
            colId: 'c2c',
            field: 'c2c',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'ES',
            colId: 'es',
            field: 'es',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'Min Sep',
            colId: 'minSep',
            field: 'minSep',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
            cellStyle: centerAlignCell,
          },
          {
            headerName: 'SF',
            colId: 'sf',
            field: 'sf',
            valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
            cellStyle: centerAlignCell,
          },
        ],
        defaultColDef: {
          suppressHeaderMenuButton: true,
          suppressHeaderFilterButton: true,
          sortable: false,
          resizable: true,
          editable: false,
        },
      },
      getDetailRowData: (params) => {
        params.successCallback(Array.isArray(params.data?.acResults) ? params.data?.acResults : [])
      },
    }
  }, [centerAlignCell])

  return (
    <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={sortColDefs(columnDefs, 'acDashboardWellGrid')}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        masterDetail={true}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  )
}

export default AntiCollisionDashboardWellGrid
