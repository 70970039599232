import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, dateComparator, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getComponentColor } from 'components/common/drillStringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const InventoryKpiGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/well/toolInventory/getInventory',
    timeout: 60000,
  })

  const dateFilterComparator = useCallback(dateComparator, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('inventoryKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.inventoryKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const dataResponse = await getKpiData({
      wellName: array2pipestr(selectedWells),
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let motorWS = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, motorWS, 'Inventory Kpi')
    XLSX.writeFile(wb, 'inventoryKpi.xlsx')
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const columnDefs = [
    {
      headerName: 'Well',
      colId: 'actualwell',
      field: 'actualwell',
      cellStyle: centerAlignCell,
      pinned: 'left',
    },
    {
      headerName: 'Rig',
      field: 'rig',
      colId: 'rig',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Job#',
      field: 'jobNum',
      colId: 'jobNum',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'SN',
      colId: 'serialNum',
      field: 'serialNum',
      pinned: 'left',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => unescapeHtml(params.data?.serialNum),
    },
    {
      headerName: 'Description',
      colId: 'description',
      field: 'description',
      pinned: 'left',
      cellStyle: { alignItem: 'end' },
      valueFormatter: (params) => unescapeHtml(params.data?.description),
    },
    {
      headerName: 'Tool Type',
      colId: 'toolType',
      field: 'toolType',
      pinned: 'left',
      cellStyle: (params) => {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: getComponentColor(params.value),
        }
      },
    },
    {
      headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'od',
      field: 'od',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.od, 3)}`
      },
    },
    {
      headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
      colId: 'id',
      field: 'id',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.id, 3)}`
      },
    },
    {
      headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'length',
      field: 'length',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.length, 2)}`
      },
    },
    {
      headerName: 'Cnx Top',
      colId: 'connectionTop',
      field: 'connectionTop',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => unescapeHtml(params.data?.connectionTop),
    },
    {
      headerName: 'Cnx Bottom',
      colId: 'connectionBottom',
      field: 'connectionBottom',
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'Basket',
      colId: 'basket',
      field: 'basket',
      valueFormatter: (params) => unescapeHtml(params.data?.basket),
    },
    {
      headerName: 'Location',
      colId: 'location',
      field: 'location',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => unescapeHtml(params.data?.location),
    },
    {
      headerName: `Weight (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'})`,
      colId: 'weight',
      field: 'weight',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.weight, 2)}`
      },
    },
    {
      headerName: 'Date on Location',
      colId: 'dateOn',
      field: 'dateOn',
      sortable: false,
      cellStyle: centerAlignCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
    },
    {
      headerName: 'Date off Location',
      colId: 'dateOff',
      field: 'dateOff',
      sortable: false,
      cellStyle: centerAlignCell,
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
    },
    {
      headerName: 'On Location',
      colId: 'onLocation',
      field: 'onLocation',
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Failed',
      colId: 'failed',
      field: 'failed',
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Ready for Backload',
      colId: 'readyForBackload',
      field: 'readyForBackload',
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'In Hole',
      colId: 'inHole',
      field: 'inHole',
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Comments',
      colId: 'comments',
      field: 'comments',
      cellStyle: { alignItems: 'start' },
      valueFormatter: (params) => unescapeHtml(params.data?.comments),
    },
    {
      headerName: 'Make',
      colId: 'make',
      field: 'make',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => unescapeHtml(params.data?.make),
    },
    {
      headerName: 'Model',
      colId: 'model',
      field: 'model',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => unescapeHtml(params.data?.model),
    },
    {
      headerName: 'Dirty',
      colId: 'dirty',
      field: 'dirty',
      valueGetter: (params) => params.data?.totalToolHours > 0,
      cellStyle: centerAlignCell,
      cellRenderer: 'agCheckboxCellRenderer',
    },
    {
      headerName: 'Prev D&C Hrs',
      colId: 'prevToolHours',
      field: 'prevToolHours',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.prevToolHours, 2)}`
      },
    },
    {
      headerName: 'Total D&C Hrs',
      colId: 'totalToolHours',
      field: 'totalToolHours',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.value === 0) return ''
        return `${numberWithCommasDecimals(params.data?.totalToolHours, 2)}`
      },
    },
  ]

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('inventoryKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InventoryKpi.xlsx',
            sheetName: 'InventoryKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={data}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'inventoryKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            masterDetail={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default InventoryKpiGrid
