import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const VerticalSectionGrid = ({ wellData, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 150,
        cellDataType: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: (params) => {
          if (params.data?.tag === 'VsOrigin' || params.data?.tag === 'vsAziUseBhl') return true
          //VS AZI Editable
          if (params.data?.tag === 'VsAzi') {
            let vsAziUseBhl = false
            params.api.forEachNodeAfterFilter((node) => {
              if (node.data?.tag === 'vsAziUseBhl') vsAziUseBhl = node.data?.value
            })

            return !vsAziUseBhl
          }

          //VS NS EW Editable
          let vsOrigin = 'Well'
          params.api.forEachNodeAfterFilter((node) => {
            if (node.data?.tag === 'VsOrigin') vsOrigin = node.data?.value
          })

          return vsOrigin === 'User Defined'
        },
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'bool') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: params.data?.tag === 'VsAzi' ? 0 : -10000000,
                max: params.data?.tag === 'VsAzi' ? 360 : 10000000,
                precision: 2,
              },
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return numberWithCommasDecimals(params?.data?.value, 2)
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getPlannedWellData = (data) => {
    let verticalSection = [
      { label: `VS Azi`, value: '0', id: -1, type: 'number', tag: `VsAzi` },
      {
        label: `VS Origin`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `VsOrigin`,
        dropDownValues: ['Well', 'Facility', 'User Defined'],
      },
      { label: `VS Origin N/S`, value: '0', id: -1, type: 'number', tag: `VsOrgNorth` },
      { label: `VS Origin E/W`, value: '0', id: -1, type: 'number', tag: `VsOrgEast` },
      { label: `Use BHL`, value: false, id: -1, type: 'bool', tag: `vsAziUseBhl` },
    ]

    for (let i = 0; i < verticalSection.length; i++) {
      verticalSection[i].id = i
      if (verticalSection[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(verticalSection[i].tag)) continue
      if (data[verticalSection[i].tag] === null || data[verticalSection[i].tag] === undefined) continue
      verticalSection[i].value = data[verticalSection[i].tag]
    }

    return verticalSection
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box sx={verticalTextStyle}>Vertical Section</Box>
      <div style={{ height: '100%', width: 'calc(100% - 25px)' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getPlannedWellData(wellData)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default VerticalSectionGrid
