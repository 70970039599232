import React, { useEffect, useRef, useState } from 'react'

import { IconButton, TextField, Tooltip, Box } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'
import { styled } from '@mui/styles'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import Send from '@mui/icons-material/Send'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

import useChat, { chatSelectedRoomAtom, ownMessageAtom } from 'components/common/hooks/useChat'
import { userUserRoleAtom } from 'atoms'

const StyledFormContainer = styled(Form)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 'calc(100% - 40px)',
})

const StyledItemIconContainer = styled(IconButton)({
  position: 'absolute',
  right: '4px',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})

const defaultFormData = [
  {
    tag: 'msg',
    value: '',
    inputType: 'text',
  },
  {
    tag: 'roomId',
    value: -1,
    inputType: '-',
  },
]

const ChatSendControl = () => {
  const _isMounted = useRef(false)
  const formikRef = useRef()
  const [formData, setFormData] = useState(defaultFormData)
  const [, { sendMessage }] = useChat()
  const selectedChatRoom = useRecoilValue(chatSelectedRoomAtom)
  const { user } = useInnovaAuth()
  const userRole = useRecoilValue(userUserRoleAtom)
  const setOwnMessage = useSetRecoilState(ownMessageAtom)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current && selectedChatRoom) {
      setFormData(() => {
        let newFormData = formData.map((item) => {
          if (item.tag === 'roomId') item.value = selectedChatRoom.roomId
          return item
        })
        return newFormData
      })
    }
  }, [selectedChatRoom]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && formData) {
      formikRef.current?.resetForm({ values: reduceFormData(formData) })
    }
  }, [formData]) // eslint-disable-line react-hooks/exhaustive-deps

  const submitForm = async (data) => {
    let innovaMsg = {
      messageId: '', //This is added by the server
      roomId: selectedChatRoom.roomId,
      organization: userRole.organization,
      username: user.name,
      message: data.msg,
      timeStamp: '', //This is added by the server
      messageType: 0,
    }

    sendMessage(innovaMsg)
    setOwnMessage(innovaMsg)
  }

  const formValidationSchema = yup.object({
    msg: yup.string().required(),
    roomId: yup.number().min(0, 'Please select a chat room'),
  })

  const reduceFormData = (data) => {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    const reducedData = data.reduce((output, item) => {
      output[item.tag] = item.value
      return output
    }, {})
    return reducedData
  }

  return (
    <Box sx={{ display: 'flex', padding: '4px' }}>
      <Formik
        innerRef={formikRef}
        initialValues={reduceFormData(formData)}
        validationSchema={formValidationSchema}
        onSubmit={(values, actions) => {
          submitForm(values)
          actions.resetForm({ values: reduceFormData(formData) })
        }}>
        {({ values, touched, errors, handleChange }) => (
          <StyledFormContainer id='sendmessage'>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100% ' }}>
              <Field
                variant='standard'
                component={TextField}
                id='msg'
                name='msg'
                type='text'
                value={values.msg}
                placeholder={'Type message to send'}
                onChange={(event) => {
                  handleChange(event)
                }}
              />
              {errors['msg'] && touched['msg'] && <FormHelperText>{errors['msg']}</FormHelperText>}
              {errors['roomId'] && touched['roomId'] && <FormHelperText>{errors['roomId']}</FormHelperText>}
            </Box>
            <Tooltip
              title='send msg'
              placement='left'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <StyledItemIconContainer type='submit' size='large'>
                <Send />
              </StyledItemIconContainer>
            </Tooltip>
          </StyledFormContainer>
        )}
      </Formik>
    </Box>
  )
}

export default ChatSendControl
