import React from 'react'

import { Badge } from '@mui/material'
import { styled } from '@mui/material/styles'

const MuiStyledBadge = styled(Badge)((props) => ({
  '& .MuiBadge-badge':{
  backgroundColor: props.badgecolor,
  width: props.badgewidth,
  minWidth: props.badgewidth,
  height: props.badgeheight,
  color: 'black',
  fontWeight: 'bold',
  zIndex: 0,
  },
}))

export default function StyledBadge(props) {
  return <MuiStyledBadge {...props}/>
}
