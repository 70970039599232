import * as THREE from 'three'
import React, { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { Hud, OrthographicCamera, Text, Line } from '@react-three/drei'
import { fonts } from '../ChartComponents/fonts'

function OrientationCompass({ cameraTarget }) {
  const textRef = useRef(null)
  const { camera, size } = useThree()

  useFrame(() => {
    var vector = new THREE.Vector3()
    var center = new THREE.Vector3()
    var spherical = new THREE.Spherical()

    if (cameraTarget.current) center = cameraTarget.current

    vector.copy(camera.position).sub(center)
    spherical.setFromVector3(vector)

    textRef.current.rotation.set(0, 0, -spherical.theta - Math.PI / 2)
    textRef.current.updateMatrix()
  })

  const CENTER_X = size.width / 2 - 60
  const CENTER_Y = size.height / 2 - 60
  const RADIUS = 20
  const TEXT_OFFSET = 15
  const LINE_OFFSET = 4

  const TEXT_POSITIONS = [
    { label: 'N', x: 0, y: RADIUS + TEXT_OFFSET },
    { label: 'S', x: 0, y: -(RADIUS + TEXT_OFFSET) },
    { label: 'E', x: RADIUS + TEXT_OFFSET, y: 0 },
    { label: 'W', x: -(RADIUS + TEXT_OFFSET), y: 0 },
  ]

    const LINE_POSITIONS = [
      { color: 'red', x1: 0, y1: RADIUS + LINE_OFFSET, x2: 0, y2: RADIUS - LINE_OFFSET },
      { color: 'black', x1: 0, y1: -RADIUS - LINE_OFFSET, x2: 0, y2: -RADIUS + LINE_OFFSET },
      { color: 'black', x1: -RADIUS - LINE_OFFSET, y1: 0, x2: -RADIUS + LINE_OFFSET, y2: 0 },
      { color: 'black', x1: RADIUS + LINE_OFFSET, y1: 0, x2: RADIUS - LINE_OFFSET, y2: 0 },
    ]

  return (
    <Hud renderPriority={1}>
      <OrthographicCamera makeDefault={true} position={[0, 0, 100]} />
      <group ref={textRef} position={[CENTER_X, CENTER_Y, 0]}>
        {TEXT_POSITIONS.map((text) => (
          <Text
            key={text.label}
            position-x={text.x}
            position-y={text.y}
            position-z={0}
            text={text.label}
            font={fonts.Roboto}
            fontSize='10'
            anchorX='center'
            anchorY='middle'
            color={'#FFF'}
            rotation={[0, 0, 0]}
          />
        ))}
        {LINE_POSITIONS.map((line, index) => (
          <Line
            key={index}
            points={[
              [line.x1, line.y1, 0],
              [line.x2, line.y2, 0],
            ]}
            color={line.color}
            lineWidth={4}
            segments
          />
        ))}
      </group>
      <mesh position={[CENTER_X, CENTER_Y, 0]}>
        <meshBasicMaterial attach={`material`} color={'#0E86D4'} />
        <torusGeometry args={[RADIUS, 1, 16, 100]} />
      </mesh>
      <ambientLight intensity={1} />
    </Hud>
  )
}

export default OrientationCompass
