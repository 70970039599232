import React, { useCallback, useMemo } from 'react'
import DonutChartJs from 'components/common/DonutChartJs'
import { chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import { useRecoilValue } from 'recoil'
import { currentInfrastructureRdsSelector } from 'atoms'

const DatabaseConnectionsDonut = ({ title = '' }) => {
  const data = useRecoilValue(currentInfrastructureRdsSelector)

  const getDonutChartData = useCallback(() => {
    if (!data.connectionInfo) return []
    if (!Array.isArray(data.connectionInfo)) return []
    if (data.connectionInfo.length === 0) return []

    const getChartLabel = ({ applicationName, loginName, clientNetAddress }) => {
      if (applicationName?.length > 0 && loginName?.length > 0) {
        let newLoginName = loginName.split(/admin/i)[0]
        let capitalizedLoginName = newLoginName.charAt(0).toUpperCase() + newLoginName.slice(1)
        return `${applicationName} - ${capitalizedLoginName}`
      } else {
        return `${clientNetAddress}`
      }
    }

    let donutData = data.connectionInfo.map((connection, i) => {
      return {
        value: connection.connectionCount,
        color: i < chartSeriesColors[i]?.length ? chartSeriesColors[i] : getRandomColor(),
        label: getChartLabel(connection),
      }
    })
    return donutData
  }, [data?.connectionInfo])

  return useMemo(
    () => (
      <DonutChartJs
        title={title}
        data={getDonutChartData()}
        centerText={''}
        legendLocation={'left'}
        showLegend={false}
        showTitle={title.length > 0}
      />
    ),
    [title, getDonutChartData],
  )
}

export default DatabaseConnectionsDonut
