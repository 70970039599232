import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ChartDetailsPropertyGrid = forwardRef(({ id, data, handleUpdateFunc }, ref) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      return gridData
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
    gridApi.current.setGridOption('rowData', getRowData())
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      let eventData = {
        id: event.data.id,
        label: event.data.label,
        tag: event.data.tag,
        type: event.data.type,
        value: event.data.type === 'number' ? parseFloat(event.newValue) : event.newValue,
      }
      handleUpdateFunc(id, eventData)
      autoSizeColumns()
    },
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: 'Option',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: 'Value',
        field: 'value',
        colId: 'value',
        flex: 1,
        editable: true,
        minWidth: 350,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
              popup: true,
            }
          }
          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          return null
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return
          // revisit this after initially functional
          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index].value = params.newValue

          gridApi.current.applyTransaction({ update: gridData })
        },
      },
    ],
    [],
  )

  const getRowData = () => {
    let propertyData = []
    if (!data) return propertyData

    data.forEach((item) => {
      let rowItem = {
        id: item.id,
        label: item.label,
        value: item.value,
        type: item.type,
        tag: item.tag,
      }
      if (item.type === 'dropDown') {
        rowItem.value = item.dropDownValues[0]
        rowItem.dropDownValues = item.dropDownValues
      }
      if (item.type === 'number') {
        rowItem.min = item.min
        rowItem.max = item.max
        rowItem.precision = item.precision
      }

      propertyData.push(rowItem)
    })

    return propertyData
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  )
})

export default ChartDetailsPropertyGrid
