export const dataLabelPlugin = {
    id: `dataLabelPlugin`,
    afterDraw: (chart, args, options) => {
        if (!options.visible) return
        const { ctx } = chart

        ctx.save()
        ctx.font = ctx.font = `12px Arial`

        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'

        for (let x = 0; x < chart.config.data.datasets.length; x++) {
            for (let i = 0; i < chart.config.data.datasets[x].data.length; i++) {
                let dataPoint = chart.config.data.datasets[x].data[i]
                ctx.fillStyle = !dataPoint.hasOwnProperty('color') ? options.color : dataPoint.color

                let textAlign = options.textAlign || 'center'

                if (!dataPoint.hasOwnProperty('label')) continue
                let textWidth = ctx.measureText(dataPoint.label).width
                let xCoord = chart.getDatasetMeta(x).data[i].x
                if (textAlign === 'center') xCoord -= textWidth / 2
                if (textAlign === 'left') xCoord -= textWidth
                if (textAlign === 'right') xCoord += textWidth
                let yCoord = chart.getDatasetMeta(x).data[i].y - 10
                ctx.fillText(dataPoint.label, xCoord, yCoord)
            }
        }
    },
}

export const fillBetweenLinesPlugin = {
    id: `fillBetweenLinesPlugin`,
    afterDatasetDraw: (chart, args) => {
        if (chart.config.data.datasets.length === 0) return

        let index1 = args.index
        let index2 = chart.config.data.datasets[index1].fillBetweenSeries

        if (index2 === null || index2 === undefined) return
        if (index2 >= chart.config.data.datasets.length) return

        const { ctx } = chart
        ctx.save()

        ctx.rect(
            chart.chartArea.left,
            chart.chartArea.top,
            chart.chartArea.right - chart.chartArea.left,
            chart.chartArea.bottom - chart.chartArea.top,
        )
        ctx.clip()

        ctx.fillStyle = chart.config.data.datasets[index1].fillBetweenColor || 'rgba(255, 0, 0, 0.2)'

        let data1 = chart.getDatasetMeta(index1).data
        let data2 = chart.getDatasetMeta(index2).data

        if (!Array.isArray(data1) || !Array.isArray(data2)) return
        if (data1.length !== data2.length) return

        ctx.beginPath()
        for (let i = 0; i < data1.length; i++) {
            if (i === 0) ctx.moveTo(data1[i].x, data1[i].y)
            ctx.lineTo(data1[i].x, data1[i].y)
        }

        for (let i = data2.length - 1; i >= 0; i--) {
            ctx.lineTo(data2[i].x, data2[i].y)
        }

        ctx.closePath()
        ctx.fill()

        ctx.restore()
    },
}

export const shadingAreaPluginX = {
    id: `shadingAreaPluginX`,
    beforeDatasetDraw: (chart, args, options) => {
        if (!options.showShading && !args.meta._dataset.showShading) return
        const { ctx } = chart
        if (chart.config.data.datasets.length === 0) return

        let scale = chart.scales.x.width

        ctx.save()
        ctx.globalAlpha = 0.3
        ctx.fillStyle = args?.meta?._dataset?.color || options.color || 'rgba(255, 0, 0, 0.2)'
        ctx.rect(
            chart.chartArea.left,
            chart.chartArea.top,
            chart.chartArea.right - chart.chartArea.left,
            chart.chartArea.bottom - chart.chartArea.top,
        )
        ctx.clip()

        let numPoints = args.meta.data.length
        if (numPoints <= 1) return

        ctx.beginPath()
        for (let i = 0; i < numPoints; i++) {
            let dataPoint = args.meta.data[i]

            let xCoord = args.meta.data[i].x
            let yCoord = args.meta.data[i].y

            if (i === 0) ctx.moveTo(xCoord, yCoord)

            let tickHeight = (dataPoint.maxX ? dataPoint.maxX : 1) * scale
            ctx.lineTo(xCoord - tickHeight, yCoord)
        }

        for (let i = numPoints - 1; i >= 0; i--) {
            let dataPoint = args.meta.data[i]

            let xCoord = args.meta.data[i].x
            let yCoord = args.meta.data[i].y

            if (i === numPoints - 1) ctx.lineTo(xCoord, yCoord)

            let tickHeight = (dataPoint.minX ? dataPoint.minX : 0) * scale
            ctx.lineTo(xCoord - tickHeight, yCoord)

            if (i === 0) ctx.lineTo(xCoord, yCoord)
        }

        ctx.closePath()
        ctx.fill()

        ctx.restore()
    },
}

export const customAnnotation = {
    id: 'customAnnotation',
    afterDraw: (chart) => {
        const { _metasets, ctx } = chart
        ctx.save()

        _metasets.forEach((meta) => {
            if (meta.hidden) return
            if (!meta?._dataset?.label?.includes('@')) return
            ctx.font = 'bolder 10px Arial'
            ctx.fillStyle = meta._dataset.borderColor

            ctx.fillText(
                meta._dataset.label
                    .replace('(gpm)', '')
                    .replace('(lpm)', '')
                    .replace('Usft/min', '')
                    .replace('Ft/min', '')
                    .replace('m/min', '')
                    .replace('(Usft/hr)', '')
                    .replace('(Ft/hr)', '')
                    .replace('(m/hr)', '')
                    .replace('(Usft)', '')
                    .replace('(ft)', '')
                    .replace('(m)', '')
                    .replace('(Ft)', '')
                    .replace('(klbs)', '')
                    .replace('(tons)', '')
                    .replace('klbs', '')
                    .replace('tons', '')
                    .replace('/100.0', '')
                    .replace('/30.0', '')
                    .replace('/10.0', '')
                    .split('@')[1],
                meta.data[meta.data.length - 1].x + 12,
                meta.data[meta.data.length - 1].y - 10,
            )
        })
    },
}

export const GradientPlugIn = {
    id: 'GradientPlugIn',
    beforeDraw: (chart, args, options) => {
        if (options?.showGradient === false) return
        const ctx = chart.ctx
        const canvas = chart.canvas
        const chartArea = chart.chartArea
        if (chartArea) {
            const chartWidth = chartArea.right - chartArea.left
            const chartHeight = chartArea.bottom - chartArea.top

            var gradient = canvas
                .getContext('2d')
                .createLinearGradient(chartWidth / 2, chartArea.top, chartWidth / 2, chartArea.bottom)
            gradient.addColorStop(0, options?.theme === 'light' ? '#ffffff' : '#141414')
            gradient.addColorStop(1, options?.theme === 'light' ? '#c0c0c0' : '#282828' )

            ctx.fillStyle = gradient
            ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight)
        }
    },
}

export const ChartBackgroundPlugIn = {
    id: 'ChartBackgroundPlugIn',
    beforeDraw: (chart, args, options) => {
        if (options?.showChartBackground !== true) return
        const ctx = chart.ctx
        const chartArea = chart.chartArea
        if (chartArea) {
            const chartWidth = chartArea.right - chartArea.left
            const chartHeight = chartArea.bottom - chartArea.top

            let backColor = options?.chartBackground || '#D0D0D0'
            if (typeof options?.chartBackground === 'function') {
                backColor = options?.chartBackground()
            }

            ctx.save()
            ctx.globalCompositeOperation = 'destination-over'
            ctx.fillStyle = backColor 
            ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight)
            ctx.restore()
        }
    },
}

export const CenterTextPlugIn = {
    id: `CenterTextPlugIn`,
    afterDraw: (chart, args, options) => {
        const {
            ctx,
            chartArea: { top, left, width, height },
        } = chart

        ctx.save()
        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'
        ctx.font = ctx.font = `${Math.max(Math.floor(height * 0.15), 1.5)}px Arial`
        ctx.fillStyle = options.color
        ctx.fillText(`${options.visible ? options.centerText : ''}`, left + width / 2, top + height / 2)
    },
}

export const CustomMouseEventPlugIn = {
    id: 'CustomMouseEventPlugIn',
    beforeEvent: (chart, args, options) => {
        const event = args.event
        if (event.type === 'mousemove' || event.type === 'click') {
            const { x, y } = event
            const { top, left, right, bottom } = chart.chartArea
            if (x < left || x > right || y < top || y > bottom) {
            } else {
                event.native.preventDefault()
                event.native.stopImmediatePropagation()
            }
        }
    },
}