import React, { useEffect, useRef, useState } from 'react'

import { Box } from '@mui/material'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentPageAtom, currentWellAtom, userUserRoleAtom, actionBarWidthAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { checkFeature } from 'components/userPermissions'

import ResponsiveDashboard from 'components/common/ReactGridLayout/ResponsiveDashboard'
import DepthTimeCard from 'components/WellPages/WellDetailsDashboard/cards/DepthTimeCard'
import ThreeDeeSummaryCard from 'components/WellPages/WellDetailsDashboard/cards/ThreeDeeSummaryCard'
import PhaseStatDrilledCard from 'components/WellPages/WellDetailsDashboard/cards/PhaseStatDrilledCard'
import FootagePerDayChart from 'components/WellPages/WellDetailsDashboard/cards/FootagePerDayChart'
import BhaStatHoursCard from 'components/WellPages/WellDetailsDashboard/cards/BhaStatHoursCard'
import CostPerDayChart from 'components/WellPages/WellDetailsDashboard/cards/CostPerDayChart'
import WellCostDonutChart from 'components/WellPages/WellDetailsDashboard/cards/WellCostDonutChart'
import BhaStatRopCard from 'components/WellPages/WellDetailsDashboard/cards/BhaStatRopCard'
import BhaStatDistanceCard from 'components/WellPages/WellDetailsDashboard/cards/BhaStatDistanceCard'
import WellSummaryGrid from 'components/WellPages/WellDetailsDashboard/cards/WellSummaryGrid'
import WellMapCard from 'components/WellPages/WellDetailsDashboard/cards/WellMapCard'
import PlanViewDashCard from 'components/WellPages/WellDetailsDashboard/cards/PlanViewDashCard'
import SectionViewDashCard from 'components/WellPages/WellDetailsDashboard/cards/SectionViewDashCard'
import ToolInventoryDonutChart from 'components/WellPages/WellDetailsDashboard/cards/ToolInventoryDonutChart'
import WeatherViewCard from 'components/WellPages/WellDetailsDashboard/cards/WeatherView/WeatherViewCard'
import ConnectionAnalysisCard from 'components/WellPages/WellDetailsDashboard/cards/ConnectionsAnalysis/ConnectionAnalysisCard'
import WellActivityDonutChart from 'components/WellPages/WellDetailsDashboard/cards/WellActivityDonutChart'
import HeatMapCard from 'components/WellPages/WellDetailsDashboard/cards/HeatMapCard'

const wellDashLayout = {
  lg: [
    { i: 'a', x: 0, y: 0, w: 6, h: 1 },
    { i: 'b', x: 6, y: 0, w: 2, h: 1 },
    { i: 'c', x: 0, y: 1, w: 4, h: 2 },
    { i: 'd', x: 4, y: 1, w: 4, h: 2 },
    { i: 'e', x: 0, y: 3, w: 4, h: 2 },
    { i: 'f', x: 4, y: 3, w: 4, h: 2 },
    { i: 'g', x: 0, y: 5, w: 4, h: 2 },
    { i: 'h', x: 4, y: 5, w: 4, h: 2 },
    { i: 'i', x: 0, y: 7, w: 4, h: 2 },
    { i: 'j', x: 4, y: 7, w: 4, h: 2 },
    { i: 'k', x: 0, y: 9, w: 4, h: 2 },
    { i: 'l', x: 4, y: 9, w: 4, h: 2 },
    { i: 'm', x: 0, y: 11, w: 4, h: 2 },
    { i: 'n', x: 4, y: 11, w: 4, h: 2 },
    { i: 'o', x: 0, y: 13, w: 4, h: 2 },
    { i: 'p', x: 4, y: 13, w: 4, h: 2 },
    { i: 'q', x: 0, y: 15, w: 4, h: 2 },
    { i: 'r', x: 4, y: 15, w: 4, h: 2 },
  ],
  md: [
    { i: 'a', x: 0, y: 0, w: 2, h: 1 },
    { i: 'b', x: 2, y: 0, w: 2, h: 1 },
    { i: 'c', x: 0, y: 1, w: 2, h: 2 },
    { i: 'd', x: 2, y: 1, w: 2, h: 2 },
    { i: 'e', x: 0, y: 3, w: 2, h: 2 },
    { i: 'f', x: 2, y: 3, w: 2, h: 2 },
    { i: 'g', x: 0, y: 5, w: 2, h: 2 },
    { i: 'h', x: 2, y: 5, w: 2, h: 2 },
    { i: 'i', x: 0, y: 7, w: 2, h: 2 },
    { i: 'j', x: 2, y: 7, w: 2, h: 2 },
    { i: 'k', x: 0, y: 9, w: 2, h: 2 },
    { i: 'l', x: 4, y: 9, w: 4, h: 2 },
    { i: 'm', x: 0, y: 11, w: 4, h: 2 },
    { i: 'n', x: 4, y: 11, w: 4, h: 2 },
    { i: 'o', x: 0, y: 13, w: 4, h: 2 },
    { i: 'p', x: 4, y: 13, w: 4, h: 2 },
    { i: 'q', x: 0, y: 15, w: 4, h: 2 },
    { i: 'r', x: 4, y: 15, w: 4, h: 2 },
  ],
  sm: [
    { i: 'a', x: 0, y: 0, w: 2, h: 1 },
    { i: 'b', x: 2, y: 0, w: 2, h: 1 },
    { i: 'c', x: 0, y: 1, w: 2, h: 2 },
    { i: 'd', x: 2, y: 1, w: 2, h: 2 },
    { i: 'e', x: 0, y: 3, w: 2, h: 2 },
    { i: 'f', x: 2, y: 3, w: 2, h: 2 },
    { i: 'g', x: 0, y: 5, w: 2, h: 2 },
    { i: 'h', x: 2, y: 5, w: 2, h: 2 },
    { i: 'i', x: 0, y: 7, w: 2, h: 2 },
    { i: 'j', x: 2, y: 7, w: 2, h: 2 },
    { i: 'k', x: 0, y: 9, w: 2, h: 2 },
    { i: 'l', x: 4, y: 9, w: 4, h: 2 },
    { i: 'm', x: 0, y: 11, w: 4, h: 2 },
    { i: 'n', x: 4, y: 11, w: 4, h: 2 },
    { i: 'o', x: 0, y: 13, w: 4, h: 2 },
    { i: 'p', x: 4, y: 13, w: 4, h: 2 },
    { i: 'q', x: 0, y: 15, w: 4, h: 2 },
    { i: 'r', x: 4, y: 15, w: 4, h: 2 },
  ],
}

const dashboardId = 'wellDash'

const getDashboardCards = (currentWell) => {
  const dashCards = [
    {
      key: 'a',
      title: 'Well Summary',
      Component: WellSummaryGrid,
      visible: true,
    },
    {
      key: 'b',
      title: 'Map',
      Component: WellMapCard,
      visible: true,
    },
    {
      key: 'c',
      title: 'Depth v Days',
      Component: DepthTimeCard,
      visible: true,
    },
    {
      key: 'd',
      title: '3D View',
      Component: ThreeDeeSummaryCard,
      visible: true,
    },
    {
      key: 'e',
      title: 'Phase Footage',
      Component: PhaseStatDrilledCard,
      visible: true,
    },
    {
      key: 'f',
      title: 'Footage Per Day',
      Component: FootagePerDayChart,
      visible: true,
      customprops: {
        currentWell: currentWell,
      },
    },
    {
      key: 'g',
      title: 'BHA Footage',
      Component: BhaStatDistanceCard,
      visible: true,
    },
    {
      key: 'h',
      title: 'BHA Hours',
      Component: BhaStatHoursCard,
      visible: true,
    },
    {
      key: 'i',
      title: 'BHA ROP',
      Component: BhaStatRopCard,
      visible: true,
    },
    {
      key: 'j',
      title: 'Well Cost',
      Component: WellCostDonutChart,
      visible: true,
    },
    {
      key: 'k',
      title: 'Cost per day',
      Component: CostPerDayChart,
      visible: true,
    },
    {
      key: 'l',
      title: 'Plan View',
      Component: PlanViewDashCard,
      visible: true,
    },
    {
      key: 'm',
      title: 'Section View',
      Component: SectionViewDashCard,
      visible: true,
    },
    {
      key: 'n',
      title: 'Inventory',
      Component: ToolInventoryDonutChart,
      visible: true,
    },
    {
      key: 'o',
      title: 'Weather',
      Component: WeatherViewCard,
      visible: true,
    },
    {
      key: 'p',
      title: 'Connections',
      Component: ConnectionAnalysisCard,
      visible: true,
    },
    {
      key: 'q',
      title: 'Activity Summary (Hrs)',
      Component: WellActivityDonutChart,
      visible: true,
    },
    {
      key: 'r',
      title: 'Heat Map',
      Component: HeatMapCard,
      visible: true,
    },
  ]
  return dashCards
}

const WellDetailsPage = () => {
  const _isMounted = useRef(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [dashboardCards, setDashboardCards] = useState([])
  const userRole = useRecoilValue(userUserRoleAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wellDetailsKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateCardsFeature = (cards, feature, title) => {
    if (checkFeature(feature, userRole?.roleAttributes?.featureId)) return
    cards.splice(
      cards.findIndex((element) => element.title === title),
      1,
    )
  }

  useEffect(() => {
    if (!_isMounted.current) return
    if (!currentWell || currentWell === '') return
    if (!userRole) return
    if (!userRole.hasOwnProperty('roleAttributes')) return

    let cards = getDashboardCards(currentWell)
    updateCardsFeature(cards, 34, 'Section View')
    updateCardsFeature(cards, 34, 'Plan View')
    updateCardsFeature(cards, 34, '3D View')
    updateCardsFeature(cards, 35, 'BHA ROP')
    updateCardsFeature(cards, 35, 'BHA Hours')
    updateCardsFeature(cards, 35, 'BHA Footage')
    updateCardsFeature(cards, 35, 'Footage Per Day')
    updateCardsFeature(cards, 35, 'Phase Footage')
    updateCardsFeature(cards, 35, 'Depth v Days')
    updateCardsFeature(cards, 36, 'Well Cost')
    updateCardsFeature(cards, 36, 'Cost per day')
    updateCardsFeature(cards, 41, 'Inventory')
    updateCardsFeature(cards, 35, 'Well Summary')
    updateCardsFeature(cards, 35, 'Map')
    updateCardsFeature(cards, 35, 'Activity Summary (Hrs)')
    updateCardsFeature(cards, 35, 'Heat Map')
    setDashboardCards(cards)
  }, [currentWell, userRole]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
      }}>
      <ResponsiveDashboard
        uid={dashboardId}
        defaultLayout={wellDashLayout}
        components={Array.isArray(dashboardCards) ? dashboardCards : []}
      />
    </Box>
  )
}

export default WellDetailsPage
