import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import { debounce } from 'lodash'
import { styled } from '@mui/styles'
import { isoDateTimeToDateTimeString } from 'utils/dateTimeFunctions'
import * as XLSX from '@sheet/core'
import * as yup from 'yup'
import InputModal from 'components/common/InputModal'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const InnovaContactsPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [contactData, setContactData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedContact = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [resetCols, setResetCols] = useState(false)
  const [showUsageDateRange, setShowUsageDateRange] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dropDowns] = useInnovaAdminDropDowns(false)

  const getContacts = useInnovaAxios({
    url: '/admin/companyContacts/getCompanyContacts',
  })

  const deleteContact = useInnovaAxios({
    url: '/admin/companyContacts/deleteCompanyContact',
  })

  const addContact = useInnovaAxios({
    url: '/admin/companyContacts/createCompanyContact',
  })

  const updateContact = useInnovaAxios({
    url: '/admin/companyContacts/updateCompanyContact',
  })

  const getUsageData = useInnovaAxios({
    url: '/wellSeekerUsers/getLicenseUsage',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaContactsPageKey)
    fetchContactdata()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  const fetchContactdata = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getContacts()

    if (response?.error) return
    if (!_isMounted.current) return

    setContactData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    let reqFields = ['firstName', 'company']
    if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('innovaContactsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaContactsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InnovaContacts.xlsx',
            sheetName: 'Contacts',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    let reqFields = ['firstName', 'company']
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current.active = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addContact(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current = res.data
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return

      if (isUpdating.current) return
      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateContact(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [updateContact],
  )

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
    selectedContact.current = cloneDeep(data)

    setConfirm({
      show: true,
      title: 'Delete Contact',
      text: `Are you sure you want to delete ${data.firstName} ${data.lastName}?`,
      onConfirm: handleDelete,
    })
  }

  const handleDelete = async () => {
    if (!selectedContact.current) return
    if (!selectedContact.current.hasOwnProperty('recordId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteContact(selectedContact.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedContact.current],
      })
    }

    selectedContact.current = null
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const getUsageReport = async (data) => {
    setShowUsageDateRange(false)
    selectedContact.current = null
    if (!data) return false
    let response = await getUsageData({ ...data })

    if (response.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Could not get usage data`,
      })

      return false
    } else {
      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        onXlsxUsageReport(response.data)
      } else {
        setStatus({ show: true, severity: 'error', message: 'no usage data for selected date range' })
      }
    }

    return true
  }

  const onXlsxUsageReport = (data) => {
    if (!data) return
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    let headers = []
    headers.push('Days Used')
    let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })

    let daysUsed = []
    data.forEach((day, i) => {
      let newLine = []
      newLine.push(isoDateTimeToDateTimeString(day))
      daysUsed.push(newLine)

      if (i === data.length - 1) {
        daysUsed.push([`Total Days: ${data.length}`])
      }
    })

    XLSX.utils.sheet_add_aoa(ws, daysUsed, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Usage Data')
    XLSX.writeFile(wb, 'usageReport.xlsx')
  }

  const handleMenuClick = async (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    selectedContact.current = data
    if (action === 'usage') {
      setShowUsageDateRange(true)
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: '',
        colId: 'menu',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            showBottomRow: false,
            menuItems: [
              {
                label: 'Usage report',
                action: 'usage',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='subway:print' />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'company',
        colId: 'company',
        headerName: 'Company',
        pinned: 'left',
        lockPosition: 'left',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        valueGetter: (params) => unescapeHtml(params.data?.company),
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.companys) ? dropDowns.companys : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'firstName',
        colId: 'firstName',
        headerName: 'First Name',
        pinned: 'left',
        lockPosition: 'left',
        valueGetter: (params) => unescapeHtml(params.data?.firstName),
      },
      {
        field: 'lastName',
        colId: 'lastName',
        headerName: 'Last Name',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params.data?.lastName),
      },
      {
        field: 'emailAddress',
        colId: 'emailAddress',
        headerName: 'Email',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false

          if (typeof params.newValue !== 'string') return false
          if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(params.newValue)) {
            setStatus({ show: true, severity: 'error', message: 'not a valid email address' })
            return false
          }

          if (params.node?.rowPinned === 'bottom') {
            inputRow.current.emailAddress = params.newValue
            gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
            return true
          }

          let newData = cloneDeep(params.data)
          newData.emailAddress = params.newValue
          handleUpdate(newData)
          return true
        },
      },
      {
        field: 'phoneNum',
        colId: 'phoneNum',
        headerName: 'Phone#',
        editable: (params) => params.node?.rowPinned !== 'bottom',
      },
      {
        field: 'department',
        colId: 'department',
        headerName: 'Department',
        cellEditor: 'agSelectCellEditor',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.departments) ? dropDowns.departments : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'comments',
        colId: 'comments',
        headerName: 'Comments',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params.data?.comments),
      },
      {
        field: 'computerName',
        colId: 'computerName',
        headerName: 'Computer Name',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params.data?.computerName),
      },
      {
        field: 'macAddress',
        colId: 'macAddress',
        headerName: 'MAC Address',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params.data?.macAddress),
      },
      {
        field: 'primaryInvoiceContact',
        colId: 'primaryInvoiceContact',
        headerName: 'Primary Invoice Contact',
        cellStyle: centerAlignCell,
      },
      {
        field: 'primaryQuoteContact',
        colId: 'primaryQuoteContact',
        headerName: 'Primary Quote Contact',
        cellStyle: centerAlignCell,
      },
      {
        field: 'getsInvoices',
        colId: 'getsInvoices',
        headerName: 'Gets Invoices',
        cellStyle: centerAlignCell,
      },
      {
        field: 'getsQuotes',
        colId: 'getsQuotes',
        headerName: 'Gets Quotes',
        cellStyle: centerAlignCell,
      },
      {
        field: 'getsLicenses',
        colId: 'getsLicenses',
        headerName: 'Gets Licenses',
        cellStyle: centerAlignCell,
      },
      {
        field: 'getsSoftwareUpdates',
        colId: 'getsSoftwareUpdates',
        headerName: 'Gets Software Updates',
        cellStyle: centerAlignCell,
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        cellStyle: centerAlignCell,
      },
    ],
    [centerAlignCell, dropDowns, handleUpdate], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const PrintUsageDialog = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('computerName') === false) useInputData = false
    }

    let dateFrom = new Date(Date.now())
    dateFrom.setDate(dateFrom.getDate() - 90)

    let formData = [
      {
        tag: 'computerName',
        value: useInputData ? data.computerName : '',
        inputType: '-',
      },
      {
        tag: 'macAddress',
        value: useInputData ? data.macAddress : '',
        inputType: '-',
      },
      {
        tag: 'dateFrom',
        text: 'Date From',
        value: dateFrom.toISOString(),
        inputType: 'date',
      },
      {
        tag: 'dateTo',
        text: 'Date to',
        value: new Date(Date.now()).toISOString(),
        inputType: 'date',
      },
    ]

    return (
      <InputModal
        open={showUsageDateRange}
        onClose={cancelFunction}
        title={'Select date range for report'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={yup.object({})}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {setShowUsageDateRange ? (
        <PrintUsageDialog
          data={selectedContact.current}
          submitFunction={getUsageReport}
          cancelFunction={() => setShowUsageDateRange(false)}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={contactData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'innovaContactsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchContactdata()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InnovaContactsPage
