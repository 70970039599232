import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { AgGridReact } from 'ag-grid-react'
import { appColors, array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { saveItemToLS } from 'utils/localStorage'
import {
  sortColDefs,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const ActivityKpiGrid = () => {
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)

  const getKpiData = useInnovaAxios({
    url: '/well/dailyReports/getDailyActivity',
    timeout: 60000,
  })

  const dateFilterComparator = useCallback(dateComparator, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('activityKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.activityKpiKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const dataResponse = await getKpiData({
      wellName: array2pipestr(selectedWells),
      dailyReportId: -1,
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let motorWS = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, motorWS, 'Activity Kpi')
    XLSX.writeFile(wb, 'ActivityKpi.xlsx')
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getActivityStyles = (code) => {
    if (typeof code !== 'string' || code?.length < 2) return
    if (code?.slice(0, 2) === '26') return { color: 'white', background: 'rgba(0,0,139)' }
    if (code === '2K') return { color: 'black', background: appColors.rotateColor }
    if (code === '2J') return { color: 'black', background: 'rgb(232,147,2)' }
    if (code === '6O') return { color: 'black', background: 'yellow' }
    if (code === '6P') return { color: 'black', background: 'yellow' }
    if (code[0] === '8') return { color: 'white', background: 'red' }
    return
  }

  const columnDefs = [
    {
      headerName: 'Well',
      field: 'actualwell',
      colId: 'actualwell',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Rig',
      field: 'rig',
      colId: 'rig',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Job#',
      field: 'jobNum',
      colId: 'jobNum',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Date',
      colId: 'date',
      field: 'date',
      pinned: 'left',
      lockPosition: 'left',
      valueFormatter: (params) => {
        if (!params.data?.date) return ''
        if (typeof params.data?.date !== 'string') return ''
        if (params.data?.date === '') return ''
        if (isDateLessThan(params.data?.date, '1990-01-01')) return ''
        let dateTimeStr = params.data?.date.slice(0, 10) + 'T00:00:00'
        return new Date(Date.parse(dateTimeStr)).toLocaleDateString('default', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
    },
    {
      headerName: 'Activity',
      colId: 'activity',
      field: 'activity',
      cellStyle: (params) => {
        if (!getActivityStyles(params.data?.activity)) return centerAlignCell
        return {
          ...{ display: 'flex', alignItems: 'center', justifyContent: 'center' },
          ...getActivityStyles(params.data?.activity),
        }
      },
    },
    {
      headerName: 'Description',
      field: 'activityCodeDesc',
      colId: 'activityCodeDesc',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'Start Time',
      colId: 'startTime',
      field: 'startTime',
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'End Time',
      colId: 'endTime',
      field: 'endTime',
      width: 110,
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'Hrs',
      colId: 'hours',
      field: 'hours',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return numberWithCommasDecimals(params.data?.hours, 2)
      },
    },
    {
      headerName: `Depth From (${getUnitsText(UNITS_FOR.DEPTH)})`,
      colId: 'startDepth',
      field: 'startDepth',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.startDepth, 2),
    },
    {
      headerName: `Depth To (${getUnitsText(UNITS_FOR.DEPTH)})`,
      colId: 'endDepth',
      field: 'endDepth',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.endDepth, 2),
    },
    {
      headerName: `CL (${getUnitsText(UNITS_FOR.DEPTH)})`,
      colId: 'cl',
      field: 'cl',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => numberWithCommasDecimals(params.data?.cl, 2),
    },
    {
      headerName: 'Phase',
      colId: 'phase',
      field: 'phase',
      cellStyle: { textAlign: 'start' },
    },
    {
      headerName: 'BHA #',
      colId: 'bha',
      field: 'bha',
      cellStyle: { textAlign: 'start' },
      valueGetter: (params) => {
        if (params?.data?.bha === undefined || params?.data?.bha === -1) return ''
        return `${params?.data.bhaNumRep}: ${params?.data.bhaDesc}`
      },
    },
    {
      headerName: 'Hole Size',
      colId: 'holeSize',
      field: 'holeSize',
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (params.node?.rowPinned === 'bottom') return ''
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      headerName: 'Comments',
      colId: 'description',
      field: 'description',
      cellStyle: { textAlign: 'start' },
      valueFormatter: (params) => {
        return unescapeHtml(params.data?.description)
      },
    },
  ]

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('activityKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ActivityKpi.xlsx',
            sheetName: 'ActivityKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={data}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'activityKpiGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            masterDetail={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
          />
        </div>
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} style={{ width: 28, height: 28 }} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default ActivityKpiGrid
