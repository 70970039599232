import React, { useEffect, useRef, useState } from 'react'
import { Icon as Iconify } from '@iconify/react'
import { Box } from '@mui/material'
import WellSchematic from './WellSchematic'
import DirectionalDrillingPane from './DirectionalDrillingPane'
import AlarmsGrid from './AlarmsGrid'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import RigStateDiagGrid from './RigStatesDiag/RigStateDiagGrid'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const EdrPageLeftPane = ({ showHidePane, paneVisible, wellName, edrData, toolFaceData, alarms, diagData }) => {
  const _isMounted = useRef(false)
  const [selectedView, setSelectedView] = useState('schematic')
  const [casingData, setCasingData] = useState([])
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  const getCasingData = useInnovaAxios({
    url: '/well/getCasing',
  })

  const fetchCasing = async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    const res = await getCasingData({ wellName: wellName, incOpenHole: true })
    if (!_isMounted.current) return
    if (!res.data) return

    setCasingData(res.data)
  }

  useEffect(() => {
    _isMounted.current = true
    fetchCasing()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchCasing()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const alarmActive = () => {
    if (!Array.isArray(alarms?.current)) return false
    if (alarms.current.length === 0) return false

    for (let i = 0; i < alarms.current.length; i++) {
      if (alarms.current[i].value && !alarms.current[i].suppress) return true
    }
  }

  const Tab = ({ tag, text, flash }) => {
    return (
      <Box
        sx={{
          ...verticalTextStyle,
          borderRight: '1px solid gray',
          borderLeft: '1px solid gray',
          fontWeight: paneVisible && selectedView === tag ? 'bold' : '400',
          color: paneVisible && selectedView === tag ? '#429ceb' : '#f0f0f0',
          // Conditional CSS animation for flashing background
          animation: flash ? 'flash 1s infinite' : 'none',
          '@keyframes flash': {
            '0%': {
              backgroundColor: 'transparent',
            },
            '50%': {
              backgroundColor: 'red',
            },
            '100%': {
              backgroundColor: 'transparent',
            },
          },
        }}
        onClick={() => {
          if (!paneVisible) showHidePane()
          if (paneVisible && selectedView === tag) {
            showHidePane()
            return
          }
          setSelectedView(tag)
        }}>
        {text}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          width: '25px',
          height: '100%',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
        <Tab tag={'schematic'} text={'Well Schematic'} />
        <Tab tag={'dd'} text={'Directional'} />
        <Tab tag={'alarms'} text={'Alarms'} flash={selectedView !== 'alarms' && alarmActive()} />
        <Tab tag={'rigstatediag'} text={'Rigstate Diagnostics'} />
      </Box>
      {paneVisible ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 25px)',
            height: '100%',
          }}>
          {selectedView === 'schematic' ? <WellSchematic casingData={casingData} backGroundGradient={true} /> : null}
          {selectedView === 'dd' ? <DirectionalDrillingPane edrData={edrData} toolFaceData={toolFaceData} /> : null}
          {selectedView === 'alarms' ? <AlarmsGrid alarms={alarms} /> : null}
          {selectedView === 'rigstatediag' ? <RigStateDiagGrid dataRef={diagData} /> : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default EdrPageLeftPane
