import React, { useEffect, useRef, useState } from 'react'

import { List, ListItem, ListItemText, ListItemSecondaryAction, ListSubheader } from '@mui/material'
import { Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { filter as _filter } from 'lodash'
import SearchBar from 'components/common/SearchBar'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { appColors } from 'utils'
import { searchFunction } from './searchFunctions'

const PickListSection = ({ sectionList, selItems, setSelItems, singleItemSelect }) => {
  const _isMounted = useRef(false)
  const { theme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selItems]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true && singleItemSelect === false) setSelItems(newSelectedItems)
      if (_isMounted.current === true && singleItemSelect === true) setSelItems([])
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true && !singleItemSelect === true) setSelItems(newSelectedItems)
      if (_isMounted.current === true && singleItemSelect === true) setSelItems([id])
    }
  }

  const getChecked = (id) => {
    if (!selItems) return false
    if (Array.isArray(selItems) === false) return false
    if (selItems.includes(id) === true) return true

    return false
  }

  return (
    <React.Fragment>
      <List
        dense
        sx={{
          '&': {
            width: '100%',
            maxWidth: '50vh',
            backgroundColor: 'itemBackground',
          },
        }}
        subheader={<li />}>
        {sectionList?.map((section, index) => {
          const labelId = `checkbox-list-secondary-label-${section.header}-${index}`
          return (
            <Box component='li' key={`section-${section.header}-${index}`} sx={{ backgroundColor: 'inherit' }}>
              <Box component='ul' sx={{ backgroundColor: 'inherit', padding: '0px', listStyleType: 'none !important' }}>
                <ListSubheader
                  sx={{
                    '&': {
                      fontSize: 16,
                      fontWeight: '800',
                      color: appColors.borderColor,
                      backgroundColor: appColors.itemTextColor,
                      padding: '0px 8px 0px 8px',
                      borderBottom: '1px solid',
                    },
                  }}>
                  {section.header}
                </ListSubheader>
                {section?.data?.map((item) => (
                  <ListItem key={item.id} button>
                    <ListItemText
                      id={labelId}
                      primary={`${item.desc}`}
                      sx={{
                        '& .MuiTypography-root': {
                          color: theme === 'dark' ? appColors.headerTextColor : '#000',
                          fontSize: '14px',
                          fontFamily: 'Roboto',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge='end'
                        onChange={(event) => onItemSelect(event, item.id)}
                        checked={getChecked(item.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                        checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                        indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                        icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </Box>
            </Box>
          )
        })}
      </List>
    </React.Fragment>
  )
}

const SectionPickListDialog = (props) => {
  const {
    title,
    items,
    open,
    setOpen,
    onApply,
    initSelItems,
    singleItemSelect,
    showSearch = false,
    searchParams = [],
  } = props
  const _isMounted = useRef(false)
  const { searchBarStyle } = useInnovaTheme()
  const [selectedItems, setSelectedItems] = useState(Array.isArray(initSelItems) === true ? initSelItems : [])
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(items, searchText)
      setFilteredList(filtered)
    }
  }, [searchText, items]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && initSelItems) {
      setSelectedItems(initSelItems)
    }
  }, [initSelItems]) // eslint-disable-line react-hooks/exhaustive-deps

  let singleSelect = singleItemSelect
  if (singleItemSelect === null || singleItemSelect === undefined) singleSelect = false

  const handleSearch = (inputList, searchTxt) => {
    if (
      searchTxt &&
      typeof searchTxt === 'string' &&
      searchTxt !== '' &&
      Array.isArray(inputList) &&
      Array.isArray(searchParams)
    ) {
      const formattedQuery = searchTxt.toLowerCase()
      let filteredList = []
      inputList.forEach((section) => {
        let sectionCopy = { ...section }
        sectionCopy.data = []
        sectionCopy.data = _filter(section.data, (item) => {
          return searchFunction !== null ? searchFunction(item, formattedQuery, searchParams) : true
        })
        if (sectionCopy.data.length > 0) {
          filteredList.push(sectionCopy)
        }
      })
      return filteredList
    } else {
      return inputList
    }
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          backgroundColor: 'itemBackground',
          minWidth: '50vh',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ paddingTop: '0px', overflow: 'auto' }}>
        {showSearch === true ? (
          <Box sx={{ marginBottom: '5px' }}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={searchBarStyle()}
            />
          </Box>
        ) : null}
        <PickListSection
          singleItemSelect={singleSelect}
          sectionList={filteredList}
          selItems={selectedItems}
          setSelItems={setSelectedItems}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onApply(selectedItems)
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SectionPickListDialog
