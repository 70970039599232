import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { array2pipestr, getXlsxColumnLetter } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import AnalyticsBarChart from './AnalyticsBarChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import { chartSeriesColors } from 'utils'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const CostPerWellChart = () => {
  const _isMounted = useRef(false)
  const [costPerDayData, setCostPerDayData] = useState([])
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const { getUnitsText } = useUnits()
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  const getCostByDayData = useInnovaAxios({
    url: '/kpi/costByDay',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.costPerWellKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!data || !data.costByPhase) return output

    data.costByPhase.forEach((well) => {
      if (well.wellCost > 0) {
        output.labels.push(well.actualWell)
        if (Array.isArray(well.phases)) {
          well.phases.forEach((phase) => {
            if (output.datasets.findIndex((element) => element.label === phase.phase) < 0) {
              output.datasets.push({
                label: phase.phase,
                backgroundColor: chartSeriesColors[output.datasets.length],
                maxBarThickness: 24,
                data: [],
              })
            }
          })
        }
      }
    })

    data.costByPhase.forEach((well) => {
      if (well.wellCost > 0) {
        if (Array.isArray(well.phases)) {
          output.datasets.forEach((dataset) => {
            let index = well.phases.findIndex((element) => element.phase === dataset.label)
            if (index >= 0 && index < well.phases.length) {
              dataset.data.push(well.phases[index].phaseCost)
            } else {
              dataset.data.push(0)
            }
          })
        }
      }
    })

    return output
  }

  const fetchData = async () => {
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    if (isLoading) return

    if (!_isMounted.current) return

    let payload = {
      wellList: array2pipestr(selectedWells),
      costByPhase: 'true',
    }

    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }

    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)

    if (_isMounted.current) {
      setData(dataResponse?.data ? dataResponse.data : [])
    }

    const costPerDayResponse = await getCostByDayData({ wellList: array2pipestr(selectedWells) })

    if (!_isMounted.current) return
    setIsLoading(false)

    if (costPerDayResponse && Array.isArray(costPerDayResponse?.data)) {
      setCostPerDayData(costPerDayResponse.data)
    } else {
      setCostPerDayData([])
    }
  }

  const getPhasesList = (data) => {
    let phases = []

    if (!data || !data.costByPhase) {
      return phases
    }

    data.costByPhase.forEach((well) => {
      if (well.wellCost > 0) {
        if (Array.isArray(well.phases)) {
          well.phases.forEach((phase) => {
            if (phases.findIndex((element) => element === phase.phase) < 0) {
              phases.push(phase.phase)
            }
          })
        }
      }
    })
    return phases
  }

  const onXlsxExport = () => {
    let colIndex = 1

    // get all phases for all wells, similar to createChartData
    const phases = getPhasesList(data)
    const phasesArr = phases.map((phase) => [phase])

    const wb = XLSX.utils.book_new()
    let ws = XLSX.utils.aoa_to_sheet(phasesArr, {
      origin: 'A2',
    })

    XLSX.utils.sheet_add_aoa(ws, [[`Total Cost`]], { origin: `A${phases.length + 3}` })

    if (data && data.costByPhase) {
      data.costByPhase.forEach((well) => {
        // get phase cost array for each well, note: well.phases can be null
        let phaseCosts = []
        let totalWellCost = 0.0
        phases.forEach((phase) => {
          if (well.phases) {
            let index = well.phases.findIndex((element) => element.phase === phase)
            if (index >= 0 && index < well.phases.length) {
              phaseCosts.push(well.phases[index].phaseCost)
              totalWellCost += well.phases[index].phaseCost
            } else {
              phaseCosts.push(0)
            }
          } else {
            phaseCosts.push(0)
          }
        })

        // create column of data
        let wellData = [[`${well.actualWell}`]]
        phaseCosts.forEach((phaseCost) => wellData.push([Math.round(phaseCost * 100) / 100]))
        wellData.push([''])
        wellData.push([Math.round(totalWellCost * 100) / 100])

        // add column data to sheet
        XLSX.utils.sheet_add_aoa(ws, wellData, { origin: `${getXlsxColumnLetter(colIndex)}1` })
        colIndex += 1
      })

      XLSX.utils.book_append_sheet(wb, ws, 'Cost Per Phase')
    }

    if (costPerDayData && Array.isArray(costPerDayData) && costPerDayData.length > 0) {
      let headers = [['Operator', 'Well', 'Job#', 'Rig', 'State', 'County']]
      let wellNames = []
      for (let i = 0; i < costPerDayData.length; i++) {
        headers[0].push(costPerDayData[i].date)

        if (Array.isArray(costPerDayData[i].wellCosts)) {
          for (let j = 0; j < costPerDayData[i].wellCosts.length; j++) {
            let index = wellNames.findIndex((well) => well.actualWell === costPerDayData[i].wellCosts[j].actualWell)
            if (index < 0) {
              wellNames.push({
                actualWell: costPerDayData[i].wellCosts[j].actualWell,
                operator: costPerDayData[i].wellCosts[j].operator,
                jobNum: costPerDayData[i].wellCosts[j].jobNum,
                rig: costPerDayData[i].wellCosts[j].rig,
                state: costPerDayData[i].wellCosts[j].state,
                county: costPerDayData[i].wellCosts[j].county,
              })
            }
          }
        }
      }

      headers[0].push('Total Cost')

      let data = []
      for (let i = 0; i < wellNames.length; i++) {
        let wellRow = [
          wellNames[i].operator,
          wellNames[i].actualWell,
          wellNames[i].jobNum,
          wellNames[i].rig,
          wellNames[i].state,
          wellNames[i].county,
        ]
        let wellCost = 0
        for (let j = 0; j < costPerDayData.length; j++) {
          let dayTotalForWell = 0
          if (Array.isArray(costPerDayData[j].wellCosts)) {
            for (let k = 0; k < costPerDayData[j].wellCosts.length; k++) {
              if (costPerDayData[j].wellCosts[k].actualWell === wellNames[i].actualWell) {
                if (Array.isArray(costPerDayData[j].wellCosts[k].costs)) {
                  for (let l = 0; l < costPerDayData[j].wellCosts[k].costs?.length; l++) {
                    dayTotalForWell += costPerDayData[j].wellCosts[k].costs[l].totalValue
                  }
                }
              }
            }
          }

          wellRow.push(dayTotalForWell > 0 ? dayTotalForWell : '')
          wellCost += dayTotalForWell
        }

        wellRow.push(wellCost)
        data.push(wellRow)
      }

      let totalRowCost = ['', '', '', '', '', 'Total Cost']
      let totalRowWell = ['', '', '', '', '', '#Wells']

      let totalCost = 0
      for (let i = 0; i < costPerDayData.length; i++) {
        totalRowCost.push(costPerDayData[i].totalCost)
        totalRowWell.push(costPerDayData[i].numberOfWells)
        totalCost += costPerDayData[i].totalCost
      }

      totalRowCost.push(totalCost)
      data.push(totalRowCost)
      data.push(totalRowWell)

      let costPerDayWs = XLSX.utils.aoa_to_sheet(headers, { origin: 'A1' })
      XLSX.utils.sheet_add_aoa(costPerDayWs, data, { origin: `A2` })
      XLSX.utils.book_append_sheet(wb, costPerDayWs, 'Cost Per Day')

      let detailedCostData = []
      detailedCostData.push([
        'Date',
        'Operator',
        'Well',
        'Job#',
        'Rig',
        'State',
        'County',
        'Description',
        'Cost Code',
        'Qty',
        'BHA#',
        'Serial#',
        'Value',
        'Total Value',
      ])

      for (let j = 0; j < costPerDayData.length; j++) {
        if (Array.isArray(costPerDayData[j].wellCosts)) {
          for (let k = 0; k < costPerDayData[j].wellCosts.length; k++) {
            if (Array.isArray(costPerDayData[j].wellCosts[k].costs)) {
              for (let l = 0; l < costPerDayData[j].wellCosts[k].costs?.length; l++) {
                detailedCostData.push([
                  costPerDayData[j].date,
                  costPerDayData[j].wellCosts[k].operator,
                  costPerDayData[j].wellCosts[k].actualWell,
                  costPerDayData[j].wellCosts[k].jobNum,
                  costPerDayData[j].wellCosts[k].rig,
                  costPerDayData[j].wellCosts[k].state,
                  costPerDayData[j].wellCosts[k].county,
                  costPerDayData[j].wellCosts[k].costs[l].description,
                  costPerDayData[j].wellCosts[k].costs[l].costCode,
                  costPerDayData[j].wellCosts[k].costs[l].quantity,
                  costPerDayData[j].wellCosts[k].costs[l].bhaNum > 0
                    ? costPerDayData[j].wellCosts[k].costs[l].bhaNum
                    : '',
                  costPerDayData[j].wellCosts[k].costs[l].serialNumber,
                  costPerDayData[j].wellCosts[k].costs[l].value,
                  costPerDayData[j].wellCosts[k].costs[l].totalValue,
                ])
              }
            }
          }
        }
      }

      let detailedData = XLSX.utils.aoa_to_sheet(detailedCostData, { origin: 'A1' })
      XLSX.utils.book_append_sheet(wb, detailedData, 'Detailed cost data')
    }

    XLSX.writeFile(wb, 'Cost by Well.xlsx')
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={getUnitsText(UNITS_FOR.Depth)}
      title={'Cost Per Well'}
      xAxisTitle='Wells'
      yAxisTitle={`Cost (${getUnitsText(UNITS_FOR.Cost)})`}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default CostPerWellChart
