import React, { useEffect, useState } from 'react'
import { SpeedDialAction } from '@mui/material'

import { Icon as Iconify } from '@iconify/react'
import SpeedDial from '@mui/material/SpeedDial'
import { useRecoilState } from 'recoil'

import { STATES, COUNTIES, SHALE_PLAYS, CONVENTIONAL_PLAYS } from 'components/common/MapView/Layers'
import { WELL_PATHS_LAYER } from 'components/common/MapView/MapView'
import { wellsMapLayersSelectorAtom } from 'atoms'
import { Box } from '@mui/system'

const MapPrefs = () => {
  const [selectedLayers, setSelectedLayers] = useRecoilState(wellsMapLayersSelectorAtom)
  const [wellPaths, setWellPaths] = useState(Boolean(selectedLayers[WELL_PATHS_LAYER]))
  const [shalePlay, setShalePlay] = useState(Boolean(selectedLayers[SHALE_PLAYS]))
  const [convPlay, setConvPlay] = useState(Boolean(selectedLayers[CONVENTIONAL_PLAYS]))
  const [stateLayer, setStateLayer] = useState(Boolean(selectedLayers[STATES]))
  const [countyLayer, setCountyLayer] = useState(Boolean(selectedLayers[COUNTIES]))

  useEffect(() => {
    if (!selectedLayers) return null
  }, [selectedLayers])

  const toggleCheckedLayer = (layer) => () => {
    const currentLayer = Boolean(selectedLayers[layer])
    const newLayer = {
      ...selectedLayers,
      [layer]: !currentLayer,
    }
    setSelectedLayers(newLayer)
  }

  useEffect(() => {
    setShalePlay(selectedLayers[SHALE_PLAYS])
    setConvPlay(Boolean(selectedLayers[CONVENTIONAL_PLAYS]))
    setStateLayer(Boolean(selectedLayers[STATES]))
    setCountyLayer(Boolean(selectedLayers[COUNTIES]))
    setWellPaths(Boolean(selectedLayers[WELL_PATHS_LAYER]))
  }, [selectedLayers])


  const boundaryActions = [
    {
      icon: <Iconify icon='game-icons:oil-rig' style={{ color: shalePlay ? 'green' : 'red', fontSize: 25 }} />,
      name: 'Shale Basins',
      onClick: toggleCheckedLayer(SHALE_PLAYS),
    },
    {
      icon: <Iconify icon='fa6-solid:oil-well' style={{ color: convPlay ? 'green' : 'red', fontSize: 20 }} />,
      name: 'Conventional Basins',
      onClick: toggleCheckedLayer(CONVENTIONAL_PLAYS),
    },
    {
      icon: <Iconify icon='game-icons:texas' style={{ color: stateLayer ? 'green' : 'red', fontSize: 22 }} />,
      name: 'States',
      onClick: toggleCheckedLayer(STATES),
    },
    {
      icon: <Iconify icon='carbon:map-boundary' style={{ color: countyLayer ? 'green' : 'red', fontSize: 22 }} />,
      name: 'Counties',
      onClick: toggleCheckedLayer(COUNTIES),
    },
    {
      icon: <Iconify icon='ph:path-fill' style={{ color: wellPaths ? 'green' : 'red', fontSize: 22 }} />,
      name: 'Well Paths',
      onClick: toggleCheckedLayer(WELL_PATHS_LAYER),
    },
  ]

  return (
    <Box sx={{ position: 'absolute', top: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2) }}>
      <SpeedDial
        ariaLabel='Boundary Preferences'
        icon={<Iconify icon='ci:map' style={{ color: '#ffffff', fontSize: 22 }} />}
        direction={'left'}
        hidden={false}
        FabProps={{ size: 'small' }}>
        {boundaryActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipPlacement={'bottom'}
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                  transition: '.5s',
                  '&:hover': {
                    dropShadow: ' 5px 5px 10px 10px grey',
                    color: 'white',
                  },
                },
              },
            }}
            onClick={() => action.onClick && action.onClick()}
          />
        ))}
      </SpeedDial>
    </Box>
  )
}

export default MapPrefs
