import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import { numberWithCommas } from 'utils/stringFunctions'
import { round } from 'utils/numberFunctions'
import Avatar from '@mui/material/Avatar'
import { Icon as Iconify } from '@iconify/react'
import useDrillString from 'components/common/hooks/useDrillString/useDrillString'
import useMotorReportImages from 'components/common/hooks/useMotorReportImages'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import { dateComparator, isDateLessThan, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const defaultColDef = {
  resizable: true,
  sortable: true,
  autoHeight: true,
  editable: false,
}

const AssetHistoryGrid = ({ assetHistory, showHideGridPane, showGrid }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getUnitsText } = useUnits()
  const { fetchImagesManual } = useMotorReportImages()
  const [isPrinting, setPrinting] = useState(false)
  const isPrintingRef = useRef(false)
  const { getAgGridTheme, getChartBackColor, getWindowBarColor } = useInnovaTheme()

  const { getBhaReportPdfData, getMotorReportPdfData, setWell } = useDrillString()
  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    autoSizeColumns()
  }, [assetHistory]) // eslint-disable-line react-hooks/exhaustive-deps

  const onBhaPdfExport = useCallback(
    async (bhaNum, wellName) => {
      if (bhaNum === null || bhaNum === undefined) return
      if (isPrintingRef.current) return
      isPrintingRef.current = true
      setPrinting(true)

      await setWell(wellName)
      let pdfDoc = await getBhaReportPdfData(bhaNum)
      isPrintingRef.current = false

      if (!pdfDoc) return
      if (!_isMounted.current) return
      setPrinting(false)

      const blob = await pdf(pdfDoc.data).toBlob()
      FileSaver.saveAs(blob, pdfDoc.fileName)
    },
    [getBhaReportPdfData, setWell],
  )

  const onMotorReportPdfExport = useCallback(
    async (bhaNum, wellName) => {
      if (bhaNum === null || bhaNum === undefined) return
      if (isPrintingRef.current) return
      isPrintingRef.current = true
      setPrinting(true)

      await setWell(wellName)
      let motorImages = await fetchImagesManual(bhaNum, wellName)
      let pdfDoc = await getMotorReportPdfData(bhaNum, motorImages)

      isPrintingRef.current = false
      if (!pdfDoc) return
      if (!_isMounted.current) return
      setPrinting(false)

      const blob = await pdf(pdfDoc.data).toBlob()
      FileSaver.saveAs(blob, pdfDoc.fileName)
    },
    [setWell, getMotorReportPdfData, fetchImagesManual],
  )

  const dateSortComparator = (date1, date2) => {
    const isValidDate = (value) => {
      return value instanceof Date || !isNaN(Date.parse(value))
    }

    if (!isValidDate(date1) || !isValidDate(date2)) {
      return 0
    }

    const parsedDate1 = new Date(Date.parse(date1))
    const parsedDate2 = new Date(Date.parse(date2))
    return parsedDate1.getTime() - parsedDate2.getTime()
  }

  const dateFilterComparator = useCallback(dateComparator, [])

  const isRowMaster = useMemo(() => {
    return (data) => {
      return data ? data?.runs?.length > 0 : false
    }
  }, [])

  const columnDefs = [
    {
      headerName: '',
      field: '',
      autoHeight: true,
      minWidth: 60,
      maxWidth: 60,
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      headerName: '',
      width: 100,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              margin: 'auto',
              width: 35,
              height: 35,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: GetActivityColor(params?.data?.wellStatus),
              borderRadius: '50%',
            }}>
            {params?.data.hasOwnProperty('wellStatus') ? (
              <Avatar
                alt={params?.data?.wellStatus}
                src={getStatusAvatar(params?.data?.wellStatus)}
                style={{ width: 35, height: 35 }}
              />
            ) : null}
          </Box>
        )
      },
    },
    {
      headerName: 'Operator',
      field: 'operator',
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      maxWidth: 400,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Well',
      field: 'wellName',
      wrapText: true,
      autoHeight: true,
      minWidth: 300,
      maxWidth: 400,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Job#',
      field: 'jobNum',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'API#',
      field: 'apiNum',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Rig',
      field: 'rig',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'State',
      field: 'state',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'County',
      field: 'county',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: 'Status',
      field: 'wellStatus',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellRenderer: (params) => {
        return (
          <span
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: GetActivityColor(params.data?.wellStatus),
            }}>
            <i className='fa fa-check' aria-hidden='true'></i>
            {params.data?.wellStatus}
          </span>
        )
      },
    },
    {
      headerName: 'Formation',
      field: 'formation',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'Start Date',
      field: 'isoStartDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
      comparator: dateSortComparator,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params.data?.isoStartDate) return ''
        if (params.data?.isoStartDate === '') return ''
        if (isDateLessThan(params.data?.isoStartDate, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.isoStartDate)).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      },
    },
    {
      headerName: 'End Date',
      field: 'isoEndDate',
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
      comparator: dateSortComparator,
      minWidth: 120,
      valueGetter: (params) => {
        if (!params.data?.isoEndDate) return ''
        if (typeof params.data?.isoEndDate !== 'string') return ''
        if (params.data?.endDate === '') return ''
        if (isDateLessThan(params.data?.isoEndDate, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.isoEndDate)).toLocaleDateString('default', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      },
    },
    {
      headerName: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'startDepth',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => numberWithCommas(round(params.data?.startDepth, 2)),
    },
    {
      headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
      colId: 'endDepth',
      cellStyle: centerAlignCell,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => numberWithCommas(round(params.data?.endDepth, 2)),
    },
  ]

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getDetailContextMenuItems = useCallback(
    (params) => {
      let menuItems = [
        {
          name: 'Reset columns',
          disabled: false,
          action: () => {
            gridApi.current.resetColumnState()
          },
          icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        },
        {
          name: 'Reset filters',
          disabled: false,
          action: () => {
            gridApi.current.setFilterModel(null)
          },
          icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        },
        'copy',
        {
          name: 'Export',
          disabled: false,
          action: () => {
            gridApi.current.exportDataAsExcel({
              fileName: 'AssetRunHistory.xlsx',
              sheetName: 'History',
            })
          },
          icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
          cssClasses: ['leftAlign'],
        },
      ]

      if (params.node?.data) {
        menuItems.push(
          {
            name: 'Print BHA',
            disabled: false,
            action: () => {
              onBhaPdfExport(params.node?.data.bhaNum, params.node?.data.wellName)
            },
            icon: '<span class="iconify" data-icon="teenyicons:pdf-solid" data-width="20" style = "color:#4BB2F9"></span>',
            cssClasses: ['leftAlign'],
          },
          {
            name: 'Print Performance Report',
            disabled: false,
            action: () => {
              onMotorReportPdfExport(params.node?.data.bhaNum, params.node?.data.wellName)
            },
            icon: '<span class="iconify" data-icon="teenyicons:pdf-solid" data-width="20" style = "color:#00ff00"></span>',
            cssClasses: ['leftAlign'],
          },
        )
      }

      return menuItems
    },
    [onBhaPdfExport, onMotorReportPdfExport],
  )

  const detailCellRendererParams = useMemo(() => {
    return {
      detailGridOptions: {
        getContextMenuItems: getDetailContextMenuItems,
        columnDefs: [
          {
            headerName: 'BHA#',
            field: 'bhaNumRep',
            cellStyle: centerAlignCell,
            valueFormatter: (params) =>
              parseInt(params.data?.bhaNumRep) < 0 ? params.data?.bhaNum : params.data?.bhaNumRep,
            resizable: true,
          },
          {
            headerName: 'Start Date',
            field: 'isoStartDate',
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: dateFilterComparator,
            },
            comparator: dateSortComparator,
            minWidth: 120,
            valueGetter: (params) => {
              if (!params.data?.isoStartDate) return ''
              if (params.data?.isoStartDate === '') return ''
              if (isDateLessThan(params.data?.isoStartDate, '1990-01-01')) return ''
              return new Date(Date.parse(params.data?.isoStartDate)).toLocaleDateString('default', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            },
            resizable: true,
          },
          {
            headerName: 'End Date',
            field: 'isoEndDate',
            filter: 'agDateColumnFilter',
            filterParams: {
              comparator: dateFilterComparator,
            },
            comparator: dateSortComparator,
            minWidth: 120,
            valueGetter: (params) => {
              if (!params.data?.isoEndDate) return ''
              if (typeof params.data?.isoEndDate !== 'string') return ''
              if (params.data?.endDate === '') return ''
              if (isDateLessThan(params.data?.isoEndDate, '1990-01-01')) return ''
              return new Date(Date.parse(params.data?.isoEndDate)).toLocaleDateString('default', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })
            },
            resizable: true,
          },
          {
            headerName: `Start Depth (${getUnitsText(UNITS_FOR.Depth)})`,
            colId: 'startDepth',
            cellStyle: centerAlignCell,
            filter: 'agSetColumnFilter',
            filterParams: {
              excelMode: 'windows',
            },
            valueFormatter: (params) => numberWithCommas(round(params.data?.startDepth, 2)),
            resizable: true,
          },
          {
            headerName: `End Depth (${getUnitsText(UNITS_FOR.Depth)})`,
            colId: 'endDepth',
            cellStyle: centerAlignCell,
            autoHeight: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              excelMode: 'windows',
            },
            valueFormatter: (params) => numberWithCommas(params.data?.endDepth),
            resizable: true,
          },
          {
            headerName: `Drilled (${getUnitsText(UNITS_FOR.Depth)})`,
            field: 'drilled',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => numberWithCommas(round(params.data?.drilled, 2)),
            resizable: true,
          },
          {
            headerName: 'BRT Hrs',
            field: 'brtHrs',
            valueFormatter: (params) => round(params.data?.brtHrs, 2),
            resizable: true,
          },
          {
            headerName: 'Drlg Hrs',
            field: 'drillingHrs',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => round(params.data?.drillingHrs, 2),
            resizable: true,
          },
          {
            headerName: 'Total D&C',
            field: 'drillingCircHrs',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => round(params.data?.drillingCircHrs, 2),
            resizable: true,
          },
          {
            headerName: 'Max DLS',
            field: 'maxDls',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => round(params.data?.maxDls, 2),
            resizable: true,
          },
          {
            headerName: 'Max Temp',
            field: 'maxTemp',
            cellStyle: centerAlignCell,
            valueFormatter: (params) => round(params.data?.maxTemp, 2),
            resizable: true,
          },
        ],
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data?.runs)
      },
    }
  }, [getUnitsText, getDetailContextMenuItems, dateFilterComparator])

  return (
    <React.Fragment>
      {isPrinting ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}

      <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
        <Box
          sx={{
            width: '100%',
            height: '25px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: getWindowBarColor(),
          }}>
          {!showGrid ? (
            <Box
              onClick={showHideGridPane}
              sx={{
                marginLeft: '50%',
                color: '#f0f0f0',
                cursor: 'pointer',
                '&:hover': {
                  color: '#429ceb',
                },
              }}>
              Asset History
            </Box>
          ) : null}
          <Box
            onClick={showHideGridPane}
            sx={{
              marginLeft: 'auto',
              width: '25px',
              height: '25px',
              backgroundColor: '#222628',
              cursor: 'pointer',
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
          </Box>
        </Box>
        <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 25px)' }}>
          <AgGridReact
            rowData={Array.isArray(assetHistory) ? assetHistory : []}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            isRowMaster={isRowMaster}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            masterDetail={true}
            detailCellRendererParams={detailCellRendererParams}
            gridOptions={gridOptions}
            onFirstDataRendered={onFirstDataRendered}
            onGridReady={onGridReady}
            headerHeight={30}
          />
        </div>
      </Box>
    </React.Fragment>
  )
}

export default AssetHistoryGrid
