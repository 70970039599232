import { Vector3, DoubleSide, BufferGeometry, BufferAttribute } from 'three'
import { calcSurvey } from 'utils/projectionCalcs'
import { cloneDeep } from 'lodash'

const translatePoints = (p1, p2, translationDistance) => {
  const lineDirection = new Vector3().copy(p2).sub(p1).normalize()

  // Calculate the perpendicular direction in the x-z plane
  const perpendicularDirection = new Vector3(lineDirection.z, 0, -lineDirection.x)

  // Scale the perpendicular direction by translation distance for left/right translation
  const translation = perpendicularDirection.clone().multiplyScalar(translationDistance)

  // Translate both points using the calculated translation
  const translatedP1 = p1.clone().add(translation)
  const translatedP2 = p2.clone().add(translation)

  return { p1: translatedP1, p2: translatedP2 }
}

const GeoSteering = ({ geosteering, display, scale }) => {
  if (!display) return null
  if (!Array.isArray(geosteering)) return null
  if (geosteering.length === 0) return null
  if (geosteering.length < 2) return null

  let points = []
  let meshes = []
  for (let i = 0; i < geosteering.length - 1; i++) {
    let start = { ...geosteering[i] }
    let end = { ...geosteering[i + 1] }
    end.inc = start.inc
    calcSurvey(start, end)

    let startCoord = new Vector3(start.ns * scale, -(start.tvd - start.thickup) * scale, start.ew * scale)
    let endCoord = new Vector3(end.ns * scale, -(end.tvd - start.thickup) * scale, end.ew * scale)

    if (!start.isInterpolated && points.length === 0) points.push(startCoord)
    if (!end.isInterpolated) points.push(endCoord)

    if (points.length > 1 && !end.isInterpolated) {
      let rightSideTop = []
      let leftSideTop = []

      for (let j = 0; j < points.length - 1; j++) {
        const { p1: p1R, p2: p2R } = translatePoints(points[j], points[j + 1], 10)
        rightSideTop.push(cloneDeep(p1R))

        const { p1: p1L, p2: p2L } = translatePoints(points[j], points[j + 1], -10)
        leftSideTop.push(cloneDeep(p1L))

        if (j === points.length - 2) {
          rightSideTop.push(cloneDeep(p2R))
          leftSideTop.push(cloneDeep(p2L))
        }
      }

      let topPoints = [...rightSideTop, ...leftSideTop.reverse()]
      let thickness = (start.thickup + start.thickdown) * scale
      let bottomPoints = []
      for (let j = 0; j < topPoints.length; j++) {
        let pt = cloneDeep(topPoints[j])
        pt.y -= thickness
        bottomPoints.push(pt)
      }

      const topVertices = []
      const bottomVertices = []

      for (let j = 0; j < topPoints.length; j++) {
        topVertices.push(topPoints[j].x, topPoints[j].y, topPoints[j].z)
        bottomVertices.push(bottomPoints[j].x, bottomPoints[j].y, bottomPoints[j].z)
      }

      const topFaces = []
      const bottomFaces = []

      for (let i = 0; i < topPoints.length - 1; i++) {
        topFaces.push(i, i + 1, topPoints.length - i - 1)
        bottomFaces.push(topPoints.length +i, topPoints.length + i + 1, topPoints.length + topPoints.length - i - 1)
      }

      const sideFaces = []
      for (let i = 0; i < topPoints.length; i++) {
        let nextIndex = (i + 1) % topPoints.length
        sideFaces.push(i, nextIndex, i + topPoints.length)
        sideFaces.push(nextIndex, nextIndex + topPoints.length, i + topPoints.length)
      }

      const geometry = new BufferGeometry()
      geometry.setAttribute('position', new BufferAttribute(new Float32Array([...topVertices, ...bottomVertices]), 3))
      geometry.setIndex([...topFaces, ...bottomFaces, ...sideFaces])

      meshes.push(
        <mesh key={`geoSteering${i}`} geometry={geometry}>
          <meshBasicMaterial attach='material' color={'#00FF00'} side={DoubleSide} opacity={0.3} transparent={true} />
        </mesh>,
      )

      points = []
    }

    if (end.isInterpolated) points.push(endCoord)
  }

  return meshes
}

export default GeoSteering
