import { useRef, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { themeSelector } from 'atoms'
import { appColors } from 'utils'

function useInnovaTheme() {
    const _isMounted = useRef()
    const [theme, setTheme] = useRecoilState(themeSelector)

    useEffect(() => {
        _isMounted.current = true
        return () => {
            _isMounted.current = false
        }
    }, [])

    const getAgGridTheme = () => {
        return theme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'
    }

    const searchBarStyle = (flex = 1) => ({
        flex: flex,
        backgroundColor: theme === 'dark' ? '#111111' : '#f0f0f0',
        borderRadius: 0,
        border: theme === 'dark' ? '' : '1px solid #ccc',
    })

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark')
    }

    const getBackColor = () => {
        return theme === 'dark' ? appColors.itemBackColor : '#fff'
    }

    const getChartBackColor = () => {
        return theme === 'dark' ? '#192734' : '#fff'
    }

    const getWindowBarColor = () => {
        return theme === 'dark' ? '#181d1f' : '#385d85'
    }

    const getTreeBackColor = () => {
        return theme === 'dark' ? '#181d1f' : '#fff'
    }

    const getActionBarColor = () => {
        return theme === 'dark' ? '#000' : '#4c85c2'
    }

    const getTextColor = () => {
        return theme === 'dark' ? '#FFF' : '#000'
    }

    const getLinearGradient = () => {
        return theme === 'dark'
            ? 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)'
            : 'linear-gradient(to bottom, #ffffff 0%, #C0C0C0 100%)'
    }

    return { getLinearGradient, toggleTheme, theme, getAgGridTheme, searchBarStyle, getBackColor, getChartBackColor, getWindowBarColor, getTreeBackColor, getActionBarColor, getTextColor }
}

export default useInnovaTheme