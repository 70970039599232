import React, { useState, useRef, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { Backdrop, CircularProgress } from '@mui/material'
import WellSelectorEditor from 'components/common/CellEditors/WellSelectorEditor'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DataAcqRestoreArchiveModal = ({ selectedFile, onClose }) => {
  const _isMounted = useRef()
  const isLoadingRef = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [isLoading, setLoading] = useState(false)
  const gridApi = useRef(null)
  const [dropDownLists, setDropDownLists] = useState({})
  const { getAgGridTheme } = useInnovaTheme()

  const getDropDownData = useInnovaAxios({
    url: '/dataAcq/getDropDownLists',
  })

  const restoreArchive = useInnovaAxios({
    url: '/dataAcq/restoreWellArchive',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseDialog = async () => {
    onClose()
  }

  const handleRestore = async () => {
    if (!gridApi.current) return

    let data = []
    gridApi.current.forEachNode((node) => {
      if (node.data) {
        data.push(node.data)
      }
    })

    if (data.length === 0) return
    data[0].dataStoreId = data[0].dataStore

    if (isLoadingRef.current) return false
    if (!_isMounted.current) return
    setLoading(true)

    isLoadingRef.current = true
    let res = await restoreArchive(data[0])
    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res?.data?.status !== 'OK') {
      setStatus({
        show: true,
        severity: 'error',
        message: res?.data?.message,
      })
      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: res?.data?.message,
    })

    onClose()
  }

  const fetchDropDowns = async () => {
    if (isLoadingRef.current) return
    if (!_isMounted.current) return
    setLoading(true)
    isLoadingRef.current = true

    const response = await getDropDownData()
    isLoadingRef.current = false
    if (!_isMounted.current) return
    setLoading(false)

    if (response?.error) return
    setDropDownLists(response.data ? response.data : {})
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  let columnDef = [
    {
      field: 'dataStore',
      colId: 'dataStore',
      headerName: 'Data Store',
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return ''
        if (params?.data?.dataStore === -1) return 'Default'
        if (!dropDownLists) return ''
        if (!Array.isArray(dropDownLists?.dataStores)) return ''
        let index = dropDownLists.dataStores.findIndex((v) => v.uid === params?.data?.dataStore)
        if (index < 0) return ''
        return unescapeHtml(dropDownLists.dataStores[index].storeName)
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        let dropDowns = ['Default']
        if (Array.isArray(dropDownLists?.dataStores)) {
          for (let i = 0; i < dropDownLists.dataStores.length; i++) {
            dropDowns.push(
              `#${dropDownLists.dataStores[i].uid} - ${unescapeHtml(dropDownLists.dataStores[i].storeName)}`,
            )
          }
        }

        return {
          values: dropDowns,
        }
      },
      valueSetter: (params) => {
        if (!params.newValue) return false
        if (typeof params.newValue !== 'string') return false

        let data = cloneDeep(params.data)
        if (params.newValue === 'Default') {
          data.dataStore = -1

          gridApi.current.applyTransaction({
            update: [data],
          })

          return true
        }

        if (!Array.isArray(dropDownLists?.dataStores)) return false
        let index = dropDownLists.dataStores.findIndex(
          (s) => `#${s.uid} - ${unescapeHtml(s.storeName)}` === params.newValue,
        )

        if (index < 0) return false
        data.dataStore = dropDownLists.dataStores[index].uid

        gridApi.current.applyTransaction({
          update: [data],
        })

        return true
      },
    },
    {
      field: 'wellName',
      colId: 'wellName',
      headerName: 'Well Name',
      cellEditor: WellSelectorEditor,
      cellEditorPopup: true,
      width: 400,
      cellEditorParams: (params) => {
        return {
          wellList: Array.isArray(dropDownLists.wellNames) ? dropDownLists.wellNames : [],
        }
      },
    },
  ]

  return (
    <>
      <Dialog
        maxWidth='xl'
        PaperProps={{
          sx: {
            width: '80vw',
            height: '70vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={handleCloseDialog}>
        <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <DialogTitle>Restore Archive</DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              rowData={[{ uid: selectedFile.uid, wellName: selectedFile.wellName, dataStore: -1 }]}
              columnDefs={columnDef}
              defaultColDef={defaultColDef}
              animateRows={true}
              enableBrowserTooltips={true}
              headerHeight={30}
              onGridReady={onGridReady}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={() => handleRestore()}>
            Restore
          </Button>
          <Button color='primary' variant='contained' onClick={handleCloseDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default DataAcqRestoreArchiveModal
