import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  sortColDefs,
  htmlSymbolHandling,
  CustomLoadingOverlay,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { cloneDeep } from 'lodash'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import useInnovaContacts from 'components/common/hooks/useInnovaContacts'
import useInnovaInstallTypes from 'components/common/hooks/useInnovaInstallTypes'
import useInnovaLicenseFeatures from 'components/common/hooks/useInnovaLicenseFeatures'
import { debounce } from 'lodash'
import { styled } from '@mui/styles'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import * as yup from 'yup'
import InputModal from 'components/common/InputModal'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { isoDateAddition, IsoDateTimeStringToDateString } from 'utils/dateTimeFunctions'
import FileSaver from 'file-saver'
import { uuidv4, removeSymbolsFromName } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const licenseExpiryDropDown = [
  { label: '1 Week', value: '1 Week' },
  { label: '2 Weeks', value: '2 Weeks' },
  { label: '1 Month', value: '1 Month' },
  { label: '2 Months', value: '2 Months' },
  { label: '1 Year', value: '1 Year' },
  { label: 'Other', value: 'Other' },
]

const InnovaLicensingPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [licenseData, setLicenseData] = useState([])
  const [searchText, setSearchText] = useState('')
  const selectedLicense = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const [showCreateLicenseModal, setshowCreateLicenseModal] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [confirmKill, setConfirmKill] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dropDowns] = useInnovaAdminDropDowns(false)
  const [innovaContacts] = useInnovaContacts()
  const [installTypes] = useInnovaInstallTypes(false)
  const [licenseFeatures] = useInnovaLicenseFeatures(false)
  const [showEmailLicense, setShowEmailLicense] = useState(false)
  const [showLicenseInfo, setShowLicenseInfo] = useState(false)
  const [selectedLicenseFeatures, setSelectedLicenseFeatures] = useState([])
  const [showCopyLicense, setShowCopyLicense] = useState(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const emailLicense = useInnovaAxios({
    url: '/admin/licenses/emailLicense',
  })

  const getLicFeatures = useInnovaAxios({
    url: '/admin/licenses/getLicenseFeatures',
  })

  const duplicateLicense = useInnovaAxios({
    url: '/admin/licenses/duplicateLicense',
  })

  const killLicense = useInnovaAxios({
    url: '/admin/licenses/licenseKill',
  })

  const getLicenseData = useInnovaAxios({
    url: '/admin/licenses/getLicenses',
  })

  const deleteLicense = useInnovaAxios({
    url: '/admin/licenses/deleteLicense',
  })

  const addLicense = useInnovaAxios({
    url: '/admin/licenses/createLicense',
  })

  const updateLicense = useInnovaAxios({
    url: '/admin/licenses/updateLicense',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaLicensesPageKey)
    fetchLicensedata()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.licenseId)
    }
  }, [])

  const fetchLicensedata = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getLicenseData()

    if (response?.error) return
    if (!_isMounted.current) return
    if (!Array.isArray(response.data)) return
    let sortedData = cloneDeep(response.data)

    sortedData.sort((firstEl, secondEl) => {
      let d1 = new Date(Date.parse(firstEl.generatedDate)).getTime()
      let d2 = new Date(Date.parse(secondEl.generatedDate)).getTime()
      if (d1 === d2) return 0
      return d1 < d2 ? 1 : -1
    })

    setLicenseData(sortedData)
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('licenseId')) return

      if (isUpdating.current) return
      data = htmlSymbolHandling(data)
      data.companyName = data.company

      isUpdating.current = true
      let res = await updateLicense(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        data.companyId = res.data.companyId
        data.invoiceNum = res.data.invoiceNum
        data.invoiceValue = res.data.invoiceValue
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [updateLicense],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [handleUpdate])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('innovaLicensesGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaLicensesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InnovaLicenses.xlsx',
            sheetName: 'Licenses',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleDelete = useCallback(async () => {
    if (!selectedLicense.current) return
    if (!selectedLicense.current.hasOwnProperty('licenseId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteLicense(selectedLicense.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedLicense.current],
      })
    }

    selectedLicense.current = null
  }, [deleteLicense])

  const onClickDelete = useCallback(
    (data) => {
      selectedLicense.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete License',
        text: `Are you sure you want to ${data?.product} license for ${unescapeHtml(data?.company)}?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const createLicenseFile = (data) => {
    if (!data) return

    let blob = new Blob([data.license], { type: 'text/plain;charset=utf-8' })

    let fileName = `${data.product === 'ENGINEERING' ? 'ENG' : 'WS'} - LICENSE - ${
      data.computerName === '' ? uuidv4() : removeSymbolsFromName(data.computerName)
    } - ${data.company === '' ? uuidv4() : removeSymbolsFromName(unescapeHtml(data.company))}.txt`

    FileSaver.saveAs(blob, fileName)
  }

  const getLicenseFeatures = useCallback(
    async (data) => {
      setSelectedLicenseFeatures([])
      if (!data) return
      if (data.hasOwnProperty('licenseType') === false) return

      let res = await getLicFeatures({
        license: data.license,
        orgName: data.licenseType,
        product: data.product,
      })

      if (res.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      let featureArr = []
      let product = ''
      if (
        res?.data?.hasOwnProperty('wellSeeker') &&
        Array.isArray(res?.data?.wellSeeker) &&
        res?.data?.wellSeeker.length > 0
      ) {
        featureArr = res?.data?.wellSeeker
        product = 'Well Seeker'
      }

      if (
        res?.data?.hasOwnProperty('engineering') &&
        Array.isArray(res?.data?.engineering) &&
        res?.data?.engineering.length > 0
      ) {
        featureArr = res?.data?.engineering
        product = 'Engineering'
      }

      let licFeatures = []
      licFeatures.push({
        product: product,
        data: featureArr.map((feat, index) => {
          return { feature: feat.description, id: index }
        }),
      })

      if (_isMounted.current === true) setSelectedLicenseFeatures(licFeatures)
    },
    [getLicFeatures],
  )

  const onClickLicenseKill = (data) => {
    let licenseActive = data.licenseKilled || !data.licenseActive ? false : true

    setConfirmKill({
      show: true,
      title: `${licenseActive ? 'Deactivate' : 'Activate'} License`,
      text: `Are you sure you want ${licenseActive ? 'deactivate' : 'activate'} ${
        data?.product
      } license for ${unescapeHtml(data?.company)}?`,
    })
  }

  const handleMenuClick = useCallback(
    async (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedLicense.current = data
      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'email') {
        setShowEmailLicense(true)
      }

      if (action === 'saveToFile') {
        createLicenseFile(data)
      }

      if (action === 'features') {
        await getLicenseFeatures(data)
        setShowLicenseInfo(true)
      }

      if (action === 'duplicate') {
        await getLicenseFeatures(data)
        setShowCopyLicense(true)
      }

      if (action === 'deactivate') {
        onClickLicenseKill(data)
      }
    },
    [onClickDelete, getLicenseFeatures],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'menu',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            showBottomRow: false,
            menuItems: [
              {
                label: 'License Features',
                action: 'features',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fluent:multiselect-16-filled' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Duplicate',
                action: 'duplicate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='ant-design:copy-outlined' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Email',
                action: 'email',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:email-outline' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Download to file',
                action: 'saveToFile',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='bxs:file' />
                  </StyledIconContainer>
                ),
              },
              {
                label: params?.data?.licenseKilled || !params?.data?.licenseActive ? 'Activate' : 'Deactivate',
                action: 'deactivate',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon
                      icon='material-symbols:disabled-by-default-sharp'
                      color={params?.data?.licenseKilled || !params?.data?.licenseActive ? '#00ff00' : 'red'}
                    />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'company',
        colId: 'company',
        headerName: 'Company',
        pinned: 'left',
        lockPosition: 'left',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        editable: true,
        valueGetter: (params) => unescapeHtml(params.data?.company),
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.companys) ? dropDowns.companys : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'product',
        colId: 'product',
        headerName: 'Product',
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        colId: 'status',
        headerName: 'Status',
        pinned: 'left',
        lockPosition: 'left',
        valueGetter: (params) => {
          if (params?.data?.licenseKilled || !params?.data?.licenseActive) return 'Deactivated'
          if (params?.data?.perpetualLicense) return 'Perpetual'
          if (params?.data?.expiryDays <= 0) return 'Expired'
          if (params?.data?.expiryDays <= 60) return `Expires soon`
          return 'Valid'
        },
        cellStyle: (params) => {
          let color = '#00ff00'
          if (params?.data?.licenseKilled || !params?.data?.licenseActive) color = 'red'
          if (!params?.data?.perpetualLicense && color !== 'red') {
            if (params?.data?.expiryDays <= 7) color = 'red'
            if (params?.data?.expiryDays > 7 && params?.data?.expiryDays <= 30) color = 'orange'
          }

          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            color: color,
          }
        },
      },
      {
        field: 'expiryDays',
        colId: 'expiryDays',
        headerName: 'Expiry Days',
        valueFormatter: (params) => {
          if (params?.data.perpetualLicense) return 'Never'
          if (params?.data.expiryDays <= 0) return 'Expired'
          return parseInt(params?.data?.expiryDays)
        },
        cellStyle: (params) => {
          let color = '#00ff00'
          if (!params?.data?.perpetualLicense) {
            if (params?.data?.expiryDays <= 7) color = 'red'
            if (params?.data?.expiryDays > 7 && params?.data?.expiryDays <= 30) color = 'orange'
          }

          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: color,
          }
        },
      },
      {
        field: 'contact',
        colId: 'contact',
        headerName: 'Contact',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        editable: true,
        valueGetter: (params) => {
          if (!params?.data?.firstName && !params?.data?.lastName) return ''
          return unescapeHtml(`${params.data?.firstName} ${params.data?.lastName}`)
        },
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(innovaContacts) ? innovaContacts : []
          dropDown = dropDown.filter((contact) => contact.companyName === params.data?.company)

          return {
            values: dropDown.map((value) => value.label),
          }
        },
        valueSetter: (params) => {
          let data = cloneDeep(params.data)
          if (!params.newValue) {
            data.contactId = 0
            data.firstName = ''
            data.lastName = ''
            handleUpdate(data)
            return false
          }

          if (typeof params.newValue !== 'string') {
            data.contactId = 0
            data.firstName = ''
            data.lastName = ''
            handleUpdate(data)
            return false
          }

          if (params.newValue === '') {
            data.contactId = 0
            data.firstName = ''
            data.lastName = ''
            handleUpdate(data)
            return false
          }

          if (!Array.isArray(innovaContacts)) return false
          if (innovaContacts.length === 0) return false
          if (!params.newValue) return false
          if (typeof params.newValue !== 'string') return false

          let contact = innovaContacts.find((c) => c.label === params.newValue)
          if (!contact) return false

          data.contactId = contact.value
          data.firstName = contact.firstName
          data.lastName = contact.lastName

          handleUpdate(data)
        },
      },
      {
        field: 'generatedDate',
        colId: 'generatedDate',
        headerName: 'Created Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => `${dateTimeFormatter(params.data?.generatedDate)}`,
      },
      {
        field: 'generatedBy',
        colId: 'generatedBy',
        headerName: 'Created By',
        valueFormatter: (params) => getUserNameFromEmail(params?.data?.generatedBy),
      },
      {
        field: 'expiryDate',
        colId: 'expiryDate',
        headerName: 'Expiry Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => `${dateTimeFormatter(params.data?.expiryDate)}`,
      },
      {
        field: 'maxUsers',
        colId: 'maxUsers',
        headerName: 'Max Users',
        cellStyle: centerAlignCell,
      },
      {
        field: 'licenseType',
        colId: 'licenseType',
        headerName: 'Install Type',
      },
      {
        field: 'computerName',
        colId: 'computerName',
        headerName: 'Comp Name',
        editable: true,
      },
      {
        field: 'computerCode',
        colId: 'computerCode',
        headerName: 'Comp Code',
        editable: true,
      },
      {
        field: 'invoiceNum',
        colId: 'invoiceNum',
        headerName: 'Invoice#',
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        editable: true,
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.invoiceNums) ? dropDowns.invoiceNums : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'invoiceValue',
        colId: 'invoiceValue',
        headerName: 'Invoice Value',
        valueFormatter: (params) =>
          params?.data?.invoiceValue > 0 ? numberWithCommasDecimals(params?.data?.invoiceValue, 2) : '',
        cellStyle: centerAlignCell,
      },
      {
        field: 'lockedVersion',
        colId: 'lockedVersion',
        headerName: 'Verion Locked',
        cellStyle: centerAlignCell,
      },
      {
        field: 'isEvaluation',
        colId: 'isEvaluation',
        headerName: 'Evaluation',
      },
      {
        field: 'allowRemoteSession',
        colId: 'allowRemoteSession',
        headerName: 'Remote Access',
      },
      {
        field: 'perpetualLicense',
        colId: 'perpetualLicense',
        headerName: 'Perpetual',
      },
      {
        field: 'licenseKilled',
        colId: 'licenseKilled',
        headerName: 'Killed',
      },
      {
        field: 'licenseActive',
        colId: 'licenseActive',
        headerName: 'Active',
      },
      {
        field: 'deactivatedDate',
        colId: 'deactivatedDate',
        headerName: 'Deactivated Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => `${dateTimeFormatter(params.data?.deactivatedDate)}`,
      },
      {
        field: 'comments',
        colId: 'comments',
        headerName: 'Comments',
        editable: true,
        valueGetter: (params) => unescapeHtml(params.data?.comments),
      },
      {
        field: 'license',
        colId: 'license',
        headerName: 'License',
        maxWidth: 400,
      },
    ],
    [
      centerAlignCell,
      dropDowns,
      handleUpdate,
      dateFilterComparator,
      dateTimeFormatter,
      innovaContacts,
      handleMenuClick,
    ],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getInstallTypes = () => {
    if (!Array.isArray(installTypes)) return []
    return [...new Map(installTypes.map(item => [item.label, item])).values()].sort((a, b) => a.label.localeCompare(b.label))
  }

  const CreateLicenseDialog = ({ submitFunction, cancelFunction }) => {
    let formValidationSchema = yup.object({
      companyName: yup.string().required(),
      product: yup.string().required(),
      orgName: yup.string().required(),
      expiryDate: yup.string().required(),
      maxUsers: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .nullable(),
      evalDays: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .nullable(),
    })

    let dt = new Date(Date.now()).toISOString()

    let formData = [
      {
        tag: 'companyName',
        value: '',
        text: 'Company',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(dropDowns.companys) ? dropDowns?.companys : [],
        useLabel: true,
      },
      {
        tag: 'product',
        value: 'Well Seeker',
        text: 'Product',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(dropDowns.product) ? dropDowns?.product.slice(0, 2) : [],
        useLabel: true,
      },
      {
        tag: 'orgName',
        value: 'innova',
        text: 'Install Type',
        inputType: 'dropdown',
        dropDownValues: getInstallTypes(),
        useLabel: true,
        icon: 'floppy',
      },
      {
        tag: 'licenseDuration',
        value: '1 Month',
        text: 'License Duration',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(licenseExpiryDropDown) ? licenseExpiryDropDown : [],
        useLabel: true,
      },
      {
        tag: 'expiryDate',
        value: dt,
        text: 'Expiry Date',
        inputType: 'date',
        filterProperty: 'licenseDuration',
        filterValue: 'Other',
      },
      {
        tag: 'isEval',
        text: 'Evaluation License',
        value: false,
        inputType: 'boolean',
      },
      {
        tag: 'evalDays',
        value: '30',
        text: 'Evaluation Days',
        inputType: 'text',
        filterProperty: 'isEval',
        filterValue: 'true',
      },
      {
        tag: 'maxUsers',
        value: '50',
        text: 'Max Users',
        inputType: 'text',
      },
      {
        tag: 'computerCode',
        value: '',
        text: 'Computer Code',
        inputType: 'text',
        icon: 'computer',
        iconFamily: 'MaterialIcons',
      },
      {
        tag: 'computerName',
        value: '',
        text: 'Computer Name',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
      {
        tag: 'allowRemoteSession',
        text: 'Allow Remote Session',
        value: true,
        inputType: 'boolean',
      },
      {
        tag: 'allowDebugger',
        value: false,
        inputType: '-',
      },
      {
        tag: 'perpetualLicense',
        text: 'Perpestual License',
        value: false,
        inputType: 'boolean',
      },
      {
        tag: 'lockedVersion',
        value: '',
        text: 'Locked Version',
        inputType: 'text',
        filterProperty: 'perpetualLicense',
        filterValue: 'true',
      },
      {
        tag: 'invoiceNum',
        value: '',
        text: 'Invoice #',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(dropDowns.invoiceNums) ? dropDowns?.invoiceNums : [],
        doNotSort: true,
        useLabel: true,
        icon: 'dollar',
        iconFamily: 'FontAwesome',
      },
      {
        tag: 'contactId',
        value: '',
        text: 'Contact',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(innovaContacts) ? innovaContacts : [],
        useLabel: true,
        filterField: 'companyName',
      },
      {
        tag: 'comments',
        value: '',
        text: 'Comments',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
    ]

    if (Array.isArray(licenseFeatures)) {
      licenseFeatures.forEach((feature) => {
        formData.push({
          tag: feature.tag,
          value: feature.isDef,
          text: feature.label,
          inputType: 'boolean',
          filterProperty: 'product',
          filterValue: feature.product,
        })
      })
    }

    return (
      <InputModal
        open={showCreateLicenseModal}
        onClose={cancelFunction}
        title={'Generate License'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  function getNewExpiryDate(licenseDuration, expiryDate) {
    if (licenseDuration !== 'Other') {
      let currentDate = new Date(Date.now()).toISOString()
      let daysToAdd = 7
      if (licenseDuration === '2 Weeks') daysToAdd = 14
      if (licenseDuration === '1 Month') daysToAdd = 30
      if (licenseDuration === '2 Months') daysToAdd = 60
      if (licenseDuration === '1 Year') daysToAdd = 365
      expiryDate = isoDateAddition(currentDate, daysToAdd, true)
    }

    return expiryDate
  }

  function buildFeatureStr(data) {
    let features = ''
    for (const property in data) {
      if (data.isEngineering && property.includes('engineering') && data[property] === true) {
        let splitFeature = property.split('-')
        if (splitFeature.length > 1) features += `${splitFeature[1]}|`
      }

      if (!data.isEngineering && !property.includes('engineering') && data[property] === true) {
        let splitFeature = property.split('-')
        if (splitFeature.length > 1) features += `${splitFeature[1]}|`
      }
    }

    return features
  }

  const handleCreateLicense = async (data) => {
    setshowCreateLicenseModal(false)
    if (!data) return
    if (isLoading) return

    data.expiryDate = getNewExpiryDate(data.licenseDuration, data.expiryDate)
    data.expiryDate = IsoDateTimeStringToDateString(data.expiryDate)
    if (data.product === 'Engineering') data.isEngineering = true
    data.features = ''
    data.features = buildFeatureStr(data)

    setLoading(true)
    let res = await addLicense({ ...data })
    if (!_isMounted.current) return

    if (res.error) {
      setLoading(false)
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res.data) {
      gridApi.current.applyTransaction({
        add: [res.data],
        addIndex: 0,
      })
    }

    setStatus({ show: true, severity: 'success', message: 'License added' })
    setLoading(false)
    return true
  }

  const EmailLicenseDialog = ({ data, submitFunction, cancelFunction }) => {
    if (!data) return
    if (data.hasOwnProperty('licenseId') === false) return

    let formValidationSchema = yup.object({
      licenseId: yup.string().required(),
      emailAddress: yup.string().email().required(),
    })

    let formData = [
      {
        tag: 'licenseId',
        value: data.licenseId,
        inputType: '-',
      },
      {
        tag: 'emailAddress',
        value: '',
        text: 'Email Address',
        inputType: 'text',
      },
      {
        tag: 'comments',
        value: '',
        text: 'Comments',
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={showEmailLicense}
        onClose={cancelFunction}
        title={'Email license'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleEmailLicense = async (data) => {
    setShowEmailLicense(false)
    if (!data) return false
    if (data.hasOwnProperty('licenseId') === false) return

    let res = await emailLicense({ ...data })

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    setStatus({ show: true, severity: 'success', message: 'License emailed' })
    return
  }

  const isFeatureSelected = (featureName, selectedFeatures) => {
    if (!Array.isArray(selectedFeatures)) return false
    if (selectedFeatures.length === 0) return false
    if (!selectedFeatures[0].hasOwnProperty('data')) return false
    if (!Array.isArray(selectedFeatures[0].data)) return false

    for (let i = 0; i < selectedFeatures[0].data.length; i++) {
      if (selectedFeatures[0].data[i].feature !== featureName) continue
      return true
    }

    return false
  }

  const LicenseFeaturesDialog = ({ data, submitFunction, cancelFunction }) => {
    if (!data) return
    if (!data.hasOwnProperty('product')) return
    let formValidationSchema = yup.object({})

    let product = 'Well Seeker'
    if (data.product.includes('ENGINEERING')) product = 'Engineering'

    let formData = []

    if (Array.isArray(licenseFeatures)) {
      licenseFeatures.forEach((feature) => {
        if (feature.product === product) {
          formData.push({
            tag: feature.tag,
            value: isFeatureSelected(feature.label, selectedLicenseFeatures),
            text: feature.label,
            inputType: 'boolean',
            filterProperty: 'product',
            filterValue: feature.product,
          })
        }
      })
    }

    return (
      <InputModal
        open={showLicenseInfo}
        onClose={cancelFunction}
        title={'License Features'}
        formData={formData}
        submitForm={() => {}}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const DuplicateLicenseDialog = ({ data, submitFunction, cancelFunction }) => {
    if (!data) return
    if (data.hasOwnProperty('licenseId') === false) return

    let formValidationSchema = yup.object({
      expiryDate: yup.string().required(),
    })

    let product = 'Well Seeker'
    if (data.product.includes('ENGINEERING')) product = 'Engineering'

    let formData = [
      {
        tag: 'recordId',
        value: data.licenseId,
        inputType: '-',
      },
      {
        tag: 'product',
        value: product,
        inputType: '-',
      },
      {
        tag: 'licenseDuration',
        value: 'Other',
        text: 'License Duration',
        inputType: 'dropdown',
        dropDownValues: Array.isArray(licenseExpiryDropDown) ? licenseExpiryDropDown : [],
        useLabel: true,
        icon: 'calendar',
        iconFamily: 'AntDesign',
      },
      {
        tag: 'expiryDate',
        value: `${data.expiryDate}T00:01:00`,
        text: 'Expiry Date',
        inputType: 'date',
        filterProperty: 'licenseDuration',
        filterValue: 'Other',
      },
      {
        tag: 'computerCode',
        value: data.computerCode,
        text: 'Computer Code',
        inputType: 'text',
      },
      {
        tag: 'comments',
        value: data.comments,
        text: 'Comments',
        inputType: 'text',
      },
    ]

    if (Array.isArray(licenseFeatures)) {
      licenseFeatures.forEach((feature) => {
        if (feature.product === product) {
          formData.push({
            tag: feature.tag,
            value: isFeatureSelected(feature.label, selectedLicenseFeatures),
            text: feature.label,
            inputType: 'boolean',
            filterProperty: 'product',
            filterValue: feature.product,
          })
        }
      })
    }

    return (
      <InputModal
        open={showCopyLicense}
        onClose={cancelFunction}
        title={'Duplicate license'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleCopyLicense = async (data) => {
    setShowCopyLicense(false)
    if (!data) return false
    if (data.hasOwnProperty('recordId') === false) return
    if (isLoading) return

    if (data.licenseDuration === 'Other') {
      data.expiryDate = IsoDateTimeStringToDateString(data.expiryDate)
    }

    data.expiryDate = getNewExpiryDate(data.licenseDuration, data.expiryDate)
    data.expiryDate = IsoDateTimeStringToDateString(data.expiryDate)

    if (data.product === 'Engineering') data.isEngineering = true
    let features = buildFeatureStr(data)

    setLoading(true)
    let res = await duplicateLicense({
      recordId: data.recordId,
      expiryDate: data.expiryDate,
      features: features,
      computerCode: data.computerCode,
      comments: data.comments,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res.data) {
      gridApi.current.applyTransaction({
        add: [res.data],
        addIndex: 0,
      })
    }
    setStatus({ show: true, severity: 'success', message: 'License copied' })
  }

  const confirmKillLicense = async () => {
    if (isLoading) return

    setLoading(true)
    const res = await killLicense({
      license: selectedLicense.current.license,
      licenseId: selectedLicense.current.licenseId,
    })

    if (!_isMounted.current) return
    setLoading(false)

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (res.data) {
      selectedLicense.current.licenseActive = !selectedLicense.current.licenseActive
      gridApi.current.applyTransaction({
        update: [cloneDeep(selectedLicense.current)],
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `License ${selectedLicense.current.licenseActive ? 'activated' : 'deactivated'}`,
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {showCopyLicense ? (
        <DuplicateLicenseDialog
          data={selectedLicense.current}
          submitFunction={handleCopyLicense}
          cancelFunction={() => setShowCopyLicense(false)}
        />
      ) : null}
      {showEmailLicense ? (
        <EmailLicenseDialog
          data={selectedLicense.current}
          submitFunction={handleEmailLicense}
          cancelFunction={() => setShowEmailLicense(false)}
        />
      ) : null}
      {showCreateLicenseModal ? (
        <CreateLicenseDialog
          submitFunction={handleCreateLicense}
          cancelFunction={() => setshowCreateLicenseModal(false)}
        />
      ) : null}
      {showLicenseInfo ? (
        <LicenseFeaturesDialog
          data={selectedLicense.current}
          submitFunction={() => setShowLicenseInfo(false)}
          cancelFunction={() => setShowLicenseInfo(false)}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {confirmKill.show ? (
        <ConfirmDialog
          title={confirmKill?.title}
          open={confirmKill?.show}
          setOpen={() => setConfirmKill({ show: false })}
          onConfirm={() => confirmKillLicense()}>
          {confirmKill?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={licenseData}
            columnDefs={sortColDefs(columnDefs, 'innovaLicensesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchLicensedata()
              },
            },
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                setshowCreateLicenseModal(true)
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InnovaLicensingPage
