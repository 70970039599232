import { useEffect, useState, useRef } from 'react'

import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from '../useInnovaAxios'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentWellAtom, currentEngBhaHeadersAtom } from 'atoms'

function useEngDropDowns() {
  const _isMounted = useRef(true)
  const dropDowns = useRef({})
  const rawData = useRef({})
  const isLoading = useRef(false)
  const [dropDownsLoading, setDropDownsLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const setCurrentEngBhaHeaders = useSetRecoilState(currentEngBhaHeadersAtom)
  const currentWellRef = useRef(currentWell)

  const getData = useInnovaAxios({
    url: '/engineering/getDropDownLists',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
    fetchEngDropDowns()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchEngDropDowns = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return

    if (_isMounted.current) setDropDownsLoading(true)

    isLoading.current = true
    const res = await getData({ wellName: currentWellRef.current })
    isLoading.current = false

    if (!_isMounted.current) return
    setDropDownsLoading(false)
    rawData.current = res?.data ? res.data : {}
    getDropDowns()
  }

  async function getDropDowns() {
    if (!rawData.current) return
    let newDropDowns = {}

    for (const property in rawData.current) {
      newDropDowns[property] = []
      if (Array.isArray(rawData.current[property])) {
        if (property !== 'drillString' && property !== 'wellbores' && property !== 'engCases') {
          rawData.current[property].forEach((val) => {
            newDropDowns[property].push({ label: unescapeHtml(val), value: unescapeHtml(val) })
          })
        }

        if (property === 'drillString') {
          rawData.current[property].forEach((val) => {
            newDropDowns[property].push({
              label: `${val.bhaNumRep}:${unescapeHtml(val.description)}`,
              value: unescapeHtml(val.bhaNum),
            })
          })

          setCurrentEngBhaHeaders(rawData.current[property])
        }

        if (property === 'wellbores') {
          rawData.current[property].forEach((val) => {
            newDropDowns[property].push({
              label: `${unescapeHtml(val.description)}`,
              value: unescapeHtml(val.recordId),
            })
          })
        }

        if (property === 'engCases') {
          rawData.current[property].forEach((val) => {
            let label = val.active ? `${unescapeHtml(val.caseDesc)} (Active)` : unescapeHtml(val.caseDesc)

            newDropDowns[property].push({
              label: label,
              value: unescapeHtml(val.recordId),
            })
          })
        }

        let uniqueArray = [...newDropDowns[property]]
        newDropDowns[property] = []

        for (let i = 0; i < uniqueArray.length; i++) {
          let found = false
          for (let j = 0; j < uniqueArray.length; j++) {
            if (uniqueArray[j].value === uniqueArray[i].value && i !== j) {
              found = true
              break
            }
          }

          if (!found) newDropDowns[property].push({ ...uniqueArray[i] })
        }
      }

      if (property !== 'drillString' && property !== 'wellbores' && property !== 'engCases') {
        newDropDowns[property].sort((a, b) => typeof a?.label === 'string' ? a.label.localeCompare(b.label) : true)
      } else {
        newDropDowns[property].sort((a, b) => a.value < b.value)
      }
    }

    if (_isMounted.current) dropDowns.current = newDropDowns
  }

  const getDropDownValues = (param) => {
    if (!dropDowns.current) return []
    if (typeof param !== 'string') return []
    if (!Array.isArray(dropDowns.current[param])) return []
    return [...dropDowns.current[param]]
  }

  const getDropDownLabel = (params) => {
    if (!dropDowns.current) return ''
    if (typeof params?.data?.dropDownField !== 'string') return ''

    if (!Array.isArray(dropDowns.current[params.data.dropDownField])) return ''
    const list = dropDowns.current[params.data.dropDownField]

    for (let i = 0; i < list.length; i++) {
      if (list[i].value === params.value) return list[i].label
    }

    return ''
  }

  const getDropDownValue = (data) => {
    if (!dropDowns.current) return ''
    if (typeof data?.dropDownField !== 'string') return ''

    if (!Array.isArray(dropDowns.current[data.dropDownField])) return ''
    const list = dropDowns.current[data.dropDownField]

    for (let i = 0; i < list.length; i++) {
      if (list[i].label === data.value) return list[i].value
    }

    return ''
  }

  function getWellGeoIds() {
    if (!Array.isArray(dropDowns.current?.wellbores)) return []
    if (dropDowns.current?.wellbores.length === 0) return []

    let id = []
    for (let i = 0; i < dropDowns.current?.wellbores.length; i++) {
      id.push(dropDowns.current?.wellbores[i].value)
    }

    return id
  }

  function getBhaIds() {
    if (!Array.isArray(dropDowns.current?.drillString)) return []
    if (dropDowns.current?.drillString.length === 0) return []

    let id = []
    for (let i = 0; i < dropDowns.current?.drillString.length; i++) {
      id.push(dropDowns.current?.drillString[i].value)
    }

    return id
  }

  function getWellGeoLabel(id) {
    if (!Array.isArray(dropDowns.current?.wellbores)) return ''
    if (dropDowns.current?.wellbores.length === 0) return ''
    if (id === null || id === undefined) return ''

    for (let i = 0; i < dropDowns.current?.wellbores.length; i++) {
      if (dropDowns.current?.wellbores[i].value === id) return dropDowns.current?.wellbores[i].label
    }

    return ''
  }

  function getBhaLabel(id) {
    if (!Array.isArray(dropDowns.current?.drillString)) return ''
    if (dropDowns.current?.drillString.length === 0) return ''
    if (id === null || id === undefined) return ''

    for (let i = 0; i < dropDowns.current?.drillString.length; i++) {
      if (dropDowns.current?.drillString[i].value === id) return dropDowns.current?.drillString[i].label
    }

    return ''
  }

  function getEngCaseLabel(id) {
    if (!Array.isArray(dropDowns.current?.engCases)) return ''
    if (dropDowns.current?.engCases.length === 0) return ''
    if (id === null || id === undefined) return ''

    for (let i = 0; i < dropDowns.current?.engCases.length; i++) {
      if (dropDowns.current?.engCases[i].value === id) {
        let label = dropDowns.current?.engCases[i].label
        if (typeof label !== 'string') label = ''
        label = label.replace(' (Active)', '')
        return label
      }
    }

    return ''
  }

  return {
    dropDownsLoading,
    fetchEngDropDowns,
    getDropDownValues,
    getDropDownValue,
    getDropDownLabel,
    getWellGeoIds,
    getBhaIds,
    getWellGeoLabel,
    getEngCaseLabel,
    getBhaLabel,
  }
}

export default useEngDropDowns
