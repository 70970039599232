import React from 'react'
import DonutChartJs from 'components/common/DonutChartJs'
import { Box } from '@mui/material'
import { getRandomColor } from 'utils/colorFunctions'
import { chartSeriesColors } from 'utils'

const getColor = (index) => {
  if (index < chartSeriesColors.length) return chartSeriesColors[index]
  return getRandomColor()
}

export function getDistinctOperators(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []
  let index = -1
  data.forEach((pt) => {
    if (output.findIndex((element) => element.label === pt.operator) < 0) {
      index++
      output.push({ selected: false, label: pt.operator, value: 0, color: getColor(index), opacity: 'A0' })
      output.push({ selected: true, label: pt.operator, value: 0, color: getColor(index), opacity: 'ff' })
    }
  })

  return output
}

const getOperatorData = (wells, selectedWells) => {
  if (!wells) return []
  if (!Array.isArray(wells)) return []
  if (wells.length === 0) return []

  let ops = getDistinctOperators(wells)
  for (let i = 0; i < wells.length; i++) {
    let isSelected = -1
    if (Array.isArray(selectedWells) && selectedWells.length > 0) {
      isSelected = selectedWells.findIndex((selWellName) => wells[i].actualWell === selWellName.actualWell)
    }

    let index = ops.findIndex((element) => {
      if (element.label !== wells[i].operator) return false
      if (isSelected >= 0 && element.selected) return true
      if (isSelected < 0 && !element.selected) return true
      return false
    })

    if (index >= 0) ops[index].value++
  }
  return ops
}

const getTitle = (wells) => {
  if (!Array.isArray(wells)) return 'Wells'
  if (wells.length === 0) return 'Wells'
  return `Wells (${wells.length})`
}

const AnalyticsSearchDonut = ({ wellData, selectedWells, showFilterControls }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}>
      {showFilterControls ? (
        <DonutChartJs
          title={getTitle(selectedWells)}
          data={getOperatorData(wellData, selectedWells)}
          legendLocation={'right'}
          showLegend={false}
          showTitle={true}
          maintainAspectRatio={true}
        />
      ) : null}
    </Box>
  )
}

export default AnalyticsSearchDonut
