import React, { useState, useRef, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { Backdrop, CircularProgress } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import fileDownload from 'js-file-download'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const getInitialDateRange = () => {
  const currentDate = new Date()
  const dateFrom = new Date(currentDate)
  dateFrom.setDate(currentDate.getDate() - 180)

  return [{ dateFrom: dateFrom.toISOString().substring(0, 10), dateTo: currentDate.toISOString().substring(0, 10) }]
}

const dateDiff = (data) => {
  const dateTo = new Date(Date.parse(data?.dateTo))
  const dateFrom = new Date(Date.parse(data?.dateFrom))
  const dateDifferenceInMilliseconds = dateTo.getTime() - dateFrom.getTime()
  const dateDifferenceInDays = dateDifferenceInMilliseconds / (1000 * 60 * 60 * 24)
  return Math.round(dateDifferenceInDays)
}

function isDateLessThan(date1, date2) {
  const time1 = new Date(date1).getTime()
  const time2 = new Date(date2).getTime()

  return time1 < time2
}

const dateTimeFormatter = (value) => {
  if (!value) return ''
  if (typeof value !== 'string') return ''
  if (value === '') return ''
  if (isDateLessThan(value, '1990-01-01')) return ''
  value = value.replace(/Z/g, '')
  value += 'T00:00:01'
  return new Date(Date.parse(value)).toLocaleDateString('default', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
}

const isValidDate = (value) => {
  return value instanceof Date || !isNaN(Date.parse(value))
}

const DateRangeGrid = ({ dateRangeGridApi }) => {
  const { getAgGridTheme } = useInnovaTheme()
  const dateRange = useRef(getInitialDateRange())

  const onGridReady = (params) => {
    dateRangeGridApi.current = params.api
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  let columnDef = [
    {
      field: 'dateFrom',
      colId: 'dateFrom',
      headerName: 'Date From',
      valueFormatter: (params) => dateTimeFormatter(params?.value),
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: '1950-01-01',
        max: '2200-01-01',
      },
    },
    {
      field: 'dateTo',
      colId: 'dateTo',
      headerName: 'Date To',
      valueFormatter: (params) => dateTimeFormatter(params?.value),
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: '1950-01-01',
        max: '2200-01-01',
      },
    },
    {
      field: 'days',
      colId: 'days',
      headerName: 'Days',
      editable: false,
      cellStyle: (params) => {
        let deltaDays = dateDiff(params?.data)
        let color = 'green'
        if (deltaDays < 0 || deltaDays > 180) {
          color = 'red'
        }

        return { display: 'flex', alignItems: 'center', justifyContent: 'center', color: color }
      },
      valueGetter: (params) => {
        return dateDiff(params?.data)
      },
    },
  ]

  return (
    <div
      className={getAgGridTheme()}
      style={{ width: '100%', height: '100%', maxHeight: '80px', marginBottom: '10px' }}>
      <AgGridReact
        rowData={dateRange.current}
        columnDefs={columnDef}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        headerHeight={30}
        onGridReady={onGridReady}
      />
    </div>
  )
}

const TABLENAMES = [
  {
    tableName: 'COST_CODES',
    include: false,
  },
  {
    tableName: 'FIELD_COST',
    include: false,
  },
  {
    tableName: 'BID_SHEETS',
    include: false,
  },
  {
    tableName: 'DAILY_ACTIVITY',
    include: false,
  },
  {
    tableName: 'DAILY_REPORTS',
    include: false,
  },
  {
    tableName: 'DRILL_STRING',
    include: false,
  },
  {
    tableName: 'MOTOR_REPORT',
    include: false,
  },
  {
    tableName: 'FIELD',
    include: false,
  },
  {
    tableName: 'FACILITY',
    include: false,
  },
  {
    tableName: 'WELL',
    include: false,
  },
  {
    tableName: 'ACTUALWELL',
    include: false,
  },
  {
    tableName: 'SLIDE_RECORDS',
    include: false,
  },
  {
    tableName: 'ACTIVITY_CODES',
    include: false,
  },
  {
    tableName: 'INVENTORY',
    include: false,
  },
]

const TableExportModal = ({ onClose }) => {
  const _isMounted = useRef()
  const dateRangeGridApi = useRef(null)
  const isLoadingRef = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [isLoading, setLoading] = useState(false)
  const [minMaxReportDates, setMinMaxReportDates] = useState('')
  const { getAgGridTheme } = useInnovaTheme()

  const gridApi = useRef(null)

  const exportDataTables = useInnovaAxios({
    url: '/admin/dataTableExport',
    timeout: 300000, // 5 minute timeout, as data tables can be large
    responseType: 'blob', // critically important to properly retrieve the zip file
  })

  const getMinMaxDates = useInnovaAxios({
    url: '/admin/minMaxReportDates',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchMinMaxDates()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMinMaxDates = async () => {
    let res = await getMinMaxDates()
    if (res.error) return
    setMinMaxReportDates(`${dateTimeFormatter(res.data.minDate)} - ${dateTimeFormatter(res.data.maxDate)}`)
  }

  const getFileNameFromHeader = (disposition) => {
    var filename = ''
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      var matches = filenameRegex.exec(disposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }
    return filename
  }

  const onClickExport = async () => {
    if (isLoadingRef.current) return
    if (!gridApi.current) return
    let tablesToExport = []

    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) {
        if (node.data.include) tablesToExport.push(node.data.tableName)
      }
    })

    if (tablesToExport.length === 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'No tables selected',
      })
      return
    }

    if (!dateRangeGridApi.current) return

    let dateRanges = []
    dateRangeGridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) {
        dateRanges.push(node.data)
      }
    })

    if (dateRanges.length === 0) return

    let deltaDays = dateDiff(dateRanges[0])
    if (deltaDays < 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Date from greate than date to',
      })
      return
    }

    if (deltaDays > 180) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Max date range exceeded',
      })
      return
    }

    if (!isValidDate(dateRanges[0]?.dateFrom)) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Invalid date from',
      })
      return
    }

    if (!isValidDate(dateRanges[0]?.dateTo)) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Invalid date to',
      })
      return
    }

    setLoading(true)
    isLoadingRef.current = true
    let res = await exportDataTables({
      tables: tablesToExport.join('|'),
      dateFrom: dateRanges[0].dateFrom?.substring(0, 10),
      dateTo: dateRanges[0].dateTo?.substring(0, 10),
    })

    isLoadingRef.current = false
    if (!_isMounted.current) return
    setLoading(false)

    if (res.error) {
      const blob = new Blob([res?.error?.response?.data], { type: 'application/json' })
      const reader = new FileReader()

      reader.onload = function (event) {
        const jsonData = event.target.result
        try {
          const jsonObject = JSON.parse(jsonData)

          setStatus({
            show: true,
            severity: 'error',
            message: 'Table Export failed: ' + jsonObject.error,
          })
        } catch (error) {}
      }

      reader.readAsText(blob)
      return
    }

    setStatus({ show: true, severity: 'success', message: 'Table Export completed' })
    const fileName = getFileNameFromHeader(res.headers['content-disposition'])
    let blobData = new Blob([res.data])
    fileDownload(blobData, fileName)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseDialog = async () => {
    onClose()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  let columnDef = [
    {
      field: 'tableName',
      colId: 'tableName',
      headerName: 'Table',
      editable: false,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
    {
      field: 'include',
      colId: 'include',
      headerName: 'Export',
      cellEditor: 'agCheckboxCellEditor',
      cellRenderer: 'agCheckboxCellRenderer',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
  ]

  const onCheckUncheckAll = (check) => {
    if (!gridApi.current) return

    let rowsToUpdate = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) {
        rowsToUpdate.push(node.data)
        rowsToUpdate[rowsToUpdate.length - 1].include = check
      }
    })

    gridApi.current.applyTransaction({
      update: rowsToUpdate,
    })
  }

  return (
    <>
      <Dialog
        maxWidth='xl'
        PaperProps={{
          sx: {
            width: '70vw',
            height: '70vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={handleCloseDialog}>
        <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <DialogTitle>{`Export Data Tables: ${minMaxReportDates}`}</DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <DateRangeGrid dateRangeGridApi={dateRangeGridApi} />
          <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 90px)' }}>
            <AgGridReact
              rowData={TABLENAMES}
              columnDefs={columnDef}
              defaultColDef={defaultColDef}
              animateRows={true}
              enableBrowserTooltips={true}
              headerHeight={30}
              onGridReady={onGridReady}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={() => onCheckUncheckAll(false)}>
            Unselect all
          </Button>
          <Button color='primary' variant='contained' onClick={() => onCheckUncheckAll(true)}>
            Select all
          </Button>
          <Button color='primary' variant='contained' onClick={onClickExport}>
            Export
          </Button>
          <Button color='primary' variant='contained' onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default TableExportModal
