import { useState, useRef } from 'react'
import React from 'react'
import Tippy from '@tippyjs/react'
import { Icon as Iconify } from '@iconify/react'
import { Box, Tooltip } from '@mui/material'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const addIconRenderer = (showBottomRow) => {
  if (showBottomRow !== null && showBottomRow !== undefined && showBottomRow === false) return null
  return (
    <React.Fragment>
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Tooltip
          title={'Add'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
        </Tooltip>
      </Box>
    </React.Fragment>
  )
}

const PopupCellRenderer = (props) => {
  const tippyRef = useRef()
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)
  const { getBackColor, theme } = useInnovaTheme()

  const isBottomRow = () => {
    if (!props) return false
    if (!props.hasOwnProperty('node')) return false
    if (!props.node.hasOwnProperty('rowPinned')) return false
    return props.node?.rowPinned === 'bottom'
  }

  const dropDownContent = (
    <Box
      sx={{
        backgroundColor: getBackColor(),
        padding: '3px',
        border: '1px solid gray',
        '&:hover': {
          cursor: 'pointer',
        },
      }}>
      {Array.isArray(props.menuItems)
        ? props.menuItems.map((item, idx) => {
            return (
              <Box
                key={`${idx}`}
                sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: theme === 'dark' ? 'white' : '#000' }}
                onClick={() => {
                  hide()
                  if (item.onClick) item.onClick(item.action, props.data)
                }}>
                {item.icon ? <item.icon /> : null}
                {item.label ? item.label : null}
              </Box>
            )
          })
        : null}
    </Box>
  )

  return !isBottomRow() ? (
    <Tippy
      ref={tippyRef}
      content={dropDownContent}
      visible={visible}
      onClickOutside={hide}
      allowHTML={true}
      arrow={false}
      appendTo={document.body}
      interactive={true}
      placement='right'>
      <StyledIconContainer onClick={visible ? hide : show}>
        <Iconify
          icon='ep:menu'
          style={{ color: appColors.itemTextColor, height: '21px', width: '21px', padding: '0px' }}
        />
      </StyledIconContainer>
    </Tippy>
  ) : (
    addIconRenderer(props.showBottomRow)
  )
}

export default PopupCellRenderer
