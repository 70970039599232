import { isoStringToDateString, isoDateAddition } from 'utils/dateTimeFunctions'
import { numberWithCommas } from 'utils/stringFunctions'
import { round } from 'utils/numberFunctions'

const getDueDate = (invoiceDate, paymentTermsDays) => {
  if (!paymentTermsDays) paymentTermsDays = 0
  if (typeof paymentTermsDays !== 'number') paymentTermsDays = 0
  if (paymentTermsDays < 0) paymentTermsDays = 0
  if (!invoiceDate) return null
  return isoDateAddition(invoiceDate, paymentTermsDays, true)
}

export function createInvoiceHtml(data, isQuote, selectedBankAccount) {
  if (!data) return ''

  let invoiceDueDate = getDueDate(data.createdDate + 'T00:01:00', isQuote ? data.validFor : data.terms)

  let paddingCols = '<td></td>'
  if (!isQuote) {
    paddingCols += '<td></td>'
    paddingCols += '<td></td>'
  }

  let headerStr = `<b>Invoice #:</b>${data.invoiceNum}
                 <br />
                 <b>Your Ref:</b>${data.customerRef}
                <br />
                <b>Invoice Date:</b>${isoStringToDateString(data.createdDate + 'T00:01:00')}
                <br />
                <b>Due Date:</b>${isoStringToDateString(invoiceDueDate)}`

  if (isQuote) {
    headerStr = `<b>Quote #:</b>${data.invoiceNum}
                    <br />
                    <b>Issue Date:</b>${isoStringToDateString(data.createdDate + 'T00:01:00')}
                    <br />
                    <b>Valid Until:</b>${isoStringToDateString(invoiceDueDate)}`
  }

  let addressHeader = `<b>INVOICE</b><br />`
  if (isQuote) addressHeader = `<b>QUOTE</b><br />`

  let ukAddress = `Innova Drilling & Intervention Limited
                    <br />
                    2 Marischal Square
                    <br />
                    Broad Street
                    <br />
                    Aberdeen
                    <br />
                    AB10 1DQ
                    <br />
                    VAT Registration No. 152 9175 04`

  let usaAddress = `Innova Drilling and Intervention Limited
                      <br />
                      3050 Post Oak Blvd
                      <br />
                      Suite 510
                      <br />
                      Houston
                      <br />
                      TX, 77056`

  let innovaAddress = `${addressHeader}
                        ${data.allocationCountry === 'UK' ? ukAddress : usaAddress}`

  let customerAddress = ''
  if (data.contactDetails !== null) {
    customerAddress += `<b>FAO</b>: ${data.contactDetails.firstName ? data.contactDetails.firstName : ''} ${data.contactDetails.lastName ? data.contactDetails.lastName : ''
      }<br />
                        ${data.companyDetails.companyName}<br />
                        ${data.companyDetails.address1}<br />
                        ${data.companyDetails.address2}
                        ${data.companyDetails.address3 !== '' ? '<br />' + data.companyDetails.address3 : ''}
                        ${data.companyDetails.country !== '' ? '<br />' + data.companyDetails.country : ''}
                        ${data.companyDetails.zipCode !== '' ? '<br />' + data.companyDetails.zipCode : ''}
                        <br />
                        <b>Email:</b>${data.contactDetails.emailAddress ? data.contactDetails.emailAddress : ''}<br />
                        <b>Phone:</b>${data.contactDetails.phoneNum ? data.contactDetails.phoneNum : ''}<br />`
  }

  let taxText = '<td>Sales Tax(%)</td>'
  if (data.currency !== 'USD') taxText = '<td>Tax Rate(%)</td>'

  let invoiceGrid = ''
  if (Array.isArray(data.invoiceDetails)) {
    for (let i = 0; i < data.invoiceDetails.length; i++) {
      if (i < data.invoiceDetails.length - 1) {
        invoiceGrid += '<tr class="item">'
      } else {
        invoiceGrid += '<tr class="item last">'
      }

      invoiceGrid += `<td>${i + 1}</td>`
      invoiceGrid += `<td>${data.invoiceDetails[i].description}</td>`
      invoiceGrid += `<td>${data.invoiceDetails[i].qty > 0 ? data.invoiceDetails[i].qty : ''}</td>`
      invoiceGrid += `<td>${data.invoiceDetails[i].value !== 0 ? numberWithCommas(round(data.invoiceDetails[i].value, 2)) : ''
        }</td>`

      if (!isQuote) {
        invoiceGrid += `<td>${data.invoiceDetails[i].taxRate > 0 ? round(data.invoiceDetails[i].taxRate, 3) : ''}</td>`
      }

      if (!isQuote) {
        invoiceGrid += `<td>${Math.abs(data.invoiceDetails[i].totalTax) > 0
          ? numberWithCommas(round(data.invoiceDetails[i].totalTax, 2))
          : ''
          }</td>`
      }

      invoiceGrid += `<td>${data.invoiceDetails[i].total !== 0 ? numberWithCommas(round(data.invoiceDetails[i].total, 2)) : ''
        }</td>`

      invoiceGrid += '</tr>'
    }
  }

  let frontCurrencyText = '$'
  if (data.currency === 'GBP') frontCurrencyText = '£'
  if (data.currency === 'EURO') frontCurrencyText = '€'

  let exchangeRate = data.exchangeRate
  if (data.currency === 'USD') exchangeRate = 1.0

  let totalTaxText = ''
  if (!isQuote) {
    totalTaxText = `<td>${frontCurrencyText}${numberWithCommas(round(data.totalTax * exchangeRate, 2))}</td>`
  }

  let totalText = `<td>Total ${data.currency}:</td>
                   ${totalTaxText}
                   <td>${frontCurrencyText}${numberWithCommas(
    round((data.totalValue + data.totalTax) * exchangeRate, 2),
  )}</td>`



  let spanText = isQuote ? '<td colspan="5">' : '<td colspan="7">'
  let comments = ''

  let taxId = ''
  if (data.companyDetails !== null && typeof data.companyDetails.euTaxId === 'string') {
    taxId = data.companyDetails.euTaxId
  }

  if (data.comments !== '' || data.currency !== 'USD' || (taxId !== '' && !isQuote) || data.companyDetails.taxExempt) {
    comments = `<tr>
                 <td>&nbsp;</td>
                </tr>
                <tr class="heading">
  	                ${spanText}Additional Information</td>
  	            </tr>
  	            <tr class="details">
  	            ${spanText}${data.comments}`

    if (data.comments !== '' && data.currency !== 'USD') comments += '<br />'
    if (data.currency !== 'USD') comments += `Exchange rate used ${round(exchangeRate, 4)}`
    if (!isQuote && taxId !== '') {
      if (data.comments !== '' || data.currency !== 'USD') comments += '<br />'

      comments += `Tax ID#: ${taxId}
                    <br />
  		            Buyer is liable for VAT and any applicable local taxes<br />`
    }

    if (data.companyDetails.taxExempt) {
      comments += `Customer is exempt from ${data.currency === 'USD' ? 'TX Sales tax' : 'VAT'}<br />`
    }

    comments += `</td>
  	            </tr>`
  }

  let bankAccount = ''
  if (!isQuote) {
    bankAccount = `<tr>
  	                    <td>&nbsp;</td>
  	                </tr>
                    <tr class="heading">
  	                    ${spanText}Payment Information</td>
  	                </tr>`

    let bankDetailsStr = ''
    let bankDetailsValues = ''

    if (selectedBankAccount !== null) {
      bankDetailsStr += `<b>Bank:</b><br />
                        <b>Name:</b><br />
                        <b>A/C#:</b><br />
                        <b>${selectedBankAccount?.bank.indexOf('Barclays') >= 0 ? 'Sort Code' : 'ABA#'}:</b><br />
                        <b>${selectedBankAccount?.bank.indexOf('Barclays') >= 0 ? 'IBAN' : 'SWIFT'}:</b><br />
                        <b>${selectedBankAccount?.bank.indexOf('Barclays') >= 0 ? 'SWIFT/BIC' : 'Address'}:</b><br />`

      bankDetailsValues += `${selectedBankAccount?.bank}<br />
                            Innova Drilling & Intervention Ltd<br />
                            ${selectedBankAccount?.accountNum}<br />
                            ${selectedBankAccount?.bank.indexOf('Barclays') >= 0
          ? selectedBankAccount?.sortCode
          : selectedBankAccount?.routingNo
        }<br />
                             ${selectedBankAccount?.bank.indexOf('Barclays') >= 0
          ? selectedBankAccount?.iban
          : selectedBankAccount?.swiftCode
        }<br />
                            ${selectedBankAccount?.bank.indexOf('Barclays') >= 0
          ? selectedBankAccount?.swiftCode
          : selectedBankAccount?.address
        }<br />`

      if (selectedBankAccount?.bank.indexOf('Barclays Bank PLC - GBP') >= 0) {
        bankDetailsStr += `<b>Note:</b>`
        bankDetailsValues += `Payments to be made in GBP`
      }

      if (selectedBankAccount?.bank.indexOf('Barclays Bank PLC - USD') >= 0) {
        bankDetailsStr += `<b>Routing#:</b>`
        bankDetailsValues += `${selectedBankAccount?.routingNo}`
      }
    }

    bankAccount += `<tr class="details">
                        <td colspan="1">${bankDetailsStr}</td>
                        <td colspan="1">${bankDetailsValues}</td>
                    </tr>`
  }

  let invoiceStr = `
    <div>
    <style>
        .invoice-box {
          width: 90%;
          margin: 5px;
          padding: 30px;
          font-size: 16px;
          line-height: 24px;
          font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
          color:#555;
        }
        .invoice-box table {
          line-height: inherit;
          text-align: left;
        }
        .invoice-box table td {
          padding: 5px;
          vertical-align: top;
        }
        .invoice-box table tr td:nth-child(2) {
          text-align: right;
        }
        .invoice-box table tr.top table td {
          padding-bottom: 20px;
        }
        .invoice-box table tr.top table td.title {
          font-size: 45px;
          width: 100%;
          line-height: 45px;
          color: #333;
        }
        .invoice-box table tr.information table td {
          padding-bottom: 40px;
        }
        .invoice-box table tr.heading td {
          background: #eee;
          border-bottom: 1px solid #ddd;
          font-weight: bold;
        }
        .invoice-box table tr.details td {
          padding-bottom: 20px;
        }
        .invoice-box table tr.item td {
          border-bottom: 1px solid #eee;
        }
        .invoice-box table tr.item.last td {
          border-bottom: none;
        }
        .invoice-box table tr.total td {
          border-top: 2px solid #eee;
          font-weight: bold;
        }
        .rtl {
          direction: rtl;
          font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial,sans-serif;
        }
    </style>
  </div>
  <div>
    <div class="invoice-box">
        <table cellpadding="0" cellspacing="0" width="100%">
          <tr class="top">
              <td class="title" colspan="1">
                <img src="https://wellseekerfiles.s3.us-east-2.amazonaws.com/logos/Innova.jpg" style="display:block;width:auto;height:auto;max-width:300px" />
              </td>
              <td colspan="1">${headerStr}</td>
          </tr>
          <tr class="information">
              <td colspan="1">${innovaAddress}</td>
              <td colspan="1">${customerAddress}</td>
          </tr>
        </table>
        <table cellpadding="0" cellspacing="0" width="100%">
          <tr>
              <td>&nbsp;</td>
          </tr>
          <tr class="heading">
              <td>#</td>
              <td>Description</td>
              <td>Qty</td>
              <td>Price (USD)</td>
              ${!isQuote ? taxText : ''}
              ${!isQuote ? '<td>Tax (USD)</td>' : ''}
              <td>Total (USD)</td>
          </tr>
          ${invoiceGrid}
          <tr class="total">
              <td></td>
              ${paddingCols}
              ${isQuote ? '<td></td>' : ''}
              ${totalText}
          </tr>
          <tr>
              <td>&nbsp;</td>
          </tr>
          <tr class="heading">
              <td colspan="2">
                ${isQuote ? 'Quote Valid For (days)' : 'Payment Terms (days)'}
              </td>
              ${paddingCols}
              <td colspan="2">${isQuote ? data.validFor : data.terms}</td>
          </tr>
          ${comments}
        </table>
        <table cellpadding="0" cellspacing="0" width="100%">
          ${bankAccount}
        </table>
    </div>
  </div>`

  return invoiceStr
}
