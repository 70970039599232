import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

//const PUMP_JACK_LARGE = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/oil_pump.fbx'
//const PUMP_JACK_URL = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/pumpJackSmall.obj'
const PUMP_JACK_LARGE_DRACO = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/pumpJackDraco.gltf'

function WellHead({ wellData, scale }) {
   const pumpJack = useLoader(GLTFLoader, PUMP_JACK_LARGE_DRACO, (loader) => {
     const dracoLoader = new DRACOLoader()
     dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/')
     loader.setDRACOLoader(dracoLoader)
   })

  scale /= 5

  if (!wellData.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (wellData.data.length === 0) return null
  if (!pumpJack) return null

  if(Math.abs(wellData.data[0].x + wellData.data[0].y + wellData.data[0].z) < 0.1) return null

  const position = { x: wellData.data[0].x, y: wellData.data[0].y < 0 ? wellData.data[0].y : 0, z: wellData.data[0].z }

  let pos = [
    position.x,
    position.y,
    position.z,
  ]

  return (
    <mesh scale={0.001} position={pos}>
      <primitive object={pumpJack.scene.clone()} />
    </mesh>
  )
}

export default WellHead
