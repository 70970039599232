import React, { useEffect, useRef, useState } from 'react'

import { List, ListItem, Box } from '@mui/material'
import { styled } from '@mui/styles'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

import { useRecoilValue } from 'recoil'
import useChat, {
  chatSelectedRoomAtom,
  chatNewInboundMessageSelector,
  ownMessageAtom,
  chatCollapsedAtom,
} from 'components/common/hooks/useChat'
import { createMessageIdString, createMessageReadMsg, getUserNameInitials } from 'utils/chatFunctions'
import { isoStringToLocaleStr } from 'utils/dateTimeFunctions'
import { userRoleSelector } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const sortDatetime = (a, b) => {
  if (typeof a.date !== 'string') return -1
  if (typeof b.date !== 'string') return -1
  let d1 = new Date(Date.parse(a.timeStamp))
  let d2 = new Date(Date.parse(b.timeStamp))
  if (d1 < d2) return -1
  if (d1 > d2) return 1

  return 0
}

const StyledOwnRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'center',
})

const StyledOtherRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
})

const ConversationWindow = () => {
  const scrollRef = useRef(null)
  const _isMounted = useRef(false)
  const selectedChatRoom = useRecoilValue(chatSelectedRoomAtom)
  const newMessages = useRecoilValue(chatNewInboundMessageSelector)
  const ownMessage = useRecoilValue(ownMessageAtom)
  const [chatData, setChatData] = useState([])
  const { user } = useInnovaAuth()
  const userRole = useRecoilValue(userRoleSelector)
  const [, { sendMessage, refreshChatRooms }] = useChat()
  const chatCollapsed = useRecoilValue(chatCollapsedAtom)
  const { getLinearGradient } = useInnovaTheme()

  const getChatMessages = useInnovaAxios({
    url: '/chat/getMessages',
  })

  const markMessagesAsRead = useInnovaAxios({
    url: '/chat/markMessagesRead',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current && selectedChatRoom) {
      getData()
    }
  }, [selectedChatRoom]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && newMessages.chatMsgs?.length > 0) {
      let newMsgs = []
      newMessages.chatMsgs.forEach((newMsg) => {
        if (newMsg.roomId === selectedChatRoom.roomId) {
          newMsgs.push(newMsg)
        }
      })

      newMsgs.sort(sortDatetime)

      //mark these new messages as read
      newMsgs.forEach((msg) => {
        let msgRead = createMessageReadMsg(msg, userRole?.organization)
        if (msgRead !== null) {
          sendMessage(msgRead)
        }
      })

      // check to see if message is already in the list
      let uniqueMsgs = []
      newMsgs.forEach((msg) => {
        let index = chatData.findIndex((element) => element.messageId === msg.messageId)
        if (index < 0) uniqueMsgs.push(msg)
      })

      const allMsgs = [...chatData, ...uniqueMsgs] // newMessages.chatMsgs[newMessages.chatMsgs.length-1]]
      setChatData(allMsgs)
    }
  }, [newMessages]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && ownMessage) {
      let messages = [...chatData]
      messages.push(ownMessage)
      setChatData(messages)
    }
  }, [ownMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    if (!selectedChatRoom || !selectedChatRoom?.roomId || selectedChatRoom?.roomId < 0) {
      return
    }
    const response = await getChatMessages({
      chatRoomId: selectedChatRoom.roomId,
    })
    if (response?.data) {
      let sortedData = [...response.data]
      sortedData.sort(sortDatetime)

      // mark messages as read
      const readResp = await markMessagesAsRead({
        messageList: createMessageIdString(sortedData),
      })
      if (readResp?.error) {
        console.error('error marking message as read')
      }

      refreshChatRooms(true) // update chat rooms to reflect read msg count

      setChatData(sortedData)
    } else {
      setChatData([])
    }
  }

  const renderOwnMessage = (item, index) => {
    let msg = item.message.replace('\\\\', '\\')
    msg = msg.replace('&sq;', "'")
    return (
      <StyledOwnRow>
        <Box
          sx={{
            display: 'flex',
            padding: '12px 12px 6px 12px',
            borderRadius: '10px',
            backgroundColor: '#1679D0',
            color: '#FFF',
            marginLeft: '35%',
            whiteSpace: 'normal',
            maxWidth: '326px',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
            <Box sx={{ fontSize: '16px' }}>{msg}</Box>
            <StyledOwnRow>
              <Box sx={{ padding: '8px 0px 0px 0px', fontSize: '10px', textAlign: 'right' }}>
                {isoStringToLocaleStr(
                  item.timeStamp.length > 1 ? item.timeStamp : new Date(Date.now()).toISOString(),
                  false,
                  true,
                  false,
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: 700,
                  margin: '8px 0px 0px 8px',
                  backgroundColor: 'lightgrey',
                  color: '#000',
                  border: '2px solid lightgrey',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                }}>
                {getUserNameInitials(item.username).toUpperCase()}
              </Box>
            </StyledOwnRow>
          </Box>
        </Box>
      </StyledOwnRow>
    )
  }

  const renderOtherMessage = (item, index) => {
    let msg = item.message.replace('\\\\', '\\')
    msg = msg.replace('&sq;', "'")
    return (
      <StyledOtherRow>
        <Box
          sx={{
            display: 'flex',
            padding: '12px',
            borderRadius: '10px',
            backgroundColor: '#000',
            color: '#FFF',
            marginLeft: '8px',
            marginRight: '35%',
            whiteSpace: 'normal',
            maxWidth: '326px',
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
            <Box sx={{ fontSize: '16px' }}>{msg}</Box>
            <StyledOtherRow>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: 700,
                  margin: '8px 8px 0px 0px',
                  backgroundColor: 'lightgrey',
                  color: '#000',
                  border: '2px solid lightgrey',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                }}>
                {getUserNameInitials(item.username).toUpperCase()}
              </Box>
              <Box sx={{ padding: '8px 0px 0px 0px', fontSize: '10px', textAlign: 'right' }}>
                {isoStringToLocaleStr(item.timeStamp, false, true, false)}
              </Box>
            </StyledOtherRow>
          </Box>
        </Box>
      </StyledOtherRow>
    )
  }

  const renderChatMessage = (item, index) => {
    if (item.username.toLowerCase() === user.name?.toLowerCase()) {
      return renderOwnMessage(item, index)
    } else {
      return renderOtherMessage(item, index)
    }
  }

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: 'smooth' })
    }
  }, [chatData])

  return (
    <Box
      sx={{
        border: '1px solid #000',
        borderRadius: '5px',
        margin: '8px 8px 8px 8px',
        height: 'calc(calc(100vh - 154px) * 0.65)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
      style={
        chatCollapsed ? { height: 'calc(calc(100vh - 102px) * 1.00)' } : { height: 'calc(calc(100vh - 154px) * 0.65)' }
      }>
      <List
        dense
        sx={{
          width: '100%',
          height: '100%',
          background: getLinearGradient(),
          overflow: 'auto',
          position: 'relative',
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#136cb9',
            outline: '1px solid #1679d0',
          },
        }}>
        {chatData?.map((item, index) => (
          <ListItem
            sx={{
              display: 'flex',
              padding: '4px 0px 4px 0px',
              borderRadius: '5px',
              width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
            }}
            dense
            disableGutters={true}
            key={`${index}`}
            ref={index === chatData.length - 1 ? scrollRef : null}>
            {renderChatMessage(item, index)}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ConversationWindow
