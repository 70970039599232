import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useRef, useMemo} from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const HeatMapSettingsModal = ({
  open,
  onClose,
  data,
  params,
  handleUpdateParams,
}) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  let columnDefs = [
    {
      field: 'axis',
      colId: 'axis',
      headerName: '',
      editable: false,
    },
    {
      field: 'variable',
      colId: 'variable',
      headerName: 'Variable',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: data?.map((d) => d.label),
      },
    },
    {
      field: 'min',
      colId: 'min',
      headerName: 'Min',
      editable: (params) => !params.data?.autoScale,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 2)
      },
      cellEditor: 'agNumberCellEditor',
    },
    {
      field: 'max',
      colId: 'max',
      headerName: 'Max',
      editable: (params) => !params.data?.autoScale,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 2)
      },
      cellEditor: 'agNumberCellEditor',
    },
    {
      field: 'autoScale',
      colId: 'autoScale',
      headerName: 'Auto Scale',
    },
    {
      field: 'ticks',
      colId: 'ticks',
      headerName: '# Ticks',
      editable: (params) => !params.data?.autoTicks,
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 2)
      },
      cellEditor: 'agNumberCellEditor',
    },
    {
      field: 'autoTicks',
      colId: 'autoTicks',
      headerName: 'Auto Ticks',
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      resizable: false,
      sortable: false,
      autoHeight: true,
      editable: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.axis)
    }
  }, [])

  const gridOptions = {
    onCellEditingStopped: (event) => {},
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xl'
      PaperProps={{
        sx: {
          width: '60vw',
          height: '40vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Heat Map Settings</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box className={getAgGridTheme()} style={{ height: '2%', width: '100%', paddingLeft: '30px' }}></Box>
        <Box className={getAgGridTheme()} style={{ height: '100%', width: '100%', padding: '30px' }}>
          <AgGridReact
            rowData={params ? params : []}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' type='button' color='primary' onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant='contained'
          type='button'
          color='primary'
          onClick={() => {
            onClose()
            handleUpdateParams(params)
          }}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HeatMapSettingsModal
