import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import GridLines from 'components/ThreeDeeView/ChartComponents/Grid'
import OrientationCompass from 'components/ThreeDeeView/Controls/OrientationCompass'
import { OrbitControls } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { Vector3 } from 'three'
import cloneDeep from 'lodash/cloneDeep'
import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SCALE = 1
const INITIAL_CAM_OFFSET = 30
const PUMP_JACK_LARGE_DRACO = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/pumpJackDraco.gltf'

function ChartControls({ cameraTarget, template }) {
  const { camera } = useThree()
  const ref = useRef(null)

  const changeCamPostion = () => {
    let newCamTarget = new Vector3(0, 0, 0)

    if (template.current) {
      let x = template.current.offsetNorth
      let z = template.current.offsetEast

      if (template.current.geometry === 'RECT') {
        x += template.current.rectData.topLeftNorth
        z += template.current.rectData.topLeftEast
      }

      newCamTarget = new Vector3(x, INITIAL_CAM_OFFSET, z)
    }

    let camOffset = camera.position.clone().sub(cameraTarget.current)
    let newCamPosition = camOffset.clone().add(newCamTarget)

    cameraTarget.current = cloneDeep(newCamTarget)

    camera.lookAt(newCamTarget)
    camera.position.x = newCamPosition.x
    camera.position.y = newCamPosition.y
    camera.position.z = newCamPosition.z
    ref.current.target = newCamTarget
    ref.current.target0 = newCamTarget
    ref.current.position0 = newCamPosition
    ref.current.update()
  }

  useFrame(() => {
    changeCamPostion()
  })

  return <OrbitControls ref={ref} args={[camera]} />
}

const getSlots3d = (template, scale) => {
  let slots = []
  if (!template) return slots
  template.slots.forEach((slot) => {
    slots.push({
      uid: slot.uid,
      slotName: slot.slotName,
      x: slot.slotPosition.localNorth * scale,
      y: 0,
      z: slot.slotPosition.localEast * scale,
    })
  })

  return slots
}

function WellHead({ position }) {
  const pumpJack = useLoader(GLTFLoader, PUMP_JACK_LARGE_DRACO, (loader) => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/')
    loader.setDRACOLoader(dracoLoader)
  })

  if (!pumpJack) return null

  let pos = [position.x, position.y, position.z]

  return (
    <mesh scale={0.005} position={pos}>
      <primitive object={pumpJack.scene.clone()} />
    </mesh>
  )
}

const TemplatesThreeDeeChart = ({ template, isLoading = false }) => {
  const cameraTargetRef = useRef(new Vector3(0, 0, 0))
  const [chartData, setChartData] = useState([])
  const templateRef = useRef(null)
  const { getBackColor, theme } = useInnovaTheme()

  useEffect(() => {
    templateRef.current = template
    if (!template) return
    let slots = getSlots3d(template, SCALE)

    setChartData(slots)
  }, [template]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Canvas
        style={{ width: '100%', height: '100%', background: getBackColor() }}
        flat
        camera={{ position: [1, INITIAL_CAM_OFFSET, 1], fov: 10, near: 0.1, far: 5000 }}
        onCreated={({ camera }) => {
          camera.lookAt(0, 0, 0)
        }}>
        <ChartControls cameraTarget={cameraTargetRef} template={templateRef} />
        <OrientationCompass cameraTarget={cameraTargetRef} />
        <ambientLight color={'#FFFFFF'} intensity={1000} />
        <pointLight color={0xffffbb} intensity={100} distance={1000} position={[0, 10, 0]} />
        <pointLight color={0xffffbb} intensity={100} distance={1000} position={[0, 10, 0]} />
        <GridLines
          scale={SCALE}
          showVerticalSides={false}
          templateData={chartData}
          labelColor={theme === 'dark' ? 0x909090 : 0x000000}
          gridColor={theme === 'dark' ? 0x909090 : 0x000000}
        />
        {Array.isArray(chartData) ? chartData.map((slot) => <WellHead key={`${slot.uid}`} position={slot} />) : null}
      </Canvas>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
    </div>
  )
}

export default TemplatesThreeDeeChart
