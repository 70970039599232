export const filterDefs = [
  { id: 23, isActive: false, text: 'Start Date', type: 'date', value: '' },
  { id: 24, isActive: false, text: 'End Date', type: 'date', value: '' },
  {
    id: 0,
    isActive: false,
    dropDownField: 'operators',
    text: 'Operator',
    type: 'dropDown',
    value: [],
  },
  {
    id: 1,
    isActive: false,
    dropDownField: 'rigs',
    text: 'Rig',
    type: 'dropDown',
    value: [],
  },
  {
    id: 2,
    isActive: false,
    dropDownField: 'states',
    text: 'State',
    type: 'dropDown',
    value: [],
  },
  {
    id: 3,
    isActive: false,
    dropDownField: 'counties',
    text: 'County',
    type: 'dropDown',
    value: [],
  },
  {
    id: 4,
    isActive: false,
    dropDownField: 'wellStatus',
    text: 'Well Status',
    type: 'dropDown',
    value: [],
  },
  {
    id: 5,
    isActive: false,
    dropDownField: 'jobNumbers',
    text: 'Job#',
    type: 'dropDown',
    value: [],
  },
  {
    id: 6,
    isActive: false,
    dropDownField: 'directionalCompanys',
    text: 'Dir Co.',
    type: 'dropDown',
    value: [],
  },
  {
    id: 7,
    isActive: false,
    dropDownField: 'formations',
    text: 'Formation',
    type: 'dropDown',
    value: [],
  },
  {
    id: 8,
    isActive: false,
    dropDownField: 'holeSize',
    text: 'Hole Size',
    type: 'dropDown',
    value: [],
  },
  {
    id: 9,
    isActive: false,
    dropDownField: 'phases',
    text: 'Phase',
    type: 'dropDown',
    value: [],
  },
  {
    id: 10,
    isActive: false,
    dropDownField: 'district',
    text: 'District',
    type: 'dropDown',
    value: [],
  },
  {
    id: 11,
    isActive: false,
    dropDownField: 'failure',
    text: 'Failure',
    type: 'dropDown',
    value: [],
  },
  {
    id: 12,
    isActive: false,
    dropDownField: 'motorSn',
    text: 'Motor SN',
    type: 'dropDown',
    value: [],
  },
  {
    id: 13,
    isActive: false,
    dropDownField: 'motorMake',
    text: 'Motor Make',
    type: 'dropDown',
    value: [],
  },
  {
    id: 14,
    isActive: false,
    dropDownField: 'bitMake',
    text: 'Bit Make',
    type: 'dropDown',
    value: [],
  },
  {
    id: 15,
    isActive: false,
    dropDownField: 'mwdType',
    text: 'MWD Type',
    type: 'dropDown',
    value: [],
  },
  {
    id: 16,
    isActive: false,
    dropDownField: 'driveType',
    text: 'Drive Type',
    type: 'dropDown',
    value: [],
  },
  {
    id: 17,
    isActive: false,
    dropDownField: 'motorLobes',
    text: 'Motor Lobes',
    type: 'dropDown',
    value: [],
  },
  {
    id: 18,
    isActive: false,
    dropDownField: 'motorStages',
    text: 'Motor Stages',
    type: 'dropDown',
    value: [],
  },
  {
    id: 19,
    isActive: false,
    text: 'Serial #',
    type: 'text',
    value: [],
  },
  {
    id: 20,
    isActive: false,
    text: 'Assembly Type',
    dropDownField: 'assemblyType',
    type: 'dropDown',
    value: [],
  },
  {
    id: 32,
    isActive: false,
    text: 'Mud Type',
    dropDownField: 'mudType',
    type: 'dropDown',
    value: [],
  },
  {
    id: 21,
    isActive: false,
    text: 'Rental jobs',
    dropDownField: 'rentalJobs',
    type: 'dropDown',
    value: [],
  },
  {
    id: 22,
    isActive: true,
    dropDownField: 'yesNo',
    text: 'Get TVD',
    type: 'dropDown',
    value: ['No'],
    defValue: 'No',
  },
  { id: 25, isActive: false, dropDownField: 'yesNo', text: 'LIH', type: 'dropDown', value: [] },
  {
    id: 26,
    isActive: true,
    dropDownField: 'yesNo',
    text: 'Inc Offsets',
    type: 'dropDown',
    value: ['No'],
    defValue: 'No',
  },
  {
    id: 27,
    isActive: false,
    dropDownField: '-',
    text: '#Results',
    type: 'numeric',
    value: ['10'],
    regEx: /^[1-9]\d*$/,
    defValue: '10',
  },
  { id: 28, isActive: false, dropDownField: 'yesNo', text: 'Inc Non Drill', type: 'dropDown', value: [] },
  {
    id: 29,
    isActive: false,
    dropDownField: '-',
    text: 'Radius (miles)',
    type: 'numeric',
    value: ['25'],
    group: 'radius',
    regEx: /^(?:\d{1,3}(?:,\d{3})*(?:\.\d+)?|1000(?:\.0+)?)$/,
    defValue: '25',
  },
  {
    id: 30,
    isActive: false,
    dropDownField: '-',
    text: 'Lat',
    type: 'numeric',
    value: ['29.7604'],
    group: 'radius',
    regEx: /^-?(?:(?:\d|[1-9]\d|1[0-7]\d)(?:\.\d+)?|180(?:\.0+)?)$/,
    defValue: '29.7604',
  },
  {
    id: 31,
    isActive: false,
    dropDownField: '-',
    text: 'Long',
    type: 'numeric',
    value: ['-95.3698'],
    group: 'radius',
    regEx: /^-?(?:(?:\d|[1-9]\d|1[0-7]\d)(?:\.\d+)?|180(?:\.0+)?)$/,
    defValue: '-95.3698',
  },
]

export const getParameter = (param, filterDefs) => {
  if (!Array.isArray(filterDefs)) return []
  if (!param) return []
  if (typeof param !== 'string') return []
  if (param === '') return []

  for (let i = 0; i < filterDefs.length; i++) {
    if (filterDefs[i].text === param) {
      if (!filterDefs[i].hasOwnProperty('value')) return []
      if (!filterDefs[i].isActive) return []
      if (Array.isArray(filterDefs[i].value)) return filterDefs[i].value
      return filterDefs[i].value
    }
  }

  return []
}

export const getParameterActive = (param, filterDefs) => {
  if (!Array.isArray(filterDefs)) return false
  if (!param) return false
  if (typeof param !== 'string') return false
  if (param === '') return false

  for (let i = 0; i < filterDefs.length; i++) {
    if (filterDefs[i].text === param) {
      if (!filterDefs[i].hasOwnProperty('isActive')) return false
      return filterDefs[i].isActive
    }
  }

  return false
}
