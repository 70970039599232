import React, { useEffect, useRef, useState, useMemo } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PersonnelNamesPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const isLoadingDropDowns = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [personnelData, setPersonnelData] = useState([])
  const [personnelDropDowns, setPersonnelDropDowns] = useState({ employment: [], positions: [] })
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getData = useInnovaAxios({
    url: '/admin/personnel/getPersonnelNames',
  })

  const addData = useInnovaAxios({
    url: '/admin/personnel/createPersonnelName',
  })

  const deleteData = useInnovaAxios({
    url: '/admin/personnel/deletePersonnelName',
  })

  const getDropDownData = useInnovaAxios({
    url: '/admin/personnel/getDropDowns',
  })

  const updateData = useInnovaAxios({
    url: '/admin/personnel/updatePersonnelNames',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.personnelNamesPageKey)
    fetchData()
    fetchDropDownsData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData()
    if (_isMounted.current) {
      setPersonnelData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const fetchDropDownsData = async () => {
    if (isLoadingDropDowns.current) return
    isLoadingDropDowns.current = true
    const response = await getDropDownData()
    isLoadingDropDowns.current = false
    if (_isMounted.current) {
      setPersonnelDropDowns(Array.isArray(response.data?.positions) ? response.data : { employment: [], positions: [] })
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom' && isPinnedRowDataCompleted()) {
        handleAddRow([event.node.data])
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate([event.data])
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function clearCells(start, end, columns, gridApi) {
    let itemsToUpdate = []
    for (let i = start; i <= end; i++) {
      let data = gridApi.rowModel.rowsToDisplay[i].data
      columns.forEach((column) => {
        data[column] = ''
      })
      itemsToUpdate.push(data)
    }

    handleUpdate(itemsToUpdate)
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'name' && colDef.field !== 'position') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            params.api.getCellRanges().forEach((range) => {
              let colIds = range.columns.map((col) => col.colId)
              let startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex)
              let endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex)
              clearCells(startRowIndex, endRowIndex, colIds, params.api)
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('personnelNamesGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('personnelNamesGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'PersonnelNames.xlsx',
            sheetName: 'Personnel',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    return columnDefs.every((def) => {
      if (def.field === 'actions') return true
      if (def.field === 'email') return true
      if (def.field === 'phoneNum') return true
      if (def.field === 'employment') return true
      if (def.field === 'dayRate') return true
      if (def.field === 'active') return true
      return inputRow.current[def.field]
    })
  }

  const handleDelete = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteData(data)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [data],
      })
    }
  }

  const handleAddRow = async (data) => {
    if (!Array.isArray(data)) return
    if (isAdding.current) return
    for (let i = 0; i < data.length; i++) {
      data[i].active = true
      data[i] = htmlSymbolHandling(data[i])
    }
    isAdding.current = true
    let res = await addData({ personnelNames: JSON.stringify(data) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      return
    }

    if (!res?.error) {
      let personnelToAdd = []
      for (let i = 0; i < res.data.length; i++) {
        personnelToAdd.push(res.data[i])
      }
      gridApi.current.applyTransaction({
        add: personnelToAdd,
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i].dayRate = parseFloat(data[i].dayRate)
      data[i] = htmlSymbolHandling(data[i])
    }

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateData({ personnelNames: JSON.stringify(data) })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow([params.node.data])
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  let columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      cellRenderer: actionIconRenderer,
    },
    {
      field: 'name',
      colId: 'name',
      headerName: 'Name',
      valueGetter: (params) => unescapeHtml(params.data?.name),
    },
    {
      field: 'position',
      colId: 'position',
      headerName: 'Position',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return { values: Array.isArray(personnelDropDowns.positions) ? personnelDropDowns.positions : [] }
      },
    },
    {
      field: 'email',
      colId: 'email',
      headerName: 'Email',
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.email),
    },
    {
      field: 'phoneNum',
      colId: 'phoneNum',
      headerName: 'Phone #',
      cellStyle: centerAlignCell,
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.phoneNum),
    },
    {
      field: 'employment',
      colId: 'employment',
      headerName: 'Employment',
      cellStyle: centerAlignCell,
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return { values: Array.isArray(personnelDropDowns.employment) ? personnelDropDowns.employment : [] }
      },
    },
    {
      field: 'dayRate',
      colId: 'dayRate',
      headerName: 'Day Rate',
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      cellStyle: centerAlignCell,
      valueFormatter: (params) =>
        params.data?.dayRate > 0 ? numberWithCommasDecimals(parseFloat(params.data?.dayRate), 2) : '',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 100000,
        precision: 2,
      },
    },
    {
      field: 'active',
      colId: 'active',
      headerName: 'Active',
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      cellRenderer: 'agCheckboxCellRenderer',
      cellEditor: 'agCheckboxCellEditor',
    },
  ]

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    if (!params.hasOwnProperty('data') && !Array.isArray(params.data) && params.data?.length < 1) {
      return null
    }
    let data = params.data?.map((dataRow) => {
      return dataRow.slice()
    })

    // delete the empty last row in the clipboard data, if one exists
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] === 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    // make sure there's data to process after we remove the typical empty row
    if (data.length < 1) return null

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        const row = data[i].slice(0, 1)[0]
        let rowData = row.split(',')
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(rowData)) continue

        let rigNameData = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column

        for (let j = 0; j < rowData.length; j++) {
          if (!currentColumn) continue
          rigNameData.data[currentColumn.colDef.field] = rowData[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        rowsToUpdate.push(cloneDeep(rigNameData.data))
        curRowIndex++
      }
    }

    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        if (row.length < 2) continue
        const newName = row[0]
        let position = ''
        if (Array.isArray(personnelDropDowns.positions) && personnelDropDowns.positions.length > 0) {
          position = personnelDropDowns.positions.includes(row[1]) ? row[1] : 'DD'
        }
        let email = ''
        if (row.length > 2) {
          email = row[0][2]
        }
        let phoneNum = ''
        if (row.length > 3) {
          phoneNum = row[0][3]
        }
        let employment = ''
        if (row.length > 4) {
          employment = personnelDropDowns.employment.includes(row[4]) ? row[4] : 'Contract'
        }
        let dayRate = 0
        if (row.length > 5) {
          dayRate = row[5]
        }
        rowsToAdd.push({ name: newName, position: position, email: email, phoneNum: phoneNum, employment: employment, dayRate: dayRate, active: true })
        rowsToAdd.reverse()
      }
    }

    if (rowsToUpdate.length > 0) {
      handleUpdate(rowsToUpdate)
    }

    if (rowsToAdd.length > 0) {
      handleAddRow(rowsToAdd)
    }

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={personnelData}
            columnDefs={sortColDefs(columnDefs, 'personnelNamesGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            processDataFromClipboard={processDataFromClipboard}
            headerHeight={30}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
                fetchDropDownsData()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default PersonnelNamesPage
