import React, { useState, useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import ThreeDeeView from 'components/ThreeDeeView/ThreeDeeView'
import PlanViewChart from 'components/WellPages/PlanView/PlanViewChart'
import SectionViewChart from 'components/WellPages/SectionView/SectionViewChart'
import SurveysGrid from 'components/WellPages/SurveyPage/SurveysGrid'
import SplitPane from 'components/common/SplitPane'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import useSurveyData from 'components/common/hooks/useSurveyData'
import ToolsPane from 'components/WellPages/SurveyPage/ToolsPane/ToolsPane'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import { cloneDeep } from 'lodash'
import useGeosteering from 'components/common/hooks/useGeosteering'
import { getFullGeoSteeringData } from 'utils/GeoSteeringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SurveysGridChartPage = ({ wellName, surveyName = '', isWellbore, isPlan, isSurvey }) => {
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const [surveys, setSurveys] = useState([])
  const [projections, setProjections] = useState([])
  const {
    wellData,
    isLoading,
    getPrincipalPlanName,
    getWellPlanSurveys,
    getVerticalSectionParams,
    getTargets,
    getCriticalPoints,
    toggleErrorEllipses,
  } = useSurveyData(wellName, isPlan)
  const [showToolsPane, setShowToolsPane] = useState(false)
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const geoZones = useRef([])
  const [geoSteeringDataRaw, setGeoSteeringDataRaw] = useState([])
  const { fetchGeoSteeringData } = useGeosteering(wellName)
  const { getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    getGeoSteeringData()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calcGeoZones()
  }, [surveys, geoSteeringDataRaw, wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const getGeoSteeringData = async () => {
    let res = await fetchGeoSteeringData()
    if (res?.error) return

    setGeoSteeringDataRaw(Array.isArray(res.data) ? res.data : [])
  }

  const calcGeoZones = () => {
    geoZones.current = getFullGeoSteeringData(
      geoSteeringDataRaw,
      wellData?.actualWellData?.verticalSection,
      cloneDeep(surveys),
      wellData?.wellPlan?.surveyData,
    )
    setShouldUpdate(!shouldUpdate)
  }

  const getInitialPaneSize = (index) => {
    let pageLayout = getItemFromLS('surveysPageLayout', 'pageLayout')

    if (!Array.isArray(pageLayout)) return '70%'
    if (index >= pageLayout.length) return '70%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newLayout = [{ size: '70%', visible: false }]

    let pageLayout = getItemFromLS('surveysPageLayout', 'pageLayout')
    if (Array.isArray(pageLayout) && pageLayout.length >= 0) newLayout = cloneDeep(pageLayout)
    newLayout[index].size = newSize
    saveItemToLS('surveysPageLayout', 'pageLayout', cloneDeep(newLayout))
  }

  return (
    <SplitPane
      split='horizontal'
      defaultSize={'50%'}
      size={paneSize}
      style={{
        height: '100%',
        width: !isSurvey ? `100%` : `calc(100% - 25px)`,
        maxWidth: !isSurvey ? `100%` : `calc(100% - 25px)`,
      }}
      allowResize={true}>
      <Carousel autoPlay={false} height={'100%'} fullHeightHover={false} swipe={false} sx={{ width: '100%' }}>
        <ThreeDeeView
          refWellSurveys={surveys}
          wellData={wellData}
          isLoading={isLoading}
          wellName={wellName}
          projections={projections}
          isPlan={isPlan}
          refGeosteering={geoZones}
          toggleErrorEllipses={toggleErrorEllipses}
        />
        <Box sx={{ backgroundColor: getChartBackColor(), height: '100%', width: '100%' }}>
          <PlanViewChart
            data={wellData}
            refWellSurveys={surveys}
            isLoading={isLoading}
            projections={projections}
            isPlan={isPlan}
            toggleErrorEllipses={toggleErrorEllipses}
            printing={false}
          />
        </Box>
        <Box sx={{ backgroundColor: getChartBackColor(), height: '100%', width: '100%' }}>
          <SectionViewChart
            data={wellData}
            refWellSurveys={surveys}
            isLoading={isLoading}
            projections={projections}
            isPlan={isPlan}
            refGeosteering={geoZones.current}
            toggleErrorEllipses={toggleErrorEllipses}
            printing={false}
          />
        </Box>
      </Carousel>
      <SplitPane
        split='vertical'
        onDragFinished={(params) => onDragFinished(params, 0)}
        defaultSize={!isPlan ? 'calc(100% - 25px)' : '100%'}
        size={showToolsPane ? getInitialPaneSize(0) : !isPlan ? 'calc(100% - 25px)' : '100%'}
        allowResize={showToolsPane}
        style={{
          height: '100%',
          width: `100%`,
          maxWidth: `100%`,
        }}>
        <SurveysGrid
          wellName={wellName}
          handleShowChart={handleShowChart}
          showChart={showChart}
          setSurveys={setSurveys}
          isEditable={!isWellbore}
          surveyName={surveyName ? surveyName : null}
          isPlan={isPlan}
        />
        {!isPlan ? (
          <ToolsPane
            wellName={wellName}
            principalPlanName={getPrincipalPlanName()}
            showHidePane={() => setShowToolsPane(!showToolsPane)}
            paneVisible={showToolsPane}
            surveys={surveys}
            wellPlan={getWellPlanSurveys()}
            vsParams={getVerticalSectionParams()}
            projections={projections}
            setProjections={setProjections}
            targets={getTargets(true)}
            criticalPoints={getCriticalPoints()}
          />
        ) : (
          <React.Fragment />
        )}
      </SplitPane>
    </SplitPane>
  )
}

export default SurveysGridChartPage
