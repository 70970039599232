import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
}

const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    value += 'T00:00:01'
    return new Date(Date.parse(value)).toLocaleDateString('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })
}

const centerAlignCell = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

export const DEFAULT_COL_DEF = {
    resizable: true,
    sortable: false,
    autoHeight: true,
    editable: false,
    suppressHeaderMenuButton: false,
    headerClass: 'header-no-padding',
    cellStyle: centerAlignCell,
}

export const COL_DEF_PROJECT =
    [
        {
            field: 'name',
            colId: 'name',
            headerName: 'Name',
            minWidth: 150,
            cellStyle: { alignItem: 'end' },
            valueFormatter: (params) => unescapeHtml(params.data?.name),
        },
        {
            field: 'measure_unit',
            colId: 'measure_unit',
            headerName: 'Measure Unit',
            minWidth: 150,
            cellStyle: { alignItem: 'end' },
            valueFormatter: (params) => unescapeHtml(params.data?.measure_unit),
        },
        {
            field: 'role',
            colId: 'role',
            headerName: 'Role',
            minWidth: 150,
            cellStyle: { alignItem: 'end' },
            valueFormatter: (params) => unescapeHtml(params.data?.role),
        },
        {
            field: 'modified_on',
            colId: 'modified_on',
            headerName: 'Modified On',
            minWidth: 150,
            valueFormatter: (params) => dateTimeFormatter(params.data?.modified_on),
        },
        {
            field: 'authority',
            colId: 'authority',
            headerName: 'Geo Authority',
            minWidth: 150,
            cellStyle: { alignItem: 'end' },
            valueFormatter: (params) => unescapeHtml(params.data?.geo_crs?.authority),
        },
        {
            field: 'code',
            colId: 'code',
            headerName: 'Geo Code',
            minWidth: 150,
            cellStyle: centerAlignCell,
            valueFormatter: (params) => {
                return numberWithCommasDecimals(params.data?.geo_crs?.code, 0)
            },
        },
        {
            field: 'measure_unit',
            colId: 'measure_unit',
            headerName: 'Geo Measure Unit',
            minWidth: 150,
            cellStyle: { alignItem: 'end' },
            valueGetter: (params) => unescapeHtml(params.data?.geo_crs?.measure_unit),
        },
        {
            field: 'geoName',
            colId: 'geoName',
            headerName: 'Geo Name',
            minWidth: 150,
            cellStyle: { alignItem: 'end' },
            valueGetter: (params) => unescapeHtml(params.data?.geo_crs?.name),
        },
    ]

export const COL_DEF_WELL = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueGetter: (params) => unescapeHtml(params.data?.name),
    },
    {
        field: 'has_interpretations',
        colId: 'has_interpretations',
        headerName: 'Interpretations',
        minWidth: 150,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        valueGetter: (params) => params?.data?.flags?.has_interpretations,
    },
    {
        field: 'has_logs',
        colId: 'has_logs',
        headerName: 'Logs',
        minWidth: 150,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        valueGetter: (params) => params?.data?.flags?.has_logs,
    },
    {
        field: 'has_mudlogs',
        colId: 'has_mudlogs',
        headerName: 'Mudlogs',
        minWidth: 150,
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        valueGetter: (params) => params?.data?.flags?.has_mudlogs,
    },
    {
        field: 'has_nested_wells',
        colId: 'has_nested_wells',
        headerName: 'Nested Wells',
        minWidth: 150,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        valueGetter: (params) => params?.data?.flags?.has_nested_wells,
    },
    {
        field: 'has_target_lines',
        colId: 'has_target_lines',
        headerName: 'Target Lines',
        minWidth: 150,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        valueGetter: (params) => params?.data?.flags?.has_target_lines,
    },
    {
        field: 'has_topsets',
        colId: 'has_topsets',
        headerName: 'Topsets',
        minWidth: 150,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        valueGetter: (params) => params?.data?.flags?.has_topsets,
    },
]

export const COL_DEF_WELL_DATA = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueGetter: (params) => unescapeHtml(params.data?.name),
    },
    {
        field: 'operator',
        colId: 'operator',
        headerName: 'Operator',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueGetter: (params) => unescapeHtml(params.data?.operator),
    },
    {
        field: 'xsrf',
        colId: 'xsrf',
        headerName: 'Xsrf',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.xsrf?.val, 3)
        },
    },
    {
        field: 'ysrf',
        colId: 'ysrf',
        headerName: 'Ysrf',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.ysrf?.val, 3)
        },
    },
    {
        field: 'kb',
        colId: 'kb',
        headerName: 'KB',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.kb?.val, 3)
        },
    },
    {
        field: 'convergence',
        colId: 'convergence',
        headerName: 'Convergence',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.convergence?.val, 3)
        },
    },
    {
        field: 'azimuth',
        colId: 'azimuth',
        headerName: 'Azimuth',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.azimuth?.val, 3)
        },
    },
    {
        field: 'tie_in_tvd',
        colId: 'tie_in_tvd',
        headerName: 'Tie In TVD',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tie_in_tvd?.val, 3)
        },
    },
    {
        field: 'tie_in_ns',
        colId: 'tie_in_ns',
        headerName: 'Tie In NS',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tie_in_ns?.val, 3)
        },
    },
    {
        field: 'tie_in_ew',
        colId: 'tie_in_ew',
        headerName: 'Tie In EW',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tie_in_ew?.val, 3)
        },
    },
]

export const COL_DEF_TOP_HEADER = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => unescapeHtml(params.data?.name),
    },
]

export const COL_DEF_TOP_SET = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => unescapeHtml(params.data?.name),
    },
    {
        field: 'topset_name',
        colId: 'topset_name',
        headerName: 'Topset Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => unescapeHtml(params.data?.topset_name),
    },
    {
        field: 'md',
        colId: 'md',
        headerName: 'MD',
        cellStyle: centerAlignCell,
        minWidth: 150,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.md?.val, 3)
        },
    },
]

export const COL_DEF_TARGET_LINE_HEADER = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => unescapeHtml(params.data?.name),
    },
]

export const COL_DEF_TARGET_LINE_DATA = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => unescapeHtml(params.data?.name),
    },
    {
        field: 'inclination',
        colId: 'inclination',
        headerName: 'Dip',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.inclination?.val, 3)
        },
    },
    {
        field: 'azimuth',
        colId: 'azimuth',
        headerName: 'Azimuth',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.azimuth?.val, 3)
        },
    },
    {
        field: 'tvd_vs',
        colId: 'tvd_vs',
        headerName: 'TVD @ ZeroVS',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tvd_vs?.val, 3)
        },
    },
    {
        field: 'origin_vs',
        colId: 'origin_vs',
        headerName: 'Origin VS',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.origin_vs?.val, 3)
        },
    },
    {
        field: 'origin_tvd',
        colId: 'origin_tvd',
        headerName: 'Origin TVD',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.origin_tvd?.val, 3)
        },
    },
    {
        field: 'target_vs',
        colId: 'target_vs',
        headerName: 'Target VS',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.target_vs?.val, 3)
        },
    },
    {
        field: 'target_tvd',
        colId: 'target_tvd',
        headerName: 'Target TVD',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.target_tvd?.val, 3)
        },
    },
    // {
    //     field: 'delta_tvd',
    //     colId: 'delta_tvd',
    //     headerName: 'Delta TVD',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.delta_tvd?.val, 3)
    //     },
    // },
    // {
    //     field: 'delta_vs',
    //     colId: 'delta_vs',
    //     headerName: 'Delta VS',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.delta_vs?.val, 3)
    //     },
    // },
    // {
    //     field: 'length',
    //     colId: 'length',
    //     headerName: 'Length',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.length?.val, 3)
    //     },
    // },
    // {
    //     field: 'origin_base_coridor_tvd',
    //     colId: 'origin_base_coridor_tvd',
    //     headerName: 'Origin Base Coridor TVD',
    //     minWidth: 150,
    //     cellRenderer: 'agCheckboxCellRenderer',
    //     cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    // },
    // {
    //     field: 'origin_md',
    //     colId: 'origin_md',
    //     headerName: 'Origin MD',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.origin_md?.val, 3)
    //     },
    // },
    // {
    //     field: 'origin_top_corridor_tvd',
    //     colId: 'origin_top_corridor_tvd',
    //     headerName: 'Origin Top Corridor TVD',
    //     minWidth: 150,
    //     cellRenderer: 'agCheckboxCellRenderer',
    //     cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    // },
    // {
    //     field: 'origin_x',
    //     colId: 'origin_x',
    //     headerName: 'Origin X',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.origin_x?.val, 3)
    //     },
    // },
    // {
    //     field: 'origin_y',
    //     colId: 'origin_y',
    //     headerName: 'Origin Y',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.origin_y?.val, 3)
    //     },
    // },
    // {
    //     field: 'origin_z',
    //     colId: 'origin_z',
    //     headerName: 'Origin Z',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.origin_z?.val, 3)
    //     },
    // },
    // {
    //     field: 'target_base_corridor_tvd',
    //     colId: 'target_base_corridor_tvd',
    //     headerName: 'Target Base Corridor TVD',
    //     minWidth: 150,
    //     cellRenderer: 'agCheckboxCellRenderer',
    //     cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    // },
    // {
    //     field: 'target_md',
    //     colId: 'target_md',
    //     headerName: 'Target MD',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.target_md?.val, 3)
    //     },
    // },
    // {
    //     field: 'target_top_corridor_tvd',
    //     colId: 'target_top_corridor_tvd',
    //     headerName: 'Target Top Corridor TVD',
    //     minWidth: 150,
    //     cellRenderer: 'agCheckboxCellRenderer',
    //     cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    // },
    // {
    //     field: 'target_x',
    //     colId: 'target_x',
    //     headerName: 'Target X',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.target_x?.val, 3)
    //     },
    // },
    // {
    //     field: 'target_y',
    //     colId: 'target_y',
    //     headerName: 'Target Y',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.target_y?.val, 3)
    //     },
    // },
    // {
    //     field: 'target_z',
    //     colId: 'target_z',
    //     headerName: 'Target Z',
    //     minWidth: 150,
    //     cellStyle: centerAlignCell,
    //     valueFormatter: (params) => {
    //         return numberWithCommasDecimals(params.data?.target_z?.val, 3)
    //     },
    // },

]

export const COL_DEF_PLAN_HEADER = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => unescapeHtml(params.data?.name),
    },
]

export const COL_DEF_PLAN_DATA = [
    {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        minWidth: 150,
        cellStyle: { alignItem: 'end' },
        valueGetter: (params) => unescapeHtml(params.data?.name),
    },
    {
        field: 'xsrf',
        colId: 'xsrf',
        headerName: 'xsrf',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.xsrf?.val, 3)
        },
    },
    {
        field: 'ysrf',
        colId: 'ysrf',
        headerName: 'ysrf',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.ysrf?.val, 3)
        },
    },
    {
        field: 'kb',
        colId: 'kb',
        headerName: 'kb',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.kb?.val, 3)
        },
    },
    {
        field: 'tie_in_tvd',
        colId: 'tie_in_tvd',
        headerName: 'tie_in_tvd',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tie_in_tvd?.val, 3)
        },
    },
    {
        field: 'tie_in_ns',
        colId: 'tie_in_ns',
        headerName: 'tie_in_ns',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tie_in_ns?.val, 3)
        },
    },
    {
        field: 'tie_in_ew',
        colId: 'tie_in_ew',
        headerName: 'tie_in_ew',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.tie_in_ew?.val, 3)
        },
    },
    {
        field: 'corridor_top',
        colId: 'corridor_top',
        headerName: 'corridor_top',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.corridor_top?.val, 3)
        },
    },
    {
        field: 'corridor_base',
        colId: 'corridor_base',
        headerName: 'corridor_base',
        minWidth: 150,
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
            return numberWithCommasDecimals(params.data?.corridor_base?.val, 3)
        },
    },
]