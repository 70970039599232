import React, { useRef, useEffect } from 'react'
import { Box } from '@mui/material'
import CostCodesGrid from 'components/WellPages/DailyCostPage/CostCodesGrid'
import WellPropertiesPage from './WellPropertiesPage/WellPropertiesPage'
import TargetsPage from 'components/DatabaseTree/common/TargetsPage/TargetsPage'
import { dateBaseTreeSelectedPageAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const WellPage = ({ node }) => {
  const _isMounted = useRef(false)
  const [selectedPage, setSelectedPage] = useRecoilState(dateBaseTreeSelectedPageAtom)
  const canChangePage = useRef(true)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    if (selectedPage) {
      if (selectedPage?.nodeId !== node.nodeId) setSelectedPage(null)
    }
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          width: 'calc(100% - 25px)',
          height: '100%',
        }}>
        {!selectedPage || selectedPage?.page === 'WELL' ? (
          <WellPropertiesPage wellName={node?.name} nodeId={node.nodeId} canChangePage={canChangePage} />
        ) : null}
        {selectedPage?.page === 'COST_CODES' ? <CostCodesGrid parentWell={node?.name} /> : null}
        {selectedPage?.page === 'TARGETS' ? <TargetsPage well={node.name} /> : null}
      </Box>
      <Box
        sx={{
          width: '24px',
          height: '100%',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          border: '1px solid gray',
          marginLeft: '1px',
        }}>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: !selectedPage || selectedPage?.page === 'WELL' ? 'bold' : '400',
            color: !selectedPage || selectedPage?.page === 'WELL' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!canChangePage.current) return
            setSelectedPage({ nodeId: node.nodeId, page: 'WELL' })
          }}>
          Properties
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: selectedPage?.page === 'COST_CODES' ? 'bold' : '400',
            color: selectedPage?.page === 'COST_CODES' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!canChangePage.current) return
            setSelectedPage({ nodeId: node.nodeId, page: 'COST_CODES' })
          }}>
          Cost Codes
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: selectedPage?.page === 'TARGETS' ? 'bold' : '400',
            color: selectedPage?.page === 'TARGETS' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!canChangePage.current) return
            setSelectedPage({ nodeId: node.nodeId, page: 'TARGETS' })
          }}>
          Targets
        </Box>
      </Box>
    </Box>
  )
}

export default WellPage
