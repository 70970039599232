import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import SearchBar from 'components/common/SearchBar'
import {
  sortColDefs,
  relativeTime,
  dateComparator,
  isDateLessThan,
  CustomLoadingOverlay,
} from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'
import { styled } from '@mui/styles'
import * as jszip from 'jszip'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const defaultColDef = {
  resizable: true,
  sortable: true,
  autoHeight: true,
  wrapText: false,
  editable: false,
  filter: 'agSetColumnFilter',
  filterParams: {
    excelMode: 'windows',
  },
}

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const ApiHealthLogsGrid = ({ isLoading, setIsLoading, setFilteredData, showChart, setShowChart }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const [logData, setLogData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getApiLogData = useInnovaAxios({
    url: '/admin/logs/detail',
    responseType: 'blob', // critically important to properly retrieve the zip file
  })

  const fetchApiLogData = async () => {
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const res = await getApiLogData({
      param: 'all',
    })
    if (_isMounted.current) setIsLoading(false)
    if (!_isMounted.current) return

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!res.data) return

    let blobData = new Blob([res.data])
    let zip = await jszip.loadAsync(blobData, { base64: true })
    let jsonData = await zip.files['jsonLog.json'].async('string')
    let sortedData = JSON.parse(jsonData.replace(/\0/g, ''))
    if (!Array.isArray(sortedData) || sortedData.length === 0) return

    sortedData.sort((firstEl, secondEl) => (firstEl.ts !== secondEl.ts ? (firstEl.ts < secondEl.ts ? 1 : -1) : 0))
    setLogData(sortedData)
  }

  useEffect(() => {
    _isMounted.current = true
    fetchApiLogData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const dateFilterComparator = useCallback(dateComparator, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const getIcon = (level) => {
    switch (level) {
      case 'info':
        return <Iconify icon='bi:info-circle-fill' width='25' height='25' color='blue' />
      case 'error':
        return <Iconify icon='codicon:error' width='25' height='25' color='red' />
      case 'warn':
        return <Iconify icon='clarity:warning-solid' width='25' height='25' color='yellow' />
      default:
        return <Iconify icon='carbon:unknown-filled' width='25' height='25' color='gray' />
    }
  }

  const leftAlignedCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        field: 'level',
        colId: 'icon',
        headerName: 'Level',
        width: 100,
        resizable: false,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: (params) => {
          if (!params) return null
          if (!params.data) return null
          if (typeof params?.data?.level !== 'string') return null
          return getIcon(params?.data?.level)
        },
      },
      {
        field: 'caller',
        colId: 'caller',
        headerName: 'File',
        cellStyle: leftAlignedCell,
      },
      {
        field: 'func',
        colId: 'func',
        headerName: 'Func',
        cellStyle: leftAlignedCell,
      },
      {
        field: 'ts',
        colId: 'ts',
        headerName: 'Time',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => `${dateTimeFormatter(params.data?.ts)} [${relativeTimeFormatter(params.data?.ts)}]`,
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
        cellStyle: leftAlignedCell,
      },
      {
        field: 'msg',
        colId: 'msg',
        headerName: 'Message',
        cellStyle: leftAlignedCell,
      },
      {
        field: 'param',
        colId: 'param',
        headerName: 'Param',
        cellStyle: leftAlignedCell,
      },
      {
        field: 'err',
        colId: 'err',
        headerName: 'Error',
        cellStyle: leftAlignedCell,
      },
    ],
    [dateTimeFormatter, dateFilterComparator], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    setFilteredData(filteredNodes)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('apiHealthLogsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('apiHealthLogsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ApiHealthLog.xlsx',
            sheetName: 'Log',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ];
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={logData}
          columnDefs={sortColDefs(columnDefs, 'apiHealthLogsGrid')}
          defaultColDef={defaultColDef}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItems}
          headerHeight={30}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchApiLogData()
              },
            },
            {
              icon: (
                <Iconify
                  icon={showChart ? 'bxs:hide' : 'bxs:show'}
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: showChart ? 'Hide Charts' : 'Show Charts',
              onClick: () => setShowChart(!showChart),
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default ApiHealthLogsGrid
