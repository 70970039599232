import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import { getCostsByCode } from 'utils/dailyCostFunctions'
import DonutChartJs from 'components/common/DonutChartJs'
import { chartSeriesColors } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommas } from 'utils/stringFunctions'
import { round } from 'utils/numberFunctions'
import useWellData from 'components/common/hooks/useWellData'
import { getRandomColor } from 'utils/colorFunctions'

const WellCostDonutChart = () => {
  const { getUnitsText } = useUnits()
  const { wellData } = useWellData()

  const getDonutChartData = (costs) => {
    if (!costs) return []
    if (!Array.isArray(costs)) return []
    if (costs.length === 0) return []

    const costsByCode = getCostsByCode(costs)
    return costsByCode.map((cost, i) => {
      return {
        value: cost.hasOwnProperty('total') ? cost.total : 0,
        color: i < chartSeriesColors.length ? chartSeriesColors[i] : getRandomColor(),
        label: `${cost.code[0]}:${getUnitsText(UNITS_FOR.Cost)}${numberWithCommas(cost.total)}`,
      }
    })
  }

  const getDonutChartCenterText = (costs) => {
    if (!costs) return []
    if (!Array.isArray(costs)) return []
    if (costs.length === 0) return []

    const costsByCode = getCostsByCode(costs)
    let totalWellCost = costsByCode.reduce((a, b) => a + b.total, 0)

    return `${getUnitsText(UNITS_FOR.Cost)}${numberWithCommas(round(totalWellCost, 2))}`
  }

  return wellData?.costs ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0c0c0c',
        height: '100%',
        width: '100%',
      }}>
      <DonutChartJs
        title={''}
        data={getDonutChartData(wellData.costs)}
        centerText={getDonutChartCenterText(wellData.costs)}
        legendLocation={'left'}
        showLegend={false}
        showTitle={false}
      />
    </Box>
  ) : (
    <Box>
      <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation='wave' />
    </Box>
  )
}

export default WellCostDonutChart
