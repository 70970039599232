import React from 'react'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import { getBHAStats } from 'utils/dailyActivityFunctions'
import Skeleton from '@mui/material/Skeleton'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BhaStatisticsChartROP = ({ data, title = '' }) => {
  const { getUnitsText } = useUnits()
  const { theme, getTextColor } = useInnovaTheme()

  const createChartData = () => {
    if (!data) return { labels: [], datasets: [] }
    if (!Array.isArray(data.dailyActivity)) return { labels: [], datasets: [] }
    let dailyData = getBHAStats(data.dailyActivity)
    const chartData = {
      labels: dailyData.map((day, i) => 'BHA #' + (parseInt(i) + 1).toString()),
      datasets: [
        {
          data: dailyData.map((day) => Math.round(day.rotateROP, 2)),
          backgroundColor: appColors.rotateColor,
          label: `Rotate (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
        {
          data: dailyData.map((day) => Math.round(day.slideROP, 2)),
          backgroundColor: appColors.slideColor,
          label: `Slide (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
        {
          data: dailyData.map((day) => Math.round(day.avgROP, 2)),
          backgroundColor: appColors.avgColor,
          label: `Avg (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
          categoryPercentage: 0.5,
          barPercentage: 0.75,
        },
      ],
    }
    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    indexAxis: 'x',
    layout: {
      padding: {
        left: 20,
        right: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      tooltip: {
        yAlign: 'bottom',
        xAlign: 'center',
      },
      title: {
        display: title.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          color: getTextColor(),
        },
      },
      datalabels: {
        labels: {
          value: {
            font: {
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        stacked: false,
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: '#404040',
        },
      },
    },
  }

  return data ? (
    <Bar type='bar' options={chartOptions} data={createChartData()} plugins={[ChartDataLabels]} />
  ) : (
    <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation='wave' />
  )
}

export default BhaStatisticsChartROP
