import React, { useEffect, useRef, useMemo } from 'react'
import BHAStatisticsChartDistance from './BhaStatisticsChartDistance'
import BhaStatisticsChartHours from './BhaStatisticsChartHours'
import BhaStatisticsChartROP from './BhaStatisticsChartROP'
import Carousel from 'react-material-ui-carousel'
import useWellData from 'components/common/hooks/useWellData'
import { Box } from '@mui/material'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DrillStringChartsCarousel = () => {
  const _isMounted = useRef(false)
  const { wellData } = useWellData()
  const { getBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const containerStyle = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      backgroundColor: getBackColor(),
    }),
    [getBackColor],
  )

  return useMemo(() => {
    return (
      <Carousel autoPlay={false} height={'100%'} sx={{ width: '100%' }} swipe={false}>
        <Box sx={containerStyle}>
          <BHAStatisticsChartDistance data={wellData} title='BHA - Drilled' />
        </Box>
        <Box sx={containerStyle}>
          <BhaStatisticsChartHours data={wellData} title='BHA - Hours' />
        </Box>
        <Box sx={containerStyle}>
          <BhaStatisticsChartROP data={wellData} title='BHA - ROP' />
        </Box>
      </Carousel>
    )
  }, [wellData, containerStyle])
}

export default DrillStringChartsCarousel
