// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import * as jszip from 'jszip'

const useUploadEdm = () => {
  const _isMounted = useRef(false)
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const uploadEdmFile = async (files, options) => {
    const result = await uploadFile('/dataImport/uploadEdmFiles', files, options)
    return result
  }

  const uploadFile = async (uploadUrl, files, importOptions) => {
    if (!Array.isArray(files)) return false

    const zip = new jszip()
    for (let i = 0; i < files.length; i++) {
      zip.file(files[i].name, files[i])
    }

    let zipFile = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })

    let formData = new FormData()
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: uploadUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('importSurveyTools', importOptions.importSurveyTools)
    formData.append('importTargets', importOptions.importTargets)
    formData.append('importAllPlans', importOptions.importAllPlans)
    formData.append('importLeaseLines', importOptions.importLeaseLines)
    formData.append('assignLeaseLines', importOptions.assignLeaseLines)
    formData.append('assignOffsets', importOptions.assignOffsets)
    formData.append('assignOffsetsRadius', importOptions.assignOffsetsRadius)
    formData.append('actualWellStatus', importOptions.actualWellStatus)
    formData.append('file', zipFile)

    // need try/catch to properly handle 400 bad request
    try {
      const response = await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 300000,
        ...options,
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      if (response.error) {
        console.error('useUploadEdm response error:', response.error)
        return false
      } else {
        return response?.data
      }
    } catch (err) {
      console.error('useUploadEdm exception:', err)
      return false
    }
  }
  return uploadEdmFile
}

export default useUploadEdm
