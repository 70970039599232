import React, { useEffect, useRef } from 'react'
import { Box, Tooltip } from '@mui/material'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import BlankImage from '../../../../assets/NoImage.png'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const OperatorLogo = ({ logo, deleteLogo, uploadLogo, text }) => {
  const _isMounted = useRef(false)
  const { getLinearGradient, getTextColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const SelectImageIcon = ({ type }) => {
    const inputRef = useRef(null)

    return (
      <Tooltip
        title='Select image'
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Box
          onClick={() => {
            if (!inputRef.current) return
            inputRef.current.click()
          }}
          sx={{
            width: '25px',
            display: 'flex',
            margin: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
          <input
            ref={inputRef}
            style={{ display: 'none' }}
            type='file'
            accept='image/png, image/jpeg, image/bmp'
            onChange={(event) => {
              uploadLogo(event?.target?.files[0])
            }}
          />
          <Iconify icon='bi:image' style={{ color: appColors.accentColor, fontSize: '24px' }} />
        </Box>
      </Tooltip>
    )
  }

  const DeleteImageIcon = ({ deleteLogo }) => {
    return (
      <Tooltip
        title='Delete image'
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Box
          sx={{
            width: '25px',
            display: 'flex',
            margin: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => deleteLogo()}>
          <Iconify icon='fa-regular:trash-alt' style={{ color: '#C00000', fontSize: '24px' }} />
        </Box>
      </Tooltip>
    )
  }

  const ClampedImage = ({ src }) => {
    return (
      <Box
        component='img'
        src={src !== 'data:image/*;base64,' ? src : BlankImage}
        alt='NO LOGO'
        sx={{ width: '100%', height: 'calc(100% - 45px)', paddingBottom: '15px', objectFit: 'contain' }}
      />
    )
  }

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #000',
        background: getLinearGradient(),
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: '50%',
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'start',
          alignItems: 'center',
          maxHeight: '35px',
          flex: 0.25,
        }}>
        <DeleteImageIcon deleteLogo={deleteLogo} />
        <SelectImageIcon />
        <Box
          sx={{
            paddingLeft: '10px',
            color: getTextColor(),
          }}>
          {text}
        </Box>
      </Box>
      <ClampedImage src={logo} />
    </Box>
  )
}

export default OperatorLogo
