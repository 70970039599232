import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import DonutChartJs from 'components/common/DonutChartJs'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useWellData from 'components/common/hooks/useWellData'
import { getDailyActivityColor } from 'utils/colorFunctions'

const WellActivityDonutChart = () => {
  const { wellData } = useWellData()

  const getDonutChartData = (activitys) => {
    if (!activitys) return []
    if (!Array.isArray(activitys)) return []
    if (activitys.length === 0) return []

    return activitys.map((activity, i) => {
      return {
        value: activity.totalHours,
        color: getDailyActivityColor(i, activity.code),
        label: `${activity.code} ${activity.description}`,
      }
    })
  }

  const getDonutChartCenterText = (activitys) => {
    if (!activitys) return []
    if (!Array.isArray(activitys)) return []
    if (activitys.length === 0) return []

    let totalWelHours = activitys.reduce((acc, activity) => {
      return acc + activity.totalHours
    }, 0)

    return `${numberWithCommasDecimals(totalWelHours, 2)}`
  }

  return wellData?.activitySummary ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0c0c0c',
        height: '100%',
        width: '100%',
      }}>
      <DonutChartJs
        title={''}
        data={getDonutChartData(wellData.activitySummary)}
        centerText={getDonutChartCenterText(wellData.activitySummary)}
        legendLocation={'left'}
        showLegend={false}
        showTitle={false}
      />
    </Box>
  ) : (
    <Box>
      <Skeleton height={'100%'} width={'100%'} variant='rectangular' animation='wave' />
    </Box>
  )
}

export default WellActivityDonutChart
