import React, { useRef, useEffect, useState, useMemo } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import useInnovaAdminDropDowns from 'components/common/hooks/useInnovaAdminDropDown'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InnovaCompanysPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [innovaCompanysData, setInnovaCompanysData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedCompany = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dropDowns] = useInnovaAdminDropDowns(false)

  const getCompanyData = useInnovaAxios({
    url: '/admin/companyDetails/getCompanyDetails',
  })

  const deleteCompany = useInnovaAxios({
    url: '/admin/companyDetails/deleteCompanyDetail',
  })

  const addCompany = useInnovaAxios({
    url: '/admin/companyDetails/createCompanyDetail',
  })

  const updateCompany = useInnovaAxios({
    url: '/admin/companyDetails/updateCompanyDetail',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.innovaCompanysPageKey)
    fetchInnovaCompanys()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.companyId)
    }
  }, [])

  const fetchInnovaCompanys = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getCompanyData()

    if (response?.error) return
    if (!_isMounted.current) return

    setInnovaCompanysData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    let reqFields = ['companyName']
    if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('innovaCompanysGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaCompanysGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InnovaCompanys.xlsx',
            sheetName: 'Companys',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    let reqFields = ['companyName']
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current.active = true
    inputRow.current.paymentTerms = 30
    inputRow.current = htmlSymbolHandling(inputRow.current)

    //INCLUDE THIS LINE IF TESTING OTHERWISE COMPANY GETS CREATED IN XERO
    //inputRow.current.devMode = true

    let res = await addCompany(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.companyId = res.data.companyId
      inputRow.current.createdBy = res.data.createdBy
      inputRow.current.modifiedBy = res.data.modifiedBy
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('companyId')) return

    if (isUpdating.current) return
    data = htmlSymbolHandling(data)

    //INCLUDE THIS LINE IF TESTING OTHERWISE COMPANY GETS CREATED IN XERO
    //data.devMode = true

    isUpdating.current = true
    let res = await updateCompany(data)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      data.createdBy = res.data.createdBy
      data.modifiedBy = res.data.modifiedBy
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
    selectedCompany.current = cloneDeep(data)

    setConfirm({
      show: true,
      title: 'Delete Company',
      text: `Are you sure you want to delete ${unescapeHtml(data.companyName)}?`,
      onConfirm: handleDelete,
    })
  }

  const handleDelete = async () => {
    if (!selectedCompany.current) return
    if (!selectedCompany.current.hasOwnProperty('companyId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteCompany(selectedCompany.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedCompany.current],
      })
    }

    selectedCompany.current = null
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '25px' }}>
              {params.node.rowIndex + 1}
            </Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  let columnDefs = [
    {
      field: 'actions',
      colId: 'actions',
      width: 86,
      headerName: '',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      cellRenderer: actionIconRenderer,
    },
    {
      field: 'companyName',
      colId: 'companyName',
      headerName: 'Company',
      pinned: 'left',
      lockPosition: 'left',
      valueGetter: (params) => unescapeHtml(params.data?.companyName),
    },
    {
      field: 'address1',
      colId: 'address1',
      headerName: 'Address 1',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.address1),
    },
    {
      field: 'address2',
      colId: 'address2',
      headerName: 'Address 2',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.address2),
    },
    {
      field: 'address3',
      colId: 'address3',
      headerName: 'Address 3',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.address3),
    },
    {
      field: 'country',
      colId: 'country',
      headerName: 'Country',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.country),
    },
    {
      field: 'zipCode',
      colId: 'zipCode',
      headerName: 'Zip Code',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.zipCode),
    },
    {
      field: 'paymentMethod',
      colId: 'paymentMethod',
      headerName: 'Payment Method',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        let dropDown = Array.isArray(dropDowns.paymentMethod) ? dropDowns.paymentMethod : []
        return {
          values: dropDown.map((value) => value.label),
        }
      },
    },
    {
      field: 'paymentTerms',
      colId: 'paymentTerms',
      headerName: 'Payment Terms',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 180,
        precision: 0,
      },
    },
    {
      field: 'euTaxId',
      colId: 'euTaxId',
      headerName: 'Tax ID',
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.euTaxId),
    },
    {
      field: 'comments',
      colId: 'comments',
      headerName: 'Comments',
      maxWidth: 400,
      editable: (params) => params.node?.rowPinned !== 'bottom',
      valueGetter: (params) => unescapeHtml(params.data?.comments),
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 1000,
        rows: 10,
        cols: 50,
      },
    },
    {
      field: 'taxExempt',
      colId: 'taxExempt',
      headerName: 'Tax Expempt',
      cellStyle: centerAlignCell,
    },
    {
      field: 'active',
      colId: 'active',
      headerName: 'Active',
      cellStyle: centerAlignCell,
    },
    {
      field: 'modifiedBy',
      colId: 'modifiedBy',
      headerName: 'Last modified by',
      editable: false,
    },
  ]

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={innovaCompanysData}
            columnDefs={sortColDefs(columnDefs, 'innovaCompanysGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchInnovaCompanys()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default InnovaCompanysPage
