import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { useSetRecoilState } from 'recoil'
import { dateBaseTreeUpdateNameAtom } from 'atoms'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const OperatorContactGrid = ({ operatorData, handleUpdate, nodeId }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const setDataBaseTreeNameUpdate = useSetRecoilState(dateBaseTreeUpdateNameAtom)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateFunc = useCallback(
    async (data) => {
      if (!gridApi.current) return
      if (!operatorData) return
      if (!Array.isArray(data)) return

      let newName = null
      let newData = cloneDeep(operatorData)
      for (let i = 0; i < data.length; i++) {
        if (!newData.hasOwnProperty(data[i].tag)) continue
        if (data[i].tag === 'opName' && data[i].value !== operatorData.opName) newName = data[i].value

        newData[data[i].tag] = data[i].value
      }

      let res = await handleUpdate(newData)
      if (!res) return

      if (newName) {
        setDataBaseTreeNameUpdate({ name: newName, nodeId: nodeId, caller: 'UI' })
      }
    },
    [operatorData, handleUpdate, setDataBaseTreeNameUpdate, nodeId],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        minWidth: 350,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: true,
        cellEditorSelector: (params) => {
          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          if (params.data?.type === 'text') {
            let index = gridData.findIndex((data) => data.id === params.data?.id)
            if (index >= 0) gridData[index].value = params.newValue
          }

          handleUpdateFunc(gridData)
        },
      },
    ],
    [handleUpdateFunc],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getOperatorContactData = (data) => {
    let contact = [
      { label: `Name`, value: '', id: 1, type: 'text', tag: `opName` },
      { label: `Contact`, value: '', id: 2, type: 'text', tag: `contact` },
      { label: `Address`, value: '', id: 3, type: 'text', tag: `address` },
    ]

    if (!data) return contact
    for (let i = 0; i < contact.length; i++) {
      if (contact[i].tag === '') continue
      if (!data.hasOwnProperty(contact[i].tag)) continue
      if (!data[contact[i].tag]) continue
      contact[i].value = data[contact[i].tag]
    }

    return contact
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getOperatorContactData(operatorData)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default OperatorContactGrid
