import { useEffect, useRef, useState } from 'react'
import useAxiosGzip from './useAxiosGzip'

const useSurveyData = (wellName = null, isPlan = false) => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const isLoadingRef = useRef(false)
  const [wellData, setWellData] = useState(null)
  const currentWellRef = useRef(wellName)
  const showErrorEllipses = useRef(false)

  const getWellData = useAxiosGzip({
    url: '/well/getWellDataGz',
  })

  useEffect(() => {
    _isMounted.current = true
    getData()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentWellRef.current = wellName
    getData()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPrincipalPlanName = () => {
    if (isLoading) return ''
    if (!wellData?.wellPlan?.offsetWellbore) return ''
    return wellData?.wellPlan?.offsetWellbore
  }

  const getWellPlanSurveys = () => {
    if (isLoading) return []
    if (!Array.isArray(wellData?.wellPlan?.surveyData)) return []
    return wellData?.wellPlan?.surveyData
  }

  const getData = async () => {
    if (!currentWellRef.current || currentWellRef.current === '') return
    if (isLoadingRef.current) return
    isLoadingRef.current = true
    setIsLoading(true)

    const response = await getWellData({
      wellName: currentWellRef.current,
      wellPlan: true,
      surveys: true,
      offsetWells: true,
      targets: true,
      actualWellData: true,
      dailyActivity: true,
      costs: true,
      inventory: true,
      summary: true,
      isPlan: isPlan,
      calcErrorEllipses: showErrorEllipses.current,
    })

    if (response?.data) {
      setWellData(response?.data)
    }

    isLoadingRef.current = false
    setIsLoading(false)
  }

  const refreshWellData = async () => {
    await getData()
  }

  function getVerticalSectionParams() {
    let vsParams = {
      vsAzi: 0,
      vsNS: 0,
      vsEW: 0
    }

    if (!wellData) return vsParams
    if (!wellData.actualWellData?.verticalSection) return vsParams

    vsParams.vsAzi = wellData.actualWellData?.verticalSection.VsAzi
    vsParams.vsNS = wellData.actualWellData?.verticalSection.VsOrgNorth
    vsParams.vsEW = wellData.actualWellData?.verticalSection.VsOrgEast
    return vsParams
  }

  function getTargets(leaseLinesOnly = false) {
    if (!wellData) return []
    if (!Array.isArray(wellData.targets)) return []
    if (!leaseLinesOnly) return wellData.targets
    return wellData.targets.filter((tgt) => tgt.isLeaseLine)
  }

  function getCriticalPoints() {
    if (isLoading) return []
    if (!Array.isArray(wellData?.criticalPoints)) return []
    return wellData?.criticalPoints
  }

  const toggleErrorEllipses = (show) => {
    if (show && showErrorEllipses.current) return
    if (!show && !showErrorEllipses.current) return
    showErrorEllipses.current = show
    if (!show) return
    getData(currentWellRef.current)
  }

  return { wellData, refreshWellData, isLoading, currentWell: wellName, getPrincipalPlanName, getWellPlanSurveys, getVerticalSectionParams, getTargets, getCriticalPoints, toggleErrorEllipses }
}

export default useSurveyData
