import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { actionBarWidthAtom } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import SplitPane from 'components/common/SplitPane'
import { Box } from '@mui/material'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import RefreshIcon from '@mui/icons-material/Refresh'
import MultiDbWellListGrid from './MultiDbWellListGrid'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import WellsMapMultiDbPage from './WellsMapMultiDbPage'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'

const MultiDbWellListPage = () => {
  const _isMounted = useRef(false)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [showChart, setShowChart] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [filteredWells, setFilteredWells] = useState(false)
  const [selectedWells, setSelectedWells] = useState(false)
  const [wells, setWells] = useState([])

  const getWellData = useAxiosGzip({
    url: '/wellsMultiDbGz',
  })

  const fetchWellData = async () => {
    if (isLoading) return

    if (_isMounted.current) {
      setIsLoading(true)
    }

    const response = await getWellData({ allWells: true })
    if (_isMounted.current) setIsLoading(false)

    if (response?.error) {
      return
    }

    if (response?.data) {
      if (_isMounted.current) {
        setWells(Array.isArray(response.data) ? response.data : [])
      }
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.multiDbWellListKey)
    fetchWellData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          fetchWellData()
        },
      },
    ]

    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Charts',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Charts',
        onClick: () => handleShowChart(),
      })
    }
    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  return (
    <React.Fragment>
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={showChart ? '50%' : 0}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <WellsMapMultiDbPage selectedWells={selectedWells} filteredWells={filteredWells} />
        <MultiDbWellListGrid
          wells={wells}
          isLoading={isLoading}
          setFilteredData={setFilteredWells}
          setSelectedWells={setSelectedWells}
        />
      </SplitPane>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(2),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton actions={getMenuItems()} />
      </Box>
    </React.Fragment>
  )
}

export default MultiDbWellListPage
