import React, { useRef, useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  sortColDefs,
  htmlSymbolHandling,
  relativeTime,
  CustomLoadingOverlay,
} from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ErrorTermsGrid from './ErrorTermsGrid'
import ErrorModelUploadModal from './ErrorModelUploadModal'
import { debounce } from 'lodash'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import IPMMissingModal from './IPMMissingModal'
import IPMRenameModal from './IPMRenameModal'

const ErrorModelsPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const containerRef = useRef(null)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  //const [errorModels, setErrorModels] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [showIPMMissingModal, setShowIPMMissingModal] = useState(false)
  const [showIPMSwapModal, setShowIPMSwapModal] = useState(false)
  const swapIPMRef = useRef(null)
  const errorModelRef = useRef(null)

  const getErrorModels = useInnovaAxios({
    url: '/errorModels/getErrorModelHeaders',
  })
  const deleteErrorModel = useInnovaAxios({
    url: '/errorModels/deleteErrorModel',
  })

  const addErrorModel = useInnovaAxios({
    url: '/errorModels/addErrorModelHeader',
  })

  const updateErrorModel = useInnovaAxios({
    url: '/errorModels/updateErrorModelHeader',
  })

  const swapIPM = useInnovaAxios({
    url: '/errorModels/swapIPMName',
  })

  const removeDuplicates = useInnovaAxios({
    url: '/errorModels/removeDuplicates',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.errorModelsKey)
    fetchErrorModels()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return params?.rowPinned ? params.rowPinned : params.data?.fileName
    }
  }, [])

  const fetchErrorModels = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getErrorModels()

    setLoading(false)
    if (response?.error) return
    if (!_isMounted.current) return

    errorModelRef.current = response.data?.ErrorModelData ? response.data.ErrorModelData : []
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow(event.node.data)
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const reqFields = useMemo(() => ['fileName', 'toolName'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('errorModelGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('errorModelGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ErrorModels.xlsx',
            sheetName: 'ErrorModels',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Swap IPM',
        disabled: false,
        action: () => {
          swapIPMRef.current = params.node.data
          setShowIPMSwapModal(true)
        },
        icon: '<span class="iconify" data-icon="bx:rename" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const handleAddRow = async (data) => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(data)

    let res = await addErrorModel(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.toolId = res.data.toolId
      inputRow.current.uploadedDate = res.data.uploadedDate
      inputRow.current.uploadedBy = res.data.uploadedBy

      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })

      setStatus({
        show: true,
        severity: 'info',
        message: `${'Added Error Model'}`,
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('toolId')) return
    if (isUpdating.current) return
    data = htmlSymbolHandling(data)

    isUpdating.current = true
    let res = await updateErrorModel(data)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const handleDelete = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('toolId')) return

    if (data.hasOwnProperty('inUse') && data.inUse) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${'Cannot delete inUse Error Model'}`,
      })
      return
    }

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteErrorModel(data)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [data],
      })
    }
  }

  const handleSwapIPM = async (originalName, newName) => {
    if (!originalName) return
    if (!newName) return

    const response = await swapIPM({ originalName, newName })
    if (response?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${response?.error?.response?.data?.error}`,
      })
    }

    setShowIPMMissingModal(false)
    fetchErrorModels()
  }

  const handleRemoveDuplicates = async () => {
    const response = await removeDuplicates()
    if (response?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${response?.error?.response?.data?.error}`,
      })
      return
    }

    if (!response?.error) {
      setStatus({
        show: true,
        severity: 'info',
        message: `${'Removed Duplicates'}`,
      })
    }

    fetchErrorModels()
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow(params.data)
              }
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const relativeTimeFormatter = useCallback(relativeTime, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'toolTerms',
        colId: 'toolTerms',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 60,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'actions',
        colId: 'actions',
        width: 100,
        headerName: '',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'inUse',
        colId: 'inUse',
        headerName: 'In Use',
        resizable: false,
        width: 80,
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
      },
      {
        field: 'numberOfWells',
        colId: 'numberOfWells',
        headerName: '# Actuals',
        resizable: true,
        width: 80,
        editable: false,
        valueGetter: (params) => params.data?.numberOfWells,
      },
      {
        field: 'numberOfPlans',
        colId: 'numberOfPlans',
        headerName: '# Plans',
        resizable: true,
        width: 80,
        editable: false,
      },
      {
        field: 'fileName',
        colId: 'fileName',
        headerName: 'File Name',
        valueGetter: (params) => unescapeHtml(params.data?.fileName),
      },
      {
        field: 'toolName',
        colId: 'toolName',
        headerName: 'Tool Name',
        valueGetter: (params) => unescapeHtml(params.data?.toolName),
      },
      {
        field: 'uploadedDate',
        colId: 'uploadedDate',
        headerName: 'Uploaded Date',
        valueFormatter: (params) => {
          if (!params.data?.uploadedDate) return ''
          if (params.data?.uploadedDate === '') return ''
          if (isDateLessThan(params.data?.uploadedDate, '1990-01-01')) return ''

          return `${new Date(Date.parse(params.data?.uploadedDate)).toLocaleDateString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })} [${relativeTimeFormatter(params.data?.uploadedDate)}]`
        },
      },
      {
        field: 'uploadedBy',
        colId: 'uploadedBy',
        headerName: 'Uploaded By',
        valueGetter: (params) => getUserNameFromEmail(params.data?.uploadedBy),
      },
    ],
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        <ErrorTermsGrid setStatus={setStatus} toolId={params.data?.toolId} fileName={params.data?.fileName} />
      </Box>
    )
  })

  const handleUploadIpmFile = async () => {
    setShowUploadModal(false)
    await fetchErrorModels()
  }

  const getIPMData = () => {
    let ipmData = []
    if (!Array.isArray(errorModelRef.current)) return ipmData

    for (let i = 0; i < errorModelRef.current.length; i++) {
      let row = errorModelRef.current[i]
      if (!row.missing) {
        ipmData.push({
          toolId: row.toolId,
          toolTerms: row.toolTerms,
          inUse: row.inUse,
          fileName: row.fileName,
          toolName: row.toolName,
          uploadedDate: row.uploadedDate,
          uploadedBy: row.uploadedBy,
          numberOfPlans: row.numberOfPlans,
          numberOfWells: row.numberOfWells,
        })
      }
    }

    return ipmData
  }

  const getMissingIPM = () => {
    let missingIPM = []
    if (!Array.isArray(errorModelRef.current)) return missingIPM

    for (let i = 0; i < errorModelRef.current.length; i++) {
      let row = errorModelRef.current[i]
      if (row.missing) {
        missingIPM.push({
          toolId: row.toolId,
          toolTerms: row.toolTerms,
          inUse: row.inUse,
          fileName: row.fileName,
          toolName: row.toolName,
          uploadedDate: row.uploadedDate,
          uploadedBy: row.uploadedBy,
          numberOfPlans: row.numberOfPlans,
          numberOfWells: row.numberOfWells,
          wellDetails: row.wellDetails,
        })
      }
    }

    return missingIPM
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {showUploadModal ? <ErrorModelUploadModal open={showUploadModal} onClose={handleUploadIpmFile} /> : null}
      {showIPMMissingModal ? (
        <IPMMissingModal
          ipmData={getMissingIPM()}
          onCancel={() => setShowIPMMissingModal(false)}
          onSwapIPM={handleSwapIPM}
          ipms={getIPMData()}
        />
      ) : null}
      {showIPMSwapModal ? (
        <IPMRenameModal
          isOpen={showIPMSwapModal}
          onClose={() => setShowIPMSwapModal(false)}
          ipm={swapIPMRef.current}
          ipms={getIPMData()}
          onRename={handleSwapIPM}
        />
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box ref={containerRef} sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={getIPMData()}
            columnDefs={sortColDefs(columnDefs, 'errorModelGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            masterDetail={true}
            detailCellRenderer={DetailCellRenderer}
            detailRowAutoHeight={true}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchErrorModels()
              },
            },
            {
              icon: (
                <Iconify
                  icon='akar-icons:cloud-upload'
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: 'Import IPM Files',
              onClick: () => {
                setShowUploadModal(true)
              },
            },
            {
              icon: (
                <Iconify
                  icon='mdi:file-find-outline'
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: 'Find Missing IPM',
              onClick: () => {
                setShowIPMMissingModal(true)
              },
            },
            {
              icon: <Iconify icon='uiw:delete' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
              name: 'Remove Duplicates',
              onClick: () => {
                handleRemoveDuplicates()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default ErrorModelsPage
