import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, getStringId, CustomHeader } from 'components/common/AgGridUtils'
import { uuidv4 } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { Icon as Iconify } from '@iconify/react'
import { cloneDeep } from 'lodash'
import { calcSurvey, DTF_CL, nudgeTvdIncAzi } from 'utils/projectionCalcs'
import { threeDeeScan } from 'utils/threeDeeScan'
import { pointInterp } from 'utils/surveyFunctions'
import { appColors } from 'utils'
import { styled } from '@mui/styles'
import AdditionalProjections from './AdditionalProjections'
import PickListVirtualDialog from 'components/common/PickListVirtualDialog'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PROJ_METHODS = [
  { label: 'MD/INC/AZI', editableCols: ['md', 'inc', 'azi'] },
  { label: 'CL/INC/AZI', editableCols: ['cl', 'inc', 'azi'] },
  { label: 'MD/DLS/TF', editableCols: ['md', 'dls', 'tf'] },
  { label: 'CL/DLS/TF', editableCols: ['cl', 'dls', 'tf'] },
  { label: 'TVD/INC/AZI', editableCols: ['tvd', 'inc', 'azi'] },
]

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const MultiNudge = ({ surveys, wellPlan, vsParams, setProjections, criticalPoints }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const selectedProjection = useRef(null)
  const { getUnitsText, getDoglegBase } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const [showSelectCriticalPointDialog, setShowSelectCriticalPointDialog] = useState(false)
  const surveyData = useRef([
    {
      uid: 'LAST-SURVEY',
      type: 'Svy',
      method: 'MD/INC/AZI',
      md: 0,
      inc: 0,
      azi: 0,
      cl: 0,
      ns: 0,
      ew: 0,
      tvd: 0,
      dls: 0,
      tfo: 0,
      vs: 0,
      ud: 0,
      lr: 0,
      dist: 0,
      error: false,
    },
  ])

  const getProjectionData = () => {
    if (!gridApi.current) return []

    let gridSurveys = []
    gridApi.current?.forEachNodeAfterFilterAndSort((node) => {
      if (node.data) gridSurveys.push(node.data)
    })

    return gridSurveys
  }

  const calcProjections = useCallback(() => {
    let gridSurveys = getProjectionData()
    if (gridSurveys.length <= 0) return

    for (let i = 1; i < gridSurveys.length; i++) {
      gridSurveys[i].error = false
      if (gridSurveys[i].method === 'CL/INC/AZI') gridSurveys[i].md = gridSurveys[i - 1].md + gridSurveys[i].cl
      if (gridSurveys[i].method === 'CL/DLS/TF') gridSurveys[i].md = gridSurveys[i - 1].md + gridSurveys[i].cl

      gridSurveys[i].cl = gridSurveys[i].md - gridSurveys[i - 1].md
      if (gridSurveys[i].cl < 0) {
        gridSurveys[i].error = true
        continue
      }

      if (gridSurveys[i].method === 'MD/INC/AZI' || gridSurveys[i].method === 'CL/INC/AZI') {
        calcSurvey(gridSurveys[i - 1], gridSurveys[i], getDoglegBase(), vsParams)
      }

      if (gridSurveys[i].method === 'MD/DLS/TF' || gridSurveys[i].method === 'CL/DLS/TF') {
        const { md, inc, azi } = DTF_CL(
          gridSurveys[i - 1],
          gridSurveys[i].dls,
          gridSurveys[i].tf,
          gridSurveys[i].cl,
          getDoglegBase(),
        )
        gridSurveys[i].md = md
        gridSurveys[i].inc = inc
        gridSurveys[i].azi = azi
        calcSurvey(gridSurveys[i - 1], gridSurveys[i], getDoglegBase(), vsParams)
      }

      if (gridSurveys[i].method === 'TVD/INC/AZI') {
        let res = nudgeTvdIncAzi(gridSurveys[i - 1], gridSurveys[i], getDoglegBase(), [])
        if (res) {
          calcSurvey(gridSurveys[i - 1], gridSurveys[i], getDoglegBase(), vsParams)
        }
      }

      if (gridSurveys[i].error) continue
      let acResult = threeDeeScan({ ...gridSurveys[i] }, wellPlan)
      gridSurveys[i].ud = acResult?.acScan?.UD ? acResult?.acScan?.UD : 0
      gridSurveys[i].lr = acResult?.acScan?.LR ? acResult?.acScan?.LR : 0
      gridSurveys[i].dist = acResult?.acScan?.dist ? acResult?.acScan?.dist : 0
    }

    if (gridApi.current) {
      let interpProjections = []
      for (let i = 0; i < gridSurveys.length; i++) {
        if (gridSurveys[i].error) continue

        if (interpProjections.length === 0) {
          interpProjections.push({ ...gridSurveys[i] })
          continue
        }

        if (gridSurveys[i].cl <= getDoglegBase()) {
          interpProjections.push({ ...gridSurveys[i] })
          continue
        }

        if (gridSurveys[i].cl > getDoglegBase()) {
          let curMd = interpProjections[interpProjections.length - 1].md + getDoglegBase()

          //Subtracting 1 so that there are never two surveys at the same depth
          while (curMd < gridSurveys[i].md - 1) {
            let interpSurvey = pointInterp(gridSurveys, curMd)
            curMd += getDoglegBase()

            if (interpSurvey.md > interpProjections[interpProjections.length - 1].md) {
              interpProjections.push({ ...interpSurvey })
            }
          }

          interpProjections.push({ ...gridSurveys[i] })
        }
      }

      setProjections(interpProjections)

      gridApi.current.applyTransaction({
        update: gridSurveys,
      })
    }

    autoSizeColumns()
  }, [vsParams, getDoglegBase, wellPlan, setProjections])

  const isPinnedRowDataCompleted = useCallback(() => {
    if (!inputRow.current) return false
    if (!inputRow.current?.method) return false
    let method = PROJ_METHODS.find((meth) => meth.label === inputRow.current.method)
    if (!method) return false

    for (let i = 0; i < method.editableCols.length; i++) {
      if (!inputRow.current.hasOwnProperty(method.editableCols[i])) return false
    }

    return true
  }, [])

  const handleAddRow = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return

    if (!gridApi.current) return
    inputRow.current.uid = uuidv4()
    inputRow.current.error = false
    inputRow.current.type = 'Proj'

    gridApi.current.applyTransaction({
      add: [inputRow.current],
    })

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [isPinnedRowDataCompleted])

  const getLastSurvey = () => {
    if (!Array.isArray(surveys)) return
    if (surveys.length === 0) return
    if (!gridApi.current) return

    let lastSurvey = cloneDeep(surveys[surveys.length - 1])
    lastSurvey.uid = 'LAST-SURVEY'
    lastSurvey.type = 'Svy'
    lastSurvey.method = 'MD/INC/AZI'
    lastSurvey.error = false

    gridApi.current.applyTransaction({
      update: [lastSurvey],
    })
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false

      let projData = getProjectionData()
      if (projData.length > 0) {
        saveItemToLS('bitProjections', 'gridData', projData)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getLastSurvey()
    calcProjections()
  }, [surveys]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            return true
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
      cellStyle: centerAlignCell,
      filter: null,
      headerClass: 'header-no-padding',
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('multiNudgeBitProjGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('multiNudgeBitProjGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'BitProjections.xlsx',
            sheetName: 'Projections',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  const handleDelete = useCallback(
    (data) => {
      if (!data) return
      if (!data?.uid) return
      if (data.uid === 'LAST-SURVEY') return
      if (!gridApi.current) return

      gridApi.current.applyTransaction({
        remove: [data],
      })

      calcProjections()
    },
    [calcProjections],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const isEditable = useCallback((params) => {
    if (params?.data?.uid === 'LAST-SURVEY') return false
    if (params.colDef.field === 'method') return true

    let method = PROJ_METHODS.find((meth) => meth.label === params?.data?.method)
    if (!method) return false
    if (!Array.isArray(method?.editableCols)) return false
    return method?.editableCols.findIndex((col) => col === params.colDef.field) >= 0
  }, [])

  const getCellStyle = useCallback(
    (params) => {
      if (!isEditable(params)) return centerAlignCell
      return { ...centerAlignCell, backgroundColor: 'green' }
    },
    [isEditable, centerAlignCell],
  )

  const getValueFormatter = useCallback(
    (params) => {
      if (isEmptyPinnedCell(params) && isEditable(params)) {
        return params.colDef.field[0].toUpperCase() + params.colDef.field.slice(1) + '...'
      }

      return numberWithCommasDecimals(params.value, 2)
    },
    [isEditable],
  )

  const showAdditionalProjections = (data) => {
    if (!gridApi.current) return
    gridApi.current.forEachNode((node) => {
      if (node.data.uid !== data.uid) gridApi.current.setRowNodeExpanded(node, false)

      if (node.data.uid === data.uid) {
        gridApi.current.setRowNodeExpanded(node, !node.expanded)
      }
    })
  }

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'delete') {
        handleDelete(data)
      }

      if (action === 'selectTarget') {
        selectedProjection.current = data
        setShowSelectCriticalPointDialog(true)
      }

      if (action === 'additionalProjections') {
        selectedProjection.current = data
        showAdditionalProjections(data)
      }
    },
    [handleDelete],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 50,
        maxWidth: 50,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          if(params?.data?.uid === 'LAST-SURVEY') return null
          let menuItems = [
            {
              label: 'Delete',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                </StyledIconContainer>
              ),
            },
          ]

          if (params?.data?.method === 'TVD/INC/AZI') {
            menuItems.push({
              label: 'Select target',
              action: 'selectTarget',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='bx:target-lock' />
                </StyledIconContainer>
              ),
            })

            menuItems.push({
              label: 'Additional projections',
              action: 'additionalProjections',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='game-icons:flying-target' />
                </StyledIconContainer>
              ),
            })
          }

          return {
            menuItems: menuItems,
          }
        },
      },
      {
        colId: 'method',
        field: 'method',
        headerName: 'Method',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => isEditable(params),
        cellEditor: 'agSelectCellEditor',
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Method...' : params.data?.method),
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(PROJ_METHODS) ? PROJ_METHODS.map((method) => method.label) : [],
          }
        },
      },
      {
        colId: 'md',
        field: 'md',
        headerName: 'MD',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        colId: 'cl',
        field: 'cl',
        headerName: 'CL',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        colId: 'inc',
        field: 'inc',
        headerName: 'INC',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
      },
      {
        colId: 'azi',
        field: 'azi',
        headerName: 'AZI',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 360,
          precision: 2,
        },
      },
      {
        colId: 'dls',
        field: 'dls',
        headerName: 'DLS',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Dogleg) },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
      },
      {
        colId: 'tf',
        field: 'tf',
        headerName: 'TF',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -180,
          max: 360,
          precision: 2,
        },
      },
      {
        colId: 'tvd',
        field: 'tvd',
        headerName: 'TVD',
        cellStyle: getCellStyle,
        editable: (params) => isEditable(params),
        valueFormatter: getValueFormatter,
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        colId: 'ns',
        field: 'ns',
        headerName: 'NS',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'ew',
        field: 'ew',
        headerName: 'EW',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'vs',
        field: 'vs',
        headerName: 'VS',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'ud',
        field: 'ud',
        headerName: 'UD',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'lr',
        field: 'lr',
        headerName: 'LR',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'dist',
        field: 'dist',
        headerName: 'DIST',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
    ],
    [getUnitsText, isEditable, getCellStyle, getValueFormatter, handleMenuClick],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    suppressRowClickSelection: true,
    onCellEditingStopped: (params) => {
      if (params.oldValue === params.newValue) return

      if (params.node?.rowPinned === 'bottom') {
        handleAddRow()
      }

      calcProjections()
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onRowDataUpdated: (event) => {
      autoSizeColumns()
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    let projData = getItemFromLS('bitProjections', 'gridData')
    if (projData?.length > 1) {
      gridApi.current.applyTransaction({
        add: projData.slice(1),
      })
    }
    autoSizeColumns()
    getLastSurvey()
    calcProjections()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const getRowStyle = (params) => {
    if (params?.data?.error === true) {
      return { border: '1px solid red' }
    }
  }

  const handleSelectCrticalPoint = async (selectedPoint) => {
    setShowSelectCriticalPointDialog(false)
    if (!selectedProjection.current) return
    if (!Array.isArray(selectedPoint)) return
    if (selectedPoint.length === 0) return
    if (!Array.isArray(criticalPoints)) return []
    if (criticalPoints.length === 0) return []
    if (!gridApi.current) return

    if (selectedPoint[0] < 0 || selectedPoint[0] >= criticalPoints.length) return
    selectedProjection.current.inc = criticalPoints[selectedPoint[0]].inc
    selectedProjection.current.azi = criticalPoints[selectedPoint[0]].azi
    selectedProjection.current.tvd = criticalPoints[selectedPoint[0]].tvd

    gridApi.current.applyTransaction({
      update: [selectedProjection.current],
    })

    calcProjections()
  }

  const getCriticalPointsList = () => {
    if (!Array.isArray(criticalPoints)) return []
    if (criticalPoints.length === 0) return []

    return criticalPoints.map((pt, index) => {
      return {
        desc: `MD:${numberWithCommasDecimals(pt.md, 2)}, INC:${numberWithCommasDecimals(
          pt.inc,
          2,
        )}, AZI:${numberWithCommasDecimals(pt.azi, 2)}, TVD:${numberWithCommasDecimals(pt.tvd, 2)}`,
        id: index,
      }
    })
  }

  const DetailCellRenderer = () => {
    return (
      <Box sx={{ height: 200 }}>
        <AdditionalProjections
          wellPlan={wellPlan}
          vsParams={vsParams}
          selectedUid={selectedProjection.current.uid}
          projections={getProjectionData()}
        />
      </Box>
    )
  }

  const isRowMaster = useMemo(() => {
    return (data) => {
      return data?.method === 'TVD/INC/AZI'
    }
  }, [])

  return (
    <React.Fragment>
      {showSelectCriticalPointDialog ? (
        <PickListVirtualDialog
          title='Critical Points'
          open={showSelectCriticalPointDialog}
          setOpen={() => setShowSelectCriticalPointDialog(false)}
          onApply={handleSelectCrticalPoint}
          items={getCriticalPointsList()}
          singleItemSelect={true}
          showSearch={false}
          initSelItems={[]}
        />
      ) : null}

      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={surveyData.current}
          columnDefs={sortColDefs(columnDefs, 'multiNudgeBitProjGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          onGridReady={onGridReady}
          groupRowsSticky={true}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          onFirstDataRendered={onFirstDataRendered}
          rowDragManaged={true}
          headerHeight={40}
          components={components}
          getRowStyle={getRowStyle}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
          isRowMaster={isRowMaster}
        />
      </div>
    </React.Fragment>
  )
}

export default MultiNudge
