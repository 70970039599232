import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import MultiWellDashboardSearch from './MultiWellDashBoardSearch'
import { array2pipestr } from 'utils'
import { appColors } from 'utils'
import { multiWellDashboardSelectedWells, currentPageAtom, actionBarWidthAtom } from 'atoms'
import ResponsiveDashboard from 'components/common/ReactGridLayout/ResponsiveDashboard'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box } from '@mui/material'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import MultiWellDashboardCard from './MultiWellDashBoardCard'
import { GetActivityColor } from 'components/common/activitySelector'
import useInterval from 'components/common/hooks/useInterval'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MultiWellDashboardPage = () => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(multiWellDashboardSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const { getBackColor } = useInnovaTheme()

  const getDashboardData = useInnovaAxios({
    url: '/multiWellDashboard',
  })

  const TitleText = ({ children }) => {
    return (
      <Box
        sx={{
          flexGrow: 0,
          margin: 'auto',
          color: appColors.itemTextColor,
          fontWeight: 500,
          fontSize: 13,
        }}>
        {children}
      </Box>
    )
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.multiWellDashboardPageKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(() => {
    fetchData()
  }, 5000)

  const fetchData = async () => {
    if (selectedWells.length < 1) {
      await setData([])
      return
    }

    const dataResponse = await getDashboardData({
      wellList: array2pipestr(selectedWells),
    })
    if (_isMounted.current) {
      if (dataResponse?.data) {
        setData(dataResponse.data)
      } else {
        setData([])
      }
    }
  }

  const columns = { xl: 8, lg: 8, md: 4, sm: 2, xs: 2, xxs: 2 }

  const buildLayout = () => {
    //Try to get layout from local storage if not use default
    let wellDashLayout = getItemFromLS('multiWellDashboard', 'layouts') || {
      lg: [],
      md: [],
      sm: [],
    }

    if (!data) return wellDashLayout
    if (!Array.isArray(data)) return wellDashLayout

    let defWidth = 2
    let defHeight = 2

    const getCardPos = (index, layout) => {
      let cardsPerRow = Math.max(columns[layout] / defWidth, 1)
      return { x: (index % cardsPerRow) * defWidth, y: Math.floor(index / cardsPerRow) }
    }

    //Find cards which need to be removed
    let cardsToRemove = []
    wellDashLayout.lg.forEach((layoutElement) => {
      let index = data.findIndex((element) => element.actualWell === layoutElement.i)
      if (index < 0) cardsToRemove.push(layoutElement.i)
    })

    //Remove cards from the layout
    cardsToRemove.forEach((card) => {
      for (const property in wellDashLayout) {
        let index = wellDashLayout[property].findIndex((layoutElement) => card === layoutElement.i)
        if (index > -1) wellDashLayout[property].splice(index, 1)
      }
    })

    //Remove gaps from layout
    for (const layout in wellDashLayout) {
      wellDashLayout[layout].forEach((card, index) => {
        const { x, y } = getCardPos(index, layout)
        card.x = x
        card.y = y
      })
    }

    //Add new cards
    data.forEach((well) => {
      let index = wellDashLayout.lg.findIndex((layoutElement) => well.actualWell === layoutElement.i)
      if (index < 0) {
        for (const layout in wellDashLayout) {
          const { x, y } = getCardPos(wellDashLayout[layout].length, layout)
          wellDashLayout[layout].push({
            i: `${well.actualWell}`,
            x,
            y,
            w: defWidth,
            h: defHeight,
            isResizable: false,
          })
        }
      }
    })

    //Layout needs to be saved to local storage so it can get retrieved by the dashboard
    saveItemToLS('multiWellDashboard', 'layouts', wellDashLayout)
    return wellDashLayout
  }

  const TitleComponent = ({ wellData }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '50px',
        backgroundColor: getBackColor(),
      }}>
      <TitleText>{wellData?.operator}</TitleText>
      <TitleText>
        <span>{wellData?.actualWell} - </span>
        <span style={{ color: GetActivityColor(wellData.wellStatus) }}>{wellData.wellStatus}</span>
      </TitleText>
      <TitleText>
        {`${wellData.jobNum || '-'} - ${wellData.rig || '-'} - ${wellData.state || '-'}/${wellData.county || '-'} - ${
          wellData.phase || '-'
        }`}
      </TitleText>
    </Box>
  )

  const getDashboardCards = () => {
    let dashCards = []

    if (!data) return dashCards
    if (!Array.isArray(data)) return dashCards

    dashCards = data.map((well) => {
      return {
        key: `${well.actualWell}`,
        TitleComponent: TitleComponent,
        title: well.actualWell,
        Component: MultiWellDashboardCard,
        visible: true,
        customprops: { wellData: well },
        titleprops: { wellData: well },
        showCloseButton: false,
      }
    })

    return dashCards
  }

  return (
    <Box
      sx={{
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        height: 'calc(100vh-64px)',
      }}>
      <MultiWellDashboardSearch
        showPhaseFilter={false}
        backGroundTransparent={true}
        allowedStatus={['Active', 'Standby', 'TD']}>
        <Box
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            flex: 1,
            width: '100%',
            height: '100%',
          }}>
          <ResponsiveDashboard
            uid={'multiWellDashboard'}
            defaultLayout={buildLayout()}
            components={getDashboardCards()}
            showMenu={false}
            columns={columns}
          />
        </Box>
      </MultiWellDashboardSearch>
    </Box>
  )
}

export default MultiWellDashboardPage
