import React, { useEffect, useRef, useState } from 'react'

import { Avatar, IconButton, Tooltip, Box } from '@mui/material'
import { styled } from '@mui/styles'
import SearchBar from 'components/common/SearchBar'
import { filter as _filter } from 'lodash'
import { Icon as Iconify } from '@iconify/react'

import SimpleStyledList from 'components/common/SimpleStyledList'
import { useRecoilState, useRecoilValue } from 'recoil'
import { chatRefreshRoomsAtom, chatSelectedRoomAtom, chatCollapsedSelector } from 'components/common/hooks/useChat'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import { getUserNameFromEmail, getUserNameInitials } from 'utils/chatFunctions'
import { isoToTimeDayString } from 'utils/dateTimeFunctions'
import { appColors } from 'utils'
import AddIcon from '@mui/icons-material/Add'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SectionPickListDialog from 'components/common/SectionPickListDialog'
import { buildPipeDelimitedString } from 'utils/arrayFunctions'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledItemDelIcon = styled(IconButton)({
  flex: 0.3,
  alignItems: 'flex-end',
  justifyContent: 'center',
})

const StyledDeleteIcon = styled(Iconify)({
  color: '#C00000',
  fontSize: '20px',
})

const StyledItemIconContainer = styled(IconButton)({
  position: 'absolute',
  right: '4px',
  width: '40px',
  height: '40px',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledAvatarContainer = styled(Box)({
  display: 'flex',
  margin: '10px',
  marginRight: '15px',
  width: '40px',
  height: '40px',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'lightgrey',
  color: '#000',
  borderRadius: '50%',
  zIndex: 10,
  cursor: 'pointer',
})

const StyledColContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
})

const ChatRoomControl = () => {
  const _isMounted = useRef(false)
  const [filteredList, setFilteredList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedChatRoom, setSelectedChatRoom] = useRecoilState(chatSelectedRoomAtom)
  const [chatCollapsed, setChatCollapsed] = useRecoilState(chatCollapsedSelector)
  const { user } = useInnovaAuth()
  const [showChatroomPickModal, setShowChatroomPickModal] = useState({ show: false, isAdd: false })
  const [contactList, setContactList] = useState([])
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const refreshChatRoomFlag = useRecoilValue(chatRefreshRoomsAtom)
  const { getCompanyIcon } = useOrgIcons()
  const [chatRoomData, setChatRoomData] = useState()
  const [dataContacts, setDataContacts] = useState()
  const { searchBarStyle } = useInnovaTheme()

  const getChatRooms = useInnovaAxios({
    url: '/chat/getChatRooms',
  })

  const getContacts = useInnovaAxios({
    url: '/chat/getContacts',
  })

  const createChatRoom = useInnovaAxios({
    url: '/chat/createChatRoom',
  })

  const addUserChatRoom = useInnovaAxios({
    url: '/chat/addUserToChatRoom',
  })

  const removeUserFromChatRoom = useInnovaAxios({
    url: '/chat/removeUserChatRoom',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchChatRooms()
    fetchContacts()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (chatRoomData) {
      const filtered = handleSearch(searchText, chatRoomData)
      if (_isMounted.current) setFilteredList(filtered)
    } else {
      if (_isMounted.current) setFilteredList([])
    }
  }, [chatRoomData, searchText])

  useEffect(() => {
    if (_isMounted.current && dataContacts) {
      setContactList(createContactList(dataContacts))
    }
  }, [dataContacts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && refreshChatRoomFlag >= 0) {
      fetchChatRooms()
    }
  }, [refreshChatRoomFlag]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchChatRooms = async () => {
    const response = await getChatRooms()
    if (_isMounted.current && response?.data) {
      setChatRoomData(response.data)
    }
  }

  const fetchContacts = async () => {
    const response = await getContacts()
    if (_isMounted.current && response?.data) {
      setDataContacts(response.data)
    }
  }

  const handleSearch = (text, data) => {
    if (text && typeof text === 'string' && text !== '') {
      const filterList = _filter(data, (item) => {
        let result = false
        for (let i = 0; i < item.members?.length; i++) {
          const member = item.members[i].toLowerCase().trim()
          if (member.includes(text.toLowerCase().trim())) result = true
        }
        return result
      })

      return filterList
    } else {
      return data
    }
  }

  const selectChatRoom = (event, item) => {
    event.stopPropagation()
    setSelectedChatRoom(item)
  }

  const getGroupMembersText = (membersArray) => {
    if (!Array.isArray(membersArray)) return ''
    if (membersArray.length > 0) {
      let numGroupMembers = 0
      let firstMember = ''
      membersArray.forEach((member) => {
        if (member !== user?.name) {
          if (firstMember === '') firstMember = member
          numGroupMembers++
        }
      })

      return getUserNameFromEmail(firstMember) + (numGroupMembers > 1 ? ` +${numGroupMembers - 1}` : '')
    }
    return ''
  }

  const getFirstGroupMemberInitials = (membersArray) => {
    if (!Array.isArray(membersArray)) return ''

    let firstMember = ''
    for (let i = 0; i < membersArray.length; i++) {
      if (membersArray[i] !== user.name) {
        firstMember = membersArray[i]
        break
      }
    }
    return getUserNameInitials(firstMember)
  }

  const createContactList = (contacts) => {
    if (Array.isArray(contacts) === false) return []
    if (contacts.length === 0) return []

    let output = []

    contacts.forEach((contact) => {
      if (contact.userName !== user.name) {
        let orgIndex = output.findIndex((element) => element.organization === contact.organization)
        if (orgIndex < 0) {
          output.push({
            header: contact.organization,
            organization: contact.organization,
            image: getCompanyIcon(contact.organization, true),
            data: [{ userName: contact.userName, id: contact.userName, desc: contact.userName }],
          })
        }

        if (orgIndex >= 0)
          output[orgIndex].data.push({ userName: contact.userName, id: contact.userName, desc: contact.userName })
      }
    })

    function sortOrgs(a, b) {
      if (a.organization < b.organization) return -1
      if (a.organization > b.organization) return 1

      return 0
    }

    output.sort(sortOrgs)

    function sortUsers(a, b) {
      if (a.userName < b.userName) return -1
      if (a.userName > b.userName) return 1

      return 0
    }

    output.forEach((org) => org.data.sort(sortUsers))
    return output
  }

  const doesChatRoomExist = (newMembersList) => {
    if (!Array.isArray(newMembersList)) return -1
    if (newMembersList.length === 0) return -1
    if (!Array.isArray(chatRoomData)) return -1
    if (chatRoomData.length === 0) return -1

    for (let i = 0; i < chatRoomData.length; i++) {
      if (!Array.isArray(chatRoomData[i].members)) continue
      if (chatRoomData[i].members.length === 0) continue
      if (chatRoomData[i].members.length !== newMembersList.length) continue

      let roomSame = true
      for (let j = 0; j < newMembersList.length; j++) {
        if (chatRoomData[i].members.findIndex((element) => element === newMembersList[j]) < 0) {
          roomSame = false
          break
        }
      }

      if (roomSame) return chatRoomData[i].roomId
    }

    return -1
  }

  const handleNewChatroom = () => {
    setShowChatroomPickModal({ show: true, isAdd: true })
  }

  const applyNewChatRoom = async (newChatRoomMembers) => {
    if (!newChatRoomMembers || !Array.isArray(newChatRoomMembers)) return false

    newChatRoomMembers.push(user.name)

    let roomId = doesChatRoomExist(newChatRoomMembers)
    if (roomId < 0) {
      const response = await createChatRoom({
        memberList: buildPipeDelimitedString(newChatRoomMembers),
      })

      if (response?.data) {
        if (response.data.hasOwnProperty('roomId')) roomId = response.data.roomId
        await fetchChatRooms()
      }
    }

    if (roomId >= 0) {
      setSelectedChatRoom({ roomId, members: newChatRoomMembers })
    }

    return 'chat room created'
  }

  const getUserNameArray = () => {
    let output = [user.name]
    if (!Array.isArray(selectedChatRoom.members)) return output

    if (selectedChatRoom.members.length === 0) return output
    for (let i = 0; i < selectedChatRoom.members.length; i++) {
      if (selectedChatRoom.members[i] === user.name) continue
      output.push(selectedChatRoom.members[i])
    }

    return output
  }

  const applyUpdateChatRoom = async (newUserList) => {
    if (!newUserList || !Array.isArray(newUserList)) return

    let usersToAdd = []
    let currentUsers = getUserNameArray()
    for (let i = 0; i < newUserList.length; i++) {
      if (currentUsers.findIndex((element) => element === newUserList[i]) < 0) usersToAdd.push(newUserList[i])
    }

    if (usersToAdd.length === 0) return

    const response = await addUserChatRoom({
      chatRoomId: selectedChatRoom.roomId,
      userList: buildPipeDelimitedString(usersToAdd),
    })
    if (response?.error) {
      console.error('error saving users to chatroom')
    }
    await fetchChatRooms()

    let paramsCopy = { ...selectedChatRoom }
    paramsCopy.members = [...newUserList]
    setSelectedChatRoom(paramsCopy)
  }

  const onAddChatRoomUser = (event, item) => {
    event.stopPropagation()
    setSelectedChatRoom(item)
    setShowChatroomPickModal({ show: true, isAdd: false })
  }

  const onDeleteFromChatRoom = (event, item) => {
    event.stopPropagation()
    setSelectedChatRoom(item)
    setConfirm({
      show: true,
      title: 'Delete User from ChatRoom',
      text: `Are you sure you want to leave the chat room?`,
      itemIndex: item.roomId,
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex > 0) {
      const deleteResponse = await removeUserFromChatRoom({
        chatRoomId: confirm?.itemIndex,
        userList: user.name,
      })
      if (deleteResponse?.error) {
        console.error(`error removing user ${user.name} from chatroom`, deleteResponse?.error)
      }
      await fetchChatRooms()
    }
  }

  const toggleChatrooms = () => {
    setChatCollapsed(!chatCollapsed)
  }

  const renderItem = (item, index) => {
    let msg = item.lastMsg.message
    if (msg) {
      msg = item.lastMsg.message.replace('\\\\', '\\')
      msg = msg.replace('&sq;', "'")
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: `1px solid`,
          width: '100%',
          borderRadius: '4px',
          borderColor: item.roomId === selectedChatRoom.roomId ? '#0F0' : appColors.itemBackColor,
          '&:hover': {
            borderColor: appColors.accentColor,
          },
          cursor: 'pointer',
        }}
        onClick={(event) => selectChatRoom(event, item)}>
        <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <StyledColContainer sx={{ flex: 1 }}>
            <StyledAvatarContainer onClick={(event) => onAddChatRoomUser(event, item)}>
              <Tooltip
                title='add users to room'
                placement='left'
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                    },
                  },
                }}>
                <Avatar
                  alt='first group member'
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'lightgrey',
                    color: '#000',
                    borderRadius: '50%',
                  }}>
                  {getFirstGroupMemberInitials(item.members)}
                </Avatar>
              </Tooltip>
            </StyledAvatarContainer>
          </StyledColContainer>
          <StyledColContainer sx={{ flex: 4 }}>
            <Box sx={{ textAlign: 'left', color: appColors.headerTextColor, padding: '4px' }}>
              {getGroupMembersText(item.members)}
            </Box>
            <Box sx={{ textAlign: 'left', color: appColors.itemTextColor, padding: '4px' }}>{msg}</Box>
          </StyledColContainer>
          <StyledColContainer sx={{ flex: 4 }}>
            <Box sx={{ textAlign: 'right', color: appColors.headerTextColor, padding: '4px' }}>
              {isoToTimeDayString(item.lastMsg.timeStamp)}
            </Box>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', flex: 1 }}>
              <Avatar
                alt='unread msgs'
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  backgroundColor: '#0F0',
                  color: '#000',
                  borderRadius: '50%',
                  margin: '4px',
                  height: '30px',
                  width: '30px',
                  fontSize: '12px',
                  fontWeight: 500,
                  visibility: item.unreadMsgs > 0 ? 'visible' : 'hidden',
                }}>
                {item.unreadMsgs}
              </Avatar>
              <Tooltip
                title='leave chat room'
                placement='bottom'
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                    },
                  },
                }}>
                <StyledItemDelIcon onClick={(event) => onDeleteFromChatRoom(event, item)} size='large'>
                  <StyledDeleteIcon icon='icomoon-free:exit' />
                </StyledItemDelIcon>
              </Tooltip>
            </Box>
          </StyledColContainer>
        </Box>
      </Box>
    )
  }

  const renderCreateUpdateChatroomDialog = () => {
    if (showChatroomPickModal.isAdd) {
      return renderCreateChatroomDialog()
    } else {
      return renderEditChatroomDialog()
    }
  }

  const renderCreateChatroomDialog = () => {
    return (
      <SectionPickListDialog
        title='Create Chatroom'
        open={showChatroomPickModal.show}
        setOpen={() => setShowChatroomPickModal({ show: false, isAdd: true })}
        onApply={applyNewChatRoom}
        items={Array.isArray(contactList) ? contactList : []}
        initSelItems={[]}
        singleItemSelect={false}
        showSearch={true}
        searchParams={['userName']}
      />
    )
  }

  const renderEditChatroomDialog = () => {
    return (
      <SectionPickListDialog
        title='Add Users'
        open={showChatroomPickModal.show}
        setOpen={() => setShowChatroomPickModal({ show: false, isAdd: false })}
        onApply={applyUpdateChatRoom}
        items={Array.isArray(contactList) ? contactList : []}
        initSelItems={selectedChatRoom.members}
        singleItemSelect={false}
        showSearch={true}
        searchParams={['userName']}
      />
    )
  }

  const renderConfirmDeleteDialog = () => {
    return (
      <ConfirmDialog
        title={confirm?.title}
        open={confirm?.show}
        setOpen={() => setConfirm({ show: false })}
        onConfirm={() => confirmDelete()}>
        {confirm?.text}
      </ConfirmDialog>
    )
  }

  return (
    <React.Fragment>
      {showChatroomPickModal.show ? renderCreateUpdateChatroomDialog() : null}
      {renderConfirmDeleteDialog()}
      <Box sx={{ display: 'flex', flexDirection: 'row', height: '40px', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            color: appColors.headerTextColor,
            width: '100%',
            padding: ' 4px',
          }}>
          Chat Rooms
        </Box>
        {chatCollapsed ? (
          <Tooltip
            title='show chatrooms'
            placement='bottom'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <StyledItemIconContainer sx={{ mr: '40px' }} onClick={() => toggleChatrooms()} size='large'>
              <ArrowDownIcon />
            </StyledItemIconContainer>
          </Tooltip>
        ) : (
          <Tooltip
            title='hide chatrooms'
            placement='bottom'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <StyledItemIconContainer sx={{ mr: '40px' }} onClick={() => toggleChatrooms()} size='large'>
              <ArrowUpIcon />
            </StyledItemIconContainer>
          </Tooltip>
        )}
        <Tooltip
          title='Add chatroom'
          placement='bottom'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <StyledItemIconContainer onClick={() => handleNewChatroom()} size='large'>
            <AddIcon />
          </StyledItemIconContainer>
        </Tooltip>
      </Box>
      <SearchBar
        autoFocus={true}
        value={searchText}
        onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
        onCancelSearch={() => setSearchText('')}
        style={
          chatCollapsed
            ? {
                visibility: 'hidden',
                height: '0px',
              }
            : searchBarStyle()
        }
      />
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          height: chatCollapsed ? 'calc(calc(100vh - 102px) * 0.00)' : 'calc(calc(100vh - 154px) * 0.35)',
          border: '1px solid #000',
          borderRadius: '4px',
        }}>
        <SimpleStyledList listItems={filteredList} renderItem={renderItem}></SimpleStyledList>
      </Box>
    </React.Fragment>
  )
}

export default ChatRoomControl
