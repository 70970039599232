import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WellSeekersStackedChart = ({ userData, isLoading }) => {
  const { theme, getChartBackColor } = useInnovaTheme()

  const chartOptions = {
    layout: {
      padding: {
        right: 20,
        left: 20,
        top: 20,
        bottom: 20,
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: appColors.headerTextColor,
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: appColors.headerTextColor,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'right',
        labels: {
          boxWidth: 5,
        },
        title: {
          display: true,
          text: userData?.title,
          color: '#fff',
          position: 'top',
          align: 'center',
        },
      },
    },
  }

  const createChartData = () => {
    const chartData = {
      labels: Array.isArray(userData?.barLabels) ? userData.barLabels : [],
      datasets: [],
    }

    if (!Array.isArray(userData?.seriesLabels)) return chartData
    if (userData.seriesLabels.length === 0) return chartData

    for (let i = 0; i < userData.seriesLabels.length; i++) {
      let datasetItem = {}
      datasetItem.label = userData.seriesLabels[i]

      let companyData = []
      for (let j = 0; j < userData.values.length; j++) {
        companyData.push(userData.values[j][i])
      }
      datasetItem.data = companyData
      datasetItem.backgroundColor = userData.colors[i]
      chartData.datasets.push(datasetItem)
    }

    return chartData
  }

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        display: 'flex',
        width: '99.9%',
        height: '100%',
        overflow: 'hidden',
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <Bar type='bar' options={chartOptions} data={createChartData()} />
    </Box>
  )
}

export default WellSeekersStackedChart
