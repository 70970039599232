import React, { useState, useEffect, useRef, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Icon as Iconify } from '@iconify/react'
import { Box } from '@mui/material'
import SearchBar from 'components/common/SearchBar'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'
import { debounce } from 'lodash'
import DropDownPicker from 'components/common/DropDownPicker'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PersonnelUtilizationNamesGrid = ({
  setSelectedPersonnelNames,
  personnelData,
  showHidePane,
  personnelNamesVisible,
  dateRange,
  setDateRange,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [searchText, setSearchText] = useState('')
  const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor, getTextColor } = useInnovaTheme()

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerName: 'Name',
        field: 'name',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Position',
        field: 'position',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Employment',
        field: 'employment',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: false,
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.name)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset dates',
        disabled: false,
        action: () => {
          setDateRange({ dateFrom: null, dateTo: null })
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  function isValidDateTime(dateTime) {
    if (dateTime === null) return false
    const dateObj = new Date(dateTime)
    return dateObj instanceof Date && !isNaN(dateObj)
  }

  const getDateFromAndTo = (dateFilter) => {
    if (dateFilter === 'User Defined') return
    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse('1900-01-01'))

    if (typeof dateFilter === 'string' && dateFilter !== 'All') {
      if (dateFilter === 'Current Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(1)
      }

      if (dateFilter === 'Previous Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 1)
        dateFrom.setDate(1)

        let daysInMonth = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).getDate()
        dateTo = new Date(Date.parse(dateFrom.toISOString()))
        dateTo.setDate(daysInMonth)
        dateTo.setYear(dateFrom.getFullYear())
      }

      if (dateFilter === 'Last 30 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 30)
      }

      if (dateFilter === 'Last 60 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 60)
      }

      if (dateFilter === 'Last 6 Months') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 6)
      }

      if (dateFilter === 'Current Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      }

      if (dateFilter === 'Last Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear() - 1}-01-01T00:00:01`))
        dateTo = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T23:59:59`))
      }
    }

    setDateRange({
      dateTo: dateTo.toISOString().substring(0, 10),
      dateFrom: dateFrom.toISOString().substring(0, 10),
      dropDownText: dateFilter,
    })
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return
    let selectedRows = gridApi.current.getSelectedNodes()
    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (!Array.isArray(selectedRows)) {
      if (_isMounted.current) {
        setSelectedPersonnelNames([])
      }
    }

    let newSelection = []
    for (let i = 0; i < selectedRows.length; i++) {
      let index = filteredNodes.findIndex((node) => node.name === selectedRows[i].data.name)
      if (index < 0) continue

      newSelection.push(selectedRows[i].data.name)
    }

    if (_isMounted.current) {
      setSelectedPersonnelNames(newSelection)
    }
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: personnelNamesVisible ? 'flex' : 'none',
        }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              width: '100%',
              height: '40px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: getWindowBarColor(),
              display: 'flex',
            }}>
            <Box sx={{ flex: 1, marginRight: '8px' }}>
              <DatePicker
                views={['year', 'month', 'day']}
                disableMaskedInput
                value={dateRange.dateFrom}
                onChange={(date) => {
                  if (!isValidDateTime(date)) {
                    setDateRange({ dateFrom: '', dateTo: dateRange.dateTo })
                    return
                  }

                  setDateRange({
                    dateFrom: date.toISOString(),
                    dateTo: dateRange.dateTo,
                    dropDownText: 'User Defined',
                  })
                }}
                renderInput={(params) => (
                  <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
                )}
              />
            </Box>
            <Box sx={{ flex: 1, marginRight: '8px' }}>
              <DatePicker
                views={['year', 'month', 'day']}
                disableMaskedInput
                value={dateRange.dateTo}
                onChange={(date) => {
                  if (!isValidDateTime(date)) {
                    setDateRange({ dateFrom: dateRange.dateFrom, dateTo: '', dropDownText: 'User Defined' })
                    return
                  }

                  setDateRange({
                    dateTo: date.toISOString(),
                    dateFrom: dateRange.dateFrom,
                    dropDownText: 'User Defined',
                  })
                }}
                renderInput={(params) => (
                  <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
                )}
                sx={{ width: '150px' }}
              />
            </Box>
          </Box>
        </LocalizationProvider>
        <Box
          sx={{
            width: '100%',
            height: '40px',
            paddingTop: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
          }}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle(3)}
          />
          <Box sx={{ flex: 1, marginLeft: '10px', marginRight: '10px' }}>
            <DropDownPicker
              listOptions={[
                { label: 'All', value: 'All' },
                { label: 'Current Month', value: 'Current Month' },
                { label: 'Previous Month', value: 'Previous Month' },
                { label: 'Last 30 Days', value: 'Last 30 Days' },
                { label: 'Last 60 Days', value: 'Last 60 Days' },
                { label: 'Last 6 Months', value: 'Last 6 Months' },
                { label: 'Current Year', value: 'Current Year' },
                { label: 'Last Year', value: 'Last Year' },
                { label: 'User Defined', value: 'User Defined' },
              ]}
              value={dateRange.dropDownText}
              onChange={(newValue) => {
                getDateFromAndTo(newValue)
              }}
              height={'50px'}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: personnelNamesVisible ? 'flex' : 'none',
          width: '100%',
          height: 'calc(100% - 125px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={personnelData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            groupDisplayType={'groupRows'}
            onGridReady={onGridReady}
            onFilterChanged={onFilterChanged}
            onSelectionChanged={onFilterChanged}
            gridOptions={gridOptions}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
          />
        </div>
      </Box>
      {!personnelNamesVisible ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Personnel Names
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default PersonnelUtilizationNamesGrid
