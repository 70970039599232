import React, { useEffect, useState } from 'react'
import { Container, Box } from '@mui/material'
import { styled } from '@mui/styles'
import { mdiBarrel } from '@mdi/js'
import { Icon } from '@mdi/react'

import { GetOilPrice } from 'components/common/apiCalls'

const StyledSubTitle = styled(Box)({
  fontSize: '14px',
  marginTop: '3px',
  marginLeft: '10px',
})

const StyledTitle = styled(Box)({
  display: 'flex',
  fontSize: '14px',
  marginTop: '3px',
  fontWeight: 'bold',
  justifyContent: 'right',
})

const StyledColContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
})

const OilPriceCard = () => {
  const [oilPrices, setOilPrices] = useState({})

  const getOilPrices = async () => {
    let brent = await GetOilPrice('BRENT_CRUDE_USD')
    let wti = await GetOilPrice('WTI_USD')
    if (brent && wti && brent.data && wti.data) {
      let brentDate = new Date(brent.data?.created_at)
      let wtiDate = new Date(wti.data?.created_at)
      brent.data.localTime = brentDate?.toLocaleTimeString()
      wti.data.localTime = wtiDate?.toLocaleTimeString()
      setOilPrices({ brent, wti })
    }
  }

  useEffect(() => {
    getOilPrices()
  }, [])

  return (
    <React.Fragment>
      <Container
        sx={{
          '&': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingVertical: '16px',
            paddingBottom: '8px',
            borderTop: '1px solid gray',
            borderBottom: '1px solid gray',
          },
        }}>
        <Box sx={{ font: '1em roboto', fontWeight: 'bold', py: '8px' }}>Oil Price</Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Icon path={mdiBarrel} size={1} color='black' style={{ marginRight: '8px' }}></Icon>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'right' }}>
              <StyledTitle>Brent:</StyledTitle>
              <StyledTitle>WTI:</StyledTitle>
            </Box>
            <StyledColContainer>
              <StyledSubTitle>{oilPrices?.brent?.data?.formatted}</StyledSubTitle>
              <StyledSubTitle>{oilPrices?.wti?.data?.formatted}</StyledSubTitle>
            </StyledColContainer>

            <StyledColContainer>
              <StyledSubTitle>{oilPrices?.brent?.data?.localTime}</StyledSubTitle>
              <StyledSubTitle>{oilPrices?.wti?.data?.localTime}</StyledSubTitle>
            </StyledColContainer>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default OilPriceCard
