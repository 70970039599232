function deltaDistToLatLong(origin, deltaNs, deltaEw, units) {
  let newCoords = { lat: -999.25, lng: -999.25 }
  if (!deltaEw) deltaEw = 0
  if (!deltaNs) deltaNs = 0
  if (!origin) return newCoords
  if (!origin.hasOwnProperty('lat')) return newCoords
  if (!origin.hasOwnProperty('lng')) return newCoords
  if (!units) units = 'usft'
  let toMetersConv = 1.0
  if (units.toLowerCase() === 'usft' || units.toLowerCase() === 'ft') toMetersConv = 0.3048

  deltaNs *= toMetersConv
  deltaEw *= toMetersConv

  let earthRadiusKM = 6378.137
  let meterPerDegree = 1.0 / (((2.0 * Math.PI) / 360.0) * earthRadiusKM) / 1000.0 //1 meter in degree

  let newLatitude = origin.lat + deltaNs * meterPerDegree
  let newLongitude = origin.lng + (deltaEw * meterPerDegree) / Math.cos(origin.lat * (Math.PI / 180.0))

  return { lat: newLatitude, lng: newLongitude }
}

export function convertTgtToLatLng(origin, tgt, units) {
  if (!tgt) return []
  if (!tgt.hasOwnProperty('polygonPoints')) return []
  if (!Array.isArray(tgt.polygonPoints)) return []
  if (tgt.polygonPoints.length <= 1) return []
  if (!origin) return []
  if (!origin.hasOwnProperty('lat')) return []
  if (!origin.hasOwnProperty('lng')) return []
  if (!units) units = 'usft'

  let output = [
    deltaDistToLatLong(
      { lat: origin.lat, lng: origin.lng },
      tgt.polygonPoints[0].top.localNorth,
      tgt.polygonPoints[0].top.localEast,
      units,
    ),
  ]

  for (let i = 1; i < tgt.polygonPoints.length; i++) {
    let deltaNs = tgt.polygonPoints[i].top.localNorth - tgt.polygonPoints[i - 1].top.localNorth
    let deltaEw = tgt.polygonPoints[i].top.localEast - tgt.polygonPoints[i - 1].top.localEast
    output.push(deltaDistToLatLong(output[i - 1], deltaNs, deltaEw, units))
  }

  output.push(output[0])
  return output
}

export const decToSexa = (decVal, coordType) => {
  const positiveVal = Math.abs(decVal)
  let degrees = Math.trunc(positiveVal)
  let minutes = Math.trunc((positiveVal - degrees) * 60)
  let seconds = positiveVal * 3600.0 - minutes * 60.0 - degrees * 3600.0

  if (Math.abs(60.0 - seconds) < 0.0001) {
    seconds = 0
    minutes++
  }

  if (Math.abs(60.0 - minutes) < 0.0001) {
    minutes = 0
    degrees++
  }

  let dms = `${degrees.toFixed(0)}° ${minutes.toFixed(0)}' ${seconds.toFixed(3)}"`

  if (coordType === 'lat') {
    if (decVal < 0.0) {
      dms += 'S'
    } else {
      dms += 'N'
    }
  } else {
    if (decVal < 0.0) {
      dms += 'W'
    } else {
      dms += 'E'
    }
  }

  return dms
}
