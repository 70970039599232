function getMsgTypeStr(msgTypeEnum) {
  if (typeof msgTypeEnum !== 'number') return 'unknown'

  switch (msgTypeEnum) {
    case 0:
      return 'chatMessage'
    case 1:
      return 'usersOnlineList'
    case 2:
      return 'usersDisconnected'
    case 3:
      return 'usersConnected'
    case 4:
      return 'messageRead'
    default:
      return 'unknown'
  }
}


export function parseMsgStrToJson(socketData) {
  if (typeof socketData !== 'string') return null
  let message = JSON.parse(socketData)
  if (!message.hasOwnProperty('messageType')) return null
  if (typeof message.message !== 'string') return null
  if (message.message === '') return null
  message.messageTypeStr = getMsgTypeStr(message.messageType)
  return message
}

export function capitalizeUserNames(inputUser) {
  if (typeof inputUser !== 'string') return ''
  let userNamesSplit = inputUser.split('.')
  if (!Array.isArray(userNamesSplit)) return ''
  if (userNamesSplit.length === 1) userNamesSplit = inputUser.split('-')

  if (userNamesSplit.length > 0) {
    inputUser = ''

    for (let i = 0; i < userNamesSplit.length; i++) {
      if (typeof userNamesSplit[i] !== 'string') continue
      if (userNamesSplit[i].length === 0) continue

      userNamesSplit[i] = userNamesSplit[i][0].toUpperCase() + userNamesSplit[i].substring(1)
      inputUser += userNamesSplit[i]

      if (i < userNamesSplit.length - 1) inputUser += ' '
    }
  }

  return inputUser
}

export function removeEmailAddress(inputStr) {
  if (typeof inputStr !== 'string') return ''
  const membersArray = inputStr.split('@')
  if (membersArray.length > 0) {
    return membersArray[0]
  }
  return ''
}

export function getUserNameFromEmail(inputStr) {
  if (typeof inputStr !== 'string') return ''
  let userNameNoEmail = removeEmailAddress(inputStr)
  let userName = capitalizeUserNames(userNameNoEmail)
  return userName
}

export function getUserNameInitials(inputStr) {
  if (typeof inputStr !== 'string') return ''
  if (inputStr === '') return ''

  let userName = getUserNameFromEmail(inputStr)

  let userNamesSplit = userName.split(' ')
  let userInitials = ''
  if (userNamesSplit.length > 1) {
    userInitials += userNamesSplit[0][0]
    userInitials += userNamesSplit[userNamesSplit.length - 1][0]
  }

  // if userName doesn't follow typical format, extract the first 2 letters
  if (userInitials === '') {
    userInitials = userName.slice(0, 2)
  }

  return userInitials
}

export function innovaMsg2GiftedChatMsg(innovaMsg) {
  if (typeof innovaMsg !== 'object') return null
  if (!innovaMsg.hasOwnProperty('username')) return null

  let giftedChatMsg = {
    _id: innovaMsg.messageId,
    text: innovaMsg.message,
    createdAt: new Date(Date.parse(innovaMsg.timeStamp)),
    user: {
      _id: innovaMsg.username,
      name: getUserNameFromEmail(innovaMsg.username),
    },
  }

  return giftedChatMsg
}

export function giftedChatMsg2InnovaMsg(giftedChatMsg, chatRoomId, org) {
  if (typeof giftedChatMsg !== 'object') return null
  if (!giftedChatMsg.hasOwnProperty('user')) return null

  let innovaMsg = {
    messageId: '', //This is added by the server
    roomId: chatRoomId,
    organization: org,
    username: giftedChatMsg.user._id,
    message: giftedChatMsg.text,
    timeStamp: '', //This is added by the server
    messageType: 0,
  }

  return innovaMsg
}

export function createMessageReadMsg(msg, org) {
  if (typeof msg !== 'object') return null
  if (!msg.hasOwnProperty('_id')) return null

  let msgRead = {
    messageId: '', //This is added by the server
    roomId: null,
    organization: org,
    username: msg.user._id,
    message: msg._id,
    timeStamp: '', //This is added by the server
    messageType: 4,
  }

  return msgRead
}

export function createMessageIdString(messages) {
  if (!Array.isArray(messages)) return ''
  if (messages.length === 0) return ''
  let messageIdStr = ''
  messages.forEach((msg, index) => {
    if (msg.hasOwnProperty('messageId')) {
      messageIdStr += msg.messageId

      if (index < messages.length - 1) messageIdStr += '|'
    }
  })

  return messageIdStr
}
