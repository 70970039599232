import React, { useEffect, useRef, useState, useCallback } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import SplitPane from 'components/common/SplitPane'
import { Box, CircularProgress, Tooltip, Alert, Snackbar } from '@mui/material'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import ActualWellGrid from './ActualWellGrid'
import VerticalSectionGrid from 'components/DatabaseTree/PlannedWell/PlannedWellPropertiesPage/VerticalSectionGrid'
import { isEqual } from 'lodash'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { dateBaseTreeUpdateNameAtom, actualWellPropertiesLayoutAtom, refreshWellList } from 'atoms'
import DepthReferenceGrid from 'components/DatabaseTree/PlannedWell/PlannedWellPropertiesPage/DepthReferenceGrid'
import ConfirmDialog from 'components/common/ConfirmDialog'
import MagneticsGrid from 'components/DatabaseTree/PlannedWell/PlannedWellPropertiesPage/MagneticsGrid'

const ActualWellPropertiesPage = ({ actualWell, nodeId }) => {
  const _isMounted = useRef(false)
  const [isUpdating, setUpdating] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [actualWellData, setActualWellData] = useState(null)
  const actualWelldataRef = useRef(null)
  const [dataChanged, setDataChanged] = useState(false)
  const isUpdatingRef = useRef(false)
  const orgDataRef = useRef(actualWellData)
  const tvdAdjustOptionsRef = useRef(null)
  const [pageLayout, setPageLayout] = useRecoilState(actualWellPropertiesLayoutAtom)
  const [databaseTreeUpdateName, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const facilityWellOffsetRef = useRef({ localNorth: 0, localEast: 0, localCoordRef: 'WELL' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const refreshWells = useSetRecoilState(refreshWellList)

  const getActualWell = useInnovaAxios({
    url: '/well/getActualWells',
  })

  const updateActualWell = useInnovaAxios({
    url: '/well/updateActualWell',
  })

  const getActualFacilityOffset = useInnovaAxios({
    url: '/coordinateConv/getWellFacilityOffset',
  })

  const adjustDatumElevation = useInnovaAxios({
    url: '/well/adjustDatumElevation',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchWellFacilityOffset()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTvdChange = useCallback(async () => {
    if (isUpdatingRef.current) return
    if (!tvdAdjustOptionsRef.current) return
    setLoading(true)

    isUpdatingRef.current = true
    const res = await adjustDatumElevation(tvdAdjustOptionsRef.current)
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }
  }, [adjustDatumElevation, setStatus])

  const fetchWellFacilityOffset = async () => {
    const res = await getActualFacilityOffset({ wellName: actualWell, isPlan: false })
    if (!_isMounted.current) return

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!res.data) return
    facilityWellOffsetRef.current = res.data
  }

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!actualWellData) return
    if (data.caller === 'UI') return
    if (data.nodeId !== nodeId) return

    let newData = cloneDeep(actualWellData)
    newData.well = data.name
    orgDataRef.current.well = data.name
    actualWelldataRef.current.well = data.name
    setActualWellData(newData)
    setDataBaseTreeNameUpdate(null)
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) {
      if (index === 0) return '20%'
      if (index === 1) return '50%'

      return '35%'
    }

    if (index >= pageLayout.length) {
      if (index === 0) return '20%'
      if (index === 1) return '50%'

      return '35%'
    }
    return pageLayout[index].size
  }
  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getActualWell({ actualWell: actualWell })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    //Flatten Data
    let actualData = {
      wellName: res.data[0].wellName,
      actualWellName: res.data[0].actualWellName,
      description: res.data[0].description,
      locked: res.data[0].locked,
      datumName: res.data[0].depthReference.Name,
      datumElevation: res.data[0].depthReference.Elevation,
      VsAzi: res.data[0].verticalSection.VsAzi,
      VsOrgNorth: res.data[0].verticalSection.VsOrgNorth,
      VsOrgEast: res.data[0].verticalSection.VsOrgEast,
      VsOrigin: res.data[0].verticalSection.VsOrigin,
      sideTrackParentWell: res.data[0].sidetrackData.parentWell,
      sideTrackDepth: res.data[0].sidetrackData.Depth,
      sideTrackErrorsStart: res.data[0].sidetrackData.sideTrackErrorsStart,
      isSideTrack: res.data[0].sidetrackData.isSideTrack,
      orgActualWellName: res.data[0].actualWellName,
      bhalNs: res.data[0].bottomHoleLocation?.ns,
      bhalEw: res.data[0].bottomHoleLocation?.ew,
      vsAziUseBhl: res.data[0].verticalSection.UseBhl,
      wellStatus: res.data[0].WellStatus,
    }

    actualWelldataRef.current = cloneDeep(actualData)
    orgDataRef.current = cloneDeep(actualData)
    setActualWellData(actualData)
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    let res = await updateActualWell(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setUpdating(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (orgDataRef.current.wellStatus !== data.wellStatus) {
      //Refresh main wells list
      refreshWells(Date.now().toString())
    }

    if (orgDataRef.current.actualWell !== data.actualWell) {
      setDataBaseTreeNameUpdate({ name: data.well, nodeId: nodeId, caller: 'UI' })
    }

    if (data.datumElevation !== orgDataRef.current.datumElevation) {
      tvdAdjustOptionsRef.current = {
        actualWell: data.actualWellName,
        newElevation: data.datumElevation,
        deltaElevation: data.datumElevation - orgDataRef.current.datumElevation,
        well: data.wellName,
        preserveTvds: true,
        newDatumName: data.datumName,
      }

      setConfirm({
        show: true,
        title: 'TVD Adjustments',
        text: `Do you wish to preserve the TVD's for the wells using this datum?`,
      })
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
  }

  const updateActualWellDepthRef = (data) => {
    if (!data) return

    let newData = cloneDeep(actualWelldataRef.current)
    newData.datumName = data.datumName
    newData.datumElevation = data.datumElevation

    setDataChanged(!isEqual(newData, orgDataRef.current))
    actualWelldataRef.current = cloneDeep(newData)
    setActualWellData(newData)
  }

  const updateActualWellData = (data) => {
    if (!data) return

    let newData = cloneDeep(actualWelldataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    if (newData.VsOrigin === 'Well') {
      newData.VsOrgNorth =
        facilityWellOffsetRef.current?.localCoordRef === 'WELL' ? 0 : facilityWellOffsetRef.current?.localNorth
      newData.VsOrgEast =
        facilityWellOffsetRef.current?.localCoordRef === 'WELL' ? 0 : facilityWellOffsetRef.current?.localEast
    }

    if (newData.VsOrigin === 'Facility') {
      newData.VsOrgNorth =
        facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localNorth
      newData.VsOrgEast =
        facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localEast
    }

    if (newData.vsAziUseBhl) {
      let bhlNs = actualWelldataRef.current.bhalNs
      let bhlEw = actualWelldataRef.current.bhalEw

      bhlNs += facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localNorth
      bhlEw += facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localEast

      let deltaNs = bhlNs - newData.VsOrgNorth
      let deltaEw = bhlEw - newData.VsOrgEast
      let vsAzi = Math.atan2(deltaEw, deltaNs) * (180 / Math.PI)
      if (vsAzi < 0) vsAzi += 360
      if (vsAzi > 360) vsAzi -= 360
      newData.VsAzi = vsAzi
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    actualWelldataRef.current = cloneDeep(newData)
    setActualWellData(newData)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => handleUpdate(actualWelldataRef.current)}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          actualWelldataRef.current = cloneDeep(orgDataRef.current)
          setDataChanged(false)
          setActualWellData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onNo={() => {
            tvdAdjustOptionsRef.current.preserveTvds = false
            handleTvdChange()
          }}
          onConfirm={() => {
            tvdAdjustOptionsRef.current.preserveTvds = true
            handleTvdChange()
          }}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(0)}
        allowResize={true}
        defaultSize={'20%'}
        minSize={'10%'}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SplitPane
          split='vertical'
          size={getInitialPaneSize(2)}
          allowResize={true}
          defaultSize={'50%'}
          onDragFinished={(params) => onDragFinished(params, 2)}>
          <ActualWellGrid actualWellData={actualWellData} handleUpdate={updateActualWellData} />
          <VerticalSectionGrid wellData={actualWellData} handleUpdate={updateActualWellData} />
        </SplitPane>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(2)}
          allowResize={true}
          defaultSize={'35%'}
          minSize={'10%'}
          onDragFinished={(params) => onDragFinished(params, 2)}
          style={{
            height: '100%',
            width: `100%`,
            maxWidth: `100%`,
          }}>
          <DepthReferenceGrid wellData={actualWellData} handleUpdate={updateActualWellDepthRef} />
          <MagneticsGrid wellName={actualWellData?.actualWellName} isPlan={false} />
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ActualWellPropertiesPage
