import Wellbore from "./Wellbore"

const OffsetWellbores = ({ offsetData, display }) => {
  if (!display) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null

  let tubeSegments = 16
  if (offsetData.length > 20) {
    tubeSegments = 8
  }

  return offsetData.map((osWell, index) => (
    <Wellbore segments={tubeSegments} wellData={osWell} key={`${osWell.name}wellbore${index}`} />
  ))
}

export default OffsetWellbores