const cellStyle = ({
    fgColor = null, // null means default to transparent
    horzAlign = 'center',
    vertAlign = 'center',
    bold = false,
    top = '',
    right = '',
    bottom = '',
    left = '',
    textOrientation = 0,
    wrap = false,
}) => {
    let style = {
        alignment: {
            horizontal: horzAlign,
            vertical: vertAlign,
            textRotation: textOrientation,
            wrapText: wrap,
        },
        bold: bold,
        top: { style: top },
        right: { style: right },
        bottom: { style: bottom },
        left: { style: left },
    }

    // if no fgColor, cell is transparent
    if (fgColor !== null) {
        style.fgColor = { rgb: fgColor }
    }
    return style
}

export const emptyCells = (size) => {
    return Array(size).fill(undefined)
}

export const mergeCells = ({ row, col, rowEnd = row, colEnd = col, style }) => {
    return {
        s: { r: row, c: col },
        e: { r: rowEnd, c: colEnd },
        style: style,
    }
}

export const getStyles = (filledCellColor) => {
    return {
        right: cellStyle({ horzAlign: 'right' }),
        rightWrap: cellStyle({ horzAlign: 'right', wrap: true }),
        righttTTt: cellStyle({ horzAlign: 'right', top: 'thin', right: 'thick', bottom: 'thick', left: 'thin' }),
        righttttt: cellStyle({ horzAlign: 'right', top: 'thin', right: 'thin', bottom: 'thin', left: 'thin' }),
        rightttTT: cellStyle({ horzAlign: 'right', top: 'thin', right: 'thin', bottom: 'thick', left: 'thick' }),
        rightBoldtttt: cellStyle({
            horzAlign: 'right',
            bold: true,
            top: 'thin',
            right: 'thin',
            bottom: 'thin',
            left: 'thin',
        }),
        rightBoldTTTT: cellStyle({
            horzAlign: 'right',
            bold: true,
            top: 'thick',
            right: 'thick',
            bottom: 'thick',
            left: 'thick',
        }),
        left: cellStyle({ horzAlign: 'left' }),
        leftttt: cellStyle({ horzAlign: 'left', top: 'thin', right: 'thin', bottom: 'thin', left: 'thin' }),
        leftBold: cellStyle({ horzAlign: 'left', bold: true }),
        centerTTtT: cellStyle({ top: 'thick', right: 'thick', bottom: 'thin', left: 'thick' }),
        centertTtT: cellStyle({ top: 'thin', right: 'thick', bottom: 'thin', left: 'thick' }),
        centertTTT: cellStyle({ top: 'thin', right: 'thick', bottom: 'thick', left: 'thick' }),
        centertttt: cellStyle({ top: 'thin', right: 'thin', bottom: 'thin', left: 'thin' }),
        centerTTTT: cellStyle({ top: 'thick', right: 'thick', bottom: 'thick', left: 'thick' }),
        centerBoldtttt: cellStyle({ bold: true, top: 'thin', right: 'thin', bottom: 'thin', left: 'thin' }),
        centerBoldWraptttt: cellStyle({ bold: true, top: 'thin', right: 'thin', bottom: 'thin', left: 'thin', wrap: true }),
        centerVerttttt: cellStyle({ top: 'thin', right: 'thin', bottom: 'thin', left: 'thin', textOrientation: 90 }),
        fillCenterBoldtTtT: cellStyle({
            fgColor: filledCellColor,
            bold: true,
            top: 'thin',
            right: 'thick',
            bottom: 'thin',
            left: 'thick',
        }),
        fillCenterBoldtTTT: cellStyle({
            fgColor: filledCellColor,
            bold: true,
            top: 'thin',
            right: 'thick',
            bottom: 'thick',
            left: 'thick',
        }),
        fillCenterBoldTTTT: cellStyle({
            fgColor: filledCellColor,
            bold: true,
            top: 'thick',
            right: 'thick',
            bottom: 'thick',
            left: 'thick',
        }),
        fillCentertttt: cellStyle({
            fgColor: filledCellColor,
            top: 'thin',
            right: 'thin',
            bottom: 'thin',
            left: 'thin',
        }),
        fillCenterBoldtttt: cellStyle({
            fgColor: filledCellColor,
            bold: true,
            top: 'thin',
            right: 'thin',
            bottom: 'thin',
            left: 'thin',
        }),
        fillRightBoldTTtT: cellStyle({
            fgColor: filledCellColor,
            horzAlign: 'right',
            bold: true,
            top: 'thick',
            right: 'thick',
            bottom: 'thin',
            left: 'thick',
        }),
        fillRightBoldtTtT: cellStyle({
            fgColor: filledCellColor,
            horzAlign: 'right',
            bold: true,
            top: 'thin',
            right: 'thick',
            bottom: 'thin',
            left: 'thick',
        }),
        fillRightBoldtTTT: cellStyle({
            fgColor: filledCellColor,
            horzAlign: 'right',
            bold: true,
            top: 'thin',
            right: 'thick',
            bottom: 'thick',
            left: 'thick',
        }),
        fillRightttTT: cellStyle({
            fgColor: filledCellColor,
            horzAlign: 'right',
            top: 'thin',
            right: 'thin',
            bottom: 'thick',
            left: 'thick',
        }),
        fillRightBoldtttt: cellStyle({
            fgColor: filledCellColor,
            horzAlign: 'right',
            bold: true,
            top: 'thin',
            right: 'thin',
            bottom: 'thin',
            left: 'thin',
        }),
    }
}

export const getLastCol = (startCol, colDef) => {
    let lastCol = startCol
    for (let i = 0; i < colDef.length; i++) {
        lastCol += colDef[i].colSpan
    }

    lastCol--
    return lastCol
}

export const makeTableHeaderData = (colDef) => {
    if (!Array.isArray(colDef)) return []
    let cols = []
    for (let i = 0; i < colDef.length; i++) {
        cols.push(colDef[i].label)
        if (colDef[i].colSpan > 1) {
            cols.push(...emptyCells(colDef[i].colSpan - 1))
        }
    }

    return [cols]
}

export const makeMergedCells = (colDef, startRow, startCol, numRows) => {
    if (!Array.isArray(colDef)) return []
    let mergedCells = []

    for (let i = 0; i < numRows; i++) {
        let curCol = startCol
        for (let j = 0; j < colDef.length; j++) {
            mergedCells.push(mergeCells({
                row: startRow + i,
                col: curCol,
                colEnd: curCol + colDef[j].colSpan - 1,
                style: colDef[j].headerStyle,
            }))

            curCol += colDef[j].colSpan
        }
    }

    return mergedCells
}

export const generateXlsColumnWidth = (ws, colNum, chWidth) => {
    if (!ws) return
    return (ws['!cols'][colNum] = { wch: chWidth })
}


