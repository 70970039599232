import { chartSeriesColors } from 'utils'

export const curveOperations = [
  { label: '+', value: '+' },
  { label: '-', value: '-' },
  { label: '*', value: '*' },
  { label: '/', value: '/' },
]

export const shadeType = [
  { label: 'Above', value: 'above' },
  { label: 'Below', value: 'below' },
  { label: 'Between Curves', value: 'betweenCurves' },
]

export const plotDefs = [
  {
    curves: [
      {
        uid: 0,
        mnemonic: 'torque',
        label: 'Torque',
        scaleMin: 0,
        scaleMax: 50,
        color: chartSeriesColors[0],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 1,
        mnemonic: 'rop',
        label: 'ROP',
        scaleMin: 0,
        scaleMax: 300,
        color: chartSeriesColors[1],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 2,
        mnemonic: 'flow',
        label: 'Flow Rate',
        scaleMin: 0,
        scaleMax: 1000,
        color: chartSeriesColors[2],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
    ],
  },
  {
    curves: [
      {
        uid: 3,
        mnemonic: 'hkld',
        label: 'Hookload',
        units: 'klb',
        scaleMin: 0,
        scaleMax: 250,
        color: chartSeriesColors[0],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 4,
        mnemonic: 'wob',
        label: 'Weight on Bit',
        scaleMin: 0,
        scaleMax: 50,
        color: chartSeriesColors[1],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 5,
        mnemonic: 'blockPosition',
        label: 'Block Position',
        scaleMin: 0,
        scaleMax: 100,
        color: chartSeriesColors[2],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
    ],
  },
  {
    curves: [
      {
        uid: 6,
        mnemonic: 'pressure',
        label: 'Pump Pressure',
        scaleMin: 0,
        scaleMax: 10000,
        color: chartSeriesColors[0],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 7,
        mnemonic: 'diffPressure',
        label: 'Diff Pressure',
        scaleMin: 0,
        scaleMax: 1000,
        color: chartSeriesColors[1],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 8,
        mnemonic: 'rpm',
        label: 'RPM',
        scaleMin: 0,
        scaleMax: 150,
        color: chartSeriesColors[2],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
    ],
  },
  {
    curves: [
      {
        uid: 9,
        mnemonic: 'temperature',
        label: 'Temperature',
        scaleMin: 0,
        scaleMax: 300,
        color: chartSeriesColors[0],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 10,
        mnemonic: 'contInc',
        label: 'Cont Inc',
        scaleMin: 0,
        scaleMax: 95,
        color: chartSeriesColors[1],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
      {
        uid: 11,
        mnemonic: 'gamma',
        label: 'Gamma',
        scaleMin: 0,
        scaleMax: 250,
        color: chartSeriesColors[2],
        calculated: false,
        shaded: false,
        operations: [{ operationName: '', curve1: 'none', operation: curveOperations[0], curve2: 'none' }],
        shading: [{ shadeType: shadeType[0], shadeValue: 0, shadeColor: chartSeriesColors[0], shadeCurve: 'none' }],
      },
    ],
  },
]
