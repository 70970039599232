import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import { debounce } from 'lodash'
import { styled } from '@mui/styles'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const WitsmlServerPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const isTesting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [witsmlData, setWitsmlData] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedServer = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getWitsmlServers = useInnovaAxios({
    url: '/dataAcq/witsml/getWitsmlServers',
  })

  const deleteWitsmlServer = useInnovaAxios({
    url: '/dataAcq/witsml/deleteWitsmlServer',
  })

  const addWitsmlServer = useInnovaAxios({
    url: '/dataAcq/witsml/addWitsmlServer',
  })

  const updateWitsmlServer = useInnovaAxios({
    url: '/dataAcq/witsml/updateWitsmlServers',
  })

  const testWitsmlConnection = useInnovaAxios({
    url: '/dataAcq/witsml/testConnection',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.witsmlServersKey)
    fetchWitsmlServers()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchWitsmlServers = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getWitsmlServers()

    if (response?.error) return
    if (!_isMounted.current) return

    setWitsmlData(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  let reqFields = useMemo(() => ['serverName', 'witsmlServer', 'witsmlUser', 'witsmlPass', 'witsmlVer'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('witsmlServersGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('witsmlServersGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WitsmlServers.xlsx',
            sheetName: 'Servers',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return

    let nameExists = false
    gridApi.current.forEachNode((node) => {
      if (node.data && node.data.serverName === inputRow.current.serverName) nameExists = true
    })

    if (nameExists) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${inputRow.current.serverName} already exists}`,
      })
      return
    }

    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)
    let res = await addWitsmlServer(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('uid')) return

      if (isUpdating.current) return
      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateWitsmlServer(data)
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [data],
        })
      }
    },
    [updateWitsmlServer],
  )

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) {
      setStatus({
        show: true,
        severity: 'error',
        message: `User does not have permission to delete`,
      })
      return
    }
    selectedServer.current = cloneDeep(data)

    setConfirm({
      show: true,
      title: 'Delete Server',
      text: `Are you sure you want to delete ${data.serverName}?`,
      onConfirm: handleDelete,
    })
  }

  const handleDelete = async () => {
    if (!selectedServer.current) return
    if (!selectedServer.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteWitsmlServer(selectedServer.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedServer.current],
      })
    }

    selectedServer.current = null
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleTestWitsmlConnection = async (data) => {
    if (isTesting.current) return
    if (!data) return

    isTesting.current = true
    let res = await testWitsmlConnection(data)
    isTesting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!res?.data?.data?.success || res?.data?.data?.witsmlVer === '') {
      setStatus({
        show: true,
        severity: 'error',
        message: `Server failed to respond`,
      })

      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Success - server responded v${res?.data?.data?.witsmlVer}`,
    })
  }

  const handleMenuClick = (action, data) => {
    if (typeof action !== 'string') return
    if (!data) return

    if (action === 'test') {
      handleTestWitsmlConnection(data)
    }

    if (action === 'delete') {
      onClickDelete(data)
    }
  }

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          return {
            menuItems: [
              {
                label: 'Test Connection',
                action: 'test',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='solar:server-bold' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Delete',
                action: 'delete',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                  </StyledIconContainer>
                ),
              },
            ],
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'serverName',
        colId: 'serverName',
        headerName: 'Name',
        pinned: 'left',
        lockPosition: 'left',
        valueGetter: (params) => unescapeHtml(params.data?.serverName),
      },
      {
        field: 'witsmlServer',
        colId: 'witsmlServer',
        headerName: 'Server (IP/URL)',
        valueGetter: (params) => unescapeHtml(params.data?.witsmlServer),
      },
      {
        field: 'witsmlUser',
        colId: 'witsmlUser',
        headerName: 'Username',
        valueGetter: (params) => unescapeHtml(params.data?.witsmlUser),
      },
      {
        field: 'witsmlPass',
        colId: 'witsmlPass',
        headerName: 'Password',
        cellStyle: centerAlignCell,
        valueGetter: (params) => unescapeHtml(params.data?.witsmlPass),
        valueFormatter: (params) => {
          if (params?.node?.rowPinned !== 'bottom') return '******'
          if (isEmptyPinnedCell(params)) return 'Password....'
          return params.value
        },
      },
      {
        field: 'witsmlVer',
        colId: 'witsmlVer',
        headerName: 'Version',
        cellEditor: 'agSelectCellEditor',
        cellStyle: centerAlignCell,
        cellEditorParams: (params) => {
          return {
            values: ['1.3.1', '1.4.1'],
          }
        },
      },
    ],
    [centerAlignCell, handleUpdate, handleMenuClick], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={witsmlData}
            columnDefs={sortColDefs(columnDefs, 'witsmlServersGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchWitsmlServers()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default WitsmlServerPage
