import * as THREE from 'three'

import { CatmullRomCurve3, DoubleSide } from 'three'
import React, { useCallback, useRef } from 'react'
import { Ring, Sphere, Text } from '@react-three/drei'
import { fonts } from './fonts'
import { useFrame } from '@react-three/fiber'

const SPHERE_COLOR = 0x800080
const RING_COLOR_1 = 0x0000ff
const RING_COLOR_2 = 0xffffff
const COMPASS_COL = 0x339933
const CLOSEST_APPROACH_COL = 0x339933

function Circle({ color, radius, innerRadius }) {
  return (
    <Ring args={[innerRadius, radius, 32]} rotation={[Math.PI / 2, 0, 0]}>
      <meshBasicMaterial attach='material' color={color} transparent={true} opacity={0.6} side={THREE.DoubleSide} />
    </Ring>
  )
}

function ClosestApproach({ chartData, focusedSurveyIndex, scale, closestApproachSvy }) {
  if (!Array.isArray(chartData?.refData)) return null
  if (chartData.refData.length === 0) return null
  if (!Array.isArray(chartData.refData[0].svyData)) return null
  if (chartData.refData[0].svyData.length === 0) return null

  let surveyIndex = 0
  if (focusedSurveyIndex.current >= 0 && focusedSurveyIndex.current < chartData.refData[0].svyData.length) {
    surveyIndex = focusedSurveyIndex.current
  }

  if (surveyIndex < 0) return null
  if (surveyIndex >= chartData.refData[0].svyData.length) return null


  let svy = chartData.refData[0].svyData[surveyIndex]
  let p1 = new THREE.Vector3(svy.ns * scale, -svy.tvd * scale, svy.ew * scale)
  let p2 = new THREE.Vector3(
    closestApproachSvy.current?.acScan ? closestApproachSvy.current.acScan.offNS : svy.ns,
    closestApproachSvy.current?.acScan ? -closestApproachSvy.current.acScan.offTVD : -svy.tvd,
    closestApproachSvy.current?.acScan ? closestApproachSvy.current.acScan.offEW : svy.ew,
  )

  if (Math.abs(p2.x) === 9999.25 && Math.abs(p2.y) === 9999.25 && Math.abs(p2.z) === 9999.25) return null

  p2.x *= scale
  p2.y *= scale
  p2.z *= scale

  const path = new CatmullRomCurve3([p1, p2])

  return (
    <group>
      <Sphere args={[0.7, 16, 16]} position={p2} key={`closeApproachSphere`}>
        <meshBasicMaterial attach='material' color={CLOSEST_APPROACH_COL} transparent={true} opacity={0.75} />
      </Sphere>

      <mesh visible>
        <tubeGeometry args={[path, 64, 0.2, 16, false]} />
        <meshStandardMaterial color={CLOSEST_APPROACH_COL} side={DoubleSide} />
      </mesh>
    </group>
  )
}

function WorkSightText({ radius }) {
  let maxRad = Math.max(radius, 1)
  let increment = Math.max(maxRad / 5, 1)
  return (
    <group>
      <Text
        key={`N`}
        position-x={maxRad + increment / 2}
        position-y={0}
        position-z={0}
        text={'N'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={COMPASS_COL}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Text
        key={`E`}
        position-x={0}
        position-y={0}
        position-z={maxRad + increment / 2}
        text={'E'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={COMPASS_COL}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Text
        key={`S`}
        position-x={-(maxRad + increment / 2)}
        position-y={0}
        position-z={0}
        text={'S'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={COMPASS_COL}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Text
        key={`W`}
        position-x={0}
        position-y={0}
        position-z={-(maxRad + increment / 2)}
        text={'W'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={COMPASS_COL}
        rotation={[0, -Math.PI / 2, 0]}
      />
    </group>
  )
}

function WorkSightRings({ radius }) {
  let maxRad = Math.max(radius, 1)
  let increment = Math.max(maxRad / 5, 1)
  let curCol = RING_COLOR_1

  let output = []

  for (let i = maxRad; i > 0; i = i - increment) {
    output.push(<Circle color={curCol} radius={i} innerRadius={i - increment} key={`${i}workSightRing`} />)

    if (curCol === RING_COLOR_1) {
      curCol = RING_COLOR_2
    } else {
      curCol = RING_COLOR_1
    }
  }

  return output
}

function CompassRing({ radius }) {
  let maxRad = Math.max(radius, 1)
  let increment = Math.max(maxRad / 5, 1)
  return <Circle color={COMPASS_COL} radius={maxRad + increment / 2} innerRadius={maxRad} />
}

function HighSideLine({ radius }) {
  const ref = useRef()

  const onUpdate = useCallback(
    (self) => {
      let points = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(Math.max(radius, 1), 0, 0)]
      self.setFromPoints(points)
      self.verticesNeedUpdate = true
      self.computeBoundingSphere()
    },
    [radius],
  )

  return (
    <lineSegments ref={ref}>
      <bufferGeometry attach='geometry' onUpdate={onUpdate} />
      <lineBasicMaterial attach='material' color={0xff0000} linewidth={20} />
    </lineSegments>
  )
}

export function rotateAroundWorldAxis(object, axis, rotationVec) {
  let rotAxis = new THREE.Vector3(axis === 'x' ? 1 : 0, axis === 'y' ? 1 : 0, axis === 'z' ? 1 : 0)
  let radians = 0
  if (axis === 'x') radians = rotationVec.x
  if (axis === 'y') radians = rotationVec.y
  if (axis === 'z') radians = rotationVec.z

  let rotWorldMatrix = new THREE.Matrix4()
  rotWorldMatrix.makeRotationAxis(rotAxis.normalize(), radians)
  rotWorldMatrix.multiply(object.matrix)
  object.matrix = rotWorldMatrix
  object.rotation.setFromRotationMatrix(object.matrix)
  object.updateMatrix()
}

export function resetObjectRotation(object) {
  object.matrix = new THREE.Matrix4()
  object.rotation.setFromRotationMatrix(object.matrix)
  object.updateMatrix()
}

const WorkSight = ({ radius, chartData, offsetsOn, scale, focusedSurveyIndex, closestApproachSvy }) => {
  const ref = useRef()
  const compassRef = useRef()
  const workSightRef = useRef()

  const moveWorkSight = () => {
    if (!Array.isArray(chartData?.refData)) return
    if (chartData.refData.length === 0) return
    if (!Array.isArray(chartData.refData[0].data)) return
    if (chartData.refData[0].data.length === 0) return

    let surveyIndex = 0
    if (focusedSurveyIndex.current >= 0 && focusedSurveyIndex.current < chartData.refData[0].data.length) {
      surveyIndex = focusedSurveyIndex.current
    }

    let newPosition = new THREE.Vector3(
      chartData?.refData[0].data[surveyIndex].x,
      chartData?.refData[0].data[surveyIndex].y,
      chartData?.refData[0].data[surveyIndex].z,
    )

    workSightRef.current.position.set(newPosition.x, newPosition.y, newPosition.z)
    compassRef.current.position.set(newPosition.x, newPosition.y, newPosition.z)

    let incRad = chartData?.refData[0].svyData[surveyIndex].inc * (Math.PI / 180)
    let aziRad = chartData?.refData[0].svyData[surveyIndex].azi * (Math.PI / 180)

    let newRot = new THREE.Vector3(0, Math.PI * 2.0 - aziRad, incRad)
    let rotOrder = ['z', 'y']

    workSightRef.current.rotation.set(0, 0, 0)
    workSightRef.current.updateMatrix()
    rotOrder.forEach((ax) => rotateAroundWorldAxis(workSightRef.current, ax, newRot))
  }

  useFrame(() => {
    moveWorkSight()
  })

  return (
    <group ref={ref} position={[0, 0, 0]}>
      <group ref={workSightRef}>
        <Sphere args={[Math.max((radius * scale) / 100, 0.8), 16, 16]}>
          <meshBasicMaterial color={SPHERE_COLOR} transparent={true} opacity={0.75} />
        </Sphere>
        <WorkSightRings radius={radius * scale} />
        <HighSideLine radius={radius * scale} />
      </group>
      <group ref={compassRef}>
        <CompassRing radius={radius * scale} />
        <WorkSightText radius={radius * scale} />
      </group>
      <group>
        <ClosestApproach
          chartData={chartData}
          focusedSurveyIndex={focusedSurveyIndex}
          closestApproachSvy={closestApproachSvy}
          scale={scale}
        />
      </group>
    </group>
  )
}

export default WorkSight
