import React, { useEffect, useRef, useMemo } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId, CustomHeader } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ConfirmTargetLineModal = ({ open, onClose, targetLineData, handleAcceptTargetLine }) => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  let columnDefs = [
    {
      field: 'name',
      colId: 'name',
      headerName: 'Name',
    },
    {
      field: 'tvd_vs.val',
      colId: 'tvd_vs',
      headerName: 'TVD @ 0 VS',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'inclination.val',
      colId: 'inclination',
      headerName: 'Dip',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'origin_tvd.val',
      colId: 'origin_tvd',
      headerName: 'Tvd Origin',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'origin_vs.val',
      colId: 'origin_vs',
      headerName: 'VS Origin',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'target_tvd.val',
      colId: 'target_tvd',
      headerName: 'TVD Target',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'target_vs.val',
      colId: 'target_vs',
      headerName: 'VS Target',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'tvd_up',
      colId: 'tvd_up',
      headerName: 'TVD Limit Up',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
    {
      field: 'tvd_down',
      colId: 'tvd_down',
      headerName: 'TVD Limit Down',
      valueFormatter: (params) => {
        return numberWithCommasDecimals(params.value, 3)
      },
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const gridOptions = {
    onCellEditingStopped: (event) => {},
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  return (
    <Dialog
      maxWidth='xl'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '70vw',
          height: '60vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>New Target Line</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ height: '100px', width: '100%', padding: '5px' }}>
            <div className={getAgGridTheme()} style={{ width: '100%', height: '110px', paddingTop: '5px' }}>
              <AgGridReact
                rowData={targetLineData ? [targetLineData] : []}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                getRowId={getRowId}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                components={components}
              />
            </div>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          type='button'
          color='primary'
          onClick={() => {
            onClose()
            handleAcceptTargetLine(false)
          }}>
          Decline
        </Button>
        <Button
          variant='contained'
          type='button'
          color='primary'
          onClick={() => {
            onClose()
            handleAcceptTargetLine(true, targetLineData)
          }}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmTargetLineModal
