import React, { useEffect, useRef, useState } from 'react'
import { Icon as Iconify } from '@iconify/react'
import { Box } from '@mui/material'
import RealTimeAc from './RealTimeAc'
import MultiNudge from './MultiNudge'
import GeosteeringPage from 'components/Geosteering/GeosteeringPage'
import { checkFeature } from 'components/userPermissions'
import { useRecoilValue } from 'recoil'
import { userRoleSelector } from 'atoms'
import SoloCloudPage from 'components/WellPages/SurveyPage/SoloCloud/SoloCloudPage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const ToolsPane = ({
  showHidePane,
  paneVisible,
  wellName,
  principalPlanName,
  surveys,
  wellPlan,
  vsParams,
  projections,
  setProjections,
  targets,
  criticalPoints,
  getGeoSteeringData,
}) => {
  const _isMounted = useRef(false)
  const [selectedTool, setSelectedTool] = useState('rtAc')
  const userRole = useRecoilValue(userRoleSelector)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!paneVisible) {
      setProjections([])
    }
  }, [paneVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          width: '25px',
          height: '100%',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: paneVisible && selectedTool === 'rtAc' ? 'bold' : '400',
            color: paneVisible && selectedTool === 'rtAc' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!paneVisible) showHidePane()
            if (paneVisible && selectedTool === 'rtAc') {
              showHidePane()
              return
            }
            setSelectedTool('rtAc')
          }}>
          Real Time AC
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: paneVisible && selectedTool === 'multiNudge' ? 'bold' : '400',
            color: paneVisible && selectedTool === 'multiNudge' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!paneVisible) showHidePane()
            if (paneVisible && selectedTool === 'multiNudge') {
              showHidePane()
              return
            }
            setSelectedTool('multiNudge')
          }}>
          Multi Nudge
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: paneVisible && selectedTool === 'geoSteering' ? 'bold' : '400',
            color: paneVisible && selectedTool === 'geoSteering' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!paneVisible) showHidePane()
            if (paneVisible && selectedTool === 'geoSteering') {
              showHidePane()
              return
            }
            setSelectedTool('geoSteering')
          }}>
          GeoSteering
        </Box>
        {checkFeature(13, userRole?.roleAttributes?.featureId) ? (
          <Box
            sx={{
              ...verticalTextStyle,
              fontWeight: paneVisible && selectedTool === 'soloCloud' ? 'bold' : '400',
              color: paneVisible && selectedTool === 'soloCloud' ? '#429ceb' : '#f0f0f0',
            }}
            onClick={() => {
              if (!paneVisible) showHidePane()
              if (paneVisible && selectedTool === 'soloCloud') {
                showHidePane()
                return
              }
              setSelectedTool('soloCloud')
            }}>
            Solo Cloud
          </Box>
        ) : null}
      </Box>

      {paneVisible ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 25px)',
            height: '100%',
          }}>
          {selectedTool === 'rtAc' ? (
            <RealTimeAc
              leaseLines={targets}
              projections={projections}
              wellName={wellName}
              principalPlanName={principalPlanName}
            />
          ) : null}
          {selectedTool === 'multiNudge' ? (
            <MultiNudge
              criticalPoints={criticalPoints}
              vsParams={vsParams}
              surveys={surveys}
              wellPlan={wellPlan}
              setProjections={setProjections}
            />
          ) : null}
          {selectedTool === 'geoSteering' ? (
            <GeosteeringPage wellName={wellName} shouldUpdate={getGeoSteeringData} />
          ) : null}
          {selectedTool === 'soloCloud' ? <SoloCloudPage wellName={wellName} /> : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default ToolsPane
