import React, { useEffect, useRef, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { isEqual } from 'lodash'
import SplitPane from 'components/common/SplitPane'
import { useRecoilState } from 'recoil'
import { templatesLayoutAtom, selectedTemplateAtom } from 'atoms'
import { Box, Tooltip, Alert, Snackbar } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import TemplatesListGrid from './TemplatesListGrid'
import TemplatesThreeDeeChart from './TemplatesThreeDeeChart'
import TemplateGeometryGrid from './TemplateGeometryGrid'
import SlotCoordsGrid from './SlotCoordsGrid'
import { uuidv4 } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const TemplatesPage = ({ facility }) => {
  const _isMounted = useRef(false)
  const [pageLayout, setPageLayout] = useRecoilState(templatesLayoutAtom)
  const [dataChanged, setDataChanged] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [selectedTemplate, setSelectedTemplate] = useRecoilState(selectedTemplateAtom)
  const selectedTemplateRef = useRef(selectedTemplate)
  const templateList = useRef(null)
  const [selectedPane, setSelectedPane] = useState('SLOTS')
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      setSelectedTemplate(null)
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedTemplate) {
      selectedTemplateRef.current = null
      setDataChanged(false)
    }

    if (selectedTemplate) {
      selectedTemplateRef.current = cloneDeep(selectedTemplate)
      setDataChanged(!isEqual(selectedTemplate.orgData, selectedTemplate.data))
    }
  }, [selectedTemplate, setDataChanged, selectedPane])

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '30%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showTemplateList, setShowTemplateList] = useState(getPaneVisible(0))

  const updateSlots = (data) => {
    if (!data) return
    if (!selectedTemplateRef.current) return
    if (!selectedTemplateRef.current.data) return

    if (!Array.isArray(selectedTemplateRef.current.data.slots)) selectedTemplateRef.current.data.slots = []

    if (data.action === 'delete') {
      selectedTemplateRef.current.data.slots = selectedTemplateRef.current.data.slots.filter(
        (slot) => slot.uid !== data.data.uid,
      )
    }

    if (data.action === 'update') {
      selectedTemplateRef.current.data.slots = selectedTemplateRef.current.data.slots.map((slot) => {
        if (slot.uid === data.data.uid) {
          slot.slotName = data.data.slotName
          slot.slotPosition.localNorth = data.data.localNorth
          slot.slotPosition.localEast = data.data.localEast
          slot.slotPosition.gridNorth = data.data.gridNorth
          slot.slotPosition.gridEast = data.data.gridEast
          slot.coordInput = data.data.coordInput
        }
        return slot
      })
    }

    if (data.action === 'add') {
      selectedTemplateRef.current.data.slots.push({
        slotName: data.data.slotName,
        uid: uuidv4(),
        coordInput: data.data.coordInput,
        slotPosition: {
          localNorth: data.data.localNorth,
          localEast: data.data.localEast,
          gridNorth: data.data.gridNorth,
          gridEast: data.data.gridEast,
        },
      })
    }

    templateList?.current?.calculateTemplate(selectedTemplateRef.current.data)
  }

  const updateTemplate = (data) => {
    if (!data) return
    if (!selectedTemplateRef.current) return
    if (!selectedTemplateRef.current.data) return

    if (data.tag === 'topLeftEast') selectedTemplateRef.current.data.rectData.topLeftEast = data.value
    if (data.tag === 'topLeftNorth') selectedTemplateRef.current.data.rectData.topLeftNorth = data.value
    if (data.tag === 'rows') selectedTemplateRef.current.data.rectData.rows = data.value
    if (data.tag === 'columns') selectedTemplateRef.current.data.rectData.columns = data.value
    if (data.tag === 'slotNumbering') selectedTemplateRef.current.data.rectData.slotNumbering = data.value
    if (data.tag === 'xSpacing') selectedTemplateRef.current.data.rectData.xSpacing = data.value
    if (data.tag === 'ySpacing') selectedTemplateRef.current.data.rectData.ySpacing = data.value
    if (data.tag === 'numSlots') selectedTemplateRef.current.data.circData.numSlots = data.value
    if (data.tag === 'radius') selectedTemplateRef.current.data.circData.radius = data.value

    if (selectedTemplateRef.current.data.geometry === 'CIRC' && data.tag === 'rotation') {
      selectedTemplateRef.current.data.circData.rotation = data.value
    }

    if (selectedTemplateRef.current.data.geometry === 'RECT' && data.tag === 'rotation') {
      selectedTemplateRef.current.data.rectData.rotation = data.value
    }

    templateList?.current?.calculateTemplate(selectedTemplateRef.current.data)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => {
          templateList?.current?.commitChanges()
        }}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          templateList?.current?.discardChanges()
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showTemplateList)
    setShowTemplateList(!showTemplateList)
  }

  return (
    <React.Fragment>
      {dataChanged ? <SaveButton /> : null}
      {dataChanged ? <DiscardButton /> : null}
      <SplitPane
        split='vertical'
        size={showTemplateList ? getInitialPaneSize(0) : '25px'}
        allowResize={showTemplateList}
        minSize={'10%'}
        defaultSize={getInitialPaneSize(0)}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <TemplatesListGrid
          isVisible={showTemplateList}
          showHidePane={showHidePane}
          facility={facility}
          ref={templateList}
        />
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          minSize={'10%'}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}>
          <TemplatesThreeDeeChart template={selectedTemplate ? selectedTemplate?.data : null} />
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundColor: getChartBackColor(),
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box
              sx={{
                width: '24px',
                height: '100%',
                backgroundColor: getWindowBarColor(),
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                border: '1px solid gray',
                marginLeft: '1px',
              }}>
              <Box
                sx={{
                  ...verticalTextStyle,
                  fontWeight: selectedPane === 'GEOMETRY' ? 'bold' : '400',
                  color: selectedPane === 'GEOMETRY' ? '#429ceb' : '#f0f0f0',
                }}
                onClick={() => {
                  setSelectedPane('GEOMETRY')
                }}>
                Geometry
              </Box>
              <Box
                sx={{
                  ...verticalTextStyle,
                  fontWeight: selectedPane === 'SLOTS' ? 'bold' : '400',
                  color: selectedPane === 'SLOTS' ? '#429ceb' : '#f0f0f0',
                }}
                onClick={() => {
                  setSelectedPane('SLOTS')
                }}>
                Slots
              </Box>
            </Box>
            {selectedPane === 'GEOMETRY' ? (
              <TemplateGeometryGrid selectedTemplate={selectedTemplate?.data} handleUpdate={updateTemplate} />
            ) : null}
            {selectedPane === 'SLOTS' ? (
              <SlotCoordsGrid selectedTemplate={selectedTemplate?.data} handleUpdate={updateSlots} />
            ) : null}
          </Box>
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default TemplatesPage
