import React, { useEffect, useRef, useMemo, useState, useCallback, useImperativeHandle, forwardRef } from 'react'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { appColors } from 'utils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import Alert from '@mui/material/Alert'
import SearchBar from 'components/common/SearchBar'
import DropDownPicker from 'components/common/DropDownPicker'
import {
  sortColDefs,
  htmlSymbolHandling,
  showHideDetailGrid,
  CustomLoadingOverlay,
  relativeTime,
  isValidDate,
  dateComparator,
  isDateLessThan,
  getStringId,
} from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { Icon as Iconify } from '@iconify/react'
import { styled } from '@mui/styles'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { debounce, cloneDeep } from 'lodash'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import { numberWithCommasDecimals, removeSymbolsFromName } from 'utils/stringFunctions'
import { isoDateAddition, getDeltaIsoDays } from 'utils/dateTimeFunctions'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import * as XLSX from '@sheet/core'
import { getRevenueByCustomerPerMonth } from './InnovaInvoiceCharts'
import { createInvoiceHtml } from 'components/InnovaInvoices/createInvoiceHtml'
import HtmlPdf from 'components/common/PDFGen/HtmlStringPdf'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import InvoiceDetailsGrid from './InvoiceDetailsGrid'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const InnovaInvoiceGrid = ({ isLoading, setIsLoading, setFilteredData, showChart, setShowChart, dropDowns }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const inputRow = useRef({})
  const confirmStatusChangeRef = useRef(false)
  const containerRef = useRef(null)
  const selectedInvoice = useRef(null)
  const [confirmStatusChange, setConfirmStatusChange] = useState({ show: false, title: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [invoiceData, setInvoiceData] = useState([])
  const [dateFilter, setDateFilter] = useState('Current Year')
  const [searchText, setSearchText] = useState('')
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const htmlPdfData = useRef('')
  const [showPdfComponent, setShowPdfComponent] = useState(false)
  const [bankAccounts, setBankAccounts] = useState([])
  const userRole = useRecoilValue(userUserRoleAtom)
  const [forceRePrint, setForceRePrint] = useState(0)
  const { user } = useInnovaAuth()
  const { getAgGridTheme, searchBarStyle, getBackColor, getChartBackColor, getTextColor } = useInnovaTheme()

  const getData = useAxiosGzip({
    url: '/admin/invoices/getInnovaInvoicesGz',
  })

  const getBankAccounts = useInnovaAxios({
    url: '/admin/bank/getAccounts',
  })

  const deleteInvoice = useInnovaAxios({
    url: '/admin/invoices/deleteInnovaInvoice',
  })

  const addInvoice = useInnovaAxios({
    url: '/admin/invoices/createInnovaInvoice',
  })

  const updateInvoice = useInnovaAxios({
    url: '/admin/invoices/updateInnovaInvoice',
  })

  const printInvoice = useInnovaAxios({
    url: '/admin/invoices/getInnovaInvoices',
  })

  const copyInvoice = useInnovaAxios({
    url: '/admin/invoices/duplicateInvoice',
  })

  const updateSalesTaxPaid = useInnovaAxios({
    url: '/admin/invoices/updateSalesTaxPaid',
  })

  const updateRefundCollected = useInnovaAxios({
    url: '/admin/invoices/updateRefundCollected',
  })

  const createUpcomingInv = useInnovaAxios({
    url: '/admin/invoices/createUpcomingInvoices',
  })

  const getXeroInvoiceUrl = useInnovaAxios({
    url: '/admin/xero/getXeroInvoiceUrl',
  })

  const addInvoiceLineItem = useInnovaAxios({
    url: '/admin/invoices/addInnovaInvoiceDetail',
  })

  const updateInvoiceLineItem = useInnovaAxios({
    url: '/admin/invoices/updateInnovaInvoiceDetail',
  })

  const deleteInvoiceLineItem = useInnovaAxios({
    url: '/admin/invoices/deleteInnovaInvoiceDetail',
  })

  const reqFields = ['company', 'terms', 'allocationCountry', 'bankAccount', 'currency']

  function createPinnedCellPlaceholder({ colDef }) {
    if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  function isPinnedRowDataCompleted() {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)
    inputRow.current.status = 'Draft'
    inputRow.current.exchangeRate = 1
    inputRow.current.issueDate = ''
    inputRow.current.sentDate = ''
    inputRow.current.paidDate = ''

    let res = await addInvoice(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current = res.data
      gridApi.current.applyTransaction({
        add: [inputRow.current],
        addIndex: 0,
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: true,
    wrapText: true,
    editable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
    valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      sortable: true,
    }),
    [],
  )

  const fetchInvoiceData = async () => {
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const res = await getData()

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setInvoiceData(Array.isArray(res.data) ? res.data : [])
  }

  const fetchBankAccounts = async () => {
    let res = await getBankAccounts()
    if (!_isMounted.current) return
    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setBankAccounts(Array.isArray(res.data) ? res.data : [])
  }

  useEffect(() => {
    _isMounted.current = true
    fetchInvoiceData()
    fetchBankAccounts()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [dateFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const convertDateToDateStr = useCallback((date) => {
    if (!date) return ''
    if (typeof date !== 'string') return ''
    if (date === '') return ''
    if (!isValidDate(date)) return ''
    let dateStr = new Date(Date.parse(date)).toISOString().substring(0, 10)
    return dateStr
  }, [])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return
      if (isUpdating.current) return
      data = htmlSymbolHandling(data)

      //This is deliberate issue date is required by the http form but doesnt exist on the object
      data.issueDate = convertDateToDateStr(data.createdDate)

      if (!data.sentBy || data.sentBy === '') data.sentDate = ''
      data.sentDate = convertDateToDateStr(data.sentDate)

      isUpdating.current = true
      let res = await updateInvoice({ ...data })
      isUpdating.current = false

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: [res.data],
        })
      }
    },
    [updateInvoice, convertDateToDateStr],
  )

  const dateFilterComparator = useCallback(dateComparator, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '' || value === '-1') return ''
    if (value.indexOf('T') < 0) value += 'T00:00:01'
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const leftAlignedCell = useMemo(() => {
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }
  }, [])

  const handleDelete = useCallback(async () => {
    if (!selectedInvoice.current) return
    if (!selectedInvoice.current.hasOwnProperty('recordId')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteInvoice(selectedInvoice.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [selectedInvoice.current],
      })
    }

    selectedInvoice.current = null
  }, [deleteInvoice])

  const onClickDelete = useCallback(() => {
    setConfirm({
      show: true,
      title: 'Delete Invoice',
      text: `Are you sure you want to delete ${selectedInvoice.current.invoiceNum} - ${selectedInvoice.current.company}?`,
      type: 'delete',
    })
  }, [])

  const goToXeroInvoice = useCallback(
    async (data) => {
      if (!data) return
      if (typeof data?.xeroId !== 'string' || data?.xeroId === '') {
        setStatus({
          show: true,
          severity: 'error',
          message: `No Xero Invoice ID found`,
        })

        return
      }

      const res = await getXeroInvoiceUrl({
        xeroInvoiceId: data?.xeroId,
      })

      if (!_isMounted.current) return

      if (res.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: ` ${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (typeof res.data !== 'string' || res.data === '') {
        setStatus({
          show: true,
          severity: 'error',
          message: `Could not get invoice URL`,
        })

        return
      }

      window.open(res.data, '_blank', 'noreferrer')
    },
    [getXeroInvoiceUrl],
  )

  const duplicateQuote = useCallback(
    async (data) => {
      if (!data) return
      if (isLoading) return

      setIsLoading(true)
      const res = await copyInvoice(data)
      if (!_isMounted.current) return
      setIsLoading(false)

      if (res.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (gridApi.current && res.data) {
        gridApi.current.applyTransaction({
          add: [res.data],
          addIndex: 0,
        })

        setStatus({
          show: true,
          severity: 'info',
          message: `${'Invoice duplicated'}`,
        })
      }
    },
    [copyInvoice, setIsLoading, isLoading],
  )

  const onPrint = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return
      if (isLoading) return

      setIsLoading(true)
      let printResponse = await printInvoice({ recordId: data.recordId })
      if (!_isMounted.current) return
      setIsLoading(false)

      if (printResponse.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${printResponse?.error?.response?.data?.error}`,
        })

        return
      }

      let selectedBankAccount = null
      if (
        Array.isArray(bankAccounts) &&
        bankAccounts.length > 0 &&
        typeof printResponse.data[0].bankAccount === 'string'
      ) {
        for (let i = 0; i < bankAccounts.length; i++) {
          if (bankAccounts[i].bank === printResponse.data[0].bankAccount) {
            selectedBankAccount = bankAccounts[i]
            break
          }
        }
      }

      if (Array.isArray(printResponse.data) && printResponse.data.length > 0) {
        htmlPdfData.current = {
          html: createInvoiceHtml(printResponse.data[0], false, selectedBankAccount),
          fileName: removeSymbolsFromName(
            `Invoice-${printResponse.data[0].invoiceNum}-${unescapeHtml(printResponse.data[0].company)}`,
          ),
        }

        setForceRePrint((prevVal) => prevVal + 1)
        setShowPdfComponent(true)
      }
    },
    [bankAccounts, printInvoice, isLoading, setIsLoading],
  )

  const toggleSalesTaxPaid = useCallback(
    async (data) => {
      if (!data) return
      if (isLoading) return

      setIsLoading(true)
      const res = await updateSalesTaxPaid({
        recordId: data?.recordId,
        taxPaid: !data?.salesTaxPaid,
      })

      if (!_isMounted.current) return
      setIsLoading(false)

      if (res.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      if (gridApi.current) {
        data.salesTaxPaid = !data.salesTaxPaid
        data.salesTaxPaidDate = ''
        if (data.salesTaxPaid) {
          data.salesTaxPaidDate = new Date(Date.now()).toISOString()
        }

        gridApi.current.applyTransaction({
          update: [data],
        })
      }

      setStatus({ show: true, severity: 'success', message: 'Sales Tax Updated successfully' })
    },
    [updateSalesTaxPaid, isLoading, setIsLoading],
  )

  const toggleRefundCollected = useCallback(
    async (data) => {
      if (!data) return
      if (isLoading) return

      setIsLoading(true)
      const res = await updateRefundCollected({
        recordId: data?.recordId,
        collected: !data?.refundCollected,
      })

      if (!_isMounted.current) return
      setIsLoading(false)

      if (res.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      if (gridApi.current) {
        data.refundCollected = !data.refundCollected
        data.refundCollectedDate = ''
        if (data.refundCollected) {
          data.refundCollectedDate = new Date(Date.now()).toISOString()
        }

        gridApi.current.applyTransaction({
          update: [data],
        })
      }

      setStatus({ show: true, severity: 'success', message: 'Refund collected updated successfully' })
    },
    [updateRefundCollected, isLoading, setIsLoading],
  )

  const handleMenuClick = useCallback(
    async (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedInvoice.current = cloneDeep(data)
      if (action === 'delete') {
        onClickDelete()
      }

      if (action === 'duplicate') {
        duplicateQuote(data)
      }

      if (action === 'print') {
        onPrint(data)
      }

      if (action === 'xero') {
        goToXeroInvoice(data)
      }

      if (action === 'salesTaxPaid') {
        toggleSalesTaxPaid(data)
      }

      if (action === 'refund') {
        toggleRefundCollected(data)
      }
    },
    [onClickDelete, duplicateQuote, onPrint, goToXeroInvoice, toggleSalesTaxPaid, toggleRefundCollected],
  )

  const getDueDate = (invoiceDate, paymentTermsDays) => {
    if (!paymentTermsDays) paymentTermsDays = 0
    if (typeof paymentTermsDays !== 'number') paymentTermsDays = 0
    if (paymentTermsDays < 0) paymentTermsDays = 0
    if (!invoiceDate) return null
    return isoDateAddition(invoiceDate, paymentTermsDays, true)
  }

  const expandIconRenderer = useCallback(
    (params) => {
      return (
        <Box
          onClick={() => showHideDetailGrid(params, 'recordId', false, setShowChart, showChart?.current)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
          <Iconify
            icon={!params?.node?.expanded ? 'material-symbols:chevron-right-rounded' : 'mdi:chevron-down'}
            style={{ color: getTextColor(), height: '30px', width: '30px' }}
          />
        </Box>
      )
    },
    [setShowChart]) // eslint-disable-line react-hooks/exhaustive-deps

  let columnDefs = useMemo(
    () => [
      {
        field: 'invoiceData',
        colId: 'invoiceData',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 30,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: expandIconRenderer,
        cellStyle: centerAlignCell,
      },
      {
        headerName: '',
        colId: 'actions',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          let menuOptions = [
            {
              label: 'Duplicate',
              action: 'duplicate',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='akar-icons:copy' />
                </StyledIconContainer>
              ),
            },
            {
              label: 'Print',
              action: 'print',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='fluent:print-24-filled' />
                </StyledIconContainer>
              ),
            },
          ]

          //Can only delete a draft invoice
          if (params.data?.status === 'Draft') {
            menuOptions.push({
              label: 'Delete',
              action: 'delete',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='fa-regular:trash-alt' color={'red'} />
                </StyledIconContainer>
              ),
            })
          }

          if (params.data?.status !== 'Draft' && userRole?.finAdmin) {
            menuOptions.push({
              label: 'Xero Invoice',
              action: 'xero',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='simple-icons:xero' />
                </StyledIconContainer>
              ),
            })

            menuOptions.push({
              label: !params.data?.salesTaxPaid ? 'Mark sales tax as paid' : 'Mark sales tax as unpaid',
              action: 'salesTaxPaid',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='tabler:receipt-tax' color={params.data?.salesTaxPaid ? 'green' : 'red'} />
                </StyledIconContainer>
              ),
            })
          }

          if (params.data?.status === 'Cancelled' && userRole?.finAdmin && params.data?.salesTaxPaid) {
            menuOptions.push({
              label: !params.data?.refundCollected
                ? 'Mark sales tax refund collected'
                : 'Mark sales tax refund un-collected',
              action: 'refund',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='mdi:cash-refund' color={params.data?.refundCollected ? 'green' : 'red'} />
                </StyledIconContainer>
              ),
            })
          }

          return {
            menuItems: menuOptions,
          }
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'invoiceNum',
        colId: 'invoiceNum',
        headerName: 'Invoice#',
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
      },
      {
        field: 'company',
        colId: 'company',
        headerName: 'Company',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return true
          return params.data?.status === 'Draft'
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        valueGetter: (params) => unescapeHtml(params.data?.company),
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.companys) ? dropDowns.companys : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'bankAccount',
        colId: 'bankAccount',
        headerName: 'Bank',
        cellEditor: 'agSelectCellEditor',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return true
          return params.data?.status === 'Draft'
        },
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(bankAccounts) ? bankAccounts : []
          return {
            values: dropDown.map((value) => value.bank),
          }
        },
      },
      {
        field: 'terms',
        colId: 'terms',
        headerName: 'Payment Terms',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return true
          return params.data?.status === 'Draft'
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 360,
          precision: 0,
        },
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'Terms...'
          return params.value
        },
      },
      {
        field: 'totalValue',
        colId: 'totalValue',
        headerName: 'Total Value',
        valueFormatter: (params) => {
          if (parseFloat(params?.data?.totalValue) === 0) return ''
          return numberWithCommasDecimals(params?.data?.totalValue, 2)
        },
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'totalTax',
        colId: 'totalTax',
        headerName: 'Total Tax',
        valueFormatter: (params) => {
          if (parseFloat(params?.data?.totalTax) === 0) return ''
          return numberWithCommasDecimals(params?.data?.totalTax, 2)
        },
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'status',
        colId: 'status',
        headerName: 'Status',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          if (params.data?.status === 'Cancelled') return false
          if (params.data?.status === 'Paid') return false
          return true
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let statusDropDowns = []
          if (params.data?.status === 'Draft') {
            statusDropDowns = ['Due', 'Draft']
          }

          if (params.data?.status === 'Due') {
            statusDropDowns = ['Due', 'Paid', 'Check recieved clearing', 'Cancelled']
          }

          if (params.data?.status === 'Check recieved clearing') {
            statusDropDowns = ['Paid', 'Check recieved clearing', 'Cancelled']
          }

          return {
            values: statusDropDowns,
          }
        },
        cellStyle: (params) => {
          let color = '#4BB2F9'
          if (params.data?.status === 'Paid') color = '#00ff00'
          if (params.data?.status === 'Check recieved clearing') color = '#ffff00'
          if (params.data?.status === 'Cancelled') color = '#ff0000'

          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            color: color,
          }
        },
        valueSetter: (params) => {
          confirmStatusChangeRef.current = true

          if (params.oldValue === 'Draft' && params.newValue === 'Due') {
            setConfirmStatusChange({
              show: true,
              text: `Are you sure you want to change status to due, Xero invoice will be created and cannot be deleted?`,
            })

            selectedInvoice.current = cloneDeep(params.data)
            return
          }

          params.data.status = params.newValue
          handleUpdate(params.data)
        },
      },
      {
        field: 'dueDate',
        colId: 'dueDate',
        headerName: 'Due Date',
        editable: false,
        valueFormatter: (params) => {
          if (params.data?.status !== 'Due') return ''
          let dueDate = getDueDate(params.data?.createdDate, params.data?.terms)
          if (!dueDate) return ''
          return `${dateTimeFormatter(dueDate)} [${relativeTimeFormatter(dueDate)}]`
        },
        valueGetter: (params) => {
          if (params.data?.status !== 'Due') return ''
          let dueDate = getDueDate(params.data?.createdDate, params.data?.terms)
          if (!dueDate) return ''
          return dueDate
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        cellStyle: (params) => {
          let dueDate = getDueDate(params.data?.createdDate, params.data?.terms)
          let dueDays = getDeltaIsoDays(new Date(Date.now()).toISOString(), dueDate)
          let color = '#00ff00'
          if (dueDays <= 7) color = 'orange'
          if (dueDays <= 0) color = '#ff0000'

          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            color: color,
          }
        },
      },
      {
        field: 'isOverdue',
        colId: 'isOverdue',
        headerName: 'Overdue',
        editable: false,
        cellRendererSelector: (params) => {
          if (params?.node?.rowPinned !== 'bottom') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return { component: () => <></> }
        },
        valueGetter: (params) => {
          if (params.data?.status !== 'Due') return false
          let dueDate = getDueDate(params.data?.createdDate, params.data?.terms)
          let dueDays = getDeltaIsoDays(new Date(Date.now()).toISOString(), dueDate)
          return dueDays < 0
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'allocationCountry',
        colId: 'allocationCountry',
        headerName: 'Allocation',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return true
          return params.data?.status === 'Draft'
        },
        cellStyle: centerAlignCell,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.allocationCountry) ? dropDowns.allocationCountry : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'currency',
        colId: 'currency',
        headerName: 'Currency',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return true
          return params.data?.status === 'Draft'
        },
        cellEditor: 'agSelectCellEditor',
        cellStyle: centerAlignCell,
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.currency) ? dropDowns.currency : []
          return {
            values: dropDown.map((value) => value.label),
          }
        },
      },
      {
        field: 'exchangeRate',
        colId: 'exchangeRate',
        headerName: 'Exchange Rate',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          return params.data?.status === 'Draft'
        },
        valueFormatter: (params) => (parseFloat(params?.data?.exchangeRate) > 0 ? params?.data?.exchangeRate : ''),
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 4,
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'customerRef',
        colId: 'customerRef',
        headerName: 'Customer Ref',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          return params.data?.status === 'Draft'
        },
        valueGetter: (params) => unescapeHtml(params.data?.customerRef),
      },
      {
        field: 'createdDate',
        colId: 'createdDate',
        headerName: 'Issue Date',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          return params.data?.status === 'Draft'
        },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.createdDate),
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        field: 'sentDate',
        colId: 'sentDate',
        headerName: 'Sent Date',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          return true
        },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.sentDate),
        valueGetter: (params) => {
          if (!params.value) return ''
        },
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueSetter: (params) => {
          if (!params.newValue) params.data.sentDate = ''
          if (params.newValue) params.data.sentDate = params.newValue

          if (isDateLessThan(params.data?.sentDate, params.data?.createdDate)) {
            setStatus({
              show: true,
              severity: 'error',
              message: `Sent date cant be before issue date`,
            })

            params.data.sentDate = params.data?.createdDate
          }

          if (!params?.data?.sentBy || params?.data?.sentBy === '') params.data.sentBy = user.name
          handleUpdate(params.data)
        },
      },
      {
        field: 'sentBy',
        colId: 'sentBy',
        headerName: 'Sent By',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          return true
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        valueGetter: (params) => getUserNameFromEmail(params?.data?.sentBy),
        cellEditorParams: (params) => {
          let dropDown = Array.isArray(dropDowns.users) ? dropDowns.users : []
          return {
            values: dropDown.map((value) => getUserNameFromEmail(value.label)),
          }
        },
        valueSetter: (params) => {
          if (!params.newValue || params.newValue === '') {
            params.data.sentBy = ''
            params.data.sentDate = ''
          } else {
            params.data.sentBy = params.newValue
            if (!params.oldValue || params.oldValue === '') {
              params.data.sentDate = new Date(Date.now()).toISOString().substring(0, 10)
            }

            if (isDateLessThan(params.data?.sentDate, params.data?.createdDate)) {
              params.data.sentDate = params.data?.createdDate
            }
          }

          handleUpdate(params.data)
        },
      },
      {
        field: 'modifiedDate',
        colId: 'modifiedDate',
        headerName: 'Modified Date',
        valueFormatter: (params) => dateTimeFormatter(params?.data?.modifiedDate),
        editable: false,
      },
      {
        field: 'modifiedBy',
        colId: 'modifiedBy',
        headerName: 'Modified By',
        valueFormatter: (params) => getUserNameFromEmail(params?.data?.modifiedBy),
        editable: false,
      },
      {
        field: 'paidDate',
        colId: 'paidDate',
        headerName: 'Paid Date',
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          if (params.data?.status === 'Cancelled') return false
          if (params.data?.status === 'Draft') return false
          return true
        },
        valueGetter: (params) => {
        if(!params.value) return ''
      },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.paidDate),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        field: 'comments',
        colId: 'comments',
        headerName: 'Comments',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellStyle: leftAlignedCell,
        maxWidth: 600,
        valueGetter: (params) => unescapeHtml(params?.data?.comments),
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 2000,
          rows: 10,
          cols: 50,
        },
      },
      {
        field: 'salesTaxPaid',
        colId: 'salesTaxPaid',
        headerName: 'Sales Tax Paid',
        hide: (params) => !userRole?.finAdmin,
        editable: false,
        cellRendererSelector: (params) => {
          if (params?.node?.rowPinned !== 'bottom') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return { component: () => <></> }
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'salesTaxPaidDate',
        colId: 'salesTaxPaidDate',
        headerName: 'Sales Tax Paid Date',
        hide: (params) => !userRole?.finAdmin,
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          if (params.data?.status === 'Draft') return false
          if (!params.data?.salesTaxPaidDate) return false
          return true
        },
        valueGetter: (params) => {
        if(!params.value) return ''
      },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.salesTaxPaidDate),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        field: 'refundCollected',
        colId: 'refundCollected',
        headerName: 'Refund Collected',
        hide: (params) => !userRole?.finAdmin,
        editable: false,
        cellRendererSelector: (params) => {
          if (params?.node?.rowPinned !== 'bottom') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return { component: () => <></> }
        },
        cellStyle: centerAlignCell,
      },
      {
        field: 'refundCollectedDate',
        colId: 'refundCollectedDate',
        headerName: 'Refund Collected Date',
        hide: (params) => !userRole?.finAdmin,
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') return false
          if (params.data?.status === 'Draft') return false
          if (!params.data?.refundCollected) return false
          return true
        },
        valueGetter: (params) => {
        if(!params.value) return ''
      },
        valueFormatter: (params) => dateTimeFormatter(params?.data?.refundCollectedDate),
        cellEditor: 'agDateStringCellEditor',
        cellEditorParams: {
          min: '1950-01-01',
          max: '2200-01-01',
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
      },
      {
        field: 'badDebt',
        colId: 'badDebt',
        headerName: 'Bad Debt',
        hide: (params) => !userRole?.finAdmin,
        editable: (params) => params.data?.status === 'Cancelled',
        cellRendererSelector: (params) => {
          if (params?.node?.rowPinned !== 'bottom') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return { component: () => <></> }
        },
        cellStyle: centerAlignCell,
      },
    ],
    [
      dateFilterComparator,
      dateTimeFormatter,
      relativeTimeFormatter,
      dropDowns,
      leftAlignedCell,
      centerAlignCell,
      handleMenuClick,
      handleUpdate,
      bankAccounts,
      userRole,
      expandIconRenderer,
      user,
    ],
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    setFilteredData(filteredNodes)
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('innovaInvoiceGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      if (!confirmStatusChangeRef.current) handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    onExpandOrCollapseAll: () => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('innovaInvoiceGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'InnovaInvoices.xlsx',
            sheetName: 'Invoices',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getDateFromAndToFilters = useCallback(() => {
    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse('1900-01-01'))

    if (typeof dateFilter === 'string' && dateFilter !== 'All') {
      if (dateFilter === 'Current Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(1)
      }

      if (dateFilter === 'Previous Month') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 1)
        dateFrom.setDate(1)

        let daysInMonth = new Date(dateFrom.getFullYear(), dateFrom.getMonth() + 1, 0).getDate()
        dateTo = new Date(Date.parse(dateFrom.toISOString()))
        dateTo.setDate(daysInMonth)
        dateTo.setYear(dateFrom.getFullYear())
      }

      if (dateFilter === 'Last 30 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 30)
      }

      if (dateFilter === 'Last 60 Days') {
        dateFrom = new Date(Date.now())
        dateFrom.setDate(dateFrom.getDate() - 60)
      }

      if (dateFilter === 'Last 6 Months') {
        dateFrom = new Date(Date.now())
        dateFrom.setMonth(dateFrom.getMonth() - 6)
      }

      if (dateFilter === 'Current Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))
      }

      if (dateFilter === 'Last Year') {
        dateFrom = new Date(Date.parse(`${dateTo.getFullYear() - 1}-01-01T00:00:01`))
        dateTo = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T23:59:59`))
      }
    }

    return { dateTo: dateTo.toISOString().substring(0, 10), dateFrom: dateFrom.toISOString().substring(0, 10) }
  }, [dateFilter])

  const isExternalFilterPresent = useCallback(() => {
    return dateFilter !== 'All'
  }, [dateFilter])

  const doesExternalFilterPass = useCallback(
    (node) => {
      let { dateFrom, dateTo } = getDateFromAndToFilters()
      if (node.data) {
        if (!isValidDate(node.data.createdDate)) return false

        let dateFromObj = new Date(Date.parse(dateFrom))
        let dateToObj = new Date(Date.parse(dateTo))

        let dt = new Date(Date.parse(node.data.createdDate)).getTime()
        if (dt < dateFromObj.getTime() || dt > dateToObj.getTime()) return false
      }
      return true
    },
    [getDateFromAndToFilters],
  )

  const getGridData = () => {
    if (!gridApi.current) return []

    let filteredNodes = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) filteredNodes.push(node.data)
    })

    return filteredNodes
  }

  const onXlsxSummaryExport = () => {
    let invData = getGridData()

    if (!invData) return
    if (!Array.isArray(invData)) return
    if (invData.length === 0) return

    let summaryData = getRevenueByCustomerPerMonth(invData, false)

    let headers = []
    headers.push('#')
    headers.push('Company')
    summaryData.labels.forEach((label) => {
      if (typeof label !== 'string') return
      let yyyymm = `${label.split('-', 2)[0]}-${label.split('-', 2)[1]}`
      headers.push(yyyymm)
    })
    headers.push('Company Total')

    const wb = XLSX.utils.book_new()
    let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })
    let dataArray = []
    let row = []

    let runningTotal = 0
    summaryData.datasets.forEach((dataset, i) => {
      row = []
      row.push(i)
      row.push(dataset.label)
      row.push(...dataset.data)
      const initVal = 0
      let companyTotal = dataset.data.reduce((prev, curr) => prev + curr, initVal)
      row.push(companyTotal)
      runningTotal += companyTotal
      dataArray.push(row)
    })
    dataArray.sort((a, b) => {
      if (a[a.length - 1] < b[a.length - 1]) return 1
      if (a[a.length - 1] > b[a.length - 1]) return -1
      return 0
    })
    dataArray.forEach((item, i) => {
      dataArray[i][0] = i + 1
    })

    dataArray.push([])
    dataArray.push(['Total', runningTotal])

    XLSX.utils.sheet_add_aoa(ws, dataArray, { origin: `A2` })

    XLSX.utils.book_append_sheet(wb, ws, 'Summary')
    XLSX.writeFile(wb, 'RevenueSummary.xlsx')
  }

  const validateTaxStatus = (currency, allocCountry) => {
    if (currency === 'USD' && allocCountry !== 'USA') return false
    if (currency === 'GBP' && allocCountry !== 'UK') return false

    return true
  }

  const getExchangeRate = (currencyType, allocCountry, baseRate) => {
    let exchRate = baseRate

    // zero check
    if (exchRate < 0.01) {
      exchRate = 1.0
    }

    if (allocCountry === 'USA') {
      return exchRate
    }
    if (allocCountry === 'UK') {
      if (currencyType === 'GBP') return 1
      if (currencyType === 'USD') return 1.0 / exchRate
      return exchRate
    }
    return 1.0
  }

  const onTaxExport = (currency) => {
    let invData = getGridData()
    if (!invData) return
    if (!currency) return
    if (typeof currency !== 'string') return
    if (!Array.isArray(invData)) return
    if (invData.length === 0) return

    let headers = []
    headers.push('#')
    headers.push('Company')
    headers.push('Invoice #')
    headers.push('Invoice Date')
    headers.push('Status')
    headers.push('Line Item')
    headers.push('Description')
    headers.push('Total Value')
    headers.push('Total Tax')
    headers.push('Line Total')
    headers.push('Comments')

    const wb = XLSX.utils.book_new()

    let workSheetNames = []
    for (let i = 0; i < invData.length; i++) {
      if (invData[i].status !== 'Cancelled' && invData[i].status !== 'Draft') {
        let dt = new Date(Date.parse(invData[i].createdDate))
        let dateStr = `${dt.getFullYear()}-${dt.getMonth() + 1 < 10 ? '0' : ''}${dt.getMonth() + 1}-01T00:01:00`
        let index = workSheetNames.findIndex((label) => label === dateStr)
        if (index < 0) workSheetNames.push(dateStr)
      }
    }

    workSheetNames.sort((firstEl, secondEl) =>
      new Date(Date.parse(firstEl)).getTime() < new Date(Date.parse(secondEl)).getTime() ? 1 : -1,
    )

    let allWorkSheet = ['All']
    workSheetNames = allWorkSheet.concat(workSheetNames)

    for (let k = 0; k < workSheetNames.length; k++) {
      let xlExport = []
      let rowCounter = 1
      let totalValue = 0
      let totalTax = 0
      let total = 0
      let ws = XLSX.utils.aoa_to_sheet([headers], { origin: 'A1' })

      for (let i = 0; i < invData.length; i++) {
        if (!validateTaxStatus(currency, invData[i].allocationCountry)) continue
        if (invData[i].status === 'Cancelled') {
          if (!invData[i].salesTaxPaid) continue
          if (invData[i].salesTaxPaid && invData[i].refundCollected) continue
        }

        if (invData[i].status === 'Draft') continue
        const exchangeRate = getExchangeRate(currency, invData[i].allocationCountry, invData[i].exchangeRate)

        let dt = new Date(Date.parse(invData[i].createdDate + 'T00:01:00'))

        let sheetIndex = workSheetNames.findIndex((dateStr) => {
          let seriesDate = new Date(Date.parse(dateStr))
          if (seriesDate.getFullYear() === dt.getFullYear() && seriesDate.getMonth() === dt.getMonth()) {
            return true
          }

          return false
        })

        if (sheetIndex !== k && k > 0) continue

        let refundMultiple = 1
        if (invData[i].status === 'Cancelled') refundMultiple = -1

        if (Array.isArray(invData[i].invoiceDetails) && invData[i].invoiceDetails.length > 0) {
          for (let j = 0; j < invData[i].invoiceDetails.length; j++) {
            if (invData[i].invoiceDetails[j].totalTax === 0) continue

            let newRow = []
            newRow.push(rowCounter)
            newRow.push(unescapeHtml(invData[i].company))
            newRow.push(invData[i].invoiceNum)
            newRow.push(invData[i].createdDate)
            newRow.push(invData[i].status)
            newRow.push(j + 1)
            newRow.push(unescapeHtml(invData[i].invoiceDetails[j].description))
            newRow.push(invData[i].invoiceDetails[j].totalValue * exchangeRate * refundMultiple)
            newRow.push(invData[i].invoiceDetails[j].totalTax * exchangeRate * refundMultiple)
            newRow.push(invData[i].invoiceDetails[j].total * exchangeRate * refundMultiple)
            newRow.push(refundMultiple < 0 ? 'Refund' : '')

            xlExport.push(newRow)
            rowCounter++

            totalValue += invData[i].invoiceDetails[j].totalValue * exchangeRate * refundMultiple
            totalTax += invData[i].invoiceDetails[j].totalTax * exchangeRate * refundMultiple
            total += invData[i].invoiceDetails[j].total * exchangeRate * refundMultiple
          }
        } else {
          if (invData[i].totalTax > 0) {
            let newRow = []
            newRow.push(rowCounter)
            newRow.push(unescapeHtml(invData[i].company))
            newRow.push(invData[i].invoiceNum)
            newRow.push(invData[i].createdDate)
            newRow.push(invData[i].status)
            newRow.push('')
            newRow.push('')
            newRow.push(invData[i].totalValue * exchangeRate * refundMultiple)
            newRow.push(invData[i].totalTax * exchangeRate * refundMultiple)
            newRow.push((invData[i].totalValue + invData[i].totalTax) * exchangeRate * refundMultiple)
            newRow.push(refundMultiple < 0 ? 'Refund' : '')
            xlExport.push(newRow)
            rowCounter++

            totalValue += invData[i].totalValue * exchangeRate * refundMultiple
            totalTax += invData[i].totalTax * exchangeRate * refundMultiple
            total += (invData[i].totalValue + invData[i].totalTax) * exchangeRate * refundMultiple
          }
        }
      }

      let newRow = []
      newRow.push('')
      newRow.push('')
      newRow.push('')
      newRow.push('')
      newRow.push('')
      newRow.push('')
      newRow.push('Total')
      newRow.push(totalValue)
      newRow.push(totalTax)
      newRow.push(total)
      xlExport.push(newRow)

      let sheetName = 'All'

      if (k > 0) {
        let dt = new Date(Date.parse(workSheetNames[k]))
        sheetName = dt.toLocaleDateString('default', { year: 'numeric', month: 'long' })
      }

      XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })
      XLSX.utils.book_append_sheet(wb, ws, sheetName)
    }

    XLSX.writeFile(wb, `${currency} - Tax Report.xlsx`)
  }

  const onClickUpcomingInvoices = () => {
    setConfirm({
      show: true,
      title: 'Create upcoming invoices',
      text: `Are you sure you want to create all upcoming invoices?`,
      type: 'upcoming',
    })
  }

  const afterPdfPrint = () => {
    setShowPdfComponent(false)
  }

  const handleCreateUpcomingInvoices = async () => {
    if (isLoading) return
    setIsLoading(true)
    const res = await createUpcomingInv()
    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (Array.isArray(res.data) && gridApi.current) {
      res.data.reverse()
      gridApi.current.applyTransaction({
        add: res.data,
        addIndex: 0,
      })
    }

    if (!_isMounted.current) return
    setIsLoading(false)
    setStatus({ show: true, severity: 'success', message: 'Upcoming invoices created' })
  }

  const handleUpdateInvoiceDetails = async (data) => {
    if (!data) return
    if (!gridApi.current) return
    data = htmlSymbolHandling(data)

    let res = null
    data.data.rowNum = data.data.sequenceNum
    data.data.recordId = data.recordId
    if (!data.data.qty) data.data.qty = 0
    if (!data.data.value) data.data.value = 0

    const orgData = gridApi.current.getRowNode(data.recordId)
    if (!orgData) return
    let orgLineItems = Array.isArray(orgData.data.invoiceDetails) ? cloneDeep(orgData.data.invoiceDetails) : []

    let returnData = null
    if (data.action === 'delete') {
      if (isDeleting.current) return
      isDeleting.current = true
      res = await deleteInvoiceLineItem(data.data)
      isDeleting.current = false
      returnData = cloneDeep(data.data)
    }

    if (data.action === 'add') {
      if (isAdding.current) return
      isAdding.current = true
      res = await addInvoiceLineItem(data.data)
      isAdding.current = false

      if (Array.isArray(res?.data?.invoiceDetails)) {
        const { invoiceDetails } = res.data
        for (let i = 0; i < invoiceDetails.length; i++) {
          let foundItem = orgLineItems.find((item) => item.sequenceNum === invoiceDetails[i].sequenceNum)
          if (!foundItem) {
            returnData = cloneDeep(invoiceDetails[i])
            break
          }
        }
      }
    }

    if (data.action === 'update') {
      if (isUpdating.current) return
      isUpdating.current = true
      res = await updateInvoiceLineItem(data.data)
      isUpdating.current = false

      if (Array.isArray(res?.data?.invoiceDetails)) {
        const { invoiceDetails } = res.data
        let lineItem = invoiceDetails.find((item) => item.sequenceNum === data.data.sequenceNum)
        if (lineItem) returnData = cloneDeep(lineItem)
      }
    }

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res.data) {
      gridApi.current.applyTransaction({
        update: [res.data],
      })

      return returnData
    }

    return null
  }

  const handleInvoiceStatusChange = () => {
    confirmStatusChangeRef.current = false
    if (!selectedInvoice.current) return

    selectedInvoice.current.status = 'Due'
    handleUpdate(cloneDeep(selectedInvoice.current))
    selectedInvoice.current = null
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        {
          <InvoiceDetailsGrid
            invoiceData={params.data}
            handleUpdate={handleUpdateInvoiceDetails}
            dropDowns={dropDowns}
          />
        }
      </Box>
    )
  })

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      <Box
        sx={{
          backgroundColor: getBackColor(),
          padding: '5px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {confirmStatusChange.show ? (
          <ConfirmDialog
            title={'Change invoice status'}
            open={confirmStatusChange?.show}
            setOpen={() => {
              confirmStatusChangeRef.current = false
              setConfirmStatusChange({ show: false })
            }}
            onConfirm={() => {
              handleInvoiceStatusChange()
            }}>
            {confirmStatusChange?.text}
          </ConfirmDialog>
        ) : null}
        {confirm.show ? (
          <ConfirmDialog
            title={confirm?.title}
            open={confirm?.show}
            setOpen={() => setConfirm({ show: false })}
            onConfirm={() => (confirm.type === 'delete' ? handleDelete() : handleCreateUpcomingInvoices())}>
            {confirm?.text}
          </ConfirmDialog>
        ) : null}
        <Box sx={{ flex: 1, marginLeft: '10px', marginRight: '10px' }}>
          <DropDownPicker
            listOptions={[
              { label: 'All', value: 'All' },
              { label: 'Current Month', value: 'Current Month' },
              { label: 'Previous Month', value: 'Previous Month' },
              { label: 'Last 30 Days', value: 'Last 30 Days' },
              { label: 'Last 60 Days', value: 'Last 60 Days' },
              { label: 'Last 6 Months', value: 'Last 6 Months' },
              { label: 'Current Year', value: 'Current Year' },
              { label: 'Last Year', value: 'Last Year' },
            ]}
            value={dateFilter}
            onChange={(newValue) => {
              setDateFilter(newValue)
            }}
          />
        </Box>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle(6)}
        />
      </Box>
      <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          loading={isLoading}
          rowData={invoiceData}
          columnDefs={sortColDefs(columnDefs, 'innovaInvoiceGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getContextMenuItems={getContextMenuItems}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          headerHeight={30}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          detailRowAutoHeight={true}
        />
      </div>
      {showPdfComponent ? (
        <Box
          style={{
            display: 'none',
          }}>
          <HtmlPdf
            htmlString={htmlPdfData.current?.html}
            fileName={htmlPdfData.current?.fileName}
            cleanUpFunction={afterPdfPrint}
            forcePrint={forceRePrint}
          />
        </Box>
      ) : null}
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchInvoiceData()
              },
            },
            {
              icon: <Iconify icon='tabler:report-money' style={{ fontSize: '30px', color: 'green' }} />,
              name: 'Export Revenue Summary',
              onClick: onXlsxSummaryExport,
            },
            {
              icon: <Iconify icon='la:flag-usa' style={{ fontSize: '36px', color: 'black' }} />,
              name: 'Texas Sales tax report',
              onClick: () => onTaxExport('USD'),
            },
            {
              icon: (
                <Iconify
                  icon='emojione-monotone:globe-showing-europe-africa'
                  style={{ fontSize: '36px', color: 'black' }}
                />
              ),
              name: 'VAT Report',
              onClick: () => onTaxExport('GBP'),
            },
            {
              icon: <Iconify icon='wpf:future' style={{ fontSize: '36px' }} />,
              name: 'Create upcoming invoices',
              onClick: () => onClickUpcomingInvoices(),
            },
            {
              icon: (
                <Iconify
                  icon={showChart ? 'bxs:hide' : 'bxs:show'}
                  style={{ color: appColors.itemTextColor, width: 28, height: 28 }}
                />
              ),
              name: showChart ? 'Hide Charts' : 'Show Charts',
              onClick: () => setShowChart(!showChart),
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default InnovaInvoiceGrid
