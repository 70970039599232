import React, { useCallback, useMemo } from 'react'
import 'components/Analytics/Charts/chart.css'
import { Line } from 'react-chartjs-2'
import { appColors } from 'utils'
import { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const DirectionalScoreCardChart = ({ wellData, getUnitsText }) => {
  const { theme } = useInnovaTheme()

  const interpolate = useCallback(
    (md, usePlan = false, param = 'dls') => {
      if (!wellData) return -999.25
      let surveys = usePlan ? wellData.plan : wellData.surveys

      if (!Array.isArray(surveys)) return -999.25

      if (surveys.length <= 1) return -999.25
      if (!md) return -999.25
      if (typeof md !== 'number') return -999.25
      if (md < surveys[0].md) return -999.25
      if (md > surveys[surveys.length - 1].md) return -999.25

      for (let i = 1; i < surveys.length; i++) {
        if (md < surveys[i - 1].md || md > surveys[i].md) continue
        let x0 = surveys[i - 1].md
        let x1 = surveys[i].md
        let y0 = surveys[i - 1][param]
        let y1 = surveys[i][param]
        let x = md

        return y0 + (y1 - y0) * ((x - x0) / (x1 - x0))
      }

      return -999.25
    },
    [wellData],
  )

  const getMaxSvyDepth = useCallback(() => {
    if (!wellData) return 0

    let maxSvy = 0
    if (Array.isArray(wellData.surveys) && wellData.surveys.length > 0) {
      maxSvy = wellData.surveys[wellData.surveys.length - 1].md
    }

    let maxPlan = 0
    if (Array.isArray(wellData.plan) && wellData.plan.length > 0) {
      maxPlan = wellData.plan[wellData.plan.length - 1].md
    }

    return maxSvy > maxPlan ? maxSvy : maxPlan
  }, [wellData])

  const getTitle = useMemo(() => {
    if (!wellData) return ''
    let title = 'Using motor DLS limits for curve'
    if (wellData.curveUsesRss) title += 'Using RSS DLS limits for curve'
    if (wellData.useArtificialLiftCriteria) title += ' / Using artificial lift criteria pior to KOP'
    return title
  }, [wellData])

  const options = useMemo(
    () => ({
      layout: {
        padding: {
          right: 40,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        GradientPlugIn: {
          showGradient: true,
          theme: theme,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: function (item, chart) {
              return !item.text.includes('-')
            },
          },
        },
        tooltip: {
          displayColors: false,
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: function (tooltipItem) {
              return `MD: ${numberWithCommasDecimals(tooltipItem[0].parsed.x, 2)} ${getUnitsText(UNITS_FOR.Depth)}`
            },
            label: function (context) {
              return [
                `Act DLS: ${numberWithCommasDecimals(interpolate(context.parsed.x, false, 'dls'), 2)}`,
                `Plan DLS: ${numberWithCommasDecimals(interpolate(context.parsed.x, true, 'dls'), 2)}`,
                `Act Inc: ${numberWithCommasDecimals(interpolate(context.parsed.x, false, 'inc'), 2)}`,
                `Plan Inc: ${numberWithCommasDecimals(interpolate(context.parsed.x, true, 'inc'), 2)}`,
                `Build Rate: ${numberWithCommasDecimals(Math.abs(interpolate(context.parsed.x, false, 'br')), 2)}`,
                `Eff TR: ${numberWithCommasDecimals(Math.abs(interpolate(context.parsed.x, false, 'effectiveTr')), 2)}`,
              ]
            },
          },
          filter: function (tooltipItem, index) {
            if (index > 0) return false
            return true
          },
        },
        title: {
          display: true,
          text: getTitle,
          color: appColors.headerTextColor,
          font: {
            size: 14,
          },
        },
        zoom: {
          animation: {
            duration: 0,
          },
          pan: {
            enabled: true,
            mode: 'xy',
          },
          limits: {
            x: {
              min: 0,
              max: getMaxSvyDepth(),
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          title: {
            display: true,
            text: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
            color: appColors.headerTextColor,
          },
          grid: {
            color: '#404040',
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: `DLS (${getUnitsText(UNITS_FOR.Dogleg)})`,
            color: appColors.headerTextColor,
          },
          grid: {
            color: '#404040',
          },
        },
        y2: {
          beginAtZero: true,
          title: {
            display: true,
            text: `Inc (deg)`,
            color: appColors.headerTextColor,
          },
          grid: {
            color: '',
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      animation: {
        duration: 0,
      },
    }),
    [getUnitsText, interpolate, getMaxSvyDepth, getTitle, theme],
  )

  const isInSectionRange = (md, section) => {
    if (!section) return false
    if (typeof md !== 'number') return false

    return md >= section.startDepth && md <= section.endDepth
  }

  const getDlsLimit = (md, level, curDls, curveyOnly = false) => {
    if (typeof level !== 'string') return 0
    if (typeof md !== 'number') return 0
    if (!wellData) return 0
    if (!wellData.wellSections) return 0

    let section = ''
    if (isInSectionRange(md, wellData.wellSections.vertical)) section = 'vertical'
    if (isInSectionRange(md, wellData.wellSections.tangent)) section = 'tangent'
    if (isInSectionRange(md, wellData.wellSections.curve)) section = 'curve'
    if (isInSectionRange(md, wellData.wellSections.lateral)) section = 'lateral'

    if (section !== 'curve' && curveyOnly) return 0

    if ((section === 'vertical' || section === 'tangent') && wellData.useArtificialLiftCriteria) {
      let kop = wellData.wellSections.curve.startDepth
      let criteria = wellData?.scoreCardCriteria?.ArtificialLift
      if (!criteria) return 0

      if (md > kop || kop === 0) return 0

      if (md < criteria.lengthSurfaceInterval) {
        return curDls < criteria.dlsLimitSurfaceInterval ? criteria.dlsLimitSurfaceInterval : curDls
      }

      if (md < kop * 0.25) {
        return curDls < criteria.dlsLimitFirstQuater ? criteria.dlsLimitFirstQuater : curDls
      }

      if (md < kop * 0.5) {
        return curDls < criteria.dlsLimitSecondQuater ? criteria.dlsLimitSecondQuater : curDls
      }

      if (md < kop * 0.75) {
        return curDls < criteria.dlsLimitThirdQuater ? criteria.dlsLimitThirdQuater : curDls
      }

      if (md < kop - criteria.lengthNoSteerZone) {
        return curDls < criteria.dlsLimitFourthQuater ? criteria.dlsLimitFourthQuater : curDls
      }

      return curDls < criteria.dlsLimitNoSteerZone ? criteria.dlsLimitNoSteerZone : curDls
    }

    if (section !== 'curve') {
      let criteria = wellData?.scoreCardCriteria[section]
      if (!criteria) return 0
      if (criteria.hasOwnProperty(level)) {
        return criteria[level]?.max
      }
    }

    if (section === 'curve') {
      let criteria = wellData.curveUsesRss ? wellData?.scoreCardCriteria.rss : wellData?.scoreCardCriteria.motor
      if (!criteria) return 0
      if (criteria.hasOwnProperty(level)) {
        if (!curveyOnly) return (1 + criteria[level]?.max) * curDls
        if (curveyOnly) return (1 - criteria[level]?.max) * curDls
      }
    }

    return 0
  }

  const newSeries = (
    svys,
    name,
    color,
    param = 'dls',
    yAxisID = 'y',
    borderDash = null,
    fillBetweenSeries = -1,
    curveOnly = false,
  ) => {
    let series = {
      type: 'line',
      label: name,
      data: [],
      showLine: fillBetweenSeries < 0 ? true : false,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      interpolate: true,
      steppedLine: true,
      yAxisID,
      fillBetweenSeries: fillBetweenSeries,
      fillBetweenColor: color,
    }

    if (Array.isArray(svys)) {
      if (fillBetweenSeries < 0) {
        series.data = svys.map((svy) => ({ x: svy.md, y: Math.abs(svy[param]) }))
      }

      if (fillBetweenSeries >= 0) {
        let level = ''
        if (color === 'rgba(0,255,0, 0.2)') level = 'excellent'
        if (color === 'rgba(255,165,0, 0.2)') level = 'good'
        if (color === 'rgba(255,0,0, 0.2)') level = 'notGood'

        series.data = svys.map((svy) => ({ x: svy.md, y: getDlsLimit(svy.md, level, svy.dls, curveOnly) }))
      }
    }

    if (Array.isArray(borderDash)) {
      series.borderDash = borderDash
    }

    return series
  }

  const getChartData = () => {
    const chartData = { datasets: [] }
    if (!wellData) return chartData

    let planCol = appColors.planTrajectoryColor
    let actCol = appColors.surveyTrajectoryColor

    chartData.datasets.push(newSeries(wellData.plan, 'Plan DLS', planCol))
    chartData.datasets.push(newSeries(wellData.surveys, 'Act DLS', actCol))
    chartData.datasets.push(newSeries(wellData.plan, 'Plan Inc', planCol, 'inc', 'y2', [5, 5]))
    chartData.datasets.push(newSeries(wellData.surveys, 'Act Inc', actCol, 'inc', 'y2', [5, 5]))
    chartData.datasets.push(newSeries(wellData.surveys, 'Build Rate', 'cyan', 'br', 'y', [2, 2]))
    chartData.datasets.push(newSeries(wellData.surveys, 'Eff Turn', 'purple', 'effectiveTr', 'y', [2, 2]))

    const excellentColor = 'rgba(0,255,0, 0.2)'
    const goodColor = 'rgba(255,165,0, 0.2)'
    const notGoodColor = 'rgba(255,0,0, 0.2)'

    chartData.datasets.push(newSeries(wellData.plan, '-', excellentColor, 'dls', 'y', null, 0))
    chartData.datasets.push(newSeries(wellData.plan, '-', goodColor, 'dls', 'y', null, chartData.datasets.length - 1))
    chartData.datasets.push(
      newSeries(wellData.plan, '-', notGoodColor, 'dls', 'y', null, chartData.datasets.length - 1),
    )

    chartData.datasets.push(newSeries(wellData.plan, '-', excellentColor, 'dls', 'y', null, 0, true))
    chartData.datasets.push(
      newSeries(wellData.plan, '-', goodColor, 'dls', 'y', null, chartData.datasets.length - 1, true),
    )
    chartData.datasets.push(
      newSeries(wellData.plan, '-', notGoodColor, 'dls', 'y', null, chartData.datasets.length - 1, true),
    )

    return chartData
  }

  return <Line options={options} data={getChartData()} />
}

export default DirectionalScoreCardChart
