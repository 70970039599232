import { cloneDeep } from 'lodash'
import { calcSurvey, findLanding } from 'utils/projectionCalcs'
import { pointInterp, calcVS } from 'utils/surveyFunctions'

const geoSteeringDataPointFromSurvey = (survey, thickup, thickdown) => {
    let geoSteeringData = []
    geoSteeringData.md = survey.md
    geoSteeringData.tvd = survey.tvd
    geoSteeringData.dip = survey.inc
    geoSteeringData.thicknessTop = thickup
    geoSteeringData.thicknessBottom = thickdown
    geoSteeringData.bottomTvd = survey.tvd + thickdown
    geoSteeringData.topTvd = survey.tvd - thickup
    geoSteeringData.uid = -999
    return geoSteeringData
}

const isOffsetPlanInflectionPoint = (svy, prevSvy) => {
    if (svy.inc < 1 && Math.abs(svy.inc) > 0.1) return false
    if (svy.inc > 120 && Math.abs(180 - svy.inc) > 0.1) return false
    let CL = svy.md - prevSvy.md
    if(CL > 0.06) return false
    return Math.abs(svy.inc - prevSvy.inc) > 0.1
}

const getPlanInflectionPointIndexes = (svys) => {
    let planInflectionPoints = []
    let curInflectionPoint = { first: -1, second: -1 }
    for (let i = 0; i < svys.length - 1; i++) {
        if (i === 0) {
            continue
        }
        if (isOffsetPlanInflectionPoint(svys[i], svys[i - 1])) {
            if (curInflectionPoint.first < 0) {
                curInflectionPoint.first = svys[i - 1].md
            } else {
                curInflectionPoint.second = svys[i].md
                planInflectionPoints.push(curInflectionPoint)
                curInflectionPoint = { first: -1, second: -1 }
            }
        }
    }

    return planInflectionPoints
}

const adjustDipBasedOnTurn = (survey, newPoint) => {
    if (survey.inc === 90) return

    let deltaAz = (Math.abs(newPoint.azi - survey.azi) * Math.PI) / 180
    if (deltaAz === 0) return

    let pi2 = Math.PI / 2
    let factor = Math.cos(deltaAz + pi2)

    if (deltaAz > pi2) {
        factor = -1 + Math.cos(deltaAz)
    }

    let deltaDip = (newPoint.inc - 90) * factor
    newPoint.inc += deltaDip
}

export const getFullGeoSteeringData = (geoData, verticalSection, actual, plan) => {
    if (!verticalSection) verticalSection = { VsAzi: 0, VsOrgNorth: 0, VsOrgEast: 0 }
    if (!Array.isArray(geoData)) geoData = []

    const { VsAzi, VsOrgNorth, VsOrgEast } = verticalSection
    let svys = []

    if (!Array.isArray(actual)) actual = []
    if (!Array.isArray(plan)) plan = []

    let usingPlan = true
    svys = plan
    if (svys.length === 0) svys = actual
    if (actual.length > 0 && svys.length > 0) {
        if (svys[svys.length - 1].md < actual[actual.length - 1].md) {
            usingPlan = false
            svys = actual
        }
    }

    if (svys.length === 0) return []
    let planInflectionPoints = []
    if (usingPlan) {
        planInflectionPoints = getPlanInflectionPointIndexes(svys)
    }
    let defaultThickness = 20
    let landingPoint = findLanding(svys)

    //Add Landing Point
    let geoSteeringData = []
    if (landingPoint.md > 0) {
        if (geoData.length === 0) {
            geoSteeringData.push(geoSteeringDataPointFromSurvey(landingPoint, defaultThickness, defaultThickness))
        }
        if (geoData.length > 0) {
            if (landingPoint.md < geoData[0].md) {
                geoSteeringData.push(
                    geoSteeringDataPointFromSurvey(landingPoint, geoData[0].thicknessTop, geoData[0].thicknessBottom),
                )
            }
        }
    }

    //Add Geosteering Data
    for (let i = 0; i < geoData.length; i++) {
        //IF using a plan then need to adjust the measured depths of the geo steering points to accoutn for the steps in the plan
        let additionalMD = 0
        for (let j = 0; j < planInflectionPoints.length; j++) {
            if (geoData[i].md >= planInflectionPoints[j].first) {
                additionalMD += planInflectionPoints[j].second - planInflectionPoints[j].first
            }
        }
        geoSteeringData.push(geoData[i])
        geoSteeringData[geoSteeringData.length - 1].md += additionalMD
    }

    //Add Last Point
    if (geoSteeringData.length > 0) {
        if (geoSteeringData[geoSteeringData.length - 1].md < svys[svys.length - 1].md) {
            geoSteeringData.push(
                geoSteeringDataPointFromSurvey(
                    svys[svys.length - 1],
                    geoSteeringData[geoSteeringData.length - 1].thicknessTop,
                    geoSteeringData[geoSteeringData.length - 1].thicknessBottom,
                ),
            )
        }
    }

    //Interpolate points
    let interpSurveyPoints = []
    for (let i = 0; i < geoSteeringData.length - 1; i++) {
        let startZone = pointInterp(svys, geoSteeringData[i].md)
        if (startZone.md < 0) continue

        startZone.thickup = geoSteeringData[i].thicknessTop
        startZone.thickdown = geoSteeringData[i].thicknessBottom
        startZone.tvd = geoSteeringData[i].tvd
        startZone.inc = geoSteeringData[i].dip
        startZone.vs = calcVS(VsAzi, startZone.ns, startZone.ew, VsOrgNorth, VsOrgEast)
        startZone.isInterpolated = false

        let endZone = pointInterp(svys, geoSteeringData[i + 1].md)
        if (endZone.md < 0) continue

        endZone.thickup = geoSteeringData[i].thicknessTop
        endZone.thickdown = geoSteeringData[i].thicknessBottom
        endZone.inc = geoSteeringData[i].dip
        endZone.vs = calcVS(VsAzi, endZone.ns, endZone.ew, VsOrgNorth, VsOrgEast)

        if (geoSteeringData[i].uid !== -999) {
            endZone.inc = startZone.inc
            adjustDipBasedOnTurn(startZone, endZone)

            let tvdSurvey = cloneDeep(endZone)
            if (interpSurveyPoints.length > 0) {
                calcSurvey(interpSurveyPoints[interpSurveyPoints.length - 1], tvdSurvey)
                endZone.tvd = tvdSurvey.tvd
            } else {
                endZone.tvd = startZone.tvd
            }
        }

        interpSurveyPoints.push(startZone)

        let psuedoSurveyPoints = []
        psuedoSurveyPoints.push(startZone)
        for (let j = 0; j < svys.length; j++) {
            if (svys[j].md >= endZone.md) break

            if (svys[j].md <= psuedoSurveyPoints[psuedoSurveyPoints.length - 1].md) continue

            psuedoSurveyPoints.push(cloneDeep(svys[j]))
            if (geoSteeringData[i].uid !== -999) {
                psuedoSurveyPoints[psuedoSurveyPoints.length - 1].inc = startZone.inc
            }
            adjustDipBasedOnTurn(startZone, psuedoSurveyPoints[psuedoSurveyPoints.length - 1])
        }
        psuedoSurveyPoints.push(endZone)
        let curMD = startZone.md
        let deltaMD = 50
        while (curMD <= endZone.md - deltaMD) {
            let interpPoint = pointInterp(svys, curMD)
            if (interpPoint.md < 0) break

            if (geoSteeringData[i].uid !== -999) {
                let incSurvey = interpPoint
                incSurvey = pointInterp(psuedoSurveyPoints, incSurvey.md)
                if (incSurvey.md > 0) {
                    interpPoint.inc = incSurvey.inc
                }
                let tvdSurvey = cloneDeep(interpPoint)
                calcSurvey(interpSurveyPoints[interpSurveyPoints.length - 1], tvdSurvey)
                interpPoint.tvd = tvdSurvey.tvd
            }
            interpPoint.vs = calcVS(VsAzi, interpPoint.ns, interpPoint.ew, VsOrgNorth, VsOrgEast)
            interpPoint.thickup = startZone.thickup
            interpPoint.thickdown = startZone.thickdown
            interpPoint.isInterpolated = true
            curMD += deltaMD

            if (interpSurveyPoints.length > 0) {
                if (interpPoint.md <= interpSurveyPoints[interpSurveyPoints.length - 1].md) continue
            }
            interpSurveyPoints.push(interpPoint)
        }

        if (interpSurveyPoints.length > 0) {
            if (endZone.md <= interpSurveyPoints[interpSurveyPoints.length - 1].md) {
                continue
            }
        }
        interpSurveyPoints.push(endZone)
    }

    return interpSurveyPoints
}
