
import { fonts } from './fonts'
import { Text } from '@react-three/drei'

const TextLabel = ({ position, label, rotation, color, size }) => {
  if (typeof label !== 'string') return null
  if (label === '') return null

  return (
    <Text
      position-x={position.x}
      position-y={position.y}
      position-z={position.z}
      text={label}
      font={fonts.Roboto}
      fontSize={size ? `${size}` : '1'}
      anchorX='center'
      anchorY='middle'
      color={color}
      rotation={rotation ? [rotation.x, rotation.y, rotation.z] : [0, 0, 0]}
    />
  )
}

export default TextLabel