import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Canvas } from '@react-three/fiber'
import GridLines from 'components/ThreeDeeView/ChartComponents/Grid'
import OrientationCompass from 'components/ThreeDeeView/Controls/OrientationCompass'
import { Vector3, CatmullRomCurve3, DoubleSide, CircleGeometry, Matrix4, TubeGeometry } from 'three'
import ChartControls from 'components/ThreeDeeView/Controls/ChartControls'
import BhaInfoOverlay from 'components/WellPages/EngineeringDashboard/BhaAnalysis/ThreeDeeChart/BhaInfoOverlay'
import ChartOptions from 'components/WellPages/EngineeringDashboard/BhaAnalysis/ThreeDeeChart/ChartOptions'
import { debounce } from 'lodash'
import { Box } from '@mui/material'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'
import { lerpColor } from 'utils/colorFunctions'
import { normalize } from 'utils/numberFunctions.js'
import WorkSight from 'components/WellPages/EngineeringDashboard/BhaAnalysis/ThreeDeeChart/WorkSight'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SCALE = 1
const INITIAL_CAM_OFFSET = 30
const CAM_SPEED = 0.25
const CONTAINER_ID = 'bhaAnalysisthreeDeeChartContainer'

const MIN_DATA_COLOR = '#0000FF'
const MID_DATA_COLOR = '#00FF00'
const MAX_DATA_COLOR = '#FF0000'

const getBhaSegmentColor = (value, min, max) => {
  let normColor = normalize(value, min, max)
  if (normColor <= 0.5) {
    normColor *= 2
    return lerpColor(MIN_DATA_COLOR, MID_DATA_COLOR, normColor)
  }

  normColor = (normColor - 0.5) * 2
  return lerpColor(MID_DATA_COLOR, MAX_DATA_COLOR, normColor)
}

const getWellboreSurveys = (bhaData) => {
  if (!bhaData?.hasOwnProperty('results')) return null
  if (!Array.isArray(bhaData.results)) return null
  if (bhaData.results.length < 2) return null

  let surveys = []
  for (let i = 0; i < bhaData.results.length; i++) {
    let svy = bhaData.results[i].surveyStation
    surveys.push(new Vector3(svy.ns * SCALE, -svy.tvd * SCALE, svy.ew * SCALE))
  }

  return surveys
}

const Wellbore = ({ bhaData, segments = 16, display }) => {
  if (!display) return null
  if (!bhaData?.hasOwnProperty('results')) return null
  if (!Array.isArray(bhaData.results)) return null
  if (bhaData.results.length < 2) return null

  let wellboreId = bhaData.params.wellboreIdDepthUnits * SCALE
  let centerline = getWellboreSurveys(bhaData)
  const path = new CatmullRomCurve3(centerline)

  return (
    <>
      <mesh>
        <tubeGeometry args={[path, centerline.length > 64 ? centerline.length : 64, wellboreId, segments, false]} />
        <meshBasicMaterial attach='material' color={0xd3d3d3} transparent={true} opacity={0.25} side={DoubleSide} />
      </mesh>
    </>
  )
}

const rotatePoint = (inc, azi, pt) => {
  //NS = X
  //EW = Z
  //TVD = Y

  let newPoint = { x: 0, y: 0, z: 0 }

  //Rotate Inc
  let incRad = (inc * Math.PI) / 180
  newPoint.x = pt.x * Math.cos(incRad)
  newPoint.y = -pt.x * Math.sin(incRad)

  //Rotate Azimuth
  let distance = Math.sqrt(Math.pow(newPoint.x, 2) + Math.pow(newPoint.z, 2))
  let calcAziAngle = (azi * Math.PI) / 180 + Math.atan2(newPoint.x, newPoint.z)
  newPoint.x = Math.sin(calcAziAngle) * distance
  newPoint.z = Math.cos(calcAziAngle) * distance

  return newPoint
}

const calcoffset = (pt) => {
  let offset = { x: pt.hsDefDepthUnits, y: 0, z: pt.rsDefDepthUnits }
  return rotatePoint(pt.surveyStation.inc, pt.surveyStation.azi, offset)
}

const getBhaSurveys = (points) => {
  if (!points) return null
  if (!Array.isArray(points)) return null
  if (points.length < 2) return null

  let surveys = []
  for (let i = 0; i < points.length; i++) {
    let svy = points[i].surveyStation
    let offset = calcoffset(points[i])
    surveys.push(new Vector3((svy.ns + offset.x) * SCALE, -(svy.tvd + offset.y) * SCALE, (svy.ew + offset.z) * SCALE))
  }

  return surveys
}

const getMinMaxColorValues = (bhaData, colorParam) => {
  if (!bhaData?.hasOwnProperty('results')) return null
  if (!Array.isArray(bhaData.results)) return null
  if (bhaData.results.length < 2) return null

  let minColorVal = Number.MAX_VALUE
  let maxColorVal = -1
  for (let i = 0; i < bhaData.results.length; i++) {
    let val = bhaData.results[i][colorParam]
    if (val < minColorVal) minColorVal = val
    if (val > maxColorVal) maxColorVal = val
  }

  return { minColorVal, maxColorVal }
}

const Bha = ({ bhaData, colorParam }) => {
  if (!bhaData?.hasOwnProperty('results')) return null
  if (!Array.isArray(bhaData.results)) return null
  if (bhaData.results.length < 2) return null

  let { minColorVal, maxColorVal } = getMinMaxColorValues(bhaData, colorParam)

  return (
    <>
      {bhaData.results.map((pt, index) => {
        if (index >= bhaData.results.length - 1) return null

        return (
          <BhaSegment
            key={index}
            points={[bhaData.results[index], bhaData.results[index + 1]]}
            od={bhaData.results[index].odDepthUnits}
            color={getBhaSegmentColor(bhaData.results[index][colorParam], minColorVal, maxColorVal)}
          />
        )
      })}
    </>
  )
}

const BhaSegment = ({ points, segments = 16, od = 1, color }) => {
  if (!points) return null
  if (!Array.isArray(points)) return null
  if (points.length < 2) return null

  let centerLine = getBhaSurveys(points)
  const path = new CatmullRomCurve3(centerLine)
  const tubeGeometry = new TubeGeometry(path, segments, od * SCALE, segments, false)

  const endCapGeometry = new CircleGeometry(od * SCALE, 48).applyMatrix4(
    new Matrix4()
      .setPosition(path.getPoint(0))
      .lookAt(path.getPoint(0), new Vector3(...centerLine[1]), new Vector3(1, 0, 0)),
  )

  const combinedGeometry = BufferGeometryUtils.mergeGeometries([tubeGeometry, endCapGeometry])

  return (
    <>
      <mesh>
        <primitive object={combinedGeometry} attach='geometry' />
        <meshBasicMaterial attach='material' color={color} side={DoubleSide} />
      </mesh>
    </>
  )
}

const ContactForces = ({ bhaData, display }) => {
  if (!display) return null
  if (!bhaData?.hasOwnProperty('results')) return null
  if (!Array.isArray(bhaData.results)) return null
  if (bhaData.results.length < 2) return null

  let { minColorVal, maxColorVal } = getMinMaxColorValues(bhaData, 'totalContactForce')

  let MAX_ARROW_LENGTH = 3

  return (
    <>
      {bhaData.results.map((pt, index) => {
        let deflectionOffset = calcoffset(pt)
        let tfAngle = Math.atan2(pt.hsDef, pt.rsDef)
        let dist = pt.odDepthUnits
        let odOffset = rotatePoint(pt.surveyStation.inc, pt.surveyStation.azi, {
          x: dist * Math.sin(tfAngle),
          y: 0,
          z: dist * Math.cos(tfAngle),
        })

        odOffset.x += pt.surveyStation.ns + deflectionOffset.x
        odOffset.y += pt.surveyStation.tvd + deflectionOffset.y
        odOffset.z += pt.surveyStation.ew + deflectionOffset.z

        let normalizedContactForce = normalize(pt.totalContactForce, minColorVal, maxColorVal) * MAX_ARROW_LENGTH
        let arrowLength = normalizedContactForce * MAX_ARROW_LENGTH

        //Makes bigger arrows have smaller heads
        //Allows smaller arrows to be visible
        let scaledArrowHeadSize = Math.max(0.1, 1 - normalizedContactForce)

        return pt.totalContactForce > 0 ? (
          <arrowHelper
            args={[
              new Vector3(-deflectionOffset.x, -deflectionOffset.y, -deflectionOffset.z).normalize(),
              new Vector3(odOffset.x * SCALE, -odOffset.y * SCALE, odOffset.z * SCALE),
              arrowLength,
              getBhaSegmentColor(pt.totalContactForce, minColorVal, maxColorVal),
              arrowLength * scaledArrowHeadSize, //Head length
              arrowLength * scaledArrowHeadSize, //Head width
            ]}
            key={index}
          />
        ) : null
      })}
    </>
  )
}

const BhaAnalysisThreeDeeChart = ({ bhaData, isToolbarExpanded, visible = true }) => {
  const cameraTargetRef = useRef(new Vector3(0, 0, 0))
  const { getBackColor, theme } = useInnovaTheme()
  const focusedSurveyIndexRef = useRef(-1)
  const [focusedSurveyIndex, setFocusedSurveyIndex] = useState(0)
  const [chartData, setChartData] = useState({
    refData: [],
  })

  const [displaySettings, setDisplaySettings] = useState({
    showWellbore: true,
    showOverlay: true,
    showContactForce: true,
    showVerticalScale: true,
    colorByBendingMoment: true,
    showWorkSight: true,
  })

  useEffect(() => {
    if (!bhaData) return

    //This is a bit of hack to allow the re-use of the chartcontrols component
    setChartData({ refData: [{ data: getWellboreSurveys(bhaData) }] })

    if (chartData?.refData[0]?.data?.length > 0) {
      cameraTargetRef.current = new Vector3(
        chartData?.refData[0].data[0].x,
        chartData?.refData[0].data[0].y,
        chartData?.refData[0].data[0].z,
      )
    }

    handleFocusedSurveyIndexChange(focusedSurveyIndexRef.current)
  }, [bhaData]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceFocusedSurveyIndexChange = debounce((newIndex) => {
    setFocusedSurveyIndex(newIndex)
  }, 50)

  const handleFocusedSurveyIndexChange = useCallback(
    (newIndex) => {
      debounceFocusedSurveyIndexChange(newIndex)
    },
    [debounceFocusedSurveyIndexChange],
  )

  useEffect(() => {
    handleFocusedSurveyIndexChange(focusedSurveyIndexRef.current)
  }, [focusedSurveyIndexRef, handleFocusedSurveyIndexChange])

  const getBhaPoint = (index) => {
    if (!Array.isArray(bhaData?.results)) return null
    if (bhaData.results.length <= 0) return null
    if (index < 0) return bhaData.results[0]
    if (index >= bhaData.results.length) {
      return bhaData.results[bhaData.results.length - 1]
    }

    return bhaData.results[index]
  }

  const getLeftOffset = () => {
    if (isToolbarExpanded) return DRAWERWIDE + 15
    return DRAWERSLIM + 15
  }

  const getWorksightOd = () => {
    if (!bhaData) return 1
    if (!bhaData.hasOwnProperty('params')) return 1
    return bhaData?.params?.wellboreIdDepthUnits
  }

  return (
    <div
      id={CONTAINER_ID}
      style={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        display: visible ? 'flex' : 'none',
      }}>
      <Canvas
        style={{ width: '100%', height: '100%', background: getBackColor() }}
        flat
        gl={{ preserveDrawingBuffer: true }}
        camera={{ position: [-INITIAL_CAM_OFFSET, INITIAL_CAM_OFFSET / 2, 5], fov: 24, near: 0.1, far: 5000 }}
        onCreated={({ camera }) => {
          camera.lookAt(0, 0, 0)
        }}>
        <ChartControls
          id={CONTAINER_ID}
          chartData={chartData}
          focusedSurveyIndex={focusedSurveyIndexRef}
          camSpeed={CAM_SPEED}
          setFocusedSurveyIndex={handleFocusedSurveyIndexChange}
          cameraTarget={cameraTargetRef}
          invertControls={true}
        />
        <OrientationCompass cameraTarget={cameraTargetRef} />
        <ambientLight color={'#FFFFFF'} intensity={1} />
        <GridLines
          scale={SCALE}
          bhaData={getWellboreSurveys(bhaData)}
          showVerticalSides={displaySettings.showVerticalScale}
          labelColor={theme === 'dark' ? 0x909090 : 0x000000}
          gridColor={theme === 'dark' ? 0x909090 : 0x000000}
        />
        <Bha
          bhaData={bhaData}
          colorParam={displaySettings.colorByBendingMoment ? 'totalBendingMoment' : 'totalShearLoad'}
        />
        <ContactForces bhaData={bhaData} display={displaySettings.showContactForce} />
        <Wellbore bhaData={bhaData} display={displaySettings.showWellbore} />
        {displaySettings.showWorkSight ? (
          <WorkSight focusedSurveyIndex={focusedSurveyIndexRef} bhaData={bhaData} scale={SCALE} od={getWorksightOd()} />
        ) : null}
      </Canvas>
      {displaySettings.showOverlay ? (
        <BhaInfoOverlay
          bhaPoint={getBhaPoint(focusedSurveyIndex)}
          leftOffset={`${getLeftOffset()}px`}
          minDataColor={MIN_DATA_COLOR}
          midDataColor={MID_DATA_COLOR}
          maxDataColor={MAX_DATA_COLOR}
          minMaxValues={getMinMaxColorValues(
            bhaData,
            displaySettings.colorByBendingMoment ? 'totalBendingMoment' : 'totalShearLoad',
          )}
        />
      ) : null}
      <Box sx={{ position: 'absolute', display: 'flex', bottom: '5px', left: `${getLeftOffset()}px` }}>
        <ChartOptions
          displaySettings={displaySettings}
          setDisplaySettings={setDisplaySettings}
          minDataColor={MIN_DATA_COLOR}
          midDataColor={MID_DATA_COLOR}
          maxDataColor={MAX_DATA_COLOR}
          minMaxValues={getMinMaxColorValues(
            bhaData,
            displaySettings.colorByBendingMoment ? 'totalBendingMoment' : 'totalShearLoad',
          )}
        />
      </Box>
    </div>
  )
}

export default BhaAnalysisThreeDeeChart
