import React, { useEffect, useRef } from 'react'
import { Icon as Iconify } from '@iconify/react'
import { Box } from '@mui/material'
import CostCodesGrid from './CostCodesGrid'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const CostCodesPane = ({ showHidePane, paneVisible, wellName, refreshCosts }) => {
  const _isMounted = useRef(false)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          width: '25px',
          height: '100%',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: paneVisible ? 'bold' : '400',
            color: paneVisible ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            showHidePane()
          }}>
          Cost Codes
        </Box>
      </Box>
      {paneVisible ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 25px)',
            height: '100%',
            backgroundColor: 'red',
          }}>
          <CostCodesGrid wellName={wellName} refreshCosts={refreshCosts} />
        </Box>
      ) : null}
    </Box>
  )
}

export default CostCodesPane
