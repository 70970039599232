import React, { Fragment } from 'react'
import { Text, Image, View } from '@react-pdf/renderer'
import PdfTable from './PdfTable'

const PdfHeader = ({ headerData, reportSettings }) => {
  if (!headerData) return null

  const getLogo = () => {
    if (!headerData.logo) return null
    return headerData.logo
  }

  const getLogoSecondary = () => {
    if (!headerData.logoSecondary) return null
    return headerData.logoSecondary
  }

  const getLogoWidth = () => {
    if (!headerData.showLogo) return '0%'
    if (!headerData.logoWidth) return '30%'
    if (typeof headerData.logoWidth !== 'string') return '30%'
    return headerData.logoWidth
  }

  const getLogoFill = () =>{
    if (!headerData.showLogo) return 'contain'
    if (!headerData.logoFill) return 'contain'
    if (typeof headerData.logoFill !== 'string') return 'contain'
    return headerData.logoFill
  }

  const getLogoSecondaryWidth = () => {
    if (!headerData.showLogo) return '0%'
    if (!headerData.logoSecondaryWidth) return '30%'
    if (typeof headerData.logoSecondaryWidth !== 'string') return '30%'
    return headerData.logoSecondaryWidth
  }

  const getLogoSecondaryFill = () =>{
    if (!headerData.showLogo) return 'contain'
    if (!headerData.logoSecondaryFill) return 'contain'
    if (typeof headerData.logoSecondaryFill !== 'string') return 'contain'
    return headerData.logoSecondaryFill
  }

  const getNumericWidth = (width) => {
    if (!width) return 0
    if (typeof width === 'number') return width
    return parseFloat(width.replace('%', ''))
  }

  const getTableWidth = () => {
    if (!headerData) return '100%'
    if (!headerData?.showLogo) return '100%'

    let tableWidth = 100

    if (isLogoValid()) {
      let logoWidth = getNumericWidth(headerData?.logoWidth)
      if (logoWidth === 0) logoWidth = 30
      tableWidth -= logoWidth
    }

    if (isLogoSecondaryValid()) {
      let logoWidth = getNumericWidth(headerData.logoSecondaryWidth)
      if (logoWidth === 0) logoWidth = 30
      tableWidth -= logoWidth
    }

    return `${tableWidth}%`
  }

  const isLogoValid = () => {
    if (!headerData?.logo || typeof headerData?.logo !== 'string' || headerData.logo === '') return false
    return true
  }

  const isLogoSecondaryValid = () => {
    if (!headerData?.logoSecondary || typeof headerData?.logoSecondary !== 'string' || headerData.logoSecondary === '')
      return false
    return true
  }

  return (
    <Fragment>
      {headerData.showTitle ? (
        <View
          style={{
            margin: 4,
          }}>
          <Text style={{ fontSize: 11, fontWeight: 'bold', fontFamily: 'Roboto' }}>{headerData.title}</Text>
        </View>
      ) : null}
      <View
        style={{
          width: '100%',
        }}>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          {headerData.showLogo && isLogoValid() ? (
            <Image
              src={getLogo()}
              style={{ width: getLogoWidth(), height: 60, objectFit: getLogoFill(), alignSelf: 'center' }}></Image>
          ) : null}
          {headerData.showLogo && isLogoSecondaryValid() ? (
            <Image
              src={getLogoSecondary()}
              style={{ width: getLogoSecondaryWidth(), height: 60, objectFit: getLogoSecondaryFill(), alignSelf: 'center' }}></Image>
          ) : null}
          {headerData.data ? (
            <View style={{ flexDirection: 'col', justifyContent: 'flex-start', width: getTableWidth() }}>
              <PdfTable data={headerData} reportSettings={reportSettings} />
            </View>
          ) : null}
        </View>
      </View>
    </Fragment>
  )
}

export default PdfHeader
