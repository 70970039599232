import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { Box, IconButton, Snackbar } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DepthDatumGrid = ({ wellName, setSelectedDatum }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const selectedDatum = useRef(null)
  const [isLoading, setLoading] = useState(false)
  const [depthDatumData, setDepthDatumDataData] = useState([])
  const inputRow = useRef({})
  const tvdAdjustOptionsRef = useRef(null)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [confirmDelete, setConfirmDelete] = useState({ show: false, title: '' })
  const { getAgGridTheme } = useInnovaTheme()

  const getDepthDatums = useInnovaAxios({
    url: '/well/getDepthDatums',
  })

  const updateDepthDatum = useInnovaAxios({
    url: '/well/updateDepthDatum',
  })

  const deleteDepthDatum = useInnovaAxios({
    url: '/well/deleteDepthDatum',
  })

  const addDepthDatum = useInnovaAxios({
    url: '/well/addDepthDatum',
  })

  const adjustDatumElevation = useInnovaAxios({
    url: '/well/adjustDatumElevation',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDepthDatums()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchDepthDatums()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  function sortDepthDatums(a, b) {
    // Move 'default' to the front
    if (a.name === 'default') return -1
    if (b.name === 'default') return 1

    // Sort alphabetically by name
    return a.name.localeCompare(b.name)
  }

  const fetchDepthDatums = async () => {
    if (isLoading) return
    if (!wellName) return
    setLoading(true)

    const response = await getDepthDatums({ wellName: wellName })

    if (response?.error) return
    if (!_isMounted.current) return

    setDepthDatumDataData(Array.isArray(response.data) ? response.data.sort(sortDepthDatums) : [])
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (selectedRows.length > 0) {
      setSelectedDatum(selectedRows[0].data)
    }
  }

  const gridOptions = {
    loadingOverlayComponent: CustomLoadingOverlay,
    pinnedBottomRowData: [inputRow.current],
    onSelectionChanged: onSelectionChanged,
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      if (event.colDef.field === 'elevation' && event.oldValue !== event.newValue && isDatumInUse(event.data)) {
        tvdAdjustOptionsRef.current = {
          datumName: event.data.name,
          newElevation: event.newValue,
          deltaElevation: event.newValue - event.oldValue,
          well: wellName,
          preserveTvds: true,
        }

        setConfirm({
          show: true,
          title: 'TVD Adjustments',
          text: `Do you wish to preserve the TVD's for the wells using this datum?`,
        })
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const reqFields = useMemo(() => ['name', 'elevation'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [centerAlignCell, isEmptyPinnedCell, createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('depthDatumsGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    if (inputRow.current.name === '') return
    if (!wellName) return
    inputRow.current.wellName = wellName
    inputRow.current.rig = ''
    inputRow.current.airGap = 0
    inputRow.current.waterDepthGl = 0
    inputRow.current.isDefault = false
    inputRow.current.offshoreCheck = false
    inputRow.current.subseaCheck = false

    inputRow.current = htmlSymbolHandling(inputRow.current)

    isAdding.current = true
    let res = await addDepthDatum({ ...inputRow.current })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [wellName, addDepthDatum, isPinnedRowDataCompleted])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('name')) return

      if (isUpdating.current) return
      if (!data.offshoreCheck) data.subseaCheck = false
      if (!data.subseaCheck) data.wellheadElev = 0

      data = htmlSymbolHandling(data)

      isUpdating.current = true
      let res = await updateDepthDatum({ ...data })
      isUpdating.current = false

      if (!_isMounted.current) return
      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!Array.isArray(res.data)) return
      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: res.data.sort(sortDepthDatums),
        })
      }

      let selectedRows = gridApi.current.getSelectedNodes()
      if (Array.isArray(selectedRows) && selectedRows.length > 0) {
        if (selectedRows[0].data.uid === data.uid) setSelectedDatum(data)
      }
    },
    [updateDepthDatum, setSelectedDatum],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedDatum.current) return
    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteDepthDatum({ ...selectedDatum.current, datumName: selectedDatum.current.name })
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      selectedDatum.current = null
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedDatum.current],
      })
    }

    selectedDatum.current = null
  }, [deleteDepthDatum])

  const onClickDelete = useCallback(
    (data) => {
      selectedDatum.current = data
      setConfirmDelete({
        show: true,
        title: 'Delete depth datum',
        text: `Are you sure you want to delete ${data.name}?`,
        onConfirm: handleDelete,
      })
    },
    [handleDelete],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
            size='large'>
            {params.data?.name !== 'Default' ? (
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            ) : (
              <Box sx={{ width: '15px', height: '15px' }} />
            )}
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [onClickDelete, handleAddRow],
  )

  const isDatumInUse = useCallback((datumData) => {
    if (!datumData) return false
    if (!Array.isArray(datumData.inUse)) return false
    return datumData.inUse.length > 0
  }, [])

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        valueGetter: (params) => unescapeHtml(params.data?.name),
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
      },
      {
        field: 'rig',
        colId: 'rig',
        headerName: 'Rig',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueGetter: (params) => unescapeHtml(params.data?.rig),
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
      },
      {
        field: 'elevation',
        colId: 'elevation',
        headerName: 'Elevation',
        editable: (params) => params.data?.name !== 'Default',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Elevation...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 300000,
          precision: 2,
        },
      },
      {
        field: 'airGap',
        colId: 'airGap',
        headerName: 'Air Gap',
        editable: false,
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''

          let airGap = params.data?.offshoreCheck
            ? params.data?.elevation
            : params.data?.elevation - params.data?.waterDepthGl
          return numberWithCommasDecimals(airGap, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 300000,
          precision: 2,
        },
      },
      {
        field: 'waterDepthGl',
        colId: 'waterDepthGl',
        headerName: 'Water Depth / GL',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 300000,
          precision: 2,
        },
      },
      {
        field: 'wellheadElev',
        colId: 'wellheadElev',
        headerName: 'Wellhead Elevation',
        editable: (params) => params?.node?.rowPinned !== 'bottom' && params?.data?.subseaCheck,
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 300000,
          precision: 2,
        },
      },
      {
        field: 'isDefault',
        colId: 'isDefault',
        headerName: 'Default',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        field: 'offshoreCheck',
        colId: 'offshoreCheck',
        headerName: 'Offshore',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        field: 'subseaCheck',
        colId: 'subseaCheck',
        headerName: 'Subsea',
        editable: (params) => params?.node?.rowPinned !== 'bottom',
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        field: 'inUse',
        colId: 'inUse',
        headerName: 'In Use',
        editable: false,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
        valueGetter: (params) => Array.isArray(params.data?.inUse) && params.data?.inUse.length > 0,
      },
    ],
    [actionIconRenderer, isEmptyPinnedCell],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleTvdChange = useCallback(async () => {
    if (isUpdating.current) return
    if (!tvdAdjustOptionsRef.current) return
    setLoading(true)

    isUpdating.current = true
    const res = await adjustDatumElevation(tvdAdjustOptionsRef.current)
    isUpdating.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }
  }, [adjustDatumElevation])

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: '100%',
        marginLeft: 0,
        width: '100%',
        maxWidth: `100%`,
        maxHeight: `100%`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onNo={() => {
            tvdAdjustOptionsRef.current.preserveTvds = false
            handleTvdChange()
          }}
          onConfirm={() => {
            tvdAdjustOptionsRef.current.preserveTvds = true
            handleTvdChange()
          }}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {confirmDelete.show ? (
        <ConfirmDialog
          title={confirmDelete?.title}
          open={confirmDelete?.show}
          setOpen={() => setConfirmDelete({ show: false })}
          onConfirm={confirmDelete.onConfirm}>
          {confirmDelete?.text}
        </ConfirmDialog>
      ) : null}
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={depthDatumData}
            columnDefs={sortColDefs(columnDefs, 'depthDatumsGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            rowSelection={'single'}
            headerHeight={30}
            onGridReady={onGridReady}
            getRowId={getRowId}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default DepthDatumGrid
