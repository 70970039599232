import { useEffect, useRef } from 'react'

import { atom, useRecoilState } from 'recoil'
import { Buffer } from 'buffer'

import { multiParse } from 'utils'
import { removeFileExt } from 'utils/stringFunctions'
import useInnovaAxios from './useInnovaAxios'

export const bhaImagesAtom = atom({
  key: 'bhaImageAtomKey',
  default: [],
})

var _isInitialized = false

const drillStringFileMap = [
  { compType: 'Drill Bit', filename: '110' },
  { compType: 'Hole Opener', filename: '111' },
  { compType: 'Circ Sub', filename: '112' },
  { compType: 'Cross Over', filename: '113' },
  { compType: 'RSS', filename: '114' },
  { compType: 'MWD/LWD', filename: '115' },
  { compType: 'Motor', filename: '116' },
  { compType: 'Turbine', filename: '117' },
  { compType: 'Stabilizer', filename: '118' },
  { compType: 'Drill Collar', filename: '119' },
  { compType: 'Drill Pipe/HWDP', filename: '120' },
  { compType: 'Jar', filename: '121' },
  { compType: 'Accelerator', filename: '122' },
  { compType: 'Sub', filename: '123' },
  { compType: 'Liner', filename: '124' },
  { compType: 'Casing/Tubing', filename: '125' },
  { compType: 'Agitator', filename: '126' },
  { compType: 'Reamer', filename: '127' },
]

const useBhaImages = () => {
  const _isMounted = useRef(false)
  const [bhaPrintImages, setBhaPrintImages] = useRecoilState(bhaImagesAtom)

  const getAllBhaPrintImages = useInnovaAxios({
    url: '/well/drillString/getBhaImages',
  })

  useEffect(() => {
    _isMounted.current = true
    if (!_isInitialized) {
      _isInitialized = true
      fetchBhaImages()
    }
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBhaImages = async () => {
    const response = await getAllBhaPrintImages()
    if (_isMounted.current && response?.data) {
      const mfpBoundary = response.data.substring(2, response.data.search('Content')).trim()
      let parts = multiParse(Buffer.from(response.data), mfpBoundary)
      let images = parts.map((part) => {
        let imageObj = { ...part }
        imageObj.data = 'data:image/*;base64,' + part.data
        imageObj.filename = removeFileExt(part.filename)
        return imageObj
      })
      setBhaPrintImages(images)
    } else {
      _isInitialized = false
    }
  }

  const getPrintImage = (item, invertImage = false) => {
    const { type, bitType, stabOd } = item

    let isSlickMotor = false
    let imagePrefix = ''
    const imageObj = drillStringFileMap.find((tool) => tool.compType === type)
    if (imageObj) {
      switch (imageObj.compType) {
        case 'Motor':
          if (stabOd <= 0) {
            isSlickMotor = true
            imagePrefix = invertImage ? '121' : '9121' // use SlickMotor special case
          } else {
            imagePrefix = imageObj.filename
          }
          break
        case 'Drill Bit':
          if (bitType === 'PDC') imagePrefix = '110'
          else imagePrefix = '110' // if we get a rock bit image, set it's filename here
          break
        default:
          imagePrefix = imageObj.filename
          break
      }
      if (imagePrefix !== '') {
        if (invertImage && !isSlickMotor) {
          imagePrefix = '9' + imagePrefix
        }

        const bhaImage = bhaPrintImages.find((item) => item.filename === imagePrefix)
        if (bhaImage) return bhaImage.data
      }
    }
    return ''
  }

  return { getPrintImage }
}

export default useBhaImages
