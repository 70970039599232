export function checkPermission(requestedPermission, currentUserPermissions) {
  if (!currentUserPermissions) return false
  if (!currentUserPermissions.hasOwnProperty(requestedPermission)) return false
  if (currentUserPermissions[requestedPermission]) return true
  return false
}

export function checkFeature(featureId, currentUserFeatures) {
  if (!currentUserFeatures) return false
  if (!Array.isArray(currentUserFeatures)) return false
  if (currentUserFeatures.length === 0) return false

  let featureIdFull = `${process.env.REACT_APP_ICP_PRODUCT_KEY}-${parseInt(featureId)}`
  let featureEnabled = currentUserFeatures.findIndex((feature) => feature === featureIdFull)
  return featureEnabled >= 0
}
