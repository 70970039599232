import React, { useRef, useEffect, useState, useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import { Box, Avatar } from '@mui/material'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { getStatusAvatar } from 'components/common/activitySelector'
import { Icon as Iconify } from '@iconify/react'
import SearchBar from 'components/common/SearchBar'
import { throttle, cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const EngineeringCaseImportModal = ({ onClose, onApply }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [resetCols, setResetCols] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [selectedCase, setSelectedCase] = useState(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getCases = useInnovaAxios({
    url: '/engineering/getAllEngCases',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('engineeringImportGrid', 'colLayout', colLayout)
    }
  }

  const throttledSetQuickFilter = throttle((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    throttledSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchBhaData = async () => {
    if (isLoading) return
    setIsLoading(true)

    let res = await getCases()
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) return

    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', res.data)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    loadingOverlayComponent: CustomLoadingOverlay,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onRowGroupOpened: () => {
      setTimeout(() => {
        autoSizeColumns()
      }, 100)
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('engineeringImportGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      'expandAll',
      'contractAll',
      'autoSizeAll',
    ]
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchBhaData()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const GroupRowInnerRenderer = (props) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}>
          {props.node.field === 'wellName' ? (
            <Avatar
              alt={props.node.field}
              src={getStatusAvatar('Unknkown')}
              style={{ width: 35, height: 35, backgroundColor: '#03a9f4' }}
            />
          ) : null}
          {props.node.field === 'operator' ? (
            <Iconify icon='ic:baseline-oil-barrel' width='35' height='35' color='black' />
          ) : null}
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
      </Box>
    )
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    if (!_isMounted.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (!Array.isArray(selectedRows) || selectedRows.length === 0) {
      setSelectedCase(null)
    }

    if (selectedRows.length > 0) {
      setSelectedCase(cloneDeep(selectedRows[0]?.data))
    }
  }

  const columnDefs = useMemo(
    () => [
      {
        colId: 'operator',
        headerName: 'Operator',
        field: 'operator',
        rowGroup: true,
      },
      {
        colId: 'wellName',
        headerName: 'Well',
        field: 'wellName',
        rowGroup: true,
      },
      {
        colId: 'jobNum',
        field: 'jobNum',
        headerName: 'Job#',
        headerCheckboxSelection: false,
        checkboxSelection: true,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        colId: 'rig',
        field: 'rig',
        headerName: 'Rig',
      },
      {
        colId: 'state',
        field: 'state',
        headerName: 'State',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'county',
        field: 'county',
        headerName: 'County',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'wellStatus',
        field: 'wellStatus',
        headerName: 'Status',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'caseDesc',
        field: 'caseDesc',
        headerName: 'Description',
        valueFormatter: (params) => unescapeHtml(params.value),
      },
    ],
    [centerAlignCell],
  )

  const groupRowRendererParams = {
    suppressCount: true,
    innerRenderer: GroupRowInnerRenderer,
  }

  return (
    <Dialog
      maxWidth='lg'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Copy Engineering Case`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            marginBottom: '5px',
            border: '1px solid gray',
          }}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle(3)}
          />
        </Box>
        <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 80px)' }}>
          <AgGridReact
            columnDefs={sortColDefs(columnDefs, 'engineeringImportGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            loading={isLoading}
            gridOptions={gridOptions}
            headerHeight={30}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            groupRowRendererParams={groupRowRendererParams}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Cancel
        </Button>
        {selectedCase ? (
          <Button variant='outlined' onClick={() => onApply(selectedCase)} color='primary'>
            Import
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

export default EngineeringCaseImportModal
