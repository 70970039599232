import React, { useEffect, useRef, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import cloneDeep from 'lodash/cloneDeep'
import { isEqual } from 'lodash'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import SplitPane from 'components/common/SplitPane'
import FieldGrid from './FieldGrid'
import { useRecoilState } from 'recoil'
import { fieldPropertiesLayoutAtom, dateBaseTreeUpdateNameAtom } from 'atoms'
import { CircularProgress, Box, Tooltip, Alert, Snackbar } from '@mui/material'
import MapView from 'components/common/MapView/MapView'
import CoordinatesGrid from 'components/DatabaseTree/common/CoordinatesGrid'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'

const FieldPropertiesPage = ({ field, nodeId }) => {
  const _isMounted = useRef(false)
  const isUpdatingRef = useRef(false)
  const northRefChanged = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [fieldData, setFieldData] = useState(null)
  const [pageLayout, setPageLayout] = useRecoilState(fieldPropertiesLayoutAtom)
  const [dropDownData, setFieldDropDownData] = useState([])
  const [dataChanged, setDataChanged] = useState(false)
  const [isUpdating, setUpdating] = useState(false)
  const fieldDataRef = useRef(fieldData)
  const orgDataRef = useRef(fieldData)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [databaseTreeUpdateName, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const resetCoordinates = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getField = useInnovaAxios({
    url: '/field/getField',
  })

  const updateField = useInnovaAxios({
    url: '/field/updateField',
  })

  const getFieldDropDowns = useInnovaAxios({
    url: '/field/getDropDownLists',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!fieldData) return
    if (data.caller === 'UI') return
    if (data.nodeId !== nodeId) return

    let newData = cloneDeep(fieldData)
    newData.field = data.name
    fieldDataRef.current.field = data.name
    orgDataRef.current.field = data.name
    setFieldData(newData)
    setDataBaseTreeNameUpdate(null)
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '70%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getField({ field: field })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    fieldDataRef.current = cloneDeep(res.data[0])
    orgDataRef.current = cloneDeep(res.data[0])
    setFieldData(res.data[0])
  }

  const fetchDropDowns = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getFieldDropDowns()

    if (_isMounted.current) {
      setFieldDropDownData(response.data)
      setLoading(false)
    }
  }

  const updateFieldDataCoords = (data) => {
    if (!data) return

    let newData = cloneDeep(fieldDataRef.current)
    newData.latDeg = data.latDeg
    newData.longDeg = data.longDeg
    newData.gridNorth = data.gridNs
    newData.gridEast = data.gridEw
    newData.gridConv = data.gridConv

    if (data.inputMeth === 'dms' || data.inputMeth === 'deg') {
      newData.inputMeth = 'Lat Long'
    }

    if (data.inputMeth === 'grid') {
      newData.inputMeth = 'Map Coordinates'
    }

    setDataChanged(!isEqual(orgDataRef.current, newData))
    fieldDataRef.current = newData
    setFieldData(newData)
  }

  const updateFieldData = (data) => {
    if (!data) return

    let newData = cloneDeep(fieldDataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      if (data.tag === 'northRef' && data.value !== newData.northRef) {
        northRefChanged.current = true
      }

      newData[data.tag] = data.value

      if (newData.systemDatum !== 'User Defined') {
        newData.systemDatumName = newData.systemDatum
      }

      if (newData.systemDatum === 'MSL') {
        newData.systemElevation = 0
      }
    }

    setDataChanged(!isEqual(orgDataRef.current, newData))
    fieldDataRef.current = newData
    setFieldData(newData)
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    let res = await updateField(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setUpdating(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (orgDataRef.current.field !== data.field) {
      setDataBaseTreeNameUpdate({ name: data.field, nodeId: nodeId, caller: 'UI' })
    }

    orgDataRef.current = cloneDeep(data)
    northRefChanged.current = false
    setDataChanged(false)
  }

  const confirmAzimuthChanges = () => {
    setConfirm({
      show: true,
      title: 'North Reference Changed',
      text: `Warning: North Reference Changed do you wish to change all azimuths to match selection?`,
    })
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => (northRefChanged.current ? confirmAzimuthChanges() : handleUpdate(fieldDataRef.current))}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          fieldDataRef.current = cloneDeep(orgDataRef.current)
          resetCoordinates.current = true
          setDataChanged(false)
          setFieldData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const getFieldArray = () => {
    return fieldData
      ? [{ id: 0, actualWell: fieldData.field, latitude: fieldData.latDeg, longitude: fieldData.longDeg }]
      : []
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onNo={() => {
            fieldDataRef.current.shouldUpdateAzimuths = false
            handleUpdate(fieldDataRef.current)
          }}
          onConfirm={() => {
            fieldDataRef.current.shouldUpdateAzimuths = true
            handleUpdate(fieldDataRef.current)
          }}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(0)}
        allowResize={true}
        minSize={'10%'}
        defaultSize={getInitialPaneSize(0)}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          minSize={'10%'}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}>
          <FieldGrid fieldData={fieldData} dropDowns={dropDownData} handleUpdate={updateFieldData} />
          <CoordinatesGrid
            resetCoordinates={resetCoordinates}
            coords={
              fieldDataRef.current
                ? {
                    latDeg: fieldDataRef.current?.latDeg,
                    longDeg: fieldDataRef.current?.longDeg,
                    gridNs: fieldDataRef.current?.gridNorth,
                    gridEw: fieldDataRef.current?.gridEast,
                    localNs: 0,
                    localEw: 0,
                    gridConv: fieldDataRef.current?.gridConv,
                  }
                : null
            }
            options={{
              dms: true,
              deg: true,
              grid: true,
              local: false,
              input: fieldDataRef.current?.inputMeth === 'Lat Long' ? 'dms' : 'grid',
            }}
            crs={fieldDataRef.current ? fieldDataRef.current?.crs : null}
            updateData={updateFieldDataCoords}
          />
        </SplitPane>
        {fieldData ? (
          <MapView wells={getFieldArray()} center={{ lat: fieldData.latDeg, lng: fieldData.longDeg }} />
        ) : (
          <Box />
        )}
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default FieldPropertiesPage
