import React, { useEffect, useRef, useMemo } from 'react'
import DailyReportDepthTimeChart from './DailyReportDepthTimeChart'
import PhaseStatisticsChartDrilled from './PhaseStatisticsChartDrilled'
import PhaseStatisticsChartHours from './PhaseStatisticsChartHours'
import PhaseStatisticsChartRop from './PhaseStatisticsChartRop'
import Carousel from 'react-material-ui-carousel'

const DailyReportsChartsCarousel = ({ filteredDailyReportData, showBarChart, phaseFilter, witsData = [] }) => {
  const _isMounted = useRef(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  return useMemo(() => {
    return (
      <Carousel autoPlay={false} height={'100%'} sx={{ width: '100%' }} swipe={false}>
        <DailyReportDepthTimeChart
          showSlideRotData={showBarChart}
          filteredDailyReportData={filteredDailyReportData}
          phaseFilter={phaseFilter}
          witsData={witsData}
        />
        <PhaseStatisticsChartDrilled data={filteredDailyReportData} title={'Phase Footage'} phaseFilter={phaseFilter} />
        <PhaseStatisticsChartHours data={filteredDailyReportData} title={'Phase Hours'} phaseFilter={phaseFilter} />
        <PhaseStatisticsChartRop data={filteredDailyReportData} title={'Phase ROP'} phaseFilter={phaseFilter} />
      </Carousel>
    )
  }, [filteredDailyReportData, showBarChart, phaseFilter, witsData])
}

export default DailyReportsChartsCarousel
