import React, { useMemo, useRef, useImperativeHandle, forwardRef, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const wear = [
  { label: '0 - 0% Wear', value: 0 },
  { label: '1 - 12.5% Wear', value: 1 },
  { label: '2 - 25% Wear', value: 2 },
  { label: '3 - 37.5% Wear', value: 3 },
  { label: '4 - 50% Wear', value: 4 },
  { label: '5 - 62.5% Wear', value: 5 },
  { label: '6 - 75% Wear', value: 6 },
  { label: '7 - 87.5% Wear', value: 7 },
  { label: '8 - 100% Wear', value: 8 },
]

const dullCharacteristics = [
  { label: 'BC - Broken Cone', value: 'BC' },
  { label: 'BF - Bond Failure', value: 'BF' },
  { label: 'BT - Broken Tooth / Cutter', value: 'BT' },
  { label: 'BU - Balled Up', value: 'BU' },
  { label: 'CC - Cracked Cone', value: 'CC' },
  { label: 'CD - Cone Dragged', value: 'CD' },
  { label: 'CI - Cone Interference', value: 'CI' },
  { label: 'CR - Cored', value: 'CR' },
  { label: 'CT - Chipped Teeth / Cutters', value: 'CT' },
  { label: 'ER - Erosion', value: 'ER' },
  { label: 'FC - Flat Crested Wear', value: 'FC' },
  { label: 'HC - Heat Checking', value: 'HC' },
  { label: 'JD - Junk Damage', value: 'JD' },
  { label: 'LC - Lost Cone', value: 'LC' },
  { label: 'LN - Lost Nozzle', value: 'LN' },
  { label: 'LT - Lost Teeth / Cutters', value: 'LT' },
  { label: 'NO - No Major / Other Dull Characteristics', value: 'NO' },
  { label: 'NR - Not Rerunnable', value: 'NR' },
  { label: 'OC - Off Center Wear', value: 'OC' },
  { label: 'PB - Pinched Bit', value: 'PB' },
  { label: 'PN - Plugged Nozzle', value: 'PN' },
  { label: 'RG - Rounded Gauge', value: 'RG' },
  { label: 'RO - Ring Out', value: 'RO' },
  { label: 'RR - Rerunnable', value: 'RR' },
  { label: 'SD - Shirttail Damage', value: 'SD' },
  { label: 'SS - Self Sharpening Wear', value: 'SS' },
  { label: 'TR - Tracking', value: 'TR' },
  { label: 'WO - Washed Out Bit', value: 'WO' },
  { label: 'WT - Worn Teeth / Cutters', value: 'WT' },
]

const location = [
  { label: 'C - Cone', value: 'C' },
  { label: 'N - Nose', value: 'N' },
  { label: 'T - Taper', value: 'T' },
  { label: 'S - Shoulder', value: 'S' },
  { label: 'G - Gauge', value: 'G' },
  { label: 'A - All Areas', value: 'A' },
]

const bearing = [
  { label: 'X - Not applicable', value: 'X' },
  { label: 'E - Effective', value: 'E' },
  { label: 'F - Failed', value: 'F' },
  { label: 'N - Not able to grade', value: 'N' },
]

const gauge = [
  { label: 'I', value: 'I' },
  { label: '1/16', value: '1/16' },
  { label: '1/8', value: '1/8' },
  { label: '3/16', value: '3/16' },
  { label: '1/4', value: '1/4' },
  { label: '5/16', value: '5/16' },
  { label: '3/8', value: '3/8' },
  { label: '7/16', value: '7/16' },
  { label: '1/2', value: '1/2' },
  { label: '9/16', value: '9/16' },
  { label: '5/8', value: '5/8' },
  { label: '11/16', value: '11/16' },
  { label: '3/4', value: '3/4' },
  { label: '13/16', value: '13/16' },
  { label: '7/8', value: '7/8' },
  { label: '15/16', value: '15/16' },
  { label: '1', value: '1' },
]

const reasonPooh = [
  { label: 'BHA - Change Bottom Hole Assembly', value: 'BHA' },
  { label: 'CM - Condition Mud', value: 'CM' },
  { label: 'DMF - Downhole Motor Failure', value: 'DMF' },
  { label: 'DP - Drill Plug', value: 'DP' },
  { label: 'DSF - Drill String Failure', value: 'DSF' },
  { label: 'DST - Drill Stem Testing', value: 'DST' },
  { label: 'DTF - Downhole Tool Failure', value: 'DTF' },
  { label: 'FM - Formation', value: 'FM' },
  { label: 'HP - Hole Problems', value: 'HP' },
  { label: 'HR - Hours on Bit', value: 'HR' },
  { label: 'LIH - Lost in Hole', value: 'LIH' },
  { label: 'LOG - Run Logs', value: 'LOG' },
  { label: 'PP - Pump Pressure', value: 'PP' },
  { label: 'RIG - Rig Repair', value: 'RIG' },
  { label: 'TD - Total Depth / Casing Depth', value: 'TD' },
  { label: 'TQ - Torque', value: 'TQ' },
  { label: 'TW - Twist Off', value: 'TW' },
  { label: 'WC - Weather Conditions', value: 'WC' },
  { label: 'PR - Penetration Rate', value: 'PR' },
  { label: 'O - Other', value: 'O' },
]

export const BitGradeGrid = forwardRef(({ bitGrade, isAutoGrade = false, setBitGrade }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    getBitGradeFromGrid,
  }))

  const parseBitGrade = (grade) => {
    let bitGradeOut = {
      uid: 0,
      inner: '',
      outer: '',
      primaryDull: '',
      location: '',
      gauge: '',
      bearing: '',
      otherDull: '',
      reasonPooh: '',
      new: false,
    }
    if (!grade) return [bitGradeOut]
    if (typeof grade !== 'string') return [bitGradeOut]
    if (grade.toLowerCase().includes('new')) {
      bitGradeOut.new = true
      return [bitGradeOut]
    }

    let splitGrade = grade.split('-')
    if (splitGrade.length > 0) bitGradeOut.inner = parseInt(splitGrade[0])
    if (splitGrade.length > 1) bitGradeOut.outer = parseInt(splitGrade[1])
    if (splitGrade.length > 2) bitGradeOut.primaryDull = splitGrade[2]
    if (splitGrade.length > 3) bitGradeOut.location = splitGrade[3]
    if (splitGrade.length > 4) bitGradeOut.bearing = splitGrade[4]
    if (splitGrade.length > 5) bitGradeOut.gauge = splitGrade[5]
    if (splitGrade.length > 6) bitGradeOut.otherDull = splitGrade[6]
    if (splitGrade.length > 7) bitGradeOut.reasonPooh = splitGrade[7]
    return [bitGradeOut]
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const getValueFromDropDown = (label, list) => {
    if (!list) return ''
    if (!label) return ''
    if (!Array.isArray(list)) return ''

    let item = list.find((listItem) => listItem.label === label)
    if (!item) return ''
    return item.value
  }

  const getLabelFromDropDown = (value, list) => {
    if (!list) return ''
    if (value === null || value === undefined) return ''
    if (!Array.isArray(list)) return ''

    let item = list.find((listItem) => listItem.value === value)
    if (!item) return ''
    return item.label
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''

            params.api.applyTransaction({
              update: [params.data],
            })
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: null,
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])

  let columnDefs = [
    {
      field: 'inner',
      colId: 'inner',
      headerName: 'Inner',
      minWidth: 150,
      cellEditor: 'agSelectCellEditor',
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: wear.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.inner = getValueFromDropDown(params.newValue, wear)
        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.inner, wear)
      },
    },
    {
      field: 'outer',
      colId: 'outer',
      headerName: 'Outer',
      minWidth: 150,
      cellEditor: 'agSelectCellEditor',
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: wear.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.outer = getValueFromDropDown(params.newValue, wear)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.outer, wear)
      },
    },
    {
      field: 'primaryDull',
      colId: 'primaryDull',
      headerName: 'Primary Dull',
      cellEditor: 'agSelectCellEditor',
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: dullCharacteristics.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.primaryDull = getValueFromDropDown(params.newValue, dullCharacteristics)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.primaryDull, dullCharacteristics)
      },
    },
    {
      field: 'location',
      colId: 'location',
      headerName: 'Location',
      cellEditor: 'agSelectCellEditor',
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: location.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.location = getValueFromDropDown(params.newValue, location)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.location, location)
      },
    },
    {
      field: 'bearing',
      colId: 'bearing',
      headerName: 'Bearing',
      cellEditor: 'agSelectCellEditor',
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: bearing.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.bearing = getValueFromDropDown(params.newValue, bearing)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.bearing, bearing)
      },
    },
    {
      field: 'gauge',
      colId: 'gauge',
      headerName: 'Gauge',
      cellEditor: 'agSelectCellEditor',
      cellStyle: (params) => {
        if (!isAutoGrade) return centerAlignCell
        return { ...centerAlignCell, backgroundColor: 'orange' }
      },
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: gauge.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.gauge = getValueFromDropDown(params.newValue, gauge)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.gauge, gauge)
      },
    },
    {
      field: 'otherDull',
      colId: 'otherDull',
      headerName: 'Other Dull',
      cellEditor: 'agSelectCellEditor',
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: dullCharacteristics.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.otherDull = getValueFromDropDown(params.newValue, dullCharacteristics)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.otherDull, dullCharacteristics)
      },
    },
    {
      field: 'reasonPooh',
      colId: 'reasonPooh',
      headerName: 'Reason POOH',
      cellEditor: 'agSelectCellEditor',
      cellStyle: (params) => {
        if (!isAutoGrade) return centerAlignCell
        return { ...centerAlignCell, backgroundColor: 'orange' }
      },
      editable: (params) => !params.data?.new,
      cellEditorParams: (params) => {
        return { values: reasonPooh.map((v) => v.label) }
      },
      valueSetter: (params) => {
        if (!params.newValue) return
        params.data.reasonPooh = getValueFromDropDown(params.newValue, reasonPooh)

        params.api.applyTransaction({
          update: [params.data],
        })

        autoSizeColumns()
      },
      valueGetter: (params) => {
        return getLabelFromDropDown(params.data?.reasonPooh, reasonPooh)
      },
    },
    {
      field: 'new',
      colId: 'new',
      headerName: 'New',
      cellEditor: 'agCheckboxCellEditor',
      valueSetter: (params) => {
        params.data.new = params.newValue
        if (params.data?.new) {
          params.data.inner = ''
          params.data.outer = ''
          params.data.primaryDull = ''
          params.data.location = ''
          params.data.bearing = ''
          params.data.gauge = ''
          params.data.otherDull = ''
          params.data.reasonPooh = ''
        }

        params.api.applyTransaction({
          update: [params.data],
        })
      },
    },
  ]

  const gridOptions = {
    onCellEditingStopped: (params) => {
      if (!params?.valueChanged) return
      if (setBitGrade) setBitGrade(getBitGradeFromGrid())
    },
    suppressRowClickSelection: true,
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const getBitGradeFromGrid = () => {
    if (!gridApi.current) return ''

    let rowData = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) rowData.push(node.data)
    })

    if (rowData.length === 0) return ''
    if (rowData[0].new) return 'NEW'

    let bitGradeOut = `${rowData[0].inner}-${rowData[0].outer}-${rowData[0].primaryDull}-${rowData[0].location}-${rowData[0].bearing}-${rowData[0].gauge}-${rowData[0].otherDull}-${rowData[0].reasonPooh}`
    if (bitGradeOut === 'NaN-NaN------') return ''
    if (bitGradeOut === '-------') return ''
    return bitGradeOut
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={parseBitGrade(bitGrade)}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
})

const BitGradeEditor = (props) => {
  const bitGradeEditorRef = useRef(null)
  const bitGradeRef = useRef(props.value)

  useEffect(() => {
    return () => {
      //This is not the correct way to do this, should call props.onValueChange but for some reason
      //the parent component is not updating the value correctly, valueSetter is not being called
      props.data[props.colDef.field] = bitGradeRef.current
      props.api.applyTransaction({ update: [props.data] })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ height: '10vh', width: '50vw', maxWidth: '600px', maxHeight: '300px' }}>
      <BitGradeGrid
        ref={bitGradeEditorRef}
        bitGrade={props.value}
        setBitGrade={(newValue) => (bitGradeRef.current = newValue)}
      />
    </Box>
  )
}

export default BitGradeEditor
