import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { array2pipestr } from 'utils'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import DonutChartJs from 'components/common/DonutChartJs'
import { round } from 'utils/numberFunctions'
import { styled } from '@mui/styles'
import { chartSeriesColors } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import CircularProgress from '@mui/material/CircularProgress'
import { AnalyticsChartButton } from './AnalyticsBarChart'

const StyledChartContainer = styled(Box)({
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  border: '1px solid gray',
})

const StyledRowContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '50%',
})

const BhaResultsCharts = () => {
  const { getUnitsText } = useUnits()
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.bhaResultsKpiKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return

    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      bhaResults: true,
    }

    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }

    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    if (!data) return

    let headers = []
    headers.push('MD')
    headers.push('Phase')
    headers.push('ROP')
    headers.push('WOB')
    headers.push('RPM')
    headers.push('Flow Rate')
    headers.push('Torque')
    headers.push('On Btm Press')
    headers.push('Off Btm Press')
    headers.push('Diff P')
    headers.push('Temp')

    const wb = XLSX.utils.book_new()

    let ws = XLSX.utils.aoa_to_sheet([['Statistic'], ['Value']], { origin: 'A1' })
    let xlExport = []
    xlExport.push(['#BHA', data.bhaResults.numBha])
    xlExport.push(['#Unplanned Trips', data.bhaResults.unplannedTrips])
    xlExport.push(['#Motor Failures', data.bhaResults.motorFailure])
    xlExport.push(['#MWD Failures', data.bhaResults.mwdFailure])
    xlExport.push(['#RSS Failures', data.bhaResults.rssFailure])
    xlExport.push(['#Catastrophic Failures', data.bhaResults.catastrophicFailure])
    xlExport.push(['#LIH', data.bhaResults.Lih])
    xlExport.push(['#Successful BHA', data.bhaResults.successfulBha])
    xlExport.push(['#Slide Drilled', data.bhaResults.slideDrilled])
    xlExport.push(['#Rotate Drilled', data.bhaResults.rotateDrilled])

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })

    xlExport = []
    if (Array.isArray(data.bhaResults.reasonPulledStats)) {
      for (let i = 0; i < data.bhaResults.reasonPulledStats.length; i++) {
        xlExport.push([data.bhaResults.reasonPulledStats[i].reasonPooh, data.bhaResults.reasonPulledStats[i].numBha])
      }
    }

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'D1' })
    XLSX.utils.book_append_sheet(wb, ws, 'Bha Results')
    XLSX.writeFile(wb, 'bhaResults.xlsx')
  }

  function isDataOk() {
    if (!data) return false
    if (Array.isArray(data)) return false
    if (!data.hasOwnProperty('bhaResults')) return false
    if (!data.bhaResults) return false
    if (!data.bhaResults.hasOwnProperty('Lih')) return false
    return true
  }

  const DonutChart = ({ title, data }) => (
    <DonutChartJs
      data={isDataOk() ? data : []}
      title={title}
      legendLocation={'bottom'}
      showLegend={true}
      showTitle={true}
      backGroundColor={'transparent'}
      showGradient={true}
      padding={'10%'}
      showCenterText={true}
      centerText={!isDataOk() ? 'No data' : null}
    />
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
      <AnalyticsChartButton
        onClick={onXlsxExport}
        icon={'file-icons:microsoft-excel'}
        toolTipText={'Export to Excel'}
        color={'green'}
      />
      <StyledRowContainer>
        <StyledChartContainer>
          <DonutChart
            data={[
              { value: data?.bhaResults?.successfulBha, color: '#59FF00', label: 'Success' },
              {
                value: data?.bhaResults?.numBha - data?.bhaResults?.successfulBha,
                color: '#ff6347',
                label: 'Failures',
              },
            ]}
            title={'Failures'}
          />
        </StyledChartContainer>
        <StyledChartContainer>
          <DonutChart
            data={[
              {
                value: round(data?.bhaResults?.rotateDrilled, 0),
                color: '#59FF00',
                label: `Rotate ${getUnitsText(UNITS_FOR.Depth)}`,
              },
              {
                value: round(data?.bhaResults?.slideDrilled, 0),
                color: '#F59B00',
                label: `Slide ${getUnitsText(UNITS_FOR.Depth)}`,
              },
            ]}
            title={'Footage'}
          />
        </StyledChartContainer>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledChartContainer>
          <DonutChart
            data={[
              {
                value: data?.bhaResults?.unplannedTrips,
                color: chartSeriesColors[0],
                label: 'Unplanned trips',
              },
              { value: data?.bhaResults?.motorFailure, color: chartSeriesColors[1], label: 'Motor Failure' },
              { value: data?.bhaResults?.mwdFailure, color: chartSeriesColors[2], label: 'MWD Failure' },
              { value: data?.bhaResults?.rssFailure, color: chartSeriesColors[3], label: 'RSS Failure' },
              { value: data?.bhaResults?.Lih, color: chartSeriesColors[4], label: 'LIH' },
              {
                value: data?.bhaResults?.catastrophicFailure,
                color: chartSeriesColors[5],
                label: 'Catastrophic Failure',
              },
            ]}
            title={'Failure Details'}
          />
        </StyledChartContainer>
        <StyledChartContainer>
          <DonutChart
            data={data?.bhaResults?.reasonPulledStats?.map((reason, index) => {
              return {
                value: reason.numBha,
                color: chartSeriesColors[index],
                label: reason.reasonPooh,
              }
            })}
            title={'Reason POOH'}
          />
        </StyledChartContainer>
      </StyledRowContainer>
    </Box>
  )
}

export default BhaResultsCharts
