import React, { useEffect, useRef, useState } from 'react'

import { styled } from '@mui/styles'
import { Field, Form, Formik } from 'formik'
import { each as _each } from 'lodash'
import { HexColorInput } from 'react-colorful'

import { appColors } from 'utils'
import { Select, Tooltip } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import { TextField as FormikTextField } from 'components/common/formikMUI/TextField'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import { Icon as Iconify } from '@iconify/react'
import { CheckboxWithLabel } from 'components/common/formikMUI/CheckboxWithLabel'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import { Button, InputAdornment, Box } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { escapeHtmlObj, unescapeHtml } from 'utils/htmlSymbolHandling'
import ColorPicker from 'components/common/ColorPicker'
import PickValuesVirtualDialog from './PickValuesVirtualDialog'
import PickListVirtualDialog from './PickListVirtualDialog'
import moment from 'moment'
import useInnovaTheme from './hooks/useInnovaTheme'

const StyledFormItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const StyledIcon = styled(Iconify)({
  color: appColors.headerTextColor,
  height: '32px',
  width: '32px',
})

const InputForm = ({ formId, inputData, submitForm, validationSchema }) => {
  const _isMounted = useRef(false)
  const [isCopied, setIsCopied] = useState({
    index: -1,
    status: false,
  })
  const [initialValues, setInitialValues] = useState(createInitialValues(inputData))
  const [showPick, setShowPick] = useState({ index: -1, show: false })
  const [showEditPicklistValues, setShowEditPicklistValues] = useState({ index: -1, show: false })
  const [pickData, setPickData] = useState({})
  const [pickObjData, setPickObjData] = useState({})
  const { theme, getBackColor, getTextColor } = useInnovaTheme()

  // Options and createOptions for Material-UI Select component:
  const createOptions = (list) => {
    const options = []

    _each(list, (item) => {
      options.push(
        <MenuItem
          value={item.value}
          key={item.value}
          sx={{
            margin: '5px',
            fontSize: 16,
            paddingVertical: '5px',
            paddingHorizontal: '10px',
            borderWidth: '1px',
            height: '32px',
            color: appColors.itemTextColor,
            borderColor: appColors.borderColor,
            backgroundColor: getBackColor(),
            borderRadius: '5px',
            paddingRight: '10px', // to ensure the text is never behind the icon
            '&:before': {
              backgroundColor: getBackColor(),
              borderColor: appColors.borderColor,
            },
            '&.Mui-selected': {
              backgroundColor: '#133f60',
            },
          }}>
          {item.label}
        </MenuItem>,
      )
    })

    return options
  }

  useEffect(() => {
    _isMounted.current = true
    setInitialValues(createInitialValues(inputData))

    if (inputData && Array.isArray(inputData)) {
      let pickListUpdate = {}
      inputData.forEach((item) => {
        item.value = unescapeHtml(item.value)
        if (item.inputType === 'picklist' && item.hasOwnProperty('pickListValues')) {
          let valuesArr = []
          item.value?.forEach((i) => {
            let foundObj = item.pickListValues?.find((j) => j.desc === i.label)
            if (foundObj) {
              valuesArr.push(foundObj.id)
            }
          })
          let itemPickStrlist = { ...pickData, [item.tag]: valuesArr }
          pickListUpdate = { ...pickListUpdate, ...itemPickStrlist }
        }
        if (item.inputType === 'pickValues') {
          let itemPickObjlist = { ...pickObjData, [item.tag]: item.value }
          setPickObjData(itemPickObjlist)
        }
      })
      setPickData(pickListUpdate)
    }

    return () => {
      _isMounted.current = false
    }
  }, [inputData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && isCopied.status) {
      setTimeout(() => {
        setIsCopied({
          index: -1,
          status: false,
        })
      }, 1500)
    }
  }, [isCopied]) // eslint-disable-line react-hooks/exhaustive-deps

  const applyPickData = (newPickData) => {
    if (!Array.isArray(newPickData)) return
    setPickData({ ...pickData, [inputData[showPick.index].tag]: newPickData })
  }

  const applyPickObjData = (newPickData) => {
    let pickObj = { ...pickObjData, [inputData[showEditPicklistValues.index].tag]: [] }
    newPickData.forEach((obj) => {
      pickObj[inputData[showEditPicklistValues.index].tag].push({ valueIndex: obj.value, dataValue: obj.dataValue })
    })

    setPickObjData(pickObj)
  }

  function createInitialValues(data) {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    const reducedData = data.reduce((output, item) => {
      output[item.tag] = unescapeHtml(item.value)
      return output
    }, {})
    return reducedData
  }

  const Label = ({ label }) => {
    return <InputLabel sx={{ color: theme === 'dark' ? appColors.headerTextColor : '#000' }}>{label}</InputLabel>
  }

  const formFilterFunction = (formikProps, data) => {
    if (data === undefined || data === null) return true
    if (!data.hasOwnProperty('filterProperty')) return true
    if (!data.hasOwnProperty('filterValue') && !data.hasOwnProperty('filterFunction')) return true
    if (typeof data.filterProperty !== 'string') return true
    if (!formikProps.hasOwnProperty(data.filterProperty)) return true

    if (data.hasOwnProperty('filterFunction')) {
      return data.filterFunction(formikProps[data.filterProperty])
    }

    if (typeof data.filterValue !== 'string' && typeof data.filterValue !== 'object') return true

    let checkValue = formikProps[data.filterProperty]
    if (typeof checkValue === 'boolean') checkValue = checkValue.toString()
    if (typeof checkValue === 'string') checkValue = checkValue.toLowerCase()

    let filterValue = []
    if (!Array.isArray(data.filterValue)) filterValue.push(data.filterValue.toLowerCase())
    if (Array.isArray(data.filterValue)) {
      data.filterValue.forEach((val) => {
        if (typeof val === 'string') filterValue.push(val.toLowerCase())
      })
    }

    for (let i = 0; i < filterValue.length; i++) {
      if (checkValue === filterValue[i]) return !data.hasOwnProperty('filterValueNotEqual')
    }

    return data.hasOwnProperty('filterValueNotEqual')
  }

  const renderPickObjData = (data, pickListValues) => {
    let responseStr = ''

    if (data && Array.isArray(data)) {
      data.forEach((obj) => {
        let foundDesc = pickListValues.find((item) => item.value === obj.valueIndex)
        if (responseStr.length > 0) {
          responseStr += ', '
        }
        responseStr += foundDesc?.label + ': ' + obj.dataValue?.toFixed(2)
      })
    }
    return responseStr
  }

  const renderFormItem = (index, formikProps) => {
    let showItem = false
    if (formFilterFunction === null) showItem = true
    if (
      !showItem &&
      !inputData[index].hasOwnProperty('filterValue') &&
      !inputData[index].hasOwnProperty('filterFunction')
    ) {
      showItem = true
    }

    if (!showItem && !inputData[index].hasOwnProperty('filterProperty')) {
      showItem = true
    }

    if (!showItem) {
      if (!formFilterFunction(formikProps.values, inputData[index])) {
        return <Box key={index} /> // this Box should probably be styled with sx={{ height: '0px' }}
      }
    }

    const sortDropdownAZ = (dropDownVals, doNotSort) => {
      if (!dropDownVals) return
      if (dropDownVals?.length > 0) {
        if (dropDownVals[0]?.hasOwnProperty('label')) {
          var sortedDropDowns = [...dropDownVals]
          if (doNotSort) return sortedDropDowns
          sortedDropDowns?.sort(function (a, b) {
            if (a.label < b.label) {
              return -1
            }
            if (a.label > b.label) {
              return 1
            }
            return 0
          })
          return sortedDropDowns
        }
      }
    }

    const renderLabelFromValue = (valsArr, pickListArr) => {
      let responseStr = ''
      if (valsArr && Array.isArray(valsArr)) {
        valsArr.forEach((val) => {
          let foundDesc = pickListArr.find((item) => item.id === val)
          if (responseStr.length > 0) {
            responseStr += ', '
          }
          responseStr += foundDesc?.desc
        })
      }
      return responseStr
    }

    const handleDateValidation = (date, input, format = 'DD-MMM-YYYY') => {
      let dt = date.format().substring(0, 10)
      if (input !== undefined && input?.length >= 10) {
        if (dt !== 'Invalid Date') {
          return dt
        } else {
          let momentDate = moment(input).format(format)
          if (moment(momentDate, format, true).isValid() && moment(momentDate).isAfter('1990-01-01')) {
            return momentDate
          }
        }
      }
      return dt
    }

    return (
      <StyledFormItem
        key={index}
        sx={{
          height: inputData[index].inputType !== '-' ? '100%' : '0px',
        }}>
        {inputData[index].inputType === 'text' && (
          <>
            <Label label={inputData[index].text} />
            <Field
              component={FormikTextField}
              name={inputData[index].tag}
              type={inputData[index].inputFormType}
              placeholder={inputData[index].text}
              InputProps={{
                startAdornment: renderIcon(index),
                endAdornment: inputData[index].copyable ? renderClipboard(index) : null,
              }}
              variant='standard'
            />
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </>
        )}

        {inputData[index].inputType === 'color' && (
          <>
            <Label label={inputData[index].text} />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <HexColorInput
                style={{
                  width: 'calc(100% - 80px)',
                  margin: 5,
                  fontSize: 16,
                  borderWidth: 1,
                  borderRadius: 5,
                  height: 30,
                  padding: 5,
                  paddingLeft: 10,
                  borderColor: appColors.borderColor,
                  backgroundColor: theme === 'dark' ? appColors.itemBackColor : '#FFF',
                  color: appColors.itemTextColor,
                }}
                color={formikProps.values[inputData[index].tag]}
                onChange={(e) => {
                  formikProps.setFieldValue(inputData[index].tag, e)
                }}
              />
              <ColorPicker
                color={formikProps.values[inputData[index].tag]}
                onChange={(e) => {
                  formikProps.setFieldValue(inputData[index].tag, e)
                }}
              />
            </Box>
          </>
        )}

        {inputData[index].inputType === 'paragraph' && (
          <>
            <Label label={inputData[index].text} />
            <Field
              component={FormikTextField}
              name={inputData[index].tag}
              type={inputData[index].inputFormType}
              placeholder={inputData[index].text}
              InputProps={{
                startAdornment: renderIcon(index),
                endAdornment: inputData[index].copyable ? renderClipboard(index) : null,
              }}
              multiline
              minRows={2}
              maxRows={10}
              style={{ height: 'auto' }}
              variant='standard'
            />
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </>
        )}

        {inputData[index].inputType === 'boolean' && (
          <>
            <Label label={inputData[index].text} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: '5px',
                paddingLeft: '8px',
                position: 'relative',
                alignItems: 'left',
                border: '1px solid',
                borderWidth: '1px',
                overflow: 'hidden',
                borderColor: appColors.borderColor,
                backgroundColor: theme === 'dark' ? appColors.itemBackColor : '#FFF',
                color: appColors.itemTextColor,
                transition: (theme) => theme.transitions.create(['border-color', 'box-shadow']),
                '&:hover': {
                  backgroundColor: theme === 'dark' ? appColors.itemBackColor : '#C0C0C0',
                  borderColor: appColors.accentColor,
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderColor: appColors.accentColor,
                  borderWidth: '1px',
                },
              }}>
              <Field
                component={CheckboxWithLabel}
                name={inputData[index].tag}
                type='checkbox'
                Label={{ label: inputData[index].text, labelPlacement: 'end' }}
                checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                style={{ color: appColors.itemTextColor }}
              />
            </Box>
          </>
        )}

        {inputData[index].inputType === 'dropdown' && (
          <>
            <Label label={inputData[index].text} />
            <Field
              component={Select}
              name={inputData[index].tag}
              id={inputData[index].tag}
              value={formikProps.values[inputData[index].tag]}
              labelId={`${inputData[index].text}-label-id`}
              onChange={(e) => {
                formikProps.setFieldValue(inputData[index].tag, e.target.value)
              }}
              sx={{
                borderColor: appColors.borderColor,
                backgroundColor: theme === 'dark' ? appColors.itemBackColor : '#FFF',
                borderRadius: '5px',
                '&:before': {
                  borderColor: appColors.borderColor,
                },
                '& .MuiFormControl-root': {
                  width: '100%',
                },
                '&:hover': {
                  '&& fieldset': {
                    borderColor: appColors.accentColor,
                  },
                },
                svg: { color: getTextColor() },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: theme === 'dark' ? '#202020' : '#FFF',
                    '& ul': {
                      backgroundColor: theme === 'dark' ? '#202020' : '#FFF',
                    },
                    '& li': {
                      '&:hover': {
                        border: '1px solid #3f97d9',
                        backgroundColor: theme === 'dark' ? 'rgba(66, 156, 235, 0.08)' : '#f0f0f0',
                      },
                      border: '1px solid transparent',
                      fontSize: 12,
                    },
                  },
                },
              }}
              variant='outlined'>
              {createOptions(
                inputData[index].hasOwnProperty('filterField')
                  ? filterDropwDownList(
                      formikProps.values[inputData[index].filterField],
                      inputData[index].filterField,
                      sortDropdownAZ(
                        inputData[index]?.dropDownValues,
                        inputData[index].hasOwnProperty('doNotSort') ? inputData[index].doNotSort : true,
                      ),
                    )
                  : sortDropdownAZ(
                      inputData[index]?.dropDownValues,
                      inputData[index].hasOwnProperty('doNotSort') ? inputData[index].doNotSort : true,
                    ),
              )}
            </Field>
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </>
        )}

        {inputData[index].inputType === 'date' && (
          <>
            <Label label={inputData[index].text} />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={['year', 'day']}
                id={inputData[index].tag}
                name={inputData[index].tag}
                disableMaskedInput
                inputFormat={
                  inputData[index].hasOwnProperty('dateFormat') ? inputData[index].dateFormat : 'DD-MMM-YYYY'
                }
                value={formikProps.values[inputData[index].tag]}
                onChange={(date, input) => {
                  let dt = handleDateValidation(date, input, inputData[index].dateFormat)
                  formikProps.setFieldValue(inputData[index].tag, dt)
                }}
                renderInput={(params) => (
                  <TextField
                    variant='standard'
                    {...params}
                    helperText={null}
                    sx={{
                      svg: { color: getTextColor() },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </>
        )}

        {inputData[index].inputType === 'picklist' && (
          <Box onClick={() => handlePicklistOnClick(index)}>
            <Label label={inputData[index].text} />
            <Field
              component={FormikTextField}
              name={inputData[index].tag}
              type={inputData[index].inputFormType}
              placeholder={pickData[inputData[index].tag]?.length > 0 ? '' : inputData[index].text}
              value={
                pickData[inputData[index].tag]?.length > 0
                  ? renderLabelFromValue(pickData[inputData[index].tag], inputData[index].pickListValues)
                  : ''
              }
              InputProps={{
                readOnly: true,
                startAdornment: renderIcon(index),
                endAdornment: inputData[index].copyable ? renderClipboard(index) : null,
              }}
              variant='standard'
            />
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </Box>
        )}

        {inputData[index].inputType === 'pickValues' && (
          <Box onClick={() => handlePickValuesOnClick(index)}>
            <Label label={inputData[index].text} />
            <Field
              component={FormikTextField}
              name={inputData[index].tag}
              type={inputData[index].inputFormType}
              placeholder={pickObjData[inputData[index].tag]?.length > 0 ? '' : inputData[index].text}
              value={
                pickObjData[inputData[index].tag]?.length > 0
                  ? renderPickObjData(pickObjData[inputData[index].tag], inputData[index].pickListValues)
                  : ''
              }
              InputProps={{
                readOnly: true,
                startAdornment: renderIcon(index),
                endAdornment: inputData[index].copyable ? renderClipboard(index) : null,
              }}
              variant='standard'
            />
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </Box>
        )}
      </StyledFormItem>
    )
  }

  const filterDropwDownList = (filterValue, filterField, list) => {
    if (!filterValue || !filterField) return []
    if (filterValue === '' || filterField === '') return []
    if (!list) return []
    if (!Array.isArray(list)) return []
    if (list.length === 0) return []

    let output = []
    list.forEach((item) => {
      if (item[filterField] === filterValue) output.push({ label: item.label, value: item.value })
    })
    return output
  }

  const handleOnClick = (index, text) => {
    navigator.clipboard.writeText(text)
    setIsCopied({
      index: index,
      status: true,
    })
  }

  const handlePicklistOnClick = (index) => {
    setShowPick({ index: index, show: true })
  }

  const handlePickValuesOnClick = (index) => {
    setShowEditPicklistValues({ index: index, show: true })
  }

  const renderClipboard = (index) => {
    return (
      <>
        <Tooltip
          title='Copied!'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}
          open={isCopied.index === index ? isCopied.status : false}
          disableHoverListener>
          <InputAdornment position='end'>
            <Button onClick={() => handleOnClick(index, inputData[index].value)}>
              <StyledIcon icon={'fa-regular:copy'} style={{ color: appColors.itemTextColor, height: 25, width: 25 }} />
            </Button>
          </InputAdornment>
        </Tooltip>
      </>
    )
  }

  const renderIcon = (index) => {
    let showIcon = checkRender()
    function checkRender() {
      if (inputData[index].hasOwnProperty('icon') === false) return false
      if (inputData[index].hasOwnProperty('iconFamily') === false) return false
      if (inputData[index].icon === '') return false
      if (inputData[index].iconFamily === '') return false

      return true
    }

    if (showIcon) {
      return <>{inputData[index].iconFamily === 'Iconify' && <StyledIcon icon={inputData[index].icon} size={30} />}</>
    } else {
      return <></>
    }
  }

  return (
    <>
      {showPick.show ? (
        <PickListVirtualDialog
          title={'Select ' + inputData[showPick.index].text}
          open={showPick.show}
          setOpen={() => setShowPick({ ...showPick, show: false })}
          onApply={applyPickData}
          singleItemSelect={false}
          items={inputData[showPick.index].pickListValues}
          showSearch={true}
          initSelItems={pickData[inputData[showPick.index].tag]}
        />
      ) : null}
      {showEditPicklistValues.show ? (
        <PickValuesVirtualDialog
          title={'Select ' + inputData[showEditPicklistValues.index].text}
          open={showEditPicklistValues.show}
          setOpen={() => setShowEditPicklistValues({ ...showEditPicklistValues, show: false })}
          onApply={applyPickObjData}
          singleItemSelect={false}
          items={inputData[showEditPicklistValues.index].pickListValues}
          showSearch={true}
          initSelItems={pickObjData[inputData[showEditPicklistValues.index].tag]}
        />
      ) : null}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          escapeHtmlObj(values)
          for (const property in pickData) {
            values[property] = pickData[property]
          }
          for (const property in pickObjData) {
            values[property] = pickObjData[property]
          }
          submitForm(values, formikHelpers)
        }}>
        {({ values, touched, errors, setFieldValue }) => (
          <Form
            id={formId}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left' }}>
            {inputData.map((_, i) => renderFormItem(i, { touched, errors, setFieldValue, values }))}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default InputForm
