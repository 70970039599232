import React, { useRef, useEffect, useState } from 'react'
import { soloCloudLayoutAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import { Snackbar } from '@mui/material'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import Alert from '@mui/material/Alert'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import SoloCloudTree from './SoloCloudTree'
import SoloCloudSettingsGrid from './SoloCloudSettingsGrid'
import { escapeHtml } from 'utils/htmlSymbolHandling'
import SoloCloudDetailsGrid from './SoloCloudDetailsGrid'
import { cloneDeep } from 'lodash'
import SoloCloudCredentials from './SoloCloudCredentials'
import { SOLO_CLOUD_TREE_LEVELS, createNodeId } from './SoloCloudTree'

const SoloCloudPage = ({ wellName }) => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const isUpdating = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [pageLayout, setPageLayout] = useRecoilState(soloCloudLayoutAtom)
  const configDataRef = useRef([])
  const [config, setConfig] = useState({})
  const [creds, setCreds] = useState({ userName: '', password: '', valid: false })
  const [checkedNodes, setCheckedNodes] = useState([])
  const [selectedNode, setSelectedNode] = useState(SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD)
  const [gridDetailsData, setGridDetailsData] = useState([])
  const treeDataRef = useRef([])
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const starredObjectsRef = useRef([])

  const getConfig = useInnovaAxios({
    url: '/solocloud/getConfig',
  })

  const updateConfigCredentials = useInnovaAxios({
    url: '/solocloud/updateConfigCredentials',
  })

  const updateConfig = useInnovaAxios({
    url: '/solocloud/updateConfig',
  })

  const projects = useInnovaAxios({
    url: '/solocloud/projects',
  })

  const wells = useInnovaAxios({
    url: '/solocloud/wells',
  })

  const targetLines = useInnovaAxios({
    url: '/solocloud/targetLines',
  })

  const plans = useInnovaAxios({
    url: '/solocloud/plans',
  })

  const topSets = useInnovaAxios({
    url: '/solocloud/topSets',
  })

  const starredObjects = useInnovaAxios({
    url: '/solocloud/starredObjects',
  })

  const tops = useInnovaAxios({
    url: '/solocloud/tops',
  })

  const targetLineData = useInnovaAxios({
    url: '/solocloud/targetLineData',
  })

  const planData = useInnovaAxios({
    url: '/solocloud/planData',
  })

  const wellData = useInnovaAxios({
    url: '/solocloud/wellData',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchConfig()
    getNodeDataForDetails([], SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD)
    return () => {
      _isMounted.current = false
    }
  }, [creds]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNodeSelectionChange = async (newSelectedNodes, shouldUpdateApi = true) => {
    let payload = {
      projectName: '',
      projectUuid: '',
      wellName: '',
      wellUuid: '',
      targetName: '',
      targetLineUuid: '',
      planName: '',
      planUuid: '',
    }

    let update = [
      { tag: 'projectUuid', value: '' },
      { tag: 'projectUom', value: '' },
      { tag: 'lateralUuid', value: '' },
      { tag: 'targetLineUuid', value: '' },
    ]
    let project = newSelectedNodes.find((n) => n.level === SOLO_CLOUD_TREE_LEVELS.PROJECT)

    if (project) {
      payload.projectName = escapeHtml(project.name)
      payload.projectUuid = project.uuid
      update[0].value = project.uuid
      let foundNode = treeDataRef.current.find((n) => n.uuid === project.uuid)
      update[1].value = foundNode?.uom
    }

    let well = newSelectedNodes.find((n) => n.level === SOLO_CLOUD_TREE_LEVELS.WELL)
    if (well) {
      payload.wellName = escapeHtml(well.name)
      payload.wellUuid = well.uuid
      update[2].value = well.uuid
    }

    let target = newSelectedNodes.find((n) => n.level === SOLO_CLOUD_TREE_LEVELS.TARGET_LINE)
    if (target) {
      payload.targetName = escapeHtml(target.name)
      payload.targetLineUuid = target.uuid
      update[3].value = target.uuid
    }

    let topSet = newSelectedNodes.find((n) => n.level === SOLO_CLOUD_TREE_LEVELS.TOP_SET)
    if (topSet) {
      payload.topSetName = escapeHtml(topSet.name)
      payload.topSetUuid = topSet.uuid
    }

    let plan = newSelectedNodes.find((n) => n.level === SOLO_CLOUD_TREE_LEVELS.PLAN)
    if (plan) {
      payload.planName = escapeHtml(plan.name)
      payload.planUuid = plan.uuid
    }

    handleUpdateConfig(update)
    setCheckedNodes(newSelectedNodes)

    if (shouldUpdateApi) await updateInfoSelection(payload)
  }

  const handleSetActiveNode = (node, level) => {
    if (level && node) {
      node.level = level
    }

    setSelectedNode(node)
    getNodeDataForDetails(node, level)
  }

  const updateInfoSelection = async (payload) => {
    if (!payload) return
  }

  const fetchConfig = async () => {
    if (wellName === '') return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)

    let res = await getConfig({ wellName: wellName })

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      return
    }

    configDataRef.current = res?.data
    setConfig(res?.data)

    if (res?.data?.userName && res?.data?.password) {
      if (creds.userName !== res?.data?.userName || creds.password !== res?.data?.password) {
        setCreds({
          userName: res?.data?.userName ? res?.data?.userName : '',
          password: res?.data?.password ? res?.data?.password : '',
          valid:
            res?.data?.userName && res?.data?.password && res?.data?.userName !== '' && res?.data?.password !== ''
              ? true
              : false,
        })
      }
    }

    if (!creds?.valid){
      treeDataRef.current = []
    }
  }

  function sanitizeEmail(email) {
    if (!email) return ''

    const parts = email.split('@')
    if (parts.length !== 2) return email

    const localPart = parts[0]
    const domainPart = parts[1]

    let sanitizedLocalPart =
      localPart.length > 2
        ? localPart[0] + '*'.repeat(localPart.length - 2) + localPart[localPart.length - 1]
        : localPart.replace(/.(?=.)/g, '*')

    return `${sanitizedLocalPart}@${domainPart}`
  }

  const handleUpdateCredentials = async (newCreds) => {
    if (!newCreds) return
    if (!newCreds.hasOwnProperty('userName') || !newCreds.hasOwnProperty('password')) return
    if (!configDataRef.current) return
    if (!_isMounted.current) return
    if (isUpdating.current) return

    let newConfig = cloneDeep(configDataRef.current)
    newConfig.userName = newCreds.userName
    newConfig.password = newCreds.password
    newConfig.wellName = wellName

    isUpdating.current = true
    let res = await updateConfigCredentials({ wellName: wellName, soloCloudConfig: JSON.stringify(newConfig)})

    if (!_isMounted.current) return
    isUpdating.current = false
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (newCreds.userName !== '' && newCreds.password !== '') {
      setCreds({ userName: sanitizeEmail(newCreds.userName), password: '******', valid: true })
      return
    }

    setCreds({ userName: '', password: '', valid: false })
  }

  const handleUpdateConfig = async (update) => {
    if (!update) return
    if (!Array.isArray(update)) return
    if (!configDataRef.current) return
    if (isUpdating.current) return
    if (!_isMounted.current) return

    let newConfig = cloneDeep(configDataRef.current)
    if (!newConfig) return
    if (newConfig.length === 0) return

    for (let i = 0; i < update.length; i++) {
      if (!newConfig.hasOwnProperty(update[i].tag)) continue
      newConfig[update[i].tag] = update[i].value
    }

    isUpdating.current = true
    let res = await updateConfig({ wellName: wellName, soloCloudConfig: JSON.stringify(newConfig) })

    if (!_isMounted.current) return
    isUpdating.current = false

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    configDataRef.current = cloneDeep(newConfig)
    setConfig(newConfig)
  }

  const fetchProjects = async () => {
    if (!wellName) return
    if (wellName === '') return
    if (isLoading) return
    if (!_isMounted.current) return
    if (!config) return
    if (!config.hasOwnProperty('userName') || !config.hasOwnProperty('password')) return
    if (config.userName === '' || config.password === '') return
    if( !creds.valid) return

    setIsLoading(true)
    let res = await projects({ wellName: wellName })

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      treeDataRef.current = []
      return
    }

    let treeData = []
    const projectData = res?.data?.content ? res?.data?.content : []
    if (Array.isArray(projectData)) {
      for (let i = 0; i < projectData.length; i++) {
        if (projectData[i].name === '') continue
        treeData.push({
          children: [],
          level: SOLO_CLOUD_TREE_LEVELS.PROJECT,
          name: projectData[i].name,
          uuid: projectData[i].uuid,
          nodeId: createNodeId(projectData[i].uuid, SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD),
          uom: projectData[i].measure_unit,
          parentUuid: SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD,
          parentNodeId: SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD,
        })
      }
      treeData.sort((a, b) => a.name.localeCompare(b.name))
      treeDataRef.current = cloneDeep(treeData)
      setShouldUpdate(!shouldUpdate)
    }
    setGridDetailsData(projectData)
  }

  const fetchStarredObjects = async (node) => {
    if (!wellName) return
    if (wellName === '') return
    if (!node) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await starredObjects({ wellName: wellName, lateralUuid: node.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    starredObjectsRef.current = res?.data ? res?.data : []
  }

  const fetchWells = async (node) => {
    if (!wellName) return
    if (wellName === '') return
    if (!node) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await wells({ wellName: wellName, projectUuid: node.uuid })

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    let treeData = cloneDeep(treeDataRef.current)
    const wellData = res?.data?.content ? res?.data?.content : []
    if (Array.isArray(wellData)) {
      let parentIndex = treeData.findIndex((n) => n.uuid === node.uuid)
      if (parentIndex >= 0) {
        for (let i = 0; i < wellData.length; i++) {
          if (wellData[i].name === '') continue
          let exists = treeData[parentIndex].children.findIndex(
            (n) => n.nodeId === createNodeId(wellData[i].uuid, node.uuid),
          )
          if (exists >= 0) {
            treeData[parentIndex].children[exists].name = wellData[i].name
            treeData[parentIndex].children[exists].uuid = wellData[i].uuid
            treeData[parentIndex].children[exists].parentUuid = node.uuid
            treeData[parentIndex].children[exists].parentNodeId = node.nodeId
          } else {
            treeData[parentIndex].children.push({
              children: [],
              level: SOLO_CLOUD_TREE_LEVELS.WELL,
              name: wellData[i].name,
              uuid: wellData[i].uuid,
              parentUuid: node.uuid,
              parentNodeId: node.nodeId,
              nodeId: createNodeId(wellData[i].uuid, node.uuid),
            })
          }
        }
      }
    }

    treeDataRef.current = cloneDeep(treeData)
    setShouldUpdate(!shouldUpdate)
    setGridDetailsData(res?.data?.content ? res?.data?.content : [])
  }

  const fetchTargetLines = async (node) => {
    if (!wellName) return
    if (wellName === '') return
    if (!node) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await targetLines({ wellName: wellName, lateralUuid: node.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    let treeData = cloneDeep(treeDataRef.current)
    let projectIndex = treeData.findIndex((n) => n.nodeId === node.parentNodeId)
    if (projectIndex >= 0) {
      let wellIndex = treeData[projectIndex].children.findIndex((n) => n.nodeId === node.nodeId)
      if (wellIndex >= 0) {
        let targetLineData = res?.data?.content ? res?.data?.content : []
        if (Array.isArray(targetLineData)) {
          for (let i = 0; i < targetLineData.length; i++) {
            if (targetLineData[i].name === '') continue

            let isStarred = starredObjectsRef.current?.target_line === targetLineData[i].uuid
            let exists = treeData[projectIndex].children[wellIndex].children.findIndex(
              (n) => n.nodeId === createNodeId(targetLineData[i].uuid, node.uuid),
            )
            if (exists >= 0) {
              treeData[projectIndex].children[wellIndex].children[exists].name = targetLineData[i].name
              treeData[projectIndex].children[wellIndex].children[exists].uuid = targetLineData[i].uuid
              treeData[projectIndex].children[wellIndex].children[exists].parentUuid = node.uuid
              treeData[projectIndex].children[wellIndex].children[exists].parentNodeId = node.nodeId
              treeData[projectIndex].children[wellIndex].children[exists].starred = isStarred
            } else {
              treeData[projectIndex].children[wellIndex].children.push({
                children: [],
                level: SOLO_CLOUD_TREE_LEVELS.TARGET_LINE,
                name: targetLineData[i].name,
                uuid: targetLineData[i].uuid,
                parentUuid: node.uuid,
                parentNodeId: node.nodeId,
                nodeId: createNodeId(targetLineData[i].uuid, node.uuid),
                starred: isStarred,
              })
            }
          }
        }
      }
    }

    treeDataRef.current = cloneDeep(treeData)
    setShouldUpdate(!shouldUpdate)
    setGridDetailsData(res?.data?.content ? res?.data?.content : [])
  }

  const fetchTopSets = async (node) => {
    if (!wellName) return
    if (wellName === '') return
    if (!node) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await topSets({ wellName: wellName, lateralUuid: node.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    let treeData = cloneDeep(treeDataRef.current)
    let projectIndex = treeData.findIndex((n) => n.nodeId === node.parentNodeId)
    if (projectIndex >= 0) {
      let wellIndex = treeData[projectIndex].children.findIndex((n) => n.nodeId === node.nodeId)
      if (wellIndex >= 0) {
        let topSetData = res?.data?.content ? res?.data?.content : []
        if (Array.isArray(topSetData)) {
          for (let i = 0; i < topSetData.length; i++) {
            let exists = treeData[projectIndex].children[wellIndex].children.findIndex(
              (n) => n.nodeId === createNodeId(topSetData[i].uuid, node.uuid),
            )

            let isStarred = starredObjectsRef.current?.stopset === topSetData[i].uuid
            if (exists >= 0) {
              treeData[projectIndex].children[wellIndex].children[exists].name = topSetData[i].name
              treeData[projectIndex].children[wellIndex].children[exists].uuid = topSetData[i].uuid
              treeData[projectIndex].children[wellIndex].children[exists].parentUuid = node.uuid
              treeData[projectIndex].children[wellIndex].children[exists].parentNodeId = node.nodeId
              treeData[projectIndex].children[wellIndex].children[exists].starred = isStarred
            } else {
              treeData[projectIndex].children[wellIndex].children.push({
                children: [],
                level: SOLO_CLOUD_TREE_LEVELS.TOP_SET,
                name: topSetData[i].name,
                uuid: topSetData[i].uuid,
                parentUuid: node.uuid,
                parentNodeId: node.nodeId,
                nodeId: createNodeId(topSetData[i].uuid, node.uuid),
                starred: isStarred,
              })
            }
          }
        }
      }
    }

    treeDataRef.current = cloneDeep(treeData)
    setShouldUpdate(!shouldUpdate)
    setGridDetailsData(res?.data?.content ? res?.data?.content : [])
  }

  const fetchPlans = async (node) => {
    if (!wellName) return
    if (wellName === '') return
    if (!node) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await plans({ wellName: wellName, lateralUuid: node.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    let treeData = cloneDeep(treeDataRef.current)
    let projectIndex = treeData.findIndex((n) => n.nodeId === node.parentNodeId)
    if (projectIndex >= 0) {
      let wellIndex = treeData[projectIndex].children.findIndex((n) => n.nodeId === node.nodeId)
      if (wellIndex >= 0) {
        let planData = res?.data?.content ? res?.data?.content : []
        if (Array.isArray(planData)) {
          for (let i = 0; i < planData.length; i++) {
            let exists = treeData[projectIndex].children[wellIndex].children.findIndex(
              (n) => n.nodeId === createNodeId(planData[i].uuid, node.uuid),
            )

            let isStarred = starredObjectsRef.current?.nested_well === planData[i].uuid
            if (exists >= 0) {
              treeData[projectIndex].children[wellIndex].children[exists].name = planData[i].name
              treeData[projectIndex].children[wellIndex].children[exists].uuid = planData[i].uuid
              treeData[projectIndex].children[wellIndex].children[exists].parentUuid = node.uuid
              treeData[projectIndex].children[wellIndex].children[exists].parentNodeId = node.nodeId
              treeData[projectIndex].children[wellIndex].children[exists].starred = isStarred
            } else {
              treeData[projectIndex].children[wellIndex].children.push({
                children: [],
                level: SOLO_CLOUD_TREE_LEVELS.PLAN,
                name: planData[i].name,
                uuid: planData[i].uuid,
                parentUuid: node.uuid,
                parentNodeId: node.nodeId,
                nodeId: createNodeId(planData[i].uuid, node.uuid),
                starred: isStarred,
              })
            }
          }
        }
      }
    }

    treeDataRef.current = cloneDeep(treeData)
    setShouldUpdate(!shouldUpdate)
    setGridDetailsData(res?.data?.content ? res?.data?.content : [])
  }

  const fetchTops = async (activeNode) => {
    if (!wellName) return
    if (wellName === '') return
    if (!activeNode) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await tops({ wellName: wellName, topSetUuid: activeNode.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setGridDetailsData(res?.data.content ? res?.data.content : [])
  }

  const fetchTargetLineData = async (activeNode) => {
    if (!wellName) return
    if (wellName === '') return
    if (!activeNode) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await targetLineData({ wellName: wellName, targetLineUuid: activeNode.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setGridDetailsData(res?.data?.content ? res?.data?.content : [])
  }

  const fetchPlanData = async (activeNode) => {
    if (!wellName) return
    if (wellName === '') return
    if (!activeNode) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await planData({ wellName: wellName, wellPlanUuid: activeNode.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setGridDetailsData(res?.data ? [res?.data] : [])
  }

  const fetchWellData = async (activeNode) => {
    if (!wellName) return
    if (wellName === '') return
    if (!activeNode) return
    if (isLoading) return
    if (!_isMounted.current) return

    setIsLoading(true)
    let res = await wellData({ wellName: wellName, lateralUuid: activeNode.uuid })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setGridDetailsData(res?.data ? [res?.data] : [])
  }

  const getNodeDataForDetails = async (node, level) => {
    if (level === SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD) {
      fetchProjects()
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.PROJECT) {
      fetchWells(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.WELL) {
      fetchWellData(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.TOP_SET) {
      fetchTops(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.TOP_HEADER) {
      fetchTopSets(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.TARGET_LINE) {
      fetchTargetLineData(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.TARGET_LINE_HEADER) {
      fetchTargetLines(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.PLAN) {
      fetchPlanData(node)
    }

    if (level === SOLO_CLOUD_TREE_LEVELS.PLAN_HEADER) {
      fetchPlans(node)
    }
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '30%' : '30%'
    if (index >= pageLayout.length) return index === 0 ? '30%' : '30%'
    return pageLayout[index].size
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  return (
    <React.Fragment>
      <SplitPane
        split='vertical'
        onDragFinished={(params) => onDragFinished(params, 0)}
        defaultSize={getInitialPaneSize(0)}
        size={getInitialPaneSize(0)}
        style={{
          height: '100%',
          width: 'calc(100% - 25px)',
          marginLeft: `25px`,
          maxWidth: '100%',
        }}>
        <Box sx={{ width: '100%', height: '100%' }}>
          <SoloCloudCredentials creds={creds} updateCreds={handleUpdateCredentials} setStatus={setStatus} config ={config} />
          <SoloCloudTree
            treeData={treeDataRef}
            fetchWells={fetchWells}
            fetchTargetLines={fetchTargetLines}
            fetchPlans={fetchPlans}
            fetchTopSets={fetchTopSets}
            fetchStarredObjects={fetchStarredObjects}
            setStatus={setStatus}
            checkedNodes={checkedNodes}
            setCheckedNodes={handleTreeNodeSelectionChange}
            setActiveNode={handleSetActiveNode}
            config={config}
            shouldUpdate={shouldUpdate}
          />
        </Box>
        <SplitPane
          split='horizontal'
          onDragFinished={(params) => onDragFinished(params, 1)}
          defaultSize={'50%'}
          allowResize={true}>
          <SoloCloudSettingsGrid config={config} handleUpdateConfig={handleUpdateConfig} />
          <SoloCloudDetailsGrid
            data={Array.isArray(gridDetailsData) ? gridDetailsData : []}
            selectedNode={selectedNode}
          />
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default SoloCloudPage
