import React from 'react'
import { Box } from '@mui/material'
import { appColors } from 'utils'
import {
  RULER_WIDTH,
  VIEWPORT_PADDING,
  convertUserToPixels,
} from 'components/WellPages/WallplotComposer/Viewport/cssTransformUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const HorizontalRuler = ({ pageLayout, units, zoomScale, rulerRef }) => {
  const rulerWidthPx = convertUserToPixels(pageLayout.width, units, zoomScale)
  const majorTickHeight = RULER_WIDTH - 2
  const minorTickHeight = RULER_WIDTH / 4
  const { theme } = useInnovaTheme()

  const ticks = []
  for (let inch = 0; inch <= pageLayout.width; inch += 0.25) {
    const leftPosition = convertUserToPixels(inch, units, zoomScale)
    const isMajorTick = inch % 1 === 0
    ticks.push(
      <Box
        key={inch}
        sx={{
          position: 'absolute',
          left: `${leftPosition}px`,
          top: isMajorTick ? `0px` : `${majorTickHeight / 2 - minorTickHeight / 2}px`,
          width: isMajorTick && inch > 0 ? '0px' : '1px',
          height: isMajorTick ? `${majorTickHeight}px` : `${minorTickHeight}px`,
          backgroundColor: appColors.headerTextColor,
          color: theme === 'dark' ? '#ffffff' : '#222222',
          textAlign: 'center',
          fontSize: '10px',
          fontWeight: 'bold',
        }}>
        {isMajorTick && inch > 0 ? inch : ''}
      </Box>,
    )
  }

  return (
    <Box
      ref={rulerRef}
      sx={{
        height: `${RULER_WIDTH}px`,
        position: 'sticky',
        top: '0px',
        left: `0px`,
        marginLeft: `${VIEWPORT_PADDING + RULER_WIDTH}px`,
        width: `${rulerWidthPx}px`,
        borderBottom: `1px solid ${appColors.headerTextColor}`,
        backgroundColor: theme === 'dark' ? '#222222' : '#ffffff',
        zIndex: 9998,
      }}>
      {ticks}
      <Box
        id='wpc-horizontal-cursor'
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '100%',
          width: '1px',
          backgroundColor: 'red',
        }}
      />
    </Box>
  )
}

export default HorizontalRuler
