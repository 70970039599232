import React from 'react'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'

const PageSizeModal = ({ submitFunction, cancelFunction, units, width, height }) => {
  const formValidationSchema = yup.object({
    width: yup.number().required(),
    height: yup.number().required(),
  })

  let formData = [
    {
      tag: 'width',
      value: width,
      text: `Width (${units === 'm' ? 'cm' : 'in'})`,
      inputType: 'text',
    },
    {
      tag: 'height',
      value: height,
      text: `Height (${units === 'm' ? 'cm' : 'in'})`,
      inputType: 'text',
    },
  ]

  const submitForm = (data) => {
    let width = parseFloat(data.width)
    let height = parseFloat(data.height)
    if (units === 'm') width = width / 2.54
    if (units === 'm') height = height / 2.54
    submitFunction({ size: 'Custom', width: width, height: height })
  }

  return (
    <InputModal
      open={true}
      onClose={cancelFunction}
      title={'Custom Page Size'}
      formData={formData}
      submitForm={submitForm}
      cancelFunction={cancelFunction}
      validationSchema={formValidationSchema}
    />
  )
}

export default PageSizeModal
