export const getItemFromLS = (item, key) => {
  let ls = {}
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem(item)) || {}
    } catch (e) {
      console.error('error: cannot read local storage ', item)
    }
    return ls[key]
  }
}

export const saveItemToLS = (item, key, value) => {
  if (global.localStorage) {
    global.localStorage.setItem(item, JSON.stringify({ [key]: value }))
  } else {
    console.error('error: local storage unavailable ', item)
  }
}