import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, userUserRoleAtom } from 'atoms'
import { numberWithCommasDecimals, removeSpecialSymbols } from 'utils/stringFunctions'
import * as XLSX from '@sheet/image'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling.js'
import { numberToString } from 'utils/numberFunctions'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { appColors } from 'utils'
import {
  getStyles,
  makeMergedCells,
} from 'components/common/ExcelReports/ExcelReportFunctions'

function useInventory(wellName) {
  const _isMounted = useRef(true)
  const inventoryData = useRef([])
  const dropDowns = useRef({})
  const wellInfo = useRef({})
  const isLoading = useRef(false)
  const isDropDownsLoading = useRef(false)
  const isWellInfoLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(wellName ? wellName : currentWell)
  const { getUnitsText } = useUnits()
  const { getCurrentOrgIcon } = useOrgIcons()
  const userRole = useRecoilValue(userUserRoleAtom)

  const getWellInventory = useInnovaAxios({
    url: '/well/toolInventory/getInventory',
  })

  const getInventoryDropDowns = useInnovaAxios({
    url: '/well/toolInventory/getInventoryDropDowns',
  })

  const getWellInfo = useInnovaAxios({
    url: '/well/wellInfo/getWellInfo',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = wellName ? wellName : currentWell
    fetchInventory()
    fetchDropDowns()
    fetchWellInfo()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWellInfo = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isWellInfoLoading.current) return
    isWellInfoLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getWellInfo({ wellName: currentWellRef.current })
    if (_isMounted.current) {
      wellInfo.current = response?.data ? response.data : null
    }

    isWellInfoLoading.current = false
  }

  const fetchDropDowns = async () => {
    if (isDropDownsLoading.current) return
    isDropDownsLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getInventoryDropDowns()
    if (_isMounted.current) {
      dropDowns.current = response?.data ? response.data : null
    }

    isDropDownsLoading.current = false
  }

  const getDropDowns = () => {
    let newListData = { toolTypes: [], connectionTypes: [] }
    if (!dropDowns.current) return newListData
    if (!dropDowns.current.hasOwnProperty('toolTypes')) return newListData
    if (!dropDowns.current.hasOwnProperty('connectionTypes')) return newListData
    if (!Array.isArray(dropDowns.current.connectionTypes)) return newListData
    if (!Array.isArray(dropDowns.current.toolTypes)) return newListData

    dropDowns.current.connectionTypes.forEach((conn) => {
      let index = newListData.connectionTypes.findIndex((element) => element.value === conn)

      if (index < 0) {
        newListData.connectionTypes.push({ label: conn, value: `${conn}` })
      }
    })

    dropDowns.current.toolTypes.forEach((tool) => {
      newListData.toolTypes.push({ label: tool.description, value: `${tool.description}` })
    })

    return newListData
  }

  const fetchInventory = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getWellInventory({ wellName: currentWellRef.current })
    if (_isMounted.current) {
      setLoading(false)
      inventoryData.current = response?.data ? response.data : null
    }

    isLoading.current = false
  }

  const fetchInventoryData = (incMwdTools = false) => {
    if (!inventoryData.current) return []
    if (!Array.isArray(inventoryData.current)) return []
    if (incMwdTools) return inventoryData.current

    let filteredData = []
    for (let i = 0; i < inventoryData.current.length; i++) {
      filteredData.push(inventoryData.current[i])
    }

    return filteredData
  }

  const makeEmptyArray = (size) => {
    return Array(size).fill(undefined)
  }

  const onXlsxExport = () => {
    if (!wellInfo.current) return
    if (!inventoryData.current) return
    const wb = XLSX.utils.book_new()

    let reportHeaders = [
      [
        ...makeEmptyArray(4),
        'Job #:',
        ...makeEmptyArray(1),
        wellInfo.current?.jobNum,
        ...makeEmptyArray(2),
        'Operator:',
        ...makeEmptyArray(1),
        wellInfo.current?.operator,
      ],
      [
        ...makeEmptyArray(4),
        'API Job #:',
        ...makeEmptyArray(1),
        wellInfo.current?.apiNum,
        ...makeEmptyArray(2),
        'Field:',
        ...makeEmptyArray(1),
        wellInfo.current?.field,
      ],
      [
        ...makeEmptyArray(4),
        'Rig:',
        ...makeEmptyArray(1),
        wellInfo.current?.rig,
        ...makeEmptyArray(2),
        'Well:',
        ...makeEmptyArray(1),
        wellInfo.current?.wellName,
      ],
      [
        ...makeEmptyArray(4),
        'County:',
        ...makeEmptyArray(1),
        wellInfo.current?.county,
        ...makeEmptyArray(2),
        'State:',
        ...makeEmptyArray(1),
        wellInfo.current?.state,
      ],
      [
        ...makeEmptyArray(4),
        'AFE #:',
        ...makeEmptyArray(1),
        wellInfo.current?.afeNum,
        ...makeEmptyArray(2),
        'PO #:',
        ...makeEmptyArray(1),
        wellInfo.current?.poNum,
      ],
      ['Tool Inventory'],
      [
        'S/N',
        'Description',
        ...makeEmptyArray(2),
        `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        `Length (${getUnitsText(UNITS_FOR.Depth)})`,
        ...makeEmptyArray(1),
        'CNX Top / Bot',
        'Status',
        'Make / Model',
        'Date Arrived',
        ...makeEmptyArray(1),
        'Date Left',
      ],
    ]

    let ws = XLSX.utils.aoa_to_sheet(reportHeaders, {
      origin: 'B2',
    })

    if (Array.isArray(inventoryData.current) && inventoryData.current?.length > 0) {
      let xlExport = []

      for (let i = 0; i < inventoryData.current.length; i++) {
        let item = inventoryData.current[i]
        xlExport.push([
          item?.serialNum,
          unescapeHtml(item?.description),
          ...makeEmptyArray(2),
          item?.od ? numberWithCommasDecimals(item?.od, 3) : '',
          item?.length ? numberWithCommasDecimals(item?.length, 2) : '',
          ...makeEmptyArray(1),
          `${item?.connectionTop} ${!item?.connectionTop && !item?.connectionBottom ? '' : '/'} ${item?.connectionBottom
          }`,
          item?.status ? item?.status : '',
          `${item?.make} ${!item?.make && !item?.model ? '' : '/'} ${item?.model}`,
          item?.dateOn,
          ...makeEmptyArray(1),
          item?.dateOff,
        ])
      }

      XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'B9' })
    }

    const styles = getStyles(userRole?.userPrefs?.reportSettings?.primaryColor)

    let headerColDef = [
      { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
      { colSpan: 3, headerStyle: styles.lefttttt },
      { colSpan: 2, headerStyle: styles.fillRightBoldtttt },
      { colSpan: 3, headerStyle: styles.lefttttt },
    ]

    let mergedHeaderData = [
      ...makeMergedCells(headerColDef, 1, 5, 5),
      {
        s: { r: 6, c: 1 },
        e: { r: 6, c: 14 },
        style: styles.fillCenterBoldtttt,
      }, // Header
      {
        s: { r: 7, c: 1 },
        e: { r: 7, c: 1 },
        style: styles.fillCenterBoldtttt,
      }, // S/N header
      {
        s: { r: 7, c: 2 },
        e: { r: 7, c: 4 },
        style: styles.fillCenterBoldtttt,
      }, // Description header
      {
        s: { r: 7, c: 5 },
        e: { r: 7, c: 5 },
        style: styles.fillCenterBoldtttt,
      }, // OD header
      {
        s: { r: 7, c: 6 },
        e: { r: 7, c: 7 },
        style: styles.fillCenterBoldtttt,
      }, // Length header
      {
        s: { r: 7, c: 8 },
        e: { r: 7, c: 8 },
        style: styles.fillCenterBoldtttt,
      }, // CNX Top / Bot header
      {
        s: { r: 7, c: 9 },
        e: { r: 7, c: 9 },
        style: styles.fillCenterBoldtttt,
      }, // Status header
      {
        s: { r: 7, c: 10 },
        e: { r: 7, c: 10 },
        style: styles.fillCenterBoldtttt,
      }, // make / model header
      {
        s: { r: 7, c: 11 },
        e: { r: 7, c: 12 },
        style: styles.fillCenterBoldtttt,
      }, // Date Arrived header
      {
        s: { r: 7, c: 13 },
        e: { r: 7, c: 14 },
        style: styles.fillCenterBoldtttt,
      }, // Date Left header
    ]

    ws['!merges'] = mergedHeaderData

    for (const field in ws) {
      if (field === '!ref' || field === '!merges') continue
      const { r: row, c: column } = XLSX.utils.decode_cell(field)

      if (row < 8) continue

      if (column === 2) {
        //merge and style Description Col
        ws['!merges'].push({
          s: { r: row, c: column },
          e: { r: row, c: column + 2 },
          style: styles.centertttt,
        })
        continue
      }

      if (column === 6 || column === 11 || column === 13) {
        //merge and style Length Col
        ws['!merges'].push({
          s: { r: row, c: column },
          e: { r: row, c: column + 1 },
          style: styles.centertttt,
        })
        continue
      }

      //style all non merged cells in dataset
      ws[field].s = styles.centertttt
    }

    //add footer
    let footerAddress = XLSX.utils.encode_cell({ r: inventoryData.current.length + 8, c: 1 })
    ws[footerAddress] = { v: 'icpwebportal', t: 's' }

    ws['!merges'].push({
      s: { r: inventoryData.current.length + 8, c: 1 },
      e: { r: inventoryData.current.length + 8, c: 14 },
      style: styles.righttttt,
    })

    ws['!merges'].forEach((cellAddresses) => {
      const { s, e, style } = cellAddresses

      let startAddress = XLSX.utils.encode_cell(s)
      let endAddress = XLSX.utils.encode_cell(e)
      let cellRange = `${startAddress}:${endAddress}`

      if (style) XLSX.utils.sheet_set_range_style(ws, cellRange, style)
    })

    //add column widths
    if (!ws['!cols']) ws['!cols'] = [...makeEmptyArray(14)]
    generateColumnWidth(ws, 1, 12) // SN col
    generateColumnWidth(ws, 8, 25) // cnx top/bot col
    generateColumnWidth(ws, 10, 15) // make/model col
    generateColumnWidth(ws, 12, 20) // date arrived  col
    generateColumnWidth(ws, 14, 20) // date left  col

    //add logo
    ws['!images'] = [
      {
        '!pos': {
          c: 1,
          r: 1,
          x: 130,
          y: 5,
          w: 100,
          h: 100,
        },
        '!datatype': 'base64',
        '!data': getCurrentOrgIcon(),
      },
    ]
    // add sheet border
    let cellAddresses = ws['!ref'].split(':')
    XLSX.utils.sheet_set_range_style(ws, `B2:${cellAddresses[1]}`, {
      top: { style: 'thin' },
      right: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
    })

    XLSX.utils.book_append_sheet(wb, ws, 'Tool Inventory')
    XLSX.writeFile(wb, `${currentWellRef.current}_Tool_Inventory.xlsx`, { cellStyles: true, bookImages: true })
  }

  const generateColumnWidth = (ws, colNum, chWidth) => {
    if (!ws) return
    return (ws['!cols'][colNum] = { wch: chWidth })
  }

  const generatePdfDocument = () => {
    let inventoryRows = []
    inventoryRows.push(
      [{ text: 'Tool Inventory', isHeader: true, columnSpan: 11 }],
      [
        { text: 'S/N', isHeader: true },
        { text: 'Item Description', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: 'Cnx Top', isHeader: true },
        { text: 'Cnx Bottom', isHeader: true },
        { text: 'Status', isHeader: true },
        { text: 'Make', isHeader: true },
        { text: 'Model', isHeader: true },
        { text: 'Date Arrived', isHeader: true },
        { text: 'Date Left', isHeader: true },
      ],
    )

    for (let i = 0; i < inventoryData.current.length; i++) {
      const element = [
        { text: inventoryData.current[i].serialNum },
        { text: unescapeHtml(inventoryData.current[i].description) },
        { text: numberToString(inventoryData.current[i].od, 3) },
        { text: numberToString(inventoryData.current[i].length, 2) },
        { text: inventoryData.current[i].connectionTop },
        { text: inventoryData.current[i].connectionBottom },
        {
          text: inventoryData.current[i].status,
          color: inventoryData.current[i].status === 'Clean' ? appColors.cleanStatusColor : appColors.dirtyStatusColor,
        },
        { text: inventoryData.current[i].make },
        { text: inventoryData.current[i].model },
        { text: inventoryData.current[i].dateOn },
        { text: inventoryData.current[i].dateOff },
      ]
      inventoryRows.push(element)
    }

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: `${wellInfo.current.operator} / ${currentWell} - Tool Inventory`,
        showLogo: true,
        logo: getCurrentOrgIcon(),
        logoWidth: '25%',
        columnWidths: ['20%', '30%', '20%', '30%'],
        data: [
          [
            { text: 'Job#', isHeader: true },
            { text: wellInfo.current.jobNum, textAlign: 'left' },
            { text: 'Operator', isHeader: true },
            { text: wellInfo.current.operator, textAlign: 'left' },
          ],
          [
            { text: 'API#', isHeader: true },
            { text: wellInfo.current.apiNum, textAlign: 'left' },
            { text: 'Field', isHeader: true },
            { text: wellInfo.current.field, textAlign: 'left' },
          ],
          [
            { text: 'AFE#', isHeader: true },
            { text: wellInfo.current.afeNum, textAlign: 'left' },
            { text: 'Well', isHeader: true },
            { text: wellInfo.current.wellName, textAlign: 'left' },
          ],
          [
            { text: 'PO#', isHeader: true },
            { text: wellInfo.current.poNum, textAlign: 'left' },
            { text: 'State', isHeader: true },
            { text: wellInfo.current.state, textAlign: 'left' },
          ],
          [
            { text: 'Rig#', isHeader: true },
            { text: wellInfo.current.rig, textAlign: 'left' },
            { text: 'County', isHeader: true },
            { text: wellInfo.current.county, textAlign: 'left' },
          ],
        ],
      },
      {
        showTitle: true,
        title: `${wellInfo.current.operator} / ${currentWellRef.current} - Tool Inventory`,
        fixedHeaders: 2,
        manualWidth: true,
        columnWidths: ['15%', '15%', '7%', '9%', '6%', '11%', '6%', '7%', '8%', '8%', '8%'],
        data: inventoryRows,
      },
    ]

    return docData
  }

  const getPdfData = () => {
    if (!Array.isArray(inventoryData.current)) return null
    if (!wellInfo.current.hasOwnProperty('jobNum')) return null

    return {
      fileName: `Tool Inventory - ${removeSpecialSymbols(currentWell)}`,
      data: (
        <PdfDocument data={generatePdfDocument()} multiDocument={false} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings} />
      ),
    }
  }

  return {
    loading,
    fetchInventory,
    fetchInventoryData,
    onXlsxExport,
    getDropDowns,
    getPdfData,
    currentWell
  }
}

export default useInventory
