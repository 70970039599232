import React from 'react'
import { appColors } from 'utils'

const LineCellRenderer = (props) => {
  const boxStyle = {
    position: 'relative',
    width: '100px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
  }

  const lineStyle = {
    top: '50%',
    left: 0,
    width: '100%',
    borderTop: `2px ${props.value} ${appColors.itemTextColor}`,
    transform: 'translateY(-50%)', // centers the line
  }

  return (
    props.value != null && (
      <div>
        <div style={boxStyle}>
          <div style={{ marginRight: '8px', height: '28px', lineHeight: '28px' }}>{props.value}</div>
          <div style={lineStyle}></div>
        </div>
      </div>
    )
  )
}

export default LineCellRenderer
