import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { currentPageAtom, actionBarWidthAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import DailyCostGrid from './DailyCostGrid'
import MenuButton from 'components/common/MenuButton'
import SplitPane from 'components/common/SplitPane'
import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { Icon as Iconify } from '@iconify/react'
import DailyCostCharts from './DailyCostCharts'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import RefreshIcon from '@mui/icons-material/Refresh'
import InputModal from 'components/common/InputModal'
import useDailyCosts from 'components/common/hooks/useDailyCosts/useDailyCosts'
import useAnimatePane from 'components/common/hooks/useAnimatePane'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import CostCodesPane from './CostCodesPane'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const DailyCostPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [showCostCodes, setShowCostCodes] = useState(false)
  const [dateModalVisible, setDateModalVisible] = useState(false)
  const {
    loading: isLoading,
    fetchWellCosts,
    fetchCostData,
    getPdfData,
    getXlsData,
    getDropDowns,
    getSerialNumbers,
    currentWell,
  } = useDailyCosts()
  const [dailyCostData, setDailyCostData] = useState({})
  const [filteredCostData, setFilteredData] = useState([])
  const { setPaneVisible: setShowChart, paneVisible: showChart, paneSize } = useAnimatePane()
  const [submitXlsDateRange, setSubmitXlsDateRange] = useState(false)
  const showChartRef = useRef(showChart)

  useEffect(() => {
    showChartRef.current = showChart
  }, [showChart])

  useEffect(() => {
    if (!isLoading) {
      setDailyCostData(fetchWellCosts())
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.costsHeadersKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoading) setDailyCostData(fetchWellCosts())
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const onPdfExportGrouped = async () => {
    const pdfDoc = getPdfData(true)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onPdfExportGroupedByDate = async (startDate, endDate) => {
    const pdfDoc = getPdfData(false, startDate, endDate)
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onPdfExport = async () => {
    const pdfDoc = getPdfData()
    if (!pdfDoc) return
    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const refreshWellData = () => {
    setDailyCostData({})
    fetchCostData()
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: <RefreshIcon />,
        name: 'Refresh',
        onClick: () => {
          refreshWellData()
        },
      },
      { icon: <PictureAsPdfIcon style={{ color: 'darkgreen' }} />, name: 'Cost Report', onClick: onPdfExport },
      {
        icon: <PictureAsPdfIcon color='action' />,
        name: 'Grouped Cost Report',
        onClick: onPdfExportGrouped,
      },
      {
        icon: <PictureAsPdfIcon style={{ color: 'red' }} />,
        name: 'Cost Report Date Range',
        onClick: () => setDateModalVisible(true),
      },
    ]
    if (!showChart) {
      actions.push({
        icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Show Charts',
        onClick: () => handleShowChart(),
      })
    } else {
      actions.push({
        icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
        name: 'Hide Charts',
        onClick: () => handleShowChart(),
      })
    }
    return actions
  }

  const handleShowChart = () => {
    setShowChart((prev) => !prev)
  }

  const dateFilter = async (data, formikActions) => {
    setDateModalVisible(false)
    if (typeof data.startDate !== 'string') return false
    if (typeof data.endDate !== 'string') return false
    let startDate = data.startDate.slice(0, 10)
    let endDate = data.endDate.slice(0, 10)
    if (!isValidDate(data.startDate)) return false
    if (!isValidDate(data.endDate)) return false

    formikActions?.setSubmitting(false)
    submitXlsDateRange ? getXlsData('dateRange', startDate, endDate) : onPdfExportGroupedByDate(startDate, endDate)
    setSubmitXlsDateRange(false)
    return true
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const formatDateTimeString = (dateTimeStr) => {
    if (!isValidDate(dateTimeStr)) return null
    let date = new Date(dateTimeStr)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const DateFilterModal = ({ submitFunction, cancelFunction }) => {
    const currentDate = new Date(Date.now())

    let startDate = formatDateTimeString(currentDate)
    let endDate = formatDateTimeString(currentDate)
    const { dailyCosts } = dailyCostData

    if (Array.isArray(dailyCosts) && dailyCosts.length > 0) {
      if (isValidDate(`${dailyCosts[0]?.date}T00:01:00`))
        endDate = formatDateTimeString(`${dailyCosts[0]?.date}T00:01:00`)
      if (isValidDate(`${dailyCosts[dailyCosts.length - 1].date}T00:01:00`))
        startDate = formatDateTimeString(`${dailyCosts[dailyCosts.length - 1].date}T00:01:00`)
    }

    let formData = [
      {
        tag: 'startDate',
        value: startDate,
        text: 'From',
        inputType: 'date',
      },
      {
        tag: 'endDate',
        value: endDate,
        text: 'To',
        inputType: 'date',
      },
    ]

    return (
      <InputModal
        open={dateModalVisible}
        onClose={cancelFunction}
        title={'Select Dates'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
      />
    )
  }

  const CostGrid = useMemo(
    () => (
      <DailyCostGrid
        gridData={dailyCostData.hasOwnProperty('dailyCosts') ? dailyCostData.dailyCosts : []}
        setFilteredData={setFilteredData}
        getDropDowns={getDropDowns}
        getSerialNumbers={getSerialNumbers}
        isLoading={isLoading}
        setShowChart={setShowChart}
        showChart={showChartRef}
        getXlsData={getXlsData}
        setDateModalVisible={setDateModalVisible}
        setSubmitXlsDateRange={setSubmitXlsDateRange}
      />
    ),
    [dailyCostData, isLoading, setShowChart, setDateModalVisible, setSubmitXlsDateRange], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <React.Fragment>
      {dateModalVisible ? (
        <DateFilterModal submitFunction={dateFilter} cancelFunction={() => setDateModalVisible(false)} />
      ) : null}
      <SplitPane
        split='horizontal'
        defaultSize={'50%'}
        size={paneSize}
        style={{
          marginTop: '64px',
          marginLeft: `${leftPos}px`,
          height: 'calc(100vh - 64px)',
          maxHeight: 'calc(100vh - 64px)',
          minHeight: 'calc(100vh - 64px)',
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
          minWidth: `calc(100% - ${leftPos}px)`,
        }}
        allowResize={true}>
        <DailyCostCharts filteredDailyCosts={filteredCostData} />
        <SplitPane
          split='vertical'
          defaultSize={'calc(100% - 25px)'}
          size={showCostCodes ? '50%' : 'calc(100% - 25px)'}
          allowResize={showCostCodes}
          style={{
            height: '100%',
            width: `100%`,
            maxWidth: `100%`,
          }}>
          {CostGrid}
          <CostCodesPane
            showHidePane={() => setShowCostCodes(!showCostCodes)}
            paneVisible={showCostCodes}
            wellName={currentWell}
            refreshCosts={refreshWellData}
          />
        </SplitPane>
      </SplitPane>
      <StyledMenuIcon>
        <MenuButton actions={getMenuItems()} />
      </StyledMenuIcon>
    </React.Fragment>
  )
}

export default DailyCostPage
