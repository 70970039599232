import { Box, Tooltip } from '@mui/material'

const ErrorCellRenderer = ({ errors }) => {
  const getToolTipText = (errors) => {
    if (errors.length === 0) return null

    return (
      <table>
        <tbody style={{ color: 'white' }}>
          {errors.map((err, idx) => {
            return (
              <tr key={idx}>
                <td>
                  {idx + 1} - {err.warning}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <Box sx={{ width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Tooltip
        title={getToolTipText(errors)}
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Box
          sx={{
            width: '20px',
            height: '20px',
            borderRadius: '10px',
            backgroundColor: errors.length > 0 ? 'tomato' : 'green',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            display: 'flex',
            fontWeight: 'bold',
          }}>
          {errors.length > 0 ? errors.length : ''}
        </Box>
      </Tooltip>
    </Box>
  )
}

export default ErrorCellRenderer
