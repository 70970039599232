import { useEffect, useRef, useState } from 'react'
import useInnovaAxios from './useInnovaAxios'

const useCurveImportMnemonics = () => {
  const _isMounted = useRef(true)
  const [curveImportMnemonics, setCurveImportMnemonics] = useState([])
  const isLoadingImportMnemonics = useRef(false)

  const getCurveMnemonics = useInnovaAxios({
    url: '/dataAcq/getCurveImportMnemonics',
  })


  useEffect(() => {
    _isMounted.current = true
    fetchCurveMnemonics()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCurveMnemonics = async () => {
    if (!_isMounted.current) return
    if (isLoadingImportMnemonics.current) return

    isLoadingImportMnemonics.current = true
    try {
      const res = await getCurveMnemonics()
      isLoadingImportMnemonics.current = false
      if (res?.error) return
      if (!res.data) return
      setCurveImportMnemonics(res.data)
    } catch (error) {
      console.error('Error fetching curve import mnemonics:', error)
    }
  }

  const getCurveImportMnemonics = () => {
    if (isLoadingImportMnemonics) return []
    if (!curveImportMnemonics) return []
    if (!Array.isArray(curveImportMnemonics)) return []
    return curveImportMnemonics
  } 

  return { curveImportMnemonics, getCurveImportMnemonics }
}

export default useCurveImportMnemonics
