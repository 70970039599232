import React, { useState, useRef, useEffect } from 'react'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import { Box, Snackbar } from '@mui/material'
import Alert from '@mui/material/Alert'
import WitsmlServerTree from './WitsmlTree'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import WitsmlSelectionGrid from './WitsmlSelectionGrid'
import LogInfoGrid from './LogInfoGrid'
import CurveInfoGrid from './CurveInfoGrid'
import CurveMappingGrid from './CurveMappingGrid'
import { escapeHtml } from 'utils/htmlSymbolHandling'

const WitsmlConfigPane = ({ witsmlServer, dataConfig, updateCurveMapping, updateWitsmlSelection }) => {
  const _isMounted = useRef(true)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [selectedNodes, setSelectedNodes] = useState([])
  const [witsmlVersion, setWitsmlVersion] = useState({ success: false, message: '' })
  const [selectedLog, setSelectedLog] = useState(null)
  const [selectedCurveInfo, setSelectedCurveInfo] = useState(null)
  const isTesting = useRef(false)

  const testWitsmlConnection = useInnovaAxios({
    url: '/dataAcq/witsml/testConnection',
  })

  useEffect(() => {
    _isMounted.current = true
    handleTestWitsmlConnection()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNodeSelectionChange = async (newSelectedNodes, shouldUpdateApi = true) => {
    let payload = {
      uid: dataConfig.uid,
      wellName: '',
      wellUuid: '',
      wellBoreName: '',
      wellBoreUuid: '',
      logName: '',
      logUuid: '',
      trajName: '',
      trajUuid: '',
    }

    let well = newSelectedNodes.find((n) => n.level === 'well')
    if (well) {
      payload.wellName = escapeHtml(well.name)
      payload.wellUuid = well.uuid
    }

    let wellBore = newSelectedNodes.find((n) => n.level === 'wellBore')
    if (wellBore) {
      payload.wellBoreName = escapeHtml(wellBore.name)
      payload.wellBoreUuid = wellBore.uuid
    }

    let log = newSelectedNodes.find((n) => n.level === 'log')
    if (log) {
      payload.logName = escapeHtml(log.name)
      payload.logUuid = log.uuid
    }

    let traj = newSelectedNodes.find((n) => n.level === 'traj')
    if (traj) {
      payload.trajName = escapeHtml(traj.name)
      payload.trajUuid = traj.uuid
    }

    setSelectedNodes(newSelectedNodes)

    if (shouldUpdateApi) await updateWitsmlSelection(payload)
  }

  const handleTestWitsmlConnection = async () => {
    if (isTesting.current) return
    if (!witsmlServer) return

    isTesting.current = true
    let res = await testWitsmlConnection(witsmlServer)
    isTesting.current = false

    if (res?.error) {
      setWitsmlVersion({ success: false, message: `${res?.error?.response?.data?.error}` })
      return
    }

    if (!res?.data?.data?.success || res?.data?.data?.witsmlVer === '') {
      setWitsmlVersion({ success: false, message: 'Server failed to respond' })
      return
    }

    setWitsmlVersion({ success: true, message: `v${res?.data?.data?.witsmlVer}` })
  }

  const InfoGrids = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box sx={{ width: '50%', height: '100%' }}>
          <Box sx={{ width: '100%', height: '50%' }}>
            <WitsmlSelectionGrid selectedNodes={selectedNodes} witsmlVersion={witsmlVersion} />
          </Box>
          <Box sx={{ width: '100%', height: '50%' }}>
            <LogInfoGrid selectedLog={selectedLog} />
          </Box>
        </Box>
        <Box sx={{ width: '50%', height: '100%' }}>
          <Box sx={{ width: '100%', height: '50%', backgroundColor: 'red' }}>
            <CurveMappingGrid
              selectedConfig={dataConfig}
              selectedLog={selectedCurveInfo}
              updateCurveMapping={updateCurveMapping}
            />
          </Box>
          <Box sx={{ width: '100%', height: '50%' }}>
            <CurveInfoGrid selectedLog={selectedLog} witsmlServer={witsmlServer} setStatus={setStatus} />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <React.Fragment>
      <SplitPane
        split='vertical'
        defaultSize={'30%'}
        size={'30%'}
        allowResize={true}
        style={{
          height: `100%`,
          width: `100%`,
        }}>
        <WitsmlServerTree
          witsmlServer={witsmlServer}
          setStatus={setStatus}
          selectedNodes={selectedNodes}
          setSelectedNodes={handleTreeNodeSelectionChange}
          setSelectedLog={setSelectedLog}
          selectedConfig={dataConfig}
          setSelectedCurveInfo={setSelectedCurveInfo}
          selectedCurveInfo={selectedCurveInfo}
        />
        <InfoGrids />
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={() => {
            setStatus({ show: false, severity: 'info', message: '' })
          }}>
          <Alert
            onClose={() => {
              setStatus({ show: false, severity: 'info', message: '' })
            }}
            severity={status.severity}
            elevation={4}
            variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default WitsmlConfigPane
