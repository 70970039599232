import React, { useEffect, useRef, useMemo } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommas } from 'utils/stringFunctions'
import { round } from 'utils/numberFunctions'
import AnalyticsBarChart from 'components/Analytics/Charts/AnalyticsBarChart'
import { appColors } from 'utils'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AssetSearchCharts = ({ assetHistory, toolData }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  function getTotalsData() {
    if (!assetHistory) return []
    if (!Array.isArray(assetHistory)) return []
    if (assetHistory.length <= 0) return []

    let output = [
      { uid: 0, numWells: assetHistory.length, numRuns: 0, drilled: 0, drlgHrs: 0, brtHrs: 0, maxDls: 0, maxTemp: 0 },
    ]

    for (let i = 0; i < assetHistory.length; i++) {
      if (!Array.isArray(assetHistory[i].runs)) continue
      output[0].numRuns += assetHistory[i].runs.length

      for (let j = 0; j < assetHistory[i].runs.length; j++) {
        output[0].drilled += assetHistory[i].runs[j].drilled
        output[0].drlgHrs += assetHistory[i].runs[j].drillingCircHrs
        output[0].brtHrs += assetHistory[i].runs[j].brtHrs
        if (assetHistory[i].runs[j].maxDls > output[0].maxDls) output[0].maxDls = assetHistory[i].runs[j].maxDls
        if (assetHistory[i].runs[j].maxTemp > output[0].maxTemp) output[0].maxTemp = assetHistory[i].runs[j].maxTemp
      }
    }

    return output
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      flex: 1,
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const columnDefs = useMemo(
    () => [
      {
        headerName: '#Wells',
        field: 'numWells',
        cellStyle: centerAlignCell,
      },
      {
        headerName: '#Runs',
        field: 'numRuns',
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Drilled',
        field: 'drilled',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommas(round(params.data?.drilled, 2)),
      },
      {
        headerName: 'D&C Hrs',
        field: 'drlgHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => round(params.data?.drlgHrs, 2),
      },
      {
        headerName: 'BRT Hrs',
        field: 'brtHrs',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => round(params.data?.brtHrs, 2),
      },
      {
        headerName: 'Max DLS',
        field: 'maxDls',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => round(params.data?.maxDls, 2),
      },
      {
        headerName: 'Max Temp',
        field: 'maxTemp',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => round(params.data?.maxTemp, 2),
      },
    ],
    [],
  )

  const createChartData = () => {
    let output = {
      labels: [],
      datasets: [
        {
          label: 'Tool Type',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!Array.isArray(toolData)) return output

    for (let i = 0; i < toolData.length; i++) {
      let index = output.labels.findIndex((label) => label === toolData[i].toolType)
      if (index < 0) {
        output.labels.push(toolData[i].toolType)
        output.datasets[0].data.push(1)
        continue
      }

      output.datasets[0].data[index]++
    }

    return output
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {assetHistory === null ? (
        <AnalyticsBarChart
          wellData={createChartData()}
          units={''}
          title={''}
          xAxisTitle='Component Type'
          yAxisTitle={''}
          isLoading={false}
          onXlsxExport={null}
        />
      ) : null}
      {assetHistory !== null ? (
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={getTotalsData()}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            groupDisplayType={'groupRows'}
            onGridReady={onGridReady}
            headerHeight={30}
          />
        </div>
      ) : null}
    </Box>
  )
}

export default AssetSearchCharts
