import { useEffect, useState, useRef } from 'react'

import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from './useInnovaAxios'

function useInnovaContacts(refetchData) {
  const _isMounted = useRef(true)
  const [innovaContacts, setInnovaContacts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rawData, setRawData] = useState()

  const getData = useInnovaAxios({
    url: '/admin/companyContacts/getCompanyContacts',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getDropDowns()
  }, [rawData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [refetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    const response = await getData()
    if (_isMounted.current && response?.data) {
      setRawData(response.data)
    }
  }

  async function getDropDowns() {
    if (_isMounted.current === true) setIsLoading(true)
    if (!rawData) return

    let newDropDowns = []
    if (Array.isArray(rawData)) {
      rawData.forEach((val) => {
        newDropDowns.push({
          label: `${val.firstName} ${val.lastName}`,
          value: val.recordId,
          companyName: val.company !== undefined && val.company !== null ? unescapeHtml(val.company) : '',
          firstName: val.firstName,
          lastName: val.lastName
        })
      })
    }

    if (_isMounted.current === true) {
      setInnovaContacts(newDropDowns)
      setIsLoading(false)
    }
  }

  return [innovaContacts, isLoading]
}

export default useInnovaContacts
