import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { stylesToGridData } from 'components/WellPages/WallplotComposer/Elements/ElementPropertyModal/propertyUtils'
import { CHART_TYPES as chartTypes } from 'components/WellPages/WallplotComposer/Elements/elementDefs'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ChartOptionsGrid = forwardRef(({ chartData, chartType }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      return gridData
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        flex: 1,
        editable: true,
        cellDataType: false,
        cellStyle: (params) => {
          if (params.data?.type === 'color') {
            return {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: params.value,
              color: params.value,
            }
          }
          return { display: 'flex', alignItems: 'center', justifyContent: 'start' }
        },
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          if (params.data?.type === 'number') {
            return {
              component: 'agTextCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index < 0) return

          if (params?.data?.type === 'number') {
            if (params.newValue === null || params.newValue === undefined) params.newValue = 0
            params.newValue = parseFloat(params.newValue)
          }

          gridData[index].value = params.newValue

          gridApi.current.applyTransaction({ update: gridData })
        },
        cellRendererSelector: (params) => {
          if (params.data?.type === 'bool') {
            return {
              component: 'agCheckboxCellRenderer',
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, params.data?.precision || 0)}`
          }
          if (params?.data?.type === 'fileInput') {
            return params?.data?.value.name
          }
          return params?.data?.value
        },
      },
    ],
    [gridApi.current], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPropertyData = (data) => {
    let propertyData = []
    if (!data) return propertyData

    propertyData.push({
      label: `Offset Wells`,
      value: false,
      type: 'bool',
      tag: `showOffsets`,
    })
    propertyData.push({
      label: `Targets`,
      value: false,
      type: 'bool',
      tag: `showTargets`,
    })
    propertyData.push({
      label: `Driller Targets`,
      value: false,
      type: 'bool',
      tag: `showDrillersTargets`,
    })
    // commenting out until we get casing symbol drawing implemented
    // casing label does this job now.
    // propertyData.push({
    //   label: `Casing`,
    //   value: false,
    //   type: 'bool',
    //   tag: `showCasing`,
    // })
    // propertyData.push({
    //   label: `Annotations`,
    //   value: false,
    //   type: 'bool',
    //   tag: `showAnnotations`,
    // })
    propertyData.push({
      label: `Error Ellipses`,
      value: false,
      type: 'bool',
      tag: `showErrorEllipses`,
    })
    if (chartType === chartTypes.sectionView) {
      propertyData.push({
        label: `Geosteering`,
        value: false,
        type: 'bool',
        tag: `showGeosteering`,
      })
    }

    for (let i = 0; i < propertyData.length; i++) {
      propertyData[i].id = i
    }

    if (!data) return propertyData
    const gridData = stylesToGridData(data)

    for (let i = 0; i < propertyData.length; i++) {
      if (propertyData[i].tag === '') continue
      if (!gridData.hasOwnProperty(propertyData[i].tag)) continue
      propertyData[i].value = gridData[propertyData[i].tag]
    }

    return propertyData
  }

  return (
    <div style={{ display: 'flex', height: '100%', width: '100%' }}>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getPropertyData(chartData)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </div>
  )
})

export default ChartOptionsGrid
