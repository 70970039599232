import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import AnalyticsBarChart from './AnalyticsBarChart'
import { appColors, array2pipestr, getXlsxColumnLetter } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const FootageParamChart = ({ url }) => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const { getUnitsText } = useUnits()
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: url,
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(getChartSpecificParam('pageKey'))

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const getChartSpecificParam = (paramToGet) => {
    if (url === '/kpi/footagePerMotorMake') {
      if (paramToGet === 'propName') return 'motorMake'
      if (paramToGet === 'filterDefValue') return 'Motor Make'
      if (paramToGet === 'payloadPropName') return 'motorMakes'
      if (paramToGet === 'chartTitle') return 'ROP / Footage By Motor Make'
      if (paramToGet === 'pageKey') return PAGE_KEYS.footageRopByMotorPageKey
    }

    if (url === '/kpi/footagePerBitMake') {
      if (paramToGet === 'propName') return 'bitMake'
      if (paramToGet === 'filterDefValue') return 'Bit Make'
      if (paramToGet === 'payloadPropName') return 'bitMakes'
      if (paramToGet === 'chartTitle') return 'ROP / Footage By Bit Make'
      if (paramToGet === 'pageKey') return PAGE_KEYS.footageRopByBitPageKey
    }

    if (url === '/kpi/footagePerFormation') {
      if (paramToGet === 'propName') return 'formation'
      if (paramToGet === 'filterDefValue') return 'Formation'
      if (paramToGet === 'payloadPropName') return 'formations'
      if (paramToGet === 'chartTitle') return 'ROP / Footage By Formation'
      if (paramToGet === 'pageKey') return PAGE_KEYS.footageRopByFormationPageKey
    }

    if (url === '/kpi/footagePerHoleSize') {
      if (paramToGet === 'propName') return 'holeSize'
      if (paramToGet === 'filterDefValue') return 'Hole Size'
      if (paramToGet === 'payloadPropName') return 'holeSizes'
      if (paramToGet === 'chartTitle') return 'ROP / Footage By Hole Size'
      if (paramToGet === 'pageKey') return PAGE_KEYS.footageRopByHoleSizePageKey
    }

    if (url === '/kpi/footagePerDirCo') {
      if (paramToGet === 'propName') return 'dirCo'
      if (paramToGet === 'filterDefValue') return 'Dir Co.'
      if (paramToGet === 'chartTitle') return 'ROP / Footage By Directional Co.'
      if (paramToGet === 'pageKey') return PAGE_KEYS.footageRopByDirCoPageKey
    }

    if (url === '/kpi/footagePerRigNoJobDetails') {
      if (paramToGet === 'propName') return 'rig'
      if (paramToGet === 'filterDefValue') return 'Rig'
      if (paramToGet === 'chartTitle') return 'ROP / Footage By Rig'
      if (paramToGet === 'pageKey') return PAGE_KEYS.footageRopByRigPageKey
    }

    return 'unknown'
  }

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [
        {
          label: 'Slide',
          backgroundColor: appColors.slideColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'y',
        },
        {
          label: 'Rotate',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'y',
        },
        {
          type: 'line',
          label: 'Slide ROP',
          backgroundColor: appColors.itemTextColor,
          borderColor: appColors.itemTextColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'yAxisLine',
        },
        {
          type: 'line',
          label: 'Rotate ROP',
          backgroundColor: appColors.dirNptColor,
          borderColor: appColors.dirNptColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'yAxisLine',
        },
        {
          type: 'line',
          label: 'Avg ROP',
          backgroundColor: appColors.avgColor,
          borderColor: appColors.avgColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
          yAxisID: 'yAxisLine',
        },
      ],
    }

    if (!data) return output

    let labelName = getChartSpecificParam('propName')
    for (let i = 0; i < data.length; i++) {
      if (!data[i].hasOwnProperty(labelName)) continue
      output.labels.push(data[i][labelName])
      output.datasets[0].data.push(parseInt(data[i].slideFootage))
      output.datasets[1].data.push(parseInt(data[i].rotateFootage))
      output.datasets[2].data.push(parseInt(data[i].slideRop))
      output.datasets[3].data.push(parseInt(data[i].rotateRop))
      output.datasets[4].data.push(parseInt(data[i].avgRop))
    }

    return output
  }

  const isFilterActive = (filter) => {
    if (Array.isArray(filter) && filter.length > 0) return true
    return false
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
    }

    let filter = getParameter(getChartSpecificParam('filterDefValue'), searchParams)
    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }

    payload.dateTo = dateTo.substring(0, 10)
    payload.dateFrom = dateFrom.substring(0, 10)

    let payLoadPropName = getChartSpecificParam('payloadPropName')
    if (isFilterActive(filter) && payLoadPropName !== '') {
      payload[payLoadPropName] = filter.join('|')
    }

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const chartOptions = {
    scales: {
      yAxisLine: {
        title: {
          display: true,
          text: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        stacked: false,
        position: 'right',
        ticks: {
          autoSkip: false,
          precision: 0,
          count: (context) => {
            return context.chart.scales.y.ticks.length
          },
        },
      },
    },
  }

  const onXlsxExport = () => {
    let colIndex = 1
    let totalDrilled = 0.0
    let totalSlide = 0.0
    let totalRotate = 0.0
    let slidePct = 0.0
    let rotatePct = 0.0
    let ws = XLSX.utils.aoa_to_sheet(
      [
        [`Slide Footage (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Rotate Footage (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Total Footage (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Rotate ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
        [`Slide ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
        [`Avg ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`],
      ],
      {
        origin: 'A2',
      },
    )
    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [`Total Drilled (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Total Slide (${getUnitsText(UNITS_FOR.Depth)})`],
        [`Total Rotate (${getUnitsText(UNITS_FOR.Depth)})`],
        ['Slide %'],
        ['Rotate %'],
      ],
      { origin: 'A11' },
    )

    let labelName = getChartSpecificParam('propName')
    if (data && Array.isArray(data)) {
      data.forEach((well) => {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [`${well[labelName]}`],
            [parseFloat(well.slideFootage)],
            [parseFloat(well.rotateFootage)],
            [parseFloat(well.totalFootage)],
            [parseFloat(well.rotateRop)],
            [parseFloat(well.slideRop)],
            [parseFloat(well.avgRop)],
          ],
          { origin: `${getXlsxColumnLetter(colIndex)}1` },
        )
        totalSlide += parseFloat(well.slideFootage)
        totalRotate += parseFloat(well.rotateFootage)
        colIndex += 1
      })

      totalDrilled = totalSlide + totalRotate
      if (totalDrilled > 0.01) {
        slidePct = (totalSlide / totalDrilled) * 100
        rotatePct = (totalRotate / totalDrilled) * 100
      }

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [+totalDrilled.toFixed(2)],
          [+totalSlide.toFixed(2)],
          [+totalRotate.toFixed(2)],
          [+slidePct.toFixed(2)],
          [+rotatePct.toFixed(2)],
        ],
        { origin: 'B11' },
      )
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Slide Rotate Footage')
    XLSX.writeFile(wb, `Rop_Footage - ${getChartSpecificParam('propName')}.xlsx`)
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={getUnitsText(UNITS_FOR.Depth)}
      title={getChartSpecificParam('chartTitle')}
      xAxisTitle={getChartSpecificParam('filterDefValue')}
      yAxisTitle={`Footage (${getUnitsText(UNITS_FOR.Depth)})`}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
      chartOptions={chartOptions}
    />
  )
}

export default FootageParamChart
