import React, { useEffect, useRef, useState } from 'react'
import SplitPane from 'components/common/SplitPane'
import DepthDatumGrid from './DepthDatumGrid'
import { Box } from '@mui/material'
import floatingRigImage from 'assets/DepthDatums/floater.bmp'
import jackupRigImage from 'assets/DepthDatums/jackup.bmp'
import landRigImage from 'assets/DepthDatums/landrig.bmp'

const DepthDatumsPage = ({ wellName }) => {
  const _isMounted = useRef(false)
  const [selectedDatum, setSelectedDatum] = useState(null)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const DatumImage = () => {
    let src = landRigImage
    if (selectedDatum?.offshoreCheck) {
      src = jackupRigImage
      if (selectedDatum?.subseaCheck) {
        src = floatingRigImage
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          padding: '5px',
        }}>
        <Box component='img' src={src} alt='NONE' sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
      </Box>
    )
  }

  return (
    <SplitPane split='vertical' size={'75%'} allowResize={true} defaultSize={'75%'}>
      <DepthDatumGrid wellName={wellName} setSelectedDatum={setSelectedDatum} />
      <DatumImage />
    </SplitPane>
  )
}

export default DepthDatumsPage
