import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { sortColDefs, getStringId, CustomHeader } from 'components/common/AgGridUtils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import { calcSurvey, nudgeTvdIncAzi } from 'utils/projectionCalcs'
import { threeDeeScan } from 'utils/threeDeeScan'
import { uuidv4 } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AdditionalProjections = ({ selectedUid, projections, wellPlan, vsParams }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [projData, setProjData] = useState([])
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText, getDoglegBase } = useUnits()
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false

      let gridSurveys = getProjectionData()
      if (Array.isArray(gridSurveys) && gridSurveys.length > 1) {
        saveItemToLS(
          'landingProjGridDeltaTvd',
          'deltaTvd',
          gridSurveys.map((p) => p.dtvd),
        )
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onGridReady = (params) => {
    gridApi.current = params.api

    calcProjections()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('landingProjGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (params) => {
      if (params.oldValue === params.newValue) return
      if (params?.column?.colDef.colId === 'dtvd' || params?.column?.colDef.colId === 'tvd') return
      calcProjections()
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const getProjectionData = () => {
    if (!gridApi.current) return []

    let gridSurveys = []
    gridApi.current?.forEachNodeAfterFilterAndSort((node) => {
      if (node.data) gridSurveys.push(node.data)
    })

    return gridSurveys
  }

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  const calcProjections = useCallback(() => {
    let gridSurveys = getProjectionData()

    if (!Array.isArray(projections)) return
    if (projections.length <= 1) return

    let selectedProjIndex = projections.findIndex((p) => p.uid === selectedUid)
    if (selectedProjIndex <= 0) return
    if (selectedProjIndex === 0) return

    let tieOnSurvey = cloneDeep(projections[selectedProjIndex - 1])
    let additionalProjections =
      gridSurveys.length === 0
        ? [cloneDeep(projections[selectedProjIndex]), cloneDeep(projections[selectedProjIndex])]
        : gridSurveys

    let deltaTvd = [10, 20]
    if (gridSurveys.length === 0) {
      let savedDeltas = getItemFromLS('landingProjGridDeltaTvd', 'deltaTvd')
      if (Array.isArray(savedDeltas) && savedDeltas.length > 1) {
        deltaTvd[0] = savedDeltas[0]
        deltaTvd[1] = savedDeltas[1]
      }

      additionalProjections[0].dtvd = deltaTvd[0]
      additionalProjections[0].tvd += deltaTvd[0]
      additionalProjections[0].uid = uuidv4()
      additionalProjections[1].dtvd = deltaTvd[1]
      additionalProjections[1].tvd += deltaTvd[1]
      additionalProjections[1].uid = uuidv4()
    }

    if (gridSurveys.length > 0) {
      additionalProjections[0].tvd = projections[selectedProjIndex].tvd + additionalProjections[0].dtvd
      additionalProjections[1].tvd = projections[selectedProjIndex].tvd + additionalProjections[1].dtvd
    }

    for (let i = 0; i < additionalProjections.length; i++) {
      additionalProjections[i].error = false

      let res = nudgeTvdIncAzi(tieOnSurvey, additionalProjections[i], getDoglegBase(), [])
      if (res) {
        calcSurvey(tieOnSurvey, additionalProjections[i], getDoglegBase(), vsParams)
      }

      if (additionalProjections[i].error) continue
      let acResult = threeDeeScan({ ...additionalProjections[i] }, wellPlan)
      additionalProjections[i].ud = acResult?.acScan?.UD ? acResult?.acScan?.UD : 0
      additionalProjections[i].lr = acResult?.acScan?.LR ? acResult?.acScan?.LR : 0
      additionalProjections[i].dist = acResult?.acScan?.dist ? acResult?.acScan?.dist : 0
    }

    if (gridApi.current) {
      if (gridSurveys.length === 0) {
        setProjData(additionalProjections)
      }

      if (gridSurveys.length > 0) {
        gridApi.current.applyTransaction({
          update: additionalProjections,
        })
      }
    }

    autoSizeColumns()
  }, [vsParams, getDoglegBase, wellPlan, projections, selectedUid])

  const getRefTvd = useCallback(() => {
    if (!Array.isArray(projections)) return 0
    if (projections.length <= 1) return 0

    let selectedProjIndex = projections.findIndex((p) => p.uid === selectedUid)
    if (selectedProjIndex <= 0) return 0
    if (selectedProjIndex === 0) return 0
    return projections[selectedProjIndex].tvd
  }, [projections, selectedUid])

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('landingProjGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const centerAlignCellGreen = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'green',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        colId: 'dtvd',
        field: 'dtvd',
        headerName: 'DTVD',
        pinned: 'left',
        lockPosition: 'left',
        maxWidth: 80,
        editable: true,
        cellStyle: centerAlignCellGreen,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000,
          max: 100000,
          precision: 2,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return
          let data = { ...params.data }
          data.dtvd = params.newValue
          data.tvd = getRefTvd() + params.newValue

          gridApi.current.applyTransaction({
            update: [data],
          })

          calcProjections()
        },
      },
      {
        colId: 'md',
        field: 'md',
        headerName: 'MD',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'cl',
        field: 'cl',
        headerName: 'CL',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'inc',
        field: 'inc',
        headerName: 'INC',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCellGreen,
        editable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
      },
      {
        colId: 'azi',
        field: 'azi',
        headerName: 'AZI',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCellGreen,
        editable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 360,
          precision: 2,
        },
      },
      {
        colId: 'dls',
        field: 'dls',
        headerName: 'DLS',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        editable: false,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Dogleg) },
      },
      {
        colId: 'tf',
        field: 'tf',
        headerName: 'TF',
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        editable: false,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: '°' },
      },
      {
        colId: 'tvd',
        field: 'tvd',
        headerName: 'TVD',
        cellStyle: centerAlignCellGreen,
        editable: true,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return
          let data = { ...params.data }
          data.tvd = params.newValue
          data.dtvd = params.newValue - getRefTvd()
          gridApi.current.applyTransaction({
            update: [data],
          })

          calcProjections()
        },
      },
      {
        colId: 'ns',
        field: 'ns',
        headerName: 'NS',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'ew',
        field: 'ew',
        headerName: 'EW',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'vs',
        field: 'vs',
        headerName: 'VS',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'ud',
        field: 'ud',
        headerName: 'UD',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'lr',
        field: 'lr',
        headerName: 'LR',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'dist',
        field: 'dist',
        headerName: 'DIST',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
    ],
    [getUnitsText, centerAlignCellGreen, centerAlignCell, getRefTvd, calcProjections],
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const getRowStyle = (params) => {
    if (params?.data?.error === true) {
      return { border: '1px solid red' }
    }
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={projData}
        columnDefs={sortColDefs(columnDefs, 'landingProjGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={40}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        getRowId={getRowId}
        getRowStyle={getRowStyle}
        components={components}
      />
    </div>
  )
}

export default AdditionalProjections
