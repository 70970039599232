import React, { useMemo } from 'react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { Line } from 'react-chartjs-2'
import { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

export const TowercomparisonChart = ({ data, getUnitsText, showCumulative }) => {
  const { theme, getTextColor } = useInnovaTheme()

  const getTitle = useMemo(() => {
    if (!data) return ''
    let title = `${data.wellName} - Comparison (${data.timePeriod})`
    return title
  }, [data])

  const options = useMemo(
    () => ({
      layout: {
        padding: {
          right: 40,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        GradientPlugIn: {
          showGradient: true,
          theme: theme,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            filter: function (item, chart) {
              return !item.text.includes('-')
            },
            color: getTextColor(),
          },
        },
        tooltip: {
          displayColors: false,
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: function (tooltipItem) {
              return `Day ${tooltipItem[0].dataIndex + 1}`
            },
            label: function (context) {
              return [
                `Day Footage: ${numberWithCommasDecimals(data.days[context.dataIndex].day.footage, 2)} (${getUnitsText(
                  UNITS_FOR.Depth,
                )})`,
                `Night Footage: ${numberWithCommasDecimals(
                  data.days[context.dataIndex].night.footage,
                  2,
                )} (${getUnitsText(UNITS_FOR.Depth)})`,
                `Day ROP: ${numberWithCommasDecimals(data.days[context.dataIndex].day.rop, 2)} (${getUnitsText(
                  UNITS_FOR.Depth,
                )}/hr)`,
                `Night ROP: ${numberWithCommasDecimals(data.days[context.dataIndex].night.rop, 2)} (${getUnitsText(
                  UNITS_FOR.Depth,
                )}/hr)`,
                `Day Cumulative Footage: ${numberWithCommasDecimals(
                  data.days[context.dataIndex].day.cumulativeFootage,
                  2,
                )} (${getUnitsText(UNITS_FOR.Depth)})`,
                `Night Cumulative Footage: ${numberWithCommasDecimals(
                  data.days[context.dataIndex].night.cumulativeFootage,
                  2,
                )} (${getUnitsText(UNITS_FOR.Depth)})`,
                `Day Average ROP: ${numberWithCommasDecimals(
                  data.days[context.dataIndex].day.averageRop,
                  2,
                )} (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
                `Night Average ROP: ${numberWithCommasDecimals(
                  data.days[context.dataIndex].night.averageRop,
                  2,
                )} (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
              ]
            },
          },
          filter: function (tooltipItem, index) {
            if (index > 0) return false
            return true
          },
        },
        title: {
          display: true,
          text: getTitle,
          color: getTextColor(),
          font: {
            size: 14,
          },
        },
        zoom: {
          animation: {
            duration: 0,
          },
          pan: {
            enabled: true,
            mode: 'xy',
          },
          limits: {
            x: {
              min: 0,
              max: 100,
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          title: {
            display: true,
            text: `Days`,
            color: getTextColor(),
          },
          grid: {
            color: '#404040',
          },
          ticks: {
            color: getTextColor(),
          }
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
            color: getTextColor(),
          },
          grid: {
            color: '#404040',
          },
          ticks: {
            color: getTextColor(),
          }
        },
        y2: {
          beginAtZero: true,
          title: {
            display: true,
            text: `ROP (${getUnitsText(UNITS_FOR.Depth)})/hr`,
            color: getTextColor(),
          },
          grid: {
            color: '#404040',
          },
          ticks: {
            color: getTextColor(),
          }
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      animation: {
        duration: 0,
      },
    }),
    [getUnitsText, theme, getTitle, data.days, getTextColor],
  )

  const newLineSeries = (data, name, color, type, yAxisID = 'y') => {
    let series = {
      type: 'line',
      label: name,
      data: [],
      showLine: true,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 2,
      pointRadius: 0,
      pointHoverRadius: 0,
      interpolate: true,
      steppedLine: true,
      borderDash: [5, 5],
      yAxisID,
    }

    if (!data) return series
    if (!Array.isArray(data)) return series

    for (let i = 0; i < data.length; i++) {
      if (type === 'DAY ROP' && data[i].hasOwnProperty('day')) {
        series.data.push({
          x: i + 1,
          y: data[i].day.rop,
        })
      }
      if (type === 'NIGHT ROP' && data[i].hasOwnProperty('night')) {
        series.data.push({
          x: i + 1,
          y: data[i].night.rop,
        })
      }
      if (type === 'DAY AVERAGE ROP' && data[i].hasOwnProperty('day')) {
        series.data.push({
          x: i + 1,
          y: data[i].day.averageRop,
        })
      }
      if (type === 'NIGHT AVERAGE ROP' && data[i].hasOwnProperty('night')) {
        series.data.push({
          x: i + 1,
          y: data[i].night.averageRop,
        })
      }
    }

    return series
  }

  const newBarSeries = (data, name, color, type, yAxisID = 'y') => {
    let series = {
      type: 'bar',
      label: name,
      data: [],
      backgroundColor: color,
      borderColor: color,
      borderWidth: 1,
      categoryPercentage: 0.5,
      maxBarThickness: 24,
      yAxisID,
    }

    if (!data) return series
    if (!Array.isArray(data)) return series

    for (let i = 0; i < data.length; i++) {
      if (type === 'DAY FOOTAGE' && data[i].hasOwnProperty('day')) {
        series.data.push({
          x: i + 1,
          y: data[i].day.footage,
        })
      }
      if (type === 'NIGHT FOOTAGE' && data[i].hasOwnProperty('night')) {
        series.data.push({
          x: i + 1,
          y: data[i].night.footage,
        })
      }
      if (type === 'DAY CUMULATIVE FOOTAGE' && data[i].hasOwnProperty('day')) {
        series.data.push({
          x: i + 1,
          y: data[i].day.cumulativeFootage,
        })
      }
      if (type === 'NIGHT CUMULATIVE FOOTAGE' && data[i].hasOwnProperty('night')) {
        series.data.push({
          x: i + 1,
          y: data[i].night.cumulativeFootage,
        })
      }
    }

    return series
  }

  const getChartData = () => {
    const chartData = { datasets: [] }
    if (!data) return chartData
    if (!data.hasOwnProperty('days')) return chartData
    if (!Array.isArray(data.days)) return chartData

    chartData.datasets.push(newBarSeries(data.days, 'Day Footage', '#FF5733', 'DAY FOOTAGE', 'y'))
    chartData.datasets.push(newBarSeries(data.days, 'Night Footage', '#0096FF', 'NIGHT FOOTAGE', 'y'))
    chartData.datasets.push(newLineSeries(data.days, 'Day ROP', '#FF5733', 'DAY ROP', 'y2'))
    chartData.datasets.push(newLineSeries(data.days, 'Night ROP', '#0096FF', 'NIGHT ROP', 'y2'))
    if (showCumulative) {
      chartData.datasets.push(newBarSeries(data.days, 'Day Cumulative Footage', '#ffa505', 'DAY CUMULATIVE FOOTAGE', 'y'))
      chartData.datasets.push(newBarSeries(data.days, 'Night Cumulative Footage', '#4CBB17', 'NIGHT CUMULATIVE FOOTAGE', 'y'))
      chartData.datasets.push(newLineSeries(data.days, 'Day Average ROP', '#ffa505', 'DAY AVERAGE ROP', 'y2'))
      chartData.datasets.push(newLineSeries(data.days, 'Night Average ROP', '#4CBB17', 'NIGHT AVERAGE ROP', 'y2'))
    }
    return chartData
  }

  return <Line options={options} data={getChartData()} />
}

export default TowercomparisonChart
