import React, { useState, useRef, useEffect } from 'react'
import ToolfaceControl from './ToolfaceControl'
import AutoSizer from 'react-virtualized-auto-sizer'
import { styled } from '@mui/styles'
import DigitalTextDisplay from './DigitalTextDisplay'
import SurveyWidget from './SurveyWidget'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { round } from 'utils/numberFunctions'
import { projectionCalc, nudgeTvdIncAzi, findLanding } from 'utils/projectionCalcs'
import { threeDeeScan } from 'utils/threeDeeScan'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { Box } from '@mui/material'

const StyledDSItemContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  width: '50%',
  height: '100%',
})

const StyledWidgetContainer = styled(Box)({
  flex: 1,
  display: 'flex',
  width: '33%',
  height: '100%',
})

const StyledTextRow = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#202020',
  width: '100%',
  maxHeight: '75px',
})

const MultiWellDashboardCard = ({ wellData }) => {
  const [motorYield, setMotorYield] = useState(15)
  const dlsToLand = useRef(null)
  const bitProjection = useRef(null)
  const lastSurvey = useRef(null)
  const { getUnitsText } = useUnits()

  const getLastSurvey = () => {
    if (!wellData) return null
    if (!Array.isArray(wellData.surveys)) return null
    if (wellData.surveys.length === 0) return null
    let svy = { ...wellData.surveys[wellData.surveys.length - 1] }
    if (!Array.isArray(wellData.wellPlan)) return svy
    svy = threeDeeScan(svy, wellData.wellPlan)

    return svy
  }

  const getToolFaces = () => {
    let svyInc = 5.0
    let svy = lastSurvey.current
    if (svy !== null) svyInc = svy.inc

    if (!Array.isArray(svyInc < 5 ? wellData.mtfBuffer : wellData.gtfBuffer)) return []
    return svyInc < 5 ? wellData.mtfBuffer : wellData.gtfBuffer
  }

  const getEtf = () => {
    if (!wellData) return null
    let lastSvy = getLastSurvey()
    if (!lastSvy) return null
    return lastSvy.inc < 5 ? wellData?.etfMag : wellData.etfGrav
  }

  const getSlideGrade = () => {
    if (!wellData) return null
    let lastSvy = getLastSurvey()
    if (!lastSvy) return null
    return lastSvy.inc < 5 ? wellData?.slideGradeMag : wellData.slideGradeGrav
  }

  const getParameter = (param, decPl) => {
    if (!wellData) return '--'
    if (!wellData.hasOwnProperty(param)) return '--'
    if (wellData[param] === 0) return '0'
    if (wellData[param] === null || wellData[param] === undefined) return '--'
    if (typeof wellData[param] !== 'number') return wellData[param]
    return round(wellData[param], decPl ? decPl : 2)
  }

  const getLastMy = () => {
    if (!wellData) return '--'
    if (!wellData.hasOwnProperty('lastMotorYield')) return '--'
    return round(wellData.lastMotorYield.motorYield, 2)
  }

  const getBitDepthColor = () => {
    let bitDepth = getParameter('bitDepth')
    let holeDepth = getParameter('holeDepth')
    if (bitDepth === '--') return null

    let deltaDepth = (typeof holeDepth === 'number' ? holeDepth : 0) - (typeof bitDepth === 'number' ? bitDepth : 0)
    return deltaDepth < 0.5 ? 'green' : 'red'
  }

  const getRigStateColor = () => {
    let rigState = getParameter('rigState')
    if (rigState === 'UNKNOWN') return 'red'
    if (rigState === 'ROTATING' || 'SLIDING') return 'green'
    return 'organge'
  }

  const getDlsBase = () => {
    if (!getUnitsText(UNITS_FOR.Depth)) return 100
    if (getUnitsText(UNITS_FOR.Depth) === 'm') return 30
    return 100
  }

  const calcBitProjection = () => {
    if (!wellData) return null
    let lastSvy = getLastSurvey()

    let proj = projectionCalc(
      getParameter('holeDepth'),
      lastSvy ? { ...lastSvy } : null,
      parseFloat(motorYield),
      -1,
      -1,
      wellData?.slideRecords || [],
      wellData.wellPlan,
      getDlsBase(),
    )

    dlsToLand.current = 0
    let landing = findLanding(wellData.wellPlan)
    if (landing && landing.md > proj.md) {
      let res = nudgeTvdIncAzi(proj, lastSvy, getDlsBase(), wellData.wellPlan)
      if (res) dlsToLand.current = lastSvy.dls
    }

    return proj
  }

  useEffect(() => {
    bitProjection.current = calcBitProjection()
  }, [motorYield]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    lastSurvey.current = getLastSurvey()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ width: '100%', height: '50%', margin: 'auto' }}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <ToolfaceControl
                rigState={wellData.rigState}
                toolFaceData={getToolFaces()}
                svyData={lastSurvey.current}
                bitData={bitProjection.current}
                slideGrade={getSlideGrade()}
                etf={getEtf()}
                phase={wellData.phase}
                lastMy={getLastMy()}
                dlsToLand={dlsToLand.current}
                h={height}
                w={width}
                updateTime={new Date(Date.now()).toISOString()}
              />
            )
          }}
        </AutoSizer>
      </Box>
      <Box sx={{ width: '100%', height: '50%' }}>
        <StyledTextRow>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Hole Depth'}
              value={getParameter('holeDepth', 2)}
              unit={getUnitsText(UNITS_FOR.Depth)}
            />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Bit Depth'}
              value={getParameter('bitDepth', 2)}
              unit={getUnitsText(UNITS_FOR.Depth)}
              color={getBitDepthColor()}
            />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'ROP'}
              value={getParameter('rop', 1)}
              unit={`${getUnitsText(UNITS_FOR.Depth)}/hr`}
            />
          </StyledWidgetContainer>
        </StyledTextRow>
        <StyledTextRow>
          <StyledWidgetContainer>
            <DigitalTextDisplay title={'WOB'} value={getParameter('wob', 1)} unit={getUnitsText(UNITS_FOR.Weight)} />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay title={'RPM'} value={getParameter('rpm', 0)} unit={''} />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Diff P'}
              value={getParameter('diffP', 0)}
              unit={getUnitsText(UNITS_FOR.Pressure)}
            />
          </StyledWidgetContainer>
        </StyledTextRow>
        <StyledTextRow>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Rig state'}
              value={getParameter('rigState')}
              unit={''}
              color={getRigStateColor()}
            />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Flow'}
              value={getParameter('flow', 0)}
              unit={getUnitsText(UNITS_FOR.FlowRate)}
            />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Hole Size'}
              value={getParameter('holeSize', 3)}
              unit={getUnitsText(UNITS_FOR.Diameter)}
            />
          </StyledWidgetContainer>
        </StyledTextRow>
        <StyledTextRow>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Slide unseen'}
              value={getParameter('unSeenSlide', 2)}
              unit={getUnitsText(UNITS_FOR.Depth)}
            />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay title={'Last MY'} value={round(getLastMy(), 2)} unit={getUnitsText(UNITS_FOR.Dogleg)} />
          </StyledWidgetContainer>
          <StyledWidgetContainer>
            <DigitalTextDisplay
              title={'Active MY'}
              value={motorYield}
              unit={getUnitsText(UNITS_FOR.Dogleg)}
              isEditable={true}
              setterFunc={setMotorYield}
            />
          </StyledWidgetContainer>
        </StyledTextRow>
        <StyledTextRow>
          <SurveyWidget svy1={lastSurvey.current} svy2={bitProjection.current} />
        </StyledTextRow>
        <StyledTextRow>
          <StyledDSItemContainer>
            <DigitalTextDisplay
              value={wellData?.motorDesc ? unescapeHtml(wellData?.motorDesc) : '--'}
              displayUnits={false}
              displayTitle={false}
            />
          </StyledDSItemContainer>
          <StyledDSItemContainer>
            <DigitalTextDisplay
              value={wellData?.bitDescription ? unescapeHtml(wellData?.bitDescription) : '--'}
              displayUnits={false}
              displayTitle={false}
            />
          </StyledDSItemContainer>
        </StyledTextRow>
      </Box>
    </Box>
  )
}

export default MultiWellDashboardCard
