import WellSpheres from "./WellSpheres"

const OffsetWellboreSpheres = ({ offsetData, offsetsOn, display }) => {
  if (!display) return null
  if (!offsetsOn) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null

  let shpereSegments = 16
  let sphereInterval = 1
  if (offsetData.length > 20) {
    shpereSegments = 8
    sphereInterval = 5
  }

  return offsetData.map((osWell, index) => (
    <WellSpheres
      interval={sphereInterval}
      segments={shpereSegments}
      wellData={osWell}
      key={`${osWell.name}sphere${index}`}
      display={true}
    />
  ))
}

export default OffsetWellboreSpheres