import { cloneDeep } from 'lodash'
import { calcVS } from 'utils/surveyFunctions'

export function getDrillersTargets2D(targets, output, chartType, vsData, targetsOn) {
  if (targetsOn === false) return
  if (!targets) targets = []
  if (Array.isArray(targets) === false) targets = []

  targets.forEach((tgt) => {
    if (!Array.isArray(tgt?.drillingTargetPoints)) return
    if (tgt.drillingTargetPoints.length === 0) return
    let tgtCopy = cloneDeep(tgt)
    tgtCopy.targetName = `${tgtCopy.targetName} - Drillers Tgt @(${tgtCopy.drillersTargetConfidence}%)`
    tgtCopy.geometry = 'POLYGON'
    tgtCopy.color = 'tomato'
    tgtCopy.polygonPoints = []

    tgt?.drillingTargetPoints.forEach((pt) => {
      tgtCopy.polygonPoints.push({
        top: {
          ...pt
        },
        bottom: {
          ...pt
        },
      })
    })

    if (chartType === 'plan') addTargetPointPlan(output, tgtCopy, 'tomato', 'dot')
    if (chartType === 'section') addTargetPointSection(output, vsData, tgtCopy, 'tomato', 'dot')
    processPolygon(output, vsData, tgtCopy, chartType)
  })
}

export function getTargets2D(targets, output, chartType, vsData, targetsOn) {
  if (targetsOn === false) return
  if (!targets) targets = []
  if (Array.isArray(targets) === false) targets = []

  targets.forEach((tgt) => {
    if (chartType === 'plan') addTargetPointPlan(output, tgt, 'tomato', 'dot')
    if (chartType === 'section') addTargetPointSection(output, vsData, tgt, 'tomato', 'dot')

    //Process Geometry
    if (tgt.geometry === 'POLYGON') processPolygon(output, vsData, tgt, chartType)
    if (tgt.geometry === 'CIRCLE') processCircle(output, vsData, tgt, chartType)
    if (tgt.geometry === 'ELLIPSE') processEllipse(output, vsData, tgt, chartType)
    if (tgt.geometry === 'RECTANGLE') processRectangle(output, vsData, tgt, chartType)
  })
}

function processTargetPoints(outputRef, points, chartType, vsData, verticalLineInterval) {
  //Draw top of target and vertical lines if there is a thickness
  let verticalIntervalCounter = 0
  points.forEach((pt) => {
    verticalIntervalCounter++

    outputRef.points.push({
      x: chartType === 'plan' ? pt.top.localEast : calcVSforTgt(vsData, pt.top.localNorth, pt.top.localEast),
      y: chartType === 'plan' ? pt.top.localNorth : pt.top.tvd,
    })

    if (verticalIntervalCounter === verticalLineInterval) {
      if (pt.top.tvd !== pt.bottom.tvd) {
        outputRef.points.push({
          x: chartType === 'plan' ? pt.bottom.localEast : calcVSforTgt(vsData, pt.bottom.localNorth, pt.bottom.localEast),
          y: chartType === 'plan' ? pt.bottom.localNorth : pt.bottom.tvd,
        })

        outputRef.points.push({
          x: chartType === 'plan' ? pt.top.localEast : calcVSforTgt(vsData, pt.top.localNorth, pt.top.localEast),
          y: chartType === 'plan' ? pt.top.localNorth : pt.top.tvd,
        })
      }

      verticalIntervalCounter = 0
    }
  })

  //Draw bottom of target
  points.forEach((pt) => {
    outputRef.points.push({
      x: chartType === 'plan' ? pt.bottom.localEast : calcVSforTgt(vsData, pt.bottom.localNorth, pt.bottom.localEast),
      y: chartType === 'plan' ? pt.bottom.localNorth : pt.bottom.tvd,
    })
  })
}

function processPolygon(output, vsData, tgt, chartType) {
  if (!Array.isArray(tgt.polygonPoints)) return
  if (tgt.polygonPoints.length === 0) return

  let outputRef = getLastTgtRef(output)

  if (tgt.isLeaseLine === true || tgt.isHardLine === true) {
    if (chartType === 'plan') outputRef.x = tgt.polygonPoints[0].top.localEast
    if (chartType === 'plan') outputRef.y = tgt.polygonPoints[0].top.localNorth
    if (chartType === 'section') {
      outputRef.symbol = 'donotshow'
      return
    }
  }

  outputRef.points = []
  processTargetPoints(outputRef, tgt.polygonPoints, chartType, vsData, 1)
}

function checkValid(input) {
  if (!input) return false
  if (!input.hasOwnProperty('points')) return false
  if (!Array.isArray(input.points)) return false
  if (input.points.length === 0) return false

  return true
}

function processCircle(output, vsData, tgt, chartType) {
  if (!checkValid(tgt.circleProperties)) return
  let outputRef = getLastTgtRef(output)

  outputRef.points = []
  processTargetPoints(outputRef, tgt.circleProperties.points, chartType, vsData, 5)
}

function processEllipse(output, vsData, tgt, chartType) {
  if (!checkValid(tgt.ellipseProperties)) return
  let outputRef = getLastTgtRef(output)

  outputRef.points = []
  processTargetPoints(outputRef, tgt.ellipseProperties.points, chartType, vsData, 5)
}

function processRectangle(output, vsData, tgt, chartType) {
  if (!checkValid(tgt.rectProperties)) return
  let outputRef = getLastTgtRef(output)

  outputRef.points = []
  processTargetPoints(outputRef, tgt.rectProperties.points, chartType, vsData, 1)
}

function getLastTgtRef(output) {
  let outputRef = {}
  if (output.length > 0) outputRef = output[output.length - 1]
  return outputRef
}

function addTargetPointPlan(output, tgt, color, symbol) {
  output.push({
    color: tgt.color || color,
    x: tgt.targetCenter.localEast,
    y: tgt.targetCenter.localNorth,
    text: tgt.targetName,
    symbol: symbol,
  })
}

function addTargetPointSection(output, vsData, tgt, color, symbol) {
  output.push({
    color: tgt.color || color,
    x: calcVSforTgt(vsData, tgt.targetCenter.localNorth, tgt.targetCenter.localEast),
    y: tgt.targetCenter.tvd,
    text: tgt.targetName,
    symbol: symbol,
  })
}

function calcVSforTgt(vsData, ns, ew) {
  return calcVS(vsData.VsAzi, ns, ew, vsData.VsOrgNorth, vsData.VsOrgEast)
}
