import { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentWellAtom, wellDataSelector, refetchWellDataAtom } from 'atoms'
import useAxiosGzip from './useAxiosGzip'
import { cloneDeep, takeRight } from 'lodash'

var _isWellDataInitialized = false
var _lastWell = '' // suppress useEffect [currentWell] when multiple components use this hook

const useWellData = (wellName = null) => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const isLoadingRef = useRef(false)
  const showErrorEllipses = useRef(false)
  const [wellData, setWellData] = useRecoilState(wellDataSelector)
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellRef = useRef(wellName ? wellName : currentWell.wellName)
  
  const getWellData = useAxiosGzip({
    url: '/well/getWellDataGz',
  })

  const shouldRefreshWellData = useRecoilValue(refetchWellDataAtom)

  useEffect(() => {
    _isMounted.current = true
    if (!_isWellDataInitialized) {
      _isWellDataInitialized = true
      _lastWell = currentWellRef.current
      if (currentWellRef.current && currentWellRef.current.length > 0) {
        getData(currentWellRef.current)
      }
    }
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    if (!wellName && !currentWell.wellName) return
    currentWellRef.current = wellName ? wellName : currentWell.wellName

    if (currentWellRef.current?.length === 0) return
    if (_lastWell === currentWellRef.current) return

    _lastWell = currentWellRef.current
    getData(currentWellRef.current)
  }, [currentWell, wellName, shouldRefreshWellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPrincipalPlanName = () => {
    if (isLoading) return ''
    if (!wellData?.wellPlan?.offsetWellbore) return ''
    return wellData?.wellPlan?.offsetWellbore
  }

  const getWellPlanSurveys = () => {
    if (isLoading) return []
    if (!Array.isArray(wellData?.wellPlan?.surveyData)) return []
    return wellData?.wellPlan?.surveyData
  }

  const getData = async (well) => {
    if (!well || well === '') return
    if (isLoadingRef.current) return
    isLoadingRef.current = true
    setIsLoading(true)

    const response = await getWellData({
      wellName: well,
      wellPlan: true,
      surveys: true,
      offsetWells: true,
      targets: true,
      actualWellData: true,
      dailyActivity: true,
      costs: true,
      inventory: true,
      summary: true,
      connections: true,
      geosteering: true,
      activitySummary: true,
      calcErrorEllipses: showErrorEllipses.current,
    })

    if (response?.data) {
      setWellData(response?.data)
    }

    isLoadingRef.current = false
    setIsLoading(false)
  }

  const refreshWellData = async () => {
    await getData(currentWell.wellName)
  }

  function getVerticalSectionParams() {
    let vsParams = {
      vsAzi: 0,
      vsNS: 0,
      vsEW: 0
    }

    if (!wellData) return vsParams
    if (!wellData.actualWellData?.verticalSection) return vsParams

    vsParams.vsAzi = wellData.actualWellData?.verticalSection.VsAzi
    vsParams.vsNS = wellData.actualWellData?.verticalSection.VsOrgNorth
    vsParams.vsEW = wellData.actualWellData?.verticalSection.VsOrgEast
    return vsParams
  }

  function getTargets(leaseLinesOnly = false) {
    if (!wellData) return []
    if (!Array.isArray(wellData.targets)) return []
    if (!leaseLinesOnly) return wellData.targets
    return wellData.targets.filter((tgt) => tgt.isLeaseLine)
  }

  function getCriticalPoints() {
    if (isLoading) return []
    if (!Array.isArray(wellData?.criticalPoints)) return []
    return wellData?.criticalPoints
  }

  function getConnectionData(numConnections = 0) {
    if (isLoading) return []
    if (!Array.isArray(wellData?.connectionData)) return []
    if (wellData.connectionData.length === 0) return []
    if (!Array.isArray(wellData?.connectionData[0]?.connections)) return []
    if (numConnections > 0) return takeRight(wellData?.connectionData[0]?.connections, numConnections)
    return wellData?.connectionData[0]?.connections
  }

  function getAllConnectionData() {
    if (isLoading) return []
    if (!Array.isArray(wellData?.connectionData)) return []
    if (wellData.connectionData.length === 0) return []
    if (!Array.isArray(wellData?.connectionData[0]?.connections)) return []
    return wellData?.connectionData
  }

  function getGeoSteeringData() {
    if (isLoading) return []
    if (!Array.isArray(wellData?.geosteering)) return []
    if (wellData.geosteering.length === 0) return []
    return wellData?.geosteering
  }

  const toggleErrorEllipses = (show) => {
    if (show && showErrorEllipses.current) return
    if (!show && !showErrorEllipses.current) return
    showErrorEllipses.current = show
    if (!show) return
    getData(currentWellRef.current)
  }

  const updateWellDataPlan = (offsetName) => {
    if (!offsetName) return
    if (!wellData) return
    if (!wellData.wellPlan) return

    let newWellData = cloneDeep(wellData)
    newWellData.wellPlan.offsetWellbore = offsetName
    setWellData(newWellData)
  }

  const getCurrentWell = () => {
    return currentWell.wellName
  }

  return {
    wellData,
    refreshWellData,
    isLoading,
    getCurrentWell,
    getPrincipalPlanName,
    getWellPlanSurveys,
    getVerticalSectionParams,
    getTargets,
    getCriticalPoints,
    getConnectionData,
    getAllConnectionData,
    getGeoSteeringData,
    toggleErrorEllipses,
    updateWellDataPlan,
  }
}

export default useWellData
