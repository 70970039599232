const deg2Rad = Math.PI / 180
const rad2Deg = 180 / Math.PI
const nullVal = -9999.25
const threeDeeScanOutput = {
  dist: nullVal,
  deltaInc: nullVal,
  deltaAzi: nullVal,
  UD: nullVal,
  LR: nullVal,
  TFO: nullVal,
  TFN: nullVal,
  offMD: nullVal,
  offINC: nullVal,
  offAZI: nullVal,
  offTVD: nullVal,
  offNS: nullVal,
  offEW: nullVal,
}

export function threeDeeScan(Point, Offset) {
  Point.acScan = { ...threeDeeScanOutput }

  if (!Point.hasOwnProperty('md')) return Point
  if (!Array.isArray(Offset)) return Point
  if (Offset?.length <= 1) return Point

  // Load current reference well position and calculate position vector
  let MD = Point.md
  let Inc = Point.inc * deg2Rad
  let Azi = Point.azi * deg2Rad
  let TVD = Point.tvd
  let NS = Point.ns
  let EW = Point.ew

  // Calculate Tool face angle Th is high side vector Tl is lateral vector
  let Th1 = Math.cos(Inc) * Math.cos(Azi)
  let Th2 = Math.cos(Inc) * Math.sin(Azi)
  let Th3 = -Math.sin(Inc)

  let Tl1 = -Math.sin(Azi)
  let Tl2 = Math.cos(Azi)
  let Tl3 = 0.0

  let Dist = 999999.0

  // Loop through Offset well surveys to find closest approach
  for (let j = 0; j < Offset.length; j++) {
    let MD1 = Offset[j].md
    let INC1 = Offset[j].inc * deg2Rad
    let AZI1 = Offset[j].azi * deg2Rad
    let TVD1 = Offset[j].tvd
    let NS1 = Offset[j].ns
    let EW1 = Offset[j].ew

    let MD2 = Offset[j].md
    let INC2 = Offset[j].inc * deg2Rad
    let AZI2 = Offset[j].azi * deg2Rad
    let TVD2 = Offset[j].tvd
    let NS2 = Offset[j].ns
    let EW2 = Offset[j].ew

    if (j < Offset.length - 1) {
      MD2 = Offset[j + 1].md
      INC2 = Offset[j + 1].inc * deg2Rad
      AZI2 = Offset[j + 1].azi * deg2Rad
      TVD2 = Offset[j + 1].tvd
      NS2 = Offset[j + 1].ns
      EW2 = Offset[j + 1].ew
    }

    let sinc1 = Math.sin(INC1)
    let sinc2 = Math.sin(INC2)
    let cinc1 = Math.cos(INC1)

    let sazi1 = Math.sin(AZI1)
    let sazi2 = Math.sin(AZI2)
    let cazi1 = Math.cos(AZI1)
    let cazi2 = Math.cos(AZI2)

    let T11 = sinc1 * cazi1
    let T12 = sinc1 * sazi1
    let T13 = cinc1

    let T21 = sinc2 * cazi2
    let T22 = sinc2 * sazi2
    let T23 = Math.cos(INC2)

    let dNS1 = NS - NS1
    let dEW1 = EW - EW1
    let dTV1 = TVD - TVD1

    let N1 = T11 * dNS1 + T12 * dEW1 + T13 * dTV1
    let N2 = T21 * dNS1 + T22 * dEW1 + T23 * dTV1

    // Calculate Alpha (Dogleg) for survey stations
    let Alpha = Math.cos(INC2 - INC1) - sinc1 * sinc2 * (1 - Math.cos(AZI2 - AZI1))
    Alpha = Alpha === 1.0 ? 0.0 : Math.PI / 2 - Math.atan(Alpha / Math.sqrt(1 - Alpha * Alpha))

    let deltaMd = MD2 - MD1
    let alphaStar = 0.0
    let mdStar = 0.0
    let tStar1 = 0
    let tStar2 = 0
    let tStar3 = 0

    if (Alpha < 0.0001) {
      // calculate alphaStar and mdStar and T_Star for straight hole conditions
      if (N1 - N2 + deltaMd === 0.0) {
        alphaStar = 0.0
        mdStar = 0.0
      } else {
        alphaStar = 0.0
        mdStar = (deltaMd * N1) / (N1 - N2 + deltaMd)
      }

      if (deltaMd < 0.1) mdStar = 0
      if (MD === 0 && mdStar < 0) mdStar = Math.abs(mdStar)

      if (Alpha === 0.0) {
        tStar1 = T11
        tStar2 = T12
        tStar3 = T13
      }
    } else {
      // Calculate alphaStar mdStar and T_Star
      let sAlpha = Math.sin(Alpha)
      alphaStar = Math.abs(Math.atan(N1 / ((N1 * Math.cos(Alpha) - N2) / sAlpha + deltaMd / Alpha)))

      if (alphaStar < 0) alphaStar = 0
      if (alphaStar > Alpha) alphaStar = Alpha

      mdStar = (deltaMd * alphaStar) / Alpha

      if (MD === 0 && mdStar < 0) mdStar = Math.abs(mdStar)

      let sinalphaStarDivSinAlpha = Math.sin(alphaStar) / sAlpha
      let sinAlphaMalphaStar = Math.sin(Alpha - alphaStar) / sAlpha

      tStar1 = sinAlphaMalphaStar * T11 + sinalphaStarDivSinAlpha * T21
      tStar2 = sinAlphaMalphaStar * T12 + sinalphaStarDivSinAlpha * T22
      tStar3 = sinAlphaMalphaStar * T13 + sinalphaStarDivSinAlpha * T23
    }

    let incStar = 0
    let aziStar = 0
    let tempDist = 999999
    let tvdStar = 0
    let nsStar = 0
    let ewStar = 0
    let deltaTVD = 0
    let deltaNS = 0
    let deltaEW = 0

    if (mdStar < 0.01 && mdStar > -0.01) mdStar = 0

    let checkFlag = 0
    if (mdStar - deltaMd > 0.1 || alphaStar > Alpha || alphaStar < 0 || mdStar < 0) {
      checkFlag = 0
    } else {
      checkFlag = 1

      // Calculate inc and azi of closest approach
      incStar = tStar3 === 0.0 ? INC1 : Math.atan(Math.sqrt(tStar1 * tStar1 + tStar2 * tStar2) / tStar3)
      if (incStar < 0) incStar = incStar + Math.PI

      aziStar = tStar1 === 0.0 ? AZI1 : Math.atan2(tStar2, tStar1)

      if (aziStar > Math.PI * 2) {
        aziStar = Math.PI * 2 - aziStar
      } else if (aziStar < 0) {
        aziStar = Math.PI * 2 + aziStar
      }

      // Calculate Position of closest approach
      let RF = 1.0
      if (alphaStar >= 0.001) RF = (360.0 / (alphaStar * rad2Deg * Math.PI)) * Math.tan(alphaStar / 2.0)

      let mdOverTwo = mdStar / 2
      tvdStar = TVD1 + mdOverTwo * ((cinc1 + Math.cos(incStar)) * RF)
      nsStar = NS1 + mdOverTwo * ((sinc1 * cazi1 + Math.sin(incStar) * Math.cos(aziStar)) * RF)
      ewStar = EW1 + mdOverTwo * ((sinc1 * sazi1 + Math.sin(incStar) * Math.sin(aziStar)) * RF)

      deltaTVD = tvdStar - TVD
      deltaNS = nsStar - NS
      deltaEW = ewStar - EW

      tempDist = Math.sqrt(deltaTVD * deltaTVD + deltaNS * deltaNS + deltaEW * deltaEW)
    }

    // Check to see if new position is closer than previous found closest approach and that it lies within the normal plane
    if (tempDist < Dist && checkFlag === 1) {
      let PMPTL = deltaTVD * Tl3 + deltaNS * Tl1 + deltaEW * Tl2
      let PMPTH = deltaTVD * Th3 + deltaNS * Th1 + deltaEW * Th2
      let TF = 0
      let TFN = 0

      TF = PMPTH === 0.0 ? 0.0 : Math.atan2(PMPTL, PMPTH)

      if (deltaNS < 0.000001 && deltaNS > -0.000001) deltaNS = 0
      if (deltaEW < 0.000001 && deltaEW > -0.000001) deltaEW = 0

      // Calculate TF to Azi North
      if (deltaNS !== 0.0) {
        TFN = Math.atan2(deltaEW, deltaNS)

        if (TFN < 0) TFN += Math.PI * 2
        if (TFN > Math.PI * 2) TFN -= Math.PI * 2
      } else {
        if (deltaEW > 0) {
          TFN = Math.PI * 0.5
        } else if (deltaEW < 0) {
          TFN = 2 * Math.PI * 0.75
        } else {
          TFN = 0
        }
      }

      //Check to see if point is actually an existing survey point
      if (MD1 === MD || MD2 === MD) {
        if (TVD1 === TVD && NS1 === NS && EW1 === EW) {
          tempDist = 0
          TFN = 0
          TF = 0
          incStar = Inc
          aziStar = Azi
          nsStar = NS
          ewStar = EW
          tvdStar = TVD
        }

        if (TVD2 === TVD && NS2 === NS && EW2 === EW) {
          tempDist = 0
          TFN = 0
          TF = 0
          incStar = Inc
          aziStar = Azi
          nsStar = NS
          ewStar = EW
          tvdStar = TVD
        }
      }

      Dist = tempDist
      Point.acScan.offMD = MD1 + mdStar
      Point.acScan.offTVD = tvdStar
      Point.acScan.offNS = nsStar
      Point.acScan.offEW = ewStar
      Point.acScan.offINC = incStar
      Point.acScan.offAZI = aziStar
      Point.acScan.TFO = TF
      Point.acScan.TFN = TFN
    }
  }

  calcUpDnLeftRight(Point)
  Point.acScan.TFO *= rad2Deg
  Point.acScan.TFN *= rad2Deg
  Point.acScan.offINC *= rad2Deg
  Point.acScan.offAZI *= rad2Deg
  Point.acScan.deltaInc *= rad2Deg
  Point.acScan.deltaAzi *= rad2Deg
  return Point
}

export function calcUpDnLeftRight(Point) {
  if (Point === undefined || Point === null) return
  if (!Point.hasOwnProperty('acScan')) return

  // Calculate Tool face angle Th is high side vector Tl is lateral vector
  let dTV = Point.acScan.offTVD - Point.tvd
  let dNS = Point.acScan.offNS - Point.ns
  let dEW = Point.acScan.offEW - Point.ew
  let Inc_Star = Point.acScan.offINC
  let Azi_Star = Point.acScan.offAZI

  let tfSign = 1.0
  if (Point.acScan.offINC === 0 && Point.inc > 0) {
    Inc_Star = Point.inc * deg2Rad
    Azi_Star = Point.inc * deg2Rad
    tfSign = -1
  }

  let Dist = Math.sqrt(dTV * dTV + dNS * dNS + dEW * dEW)
  let Th1 = Math.cos(Inc_Star) * Math.cos(Azi_Star)
  let Th2 = Math.cos(Inc_Star) * Math.sin(Azi_Star)
  let Th3 = -Math.sin(Inc_Star)

  let Tl1 = -Math.sin(Azi_Star)
  let Tl2 = Math.cos(Azi_Star)
  let Tl3 = 0.0

  let PMPTL = dTV * Tl3 + dNS * Tl1 + dEW * Tl2
  let PMPTH = dTV * Th3 + dNS * Th1 + dEW * Th2
  let TF = PMPTH === 0.0 ? 0.0 : Math.atan2(PMPTL, PMPTH) * tfSign

  if (Point.inc === 0 && Point.acScan.offINC === 0) {
    TF = Math.atan2(-dEW, -dNS)
    if (dNS === 0) {
      if (dEW > 0) {
        TF = Math.PI / 2
      } else if (dEW < 0) {
        TF = Math.PI + Math.PI / 2
      } else {
        TF = 0
      }
    } else if (dEW === 0) {
      if (dNS > 0) {
        TF = 0
      } else if (dNS < 0) {
        TF = Math.PI
      } else {
        TF = 0
      }
    }
  }

  Point.acScan.LR = Dist * Math.sin(TF) * -1
  Point.acScan.UD = Dist * Math.cos(TF) * -1

  Point.acScan.deltaInc = -(Point.inc - Point.acScan.offINC)
  Point.acScan.deltaAzi = -deltaAziRadians(Point.azi, Point.acScan.offAZI)

  Point.acScan.dist = Math.sqrt(Point.acScan.UD * Point.acScan.UD + Point.acScan.LR * Point.acScan.LR)
}

export function deltaAzi(azi1, azi2) {
  let deltaAzi = azi2 - azi1
  let Sign = deltaAzi >= 0 ? -1 : 1
  if (Math.abs(deltaAzi) > 180) deltaAzi = (360 - Math.abs(deltaAzi)) * Sign

  return deltaAzi
}

export function deltaAziRadians(azi1, azi2) {
  let deltaAzi = azi2 - azi1
  let Sign = deltaAzi >= 0 ? -1 : 1
  if (Math.abs(deltaAzi) > Math.PI) deltaAzi = (Math.PI * 2 - Math.abs(deltaAzi)) * Sign

  return deltaAzi
}

export function leaseLineProximity(svy, leaseLine, isHighSideRef = true) {
  let result = { tfo: 0, tfn: 0, offMd: 0, offTvd: 0, offNs: 0, offEw: 0, RefMd: 0, refEllip: 0, offEllip: 0, ES: 0, SF: 0, C2C: 0, waning: '', offsetName: '', isLeaseLine: true, refCasingOd: 0, offCasingOd: 0 }
  if (!Array.isArray(leaseLine)) return result
  if (leaseLine.length === 0) return result

  if (!svy) return result
  if (!svy.hasOwnProperty('md')) return result

  let deg2rad = Math.PI / 180
  let rad2deg = 180 / Math.PI

  let Th1 = Math.cos(svy.inc * deg2rad) * Math.cos(svy.azi * deg2rad)
  let Th2 = Math.cos(svy.inc * deg2rad) * Math.sin(svy.azi * deg2rad)

  let Tl1 = -Math.sin(svy.azi * deg2rad)
  let Tl2 = Math.cos(svy.azi * deg2rad)

  result.C2C = 999999.0;
  for (let j = 0; j < leaseLine.length; j++) {
    let NS1 = leaseLine[j].localNorth
    let EW1 = leaseLine[j].localEast
    let NS2 = leaseLine[j].localNorth
    let EW2 = leaseLine[j].localEast

    if (j < leaseLine.length - 1) {
      NS2 = leaseLine[j + 1].localNorth
      EW2 = leaseLine[j + 1].localEast
    }

    let dNS = NS2 - NS1
    let dEW = EW2 - EW1
    let t = ((svy.ew - EW1) * dEW + (svy.ns - NS1) * dNS) / ((dEW * dEW) + (dNS * dNS))

    if (t < 0) {
      dEW = svy.ew - EW1
      dNS = svy.ns - NS1
    } else if (t > 1) {
      dEW = svy.ew - EW2
      dNS = svy.ns - NS2
    } else {
      dEW = svy.ew - (EW1 + (t * dEW))
      dNS = svy.ns - (NS1 + (t * dNS))
    }

    let distToLeaseLine = Math.sqrt((dEW * dEW) + (dNS * dNS))    
    if (distToLeaseLine < result.C2C) {
      result.offNs = dNS;
      result.offEw = dEW;
      result.offTvd = svy.tvd;

      let PMPTL = (dNS * Tl1) + (dEW * Tl2)
      let PMPTH = (dNS * Th1) + (dEW * Th2)
      result.tfo = PMPTH === 0.0 ? 0.0 : Math.atan2(PMPTL, PMPTH)

      if (dNS < 0.000001 && dNS > -0.000001) dNS = 0
      if (dEW < 0.000001 && dEW > -0.000001) dEW = 0

      if (dNS !== 0.0) {
        result.tfo = Math.atan2(dEW, dNS)
        if (result.tfo < 0) result.TfnToOffset += Math.PI * 2;
        if (result.TfnToOffset > Math.PI * 2) result.TfnToOffset -= Math.PI * 2
      } else {
        if (dEW > 0) {
          result.tfo = Math.PI * 0.5
        } else if (dEW < 0) {
          result.tfo = 2 * Math.PI * 0.75;
        } else {
          result.tfo = 0
        }
      }

      result.C2C = distToLeaseLine
      result.tfo *= rad2deg
      result.tfo *= rad2deg
    }
  }

  return result
}