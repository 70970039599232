import React from 'react'

import { Avatar, Container, Box } from '@mui/material'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'

import { userRoleSelector } from 'atoms'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { useRecoilValue } from 'recoil'

const UserProfileCard = () => {
  const { user } = useInnovaAuth()
  const userRole = useRecoilValue(userRoleSelector)
  const { getCurrentOrgIcon } = useOrgIcons()

  return (
    <React.Fragment>
      <Container sx={{ '&': { px: '16px' } }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            sx={{ width: '50px', height: '50px', backgroundColor: 'white', marginRight: '10px' }}
            alt='user'
            src={getCurrentOrgIcon()}
          />
          <Box>
            <Box sx={{ font: '1em roboto', fontWeight: 'bold', py: '8px' }}>Logged In</Box>
            <Box sx={{ font: '0.8em roboto', py: '8px' }}>{user?.name}</Box>
            <Box sx={{ font: '0.8em roboto', py: '8px' }}>{userRole?.organization}</Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default UserProfileCard
