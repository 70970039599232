import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import {
  sortColDefs,
  CustomLoadingOverlay,
  isDateLessThan,
  dateComparator,
} from 'components/common/AgGridUtils'
import { cloneDeep } from 'lodash'
import { Box } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ConnectionAnalysisGrid = ({ isLoading, connectionData, avgValues, setFilteredData }) => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()
  const { getAgGridTheme } = useInnovaTheme()

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('connectionAnalysisGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }
  }, [connectionData])

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
    cellStyle: centerAlignCell,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const compareValueCellRenderer = useCallback((props) => {
    let color = props.avgValue >= props.value ? 'red' : 'green'
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box>{props.value ? numberWithCommasDecimals(props.value, 2) : '-'}</Box>
        <Box
          sx={{
            color: color,
            marginLeft: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}>
          {props.avgValue > 0 ? (
            <Iconify
              icon={color === 'red' ? 'mdi:arrow-up-thin' : 'mdi:arrow-down-thin'}
              width='25'
              height='25'
              color={color}
            />
          ) : null}
        </Box>
      </Box>
    )
  }, [])

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  }, [])

  const dateFilterComparator = useCallback(dateComparator, [])

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent' },
      ],
    }
  }, [])

  const columnDefs = useMemo(
    () => [
      {
        headerName: `Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'bitDepth',
        field: 'bitDepth',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
      },
      {
        headerName: `Date/Time`,
        colId: 'timeStamp',
        field: 'timeStamp',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => dateTimeFormatter(params.value),
      },
      {
        headerName: `Shift`,
        colId: 'dayShift',
        field: 'dayShift',
        cellDataType: false,
        valueFormatter: (params) => (params.value ? 'Day' : 'Night'),
      },
      {
        headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'holeSize',
        field: 'holeSize',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 3),
      },
      {
        headerName: `Connection (min)`,
        colId: 'connectionTime',
        field: 'connectionTime',
        valueGetter: (params) => params.data?.connectionTime / 60,
        cellRenderer: compareValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.value,
            avgValue: avgValues?.connTime,
          }
        },
      },
      {
        headerName: `Wt 2 Wt (min)`,
        colId: 'wt2WtTime',
        field: 'wt2WtTime',
        valueGetter: (params) => params.data?.wt2WtTime / 60,
        cellRenderer: compareValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.value,
            avgValue: avgValues?.wt2wt,
          }
        },
      },
      {
        headerName: `Slip 2 Slip (min)`,
        colId: 'slp2SlpTime',
        field: 'slp2SlpTime',
        valueGetter: (params) => params.data?.slp2SlpTime / 60,
        cellRenderer: compareValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.value,
            avgValue: avgValues?.slp2slp,
          }
        },
      },
      {
        headerName: `Slp 2 Btm (min)`,
        colId: 'surveyTime',
        field: 'surveyTime',
        valueGetter: (params) => params.data?.surveyTime / 60,
        cellRenderer: compareValueCellRenderer,
        cellRendererParams: (params) => {
          return {
            value: params.value,
            avgValue: avgValues?.surveyTime,
          }
        },
      },
    ],
    [getUnitsText, dateTimeFormatter, compareValueCellRenderer, avgValues, dateFilterComparator],
  )

  const getConnectionData = (data) => {
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []
    if (!Array.isArray(data[0].connections)) return []
    if (data[0].connections.length === 0) return []
    return cloneDeep(data[0].connections)
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) {
      setFilteredData(filteredNodes)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('connectionAnalysisGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ConnectionAnalysis.xlsx',
            sheetName: 'Connections',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }
  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        loading={isLoading}
        rowData={getConnectionData(connectionData)}
        columnDefs={sortColDefs(columnDefs, 'connectionAnalysisGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        headerHeight={30}
        statusBar={statusBar}
        enableRangeSelection='true'
        onFilterChanged={onFilterChanged}
      />
    </div>
  )
}

export default ConnectionAnalysisGrid
