import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { currentPageAtom, actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { Box, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from 'utils'
import Alert from '@mui/material/Alert'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { escapeHtml, unescapeHtml } from 'utils/htmlSymbolHandling'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep, debounce } from 'lodash'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import { styled } from '@mui/styles'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledPopupMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const ThirdPartyAppCredsPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [appCreds, setAppCreds] = useState([])
  const [appTypes, setAppTypes] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const selectedCreds = useRef(null)
  const isTesting = useRef(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getAppCreds = useInnovaAxios({
    url: '/admin/thirdPartyAppCreds/getCreds',
  })

  const addAppCreds = useInnovaAxios({
    url: '/admin/thirdPartyAppCreds/addCreds',
  })

  const updateCreds = useInnovaAxios({
    url: '/admin/thirdPartyAppCreds/updateCreds',
  })

  const deleteCreds = useInnovaAxios({
    url: '/admin/thirdPartyAppCreds/deleteCreds',
  })

  const getAppTypes = useInnovaAxios({
    url: '/admin/thirdPartyAppCreds/getAppTypes',
  })

  const testOasisConnection = useInnovaAxios({
    url: '/oasis/authToken',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.thirdPartyAppCredsPageKey)
    fetchAppCreds()
    fetchAppTypes()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchAppCreds = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getAppCreds()

    if (response?.error) return
    if (!_isMounted.current) return

    setAppCreds(Array.isArray(response.data) ? response.data : [])
    setLoading(false)
  }

  const fetchAppTypes = async () => {
    const response = await getAppTypes()
    if (response?.error) return
    if (!_isMounted.current) return
    setAppTypes(Array.isArray(response.data) ? response.data : [])
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate(event.data)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const reqFields = useMemo(() => ['userName', 'password', 'application'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('thirdPartyAppCredsGrid', 'colLayout', colLayout)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('thirdPartyAppCredsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ThirdPartyApps.xlsx',
            sheetName: 'Creds',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
      if (typeof inputRow.current[reqFields[i]] !== 'string') return false
      if (inputRow.current[reqFields[i]] === '') return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current.userNameApp = inputRow.current.userName
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addAppCreds(inputRow.current)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current = res.data
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return

    if (isUpdating.current) return

    isUpdating.current = true
    data.userNameApp = data.userName
    data.url = data.data.url
    data.subscriptionKey = data.data.subscriptionKey
    data.refreshToken = data.data.refreshToken
    data.apiVersion = data.data.apiVersion
    data.description = escapeHtml(data.data.description)

    let res = await updateCreds(data)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const handleDelete = useCallback(async () => {
    if (!selectedCreds.current) return
    if (!selectedCreds.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteCreds(selectedCreds.current)
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedCreds.current],
      })
    }

    selectedCreds.current = null
  }, [deleteCreds])

  const onClickDelete = useCallback(
    (data) => {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
      selectedCreds.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete Credentials',
        text: `Are you sure you want to delete credentials for ${data.application}?`,
        onConfirm: handleDelete,
      })
    },
    [userRole, handleDelete],
  )

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleTestOasisConnection = useCallback(async () => {
    if (isTesting.current) return

    isTesting.current = true
    let res = await testOasisConnection()
    isTesting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Success`,
    })
  }, [testOasisConnection])

  const handleMenuClick = useCallback(
    (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      if (action === 'delete') {
        onClickDelete(data)
      }

      if (action === 'testOasis') {
        handleTestOasisConnection()
      }
    },
    [onClickDelete, handleTestOasisConnection],
  )

  let columnDefs = [
    {
      headerName: '',
      colId: 'actions',
      cellStyle: centerAlignCell,
      cellRenderer: PopupCellRenderer,
      cellRendererParams: (params) => {
        let menuItems = [
          {
            label: 'Delete',
            action: 'delete',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledPopupMenuIcon icon='fa-regular:trash-alt' color={'red'} />
              </StyledIconContainer>
            ),
          },
        ]

        if (params.data?.application === 'OASIS') {
          menuItems.push({
            label: 'Test Connection',
            action: 'testOasis',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledPopupMenuIcon icon='solar:server-bold' />
              </StyledIconContainer>
            ),
          })
        }

        return {
          menuItems,
        }
      },
      pinned: 'left',
      lockPosition: 'left',
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      resizable: false,
      filter: null,
      width: 70,
    },
    {
      field: 'organization',
      colId: 'organization',
      headerName: 'Organization',
      valueGetter: (params) => unescapeHtml(params.data?.organization),
      editable: false,
    },
    {
      field: 'description',
      colId: 'description',
      headerName: 'Description',
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return null
        return params?.data?.data?.description
      },
      valueSetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return false
        if (params.oldValue === params.newValue) return false

        let data = cloneDeep(params.data)
        data.data.description = params.newValue
        handleUpdate(data)

        return true
      },
    },
    {
      field: 'application',
      colId: 'application',
      headerName: 'Application',
      valueGetter: (params) => unescapeHtml(params.data?.application),
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
          values: Array.isArray(appTypes) ? appTypes : [],
        }
      },
    },
    {
      field: 'userName',
      colId: 'userName',
      headerName: 'Username',
    },
    {
      field: 'password',
      colId: 'password',
      headerName: 'Password',
      valueFormatter: (params) => {
        if (params.node?.rowPinned !== 'bottom') return '*******'
        if (inputRow.current.password) return inputRow.current.password
        return 'Password...'
      },
    },
    {
      field: 'url',
      colId: 'url',
      headerName: 'URL',
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return null
        return params?.data?.data?.url
      },
      valueSetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return false
        if (params.oldValue === params.newValue) return false

        let data = cloneDeep(params.data)
        data.data.url = params.newValue
        handleUpdate(data)

        return true
      },
    },
    {
      field: 'subscriptionKey',
      colId: 'subscriptionKey',
      headerName: 'Subscription Key',
      editable: (params) => params.node?.rowPinned !== 'bottom' && params.data?.application === 'PELOTON',
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return null
        return params?.data?.data?.subscriptionKey
      },
      valueSetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return false
        if (params.oldValue === params.newValue) return false

        let data = cloneDeep(params.data)
        data.data.subscriptionKey = params.newValue
        handleUpdate(data)

        return true
      },
    },
    {
      field: 'refreshToken',
      colId: 'refreshToken',
      headerName: 'Refresh Token',
      editable: (params) => params.node?.rowPinned !== 'bottom' && params.data?.application === 'PELOTON',
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return null
        return params?.data?.data?.refreshToken
      },
      valueSetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return false
        if (params.oldValue === params.newValue) return false

        let data = cloneDeep(params.data)
        data.data.refreshToken = params.newValue
        handleUpdate(data)

        return true
      },
    },
    {
      field: 'apiVersion',
      colId: 'apiVersion',
      headerName: 'API Version',
      editable: (params) => params.node?.rowPinned !== 'bottom' && params.data?.application === 'PELOTON',
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return null
        return params?.data?.data?.apiVersion
      },
      valueSetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return false
        if (params.oldValue === params.newValue) return false

        let data = cloneDeep(params.data)
        data.data.apiVersion = params.newValue
        handleUpdate(data)

        return true
      },
    },
  ]

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={appCreds}
            columnDefs={sortColDefs(columnDefs, 'thirdPartyAppCredsGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchAppCreds()
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default ThirdPartyAppCredsPage
