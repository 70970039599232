const deg2Rad = Math.PI / 180
export function pointInterpTVD(Surveys, TVD) {
  let Result = {
    md: -1,
    inc: 0,
    azi: 0,
    tvd: 0,
    ns: 0,
    ew: 0,
    vs: 0,
    dls: 0,
    tr: 0,
    br: 0,
  }

  if (!Surveys) return Result
  if (Array.isArray(Surveys) === false) return Result
  if (Surveys.length <= 1) return Result

  let MinTVD = parseFloat(Surveys[0].tvd)
  let MaxTVD = parseFloat(Surveys[Surveys.length - 1].tvd)

  if (TVD > MaxTVD || TVD < MinTVD) return Result

  let Sur1MD = 0
  let Sur1TVD = 0
  let Sur1NS = 0
  let Sur1EW = 0
  let Sur1Inc = 0
  let Sur1Azi = 0
  let SurBR = 0
  let SurTR = 0
  let SurTFO = 0
  let SurDLS = 0

  let Sur2MD = 0
  let Sur2Inc = 0
  let Sur2Azi = 0

  for (let i = 0; i < Surveys.length; i++) {
    if (Surveys[i].tvd >= TVD) {
      if (i === 0) i = 1

      Sur1MD = Surveys[i - 1].md
      Sur1TVD = Surveys[i - 1].tvd
      Sur1NS = Surveys[i - 1].ns
      Sur1EW = Surveys[i - 1].ew
      Sur1Inc = Surveys[i - 1].inc * deg2Rad
      Sur1Azi = Surveys[i - 1].azi * deg2Rad

      Sur2MD = Surveys[i].md
      Sur2Inc = Surveys[i].inc * deg2Rad
      Sur2Azi = Surveys[i].azi * deg2Rad
      SurTFO = Surveys[i].tfo
      SurDLS = Surveys[i].dls
      SurBR = Surveys[i].br
      SurTR = Surveys[i].tr
      break
    }
  }

  let DL = Math.cos(Sur2Inc - Sur1Inc) - Math.sin(Sur1Inc) * Math.sin(Sur2Inc) * (1 - Math.cos(Sur2Azi - Sur1Azi))
  if (DL === 1) {
    DL = 0
  } else {
    DL = Math.PI / 2 - Math.atan(DL / Math.sqrt(1 - DL * DL))
  }

  let intVal = 5
  let CL = Sur2MD - Sur1MD
  if (CL === 0) {
    Result.tvd = Sur1TVD
    Result.ns = Sur1NS
    Result.ew = Sur1EW
    Result.md = Sur1MD
    Result.inc = Sur1Inc * (1 / deg2Rad)
    Result.azi = Sur1Azi * (1 / deg2Rad)
    return Result
  }

  let CL_Star = 0
  let tvdTemp = 999999
  let i = 0
  let DTVD = 0

  do {
    i++
    CL_Star += intVal
    let A_Star = (CL_Star / CL) * DL

    let T11 = Math.sin(Sur1Inc) * Math.cos(Sur1Azi)
    let T12 = Math.sin(Sur1Inc) * Math.sin(Sur1Azi)
    let T13 = Math.cos(Sur1Inc)

    let T21 = Math.sin(Sur2Inc) * Math.cos(Sur2Azi)
    let T22 = Math.sin(Sur2Inc) * Math.sin(Sur2Azi)
    let T23 = Math.cos(Sur2Inc)

    let T_Star_1 = T11
    let T_Star_2 = T12
    let T_Star_3 = T13

    if (Math.abs(DL) > 0) {
      T_Star_1 = (Math.sin(DL - A_Star) / Math.sin(DL)) * T11 + (Math.sin(A_Star) / Math.sin(DL)) * T21
      T_Star_2 = (Math.sin(DL - A_Star) / Math.sin(DL)) * T12 + (Math.sin(A_Star) / Math.sin(DL)) * T22
      T_Star_3 = (Math.sin(DL - A_Star) / Math.sin(DL)) * T13 + (Math.sin(A_Star) / Math.sin(DL)) * T23
    }

    if (Math.abs(T_Star_3) > 0) Result.inc = Math.atan(Math.sqrt(T_Star_1 * T_Star_1 + T_Star_2 * T_Star_2) / T_Star_3)
    if (Result.inc < 0) Result.inc += Math.PI
    if (Math.abs(T_Star_1) > 0) Result.azi = Math.atan2(T_Star_2, T_Star_1)
    if (Result.azi > 2 * Math.PI) Result.azi -= 2 * Math.PI
    if (Result.azi < 0) Result.azi += 2 * Math.PI

    let RF = 1
    if (A_Star >= 0.001) RF = (360 / (A_Star * (180 / Math.PI) * Math.PI)) * Math.tan(A_Star / 2)

    Result.tvd = Sur1TVD + (CL_Star / 2) * ((Math.cos(Sur1Inc) + Math.cos(Result.inc)) * RF)
    Result.ns =
      Sur1NS +
      (CL_Star / 2) * ((Math.sin(Sur1Inc) * Math.cos(Sur1Azi) + Math.sin(Result.inc) * Math.cos(Result.azi)) * RF)
    Result.ew =
      Sur1EW +
      (CL_Star / 2) * ((Math.sin(Sur1Inc) * Math.sin(Sur1Azi) + Math.sin(Result.inc) * Math.sin(Result.azi)) * RF)
    Result.md = Sur1MD + CL_Star
    Result.inc *= 1 / deg2Rad
    Result.azi *= 1 / deg2Rad
    Result.tfo = SurTFO
    Result.dls = SurDLS
    Result.br = SurBR
    Result.tr = SurTR

    DTVD = Math.abs(TVD - Result.tvd)
    if (DTVD > tvdTemp) {
      tvdTemp = 99999
      CL_Star = CL_Star - 2 * intVal
      intVal /= 2
    } else {
      tvdTemp = DTVD
    }
  } while (DTVD > 0.0005 && i < 8000)

  if (i >= 8000) {
    Result.md = -1
    Result.inc = 0
    Result.azi = 0
    Result.tvd = 0
    Result.ns = 0
    Result.ew = 0
    Result.vs = 0
    Result.dls = 0
    Result.tr = 0
    Result.br = 0
  }

  return Result
}

export function pointInterp(Surveys, MD) {
  let Result = {
    md: -1,
    inc: 0,
    azi: 0,
    tvd: 0,
    ns: 0,
    ew: 0,
    vs: 0,
    dls: 0,
    tr: 0,
    br: 0,
  }

  if (!Surveys) return Result
  if (Array.isArray(Surveys) === false) return Result
  if (Surveys.length <= 1) return Result

  let MinMD = parseFloat(Surveys[0].md)
  let MaxMD = parseFloat(Surveys[Surveys.length - 1].md)

  if (MD > MaxMD || MD < MinMD) return Result

  let Sur1MD = 0
  let Sur1TVD = 0
  let Sur1NS = 0
  let Sur1EW = 0
  let Sur1Inc = 0
  let Sur1Azi = 0
  let SurBR = 0
  let SurTR = 0
  let SurTFO = 0
  let SurDLS = 0

  let Sur2MD = 0
  let Sur2Inc = 0
  let Sur2Azi = 0

  for (let i = 0; i < Surveys.length; i++) {
    if (Surveys[i].md >= MD) {
      if (i === 0) i = 1

      Sur1MD = Surveys[i - 1].md
      Sur1TVD = Surveys[i - 1].tvd
      Sur1NS = Surveys[i - 1].ns
      Sur1EW = Surveys[i - 1].ew
      Sur1Inc = Surveys[i - 1].inc * deg2Rad
      Sur1Azi = Surveys[i - 1].azi * deg2Rad

      Sur2MD = Surveys[i].md
      Sur2Inc = Surveys[i].inc * deg2Rad
      Sur2Azi = Surveys[i].azi * deg2Rad
      SurTFO = Surveys[i].tfo
      SurDLS = Surveys[i].dls
      SurBR = Surveys[i].br
      SurTR = Surveys[i].tr
      break
    }
  }

  let DL = Math.cos(Sur2Inc - Sur1Inc) - Math.sin(Sur1Inc) * Math.sin(Sur2Inc) * (1 - Math.cos(Sur2Azi - Sur1Azi))
  if (DL === 1) {
    DL = 0
  } else {
    DL = Math.PI / 2 - Math.atan(DL / Math.sqrt(1 - DL * DL))
  }

  let CL = Sur2MD - Sur1MD
  if (CL === 0) {
    Result.tvd = Sur1TVD
    Result.ns = Sur1NS
    Result.ew = Sur1EW
    Result.md = Sur1MD
    Result.inc = Sur1Inc * (1 / deg2Rad)
    Result.azi = Sur1Azi * (1 / deg2Rad)
    return Result
  }

  let CL_Star = MD - Sur1MD
  let A_Star = (CL_Star / CL) * DL

  let T11 = Math.sin(Sur1Inc) * Math.cos(Sur1Azi)
  let T12 = Math.sin(Sur1Inc) * Math.sin(Sur1Azi)
  let T13 = Math.cos(Sur1Inc)

  let T21 = Math.sin(Sur2Inc) * Math.cos(Sur2Azi)
  let T22 = Math.sin(Sur2Inc) * Math.sin(Sur2Azi)
  let T23 = Math.cos(Sur2Inc)

  let T_Star_1 = T11
  let T_Star_2 = T12
  let T_Star_3 = T13

  if (Math.abs(DL) > 0) {
    T_Star_1 = (Math.sin(DL - A_Star) / Math.sin(DL)) * T11 + (Math.sin(A_Star) / Math.sin(DL)) * T21
    T_Star_2 = (Math.sin(DL - A_Star) / Math.sin(DL)) * T12 + (Math.sin(A_Star) / Math.sin(DL)) * T22
    T_Star_3 = (Math.sin(DL - A_Star) / Math.sin(DL)) * T13 + (Math.sin(A_Star) / Math.sin(DL)) * T23
  }

  if (Math.abs(T_Star_3) > 0) Result.inc = Math.atan(Math.sqrt(T_Star_1 * T_Star_1 + T_Star_2 * T_Star_2) / T_Star_3)
  if (Result.inc < 0) Result.inc += Math.PI
  if (Math.abs(T_Star_1) > 0) Result.azi = Math.atan2(T_Star_2, T_Star_1)
  if (Result.azi > 2 * Math.PI) Result.azi -= 2 * Math.PI
  if (Result.azi < 0) Result.azi += 2 * Math.PI

  let RF = 1
  if (A_Star >= 0.001) RF = (360 / (A_Star * (180 / Math.PI) * Math.PI)) * Math.tan(A_Star / 2)

  Result.tvd = Sur1TVD + (CL_Star / 2) * ((Math.cos(Sur1Inc) + Math.cos(Result.inc)) * RF)
  Result.ns =
    Sur1NS +
    (CL_Star / 2) * ((Math.sin(Sur1Inc) * Math.cos(Sur1Azi) + Math.sin(Result.inc) * Math.cos(Result.azi)) * RF)
  Result.ew =
    Sur1EW +
    (CL_Star / 2) * ((Math.sin(Sur1Inc) * Math.sin(Sur1Azi) + Math.sin(Result.inc) * Math.sin(Result.azi)) * RF)
  Result.md = MD
  Result.inc *= 1 / deg2Rad
  Result.azi *= 1 / deg2Rad
  Result.tfo = SurTFO
  Result.dls = SurDLS
  Result.br = SurBR
  Result.tr = SurTR
  return Result
}

export function calcVS(vsAzi, NS, EW, vsNs, vsEw) {
  vsAzi *= Math.PI / 180

  let horzDist = Math.sqrt(NS * NS + EW * EW)
  if (NS === 0.0) NS = 0.000000001

  let vsOrg = 0
  let cosVal = 0
  if (NS === 0.0) {
    vsOrg = Math.sqrt(vsNs * vsNs + vsEw * vsEw) * Math.cos(Math.abs(vsAzi + Math.PI))
    cosVal = Math.abs(vsAzi + Math.PI)
  } else {
    vsOrg = Math.sqrt(vsNs * vsNs + vsEw * vsEw) * Math.cos(Math.abs(vsAzi - Math.atan2(vsEw, vsNs) + Math.PI))
    cosVal = Math.abs(vsAzi - Math.atan2(-EW, -NS) + Math.PI)
  }

  return vsOrg + horzDist * Math.cos(cosVal)
}
