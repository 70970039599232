import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { Icon as Iconify } from '@iconify/react'
import { cloneDeep } from 'lodash'
import { uuidv4 } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const blankSettings = [
  { uid: 999, organization: '', password: '', unitPrefs: '', roleId: -1, passwordResetRequired: false },
]

const MultiUserImportModal = ({ onCreateMultiUsers, onCancel, existingUserNames, orgList, roleList, unitList }) => {
  const _isMounted = useRef(false)
  const gridSettingsRef = useRef()
  const gridSettingsApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const getFirstRoleForOrg = (organization) => {
    if (!organization) return -1
    if (typeof organization !== 'string') return -1

    if (!Array.isArray(roleList)) return -1
    let role = roleList.find((r) => r.organization === organization)
    if (!role) return -1
    return role.id
  }

  const getFirstUnitSetForOrg = (organization) => {
    if (!organization) return -1
    if (typeof organization !== 'string') return -1

    if (!Array.isArray(unitList)) return -1
    let units = unitList.find((u) => u.organization === organization)
    if (!units) return -1
    return units.label
  }

  const getAuthTypeForOrg = (organization) => {
    if (!organization) return 'Innova'
    if (typeof organization !== 'string') return 'Innova'

    if (!Array.isArray(orgList)) return 'Innova'
    let org = orgList.find((o) => o.organization === organization)
    if (!org) return 'Innova'
    return org.authType
  }

  const isMfaEnabledForOrg = (organization) => {
    if (!organization) return false
    if (typeof organization !== 'string') return false

    if (!Array.isArray(orgList)) return false
    let org = orgList.find((o) => o.organization === organization)
    if (!org) return false
    return org.mfaEnabled
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const defaultColDefSettingsGrid = useMemo(() => {
    return {
      resizable: true,
      editable: true,
      autoHeight: true,
      sortable: false,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleDelete = (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return

    gridApi.current.applyTransaction({
      remove: [data],
    })
  }

  function isPinnedRowDataCompleted() {
    let requiredFields = ['userName']
    for (let i = 0; i < requiredFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredFields[i])) return false
    }

    return true
  }

  const handleAddRow = async () => {
    if (!isPinnedRowDataCompleted()) return

    inputRow.current.uid = uuidv4()
    gridApi.current.applyTransaction({
      add: [inputRow.current],
    })

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '25px' }}>
              {params.node.rowIndex + 1}
            </Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const getImportUserNames = () => {
    if (!gridApi.current) return []
    let importUserNames = []
    gridApi.current.forEachNode((node) => {
      if (node.data?.hasOwnProperty('userName')) {
        importUserNames.push(node.data)
      }
    })

    return importUserNames
  }

  const doesUserNameExist = useCallback(
    (userName) => {
      if (!userName) return true
      if (typeof userName !== 'string') return true
      if (!Array.isArray(existingUserNames)) return true

      if (existingUserNames.findIndex((user) => user === userName) >= 0) return true
      let importUserNames = getImportUserNames()

      let userNameCount = 0
      for (let i = 0; i < importUserNames.length; i++) {
        if (importUserNames[i].userName.toLowerCase() === userName.toLowerCase()) {
          userNameCount++
        }
      }

      if (userNameCount > 1) return true
      return false
    },
    [existingUserNames],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'validation',
        colId: 'validation',
        headerName: 'Validation',
        editable: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: 'red',
        },
        valueGetter: (params) => {
          if (params?.node?.rowPinned === 'bottom') return ''

          if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(params?.data.userName)) {
            return 'Not a valid email'
          }

          if (doesUserNameExist(params?.data?.userName)) {
            return 'User already exists'
          }
        },
      },
    ],
    [doesUserNameExist], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const onSettingsGridReady = (params) => {
    gridSettingsApi.current = params.api
  }

  const onFirstDataRenderedSettings = (params) => {
    autoSizeColumnsSettings()
  }

  const autoSizeColumnsSettings = () => {
    if (gridSettingsApi.current === null) return
    gridSettingsApi.current?.autoSizeAllColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'userName') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('uid')) return

    gridApi.current.applyTransaction({
      update: [data],
    })

    autoSizeColumns()
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate(event.data)
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
  }

  const processDataFromClipboard = useCallback((params) => {
    const data = cloneDeep(params.data)
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1

    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()
    const focusedIndex = focusedCell.rowIndex
    if (focusedIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = focusedIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex
      const rowsToAdd = []

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        const rowObject = { uid: uuidv4(), userName: '' }
        let currentColumn = focusedCell.column

        row.forEach((item) => {
          if (!currentColumn) {
            return
          }

          rowObject[currentColumn.colDef.field] = item
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        })
        rowsToAdd.push(rowObject)
      }

      gridApi.current.applyTransaction({ add: rowsToAdd.reverse() })
    }

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return data
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const getRowIdSettings = useMemo(() => {
    return (params) => {
      return params.data?.uid
    }
  }, [])

  function onPasteEnd(params) {
    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const handleUpdateSettingsGrid = async (data) => {
    if (!data) return

    gridSettingsRef.current.api.applyTransaction({
      update: [data],
    })

    autoSizeColumnsSettings()
  }

  const isPasswordValid = (password) => {
    if (!password) return false
    if (typeof password !== 'string') return false

    if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/.test(password)) {
      return false
    }

    return true
  }

  const isUserNameValid = (userName) => {
    if (!userName) return false
    if (typeof userName !== 'string') return false

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(userName)) {
      return false
    }

    if (doesUserNameExist(userName)) {
      return false
    }

    return true
  }

  let columnDefsSettingsGrid = [
    {
      field: 'organization',
      colId: 'organization',
      headerName: 'Organization',
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
      cellEditor: 'agRichSelectCellEditor',
      cellEditorPopup: true,
      cellEditorParams: (params) => {
        let values = Array.isArray(orgList) ? orgList : []
        return {
          values: values.map((org) => org.organization),
        }
      },
      valueSetter: (params) => {
        if (params.newValue === params.oldValue) return

        let newData = cloneDeep(params.data)
        newData.organization = params.newValue
        newData.roleId = getFirstRoleForOrg(params.newValue)
        newData.unitPrefs = getFirstUnitSetForOrg(params.newValue)
        newData.authType = getAuthTypeForOrg(params.newValue)
        newData.mfaEnabled = isMfaEnabledForOrg(params.newValue)
        if (newData.authType !== 'Innova') {
          newData.apiEnabled = false
        }
        handleUpdateSettingsGrid(newData)
        return
      },
    },
    {
      field: 'password',
      colId: 'password',
      headerName: 'Password',
      cellStyle: centerAlignCell,
      editable: (params) => {
        if (params.data?.authType !== 'Innova') return false
        return true
      },
      valueFormatter: (params) => {
        if (params.data?.authType !== 'Innova') return ''
        if (params?.data.password) return params?.data.password
      },
      valueSetter: (params) => {
        if (typeof params.newValue !== 'string') return
        if (!isPasswordValid(params.newValue)) {
          setStatus({
            show: true,
            severity: 'error',
            message:
              'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
          })
          return false
        }

        let data = cloneDeep(params.data)
        data.password = params.newValue

        handleUpdateSettingsGrid(data)
      },
    },
    {
      field: 'roleId',
      colId: 'roleId',
      headerName: 'Role',
      minWidth: 200,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
      valueGetter: (params) => {
        if (!params.data) return ''
        if (typeof params.data?.roleId !== 'number') return ''
        if (!Array.isArray(roleList)) return ''
        let role = roleList.find((r) => r.id === params.data?.roleId)
        if (!role) return ''
        return role.name
      },
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        let values = []
        if (Array.isArray(roleList)) {
          roleList.forEach((role) => {
            if (role.organization === params.data?.organization) values.push(role.name)
          })
        }

        return {
          values: values,
        }
      },
      valueSetter: (params) => {
        let roleId = -1
        if (params.newValue && typeof params.newValue === 'string') {
          if (Array.isArray(roleList)) {
            for (let i = 0; i < roleList.length; i++) {
              if (roleList[i].organization === params.data?.organization && roleList[i].name === params.newValue) {
                roleId = roleList[i].id
                break
              }
            }
          }
        }

        if (roleId < 0) return

        let newData = cloneDeep(params.data)
        newData.roleId = roleId
        handleUpdateSettingsGrid(newData)
      },
    },
    {
      field: 'unitPrefs',
      colId: 'unitPrefs',
      headerName: 'Units',
      cellStyle: centerAlignCell,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        let values = []
        if (Array.isArray(unitList)) {
          unitList.forEach((unit) => {
            if (unit.organization === params.data?.organization) values.push(unit.label)
          })
        }

        return {
          values: values,
        }
      },
    },
    {
      field: 'passwordResetRequired',
      colId: 'passwordResetRequired',
      headerName: 'Pwd Reset Req',
      cellStyle: centerAlignCell,
    },
  ]

  const handleAddUsers = () => {
    let importUserNames = getImportUserNames()

    if (!gridSettingsApi.current) return []

    let settings = {}
    gridSettingsApi.current.forEachNode((node) => {
      settings = cloneDeep(node.data)
    })

    if (!isPasswordValid(settings.password) && settings.authType === 'Innova') {
      setStatus({
        show: true,
        severity: 'error',
        message:
          'Password invalid, must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      })
      return
    }

    if (settings.organization === '' || !settings.organization) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Invalid Organization',
      })
      return
    }

    if (settings.unitPrefs === '' || !settings.unitPrefs) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Invalid units',
      })
      return
    }

    if (settings.roleId === -1) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Invalid role',
      })
      return
    }

    let userNamesToAdd = []
    for (let i = 0; i < importUserNames.length; i++) {
      if (isUserNameValid(importUserNames[i].userName)) {
        userNamesToAdd.push({
          userName: importUserNames[i].userName,
          email: importUserNames[i].userName,
          organization: settings.organization,
          roleId: settings.roleId,
          authType: settings.authType,
          unitPrefs: settings.unitPrefs,
          mfaEnabled: settings.mfaEnabled,
          apiEnabled: false,
          passwordResetRequired: settings.passwordResetRequired,
          active: true,
          lastLogin: '',
          createdAt: new Date(Date.now()).toISOString(),
          password: settings.password,
        })
      }
    }

    if (onCreateMultiUsers) onCreateMultiUsers(userNamesToAdd)
  }

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      maxWidth='xl'
      PaperProps={{
        sx: {
          width: '40vw',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>Multi User Import</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box className={getAgGridTheme()} style={{ maxWidth: '100%', height: '75%' }}>
          <AgGridReact
            rowData={[]}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowSelection='multiple'
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            gridOptions={gridOptions}
            headerHeight={30}
            processDataFromClipboard={processDataFromClipboard}
            getRowId={getRowId}
            onPasteEnd={onPasteEnd}
          />
        </Box>
        <Box className={getAgGridTheme()} style={{ maxWidth: '100%', height: '25%' }}>
          <AgGridReact
            ref={gridSettingsRef}
            rowData={blankSettings}
            columnDefs={columnDefsSettingsGrid}
            defaultColDef={defaultColDefSettingsGrid}
            animateRows={true}
            onGridReady={onSettingsGridReady}
            onFirstDataRendered={onFirstDataRenderedSettings}
            headerHeight={30}
            getRowId={getRowIdSettings}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ margin: '5px' }}
          variant='contained'
          type='button'
          color='primary'
          onClick={() => handleAddUsers()}>
          Add Users
        </Button>
        <Button sx={{ margin: '5px' }} variant='contained' type='button' color='primary' onClick={() => onCancel()}>
          Cancel
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  )
}

export default MultiUserImportModal
