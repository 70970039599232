import { useEffect, useRef, useState } from 'react'

import useInnovaAxios from './useInnovaAxios'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import { cloneDeep } from 'lodash'

function usePersonnel() {
  const _isMounted = useRef(true)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const [isLoading, setIsLoading] = useState(false)
  const personnelData = useRef(null)
  const isUpdating = useRef(false)

  const getDailyReport = useInnovaAxios({
    url: '/well/dailyReports/getReportHeaders',
  })

  const updatePersonnel = useInnovaAxios({
    url: '/well/dailyReports/updatePersonnel',
  })

  useEffect(() => {
    if (!currentWell || !_isMounted.current) return
    currentWellRef.current = currentWell
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const getPersonnelTags = (position, getNight, showAll) => {
    if (!position) return []
    if (!position.hasOwnProperty('tag')) return []
    if (typeof position.tag !== 'string') return []
    if (!showAll && !position.alwaysDisp) return []

    const { tag, hasNight } = position

    let prefix = ''
    if (hasNight) prefix = getNight ? 'night' : 'day'

    return [
      { inputTag: `${prefix}${tag}`, outputTag: 'name' },
      { inputTag: `${prefix}${tag}Timeon`, outputTag: 'timeOn' },
      { inputTag: `${prefix}${tag}Timeoff`, outputTag: 'timeOff' },
      { inputTag: `${prefix}${tag}Relief`, outputTag: 'relief' },
      { inputTag: `${prefix}${tag}ReliefTimeon`, outputTag: 'reliefTimeOn' },
      { inputTag: `${prefix}${tag}ReliefTimeoff`, outputTag: 'reliefTimeOff' },
    ]
  }

  const addNewPosition = (outputNames, personData, position, getNight, showAll, dailyReportId) => {
    let tags = getPersonnelTags(position, getNight, showAll)
    if (tags.length === 0) return

    let prefix = ''
    if (position.hasNight) prefix = getNight ? 'Night' : 'Day'

    let newPosition = {
      position: `${prefix} ${position.label}`,
      dropDownFilter: position.dropDownFilter,
      uid: outputNames.length + 1,
      tag: `${prefix.toLowerCase()}${position.tag}`,
      dailyReportId: dailyReportId,
    }

    for (let j = 0; j < tags.length; j++) {
      if (!personData.hasOwnProperty(tags[j].inputTag)) continue
      newPosition[tags[j].outputTag] = personData[tags[j].inputTag]
    }

    outputNames.push(newPosition)
  }

  const handlePersonnelData = (personnelData, showAll, dailyReportId) => {
    if (!personnelData) return []

    let positions = [
      { tag: 'CoMan', hasNight: true, label: 'Co. Man', alwaysDisp: true, dropDownFilter: 'COMPANY MAN' },
      { tag: 'Dd', hasNight: true, label: 'DD', alwaysDisp: true, dropDownFilter: 'DD' },
      { tag: 'Mwd', hasNight: true, label: 'MWD', alwaysDisp: true, dropDownFilter: 'MWD' },
      { tag: 'Pusher', hasNight: true, label: 'Tool Pusher', alwaysDisp: true, dropDownFilter: 'TOOLPUSHER' },
      {
        tag: 'drillingEng',
        hasNight: false,
        label: 'Drilling Eng.',
        alwaysDisp: false,
        dropDownFilter: 'DRILLING ENGINEER',
      },
      { tag: 'traniee1', hasNight: false, label: 'Trainee 1', alwaysDisp: false, dropDownFilter: '' },
      { tag: 'traniee2', hasNight: false, label: 'Trainee 2', alwaysDisp: false, dropDownFilter: '' },
      { tag: 'thirdman', hasNight: false, label: 'Third Man', alwaysDisp: false, dropDownFilter: '' },
      { tag: 'fourthman', hasNight: false, label: 'Fourth Man', alwaysDisp: false, dropDownFilter: '' },
      { tag: 'geologist', hasNight: false, label: 'Geologist', alwaysDisp: false, dropDownFilter: 'GEOLOGIST' },
      { tag: 'mudEng', hasNight: false, label: 'Mud Engineer', alwaysDisp: false, dropDownFilter: 'MUD ENGINEER' },
    ]

    let outputNames = []
    for (let i = 0; i < positions.length; i++) {
      addNewPosition(outputNames, personnelData, positions[i], false, showAll, dailyReportId)
      if (positions[i].hasNight) addNewPosition(outputNames, personnelData, positions[i], true, showAll, dailyReportId)
    }

    return outputNames
  }
  const fetchPersonnelData = async (dailyReportId, showAll) => {
    if (dailyReportId === null || dailyReportId === undefined) return []
    if (typeof dailyReportId !== 'number') return []
    if (typeof currentWellRef.current !== 'string') return []
    if (currentWellRef.current === '') return []
    if (isLoading) return []

    if (_isMounted.current) setIsLoading(true)
    const response = await getDailyReport({ dailyReportId: dailyReportId, wellName: currentWellRef.current })
    if (_isMounted.current) setIsLoading(false)

    if (!response?.data) return []
    if (!response.data.hasOwnProperty('dailyReports')) return []
    if (response.data?.dailyReports?.length === 0) return []

    personnelData.current = response.data.dailyReports[0]?.personnelData
    return handlePersonnelData(personnelData.current, showAll, dailyReportId)
  }

  const handleUpdatePersonnel = async (newData) => {
    if (typeof currentWellRef.current !== 'string') return { isError: true, message: 'no current well' }
    if (currentWellRef.current === '') return { isError: true, message: 'no current well' }
    if (isUpdating.current) return { isError: true, message: 'update in progress' }
    if (!newData) return { isError: true, message: 'no data' }
    if (!newData.hasOwnProperty('tag')) return { isError: true, message: 'no tag' }
    if (!personnelData.current) return { isError: true, message: 'no personnel data' }

    personnelData.current[newData.tag] = newData.name
    personnelData.current[newData.tag + 'Timeon'] = newData.timeOn
    personnelData.current[newData.tag + 'Timeoff'] = newData.timeOff
    personnelData.current[newData.tag + 'Relief'] = newData.relief
    personnelData.current[newData.tag + 'ReliefTimeon'] = newData.reliefTimeOn
    personnelData.current[newData.tag + 'ReliefTimeoff'] = newData.reliefTimeOff

    let newPersonnelData = cloneDeep(personnelData.current)

    isUpdating.current = true
    const response = await updatePersonnel({
      dailyReportId: newData.dailyReportId,
      wellName: currentWellRef.current,
      ...newPersonnelData,
    })
    isUpdating.current = false

    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  return {
    fetchPersonnelData,
    handleUpdatePersonnel,
    isLoading,
  }
}

export default usePersonnel
