import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { appColors, array2pipestr } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import AnalyticsBarChart from './AnalyticsBarChart'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CostPerOperatorChart = () => {
  const _isMounted = useRef(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const { getUnitsText } = useUnits()
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { theme } = useInnovaTheme()

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  const chartOptions = {
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.costPerOperatorKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [
        {
          backgroundColor: appColors.rotateColor,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!data || !data.costByWell) return output
    data.costByWell.forEach((well) => {
      if (output.labels.findIndex((element) => element === well.operator) < 0) {
        output.labels.push(well.operator)
        output.datasets[0].data.push(0)
      }
    })
    data.costByWell.forEach((well) => {
      if (typeof well.wellCost === 'number') {
        let index = output.labels.findIndex((element) => element === well.operator)
        if (index >= 0) {
          output.datasets[0].data[index] += well.wellCost
        }
      }
    })

    return output
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      costByWell: 'true',
    }

    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }

    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      setIsLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet([['Operator', 'Total Cost']], {
      origin: 'A1',
    })

    if (data && data.costByWell) {
      let exportData = {
        operators: [],
        totalCost: [],
      }

      data.costByWell.forEach((well) => {
        if (exportData.operators.findIndex((element) => element === well.operator) < 0) {
          exportData.operators.push(well.operator)
          exportData.totalCost.push(0)
        }
      })

      data.costByWell.forEach((well) => {
        if (typeof well.wellCost === 'number') {
          let index = exportData.operators.findIndex((element) => element === well.operator)
          if (index >= 0) {
            exportData.totalCost[index] += well.wellCost
          }
        }
      })

      let costPerOperatorExport = []
      if (exportData.operators.length > 0) {
        for (let i = 0; i < exportData.operators.length; i++) {
          let opRow = []
          opRow.push(exportData.operators[i])
          opRow.push(exportData.totalCost[i])
          costPerOperatorExport.push(opRow)
        }
      }

      XLSX.utils.sheet_add_aoa(ws, costPerOperatorExport, { origin: `A2` })
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Operator Cost')
    XLSX.writeFile(wb, 'Cost by Operator.xlsx')
  }

  return (
    <AnalyticsBarChart
      wellData={createChartData(data)}
      units={getUnitsText(UNITS_FOR.Depth)}
      title={'Cost Per Operator'}
      xAxisTitle={'Operator'}
      yAxisTitle={`Cost (${getUnitsText(UNITS_FOR.Cost)})`}
      chartOptions={chartOptions}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default CostPerOperatorChart
