import React from 'react'

import { Bar } from 'react-chartjs-2'
import { Box, CircularProgress } from '@mui/material'
import { appColors, chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import DonutChartJs from 'components/common/DonutChartJs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}

const CrmSystemCharts = ({ data, isLoading }) => {
  const { theme, getChartBackColor } = useInnovaTheme()
  
  const getInteractionTypesData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let interactionData = []
    for (let i = 0; i < data.length; i++) {
      let index = -1
      for (let j = 0; j < interactionData.length; j++) {
        if (interactionData[j].label === data[i].interactionType) {
          interactionData[j].value++
          index = j
          break
        }
      }

      if (index < 0) {
        interactionData.push({
          value: 1,
          color:
            interactionData.length < chartSeriesColors.length
              ? chartSeriesColors[interactionData.length]
              : getRandomColor(),
          label: data[i].interactionType,
        })
      }
    }

    return interactionData
  }

  const getCurrentMonthData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let dateTo = new Date(Date.now())
    let dateFrom = new Date(
      Date.parse(
        `${dateTo.getFullYear()}-${dateTo.getMonth() + 1 < 10 ? '0' : ''}${dateTo.getMonth() + 1}-01T00:00:00Z`,
      ),
    )
    dateFrom.setUTCDate(1)

    let interactionData = []
    for (let i = 0; i < data.length; i++) {
      let dt = new Date(Date.parse(data[i].dateTime)).getTime()
      if (dt < dateFrom.getTime() || dt > dateTo.getTime()) continue

      let index = -1
      for (let j = 0; j < interactionData.length; j++) {
        if (interactionData[j].label === getUserNameFromEmail(data[i].createdBy)) {
          interactionData[j].value++
          index = j
          break
        }
      }

      if (index < 0) {
        interactionData.push({
          value: 1,
          color:
            interactionData.length < chartSeriesColors.length
              ? chartSeriesColors[interactionData.length]
              : getRandomColor(),
          label: getUserNameFromEmail(data[i].createdBy),
        })
      }
    }

    return interactionData
  }

  const getCurrentYearData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00Z`))

    let interactionData = []
    for (let i = 0; i < data.length; i++) {
      let dateTime = data[i].dateTime
      if (typeof dateTime === 'string') {
        if (dateTime.indexOf('T') < 0) {
          dateTime += 'T00:01:00'
        }
      }

      let dt = new Date(Date.parse(dateTime)).getTime()
      if (dt < dateFrom.getTime() || dt > dateTo.getTime()) {
        continue
      }

      let index = -1
      for (let j = 0; j < interactionData.length; j++) {
        if (interactionData[j].label === getUserNameFromEmail(data[i].createdBy)) {
          interactionData[j].value++
          index = j
          break
        }
      }

      if (index < 0) {
        interactionData.push({
          value: 1,
          color:
            interactionData.length < chartSeriesColors.length
              ? chartSeriesColors[interactionData.length]
              : getRandomColor(),
          label: getUserNameFromEmail(data[i].createdBy),
        })
      }
    }

    return interactionData
  }

  function getInteractionChartData() {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!data) return output
    if (!Array.isArray(data)) return output
    if (data.length === 0) return output

    for (let i = 0; i < data.length; i++) {
      let index = -1
      for (let j = 0; j < output.datasets.length; j++) {
        if (output.datasets[j].label === getUserNameFromEmail(data[i].createdBy)) {
          index = j
          break
        }
      }

      if (index < 0) {
        output.datasets.push({
          label: getUserNameFromEmail(data[i].createdBy),
          backgroundColor:
            output.datasets.length < chartSeriesColors.length
              ? chartSeriesColors[output.datasets.length]
              : getRandomColor(),
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        })
      }
    }

    for (let i = 0; i < data.length; i++) {
      let dt = new Date(Date.parse(data[i].dateTime))
      let dateStr = `${dt.getFullYear()}-${dt.getMonth() + 1 < 10 ? '0' : ''}${dt.getMonth() + 1}-01T00:01:00`
      let index = output.labels.findIndex((label) => label === dateStr)
      if (index < 0) output.labels.push(dateStr)
    }

    output.labels.sort((firstEl, secondEl) =>
      new Date(Date.parse(firstEl)).getTime() > new Date(Date.parse(secondEl)).getTime() ? 1 : -1,
    )

    for (let i = 0; i < output.datasets.length; i++) {
      for (let j = 0; j < output.labels.length; j++) {
        output.datasets[i].data.push(0)
      }
    }

    for (let i = 0; i < data.length; i++) {
      let dateTime = data[i].dateTime
      if (typeof dateTime === 'string') {
        if (dateTime.indexOf('T') < 0) {
          dateTime += 'T00:01:00'
        }
      }
      let dt = new Date(Date.parse(dateTime))

      let pointIndex = output.labels.findIndex((dateStr) => {
        let seriesDate = new Date(Date.parse(dateStr))
        if (seriesDate.getFullYear() === dt.getFullYear() && seriesDate.getMonth() === dt.getMonth()) {
          return true
        }

        return false
      })

      let seriesIndex = output.datasets.findIndex(
        (dataset) => dataset.label === getUserNameFromEmail(data[i].createdBy),
      )
      if (seriesIndex < 0) seriesIndex = 0
      if (seriesIndex >= 0 && pointIndex >= 0) {
        output.datasets[seriesIndex].data[pointIndex] += 1
      }
    }

    for (let i = 0; i < output.labels.length; i++) {
      let dt = new Date(Date.parse(output.labels[i]))
      output.labels[i] = dt.toLocaleDateString('default', { year: 'numeric', month: 'long' })
    }

    return output
  }

  return (
    <Box
      sx={{
        ...boxStyle,
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          flex: 6,
          backgroundColor: getChartBackColor(),
          display: 'flex',
          width: '99.9%',
          height: '100%',
          overflow: 'hidden',
        }}>
        <Bar
          options={{
            layout: {
              padding: {
                right: 20,
                left: 20,
              },
            },
            plugins: {
              GradientPlugIn: {
                showGradient: true,
                theme: theme,
              },
              title: {
                display: true,
                text: `Interactions`,
                color: appColors.itemTextColor,
                font: {
                  size: 20,
                },
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true,
                ticks: {
                  color: appColors.headerTextColor,
                },
              },
              y: {
                stacked: true,
                ticks: {
                  color: appColors.headerTextColor,
                },
                grid: {
                  color: 'gray',
                },
              },
            },
          }}
          data={getInteractionChartData()}
        />
        {isLoading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          ...boxStyle,
          flexDirection: 'column',
          flex: 1,
        }}>
        <Box sx={{ ...boxStyle }}>
          <DonutChartJs
            title={'Current Month'}
            data={getCurrentMonthData()}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
        <Box sx={{ ...boxStyle }}>
          <DonutChartJs
            title={'Current Year'}
            data={getCurrentYearData()}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
        <Box sx={{ ...boxStyle }}>
          <DonutChartJs
            title={'Interaction Types'}
            data={getInteractionTypesData()}
            legendLocation={'right'}
            showLegend={false}
            showTitle={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default CrmSystemCharts
