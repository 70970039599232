import React, { useRef, useEffect, useState } from 'react'
import 'components/common/SplitPane/splitPaneResizer.css'
import { Box, Tooltip } from '@mui/material'
import BidAddressGrid from './BidAddressGrid'
import BidNotesGrid from './BidNotesGrid'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import { bidAddressesLayoutAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import cloneDeep from 'lodash/cloneDeep'
import { isEqual } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import BidCategoryGrid from './BidCategoryGrid'
import { uuidv4 } from 'utils/stringFunctions'

const BidDetailsPane = ({ selectedBid, updateBid, addresses, setStatus }) => {
  const _isMounted = useRef(false)
  const [pageLayout, setPageLayout] = useRecoilState(bidAddressesLayoutAtom)
  const [dataChanged, setDataChanged] = useState(false)
  const orgDataRef = useRef(cloneDeep(selectedBid))
  const bidDataRef = useRef(cloneDeep(selectedBid))
  const [bidData, setBidData] = useState(cloneDeep(selectedBid))
  const [isUpdating, setUpdating] = useState(false)
  const isUpdatingRef = useRef(false)
  const addressGridTypes = ['Customer', 'Supplier']

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    bidDataRef.current = cloneDeep(selectedBid)

    if (Array.isArray(bidDataRef.current?.bidDetails)) {
      for (let i = 0; i < bidDataRef.current.bidDetails.length; i++) {
        bidDataRef.current.bidDetails[i].uid = uuidv4()
        if (!Array.isArray(bidDataRef.current.bidDetails[i].details)) continue

        for (let j = 0; j < bidDataRef.current.bidDetails[i].details.length; j++) {
          bidDataRef.current.bidDetails[i].details[j].uid = uuidv4()
        }
      }
    }

    orgDataRef.current = cloneDeep(bidDataRef.current)
    setBidData(cloneDeep(bidDataRef.current))
  }, [selectedBid]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateBidData = (data) => {
    if (!data) return
    let newData = cloneDeep(data)
    setDataChanged(!isEqual(newData, orgDataRef.current))
    bidDataRef.current = cloneDeep(newData)
    setBidData(bidDataRef.current)
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '50%' : '50%'
    if (index >= pageLayout.length) return index === 0 ? '50%' : '50%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!bidData) return //This line is just so we done need an es lint comment for unused variables
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    let res = await updateBid(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setUpdating(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => {
          handleUpdate(bidDataRef.current)
        }}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          bidDataRef.current = cloneDeep(orgDataRef.current)
          setBidData(cloneDeep(orgDataRef.current))
          setDataChanged(false)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const isBidApproved = () => {
    if (!selectedBid) return false
    if (!selectedBid.hasOwnProperty('approvedBy')) return false
    if (typeof selectedBid.approvedBy !== 'string') return false
    return selectedBid.approvedBy !== ''
  }

  return (
    <React.Fragment>
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(0)}
        allowResize={true}
        defaultSize={getInitialPaneSize(0)}
        onDragFinished={(params) => onDragFinished(params, 0)}>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(1)}
          allowResize={true}
          defaultSize={getInitialPaneSize(1)}
          onDragFinished={(params) => onDragFinished(params, 1)}>
          <SplitPane
            split='vertical'
            onDragFinished={(params) => onDragFinished(params, 2)}
            defaultSize={getInitialPaneSize(2)}
            size={getInitialPaneSize(2)}
            allowResize={true}
            style={{
              height: 'calc(100%)',
              width: `calc(100%)`,
              maxWidth: `calc(100%)`,
            }}>
            {addressGridTypes.map((type) => (
              <BidAddressGrid
                key={type}
                bidData={bidDataRef}
                addressType={type}
                handleUpdate={updateBidData}
                addressesList={addresses}
                editable={!isBidApproved()}
              />
            ))}
          </SplitPane>
          <BidNotesGrid bidData={bidDataRef} handleUpdate={updateBidData} editable={!isBidApproved()} />
        </SplitPane>
        <BidCategoryGrid bidData={bidDataRef} handleUpdate={updateBidData} editable={!isBidApproved()} />
      </SplitPane>
    </React.Fragment>
  )
}

export default BidDetailsPane
