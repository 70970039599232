import React, { useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, IconButton } from '@mui/material'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals, uuidv4 } from 'utils/stringFunctions'
import { Icon as Iconify } from '@iconify/react'
import { cloneDeep } from 'lodash'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CentralizerRestrictionEditor = (props) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const { getUnitsText } = useUnits()
  const inputRow = useRef({})

  function getValue() {
    let gridData = []
    gridApi.current.forEachNode((node) => {
      gridData.push(node.data)
    })

    gridData.sort((a, b) => {
      return a.standoffGap - b.standoffGap
    })

    let uniqueGridData = []
    let prevStandoffGap = null
    gridData.forEach((item) => {
      if (prevStandoffGap !== item.standoffGap) {
        uniqueGridData.push({ standoffGap: item.standoffGap, force: item.force })
        prevStandoffGap = item.standoffGap
      }
    })

    return uniqueGridData
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = useCallback((params) => {
    gridApi.current = params.api
  }, [])

  const onGridPreDestroyed = useCallback(
    (params) => {
      props.onValueChange(getValue())
      if(props.column.colId === 'curvePointsAfterRestriction'){
      props.data.curvePointsAfterRestriction = getValue()
      }
      if(props.column.colId === 'curvePointsOpenHole'){
        props.data.curvePointsOpenHole = getValue()
      }

    }, [props])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let reqFields = useMemo(() => ['standoffGap', 'force'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [centerAlignCell, createPinnedCellPlaceholder])

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleAddRow = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return

    inputRow.current.uid = uuidv4()
    gridApi.current.applyTransaction({
      add: [inputRow.current],
    })

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [isPinnedRowDataCompleted])

  const handleDeleteRow = useCallback(async (data) => {
    if (!data) return

    gridApi.current.applyTransaction({
      remove: [data],
    })
  }, [])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDeleteRow(params.data) : handleAddRow())}
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDeleteRow, handleAddRow],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        minWidth: 86,
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'standoffGap',
        colId: 'standoffGap',
        headerName: `Stand Off Gap (${getUnitsText(UNITS_FOR.Diameter)})`,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 3,
        },
        valueFormatter: (params) => {
          return isEmptyPinnedCell(params) ? 'Standoff Gap...' : numberWithCommasDecimals(params.value, 3)
        },
      },
      {
        field: 'force',
        colId: 'force',
        headerName: `Force (${getUnitsText(UNITS_FOR.Force)})`,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
        valueFormatter: (params) => {
          return isEmptyPinnedCell(params) ? 'Force...' : numberWithCommasDecimals(params.value, 2)
        },
      },
    ],
    [actionIconRenderer, getUnitsText],
  )

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)

    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getModel().getRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let item = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column

        for (let j = 0; j < data[i].length; j++) {
          item.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        item.data.standoffGap = parseFloat(item.data.standoffGap)
        item.data.force = parseFloat(item.data.force)

        rowsToUpdate.push(cloneDeep(item.data))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newItem = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newItem[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        newItem.standoffGap = parseFloat(newItem.standoffGap)
        newItem.force = parseFloat(newItem.force)
        newItem.uid = uuidv4()
        rowsToAdd.push(newItem)
      }
    }

    gridApi.current.applyTransaction({ update: rowsToUpdate, add: rowsToAdd })

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  const gridOptions = {
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }
    },
    rowHeight: 35,
    suppressRowClickSelection: true,
    pinnedBottomRowData: [inputRow.current],
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  return (
    <Box sx={{ height: '500px', width: '400px', maxWidth: '600px', maxHeight: '600px' }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={
            Array.isArray(props.forces)
              ? props.forces.map((item) => {
                  return { ...item, uid: uuidv4() }
                })
              : []
          }
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          processDataFromClipboard={processDataFromClipboard}
          onGridPreDestroyed={onGridPreDestroyed}
        />
      </div>
    </Box>
  )
}

export default CentralizerRestrictionEditor
