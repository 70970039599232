import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import BhaAnalysisThreeDeeChart from 'components/WellPages/EngineeringDashboard/BhaAnalysis/ThreeDeeChart/BhaAnlysis3dChart'

const BhaAnalysis3dChartsPage = ({ isToolbarExpanded, chartType, data, isLoading }) => {
  const _isMounted = useRef(true)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  return (
    <Box
      id={`bha-analysis-3d-charts-page`}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : null}
        <BhaAnalysisThreeDeeChart
          bhaData={data}
          isToolbarExpanded={isToolbarExpanded}
          visible={chartType === 'forcesAndDeflections3d'}
        />
      )
    </Box>
  )
}

export default BhaAnalysis3dChartsPage
