import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { Icon } from '@iconify/react'
import useWeatherBackgrounds from 'components/common/hooks/useWeatherBackgrounds'
import { Box } from '@mui/material'

const StyledTinyText = styled(Box)({
  fontSize: 12,
  fontWeight: 500,
  color: appColors.itemTextColor,
  padding: '2px',
  textAlign: 'center',
})

const StyledHeaderText = styled(Box)({
  fontSize: 40,
  fontWeight: '500',
  color: appColors.itemTextColor,
  padding: '0px',
  textShadow: '1px 1px #000',
  textAlign: 'center',
})

const WeatherViewControl = ({ weather, lat, lng }) => {
  const weatherImgs = useWeatherBackgrounds()
  if (!weather) {
    return
  }

  if (weather.hasOwnProperty('weather')) {
    var icon = 'mid:weather-cloudy'
    var backImage = weatherImgs.getWeatherImage('dayClear')

    if (Array.isArray(weather.weather) === true && weather.weather.length > 0) {
      let id = weather.weather[0].id
      if (id === 200 || id === 201 || id === 202 || id === 230 || id === 231 || id === 232)
        icon = 'mdi:weather-lightning-rainy'
      if (id === 210 || id === 211 || id === 212 || id === 221) icon = 'mdi:weather-lightning'
      if (id >= 300 && id <= 321) icon = 'mdi:weather-rainy'
      if (id >= 500 && id <= 531) icon = 'mdi:weather-pouring'
      if (id >= 600 && id <= 622) icon = 'mdi:weather-snowy'
      if (id === 701 && id === 741) icon = 'mdi:weather-fog'
      if (id === 721) icon = 'mdi:weather-hazy'
      if (id === 711 || id === 731 || id === 741 || id === 751 || id === 761 || id === 762 || id === 771 || id === 781)
        icon = 'mdi:weather-tornado'

      if (id === 800 && weather.weather[0].icon.includes('n') === true) icon = 'mdi:weather-night'
      if (id === 800 && weather.weather[0].icon.includes('n') === false) icon = 'mdi:weather-sunny'
      if (id >= 801 && id <= 804) icon = 'mdi:weather-cloudy'

      let ic = weather.weather[0].icon
      if (ic.includes('n') === false) {
        if (id === 800) backImage = weatherImgs.getWeatherImage('cleard')
        if (id >= 200 && id <= 232) backImage = weatherImgs.getWeatherImage('thunderd')
        if (id >= 300 && id <= 321) backImage = weatherImgs.getWeatherImage('drizzled')
        if (id >= 500 && id <= 531) backImage = weatherImgs.getWeatherImage('raind')
        if (id >= 600 && id <= 622) backImage = weatherImgs.getWeatherImage('snowd')
        if (id >= 700 && id <= 781) backImage = weatherImgs.getWeatherImage('fogd')
        if (id >= 801 && id <= 804) backImage = weatherImgs.getWeatherImage('cloudsd')
      } else {
        if (id === 800) backImage = weatherImgs.getWeatherImage('clearn')
        if (id >= 200 && id <= 232) backImage = weatherImgs.getWeatherImage('thundern')
        if (id >= 300 && id <= 321) backImage = weatherImgs.getWeatherImage('drizzlen')
        if (id >= 500 && id <= 531) backImage = weatherImgs.getWeatherImage('rainn')
        if (id >= 600 && id <= 622) backImage = weatherImgs.getWeatherImage('snown')
        if (id >= 700 && id <= 781) backImage = weatherImgs.getWeatherImage('fogn')
        if (id >= 801 && id <= 804) backImage = weatherImgs.getWeatherImage('cloudsn')
      }
    }
  }

  return (
    <Box
      sx={{
        color: '#FFF',
        paddingTop: '20px',
        font: {
          size: '20px',
        },
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',
        backgroundImage: `url(${backImage})`,
      }}>
      <Icon style={{ display: 'flex', height: '5vh', width: '100%', justifyContent: 'center' }} icon={icon} />
      <StyledHeaderText>{weather.name}</StyledHeaderText>
      <StyledTinyText>{weather.weather[0].description}</StyledTinyText>
      <StyledHeaderText>{Math.round(weather.main.temp)}°</StyledHeaderText>
      <Box
        sx={{
          fontSize: 18,
          fontWeight: 500,
          color: appColors.itemTextColor,
          border: '',
          padding: '0px',
          textAlign: 'center',
        }}>
        H: {Math.round(weather.main.temp_max)}° L: {Math.round(weather.main.temp_min)}°
      </Box>
      <StyledTinyText>
        Lat: {lat}° Lng: {lng}°
      </StyledTinyText>
      <StyledTinyText>Wind: {weather.wind.speed} mph</StyledTinyText>
    </Box>
  )
}

export default WeatherViewControl
