import React, { useRef, useEffect, useState } from 'react'
import { currentPageAtom, analyticsRadiusFilterAtom, wellsMapLayersSelectorAtom } from 'atoms'
import { useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil'
import { Box } from '@mui/material'
import { APIProvider, Map } from '@vis.gl/react-google-maps'
import Markers from './Markers'
import Layers from './Layers'
import { uuidv4 } from 'utils/stringFunctions'
import MapPrefs from 'components/NavBar/MapPrefs'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { Circle } from './Circle'
import { Polyline } from './PolyLine'
import { cloneDeep } from 'lodash'
import { appColors } from 'utils'

export const WELL_PATHS_LAYER = 'Well Paths'

const MapView = ({
  center = { lat: 29.7604, lng: -95.3698 },
  wells = [],
  showControls = true,
  visible = true,
  pageKey = null,
  showSearchCircle = false,
  showWellPaths = false,
  leaseLines = [],
  zoom = 5,
}) => {
  const id = useRef(uuidv4())
  const mapCenter = useRef(center)
  const _isMounted = useRef(false)
  const wellPaths = useRef([])
  const isFetchingWellPaths = useRef(false)
  const selLayers = useRecoilValue(wellsMapLayersSelectorAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [radiusFilter, setRadiusFilter] = useRecoilState(analyticsRadiusFilterAtom)
  const [redraw, setRedraw] = useState(false)

  const getWellPaths = useAxiosGzip({
    url: '/well/getMapTrajectoryGz',
  })

  useEffect(() => {
    _isMounted.current = true
    if (pageKey) setActivePage(pageKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!_isMounted.current) return
    fetchWellPaths()
  }, [selLayers, wells]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCameraChange = (ev) => {
    mapCenter.current = ev.detail.center
  }

  const handleCircleDragEnd = (props) => {
    setRadiusFilter({
      isActive: radiusFilter.isActive,
      radius: radiusFilter.radius,
      center: {
        lat: props.latLng.lat(),
        lng: props.latLng.lng(),
      },
    })
  }

  const fetchWellPaths = async () => {
    if (isFetchingWellPaths.current) return
    if (!Array.isArray(wells)) return
    if (!selLayers[WELL_PATHS_LAYER] && !showWellPaths) return

    let wellPathData = []
    let wellsToFetch = []
    for (let i = 0; i < wells.length; i++) {
      let index = wellPaths.current.findIndex((well) => well.wellName === wells[i].actualWell)
      if (index < 0) {
        wellsToFetch.push({ wellName: wells[i].actualWell, isPlan: false })
        continue
      }

      wellPathData.push(cloneDeep(wellPaths.current[index]))
    }

    const response = await getWellPaths({ wells: JSON.stringify(wellsToFetch) })
    isFetchingWellPaths.current = false
    if (!_isMounted.current) return

    if (response?.error) return

    if (Array.isArray(response?.data)) {
      wellPathData = wellPathData.concat(response.data)
    }

    wellPaths.current = wellPathData
    setRedraw(!redraw)
  }

  const WellPath = ({ data }) => {
    if (!Array.isArray(data?.coords)) return null
    if (data.coords.length < 2) return null

    return (
      <Polyline
        strokeWeight={3}
        strokeColor={data?.isPlan ? appColors.planTrajectoryColor : appColors.surveyTrajectoryColor}
        path={data.coords}
      />
    )
  }

  const LeaseLine = ({ data }) => {
    if (!Array.isArray(data?.coords)) return null
    if (data.coords.length < 2) return null

    return (
      <Polyline
        strokeWeight={2}
        strokeOpacity={1}
        strokeColor={'#FF0000'}
        path={data.coords}
      />
    )
  }

  return (
    <Box sx={{ position: 'relative', width: '100%', height: '100%', display: visible ? 'flex' : 'none' }}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLEMAP_API_KEY}>
        <Map
          onCameraChanged={handleCameraChange}
          zoomControl={true}
          disableDefaultUI={true}
          defaultCenter={center}
          defaultZoom={zoom}
          gestureHandling={'greedy'}
          mapId={id.current}
          mapTypeId={'hybrid'}>
          <Markers wells={wells} />
          <Layers />
          {(showWellPaths || selLayers[WELL_PATHS_LAYER]) && Array.isArray(wellPaths.current)
            ? wellPaths.current.map((well, i) => <WellPath key={i} data={well} />)
            : null}
          {Array.isArray(leaseLines) ? leaseLines.map((leaseLine, i) => <LeaseLine key={i} data={leaseLine} />) : null}
          {showSearchCircle && radiusFilter?.isActive ? (
            <Circle
              radius={radiusFilter?.radius ? radiusFilter.radius : 50000}
              center={radiusFilter?.center ? radiusFilter.center : mapCenter.current}
              onDragEnd={handleCircleDragEnd}
              strokeColor={'#FF0000'}
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor={'#FF0000'}
              fillOpacity={0.35}
              draggable={true}
            />
          ) : null}
        </Map>
      </APIProvider>
      {showControls ? <MapPrefs /> : null}
    </Box>
  )
}

export default MapView
