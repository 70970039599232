import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WellGrid = ({ wellData, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: true,
        cellEditorSelector: (params) => {
          if (params.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: -1000000,
                max: 1000000,
                precision: 7,
              },
            }
          }

          return null
        },
        valueGetter: (params) => {
          if (params?.data?.type === 'text') {
            return unescapeHtml(params?.data?.value)
          }

          if (params?.data?.type === 'number') {
            return parseFloat(params?.data?.value)
          }

          return params?.data?.value
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return `${numberWithCommasDecimals(params?.data?.value, 2)}`
          }

          return params?.data?.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getWellData = (data) => {
    let well = [
      { label: `Well Name`, value: '', id: -1, type: 'text', tag: `well` },
      { label: `Description`, value: '', id: -1, type: 'text', tag: `description` },
      { label: `Horizontal Uncertainty`, value: 0, id: -1, type: 'number', tag: `vertUncert` },
      { label: `Vertical Uncertainty`, value: 0, id: -1, type: 'number', tag: `horzUncert` },
    ]

    for (let i = 0; i < well.length; i++) {
      well[i].id = i
      if (well[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(well[i].tag)) continue
      if (!data[well[i].tag]) continue
      well[i].value = data[well[i].tag]
    }

    return well
  }

  return (
    <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getWellData(wellData)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default WellGrid
