import React, { useRef, useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import AntiCollisionDashboardAlertSettingsGrid from 'components/AntiCollisionDashboard/Settings/AntiCollisionDashboardAlertSettingsGrid'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AntiCollisionDashboardSettings = ({ settings, onClose, onApply }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const settingsRef = useRef(settings)
  const { getUnitsText } = useUnits()
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('antiCollisionDashboardSettingsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('antiCollisionDashboardSettingsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        headerName: '',
        cellRenderer: 'agGroupCellRenderer',
        width: 35,
        cellStyle: centerAlignCell,
        cellClass: 'grid-detail-icon',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        filter: null,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'name',
        colId: 'name',
        headerName: 'Alerts',
        valueFormatter: (params) => {
          if (!params.value) return ''
          if (typeof params.value !== 'string') return ''
          if (!params.value.trim()) return ''

          //Converts camel case variable name to readable string
          let regex = /([a-z])([0-9])(?=[A-Z])/g
          let result = params.value.replace(regex, '$1 $2 ')
          result = result.replace(/([a-z])([A-Z])/g, '$1 $2')
          result = result.charAt(0).toUpperCase() + result.slice(1)

          if (result.toLowerCase() === 'c2c') result = 'C2C'
          if (result.toLowerCase() === 'sf') result = 'SF'

          return result
        },
      },
    ],
    [centerAlignCell],
  )

  const getAlerts = (data) => {
    if (!data) return []
    if (!data?.alerts) return []
    if (!data.alerts.hasOwnProperty('c2c')) return []

    let alertData = []
    for (const key in data.alerts) {
      alertData.push({
        uid: key,
        name: key,
      })
    }

    return alertData
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    const handleAlertUpdate = (data) => {
      if (!settingsRef.current) return
      if (!settingsRef.current.alerts) return
      if (!settingsRef.current.alerts.hasOwnProperty(params.data?.uid)) return

      let index = settingsRef.current.alerts[params.data?.uid].findIndex((x) => x.uid === data.uid)
      if (index < 0) return

      settingsRef.current.alerts[params.data?.uid][index] = { ...data }
    }

    const getData = () => {
      if (!settingsRef.current) return []
      if (!settingsRef.current?.alerts) return []
      if (!settingsRef.current.alerts.hasOwnProperty(params.data?.uid)) return []
      if (!Array.isArray(settingsRef.current.alerts[params.data?.uid])) return []

      return settingsRef.current.alerts[params.data?.uid]
    }

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.65
    }

    return (
      <Box sx={{ height: height }}>
        <AntiCollisionDashboardAlertSettingsGrid data={getData()} handleUpdate={handleAlertUpdate} />
      </Box>
    )
  })

  const handleUpdate = (data) => {
    if (!settingsRef.current) return
    if (!settingsRef.current.hasOwnProperty(data.tag)) return
    settingsRef.current[data.tag] = data.value
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='xl'
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Settings`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '150px', marginBottom: '10px' }}>
            <AgGridReact
              rowData={[
                { uid: 0, tag: 'incPlans', value: settingsRef.current?.incPlans, label: 'Inc Plans', type: 'bool' },
                {
                  uid: 1,
                  tag: 'c2cDist',
                  value: parseFloat(settingsRef.current?.c2cDist),
                  label: `Min C2C (${getUnitsText(UNITS_FOR.Depth)})`,
                  type: 'number',
                  min: 500,
                  max: 999999,
                },
              ]}
              columnDefs={[
                {
                  field: 'label',
                  colId: 'label',
                  headerName: 'Label',
                },
                {
                  field: 'value',
                  colId: 'value',
                  headerName: 'Value',
                  editable: true,
                  cellDataType: false,
                  cellEditorSelector: (params) => {
                    if (params.data?.type === 'number') {
                      return {
                        component: 'agTextCellEditor',
                        params: {
                          precision: 3,
                          min: params.data?.min,
                          max: params.data?.max,
                        },
                      }
                    }

                    if (params.data?.type === 'bool') {
                      return {
                        component: 'agCheckboxCellEditor',
                      }
                    }

                    return {
                      component: 'agTextCellEditor',
                    }
                  },
                  cellRendererSelector: (params) => {
                    if (params.data?.type === 'bool') {
                      return {
                        component: 'agCheckboxCellRenderer',
                      }
                    }
                    return null
                  },
                  valueFormatter: (params) => {
                    if (params.data?.type === 'number') {
                      return `${numberWithCommasDecimals(parseFloat(params.data?.value), 2)}`
                    }
                    return params.data?.value
                  },
                  valueSetter: (params) => {
                    params.data.value = params.newValue
                    if (params.data?.type === 'number') params.data.value = parseFloat(params.data?.value)

                    handleUpdate(params.data)
                    params.api.applyTransaction({ update: [params.data] })
                    return true
                  },
                },
              ]}
              defaultColDef={defaultColDef}
              animateRows={true}
              headerHeight={0}
              getRowId={getRowId}
            />
          </div>
          <div ref={containerRef} className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 170px)' }}>
            <AgGridReact
              rowData={getAlerts(settingsRef.current)}
              columnDefs={sortColDefs(columnDefs, 'antiCollisionDashboardSettingsGrid')}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              getRowId={getRowId}
              onGridReady={onGridReady}
              masterDetail={true}
              detailCellRenderer={DetailCellRenderer}
              detailRowAutoHeight={true}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Cancel
        </Button>
        <Button variant='outlined' onClick={() => onApply(settingsRef.current)} color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AntiCollisionDashboardSettings
