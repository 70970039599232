import React, { useEffect, useRef } from 'react'
import BhaStatisticsChartDistance from 'components/WellPages/DrillStringPages/CarouselItems/BhaStatisticsChartDistance'
import useWellData from 'components/common/hooks/useWellData'

const BhaStatDistanceCard = () => {
  const _isMounted = useRef(false)
  const { wellData } = useWellData()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  return <BhaStatisticsChartDistance data={wellData} />
}

export default BhaStatDistanceCard
