import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom, personnelUtilizationLayoutAtom } from 'atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import cloneDeep from 'lodash/cloneDeep'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import PersonnelUtilizationNamesGrid from './PersonnelUtilizationNamesGrid'
import PersonnelUtilizationGanttChart from './PersonnelUtilizationGanttChart'
import usePersonnelUtilizationXlsExport from 'components/common/hooks/usePersonnelUtilizationXlsExport'
import { Box } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import MenuButton from 'components/common/MenuButton'

const PersonnelUtilizationPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [pageLayout, setPageLayout] = useRecoilState(personnelUtilizationLayoutAtom)
  const isLoading = useRef(false)
  const [personnelData, setPersonnelData] = useState([])
  const [selectedNames, setSelectedNames] = useState([])
  const { handleXlsExport } = usePersonnelUtilizationXlsExport()

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showPersonnelNames, setShowPersonnelNames] = useState(getPaneVisible(0))
  const [dateRange, setDateRange] = useState(() => {
    let dates = {
      dropDownText: 'Last 30 Days',
      dateTo: new Date(Date.now()).toISOString().substring(0, 10) + 'T00:00:01',
      dateFrom: '',
    }
    let dt = new Date(Date.now())
    dt.setDate(dt.getDate() - 30)
    dates.dateFrom = dt.toISOString().substring(0, 10) + 'T00:00:01'
    return dates
  })

  const getPersonnelUtilization = useAxiosGzip({
    url: '/admin/personnel/getPersonnelUtilizationGz',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchPersonnelUtilization()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchPersonnelUtilization()
  }, [dateRange]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActivePage(PAGE_KEYS.personnelUtilizationPageKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function validateDates(dates) {
    const { dateTo, dateFrom } = dates

    let newDates = { ...dates }
    if (new Date(Date.parse(dateFrom)).getTime() > new Date(Date.parse(dateTo)).getTime()) {
      newDates.dateFrom = dateTo
      newDates.dateTo = dateFrom
    }

    return newDates
  }

  const fetchPersonnelUtilization = async () => {
    if (isLoading.current) return
    isLoading.current = true
    let dates = validateDates(dateRange)

    const response = await getPersonnelUtilization({
      dateTo: dates.dateTo ? dates.dateTo : null,
      dateFrom: dates.dateFrom ? dates.dateFrom : null,
      incRelief: true,
    })

    if (_isMounted.current) {
      setPersonnelData(response.data ? response.data : null)
      isLoading.current = false
    }
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '30%' : '30%'
    if (index >= pageLayout.length) return index === 0 ? '30%' : '30%'
    return pageLayout[index].size
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showPersonnelNames)
    setShowPersonnelNames(!showPersonnelNames)
  }

  const onXlsExport = () => {
    if (!personnelData || !Array.isArray(personnelData)) return
    if (!Array.isArray(selectedNames)) return
    handleXlsExport(personnelData, selectedNames)
  }

  return (
    <React.Fragment>
      <SplitPane
        split='vertical'
        onDragFinished={(params) => onDragFinished(params, 0)}
        defaultSize={getInitialPaneSize(0)}
        size={showPersonnelNames ? getInitialPaneSize(0) : '25px'}
        allowResize={showPersonnelNames}
        style={{
          height: 'calc(100vh - 64px)',
          marginLeft: leftPos,
          width: `calc(100% - ${leftPos}px)`,
          maxWidth: `calc(100% - ${leftPos}px)`,
        }}>
        <PersonnelUtilizationNamesGrid
          setSelectedPersonnelNames={setSelectedNames}
          personnelData={personnelData}
          showHidePane={showHidePane}
          personnelNamesVisible={showPersonnelNames}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
        <PersonnelUtilizationGanttChart personnelData={personnelData} title={''} selectedNames={selectedNames} />
      </SplitPane>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: (theme) => theme.spacing(12),
          right: (theme) => theme.spacing(2),
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
              name: 'Export to XLSX',
              onClick: onXlsExport,
            },
          ]}
        />
      </Box>
    </React.Fragment>
  )
}

export default PersonnelUtilizationPage
