import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { Tooltip } from 'chart.js'
import AnalyticsBarChart from './AnalyticsBarChart'
import { array2pipestr, createDateArray } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { chartSeriesColors } from 'utils'
import { numberWithCommas } from 'utils/stringFunctions'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CostPerDayChart = () => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const { getUnitsText } = useUnits()
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const { theme } = useInnovaTheme()
  let date = new Date()
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  const xAxisRange = useRef({
    minScale: firstDay.toISOString().slice(0, 10),
    maxScale: lastDay.toISOString().slice(0, 10),
    minLimit: firstDay.valueOf(),
    maxLimit: lastDay.valueOf(),
  })
  const yAxisRange = useRef({ min: 0, max: 10000 })
  const [chartData, setChartData] = useState(null)
  const [showChartData, setShowChartData] = useState(false)
  const [datesWithNegativeCosts, setDatesWithNegativeCosts] = useState([])
  const smallestYValue = useRef(0)
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: '/kpi/getKpis',
  })

  const getTotalCost = (date) => {
    let sumDailyCostCodes = data.costByDay.find((cost) => cost.date === date)
    if (sumDailyCostCodes) {
      return sumDailyCostCodes.totalCost
    }
  }

  Tooltip.positioners.tooltipOnCursor = function (elements, eventPosition) {
    return {
      x: eventPosition.x,
      y: eventPosition.y,
    }
  }

  const updateYTickLimit = (chart, minMs, maxMs) => {
    let minDate = new Date(minMs).toISOString().slice(0, 10)
    let maxDate = new Date(maxMs).toISOString().slice(0, 10)

    let currentXAxis = createDateArray(minDate, maxDate)

    let currentXHasNegativeVals = datesWithNegativeCosts.some((dateStr) => currentXAxis.includes(dateStr))

    if (currentXHasNegativeVals) {
      chart.chart.config._config.options.scales.y.min = yAxisRange.current.min
    } else {
      chart.chart.config._config.options.scales.y.min = 0
    }
    chart.chart.update()
  }

  const chartOptions = {
    animation: {
      duration: 0,
    },
    hover: {
      animationDuration: 0,
    },
    type: 'bar',
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      decimation: true,
      legend: { display: false },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          speed: 15,
          threshold: 5,
          onPanComplete: (chart) => {
            updateYTickLimit(chart, chart.chart.scales.x.min, chart.chart.scales.x.max)
          },
        },
        limits: {
          x: {
            min: new Date(xAxisRange.current.minScale),
            max: new Date(xAxisRange.current.maxScale),
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: 'x',
          onZoomComplete: (chart) => {
            updateYTickLimit(chart, chart.chart.scales.x.min, chart.chart.scales.x.max)
          },
        },
      },
      tooltip: {
        position: 'tooltipOnCursor',
        callbacks: {
          title: function (tooltipItem) {
            return `${tooltipItem[0].raw.x}: ${getUnitsText(UNITS_FOR.Cost)}${numberWithCommas(
              getTotalCost(tooltipItem[0].raw.x),
            )}`
          },
          label: function (context) {
            return [
              `Code: ${context.dataset.label}`,
              `Amount: ${getUnitsText(UNITS_FOR.Cost)}${numberWithCommas(context.raw.y)}`,
            ]
          },
        },
        intersect: true,
      },
    },
    scales: {
      x: {
        ticks: {
          precision: 0,
          stepSize: 1,
          autoSkip: false,
          sampleSize: 5,
        },
        min: xAxisRange.current.minScale,
        max: xAxisRange.current.maxScale,
        type: 'time',
        time: {
          unit: 'day',
          displayFormats: {
            day: 'YYYY-MM-DD',
          },
        },
        title: {
          display: true,
          text: 'Days',
          font: {
            size: 20,
            weight: 600,
          },
          color: 'rgba(102, 102, 102, 1)',
        },
      },
      y: {
        min: yAxisRange.current.min,
        max: yAxisRange.current.max,
        title: {
          display: true,
          text: `Cost (${getUnitsText(UNITS_FOR.Cost)})`,
          font: {
            size: 20,
            weight: 600,
          },
          color: 'rgba(102, 102, 102, 1)',
        },
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.costPerDayKey)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && data !== null) {
      createChartData()
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && chartData && chartData.hasOwnProperty('datasets')) {
      setShowChartData(true)
    }
  }, [chartData]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortDate = (a, b) => {
    if (typeof a.date !== 'string') return -1
    if (typeof b.date !== 'string') return -1
    let d1 = new Date(Date.parse(a.date + 'T00:00:00'))
    let d2 = new Date(Date.parse(b.date + 'T00:00:00'))
    if (d1 < d2) return -1
    if (d1 > d2) return 1

    return 0
  }

  const createChartData = () => {
    let output = {
      datasets: [],
    }

    if (!data.hasOwnProperty('costByDay') || !Array.isArray(data?.costByDay)) {
      setChartData(output)
      return
    }

    let dataCopy = { ...data }
    const cbd = [...dataCopy.costByDay]

    cbd.sort(sortDate)

    xAxisRange.current.minScale = new Date(cbd[0].date + 'T00:00:00')
    xAxisRange.current.maxScale = new Date(cbd[cbd.length - 1].date + 'T00:00:00')

    let largestYValue = 0
    let daysWithNegativeCosts = []
    smallestYValue.current = 0

    cbd.forEach((day) => {
      if (typeof day.totalCost === 'number') {
        if (day.totalCost > largestYValue) {
          let chartCeiling = day.totalCost / 10
          largestYValue = day.totalCost + chartCeiling
        }
        if (!day.hasOwnProperty('wellCosts')) return
        day.wellCosts.forEach((wellCost) => {
          wellCost?.costs?.forEach((cost, id) => {
            if (cost.totalValue < 0) {
              if (!daysWithNegativeCosts.includes((i) => i === cost.date)) {
                daysWithNegativeCosts.push(cost.date)
              }
              if (cost.totalValue < smallestYValue.current) {
                smallestYValue.current = cost.totalValue
              }
            }
            let foundDatasetIdx = output.datasets?.findIndex((dataset) => dataset.label === cost.costCode)
            if (foundDatasetIdx > -1) {
              let foundDataIdx = output.datasets[foundDatasetIdx]?.data.findIndex((data) => data.x === cost.date)
              if (foundDataIdx > -1) {
                output.datasets[foundDatasetIdx].data[foundDataIdx].y += cost.totalValue
              } else {
                output.datasets[foundDatasetIdx]?.data.push({
                  x: cost.date,
                  y: cost.totalValue,
                })
              }
            } else {
              output.datasets.push({
                label: cost.costCode,
                backgroundColor: chartSeriesColors[id],
                categoryPercentage: 0.5,
                maxBarThickness: 24,
                data: [{ x: cost.date, y: cost.totalValue }],
              })
            }
          })
        })
      }
    })

    yAxisRange.current.min = Math.floor(smallestYValue.current / 1000) * 1000
    yAxisRange.current.max = Math.floor(largestYValue / 1000) * 1000

    setDatesWithNegativeCosts(daysWithNegativeCosts)
    setChartData(output)
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData({})
      return
    }

    if (selectedWells.length < 1) {
      setData({})
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      costByDay: 'true',
    }

    let dateFrom = getParameter('Start Date', searchParams)
    let dateTo = getParameter('End Date', searchParams)

    if (!getParameterActive('Start Date', searchParams)) dateFrom = ''
    if (!getParameterActive('End Date', searchParams)) dateTo = ''

    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = '1990-01-01T07:00:00.000Z'
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    setIsLoading(true)
    const dataResponse = await getKpiData(payload)
    if (_isMounted.current) {
      if (dataResponse?.data) {
        setData(dataResponse.data)
      } else {
        setData({})
      }
    }

    setIsLoading(false)
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet([['Date', 'Total Cost', 'Wells']], {
      origin: 'A1',
    })

    if (data && data.costByDay) {
      const cbd = [...data.costByDay] //need to create non-stateful copy to sort
      cbd.sort(sortDate)

      let costPerDayExport = []
      cbd.forEach((day) => {
        let cpdRow = []
        cpdRow.push(day.date)
        cpdRow.push(day.totalCost)
        day.wellCosts.forEach((well) => {
          cpdRow.push(well.actualWell)
        })
        costPerDayExport.push(cpdRow)
      })

      XLSX.utils.sheet_add_aoa(ws, costPerDayExport, { origin: 'A2' })
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Detailed Well Cost-Day')
    XLSX.writeFile(wb, 'Cost by Day.xlsx')
  }

  return (
    <AnalyticsBarChart
      wellData={
        showChartData
          ? chartData
          : {
              labels: [],
              datasets: [
                {
                  backgroundColor: '',
                  maxBarThickness: 24,
                  data: [],
                },
              ],
            }
      }
      units={getUnitsText(UNITS_FOR.Depth)}
      title={'Cost Per Day'}
      chartOptions={chartOptions}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default CostPerDayChart
