import { appColors } from 'utils'
import { getDeltaTime } from 'utils/dateTimeFunctions'
import rigIcon from '../assets/rigIcon.png'
import code2 from '../assets/ActivityIcons/code2.png'
import code5 from '../assets/ActivityIcons/code5.png'
import code6 from '../assets/ActivityIcons/code6.png'
import code8 from '../assets/ActivityIcons/code8.png'
import code10D from '../assets/ActivityIcons/code10D.png'
import code26 from '../assets/ActivityIcons/code26.png'

export function getDistinctDates(dailyActivity) {
  let distinctDates = []
  if (!Array.isArray(dailyActivity)) return
  dailyActivity.forEach((activity) => {
    let dateFound = distinctDates.find((element) => element.label === activity.date)
    if (dateFound === undefined) {
      distinctDates.push({
        label: activity.date,
        value: distinctDates.length,
      })
    }
  })

  return distinctDates
}

export function getDistinctPhases(dailyActivity) {
  let distinctPhases = []
  if (!Array.isArray(dailyActivity)) return []
  dailyActivity.forEach((activity) => {
    let phaseFound = distinctPhases.find((element) => element.label === activity.phase)
    if (phaseFound === undefined) {
      if (activity.phase !== null && activity.phase !== '') {
        distinctPhases.push({
          label: activity.phase,
          value: distinctPhases.length,
        })
      }
    }
  })

  return distinctPhases
}

export function getDistinctBHA(dailyActivity) {
  let distinctBHA = []
  if (!Array.isArray(dailyActivity)) return

  let sortedData = [...dailyActivity]

  sortedData.sort((a, b) =>
    a.date > b.date ? 1 : a.date === b.date ? (a.endTime > b.endTime ? 1 : -1) : -1,
  )

  sortedData.forEach((activity) => {
    let bhaFound = distinctBHA.find((element) => element.bhaNum === activity.bha)
    if (bhaFound === undefined) {
      if (activity.bha !== null && activity.bha !== '' && activity.bha !== '-1') {
        distinctBHA.push({ label: `${activity.bhaNumRep}: ${activity.bhaDesc}`, value: distinctBHA.length, bhaNum: activity.bha })
      }
    }
  })

  return distinctBHA
}

function isSlide(actCode) {
  if (!actCode) return false
  if (actCode === '2J') return true

  return false
}

function isCode8(actCode) {
  if (!actCode) return false
  if (actCode.substring(0, 1) === '8') return true

  return false
}

function isDirNpt(actCode) {
  if (!actCode) return false
  if (actCode.substring(0, 2) === '26') return true

  return false
}

function isCirc(actCode) {
  if (!actCode) return false
  if (actCode.substring(0, 1) === '5') return true

  return false
}

function isDrilling(actCode) {
  if (!actCode) return false
  if (actCode.substring(0, 1) === '2' && actCode.length === 2) return true

  return false
}

function isSurveyAndConn(actCode) {
  if (!actCode) return false
  if (actCode === '10D') return true

  return false
}

function isBhaHandling(actCode) {
  if (!actCode) return false
  if (actCode === '6O' || actCode === '6P') return true

  return false
}

export function getActivityRowColor(actCode) {
  if (isCode8(actCode)) return appColors.nptColor
  if (isBhaHandling(actCode)) return appColors.bhaHandlingColor
  if (isDirNpt(actCode)) return appColors.dirNptColor
  if (isDrilling(actCode)) return !isSlide(actCode) ? appColors.rotateColor : appColors.slideColor

  return '#FFFFFF'
}

export function getActivityIcon(actCode) {
  if (isCode8(actCode)) return code8
  if (isCirc(actCode)) return code5
  if (isSurveyAndConn(actCode)) return code10D
  if (isBhaHandling(actCode)) return code6
  if (isDirNpt(actCode)) return code26
  if (isDrilling(actCode)) return code2

  return rigIcon
}

export function findBHAandPhaseChanges(dailyActivity) {
  if (!dailyActivity) return
  dailyActivity.forEach((activity, i) => {
    let bhaChange = false
    let phaseChange = false
    if (i > 0) {
      if (activity.bha !== dailyActivity[i - 1].bha) bhaChange = true
      if (activity.phase !== dailyActivity[i - 1].phase) phaseChange = true
    }

    dailyActivity[i].bhaChange = bhaChange
    dailyActivity[i].phaseChange = phaseChange
  })
}

export function dailyActivityTotals(dailyActivity, phaseFilter = '', bhaFilter = '', dateFilter = '') {
  let output = {
    drilled: 0,
    slideDistance: 0,
    rotateDistance: 0,
    drillingHours: 0,
    slideHours: 0,
    rotateHours: 0,
    slidePerc: 0,
    rotatePerc: 0,
    npt: 0,
    dirNPT: 0,
    slideROP: 0,
    rotateROP: 0,
    avgROP: 0,
    numBha: 0,
    numSlides: 0,
    startDepth: -1,
    endDepth: -1,
    startTime: '',
    endTime: '',
    phases: [],
    bhas: [],
    dates: [],
  }

  if (dailyActivity?.length > 0) {
    // eslint-disable-next-line array-callback-return
    dailyActivity.map((activity) => {
      let passFilter = true
      if (phaseFilter !== '' && activity.phase !== phaseFilter) passFilter = false
      if (bhaFilter !== '' && activity.bha !== bhaFilter) passFilter = false
      if (dateFilter !== '' && activity.date !== dateFilter) passFilter = false

      if (passFilter) {
        if (output.startDepth < 0) {
          output.startDepth = activity.startDepth
          output.startTime = activity.startTime
        }

        if (activity.endDepth > activity.startDepth) {
          output.endDepth = activity.endDepth
          output.endTime = activity.endTime
        }

        let cl = activity.endDepth - activity.startDepth
        let deltaHours = getDeltaTime(activity.startTime, activity.endTime, true)

        if (cl < 0) cl = 0

        output.drilled += cl

        if (isDrilling(activity.activity)) output.drillingHours += deltaHours

        if (isSlide(activity.activity)) {
          output.numSlides++
          output.slideDistance += cl
          output.slideHours += deltaHours
        }

        if (isCode8(activity.activity)) output.npt += deltaHours
        if (isDirNpt(activity.activity)) output.dirNPT += deltaHours

        let phaseFound = false
        output.phases.forEach((phase) => {
          if (phase === activity.phase) phaseFound = true
        })

        if (!phaseFound) {
          if (activity.phase !== '') output.phases.push(activity.phase)
        }

        let bhaFound = false
        output.bhas.forEach((b) => {
          if (b === activity.bha) bhaFound = true
        })

        if (!bhaFound) {
          if (activity.bha !== '' && activity.bha !== -1) output.bhas.push(activity.bha)
        }

        let dateFound = false
        output.dates.forEach((d) => {
          if (d === activity.date) dateFound = true
        })

        if (!dateFound) {
          if (activity.date !== '') output.dates.push(activity.date)
        }
      }
    })
  }

  output.rotateDistance = output.drilled - output.slideDistance
  output.rotateHours = output.drillingHours - output.slideHours
  output.slideROP = calcROP(output.slideDistance, output.slideHours)
  output.rotateROP = calcROP(output.rotateDistance, output.rotateHours)
  output.avgROP = calcROP(output.drilled, output.drillingHours)
  output.slidePerc = calcPercentage(output.slideDistance, output.drilled)
  output.rotatePerc = calcPercentage(output.rotateDistance, output.drilled)
  return output
}

export function calcROP(drilled, hours) {
  let rop = 0
  if (drilled > 0 && hours > 0) rop = drilled / hours

  return rop
}

export function calcPercentage(topLine, bottomLine) {
  let perc = 0
  if (topLine > 0 && bottomLine > 0) perc = (topLine / bottomLine) * 100

  return perc
}

export function getDailyStats(data) {
  if (!data) return []
  let dt = getDistinctDates(data)
  let output = []
  dt.forEach((d) => output.push(dailyActivityTotals(data, '', '', d.label)))
  return output
}

export function getPhaseStats(data) {
  let phases = getDistinctPhases(data)
  let output = []
  phases.forEach((phase) => output.push(dailyActivityTotals(data, phase.label, '', '')))
  return output
}

export function getBHAStats(data) {
  let bhas = getDistinctBHA(data)
  let output = []
  bhas.forEach((b) => output.push(dailyActivityTotals(data, '', b.bhaNum, '')))
  return output
}
