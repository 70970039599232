import React from 'react'

import { Drawer } from '@mui/material'
import { makeStyles } from '@mui/styles'

import ChatRoomControl from './ChatRoomControl'
import ConversationWindow from './ConversationWindow'
import ChatSendControl from './ChatSendControl'

const useDrawerStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.itemBackground,
    maxWidth: '400px',
  },
}))

const ChatDrawer = ({ children, selectorToggle, setSelectorToggle }) => {
  const classes = useDrawerStyles()

  const onCloseDrawer = (event) => {
    setSelectorToggle(false)
  }

  return (
    <React.Fragment>
      <Drawer
        anchor='right'
        disableEnforceFocus
        open={selectorToggle}
        onClose={onCloseDrawer}
        classes={{ paper: classes.paper }}>
        {React.Children.map(children, (child) => {
          return child
        })}
      </Drawer>
    </React.Fragment>
  )
}

const ChatControl = ({ open: chatToggle, onClose: setSelectorToggle }) => {
  return (
    <ChatDrawer selectorToggle={chatToggle} setSelectorToggle={setSelectorToggle}>
      <ChatRoomControl />
      <ConversationWindow />
      <ChatSendControl />
    </ChatDrawer>
  )
}

export default ChatControl
