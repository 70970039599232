import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { Box, Tooltip, IconButton, Snackbar } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const JointGrid = ({
  isLoading,
  selectedTally,
  addNewJoint,
  updateJoint,
  deleteJoint,
  updateSelectedTally,
  updateJointSequence,
  addMultipleJoints,
  onXlsExport,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const selectedTallyRef = useRef(selectedTally)
  const inputRow = useRef({})
  const rowsToAdd = useRef([])
  const rowsToUpdate = useRef([])
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    selectedTallyRef.current = selectedTally
  }, [selectedTally]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      let res = await updateJoint({ ...data, tallyId: selectedTallyRef.current.tallyId })
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      if (gridApi.current) {
        gridApi.current.applyTransaction({
          update: [res.data],
        })
      }

      updateSelectedTally()
      setTimeout(() => autoSizeColumns(), 100)
    },
    [updateJoint, updateSelectedTally],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate(event.data)
        return
      }

      handleAddJoint()
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onRowDragEnd: (event) => {
      let joints = []
      event.api.forEachNode((node, i) => {
        let data = cloneDeep(node.data)
        data.sequenceNo = i + 1
        joints.push(data)
      })

      if (joints.length === 0) return
      updateJointSequence(joints)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    if (colDef.field !== 'jointLength' && colDef.field !== 'azi' && colDef.field !== 'inc') return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false

            let data = params.data
            data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(data)
          }
        }
        return false
      },
      cellStyle: centerAlignCell,
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [handleUpdate, centerAlignCell])

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('jointGrid', 'colLayout', colLayout)
  }

  function isPinnedRowDataCompleted() {
    return inputRow.current.hasOwnProperty('jointLength')
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddJoint())}
              size='large'>
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </React.Fragment>
    )
  }

  const handleAddJoint = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return

    let res = await addNewJoint({ ...inputRow.current, tallyId: selectedTallyRef.current.tallyId })
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        add: [res.data],
      })

      inputRow.current = {}
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    updateSelectedTally()
  }, [addNewJoint, updateSelectedTally])

  const handleAddPastedJoints = useCallback(
    async (data) => {
      let res = await addMultipleJoints(selectedTallyRef.current.tallyId, data)
      if (res?.error) {
        setStatus({ show: true, severity: 'error', message: res.message })
        return
      }

      if (gridApi.current && Array.isArray(res.data)) {
        gridApi.current.applyTransaction({
          add: res.data,
        })
        inputRow.current = {}
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }

      updateSelectedTally()
    },

    [addMultipleJoints, updateSelectedTally],
  )

  const handleDelete = async (data) => {
    if (!data) return
    let res = await deleteJoint({ ...data, tallyId: selectedTallyRef.current.tallyId })
    if (res?.error) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return
    }

    if (gridApi.current) {
      gridApi.current.applyTransaction({
        remove: [res.data],
      })
    }

    updateSelectedTally()
  }

  let columnDefs = [
    {
      colId: 'dragHandle',
      headerName: '',
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      resizable: false,
      filter: null,
      width: 20,
      pinned: 'left',
      lockPosition: 'left',
      cellStyle: centerAlignCell,
      rowDrag: true,
    },
    {
      field: 'actions',
      colId: 'actions',
      width: 20,
      headerName: '',
      pinned: 'left',
      lockPosition: 'left',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      resizable: false,
      cellStyle: centerAlignCell,
      cellRenderer: actionIconRenderer,
    },
    {
      colId: 'jointNum',
      headerName: '#',
      pinned: 'left',
      lockPosition: 'left',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return ''
        return params?.node?.rowIndex + 1
      },
    },
    {
      headerName: 'Length',
      colId: 'jointLength',
      field: 'jointLength',
      valueFormatter: (params) => {
        if (params?.node?.rowPinned === 'bottom') {
          return 'JointLength...'
        }

        return numberWithCommasDecimals(params.value, 2)
      },
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 2000,
        precision: 2,
      },
    },
    {
      colId: 'stand',
      headerName: 'Stand',
      pinned: 'left',
      lockPosition: 'left',
      editable: false,
      filter: null,
      sortable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return ''

        let jointMod = (params?.node?.rowIndex + 1) % selectedTallyRef.current?.numJointsPerStand
        let jointText = ''

        if (jointMod === 0) jointText = (params?.node?.rowIndex + 1) / selectedTallyRef.current?.numJointsPerStand
        if (jointMod > 0) {
          for (let i = 0; i < jointMod; i++) {
            jointText += 'X'
          }
        }

        return jointText
      },
    },
    {
      headerName: 'Depth',
      colId: 'depth',
      editable: false,
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return ''

        let totalLength = selectedTallyRef.current?.bhaLength ? selectedTallyRef.current.bhaLength : 0
        params.api.forEachNodeAfterFilter((node) => {
          if (node.rowIndex <= params.node.rowIndex) totalLength += node.data.jointLength
        })

        return numberWithCommasDecimals(totalLength, 2)
      },
    },
    {
      headerName: 'Svy Depth',
      colId: 'svyDepth',
      editable: false,
      valueGetter: (params) => {
        if (params?.node?.rowPinned === 'bottom') return ''

        let totalLength = selectedTallyRef.current?.bhaLength ? selectedTallyRef.current.bhaLength : 0
        totalLength -= selectedTallyRef.current?.surveyOffset ? selectedTallyRef.current.surveyOffset : 0
        totalLength -= selectedTallyRef.current?.stickUp ? selectedTallyRef.current.stickUp : 0

        // let totalLength =
        //   selectedTallyRef.current?.bhaLength -
        //   selectedTallyRef.current?.surveyOffset -
        //   selectedTallyRef.current?.stickUp
        params.api.forEachNodeAfterFilter((node) => {
          if (node.rowIndex <= params.node.rowIndex) totalLength += node.data.jointLength
        })

        return numberWithCommasDecimals(totalLength, 2)
      },
    },
    {
      headerName: 'Inc',
      colId: 'inc',
      field: 'inc',
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueFormatter: (params) => (params.value ? numberWithCommasDecimals(params.value, 2) : ''),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 180,
        precision: 2,
      },
    },
    {
      headerName: 'Azi',
      colId: 'azi',
      field: 'azi',
      editable: (params) => params?.node?.rowPinned !== 'bottom',
      valueFormatter: (params) => (params.value ? numberWithCommasDecimals(params.value, 2) : ''),
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: {
        min: 0,
        max: 360,
        precision: 2,
      },
    },
  ]

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const gridDrop = async (event) => {
    event.preventDefault()
    if (!gridApi.current) return

    const jsonData = event.dataTransfer.getData('application/json')
    const data = JSON.parse(jsonData)

    if (!data) {
      return
    }
  }

  const gridDragOver = (event) => {
    const dragSupported = event.dataTransfer.types.length

    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  function isValidNumber(input) {
    if (typeof input !== 'string' && typeof input !== 'number') return false
    if (typeof input === 'string') input = Number(input)
    return typeof input === 'number' && !isNaN(input)
  }

  function validatePastedValue(colName, newValue) {
    if (colName === 'jointLength' && (newValue < 0 || newValue > 2000)) return false
    if (colName === 'inc' && (newValue < 0 || newValue > 180)) return false
    if (colName === 'azi' && (newValue < 0 || newValue > 360)) return false
    return true
  }

  const validateUpdateValue = useCallback((curCol, newValue) => {
    const allowedColumns = ['jointLength', 'inc', 'azi']
    if (!curCol) return false
    if (!curCol.colDef.editable) return false
    if (allowedColumns.findIndex((col) => col === curCol.colDef.field) < 0) return false
    if (!isValidNumber(newValue)) return false
    if (!validatePastedValue(curCol.colDef.field, newValue)) return false
    return true
  }, [])

  const processDataFromClipboard = useCallback(
    (params) => {
      const data = cloneDeep(params.data)
      if (!Array.isArray(data)) return null
      if (data.length === 0) return null

      //Remove last row from pasted data if empty
      //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
      const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
      if (emptyLastRow) {
        data.splice(data.length - 1, 1)
      }

      //Remove commas from pasted data
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (typeof data[i][j] !== 'string') continue
          data[i][j] = data[i][j].replace(',', '')
        }
      }

      const lastIndex = gridApi.current.getDisplayedRowCount() - 1
      const focusedCell = gridApi.current.getFocusedCell()

      //Handle updates
      rowsToUpdate.current = []
      let curRowIndex = focusedCell.rowIndex
      if (focusedCell?.rowPinned !== 'bottom') {
        for (let i = 0; i < data.length; i++) {
          if (curRowIndex > lastIndex) break
          if (!Array.isArray(data[i])) continue

          let joint = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
          let currentColumn = focusedCell.column
          if (!currentColumn) continue
          let valuesUpdated = false

          for (let j = 0; j < data[i].length; j++) {
            if (!validateUpdateValue(currentColumn, data[i][j])) {
              currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
              continue
            }

            joint.data[currentColumn.colDef.field] = parseFloat(data[i][j])
            valuesUpdated = true
            currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
          }

          if (valuesUpdated) rowsToUpdate.current.push(cloneDeep(joint.data))
          curRowIndex++
        }
      }

      //Handle add new rows
      rowsToAdd.current = []
      let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
      if (startPasteIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = startPasteIndex + (data.length - 1)
        const numRowsToAdd = resultLastIndex - lastIndex

        let index = data.length - 1
        for (let i = 0; i < numRowsToAdd; i++) {
          const row = data.slice(index, index + 1)[0]
          index--

          if (!Array.isArray(row)) continue
          const newJoint = {}
          let currentColumn = focusedCell.column
          if (!currentColumn) continue

          for (let j = 0; j < row.length; j++) {
            if (!validateUpdateValue(currentColumn, row[j])) {
              currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
              continue
            }

            newJoint[currentColumn.colDef.field] = parseFloat(row[j])
            currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
          }

          if (!newJoint.hasOwnProperty('jointLength')) continue
          rowsToAdd.current.push(newJoint)
        }
      }

      if (Array.isArray(rowsToUpdate.current)) {
        for (let i = 0; i < rowsToUpdate.current.length; i++) {
          handleUpdate(rowsToUpdate.current[i])
        }
      }

      if (Array.isArray(rowsToAdd.current)) {
        handleAddPastedJoints(rowsToAdd.current)
      }

      if (focusedCell?.rowPinned === 'bottom') {
        inputRow.current = {}
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }

      return null
    },
    [handleAddPastedJoints, handleUpdate, validateUpdateValue],
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('jointGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        action: () => {
          if (params?.node) onXlsExport(selectedTally?.tallyId)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function onPasteEnd(params) {
    if (!Array.isArray(rowsToUpdate.current)) return
    if (rowsToUpdate.current.length === 0) return
    handleUpdate(cloneDeep(rowsToUpdate.current))
    rowsToUpdate.current = []

    if (!Array.isArray(rowsToAdd.current)) return
    if (rowsToAdd.current.length === 0) return
    handleUpdate(cloneDeep(rowsToAdd.current))
    rowsToAdd.current = []
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        width: `100%`,
        maxWidth: `100%`,
        maxHeight: `calc(100vh - 64px)`,
      }}>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div
          className={getAgGridTheme()}
          style={{ width: '100%', height: '100%' }}
          onDragOver={gridDragOver}
          onDrop={(e) => gridDrop(e)}>
          <AgGridReact
            loading={isLoading}
            rowData={
              Array.isArray(selectedTally?.joints)
                ? selectedTally.joints.sort((a, b) => a.sequenceNo - b.sequenceNo)
                : []
            }
            columnDefs={sortColDefs(columnDefs, 'jointGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={30}
            rowDragManaged={true}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            processDataFromClipboard={processDataFromClipboard}
            enableRangeSelection={'true'}
            onPasteEnd={onPasteEnd}
            getContextMenuItems={getContextMenuItems}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}></Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default JointGrid
