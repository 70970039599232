import React, { useRef, useMemo, useEffect, useState, useCallback } from 'react'
import { Box, IconButton, Snackbar } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import Alert from '@mui/material/Alert'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { numberWithCommasDecimals, uuidv4 } from 'utils/stringFunctions'
import { refreshSurveyProgramGridAtom } from 'atoms'
import { useRecoilState } from 'recoil'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SurveyProgramGrid = ({ wellName, isPlan }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const wellNameRef = useRef(wellName)
  const [isLoading, setLoading] = useState(false)
  const [surveyProgramData, setSurveyProgramData] = useState([])
  const inputRow = useRef({})
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const surveyProgramList = useRef([])
  const errorModelsList = useRef([])
  const surveyData = useRef([])
  const { getUnitsText } = useUnits()
  const [refreshSurveyProgramData, setRefreshSurveyProgramData] = useRecoilState(refreshSurveyProgramGridAtom)
  const { getAgGridTheme } = useInnovaTheme()

  const getErrorModels = useInnovaAxios({
    url: '/well/getErrorModelsList',
  })

  const getSurveyProgram = useInnovaAxios({
    url: isPlan ? '/plannedWell/getSurveyProgram' : '/well/getSurveyProgram',
  })

  const updateSurveyProgram = useInnovaAxios({
    url: isPlan ? '/plannedWell/updateSurveyProgram' : '/well/updateSurveyProgram',
  })

  const deleteSurveyProgram = useInnovaAxios({
    url: isPlan ? '/plannedWell/deleteSurveyProgram' : '/well/deleteSurveyProgram',
  })

  const addSurveyProgram = useInnovaAxios({
    url: isPlan ? '/plannedWell/addSurveyProgram' : '/well/addSurveyProgram',
  })

  const getAvailableSurveyHeaders = useInnovaAxios({
    url: isPlan ? '/plannedWell/getAvailableSurveyHeadersForPlan' : '/survey/getAvailableSurveyHeadersForWell',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchSurveyProgram()
    fetchErrorModels()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    wellNameRef.current = wellName
    fetchSurveyProgram()
    fetchSurveyProgramList()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refreshSurveyProgramData) {
      fetchSurveyProgram()
      setRefreshSurveyProgramData(false)
    }
  }, [refreshSurveyProgramData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSurveyProgram = async () => {
    if (isLoading) return
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    setLoading(true)
    const res = await getSurveyProgram({ wellName: wellName })

    if (res?.error) return
    if (!_isMounted.current) return

    if (!Array.isArray(res.data)) {
      setSurveyProgramData([])
      setLoading(false)
      return
    }

    let newData = []
    for (let i = 0; i < res.data.length; i++) {
      res.data[i].uid = uuidv4()
      if (res.data[i].Well === '') continue
      if (res.data[i].Survey === '') continue
      newData.push({ ...res.data[i] })
    }

    setSurveyProgramData(newData)
    surveyData.current = newData

    setLoading(false)
  }

  const fetchSurveyProgramList = async () => {
    if (!wellName) return
    if (typeof wellName !== 'string') return
    if (wellName === '') return

    const res = await getAvailableSurveyHeaders({ wellName: wellName, includeSelf: true })

    if (res?.error) return
    if (!_isMounted.current) return

    if (!Array.isArray(res.data)) return
    let svyHeaders = []
    for (let i = 0; i < res.data.length; i++) {
      svyHeaders.push({
        survey: res.data[i].survey,
        wellName: res.data[i].actualWell,
        depthFrom: res.data[i].tieOn.depthFrom,
        depthTo: res.data[i].tieOn.depthTo,
        datumElevation: res.data[i].datumElevation,
        isPlan: res.data[i].isPlan,
        ipm: res.data[i].ipm,
      })
    }

    svyHeaders.sort((a, b) => {
      if (a.depthFrom < b.depthFrom) return -1
      if (a.depthFrom > b.depthFrom) return 1
      return 0
    })

    surveyProgramList.current = svyHeaders
  }

  const fetchErrorModels = async () => {
    const res = await getErrorModels()

    if (res?.error) return
    if (!_isMounted.current) return

    errorModelsList.current = Array.isArray(res.data) ? res.data : []
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        inputRow.current = validateData(event.data, 999999, event.column.colId)
        handleAddRow()
        return
      }

      let validatedData = validateData(event.data, event.node.rowIndex, event.column.colId)
      handleUpdate(validatedData, event.node.rowIndex)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const reqFields = useMemo(() => ['DepthFrom', 'DepthTo', 'Survey', 'IPM'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [centerAlignCell, isEmptyPinnedCell, createPinnedCellPlaceholder])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('surveyProgramGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    if (inputRow.current.name === '') return
    if (!wellNameRef.current) return
    if (typeof wellNameRef.current !== 'string') return
    if (wellNameRef.current === '') return

    inputRow.current.well = wellNameRef.current
    isAdding.current = true

    let res = await addSurveyProgram({
      wellName: wellNameRef.current,
      depthFrom: inputRow.current.DepthFrom,
      depthTo: inputRow.current.DepthTo,
      well: wellNameRef.current,
      survey: inputRow.current.Survey,
      ipm: inputRow.current.IPM,
    })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])

      setTimeout(() => {
        autoSizeColumns()
      }, 100)
    }
  }, [addSurveyProgram, isPinnedRowDataCompleted])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  function extractSurveyIndexAndName(inputString) {
    // Use a regular expression to match the index and value parts
    const regex = /#(\d{1,3}):(.+)/
    const match = inputString.match(regex)

    if (match) {
      // Extract the index (as an integer) and value
      const index = parseInt(match[1], 10)
      const value = match[2].trim() // Trim to remove leading/trailing spaces

      // Return an object with the extracted values
      return { index: index - 1, survey: value }
    } else {
      // Return null if the input string doesn't match the expected format
      return null
    }
  }

  const validateData = (data, rowNum, colId) => {
    if (!data) return
    if (rowNum === undefined) return
    if (rowNum === null) return
    if (!Array.isArray(surveyProgramList.current)) return

    if (colId === 'Survey') {
      let svyIndex = extractSurveyIndexAndName(data.Survey)
      if (!svyIndex || svyIndex.index < 0 || svyIndex.index >= surveyProgramList.current.length) {
        data.DepthFrom = 0
        data.DepthTo = 0
        data.Survey = ''
        data.IPM = ''
        data.Well = ''
        return data
      }

      data.Survey = surveyProgramList.current[svyIndex.index].survey
      data.DepthFrom = surveyProgramList.current[svyIndex.index].depthFrom
      data.DepthTo = surveyProgramList.current[svyIndex.index].depthTo
      data.IPM = surveyProgramList.current[svyIndex.index].ipm
      data.Well = surveyProgramList.current[svyIndex.index].wellName
    }

    let gridData = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) gridData.push(node.data)
    })

    if (data.DepthFrom > data.DepthTo) {
      let tempDepth = data.DepthFrom
      data.DepthFrom = data.DepthTo
      data.DepthTo = tempDepth
    }

    //999999 is the row index for the pinned row
    if (Array.isArray(gridData) && gridData.length > 1) {
      let prevRowIndex = rowNum === 999999 ? gridData.length - 1 : rowNum - 1
      let nextRowIndex = rowNum + 1 //If the pinned row then this check will always fail

      if (prevRowIndex >= 0) {
        if (data.DepthFrom < gridData[prevRowIndex].DepthTo) {
          data.DepthFrom = gridData[prevRowIndex].DepthTo
        }
      }

      if (nextRowIndex < gridData.length) {
        if (data.DepthTo > gridData[nextRowIndex].DepthFrom) {
          data.DepthTo = gridData[nextRowIndex].DepthFrom
        }
      }
    }

    return data
  }

  const handleUpdate = useCallback(
    async (data, rowNum) => {
      if (!data) return
      if (rowNum === undefined) return
      if (rowNum === null) return

      if (isUpdating.current) return
      isUpdating.current = true

      let res = await updateSurveyProgram({
        wellName: wellNameRef.current,
        depthFrom: data.DepthFrom,
        depthTo: data.DepthTo,
        well: data.Well,
        survey: data.Survey,
        ipm: data.IPM,
        rowNum: rowNum,
      })
      isUpdating.current = false

      if (!_isMounted.current) return
      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!Array.isArray(res.data)) return
      if (!res?.error) {
        gridApi.current.applyTransaction({
          update: res.data,
        })

        setTimeout(() => {
          autoSizeColumns()
        }, 100)
      }
    },
    [updateSurveyProgram],
  )

  const handleDelete = useCallback(
    async (data, rowNum) => {
      if (!data) return
      if (rowNum === undefined) return
      if (rowNum === null) return

      if (isDeleting.current) return
      isDeleting.current = true
      let res = await deleteSurveyProgram({ wellName: wellNameRef.current, rowNum: rowNum })
      isDeleting.current = false

      if (res?.error && res?.error?.response?.data?.error !== 'no survey program') {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          remove: [data],
        })
      }
    },
    [deleteSurveyProgram],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {params.data?.Well === wellNameRef.current ? (
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom' ? handleDelete(params.data, params.node.rowIndex) : handleAddRow()
              }
              size='large'>
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            </IconButton>
          ) : (
            <Box sx={{ width: '15px', height: '15px' }}>ST</Box>
          )}
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'DepthFrom',
        colId: 'DepthFrom',
        headerName: `Depth From (${getUnitsText(UNITS_FOR.Depth)})`,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return true
          return params.data?.Well === wellNameRef.current
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Depth From...'
          return params.value !== null && params.value !== undefined ? numberWithCommasDecimals(params.value, 2) : ''
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'DepthTo',
        colId: 'DepthTo',
        headerName: `Depth To (${getUnitsText(UNITS_FOR.Depth)})`,
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return true
          return params.data?.Well === wellNameRef.current
        },
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Depth To...'
          return params.value !== null && params.value !== undefined ? numberWithCommasDecimals(params.value, 2) : ''
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'Survey',
        colId: 'Survey',
        headerName: 'Survey',
        editable: (params) => {
          if (params.node?.rowPinned === 'bottom') return true
          return params.data?.Well === wellNameRef.current
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(surveyProgramList.current)
              ? surveyProgramList.current.map((item, index) => `#${index + 1}:${item.survey}`)
              : [],
          }
        },
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
      },
      {
        field: 'Well',
        colId: 'Well',
        headerName: 'Well',
        editable: false,
        valueFormatter: (params) => unescapeHtml(params.data?.Well),
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
      },
      {
        field: 'IPM',
        colId: 'IPM',
        headerName: 'IPM',
        editable: (params) => {
          return params.data?.Well === params.data?.Survey //Editable only for plans
        },
        cellStyle: (params) => {
          return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(errorModelsList.current) ? errorModelsList.current.map((item) => item) : [],
          }
        },
      },
    ],
    [actionIconRenderer, isEmptyPinnedCell, getUnitsText],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItmes: 'center',
        flexDirection: 'column',
        height: '100%',
        marginLeft: 0,
        width: '100%',
        maxWidth: `100%`,
        maxHeight: `100%`,
      }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}></Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={surveyProgramData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'surveyProgramGrid')}
            defaultColDef={defaultColDef}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            rowSelection={'single'}
            headerHeight={30}
            onGridReady={onGridReady}
            getRowId={getRowId}
            onFirstDataRendered={onFirstDataRendered}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}></Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default SurveyProgramGrid
