import React from 'react'
import { Dialog } from '@mui/material'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { Box } from '@mui/material'
import EngineeringReportGrid from './EngineeringReportGrid'
import { saveItemToLS } from 'utils/localStorage'

const EngineeringReportSettingsModal = ({ onClose, reportData, selectedChart }) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='md'
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`PDF Print Settings`}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <EngineeringReportGrid engineeringDashboardData={reportData} selectedChart={selectedChart} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            saveItemToLS('engineeringReportDetails', 'engineeringReportDetails', reportData)
            onClose(false)
          }}
          color='primary'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default EngineeringReportSettingsModal
