import { useEffect, useState, useRef } from 'react'
import useAxiosGzip from './useAxiosGzip'
import { onlyShowFollowingWellsAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import useObjectsOfInterest from 'components/common/hooks/useObjectsOfInterest'

const useLogisticsOverview = (allWells) => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [logisticsData, setLogisticsData] = useState([])
  const onlyShowFollowing = useRecoilValue(onlyShowFollowingWellsAtom)
  const { objectsOfInterest, hasObjectsOfInterest } = useObjectsOfInterest()

  const fetch = useAxiosGzip({
    url: '/logisticsOverview',
  })

  const shouldOnlyShowFollowing = () => {
    if (hasObjectsOfInterest() && onlyShowFollowing) return true
    return false
  }

  useEffect(() => {
    _isMounted.current = true
    fetchLogisticsData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchLogisticsData()
  }, [allWells, onlyShowFollowing]) // eslint-disable-line react-hooks/exhaustive-deps

  const filterLogisticsData = (data) => {
    if (!shouldOnlyShowFollowing()) return data

    let filteredData = []
    if (
      objectsOfInterest &&
      objectsOfInterest.hasOwnProperty('rigs') &&
      objectsOfInterest.hasOwnProperty('districts') &&
      objectsOfInterest.hasOwnProperty('operators') &&
      Array.isArray(objectsOfInterest.rigs) &&
      Array.isArray(objectsOfInterest.districts) &&
      Array.isArray(objectsOfInterest.operators)
    ) {
      for (let i = 0; i < data.length; i++) {
        let opIndex = objectsOfInterest.operators.findIndex((element) => element === data[i].operator)
        let districtIndex = objectsOfInterest.districts.findIndex((element) => element === data[i].district)
        let rigIndex = objectsOfInterest.rigs.findIndex((element) => element === data[i].rig)
        if (opIndex >= 0 || rigIndex >= 0 || districtIndex >= 0) {
          filteredData.push({ ...data[i] })
        }
      }
    }

    return filteredData
  }

  const fetchLogisticsData = async () => {
    if (isLoading) return

    if (_isMounted.current) setIsLoading(true)
    const response = await fetch({ getAll: allWells, allTools: allWells })
    if (_isMounted.current) setIsLoading(false)

    if (response?.error) {
      return
    }

    if (response?.data) {
      if (_isMounted.current) setLogisticsData(filterLogisticsData(response.data))
    }
  }

  return [logisticsData, isLoading, fetchLogisticsData]
}

export default useLogisticsOverview
