import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SaveWpcGrid = forwardRef(({ template }, ref) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  useImperativeHandle(ref, () => ({
    stopEditing: () => {
      if (gridApi.current) gridApi.current.stopEditing()
    },
    getData: () => {
      let gridData = []
      gridApi.current?.forEachNode((node) => {
        if (node.data) gridData.push(node.data)
      })
      return gridData
    },
  }))

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'uid',
        colId: 'uid',
        hide: true,
      },
      {
        headerName: 'Well Name',
        field: 'actualWell',
        colId: 'actualWell',
        minWidth: 150,
        editable: false,
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Template Name',
        field: 'name',
        colId: 'name',
        flex: 1,
        editable: true,
        cellStyle: leftAlignCell,
      },
    ],
    [leftAlignCell],
  )

  const getRowData = useCallback(() => {
    let rowData = []
    if (!template) return rowData

    rowData.push({
      uid: template.uid,
      actualWell: template.actualWell,
      name: template.name,
    })

    return rowData
  }, [template])

  return (
    <Box className='gridbox' sx={{ display: 'flex', minHeight: '80px', height: '100%', width: '100%' }}>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          ref={gridApi}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getRowData()}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          rowSelection={'single'}
        />
      </div>
    </Box>
  )
})

export default SaveWpcGrid
