import { useEffect, useState, useRef } from 'react'

import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'

function useEngineering() {
  const _isMounted = useRef(true)
  const initLoadRef = useRef(false)
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)
  const isDeleting = useRef(false)
  const isDeletingGeo = useRef(false)
  const isGeoUpdating = useRef(false)
  const isCaseUpdating = useRef(false)
  const isFluidUpdating = useRef(false)
  const isAdding = useRef(false)
  const isAddingGeo = useRef(false)
  const isLoading = useRef(false)
  const engCases = useRef([])
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)

  const getEngCases = useAxiosGzip({
    url: '/engineering/getEngCaseGz',
  })

  const createEngCase = useInnovaAxios({
    url: '/engineering/addEngCase',
  })

  const removeEngCase = useInnovaAxios({
    url: '/engineering/deleteEngCase',
  })

  const updateEngCase = useInnovaAxios({
    url: '/engineering/updateEngCase',
  })

  const updateEngFluid = useInnovaAxios({
    url: '/engineering/updateFluid',
  })

  const createWellGeoHeader = useInnovaAxios({
    url: '/engineering/addWellGeoHeader',
  })

  const removeWellGeoHeader = useInnovaAxios({
    url: '/engineering/deleteWellGeoHeader',
  })

  const updateWellGeoHeaderDesc = useInnovaAxios({
    url: '/engineering/updateWellGeoHeader',
  })

  const updateCaseEngCaseDesc = useInnovaAxios({
    url: '/engineering/updateEngCaseDesc',
  })

  const duplicateEngCase = useInnovaAxios({
    url: '/engineering/duplicateEngCase',
  })

  const updateWellGeoHeader = async (data) => {
    if (!data) return null
    if (isGeoUpdating.current) return null
    if (typeof currentWellRef.current !== 'string') return null
    if (currentWellRef.current === '') return null

    let payload = { actualWell: currentWellRef.current, typeDesc: data.typeDesc, recordId: data.recordId }

    isGeoUpdating.current = true
    let response = await updateWellGeoHeaderDesc(payload)
    isGeoUpdating.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  const updateCaseDesc = async (data) => {
    if (!data) return null
    if (isCaseUpdating.current) return null
    if (typeof currentWellRef.current !== 'string') return null
    if (currentWellRef.current === '') return null

    let payload = { wellName: currentWellRef.current, caseDesc: data.caseDesc, recordId: data.recordId }

    isCaseUpdating.current = true
    let response = await updateCaseEngCaseDesc(payload)
    isCaseUpdating.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    await fetchEngCases()
    return { isError: false, message: '' }
  }

  async function addWellGeoHeader(desc) {
    if (isAddingGeo.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isAddingGeo.current = true

    if (typeof desc !== 'string') desc = 'New Geometry'

    const response = await createWellGeoHeader({ actualWell: currentWellRef.current, typeDesc: desc })
    isAddingGeo.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  async function deleteWellGeoHeader(geoId) {
    if (typeof geoId !== 'number') return null
    if (geoId < 0) return null
    if (isDeletingGeo.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isDeletingGeo.current = true

    const response = await removeWellGeoHeader({ actualWell: currentWellRef.current, recordId: geoId })
    if (_isMounted.current) isDeletingGeo.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    return { isError: false, message: '' }
  }

  useEffect(() => {
    _isMounted.current = true
    currentWellRef.current = currentWell
    fetchEngCases()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    currentWellRef.current = currentWell
    setInitialLoadComplete(false)
    initLoadRef.current = false
    fetchEngCases()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateCase = async (data) => {
    if (!data) return
    if (!Array.isArray(data)) return
    if (isCaseUpdating.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return

    let payload = { wellName: currentWellRef.current, autoBitTq: false }
    for (let i = 0; i < data.length; i++) {
      if (data[i].group === 'Mud') continue
      payload[data[i].tag] = data[i].value
    }

    isCaseUpdating.current = true
    let response = await updateEngCase(payload)
    if (_isMounted.current) isCaseUpdating.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    
    await fetchEngCases()
    return { isError: false, message: '' }
  }

  const updateFluid = async (data) => {
    if (!data) return
    if (!Array.isArray(data)) return
    if (isFluidUpdating.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return

    let payload = { wellName: currentWellRef.current }
    for (let i = 0; i < data.length; i++) {
      if (data[i].group !== 'Mud' && data[i].tag !== 'recordId') continue
      if (data[i].tag !== 'recordId') payload[data[i].tag] = data[i].value
      if (data[i].tag === 'recordId') payload.engCase = data[i].value
    }

    isFluidUpdating.current = true
    let response = await updateEngFluid(payload)
    if (_isMounted.current) isFluidUpdating.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    await fetchEngCases()
    return { isError: false, message: '' }
  }

  function getEngCase(engCaseId) {
    if (typeof engCaseId !== 'number') return null
    if (!Array.isArray(engCases.current)) return null
    if (engCases.current.length === 0) return null
    if (engCaseId < 0) return null

    for (let i = 0; i < engCases.current.length; i++) {
      if (engCases.current[i].recordId === engCaseId) {
        return engCases.current[i]
      }
    }

    return null
  }

  function getInitialCaseId() {
    if (!Array.isArray(engCases.current)) return -1
    if (engCases.current.length === 0) return -1

    for (let i = 0; i < engCases.current.length; i++) {
      if (engCases.current[i].active) return engCases.current[i].recordId
    }

    return engCases.current[0].recordId
  }

  function getEngCaseIds() {
    if (!Array.isArray(engCases.current)) return []
    if (engCases.current.length === 0) return []

    let id = []
    for (let i = 0; i < engCases.current.length; i++) {
      id.push(engCases.current[i].recordId)
    }

    return id
  }

  const fetchEngCases = async () => {
    if (isLoading.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isLoading.current = true

    const response = await getEngCases({ wellName: currentWellRef.current, getAll: true })
    if (_isMounted.current) {
      engCases.current = Array.isArray(response.data) ? response.data : []
      isLoading.current = false

      if (!initLoadRef.current) {
        setInitialLoadComplete(true)
        initLoadRef.current = true
      }
    }
  }

  async function addEngCase() {
    if (isAdding.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isAdding.current = true

    const response = await createEngCase({ wellName: currentWellRef.current, caseDesc: 'New engineering case' })
    if (_isMounted.current) isAdding.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    await fetchEngCases()
    return { isError: false, message: '' }
  }

  async function copyEngCase(engCase) {
    if (!engCase) return
    if (!engCase.hasOwnProperty('caseId')) return
    if (!engCase.hasOwnProperty('wellName')) return
    if (isAdding.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isAdding.current = true

    const response = await duplicateEngCase({ orgWellName: engCase.wellName, wellName: currentWellRef.current, recordId: engCase.caseId })
    if (_isMounted.current) isAdding.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    await fetchEngCases()
    return { isError: false, message: '' }
  }

  async function deleteEngCase(engCaseId) {
    if (typeof engCaseId !== 'number') return null
    if (engCaseId < 0) return null
    if (isDeleting.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    isDeleting.current = true

    const response = await removeEngCase({ wellName: currentWellRef.current, recordId: engCaseId })
    if (_isMounted.current) isDeleting.current = false
    if (response.error) return { isError: true, message: response?.error?.response?.data?.error }
    await fetchEngCases()
    return { isError: false, message: '' }
  }

  return {
    isLoading,
    getEngCase,
    addEngCase,
    deleteEngCase,
    getInitialCaseId,
    initialLoadComplete,
    getEngCaseIds,
    updateCase,
    updateFluid,
    addWellGeoHeader,
    deleteWellGeoHeader,
    updateCaseDesc,
    updateWellGeoHeader,
    copyEngCase
  }
}

export default useEngineering
