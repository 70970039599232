import React, { useEffect, useRef } from 'react'

import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { makeStyles } from '@mui/styles'

const useListStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '100%',
    borderRadius: '4px',
    border: '1px solid #000',
    width: '100%',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      WebkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
}))

const SectionVirtualList = ({ listItems, renderItem, itemSize }) => {
  const _isMounted = useRef(false)
  const listRef = useRef()
  const classes = useListStyles()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  return (
    <React.Fragment>
      <div className={classes.listContainer} ref={listRef}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={classes.fixedListContainer}
              height={height}
              width={width}
              itemCount={Array.isArray(listItems) ? listItems.length : 0}
              itemSize={itemSize}>
              {renderItem}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    </React.Fragment>
  )
}

export default SectionVirtualList
