import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { currentPageAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { actionBarWidthAtom, userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Tooltip, IconButton, Snackbar, Alert } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import SearchBar from 'components/common/SearchBar'
import { saveItemToLS, getItemFromLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import { appColors, daysSinceActive, getDaysText } from 'utils'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useOrgIcons from 'components/common/hooks/useOrgIcons'
import { Avatar } from '@mui/material'
import { styled } from '@mui/styles'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import PopupCellRenderer from 'components/WellPages/DailyReportsPages/PopupCellRenderer'
import useUnits from 'components/common/hooks/useUnits'
import PickListVirtualDialog from 'components/common/PickListVirtualDialog'
import InputModal from 'components/common/InputModal'
import { cloneDeep } from 'lodash'
import * as yup from 'yup'
import UserCharts from './UsersCharts'
import MultiUserImportModal from './MultiUserImportModal'
import { debounce } from 'lodash'
import AlertsModal from 'components/Alerts/AlertsModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconContainer = styled(Box)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
  margin: '5px',
  marginRight: '10px',
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
})

const StyledMenuIcon = styled(Iconify)({
  color: appColors.itemTextColor,
  width: '30px',
  height: '30px',
})

const getInitialGroupRows = () => {
  let storedValue = getItemFromLS('userGridGroupRows', 'groupRows')
  if (storedValue === null || storedValue === undefined) return true
  return storedValue
}

const UsersPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const _isMounted = useRef(false)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const gridApi = useRef(null)
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [userData, setUserData] = useState([])
  const [orgList, setOrgList] = useState([])
  const [searchText, setSearchText] = useState('')
  const inputRow = useRef({})
  const { getCompanyIcon } = useOrgIcons()
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const [groupRows, setGroupRows] = useState(getInitialGroupRows())
  const selectedUser = useRef(null)
  const [showMultiUserImport, setShowMultiUserImport] = useState(false)
  const [showPickOperators, setShowPickOperators] = useState(false)
  const [showPickWells, setShowPickWells] = useState(false)
  const [operatorList, setOperatorList] = useState({ operators: [], selectedOperators: [] })
  const [wellsList, setWellsList] = useState({ wells: [], selectedWells: [] })
  const { unitList } = useUnits()
  const [roleList, setRoleList] = useState([])
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [showAlerts, setShowAlerts] = useState(false)

  const getData = useInnovaAxios({
    url: '/admin/users',
  })

  const addData = useInnovaAxios({
    url: '/admin/createUser',
  })

  const updateData = useInnovaAxios({
    url: '/admin/updateUser',
  })

  const deleteData = useInnovaAxios({
    url: '/admin/deleteUser',
  })

  const getOrgData = useInnovaAxios({
    url: '/admin/getOrgListByUserAuthType',
  })

  const getRoles = useInnovaAxios({
    url: '/admin/user/roles',
  })

  const getOperatorAccess = useInnovaAxios({
    url: '/admin/getOperatorAccess',
  })

  const updateOperatorAccess = useInnovaAxios({
    url: '/admin/updateOperatorAccess',
  })

  const getOperators = useInnovaAxios({
    url: '/admin/getOperatorList',
  })

  const getWells = useInnovaAxios({
    url: '/admin/getWellList',
  })

  const getWellAccess = useInnovaAxios({
    url: '/admin/getWellAccess',
  })

  const updateAccessWells = useInnovaAxios({
    url: '/admin/updateWellAccess',
  })

  const resetUserPwd = useInnovaAxios({
    url: '/admin/resetUserPwd',
  })

  const resetUserMfa = useInnovaAxios({
    url: '/admin/resetUserMfa',
  })

  const updateUserMfa = useInnovaAxios({
    url: '/admin/setupUserMfa',
  })

  const updateUserApi = useInnovaAxios({
    url: '/admin/setupUserApi',
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.adminUsersKey)
    fetchData()
    fetchOrganizationsList()
    fetchRoleData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      //Timeout is required to stop a flush sync error
      if (gridApi.current) gridApi.current.refreshCells()
    }, 100)
  }, [getCompanyIcon]) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      //this is the primary key as it never changes even if the user name changes
      //bit of a hack because if you change the user name then the email will be different from the username
      return getStringId(params.data?.email)
    }
  }, [])

  const onClickToggleMfa = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('mfaEnabled')) return

    data.mfaEnabled = !data.mfaEnabled

    const res = await updateUserMfa({
      authUserName: data.userName,
      use_mfa: data.mfaEnabled,
    })

    if (_isMounted.current) {
      setStatus({
        show: true,
        severity: res?.error ? 'error' : 'success',
        message: res?.error
          ? `${res?.error?.response?.data?.error}`
          : `${data.userName} mfa ${data.mfaEnabled ? 'enabled' : 'disabled'}`,
      })
    }

    if (!res?.error && gridApi.current) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const onClickToggleApi = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('apiEnabled')) return

    data.apiEnabled = !data.apiEnabled
    if (data.apiEnabled) data.mfaEnabled = false

    const res = await updateUserApi({
      authUserName: data.userName,
      apiEnabled: data.apiEnabled,
    })

    if (_isMounted.current) {
      setStatus({
        show: true,
        severity: res?.error ? 'error' : 'success',
        message: res?.error
          ? `${res?.error?.response?.data?.error}`
          : `${data.userName} api ${data.apiEnabled ? 'enabled' : 'disabled'}`,
      })
    }

    if (!res?.error && gridApi.current) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const fetchOrganizationsList = async () => {
    const response = await getOrgData()
    if (response.error && _isMounted.current) {
      return
    }

    if (_isMounted.current && Array.isArray(response.data)) {
      let orgs = [...response.data]
      orgs.sort((firstEl, secondEl) => firstEl.organization.localeCompare(secondEl.organization))
      setOrgList(orgs)
    }
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const response = await getData()
    if (response.error && _isMounted.current) {
      setLoading(false)
      return
    }

    if (!Array.isArray(response.data)) return

    let sortedUsers = cloneDeep(response.data)
    sortedUsers.sort(
      (firstEl, secondEl) =>
        firstEl.organization.localeCompare(secondEl.organization) || firstEl.userName.localeCompare(secondEl.userName),
    )

    if (_isMounted.current) {
      setUserData(sortedUsers)
      setLoading(false)
    }
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    }),
    [],
  )

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
        handleAddRow()
      }
      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate(event.data)
      }
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    onExpandOrCollapseAll: () => {
      autoSizeColumns()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  function createPinnedCellPlaceholder({ colDef }) {
    let excludedFields = ['createdAt', 'lastLogin', 'active', 'authType']
    if (excludedFields.findIndex((field) => field === colDef.field) >= 0) return ''
    return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
  }

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    expandAllNodes('expand')
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('userAdminGrid', 'colLayout', colLayout)
    }
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('userAdminGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: groupRows ? 'Ungroup Rows' : 'Group Rows',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('userAdminGrid', 'colLayout', null)
          saveItemToLS('userGridGroupRows', 'groupRows', !groupRows)
          setGroupRows(!groupRows)
        },
        icon: '<span class="iconify" data-icon="mdi:format-list-group" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Expand all',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="material-symbols:expand" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse all',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="pajamas:collapse" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Users.xlsx',
            sheetName: 'Users',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  function isPinnedRowDataCompleted() {
    let requiredFields = ['organization', 'userName', 'roleId', 'unitPrefs', 'authType']
    for (let i = 0; i < requiredFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(requiredFields[i])) return false
    }

    if (inputRow.current.authType === 'Innova' && !inputRow.current.hasOwnProperty('password')) {
      return false
    }

    if (inputRow.current.authType === 'Innova' && !inputRow.current.hasOwnProperty('passwordResetRequired')) {
      return false
    }

    return true
  }

  const numberOfUsersForOrg = (organization) => {
    if (!organization) return 0
    if (typeof organization !== 'string') return 0

    if (!gridApi.current) return 0

    let userCount = 0
    gridApi.current.forEachNode((rowNode) => {
      if (rowNode.data?.organization === organization) userCount++
    })

    return userCount
  }

  const handleDelete = async () => {
    if (!selectedUser.current) return
    if (!selectedUser.current.hasOwnProperty('userName')) return

    if (numberOfUsersForOrg(selectedUser.current.organization) <= 1) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Cannot delete last user for ${selectedUser.current.organization}`,
      })

      selectedUser.current = null
      return
    }

    if (isDeleting.current) return
    isDeleting.current = true

    let res = await deleteData({
      authUserName: selectedUser.current.userName,
    })

    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      selectedUser.current = null
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedUser.current],
      })

      setStatus({
        show: true,
        severity: 'success',
        message: `${selectedUser.current.userName} deleted`,
      })
    }

    selectedUser.current = null
  }

  const handleResetMfa = useCallback(async () => {
    const payload = {
      authUserName: selectedUser.current.userName,
    }

    const res = await resetUserMfa(payload)
    if (_isMounted.current) {
      setStatus({
        show: true,
        severity: res?.error ? 'error' : 'success',
        message: res?.error
          ? `${res?.error?.response?.data?.error}`
          : `${selectedUser.current.userName} mfa device reset`,
      })
    }
  }, [resetUserMfa])

  const handleAddRow = async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return

    inputRow.current.createdAt = new Date(Date.now()).toISOString()

    //This is required so it passes the api input validation but will not be used
    //Passwords for non innova auth handled by customer auth provider
    if (inputRow.current.authType !== 'Innova') {
      inputRow.current.password = 'notInnovaAuthPassword'
    }

    let payLoad = cloneDeep(inputRow.current)
    payLoad.newUserName = payLoad.userName
    delete payLoad.userName //Required so that the logged in user name is added to the api call

    isAdding.current = true

    let res = await addData(payLoad)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `${inputRow.current.userName} added`,
    })

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('userName')) return

    let payLoad = cloneDeep(data)
    payLoad.updateUserName = payLoad.userName
    delete payLoad.userName //Required so that the logged in user name is added to the api call

    if (isUpdating.current) return
    isUpdating.current = true
    let res = await updateData(payLoad)
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: [data],
      })
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={params.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
          {params.node?.rowPinned !== 'bottom' && !groupRows ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '25px' }}>
              {params.node.rowIndex + 1}
            </Box>
          ) : null}
        </Box>
      </React.Fragment>
    )
  }

  const GroupRowInnerRenderer = (props) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}>
          <Avatar
            alt={props.node.key}
            sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
            src={getCompanyIcon(props.node.key)}
          />
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
      </Box>
    )
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    checkbox: false,
  }


  const getOperatorsForUser = useCallback(async () => {
    let newOpList = { operators: [], selectedOperators: [] }

    if (!selectedUser.current) {
      setOperatorList(newOpList)
      return
    }

    let res = await getOperators({
      accessUser: selectedUser.current.userName,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get operators: ${res?.error?.response?.data?.error}`,
      })
      setOperatorList(newOpList)
      return
    }

    if (Array.isArray(res.data)) {
      for (let i = 0; i < res.data.length; i++) {
        newOpList.operators.push({ id: res.data[i], desc: res.data[i] })
      }
    }

    res = await getOperatorAccess({
      accessUser: selectedUser.current.userName,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get operators access: ${res?.error?.response?.data?.error}`,
      })
      setOperatorList(newOpList)
      return
    }

    newOpList.selectedOperators = Array.isArray(res.data) ? cloneDeep(res.data) : []
    setOperatorList(newOpList)
  }, [getOperatorAccess, getOperators])

  const getWellsForUser = useCallback(async () => {
    let newWellList = { wells: [], selectedWells: [] }

    if (!selectedUser.current) {
      setWellsList(newWellList)
      return
    }

    let res = await getWells({
      accessUser: selectedUser.current.userName,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get wells: ${res?.error?.response?.data?.error}`,
      })
      setWellsList(newWellList)
      return
    }

    if (Array.isArray(res.data)) {
      for (let i = 0; i < res.data.length; i++) {
        newWellList.wells.push({ id: res.data[i].wellName, desc: res.data[i].wellName })
      }
    }

    res = await getWellAccess({
      accessUser: selectedUser.current.userName,
    })

    if (!_isMounted.current) return

    if (res.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `Get well access: ${res?.error?.response?.data?.error}`,
      })
      setWellsList(newWellList)
      return
    }

    newWellList.selectedWells = Array.isArray(res.data) ? cloneDeep(res.data) : []
    setWellsList(newWellList)
  }, [getWellAccess, getWells])

  const onClickResetMfa = useCallback(async () => {
    setConfirm({
      show: true,
      title: 'Reset MFA',
      text: `Are you sure you want to reset MFA device for user ${selectedUser.current.userName}?`,
      onConfirm: handleResetMfa,
    })
  }, [handleResetMfa])

  const handleMenuClick = useCallback(
    async (action, data) => {
      if (typeof action !== 'string') return
      if (!data) return

      selectedUser.current = data

      if (action === 'operators') {
        await getOperatorsForUser()
        setShowPickOperators(true)
      }

      if (action === 'wells') {
        await getWellsForUser()
        setShowPickWells(true)
      }

      if (action === 'password') {
        setShowResetPassword(true)
      }

      if (action === 'mfa') {
        onClickResetMfa()
      }

      if (action === 'alerts') {
        setShowAlerts(true)
      }
    },
    [getOperatorsForUser, getWellsForUser, onClickResetMfa],
  )

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const getLoginColor = (data) => {
    if (!data) return appColors.itemTextColor
    if (!data.lastLogin) return appColors.itemTextColor
    if (typeof data.lastLogin !== 'string') return appColors.itemTextColor
    if (data.lastLogin === '') return appColors.itemTextColor

    let deltaDays = daysSinceActive(data.lastLogin)
    return deltaDays < 14 ? appColors.rotateColor : deltaDays < 30 ? 'orange' : 'tomato'
  }

  const getFirstRoleForOrg = (organization) => {
    if (!organization) return -1
    if (typeof organization !== 'string') return -1

    if (!Array.isArray(roleList)) return -1
    let role = roleList.find((r) => r.organization === organization)
    if (!role) return -1
    return role.id
  }

  const getFirstUnitSetForOrg = (organization) => {
    if (!organization) return -1
    if (typeof organization !== 'string') return -1

    if (!Array.isArray(unitList)) return -1
    let units = unitList.find((u) => u.organization === organization)
    if (!units) return -1
    return units.label
  }

  const getAuthTypeForOrg = (organization) => {
    if (!organization) return 'Innova'
    if (typeof organization !== 'string') return 'Innova'

    if (!Array.isArray(orgList)) return 'Innova'
    let org = orgList.find((o) => o.organization === organization)
    if (!org) return 'Innova'
    return org.authType
  }

  const isMfaEnabledForOrg = (organization) => {
    if (!organization) return false
    if (typeof organization !== 'string') return false

    if (!Array.isArray(orgList)) return false
    let org = orgList.find((o) => o.organization === organization)
    if (!org) return false
    return org.mfaEnabled
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: '',
        field: 'menu',
        colId: 'menu',
        cellStyle: centerAlignCell,
        cellRenderer: PopupCellRenderer,
        cellRendererParams: (params) => {
          let options = {
            showBottomRow: false,
            menuItems: [
              {
                label: 'Operator Access',
                action: 'operators',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='mdi:barrel' />
                  </StyledIconContainer>
                ),
              },
              {
                label: 'Well Access',
                action: 'wells',
                onClick: handleMenuClick,
                icon: () => (
                  <StyledIconContainer>
                    <StyledMenuIcon icon='game-icons:oil-pump' />
                  </StyledIconContainer>
                ),
              },
            ],
          }

          if (params.data?.authType === 'Innova' && params.data?.mfaEnabled) {
            options.menuItems.push({
              label: 'Reset MFA device',
              action: 'mfa',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='ic:round-security' />
                </StyledIconContainer>
              ),
            })
          }

          if (params.data?.authType === 'Innova') {
            options.menuItems.push({
              label: 'Reset Password',
              action: 'password',
              onClick: handleMenuClick,
              icon: () => (
                <StyledIconContainer>
                  <StyledMenuIcon icon='mdi:password-reset' />
                </StyledIconContainer>
              ),
            })
          }

          options.menuItems.push({
            label: 'Alerts',
            action: 'alerts',
            onClick: handleMenuClick,
            icon: () => (
              <StyledIconContainer>
                <StyledMenuIcon icon='vaadin:bell' />
              </StyledIconContainer>
            ),
          })

          return options
        },
        pinned: 'left',
        lockPosition: 'left',
        editable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 70,
      },
      {
        field: 'icon',
        colId: 'icon',
        headerName: '',
        width: 60,
        hide: groupRows,
        resizable: false,
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellRenderer: (params) => {
          if (!params) return null
          if (!params.data) return null
          if (typeof params.data?.organization !== 'string') return null

          return (
            <Avatar
              alt={params?.data?.organization}
              sx={{ width: '30px', height: '30px', marginLeft: '1px' }}
              src={getCompanyIcon(params?.data?.organization)}
            />
          )
        },
      },
      {
        field: 'organization',
        colId: 'organization',
        headerName: 'Organization',
        rowGroup: groupRows,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(orgList) ? orgList.map((org) => org.organization) : [],
          }
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return

          if (params.node?.rowPinned === 'bottom') {
            inputRow.current.organization = params.newValue
            inputRow.current.roleId = getFirstRoleForOrg(params.newValue)
            inputRow.current.unitPrefs = getFirstUnitSetForOrg(params.newValue)
            inputRow.current.authType = getAuthTypeForOrg(params.newValue)
            inputRow.current.mfaEnabled = isMfaEnabledForOrg(params.newValue)
            inputRow.current.apiEnabled = false
            inputRow.current.active = true
            return true
          }

          let newData = cloneDeep(params.data)
          newData.organization = params.newValue
          newData.roleId = getFirstRoleForOrg(params.newValue)
          newData.unitPrefs = getFirstUnitSetForOrg(params.newValue)
          newData.authType = getAuthTypeForOrg(params.newValue)
          newData.mfaEnabled = isMfaEnabledForOrg(params.newValue)
          if (newData.authType !== 'Innova') {
            newData.apiEnabled = false
          }
          handleUpdate(newData)
          return true
        },
      },
      {
        field: 'userName',
        colId: 'userName',
        headerName: 'User Name',
        editable: true,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: appColors.itemTextColor,
        },
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return

          if (typeof params.newValue !== 'string') return
          if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(params.newValue)) {
            setStatus({ show: true, severity: 'error', message: 'not a valid email address' })
            return false
          }

          if (params.node?.rowPinned === 'bottom') {
            inputRow.current.userName = params.newValue
            inputRow.current.email = params.newValue
            return
          }

          let newData = cloneDeep(params.data)
          newData.userName = params.newValue
          handleUpdate(newData)
          return
        },
      },
      {
        field: 'password',
        colId: 'password',
        headerName: 'Password',
        cellStyle: centerAlignCell,
        editable: (params) => {
          if (params?.node?.rowPinned !== 'bottom') return false
          if (inputRow.current?.authType !== 'Innova') return false
          return true
        },
        valueFormatter: (params) => {
          if (params.node?.rowPinned !== 'bottom') return '*******'
          if (inputRow.current?.authType !== 'Innova') return ''
          if (inputRow.current.password) return inputRow.current.password
          return 'Password...'
        },
        valueSetter: (params) => {
          if (typeof params.newValue !== 'string') return
          if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/.test(params.newValue)) {
            setStatus({
              show: true,
              severity: 'error',
              message:
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
            })
            return false
          }

          inputRow.current.password = params.newValue
          return true
        },
      },
      {
        field: 'roleId',
        colId: 'roleId',
        headerName: 'Role',
        minWidth: 200,
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') {
            return orgList.findIndex((org) => org.organization === inputRow.current.organization) >= 0
          }

          return true
        },
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        valueGetter: (params) => {
          if (!params.data) return ''
          if (typeof params.data?.roleId !== 'number') return ''
          if (!Array.isArray(roleList)) return ''
          let role = roleList.find((r) => r.id === params.data?.roleId)
          if (!role) return ''
          return role.name
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let org = params?.node?.rowPinned === 'bottom' ? inputRow.current.organization : params.data?.organization
          let values = []
          if (Array.isArray(roleList)) {
            roleList.forEach((role) => {
              if (role.organization === org) values.push(role.name)
            })
          }

          return {
            values: values,
          }
        },
        valueSetter: (params) => {
          let roleId = -1
          if (params.newValue && typeof params.newValue === 'string') {
            let org = params?.node?.rowPinned === 'bottom' ? inputRow.current.organization : params.data?.organization
            if (Array.isArray(roleList)) {
              for (let i = 0; i < roleList.length; i++) {
                if (roleList[i].organization === org && roleList[i].name === params.newValue) {
                  roleId = roleList[i].id
                  break
                }
              }
            }
          }

          if (roleId < 0) return

          if (params.node?.rowPinned === 'bottom') {
            inputRow.current.roleId = roleId
            return
          }

          let newData = cloneDeep(params.data)
          newData.roleId = roleId
          handleUpdate(newData)
        },
      },
      {
        field: 'unitPrefs',
        colId: 'unitPrefs',
        headerName: 'Units',
        cellStyle: centerAlignCell,
        editable: (params) => {
          if (params?.node?.rowPinned === 'bottom') {
            return orgList.findIndex((org) => org.organization === inputRow.current.organization) >= 0
          }

          return true
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          let org = params?.node?.rowPinned === 'bottom' ? inputRow.current.organization : params.data?.organization
          let values = []
          if (Array.isArray(unitList)) {
            unitList.forEach((unit) => {
              if (unit.organization === org) values.push(unit.label)
            })
          }

          return {
            values: values,
          }
        },
      },
      {
        field: 'createdAt',
        colId: 'createdAt',
        headerName: 'Created',
        cellStyle: centerAlignCell,
        editable: false,
        valueGetter: (params) => {
          if (!params.data?.createdAt) return ''
          if (params.data?.createdAt === '') return ''
          if (isDateLessThan(params.data?.createdAt, '1990-01-01')) return ''
          return new Date(Date.parse(params.data?.createdAt)).toLocaleDateString('default', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
        },
      },
      {
        field: 'lastLogin',
        colId: 'lastLogin',
        headerName: 'Last Login',
        editable: false,
        cellStyle: (params) => {
          return {
            ...centerAlignCell,
            color: getLoginColor(params.data),
          }
        },
        valueGetter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          if (!params.data) return '-'
          if (!params.data?.lastLogin) return '-'
          if (typeof params.data?.lastLogin !== 'string') return '-'
          if (params.data?.lastLogin === '') return '-'
          return getDaysText(daysSinceActive(params.data?.lastLogin))
        },
      },
      {
        field: 'authType',
        colId: 'authType',
        headerName: 'Auth Type',
        editable: false,
        cellStyle: centerAlignCell,
        cellRenderer: (params) => {
          if (params.node?.rowPinned === 'bottom') return null

          let icon = 'simple-icons:auth0'
          if (params.data?.authType === 'Okta') icon = 'logos:okta'
          if (params.data?.authType === 'Azure AD') icon = 'devicon:azure'

          return (
            <IconButton
              style={{ backgroundColor: 'white', padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              size='large'>
              <Iconify icon={icon} fontSize={20} />
            </IconButton>
          )
        },
      },
      {
        field: 'passwordResetRequired',
        colId: 'passwordResetRequired',
        headerName: 'Pwd Reset Req',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') {
            if (inputRow.current?.authType !== 'Innova') return null
            inputRow.current.passwordResetRequired = !inputRow.current.passwordResetRequired
            handleAddRow()
            return true
          }

          if (params.data?.authType !== 'Innova') {
            setStatus({
              show: true,
              severity: 'error',
              message: `Cannot change for non Innova auth`,
            })
            return false
          }
          params.data.passwordResetRequired = !params.data.passwordResetRequired
          handleUpdate(params.data)
          return true
        },
      },
      {
        field: 'mfaEnabled',
        colId: 'mfaEnabled',
        headerName: 'MFA Enabled',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => params.data?.mfaEnabled === 1 || params.data?.mfaEnabled === true,
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') {
           return null
          }

          if (params.data?.authType !== 'Innova') {
            setStatus({
              show: true,
              severity: 'error',
              message: `Cannot change for non Innova auth`,
            })
            return false
          }

          if (params.data?.apiEnabled) {
            setStatus({
              show: true,
              severity: 'error',
              message: `Cannot change mfa for an api user`,
            })
            return false
          }

          onClickToggleMfa(params.data)
          return true
        }
      },
      {
        field: 'apiEnabled',
        colId: 'apiEnabled',
        headerName: 'API User',
        cellStyle: centerAlignCell,
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        valueGetter: (params) => params.data?.apiEnabled === 1 || params.data?.apiEnabled === true,
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') {
            return null
           }

          if (params.data?.authType !== 'Innova') {
            setStatus({
              show: true,
              severity: 'error',
              message: `Cannot change for non Innova auth`,
            })
            return false
          }

          onClickToggleApi(params.data)
          return true
        }
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        editable: true,
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
        valueSetter: (params) => {
          if (params.node?.rowPinned === 'bottom') {
           return null
          }

          params.data.active = !params.data.active
          handleUpdate(params.data)
          return true
        }
      },
    ],
    [orgList, getCompanyIcon, groupRows, centerAlignCell, roleList, handleMenuClick], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      if (node.data?.hasOwnProperty('userName')) {
        filteredNodes.push(node.data)
      }
    })

    if (_isMounted.current) {
      setFilteredData(filteredNodes)
    }
  }

  const getExistingUserNames = () => {
    if (!gridApi.current) return

    let userNames = []
    gridApi.current.forEachNode((node) => {
      if (node.data?.hasOwnProperty('userName')) {
        userNames.push(node.data.userName)
      }
    })

    return userNames
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onClickDelete = (data) => {
    if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return

    selectedUser.current = data
    setConfirm({
      show: true,
      title: 'Delete User',
      text: `Are you sure you want to delete ${data.userName}?`,
      onConfirm: handleDelete,
    })
  }

  const ResetPasswordDialog = ({ data, submitFunction }) => {
    const formValidationSchema = yup.object({
      password: yup
        .string()
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
    })

    let formData = [
      {
        tag: 'authUserName',
        value: data.userName,
        inputType: '-',
      },
      {
        tag: 'password',
        value: '',
        text: 'Password',
        inputType: 'text',
      },
    ]

    const title = `Reset Password - ${data.userName}`

    return (
      <InputModal
        open={showResetPassword}
        onClose={() => setShowResetPassword(false)}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={() => setShowResetPassword(false)}
        validationSchema={formValidationSchema}
      />
    )
  }

  const fetchRoleData = async () => {
    const res = await getRoles()
    if (res.error) return
    setRoleList(Array.isArray(res.data) ? res.data : [])
  }

  const applyPickOperator = async (selectedOperators) => {
    if (!selectedUser.current) return
    if (!selectedOperators) return
    if (Array.isArray(selectedOperators) === false) return
    if (isUpdating.current) return

    let strArray = ''
    selectedOperators.forEach((op) => {
      if (strArray !== '') strArray += '|'
      strArray += op
    })

    isUpdating.current = true
    let res = await updateOperatorAccess({
      accessUser: selectedUser.current.userName,
      operatorList: strArray,
    })
    isUpdating.current = false

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setShowPickOperators(false)
  }

  const applyPickWells = async (selectedWells) => {
    if (!selectedUser.current) return
    if (!selectedWells) return
    if (Array.isArray(selectedWells) === false) return
    if (isUpdating.current) return

    let strArray = ''
    selectedWells.forEach((well) => {
      if (strArray !== '') strArray += '|'
      strArray += well
    })

    isUpdating.current = true
    let res = await updateAccessWells({
      accessUser: selectedUser.current.userName,
      wellList: strArray,
    })
    isUpdating.current = false

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
      return
    }

    setShowPickWells(false)
  }

  const handlePasswordReset = async (data) => {
    if (!data) return
    if (!data.hasOwnProperty('authUserName')) return
    data.resetRequiredOnNextLogin = true

    isUpdating.current = true
    let res = await resetUserPwd({ ...data, sendEmail: true, incPwdInEmail: true, resetRequiredOnNextLogin: true })
    isUpdating.current = false

    if (res.error) {
      setStatus({ show: true, severity: 'error', message: `${res?.error?.response?.data?.error}` })
    }

    if (!res.error) {
      setStatus({ show: true, severity: 'success', message: `Password reset` })
    }

    selectedUser.current = null
    setShowResetPassword(false)
  }

  const handleMultiUserCreate = async (data) => {
    setShowMultiUserImport(false)

    if (!Array.isArray(data)) return
    if (data.length === 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: `No users to add`,
      })
      return
    }

    let usersStr = ''
    for (let i = 0; i < data.length; i++) {
      if (usersStr !== '') usersStr += '|'
      usersStr += data[i].userName
    }

    let payload = {
      newUserName: usersStr,
      email: data[0].email,
      organization: data[0].organization,
      roleId: data[0].roleId,
      unitPrefs: data[0].unitPrefs,
      mfaEnabled: data[0].mfaEnabled,
      apiEnabled: data[0].apiEnabled,
      passwordResetRequired: data[0].passwordResetRequired,
      active: true,
      password: data[0].password,
    }

    //This is required so it passes the api input validation but will not be used
    //Passwords for non innova auth handled by customer auth provider
    if (data[0].authType !== 'Innova') {
      payload.password = 'notInnovaAuthPassword'
    }

    isAdding.current = true
    let res = await addData(payload)
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    for (let i = 0; i < data.length; i++) {
      data[i].password = ''
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: data,
      })
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Users added`,
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 64px)',
        marginLeft: `${leftPos}px`,
        width: `calc(100%-${leftPos}px)`,
        maxWidth: `calc(100%-${leftPos}px)`,
        maxHeight: 'calc(100vh - 64px)',
      }}>
      <UserCharts userData={filteredData} />
      {showMultiUserImport ? (
        <MultiUserImportModal
          onCreateMultiUsers={handleMultiUserCreate}
          onCancel={() => setShowMultiUserImport(false)}
          existingUserNames={getExistingUserNames()}
          orgList={orgList}
          roleList={roleList}
          unitList={unitList}
        />
      ) : null}
      {showResetPassword ? (
        <ResetPasswordDialog data={selectedUser.current} submitFunction={handlePasswordReset} />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showPickOperators ? (
        <PickListVirtualDialog
          title='Select Operators'
          open={showPickOperators}
          setOpen={() => setShowPickOperators(false)}
          onApply={applyPickOperator}
          items={
            Array.isArray(operatorList.operators)
              ? operatorList.operators.sort((a, b) => a.desc.localeCompare(b.desc))
              : []
          }
          showSearch={true}
          initSelItems={operatorList.selectedOperators}
        />
      ) : null}
      {showPickWells ? (
        <PickListVirtualDialog
          title='Select Wells'
          open={showPickWells}
          rowHeight={60}
          setOpen={() => setShowPickWells(false)}
          onApply={applyPickWells}
          items={wellsList.wells}
          showSearch={true}
          initSelItems={wellsList.selectedWells}
        />
      ) : null}
      {showAlerts ? (
        <AlertsModal
          onCancel={() => setShowAlerts(false)}
          byUser={'true'}
          byRole={'true'}
          roleId={selectedUser.current?.roleId ? selectedUser.current?.roleId.toString() : ''}
          userName={selectedUser.current?.userName ? selectedUser.current?.userName : ''}
        />
      ) : null}
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            loading={isLoading}
            rowData={userData}
            columnDefs={sortColDefs(columnDefs, 'userAdminGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            headerHeight={30}
            groupDisplayType={'groupRows'}
            groupRowsSticky={true}
            groupRowRendererParams={groupRowRendererParams}
            onFilterChanged={onFilterChanged}
          />
        </div>
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          margin: '4px',
          padding: '12px',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 2,
        }}>
        <MenuButton
          actions={[
            {
              icon: <RefreshIcon />,
              name: 'Refresh',
              onClick: () => {
                fetchData()
                if (gridApi.current) gridApi.current.onFilterChanged()
              },
            },
            {
              icon: <Iconify icon='fa:users' fontSize={16} />,
              name: 'Create Multiple Users',
              onClick: () => {
                setShowMultiUserImport(true)
              },
            },
          ]}
        />
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}

export default UsersPage
