import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, IconButton, Button, Snackbar, Alert } from '@mui/material'
import { Box } from '@mui/material'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay, htmlSymbolHandling, getStringId } from 'components/common/AgGridUtils'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import MultiSelectGridEditor from 'components/common/CellEditors/MultiSelectGridEditor'
import { numberWithCommasDecimals, uuidv4 } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const InvoiceQcSettings = ({ onClose }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApiActiveDay = useRef(null)
  const inputRow = useRef({})
  const isUpdating = useRef(false)
  const settings = useRef({})
  const [isLoading, setLoading] = useState(false)
  const [isLoadingDropDowns, setLoadingDropDowns] = useState(false)
  const dropDowns = useRef({ activityCodes: [], costCodes: [], filterParams: [], units: [] })
  const [resetCols, setResetCols] = useState(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getSettings = useInnovaAxios({
    url: '/admin/invoiceQc/getInvoiceQcSettings',
  })

  const updateSettings = useInnovaAxios({
    url: '/admin/invoiceQc/updateInvoiceQcSettings',
  })

  const getDropDowns = useInnovaAxios({
    url: '/admin/invoiceQc/getInvoiceQcDropDowns',
  })

  const fetchDropDowns = async () => {
    if (isLoadingDropDowns) return
    if (!_isMounted.current) return
    setLoadingDropDowns(true)
    const res = await getDropDowns()
    if (!_isMounted.current) return
    setLoadingDropDowns(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.data) return
    dropDowns.current = res.data
  }

  useEffect(() => {
    _isMounted.current = true
    fetchDropDowns()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  function isPinnedRowDataCompleted() {
    if (!inputRow.current?.hasOwnProperty('description')) return false
    return true
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('invoiceQcSettingsGrid', 'colLayout', colLayout)
    }
  }

  const handleDelete = useCallback(
    async (data) => {
      if (!_isMounted.current) return
      if (isUpdating.current) return
      if (isLoading) return

      let newSettings = cloneDeep(settings.current)
      newSettings.invoiceQcParams = []

      gridApi.current.forEachNode((node) => {
        if (node.data?.uid !== data.uid) {
          newSettings.invoiceQcParams.push(node.data)
        }
      })

      setLoading(true)
      isUpdating.current = true
      let res = await updateSettings({ settings: JSON.stringify(htmlSymbolHandling(newSettings)) })
      isUpdating.current = false

      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!res?.error) {
        gridApi.current.applyTransaction({
          remove: [data],
        })
      }
    },
    [isLoading, updateSettings],
  )

  const handleUpdate = useCallback(async () => {
    if (!_isMounted.current) return
    if (isUpdating.current) return
    if (isLoading) return

    let newSettings = {
      invoiceQcParams: [],
      activeDayActivityCodes: { activityCodesToInclude: [], activityCodesToExclude: [] },
    }

    gridApi.current.forEachNode((node) => {
      newSettings.invoiceQcParams.push(node.data)
    })

    gridApiActiveDay.current.forEachNode((node) => {
      if (node.data?.label === 'Must include') {
        newSettings.activeDayActivityCodes.activityCodesToInclude = Array.isArray(node.data?.values)
          ? cloneDeep(node.data?.values)
          : []
      }

      if (node.data?.label === 'Must exclude') {
        newSettings.activeDayActivityCodes.activityCodesToExclude = Array.isArray(node.data?.values)
          ? cloneDeep(node.data?.values)
          : []
      }
    })

    setLoading(true)
    isUpdating.current = true
    let res = await updateSettings({ settings: JSON.stringify(htmlSymbolHandling(newSettings)) })
    isUpdating.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }
  }, [isLoading, updateSettings])

  const handleAddRow = useCallback(async () => {
    if (!_isMounted.current) return
    if (isUpdating.current) return
    if (isLoading) return
    if (!isPinnedRowDataCompleted()) return

    inputRow.current.active = true
    inputRow.current.uid = uuidv4()
    inputRow.current.costCodes = []
    inputRow.current.qty = 0
    inputRow.current.unit = 'WELL'
    inputRow.current.filterParam = ''
    inputRow.current.filterValue = ''

    let newSettings = cloneDeep(settings.current)
    newSettings.invoiceQcParams = []

    gridApi.current.forEachNode((node) => {
      newSettings.invoiceQcParams.push(node.data)
    })

    newSettings.invoiceQcParams.push(inputRow.current)

    setLoading(true)
    isUpdating.current = true
    let res = await updateSettings({ settings: JSON.stringify(htmlSymbolHandling(newSettings)) })
    isUpdating.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [updateSettings, isLoading])

  const gridOptions = {
    pinnedBottomRowData: [inputRow.current],
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
      popupParent: document.querySelector('body'),
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      if (event.node?.rowPinned !== 'bottom') {
        handleUpdate()
      }

      setTimeout(() => {
        if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
        gridApi.current?.autoSizeAllColumns()
      }, 200)
    },
  }

  const gridOptionsActiveDay = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate()

      setTimeout(() => {
        if (gridApiActiveDay.current === null) return
        gridApiActiveDay.current?.autoSizeAllColumns()
      }, 200)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('invoiceQcSettingsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            setTimeout(() => handleUpdate(), 200)
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [handleUpdate])

  const fetchSettings = async () => {
    if (!gridApi.current) return
    if (isLoading) return
    setLoading(true)

    const res = await getSettings()
    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    settings.current = res.data
    gridApi.current.setGridOption('rowData', Array.isArray(res?.data?.invoiceQcParams) ? res.data.invoiceQcParams : [])

    if (gridApiActiveDay.current) {
      let rowData = [
        {
          uid: uuidv4(),
          label: 'Must include',
          values: Array.isArray(res.data?.activeDayActivityCodes?.activityCodesToInclude)
            ? res.data.activeDayActivityCodes.activityCodesToInclude
            : [],
        },
        {
          uid: uuidv4(),
          label: 'Must exclude',
          values: Array.isArray(res.data?.activeDayActivityCodes?.activityCodesToExclude)
            ? res.data.activeDayActivityCodes.activityCodesToExclude
            : [],
        },
      ]

      gridApiActiveDay.current.setGridOption('rowData', rowData)
    }

    setTimeout(() => {
      if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
      gridApi.current?.autoSizeAllColumns()

      if (gridApiActiveDay.current === null) return
      gridApiActiveDay.current?.autoSizeAllColumns()
    }, 200)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    fetchSettings()
  }

  const onGridReadyActiveDay = (params) => {
    gridApiActiveDay.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRenderedActiveDay = (params) => {
    if (gridApiActiveDay.current === null) return
    gridApiActiveDay.current?.autoSizeAllColumns()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? handleDelete(params.data) : handleAddRow())}
            size='large'>
            {params.data?.name !== 'Default' ? (
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            ) : (
              <Box sx={{ width: '15px', height: '15px' }} />
            )}
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  let columnDefsActiveDays = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        headerName: '',
        editable: false,
      },
      {
        field: 'values',
        colId: 'values',
        cellClass: 'overflow-visible',
        headerName: 'Activity Codes',
        valueParser: (params) => {
          if (Array.isArray(params.data?.values)) return params.data?.values.join(', ')
          return ''
        },
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditor: MultiSelectGridEditor,
        cellEditorParams: (params) => {
          return {
            currentValues: Array.isArray(params.data?.values) ? params.data?.values : [],
            values: Array.isArray(dropDowns.current?.activityCodes)
              ? dropDowns.current.activityCodes.map((code) => {
                  return { code: code.code, description: code.description, uid: uuidv4() }
                })
              : [],
          }
        },
        cellEditorPopup: true,
      },
    ],
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'description',
        colId: 'description',
        headerName: 'Description',
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Description...' : unescapeHtml(params.value)),
      },
      {
        field: 'costCodes',
        colId: 'costCodes',
        headerName: 'Cost Codes',
        width: 250,
        valueParser: (params) => {
          if (Array.isArray(params.data?.costCodes)) return params.data?.costCodes.join(', ')
          return ''
        },
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditor: MultiSelectGridEditor,
        cellEditorParams: (params) => {
          return {
            currentValues: Array.isArray(params.data?.costCodes) ? params.data?.costCodes : [],
            values: Array.isArray(dropDowns.current?.costCodes)
              ? dropDowns.current.costCodes.map((code) => {
                  return { code: code.costCode, description: code.description, uid: uuidv4() }
                })
              : [],
          }
        },
        cellEditorPopup: true,
      },
      {
        field: 'qty',
        colId: 'qty',
        headerName: 'Expected Qty',
        cellStyle: centerAlignCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          return numberWithCommasDecimals(params.data?.qty, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000000,
          precision: 2,
        },
      },
      {
        field: 'unit',
        colId: 'unit',
        headerName: 'Unit',
        width: 200,
        cellStyle: centerAlignCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(dropDowns.current?.units) ? dropDowns.current?.units : [],
          }
        },
      },
      {
        field: 'filterParam',
        colId: 'filterParam',
        headerName: 'Filter',
        width: 200,
        cellStyle: centerAlignCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return {
            values: Array.isArray(dropDowns.current?.filterParams)
              ? dropDowns.current?.filterParams.map((item) => item.uid)
              : [],
          }
        },
      },
      {
        field: 'filterValue',
        colId: 'filterValue',
        headerName: 'Filter Value',
        cellStyle: centerAlignCell,
        editable: (params) => params.node?.rowPinned !== 'bottom',
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        cellEditor: 'agCheckboxCellEditor',
        editable: (params) => params.node?.rowPinned !== 'bottom',
        cellRendererSelector: (params) => {
          if (params.node?.rowPinned === 'bottom') return null
          return {
            component: 'agCheckboxCellRenderer',
          }
        },
        cellStyle: centerAlignCell,
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          params.data.active = params.newValue
          handleUpdate()
          return true
        },
      },
    ],
    [centerAlignCell, handleUpdate, actionIconRenderer],
  )

  return (
    <Dialog
      maxWidth='lg'
      open={true}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100%',
            maxHeight: '100%',
          }}>
          <DialogTitle>{`Operating Day Settings`}</DialogTitle>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '35%' }}>
            <AgGridReact
              loading={isLoading || isLoadingDropDowns}
              columnDefs={columnDefsActiveDays}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptionsActiveDay}
              headerHeight={30}
              getRowId={getRowId}
              onGridReady={onGridReadyActiveDay}
              onFirstDataRendered={onFirstDataRenderedActiveDay}
            />
          </div>
          <DialogTitle>{`QC Checks`}</DialogTitle>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '65%' }}>
            <AgGridReact
              loading={isLoading || isLoadingDropDowns}
              columnDefs={sortColDefs(columnDefs, 'invoiceQcSettingsGrid')}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              getRowId={getRowId}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </Box>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvoiceQcSettings
