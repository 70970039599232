import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'

//const LAND_RIG_URL = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/landRigSmall.obj'
//const OFFSHORE_RIG_URL = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/offshoreRigSmall.obj'
//const LAND_RIG_LARGE = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/LandRigLarge.FBX'
//const LAND_RIG_LARGE_GTLF = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/LandRigLarge.gltf'
const LAND_RIG_LARGE_GTLF_DRACO = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/LandRigLargeDraco.gltf'

function Rig({ wellData, isOffshore, display, scale }) {
  const rig = useLoader(GLTFLoader, LAND_RIG_LARGE_GTLF_DRACO, (loader) => {
    const dracoLoader = new DRACOLoader()
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/')
    loader.setDRACOLoader(dracoLoader)
  })

  if (!display) return null
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (wellData.data.length === 0) return null
  if (!rig) return null

  const offset = { x: -1.3, y: 0.5, z: -7 }
  const position = { x: wellData.data[0].x, y: wellData.data[0].y < 0 ? wellData.data[0].y : 0, z: wellData.data[0].z }
  scale = isOffshore ? scale * 2 : scale * 0.3

  let pos = [offset.x + position.x, offset.y + position.y, offset.z + position.z]

  return (
    <mesh scale={scale} position={pos}>
      <primitive object={rig.scene} />
    </mesh>
  )
}

export default Rig
