import React, { useState } from 'react'

import { Bar } from 'react-chartjs-2'
import { Box, CircularProgress } from '@mui/material'
import { appColors, chartSeriesColors } from 'utils'
import { getRandomColor } from 'utils/colorFunctions'
import DonutChartJs from 'components/common/DonutChartJs'
import { round } from 'utils/numberFunctions'
import { numberWithCommas } from 'utils/stringFunctions'
import { getDeltaIsoDays, isoDateAddition } from 'utils/dateTimeFunctions'
import Carousel from 'react-material-ui-carousel'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}

export const getRevenueByCustomerPerMonth = (inputData, formatDate) => {
  let output = {
    labels: [],
    datasets: [],
  }

  if (!inputData) return output
  if (!Array.isArray(inputData)) return output
  if (inputData.length === 0) return output

  for (let i = 0; i < inputData.length; i++) {
    if (inputData[i].status !== 'Cancelled' && inputData[i].status !== 'Draft') {
      let dt = new Date(Date.parse(inputData[i].createdDate))
      let dateStr = `${dt.getFullYear()}-${dt.getMonth() + 1 < 10 ? '0' : ''}${dt.getMonth() + 1}-01T00:01:00`
      let index = output.labels.findIndex((label) => label === dateStr)
      if (index < 0) output.labels.push(dateStr)
    }
  }

  output.labels.sort((firstEl, secondEl) =>
    new Date(Date.parse(firstEl)).getTime() > new Date(Date.parse(secondEl)).getTime() ? 1 : -1,
  )

  for (let i = 0; i < inputData.length; i++) {
    if (inputData[i].status !== 'Cancelled' && inputData[i].status !== 'Draft') {
      let dt = new Date(Date.parse(inputData[i].createdDate + 'T00:01:00'))

      let pointIndex = output.labels.findIndex((dateStr) => {
        let seriesDate = new Date(Date.parse(dateStr))
        if (seriesDate.getFullYear() === dt.getFullYear() && seriesDate.getMonth() === dt.getMonth()) {
          return true
        }

        return false
      })

      if (!Array.isArray(inputData[i].invoiceDetails)) continue
      for (let j = 0; j < inputData[i].invoiceDetails.length; j++) {
        let companyIndex = output.datasets.findIndex((dataset) => dataset.label === inputData[i].company)
        if (companyIndex < 0) {
          let newData = new Array(output.labels.length).fill(0)
          newData[pointIndex] = inputData[i].invoiceDetails[j].total
          output.datasets.push({
            label: inputData[i].company,
            backgroundColor: i < chartSeriesColors.length ? chartSeriesColors[i] : getRandomColor(),
            categoryPercentage: 0.5,
            maxBarThickness: 24,
            data: [...newData],
          })
        } else {
          output.datasets[companyIndex].data[pointIndex] += inputData[i].invoiceDetails[j].total
        }
      }
    }
  }

  if (formatDate) {
    for (let i = 0; i < output.labels.length; i++) {
      let dt = new Date(Date.parse(output.labels[i]))
      output.labels[i] = dt.toLocaleDateString('default', { year: 'numeric', month: 'long' })
    }
  }

  output.datasets.sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  })
  return output
}

const InnovaInvoiceCharts = ({ data, isLoading, dropDowns }) => {
  const [revenueChartSelection, setRevenueChartSelection] = useState('total')
  const { getChartBackColor, theme } = useInnovaTheme()
  const getCurrentMonthData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let dateTo = new Date(Date.now())
    let dateFrom = new Date(
      Date.parse(
        `${dateTo.getFullYear()}-${dateTo.getMonth() + 1 < 10 ? '0' : ''}${dateTo.getMonth() + 1}-01T00:00:00Z`,
      ),
    )
    dateFrom.setUTCDate(1)

    let collectionsData = [
      { value: 0, color: '#4CBB17', label: 'Revenue' },
      { value: 0, color: '#F59B00', label: 'Tax' },
    ]

    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== 'Cancelled' && data[i].status !== 'Draft') {
        let dt = new Date(Date.parse(data[i].createdDate)).getTime()
        if (dt < dateFrom.getTime() || dt > dateTo.getTime()) continue

        collectionsData[0].value += data[i].totalValue
        collectionsData[1].value += data[i].totalTax
      }
    }

    return collectionsData
  }

  const getCurrentYearData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let dateTo = new Date(Date.now())
    let dateFrom = new Date(Date.parse(`${dateTo.getFullYear()}-01-01T00:01:00`))

    let collectionsData = [
      { value: 0, color: '#4CBB17', label: 'Revenue' },
      { value: 0, color: '#F59B00', label: 'Tax' },
    ]

    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== 'Cancelled' && data[i].status !== 'Draft') {
        let dt = new Date(Date.parse(data[i].createdDate)).getTime()
        if (dt < dateFrom.getTime() || dt > dateTo.getTime()) continue

        collectionsData[0].value += data[i].totalValue
        collectionsData[1].value += data[i].totalTax
      }
    }

    return collectionsData
  }

  function getRevenueChartData() {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!data) return output
    if (!Array.isArray(data)) return output
    if (data.length === 0) return output

    if (Array.isArray(dropDowns.revenueCategory)) {
      for (let i = 0; i < dropDowns.revenueCategory.length; i++) {
        output.datasets.push({
          label: dropDowns.revenueCategory[i].label,
          backgroundColor: i < chartSeriesColors.length ? chartSeriesColors[i] : 0x6495ed,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        })
      }
    } else {
      output.datasets.push({
        label: 'Revenue',
        backgroundColor: '0x6495ed',
        categoryPercentage: 0.5,
        maxBarThickness: 24,
        data: [],
      })
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== 'Cancelled' && data[i].status !== 'Draft') {
        let dt = new Date(Date.parse(data[i].createdDate))
        let dateStr = `${dt.getFullYear()}-${dt.getMonth() + 1 < 10 ? '0' : ''}${dt.getMonth() + 1}-01T00:01:00`
        let index = output.labels.findIndex((label) => label === dateStr)
        if (index < 0) output.labels.push(dateStr)
      }
    }

    output.labels.sort((firstEl, secondEl) =>
      new Date(Date.parse(firstEl)).getTime() > new Date(Date.parse(secondEl)).getTime() ? 1 : -1,
    )

    for (let i = 0; i < output.datasets.length; i++) {
      for (let j = 0; j < output.labels.length; j++) {
        output.datasets[i].data.push(0)
      }
    }

    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== 'Cancelled' && data[i].status !== 'Draft') {
        let dt = new Date(Date.parse(data[i].createdDate + 'T00:01:00'))

        let pointIndex = output.labels.findIndex((dateStr) => {
          let seriesDate = new Date(Date.parse(dateStr))
          if (seriesDate.getFullYear() === dt.getFullYear() && seriesDate.getMonth() === dt.getMonth()) {
            return true
          }

          return false
        })

        if (Array.isArray(data[i].invoiceDetails)) {
          for (let j = 0; j < data[i].invoiceDetails.length; j++) {
            let seriesIndex = output.datasets.findIndex(
              (dataset) => dataset.label === data[i].invoiceDetails[j].allocation,
            )

            if (seriesIndex < 0) seriesIndex = 3

            if (seriesIndex >= 0 && pointIndex >= 0) {
              output.datasets[seriesIndex].data[pointIndex] += data[i].invoiceDetails[j].total
            }
          }
        } else {
          if (pointIndex >= 0) {
            output.datasets[0].data[pointIndex] += data[i].totalValue + data[i].totalTax
          }
        }
      }
    }

    for (let i = 0; i < output.labels.length; i++) {
      let dt = new Date(Date.parse(output.labels[i]))
      output.labels[i] = dt.toLocaleDateString('default', { year: 'numeric', month: 'long' })
    }

    return output
  }

  const getDueDate = (invoiceDate, paymentTermsDays) => {
    if (!paymentTermsDays) paymentTermsDays = 0
    if (typeof paymentTermsDays !== 'number') paymentTermsDays = 0
    if (paymentTermsDays < 0) paymentTermsDays = 0
    if (!invoiceDate) return null
    return isoDateAddition(invoiceDate, paymentTermsDays, true)
  }

  const getCollectionsData = () => {
    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    let collectionsData = [
      { value: 0, color: '#FF6363', label: 'Overdue' },
      { value: 0, color: '#4BB2F9', label: 'Due' },
      { value: 0, color: '#FFFF00', label: 'Check recieved clearing' },
    ]

    for (let i = 0; i < data.length; i++) {
      if (data[i].status === 'Due') {
        let dueDays = getDeltaIsoDays(
          new Date(Date.now()).toISOString(),
          getDueDate(data[i].createdDate, data[i].terms),
        )

        if (dueDays < 0) collectionsData[0].value += data[i].totalValue + data[i].totalTax
        if (dueDays >= 0) collectionsData[1].value += data[i].totalValue + data[i].totalTax
      }

      if (data[i].status === 'Check recieved clearing') {
        collectionsData[2].value += data[i].totalValue + data[i].totalTax
      }
    }

    return collectionsData
  }

  const RevenueByMonthBarChart = () => {
    return (
      <Bar
        options={{
          animation: false,
          layout: {
            padding: {
              right: 20,
            },
          },
          plugins: {
            GradientPlugIn: {
              showGradient: true,
              theme: theme,
            },
            title: {
              display: true,
              text: `Revenue`,
              color: appColors.itemTextColor,
              font: {
                size: 20,
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Month',
                color: appColors.headerTextColor,
              },
              stacked: true,
            },
            y: {
              title: {
                display: true,
                text: 'Revenue ($)',
                color: appColors.headerTextColor,
              },
              grid: {
                color: '#404040',
              },
              stacked: true,
            },
          },
        }}
        data={getRevenueChartData(data, true)}
      />
    )
  }

  const RevenueByCustomerBarChart = () => {
    return (
      <Bar
        options={{
          animation: false,
          plugins: {
            GradientPlugIn: {
              showGradient: true,
              theme: theme,
            },
            title: {
              display: true,
              text: `Revenue by Customer`,
              color: appColors.itemTextColor,
              font: {
                size: 20,
              },
            },
            legend: {
              position: 'right',
              labels: {
                boxWidth: 5,
                generateLabels: (chart) => {
                  const datasets = chart.data.datasets
                  return datasets.map((data, i) => ({
                    text: typeof data.label === 'string' ? `${data.label.split(' ')[0]}` : '',
                    fillStyle: data.backgroundColor,
                    strokeStyle: data.backgroundColor,
                  }))
                },
              },
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Month',
                color: appColors.headerTextColor,
              },
              stacked: true,
            },
            y: {
              title: {
                display: true,
                text: 'Revenue ($)',
                color: appColors.headerTextColor,
              },
              grid: {
                color: '#404040',
              },
              stacked: true,
            },
          },
        }}
        data={getRevenueByCustomerPerMonth()}
      />
    )
  }

  function getRevenueByCustomerData() {
    let revenue = []

    if (!data) return []
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []
    if (!Array.isArray(dropDowns.companys)) return []

    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== 'Cancelled' && data[i].status !== 'Draft') {
        if (Array.isArray(data[i].invoiceDetails)) {
          for (let j = 0; j < data[i].invoiceDetails.length; j++) {
            let index = revenue.findIndex((company) => company.label === data[i].company)

            if (index < 0) {
              revenue.push({
                label: data[i].company,
                color: revenue.length < chartSeriesColors.length ? chartSeriesColors[revenue.length] : getRandomColor(),
                value: data[i].invoiceDetails[j].total,
              })
            }

            if (index >= 0) {
              revenue[index].value += data[i].invoiceDetails[j].total
            }
          }
        }
      }
    }

    revenue.sort((a, b) => {
      if (a.value < b.value) return 1
      if (a.value > b.value) return -1
      return 0
    })

    for (let i = 0; i < revenue.length; i++) {
      revenue[i].label = [revenue[i].label, `$${numberWithCommas(round(revenue[i].value, 2))}`]
    }

    return revenue
  }

  const toggleDonutChartSelection = () => {
    setRevenueChartSelection(revenueChartSelection === 'total' ? 'customer' : 'total')
  }

  const DonutChart = ({ title, data, toggleFunc }) => {
    let centerText = ''
    if (Array.isArray(data)) {
      let total = 0
      data.forEach((item) => (total += item.value))
      centerText = `$${numberWithCommas(round(total, 0))}`
    }

    return (
      <Box
        onClick={() => {
          if (!toggleFunc) return
          toggleFunc()
        }}
        sx={{
          ...boxStyle,
          '&:hover': {
            cursor: toggleFunc ? 'pointer' : 'auto',
          },
        }}>
        <DonutChartJs
          centerText={centerText}
          title={title}
          data={data}
          legendLocation={'right'}
          showLegend={false}
          showTitle={true}
        />
      </Box>
    )
  }

  return (
    <Box
      sx={{
        ...boxStyle,
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          flex: 6,
          backgroundColor: getChartBackColor(),
          display: 'flex',
          width: '99.9%',
          height: '100%',
          overflow: 'hidden',
        }}>
        <Carousel autoPlay={false} height={'100%'} sx={{ width: '100%' }} swipe={false} fullHeightHover={false}>
          <RevenueByMonthBarChart />
          <RevenueByCustomerBarChart />
        </Carousel>
        {isLoading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : null}
      </Box>
      <Box
        sx={{
          ...boxStyle,
          flexDirection: 'column',
          flex: 1,
        }}>
        <DonutChart title={'Current Month'} data={getCurrentMonthData()} />
        <DonutChart
          toggleFunc={toggleDonutChartSelection}
          title={'Current Year'}
          data={revenueChartSelection === 'total' ? getCurrentYearData() : getRevenueByCustomerData()}
        />
        <DonutChart title={'Collections'} data={getCollectionsData()} />
      </Box>
    </Box>
  )
}

export default InnovaInvoiceCharts
