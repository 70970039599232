import React from 'react'

import Canvas from 'components/common/Canvas'
import FlashCanvasText from 'components/common/flashCanvasText'
import { getDeltaTimeSecondsFromCurrent } from 'utils/dateTimeFunctions'
import { uuidv4 } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const Rads2Deg = 180.0 / Math.PI

const getTextWidth = (text, font) => {
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  var context = canvas.getContext('2d')
  context.font = font
  var metrics = context.measureText(text)
  return Math.ceil(metrics.width)
}

const tfTimerText = new FlashCanvasText({
  baseColor: 'rgba(56, 219, 255, 1.0)',
  altColor: 'rgba(255, 255, 0, 1.0)', // 'rgba(255, 0, 0, 1.0)',
  baseFont: 'bold 24px sans-serif',
  altFont: 'bold 24px sans-serif', //'bold 22px sans-serif'
})

const dlsToLandText = new FlashCanvasText({
  baseColor: 'rgba(56, 219, 255, 1.0)',
  altColor: 'rgba(255, 255, 0, 1.0)', // 'rgba(255, 0, 0, 1.0)',
  baseFont: 'bold 14px sans-serif',
  altFont: 'bold 14px sans-serif', //'bold 22px sans-serif'
})

const EdrToolfaceControl = ({
  h,
  w,
  svyData = null,
  bitData = null,
  etf = null,
  ttf = null,
  slideGrade = null,
  toolFaceData = [],
  rigState = 'UNKNOWN',
  updateTime = null,
  dlsToLand = null,
  lastMy = null,
  phase = null,
  id = null,
}) => {
  let maxExtent = w > h ? h : w
  let centerPtX = w / 2
  let centerPtY = h / 2
  let margin = getTextWidth('WWW', '1em sans-serif') * 2
  let maxRadius = maxExtent / 2 - margin
  let numCircles = 10
  let distanceBetweenCircles = maxRadius / numCircles
  const crossoverInc = 5.0
  const { theme, getTreeBackColor } = useInnovaTheme()

  const getCurrentTF = () => {
    if (!toolFaceData) return 0
    if (!Array.isArray(toolFaceData)) return 0
    if (toolFaceData.length === 0) return 0
    return toolFaceData[toolFaceData.length - 1]
  }

  const processTF = (slideTF, svyInc) => {
    if (slideTF === -999.25 || slideTF === -9999) {
      return { tf: -999.25, tfMod: '' }
    }

    let tfVal = parseFloat(slideTF)
    let tfMod = ''
    const inc = parseFloat(svyInc)

    if (tfVal < 0.0) {
      tfVal += 360.0
    }
    if (tfVal > 360.0) {
      tfVal -= 360.0
    }
    if (inc <= crossoverInc) {
      tfMod = '°M'
    } else {
      if (tfVal > 180.0) {
        tfVal = 360.0 - tfVal
        tfMod = '°L'
      } else {
        tfMod = '°R'
      }
    }
    return { tf: tfVal, tfMod: tfMod }
  }

  const translateTF = (slideTF, svyInc) => {
    let tfVal = slideTF
    let tfMod = ''
    if (slideTF === -999.25) {
      return { tf: -999.25, tfMod: '' }
    }
    const inc = parseFloat(svyInc)

    tfVal -= 90

    if (tfVal < 0.0) {
      tfVal += 360.0
    }

    if (tfVal > 360.0) {
      tfVal -= 360.0
    }
    if (inc <= crossoverInc) {
      tfMod = '° M'
    } else {
      if (tfVal > 180.0) {
        tfMod = '° L'
      } else {
        tfMod = '° R'
      }
    }
    return { tf: tfVal, tfMod: tfMod }
  }

  const timeFormatter = (secs) => {
    let timestr = ''
    let date = new Date(0)
    if (secs < 3599) {
      date.setSeconds(secs)
      timestr = date.toISOString().substr(14, 5)
    } else {
      timestr = '> 1hr'
    }
    return timestr
  }

  const drawScale = (context) => {
    const { width, height } = getWidthHeight(context)

    context.lineWidth = 1
    for (let i = 1; i <= numCircles; i++) {
      if (i % 4 === 0) {
        context.strokeStyle = '#B0B0B0'
      } else {
        context.strokeStyle = '#606060'
      }
      context.beginPath()
      context.arc(width / 2, height / 2, distanceBetweenCircles * i, 0, 2 * Math.PI)
      context.stroke()
    }
  }

  const drawRadialLines = (context) => {
    const radialLength = maxRadius
    let xStart, yStart, xEnd, yEnd
    context.strokeStyle = '#606060'
    context.lineWidth = 1
    for (let angle = 0; angle < 360; angle += 30) {
      context.beginPath()
      xStart = distanceBetweenCircles * 2 * Math.sin((angle / 180) * Math.PI)
      yStart = distanceBetweenCircles * 2 * Math.cos((angle / 180) * Math.PI)
      xEnd = radialLength * Math.sin((angle / 180) * Math.PI)
      yEnd = radialLength * Math.cos((angle / 180) * Math.PI)
      context.moveTo(centerPtX + xStart, centerPtY + yStart)
      context.lineTo(centerPtX + xEnd, centerPtY + yEnd)
      context.stroke()
    }
  }

  const drawRadialLabels = (context) => {
    const radialLength = maxRadius + 20
    let xEnd, yEnd
    let angleOutput = ''
    context.fillStyle = '#B0B0B0'
    context.font = '1em sans-serif'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    for (let angle = 0; angle < 360; angle += 30) {
      xEnd = radialLength * Math.sin((-(angle - 180) / 180) * Math.PI)
      yEnd = radialLength * Math.cos((-(angle - 180) / 180) * Math.PI)
      const { tf: angleNew, tfMod } = processTF(angle, getSvyInc())
      angleOutput = angleNew.toString()

      if (parseFloat(getSvyInc()) > crossoverInc && angleNew === 90.0) {
        angleOutput += tfMod
      }
      context.fillText(angleOutput, centerPtX + xEnd, centerPtY + yEnd)
    }
  }

  const getSvyInc = () => {
    if (!svyData) return '5.0'
    if (svyData === null) return '5.0'
    if (!svyData.hasOwnProperty('inc')) return '5.0'
    return svyData.inc
  }

  const getETFColor = (slideGrade) => {
    const grade = parseFloat(slideGrade)
    if (grade > 90) return 'rgba(105, 179, 76, 0.50)'
    if (grade > 80) return 'rgba(172, 179, 52, 0.50)'
    if (grade > 70) return 'rgba(250, 183, 51, 0.50)'
    if (grade > 60) return 'rgba(255, 142, 21, 0.50)'
    if (grade > 50) return 'rgba(255, 78, 17, 0.50)'
    return 'rgba(255, 13, 13, 0.50)'
  }

  const drawEffectiveTF = (context) => {
    if (rigState === 'SLIDING') {
      const { tf: tfStart } = translateTF(parseFloat(etf ? etf : '0.0') - 3, getSvyInc())
      const { tf: tfEnd } = translateTF(parseFloat(etf ? etf : '0.0') + 3, getSvyInc())
      context.fillStyle = getETFColor(slideGrade ? slideGrade : '0.0')
      context.beginPath()
      context.moveTo(centerPtX, centerPtY)
      context.arc(centerPtX, centerPtY, maxRadius, (tfStart / 180.0) * Math.PI, (tfEnd / 180.0) * Math.PI)
      context.moveTo(centerPtX, centerPtY)
      context.fill()
    }
  }

  const drawSlideTargetTF = (context) => {
    if (rigState === 'SLIDING') {
      const { tf: tfStart } = translateTF(parseFloat(ttf ? ttf : '0.0') - 2, getSvyInc())
      const { tf: tfEnd } = translateTF(parseFloat(ttf ? ttf : '0.0') + 2, getSvyInc())
      context.fillStyle = 'rgba(0, 255, 0, 0.50)'
      context.beginPath()
      context.moveTo(centerPtX, centerPtY)
      context.arc(centerPtX, centerPtY, maxRadius, (tfStart / 180.0) * Math.PI, (tfEnd / 180.0) * Math.PI)
      context.moveTo(centerPtX, centerPtY)
      context.fill()
    }
  }

  const drawTFDashes = (context) => {
    const radialLength = maxRadius
    let xStart, yStart, xEnd, yEnd
    context.strokeStyle = 'rgba(52, 201, 235, 1.0)'
    context.lineWidth = 5
    let tfCounter = 0
    if (toolFaceData.length >= 7) {
      tfCounter = toolFaceData.length - 7
    }

    let tfIndex = 0
    for (let i = toolFaceData.length - 1; i >= tfCounter; i--) {
      if (toolFaceData[i] === -999.25) continue
      xStart =
        (radialLength - distanceBetweenCircles * tfIndex - distanceBetweenCircles / 1.25) *
        Math.sin((toolFaceData[i] / 180) * Math.PI)
      yStart =
        (radialLength - distanceBetweenCircles * tfIndex - distanceBetweenCircles / 1.25) *
        Math.cos((toolFaceData[i] / 180) * Math.PI)
      xEnd = (radialLength - distanceBetweenCircles * tfIndex) * Math.sin((toolFaceData[i] / 180) * Math.PI)
      yEnd = (radialLength - distanceBetweenCircles * tfIndex) * Math.cos((toolFaceData[i] / 180) * Math.PI)
      context.beginPath()
      context.moveTo(centerPtX + xStart, centerPtY - yStart)
      context.lineTo(centerPtX + xEnd, centerPtY - yEnd)
      context.stroke()
      tfIndex++
    }
    context.lineWidth = 1 // reset
  }

  // draw the bit and survey projections
  const drawProjections = (context) => {
    const radialLength = maxRadius
    const dotRadius = maxExtent / 48
    let bitProj = { angle: 0, distance: 0 }
    let svyProj = { angle: 0, distance: 0 }

    if (bitData !== null && bitData.hasOwnProperty('acScan'))
      bitProj = calcDistAndAngle(parseFloat(bitData?.acScan?.LR), parseFloat(bitData?.acScan?.UD))
    if (svyData !== null && svyData.hasOwnProperty('acScan'))
      svyProj = calcDistAndAngle(parseFloat(svyData?.acScan?.LR), parseFloat(svyData?.acScan?.UD))

    const distance = Math.max(bitProj.distance, svyProj.distance)
    const minDistanceScale = 50
    const scale = (Math.floor(distance / minDistanceScale) + 1) * minDistanceScale

    let x, y
    context.strokeStyle = '#2D2D2D'

    if (bitData !== null) {
      context.fillStyle = '#00FF00'
      x = (bitProj.distance / scale) * radialLength * Math.sin((bitProj.angle / 180) * Math.PI)
      y = (bitProj.distance / scale) * radialLength * Math.cos((bitProj.angle / 180) * Math.PI)
      context.beginPath()
      context.arc(centerPtX + x, centerPtY - y, dotRadius, 0, 2 * Math.PI)
      context.fill()
      context.stroke()
    }

    if (svyData !== null) {
      context.fillStyle = '#009691'
      x = (svyProj.distance / scale) * radialLength * Math.sin((svyProj.angle / 180) * Math.PI)
      y = (svyProj.distance / scale) * radialLength * Math.cos((svyProj.angle / 180) * Math.PI)
      context.beginPath()
      context.arc(centerPtX + x, centerPtY - y, dotRadius, 0, 2 * Math.PI)
      context.fill()
      context.stroke()
    }

    context.fillStyle = '#B0B0B0'
    context.font = '14px sans-serif'
    context.textAlign = 'right'
    context.textBaseline = 'middle'
    x = radialLength
    y = 12
    let angleOutput = scale.toFixed(0)
    context.fillText(angleOutput, centerPtX + x, centerPtY + y)
    x = radialLength / 2
    angleOutput = (scale / 2).toFixed(0)
    context.fillText(angleOutput, centerPtX + x, centerPtY + y)
  }

  const calcDistAndAngle = (x, y) => {
    let dist = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
    let ang = Math.atan2(x, y) * Rads2Deg
    return { angle: ang, distance: dist }
  }

  const drawTFLegend = (context) => {
    const { width, height } = getWidthHeight(context)

    let x, y
    const dotRadius = 6
    context.fillStyle = '#B0B0B0'
    context.font = '14px sans-serif'
    context.textAlign = 'right'
    context.textBaseline = 'middle'
    // draw in bottom right quadrant outside of control
    x = width - dotRadius * 3
    y = height - dotRadius * 2
    context.fillText('Bit Proj', x - 4, y)
    context.fillStyle = '#00FF00'
    context.beginPath()
    context.arc(x + dotRadius, y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()
    y -= 16
    context.fillStyle = '#B0B0B0'
    context.fillText('Survey', x - 4, y)
    context.fillStyle = '#009691'
    context.beginPath()
    context.arc(x + dotRadius, y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()
  }

  const drawDlsToLand = (context) => {
    let phaseValid = false
    if (phase && typeof phase === 'string') {
      let phaseLwr = phase
      phaseLwr = phaseLwr.toLowerCase()
      if (phaseLwr.includes('crv') || phaseLwr.includes('curve')) phaseValid = true
    }

    if (dlsToLand !== null && phaseValid) {
      let x, y
      const fontLabel = '10px sans-serif'
      context.fillStyle = '#B0B0B0'
      context.font = fontLabel
      context.textAlign = 'left'
      context.textBaseline = 'middle'

      x = 8
      y = 16

      let label = 'DLS 2 LAND:'
      let labelWidth = getTextWidth(label, fontLabel)

      context.fillText(label, x, y)
      let shouldTextFlash = false
      if (lastMy && lastMy > 0 && dlsToLand > 0 && dlsToLand > lastMy * 0.8) {
        shouldTextFlash = true
      }

      if (shouldTextFlash) {
        dlsToLandText.startFlash(performance.now())
      } else {
        dlsToLandText.stopFlash()
      }

      dlsToLandText.render(context, dlsToLand, x + labelWidth + 4, y)
    }
  }

  const drawTFTimer = (context) => {
    const { height } = getWidthHeight(context)

    if (updateTime !== null) {
      let x, y
      const fontLabel = '14px sans-serif'
      context.fillStyle = '#B0B0B0'
      context.font = fontLabel
      context.textAlign = 'left'
      context.textBaseline = 'middle'

      x = 8
      y = height - 16

      let lastTfTime = getDeltaTimeSecondsFromCurrent(updateTime)

      if (lastTfTime > 60) {
        tfTimerText.startFlash(performance.now())
      } else {
        tfTimerText.stopFlash()
      }

      tfTimerText.render(context, timeFormatter(lastTfTime), x, y)
    }
  }

  const drawCenterText = (context) => {
    const { tf: angleNew, tfMod } = processTF(getCurrentTF(), getSvyInc())
    if (angleNew === -999.25) {
      return
    }

    const directive = angleNew.toFixed(0) + tfMod
    context.strokeStyle = '#2D2D2D'
    context.fillStyle = 'rgba(56, 219, 255, 1.0)'
    context.font = 'bold 2em sans-serif'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(directive, centerPtX, centerPtY)
    context.strokeText(directive, centerPtX, centerPtY)
  }

  const getWidthHeight = (context) => {
    return {
      width: w,
      height: h,
    }
  }

  const draw = (context) => {
    const { width, height } = getWidthHeight(context)

    maxExtent = width > height ? height : width
    centerPtX = width / 2
    centerPtY = height / 2
    margin = maxExtent >= 400 ? getTextWidth('90°R', '1em sans-serif') * 2 : 8
    maxRadius = maxExtent / 2 - margin
    numCircles = 8
    distanceBetweenCircles = maxRadius / numCircles

    if (maxRadius < 0) return
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = getTreeBackColor()
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    context.strokeStyle = 'rgba(0,0,0,1)'
    context.strokeRect(0, 0, context.canvas.width, context.canvas.height)

    var gradient = context.createRadialGradient(centerPtX, centerPtY, 0, centerPtX, centerPtY, maxRadius)
    gradient.addColorStop(0, theme === 'dark' ? '#404040' : '#fff')
    gradient.addColorStop(1, theme === 'dark' ? '#101010' : '#c0c0c0')
    context.fillStyle = gradient
    context.beginPath()
    context.arc(centerPtX, centerPtY, maxRadius, 0, 2 * Math.PI)
    context.fill()
    drawScale(context)
    drawRadialLines(context)
    drawProjections(context)
    drawTFDashes(context)

    if (ttf !== null) drawSlideTargetTF(context)
    if (etf !== null) drawEffectiveTF(context)

    if (maxExtent >= 400) {
      drawRadialLabels(context)
    }
    drawCenterText(context)
    drawTFLegend(context)
    drawTFTimer(context)
    drawDlsToLand(context)
  }

  return (
    <Canvas square={false} id={id ? id : uuidv4()} draw={draw} width={w} height={h} style={{ width: w, height: h }} />
  )
}

export default EdrToolfaceControl
