import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, IconButton, Tooltip, CircularProgress } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import useWallPlotComposerFiles from 'components/common/hooks/WallPlotComposer/useWallPlotComposerFiles'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CloudImageGrid = forwardRef(({ wellName, files, onPreview, onUpdate, onDelete }, ref) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { fetchImages, images, loading } = useWallPlotComposerFiles(wellName)
  const lastPreviewRowRef = useRef(-1)
  const { getAgGridTheme } = useInnovaTheme()

  // need to get the file list from the api S3 calls
  // need to have a function to retieve a file for a thumbnail, if requested
  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!_isMounted.current) return
    if (!images) return
    onPreview(images[0])
    // a dependency for onPreview causes a render problem, so we are ignoring it
  }, [images]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowFileData = () => {
    const fileRowData = []

    files?.forEach((file, idx) => {
      fileRowData.push({
        fileName: file.description,
        id: idx,
      })
    })

    return fileRowData
  }

  useEffect(() => {
    if (!_isMounted.current) return
    if (!files) return
    if (!gridApi.current) return
    gridApi.current.setGridOption('rowData', getRowFileData())
  }, [files, loading]) // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    getSelRow: () => {
      const row = gridApi.current.getSelectedRows()
      if (!row || row.length < 1) return -1
      return row[0].id
    },
  }))

  const getS3FileName = (id) => {
    if (!files) return ''
    return files[id]?.fileName
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    gridApi.current.setGridOption('rowData', getRowFileData())

    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: false,
  }

  const handlePreview = (params) => {
    fetchImages(getS3FileName(params?.id))
  }

  const handleRowDoubleClick = (params) => {
    onUpdate(params.data?.id)
  }

  const handleRowClick = (params) => {
    const lastRow = lastPreviewRowRef.current
    // need to update the ref before the modal's state change...
    lastPreviewRowRef.current = params.node.rowIndex
    if (lastRow !== params.node.rowIndex) {
      // if a new row is clicked, clear the preview
      // onPreview(null)
      fetchImages(getS3FileName(params.node.rowIndex))
    }
  }

  const actionIconRenderer = (params) => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'center',
        }}>
        <Tooltip
          title={'Preview'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => handlePreview(params.data)}
            size='large'>
            <Iconify color={'#00EE00'} icon={'material-symbols:preview-outline'} fontSize={28} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={'Delete'}
          placement='left'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => {
              // prevent rowClickHandler and delete handler from firing at the same time
              setTimeout(() => {
                onPreview(null)
                onDelete(params.data)
              }, 200)
            }}
            size='large'>
            <Iconify color={'red'} icon={'fa-regular:trash-alt'} fontSize={16} />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    [],
  )

  const columnDefs = [
    {
      headerName: '',
      field: 'fileName',
      colId: 'fileName',
      headerCheckboxSelection: false,
      checkboxSelection: true,
      minWidth: 275,
      cellStyle: leftAlignCell,
    },
    {
      headerName: 'actions',
      field: 'actions',
      colId: 'actions',
      cellDataType: false,
      editable: false,
      flex: 1,
      cellRenderer: actionIconRenderer,
    },
  ]

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          rowSelection={'single'}
          onRowClicked={handleRowClick}
          onRowDoubleClicked={handleRowDoubleClick}
        />
      </div>
    </React.Fragment>
  )
})

export default CloudImageGrid
