import React, { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'

import { OrbitControls } from '@react-three/drei'
import { Vector3 } from 'three'
import useCodes from 'components/common/hooks/useCodes'
import { validateWellboreSurveyIndexValue, isArrayValid } from '../Utils/ThreeDeeChartUtils'

const MAX_DISTANCE_FROM_TARGET = 1000.0

function ChartControls({
  id = 'threeDeeChartContainer',
  cameraTarget,
  chartData,
  focusedSurveyIndex,
  camSpeed,
  setFocusedSurveyIndex,
  changeOffsetWellIndex = null,
  invertControls = false,
}) {
  const { camera } = useThree()
  const ref = useRef(null)
  const deltaCameraPosition = useRef(0)
  const code = useCodes(id)
  const previousSurveyIndex = useRef(-1)

  const changeCamPostion = () => {
    if (!Array.isArray(chartData?.refData)) return
    if (chartData.refData.length === 0) return
    if (!Array.isArray(chartData.refData[0].data)) return
    if (chartData.refData[0].data.length === 0) return

    let surveyIndex = 0
    if (focusedSurveyIndex.current >= 0 && focusedSurveyIndex.current < chartData.refData[0].data.length) {
      surveyIndex = focusedSurveyIndex.current
    }

    let newCamTarget = new Vector3(
      chartData?.refData[0].data[surveyIndex].x,
      chartData?.refData[0].data[surveyIndex].y,
      chartData?.refData[0].data[surveyIndex].z,
    )

    if (previousSurveyIndex.current < 0) previousSurveyIndex.current = 0

    if (previousSurveyIndex.current >= chartData.refData[0].data.length) {
      previousSurveyIndex.current = surveyIndex
    }

    let oldCamTarget = new Vector3(
      chartData?.refData[0].data[previousSurveyIndex.current].x,
      chartData?.refData[0].data[previousSurveyIndex.current].y,
      chartData?.refData[0].data[previousSurveyIndex.current].z,
    )

    previousSurveyIndex.current = surveyIndex
    let camOffset = camera.position.clone().sub(oldCamTarget)
    let newCamPosition = camOffset.clone().add(newCamTarget)

    // Limit the distance from the camera target
    const distanceToTarget = newCamPosition.distanceTo(oldCamTarget)

    if (distanceToTarget > MAX_DISTANCE_FROM_TARGET) {
      let distanceToUse = MAX_DISTANCE_FROM_TARGET
      if (Math.abs(distanceToTarget - MAX_DISTANCE_FROM_TARGET) > 1000) {
        distanceToUse = 100
      }

      const direction = newCamPosition.clone().sub(oldCamTarget).normalize()

      newCamPosition.copy(oldCamTarget).add(direction.multiplyScalar(distanceToUse))

      newCamPosition.y = newCamTarget.y + 250
      newCamPosition.z = newCamTarget.z
      newCamPosition.x = newCamTarget.x
    }

    cameraTarget.current = newCamTarget

    camera.lookAt(newCamTarget)
    camera.position.x = newCamPosition.x
    camera.position.y = newCamPosition.y
    camera.position.z = newCamPosition.z
    ref.current.target = newCamTarget
    ref.current.target0 = newCamTarget
    ref.current.position0 = newCamPosition

    ref.current.update()
  }

  useFrame(() => {
    let sign = invertControls ? -1 : 1

    if (code.current.has('ArrowUp')) {
      deltaCameraPosition.current -= camSpeed * sign
    }

    if (code.current.has('ArrowDown')) {
      deltaCameraPosition.current += camSpeed * sign
    }

    if (code.current.has('Comma') && changeOffsetWellIndex) {
      changeOffsetWellIndex(-1)
    }

    if (code.current.has('Period') && changeOffsetWellIndex) {
      changeOffsetWellIndex(1)
    }

    if (Math.abs(deltaCameraPosition.current) >= 1) {
      if (deltaCameraPosition.current >= 1) focusedSurveyIndex.current += 1
      if (deltaCameraPosition.current <= -1) focusedSurveyIndex.current -= 1
      deltaCameraPosition.current = 0

      focusedSurveyIndex.current = validateWellboreSurveyIndexValue(chartData?.refData, focusedSurveyIndex.current)
      setFocusedSurveyIndex(focusedSurveyIndex.current)
    }

    if (focusedSurveyIndex.current < 0 && isArrayValid(chartData?.refData)) {
      focusedSurveyIndex.current = Math.floor(chartData?.refData[0]?.data?.length / 2)
    }

    if (isArrayValid(chartData?.refData) && focusedSurveyIndex.current >= chartData?.refData[0].data.length) {
      focusedSurveyIndex.current = chartData?.refData[0].data.length - 1
      setFocusedSurveyIndex(focusedSurveyIndex.current)
    }

    if (focusedSurveyIndex.current > 0) {
      focusedSurveyIndex.current = validateWellboreSurveyIndexValue(chartData?.refData, focusedSurveyIndex.current)
    }

    changeCamPostion()
  })

  return <OrbitControls ref={ref} args={[camera]} />
}

export default ChartControls
