import { useEffect, useRef, useCallback, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'

const useObjectQc = () => {
    const _isMounted = useRef(false)
    const isLoadingRef = useRef(false)
    const [objectQcData, setObjectQcData] = useState([])

    const getObjectQc = useInnovaAxios({
        url: '/objectQc/getObjectQc',
    })

    useEffect(() => {
        _isMounted.current = true
        fetchObjectQcData(false)
        return () => {
            _isMounted.current = false
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchObjectQcData = async () => {
        if (isLoadingRef.current) return
        if (Array.isArray(objectQcData) && objectQcData.length > 0) return

        isLoadingRef.current = true
        const res = await getObjectQc()
        isLoadingRef.current = false

        if (res.error) return
        setObjectQcData(Array.isArray(res.data) ? res.data : [])
    }

    const getDropDownValues = (operator, tag) => {
        if (!Array.isArray(objectQcData)) return []

        for (let i = 0; i < objectQcData.length; i++) {
            if (!objectQcData[i].active) continue

            let shouldUseObjectSchema = false

            if (objectQcData[i].scope === 'ALL') {
                shouldUseObjectSchema = true
            }

            if (objectQcData[i].scope === 'OPERATOR') {
                if (Array.isArray(objectQcData[i].operators) && objectQcData[i].operators.includes(operator)) {
                    shouldUseObjectSchema = true
                }
            }

            if (!shouldUseObjectSchema) continue

            let values = []
            Object.keys(objectQcData[i].dropDownLists).forEach((key) => {
                if (key === tag) {
                    values = objectQcData[i].dropDownLists[key].values
                    return
                }
            })

            if (!Array.isArray(values)) values = []
            if (values.length > 0) {
                return values
            }
        }

        return []
    }

    const getChecks = (operator, group) => {
        if (!Array.isArray(objectQcData)) return []

        let checks = []
        for (let i = 0; i < objectQcData.length; i++) {
            if (!objectQcData[i].active) continue

            let shouldUseObjectSchema = false

            if (objectQcData[i].scope === 'ALL') {
                shouldUseObjectSchema = true
            }

            if (objectQcData[i].scope === 'OPERATOR') {
                if (Array.isArray(objectQcData[i].operators) && objectQcData[i].operators.includes(operator)) {
                    shouldUseObjectSchema = true
                }
            }

            if (!shouldUseObjectSchema) continue
            if (!Array.isArray(objectQcData[i].checks)) continue
            for (let j = 0; j < objectQcData[i].checks.length; j++) {
                if (!objectQcData[i].checks[j].active) continue

                if (objectQcData[i].checks[j].group === group || !group) {
                    checks.push(objectQcData[i].checks[j])
                }
            }
        }

        return checks
    }

    const getCellStyle = useCallback(
        (params, checks, align = 'left') => {
            let isRequired = false
            let colId = params?.colDef?.field
            colId = colId?.toLowerCase()

            if (Array.isArray(checks) && params?.node?.rowPinned !== 'bottom') {
                isRequired = checks.find((check) => check.uid.toLowerCase() === colId)
            }

            let isValid = false
            if (typeof params?.value === 'number' && Math.abs(params?.value) > 0) {
                isValid = true
            }

            if (typeof params?.value === 'string' && params?.value.trim() !== '') {
                isValid = true
            }

            if (typeof params?.value === 'boolean') {
                isValid = true
            }

            return {
                display: 'flex',
                alignItems: 'center',
                justifyContent: align === 'center' ? 'center' : 'start',
                backgroundColor: isRequired && !isValid ? `#ff6347` : '',
            }
        },
        [],
    )

    const isCheckInUse = (uid, group, operator) => {
        let checks = getChecks(operator, group)
        if (!Array.isArray(checks)) return false
        uid = uid.toLowerCase()
        return checks.find((check) => check.uid.toLowerCase() === uid)
    }
    return {
        fetchObjectQcData,
        getChecks,
        isCheckInUse,
        getCellStyle,
        getDropDownValues
    }
}

export default useObjectQc
