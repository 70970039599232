import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay, htmlSymbolHandling, getStringId } from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Alert, Snackbar, Box, IconButton } from '@mui/material'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StopCollarCatalog = ({ selectedCasing = null }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [catalogData, setCatalogData] = useState(false)
  const dropDownsRef = useRef([])
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const selectedComponent = useRef(null)
  const inputRow = useRef({})
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const [searchText, setSearchText] = useState('')

  const getStopCollars = useInnovaAxios({
    url: '/componentCatalog/stopCollars/getStopCollars',
  })

  const addStopCollar = useInnovaAxios({
    url: '/componentCatalog/stopCollars/addStopCollars',
  })

  const updateStopCollar = useInnovaAxios({
    url: '/componentCatalog/stopCollars/updateStopCollars',
  })

  const deleteStopCollar = useInnovaAxios({
    url: '/componentCatalog/stopCollars/deleteStopCollars',
  })

  const getDropDowns = useInnovaAxios({
    url: '/componentCatalog/stopCollars/getStopCollarDropDowns',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchStopCollars()
    fetchDropDowns()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const fetchDropDowns = async () => {
    const res = await getDropDowns()
    if (!_isMounted.current) return
    if (res?.error) return
    dropDownsRef.current = Array.isArray(res?.data) ? res.data : []
  }

  const fetchStopCollars = async () => {
    if (isLoading) return
    if (_isMounted.current) setLoading(true)

    const res = await getStopCollars()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) return

    let stopCollarData = Array.isArray(res?.data) ? res.data : []

    if (selectedCasing) {
      stopCollarData = stopCollarData.filter((item) => Math.abs(item.size - selectedCasing.od) < 0.01)
    }

    setCatalogData(stopCollarData)
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('stopCollarCatalogGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('stopCollarCatalogGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'StopCollarCatalog.xlsx',
            sheetName: 'StopCollars',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  let reqFields = useMemo(() => ['type', 'description', 'size', 'maxOd'], [])

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addStopCollar({ stopCollars: JSON.stringify([inputRow.current]) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [addStopCollar, isPinnedRowDataCompleted])

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return

    if (isUpdating.current) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
    }

    isUpdating.current = true
    let res = await updateStopCollar({ stopCollars: JSON.stringify(data) })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })
    }
  }

  const handleAddMultipleRows = async (data) => {
    if (isAdding.current) return
    if (!Array.isArray(data)) return
    if (data.length === 0) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
    }

    isAdding.current = true

    let res = await addStopCollar({ stopCollars: JSON.stringify(data) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      if (Array.isArray(res.data) && res.data.length > 0) {
        gridApi.current.applyTransaction({
          add: res.data,
        })
      }
    }
  }

  const onClickDelete = useCallback(
    (data) => {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
      selectedComponent.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete stop collar',
        text: `Are you sure you want to delete ${data.description}?`,
      })
    },
    [userRole],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedComponent.current) return
    if (!selectedComponent.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteStopCollar({ stopCollars: JSON.stringify([selectedComponent.current]) })
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedComponent.current],
      })
    }

    selectedComponent.current = null
  }, [deleteStopCollar])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [onClickDelete, handleAddRow],
  )

  const columnDefs = useMemo(
    () => [
      {
        field: 'uid',
        colId: 'uid',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 30,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        dndSource: true,
        valueFormatter: (params) => '',
      },
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: 'Description',
        colId: 'description',
        field: 'description',
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Description...' : unescapeHtml(params.value)),
      },
      {
        headerName: 'Type',
        colId: 'type',
        field: 'type',
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Type...' : unescapeHtml(params.value)),
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return { values: Array.isArray(dropDownsRef.current) ? dropDownsRef.current : [] }
        },
      },
      {
        headerName: `Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'size',
        field: 'size',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'Size...'
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.size, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `Max OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'maxOd',
        field: 'maxOd',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'MaxOd...'
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.data?.maxOd, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
    ],
    [getUnitsText, actionIconRenderer],
  )

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const gridOptions = {
    suppressRowClickSelection: true,
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate([event.data])
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const processDataFromClipboard = (params) => {
    const data = cloneDeep(params.data)
    if (!Array.isArray(data)) return null
    if (data.length === 0) return null

    //Remove last row from pasted data if empty
    //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
    const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
    if (emptyLastRow) {
      data.splice(data.length - 1, 1)
    }

    //Remove commas from pasted data
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        if (typeof data[i][j] !== 'string') continue
        data[i][j] = data[i][j].replace(',', '')
      }
    }

    const lastIndex = gridApi.current.getDisplayedRowCount() - 1
    const focusedCell = gridApi.current.getFocusedCell()

    //Handle updates
    const rowsToUpdate = []
    let curRowIndex = focusedCell.rowIndex
    if (focusedCell?.rowPinned !== 'bottom') {
      for (let i = 0; i < data.length; i++) {
        if (curRowIndex > lastIndex) break
        if (!Array.isArray(data[i])) continue

        let stopCollar = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < data[i].length; j++) {
          stopCollar.data[currentColumn.colDef.field] = data[i][j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        stopCollar.data.size = parseFloat(stopCollar.data.size)
        stopCollar.data.maxOd = parseFloat(stopCollar.data.maxOd)
        rowsToUpdate.push(cloneDeep(stopCollar.data))
        curRowIndex++
      }
    }

    //Handle add new rows
    const rowsToAdd = []
    let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
    if (startPasteIndex + data.length - 1 > lastIndex) {
      const resultLastIndex = startPasteIndex + (data.length - 1)
      const numRowsToAdd = resultLastIndex - lastIndex

      let index = data.length - 1
      for (let i = 0; i < numRowsToAdd; i++) {
        const row = data.slice(index, index + 1)[0]
        index--

        if (!Array.isArray(row)) continue
        const newStopCollar = {}
        let currentColumn = focusedCell.column
        if (!currentColumn) continue

        for (let j = 0; j < row.length; j++) {
          newStopCollar[currentColumn.colDef.field] = row[j]
          currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
        }

        newStopCollar.size = parseFloat(newStopCollar.size)
        newStopCollar.maxOd = parseFloat(newStopCollar.maxOd)
        rowsToAdd.push(newStopCollar)
      }
    }

    handleUpdate(rowsToUpdate)
    handleAddMultipleRows(rowsToAdd.reverse())

    if (focusedCell?.rowPinned === 'bottom') {
      inputRow.current = {}
      if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }

    return null
  }

  const gridDragOver = (event) => {
    const dragSupported = event.dataTransfer.types.length

    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={handleDelete}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle()}
          />
        </Box>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }} onDragOver={gridDragOver}>
          <AgGridReact
            rowData={catalogData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'stopCollarCatalogGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={false}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            getContextMenuItems={getContextMenuItems}
            onFirstDataRendered={onFirstDataRendered}
            headerHeight={30}
            processDataFromClipboard={processDataFromClipboard}
            rowDragManaged={true}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default StopCollarCatalog
