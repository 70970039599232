import { chartSeriesColors } from '../theme'

export function getDistinctPhases(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []

  data.forEach((pt) => {
    let found = output.find((element) => element.phase === pt.phase)
    if (found === undefined) {
      if (pt.phase !== '' && pt.phase !== null) {
        output.push({
          phase: pt.phase,
          color: chartSeriesColors[output.length],
        })
      }
    }
  })

  return output
}

export function getDistinctBHA(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []
  data.forEach((pt) => {
    let found = output.find((element) => element.label === pt.bha)
    if (found === undefined) {
      if (pt.bha !== '' && pt.bha !== null) {
        output.push({
          label: pt.bha,
          color: chartSeriesColors[output.length],
        })
      }
    }
  })

  return output
}

export function createPhaseLegend(data) {
  let disinctPhase = getDistinctPhases(data)
  if (!disinctPhase) return []
  if (disinctPhase.length <= 0) return []

  let output = []
  disinctPhase.forEach((pt, i) => {
    output.push({ label: pt.phase?.length < 0 ? 'No Phase' : pt.phase, color: chartSeriesColors[output.length] })
  })

  return output
}

export function createChartData(data) {
  if (!data) return []

  const chartData = [
    {
      name: 'Depth v Days',
      color: 'tomato',
      strokeWidth: 2,
      strokeOpacity: 1,
      data: [],
    },
  ]

  chartData[0].data = createChartDataIndividualWell(data, 'label', 'bha')

  return chartData
}

export function removeFlatTime(data, removeFlatTime, noFlatTimeStart = false, noFlatTimeEnd = false) {
  if (!Array.isArray(data) || data.length <= 0) return []
  if (!removeFlatTime && !noFlatTimeStart && !noFlatTimeEnd) return data

  let output = []
  let cumDays = 0

  if (removeFlatTime) {
    for (let i = 0; i < data.length; i++) {
      if (i === 0) continue
      if (data[i].depth !== data[i - 1].depth) {
        cumDays += data[i].days - data[i - 1].days

        if (output.length === 0) {
          output.push({ ...data[i - 1] })
          output[output.length - 1].days = 0
        }
        output.push({ ...data[i] })
        output[output.length - 1].days = cumDays
      }
    }

    return output
  }

  let lastDrillDepthIndex = 0
  for (let i = 0; i < data.length; i++) {
    if (i > lastDrillDepthIndex && data[i].depth !== data[i - 1].depth) {
      lastDrillDepthIndex = i
    }
  }

  let firstDrillDepthFound = false
  for (let i = 0; i < data.length; i++) {
    if (i === 0) continue

    if (noFlatTimeStart) {
      if (!firstDrillDepthFound && data[i].depth === data[i - 1].depth) continue
      firstDrillDepthFound = true
    }

    if (noFlatTimeEnd) {
      if (i > lastDrillDepthIndex) break
    }

    cumDays += data[i].days - data[i - 1].days

    if (output.length === 0) {
      output.push({ ...data[i - 1] })
      output[output.length - 1].days = 0
    }
    output.push({ ...data[i] })
    output[output.length - 1].days = cumDays
  }

  return output
}

function createChartDataIndividualWell(data, colorByProp, cols, filterParam, filterValues) {
  if (!Array.isArray(data)) return []
  if (data.length === 0) {
    return [
      { x: 0, y: 0 },
      { x: 0, y: 0 },
    ]
  }

  if (!Array.isArray(cols)) {
    cols = []
  }

  if (typeof filterParam !== 'string') filterParam = ''
  if (!Array.isArray(filterValues)) filterValues = []

  let chartData = []
  data.forEach((item) => {
    if (filterParam !== '' && filterValues.length > 0) {
      if (!item.hasOwnProperty(filterParam)) return
      if (!filterValues.includes(item[filterParam])) return
    }

    let segmentCol = 'tomato'
    if (item.hasOwnProperty(colorByProp) && colorByProp !== '') {
      let found = cols.find((col) => col.value === item[colorByProp])
      segmentCol = found ? found.color : 'tomato'
    }

    let dataPoint = {
      x: item.days,
      y: item.depth,
      activity: item.activity,
      activityDesc: item.activityDesc,
      phase: item.phase,
      bha: item.bha,
      bhaNumRep: item.bha >= 0 ? item.bhaNumRep : -1,
      holeSize: item.holeSize,
    }

    if (colorByProp !== '') {
      dataPoint.color = segmentCol
    }

    chartData.push(dataPoint)
  })

  return chartData
}

export function getDistinctProp(data, prop) {
  if (!data) return []
  if (!Array.isArray(data)) return []
  if (data.length === 0) return []
  if (prop === undefined || prop === null) return []
  if (typeof prop !== 'string') return []
  if (prop === '') return []

  const distinctValues = new Set()

  data.forEach((well) => {
    well?.depthVDays.forEach((pt) => {
      if (!pt.hasOwnProperty(prop)) return
      distinctValues.add(pt[prop])
    })
  })

  return Array.from(distinctValues).map((val, i) => {
    return {
      value: val,
      color: i < chartSeriesColors.length ? chartSeriesColors[i] : 'tomato',
    }
  })
}

export function getDistinctPropList(data, prop) {
  if (
    !data ||
    !Array.isArray(data) ||
    data.length === 0 ||
    prop === undefined ||
    prop === null ||
    typeof prop !== 'string' ||
    prop === ''
  ) {
    return []
  }

  const distinctValues = new Map()

  data.forEach((well) => {
    well?.depthVDays.forEach((pt) => {
      if (!pt.hasOwnProperty(prop)) return
      if (prop === 'bha') {
        distinctValues.set(pt[prop], { label: pt[prop] < 0 ? 'none' : pt.bhaDesc, value: pt[prop] })
        return
      }

      if (prop === 'activity') {
        distinctValues.set(pt[prop], { label: `${pt[prop]}: ${pt.activityDesc}`, value: pt[prop] })
        return
      }

      distinctValues.set(pt[prop], { label: pt[prop], value: pt[prop] })
    })
  })

  let output = []
  distinctValues.forEach((val) => {
    output.push(val)
  })

  return output
}

export function createChartDataMultiWell(
  data,
  noFlatTime = false,
  noFlatTimeStart = false,
  noFlatTimeEnd = false,
  filterOptions = null,
  colorByParam = 'phase',
) {
  if (!Array.isArray(data)) return []
  const chartData = []
  let segmentColors = null

  if (colorByParam && colorByParam !== '') {
    segmentColors = getDistinctProp(data, colorByParam)
  }

  if (!filterOptions) {
    filterOptions = {
      filterParam: '',
      filterValues: [],
    }
  }

  if (!Array.isArray(filterOptions?.filterValues)) {
    filterOptions.filterValues = []
  }

  if (!filterOptions.filterParam) {
    filterOptions.filterParam = ''
  }

  data.forEach((well) => {
    const newChartData = {
      name: well.actualWell,
      color: 'tomato',
      strokeWidth: 2,
      strokeOpacity: 1,
      dataVals: createChartDataIndividualWell(
        removeFlatTime(well?.depthVDays, noFlatTime, noFlatTimeStart, noFlatTimeEnd),
        colorByParam,
        segmentColors,
        filterOptions.filterParam,
        filterOptions.filterValues,
      ),
    }

    if (newChartData.dataVals.length > 0) chartData.push(newChartData)
  })

  return chartData
}
