import React from 'react'

import { Box } from '@mui/material'
import BarChart from 'components/common/BarChartCjs'
import { chartSeriesColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const LogisticsChart = ({ logisticsData }) => {
  const { theme } = useInnovaTheme()
  
  const chartOptions = {
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (content) => {
            if (!Array.isArray(logisticsData)) return content.label
            return [content[0].label, logisticsData[content[0].dataIndex].jobNum]
          },
        },
      },
    },
  }

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!data) return output
    if (!Array.isArray(data)) return output
    if (data.length === 0) return output

    const toolTypes = new Set()
    const rigs = new Set()
    data.forEach((tool) => {
      rigs.add(tool.rig)
      toolTypes.add(tool.toolType)
    })

    output.labels = Array.from(rigs)
    for (const tool of toolTypes) {
      output.datasets.push({
        label: tool,
        backgroundColor: chartSeriesColors[output.datasets.length],
        maxBarThickness: 24,
        data: new Array(output.labels.length).fill(0),
      })
    }

    data.forEach((tool) => {
      let rigIndex = output.labels.findIndex((rig) => rig === tool.rig)
      let toolIndex = output.datasets.findIndex((dataset) => dataset.label === tool.toolType)
      if (toolIndex >= 0 && rigIndex >= 0) output.datasets[toolIndex].data[rigIndex]++
    })

    return output
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
      <BarChart
        wellData={createChartData(logisticsData)}
        title=''
        xAxisTitle='Rigs'
        yAxisTitle={``}
        chartOptions={chartOptions}
      />
    </Box>
  )
}

export default LogisticsChart
