import React, { useEffect, useRef, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import { Button, ButtonGroup, Box } from '@mui/material'
import moment from 'moment'
import 'chartjs-adapter-moment'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledDateRangeBtn = styled(Button)({
  fontSize: '15px',
  fontWeight: 400,
  width: '55px',
  height: '18px',
})

const StyledButtonGroup = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: '10px',
  right: '10px',
  [theme.breakpoints.down('lg')]: {
    alignSelf: '',
  },
  [theme.breakpoints.up('md')]: {
    alignSelf: 'end',
  },
}))

const Y_AXIS_ITEM_HEIGHT = 30
const MIN_ALLOWED_DATE = '1990-01-01'

const PersonnelUtilizationGanttChart = ({ personnelData, title, selectedNames }) => {
  const _isMounted = useRef(false)
  const chartOptionsRef = useRef({})
  const { theme, getChartBackColor, getTextColor } = useInnovaTheme()
  const chartRef = useRef()
  const minMaxData = useRef({
    min: MIN_ALLOWED_DATE,
    max: new Date(Date.now()).toISOString().substring(0, 10),
    count: 0,
  })
  const [dateIntervalDays, setDateIntervalDays] = useState(30)
  const dateIntervalRef = useRef(30)
  const [chartData, setChartData] = useState({
    labels: ['NO DATA'],
    datasets: [
      {
        data: [],
        backgroundColor: 'green',
        label: 'WELL',
        maxBarThickness: 15,
      },
    ],
  })

  useEffect(() => {
    _isMounted.current = true
    document.getElementById('personnelTimeLineContainer')?.addEventListener('wheel', scrollWheelHandler)
    document.getElementById('personnelTimeLineContainer')?.addEventListener('click', onScrollButtonClick)

    return () => {
      _isMounted.current = false
      document.getElementById('personnelTimeLineContainer')?.removeEventListener('wheel', scrollWheelHandler)
      document.getElementById('personnelTimeLineContainer')?.removeEventListener('click', onScrollButtonClick)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    createChartData()
  }, [personnelData, selectedNames]) // eslint-disable-line react-hooks/exhaustive-deps

  const getScrollIncrement = () => {
    return dateIntervalRef.current === 30 ? 1 : 7
  }

  const scrollWheelHandler = (e) => {
    e.preventDefault()
    if (Math.abs(e.deltaY) === 0) return

    if (e.shiftKey) {
      scrollYAxis(e.deltaY > 0 ? 'down' : 'up')
      return
    }

    scrollXAxis(e.deltaY > 0 ? 'left' : 'right')
  }

  function scrollYAxis(direction) {
    if (direction === '') return
    let deltaY = 0

    if (direction === 'up' && chartRef.current.config.options.scales.y.min > 0) {
      deltaY = -1
    }

    if (direction === 'down' && chartRef.current.config.options.scales.y.max < minMaxData.current.count - 1) {
      deltaY = 1
    }

    if (Math.abs(deltaY) === 0) return
    chartRef.current.config.options.scales.y.min += deltaY
    chartRef.current.config.options.scales.y.max += deltaY
    chartRef.current.update()
  }

  function scrollXAxis(direction) {
    if (direction === '') return
    if (direction === 'left') {
      let minXScale = moment(chartRef.current.config.options.scales.x.min)
      let minXData = moment(minMaxData.current.min)
      let deltaDays = minXScale.diff(minXData, 'days')

      if (deltaDays < 0) deltaDays = 0
      if (deltaDays >= getScrollIncrement()) deltaDays = getScrollIncrement()

      chartRef.current.config.options.scales.x.min = moment(chartRef.current.config.options.scales.x.min)
        .subtract(deltaDays, 'days')
        .format('YYYY-MM-DD')
      chartRef.current.config.options.scales.x.max = moment(chartRef.current.config.options.scales.x.max)
        .subtract(deltaDays, 'days')
        .format('YYYY-MM-DD')

      setXScaleLabel()
    }

    if (direction === 'right') {
      let maxXScale = moment(chartRef.current.config.options.scales.x.max)
      let maxXData = moment(minMaxData.current.max)
      let deltaDays = maxXData.diff(maxXScale, 'days')

      if (deltaDays < 0) deltaDays = 0
      if (deltaDays >= getScrollIncrement()) deltaDays = getScrollIncrement()

      chartRef.current.config.options.scales.x.min = moment(chartRef.current.config.options.scales.x.min)
        .add(deltaDays, 'days')
        .format('YYYY-MM-DD')
      chartRef.current.config.options.scales.x.max = moment(chartRef.current.config.options.scales.x.max)
        .add(deltaDays, 'days')
        .format('YYYY-MM-DD')

      setXScaleLabel()
    }
  }

  function onScrollButtonClick(e) {
    const rect = chartRef.current.canvas.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    let hitTest = buttonHitTest(chartRef.current?.chartArea, x, y)
    scrollYAxis(hitTest)
    scrollXAxis(hitTest)
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const shadeWeekends = {
    id: 'shadeWeekends',
    beforeDraw(chart, args, options) {
      chart.ctx.save()
      if (dateIntervalRef.current === 365) return
      chart.scales.x.ticks.forEach((tick) => {
        let tickDate = new Date(tick.value).getDay()
        if (tickDate > 0 && tickDate < 6) return

        chart.ctx.fillStyle = 'rgba(19, 63, 96, 0.25)'
        chart.ctx.fillRect(
          chart.scales.x.getPixelForValue(tick.value),
          chart.chartArea.top,
          chart.scales.x.getPixelForValue(new Date(tick.value).setHours(24)) -
            chart.scales.x.getPixelForValue(tick.value),
          chart.chartArea.height,
        )
      })
    },
  }

  const shadeMonths = {
    id: 'shadeMonths',
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, height, left, right },
        scales: { x },
      } = chart
      ctx.save()

      if (dateIntervalRef.current === 30) return
      x.ticks.forEach((tick) => {
        let month = new Date(tick.value).getMonth() + 1
        if (month % 2 > 0) return
        let date = new Date(tick.value)
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

        let leftPix = x.getPixelForValue(firstDay)
        if (leftPix < left) {
          leftPix = left
        }

        let rightPix = x.getPixelForValue(lastDay) - leftPix
        if (leftPix + rightPix > right) {
          rightPix = right - leftPix
        }
        ctx.fillStyle = 'rgba(19, 63, 96, 0.25)'
        ctx.fillRect(leftPix, top, rightPix, height)
      })
    },
  }

  const currDayTracker = {
    id: 'currDayTracker',
    beforeDraw(chart, args, options) {
      const {
        ctx,
        chartArea: { top, bottom },
        scales: { x },
      } = chart
      ctx.save()

      if (!Array.isArray(x.ticks)) return
      if (x.ticks.length === 0) return

      let chartLabelMin = moment(x.ticks[0]?.value).format('YYYY-MM-DD')
      let chartLabelMax = moment(x.ticks[x.ticks.length - 1]?.value).format('YYYY-MM-DD')
      let today = moment().format('YYYY-MM-DD')

      if (!moment(today).isBetween(chartLabelMin, chartLabelMax)) return

      for (let i = 0; i < x.ticks.length; i++) {
        if (i === 0) continue
        let lineXPos = -1
        let tickDate = moment(x.ticks[i].value).format('YYYY-MM-DD')
        let prevTickDate = moment(x.ticks[i - 1].value).format('YYYY-MM-DD')

        if (tickDate === today) {
          lineXPos = x.ticks[i].value
        }

        if (tickDate !== today && moment(today).isBetween(prevTickDate, tickDate)) {
          let momentPrevTickDate = moment(prevTickDate)
          let momentTickDate = moment(tickDate)
          let momentToday = moment(today)

          let deltaTicksDays = momentTickDate.diff(momentPrevTickDate, 'days')
          let daysDiff = momentToday.diff(momentPrevTickDate, 'days')

          let pixelsMin = x.getPixelForValue(x.ticks[i - 1].value)
          let pixelsMax = x.getPixelForValue(x.ticks[i].value)
          let pixelsPerDay = (pixelsMax - pixelsMin) / deltaTicksDays

          lineXPos = pixelsMin + daysDiff * pixelsPerDay
        }

        if (lineXPos >= 0) {
          ctx.beginPath()
          ctx.moveTo(x.getPixelForValue(lineXPos), top + 2)
          ctx.lineTo(x.getPixelForValue(lineXPos), bottom)
          ctx.lineWidth = 2
          ctx.strokeStyle = 'red'
          ctx.stroke()
          ctx.restore()
          return
        }
      }
    },
  }

  const buttonHitTest = (chartArea, x, y) => {
    const { top, left, right, bottom } = chartArea
    if (x >= left - 15 && x <= left + 15 && y >= top - 15 && y <= top + 15) return 'up'
    if (x >= left - 15 && x <= left + 15 && y >= bottom - 15 && y <= bottom + 15) return 'down'
    if (x >= left + 25 && x <= left + 55 && y >= bottom - 15 && y <= bottom + 15) return 'left'
    if (x >= right - 15 && x <= right + 15 && y >= bottom - 15 && y <= bottom + 15) return 'right'
    return ''
  }

  const moveChart = {
    id: 'moveChart',
    afterEvent(chart, args) {
      chart.canvas.addEventListener('mousemove', () => {
        const x = args.event.x
        const y = args.event.y
        chart.canvas.style.cursor = buttonHitTest(chart.chartArea, x, y) !== '' ? 'pointer' : 'default'
      })
    },

    afterDraw(chart) {
      const {
        ctx,
        chartArea: { left, right, top, bottom },
      } = chart

      const DrawChevron = (ctx, x, y, type) => {
        let arrowOffsetX = -5
        let arrowOffsetY = 7.5
        if (type === 'left') arrowOffsetX = 5

        if (type === 'down') {
          arrowOffsetX = -7.5
          arrowOffsetY = -5
        }

        if (type === 'up') {
          arrowOffsetX = -7.5
          arrowOffsetY = 5
        }

        ctx.beginPath()
        ctx.lineWidth = 1
        ctx.strokeStyle = 'rgba(102, 102, 102, 0.65)'
        ctx.fillStyle = 'rgba(37,37,37,0.65)'
        ctx.arc(x, y, 15, 0, Math.PI * 2, false)
        ctx.stroke()
        ctx.fill()
        ctx.closePath()

        ctx.beginPath()
        ctx.lineWidth = 3
        ctx.strokeStyle = 'rgba(200, 200, 200, 1)'

        if (type === 'left' || type === 'right') {
          ctx.moveTo(x + arrowOffsetX, y - arrowOffsetY)
          ctx.lineTo(x - arrowOffsetX, y)
          ctx.lineTo(x + arrowOffsetX, y + arrowOffsetY)
        }

        if (type === 'up' || type === 'down') {
          ctx.moveTo(x - arrowOffsetX, y + arrowOffsetY)
          ctx.lineTo(x, y - arrowOffsetY)
          ctx.lineTo(x + arrowOffsetX, y + arrowOffsetY)
        }

        ctx.stroke()
        ctx.closePath()
      }

      DrawChevron(ctx, left + 40, bottom, 'left')
      DrawChevron(ctx, right, bottom, 'right')
      DrawChevron(ctx, left, top, 'up')
      DrawChevron(ctx, left, bottom, 'down')
    },
  }

  const handleDateReset = () => {
    if (!chartRef.current) return
    if (!isValidDate(minMaxData.current.max))
      minMaxData.current.max = new Date(Date.now()).toISOString().substring(0, 10)
    if (!isValidDate(minMaxData.current.min))
      minMaxData.current.max = new Date(Date.parse(MIN_ALLOWED_DATE + 'T00:00:01')).toISOString().substring(0, 10)

    chartRef.current.config.options.scales.x.min = moment(minMaxData.current.max)
      .subtract(dateIntervalRef.current, 'days')
      .format('YYYY-MM-DD')
    chartRef.current.config.options.scales.x.max = minMaxData.current.max
    chartRef.current.update()
  }

  const getNumVisibleYAxisElements = () => {
    if (chartRef.current?.chartArea && chartRef.current?.chartArea?.height) {
      const numVisElements = Math.floor(chartRef.current.chartArea.height / Y_AXIS_ITEM_HEIGHT)
      return numVisElements
    }

    return 0
  }

  const setXScaleLabel = () => {
    let maxYear = moment(chartRef.current.config.options.scales.x.max).format('YYYY-MM-DD').substring(0, 4)
    let minYear = moment(chartRef.current.config.options.scales.x.min).format('YYYY-MM-DD').substring(0, 4)

    chartRef.current.config.options.scales.x.title.text = minYear === maxYear ? minYear : `${minYear}-${maxYear}`
    chartRef.current.update()
  }

  const handleDateToggle = (days) => {
    setDateIntervalDays(days)
    dateIntervalRef.current = days

    if (!chartRef.current) return
    chartRef.current.config.options.scales.x.min = moment(chartRef.current.config.options.scales.x.max)
      .subtract(days, 'days')
      .format('YYYY-MM-DD')

    setXScaleLabel()
  }

  function getPositionString(input) {
    if (typeof input !== 'string' || input.length === 0) {
      return input
    }

    const parts = input.split('_')
    const formattedParts = parts.map((part, index) => {
      if (index === 0) {
        return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      } else {
        return part.toLowerCase()
      }
    })

    const formattedString = formattedParts.join(' ')
    return formattedString
  }

  const createChartData = () => {
    let data = {
      labels: ['NO DATA'],
      datasets: [
        {
          data: [],
          backgroundColor: 'green',
          label: 'WELL',
          maxBarThickness: 15,
        },
      ],
    }

    minMaxData.current = { min: MIN_ALLOWED_DATE, max: new Date(Date.now()).toISOString().substring(0, 10), count: 0 }
    if (!Array.isArray(personnelData)) {
      setChartData(data)
    }

    let minDateMs = 999999999999
    let maxDateMs = 0
    let minAllowedDateMs = new Date(Date.parse(MIN_ALLOWED_DATE + 'T00:00:01Z')).getTime()

    const wellNames = new Set()
    const personnelNames = new Set()
    for (let i = 0; i < personnelData.length; i++) {
      let drawPerson = true
      if (Array.isArray(selectedNames) && selectedNames.length > 0) {
        drawPerson = false
        let index = selectedNames.findIndex((name) => name === personnelData[i].name)
        if (index >= 0) drawPerson = true
      }

      if (!drawPerson) continue
      personnelNames.add(personnelData[i].name)

      if (!Array.isArray(personnelData[i].wells)) continue
      for (let j = 0; j < personnelData[i].wells.length; j++) {
        wellNames.add(personnelData[i].wells[j].wellName)
      }
    }

    if (personnelNames.size > 0) {
      data.labels = []
    }

    for (const name of personnelNames) {
      data.labels.push(name)
    }

    for (let i = 0; i < personnelData.length; i++) {
      let nameIndex = data.labels.findIndex((name) => name === personnelData[i].name)
      if (nameIndex < 0) continue

      if (!Array.isArray(personnelData[i].wells)) continue
      for (let j = 0; j < personnelData[i].wells.length; j++) {
        if (!Array.isArray(personnelData[i].wells[j].days)) continue
        for (let x = 0; x < personnelData[i].wells[j].days.length; x++) {
          if (!isValidDate(personnelData[i].wells[j].days[x].date)) continue

          let startDate = new Date(Date.parse(personnelData[i].wells[j].days[x].date + 'T00:00:01'))
          let endDate = new Date(Date.parse(personnelData[i].wells[j].days[x].date + 'T23:59:59'))

          if (startDate.getTime() <= minAllowedDateMs) continue
          if (startDate.getTime() < minDateMs) minDateMs = startDate.getTime()
          if (endDate.getTime() > maxDateMs) maxDateMs = endDate.getTime()

          data.datasets[0].data.push({
            x: [
              personnelData[i].wells[j].days[x].date + 'T00:00:01',
              personnelData[i].wells[j].days[x].date + 'T23:59:59',
            ],
            y: personnelData[i].name,
            label: [
              `Well:\t${personnelData[i].wells[j].wellName}`,
              `Operator:\t${personnelData[i].wells[j].operator}`,
              `Job#:\t${personnelData[i].wells[j].jobNum}`,
              `Rig:\t${personnelData[i].wells[j].rig}`,
              `Position:\t${getPositionString(personnelData[i].wells[j].days[x].position)}`,
            ],
          })
        }
      }
    }

    if (data.labels.length > 0) {
      minMaxData.current = {
        min: new Date(minDateMs).toISOString().substring(0, 10),
        max: new Date(maxDateMs + 172800000).toISOString().substring(0, 10), //Add 2 days padding
        count: data.labels.length,
        labels: data.labels,
      }

      chartOptionsRef.current = getChartOptions()
    }

    setChartData(data)

    setTimeout(() => setXScaleLabel(), 200)
  }

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, MIN_ALLOWED_DATE)) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  const getChartOptions = () => {
    return {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      onResize: (chart) => {
        chart.config.options.scales.y.max = chart.config.options.scales.y.min + getNumVisibleYAxisElements()
      },
      indexAxis: 'y',
      layout: {
        padding: {
          right: 40,
        },
      },
      scales: {
        x: {
          min: moment(minMaxData.current.max).subtract(dateIntervalRef.current, 'days').format('YYYY-MM-DD'),
          max: minMaxData.current.max,
          type: 'time',
          time: {
            unit: 'day',
          },
          grid: {
            drawTicks: false,
            color: (context) => {
              return new Date(Date.parse(context.tick.value)).getTime() === new Date(Date.now()).getTime()
                ? 'transparent'
                : '#404040'
            },
          },
          title: {
            display: true,
            text: '2023',
            font: {
              size: 20,
              weight: 600,
            },
            color: 'rgba(102, 102, 102, 1)',
          },
          ticks: {
            color: getTextColor(),
          },
        },
        y: {
          min: 0,
          max: getNumVisibleYAxisElements(),
          ticks: {
            color: getTextColor(),
          },
        },
      },
      plugins: {
        GradientPlugIn: {
          showGradient: true,
          theme: theme,
        },
        legend: {
          display: false,
        },
        title: {
          display: title?.length > 0,
          text: title,
          fullSize: false,
          color: appColors.itemTextColor,
          font: {
            size: 20,
          },
        },
        tooltip: {
          events: ['click', 'mousemove'],
          mode: 'point',
          yAlign: 'top',
          callbacks: {
            title: function (tooltipItem) {
              return `${tooltipItem[0].label} - ${dateTimeFormatter(tooltipItem[0].raw.x[0])}`
            },
            label: function (context) {
              return context.raw.label
            },
          },
          intersect: false,
          filter: function (tooltipItem, index) {
            if (index > 0) return false
            return true
          },
        },
      },
    }
  }

  return (
    <Box
      id={'personnelTimeLineContainer'}
      sx={{
        flexDirection: 'column',
        backgroundColor: getChartBackColor(),
        padding: '5px 10px 0px 0px',
        display: 'flex',
        overflow: 'hidden',
        width: '99%',
        height: '100%',
      }}>
      <Bar
        ref={chartRef}
        options={chartOptionsRef.current}
        data={chartData}
        plugins={[moveChart, currDayTracker, shadeWeekends, shadeMonths]}
      />
      <StyledButtonGroup>
        <ButtonGroup
          variant='contained'
          sx={{
            '& .MuiButtonGroup-grouped:not(:last-of-type)': { borderRight: '0px', borderColor: 'black' },
          }}>
          <StyledDateRangeBtn
            sx={{
              color: dateIntervalDays === 365 ? appColors.itemTextColor : 'rgba(102, 102, 102, 1)',
              borderBottom: dateIntervalDays === 365 ? '2px solid black' : '',
              backgroundColor: dateIntervalDays === 365 ? '#252525' : '#111',
            }}
            onClick={(e) => {
              e.stopPropagation()
              handleDateToggle(365)
            }}>
            1yr
          </StyledDateRangeBtn>
          <StyledDateRangeBtn
            sx={{
              color: dateIntervalDays === 30 ? appColors.itemTextColor : 'rgba(102, 102, 102, 1)',
              borderRight: dateIntervalDays === 30 ? '2px solid black' : '',
              borderBottom: dateIntervalDays === 30 ? '2px solid black' : '',
              backgroundColor: dateIntervalDays === 30 ? '#252525' : '#111',
            }}
            onClick={(e) => {
              e.stopPropagation()
              handleDateToggle(30)
            }}>
            30d
          </StyledDateRangeBtn>
        </ButtonGroup>
        <Button
          sx={{
            color: appColors.itemTextColor,
            backgroundColor: '#252525',
            fontSize: '13px',
            fontWeight: 400,
            height: '18px',
            borderRight: '2px solid black',
            borderBottom: '2px solid black',
            marginLeft: '10px',
          }}
          onClick={(e) => {
            e.stopPropagation()
            handleDateReset()
          }}>
          Reset
        </Button>
      </StyledButtonGroup>
    </Box>
  )
}

export default PersonnelUtilizationGanttChart
