import { chartSeriesColors } from 'utils'
import { appColors } from 'utils'

export function getDailyActivityColor(index, activity) {
  //Required because rotateColor and slideColor have an opacity value
  if (activity === '2K') return appColors.rotateColor.substring(0, 7)
  if (activity === '2J') return appColors.slideColor.substring(0, 7)

  return getChartSeriesColor(index)
}

export function getChartSeriesColor(index) {
  if (typeof index !== 'number') return getRandomColor()

  if (index < 0) {
    return chartSeriesColors[0]
  }

  if (index < chartSeriesColors.length) {
    return chartSeriesColors[index]
  }

  return getRandomColor()
}

export function getRandomColor() {
  let letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function lerpColor(a, b, amount) {
  let ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16,
    ag = (ah >> 8) & 0xff,
    ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16,
    bg = (bh >> 8) & 0xff,
    bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab)

  return '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
}

export function adjustHexOpacity(hexColor, alpha) {
  // Remove the leading '#' if present
  const sanitizedColor = hexColor.replace('#', '')

  // Convert the hex color to RGBA
  const red = parseInt(sanitizedColor.substring(0, 2), 16)
  const green = parseInt(sanitizedColor.substring(2, 4), 16)
  const blue = parseInt(sanitizedColor.substring(4, 6), 16)

  // Convert the alpha value from a range of 0-100 to a range of 0-255
  const alphaValue = Math.round((alpha / 100) * 255)

  // Convert RGBA values to a new hex string
  const updatedHex = `#${((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1)}${alphaValue.toString(
    16,
  )}`

  return updatedHex
}