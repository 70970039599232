import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ParametersRoadmapGrid = () => {
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  const getParametersData = useInnovaAxios({
    url: '/kpi/parameterRoadmap',
    timeout: 60000,
  })

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('parametersRoadmapGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.parametersRoadmapKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const dataResponse = await getParametersData({
      wellList: array2pipestr(selectedWells),
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? dataResponse.data : [])
    }

    setTimeout(() => {
      autoSizeColumns()
    }, 500)
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })

  const defaultColDef = {
    resizable: true,
    sortable: false,
    autoHeight: false,
    editable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      excelMode: 'windows',
    },
    cellStyle: centerAlignCell,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    setTimeout(() => {
      autoSizeColumns()
    }, 200)
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
        colId: 'md',
        valueGetter: (params) => {
          return `${numberWithCommasDecimals(params.data?.md.min, 2)} - ${numberWithCommasDecimals(
            params.data?.md.max,
            2,
          )}`
        },
      },
      {
        headerName: `Wellname`,
        colId: 'wellName',
        field: 'wellName',
      },
      {
        headerName: `Phase`,
        colId: 'phase',
        field: 'phase',
      },
      {
        headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'holeSize',
        valueGetter: (params) => {
          if (!params.data?.holeSize.avg) return ''
          return numberWithCommasDecimals(params.data?.holeSize.avg, 3)
        },
      },
      {
        headerName: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        colId: 'rop',
        valueGetter: (params) => {
          if (!params.data?.rop.avg) return ''
          return numberWithCommasDecimals(params.data?.rop.avg, 2)
        },
      },
      {
        headerName: `WOB (${getUnitsText(UNITS_FOR.Weight)})`,
        colId: 'wob',
        valueGetter: (params) => {
          if (!params.data?.wob.avg) return ''
          return numberWithCommasDecimals(params.data?.wob.avg, 2)
        },
      },
      {
        headerName: `TQ (${getUnitsText(UNITS_FOR.Torque)})`,
        colId: 'tq',
        valueGetter: (params) => {
          if (!params.data?.tq.avg) return ''
          return numberWithCommasDecimals(params.data?.tq.avg, 2)
        },
      },
      {
        headerName: `RPM`,
        colId: 'rpm',
        valueGetter: (params) => {
          if (!params.data?.rpm.avg) return ''
          return numberWithCommasDecimals(params.data?.rpm.avg, 2)
        },
      },
      {
        headerName: `Flow (${getUnitsText(UNITS_FOR.FlowRate)})`,
        colId: 'flowRate',
        valueGetter: (params) => {
          if (!params.data?.flowRate.avg) return ''
          return numberWithCommasDecimals(params.data?.flowRate.avg, 2)
        },
      },
      {
        headerName: `Diff P (${getUnitsText(UNITS_FOR.Pressure)})`,
        colId: 'diffPress',
        valueGetter: (params) => {
          if (!params.data?.diffPress.avg) return ''
          return numberWithCommasDecimals(params.data?.diffPress.avg, 2)
        },
      },
    ],
    [getUnitsText], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('parametersRoadmapGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'ParametersRoadmap.xlsx',
            sheetName: 'ParametersRoadmap',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ];
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        
        rowData={data}
        loading={isLoading}
        columnDefs={sortColDefs(columnDefs, 'parametersRoadmapGrid')}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        headerHeight={30}
      />
    </div>
  )
}

export default ParametersRoadmapGrid
