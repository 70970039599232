import React, { useState, useRef, useEffect, useMemo } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Papa from 'papaparse'
import { AgGridReact } from 'ag-grid-react'
import useUploadDataAckFile from 'components/common/hooks/useUploadDataAckFile'
import { Backdrop, CircularProgress } from '@mui/material'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import useCurveImportMnemonics from 'components/common/hooks/useCurveImportMnemonics'

const OptionsGrid = ({ optionsGridApi }) => {
  const { getAgGridTheme } = useInnovaTheme()

  const onGridReady = (params) => {
    optionsGridApi.current = params.api
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  let columnDef = [
    {
      field: 'isUtc',
      colId: 'isUtc',
      headerName: 'Time in UTC',
      cellEditor: 'agCheckboxCellEditor',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
    {
      field: 'mode',
      colId: 'mode',
      headerName: 'Import Mode',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
          values: ['overwrite', 'fill'],
        }
      },
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
    },
  ]

  return (
    <div
      className={getAgGridTheme()}
      style={{ width: '100%', height: '100%', maxHeight: '80px', marginBottom: '10px' }}>
      <AgGridReact
        rowData={[{ isUtc: true, mode: 'overwrite' }]}
        columnDefs={columnDef}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        headerHeight={30}
        onGridReady={onGridReady}
      />
    </div>
  )
}

const DataAckFileImportModal = ({ selectedConfig, onClose }) => {
  const _isMounted = useRef()
  const optionsGridApi = useRef(null)
  const isLoadingRef = useRef(false)
  const [files, setFiles] = useState(null)
  const [parsedFileData, setParsedFileData] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [isLoading, setLoading] = useState(false)
  const colDefs = useRef([])
  const uploadFile = useUploadDataAckFile()
  const pinnedTopRowData = useRef([])
  const fileInputRef = useRef(null)
  const {curveImportMnemonics} = useCurveImportMnemonics()

  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    processCsvFile(files)
  }, [files]) // eslint-disable-line react-hooks/exhaustive-deps

  const getOptions = () => {
    const importMap = {
      channelMap: [],
      isDateTimeCompound: false,
      isDateTimeUtcTime: true,
      dateFormat: '',
      dateIndex: -1,
      timeIndex: -1,
      mode: 'overwrite',
      errMsg: '',
    }

    if (!pinnedTopRowData.current) return importMap

    if (optionsGridApi.current) {
      optionsGridApi.current.forEachNode((node) => {
        if (node.data) {
          importMap.mode = node.data.mode
          importMap.isDateTimeUtcTime = node.data.isUtc
        }
      })
    }

    let timeColData = null
    let dateColData = null
    let dateTimeColData = null

    let colIndex = -1
    for (const property in pinnedTopRowData.current) {
      colIndex++
      let mnemonic = curveImportMnemonics.find((mn) => mn.label === pinnedTopRowData.current[property])
      if (!mnemonic) continue

      if (mnemonic.isMnemonicCol) {
        importMap.channelMap.push({
          colIdx: colIndex,
          fieldName: mnemonic.tag,
        })
      }

      if (mnemonic.isTimeCol) {
        timeColData = cloneDeep(mnemonic)
        timeColData.colIndex = colIndex
      }

      if (mnemonic.isDateCol) {
        dateColData = cloneDeep(mnemonic)
        dateColData.colIndex = colIndex
        importMap.dateFormat = mnemonic.tag
      }

      if (mnemonic.isDateTimeCol) {
        dateTimeColData = cloneDeep(mnemonic)
        dateTimeColData.colIndex = colIndex
        importMap.dateFormat = mnemonic.tag
      }
    }

    if (dateTimeColData && dateColData) {
      importMap.errMsg = 'Multiple date columns found'
    }

    if (dateTimeColData && !dateColData && timeColData) {
      importMap.errMsg = 'Date Time column found and separate time col'
    }

    if (!dateTimeColData && dateColData && !timeColData) {
      importMap.errMsg = 'No time column found'
    }

    if (!dateTimeColData && !dateColData && timeColData) {
      importMap.errMsg = 'No date column found'
    }

    if (importMap.errMsg === '') {
      if (dateTimeColData) {
        importMap.isDateTimeCompound = false
        importMap.dateIndex = dateTimeColData.colIndex
      }

      if (!dateTimeColData && dateColData && timeColData) {
        importMap.isDateTimeCompound = true
        importMap.dateIndex = dateColData.colIndex
        importMap.timeIndex = timeColData.colIndex
        importMap.dateFormat = importMap.dateFormat + ' ' + timeColData.tag
      }
    }

    return importMap
  }

  const onClickImport = async (data) => {
    const options = getOptions()

    if (!files) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'No file selected',
      })
      return
    }

    if (!selectedConfig) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'No config selected',
      })
      return
    }

    if (options.errMsg !== '') {
      setStatus({
        show: true,
        severity: 'error',
        message: options.errMsg,
      })
      return
    }

    if (options.channelMap.length === 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'No mnemonics selected',
      })
      return
    }

    if (options.isDateTimeCompound && options.timeIndex < 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Time index < 0',
      })
      return
    }

    if (options.dateIndex < 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Date index < 0',
      })
      return
    }

    const dataKeys = Object.keys(parsedFileData[0])
    let checkDate = false
    if (options.isDateTimeCompound) {
      let dateTimeFormat = options.dateFormat.split(' ')[0]

      let momentDate = moment(
        parsedFileData[0][dataKeys[options.dateIndex]] + ' ' + parsedFileData[0][dataKeys[options.timeIndex]],
        [dateTimeFormat + ' HH:mm:ss', dateTimeFormat + ' H:mm:ss'], // allow for 1 or 2 hours digits
        false, // would prefer true when deployed, but single digit days and months are not supported in strict mode
      )
      checkDate = momentDate.isValid()
    } else {
      let momentDate = moment(parsedFileData[0][dataKeys[options.dateIndex]], options.dateFormat, true)
      checkDate = momentDate.isValid()
    }

    if (!checkDate) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Date format error',
      })
      return
    }

    if (!_isMounted.current) return
    if (isLoadingRef.current) return
    isLoadingRef.current = true
    setLoading(true)

    let payload = {
      map: options,
      wellName: selectedConfig.actualWell,
      dataStore: selectedConfig.dataStore,
    }

    const res = await uploadFile(files, payload)
    isLoadingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: res.messsage,
      })
      return
    }

    if (!res.data) return

    if (Array.isArray(res.data?.warnings) && res.data.warnings.length > 0) {
      let warningsStr = ''
      for (let i = 0; i < res.data.length; i++) {
        warningsStr += res.data.warnings[i] + '\n'
      }

      setStatus({
        show: true,
        severity: 'info',
        message: warningsStr,
      })
      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: 'File uploading',
    })
    return
  }

  function processCsvFile(file) {
    if (!file) return

    // code if we need to impose a file size limit:
    // While PapaParse can parse larger files,
    // it appears to be browser dependent and the error message is
    // incorrect (it reports 'file empty' when it is not empty)
    // if (file.size > 512000000) {
    //     setStatus({
    //       show: true,
    //       severity: 'error',
    //       message: 'CSV file is larger than 512MB. Please select a smaller file.',
    //     })
    //     return
    // }

    // preview the first 10MB of the file, to get the column definitions,
    // and prevent papaparse from throwing an error if it can't parse the entire file
    const CHUNK_SIZE = 1024 * 1024 * 10
    const start = 0
    const end = Math.min(CHUNK_SIZE, file.size)
    const filepart = file.slice(start, end)

    Papa.parse(filepart, {
      header: true,
      dynamicTyping: false,
      skipEmptyLines: true,
      complete: (result) => {
        if (result.data && result.data.length > 0) {
          getColDefs(result.data)

          let parsedFileData = []
          for (let i = 0; i < result.data.length; i++) {
            let row = result.data[i]

            //These might not be the only names for holeDepth and bitPosition
            if (
              (row.hasOwnProperty['holeDepth'] && row['holeDepth'] === -999.25) ||
              (row.hasOwnProperty['bitPosition'] && row['bitPosition'] === -999.25)
            ) {
              continue
            }

            parsedFileData.push(row)
          }

          if (!parsedFileData || parsedFileData.length < 0) {
            return
          }

          setParsedFileData(result.data)
          return
        }

        setStatus({
          show: true,
          severity: 'error',
          message: 'CSV file empty',
        })
      },
      error: (error) => {
        setStatus({
          show: true,
          severity: 'error',
          message: `Error parsing CSV: ${error.message}`,
        })
      },
    })
  }

  const onSelectFiles = (event) => {
    if (!event.target.files) return
    if (!event.target.files.length === 0) return

    const file = event.target.files[0]
    if (!file) return

    const allowedExtensions = ['.csv']
    const fileExtension = file.name.split('.').pop()

    if (allowedExtensions.includes(`.${fileExtension}`)) {
      setFiles(file)
      return
    }

    setStatus({
      show: true,
      severity: 'error',
      message: 'Please select a valid file with .csv extension.',
    })
  }

  const handleCloseStatus = () => {
    setStatus((priorStatus) => {
      // if the last status is the successful import message, close the dialog after showing that success message
      if (priorStatus.show === true && priorStatus.severity === 'success' && priorStatus.message === 'File uploading') {
        handleCloseDialog()
      }
      return { show: false, severity: 'info', message: '' }
    })
  }

  const handleCloseDialog = async () => {
    onClose()
  }

  const handleClickSelectFiles = () => {
    fileInputRef.current.click()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (!params?.node?.rowPinned === 'top') return

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()

            pinnedTopRowData.current[focusedCell.column.colDef.field] = ''
            gridApi.current.setGridOption('pinnedTopRowData', [pinnedTopRowData.current])
          }
        }
        return false
      },
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: null,
    }
  }, [])

  const lookupMnemonicFromColName = (colName) => {
    if (typeof colName !== 'string') return ''
    if (colName === '') return ''
    colName = colName.toLowerCase()

    if (colName.includes('yyyy/mm/dd') && !colName.includes('hh:mm')) {
      return 'Date YYYY/MM/DD'
    }

    if (colName.includes('mm/dd/yyyy') && !colName.includes('hh:mm')) {
      return 'Date MM/DD/YYYY'
    }

    if (colName.includes('dd/mm/yyyy') && !colName.includes('hh:mm')) {
      return 'Date DD/MM/YYYY'
    }

    if (colName.includes('yyyy-mm-dd') && !colName.includes('hh:mm')) {
      return 'Date YYYY-MM-DD'
    }

    if (colName.includes('hh:mm') && !colName.includes('yyyy')) {
      return 'Time HH:MM:SS'
    }

    if (colName.includes('hole depth') || colName.includes('holedepth') || colName.includes('hole_depth')) {
      return 'Hole Depth'
    }

    if (colName.includes('bit depth') || colName.includes('bitdepth') || colName.includes('bit_depth')) {
      return 'Bit Depth'
    }

    if (colName.includes('weight on bit') || colName.includes('wob')) {
      return 'WOB'
    }

    if (colName.includes('rpm')) {
      return 'RPM'
    }

    if (colName.includes('standpipe pressure')) {
      return 'Pressure'
    }

    if (colName.includes('diff')) {
      return 'Diff Pressure'
    }

    if (colName === 'pressure') {
      return 'Pressure'
    }

    if (colName.includes('hookload') || colName.includes('hook load') || colName.includes('hkld')) {
      return 'Hookload'
    }

    if (colName.includes('flow')) {
      return 'Flow'
    }

    if (colName.includes('block')) {
      return 'Block Position'
    }

    if (colName.includes('gamma')) {
      return 'Gamma'
    }

    if (colName.includes('gravity') || colName === 'gtf') {
      return 'GTF'
    }

    if (colName === 'mtf') {
      return 'MTF'
    }

    if (colName.includes('torque')) {
      return 'Torque'
    }

    if (colName === 'cont_inc') {
      return 'Cont Inc'
    }

    if (colName === 'cont_azi') {
      return 'Cont Azi'
    }

    if (colName.includes('rop') || colName.includes('penetration')) {
      return 'ROP'
    }

    if (colName.includes('temperature')) {
      return 'Temperature'
    }

    return ''
  }

  const getColDefs = (parsedFileData) => {
    if (!parsedFileData) return
    if (!Array.isArray(parsedFileData)) return
    if (parsedFileData.length === 0) return

    let colDef = []
    pinnedTopRowData.current = {}

    for (const property in parsedFileData[0]) {
      let mnemonic = lookupMnemonicFromColName(property)
      // if the mnemonic is already a value for one of the column keys in the pinned row, ignore any duplicate mnemonics found in the file
      if (Object.keys(pinnedTopRowData.current).some((key) => pinnedTopRowData.current[key] === mnemonic)) mnemonic = ''
      pinnedTopRowData.current[property] = mnemonic

      colDef.push({
        colId: property,
        field: property,
        headerName: property,
        editable: (params) => params?.node?.rowPinned === 'top',
        cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
        cellEditor: 'agSelectCellEditor',
        cellDataType: 'text',
        cellEditorParams: (params) => {
          return {
            values: curveImportMnemonics?.map((mnemonic) => mnemonic.label),
          }
        },
        valueSetter: (params) => {
          if (!params.newValue) return
          if (typeof params.newValue !== 'string') return
          if (params.value === params.newValue) return

          for (const property in pinnedTopRowData.current) {
            if (pinnedTopRowData.current[property] === params.newValue) {
              pinnedTopRowData.current[property] = ''
            }
          }

          pinnedTopRowData.current[params.colDef.field] = params.newValue
          gridApi.current.setGridOption('pinnedTopRowData', [pinnedTopRowData.current])
        },
      })
    }

    colDefs.current = colDef

    if (gridApi.current) {
      setTimeout(() => gridApi.current.setGridOption('pinnedTopRowData', [pinnedTopRowData.current]), 100)
    }
  }

  return (
    <>
      <Dialog
        maxWidth='xl'
        PaperProps={{
          sx: {
            width: '70vw',
            height: '50vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={handleCloseDialog}>
        <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <DialogTitle>Import Data</DialogTitle>
        <DialogContent style={{ overflow: 'auto' }}>
          <input
            ref={fileInputRef}
            style={{ display: 'none' }}
            type='file'
            accept='.csv, .xlsx, .txt'
            onChange={onSelectFiles}
          />
          <OptionsGrid optionsGridApi={optionsGridApi} />
          <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 90px)' }}>
            <AgGridReact
              rowData={parsedFileData}
              columnDefs={colDefs.current}
              defaultColDef={defaultColDef}
              animateRows={true}
              enableBrowserTooltips={true}
              headerHeight={30}
              onGridReady={onGridReady}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button color='primary' variant='contained' onClick={handleClickSelectFiles}>
            Select files
          </Button>
          <Button color='primary' variant='contained' onClick={onClickImport}>
            Import
          </Button>
          <Button color='primary' variant='contained' onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default DataAckFileImportModal
