import React, { useRef, useEffect, useState } from 'react'
import SectionViewChart from 'components/WellPages/SectionView/SectionViewChart'
import useWellData from 'components/common/hooks/useWellData'
import { getFullGeoSteeringData } from 'utils/GeoSteeringFunctions'
import { cloneDeep } from 'lodash'

const SectionViewDashCard = () => {
  const { wellData, isLoading, getGeoSteeringData, toggleErrorEllipses } = useWellData()
  const geoZones = useRef([])
  const [shouldUpdate, setShouldUpdate] = useState(false)

  useEffect(() => {
    calcGeoZones()
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const calcGeoZones = () => {
    geoZones.current = getFullGeoSteeringData(
      cloneDeep(getGeoSteeringData()),
      wellData?.actualWellData?.verticalSection,
      cloneDeep(wellData?.surveys),
      wellData?.wellPlan?.surveyData,
    )

    setShouldUpdate(!shouldUpdate)
  }

  return (
    <SectionViewChart
      data={wellData}
      isLoading={isLoading}
      refGeosteering={geoZones.current}
      toggleErrorEllipses={toggleErrorEllipses}
    />
  )
}

export default SectionViewDashCard
