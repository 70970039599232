import React from 'react'
import { Box } from '@mui/material'
import { appColors } from 'utils'
import {
  RULER_WIDTH,
  VIEWPORT_PADDING,
  convertUserToPixels,
} from 'components/WellPages/WallplotComposer/Viewport/cssTransformUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const VerticalRuler = ({ pageLayout, units, zoomScale, rulerRef }) => {
  const rulerHeightPx = convertUserToPixels(pageLayout.height, units, zoomScale)
  const majorTickWidth = RULER_WIDTH - 2
  const minorTickWidth = RULER_WIDTH / 4
  const { theme } = useInnovaTheme()

  const ticks = []
  for (let inch = 0; inch <= pageLayout.height; inch += 0.25) {
    const topPosition = convertUserToPixels(inch, units, zoomScale)
    const isMajorTick = inch % 1 === 0
    ticks.push(
      <Box
        key={inch}
        sx={{
          position: 'absolute',
          top: `${topPosition}px`,
          left: isMajorTick ? `0px` : `${majorTickWidth / 2 - minorTickWidth / 2}px`,
          height: isMajorTick && inch > 0 ? '0px' : '1px',
          width: isMajorTick ? `${majorTickWidth}px` : `${minorTickWidth}px`,
          backgroundColor: appColors.headerTextColor,
          color: theme === 'dark' ? '#ffffff' : '#222222',
          textAlign: 'center',
          fontSize: '10px',
          fontWeight: 'bold',
        }}>
        {isMajorTick && inch > 0 ? inch : ''}
      </Box>,
    )
  }

  return (
    <Box
      ref={rulerRef}
      sx={{
        marginTop: `${VIEWPORT_PADDING}px`,
        width: `${RULER_WIDTH}px`,
        position: 'sticky',
        left: '0px',
        top: '0px',
        height: `${rulerHeightPx}px`,
        borderRight: `1px solid ${appColors.headerTextColor}`,
        backgroundColor: theme === 'dark' ? '#222222' : '#ffffff',
        zIndex: 9999,
      }}>
      {ticks}
      <Box
        id='wpc-vertical-cursor'
        sx={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '1px',
          width: '100%',
          backgroundColor: 'red',
        }}
      />
    </Box>
  )
}

export default VerticalRuler
