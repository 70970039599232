import React from 'react'
import { RecoilRoot } from 'recoil'
import 'ag-grid-enterprise'
import { LicenseManager } from 'ag-grid-enterprise'
import ReactGA from 'react-ga4'

import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css' // Optional theme CSS
import 'ag-grid-community/styles/ag-theme-alpine.css' // Optional theme CSS
import './App.css'

import NavBar from './NavBar'
import LandingPage from './LandingPage'
import ActionBar from './ActionBar/ActionBar'
import RecoilData from './RecoilData'
import { ProvideAuth } from 'components/common/hooks/useInnovaAuth'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js'

import { GradientPlugIn, dataLabelPlugin, fillBetweenLinesPlugin, CenterTextPlugIn, customAnnotation, shadingAreaPluginX, ChartBackgroundPlugIn } from 'components/common/ChartJsPlugins/chartJsPlugins'
import UserProfile from 'components/UserProfile/userProfilePage'
import WellListPage from './WellList/WellListPage'
import WellDetailsPage from 'components/WellPages/WellDetailsDashboard/WellDetailsDashboardPage'
import LogisticsPage from 'components/Logistics/LogisticsPage'
import AssetSearchPage from 'components/AssetSearch/AssetSearchPage'
import IncidentDashboardPage from 'components/IncidentDashboard/IncidentDashboardPage'
import WellSeekerUsagePage from 'components/Admin/WellSeeker/WellSeekerUsage/WellSeekerUsagePage'
import { ThemeProvider } from '@mui/material/styles'
import { StyledEngineProvider } from '@mui/material/styles'
import { lightTheme, darkTheme } from './theme'
import MainRouter from './router'
import SurveysPage from './WellPages/SurveyPage/SurveysPage'
import InventoryPage from './WellPages/InventoryPage/InventoryPage'
import UnitsPage from './Admin/Units/UnitsPage'
import DailyReportPage from './WellPages/DailyReportsPages/DailyReportPage'
import SlideSheetPage from './WellPages/SlideSheetPages/SlideSheetPage'
import DrillStringPage from './WellPages/DrillStringPages/DrillStringPage'
import AntiCollisionPage from './WellPages/Anticollision/AntiCollisionPage'
import EdrPage from './EDR/EdrPage'
import UserRolesPage from './Admin/UserRoles/UserRolesPage'
import UserDatabaseAccessPage from './Admin/UsersPage/UserDatabaseAccessPage'
import UsersPage from './Admin/UsersPage/UsersPage'
import DrillLinkPage from './Admin/DrillLink/DrillLinkPage'
import OrganizationsPage from './Admin/Organizations/OrganizationsPage'
import DashboardAdminPage from './Admin/InfrastructureDashboard/DashboardAdminPage'
import IcdsAdminPage from './Admin/Icds/IcdsAdminPage'
import DailyCostPage from './WellPages/DailyCostPage/DailyCostPage'
import ErrorModelsPage from './ErrorModels/ErrorModelsPage'
import ToolOrdersPage from './ToolOrders/ToolOrdersPage'
import DataAcquisitionPage from './DataAcquisition/DataAcquisitionConfigs/DataAcquisitionPage'
import DataStorePage from './DataAcquisition/DataStorePage'
import WitsmlServerPage from './DataAcquisition/WitsmlServerPage'
import MultiWellDashboardPage from './MultiWellDashboard/MultiWellDashboardPage'
import RigNamesPage from './RigNames/RigNamesPage'
import DataQcPage from './DataQc/DataQcPage'
import ObjectValidationPage from './ObjectValidation/ObjectValidationPage'
import InvoiceQcPage from './InvoiceQc/InvoiceQcPage'
import ShippingAddressesPage from './ShippingAddresses/ShippingAddressesPage'
import ShippingTicketPage from './WellPages/ShippingTicketsPages/ShippingTicketsPages'
import PipeTallyPage from './WellPages/PipeTallyPages/PipeTallyPages'
import CRSPage from './CRS/CRSPage'
import PersonnelNamesPage from './PersonnelNames/PersonnelNamesPage'
import AuditDataPage from './AuditData/AuditDataPage'
import InnovaLicenseDeactivationsPage from './InnovaLicenseDeactivations/InnovaLicenseDeactivationsPage'
import InnovaVendorsPage from './InnovaVendors/InnovaVendorsPage'
import ThirdPartyAppCredsPage from './ThirdPartyAppCreds/ThirdPartyAppCredsPage'
import ComponentCatalogPage from './ComponentCatalog/ComponentCatalogPage'
import CentralizerCatalogPage from './ComponentCatalog/CentralizerCatalogPage'
import StopCollarCatalogPage from './ComponentCatalog/StopCollarCatalogPage'
import InnovaCompanysPage from './InnovaCompanys/InnovaCompanysPage'
import InnovaContactsPage from './InnovaContacts/InnovaContactsPage'
import InnovaSoftwareDetailsPage from './InnovaSoftwareDetails/InnovaSoftwareDetailsPage'
import InnovaSoftwareBillingPage from './InnovaSoftwareBilling/InnovaSoftwareBillingPage'
import InnovaLicensingPage from './InnovaLicensing/InnovaLicensingPage'
import CrmSystemPage from './CrmSystem/CrmSystemPage'
import InnovaInvoicesPage from './InnovaInvoices/InnovaInvoicesPage'
import InnovaQuotesPage from './InnovaQuotes/InnovaQuotesPage'
import InnovaAdminUsersPage from './Admin/InnovaAdminUsers/InnovaAdminUsersPage'
import InnovaLicenseRequestPage from './InnovaLicenseRequest/InnovaLicenseRequestPage'
import WellSeekerUpdateFilesPage from './Admin/WellSeeker/WellSeekerUpdateFiles/WellSeekerUpdateFilesPage'
import WellSeekerUpdateConfigPage from './Admin/WellSeeker/WellSeekerUpdateConfig/WellSeekerUpdateConfigPage'
import EngineeringDashboardPage from './WellPages/EngineeringDashboard/EngineeringDashboardPage'
import ApiHealthAdminPage from './Admin/ApiHealthPage/ApiHealthAdminPage'
import DataAcqHealthAdminPage from './Admin/DataAcqHealthPage/DataAcqHealthAdminPage'
import EndpointUsagePage from './Admin/EndpointUsagePage/EndpointUsagePage'
import DatabaseTreePage from './DatabaseTree/DatabaseTreePage'
import BankAccountsPage from './Admin/InnovaBankAccounts/BankAccountsPage'
import EmployeesPage from './Admin/Employees/EmployeesPage'
import AnalyticsPage from './Analytics/AnalyticsPage/AnalyticsPage'
import FileManager from './Admin/FileManager/FileManager'
import MultiDbWellListPage from './MultiDbWellList/MultiDbWellListPage'
import DataAckFileStatusPage from './DataAcquisition/DataAckFileStatus/DataAckFileStatusPage'
import DatabaseRestoreStatusPage from './Admin/DatabaseRestoreStatus/DatabaseRestoreStatusPage'
import DatabaseBackupFilesPage from './Admin/DatabaseBackupFiles/DatabaseBackupFilesPage'
import PersonnelUtilizationPage from './PersonnelUtilization/PersonnelUtilizationPage'
import WellParametersRoadmapPage from './WellPages/WellParametersRoadmap/WellParametersRoadmapPage'
import ConnectionAnalysisPage from './WellPages/ConnectionAnalysis/ConnectionAnalysisPage'
import BidsPage from './Bids/BidsPages'
import AntiCollisionDashStatusPage from 'components/Admin/AntiCollisionDashboardStatus/AntiCollisionDashStatusPage'
import AntiCollisionDashPage from 'components/AntiCollisionDashboard/AntiCollisionDashboardPage'
import WallplotComposerPage from './WellPages/WallplotComposer/WallplotComposerPage'
import DatabaseChangesPage from './Admin/DatabaseChangesPage/DatabaseChangesPage'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Tooltip, Legend, Title)
ChartJS.register(zoomPlugin, annotationPlugin)
ChartJS.register(GradientPlugIn, dataLabelPlugin, fillBetweenLinesPlugin, CenterTextPlugIn, customAnnotation, shadingAreaPluginX, ChartBackgroundPlugIn)

const publicRoutes = [
  {
    path: '/',
    exact: true,
    Component: LandingPage,
  },
]

const privateRoutes = [
  {
    path: '/user-profile',
    Component: UserProfile,
  },
  {
    path: '/well-list',
    Component: WellListPage,
  },
  {
    path: '/anti-collision-dashboard',
    Component: AntiCollisionDashPage,
  },
  {
    path: '/admin/innovaAdmin/acDashboard',
    Component: AntiCollisionDashStatusPage,
  },
  {
    path: '/admin/innovaAdmin/endPointUsage',
    Component: EndpointUsagePage,
  },
  {
    path: '/well-list-multi-db',
    Component: MultiDbWellListPage,
  },
  {
    path: '/multiwell-dashboard',
    Component: MultiWellDashboardPage,
  },
  {
    path: '/assetSearch',
    Component: AssetSearchPage,
  },
  {
    path: '/incidentDashboard',
    Component: IncidentDashboardPage,
  },
  {
    path: '/analytics/analyticsPage',
    Component: AnalyticsPage,
  },
  {
    path: '/admin/bids',
    Component: BidsPage,
  },
  {
    path: '/admin/rigNames',
    Component: RigNamesPage,
  },
  {
    path: '/admin/dataQc',
    Component: DataQcPage,
  },
  {
    path: '/admin/objectQc',
    Component: ObjectValidationPage,
  },
  {
    path: '/admin/invoiceQc',
    Component: InvoiceQcPage,
  },
  {
    path: '/admin/shippingAddresses',
    Component: ShippingAddressesPage,
  },
  {
    path: '/well-pages/tickets',
    Component: ShippingTicketPage,
  },
  {
    path: '/well-pages/pipeTallys',
    Component: PipeTallyPage,
  },
  {
    path: '/well-pages/connectionAnalysis',
    Component: ConnectionAnalysisPage,
  },
  {
    path: '/admin/crs',
    Component: CRSPage,
  },
  {
    path: '/admin/personnelNames',
    Component: PersonnelNamesPage,
  },
  {
    path: '/admin/personnelUtilization',
    Component: PersonnelUtilizationPage,
  },
  {
    path: '/admin/auditData',
    Component: AuditDataPage,
  },
  {
    path: '/innovaAdmin/databaseRestoreStatus',
    Component: DatabaseRestoreStatusPage,
  },
  {
    path: '/innovaAdmin/databaseBackupFiles',
    Component: DatabaseBackupFilesPage,
  },
  {
    path: '/innovaAdmin/innovaAdminUsers',
    Component: InnovaAdminUsersPage,
  },
  {
    path: '/innovaAdmin/licensing/licenseDeactivations',
    Component: InnovaLicenseDeactivationsPage,
  },
  {
    path: '/innovaAdmin/innovaBilling/companys',
    Component: InnovaCompanysPage,
  },
  {
    path: '/innovaAdmin/innovaBilling/contacts',
    Component: InnovaContactsPage,
  },
  {
    path: '/innovaAdmin/innovaBilling/softwareDetails',
    Component: InnovaSoftwareDetailsPage,
  },
  {
    path: '/innovaAdmin/innovaBilling/softwareBilling',
    Component: InnovaSoftwareBillingPage,
  },
  {
    path: '/innovaAdmin/licensing/licenses',
    Component: InnovaLicensingPage,
  },
  {
    path: '/innovaAdmin/licensing/licenseRequest',
    Component: InnovaLicenseRequestPage,
  },
  {
    path: '/innovaAdmin/innovaBilling/invoices',
    Component: InnovaInvoicesPage,
  },
  {
    path: '/innovaAdmin/innovaBilling/quotes',
    Component: InnovaQuotesPage,
  },
  {
    path: '/well-pages/well-page',
    Component: WellDetailsPage,
  },
  {
    path: '/well-pages/anticollision',
    Component: AntiCollisionPage,
  },
  {
    path: '/well-pages/daily-report',
    Component: DailyReportPage,
  },
  {
    path: '/well-pages/inventory',
    Component: InventoryPage,
  },
  {
    path: '/well-pages/surveys',
    Component: SurveysPage,
  },
  {
    path: '/well-pages/slide-sheets',
    Component: SlideSheetPage,
  },
  {
    path: '/well-pages/costs/dailyCostHeaders',
    Component: DailyCostPage,
  },
  {
    path: '/well-pages/parameterRoadmap',
    Component: WellParametersRoadmapPage,
  },
  {
    path: '/well-pages/drill-string',
    Component: DrillStringPage,
  },
  {
    path: '/well-pages/engineering-dashboard',
    Component: EngineeringDashboardPage,
  },
  {
    path: '/well-pages/wallplotComposer',
    Component: WallplotComposerPage,
  },
  {
    path: '/edr',
    Component: EdrPage,
  },
  {
    path: '/logistics',
    Component: LogisticsPage,
  },
  {
    path: '/admin/userAdmin/units',
    Component: UnitsPage,
  },
  {
    path: '/admin/userAdmin/users',
    Component: UsersPage,
  },
  {
    path: '/admin/userAdmin/roles',
    Component: UserRolesPage,
  },
  {
    path: '/admin/userAdmin/databaseAccess',
    Component: UserDatabaseAccessPage,
  },
  {
    path: '/admin/innovaAdmin/orgs',
    Component: OrganizationsPage,
  },
  {
    path: '/admin/innovaAdmin/dashboard',
    Component: DashboardAdminPage,
  },
  {
    path: '/admin/innovaAdmin/icds',
    Component: IcdsAdminPage,
  },
  {
    path: '/admin/innovaAdmin/drillLink',
    Component: DrillLinkPage,
  },
  {
    path: '/admin/innovaAdmin/apiHealth',
    Component: ApiHealthAdminPage,
  },
  {
    path: '/admin/innovaAdmin/dataAcqHealth',
    Component: DataAcqHealthAdminPage,
  },
  {
    path: '/admin/wellSeekerAdmin',
    Component: WellSeekerUsagePage,
  },
  {
    path: '/admin/wellSeekerUpdateFiles',
    Component: WellSeekerUpdateFilesPage,
  },
  {
    path: '/admin/wellSeekerUpdateConfig',
    Component: WellSeekerUpdateConfigPage,
  },
  {
    path: '/errorModels',
    Component: ErrorModelsPage,
  },
  {
    path: '/toolOrders',
    Component: ToolOrdersPage,
  },
  {
    path: '/dataAcquisition',
    Component: DataAcquisitionPage,
  },
  {
    path: '/dataStores',
    Component: DataStorePage,
  },
  {
    path: '/witsmlServers',
    Component: WitsmlServerPage,
  },
  {
    path: '/dataAckFileHistory',
    Component: DataAckFileStatusPage,
  },
  {
    path: '/databaseTree',
    Component: DatabaseTreePage,
  },
  {
    path: '/admin/bankRec/accounts',
    Component: BankAccountsPage,
  },
  {
    path: '/admin/bankRec/employees',
    Component: EmployeesPage,
  },
  {
    path: '/admin/innovaAdmin/crmSystem',
    Component: CrmSystemPage,
  },
  {
    path: '/admin/innovaAdmin/innovaVendors',
    Component: InnovaVendorsPage,
  },
  {
    path: '/admin/thirdPartyAppCreds',
    Component: ThirdPartyAppCredsPage,
  },
  {
    path: '/admin/compCatalog',
    Component: ComponentCatalogPage,
  },
  {
    path: '/admin/centralizerCatalog',
    Component: CentralizerCatalogPage,
  },
  {
    path: '/admin/stopCollarCatalog',
    Component: StopCollarCatalogPage,
  },
  {
    path: '/admin/fileManager',
    Component: FileManager,
  },
  {
    path: '/admin/databaseChanges',
    Component: DatabaseChangesPage,
  }
]

//Google analytics
const MEASUREMENT_ID = 'G-W7H2RW01R9'
ReactGA.initialize(MEASUREMENT_ID)

const Main = () => {
  const { theme } = useInnovaTheme()

  return <div className='App'>
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <MainRouter publicRoutes={publicRoutes} privateRoutes={privateRoutes}>
        <RecoilData />
        <NavBar />
        <ActionBar />
      </MainRouter>
    </ThemeProvider>
  </div>
}

const App = () => {
  LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-059235}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Innova_Drilling_&_Intervention}_is_granted_a_{Multiple_Applications}_Developer_License_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_June_2025}____[v3]_[01]_MTc1MTA2NTIwMDAwMA==b748c77269693b250373b5af8e2f1253',
  )

  return (
    <RecoilRoot>
      <ProvideAuth>
        <StyledEngineProvider injectFirst>
          <Main />
        </StyledEngineProvider>
      </ProvideAuth>
    </RecoilRoot>
  )
}

export default App
