import React, { useRef, useMemo, useEffect, useCallback } from 'react'
import { Box, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { cloneDeep } from 'lodash'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SlotCoordsGrid = ({ selectedTemplate, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const inputRow = useRef({})
  const selectedTemplateRef = useRef(selectedTemplate)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    selectedTemplateRef.current = cloneDeep(selectedTemplate)

    if (gridApi.current) {
      gridApi.current.setGridOption(
        'pinnedBottomRowData',
        selectedTemplateRef.current?.geometry === 'SINGLE' ? [inputRow.curent] : [],
      )
    }
  }, [selectedTemplate])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    pinnedBottomRowData: selectedTemplateRef.current?.geometry === 'SINGLE' ? [inputRow.current] : [],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        inputRow.current = { ...event.data }
        handleAddRow()
        return
      }

      let coordInput = 'LOCAL'
      if (event.column.colDef.colId === 'gridNorth' || event.column.colDef.colId === 'gridEast') {
        coordInput = 'GRID'
      }

      let payload = {
        action: 'update',
        data: { ...event.data, coordInput: coordInput },
      }

      handleUpdate(payload)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const isPinnedRowDataCompleted = useCallback(() => {
    if (!inputRow.current?.hasOwnProperty('slotName')) return false
    if (inputRow.current?.hasOwnProperty('localNorth') && inputRow.current?.hasOwnProperty('localEast')) {
      return true
    }

    if (inputRow.current?.hasOwnProperty('gridNorth') && inputRow.current?.hasOwnProperty('gridEast')) {
      return true
    }

    return false
  }, [])

  const isEmptyPinnedCell = useCallback(({ node, value }) => {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }, [])

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [centerAlignCell])

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('slotCoordsGrid', 'colLayout', colLayout)
    }
  }

  const handleAddRow = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return

    if (inputRow.current?.hasOwnProperty('localNorth') && inputRow.current?.hasOwnProperty('localEast')) {
      inputRow.current.coordInput = 'LOCAL'
    } else {
      inputRow.current.coordInput = 'GRID'
    }

    handleUpdate({ action: 'add', data: inputRow.current })

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [isPinnedRowDataCompleted, handleUpdate])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const handleDelete = useCallback(async (data) => {
    if (!data) return

    gridApi.current.applyTransaction({
      remove: [data],
    })
  }, [])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {selectedTemplateRef?.current?.geometry === 'SINGLE' && params?.data?.uid ? (
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() =>
                params.node?.rowPinned !== 'bottom'
                  ? handleDelete({ action: 'delete', data: params.data })
                  : handleAddRow()
              }
              size='large'>
              <Iconify
                color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
                icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
                fontSize={16}
              />
            </IconButton>
          ) : (
            <Box sx={{ width: '20px', height: '20px' }} />
          )}
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [handleDelete, handleAddRow],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        field: 'slotName',
        colId: 'slotName',
        headerName: 'Name',
        editable: (params) => selectedTemplateRef?.current?.geometry === 'SINGLE',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'Name...'
          return unescapeHtml(params.value)
        },
      },
      {
        field: 'localNorth',
        colId: 'localNorth',
        headerName: 'Local NS',
        editable: (params) => selectedTemplateRef?.current?.geometry === 'SINGLE',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'LocalNs...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        field: 'localEast',
        colId: 'localEast',
        headerName: 'Local EW',
        editable: (params) => selectedTemplateRef?.current?.geometry === 'SINGLE',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'LocalEw...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        field: 'gridNorth',
        colId: 'gridNorth',
        headerName: `Grid NS`,
        editable: (params) => selectedTemplateRef?.current?.geometry === 'SINGLE',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'GridNs...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
      {
        field: 'gridEast',
        colId: 'gridEast',
        headerName: `Grid EW`,
        editable: (params) => selectedTemplateRef?.current?.geometry === 'SINGLE',
        valueFormatter: (params) => {
          if (params?.node?.rowPinned === 'bottom' && isEmptyPinnedCell(params)) return 'GridEw...'
          return numberWithCommasDecimals(params.value, 2)
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: -100000000,
          max: 100000000,
          precision: 3,
        },
      },
    ],
    [actionIconRenderer, isEmptyPinnedCell],
  )

  const getGridData = () => {
    if (!selectedTemplate) return []
    if (!Array.isArray(selectedTemplate?.slots)) return []

    return selectedTemplate?.slots.map((point) => {
      return {
        uid: point.uid,
        localNorth: point.slotPosition.localNorth,
        localEast: point.slotPosition.localEast,
        gridNorth: point.slotPosition.gridNorth,
        gridEast: point.slotPosition.gridEast,
        slotName: point.slotName,
      }
    })
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={sortColDefs(columnDefs, 'slotCoordsGrid')}
        rowData={getGridData()}
        defaultColDef={defaultColDef}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        rowSelection={'single'}
        headerHeight={30}
        onGridReady={onGridReady}
        getRowId={getRowId}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  )
}

export default SlotCoordsGrid
