import React, { useRef, useEffect, useState, useMemo, forwardRef, useImperativeHandle } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import RigStateDiagDetailsGrid from './RigStateDiagDetailsGrid'
import useInterval from 'components/common/hooks/useInterval'
import { uuidv4 } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const RigStateDiagGrid = ({ dataRef }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const createDiagData = (data) => {
    let diagKeys = []
    if (!data) return []
    for (const key in data) {
      diagKeys.push({
        uid: key,
        proc: key,
      })
    }

    return diagKeys
  }

  useInterval(() => {
    if (!gridApi.current) return

    let existingData = []
    gridApi.current.forEachNode((node) => {
      if (node?.data) existingData.push(node.data)
    })

    gridApi.current.setGridOption('rowData', createDiagData(dataRef.current))
  }, 1000)

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    gridApi.current.setGridOption('rowData', createDiagData(dataRef.current))
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('rigStateDiagGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('rigStateDiagGrid', 'colLayout', colLayout)
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'proc',
        colId: 'proc',
        headerName: 'Category',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        sortable: false,
        resizable: false,
        width: 180,
        pinned: 'left',
        lockPosition: 'left',
        valueFormatter: (params) => {
          if (!params.value) return ''
          if (typeof params.value !== 'string') return ''
          if (!params.value.trim()) return ''

          //Converts camel case variable name to readable string
          let regex = /([a-z])([0-9])(?=[A-Z])/g
          let result = params.value.replace(regex, '$1 $2 ')
          result = result.replace(/([a-z])([A-Z])/g, '$1 $2')
          result = result.charAt(0).toUpperCase() + result.slice(1)

          return result
        },
        cellRenderer: 'agGroupCellRenderer',
      },
    ],
    [],
  )

  const getProperties = (obj, owner) => {
    let properties = []
    for (const key in obj) {
      properties.push({
        uid: `${owner} - ${key}`,
        name: `${owner} - ${key}`,
        value: obj[key],
      })
    }
    return properties
  }

  const createDetailData = (key) => {
    if (!dataRef.current) return []
    if (!dataRef.current.hasOwnProperty(key)) return []

    let details = []
    for (const v in dataRef.current[key]) {
      if (typeof dataRef.current[key][v] === 'object') {
        details.push(...getProperties(dataRef.current[key][v], v))
        continue
      }

      details.push({
        uid: v,
        name: v,
        value: dataRef.current[key][v],
      })
    }
    return details
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    const gridUuid = useRef(uuidv4())

    useImperativeHandle(ref, () => {
      return {
        refresh(p) {
          const detailApi = gridApi.current.getDetailGridInfo(gridUuid.current)
          if (!detailApi) return true
          detailApi.api.applyTransaction({
            update: createDetailData(params.data?.uid),
          })

          return true
        },
      }
    })

    return (
      <Box sx={{ height: '100%', width: '100%' }}>
        <RigStateDiagDetailsGrid
          data={createDetailData(params.data?.uid)}
          masterGridApi={gridApi.current}
          uuid={gridUuid.current}
        />
      </Box>
    )
  })

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        animateRows={true}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getContextMenuItems={getContextMenuItems}
        masterDetail={true}
        detailCellRenderer={DetailCellRenderer}
      />
    </div>
  )
}

export default RigStateDiagGrid
