import React from 'react'
import { Bar } from 'react-chartjs-2'
import Box from '@mui/material/Box'
import { appColors } from 'utils'
import moment from 'moment'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const ApiHealthSummaryBarChart = ({ data }) => {
  const { theme, getChartBackColor } = useInnovaTheme()
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      annotation: {},
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: true,
        text: 'API Errors & Warnings',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return [
              `${new moment(context.parsed.x).format().substring(0, 10)} date`,
              `           ${context.parsed.y} count`,
            ]
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        type: 'time',
        time: {
          unit: 'day',
        },
        title: {
          display: false,
        },
        grid: {
          color: '#404040',
        },
        stacked: true,
      },
      y: {
        beginAtZero: true,
        reverse: false,
        title: {
          display: true,
          text: 'Count',
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        stacked: true,
      },
    },
  }

  const getDatasetIndex = (logItemLevel, logItemType) => {
    switch (logItemType) {
      case 'api':
        if (logItemLevel === 'error') return 0
        if (logItemLevel === 'warn') return 1
        break
      case 'model':
        if (logItemLevel === 'error') return 2
        if (logItemLevel === 'warn') return 3
        break
      case 'store':
        if (logItemLevel === 'error') return 4
        if (logItemLevel === 'warn') return 5
        break
      case 'chat':
        if (logItemLevel === 'error') return 6
        if (logItemLevel === 'warn') return 7
        break
      case 'icds':
        if (logItemLevel === 'error') return 8
        if (logItemLevel === 'warn') return 9
        break
      case 'other':
      default:
        if (logItemLevel === 'error') return 10
        if (logItemLevel === 'warn') return 11
        return -1
    }
  }

  const createChartData = () => {
    const chartJsData = {
      datasets: [
        {
          label: 'Api-Errors',
          data: [],
          borderColor: '#59FF00CF',
          backgroundColor: '#59FF00CF',
          maxBarThickness: 24,
        },
        {
          label: 'Api-Warnings',
          data: [],
          borderColor: '#389F00CF',
          backgroundColor: '#389F00CF',
          maxBarThickness: 24,
        },
        {
          label: 'Model-Errors',
          data: [],
          borderColor: '#00A3FFCF',
          backgroundColor: '#00A3FFCF',
          maxBarThickness: 24,
        },
        {
          label: 'Model-Warnings',
          data: [],
          borderColor: '#00669FCF',
          backgroundColor: '#00669FCF',
          maxBarThickness: 24,
        },
        {
          label: 'Store-Errors',
          data: [],
          borderColor: '#F800FFCF',
          backgroundColor: '#F800FFCF',
          maxBarThickness: 24,
        },
        {
          label: 'Store-Warnings',
          data: [],
          borderColor: '#9B009FCF',
          backgroundColor: '#9B009FCF',
          maxBarThickness: 24,
        },
        {
          label: 'Chat-Errors',
          data: [],
          borderColor: '#FFFF00CF',
          backgroundColor: '#FFFF00CF',
          maxBarThickness: 24,
        },
        {
          label: 'Chat-Warnings',
          data: [],
          borderColor: '#9F9D00CF',
          backgroundColor: '#9F9D00CF',
          maxBarThickness: 24,
        },
        {
          label: 'Icds-Errors',
          data: [],
          borderColor: '#FF0000CF',
          backgroundColor: '#FF0000CF',
          maxBarThickness: 24,
        },
        {
          label: 'Icds-Warnings',
          data: [],
          borderColor: '#9F0000CF',
          backgroundColor: '#9F0000CF',
          maxBarThickness: 24,
        },
        {
          label: 'Other-Errors',
          data: [],
          borderColor: '#FF9900CF',
          backgroundColor: '#FF9900CF',
          maxBarThickness: 24,
        },
        {
          label: 'Other-Warnings',
          data: [],
          borderColor: '#9F6000CF',
          backgroundColor: '#9F6000CF',
          maxBarThickness: 24,
        },
      ],
    }

    if (Array.isArray(data)) {
      data.forEach((item) => {
        if (item.level === 'error' || item.level === 'warn') {
          const date = item.ts.split('T')[0]
          const dsIdx = getDatasetIndex(item.level, item.type)
          const index = chartJsData.datasets[dsIdx].data.findIndex((element) => date === element.x)
          if (index < 0) {
            chartJsData.datasets[dsIdx].data.push({
              x: date,
              y: 1,
            })
          } else {
            chartJsData.datasets[dsIdx].data[index].y += 1
          }
        }
      })
    }
    return chartJsData
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '99.9%',
        overflow: 'hidden',
        backgroundColor: getChartBackColor(),
      }}>
      <Bar type='bar' options={options} data={createChartData()} />
    </Box>
  )
}

export default ApiHealthSummaryBarChart
