import { Box } from '@mui/system'
import EngineeringChart from './EngineeringChart'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { forwardRef, useImperativeHandle, useRef } from 'react'

const CasingStandOffCharts = forwardRef((props, ref) => {
  const standOffChartRef = useRef(null)
  const standOffCentSpacingChartRef = useRef(null)
  const standOffHookloadChartRef = useRef(null)
  const standOffTorqueChartRef = useRef(null)
  const standOffDLSChartRef = useRef(null)
  const { getUnitsText } = useUnits()


  useImperativeHandle(ref, () => {
    return {
      getChartType(chartType) {
        if (chartType === 'standOff') return standOffChartRef
        if (chartType === 'standOffCentSpacing') return standOffCentSpacingChartRef
        if (chartType === 'standOffHookload') return standOffHookloadChartRef
        if (chartType === 'standOffTorque') return standOffTorqueChartRef
        if (chartType === 'standOffDLS') return standOffDLSChartRef
        return null
      },
    }
  })

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box ref={standOffChartRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.standOffData}
          showLegend={true}
          showCasing={true}
          title={'Casing Stand Off'}
          xAxisLabel={'Stand Off'}
          units={'%'}
          xBeginAtZero={true}
          printing={true}
        />
      </Box>
      <Box ref={standOffCentSpacingChartRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.standOffCentSpacingData}
          showLegend={true}
          showCasing={true}
          title={'Centralizer Spacing'}
          xAxisLabel={'Spacing'}
          units={getUnitsText(UNITS_FOR.Depth)}
          xBeginAtZero={true}
          printing={true}
        />
      </Box>
      <Box ref ={standOffHookloadChartRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.standOffHookloadData}
          showLegend={true}
          showCasing={true}
          title={'Hookloads'}
          xAxisLabel={'Weight'}
          units={getUnitsText(UNITS_FOR.Weight)}
          xBeginAtZero={true}
          printing={true}
        />
      </Box>
      <Box ref ={standOffTorqueChartRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.standOffTorqueData}
          showLegend={true}
          showCasing={true}
          title={'Torque'}
          xAxisLabel={'Torque'}
          units={getUnitsText(UNITS_FOR.Torque)}
          xBeginAtZero={true}
          printing={true}
        />
      </Box>
      <Box ref ={standOffDLSChartRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.standOffDLSData}
          showLegend={true}
          showCasing={true}
          title={'DLS'}
          xAxisLabel={'DLS'}
          units={getUnitsText(UNITS_FOR.Dogleg)}
          xBeginAtZero={true}
          printing={true}
        />
      </Box>
    </Box>
  )
})

export default CasingStandOffCharts
