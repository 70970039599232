import React, { useEffect, useRef, useState } from 'react'
import { Icon as Iconify } from '@iconify/react'
import { Box } from '@mui/material'
import InventoryCatalog from './InventoryCatalog'
import CompCatalog from './CompCatalog'
import StopCollarCatalog from './StopCollarCatalog'
import CasingCentralizersCatalog from './CasingCentralizersCatalog'
import { checkFeature } from 'components/userPermissions'
import { userRoleSelector } from 'atoms'
import { useRecoilValue } from 'recoil'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const CatalogPane = ({ showHideCatalog, catalogVisible, wellName, selectedComponent }) => {
  const _isMounted = useRef(false)
  const [selectedCatalog, setSelectedCatalog] = useState('inventory')
  const userRole = useRecoilValue(userRoleSelector)
  const { getChartBackColor, getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: getChartBackColor(),
        display: 'flex',
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          width: '25px',
          height: '100%',
          backgroundColor: getWindowBarColor(),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
        }}>
        <Box
          onClick={showHideCatalog}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: catalogVisible && selectedCatalog === 'inventory' ? 'bold' : '400',
            color: catalogVisible && selectedCatalog === 'inventory' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!catalogVisible) showHideCatalog()
            if (catalogVisible && selectedCatalog === 'inventory') {
              showHideCatalog()
              return
            }
            setSelectedCatalog('inventory')
          }}>
          Tool Inventory
        </Box>
        <Box
          sx={{
            ...verticalTextStyle,
            fontWeight: catalogVisible && selectedCatalog === 'componentCatalog' ? 'bold' : '400',
            color: catalogVisible && selectedCatalog === 'componentCatalog' ? '#429ceb' : '#f0f0f0',
          }}
          onClick={() => {
            if (!catalogVisible) showHideCatalog()
            if (catalogVisible && selectedCatalog === 'componentCatalog') {
              showHideCatalog()
              return
            }
            setSelectedCatalog('componentCatalog')
          }}>
          Component Catalog
        </Box>
        {(selectedComponent?.type === 'Casing/Tubing' || selectedComponent?.type === 'Liner') &&
        checkFeature(45, userRole?.roleAttributes?.featureId) ? (
          <Box
            sx={{
              ...verticalTextStyle,
              fontWeight: catalogVisible && selectedCatalog === 'stopCollar' ? 'bold' : '400',
              color: catalogVisible && selectedCatalog === 'stopCollar' ? '#429ceb' : '#f0f0f0',
            }}
            onClick={() => {
              if (!catalogVisible) showHideCatalog()
              if (catalogVisible && selectedCatalog === 'stopCollar') {
                showHideCatalog()
                return
              }
              setSelectedCatalog('stopCollar')
            }}>
            Stop Collars
          </Box>
        ) : null}
        {(selectedComponent?.type === 'Casing/Tubing' || selectedComponent?.type === 'Liner') &&
        checkFeature(46, userRole?.roleAttributes?.featureId) ? (
          <Box
            sx={{
              ...verticalTextStyle,
              fontWeight: catalogVisible && selectedCatalog === 'casingCentralizers' ? 'bold' : '400',
              color: catalogVisible && selectedCatalog === 'casingCentralizers' ? '#429ceb' : '#f0f0f0',
            }}
            onClick={() => {
              if (!catalogVisible) showHideCatalog()
              if (catalogVisible && selectedCatalog === 'casingCentralizers') {
                showHideCatalog()
                return
              }
              setSelectedCatalog('casingCentralizers')
            }}>
            Centralizers
          </Box>
        ) : null}
      </Box>

      {catalogVisible ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'calc(100% - 25px)',
            height: '100%',
          }}>
          {selectedCatalog === 'inventory' ? <InventoryCatalog wellName={wellName} /> : null}
          {selectedCatalog === 'componentCatalog' ? <CompCatalog /> : null}
          {selectedCatalog === 'stopCollar' &&
          (selectedComponent?.type === 'Casing/Tubing' || selectedComponent?.type === 'Liner') ? (
            <StopCollarCatalog selectedCasing={selectedComponent} />
          ) : null}
          {selectedCatalog === 'casingCentralizers' &&
          (selectedComponent?.type === 'Casing/Tubing' || selectedComponent?.type === 'Liner') ? (
            <CasingCentralizersCatalog selectedCasing={selectedComponent} />
          ) : null}
        </Box>
      ) : null}
    </Box>
  )
}

export default CatalogPane
