import React, { useState, useEffect, useRef, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Icon as Iconify } from '@iconify/react'
import { Tooltip, Box, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import { appColors } from 'utils'
import SearchBar from 'components/common/SearchBar'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import { selectedAssetSearchAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { debounce } from 'lodash'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledIconHeader = styled(IconButton)({
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'center',
})

const AssetSearchToolSnGrid = ({
  setFilteredToolData,
  toolData,
  showHidePane,
  showControls,
  dateRange,
  setDateRange,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [searchText, setSearchText] = useState('')
  const setSelectedAsset = useSetRecoilState(selectedAssetSearchAtom)
  const { getAgGridTheme, searchBarStyle, getChartBackColor, getWindowBarColor, getTextColor } = useInnovaTheme()

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const actionIconRenderer = useMemo(
    () => (params) => {
      return (
        <React.Fragment>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}>
              <Tooltip
                title={'Search'}
                placement='left'
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                    },
                  },
                }}>
                <StyledIconHeader
                  onClick={() => {
                    setSelectedAsset(params.node.data)
                  }}
                  size='large'>
                  <Iconify color={appColors.headerTextColor} icon='material-symbols:search' />
                </StyledIconHeader>
              </Tooltip>
            </Box>
            {params.node?.rowPinned !== 'bottom' ? (
              <Box style={{ textAlign: 'right', paddingLeft: '8px', paddingRight: '4px', width: '25px' }}>
                {params.node.rowIndex + 1}
              </Box>
            ) : null}
          </Box>
        </React.Fragment>
      )
    },
    [setSelectedAsset],
  )

  const columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        width: 110,
        headerName: '',
        editable: false,
        cellRendererSelector: () => {
          return {
            component: actionIconRenderer,
          }
        },
        cellStyle: {
          display: 'flex',
          justifyContent: 'center',
          paddingLeft: '4px !important',
          paddingRight: '4px !important',
          borderRight: '1px solid gray !important',
        },
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: 'SN#',
        field: 'serialNum',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Tool Type',
        field: 'toolType',
        filter: 'agSetColumnFilter',
        cellStyle: centerAlignCell,
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Used in BHA',
        field: 'usedInBha',
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                color: params.data?.usedInBha ? 'green' : 'red',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.usedInBha ? 'Yes' : ''}
            </span>
          )
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'In Inventory',
        field: 'usedInInv',
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                color: params.data?.usedInInv ? 'green' : 'red',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.usedInInv ? 'Yes' : ''}
            </span>
          )
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
    ],
    [actionIconRenderer],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      flex: 1,
    }
  }, [])

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset search',
        disabled: false,
        action: () => {
          setSelectedAsset(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset dates',
        disabled: false,
        action: () => {
          setDateRange({ dateFrom: null, dateTo: null })
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      'export',
    ]
  }

  function isValidDateTime(dateTime) {
    if (dateTime === null) return false
    const dateObj = new Date(dateTime)
    return dateObj instanceof Date && !isNaN(dateObj)
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) {
      setFilteredToolData(filteredNodes)
    }
  }

  return (
    <Box sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: getWindowBarColor(),
        }}>
        <Box
          onClick={showHidePane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '40px',
          paddingTop: '5px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
          display: showControls ? 'flex' : 'none',
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ flex: 1, marginRight: '8px' }}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateFrom}
              onChange={(date) => {
                if (!isValidDateTime(date)) {
                  setDateRange({ dateFrom: '', dateTo: dateRange.dateTo })
                  return
                }

                let dt = new Date(Date.parse(date))
                setDateRange({ dateFrom: dt.toISOString().substring(0, 10), dateTo: dateRange.dateTo })
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
            />
          </Box>
          <Box sx={{ flex: 1, marginRight: '8px' }}>
            <DatePicker
              views={['year', 'month', 'day']}
              disableMaskedInput
              value={dateRange.dateTo}
              onChange={(date) => {
                if (!isValidDateTime(date)) {
                  setDateRange({ dateFrom: dateRange.dateFrom, dateTo: '' })
                  return
                }

                let dt = new Date(Date.parse(date))
                setDateRange({ dateTo: dt.toISOString().substring(0, 10), dateFrom: dateRange.dateFrom })
              }}
              renderInput={(params) => (
                <TextField variant='standard' {...params} helperText={null} sx={{ svg: { color: getTextColor() } }} />
              )}
              sx={{ width: '150px' }}
            />
          </Box>
        </LocalizationProvider>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
      </Box>
      <Box
        sx={{
          display: showControls ? 'flex' : 'none',
          flexDirection: 'row',
          width: '100%',
          height: 'calc(100% - 65px)',
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={toolData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            rowSelection='multiple'
            enableRangeSelection='true'
            enableBrowserTooltips={true}
            groupDisplayType={'groupRows'}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            getContextMenuItems={getContextMenuItems}
            onFilterChanged={onFilterChanged}
            headerHeight={30}
          />
        </div>
      </Box>
      {!showControls ? (
        <Box
          sx={{
            width: '25px',
            height: '100%',
            backgroundColor: getWindowBarColor(),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Box
            onClick={showHidePane}
            sx={{
              transform: 'rotate(90deg)',
              color: '#f0f0f0',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Tool List
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default AssetSearchToolSnGrid
