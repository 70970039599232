import { getWellstatusList } from "./activitySelector"

export const blankFilterListItem = { label: '', value: 'none' }

export const blankFilterLists = {
  operators: [{ ...blankFilterListItem }],
  states: [{ ...blankFilterListItem }],
  countys: [{ ...blankFilterListItem }],
  rigs: [{ ...blankFilterListItem }],
  formations: [{ ...blankFilterListItem }],
  wellStatus: [{ ...blankFilterListItem }],
  phases: [{ ...blankFilterListItem }],
  jobNumbers: [{ ...blankFilterListItem }],
  holeSize: [{ ...blankFilterListItem }],
  directionalCompany: [{ ...blankFilterListItem }],
  districts: [{ ...blankFilterListItem }],
}

export function createFiltersList(inputArray) {
  let output = [{ label: '', value: '0' }]
  if (!Array.isArray(inputArray)) return output
  if (inputArray.length === 0) return output

  inputArray.forEach((item) => {
    output.push({ label: item, value: item.toString() })
  })

  return output
}

export function getFilterLists(filters) {
  if (!filters) return
  let newFilters = {}
  newFilters.phases = filters.hasOwnProperty('phases')
    ? createFiltersList(filters.phases)
    : [{ ...blankFilterListItem }]
  newFilters.operators = filters.hasOwnProperty('operators')
    ? createFiltersList(filters.operators)
    : [{ ...blankFilterListItem }]
  newFilters.states = filters.hasOwnProperty('states')
    ? createFiltersList(filters.states)
    : [{ ...blankFilterListItem }]
  newFilters.countys = filters.hasOwnProperty('counties')
    ? createFiltersList(filters.counties)
    : [{ ...blankFilterListItem }]
  newFilters.rigs = filters.hasOwnProperty('rigs') ? createFiltersList(filters.rigs) : [{ ...blankFilterListItem }]
  newFilters.formations = filters.hasOwnProperty('formations')
    ? createFiltersList(filters.formations)
    : [{ ...blankFilterListItem }]

  newFilters.jobNumbers = filters.hasOwnProperty('jobNumbers')
    ? createFiltersList(filters.jobNumbers)
    : [{ ...blankFilterListItem }]

  newFilters.holeSize = filters.hasOwnProperty('holeSize')
    ? createFiltersList(filters.holeSize)
    : [{ ...blankFilterListItem }]

  newFilters.directionalCompany = filters.hasOwnProperty('directionalCompanys')
    ? createFiltersList(filters.directionalCompanys)
    : [{ ...blankFilterListItem }]

  newFilters.wellStatus = createFiltersList(getWellstatusList())

  newFilters.districts = filters.hasOwnProperty('district')
    ? createFiltersList(filters.district)
    : [{ ...blankFilterListItem }]

  return newFilters
}
