import React, { useRef, useMemo } from 'react'
import { Dialog } from '@mui/material'
import useAlerts from 'components/common/hooks/useAlerts'
import { AgGridReact } from 'ag-grid-react'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getStringId } from 'components/common/AgGridUtils'

const AlertsModal = ({ onCancel, byUser, byRole, userName, roleId }) => {
  const { loading, alertData, updateAlertsByRole, updateAlertsByUser } = useAlerts(byUser, byRole, userName, roleId)
  const gridApi = useRef(null)
  const gridApiHeader = useRef(null)
  const { getUnitsText } = useUnits()
  const headersRef = useRef(null)
  const alertsRef = useRef(null)

  const { getAgGridTheme } = useInnovaTheme()

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data?.uid
    }
  }, [])

  const getRowIdHeader = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: false,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onGridReadyHeader = (params) => {
    gridApiHeader.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const onFirstDataRenderedHeader = (params) => {
    autoSizeColumnsHeader()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current.autoSizeAllColumns()
    }, 100)
  }

  const autoSizeColumnsHeader = () => {
    if (gridApiHeader.current === null) return
    if (gridApiHeader.current.isDestroyed()) return
    setTimeout(() => {
      gridApiHeader.current.autoSizeAllColumns()
    }, 100)
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const gridOptions = {
    onCellEditingStopped: (params) => {},
  }

  let columnDefs = useMemo(
    () => [
      {
        field: 'uid',
        colId: 'uid',
        headerName: '#',
        cellStyle: centerAlignCell,
      },
      {
        field: 'name',
        colId: 'name',
        headerName: 'Name',
        cellStyle: leftAlignCell,
      },
      {
        field: 'active',
        colId: 'active',
        headerName: 'Active',
        editable: (data) => (data.data.source === 'role' && byUser === 'true' && data.data.active ? false : true),
        cellStyle: centerAlignCell,
      },
      {
        field: 'min',
        colId: 'min',
        headerName: 'Min',
        editable: (data) => data.data.useMin,
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (!params.data.useMin) {
            return '--'
          }
          return numberWithCommasDecimals(params.data.min, 2)
        },
      },
      {
        field: 'max',
        colId: 'max',
        headerName: 'Max',
        editable: (data) => data.data.useMax,
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (!params.data.useMax) {
            return '--'
          }
          return numberWithCommasDecimals(params.data.max, 2)
        },
      },
      {
        field: 'units',
        colId: 'units',
        editable: false,
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (!params.data?.unit) return ''
          if (params.data?.unit === '') return ''
          return getUnitsText(UNITS_FOR[params.data?.unit])
        },
      },
    ],
    [centerAlignCell, leftAlignCell, byUser, getUnitsText],
  )

  let columnDefsHeader = useMemo(
    () => [
      {
        field: 'type',
        colId: 'type',
        headerName: 'Type',
        editable: false,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        field: 'useEmail',
        colId: 'useEmail',
        headerName: 'Email',
        editable: true,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        field: 'timeBeforeTriggerEmail',
        colId: 'timeBeforeTriggerEmail',
        headerName: 'Email Frequency',
        editable: true,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellEditor: 'agNumberCellEditor',
        valueSetter: (params) => {
          if (params.newValue === null || params.newValue <= 0) {
            params.data.timeBeforeTriggerEmail = 1
            return true
          }
          params.data.timeBeforeTriggerEmail = params.newValue
          return true
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 0)
        },
      },
      {
        field: 'emailTimeUnit',
        colId: 'emailTimeUnit',
        headerName: 'Email Time',
        editable: true,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Minutes', 'Hours', 'Days'],
        },
      },
      {
        field: 'usePush',
        colId: 'usePush',
        headerName: 'Push',
        editable: true,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
      },
      {
        field: 'timeBeforeTriggerPush',
        colId: 'timeBeforeTriggerPush',
        headerName: 'Push Frequency',
        editable: true,
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        valueSetter: (params) => {
          if (params.newValue === null || params.newValue <= 0) {
            params.data.timeBeforeTriggerPush = 1
            return true
          }
          params.data.timeBeforeTriggerPush = params.newValue
          return true
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 0)
        },
      },
      {
        field: 'pushTimeUnit',
        colId: 'pushTimeUnit',
        headerName: 'Push Time',
        editable: true,
        cellStyle: centerAlignCell,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Minutes', 'Hours', 'Days'],
        },
      },
    ],
    [centerAlignCell],
  )

  const getAlerts = () => {
    if (!alertData) return []
    if (!alertData.hasOwnProperty('alerts')) return []
    alertsRef.current = Object.values(alertData.alerts) || []
    return alertsRef.current
  }

  const getHeaders = () => {
    if (!alertData) return []
    if (!alertData.hasOwnProperty('notifications')) return []

    headersRef.current = alertData.notifications || []

    return headersRef.current
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        PaperProps={{
          sx: {
            height: '750px',
            width: '850px',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => onCancel()}>
        <DialogTitle>Alerts</DialogTitle>
        <DialogContent>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '150px' }}>
            <AgGridReact
              rowData={loading ? [] : getHeaders()}
              columnDefs={columnDefsHeader}
              defaultColDef={defaultColDef}
              getRowId={getRowIdHeader}
              gridOptions={gridOptions}
              headerHeight={30}
              onGridReady={onGridReadyHeader}
              onFirstDataRendered={onFirstDataRenderedHeader}
            />
          </div>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '450px' }}>
            <AgGridReact
              rowData={loading ? [] : getAlerts()}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              getRowId={getRowId}
              gridOptions={gridOptions}
              headerHeight={30}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => onCancel()} color='secondary'>
            Close
          </Button>
          <Button
            variant='outlined'
            onClick={() => {
              if (byUser !== 'true') {
                updateAlertsByRole(alertsRef.current, headersRef.current)
                onCancel()
              }
              updateAlertsByUser(alertsRef.current, headersRef.current)
              onCancel()
            }}
            color='primary'>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default AlertsModal
