import { SphereGeometry } from 'three'
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js'

const WellSpheres = ({ wellData, display, segments = 16, interval = 1 }) => {
  if (!display) return null
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null

  let spheres = []
  wellData.data.forEach((pt, index) => {
    if (index === 0) return null
    if (index % interval !== 0) return null
    const sphereGeometry = new SphereGeometry(0.7, segments, segments)
    sphereGeometry.translate(pt)
    spheres.push(sphereGeometry)
  })

  if (spheres.length === 0) return null
  const combinedGeometry = spheres.length === 1 ? spheres[0] : BufferGeometryUtils.mergeGeometries(spheres)

  return (
    <mesh>
      <primitive object={combinedGeometry} attach='geometry' />
      <meshBasicMaterial attach='material' color={0x00ff00} transparent={true} opacity={0.75} />
    </mesh>
  )
}

export default WellSpheres
