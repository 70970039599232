export const DPI = 96
export const RULER_WIDTH = 16
export const VIEWPORT_PADDING = 12

export const convertUserToPixels = (userValue, unit, zoom) => {
  let result = userValue * DPI * zoom
  result = unit === 'm' ? (result /= 2.54) : result
  return result
}

export const convertUserToBase = (userValue, unit) => {
  if (unit === 'm') return userValue / 2.54
  return userValue
}

export const convertBaseToUser = (baseValue, unit) => {
  if (unit === 'm') return baseValue * 2.54
  return baseValue
}

export const convertPixelsToUser = (pixelValue, unit, zoom) => {
  let result = pixelValue / DPI / zoom
  result = unit === 'm' ? (result *= 2.54) : result
  return result
}

export const extractRotateFromStyle = (styleString) => {
  const rotateRegex = /rotate\((-?\d+(\.\d+)?)deg\)/
  const match = styleString.match(rotateRegex)
  return match ? match[0] : null
}

export const extractRotateValue = (transformString) => {
  if (!transformString) return 0.0
  if (transformString.length === 0) return 0.0

  const rotateRegex = /rotate\((-?\d+\.?\d*)deg\)/
  const match = transformString.match(rotateRegex)
  if (match && match[1]) {
    return parseFloat(match[1])
  } 
  return 0.0
}

export const createRotateStyle = (rotateValue) => {
  if (rotateValue === 0) {
    return ''
  } 
  return `rotate(${rotateValue}deg)`
}

export const extractTranslate = (styleString) => {
  const translateRegex = /translate\((-?\d+)px,\s*(-?\d+)px\)/
  const match = styleString.match(translateRegex)
  return match ? { leftOffset: Number(match[1]), topOffset: Number(match[2]) } : null
}

