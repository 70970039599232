import React, { useEffect, useState, useCallback } from 'react'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { styled } from '@mui/styles'
import { Box } from '@mui/material'
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout'
import { useResizeDetector } from 'react-resize-detector'
import { Icon as Iconify } from '@iconify/react'
import { useRecoilState } from 'recoil'

import { getItemFromLS, saveItemToLS } from 'utils/localStorage'
import DashboardComponent from 'components/common/ReactGridLayout/DashboardComponent'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { currentInfrastructureChartRangeAtom } from 'atoms'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const layoutIds = ['xl', 'lg', 'md', 'sm', 'xs', 'xxs']

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const InfrastructureDashboard = ({
  uid,
  defaultLayout,
  components,
  showMenu = true,
  refreshChartData,
  fetchWrpStatus,
  toggleAwsRegionModal,
  columns = { xl: 8, lg: 8, md: 4, sm: 2, xs: 2, xxs: 2 },
}) => {
  const [layouts, setLayouts] = useState(getItemFromLS(uid, 'layouts') || defaultLayout)
  // eslint-disable-next-line no-unused-vars
  const [breakpoint, setBreakpoint] = useState('lg')
  const [infrastructureChartRange, setInstrastructureChartRange] = useRecoilState(currentInfrastructureChartRangeAtom)
  const { width: gridWidth, ref: dashRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 50 })
  const [renderedComponents, setRenderedComponents] = useState()
  const { theme, getBackColor } = useInnovaTheme()

  useEffect(() => {
    setRenderedComponents(components)
    setLayouts(getItemFromLS(uid, 'layouts') || defaultLayout)
  }, [components]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleBreakpointChange = (breakpoint) => {
    setBreakpoint(breakpoint)
  }

  const handleLayoutChange = (layout, layouts) => {
    saveItemToLS(uid, 'layouts', layouts)
    setLayouts(layouts)
  }

  const getCompIndexInLayout = useCallback(
    (key) => {
      let index = -1
      layoutIds.forEach((size) => {
        if (layouts[size]) {
          let currentLayout = layouts[size]
          const idx = currentLayout.findIndex((component) => component.i === key)
          if (idx >= 0) {
            index = idx
          }
        }
      })
      return index
    },
    [layouts],
  )

  const resetLayout = () => {
    saveItemToLS(uid, 'layouts', defaultLayout)
    setLayouts(defaultLayout)
  }

  const getMenuItems = () => {
    let actions = [
      {
        icon: (
          <Iconify
            icon='material-symbols:replay-30'
            color={infrastructureChartRange ? 'green' : 'grey'}
            width='30'
            height='30'
          />
        ),
        name: 'Toggle Chart Range',
        onClick: () => {
          setInstrastructureChartRange(!infrastructureChartRange)
        },
      },
      {
        icon: <Iconify icon='pajamas:status-closed' color={'green'} />,
        name: 'Check wrpServer status',
        onClick: () => fetchWrpStatus(),
      },
      {
        icon: <Iconify icon='logos:aws' />,
        name: 'Select AWS region',
        onClick: () => toggleAwsRegionModal(),
      },
      {
        icon: <Iconify icon='lucide:layout-dashboard' />,
        name: 'Reset Layout',
        onClick: () => resetLayout(),
      },
      { icon: <RefreshIcon />, name: 'Refresh', onClick: refreshChartData },
    ]
    return actions
  }

  const GridCards = React.useMemo(() => {
    if (!Array.isArray(renderedComponents)) return null

    return renderedComponents.map((comp) => {
      if (getCompIndexInLayout(comp.key) >= 0) {
        return (
          <Box
            key={comp.key}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme === 'dark' ? '#202020' : '#fff',
              border: '1px solid #000',
            }}>
            <DashboardComponent
              compkey={comp.key}
              title={comp.title}
              TitleComponent={comp.TitleComponent}
              titleLine2={comp.titleLine2}
              titleLine3={comp.titleLine3}
              Component={comp.Component}
              customprops={comp?.customprops || {}}
              titleprops={comp?.titleprops || {}}
              showCloseButton={false}
            />
          </Box>
        )
      } else {
        return null
      }
    })
  }, [getCompIndexInLayout, renderedComponents, theme])

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%', backgroundColor: getBackColor() }} ref={dashRef}>
      <ResponsiveGridLayout
        className='layout'
        width={gridWidth ? gridWidth : 800}
        layouts={layouts}
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={handleBreakpointChange}
        autoSize={true}
        isDraggable
        isRearrangble
        isResizeable
        isBounded
        useCSSTransforms
        rowHeight={150}
        breakpoints={{ xl: 2560, lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
        cols={columns}
        draggableHandle={'.dragHandle'}
        margin={[4, 4]}>
        {GridCards}
      </ResponsiveGridLayout>
      {showMenu ? (
        <StyledMenuIcon>
          <MenuButton actions={getMenuItems()} />
        </StyledMenuIcon>
      ) : null}
    </Box>
  )
}

export default InfrastructureDashboard
