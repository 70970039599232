import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import PdfHeader from './PdfHeader'

const PdfTable = ({ data, headerData, reportSettings }) => {
  const getData = () => {
    if (!Array.isArray(data?.data)) return []
    if (data.multiPage) return data.data.slice(data.multiPageRow ? data.multiPageRow : 1)
    return data.data
  }

  const extractNumberFromWidthStr = (widthStr) => {
    if (typeof widthStr === 'number') return widthStr
    if (typeof widthStr !== 'string') return 0
    widthStr = widthStr.replaceAll('%', '')
    return parseFloat(widthStr)
  }

  const colWidthStr = (width) => {
    return `${width}%`
  }

  const getNumRows = () => {
    if (!Array.isArray(data?.data)) return 0
    return data.data.length
  }

  const getNumCols = () => {
    let rows = getNumRows()
    if (rows === 0) return 0
    if (!Array.isArray(data?.data[0])) return 0
    return data.data[0].length
  }

  const getDefaultColWidth = () => {
    let cols = getNumCols()
    if (cols === 0) return '100%'
    return colWidthStr(100.0 / cols)
  }

  const getColWidthManual = (colIndex) => {
    if (!Array.isArray(data.columnWidths)) return getDefaultColWidth()
    if (colIndex < 0 || colIndex >= data.columnWidths.length) return getDefaultColWidth()
    return data.columnWidths[colIndex]
  }

  const colWidth = (col, index) => {
    if (!col || index < 0) return getDefaultColWidth()

    let colSpan = 1
    if (col.hasOwnProperty('columnSpan') && typeof col.columnSpan === 'number') {
      colSpan = col.columnSpan
    }

    if (col.hasOwnProperty('width') && typeof col.width === 'string') {
      let width = extractNumberFromWidthStr(col.width) * colSpan
      return colWidthStr(width)
    }

    if (data.hasOwnProperty('manualWidth') && data.manualWidth === true) {
      let totalWidth = 0
      for (let i = 0; i < colSpan; i++) {
        totalWidth += extractNumberFromWidthStr(getColWidthManual(index + i))
      }

      if (totalWidth > 100) {
        totalWidth = 100
      }

      return colWidthStr(totalWidth)
    }

    let width = extractNumberFromWidthStr(getDefaultColWidth()) * colSpan
    return colWidthStr(width)
  }

  const getFontSize = (col) => {
    if (col.hasOwnProperty('fontSize')) {
      return col.fontSize
    }
    if (data.hasOwnProperty('fontSize')) {
      return data.fontSize
    }

    return 7
  }

  const textAlignToFlex = (align) => {
    if (align === 'left') return 'start'
    if (align === 'right') return 'flex-end'
    return 'center'
  }

  const getTextAlign = (col) => {
    if (col.hasOwnProperty('textAlign')) {
      return textAlignToFlex(col.textAlign)
    }

    return 'center'
  }

  const isImageValid = (column) => {
    if (!column?.isImage || typeof column?.text !== 'string' || column.text === '') return false
    return true
  }

  const TableCell = ({ column, colIndex, isLastCol, isLastRow }) => {
    return (
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: getTextAlign(column),
          width: colWidth(column, colIndex),
          backgroundColor: column?.isHeader ? reportSettings?.primaryColor : column.hasOwnProperty('backgroundColor') ? column.backgroundColor :'',
          borderRight: isLastCol ? '' : `0.25pt solid ${reportSettings?.borderColor}`,
          borderBottom: isLastRow ? '' : `0.25pt solid ${reportSettings?.borderColor}`,
          padding: 2,
          fontWeight: column.hasOwnProperty('isBold') || column.isHeader ? 'bold' : '',
          fontSize: getFontSize(column),
          color: column.hasOwnProperty('color') ? column.color : reportSettings?.textColor,
          minHeight: 16,
        }}>
        {!column?.isImage ? <Text style ={{flexWrap: 'wrap'}}>{column.text}</Text> : null}
        {column?.isImage && isImageValid(column) ? (
          <Image
            src={column.text}
            style={{
              height: column?.height || 20,
              objectFit: column?.fit || 'contain',
              alignSelf: column?.align || 'center',
            }}></Image>
        ) : null}
      </View>
    )
  }

  const TableRow = ({ row, rowIndex, isLastRow }) => {
    if (!Array.isArray(row)) return null

    return (
      <View
        wrap={false}
        style={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor:
            data.hasOwnProperty('alternatingColors') || rowIndex % 2 === 0 ? '' : reportSettings?.alternatingRowColor,
          width: '100%',
          minHeight: 14,
          borderBottom: isLastRow ? '' : `0.25pt solid ${reportSettings?.borderColor}`,
        }}>
        {row.map((col, colIndex) => (
          <TableCell column={col} colIndex={colIndex} key={colIndex} isLastCol={colIndex === row.length - 1} isLastRow={isLastRow} />
        ))}
      </View>
    )
  }

  const TableHeader = () => {
    if (!data?.hasOwnProperty('multiPage')) return null
    if (!data.multiPage) return null
    if (!Array.isArray(data?.data)) return null

    return (
      <View
        fixed
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: reportSettings?.headerBackgroundColor || '#f1f1f1',
          width: '100%',
          borderBottom: `0.25pt solid ${reportSettings?.borderColor}`,
          borderTop: `0.25pt solid ${reportSettings?.borderColor}`,
        }}>
        {data.data.slice(0, data.multiPageRow ? data.multiPageRow : 1).map((row, rowIndex) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              minHeight: 14,
              borderBottom: `0.25pt solid ${reportSettings?.borderColor}`,
              borderTop: rowIndex === 0 ? `0.25pt solid ${reportSettings?.borderColor}` : '',
            }}
            key={rowIndex}>
            {row.map((col, colIndex) => (
              <TableCell
                column={col}
                colIndex={colIndex}
                key={colIndex}
                isLastCol={colIndex === row.length - 1}
                topBorder={true}
              />
            ))}
          </View>
        ))}
      </View>
    )
  }

  return (
    <React.Fragment>
      {data ? (
        <View
          style={{
            margin: 2,
            padding: 2,
          }}
          break={data.hasOwnProperty('breakHeader') ? data.breakHeader : false}>
          {data.breakHeader ? <PdfHeader headerData={headerData} reportSettings={reportSettings} /> : null}
        </View>
      ) : null}

      {data && getNumRows() > 0 ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'col',
            justifyContent: 'flex-start',
            border: `0.5pt solid ${reportSettings?.borderColor}`,
          }}
          wrap={data.hasOwnProperty('wrap') ? data.wrap : true}
          break={data.hasOwnProperty('break') ? data.break : false}>

            <TableHeader />

          {getData().map((row, rowIndex) => (
            <TableRow row={row} rowIndex={rowIndex} isLastRow={rowIndex === data.data.length - 1} key={rowIndex} />
          ))}
        </View>
      ) : null}
      <View style={{ height: data?.hasOwnProperty('sectionAfter') ? data.sectionAfter : 0 }} />
    </React.Fragment>
  )
}

export default PdfTable
