import React, { useRef, useEffect, useState, useMemo } from 'react'

import Dialog from '@mui/material/Dialog'
import { DialogContent, DialogTitle, DialogActions, Button } from '@mui/material'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WellRankingWeightsModal = ({ onClose, onSubmit, data }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('wellRankingWeightsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellRankingWeightsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
    ]
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.param)
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: true,
      autoHeight: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'param',
        colId: 'param',
        headerName: 'Parameter',
        valueFormatter: (params) => {
          if (!params.value) return ''
          if (typeof params.value !== 'string') return ''
          if (!params.value.trim()) return ''

          //Converts camel case variable name to readable string
          let regex = /([a-z])([0-9])(?=[A-Z])/g
          let result = params.value.replace(regex, '$1 $2 ')
          result = result.replace(/([a-z])([A-Z])/g, '$1 $2')
          result = result.charAt(0).toUpperCase() + result.slice(1)

          return result
        },
      },
      {
        field: 'weight',
        colId: 'weight',
        headerName: 'Weight',
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1,
          precision: 4,
        },
      },
      {
        field: 'active',
        colId: 'active',
        editable: true,
        headerName: 'Active',
        cellEditor: 'agCheckboxCellEditor',
        cellRenderer: 'agCheckboxCellRenderer',
        cellStyle: centerAlignCell,
        valueSetter: (params) => {
          if (params.newValue === params.oldValue) return false
          params.data.active = params.newValue
          return true
        },
      },
    ],
    [centerAlignCell],
  )

  const getGridData = () => {
    if (!gridApi.current) return

    const rowData = []
    gridApi.current.forEachNode((node) => {
      rowData.push(node.data)
    })

    return rowData
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth='xl'
      PaperProps={{
        sx: {
          width: '90vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}>
      <DialogTitle>{`Parameter Weights`}</DialogTitle>
      <DialogContent style={{ overflow: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: 'calc(100% - 15px)',
            maxHeight: '100%',
            border: '2px solid #000',
            borderRadius: '4px',
          }}>
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              rowData={Array.isArray(data) ? data : []}
              columnDefs={sortColDefs(columnDefs, 'wellRankingWeightsGrid')}
              defaultColDef={defaultColDef}
              animateRows={true}
              gridOptions={gridOptions}
              headerHeight={30}
              getRowId={getRowId}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => onSubmit(getGridData())} color='primary'>
          Apply
        </Button>
        <Button variant='outlined' onClick={() => onClose()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WellRankingWeightsModal
