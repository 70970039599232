// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'

import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { parseMsgStrToJson } from 'utils/chatFunctions'
import useInnovaAxios from './useInnovaAxios'
import { checkFeature, checkPermission } from 'components/userPermissions'
import { userUserRoleAtom } from 'atoms'

// chat related recoil atoms
export const chatSocketAtom = atom({
  key: 'chatSocketAtomKey',
  default: null,
})

export const chatMessagesAtom = atom({
  key: 'chatMessagesAtomKey',
  default: [],
})

export const chatUnreadMessagesAtom = atom({
  key: 'chatUnreadMessagesAtomKey',
  default: 0,
})

const defaultChatRoom = { roomId: -1, members: [], contacts: [] }

export const chatSelectedRoomAtom = atom({
  key: 'chatSelectedRoomAtomKey',
  default: defaultChatRoom,
})

export const chatUserOnlineAtom = atom({
  key: 'chatUsersOnlineAtomKey',
  default: [],
})

export const chatNewInboundMessageSelector = selector({
  key: 'chatNewInboundMessage',
  get: ({ get }) => {
    const chatMsgs = get(chatMessagesAtom)
    const unread = get(chatUnreadMessagesAtom)
    return { chatMsgs, unread }
  },
  set: ({ get, set }, newMsg) => {
    const newMsgArray = [...get(chatMessagesAtom), newMsg]
    set(chatMessagesAtom, newMsgArray)
    const newUnread = get(chatUnreadMessagesAtom) + 1
    set(chatUnreadMessagesAtom, newUnread)
  },
})

export const chatRefreshRoomsAtom = atom({
  key: 'chatRefreshRoomCounterKey',
  default: 0,
})

export const chatRefreshChatRoomsSelector = selector({
  key: 'chatRefreshChatRoomsKey',
  get: ({ get }) => {
    const counter = get(chatRefreshRoomsAtom)
    return counter
  },
  set: ({ get, set }, flag) => {
    const counter = get(chatRefreshRoomsAtom) + 1
    set(chatRefreshRoomsAtom, counter)
  },
})

export const ownMessageAtom = atom({
  key: 'ownMessageAtomKey',
  default: null,
})

export const chatCollapsedAtom = atom({
  key: 'chatCollapsedAtomKey',
  default: false,
})

export const chatCollapsedSelector = selector({
  key: 'chatCollapsedSelectorKey',
  get: ({ get }) => {
    const flag = get(chatCollapsedAtom)
    return flag
  },
  set: ({ set }, flag) => {
    set(chatCollapsedAtom, flag)
  },
})

// end chat atoms

var _isInitialized = false // there can be only one

const useChat = () => {
  const _isMounted = useRef(false)
  const [socket, setSocket] = useRecoilState(chatSocketAtom)
  const { user } = useInnovaAuth()
  const isGettingUnreadMessages = useRef(false)
  const chatMessages = useRecoilValue(chatMessagesAtom)
  const [unreadMessages, setUnreadMessages] = useRecoilState(chatUnreadMessagesAtom)
  const [selectedChatRoom, setSelectedChatRoom] = useRecoilState(chatSelectedRoomAtom)
  const [usersOnline, setUsersOnline] = useRecoilState(chatUserOnlineAtom)
  const setNewMessage = useSetRecoilState(chatNewInboundMessageSelector)
  const [refreshChatRoomsFlag, setRefreshChatRooms] = useRecoilState(chatRefreshChatRoomsSelector)
  const userRole = useRecoilValue(userUserRoleAtom)

  const getUnreadChatMsgs = useInnovaAxios({
    url: '/chat/getUnreadMessages',
  })

  useEffect(() => {
    _isMounted.current = true
    if (!_isInitialized && user && user.name?.length > 0) {
      connectChatWebsocket()
      getUnreadMessages()
    }

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && user && user.name?.length > 0) {
      connectChatWebsocket()
      getUnreadMessages()
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const connectChatWebsocket = async () => {
    if (socket === null || (socket && socket.readyState === 3)) {
      if (!user || user?.name?.length < 0) return
      if (_isInitialized) return
      _isInitialized = true

      let url = `${process.env.REACT_APP_ICP_API}/chat/ws?userName=${user.name}`
      url = url.replace('https', 'wss')
      url = url.replace('http', 'ws') // needed when running api locally
      const newSocket = new WebSocket(url)

      newSocket.onopen = () => {}

      newSocket.onmessage = (msg) => {
        const message = parseMsgStrToJson(msg.data)
        if (message === null) return

        if (message.messageTypeStr === 'chatMessage') {
          setNewMessage(message)
          setRefreshChatRooms(true)
        }

        if (message.messageTypeStr === 'usersOnlineList') {
          setUsersOnline(message.message.split('|'))
        }

        if (message.messageTypeStr === 'usersDisconnected') {
          let newUsersOnline = []
          let userCpy = [...usersOnline]
          for (let i = 0; i < userCpy.length; i++) {
            if (userCpy[i] !== message.message) newUsersOnline.push(userCpy[i])
          }
          setUsersOnline([...newUsersOnline])
        }

        if (message.messageTypeStr === 'usersConnected') {
          let newUsersOnline = [...usersOnline]
          if (usersOnline.findIndex((element) => element === message.message) < 0) {
            newUsersOnline.push(message.message)
            setUsersOnline([...newUsersOnline])
          }
        }

        if (message.messageTypeStr === 'messageRead') return
        if (message.messageTypeStr === 'unknown') return
      }

      newSocket.onerror = (e) => {
        console.error('wss error', e)
      }

      newSocket.onclose = (e) => {
        _isInitialized = false // reset global singleton flag
        setSocket(null)

        setTimeout(() => {
          connectChatWebsocket()
        }, 5000)
      }

      setSocket(newSocket)
    }
  }

  const getUnreadMessages = async () => {
    if (!checkFeature(7, userRole?.roleAttributes?.featureId)) return
    if (!checkPermission('canEdit', userRole.roleAttributes?.permissions)) return
    if (isGettingUnreadMessages.current) return

    isGettingUnreadMessages.current = true
    const response = await getUnreadChatMsgs()
    isGettingUnreadMessages.current = false
    if (_isMounted.current && response?.data) {
      if (response.data.hasOwnProperty('unreadMsgs')) {
        setUnreadMessages(response.data.unreadMsgs)
      }
    }

    if (response?.error) {
      if (response.error?.code !== 'ERR_CANCELED') console.error('getChatMsg error', response?.error)
    }
  }

  const sendMessage = (msg) => {
    if (socket === null || typeof msg !== 'object' || !msg.hasOwnProperty('username')) {
      return false
    }

    msg.message = msg.message.replace("'", '&sq;')
    msg.message = msg.message.replace('\\', '\\\\')
    socket.send(JSON.stringify(msg))
  }

  const refreshChatRooms = (refresh) => {
    setRefreshChatRooms(refresh)
  }

  return [
    { chatMessages, unreadMessages, selectedChatRoom, refreshChatRoomsFlag },
    { getUnreadMessages, setSelectedChatRoom, sendMessage, refreshChatRooms },
  ]
}

export default useChat
