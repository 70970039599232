import { createTheme } from '@mui/material/styles'

export const appColors = {
  modalBackColor: '#192734',
  componentBackColor: '#133f60',
  windowBackColor: '#2d2d2d',
  itemBackColor: '#202020',
  headerBackgroundColor: '#D8F2FF',
  borderColor: '#000000',
  headerTextColor: '#C0C0C0',
  itemTextColor: '#429CEB',
  itemTextColorMobile: '#34C9EB',
  accentColor: '#4BB2F9',
  rotateColor: '#59FF00CF',
  slideColor: '#F59B00EF',
  nptColor: '#FF6363EF',
  bhaHandlingColor: '#FFEF00EF',
  dirNptColor: '#FFB35CEF',
  motorColor: '#F59B00',
  mwdColor: '#59FF00CF',
  bitColor: '#FF6363EF',
  surveyTrajectoryColor: '#FF5733',
  planTrajectoryColor: '#0096FF',
  mapClusterColor: '#0782f5',
  mapClusterTextColor: '#FFFFFF',
  itemTextColorDark: '#136cb9',
  itemTextColorDark2: '#115ea2',
  itemTextColorDark3: '#0f518a',
  chartNeonBlue: '#34C9EB',
  avgColor: '#FF00FF',
  pdfTableColor: 'rgb(135, 206, 250, .25)',
  altRowColor: '#EFEFEF',
  pdfBorderColor: 'rgb(135, 206, 250)',
  cleanStatusColor: '#4CBB17',
  dirtyStatusColor: '#FF0000',
  defaultTextColor: '#000000',
}

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#429CEB',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#3C3C3C',
      contrastText: '#000',
    },
    itemBackground: '#fff',
  },
  components: {
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: `1px solid ${appColors.borderColor}`,
          overflow: 'hidden',
          margin: 5,
          width: '100%',
          fontSize: 16,
          height: '32px',
          backgroundColor: '#fff',
          color: appColors.itemTextColor,
          '&:hover': {
            backgroundColor: '#fff',
            borderColor: appColors.accentColor,
          },
          '&$focused': {
            backgroundColor: '#fff',
            borderColor: appColors.accentColor,
          },
        },
        focused: {},
        input: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingLeft: '4px',
          color: appColors.itemTextColor,
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px #fff inset !important`,
            WebkitTextFillColor: `${appColors.itemTextColor}`,
          },
        },
      },
    },
  },
})

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#429CEB',
      contrastText: '#FFF',
    },
    secondary: {
      main: appColors.headerTextColor,
      contrastText: '#fff',
    },
    itemBackground: appColors.modalBackColor,
  },
  components: {
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: appColors.headerTextColor,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: appColors.headerTextColor,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            width: '16px',
            backgroundColor: '#192734',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
            WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#133f60',
          },
        },
      },
    },
    MuiTreeView: {
      styleOverrides: {
        root: {
          '&::-webkit-scrollbar': {
            width: '16px',
            backgroundColor: '#192734',
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
            WebkitBoxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3) !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#133f60',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          border: `1px solid ${appColors.borderColor}`,
          overflow: 'hidden',
          margin: 5,
          fontSize: 16,
          height: '32px',
          width: '100%',
          backgroundColor: appColors.itemBackColor,
          color: appColors.itemTextColor,
          '&:hover': {
            backgroundColor: appColors.itemBackColor,
            borderColor: appColors.accentColor,
          },
          '&$focused': {
            backgroundColor: appColors.itemBackColor,
            borderColor: appColors.accentColor,
          },
        },
        focused: {},
        input: {},
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          paddingLeft: '4px',
          color: appColors.itemTextColor,
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${appColors.windowBackColor} inset !important`,
            WebkitTextFillColor: `${appColors.itemTextColor}`,
          },
        },
      },
    },

  },
})

export const chartSeriesColors = [
  '#5A87C6',
  '#DB3C94',
  '#3B21D9',
  '#B0ED27',
  '#A30A73',
  '#3EE371',
  '#2852D7',
  '#0BF629',
  '#E241DC',
  '#c7b295',
  '#515354',
  '#19C9F9',
  '#124408',
  '#0EB9AF',
  '#0B9649',
  '#518726',
  '#E3C340',
  '#9D9563',
  '#4FDC62',
  '#543851',
  '#1D5C19',
  '#707EDB',
  '#22AEDF',
  '#4DF59E',
  '#70BB7C',
  '#4C60B3',
  '#6C41B1',
  '#FB33EB',
  '#9DB592',
  '#1CE760',
  '#9B6CB2',
  '#DBCFDB',
  '#B8D635',
  '#8A23FA',
  '#51747C',
  '#CE83B3',
]
