import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, IconButton } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const LoadWpcGrid = forwardRef(({ templateData, handleUpdate, handleDelete }, ref) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (!gridApi.current || !templateData || templateData.length < 1) return
    gridApi.current.setGridOption('rowData', templateData)
  }, [templateData])

  useImperativeHandle(ref, () => ({
    getSelRow: () => {
      const row = gridApi.current?.getSelectedRows()
      const index = templateData.findIndex((t) => t.uid === row[0].uid)
      return index
    },
  }))

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api

    gridApi.current.setGridOption('rowData', templateData)

    setTimeout(() => {
      autoSizeColumns()
    }, 100)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const gridOptions = {
    suppressRowClickSelection: false,
  }

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => handleDelete(params.data)}
            size='large'>
            <Iconify color={'red'} icon={'fa-regular:trash-alt'} fontSize={16} />
          </IconButton>
        </Box>
      )
    },
    [handleDelete],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    }),
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'uid',
        colId: 'uid',
        hide: true,
      },
      {
        field: 'actions',
        colId: 'actions',
        width: 40,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerCheckboxSelection: false,
        checkboxSelection: true,
        headerName: 'Well Name',
        field: 'actualWell',
        colId: 'actualWell',
        minWidth: 75,
        editable: false,
        cellStyle: leftAlignCell,
      },
      {
        headerName: 'Template Name',
        field: 'name',
        colId: 'name',
        flex: 1,
        editable: false,
        cellStyle: leftAlignCell,
      },
    ],
    [leftAlignCell, actionIconRenderer],
  )

  const handleRowDoubleClick = (params) => {
    handleUpdate(params.data?.id)
  }

  return (
    <Box className='gridbox' sx={{ display: 'flex', minHeight: '200px', height: '100%', width: '100%' }}>
      <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          ref={gridApi}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          headerHeight={30}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
          rowSelection={'single'}
          onRowDoubleClicked={handleRowDoubleClick}
        />
      </div>
    </Box>
  )
})

export default LoadWpcGrid
