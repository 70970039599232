// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import * as jszip from 'jszip'

const useUploadDataAckFile = () => {
  const _isMounted = useRef(false)
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const uploadDataAckFile = async (files, options) => {
    const result = await uploadFile('/dataAcq/uploadData', files, options)
    return result
  }

  const uploadFile = async (uploadUrl, files, importOptions) => {
    if (!files) return false

    const zip = new jszip()
    zip.file(files.name, files)

    let zipFile = await zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })

    let formData = new FormData()
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: uploadUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('wellName', importOptions.wellName)
    formData.append('dataStore', importOptions.dataStore)
    formData.append('map', JSON.stringify(importOptions.map))
    formData.append('file', zipFile)

    try {
      const response = await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: 300000,
        ...options,
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      return { error: response.error, message: response.error ? response.error : '', data: response.error ? null : response.data }
    } catch (err) {
      return { error: true, message: err }
    }
  }

  return uploadDataAckFile
}

export default useUploadDataAckFile
