import React, { useEffect, useRef } from 'react'
import { Box } from '@mui/material'
import EdrToolfaceControl from './EdrToolfaceControl'
import AutoSizer from 'react-virtualized-auto-sizer'
import TextWidget from '../TextWidget'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useInterval from 'components/common/hooks/useInterval'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DirectionalDrillingPane = ({ edrData, toolFaceData }) => {
  const _isMounted = useRef(false)
  const { getUnitsText } = useUnits()
  const [refreshCounter, setRefreshCounter] = React.useState(0)
  const { getBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const getLastUpdateTime = () => {
    if (!edrData.current) return new Date(Date.now()).toISOString()
    if (!isValidDate(edrData.current.timeStamp)) return new Date(Date.now()).toISOString()
    return new Date(Date.parse(edrData.current.timeStamp)).toISOString()
  }

  const getToolFaces = () => {
    if (!Array.isArray(toolFaceData.current?.mtf)) return []
    let svyInc = toolFaceData.current.svyInc
    return svyInc < 5 ? toolFaceData.current.mtf : toolFaceData.current.gtf
  }

  const getEdrDataValue = (parameter) => {
    if (!edrData.current) return -999.25
    if (!edrData.current.hasOwnProperty(parameter)) return -999.25
    if (edrData.current[parameter] === null) return -999.25
    return edrData.current[parameter]
  }

  useInterval(() => {
    if (!_isMounted.current) return
    setRefreshCounter(refreshCounter + 1)
  }, 1000)

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}>
      <Box
        sx={{
          display: 'flex',
          minHeight: '400px',
          width: '100%',
        }}>
        <AutoSizer>
          {({ height, width }) => (
            <EdrToolfaceControl
              rigState={edrData.current?.rigState}
              toolFaceData={getToolFaces()}
              svyData={{ inc: toolFaceData.current.svyInc }}
              h={height}
              w={width}
              updateTime={getLastUpdateTime()}
            />
          )}
        </AutoSizer>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: getBackColor(),
          width: '100%',
          maxHeight: '85px',
        }}>
        <TextWidget
          title={'Rig State'}
          value={getEdrDataValue('rigState') < 0 ? 'UNKNOWN' : getEdrDataValue('rigState')}
          unit={''}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: getBackColor(),
          width: '100%',
          maxHeight: '85px',
        }}>
        <TextWidget title={'Bit Depth'} value={getEdrDataValue('bitDepth')} unit={getUnitsText(UNITS_FOR.Depth)} />
        <TextWidget title={'Hole Depth'} value={getEdrDataValue('holeDepth')} unit={getUnitsText(UNITS_FOR.Depth)} />
      </Box>
    </Box>
  )
}

export default DirectionalDrillingPane
