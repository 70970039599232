import { Box } from '@mui/material'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import EngineeringChart from './EngineeringChart'

const HydraulicsChartsReport = forwardRef((props, ref) => {
  const chartPumpPressureRef = useRef(null)
  const chartECDRef = useRef(null)
  const chartECDSnapShotRef = useRef(null)
  const chartSurgeAndSwabRef = useRef(null)
  const chartTrippingSpeedsRef = useRef(null)
  const chartMinimumFlowRef = useRef(null)
  const kickToleranceRef = useRef(null)
  const { getUnitsText } = useUnits()

  useImperativeHandle(ref, () => {
    return {
      getChartType(chartType) {
        if (chartType === 'pumpPressure') return chartPumpPressureRef
        if (chartType === 'ecd') return chartECDRef
        if (chartType === 'ecdSnapShot') return chartECDSnapShotRef
        if (chartType === 'surgeAndSwab') return chartSurgeAndSwabRef
        if (chartType === 'trippingSpeeds') return chartTrippingSpeedsRef
        if (chartType === 'minimumFlow') return chartMinimumFlowRef
        if (chartType === 'kickTolerance') return kickToleranceRef
        return null
      },
    }
  })
 
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box ref={chartPumpPressureRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.pumpPressureData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Pump Pressure'}
          xAxisLabel={'Pressure'}
          units={getUnitsText(UNITS_FOR.Pressure)}
          xBeginAtZero={false}
          printing={true}
        />
      </Box>
      <Box ref={chartECDRef} sx={{ wdith: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.ecdData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'ECD'}
          xAxisLabel={'ECD'}
          units={getUnitsText(UNITS_FOR.MudWeight)}
          xBeginAtZero={false}
          printing={true}
        />
      </Box>
      <Box ref={chartECDSnapShotRef} sx={{ wdith: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.ecdSnapshotData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'ECD Snapshot'}
          xAxisLabel={'ECD'}
          units={getUnitsText(UNITS_FOR.MudWeight)}
          xBeginAtZero={false}
          printing={true}
        />
      </Box>
      <Box ref={chartSurgeAndSwabRef} sx={{ wdith: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.surgeAndSwabData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Surge & Swab'}
          xAxisLabel={'EMW'}
          units={getUnitsText(UNITS_FOR.MudWeight)}
          xBeginAtZero={false}
          printing={true}
        />
      </Box>
      <Box ref={chartTrippingSpeedsRef} sx={{ wdith: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.minMaxTripSpeedData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={`Trip Speed Limits (Min EMW: ${props.props?.minEmw}, Max EMW: ${props.props?.maxEmw}) `}
          xAxisLabel={'Trip Speed'}
          units={`${getUnitsText(UNITS_FOR.Depth)}/min`}
          xBeginAtZero={false}
          printing={true}
        />
      </Box>
      <Box ref={chartMinimumFlowRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.minFlowData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Min Flow For Hole Cleaning'}
          xAxisLabel={'Flow Rate'}
          units={getUnitsText(UNITS_FOR.FlowRate)}
          xBeginAtZero={false}
          printing={true}
        />
      </Box>
      <Box ref={kickToleranceRef} sx={{ width: '100%', height: '100%' }}>
        <EngineeringChart
          chartData={props.props.kickToleranceData}
          showLegend={true}
          showCasing={true}
          casingAnnotations={props.props.geometryAnnotations}
          title={'Kick Tolerance'}
          xAxisLabel={'Kick Tolerance'}
          units={getUnitsText(UNITS_FOR.Volume)}
          xBeginAtZero={true}
          printing={true}
        />
        </Box>
    </Box>
  )
})

export default HydraulicsChartsReport
