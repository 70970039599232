import { useEffect, useState, useRef, useCallback } from 'react'

import useAxiosGzip from '../useAxiosGzip'
import { useRecoilValue } from 'recoil'
import { currentWellAtom, selectedEngineeringCaseAtom, userUserRoleAtom, surgeAndSwabMinMaxEmw } from 'atoms'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import { appColors } from 'utils'
import html2canvas from 'html2canvas'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { removeSpecialSymbols, replaceEscapeSymbols, numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import useInnovaAxios from '../useInnovaAxios'
import { multiParse } from 'utils'
import { Buffer } from 'buffer'
import { getUserNameFromEmail } from 'utils/chatFunctions'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import useBhaImages from '../useBhaImages'
import { getItemFromLS } from 'utils/localStorage'

function useHydraulics() {
  const _isMounted = useRef(true)
  const hydraulicsData = useRef({})
  const isLoading = useRef(false)
  const [loading, setLoading] = useState(false)
  const currentWell = useRecoilValue(currentWellAtom).wellName
  const currentWellRef = useRef(currentWell)
  const selectedEngCase = useRecoilValue(selectedEngineeringCaseAtom)
  const { getUnitsText } = useUnits()
  const userRole = useRecoilValue(userUserRoleAtom)
  const [logos, setLogos] = useState([])
  const { user } = useInnovaAuth()
  const bhaImages = useBhaImages()
  const minMaxSsEmw = useRecoilValue(surgeAndSwabMinMaxEmw)

  const getData = useAxiosGzip({
    url: '/engineering/getHydraulicsGz',
  })

  const getOperatorLogos = useInnovaAxios({
    url: '/operator/getLogos',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    currentWellRef.current = currentWell
  }, [currentWell])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }, [])

  const fetchHydraulics = async () => {
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return
    if (selectedEngCase === null || selectedEngCase === undefined) return
    if (!selectedEngCase.hasOwnProperty('recordId')) return
    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const response = await getData({
      wellName: currentWellRef.current,
      engCase: selectedEngCase?.recordId,
      incDiagnostic: true,
    })
    if (_isMounted.current) {
      setLoading(false)
      hydraulicsData.current = response?.data ? response.data : null
    }

    isLoading.current = false

    if (isLoading.current) return
    isLoading.current = true
    if (_isMounted.current) setLoading(true)

    const responseLogos = await getOperatorLogos({ operator: hydraulicsData.current.jobDetails?.operator })

    if (!_isMounted.current) return
    setLoading(false)
    isLoading.current = false

    if (responseLogos?.error) return
    if (!responseLogos?.data) return

    const mfpBoundary = responseLogos.data.substring(2, responseLogos.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(responseLogos.data), mfpBoundary)
    let logoData = parts.map((part) => {
      let logoObj = { ...part }
      logoObj.data = 'data:image/*;base64,' + part.data
      return logoObj
    })
    setLogos(logoData)
  }

  const interpChartColor = (pallete, index, maxSeries) => {
    let bluePalette = ['#006cd1', '#00deff']
    let redPalette = ['#fa6e7a', '#fa000c']
    let greenPalette = ['#93fab4', '#8ee800']

    let startColor = bluePalette[0]
    let endColor = bluePalette[1]
    if (pallete === 'green') {
      startColor = greenPalette[0]
      endColor = greenPalette[1]
    }

    if (pallete === 'red') {
      startColor = redPalette[0]
      endColor = redPalette[1]
    }

    let ratio = normalize(index, 0, maxSeries)
    return lerpColor(startColor, endColor, ratio)
  }

  const getSeriesValues = (data) => {
    if (!Array.isArray(data)) return []
    let output = []
    data.forEach((pt) => {
      output.push({
        x: pt.hasOwnProperty('data') ? pt.data : 0,
        y: pt.depth,
      })
    })

    return output
  }

  const getGeometryAnnotations = () => {
    if (!hydraulicsData.current) return null
    if (!hydraulicsData.current.hasOwnProperty('geometry')) return null
    if (!hydraulicsData.current.hasOwnProperty('sppOutput')) return null
    if (!Array.isArray(hydraulicsData.current.geometry?.geometry)) return null
    if (!Array.isArray(hydraulicsData.current.sppOutput)) return null

    let maxDepth = 0
    if (hydraulicsData.current.sppOutput.length > 0) {
      let curSeries = hydraulicsData.current.sppOutput[0]
      if (Array.isArray(curSeries.data)) {
        maxDepth = curSeries.data[curSeries.data.length - 1].depth
      }
    }

    const annotations = {}
    for (let i = 0; i < hydraulicsData.current.geometry?.geometry.length; i++) {
      if (hydraulicsData.current.geometry?.geometry[i].md > maxDepth) continue
      annotations[`geometry` + (i + 1)] = {
        type: 'line',
        value: hydraulicsData.current.geometry?.geometry[i].md,
        scaleID: 'y',
        borderColor: appColors.itemTextColor,
        borderDash: [10, 4],
        borderDashOffset: 5,
        borderWidth: 1,
        lineTension: 0.8,
        label: {
          content: `${hydraulicsData.current.geometry?.geometry[i].od} ${hydraulicsData.current.geometry?.geometry[i].compType}`,
          enabled: true,
          backgroundColor: 'transparent',
          position: 'start',
          color: appColors.itemTextColor,
          font: { weight: 'normal' },
          yAdjust: -5,
          display: true,
        },
      }
    }
    return annotations
  }

  const getPumpPressureData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('sppOutput')) return chartData
    if (!Array.isArray(hydraulicsData.current.sppOutput)) return chartData
    if (hydraulicsData.current.sppOutput.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.sppOutput.length; i++) {
      let curSeries = hydraulicsData.current.sppOutput[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.sppOutput.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.sppOutput.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getEcdSnapShotData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('ecdSnapshot')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('mudWeight')) return chartData
    if (!Array.isArray(hydraulicsData.current.ecdSnapshot)) return chartData
    if (!Array.isArray(hydraulicsData.current.mudWeight)) return chartData
    if (hydraulicsData.current.ecdSnapshot.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.ecdSnapshot.length; i++) {
      let curSeries = hydraulicsData.current.ecdSnapshot[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.ecdSnapshot.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.ecdSnapshot.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.mudWeight.length; i++) {
      let curSeries = hydraulicsData.current.mudWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        backgroundColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getMinFlowData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('minFlowRates')) return chartData
    if (!Array.isArray(hydraulicsData.current.minFlowRates)) return chartData
    if (hydraulicsData.current.minFlowRates.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.minFlowRates.length; i++) {
      let curSeries = hydraulicsData.current.minFlowRates[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.minFlowRates.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.minFlowRates.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getEcdData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('dirtyEcd')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('cleanEcd')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('mudWeight')) return chartData
    if (!Array.isArray(hydraulicsData.current.dirtyEcd)) return chartData
    if (!Array.isArray(hydraulicsData.current.cleanEcd)) return chartData
    if (!Array.isArray(hydraulicsData.current.mudWeight)) return chartData
    if (hydraulicsData.current.dirtyEcd.length === 0) return chartData
    if (hydraulicsData.current.cleanEcd.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.cleanEcd.length; i++) {
      let curSeries = hydraulicsData.current.cleanEcd[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, hydraulicsData.current.cleanEcd.length),
        backgroundColor: interpChartColor('blue', i, hydraulicsData.current.cleanEcd.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.dirtyEcd.length; i++) {
      let curSeries = hydraulicsData.current.dirtyEcd[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.dirtyEcd.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.dirtyEcd.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.mudWeight.length; i++) {
      let curSeries = hydraulicsData.current.mudWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        backgroundColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const extractEcdDataByDepth = (depth, data) => {
    let ecd = 0
    if (!Array.isArray(data)) return ecd
    if (data.length === 0) return ecd

    for (let i = 0; i < data.length; i++) {
      if (data[i].depth > depth) {
        if (i === 0) {
          ecd = data[i].data
          break
        }
        const prev = data[i - 1]
        const cur = data[i]
        const ratio = (depth - prev.depth) / (cur.depth - prev.depth)
        ecd = prev.data + (cur.data - prev.data) * ratio
        break
      }
    }

    return ecd
  }

  const getSurgeAndSwabData = () => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('surge')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('swab')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('mudWeight')) return chartData
    if (!Array.isArray(hydraulicsData.current.surge)) return chartData
    if (!Array.isArray(hydraulicsData.current.swab)) return chartData
    if (!Array.isArray(hydraulicsData.current.mudWeight)) return chartData
    if (hydraulicsData.current.surge.length === 0) return chartData
    if (hydraulicsData.current.swab.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.surge.length; i++) {
      let curSeries = hydraulicsData.current.surge[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, hydraulicsData.current.surge.length),
        backgroundColor: interpChartColor('blue', i, hydraulicsData.current.surge.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.mudWeight.length; i++) {
      let curSeries = hydraulicsData.current.mudWeight[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        backgroundColor: interpChartColor('green', i, hydraulicsData.current.mudWeight.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    for (let i = 0; i < hydraulicsData.current.swab.length; i++) {
      let curSeries = hydraulicsData.current.swab[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.swab.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.swab.length),
        data: getSeriesValues(curSeries.data),
      })
    }

    return chartData
  }

  const getStabToBit = (bhaData, stabilizerData, index) => {
    let stabToBit = stabilizerData[index].stabDfb
    for (let i = 0; i < stabilizerData[index].sequenceNo; i++) {
      stabToBit += bhaData[i].length
    }
    return stabToBit
  }

  const extractMinMaxFromData = (data, minDepth, maxDepth) => {
    if (!Array.isArray(data)) return { min: 0, max: 0, mdMin: 0, mdMax: 0 }
    if (data.length === 0) return { min: 0, max: 0, mdMin: 0, mdMax: 0 }

    let min = data[0].data
    let max = data[0].data
    let mdMin = data[0].depth
    let mdMax = data[0].depth

    let foundOpenHole = false
    for (let i = 0; i < data.length; i++) {
      if (data[i].depth < minDepth || data[i].depth > maxDepth) continue
      if (!foundOpenHole) {
        min = data[i].data
        mdMin = data[i].depth
        max = data[i].data
        mdMax = data[i].data
        foundOpenHole = true
        continue
      }
      if (data[i].data < min) {
        min = data[i].data
        mdMin = data[i].depth
      }
      if (data[i].data > max) {
        max = data[i].data
        mdMax = data[i].depth
      }
    }

    return { min: min, max: max, mdMin: mdMin, mdMax: mdMax }
  }

  const extractMinMaxTripSpeedFromData = (surge, swab, minEmw = 8.6, maxEmw = 10.5, minDepth, maxDepth) => {
    if (!Array.isArray(surge))
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (!Array.isArray(swab))
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (surge.length === 0)
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (swab.length === 0)
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (!Array.isArray(surge[0].data))
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (!Array.isArray(swab[0].data))
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (surge[0].data.length === 0)
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }
    if (swab[0].data.length === 0)
      return {
        minTripIn: 0,
        maxTripIn: 0,
        mdTripInMin: 0,
        mdTripInMax: 0,
        minTripOut: 0,
        maxTripOut: 0,
        mdTripOutMin: 0,
        mdTripOutMax: 0,
      }

    let numDepthPoint = surge[0].data.length
    let tripSpeeds = []

    for (let i = 0; i < surge.length; i++) {
      let tripSpeed = extractTripSpeedFromTitle(surge[i].title)
      if (tripSpeed < 0) continue
      tripSpeeds.push(tripSpeed)
    }

    let tripOutMin = 0
    let tripOutMax = 0
    let tripInMin = 0
    let tripInMax = 0
    let mdTripOutMin = 0
    let mdTripOutMax = 0
    let mdTripInMin = 0
    let mdTripInMax = 0

    let foundOpenHole = false
    for (let i = 0; i < numDepthPoint; i++) {
      if (surge[0].data[i].depth < minDepth || surge[0].data[i].depth > maxDepth) continue
      let tripOutData = tripSpeeds.map((tripSpeed, index) => {
        return { emw: swab[index].data[i].data, tripSpeed: tripSpeed }
      })
      let tripInData = tripSpeeds.map((tripSpeed, index) => {
        return { emw: surge[index].data[i].data, tripSpeed: tripSpeed }
      })

      let tripOutSpeed = interpExtrapTripSpeed(tripOutData, minEmw)
      let tripInSpeed = interpExtrapTripSpeed(tripInData, maxEmw)
      if (!foundOpenHole) {
        tripOutMin = tripOutSpeed
        tripOutMax = tripOutSpeed
        tripInMin = tripInSpeed
        tripInMax = tripInSpeed
        mdTripOutMin = surge[0].data[i].depth
        mdTripOutMax = surge[0].data[i].depth
        mdTripInMin = surge[0].data[i].depth
        mdTripInMax = surge[0].data[i].depth
        foundOpenHole = true
        continue
      }
      if (Math.abs(tripOutSpeed) < Math.abs(tripOutMin)) {
        tripOutMin = tripOutSpeed
        mdTripOutMin = surge[0].data[i].depth
      }
      if (Math.abs(tripOutSpeed) > Math.abs(tripOutMax)) {
        tripOutMax = tripOutSpeed
        mdTripOutMax = surge[0].data[i].depth
      }

      if (Math.abs(tripInSpeed) < Math.abs(tripInMin)) {
        tripInMin = tripInSpeed
        mdTripInMin = surge[0].data[i].depth
      }
      if (Math.abs(tripInSpeed) > Math.abs(tripInMax)) {
        tripInMax = tripInSpeed
        mdTripInMax = surge[0].data[i].depth
      }
    }

    return {
      tripInMin: tripInMin,
      tripInMax: tripInMax,
      mdTripInMin: mdTripInMin,
      mdTripInMax: mdTripInMax,
      tripOutMin: tripOutMin,
      tripOutMax: tripOutMax,
      mdTripOutMin: mdTripOutMin,
      mdTripOutMax: mdTripOutMax,
    }
  }

  const extractTripSpeedFromTitle = (title) => {
    if (!title) return -1
    if (typeof title !== 'string') return -1

    title = title
      .toLowerCase()
      .replace('usft/min', '')
      .replace('ft/min', '')
      .replace('m/min', '')
      .replace('surge @', '')
      .replace('swab @', '')
      .trim()

    return parseFloat(title)
  }

  const extractRopFromTitle = (title) => {
    if (!title) return -1
    if (typeof title !== 'string') return -1

    title = title
      .toLowerCase()
      .replace('usft/hr', '')
      .replace('ft/hr', '')
      .replace('m/hr', '')
      .replace('min flow (gpm) @', '')
      .replace('min flow (lpm) @', '')
      .trim()

    return parseFloat(title)
  }

  function interpExtrapTripSpeed(data, targetEmw) {
    if (!Array.isArray(data)) return 0
    if (data.length < 2) return 0
    // Sort the data array by emw in ascending order
    data.sort((a, b) => a.emw - b.emw)

    // Find the two data points that traddle the targetEmw
    let point1 = null
    let point2 = null

    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].emw <= targetEmw && data[i + 1].emw >= targetEmw) {
        point1 = data[i]
        point2 = data[i + 1]
        break
      }
    }

    // If targetEmw is outside the range of the data, extrapolate
    if (!point1 || !point2) {
      if (targetEmw <= data[0].emw) {
        point1 = data[0]
        point2 = data[1]
      } else {
        point1 = data[data.length - 2]
        point2 = data[data.length - 1]
      }
    }

    // Perform linear interpolation
    const slope = (point2.tripSpeed - point1.tripSpeed) / (point2.emw - point1.emw)
    const interpolatedTripSpeed = point1.tripSpeed + slope * (targetEmw - point1.emw)
    return interpolatedTripSpeed
  }

  const getMinMaxTripSpeedData = (minEmw = 8.6, maxEmw = 10.5) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('surge')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('swab')) return chartData

    if (!Array.isArray(hydraulicsData.current.surge)) return chartData
    if (!Array.isArray(hydraulicsData.current.swab)) return chartData

    if (hydraulicsData.current.surge.length === 0) return chartData
    if (hydraulicsData.current.swab.length === 0) return chartData

    if (!Array.isArray(hydraulicsData.current.surge[0].data)) return chartData
    if (hydraulicsData.current.surge[0].data.length === 0) return chartData

    let numDepthPoints = hydraulicsData.current.surge[0].data.length
    let tripSpeeds = []

    for (let i = 0; i < hydraulicsData.current.surge.length; i++) {
      let tripSpeed = extractTripSpeedFromTitle(hydraulicsData.current.surge[i].title)
      if (tripSpeed < 0) continue
      tripSpeeds.push(tripSpeed)
    }

    //Minimum
    chartData.datasets.push({
      label: 'Trip Out Speed',
      borderColor: interpChartColor('blue', 0, 1),
      backgroundColor: interpChartColor('blue', 0, 1),
      data: [],
    })

    chartData.datasets.push({
      label: 'Trip In Speed',
      borderColor: interpChartColor('red', 0, 1),
      backgroundColor: interpChartColor('red', 0, 1),
      data: [],
    })

    for (let i = 0; i < numDepthPoints; i++) {
      let tripOutData = tripSpeeds.map((tripSpeed, index) => {
        return { emw: hydraulicsData.current.swab[index].data[i].data, tripSpeed: tripSpeed }
      })
      let tripInData = tripSpeeds.map((tripSpeed, index) => {
        return { emw: hydraulicsData.current.surge[index].data[i].data, tripSpeed: tripSpeed }
      })

      chartData.datasets[0].data.push({
        x: interpExtrapTripSpeed(tripOutData, minEmw),
        y: hydraulicsData.current.surge[0].data[i].depth,
      })
      chartData.datasets[1].data.push({
        x: interpExtrapTripSpeed(tripInData, maxEmw),
        y: hydraulicsData.current.surge[0].data[i].depth,
      })
    }

    return chartData
  }

  const getShadingColor = (index) => {
    if (index === 0) return 'rgb(255, 0, 0)'
    if (index === 1) return 'rgb(255, 155, 0)'
    return 'rgb(0, 155, 0)'
  }

  const getKickToleranceData = (shadeTolerance) => {
    let chartData = {
      labels: ['x', 'y'],
      datasets: [],
    }

    if (!hydraulicsData.current) return chartData
    if (!hydraulicsData.current.hasOwnProperty('kickTolerance')) return chartData
    if (!hydraulicsData.current.hasOwnProperty('kickToleranceSwabbed')) return chartData
    if (!Array.isArray(hydraulicsData.current.kickTolerance)) return chartData
    if (!Array.isArray(hydraulicsData.current.kickToleranceSwabbed)) return chartData
    if (hydraulicsData.current.kickTolerance.length === 0) return chartData
    if (hydraulicsData.current.kickToleranceSwabbed.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.kickTolerance.length; i++) {
      let curSeries = hydraulicsData.current.kickTolerance[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('blue', i, hydraulicsData.current.kickTolerance.length),
        backgroundColor: interpChartColor('blue', i, hydraulicsData.current.kickTolerance.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    for (let i = 0; i < hydraulicsData.current.kickToleranceSwabbed.length; i++) {
      let curSeries = hydraulicsData.current.kickToleranceSwabbed[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: interpChartColor('red', i, hydraulicsData.current.kickToleranceSwabbed.length),
        backgroundColor: interpChartColor('red', i, hydraulicsData.current.kickToleranceSwabbed.length),
        data: getSeriesValues(curSeries.data),
        showShading: false,
        color: null,
      })
    }

    if (!hydraulicsData.current.hasOwnProperty('kickToleranceLimits')) return chartData
    if (!Array.isArray(hydraulicsData.current.kickToleranceLimits)) return chartData
    if (hydraulicsData.current.kickToleranceLimits.length === 0) return chartData

    for (let i = 0; i < hydraulicsData.current.kickToleranceLimits.length; i++) {
      let curSeries = hydraulicsData.current.kickToleranceLimits[i]

      chartData.datasets.push({
        label: curSeries.title,
        borderColor: getShadingColor(i),
        backgroundColor: getShadingColor(i),
        data: getSeriesValues(curSeries.data),
        showShading: shadeTolerance,
        color: shadeTolerance ? getShadingColor(i) : null,
      })
    }

    return chartData
  }

  const getHydWarnings = () => {
    if (!hydraulicsData.current) return []
    if (!hydraulicsData.current.hasOwnProperty('warnings')) return []
    if (!Array.isArray(hydraulicsData.current.warnings)) return []
    if (hydraulicsData.current.warnings.length === 0) return []

    return hydraulicsData.current.warnings
  }

  const getHydErrors = () => {
    if (!hydraulicsData.current) return []
    if (!hydraulicsData.current.hasOwnProperty('errors')) return []
    if (!Array.isArray(hydraulicsData.current.errors)) return []
    if (hydraulicsData.current.errors.length === 0) return []

    return hydraulicsData.current.errors
  }

  const getLogo = (type) => {
    if (!Array.isArray(logos)) return null
    if (logos.length === 0) return null
    if (type === 'Primary') return logos[0].data
    if (type === 'Secondary' && logos.length > 1) return logos[1].data
    return null
  }

  const getLogoDimensions = (logo) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = (err) => reject(err)
      img.src = logo
    })
  }

  const calculateDimensions = async (logo) => {
    if (!logo) return null

    try {
      const { width, height } = await getLogoDimensions(logo)
      return width > height ? 'contain' : 'cover'
    } catch (err) {
      console.error('Error calculating dimensions:', err)
      return null
    }
  }

  const getLogoFill = async (type) => {
    if (!Array.isArray(logos) || logos.length === 0) return null

    const logo = type === 'Primary' ? logos[0]?.data : logos[1]?.data
    if (!logo) return null

    const fillType = await calculateDimensions(logo)
    return fillType
  }

  const generatePdfDocument = async (
    pumpPressureImage,
    ecdImage,
    ecdSnapShotImage,
    surgeAndSwabImage,
    trippingSpeedsImage,
    minimumFlowImage,
    kickToleranceImage,
    imageHeight,
    selectedChart,
  ) => {
    if (!hydraulicsData.current) return
    let logoFill = await getLogoFill('Primary')
    let logoSecondaryFill = await getLogoFill('Secondary')

    let reportDetails = getReportDetails(selectedChart)

    let docData = [
      {
        tableType: 'header',
        showTitle: true,
        title: 'Hydraulics Report',
        showLogo: true,
        logo: getLogo('Primary'),
        logoWidth: '25%',
        logoFill: logoFill,
        logoSecondary: getLogo('Secondary'),
        logoSecondaryWidth: '25%',
        logoSecondaryFill: logoSecondaryFill,
        fontSize: 6,
        sectionAfter: 5,
        manualWidth: true,
        columnWidths: ['50%', '50%'],
        data: [
          [
            { text: 'Well:', isHeader: true },
            { text: currentWellRef.current, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
          ],
        ],
      },
      {
        columnWidths: ['15%', '18%', '15%', '18%', '15%', '18%'],
        manualWidth: true,
        fontSize: 6,
        sectionAfter: 5,
        data: [
          [
            { text: 'Client:', isHeader: true },
            { text: hydraulicsData.current.jobDetails?.operator, textAlign: 'left' },
            { text: 'Field:', isHeader: true },
            { text: hydraulicsData.current.jobDetails?.field, textAlign: 'left' },
            { text: 'Well:', isHeader: true },
            { text: hydraulicsData.current.jobDetails?.wellName, textAlign: 'left' },
          ],
          [
            { text: 'Case:', isHeader: true },
            { text: unescapeHtml(selectedEngCase?.caseDesc.replace('&dq;', '"')), textAlign: 'left' },
            { text: 'Prepared By:', isHeader: true },
            { text: getUserNameFromEmail(user?.name), textAlign: 'left' },
            { text: 'Date:', isHeader: true },
            { text: dateTimeFormatter(new Date(Date.now()).toISOString()), textAlign: 'left' },
          ],
        ],
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['18%', '16%', '16%', '18%', '16%', '16%'],
        wrap: false,
        data: addCalculationParameters(
          hydraulicsData.current.fluids,
          hydraulicsData.current.params,
          hydraulicsData.current.drillString,
        ),
      },
    ]

    if (hydraulicsData.current?.params?.model !== 'Bingham Plastic') {
      docData.push({
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['17%', '17%', '16%', '16%', '17%', '17%'],
        wrap: false,
        data: addMudRheology(hydraulicsData.current.fluids),
      })
    }

    const surgeAndSwabReport = reportDetails.find((detail) => detail.reportType === 'Surge and Swab')
    if (surgeAndSwabReport) {
      if (surgeAndSwabReport?.charts || surgeAndSwabReport?.calculatedData || surgeAndSwabReport?.summaryData) {
        docData.push({
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          columnWidths: ['17%', '17%', '16%', '16%', '17%', '17%'],
          wrap: false,
          data: addSurgeAndSwab(hydraulicsData.current.params),
        })
      }
    }

    docData.push(
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        wrap: false,
        data: addWellboreGeometry(hydraulicsData.current.wellGeometry),
      },
      {
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['50%', '50%'],
        wrap: false,
        data: addStaticLosses(hydraulicsData.current.staticLosses),
      },
    )

    if (hydraulicsData.current?.params?.includeStabs) {
      docData.push({
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['16%', '14%', '14%', '14%', '14%', '14%', '14%'],
        wrap: false,
        data: addStabData(hydraulicsData.current.drillString),
      })
    }

    docData.push({
      breakHeader: true,
      fontSize: 6,
      manualWidth: true,
      sectionAfter: 5,
      columnWidths: ['4%', '4%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '8%', '12%'],
      wrap: false,
      data: addDrillStrings(hydraulicsData.current.drillStrings, hydraulicsData.current.params?.calcDepth),
    })

    if (selectedChart === 'kickTolerance') {
      let kickTolerance = getImagesForChart(
        reportDetails,
        pumpPressureImage,
        ecdImage,
        ecdSnapShotImage,
        surgeAndSwabImage,
        trippingSpeedsImage,
        minimumFlowImage,
        kickToleranceImage,
        imageHeight,
      )

      for (let i = 0; i < kickTolerance.length; i++) {
        docData.push(kickTolerance[i])
      }

      return docData
    }

    let summaryBreakHeader = true

    const pumpPressureReport = reportDetails.find((detail) => detail.reportType === 'Pump Pressure')
    if (pumpPressureReport && pumpPressureReport?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
        data: addHydraulicsSummaryResults(hydraulicsData.current.diagnosticOutput),
      })

      docData.push({
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
        data: addHydraulicsSnapshotSummaryResults(
          hydraulicsData.current.diagnosticOutput,
          hydraulicsData.current.ecdSnapshot,
        ),
      })
      summaryBreakHeader = false
    }

    if (surgeAndSwabReport && surgeAndSwabReport?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['20%', '20%', '20%', '20%', '20%'],
        data: addSurgeAndSwabSummaryResults(
          hydraulicsData.current.surge,
          hydraulicsData.current.swab,
          hydraulicsData.current.diagnosticOutput,
        ),
      })
      summaryBreakHeader = false
    }

    const trippingSpeedsReport = reportDetails.find((detail) => detail.reportType === 'Tripping Speeds')
    if (trippingSpeedsReport && trippingSpeedsReport?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['25%', '25%', '25%', '25%'],
        data: addTrippingSpeedsSummaryResults(
          hydraulicsData.current.surge,
          hydraulicsData.current.swab,
          hydraulicsData.current.diagnosticOutput,
        ),
      })
      summaryBreakHeader = false
    }

    const minimumFlowReport = reportDetails.find((detail) => detail.reportType === 'Minimum Flow')
    if (minimumFlowReport && minimumFlowReport?.summaryData) {
      docData.push({
        breakHeader: summaryBreakHeader,
        fontSize: 6,
        manualWidth: true,
        sectionAfter: 5,
        columnWidths: ['50%', '50%'],
        data: addMinimumFlowSummaryResults(hydraulicsData.current.minFlowRates),
      })
      summaryBreakHeader = false
    }

    let images = getImagesForChart(
      reportDetails,
      pumpPressureImage,
      ecdImage,
      ecdSnapShotImage,
      surgeAndSwabImage,
      trippingSpeedsImage,
      minimumFlowImage,
      kickToleranceImage,
      imageHeight,
    )

    for (let i = 0; i < images.length; i++) {
      docData.push(images[i])
    }

    if (pumpPressureReport && pumpPressureReport?.calculatedData) {
      let diagnosticOutput = []
      let columnWidths = ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
      for (let i = 0; i < hydraulicsData.current.diagnosticOutput.length; i++) {
        diagnosticOutput.push(hydraulicsData.current.diagnosticOutput[i])
        if (diagnosticOutput.length === 3 || i === hydraulicsData.current.diagnosticOutput.length - 1) {
          if (diagnosticOutput.length === 1) {
            columnWidths = ['25%', '25%', '25%', '25%']
          }
          if (diagnosticOutput.length === 2) {
            columnWidths = ['16%', '14%', '14%', '14%', '14%', '14%', '14%']
          }
          docData.push({
            breakHeader: true,
            fontSize: 6,
            manualWidth: true,
            sectionAfter: 5,
            multiPage: true,
            multiPageRow: 3,
            columnWidths: columnWidths,
            data: addHydraulicResultsData(diagnosticOutput),
          })
          diagnosticOutput = []
        }
      }

      for (let i = 0; i < hydraulicsData.current.diagnosticOutput.length; i++) {
        docData.push({
          breakHeader: i % 2 ? false : true,
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          multiPage: true,
          multiPageRow: 3,
          columnWidths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
          data: addHydraulicsSnapShotResultsData(
            hydraulicsData.current.diagnosticOutput[i],
            hydraulicsData.current.ecdSnapshot[i],
          ),
        })
      }
    }

    if (surgeAndSwabReport && surgeAndSwabReport?.calculatedData) {
      let surgeOutput = []
      let swabOutput = []

      let tripSpeedAdded = false
      let columnWidths = ['12%', '11%', '11%', '11%', '11%', '11%', '11%', '11%', '11%']
      for (let i = 0; i < hydraulicsData.current.surge.length; i++) {
        surgeOutput.push(hydraulicsData.current.surge[i])
        swabOutput.push(hydraulicsData.current.swab[i])
        if (surgeOutput.length === 4 || i === hydraulicsData.current.surge.length - 1) {
          let maxTripSpeed = null
          if (i === hydraulicsData.current.surge.length - 1 && surgeOutput.length < 4) {
            maxTripSpeed = getMinMaxTripSpeedData(minMaxSsEmw?.minEmw, minMaxSsEmw?.maxEmw)
            tripSpeedAdded = true
          }
          let count = surgeOutput.length
          if (tripSpeedAdded) count++
          if (count === 1) {
            columnWidths = ['34%', '33%', '33%']
          }
          if (count === 2) {
            columnWidths = ['20%', '20%', '20%', '20%', '20%']
          }
          if (count === 3) {
            columnWidths = ['16%', '14%', '14%', '14%', '14%', '14%', '14%']
          }
          docData.push({
            breakHeader: true,
            fontSize: 6,
            manualWidth: true,
            sectionAfter: 5,
            multiPage: true,
            multiPageRow: 3,
            columnWidths: columnWidths,
            data: addSurgeAndSwabResultsData(surgeOutput, swabOutput, maxTripSpeed),
          })
          surgeOutput = []
          swabOutput = []
        }
      }

      if (!tripSpeedAdded) {
        const maxTripSpeed = getMinMaxTripSpeedData(minMaxSsEmw?.minEmw, minMaxSsEmw?.maxEmw)
        docData.push({
          breakHeader: true,
          fontSize: 6,
          manualWidth: true,
          sectionAfter: 5,
          multiPage: true,
          multiPageRow: 3,
          columnWidths: ['20%', '20%', '20%', '20%', '20%'],
          data: addTripSpeedData(maxTripSpeed),
        })
      }
    }

    if (minimumFlowReport && minimumFlowReport?.calculatedData) {
      let minFlowOutput = []
      let columnWidths = ['10%', '18%', '18%', '18%', '18%', '18%']
      for (let i = 0; i < hydraulicsData.current.minFlowRates.length; i++) {
        minFlowOutput.push(hydraulicsData.current.minFlowRates[i])
        if (minFlowOutput.length === 5 || i === hydraulicsData.current.minFlowRates.length - 1) {
          if (minFlowOutput.length === 1) {
            columnWidths = ['50%', '50%']
          }
          if (minFlowOutput.length === 2) {
            columnWidths = ['34%', '33%', '33%']
          }
          if (minFlowOutput.length === 3) {
            columnWidths = ['25%', '25%', '25%', '25%']
          }
          if (minFlowOutput.length === 4) {
            columnWidths = ['20%', '20%', '20%', '20%', '20%']
          }
          docData.push({
            breakHeader: true,
            fontSize: 6,
            manualWidth: true,
            sectionAfter: 5,
            multiPage: true,
            multiPageRow: 2,
            columnWidths: columnWidths,
            data: addMinimumFlowResultsData(minFlowOutput),
          })
          minFlowOutput = []
        }
      }
    }

    return docData
  }

  const getReportDetails = (selectedChart) => {
    const reportDetails = getItemFromLS('engineeringReportDetails', 'engineeringReportDetails')
    if (!reportDetails) return null

    let reportName = 'Hydraulics'
    if (selectedChart === 'kickTolerance') {
      reportName = 'Well Control'
    }

    if (!Array.isArray(reportDetails)) return null
    const reportDetail = reportDetails.find((detail) => detail.reportName === reportName)
    if (!reportDetail) return null
    if (!Array.isArray(reportDetail.reportDetails)) return null

    return reportDetail.reportDetails
  }

  const getImagesForChart = (
    reportDetails,
    pumpPressureImage,
    ecdImage,
    ecdSnapShotImage,
    surgeAndSwabImage,
    trippingSpeedsImage,
    minimumFlowImage,
    kickToleranceImage,
    imageHeight,
  ) => {
    let images = []

    let numOfCharts = 0
    if (!reportDetails) return images
    for (let i = 0; i < reportDetails.length; i++) {
      if (!reportDetails[i].hasOwnProperty('charts')) continue
      if (!reportDetails[i].hasOwnProperty('reportType')) continue
      if (reportDetails[i].charts) {
        numOfCharts++
        if (reportDetails[i].reportType === 'Pump Pressure') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: pumpPressureImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetails[i].reportType === 'ECD') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: ecdImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetails[i].reportType === 'ECD Snapshot') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: ecdSnapShotImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetails[i].reportType === 'Surge and Swab') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: surgeAndSwabImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetails[i].reportType === 'Tripping Speeds') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: trippingSpeedsImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetails[i].reportType === 'Minimum Flow') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: minimumFlowImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
        if (reportDetails[i].reportType === 'Kick Tolerance') {
          images.push({
            breakHeader: numOfCharts % 2,
            sectionAfter: 5,
            data: [[{ text: kickToleranceImage, isImage: true, width: '100%', height: imageHeight }]],
          })
        }
      }
    }

    return images
  }

  const addStabData = (drillString) => {
    if (!drillString) return []
    if (!Array.isArray(drillString)) return []
    if (drillString.length === 0) return []

    let stabData = []
    drillString.forEach((component) => {
      if (component.type === 'Stabilizer' || component.stabOd > 0) stabData.push(component)
    })

    if (stabData.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Stabilizer Data', columnSpan: 7 }],
      [
        { text: 'Component Number', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Blade Length (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Blade Width (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: 'Blade Count', isHeader: true },
        { text: `Stab->Bit (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < stabData.length; i++) {
      data.push([
        { text: stabData[i].sequenceNo + 1 },
        { text: stabData[i].description.replace('&dq;', '"') },
        { text: numberWithCommasDecimals(stabData[i].stabOd, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBl, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBw, 3) },
        { text: numberWithCommasDecimals(stabData[i].stabBc, 0) },
        { text: numberWithCommasDecimals(getStabToBit(drillString, stabData, i), 2) },
      ])
    }

    return data
  }

  const addMinimumFlowResultsData = (minFlowData) => {
    if (!minFlowData) return []
    if (!Array.isArray(minFlowData)) return []
    if (minFlowData.length === 0) return []

    const minFlow1 = minFlowData[0]
    const minFlow2 = minFlowData.length > 1 ? minFlowData[1] : null
    const minFlow3 = minFlowData.length > 2 ? minFlowData[2] : null
    const minFlow4 = minFlowData.length > 3 ? minFlowData[3] : null
    const minFlow5 = minFlowData.length > 4 ? minFlowData[4] : null

    if (!minFlow1) return []
    if (!minFlow1.hasOwnProperty('data')) return []
    if (!Array.isArray(minFlow1.data)) return []
    if (minFlow1.data.length === 0) return []

    if (minFlow2 && !minFlow2.hasOwnProperty('data')) return []
    if (minFlow2 && !Array.isArray(minFlow2.data)) return []
    if (minFlow2 && minFlow2.data.length === 0) return []
    if (minFlow2 && minFlow1.data.length !== minFlow2.data.length) return []

    if (minFlow3 && !minFlow3.hasOwnProperty('data')) return []
    if (minFlow3 && !Array.isArray(minFlow3.data)) return []
    if (minFlow3 && minFlow3.data.length === 0) return []
    if (minFlow3 && minFlow1.data.length !== minFlow3.data.length) return []

    if (minFlow4 && !minFlow4.hasOwnProperty('data')) return []
    if (minFlow4 && !Array.isArray(minFlow4.data)) return []
    if (minFlow4 && minFlow4.data.length === 0) return []
    if (minFlow4 && minFlow1.data.length !== minFlow4.data.length) return []

    if (minFlow5 && !minFlow5.hasOwnProperty('data')) return []
    if (minFlow5 && !Array.isArray(minFlow5.data)) return []
    if (minFlow5 && minFlow5.data.length === 0) return []
    if (minFlow5 && minFlow1.data.length !== minFlow5.data.length) return []

    const colSpanHeader = minFlowData.length + 1
    let data = []
    data.push([{ text: 'Minimum Flow Results Data', columnSpan: colSpanHeader }])
    let row = []
    row.push({ text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true })
    row.push({ text: minFlow1.title, isHeader: true })
    if (minFlow2) row.push({ text: minFlow2.title, isHeader: true })
    if (minFlow3) row.push({ text: minFlow3.title, isHeader: true })
    if (minFlow4) row.push({ text: minFlow4.title, isHeader: true })
    if (minFlow5) row.push({ text: minFlow5.title, isHeader: true })
    data.push(row)

    for (let i = 0; i < minFlow1.data.length; i++) {
      let row = []
      row.push({ text: numberWithCommasDecimals(minFlow1.data[i].depth, 2) })
      row.push({ text: numberWithCommasDecimals(minFlow1.data[i].data, 2) })
      if (minFlow2) row.push({ text: numberWithCommasDecimals(minFlow2.data[i].data, 2) })
      if (minFlow3) row.push({ text: numberWithCommasDecimals(minFlow3.data[i].data, 2) })
      if (minFlow4) row.push({ text: numberWithCommasDecimals(minFlow4.data[i].data, 2) })
      if (minFlow5) row.push({ text: numberWithCommasDecimals(minFlow5.data[i].data, 2) })
      data.push(row)
    }

    return data
  }

  const addTripSpeedData = (tripSpeedData) => {
    if (!tripSpeedData) return []
    if (!tripSpeedData.hasOwnProperty('datasets')) return []
    if (!Array.isArray(tripSpeedData.datasets)) return []
    if (tripSpeedData.datasets.length < 2) return []
    if (!Array.isArray(tripSpeedData.datasets[0].data) && !Array.isArray(tripSpeedData.datasets[1].data)) return []
    if (tripSpeedData.datasets[0].data.length === 0 && tripSpeedData.datasets[1].data.length === 0) return []
    if (tripSpeedData.datasets[0].data.length !== tripSpeedData.datasets[1].data.length) return []

    let data = []
    data.push(
      [{ text: 'Trip Speed Data', columnSpan: 5 }],
      [
        { text: '', isHeader: true },
        { text: `Max Trip Speed (${getUnitsText(UNITS_FOR.Depth)}/min)`, isHeader: true, columnSpan: 4 },
      ],
      [
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Max Trip In (${getUnitsText(UNITS_FOR.Depth)}/min)`, isHeader: true, columnSpan: 2 },
        { text: `Max Trip Out (${getUnitsText(UNITS_FOR.Depth)}/min)`, isHeader: true, columnSpan: 2 },
      ],
    )

    for (let i = 0; i < tripSpeedData.datasets[0].data.length; i++) {
      data.push([
        { text: numberWithCommasDecimals(tripSpeedData.datasets[0].data[i].y, 2) },
        { text: numberWithCommasDecimals(tripSpeedData.datasets[1].data[i].x, 2), columnSpan: 2 },
        { text: numberWithCommasDecimals(tripSpeedData.datasets[0].data[i].x, 2), columnSpan: 2 },
      ])
    }

    return data
  }

  const addSurgeAndSwabResultsData = (surge, swab, tripSpeedData) => {
    if (!surge) return []
    if (!Array.isArray(surge)) return []
    if (surge.length === 0) return []
    if (!swab) return []
    if (!Array.isArray(swab)) return []
    if (swab.length === 0) return []

    const outputSurge1 = surge[0]
    const outputSwab1 = swab[0]
    const outputSurge2 = surge.length > 1 ? surge[1] : null
    const outputSwab2 = swab.length > 1 ? swab[1] : null
    const outputSurge3 = surge.length > 2 ? surge[2] : null
    const outputSwab3 = swab.length > 2 ? swab[2] : null
    const outputSurge4 = surge.length > 3 ? surge[3] : null
    const outputSwab4 = swab.length > 3 ? swab[3] : null

    if (!outputSurge1) return []
    if (!Array.isArray(outputSurge1.data)) return []
    if (outputSurge1.data.length === 0) return []

    if (!outputSwab1) return []
    if (!Array.isArray(outputSwab1.data)) return []
    if (outputSwab1.data.length === 0) return []

    if (outputSurge2 && !Array.isArray(outputSurge2.data)) return []
    if (outputSurge2 && outputSurge2.data.length === 0) return []
    if (outputSurge2 && outputSurge1.data.length !== outputSurge2.data.length) return []

    if (outputSwab2 && !Array.isArray(outputSwab2.data)) return []
    if (outputSwab2 && outputSwab2.data.length === 0) return []
    if (outputSwab2 && outputSwab1.data.length !== outputSwab2.data.length) return []

    if (outputSurge3 && !Array.isArray(outputSurge3.data)) return []
    if (outputSurge3 && outputSurge3.data.length === 0) return []
    if (outputSurge3 && outputSurge1.data.length !== outputSurge3.data.length) return []

    if (outputSwab3 && !Array.isArray(outputSwab3.data)) return []
    if (outputSwab3 && outputSwab3.data.length === 0) return []
    if (outputSwab3 && outputSwab1.data.length !== outputSwab3.data.length) return []

    if (outputSurge4 && !Array.isArray(outputSurge4.data)) return []
    if (outputSurge4 && outputSurge4.data.length === 0) return []
    if (outputSurge4 && outputSurge1.data.length !== outputSurge4.data.length) return []

    if (outputSwab4 && !Array.isArray(outputSwab4.data)) return []
    if (outputSwab4 && outputSwab4.data.length === 0) return []
    if (outputSwab4 && outputSwab1.data.length !== outputSwab4.data.length) return []

    if (tripSpeedData && !tripSpeedData.hasOwnProperty('datasets')) return []
    if (tripSpeedData && !Array.isArray(tripSpeedData.datasets)) return []
    if (tripSpeedData && tripSpeedData.datasets.length < 2) return []
    if (
      tripSpeedData &&
      !Array.isArray(tripSpeedData.datasets[0].data) &&
      !Array.isArray(tripSpeedData.datasets[1].data)
    )
      return []
    if (tripSpeedData && tripSpeedData.datasets[0].data.length === 0 && tripSpeedData.datasets[1].data.length === 0)
      return []
    if (
      tripSpeedData &&
      tripSpeedData.datasets[0].data.length !== tripSpeedData.datasets[1].data.length &&
      tripSpeedData.datasets[1].data.length !== outputSurge1.data.length
    )
      return []

    let colSpan = surge.length * 2 + 1
    if (tripSpeedData) colSpan += 2

    let data = []
    data.push([{ text: 'Surge and Swab Results Data', columnSpan: colSpan }])

    let row = []
    row.push({ text: '', isHeader: true })

    row.push({
      text: `Trip Speed ${extractTripSpeedFromTitle(outputSurge1.title)} (${getUnitsText(UNITS_FOR.Depth)}/min)`,
      isHeader: true,
      columnSpan: 2,
    })

    if (outputSurge2) {
      row.push({
        text: `Trip Speed ${extractTripSpeedFromTitle(outputSurge2.title)} (${getUnitsText(UNITS_FOR.Depth)}/min)`,
        isHeader: true,
        columnSpan: 2,
      })
    }

    if (outputSurge3) {
      row.push({
        text: `Trip Speed ${extractTripSpeedFromTitle(outputSurge3.title)} (${getUnitsText(UNITS_FOR.Depth)}/min)`,
        isHeader: true,
        columnSpan: 2,
      })
    }

    if (outputSurge4) {
      row.push({
        text: `Trip Speed ${extractTripSpeedFromTitle(outputSurge4.title)} (${getUnitsText(UNITS_FOR.Depth)}/min)`,
        isHeader: true,
        columnSpan: 2,
      })
    }

    if (tripSpeedData) {
      row.push({
        text: `Max Trip Speed (${getUnitsText(UNITS_FOR.Depth)}/min)`,
        isHeader: true,
        columnSpan: 2,
      })
    }

    data.push(row)

    row = []
    row.push(
      { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      { text: `Surge (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      { text: `Swab (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
    )

    if (outputSurge2) {
      row.push(
        { text: `Surge (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Swab (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      )
    }

    if (outputSurge3) {
      row.push(
        { text: `Surge (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Swab (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      )
    }

    if (outputSurge4) {
      row.push(
        { text: `Surge (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Swab (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      )
    }

    if (tripSpeedData) {
      row.push(
        { text: `Max Trip In (${getUnitsText(UNITS_FOR.Depth)})/min`, isHeader: true },
        { text: `Max Trip Out (${getUnitsText(UNITS_FOR.Depth)})/min`, isHeader: true },
      )
    }

    data.push(row)

    for (let i = 0; i < outputSurge1.data.length; i++) {
      let row = []
      row.push({ text: numberWithCommasDecimals(outputSurge1.data[i].depth, 2) })
      row.push({ text: numberWithCommasDecimals(outputSurge1.data[i].data, 2) })
      row.push({ text: numberWithCommasDecimals(outputSwab1.data[i].data, 2) })

      if (outputSurge2) {
        row.push({ text: numberWithCommasDecimals(outputSurge2.data[i].data, 2) })
        row.push({ text: numberWithCommasDecimals(outputSwab2.data[i].data, 2) })
      }

      if (outputSurge3) {
        row.push({ text: numberWithCommasDecimals(outputSurge3.data[i].data, 2) })
        row.push({ text: numberWithCommasDecimals(outputSwab3.data[i].data, 2) })
      }

      if (outputSurge4) {
        row.push({ text: numberWithCommasDecimals(outputSurge4.data[i].data, 2) })
        row.push({ text: numberWithCommasDecimals(outputSwab4.data[i].data, 2) })
      }

      if (tripSpeedData) {
        row.push({ text: numberWithCommasDecimals(tripSpeedData.datasets[1].data[i].x, 2) })
        row.push({ text: numberWithCommasDecimals(tripSpeedData.datasets[0].data[i].x, 2) })
      }

      data.push(row)
    }

    return data
  }

  const addHydraulicsSnapShotResultsData = (diagnosticOutput, ecdSnapShot) => {
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []
    if (diagnosticOutput.length === 0) return []
    if (!ecdSnapShot) return []
    if (!ecdSnapShot.hasOwnProperty('data')) return []
    if (!Array.isArray(ecdSnapShot.data)) return []
    if (ecdSnapShot.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Hydraulics Snapshot Results', columnSpan: 10 }],
      [{ text: `Flow Rate ${diagnosticOutput[0].flowRate} (${getUnitsText(UNITS_FOR.FlowRate)})`, columnSpan: 10 }],
      [
        { text: 'Component', isHeader: true },
        { text: 'Hole Section', isHeader: true },
        { text: `Pipe Press Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Ann Press Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `AV (${getUnitsText(UNITS_FOR.Depth)}/min)`, isHeader: true },
        { text: 'Flow Regime', isHeader: true },
        { text: 'Cuttings %', isHeader: true },
        { text: 'CCI', isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    const output = diagnosticOutput[diagnosticOutput.length - 1]
    if (!output) return []
    if (!output.hasOwnProperty('sectionData')) return []
    if (!Array.isArray(output.sectionData)) return []
    if (output.sectionData.length === 0) return []

    for (let j = 0; j < output.sectionData.length; j++) {
      const ecd = extractEcdDataByDepth(output.sectionData[j].bottom, ecdSnapShot.data)
      data.push([
        { text: output.sectionData[j]?.pipeSection },
        { text: output.sectionData[j]?.holeSection },
        { text: numberWithCommasDecimals(output.sectionData[j]?.boreLoss, 2) },
        { text: numberWithCommasDecimals(output.sectionData[j]?.annularLoss, 2) },
        { text: numberWithCommasDecimals(ecd, 2) },
        { text: numberWithCommasDecimals(output.sectionData[j]?.av, 2) },
        { text: output.sectionData[j]?.flowType },
        { text: numberWithCommasDecimals(output.sectionData[j]?.cuttingsPerc, 2) },
        { text: numberWithCommasDecimals(output.sectionData[j]?.cci, 2) },
        {
          text: `${numberWithCommasDecimals(output.sectionData[j]?.top, 2)} / ${numberWithCommasDecimals(
            output.sectionData[j]?.bottom,
            2,
          )}`,
        },
      ])
    }

    return data
  }

  const addHydraulicResultsData = (diagnosticOutput) => {
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []
    if (diagnosticOutput.length === 0) return []

    const outPut1 = diagnosticOutput[0]
    const outPut2 = diagnosticOutput.length > 1 ? diagnosticOutput[1] : null
    const outPut3 = diagnosticOutput.length > 2 ? diagnosticOutput[2] : null

    if (!outPut1) return []
    if (!Array.isArray(outPut1)) return []
    if (outPut1.length === 0) return []

    if (outPut2 && !Array.isArray(outPut2)) return []
    if (outPut2 && outPut2.length === 0) return []
    if (outPut2 && outPut1.length !== outPut2.length) return []

    if (outPut3 && !Array.isArray(outPut3)) return []
    if (outPut3 && outPut3.length === 0) return []
    if (outPut3 && outPut1.length !== outPut3.length) return []

    let colSpan = diagnosticOutput.length * 3 + 1

    let data = []
    data.push([{ text: 'Hydraulic Results', columnSpan: colSpan }])

    let row = []
    row.push({ text: '', isHeader: true })
    row.push({
      text: `Flow Rate ${outPut1[0].flowRate} (${getUnitsText(UNITS_FOR.FlowRate)})`,
      isHeader: true,
      columnSpan: 3,
    })

    if (outPut2) {
      row.push({
        text: outPut2 ? `Flow Rate ${outPut2[0].flowRate} (${getUnitsText(UNITS_FOR.FlowRate)})` : '',
        isHeader: true,
        columnSpan: 3,
      })
    }

    if (outPut3) {
      row.push({
        text: outPut3 ? `Flow Rate ${outPut3[0].flowRate} (${getUnitsText(UNITS_FOR.FlowRate)})` : '',
        isHeader: true,
        columnSpan: 3,
      })
    }

    data.push(row)

    row = []
    row.push(
      { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      { text: `SPP (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
      { text: `Clean ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      { text: `Dirty ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
    )

    if (outPut2) {
      row.push(
        { text: `SPP (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Clean ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Dirty ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      )
    }

    if (outPut3) {
      row.push(
        { text: `SPP (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Clean ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Dirty ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
      )
    }

    data.push(row)

    for (let i = 0; i < outPut1.length; i++) {
      let row = []
      row.push({ text: numberWithCommasDecimals(outPut1[i].depth, 2) })
      row.push({ text: numberWithCommasDecimals(outPut1[i].spp, 2) })
      row.push({ text: numberWithCommasDecimals(outPut1[i].ecd, 2) })
      row.push({ text: numberWithCommasDecimals(outPut1[i].dirtyEcd, 2) })

      if (outPut2) {
        row.push({ text: numberWithCommasDecimals(outPut2[i].spp, 2) })
        row.push({ text: numberWithCommasDecimals(outPut2[i].ecd, 2) })
        row.push({ text: numberWithCommasDecimals(outPut2[i].dirtyEcd, 2) })
      }

      if (outPut3) {
        row.push({ text: numberWithCommasDecimals(outPut3[i].spp, 2) })
        row.push({ text: numberWithCommasDecimals(outPut3[i].ecd, 2) })
        row.push({ text: numberWithCommasDecimals(outPut3[i].dirtyEcd, 2) })
      }

      data.push(row)
    }

    return data
  }

  const addMinimumFlowSummaryResults = (minimumFlow) => {
    if (!minimumFlow) return []
    if (!Array.isArray(minimumFlow)) return []
    if (minimumFlow.length === 0) return []

    let data = []
    data.push(
      [{ text: 'Minimum Flow Summary Results', columnSpan: 2 }],
      [
        { text: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`, isHeader: true },
        {
          text: `Min Flow (${getUnitsText(UNITS_FOR.FlowRate)}) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
      ],
    )

    for (let i = 0; i < minimumFlow.length; i++) {
      const rop = extractRopFromTitle(minimumFlow[i].title)
      const minMaxFlow = extractMinMaxFromData(minimumFlow[i].data, 0, 1000000)
      data.push([
        { text: numberWithCommasDecimals(rop, 2) },
        { text: `${numberWithCommasDecimals(minMaxFlow.max, 2)} @ ${numberWithCommasDecimals(minMaxFlow.mdMax, 2)}` },
      ])
    }

    return data
  }

  const addTrippingSpeedsSummaryResults = (surge, swab, diagnosticOutput) => {
    if (!surge) return []
    if (!Array.isArray(surge)) return []
    if (surge.length === 0) return []
    if (!swab) return []
    if (!Array.isArray(swab)) return []
    if (swab.length === 0) return []
    if (surge.length !== swab.length) return []
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []
    if (diagnosticOutput.length === 0) return []
    if (!diagnosticOutput[0]) return []
    if (!Array.isArray(diagnosticOutput[0])) return []
    if (diagnosticOutput[0].length === 0) return []
    const output = diagnosticOutput[0][diagnosticOutput[0].length - 1]
    if (!output) return []
    if (!output.hasOwnProperty('sectionData')) return []
    if (!Array.isArray(output.sectionData)) return []
    if (output.sectionData.length === 0) return []
    let minDepth = 0
    let maxDepth = 0
    let foundMinDepth = false
    for (let i = 0; i < output.sectionData.length; i++) {
      if (output.sectionData[i].holeSection === 'Open Hole') {
        if (!foundMinDepth) {
          minDepth = output.sectionData[i].top
          foundMinDepth = true
        }
        maxDepth = output.sectionData[i].bottom
      }
    }

    let data = []
    data.push(
      [{ text: 'Tripping Speeds Summary Results (Open Hole)', columnSpan: 4 }],
      [
        {
          text: `Trip In Min Speed (${getUnitsText(UNITS_FOR.Depth)}/min) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        {
          text: `Trip In Max Speed (${getUnitsText(UNITS_FOR.Depth)}/min) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        {
          text: `Trip Out Min Speed (${getUnitsText(UNITS_FOR.Depth)}/min) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        {
          text: `Trip Out Max Speed (${getUnitsText(UNITS_FOR.Depth)}/min) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
      ],
    )

    const minMaxTripSpeeds = extractMinMaxTripSpeedFromData(
      surge,
      swab,
      minMaxSsEmw?.minEmw,
      minMaxSsEmw?.maxEmw,
      minDepth,
      maxDepth,
    )

    data.push([
      {
        text: `${numberWithCommasDecimals(minMaxTripSpeeds.tripInMin, 2)} @ ${numberWithCommasDecimals(
          minMaxTripSpeeds.mdTripInMin,
          2,
        )}`,
      },
      {
        text: `${numberWithCommasDecimals(minMaxTripSpeeds.tripInMax, 2)} @ ${numberWithCommasDecimals(
          minMaxTripSpeeds.mdTripInMax,
          2,
        )}`,
      },
      {
        text: `${numberWithCommasDecimals(minMaxTripSpeeds.tripOutMin, 2)} @ ${numberWithCommasDecimals(
          minMaxTripSpeeds.mdTripOutMin,
          2,
        )}`,
      },
      {
        text: `${numberWithCommasDecimals(minMaxTripSpeeds.tripOutMax, 2)} @ ${numberWithCommasDecimals(
          minMaxTripSpeeds.mdTripOutMax,
          2,
        )}`,
      },
    ])
    return data
  }

  const addSurgeAndSwabSummaryResults = (surge, swab, diagnosticOutput) => {
    if (!surge) return []
    if (!Array.isArray(surge)) return []
    if (surge.length === 0) return []
    if (!swab) return []
    if (!Array.isArray(swab)) return []
    if (swab.length === 0) return []
    if (surge.length !== swab.length) return []
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []
    if (diagnosticOutput.length === 0) return []
    if (!diagnosticOutput[0]) return []
    if (!Array.isArray(diagnosticOutput[0])) return []
    if (diagnosticOutput[0].length === 0) return []
    const output = diagnosticOutput[0][diagnosticOutput[0].length - 1]
    if (!output) return []
    if (!output.hasOwnProperty('sectionData')) return []
    if (!Array.isArray(output.sectionData)) return []
    if (output.sectionData.length === 0) return []
    let minDepth = 0
    let maxDepth = 0
    let foundMinDepth = false
    for (let i = 0; i < output.sectionData.length; i++) {
      if (output.sectionData[i].holeSection === 'Open Hole') {
        if (!foundMinDepth) {
          minDepth = output.sectionData[i].top
          foundMinDepth = true
        }
        maxDepth = output.sectionData[i].bottom
      }
    }

    let data = []
    data.push(
      [{ text: 'Surge and Swab Summary Results (Open Hole)', columnSpan: 5 }],
      [
        { text: `Trip Speed (${getUnitsText(UNITS_FOR.Depth)})/min`, isHeader: true },
        {
          text: `Min Swab (${getUnitsText(UNITS_FOR.MudWeight)}) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        {
          text: `Max Swab (${getUnitsText(UNITS_FOR.MudWeight)}) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        {
          text: `Min Surge (${getUnitsText(UNITS_FOR.MudWeight)}) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
        {
          text: `Max Surge (${getUnitsText(UNITS_FOR.MudWeight)}) @ MD (${getUnitsText(UNITS_FOR.Depth)})`,
          isHeader: true,
        },
      ],
    )

    for (let i = 0; i < surge.length; i++) {
      const curSurge = surge[i]
      const curSwab = swab[i]

      const tripSpeed = extractTripSpeedFromTitle(curSurge.title)
      if (tripSpeed < 0) continue
      const minmaxSwab = extractMinMaxFromData(curSwab.data, minDepth, maxDepth)
      const minmaxSurge = extractMinMaxFromData(curSurge.data, minDepth, maxDepth)

      data.push([
        { text: numberWithCommasDecimals(tripSpeed, 2) },
        { text: `${numberWithCommasDecimals(minmaxSwab.min, 2)} @ ${numberWithCommasDecimals(minmaxSwab.mdMin, 2)}` },
        { text: `${numberWithCommasDecimals(minmaxSwab.max, 2)} @ ${numberWithCommasDecimals(minmaxSwab.mdMax, 2)}` },
        { text: `${numberWithCommasDecimals(minmaxSurge.min, 2)} @ ${numberWithCommasDecimals(minmaxSurge.mdMin, 2)}` },
        { text: `${numberWithCommasDecimals(minmaxSurge.max, 2)} @ ${numberWithCommasDecimals(minmaxSurge.mdMax, 2)}` },
      ])
    }

    return data
  }

  const addHydraulicsSnapshotSummaryResults = (diagnosticOutput, ecdSnapShot) => {
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []
    if (diagnosticOutput.length === 0) return []
    if (!Array.isArray(diagnosticOutput[0])) return []
    if (diagnosticOutput[0].length === 0) return []
    if (!ecdSnapShot) return []
    if (!Array.isArray(ecdSnapShot)) return []
    if (ecdSnapShot.length === 0) return []
    const output = diagnosticOutput[0][diagnosticOutput[0].length - 1]
    if (!output) return []
    if (!output.hasOwnProperty('sectionData')) return []
    if (!Array.isArray(output.sectionData)) return []
    if (output.sectionData.length === 0) return []
    const ecdOutput = ecdSnapShot[0]
    if (!ecdOutput) return []
    if (!ecdOutput.hasOwnProperty('data')) return []
    if (!Array.isArray(ecdOutput.data)) return []
    if (ecdOutput.data.length === 0) return []

    let data = []
    data.push(
      [{ text: `Hydraulics Snapshot Summary Results`, columnSpan: 10 }],
      [
        {
          text: `Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)}) [${numberWithCommasDecimals(output.flowRate, 2)}]`,
          isHeader: true,
          columnSpan: 10,
        },
      ],
      [
        { text: 'Component', isHeader: true },
        { text: 'Hole Section', isHeader: true },
        { text: `Pipe Pressure Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Ann Press Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `AV (${getUnitsText(UNITS_FOR.Depth)}/min)`, isHeader: true },
        { text: 'Flow Regime', isHeader: true },
        { text: 'Cuttings %', isHeader: true },
        { text: 'CCI', isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < output.sectionData.length; i++) {
      const ecd = extractEcdDataByDepth(output.sectionData[i].bottom, ecdOutput.data)
      data.push([
        { text: output.sectionData[i].pipeSection },
        { text: output.sectionData[i].holeSection },
        { text: numberWithCommasDecimals(output.sectionData[i].boreLoss, 2) },
        { text: numberWithCommasDecimals(output.sectionData[i].annularLoss, 2) },
        { text: numberWithCommasDecimals(ecd, 2) },
        { text: numberWithCommasDecimals(output.sectionData[i].av, 2) },
        { text: output.sectionData[i].flowType },
        { text: numberWithCommasDecimals(output.sectionData[i].cuttingsPerc, 2) },
        { text: numberWithCommasDecimals(output.sectionData[i].cci, 2) },
        {
          text: `${numberWithCommasDecimals(output.sectionData[i].top, 2)} / ${numberWithCommasDecimals(
            output.sectionData[i].bottom,
            2,
          )}`,
        },
      ])
    }

    return data
  }

  const addHydraulicsSummaryResults = (diagnosticOutput) => {
    if (!diagnosticOutput) return []
    if (!Array.isArray(diagnosticOutput)) return []
    if (diagnosticOutput.length === 0) return []

    let data = []
    data.push(
      [{ text: `Hydraulics Summary Results`, columnSpan: 10 }],
      [
        { text: `Flow Rate (${getUnitsText(UNITS_FOR.FlowRate)})`, isHeader: true },
        { text: `SPP (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Ann P. Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Clean ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Dirty ECD (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: `Bit P. Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
        { text: `Impact Force (${getUnitsText(UNITS_FOR.Force)})`, isHeader: true },
        { text: 'HHP', isHeader: true },
        { text: 'HHP/sqin', isHeader: true },
        { text: `Jet Velocity (${getUnitsText(UNITS_FOR.Depth)}/s)`, isHeader: true },
      ],
    )

    for (let i = 0; i < diagnosticOutput.length; i++) {
      if (!diagnosticOutput[i]) continue
      if (!Array.isArray(diagnosticOutput[i])) continue
      if (diagnosticOutput[i].length === 0) continue
      const output = diagnosticOutput[i][diagnosticOutput[i].length - 1]
      if (!output) continue
      if (!output.hasOwnProperty('bitData')) continue
      if (!Array.isArray(output.bitData)) continue
      if (output.bitData.length === 0) continue
      if (!output.hasOwnProperty('sectionData')) continue
      if (!Array.isArray(output.sectionData)) continue
      if (output.sectionData.length === 0) continue
      let cumAnnPloss = 0.0
      for (let j = 0; j < output.sectionData.length; j++) {
        cumAnnPloss += output.sectionData[j].annularLoss
      }

      data.push([
        { text: numberWithCommasDecimals(output.flowRate, 2) },
        { text: numberWithCommasDecimals(output.spp, 2) },
        { text: numberWithCommasDecimals(cumAnnPloss, 2) },
        { text: numberWithCommasDecimals(output.ecd, 2) },
        { text: numberWithCommasDecimals(output.dirtyEcd, 2) },
        { text: numberWithCommasDecimals(output.bitData[0].pressureDrop, 2) },
        { text: numberWithCommasDecimals(output.bitData[0].impactForce, 2) },
        { text: numberWithCommasDecimals(output.bitData[0].hhp, 2) },
        { text: numberWithCommasDecimals(output.bitData[0].hhpSqin, 2) },
        { text: numberWithCommasDecimals(output.bitData[0].jv, 2) },
      ])
    }

    return data
  }

  const addSurgeAndSwab = (params) => {
    let data = []
    data.push(
      [{ text: `Surge and Swab Settings`, columnSpan: 6 }],
      [
        { text: 'Reference', isHeader: true },
        { text: params.reference },
        { text: 'Type', isHeader: true },
        { text: params.type },
        { text: 'Use Bit TFA', isHeader: true },
        { text: params.useBitTfa ? 'Yes' : 'No' },
      ],
      [
        { text: `Min EMW (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: numberWithCommasDecimals(params.minEmw, 2) },
        { text: `Max EMW (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: numberWithCommasDecimals(params.maxEmw, 2) },
        { text: 'Limit Acceleration', isHeader: true },
        { text: params.limitAcceleration ? 'Yes' : 'No' },
      ],
      [
        { text: 'Continous Circ', isHeader: true },
        { text: params.continousCirc ? 'Yes' : 'No' },
        { text: 'Continous Tripping', isHeader: true },
        { text: params.continousTripping ? 'Yes' : 'No' },
        { text: '', isHeader: true },
        { text: '' },
      ],
      [
        { text: 'Include Gels', isHeader: true },
        { text: params.includeGels ? 'Yes' : 'No' },
        { text: 'Include Pipe Accel', isHeader: true },
        { text: params.includePipeAccel ? 'Yes' : 'No' },
        { text: '', isHeader: true },
        { text: '' },
      ],
    )

    return data
  }

  const addWellboreGeometry = (wellGeometry) => {
    if (!wellGeometry) return []
    if (!Array.isArray(wellGeometry)) return []

    let data = []
    data.push(
      [{ text: 'Wellbore Geometry', columnSpan: 5 }],
      [
        { text: 'Component Type', isHeader: true },
        { text: `MD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `TVD (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < wellGeometry.length; i++) {
      data.push([
        { text: unescapeHtml(wellGeometry[i].compType) },
        { text: numberWithCommasDecimals(wellGeometry[i].md, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].tvd, 2) },
        { text: numberWithCommasDecimals(wellGeometry[i].od, 3) },
        { text: numberWithCommasDecimals(wellGeometry[i].id, 3) },
      ])
    }

    return data
  }

  const addCalculationParameters = (fluids, params, drillStrings) => {
    if (!fluids) return []
    if (!params) return []
    if (!drillStrings) return []

    let bit = null
    for (let i = 0; i < drillStrings.length; i++) {
      if (drillStrings[i].type === 'Drill Bit') {
        bit = drillStrings[i]
        break
      }
    }

    let nozzles = ''
    if (bit) {
      if (Array.isArray(bit.bitJets) && bit.bitJets.length > 0) {
        for (let i = 0; i < bit.bitJets.length; i++) {
          if (nozzles !== '') nozzles += ', '
          nozzles += bit.bitJets[i].qty + 'x' + bit.bitJets[i].size + 's'
        }
      }
    }

    let data = []
    data.push(
      [{ text: 'Calculation Parameters', columnSpan: 6 }],
      [
        { text: `Calculation Depth (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.calcDepth, 2) },
        { text: `Model`, isHeader: true },
        { text: params?.model },
        { text: `ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`, isHeader: true },
        { text: numberWithCommasDecimals(params?.rop, 2) },
      ],
      [
        { text: `Mud Weight (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.mudWeight, 2) },
        { text: `PV (cP)`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.pv, 2) },
        { text: `YP (lbf/100ft\u00B2)`, isHeader: true },
        { text: numberWithCommasDecimals(fluids.yp, 2) },
      ],
      [
        { text: `Bit TFA (${getUnitsText(UNITS_FOR.Diameter)}\u00B2)`, isHeader: true },
        { text: numberWithCommasDecimals(bit?.tfa, 3) },
        { text: `Bit Nozzles (1/32${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: nozzles },
        { text: `RPM`, isHeader: true },
        { text: numberWithCommasDecimals(params?.rpm, 2) },
      ],
      [
        { text: `ECD Adjustment (${getUnitsText(UNITS_FOR.MudWeight)})`, isHeader: true },
        { text: numberWithCommasDecimals(params?.ecdAdjustment, 2) },
        { text: `SPP Safety Factor`, isHeader: true },
        { text: numberWithCommasDecimals(params?.sppSafetyFactor, 2) },
        { text: `Include Stabs`, isHeader: true },
        { text: params?.includeStabs ? 'Yes' : 'No' },
      ],
    )

    return data
  }

  const addMudRheology = (fluids) => {
    if (!fluids) return []

    let data = []
    data.push(
      [{ text: 'Mud Rheology', columnSpan: 6 }],
      [
        { text: '600', isHeader: true },
        { text: '300', isHeader: true },
        { text: '200', isHeader: true },
        { text: '100', isHeader: true },
        { text: '6', isHeader: true },
        { text: '3', isHeader: true },
        //{ text: 'N', isHeader: true },
        //{ text: 'K', isHeader: true },
      ],
      [
        { text: numberWithCommasDecimals(fluids.sixhun, 0) },
        { text: numberWithCommasDecimals(fluids.threehun, 0) },
        { text: numberWithCommasDecimals(fluids.twohun, 0) },
        { text: numberWithCommasDecimals(fluids.onehun, 0) },
        { text: numberWithCommasDecimals(fluids.dialSix, 0) },
        { text: numberWithCommasDecimals(fluids.dialThree, 0) },
        //{ text: numberWithCommasDecimals(fluids.n, 2) },
        //{ text: numberWithCommasDecimals(fluids.k, 2) },
      ],
    )

    return data
  }

  const addStaticLosses = (staticLosses) => {
    if (!staticLosses) return []
    if (!Array.isArray(staticLosses)) return []

    let data = []

    data.push(
      [{ text: 'Static Losses', columnSpan: 2 }],
      [
        { text: 'Name', isHeader: true },
        { text: `Pressure Loss (${getUnitsText(UNITS_FOR.Pressure)})`, isHeader: true },
      ],
    )

    for (let i = 0; i < staticLosses.length; i++) {
      data.push([
        { text: unescapeHtml(staticLosses[i].name) },
        { text: numberWithCommasDecimals(staticLosses[i].pressureLoss, 2) },
      ])
    }

    return data
  }

  const addDrillStrings = (drillStrings, startDepth) => {
    if (!drillStrings) return []
    if (!Array.isArray(drillStrings)) return []

    let data = []
    data.push(
      [{ text: 'String Data', columnSpan: 13 }],

      [
        { text: '', isHeader: true },
        { text: '#', isHeader: true },
        { text: 'Description', isHeader: true },
        { text: 'Type', isHeader: true },
        { text: 'Material', isHeader: true },
        { text: `OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ OD (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `TJ ID (${getUnitsText(UNITS_FOR.Diameter)})`, isHeader: true },
        { text: `Unit Weight (${getUnitsText(UNITS_FOR.UnitWeight)})`, isHeader: true },
        { text: `Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `Total Length (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
        { text: `MD Start/End (${getUnitsText(UNITS_FOR.Depth)})`, isHeader: true },
      ],
    )

    let totalCompWeight = 0
    let cumLength = 0

    for (let i = 0; i < drillStrings.length; i++) {
      totalCompWeight = totalCompWeight + drillStrings[i].casingWeight * drillStrings[i].length
      cumLength += drillStrings[i].length

      let compTop = startDepth - cumLength
      let compBottom = compTop + drillStrings[i].length
      let image = bhaImages.getPrintImage(drillStrings[i], false)

      data.push([
        { text: image, isImage: true, height: 31, rotate: false },
        { text: `${i + 1}` },
        { text: unescapeHtml(drillStrings[i].pipeName.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].type.replace('&dq;', '"')) },
        { text: unescapeHtml(drillStrings[i].material?.materialName.replace('&dq;', '"')) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeOd, 3) },
        { text: numberWithCommasDecimals(drillStrings[i].pipeId, 3) },
        { text: drillStrings[i].tjod ? numberWithCommasDecimals(drillStrings[i].tjod, 3) : '-' },
        { text: drillStrings[i].tjid ? numberWithCommasDecimals(drillStrings[i].tjid, 3) : '-' },
        { text: numberWithCommasDecimals(drillStrings[i].casingWeight, 2) },
        { text: numberWithCommasDecimals(drillStrings[i].length, 2) },
        { text: numberWithCommasDecimals(cumLength, 2) },
        {
          text: `${numberWithCommasDecimals(compTop, 2)}/${numberWithCommasDecimals(compBottom, 2)}`,
        },
      ])
    }

    return data
  }

  const getHydPdfData = async (chartElement, wellElement, selectedChart) => {
    return new Promise(async (resolve) => {
      if (typeof currentWellRef.current !== 'string') return
      if (currentWellRef.current === '') return
      if (!selectedEngCase) return
      if (!selectedEngCase.hasOwnProperty('recordId')) return

      let title = `Hydraulics Report`

      if (selectedChart === 'kickTolerance') {
        title = `Kick Tolerance`
      }

      let pumpPressureElement = chartElement.getChartType('pumpPressure')
      const pumpPressureCanvas = await html2canvas(pumpPressureElement.current)
      const pumpPressureImage = pumpPressureCanvas.toDataURL('image/png', 1.0)

      let ecdElement = chartElement.getChartType('ecd')
      const ecdCanvas = await html2canvas(ecdElement.current)
      const ecdImage = ecdCanvas.toDataURL('image/png', 1.0)

      let ecdSnapShotElement = chartElement.getChartType('ecdSnapShot')
      const ecdSnapShotCanvas = await html2canvas(ecdSnapShotElement.current)
      const ecdSnapShotImage = ecdSnapShotCanvas.toDataURL('image/png', 1.0)

      let surgeAndSwabElement = chartElement.getChartType('surgeAndSwab')
      const surgeAndSwabCanvas = await html2canvas(surgeAndSwabElement.current)
      const surgeAndSwabImage = surgeAndSwabCanvas.toDataURL('image/png', 1.0)

      let trippingSpeedsElement = chartElement.getChartType('trippingSpeeds')
      const trippingSpeedsCanvas = await html2canvas(trippingSpeedsElement.current)
      const trippingSpeedsImage = trippingSpeedsCanvas.toDataURL('image/png', 1.0)

      let minimumFlowElement = chartElement.getChartType('minimumFlow')
      const minimumFlowCanvas = await html2canvas(minimumFlowElement.current)
      const minimumFlowImage = minimumFlowCanvas.toDataURL('image/png', 1.0)

      let kickToleranceElement = chartElement.getChartType('kickTolerance')
      const kickToleranceCanvas = await html2canvas(kickToleranceElement.current)
      const kickToleranceImage = kickToleranceCanvas.toDataURL('image/png', 1.0)

      const pdfDoc = await generatePdfDocument(
        pumpPressureImage,
        ecdImage,
        ecdSnapShotImage,
        surgeAndSwabImage,
        trippingSpeedsImage,
        minimumFlowImage,
        kickToleranceImage,
        300,
        selectedChart,
      )

      resolve({
        fileName: `${title} - ${replaceEscapeSymbols(removeSpecialSymbols(currentWellRef.current))}`,
        data: (
          <PdfDocument
            data={pdfDoc}
            multiDocument={false}
            pageOrientation={'portrait'}
            reportSettings={userRole?.userPrefs?.reportSettings}
            footerText={`${userRole.organization} powered by Innova ${new Date().getFullYear()}`}
          />
        ),
      })
    })
  }

  return {
    loading,
    fetchHydraulics,
    getPumpPressureData,
    getEcdData,
    getSurgeAndSwabData,
    getGeometryAnnotations,
    getHydWarnings,
    getHydErrors,
    getHydPdfData,
    getEcdSnapShotData,
    getMinFlowData,
    getKickToleranceData,
    getMinMaxTripSpeedData,
  }
}

export default useHydraulics
