import { useRef, useEffect, useState } from 'react'
import useInnovaAxios from './useInnovaAxios'

function useToolOrders() {
  const _isMounted = useRef(false)
  const toolOrdersData = useRef([])
  const toolOrderDropDowns = useRef({})
  const toolDetailDropDowns = useRef([])
  const [loading, setLoading] = useState(false)
  const isLoadingData = useRef(false)
  const isLoadingStatusDropDown = useRef(false)
  const isLoadingToolDetailDropDown = useRef(false)

  const getToolOrders = useInnovaAxios({
    url: '/toolOrders/getToolOrderHeaders',
  })

  const getFilterLists = useInnovaAxios({ url: '/toolOrders/getFilterLists' })
  const getDropDownLists = useInnovaAxios({ url: '/toolOrders/getDropDownLists' })

  useEffect(() => {
    _isMounted.current = true
    fetchToolOrders()
    fetchStatusDropDown()
    fetchToolDetailsDropDown()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStatusDropDown = async () => {
    if (isLoadingStatusDropDown.current) return
    isLoadingStatusDropDown.current = true
    const res = await getFilterLists()
    isLoadingStatusDropDown.current = false
    if (res?.error) return
    if (!res?.data?.hasOwnProperty('status')) return
    toolOrderDropDowns.current = res?.data
  }

  const fetchToolDetailsDropDown = async () => {
    if (isLoadingToolDetailDropDown.current) return

    isLoadingToolDetailDropDown.current = true
    const res = await getDropDownLists()
    isLoadingToolDetailDropDown.current = false

    if (res?.error) return
    if (!Array.isArray(res?.data)) return

    let dropDownList = res?.data
    if (!dropDownList) return

    toolDetailDropDowns.current = dropDownList
  }

  const fetchToolOrders = async () => {
    if (isLoadingData.current) return
    isLoadingData.current = true
    if (_isMounted.current) setLoading(true)

    const res = await getToolOrders()
    isLoadingData.current = false
    if (!_isMounted.current) return
    if (res?.error) return
    if (!Array.isArray(res?.data)) return
    toolOrdersData.current = res.data

    setLoading(false)
  }

  const getToolOrdersData = () => {
    if (!toolOrdersData.current) return []
    if (!Array.isArray(toolOrdersData.current)) return []
    if (toolOrdersData.current?.length <= 0) return []

    return toolOrdersData.current
  }

  const getToolOrderDropDowns = () => {
    if (!toolOrderDropDowns.current) return {}
    if (!toolOrderDropDowns.current.hasOwnProperty('status')) return {}
    return toolOrderDropDowns.current
  }

  const getToolDetailDropDowns = (tag) => {
    if (!toolDetailDropDowns.current) return []
    if (!Array.isArray(toolDetailDropDowns.current)) return []
    if(!tag) return []
    if(typeof tag !== 'string') return []
    
    let list = toolDetailDropDowns.current.find((dropDown)=> dropDown.tagName === tag)
    if(!list) return []
    if(!Array.isArray(list.menuItems)) return []
    return list.menuItems
  }
  
  return {
    loading,
    fetchToolOrders,
    getToolOrdersData,
    getToolOrderDropDowns,
    getToolDetailDropDowns,
    fetchToolDetailsDropDown
  }
}

export default useToolOrders
