import { useEffect, useState, useRef } from 'react'
import * as msal from '@azure/msal-browser'
import { getItemFromLS, saveItemToLS } from 'utils/localStorage'

function useAzureAd() {
  const _isMounted = useRef(true)
  const msalInstance = useRef(null)
  const [loginAttempts, setLoginAttempts] = useState(0)
  const [config, setConfig] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    initializeAuthProvider()
  }, [config]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleFailedLogin() {
    setLoginAttempts(loginAttempts + 1)
  }

  async function initializeAuthProvider() {
    msalInstance.current = null
    if (!config) return

    if (!_isMounted.current) {
      handleFailedLogin()
      return
    }

    setIsLoading(true)
    if (!config) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('auth_type')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('client_id')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('client_secret')) {
      handleFailedLogin()
      return
    }

    if (!config.hasOwnProperty('tenant_id')) {
      handleFailedLogin()
      return
    }

    const msalConfig = {
      auth: {
        authority: `https://login.microsoftonline.com/${config.tenant_id}`,
        clientId: config.client_id,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        validateAuthority: true,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case msal.LogLevel.Error:
                console.error(message)
                return
              case msal.LogLevel.Info:
                console.info(message)
                return
              case msal.LogLevel.Verbose:
                console.debug(message)
                return
              case msal.LogLevel.Warning:
                console.warn(message)
                return
              default:
                return
            }
          },
        },
      },
    }

    msalInstance.current = new msal.PublicClientApplication(msalConfig)

    try {
      const loginResponse = await msalInstance.current.loginPopup({
        redirectUri: window.location.origin,
        scopes: [`api://${config.client_id}/icpapi_read`, 'offline_access'],
        prompt: 'select_account',
      })

      if (loginResponse) {
        if (!loginResponse.hasOwnProperty('accessToken')) return
        if (typeof loginResponse.accessToken !== 'string') return
        if (loginResponse.accessToken === '') return
        updateAuthData(loginResponse, config.user_name)
        setIsAuthenticated(true)
      }
    } catch (err) {
      console.error(err)
    }

    setLoginAttempts(loginAttempts + 1)
    setIsLoading(false)
  }

  function updateAuthData(data, userName) {
    if (!data) return
    if (!data.hasOwnProperty('accessToken')) return
    if (typeof userName !== 'string') return
    if (!userName) return

    let currentAuth = getItemFromLS('auth', 'currentAuth')
    currentAuth.user.name = userName
    currentAuth.access_token = data.accessToken
    currentAuth.refresh_token = '-1'
    currentAuth.issueTime = new Date(Date.now()).getTime()
    currentAuth.expires_at = new Date(Date.parse(data.expiresOn)).getTime()
    currentAuth.expires_in = currentAuth.expires_at - currentAuth.issueTime

    saveItemToLS('auth', 'currentAuth', currentAuth)
  }

  return { setConfig, isLoading, isAuthenticated, loginAttempts }
}

export default useAzureAd
