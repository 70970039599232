import React from 'react'
import { Box, SpeedDial, SpeedDialAction } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'

const OverlayPrefs = ({ showBitDepth, setShowBitDepth, showHoleDepth, setShowHoleDepth, colorScheme, setColorScheme }) => {
  const actions = [
    {
      icon: <Iconify icon='tabler:letter-h' style={{ color: showHoleDepth ? 'green' : 'red' }} />,
      name: showHoleDepth ? 'Hide Hole Depth' : 'Show Hole Depth',
      onClick: () => setShowHoleDepth(!showHoleDepth),
    },
    {
      icon: <Iconify icon='tabler:letter-b' style={{ color: showBitDepth ? 'green' : 'red' }} />,
      name: showBitDepth ? 'Hide Bit Depth' : 'Show Bit Depth',
      onClick: () => setShowBitDepth(!showBitDepth),
    },
  ]

  const colorCodeActions = [
    {
      icon: <Iconify icon='tabler:letter-a' style={{ color: colorScheme === 'activity' ? 'green' : 'red' }} />,
      name: 'by Activity',
      onClick: () => setColorScheme('activity'),
    },
    {
      icon: <Iconify icon='tabler:letter-b' style={{ color: colorScheme === 'bha' ? 'green' : 'red' }} />,
      name: 'by BHA',
      onClick: () => setColorScheme('bha'),
    },
    {
      icon: <Iconify icon='tabler:letter-p' style={{ color: colorScheme === 'phase' ? 'green' : 'red' }} />,
      name: 'by Phase',
      onClick: () => setColorScheme('phase'),
    },
  ]

  return (
    <React.Fragment>
      <Box sx={{ position: 'absolute', top: '16px', right: '68px', alignItems: 'center', justifyContent: 'center' }}>
        <SpeedDial
          ariaLabel='Data Overlay Preferences'
          icon={<Iconify icon='material-symbols-light:settings' style={{ color: '#ffffff', fontSize: 22 }} />}
          direction={'left'}
          hidden={false}
          FabProps={{ size: 'small' }}>
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement={'bottom'}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    transition: '.5s',
                    '&:hover': {
                      dropShadow: ' 5px 5px 10px 10px grey',
                      color: 'white',
                    },
                  },
                },
              }}
              onClick={() => action.onClick && action.onClick()}
            />
          ))}
        </SpeedDial>
        {showHoleDepth || showBitDepth ? (
          <SpeedDial
            ariaLabel='Color Scheme Preferences'
            icon={<Iconify icon='material-symbols:palette' style={{ color: '#ffffff', fontSize: 22 }} />}
            direction={'left'}
            hidden={false}
            FabProps={{ size: 'small' }}>
            {colorCodeActions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                tooltipPlacement={'bottom'}
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: 'rgb(19,62,96)',
                      fontSize: '12px',
                      fontFamily: 'Roboto',
                      transition: '.5s',
                      '&:hover': {
                        dropShadow: ' 5px 5px 10px 10px grey',
                        color: 'white',
                      },
                    },
                  },
                }}
                onClick={() => action.onClick && action.onClick()}
              />
            ))}
          </SpeedDial>
        ) : null}
      </Box>
    </React.Fragment>
  )
}

export default OverlayPrefs
