import { useRef, useState, useEffect } from "react";
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import {currentWellAtom} from 'atoms'
import { cloneDeep } from 'lodash'
import { useRecoilValue } from 'recoil'

function useGeosteering(wellName){
    const _isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const isDeleting = useRef(false)
    const isUpdating = useRef(false)
    const isAdding = useRef(false)
    const currentWell = useRecoilValue(currentWellAtom).wellName
    const currentWellRef = useRef(wellName ? wellName : currentWell)
    const geosteeringData = useRef([])

    const getGeoSteeringData = useInnovaAxios({
        url: '/well/geosteering/getGeosteeringData',
    })

    const addGeoSteeringData = useInnovaAxios({
        url: '/well/geosteering/addGeosteeringData',
    })

    const updateGeoSteeringData = useInnovaAxios({
        url: '/well/geosteering/updateGeosteeringData',
    })

    const deleteGeoSteeringData = useInnovaAxios({
        url: '/well/geosteering/deleteGeosteeringData',
    })

    useEffect(() => {
        _isMounted.current = true
        return () => {
            _isMounted.current = false
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        currentWellRef.current = wellName ? wellName : currentWell
    }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchGeoSteeringData = async () => {
        if (!currentWellRef.current) return { error: true, message: 'no wellName' }
        if (typeof currentWellRef.current !== 'string') return { error: true, message: 'wellname is not a string' }
        if (currentWellRef.current === '') return { error: true, message: 'well name is empty' }
        if (isLoading) return { error: true, message: 'currently loading' }
        if (!_isMounted.current) return { error: true, message: 'not mounted' }
        
        setIsLoading(true)
        const res = await getGeoSteeringData({ actualWell: currentWellRef.current })
        
        if (!_isMounted.current) return { error: true, message: 'not mounted' }
        setIsLoading(false)

        if(res?.error) return {error: true, message: `${res?.error?.response?.data?.error}`}
        
        geosteeringData.current = Array.isArray(res?.data) ? res.data : []
        return { error: false, message: '', data: Array.isArray(res?.data) ? res.data : [] }
    }

    const addGeoSteering = async (geosteering) => {
        if (!currentWellRef.current) return {error: true, message: 'no wellName'}
        if (typeof currentWellRef.current !== 'string') return {error: true, message: 'no wellName'}
        if (currentWellRef.current === '') return {error: true, message: 'no wellName'}
        if(isAdding.current) return 
        if(isLoading) return
        if (!_isMounted.current) return
        if(!Array.isArray(geosteering) || geosteering.length === 0) return []

        setIsLoading(true)
        isAdding.current = true
        const res = await addGeoSteeringData({ geosteering: JSON.stringify(geosteering), actualWell: currentWellRef.current })
        isAdding.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!res.data) return { error: true, message: `res.data not valid` }

        geosteeringData.current.push(res?.data)
        return {error: false, data: res?.data}
    }

    const updateGeoSteering = async (geosteering) => {
        if(!geosteering) return {error: true, message: 'no geosteering'}
        if(!Array.isArray(geosteeringData.current)) return {error: true, message: 'geosteeringData not valid'}
        if(geosteeringData.current.length === 0) return {error: true, message: 'geosteeringData not valid'}
        if(!currentWellRef.current) return {error: true, message: 'no wellName'}
        if(typeof currentWellRef.current !== 'string') return {error: true, message: 'no wellName'}
        if(currentWellRef.current === '') return {error: true, message: 'no wellName'}
        if(isUpdating.current) return {error: true, message: 'isUpdating'}
        if(isLoading) return {error: true, message: 'isLoading'}
        if (!_isMounted.current) return {error: true, message: 'not mounted'}

        setIsLoading(true)
        isUpdating.current = true
        const res = await updateGeoSteeringData({ geosteering: JSON.stringify(geosteering), actualWell: currentWellRef.current })
        isUpdating.current = false

        if (!_isMounted.current) return {error: true, message: 'not mounted'}
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if(!Array.isArray(geosteeringData.current)) return {error: true, message: 'geosteeringData not valid'}
        for (let i = 0; i < res?.data; i++) {
            let index = geosteeringData.current.findIndex(item => item.uid === res?.data[i].uid)
            if(index <0) return {error: true, message: 'index < 0'}
            geosteeringData.current[index] = cloneDeep(res?.data[i])
        }

        return {error: false, data: res?.data}
    }

    const deleteGeoSteering = async (geosteering) => {
        if(!geosteering) return {error: true, message: 'no geosteering'}
        if(!Array.isArray(geosteeringData.current)) return {error: true, message: 'geosteeringData not valid'}
        if(geosteeringData.current.length === 0) return {error: true, message: 'geosteeringData not valid'}
        if(!currentWellRef.current) return {error: true, message: 'no wellName'}
        if(typeof currentWellRef.current !== 'string') return {error: true, message: 'no wellName'}
        if(currentWellRef.current === '') return {error: true, message: 'no wellName'}
        if(isDeleting.current) return {error: true, message: 'isDeleting'}
        if(isLoading) return {error: true, message: 'isLoading'}
        if (!_isMounted.current) return {error: true, message: 'not mounted'}

        setIsLoading(true)
        isDeleting.current = true
        const res = await deleteGeoSteeringData({ geosteering: JSON.stringify(geosteering), actualWell: currentWellRef.current })
        isDeleting.current = false

        if (!_isMounted.current) return {error: true, message: 'not mounted'}
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if(!Array.isArray(geosteeringData.current)) return {error: true, message: 'geosteeringData not valid'}
        for (let i = 0; i < res?.data; i++) {
            let index = geosteeringData.current.findIndex(item => item.uid === res?.data[i].uid)
            if(index <0) return {error: true, message: 'index < 0'}
            geosteeringData.current.splice(index, 1)
        }

        return {error: false, data: res?.data}
    }

    return { isLoading, fetchGeoSteeringData, addGeoSteering, updateGeoSteering, deleteGeoSteering, geosteeringData }
}

export default useGeosteering