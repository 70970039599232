import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import {
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Snackbar,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import DropDownPicker from 'components/common/DropDownPicker'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Icon as Iconify } from '@iconify/react'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { cloneDeep } from 'lodash'
import { htmlSymbolHandling, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ToolOrdersDropDownsModal = ({ setOpen }) => {
  const _isMounted = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [dropDownData, setDropDownData] = useState([])
  const [selectedDropDown, setSelectedDropDown] = useState('')
  const selectedDropDownRef = useRef('')
  const [rowData, setRowData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const { getAgGridTheme } = useInnovaTheme()
  const inputRow = useRef({})
  const gridApi = useRef(null)

  const getDropDownData = useInnovaAxios({
    url: '/toolOrders/getDropDownLists',
  })

  const deleteDropDownItem = useInnovaAxios({
    url: '/toolOrders/deleteDropDownListItem',
  })

  const addDropDownItem = useInnovaAxios({
    url: '/toolOrders/addDropDownListItem',
  })

  const updateDropDownItem = useInnovaAxios({
    url: '/toolOrders/updateDropDownListItem',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDownData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedDropDown('CONNECTION_TYPE')
    setRowData(getDropDownItems('CONNECTION_TYPE'))
  }, [dropDownData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDownData = async () => {
    setLoading(true)
    const response = await getDropDownData()
    if (response?.error) return

    if (_isMounted.current) {
      setDropDownData(Array.isArray(response.data) ? response.data : [])
      setLoading(false)
    }
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleChangeDropDown = (event) => {
    setSelectedDropDown(event)
    selectedDropDownRef.current = event
    setRowData(getDropDownItems(event))
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (!gridApi.current) return
    gridApi.current?.autoSizeAllColumns()
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const handleAddRow = useCallback(async () => {
    if (!inputRow.current || !inputRow.current.hasOwnProperty('value')) return
    if (isLoading) return
    if (!gridApi.current) return
    if (!_isMounted.current) return
    if (!selectedDropDownRef.current) return
    if (typeof selectedDropDownRef.current !== 'string') return

    setLoading(true)
    let res = await addDropDownItem({ tagName: selectedDropDownRef.current, itemName: inputRow.current.value })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (res?.data) {
      inputRow.current.recordId = res.data?.recordId
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [selectedDropDownRef, addDropDownItem, setStatus, isLoading])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return
      if (isLoading) return
      if (!gridApi.current) return
      if (!_isMounted.current) return
      if (!selectedDropDownRef.current) return
      if (typeof selectedDropDownRef.current !== 'string') return

      setLoading(true)
      let res = await updateDropDownItem(
        htmlSymbolHandling({ tagName: selectedDropDownRef.current, itemName: data.value, recordId: data.recordId }),
      )
      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }
      gridApi.current.applyTransaction({
        update: [data],
      })
      autoSizeColumns()
    },
    [setStatus, isLoading, updateDropDownItem, selectedDropDownRef],
  )

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            params.data[focusedCell?.column?.colDef?.field] = ''
            handleUpdate(cloneDeep(params.data))
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      autoHeight: true,
      filter: null,
      editable: true,
    }
  }, [handleUpdate])

  const gridOptions = useMemo(() => {
    return {
      pinnedBottomRowData: [inputRow.current],
      getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
      suppressRowClickSelection: true,
      onCellEditingStopped: (params) => {
        if (params.node?.rowPinned === 'bottom') return handleAddRow()
        if (params?.newValue === params?.oldValue) return
        handleUpdate(params.data)
      },
      loadingOverlayComponent: CustomLoadingOverlay,
    }
  }, [handleAddRow, handleUpdate])

  const handleDelete = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('recordId')) return
      if (isLoading) return
      if (!gridApi.current) return
      if (!_isMounted.current) return
      if (!selectedDropDownRef.current) return
      if (typeof selectedDropDownRef.current !== 'string') return

      setLoading(true)
      let res = await deleteDropDownItem({ tagName: selectedDropDownRef.current, recordId: data.recordId })

      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })

        return
      }

      gridApi.current.applyTransaction({
        remove: [data],
      })
    },
    [deleteDropDownItem, selectedDropDownRef, isLoading],
  )

  const getDropDownItems = (tag) => {
    if (!dropDownData) return []
    if (!Array.isArray(dropDownData)) return []
    if (!tag) return []
    if (typeof tag !== 'string') return []

    let list = dropDownData.find((dropDown) => dropDown.tagName === tag)
    if (!list) return []
    if (!Array.isArray(list.menuItemsWithId)) return []
    return list.menuItemsWithId
  }

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Tooltip
            title={'Delete'}
            placement='left'
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: 'rgb(19,62,96)',
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                },
              },
            }}>
            <IconButton
              style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => {
                if (params?.node?.rowPinned === 'bottom') return
                handleDelete(params?.data)
              }}
              size='large'>
              {params.node?.rowPinned !== 'bottom' ? (
                <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
              ) : (
                <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    [handleDelete],
  )

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.recordId)
    }
  }, [])

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const createPinnedCellPlaceholder = ({ colDef }) => {
    if (colDef?.field === 'value') return colDef.headerName + '...'
  }

  const columnDefs = [
    {
      headerName: '',
      field: 'actions',
      colId: 'actions',
      width: 70,
      pinned: 'left',
      lockPosition: 'left',
      editable: false,
      filter: null,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      resizable: false,
      sortable: false,
      cellRenderer: actionIconRenderer,
    },
    {
      colId: 'value',
      field: 'value',
      headerName: 'value',
      valueFormatter: (params) =>
        isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : unescapeHtml(params?.value),
    },
  ]

  return (
    <Dialog
      fullWidth={true}
      PaperProps={{
        sx: {
          height: '50vh',
          backgroundColor: 'itemBackground',
          overflowY: 'visible',
        },
      }}
      open={true}
      onClose={() => setOpen(false)}>
      <DialogTitle>{`Tool Orders Drop Downs`}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', height: '100%', padding: '10px' }}>
          <Box
            sx={{
              width: '100%',
              border: '1px solid grey',
              maxHeight: 80,
              marginBottom: 10,
            }}>
            <DropDownPicker
              listOptions={dropDownData.map((item) => {
                return {
                  label: item.displayText,
                  value: item.tagName,
                }
              })}
              value={selectedDropDown}
              onChange={(e) => {
                handleChangeDropDown(e)
              }}
            />
          </Box>
          <Box className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 70px)' }}>
            <AgGridReact
              rowData={rowData}
              loading={isLoading}
              columnDefs={columnDefs}
              stopEditingWhenGridLosesFocus={true}
              onGridReady={onGridReady}
              getRowId={getRowId}
              headerHeight={0}
              onFirstDataRendered={onFirstDataRendered}
              gridOptions={gridOptions}
              defaultColDef={defaultColDef}
            />
          </Box>
        </Box>
        {status?.show ? (
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={status?.show}
            autoHideDuration={2000}
            onClose={handleCloseStatus}>
            <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </Alert>
          </Snackbar>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen()} color='secondary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ToolOrdersDropDownsModal
