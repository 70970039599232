import { useRef, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { userDatabaseAccessSelector, userUserRoleAtom } from 'atoms'
import useInnovaAuth from './useInnovaAuth'
import useInnovaAxios from './useInnovaAxios'

const useUserDatabaseAccess = (shouldFetch) => {
  const _isMounted = useRef(false)
  const isFetching = useRef(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const { user, getDatabaseOrg, saveDatabaseOrg } = useInnovaAuth()
  const [userDatabaseAccess, setUserDatabaseAccess] = useRecoilState(userDatabaseAccessSelector)

  const fetchDatabaseAccess = useInnovaAxios({
    url: '/user/getOrgDatabaseAccessList',
  })

  useEffect(() => {
    _isMounted.current = true
    getUserDatabaseAccess(user)

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserDatabaseAccess = async (user) => {
    if (!user) return
    if (typeof user?.name !== 'string') {
      setUserDatabaseAccess({ userName: '', organizations: [] })
      return
    }

    if (user.name === '') {
      setUserDatabaseAccess({ userName: '', organizations: [] })
      return
    }

    let userChanged = false
    if (userDatabaseAccess?.userName !== user.name) {
      userChanged = true
    }

    if (!userChanged) {
      if (Array.isArray(userDatabaseAccess) && userDatabaseAccess.length > 0) return
      if (!shouldFetch) return
    }

    if (isFetching.current) return

    isFetching.current = true
    const res = await fetchDatabaseAccess()
    isFetching.current = false

    if (!_isMounted.current) return
    if (res?.error) return
    if (!Array.isArray(res?.data)) return
    if (res.data.length === 0) return

    setUserDatabaseAccess({ userName: user.name, organizations: res.data.sort((a, b) => a.localeCompare(b)) })

    //check to see if the current database org is valid
    //this will only be called on a full page refresh where should fetch = true in the recoildata component
    let databaseOrg = getDatabaseOrg()
    if (typeof databaseOrg === 'string' && databaseOrg !== '') {
      if (res.data.includes(databaseOrg)) return
      saveDatabaseOrg('')
    }
  }

  const getUserDatabaseAccessList = () => {
    return Array.isArray(userDatabaseAccess?.organizations) ? userDatabaseAccess?.organizations : []
  }

  const hasMultiDbAccess = () => {
    return Array.isArray(userDatabaseAccess?.organizations) && userDatabaseAccess?.organizations.length > 1
  }

  const setAccessDatabase = (organization) => {
    if (!organization) return
    if (typeof organization !== 'string') return
    saveDatabaseOrg(organization)
  }

  const getCurrentDatabaseOrg = () => {
    let org = getDatabaseOrg()
    if (!org || typeof org !== 'string' || org === '') return userRole.organization
    return org
  }

  return { getUserDatabaseAccess, getUserDatabaseAccessList, hasMultiDbAccess, getCurrentDatabaseOrg, setAccessDatabase }
}

export default useUserDatabaseAccess
