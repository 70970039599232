import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, relativeTime, dateComparator, isDateLessThan } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AntiCollisionDashErrorsGrid = ({ data, height }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
   const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      headerClass: 'header-no-padding',
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return

    gridApi.current?.autoSizeAllColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('acDashStatusErrorsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('acDashStatusErrorsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AcDashErrorLogs.xlsx',
            sheetName: 'AcDashErrorLogs',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const dateTimeFormatter = useCallback((value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    value = value.replace(/Z/g, '')
    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })
  }, [])

  const relativeTimeFormatter = useCallback(relativeTime, [])

  const dateFilterComparator = useCallback(dateComparator, [])

  let columnDefs = useMemo(
    () => [
      {
        colId: 'rowNum',
        headerName: '#',
        valueGetter: (params) => params.node.rowIndex + 1,
        cellStyle: centerAlignCell,
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        field: 'time',
        colId: 'time',
        headerName: 'Time',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        valueFormatter: (params) => {
          return `${dateTimeFormatter(params.value)} [${relativeTimeFormatter(params.value)}]`
        },
      },
      {
        field: 'error',
        colId: 'error',
        headerName: 'error',
        cellStyle: leftAlignCell,
      },
    ],
    [dateFilterComparator, centerAlignCell, leftAlignCell, dateTimeFormatter, relativeTimeFormatter],
  )

  return (
    <Box sx={{ display: 'flex', width: '100%', height: `${height - 64}px` }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          rowData={Array.isArray(data) ? data : []}
          columnDefs={sortColDefs(columnDefs, 'acDashStatusErrorsGrid')}
          defaultColDef={defaultColDef}
          animateRows={true}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={getContextMenuItems}
        />
      </div>
    </Box>
  )
}

export default AntiCollisionDashErrorsGrid
