import React from 'react'
import 'components/Analytics/Charts/chart.css'
import { Box } from '@mui/material'
import { Scatter } from 'react-chartjs-2'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import crosshairPlugin from 'components/Analytics/Charts/ParameterComparison/crossHairPlugin'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits from 'components/common/hooks/useUnits'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const LineChartCjs = ({ title, data, yAxisTitle, xAxisTitle, tag, isToolbarExpanded}) => {
  const { theme } = useInnovaTheme()
  
  const interpolate = (distanceFromBit) => {
    if (!data) return -999.25
    if (!Array.isArray(data)) return -999.25
    if (data.length <= 1) return -999.25
    if (distanceFromBit === null || distanceFromBit === undefined) return -999.25
    if (typeof distanceFromBit !== 'number') return -999.25
    if (distanceFromBit < data[0].distanceFromBit) return -999.25
    if (distanceFromBit > data[data.length - 1].distanceFromBit) return -999.25

    for (let i = 1; i < data.length; i++) {
      if (distanceFromBit < data[i - 1].distanceFromBit || distanceFromBit > data[i].distanceFromBit) continue
      let x0 = data[i - 1].distanceFromBit
      let x1 = data[i].distanceFromBit
      let y0 = data[i - 1][tag]
      let y1 = data[i][tag]
      let x = distanceFromBit

      return y0 + (y1 - y0) * ((x - x0) / (x1 - x0))
    }

    return -999.25
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM,
        right: 20,
      },
    },
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: 'white',
        font: {
          size: 14,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        displayColors: false,
        callbacks: {
          title: function (a) {
            return `${title}`
          },
          label: function (d) {
            let labelData = [`${xAxisTitle}: ${numberWithCommasDecimals(d.parsed.x, 2)}`]
            let interpValue = numberWithCommasDecimals(interpolate(d.parsed.x), 2)
            if (interpValue === -999.25) return ''

            labelData.push(`${yAxisTitle}: ${interpValue}`)
            labelData.push(d.raw.desc)

            return labelData
          },
        },
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      crosshair: {
        line: {
          color: appColors.headerTextColor,
          width: 0.5,
        },
        sync: {
          enabled: true,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: true,
          zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
          zoomboxBorderColor: '#48F',
          zoomButtonText: 'Reset',
          zoomButtonClass: 'resetZoom',
        },
      },
    },
    scales: {
      x: {
        type: 'linear',
        align: 'center',
        beginAtZero: true,
        title: {
          display: false,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        grid: {
          color: appColors.headerTextColor,
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        title: {
          display: false,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        grid: {
          color: appColors.headerTextColor,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    animation: {
      duration: 0,
    },
  }

  const getChartData = (tag) => {
    if (!data) return { datasets: [] }
    if (!Array.isArray(data)) return { datasets: [] }

    const chartData = {
      datasets: [
        {
          type: 'line',
          label: 'SERIES',
          data: data.map((d) => ({ x: d.distanceFromBit, y: d[tag], desc: d.description })),
          borderColor: 'red',
          backgroundColor: 'red',
          borderWidth: 2,
        },
      ],
    }

    return chartData
  }

  return (
    <Scatter
      options={options}
      data={getChartData(tag)}
      plugins={[crosshairPlugin]}
      style={{ width: '100%', height: '200px' }}
    />
  )
}

const BhaAnalysisForceDeflectionChart = ({ data, chartData, isToolbarExpanded, height, width }) => {
  const { getUnitsText } = useUnits()
  const { getChartBackColor } = useInnovaTheme()

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        padding: '5px',
        display: 'flex',
        height: height,
        width: width,
      }}>
      <LineChartCjs
        isToolbarExpanded={isToolbarExpanded}
        xAxisTitle={`Dist`}
        yAxisTitle={chartData.yAxisTitle}
        data={data}
        tag={chartData.tag}
        title={`${chartData.title} (${chartData.units === 'degrees' ? '°' : getUnitsText(chartData.units)})`}
      />
    </Box>
  )
}

export default BhaAnalysisForceDeflectionChart
