import React from 'react'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { getPhaseStats } from 'utils/dailyActivityFunctions'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const PhaseStatisticsChartDrilled = ({ data, title = '', phaseFilter = [] }) => {
  const { getUnitsText } = useUnits()
  const { theme, getChartBackColor, getTextColor } = useInnovaTheme()

  const getDailyActivity = () => {
    if (!Array.isArray(data) || data?.length <= 0) return []
    let dailyActivityData = []

    for (let i = 0; i < data.length; i++) {
      if (!data[i].hasOwnProperty('dailyActivity')) continue
      if (!Array.isArray(data[i].dailyActivity)) continue

      for (let j = 0; j < data[i].dailyActivity.length; j++) {
        if (Array.isArray(phaseFilter) && phaseFilter.length > 0) {
          let phaseIndex = phaseFilter.findIndex((phase) => phase === data[i].dailyActivity[j].phase)
          if (phaseIndex < 0) continue
        }

        dailyActivityData.push(data[i].dailyActivity[j])
      }
    }
    return dailyActivityData
  }

  const createChartData = () => {
    let activityData = getDailyActivity()
    if (!activityData) return { labels: [], datasets: [] }

    let dailyData = getPhaseStats(activityData)

    const chartData = {
      labels: dailyData.map((day) => (Array.isArray(day.phases) ? day.phases : 'null')),
      datasets: [
        {
          data: dailyData.map((day) => Math.round(day.rotateDistance)),
          backgroundColor: appColors.rotateColor,
          label: `Rotate (${getUnitsText(UNITS_FOR.Depth)})`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => Math.round(day.slideDistance)),
          backgroundColor: appColors.slideColor,
          label: `Slide (${getUnitsText(UNITS_FOR.Depth)})`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    indexAxis: 'y',
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: title.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          color: getTextColor(),
        },
      },
      datalabels: {
        labels: {
          value: {
            font: {
              weight: 'bold',
            },
            color: '#000',
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: '#404040',
        },
      },
    },
  }

  return data ? (
    <Box sx={{ display: 'flex', width: '100%', height: '100%', backgroundColor: getChartBackColor() }}>
      <Bar type='bar' options={chartOptions} data={createChartData()} plugins={[ChartDataLabels]} />
    </Box>
  ) : (
    <Box>
      <Skeleton height={'80vh'} variant='rectangular' animation='wave' />
    </Box>
  )
}

export default PhaseStatisticsChartDrilled
