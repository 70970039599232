import React, { useEffect, useRef } from 'react'
import BhaStatisticsChartROP from 'components/WellPages/DrillStringPages/CarouselItems/BhaStatisticsChartROP'
import useWellData from 'components/common/hooks/useWellData'

const BhaStatRopCard = () => {
  const _isMounted = useRef(false)
  const { wellData } = useWellData()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  return <BhaStatisticsChartROP data={wellData} />
}

export default BhaStatRopCard
