import React, { useRef, useEffect, useState, useMemo, useCallback } from 'react'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Box, Tooltip, IconButton, Snackbar, Alert } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { saveItemToLS } from 'utils/localStorage'
import { Icon as Iconify } from '@iconify/react'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import { sortColDefs, CustomLoadingOverlay, getStringId, CustomHeader } from 'components/common/AgGridUtils'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { decToSexa } from 'utils/mapFunctions'
import { removeSpecialSymbols } from 'utils/stringFunctions'
import { threeDeeScan } from 'utils/threeDeeScan'
import { appColors } from 'utils'
import InterpolateModal from './InterpolateModal'
import rigIcon from 'assets/rigIcon.png'
import SelectOffsetsTreeModal from 'components/WellPages/SurveyPage/SelectOffsetsTreeModal'
import useWellData from 'components/common/hooks/useWellData'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import useSurveyReport from 'components/common/hooks/useSurveyReport'
import useAxiosGzip from 'components/common/hooks/useAxiosGzip'
import { uuidv4 } from 'utils/stringFunctions'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SurveysGrid = ({
  wellName,
  surveyName = '',
  isPlan = false,
  handleShowChart,
  showChart,
  isEngineering = false,
  isVisible = true,
  setSurveys = null,
  isEditable = false,
  setFilteredData,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [isLoading, setLoading] = useState(false)
  const [surveyData, setSurveyData] = useState([])
  const [wellPlanData, setWellPlanData] = useState([])
  const [crsUnits, setCRSUnits] = useState('')
  const inputRow = useRef({})
  const surveysAboveTieOnRef = useRef([])
  const userRole = useRecoilValue(userUserRoleAtom)
  const [resetCols, setResetCols] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const currentWellRef = useRef(wellName)
  const localCoordOffset = useRef({ localNorth: 0, localEast: 0 })
  const { getUnitsText, getUnitConversion } = useUnits()
  const [showInterpolate, setShowInterpolate] = useState(false)
  const [showOffsetsSelectTreeModal, setShowOffsetsSelectTreeModal] = useState(false)
  const { refreshWellData } = useWellData()
  const { getSurveyReportPdfData, getErrorEllipseReportPdfData, fetchErrorEllipseDiagnostic } = useSurveyReport()

  const getData = useAxiosGzip({
    url: '/well/getTrajectoryGz',
  })

  const updateSurvey = useInnovaAxios({
    url: '/well/updateSurveyStation',
  })

  const updatePageSurveys = useCallback(() => {
    if (!setSurveys) return
    let gridSurveys = []

    if (Array.isArray(surveysAboveTieOnRef.current) && surveysAboveTieOnRef.current.length > 0) {
      gridSurveys = cloneDeep(surveysAboveTieOnRef.current)
    }

    gridApi.current?.forEachNodeAfterFilterAndSort((node) => {
      if (node?.rowPinned === 'bottom') return
      if (node.data) gridSurveys.push(node.data)
    })

    setSurveys(gridSurveys)
  }, [setSurveys])

  useEffect(() => {
    _isMounted.current = true
    fetchSurveyData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!gridApi.current) return
    gridApi.current.onFilterChanged()
    setTimeout(() => {
      autoSizeColumns()
    }, 100)
  }, [surveyData])

  useEffect(() => {
    currentWellRef.current = wellName
    fetchSurveyData()
  }, [wellName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const fetchSurveyData = async () => {
    if (isLoading) return
    if (!currentWellRef.current) return
    if (typeof currentWellRef.current !== 'string') return
    if (currentWellRef.current === '') return

    setLoading(true)
    const res = await getData({
      wellName: currentWellRef.current,
      isPlan: isPlan,
      surveyName: surveyName ? surveyName : '',
    })

    if (!_isMounted.current) return
    if (res?.error) {
      setLoading(false)
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error}`,
      })

      return
    }

    setCRSUnits(res.data?.crsUnits ? res.data?.crsUnits : '')
    localCoordOffset.current = res.data?.localCoordOffset ? res.data?.localCoordOffset : { localNorth: 0, localEast: 0 }

    let wellPlanSurveys = Array.isArray(res.data?.wellPlan?.surveyData) ? res.data?.wellPlan?.surveyData : []
    setWellPlanData(wellPlanSurveys)

    surveysAboveTieOnRef.current = []
    if (Array.isArray(res.data?.surveysToSurface) && res.data?.surveysToSurface.length > 0) {
      for (let i = 0; i < res.data.surveysToSurface.length; i++) {
        let svy = cloneDeep(res.data.surveysToSurface[i])
        if (wellPlanSurveys.length > 0) {
          let acResult = threeDeeScan(svy, wellPlanSurveys)
          svy.ud = acResult?.acScan?.UD ? acResult?.acScan?.UD : 0
          svy.lr = acResult?.acScan?.LR ? acResult?.acScan?.LR : 0
          svy.dist = acResult?.acScan?.dist ? acResult?.acScan?.dist : 0
        }

        svy.uid = uuidv4()
        svy.orgMd = svy.md

        let svyIndex = getSvyIndex(surveysAboveTieOnRef.current, parseFloat(svy.md))
        if (svyIndex < 0) {
          surveysAboveTieOnRef.current.push(svy)
        }
      }
    }

    let surveys = []
    if (Array.isArray(res.data?.surveys)) {
      for (let i = 0; i < res.data.surveys.length; i++) {
        let svy = cloneDeep(res.data.surveys[i])
        if (wellPlanSurveys.length > 0) {
          let acResult = threeDeeScan(svy, wellPlanSurveys)
          svy.ud = acResult?.acScan?.UD ? acResult?.acScan?.UD : 0
          svy.lr = acResult?.acScan?.LR ? acResult?.acScan?.LR : 0
          svy.dist = acResult?.acScan?.dist ? acResult?.acScan?.dist : 0
        }

        svy.uid = uuidv4()
        svy.orgMd = svy.md
        surveys.push(svy)
      }
    }

    if (setSurveys) {
      if (surveysAboveTieOnRef.current.length > 0) {
        setSurveys([...surveysAboveTieOnRef.current, ...surveys])
      }

      if (surveysAboveTieOnRef.current.length === 0) {
        setSurveys(surveys)
      }
    } //Sets the surveys at the page level for the charts
    setSurveyData(surveys)
    setLoading(false)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return

    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    pinnedBottomRowData: !isEditable ? [] : [inputRow.current],
    onCellEditingStopped: (params) => {
      if (params.oldValue === params.newValue) return

      if (params.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      if (params.colDef.field === 'md') {
        let gridSurveys = []
        gridApi.current.forEachNodeAfterFilter((node) => {
          if (node.data) gridSurveys.push(node.data)
        })

        let svyIndex = getSvyIndex(gridSurveys, parseFloat(params.newValue))
        if (svyIndex >= 0 && svyIndex !== params.node.rowIndex) return false
      }

      let svy = cloneDeep(params.data)
      svy[params.colDef.field] = params.newValue
      handleUpdate(svy)
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  let reqFields = useMemo(() => ['md', 'inc', 'azi'], [])

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const updateFilteredData = useCallback( 
    () => {
    let filteredNodes = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }, [setFilteredData])

  const handleUpdateFunc = useCallback(
    async (data) => {
      if (!data) return
      if (!currentWellRef.current) return
      if (typeof currentWellRef.current !== 'string') return
      if (currentWellRef.current === '') return
      if (isLoading) return

      if (!Array.isArray(data.add)) data.add = []
      if (!Array.isArray(data.update)) data.update = []
      if (!Array.isArray(data.delete)) data.delete = []

      //This step is required because the md is really the primary key
      //If an update changes the md the action resolves to a delete then an add
      let svysToUpdate = []
      for (let i = 0; i < data.update.length; i++) {
        if (Math.abs(parseFloat(data.update[i].orgMd) - parseFloat(data.update[i].md)) > 0.1) {
          let svyCopy = cloneDeep(data.update[i])
          svyCopy.Md = svyCopy.orgMd
          data.delete.push(svyCopy)
          data.add.push(data.update[i])
          continue
        }

        svysToUpdate.push(data.update[i])
      }

      data.update = svysToUpdate

      let gridSurveys = []
      gridApi.current.forEachNodeAfterFilter((node) => {
        if (node.data) gridSurveys.push(node.data)
      })

      //Check to make sure no duplicates are added if so remove them
      let svysToAdd = []
      for (let i = 0; i < data.add.length; i++) {
        let svyIndex = getSvyIndex(gridSurveys, data.add[i].md)

        //Need this step because the grid data has already been updated with the new md
        if (svyIndex >= 0 && data.add[i].hasOwnProperty('uid')) {
          let svyNode = gridApi.current.getRowNode(data.add[i].uid)

          //Row has found itself, legitimate update
          if (svyNode?.rowIndex === svyIndex) {
            svyIndex = -1
          }
        }

        if (svyIndex < 0) svysToAdd.push(data.add[i])
      }

      data.add = svysToAdd

      let minMd = getMinDepth(data.add, 9999999)
      minMd = getMinDepth(data.delete, minMd)
      minMd = getMinDepth(data.update, minMd)

      if (data.add.length === 0 && data.update.length === 0 && data.delete.length === 0) {
        return
      }

      if (!_isMounted.current) return
      setLoading(true)

      let res = await updateSurvey({
        surveysToUpdate: JSON.stringify(data.update),
        surveysToAdd: JSON.stringify(data.add),
        surveysToDelete: JSON.stringify(data.delete),
        wellName: currentWellRef.current,
        surveyName: surveyName,
      })

      if (!_isMounted.current) return
      setLoading(false)

      if (res?.error) {
        setStatus({
          show: true,
          severity: 'error',
          message: `${res?.error?.response?.data?.error}`,
        })
        return
      }

      if (!Array.isArray(res.data)) {
        res.data = []
      }

      let gridSvysToAdd = []
      for (let i = 0; i < data.add.length; i++) {
        let svyIndex = getSvyIndex(res.data, data.add[i].md)
        if (svyIndex < 0) continue

        let svyToAdd = cloneDeep(res.data[svyIndex])
        svyToAdd.uid = uuidv4()
        svyToAdd.orgMd = svyToAdd.md
        gridSvysToAdd.push(svyToAdd)
      }

      if (!gridApi.current) return

      let gridSvysToUpdate = []

      //The get closest index function is used because the survey being searched for may
      //have been deleted
      let startIndex = getClosestSvyIndex(res.data, minMd)
      if (startIndex >= 0) {
        for (let i = startIndex; i < res.data.length; i++) {
          let svyIndex = getSvyIndex(gridSurveys, res.data[i].md)
          if (svyIndex < 0) continue

          let svyToUpdate = cloneDeep(res.data[i])
          svyToUpdate.uid = gridSurveys[svyIndex].uid
          svyToUpdate.orgMd = svyToUpdate.md

          let index = gridSvysToAdd.findIndex((svy) => Math.abs(svy.md - svyToUpdate.md) < 0.1)
          if (index < 0) gridSvysToUpdate.push(svyToUpdate)
        }
      }

      gridApi.current.applyTransaction({
        remove: data.delete,
        add: gridSvysToAdd,
        update: gridSvysToUpdate,
      })

      gridApi.current.applyColumnState({
        state: [{ colId: 'md', sort: 'asc' }],
      })

      updateFilteredData()
      autoSizeColumns()
      updatePageSurveys()
    },
    [updateSurvey, isLoading, updatePageSurveys, surveyName, updateFilteredData],
  )

  const handleDelete = useCallback(
    async (data) => {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) {
        setStatus({
          show: true,
          severity: 'error',
          message: `User does not have permission to delete`,
        })
        return
      }

      if (!Array.isArray(data)) return
      await handleUpdateFunc({ update: [], add: [], delete: data })
    },
    [userRole, handleUpdateFunc],
  )

  const deleteMultipleRows = useCallback(() => {
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows) || selectedRows.length < 2) return false
    handleDelete(selectedRows)
    return true
  }, [handleDelete])

  const defaultColDef = useMemo(() => {
    return {
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isDeleteKey) {
            if (deleteMultipleRows()) return true
          }

          if (isBackspaceKey || isDeleteKey) {
            return true
          }
        }
        return false
      },
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      cellStyle: centerAlignCell,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: { excelMode: 'windows' },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder, centerAlignCell, deleteMultipleRows])

  const scrollToBottom = () => {
    if (!gridApi.current) return
    const renderedRowCount = gridApi.current.getDisplayedRowCount() - 1
    if (renderedRowCount <= 0) return
    gridApi.current.ensureIndexVisible(renderedRowCount)
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    scrollToBottom()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => {
      gridApi.current?.autoSizeAllColumns()
    }, 100)
  }

  const saveColumnState = () => {
    if (!gridApi.current) return

    const colLayout = gridApi.current.getColumnState()
    if (colLayout) saveItemToLS('surveysGrid', 'colLayout', colLayout)
  }

  const getErrorEllipseDiagnostic = async () => {
    let res = await fetchErrorEllipseDiagnostic(currentWellRef.current)
    if (!_isMounted.current) return
    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.message}`,
      })
      return
    }

    if (!res.data) return
    let blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, `errorEllipseDiagnostic.txt`)
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('surveysGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style = "color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'Surveys_' + removeSpecialSymbols(currentWellRef.current).slice(0, 30) + '.xlsx',
            sheetName: 'Surveys',
            skipPinnedBottom: true,
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Error Ellipse Diagnostic',
        disabled: false,
        action: () => {
          getErrorEllipseDiagnostic()
        },
        icon: '<span class="iconify" data-icon="fluent-mdl2:diagnostic" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const getMinDepth = (svys, curMinMd) => {
    if (!Array.isArray(svys)) return curMinMd
    if (svys.length === 0) return curMinMd

    for (let i = 0; i < svys.length; i++) {
      if (parseFloat(svys[i].md) < curMinMd) curMinMd = parseFloat(svys[i].md)
    }

    return curMinMd
  }

  const getSvyIndex = (svys, md) => {
    if (!Array.isArray(svys)) return -1
    if (svys.length === 0) return -1
    if (typeof md !== 'number') return -1

    for (let i = 0; i < svys.length; i++) {
      if (Math.abs(parseFloat(svys[i].md) - md) < 0.1) return i
    }

    return -1
  }

  const getClosestSvyIndex = (svys, md) => {
    if (!Array.isArray(svys)) return -1
    if (svys.length === 0) return -1
    if (typeof md !== 'number') return -1

    for (let i = svys.length - 1; i >= 0; i--) {
      if (Math.abs(parseFloat(svys[i].md) - md) < 0.1) return i
      if (parseFloat(svys[i].md) < md) return i
    }

    return -1
  }

  const handleAddRow = useCallback(async () => {
    if (!isPinnedRowDataCompleted()) return
    await handleUpdateFunc({ add: [inputRow.current], update: [], delete: [] })

    let gridSurveys = []
    gridApi.current?.forEachNodeAfterFilterAndSort((node) => {
      if (node.data) gridSurveys.push(node.data)
    })

    if (gridSurveys.length > 0) {
      let index = getSvyIndex(gridSurveys, inputRow.current.md)
      if (index >= 0) {
        gridApi.current.ensureIndexVisible(index)
      }
    }

    inputRow.current = {}
    if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
  }, [handleUpdateFunc, isPinnedRowDataCompleted])

  const handleUpdate = useCallback(
    async (data) => {
      if (!data) return
      if (!data.hasOwnProperty('uid')) return
      await handleUpdateFunc({ update: [data], add: [], delete: [] })
    },
    [handleUpdateFunc],
  )

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <React.Fragment>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip
              title={params?.node?.rowPinned !== 'bottom' ? 'Delete' : 'Add'}
              placement='left'
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'rgb(19,62,96)',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                  },
                },
              }}>
              <IconButton
                style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
                onClick={() => {
                  if (params?.node?.rowPinned !== 'bottom' && params?.node?.rowIndex > 0) {
                    handleDelete([params.data])
                  }
                }}
                size='large'>
                {params.node?.rowPinned !== 'bottom' ? (
                  <Iconify color='red' icon='fa-regular:trash-alt' fontSize={16} />
                ) : (
                  <Iconify color='green' icon='fluent:add-12-filled' fontSize={16} />
                )}
              </IconButton>
            </Tooltip>
            {params?.node?.rowPinned !== 'bottom' ? (
              <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params?.node.rowIndex + 1}</Box>
            ) : null}
          </Box>
        </React.Fragment>
      )
    },
    [handleDelete],
  )

  const latLongValueFormatter = useCallback(
    (value, coordType) => {
      if (getUnitsText(UNITS_FOR.LatLong) === 'dms') {
        return decToSexa(value, coordType)
      }

      return numberWithCommasDecimals(value, 6)
    },
    [getUnitsText],
  )

  const isEditableFunc = useCallback(
    (params) => {
      if (!isEditable) return false
      if (!checkPermission('canEdit', userRole.roleAttributes?.permissions)) return false
      if (params?.node?.rowPinned === 'bottom') return true
      if (params?.node?.rowIndex === 0) return false
      return true
    },
    [userRole, isEditable],
  )

  let columnDefs = useMemo(
    () => [
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        colId: 'md',
        field: 'md',
        headerName: 'MD',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => isEditableFunc(params),
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Md...' : numberWithCommasDecimals(params.value, 2)),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        colId: 'inc',
        field: 'inc',
        headerName: 'INC',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => isEditableFunc(params),
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Inc...' : numberWithCommasDecimals(params.value, 2)),
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 180,
          precision: 2,
        },
      },
      {
        colId: 'azi',
        field: 'azi',
        headerName: 'AZI',
        pinned: 'left',
        lockPosition: 'left',
        editable: (params) => isEditableFunc(params),
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Azi...' : numberWithCommasDecimals(params.value, 2)),
        headerComponentParams: { units: '°' },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 360,
          precision: 2,
        },
      },
      {
        colId: 'cl',
        field: 'cl',
        headerName: 'CL',
        cellStyle: (params) => {
          if (params?.value > 500 * getUnitConversion(UNITS_FOR.Depth, true))
            return { ...centerAlignCell, backgroundColor: 'orange' }
          return centerAlignCell
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'tvd',
        field: 'tvd',
        headerName: 'TVD',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'tvdSs',
        field: 'tvdSs',
        headerName: 'TVDSS',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'ns',
        field: 'ns',
        headerName: 'NS',
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          return numberWithCommasDecimals(params.value + localCoordOffset.current.localNorth, 2)
        },
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'ew',
        field: 'ew',
        headerName: 'EW',
        valueFormatter: (params) => {
          if (params.node?.rowPinned === 'bottom') return ''
          return numberWithCommasDecimals(params.value + localCoordOffset.current.localEast, 2)
        },
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'vs',
        field: 'vs',
        headerName: 'VS',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'dls',
        field: 'dls',
        headerName: 'DLS',
        cellStyle: (params) => {
          if (params?.value > 50) return { ...centerAlignCell, backgroundColor: 'orange' }
          return centerAlignCell
        },
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Dogleg) },
      },
      {
        colId: 'br',
        field: 'br',
        headerName: 'BR',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Dogleg) },
      },
      {
        colId: 'tr',
        field: 'tr',
        headerName: 'TR',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Dogleg) },
      },
      {
        colId: 'tf',
        field: 'tf',
        headerName: 'TF',
        editable: false,
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: '°' },
      },
      {
        colId: 'gridNorth',
        field: 'gridNorth',
        headerName: 'GRID N',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: crsUnits?.toLowerCase() },
      },
      {
        colId: 'gridEast',
        field: 'gridEast',
        headerName: 'GRID E',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: crsUnits?.toLowerCase() },
      },
      {
        colId: 'latDeg',
        field: 'latDeg',
        headerName: 'LAT',
        valueFormatter: (params) => latLongValueFormatter(params.value, 'lat'),
      },
      {
        colId: 'longDeg',
        field: 'longDeg',
        headerName: 'LONG',
        valueFormatter: (params) => latLongValueFormatter(params.value, 'long'),
      },
      {
        colId: 'ud',
        field: 'ud',
        headerName: 'UD',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'lr',
        field: 'lr',
        headerName: 'LR',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'dist',
        field: 'dist',
        headerName: 'DIST',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'slideseen',
        field: 'slideSeen',
        headerName: 'Slide Seen',
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params.value, 2) : ''),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'slideRemaining',
        field: 'slideRemaining',
        headerName: 'Slide Remaining',
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params.value, 2) : ''),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      {
        colId: 'motorYield',
        field: 'motorYield',
        headerName: 'MY',
        valueFormatter: (params) => (params?.value > 0 ? numberWithCommasDecimals(params.value, 2) : ''),
      },
      {
        colId: 'closureAzi',
        field: 'closureAzi',
        headerName: 'Closure Azi',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: '°' },
      },
      {
        colId: 'closureDist',
        field: 'closureDist',
        headerName: 'Closure Dist',
        valueFormatter: (params) => numberWithCommasDecimals(params.value, 2),
        headerComponentParams: { units: getUnitsText(UNITS_FOR.Depth) },
      },
      { colId: 'ipm', field: 'ipm', headerName: 'IPM' },
    ],
    [
      latLongValueFormatter,
      actionIconRenderer,
      crsUnits,
      getUnitsText,
      isEditableFunc,
      getUnitConversion,
      centerAlignCell,
    ],
  )

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const components = useMemo(() => {
    return {
      agColumnHeader: CustomHeader,
    }
  }, [])

  const handleInterpolate = () => {
    setShowInterpolate(true)
  }

  const handleCloseInterpolate = async () => {
    setShowInterpolate(false)
  }

  const handleSelectOffsetsTree = () => {
    setShowOffsetsSelectTreeModal(true)
  }

  const onErrorEllipseReportPdf = async () => {
    if (isLoading) return

    setLoading(true)
    const pdfDoc = await getErrorEllipseReportPdfData(currentWellRef.current)

    if (!_isMounted.current) return
    setLoading(false)

    if (pdfDoc?.error) {
      setStatus({ show: true, severity: 'error', message: pdfDoc.message })
      return
    }

    if (!pdfDoc) {
      setStatus({ show: true, severity: 'error', message: 'Data for error ellipse missing' })
      return
    }

    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const onSurveyReportPdf = async () => {
    if (isLoading) return

    let gridSurveys = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node.data) {
        let data = cloneDeep(node.data)
        data.ns += localCoordOffset.current.localNorth
        data.ew += localCoordOffset.current.localEast
        gridSurveys.push(data)
      }
    })

    setLoading(true)
    const pdfDoc = await getSurveyReportPdfData(currentWellRef.current, gridSurveys)

    if (!_isMounted.current) return
    setLoading(false)

    if (pdfDoc?.error) {
      setStatus({ show: true, severity: 'error', message: pdfDoc.message })
      return
    }

    if (!pdfDoc) {
      setStatus({ show: true, severity: 'error', message: 'Data for survey report missing' })
      return
    }

    const blob = await pdf(pdfDoc.data).toBlob()
    FileSaver.saveAs(blob, pdfDoc.fileName)
  }

  const getMenuItems = () => {
    let actions = [
      { icon: <RefreshIcon />, name: 'Refresh', onClick: () => fetchSurveyData() },
      { icon: <PictureAsPdfIcon />, name: 'Survey report', onClick: onSurveyReportPdf },
      {
        icon: <Iconify icon='icon-park-outline:five-ellipses' style={{ color: '#FF0000', width: 28, height: 28 }} />,
        name: 'Error Ellipse Report',
        onClick: onErrorEllipseReportPdf,
      },
      {
        icon: (
          <Box
            component='img'
            src={rigIcon}
            alt={'rigGrey'}
            sx={{
              padding: '5px',
              alignItems: 'center',
              justifyContent: 'center',
              height: 25,
              width: 25,
              display: 'flex',
            }}
          />
        ),
        name: 'Select Offsets',
        onClick: () => {
          handleSelectOffsetsTree()
        },
      },
      {
        icon: <Iconify icon='fluent:point-scan-24-regular' style={{ color: '#000', width: 28, height: 28 }} />,
        name: 'Interpolate',
        onClick: () => {
          handleInterpolate()
        },
      },
    ]

    if (!isEngineering) {
      if (!showChart) {
        actions.push({
          icon: <Iconify icon='bxs:show' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
          name: 'Show Carousel',
          onClick: () => handleShowChart(),
        })
      } else {
        actions.push({
          icon: <Iconify icon='bxs:hide' style={{ color: appColors.itemTextColor, width: 28, height: 28 }} />,
          name: 'Hide Carousel',
          onClick: () => handleShowChart(),
        })
      }
    }
    return actions
  }

  const selectOffsetsTree = async (result) => {
    setStatus({
      show: true,
      severity: result ? 'success' : 'error',
      message: result ? 'Offsets updated' : 'Offsets update failed',
    })
    if (result) await refreshWellData()
  }

  const tabToNextCell = useCallback(
    (params) => {
      const previousCell = params.previousCellPosition
      const lastRowIndex = previousCell.rowIndex

      if (previousCell?.rowPinned === 'bottom') {
        if (!params.nextCellPosition) {
          return false
        }

        return {
          rowIndex: previousCell?.rowIndex,
          column: params?.nextCellPosition?.column,
          rowPinned: previousCell.rowPinned,
        }
      }

      let nextRowIndex = checkPermission('canEdit', userRole.roleAttributes?.permissions)
        ? lastRowIndex
        : params.nextCellPosition.rowIndex

      let nextColumn = params.nextCellPosition.column
      const renderedRowCount = gridApi.current.getDisplayedRowCount()

      if (
        !params.nextCellPosition.column.colDef.editable &&
        checkPermission('canEdit', userRole.roleAttributes?.permissions)
      ) {
        nextRowIndex++

        if (nextRowIndex >= renderedRowCount) {
          nextRowIndex = renderedRowCount - 1
        }

        nextColumn = gridApi.current.getColumn('md')
      }

      return {
        rowIndex: nextRowIndex,
        column: nextColumn,
        rowPinned: previousCell.rowPinned,
      }
    },
    [userRole],
  )

  function isValidNumber(input) {
    if (typeof input !== 'string' && typeof input !== 'number') return false
    if (typeof input === 'string') input = Number(input)
    return typeof input === 'number' && !isNaN(input)
  }

  function validatePastedValue(colName, newValue) {
    if (colName === 'md' && (newValue < 0 || newValue > 100000)) return false
    if (colName === 'inc' && (newValue < 0 || newValue > 180)) return false
    if (colName === 'azi' && (newValue < 0 || newValue > 360)) return false
    return true
  }

  const validateUpdateValue = useCallback((curCol, newValue) => {
    const allowedColumns = ['md', 'inc', 'azi']
    if (!curCol) return false
    if (!curCol.colDef.editable) return false
    if (allowedColumns.findIndex((col) => col === curCol.colDef.field) < 0) return false
    if (!isValidNumber(newValue)) return false
    if (!validatePastedValue(curCol.colDef.field, newValue)) return false
    return true
  }, [])

  const processDataFromClipboard = useCallback(
    (params) => {
      const data = cloneDeep(params.data)
      if (!Array.isArray(data)) return null
      if (data.length === 0) return null

      //Remove last row from pasted data if empty
      //Excel has a bug where regardless of the selected range there will be a blank row at the bottom
      const emptyLastRow = data[data.length - 1][0] === '' && data[data.length - 1].length === 1
      if (emptyLastRow) {
        data.splice(data.length - 1, 1)
      }

      //Remove commas from pasted data
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (typeof data[i][j] !== 'string') continue
          data[i][j] = data[i][j].replace(',', '')
        }
      }

      const lastIndex = gridApi.current.getDisplayedRowCount() - 1
      let lastSvy = gridApi.current.getDisplayedRowAtIndex(lastIndex)
      const focusedCell = gridApi.current.getFocusedCell()

      //Handle updates
      const rowsToUpdate = []
      let curRowIndex = focusedCell.rowIndex
      if (focusedCell?.rowPinned !== 'bottom') {
        for (let i = 0; i < data.length; i++) {
          if (curRowIndex > lastIndex) break
          if (!Array.isArray(data[i])) continue

          let svy = cloneDeep(gridApi.current.getDisplayedRowAtIndex(curRowIndex))
          let currentColumn = focusedCell.column
          if (!currentColumn) continue
          let valuesUpdated = false

          for (let j = 0; j < data[i].length; j++) {
            if (!validateUpdateValue(currentColumn, data[i][j])) {
              currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
              continue
            }

            svy.data[currentColumn.colDef.field] = parseFloat(data[i][j])
            valuesUpdated = true
            currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
          }

          if (valuesUpdated) rowsToUpdate.push(cloneDeep(svy.data))
          curRowIndex++
        }
      }

      //Handle add new rows
      const rowsToAdd = []
      let startPasteIndex = focusedCell?.rowPinned === 'bottom' ? lastIndex + 1 : focusedCell.rowIndex
      if (startPasteIndex + data.length - 1 > lastIndex) {
        const resultLastIndex = startPasteIndex + (data.length - 1)
        const numRowsToAdd = resultLastIndex - lastIndex

        let index = data.length - 1
        for (let i = 0; i < numRowsToAdd; i++) {
          const row = data.slice(index, index + 1)[0]
          index--

          if (!Array.isArray(row)) continue
          const newSurvey = {}
          let currentColumn = focusedCell.column
          if (!currentColumn) continue

          for (let j = 0; j < row.length; j++) {
            if (!validateUpdateValue(currentColumn, row[j])) {
              currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
              continue
            }

            newSurvey[currentColumn.colDef.field] = parseFloat(row[j])
            currentColumn = gridApi.current.getDisplayedColAfter(currentColumn)
          }

          if (!newSurvey.hasOwnProperty('md')) continue
          if (!newSurvey.hasOwnProperty('inc')) continue
          if (!newSurvey.hasOwnProperty('azi')) continue
          if (newSurvey.md <= parseFloat(lastSvy.data.md)) continue

          rowsToAdd.push(newSurvey)
        }
      }

      handleUpdateFunc({ add: rowsToAdd, update: rowsToUpdate, delete: [] })

      if (focusedCell?.rowPinned === 'bottom') {
        inputRow.current = {}
        if (gridApi.current) gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
      }

      return null
    },
    [handleUpdateFunc, validateUpdateValue],
  )

  const onGridPreDestroyed = () => {
    gridApi.current = null
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={confirm.onConfirm}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showInterpolate ? (
        <InterpolateModal
          open={showInterpolate}
          onClose={handleCloseInterpolate}
          crsUnits={crsUnits.toLowerCase()}
          wellPlan={wellPlanData?.surveyData}
        />
      ) : null}
      {showOffsetsSelectTreeModal ? (
        <SelectOffsetsTreeModal
          title='Selected offsets'
          open={showOffsetsSelectTreeModal}
          setOpen={setShowOffsetsSelectTreeModal}
          showSearch={true}
          wellName={currentWellRef.current}
          useOnApplyCallback={true}
          onApply={selectOffsetsTree}
        />
      ) : null}
      <Box
        sx={{
          height: isEngineering ? 'calc(100% - 25px)' : '100%',
          width: `100%`,
          maxWidth: `100%`,
        }}>
        <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
          <AgGridReact
            rowData={surveyData}
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'surveysGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={true}
            enableBrowserTooltips={true}
            gridOptions={gridOptions}
            headerHeight={40}
            components={components}
            onGridReady={onGridReady}
            onFirstDataRendered={onFirstDataRendered}
            getContextMenuItems={getContextMenuItems}
            tabToNextCell={tabToNextCell}
            enableRangeSelection={'true'}
            rowSelection={'multiple'}
            processDataFromClipboard={processDataFromClipboard}
            onGridPreDestroyed={onGridPreDestroyed}
            onFilterChanged={onFilterChanged}
          />
        </div>
      </Box>
      {isVisible ? (
        <Box
          sx={{
            backgroundColor: 'transparent',
            margin: '4px',
            padding: '12px',
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 2,
          }}>
          <MenuButton actions={getMenuItems()} />
        </Box>
      ) : null}
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default SurveysGrid
