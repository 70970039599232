import React, { useRef, useMemo, useState, useEffect, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { saveItemToLS } from 'utils/localStorage'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay, htmlSymbolHandling, getStringId } from 'components/common/AgGridUtils'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Alert, Snackbar, Box, IconButton } from '@mui/material'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { checkPermission } from 'components/userPermissions'
import { cloneDeep } from 'lodash'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'
import CentralizerRestrictionEditor from 'components/common/CellEditors/CentralizerRestrictionEditor'
import CentralizerForceEditor from 'components/common/CellEditors/CentralizerForceEditor'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const CasingCentralizersCatalog = ({ selectedCasing = null }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const dropDownsRef = useRef([])
  const isAdding = useRef(false)
  const isUpdating = useRef(false)
  const isDeleting = useRef(false)
  const selectedComponent = useRef(null)
  const inputRow = useRef({})
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const userRole = useRecoilValue(userUserRoleAtom)
  const [searchText, setSearchText] = useState('')
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()

  const getCentralizers = useInnovaAxios({
    url: '/componentCatalog/casingCentralizers/getCasingCentralizers',
  })

  const addCentralizer = useInnovaAxios({
    url: '/componentCatalog/casingCentralizers/addCasingCentralizers',
  })

  const updateCentralizer = useInnovaAxios({
    url: '/componentCatalog/casingCentralizers/updateCasingCentralizers',
  })

  const deleteCentralizer = useInnovaAxios({
    url: '/componentCatalog/casingCentralizers/deleteCasingCentralizers',
  })

  const getDropDowns = useInnovaAxios({
    url: '/componentCatalog/casingCentralizers/getCasingCentralizerTypes',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchDropDowns()

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchDropDowns = async () => {
    const res = await getDropDowns()
    if (!_isMounted.current) return
    if (res?.error) return
    dropDownsRef.current = Array.isArray(res?.data) ? res.data : []
  }

  const fetchCentralizers = async () => {
    if (isLoading) return
    if (_isMounted.current) setLoading(true)

    const res = await getCentralizers()

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) return
    let centralizerData = Array.isArray(res?.data) ? res.data : []

    if (selectedCasing) {
      centralizerData = centralizerData.filter((item) => Math.abs(item.size - selectedCasing.od) < 0.01)
    }

    if (gridApi.current) {
      gridApi.current.setGridOption('rowData', centralizerData)
    }

  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('centralizerCatalogGrid', 'colLayout', colLayout)
    }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('centralizerCatalogGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'CentralizersCatalog.xlsx',
            sheetName: 'Centralizers',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  let reqFields = useMemo(() => ['name', 'type', 'size'], [])

  const isPinnedRowDataCompleted = useCallback(() => {
    for (let i = 0; i < reqFields.length; i++) {
      if (!inputRow.current?.hasOwnProperty(reqFields[i])) return false
    }

    return true
  }, [reqFields])

  const handleAddRow = useCallback(async () => {
    if (isAdding.current) return
    if (!isPinnedRowDataCompleted()) return
    isAdding.current = true
    inputRow.current = htmlSymbolHandling(inputRow.current)

    let res = await addCentralizer({ centralizers: JSON.stringify([inputRow.current]) })
    isAdding.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      inputRow.current.uid = res.data.uid
      gridApi.current.applyTransaction({
        add: [inputRow.current],
      })
    }

    inputRow.current = {}
    if (gridApi.current) {
      gridApi.current.setGridOption('pinnedBottomRowData', [inputRow.current])
    }
  }, [addCentralizer, isPinnedRowDataCompleted])

  const handleUpdate = async (data) => {
    if (!Array.isArray(data)) return

    if (isUpdating.current) return

    for (let i = 0; i < data.length; i++) {
      data[i] = htmlSymbolHandling(data[i])
    }

    isUpdating.current = true
    let res = await updateCentralizer({ centralizers: JSON.stringify(data) })
    isUpdating.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        update: data,
      })
    }
  }

  const onClickDelete = useCallback(
    (data) => {
      if (!checkPermission('canDelete', userRole.roleAttributes?.permissions)) return
      selectedComponent.current = cloneDeep(data)

      setConfirm({
        show: true,
        title: 'Delete centralizer',
        text: `Are you sure you want to delete ${data.name}?`,
      })
    },
    [userRole],
  )

  const handleDelete = useCallback(async () => {
    if (!selectedComponent.current) return
    if (!selectedComponent.current.hasOwnProperty('uid')) return

    if (isDeleting.current) return
    isDeleting.current = true
    let res = await deleteCentralizer({ centralizers: JSON.stringify([selectedComponent.current]) })
    isDeleting.current = false

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }

    if (!res?.error) {
      gridApi.current.applyTransaction({
        remove: [selectedComponent.current],
      })
    }

    selectedComponent.current = null
  }, [deleteCentralizer])

  const actionIconRenderer = useCallback(
    (params) => {
      return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <IconButton
            style={{ padding: '5px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => (params.node?.rowPinned !== 'bottom' ? onClickDelete(params.data) : handleAddRow())}
            size='large'>
            <Iconify
              color={params.node?.rowPinned !== 'bottom' ? 'red' : 'green'}
              icon={params.node?.rowPinned !== 'bottom' ? 'fa-regular:trash-alt' : 'fluent:add-12-filled'}
              fontSize={16}
            />
          </IconButton>
          {params.node?.rowPinned !== 'bottom' ? (
            <Box style={{ textAlign: 'right', paddingLeft: '8px' }}>{params.node.rowIndex + 1}</Box>
          ) : null}
        </Box>
      )
    },
    [onClickDelete, handleAddRow],
  )

  const getStatusEnum = (status) => {
    switch (status) {
      case 'Prototype':
        return 1
      case 'Approved':
        return 2
      case 'Special':
        return 3
      default:
        return 0
    }
  }

  const sparkLineParams = useMemo(() => {
    return {
      sparklineOptions: {
        type: 'area',
        xKey: 'x',
        yKey: 'y',
        highlightStyle: {
          fill: 'rgb(143,185,77)',
          size: 10,
        },
        axis: {
          strokeWidth: 0,
        },
        marker: {
          size: 3,
          fill: '#66FF00',
          stroke: '#66FF00',
        },
        tooltip: {
          renderer: (params) => {
            return {
              backgroundColor: 'black',
              opacity: 0.7,
              color: 'white',
            }
          },
        },
      },
    }
  }, [])

  const shouldSuppressKeyboard = (params) => {
    let isBackspaceKey = params.event.keyCode === 8
    let isDeleteKey = params.event.keyCode === 46
    let isTabKey = params.event.keyCode === 9
    let isEnterKey = params.event.keyCode === 13
    let isLeftKey = params.event.keyCode === 37
    let isUpKey = params.event.keyCode === 38
    let isRightKey = params.event.keyCode === 39
    let isDownKey = params.event.keyCode === 40

    const gridShouldDoNothing =
      params.editing &&
      (isTabKey || isEnterKey || isBackspaceKey || isDeleteKey || isLeftKey || isUpKey || isRightKey || isDownKey)
    return gridShouldDoNothing
  }

  const columnDefs = useMemo(
    () => [
      {
        field: 'uid',
        colId: 'uid',
        headerName: '',
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        resizable: false,
        filter: null,
        width: 30,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: centerAlignCell,
        dndSource: true,
        valueFormatter: (params) => '',
      },
      {
        field: 'actions',
        colId: 'actions',
        width: 86,
        headerName: '',
        editable: false,
        filter: null,
        sortable: false,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        pinned: 'left',
        lockPosition: 'left',
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: actionIconRenderer,
      },
      {
        headerName: 'Name',
        colId: 'name',
        field: 'name',
        cellStyle: { alignItem: 'end' },
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Name...' : unescapeHtml(params.value)),
        pinned: 'left',
        lockPosition: 'left',
      },
      {
        headerName: `Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'size',
        field: 'size',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return 'Size...'
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'od',
        field: 'od',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'id',
        field: 'id',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `Hole Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'holeSize',
        field: 'holeSize',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `Restriction Size (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'restrictionSize',
        field: 'restrictionSize',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: 'Status',
        colId: 'status',
        field: 'status',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? '' : unescapeHtml(params.value)),
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return { values: ['None', 'Prototype', 'Approved', 'Special'] }
        },
        valueSetter: (params) => {
          params.data.status = params.newValue
          params.data.statusEnum = getStatusEnum(params.newValue)
          return true
        },
      },
      {
        headerName: 'Type',
        colId: 'type',
        field: 'type',
        cellStyle: leftAlignCell,
        valueFormatter: (params) => (isEmptyPinnedCell(params) ? 'Type...' : unescapeHtml(params.value)),
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: (params) => {
          return { values: Array.isArray(dropDownsRef.current) ? dropDownsRef.current.map((item) => item.name) : [] }
        },
        valueSetter: (params) => {
          let centType = dropDownsRef.current.find((item) => item.name === params.newValue)
          params.data.type = centType ? params.newValue : ''
          params.data.typeUid = centType ? centType.uid : -1
          return true
        },
      },
      {
        headerName: `#Bows`,
        colId: 'numberOfBows',
        field: 'numberOfBows',
        cellStyle: centerAlignCell,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10,
          precision: 0,
        },
      },
      {
        headerName: `Min Flex OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'minFlexOd',
        field: 'minFlexOd',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `Length (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'length',
        field: 'length',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 500,
          precision: 3,
        },
      },
      {
        headerName: `Operating CSA (${getUnitsText(UNITS_FOR.Diameter)})^2`,
        colId: 'operatingCsa',
        field: 'operatingCsa',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 1000,
          precision: 2,
        },
      },
      {
        headerName: `Weight (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'})`,
        colId: 'weight',
        field: 'weight',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 5000,
          precision: 2,
        },
      },
      {
        headerName: `Restore Force (${getUnitsText(UNITS_FOR.Force)})`,
        colId: 'restoreForce',
        field: 'restoreForce',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        headerName: `Standoff @ API Restore Force (%)`,
        colId: 'standOffAtApiRestoreForce',
        field: 'standOffAtApiRestoreForce',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
      },
      {
        headerName: `API 10D Compliant`,
        colId: 'api10dCompliant',
        field: 'api10dCompliant',
        cellStyle: centerAlignCell,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      },
      {
        headerName: `API Start Force (${getUnitsText(UNITS_FOR.Force)})`,
        colId: 'apiStartForce',
        field: 'apiStartForce',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        headerName: `API Restore Force (${getUnitsText(UNITS_FOR.Force)})`,
        colId: 'apiRestoreForce',
        field: 'apiRestoreForce',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        headerName: `Displacement Limit (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'displacementLimit',
        field: 'displacementLimit',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
      },
      {
        headerName: `Standoff Post Test (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'standoffPostTest',
        field: 'standoffPostTest',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 3,
        },
      },
      {
        headerName: `Bow Spring Rate (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'}/${getUnitsText(
          UNITS_FOR.Diameter,
        )})`,
        colId: 'bowSpringRate',
        field: 'bowSpringRate',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        headerName: `Radial Clearance (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'radialClearance',
        field: 'radialClearance',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
      },
      {
        headerName: `Crush Load (${getUnitsText(UNITS_FOR.Weight) === 'klbs' ? 'lbs' : 'kg'})`,
        colId: 'crushLoad',
        field: 'crushLoad',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 2,
        },
      },
      {
        headerName: `Crush Displacement (${getUnitsText(UNITS_FOR.Diameter)})`,
        colId: 'crushDisplacement',
        field: 'crushDisplacement',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 3)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 10000,
          precision: 3,
        },
      },
      {
        headerName: `Restore Force @67% SO (${getUnitsText(UNITS_FOR.Force)})`,
        colId: 'restoreForce67PercStandOff',
        field: 'restoreForce67PercStandOff',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100000,
          precision: 2,
        },
      },
      {
        headerName: `Min SO @ API Restore Force (%)`,
        colId: 'minStandOffAtApiRestoreForce',
        field: 'minStandOffAtApiRestoreForce',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          if (isEmptyPinnedCell(params)) return ''
          if (params.value === 0) return ''
          return `${numberWithCommasDecimals(params.value, 2)}`
        },
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          min: 0,
          max: 100,
          precision: 2,
        },
      },
      {
        colId: 'curvePointsAfterRestriction',
        headerName: 'Curve Pts After Restriction',
        width: 150,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams,
        cellEditor: CentralizerRestrictionEditor,
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            forces: Array.isArray(params.data?.curvePointsAfterRestriction)
              ? params.data?.curvePointsAfterRestriction
              : [],
          }
        },
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.curvePointsAfterRestriction)) return []
          return params.data?.curvePointsAfterRestriction.map((elem) => {
            return { x: elem.standoffGap, y: elem.force }
          })
        },
        valueSetter: (params) => {
          params.data.curvePointsAfterRestriction = Array.isArray(params.newValue) ? params.newValue : []
          return true
        },
        suppressKeyboardEvent: shouldSuppressKeyboard,
      },
      {
        colId: 'curvePointsOpenHole',
        headerName: 'Curve Pts Open Hole',
        width: 150,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams,
        cellEditor: CentralizerRestrictionEditor,
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            forces: Array.isArray(params.data?.curvePointsOpenHole) ? params.data?.curvePointsOpenHole : [],
          }
        },
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.curvePointsOpenHole)) return []
          return params.data?.curvePointsOpenHole.map((elem) => {
            return { x: elem.standoffGap, y: elem.force }
          })
        },
        valueSetter: (params) => {
          params.data.curvePointsOpenHole = Array.isArray(params.newValue) ? params.newValue : []
          return true
        },
        suppressKeyboardEvent: shouldSuppressKeyboard,
      },
      {
        colId: 'insertionForce',
        headerName: 'Insertion Force',
        width: 150,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.runningForces)) return []
          return params.data?.runningForces.map((elem) => {
            return { x: elem.holeSize, y: elem.insertionForce }
          })
        },
        cellEditor: CentralizerForceEditor,
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            forces: Array.isArray(params.data?.runningForces) ? params.data?.runningForces : [],
          }
        },
        valueSetter: (params) => {
          params.data.runningForces = Array.isArray(params.newValue) ? params.newValue : []
          return true
        },
        suppressKeyboardEvent: shouldSuppressKeyboard,
      },
      {
        colId: 'runningForce',
        headerName: 'Running Force',
        width: 150,
        cellRenderer: 'agSparklineCellRenderer',
        cellRendererParams: sparkLineParams,
        valueGetter: (params) => {
          if (!Array.isArray(params.data?.runningForces)) return []
          return params.data?.runningForces.map((elem) => {
            return { x: elem.holeSize, y: elem.runningForce }
          })
        },
        cellEditor: CentralizerForceEditor,
        cellEditorPopup: true,
        cellEditorParams: (params) => {
          return {
            forces: Array.isArray(params.data?.runningForces) ? params.data?.runningForces : [],
          }
        },
        valueSetter: (params) => {
          params.data.runningForces = Array.isArray(params.newValue) ? params.newValue : []
          return true
        },
        suppressKeyboardEvent: shouldSuppressKeyboard,
      },
    ],
    [getUnitsText, actionIconRenderer, sparkLineParams, leftAlignCell, centerAlignCell],
  )

  const createPinnedCellPlaceholder = useCallback(
    ({ colDef }) => {
      if (reqFields.findIndex((field) => field === colDef.field) < 0) return ''
      return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...'
    },
    [reqFields],
  )

  function isEmptyPinnedCell({ node, value }) {
    return (node?.rowPinned === 'bottom' && value == null) || (node?.rowPinned === 'bottom' && value === '')
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: false,
      editable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => (isEmptyPinnedCell(params) ? createPinnedCellPlaceholder(params) : undefined),
    }
  }, [createPinnedCellPlaceholder])

  const gridOptions = {
    suppressRowClickSelection: true,
    pinnedBottomRowData: [inputRow.current],
    onCellEditingStopped: (event) => {
      if (event.node?.rowPinned === 'bottom') {
        handleAddRow()
        return
      }

      handleUpdate([event.data])
    },
    getRowStyle: ({ node }) => (node?.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : 0),
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    fetchCentralizers()
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(), 100)
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const gridDragOver = (event) => {
    const dragSupported = event.dataTransfer.types.length

    if (dragSupported) {
      event.dataTransfer.dropEffect = 'copy'
      event.preventDefault()
    }
  }

  return (
    <React.Fragment>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={handleDelete}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
        <Box
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={searchBarStyle()}
          />
        </Box>
        <div
          className={getAgGridTheme()}
          style={{ width: '100%', height: 'calc(100% - 25px)' }}
          onDragOver={gridDragOver}>
          <AgGridReact
            loading={isLoading}
            columnDefs={sortColDefs(columnDefs, 'centralizerCatalogGrid')}
            defaultColDef={defaultColDef}
            getRowId={getRowId}
            animateRows={false}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            getContextMenuItems={getContextMenuItems}
            onFirstDataRendered={onFirstDataRendered}
            headerHeight={30}
            rowBuffer={50}
            rowDragManaged={true}
          />
        </div>
      </Box>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default CasingCentralizersCatalog
