import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { AgGridReact } from 'ag-grid-react'
import { array2pipestr } from 'utils'
import { analyticsSelectedWells, currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { Box } from '@mui/material'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { unescapeHtml } from '../../../../utils/htmlSymbolHandling'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { styled } from '@mui/styles'
import MenuButton from 'components/common/MenuButton'
import { Icon as Iconify } from '@iconify/react'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs, CustomLoadingOverlay ,getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getItemFromLS } from 'utils/localStorage'
import { cloneDeep } from 'lodash'
import { normalize } from 'utils/numberFunctions'
import WellRankingWeightsModal from './ParamWeightsModal'
import MapView from 'components/common/MapView/MapView'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const StyledMenuIcon = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  margin: '4px',
  padding: '12px',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 2,
}))

const PARAM_WEIGHTS = [
  {
    param: 'avgRop',
    weight: 1.0,
    invert: false,
    active: true,
    min: 999999,
    max: 0,
  },
  {
    param: 'numBha',
    weight: 1.0,
    invert: true,
    active: true,
    min: 999999,
    max: 0,
  },
  {
    param: 'numFailures',
    weight: 1.0,
    invert: true,
    active: true,
    min: 999999,
    max: 0,
  },
  {
    param: 'timeOnWell',
    weight: 1.0,
    invert: true,
    active: true,
    min: 999999,
    max: 0,
  },
  {
    param: 'slidePerc',
    weight: 1.0,
    invert: true,
    active: true,
    min: 999999,
    max: 0,
  },
  {
    param: 'totalDrilled',
    weight: 1.0,
    invert: false,
    active: true,
    min: 999999,
    max: 0,
  },
]

const WellRankingKpiGrid = () => {
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { getUnitsText } = useUnits()
  const [resetCols, setResetCols] = useState(false)
  const [showWeightsModal, setShowWeightsModal] = useState(false)
  const [showMap, setShowMap] = useState(false)

  const getInitialParamWeights = () => {
    let newParams = cloneDeep(PARAM_WEIGHTS)
    let savedParams = getItemFromLS('wellRankingWeights', 'params')
    if (!savedParams || !Array.isArray(savedParams)) return newParams

    for (let i = 0; i < savedParams.length; i++) {
      let index = newParams.findIndex((param) => param.param === savedParams[i].param)
      if (index < 0) continue

      newParams[index].weight = cloneDeep(savedParams[i].weight)
      newParams[index].active = savedParams[i].active
    }

    return newParams
  }

  const paramWeights = useRef(getInitialParamWeights())

  const getKpiData = useInnovaAxios({
    url: '/kpi/wellRanking',
    timeout: 60000,
  })

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('wellRankingKpiGrid', 'colLayout', colLayout)
    }
  }

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wellRankingKey)
    return () => {
      _isMounted.current = false
    }
  }, [setActivePage])

  useEffect(() => {
    if (_isMounted.current) {
      fetchData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const calcRank = (data) => {
    if (!Array.isArray(data) || data.length === 0) return []
    if (!Array.isArray(paramWeights.current) || paramWeights.current.length === 0) return data

    let p = paramWeights.current

    //Get min max values
    for (const item of data) {
      for (const param of p) {
        if (!item.hasOwnProperty(param.param)) continue
        const value = item[param.param]
        param.min = Math.min(param.min || Infinity, value)
        param.max = Math.max(param.max || -Infinity, value)
      }
    }

    // Calculate Score
    for (const item of data) {
      let score = 0
      let numParams = 0
      for (const param of p) {
        if (!param.active || !item.hasOwnProperty(param.param)) continue
        //if(param.weight <= 0) continue
        let normValue = normalize(item[param.param], param.min, param.max)
        if (param.invert) normValue = 1 - normValue
        score += normValue * param.weight
        numParams++
      }

      if (numParams > 0) score /= numParams
      item.score = score
    }

    // Sort
    data.sort((a, b) => b.score - a.score)

    // Assign ranks
    data.forEach((item, index) => (item.rank = index + 1))

    return data
  }

  const fetchData = async () => {
    if (isLoading) return
    if (!_isMounted.current) return
    if (!Array.isArray(selectedWells)) {
      setData([])
      return
    }

    if (selectedWells.length < 1) {
      setData([])
      return
    }

    setLoading(true)
    const dataResponse = await getKpiData({
      wellList: array2pipestr(selectedWells),
    })

    if (_isMounted.current) {
      setLoading(false)
      setData(dataResponse?.data ? calcRank(dataResponse.data) : [])
    }
  }

  const onXlsxExport = () => {
    let motorWS = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, motorWS, 'Well Ranking Kpi')
    XLSX.writeFile(wb, 'wellRanking.xlsx')
  }

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: false,
    editable: false,
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    sortable: true,
  })



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.wellName)
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        colId: 'avatar',
        width: 100,
        pinned: 'left',
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                margin: 'auto',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        headerName: 'Operator',
        field: 'operator',
        colId: 'operator',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.operator)
        },
      },
      {
        headerName: 'Well',
        field: 'wellName',
        colId: 'wellName',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Job #',
        field: 'jobNum',
        colId: 'jobNum',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        colId: 'rig',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueGetter: (params) => {
          return unescapeHtml(params.data?.rig)
        },
      },
      {
        headerName: 'State',
        field: 'state',
        colId: 'state',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'County',
        field: 'county',
        colId: 'county',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `Rank`,
        field: 'rank',
        colId: 'rank',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `Score`,
        field: 'score',
        colId: 'score',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.score, 4)
        },
      },
      {
        headerName: `Avg ROP (${getUnitsText(UNITS_FOR.Depth)}/hr)`,
        field: 'avgRop',
        colId: 'avgRop',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.avgRop, 2)
        },
      },
      {
        headerName: `#BHA`,
        field: 'numBha',
        colId: 'numBha',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `#Failures`,
        field: 'numFailures',
        colId: 'numFailures',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `#Days on Well`,
        field: 'timeOnWell',
        colId: 'timeOnWell',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: `Slide (%)`,
        field: 'slidePerc',
        colId: 'slidePerc',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.slidePerc, 2)
        },
      },
      {
        headerName: `Footage (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'totalDrilled',
        colId: 'totalDrilled',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.data?.totalDrilled, 2)
        },
      },
    ],
    [getUnitsText],
  )

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('wellRankingKpiGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'WellRankingKpi.xlsx',
            sheetName: 'WellRankingKpi',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const updateWellRankings = (params) => {
    setShowWeightsModal(false)
    if (!Array.isArray(params)) params = cloneDeep(PARAM_WEIGHTS)
    saveItemToLS('wellRankingWeights', 'params', params)

    paramWeights.current = params
    let newData = calcRank(data)
    setData(newData)

    if (gridApi.current) {
      gridApi.current.setGridOption('rowData',newData)
    }
  }

  const getWellData = () => {
    if (!Array.isArray(data)) return []
    if (data.length === 0) return []

    return data.map((item, index) => {
      return {
        id: index,
        actualWell: item.wellName,
        latitude: item.latitude,
        longitude: item.longitude,
        score: item.score,
      }
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100%', height: '100%' }}>
      {showWeightsModal ? (
        <WellRankingWeightsModal
          data={paramWeights.current}
          onClose={() => setShowWeightsModal(false)}
          onSubmit={updateWellRankings}
        />
      ) : null}
      <Box sx={{ width: '100%', height: '100%' }}>
        {!showMap ? (
          <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
            <AgGridReact
              
              rowData={data}
              loading={isLoading}
              columnDefs={sortColDefs(columnDefs, 'wellRankingKpiGrid')}
              defaultColDef={defaultColDef}
              getRowId={getRowId}
              animateRows={true}
              masterDetail={true}
              rowSelection='multiple'
              enableRangeSelection='true'
              enableBrowserTooltips={true}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              groupDisplayType={'groupRows'}
              groupRowsSticky={true}
              getContextMenuItems={getContextMenuItems}
              headerHeight={30}
            />
          </div>
        ) : null}
        {showMap ? <MapView wells={getWellData()} /> : null}
      </Box>
      <StyledMenuIcon>
        <MenuButton
          actions={[
            {
              icon: <Iconify icon='file-icons:microsoft-excel' color={'green'} />,
              name: 'Export to XLSX',
              onClick: onXlsxExport,
            },
            {
              icon: (
                <Iconify
                  icon={'fluent:settings-24-filled'}
                  style={{ color: '#2e6da4', height: '35px', width: '35px' }}
                />
              ),
              name: 'Settings',
              onClick: () => {
                setShowWeightsModal(true)
              },
            },
            {
              icon: <Iconify icon={'uiw:map'} style={{ color: '#2e6da4', height: '35px', width: '35px' }} />,
              name: 'Map',
              onClick: () => {
                setShowMap(!showMap)
              },
            },
          ]}
        />
      </StyledMenuIcon>
    </Box>
  )
}

export default WellRankingKpiGrid
