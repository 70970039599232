import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const TargetGeometryGrid = ({ selectedTarget, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [selectedTarget]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 200,
        cellDataType: false,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: true,
        cellRendererSelector: (params) => {
          if (params?.data?.type === 'boolean') {
            return { component: 'agCheckboxCellRenderer' }
          }
          return null
        },
        cellEditorSelector: (params) => {
          if (params?.data?.type === 'boolean') {
            return {
              component: 'agCheckboxCellEditor',
            }
          }

          if (params?.data?.type === 'number') {
            return {
              component: 'agNumberCellEditor',
              params: {
                min: params.data?.min,
                max: params.data?.max,
                precision: params.data?.precision,
              },
            }
          }

          if (params?.data?.type === 'dropdown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          return null
        },
        valueFormatter: (params) => {
          if (params?.data?.type === 'number') {
            return numberWithCommasDecimals(params.value, params.data?.precision)
          }
          return params.value
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      if (handleUpdate) handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getGeometryData = (data) => {
    if (!data) return []

    if (data.geometry === 'POINT') {
      return []
    }

    if (data.geometry === 'RECTANGLE') {
      return [
        {
          label: `Dip Angle`,
          value: data.dipAngle,
          id: 0,
          type: 'number',
          tag: `dipAngle`,
          precision: 3,
          min: -180,
          max: 180,
        },
        {
          label: `Dip Azimuth`,
          value: data.dipAzi,
          id: 1,
          type: 'number',
          tag: `dipAzi`,
          precision: 3,
          min: 0,
          max: 360,
        },
        {
          label: `Thickness Up`,
          value: data.thicknessUp,
          id: 2,
          type: 'number',
          tag: `thicknessUp`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Thickness Dn`,
          value: data.thicknessDn,
          id: 3,
          type: 'number',
          tag: `thicknessDn`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Aiming Point Offset NS`,
          value: data.nsOffset,
          id: 4,
          type: 'number',
          tag: `nsOffset`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Aiming Point Offset EW`,
          value: data.ewOffset,
          id: 5,
          type: 'number',
          tag: `ewOffset`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Length`,
          value: data.rectProperties.length,
          id: 6,
          type: 'number',
          tag: `length`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Width`,
          value: data.rectProperties.width,
          id: 7,
          type: 'number',
          tag: `width`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Rotation`,
          value: data.rotation,
          id: 8,
          type: 'number',
          tag: `rotation`,
          precision: 2,
          min: 0,
          max: 360,
        },
      ]
    }

    if (data.geometry === 'CIRCLE') {
      return [
        {
          label: `Dip Angle`,
          value: data.dipAngle,
          id: 0,
          type: 'number',
          tag: `dipAngle`,
          precision: 3,
          min: -180,
          max: 180,
        },
        {
          label: `Dip Azimuth`,
          value: data.dipAzi,
          id: 1,
          type: 'number',
          tag: `dipAzi`,
          precision: 3,
          min: 0,
          max: 360,
        },
        {
          label: `Thickness Up`,
          value: data.thicknessUp,
          id: 2,
          type: 'number',
          tag: `thicknessUp`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Thickness Dn`,
          value: data.thicknessDn,
          id: 3,
          type: 'number',
          tag: `thicknessDn`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Aiming Point Offset NS`,
          value: data.nsOffset,
          id: 4,
          type: 'number',
          tag: `nsOffset`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Aiming Point Offset EW`,
          value: data.ewOffset,
          id: 5,
          type: 'number',
          tag: `ewOffset`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Radius`,
          value: data.circleProperties.radius,
          id: 6,
          type: 'number',
          tag: `radius`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Arc Start`,
          value: data.circleProperties.arcStart,
          id: 7,
          type: 'number',
          tag: `arcStart`,
          precision: 2,
          min: 0,
          max: 360,
        },
        {
          label: `Arc End`,
          value: data.circleProperties.arcEnd,
          id: 8,
          type: 'number',
          tag: `arcEnd`,
          precision: 2,
          min: 0,
          max: 360,
        },
        {
          label: `Rotation`,
          value: data.rotation,
          id: 9,
          type: 'number',
          tag: `rotation`,
          precision: 2,
          min: 0,
          max: 360,
        },
      ]
    }

    if (data.geometry === 'ELLIPSE') {
      return [
        {
          label: `Dip Angle`,
          value: data.dipAngle,
          id: 0,
          type: 'number',
          tag: `dipAngle`,
          precision: 3,
          min: -180,
          max: 180,
        },
        {
          label: `Dip Azimuth`,
          value: data.dipAzi,
          id: 1,
          type: 'number',
          tag: `dipAzi`,
          precision: 3,
          min: 0,
          max: 360,
        },
        {
          label: `Thickness Up`,
          value: data.thicknessUp,
          id: 2,
          type: 'number',
          tag: `thicknessUp`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Thickness Dn`,
          value: data.thicknessDn,
          id: 3,
          type: 'number',
          tag: `thicknessDn`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Aiming Point Offset NS`,
          value: data.nsOffset,
          id: 4,
          type: 'number',
          tag: `nsOffset`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Aiming Point Offset EW`,
          value: data.ewOffset,
          id: 5,
          type: 'number',
          tag: `ewOffset`,
          precision: 2,
          min: -100000,
          max: 100000,
        },
        {
          label: `Semi Major Axis`,
          value: data.ellipseProperties.semiMajor,
          id: 6,
          type: 'number',
          tag: `semiMajor`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Semi Minor Axis`,
          value: data.ellipseProperties.semiMinor,
          id: 7,
          type: 'number',
          tag: `semiMinor`,
          precision: 2,
          min: 0,
          max: 100000,
        },
        {
          label: `Arc Start`,
          value: data.ellipseProperties.arcStart,
          id: 8,
          type: 'number',
          tag: `arcStart`,
          precision: 2,
          min: 0,
          max: 360,
        },
        {
          label: `Arc End`,
          value: data.ellipseProperties.arcEnd,
          id: 9,
          type: 'number',
          tag: `arcEnd`,
          precision: 2,
          min: 0,
          max: 360,
        },
        {
          label: `Rotation`,
          value: data.rotation,
          id: 10,
          type: 'number',
          tag: `rotation`,
          precision: 2,
          min: 0,
          max: 360,
        },
      ]
    }

    if (data.geometry === 'POLYGON') {
      let polygonOptions = [
        {
          label: `Dip Angle`,
          value: data.dipAngle,
          id: 0,
          type: 'number',
          tag: `dipAngle`,
          precision: 3,
          min: -180,
          max: 180,
        },
        {
          label: `Dip Azimuth`,
          value: data.dipAzi,
          id: 1,
          type: 'number',
          tag: `dipAzi`,
          precision: 3,
          min: 0,
          max: 360,
        },
        {
          label: `Rotation`,
          value: data.rotation,
          id: 4,
          type: 'number',
          tag: `rotation`,
          precision: 2,
          min: 0,
          max: 360,
        },
        {
          label: `Coordinates Input`,
          value: data.polygonProperties.inputMethMap ? 'MAP' : 'LOCAL',
          id: 5,
          type: 'dropdown',
          tag: `inputMethMap`,
          dropDownValues: ['MAP', 'LOCAL'],
        },
        {
          label: `Individual Thickness`,
          value: data.polygonProperties.individualThickness,
          id: 6,
          type: 'boolean',
          tag: `individualThickness`,
        },
      ]

      if (data.polygonProperties.individualThickness) {
        polygonOptions.push(
          {
            label: `Thickness Up`,
            value: data.thicknessUp,
            id: 2,
            type: 'number',
            tag: `thicknessUp`,
            precision: 2,
            min: -100000,
            max: 100000,
          },
          {
            label: `Thickness Dn`,
            value: data.thicknessDn,
            id: 3,
            type: 'number',
            tag: `thicknessDn`,
            precision: 2,
            min: -100000,
            max: 100000,
          },
        )
      }

      return polygonOptions
    }

    return []
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getGeometryData(selectedTarget)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default TargetGeometryGrid
