import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { cloneDeep } from 'lodash'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BidNotesGrid = ({ bidData, handleUpdate, editable }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateFunc = useCallback(
    (data) => {
      if (!gridApi.current) return
      if (!bidData.current) return
      if (!Array.isArray(data)) return

      let newData = cloneDeep(bidData.current)
      for (let i = 0; i < data.length; i++) {
        if (!newData.hasOwnProperty(data[i].tag)) continue
        newData[data[i].tag] = data[i].value
      }

      let res = handleUpdate(newData)
      if (!res) return
    },
    [bidData, handleUpdate],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        field: 'label',
        colId: 'label',
        minWidth: 150,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        headerName: '',
        field: 'value',
        colId: 'value',
        minWidth: 350,
        flex: 1,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        cellEditor: 'agLargeTextCellEditor',
        valueGetter: (params) => unescapeHtml(params.data?.value),
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 3000,
          rows: 10,
          cols: 50,
        },
        valueSetter: (params) => {
          if (params.oldValue === params.newValue) return

          let gridData = []
          gridApi.current.forEachNode((node) => {
            if (node.data) gridData.push(node.data)
          })

          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index >= 0) gridData[index].value = params.newValue

          handleUpdateFunc(gridData)
        },
      },
    ],
    [handleUpdateFunc],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: editable,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [editable])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getData = (data) => {
    let contact = [
      { label: `Front Page`, value: '', id: 1, type: 'text', tag: `frontPage` },
      { label: `Notes`, value: '', id: 2, type: 'text', tag: `notes` },
    ]

    if (!data) return contact
    for (let i = 0; i < contact.length; i++) {
      if (contact[i].tag === '') continue
      if (!data.hasOwnProperty(contact[i].tag)) continue
      if (!data[contact[i].tag]) continue
      contact[i].value = data[contact[i].tag]
    }

    return contact
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={getData(bidData.current)}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default BidNotesGrid
