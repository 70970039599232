import { appColors } from 'utils'

import motorIcon from 'assets/bhaIcons/motor.png'
import holeOpenerIcon from 'assets/bhaIcons/holeopener.png'
import drillBitIcon from 'assets/bhaIcons/drillbit.png'
import crossoverIcon from 'assets/bhaIcons/xo.png'
import mwdIcon from 'assets/bhaIcons/mwd.png'
import subIcon from 'assets/bhaIcons/sub.png'
import drillPipeIcon from 'assets/bhaIcons/drillpipe.png'
import drillCollarIcon from 'assets/bhaIcons/drillcollar.png'
import reamerIcon from 'assets/bhaIcons/reamer.png'
import casingIcon from 'assets/bhaIcons/casing.png'
import stabIcon from 'assets/bhaIcons/stab.png'

export function getDistinctBHA(drillStringData) {
  if (!drillStringData) return []
  if (drillStringData.length <= 0) return []

  let bhaComplete = []
  drillStringData.forEach((bha, i) => {
    bhaComplete.push({
      label: 'BHA# ' + bha.bhaHeader.bhaNumRep + ': ' + bha.bhaHeader.bhaDesc,
      value: i,
    })
  })

  return bhaComplete
}

export function getComponentColor(compType) {
  if (compType === 'Motor') return appColors.motorColor
  if (compType === 'MWD/LWD' || compType === 'MWD LWD') return appColors.mwdColor
  if (compType === 'Drill Bit') return appColors.bitColor

  return appColors.headerTextColor
}

export function getCompTypeTextFromEnum(compType) {
  if (compType === undefined || compType === null) return 'Unknown'
  if (typeof compType !== 'number') return compType

  if (compType === 0) return 'Drill Bit'
  if (compType === 1) return 'Hole Opener'
  if (compType === 2) return 'Circ Sub'
  if (compType === 3) return 'Cross Over'
  if (compType === 4) return 'RSS'
  if (compType === 5) return 'MWD/LWD'
  if (compType === 6) return 'Motor'
  if (compType === 7) return 'Turbine'
  if (compType === 8) return 'Stabilizer'
  if (compType === 9) return 'Drill Collar'
  if (compType === 10) return 'Drill Pipe/HWDP'
  if (compType === 11) return 'Jar'
  if (compType === 12) return 'Accelerator'
  if (compType === 13) return 'Sub'
  if (compType === 14) return 'Liner'
  if (compType === 15) return 'Casing/Tubing'
  if (compType === 16) return 'Agitator'
  if (compType === 17) return 'Reamer'

  return 'Unknown'
}

export function getDrillStringIcon(compTypeIn) {
  if (!compTypeIn) return subIcon
  if (typeof compTypeIn !== 'string') return subIcon

  let compType = compTypeIn.toLowerCase()

  if (compType.search('motor') >= 0) return motorIcon
  if (compType.search('opener') >= 0) return holeOpenerIcon
  if (compType === 'drill bit' || compType === 'drillbit') return drillBitIcon
  if (compType === 'cross over' || compType === 'xo' || compType === 'x/o') return crossoverIcon
  if (compType.search('mwd') >= 0) return mwdIcon
  if (compType === 'sub') return subIcon
  if (compType === 'drill pipe' || compType === 'drillpipe' || compType === 'drill pipe/hwdp') return drillPipeIcon
  if (compType === 'drillcollar' || compType === 'drill collar') return drillCollarIcon
  if (compType.search('reamer') >= 0) return reamerIcon
  if (compType.search('casing') >= 0) return casingIcon
  if (compType.search('stab') >= 0) return stabIcon

  return subIcon
}
