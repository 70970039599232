import React, { useEffect, useRef, useState } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import cloneDeep from 'lodash/cloneDeep'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import SplitPane from 'components/common/SplitPane'
import OperatorContactGrid from './OperatorContactGrid'
import OperatorAntiCollisionSettingsGrid from './OperatorAntiCollisionSettingsGrid'
import OperatorWarningGrid from './OperatorWarningGrid'
import OperatorLogo from './OperatorLogo'
import { useRecoilState } from 'recoil'
import { operatorPropertiesLayoutAtom, dateBaseTreeUpdateNameAtom } from 'atoms'
import useOperatorLogos, { LOGO_PRIMARY, LOGO_SECONDARY } from 'components/common/hooks/useOperatorLogos'
import { Box, CircularProgress, Alert, Snackbar } from '@mui/material'

const OperatorPropertiesPage = ({ operatorName, nodeId, canChangePage }) => {
  const _isMounted = useRef(false)
  const isUpdating = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [operatorData, setOperatorData] = useState(null)
  const [operatorDropDownData, setOperatorDropDownData] = useState([])
  const [pageLayout, setPageLayout] = useRecoilState(operatorPropertiesLayoutAtom)
  const { getLogo, deleteLogo, uploadLogo } = useOperatorLogos(operatorName)
  const [databaseTreeUpdateName, setDatabaseTreeUpdateName] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getOperator = useInnovaAxios({
    url: '/operator/getOperator',
  })

  const updateOperator = useInnovaAxios({
    url: '/operator/updateOperator',
  })

  const getOperatorDropDowns = useInnovaAxios({
    url: '/operator/getDropDownLists',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchDropDowns()
  }, [operatorData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!operatorData) return
    if (data.nodeId !== nodeId) return
    if (data.caller === 'UI') return

    let newData = cloneDeep(operatorData)
    newData.opName = data.name
    setOperatorData(newData)
    canChangePage.current = false
    setDatabaseTreeUpdateName(null)
    canChangePage.current = true
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '20%' : '40%'
    if (index >= pageLayout.length) return index === 0 ? '20%' : '40%'
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getOperator({ operator: operatorName })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return
    res.data[0].casing = !res.data[0].casing ? 'No' : 'Yes'
    setOperatorData(res.data[0])
  }

  const fetchDropDowns = async () => {
    if (isLoading) return
    if (!operatorData) return
    setLoading(true)
    const response = await getOperatorDropDowns({ operator: operatorName, uid: operatorData.uid })

    if (_isMounted.current) {
      setOperatorDropDownData(response.data)
      setLoading(false)
    }
  }

  const handleUpdate = async (data) => {
    if (!data) return false

    if (isUpdating.current) return false
    isUpdating.current = true

    if (data.casing === 'No') {
      data.casing = false
    } else data.casing = true

    canChangePage.current = false

    if (!data.hasOwnProperty('wptsData') || !data.wptsData) {
      data.wptsData = { useWpts: false, surfaceMargin: 0, projectionUncert: 0 }
    }

    data.surfaceMargin = data.wptsData.surfaceMargin
    data.projectionUncert = data.wptsData.projectionUncert
    data.useWpts = data.wptsData.useWpts

    if (!data.hasOwnProperty('acAlerts') || !data.acAlerts) {
      data.acAlerts = { surfaceMargin: 0, coneOfExpansion: 5 }
    }

    data.minSep = data.acAlerts.surfaceMargin
    data.coneOfExpansion = data.acAlerts.coneOfExpansion

    if (data.useWpts) {
      data.minSep = 0
    }

    let res = await updateOperator(htmlSymbolHandling(data))

    isUpdating.current = false
    canChangePage.current = true

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return false
    }

    data.casing = data.casing ? 'Yes' : 'No'
    setOperatorData(data)
    return true
  }

  const handleUpload = async (type, file) => {
    if (!file) return
    let res = await uploadLogo(type, file)

    setStatus({
      show: true,
      severity: res?.error ? 'error' : 'success',
      message: res?.error ? `${res?.message}` : `Logo uploaded successfully`,
    })
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      <SplitPane
        split='horizontal'
        size={getInitialPaneSize(1)}
        allowResize={true}
        defaultSize={getInitialPaneSize(1)}
        onDragFinished={(params) => onDragFinished(params, 1)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SplitPane
          split='vertical'
          onDragFinished={(params) => onDragFinished(params, 0)}
          defaultSize={getInitialPaneSize(0)}
          size={getInitialPaneSize(0)}
          minSize={'10%'}
          allowResize={true}>
          <OperatorContactGrid operatorData={operatorData} handleUpdate={handleUpdate} nodeId={nodeId} />
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <OperatorLogo
              logo={getLogo(LOGO_PRIMARY)}
              deleteLogo={() => deleteLogo(LOGO_PRIMARY)}
              uploadLogo={(file) => handleUpload(LOGO_PRIMARY, file)}
              text={'Primary Logo'}
            />
            <OperatorLogo
              logo={getLogo(LOGO_SECONDARY)}
              deleteLogo={() => deleteLogo(LOGO_SECONDARY)}
              uploadLogo={(file) => handleUpload(LOGO_SECONDARY, file)}
              text={'Secondary Logo'}
            />
          </Box>
        </SplitPane>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(2)}
          allowResize={true}
          minSize={'10%'}
          defaultSize={getInitialPaneSize(2)}
          onDragFinished={(params) => onDragFinished(params, 2)}>
          <OperatorAntiCollisionSettingsGrid
            operatorData={operatorData}
            operatorDropDownData={operatorDropDownData}
            handleUpdate={handleUpdate}
          />
          <OperatorWarningGrid
            operatorName={operatorName}
            operatorDropDownData={operatorDropDownData}
            gridType={operatorData?.warningType}
          />
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default OperatorPropertiesPage
