import React, { useRef, useEffect } from 'react'
import { round } from 'utils/numberFunctions'
import { useRecoilValue } from 'recoil'
import { Scatter } from 'react-chartjs-2'
import { chartSeriesColors, isoStringToNumeric } from 'utils'
import { currentInfrastructureChartRangeAtom } from 'atoms'
import moment from 'moment'
import { currentInfrastructureEc2Selector } from 'atoms'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

export const Ec2UtilizationChart = ({ showTitle }) => {
  const _isMounted = useRef()
  const data = useRecoilValue(currentInfrastructureEc2Selector)
  const timeScaleSetting = useRecoilValue(currentInfrastructureChartRangeAtom)
  const chartRef = useRef()
  const { theme } = useInnovaTheme()
  let thirtyDaysAgo = moment().subtract(30, 'd').startOf('minute').format('YYYY-MM-DDThh:mm:ss')
  let rangeStart = moment().subtract(1, 'd').startOf('minute').format('YYYY-MM-DDTHH:mm:ss')
  let rangeEnd = moment().startOf('minute').format('YYYY-MM-DDTHH:mm:ss')

  const getZoomAxisRange = () => {
    if (timeScaleSetting) {
      return { min: moment(thirtyDaysAgo).valueOf(), max: moment(rangeEnd).valueOf() }
    } else {
      return { min: moment(rangeStart).valueOf(), max: moment(rangeEnd).valueOf() }
    }
  }

  useEffect(() => {
    if (_isMounted.current) {
      renderChartScales()
    }
  }, [timeScaleSetting, data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true
    renderChartScales()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const renderChartScales = () => {
    if (_isMounted.current && chartRef.current !== undefined) {
      if (timeScaleSetting) {
        chartRef.current.config._config.options.scales.x.min = thirtyDaysAgo
        chartRef.current.config._config.options.plugins.zoom.limits.x = {
          min: moment(thirtyDaysAgo).valueOf(),
          max: moment().valueOf(),
        }
        chartRef.current.config._config.options.scales.x.time = {
          unit: 'day',
          displayFormats: {
            hour: 'h A',
          },
        }
      } else {
        chartRef.current.config._config.options.scales.x.min = rangeStart
        chartRef.current.config._config.options.plugins.zoom.limits.x = {
          min: moment(rangeStart).valueOf(),
          max: moment().valueOf(),
        }
        chartRef.current.config._config.options.scales.x.time = {
          unit: 'hour',
          displayFormats: {
            hour: 'h A',
          },
        }
      }
      chartRef.current.config._config.options.scales.x.ticks.autoSkipPadding = 0
      chartRef.current.update()
    }
  }

  const formatXAxisLabels = () => {
    if (!timeScaleSetting) {
      return {
        unit: 'hour',
        displayFormats: {
          hour: 'h A',
        },
      }
    } else {
      return {
        unit: 'day',
        displayFormats: {
          hour: 'h A',
        },
      }
    }
  }

  const formatXAxisRange = () => {
    if (!timeScaleSetting) {
      return rangeStart
    } else {
      let thirtyDaysAgo = moment().subtract(30, 'days').startOf('minute').format('YYYY-MM-DDTHH:mm:ss')
      return thirtyDaysAgo
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    onResize: (chart) => {
      renderChartScales()
    },
    layout: {
      padding: {
        right: 20,
      },
    },
    animation: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      annotation: {},
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: showTitle,
        text: 'EC2 CPU Usage',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return [
              `${
                !timeScaleSetting
                  ? new Date(context.parsed.x).toLocaleTimeString('en-US')
                  : moment(context.parsed.x).format('DD-MMM-HH:MM')
              } Date/Time`,
              ` ${round(context.parsed.y, 1)} CPU(%)`,
            ]
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: getZoomAxisRange(),
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          color: (context) => {
            if (context.tick.label === '12 AM') {
              return appColors.slideColor
            } else {
              return 'rgba(102, 102, 102, 1)'
            }
          },
        },
        grid: {
          color: '#404040',
        },
        min: formatXAxisRange(),
        max: rangeEnd,
        type: 'time',
        time: formatXAxisLabels(),
      },
      y: {
        beginAtZero: true,
        reverse: false,
        title: {
          display: true,
          text: 'CPU (%)',
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        min: 0,
        max: 100,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  }

  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  const chartJsData = {
    datasets: [],
  }

  if (Array.isArray(data)) {
    data.forEach((instance, idx) => {
      if (instance.hasOwnProperty('dataPoints') && instance?.dataPoints?.length > 0) {
        let instanceData = instance.dataPoints.map((datapoint) => {
          return { x: parseFloat(isoStringToNumeric(datapoint.ts)), y: datapoint.value }
        })

        let newDataset = {
          type: 'line',
          label: instance.instanceId?.length > 0 ? instance.instanceId : 'ZZZZ',
          data: instanceData,
          borderColor: chartSeriesColors[idx],
          backgroundColor: chartSeriesColors[idx],
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          segment: {
            borderColor: (ctx) => segColor(ctx, chartSeriesColors[idx]),
            borderWidth: 3,
          },
        }
        chartJsData.datasets.push(newDataset)
      }
    })
  }

  return <Scatter ref={chartRef} options={options} data={chartJsData} />
}

export default Ec2UtilizationChart
