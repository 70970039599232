import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const SoloCloudSettingsGrid = ({ config, handleUpdateConfig }) => {
  const _isMounted = useRef(false)
  const gridApi = React.useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const columnDefs = [
    {
      headerName: '',
      field: 'label',
      colId: 'label',
      minWidth: 150,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
      },
    },
    {
      headerName: '',
      field: 'value',
      colId: 'value',
      minWidth: 350,
      editable: (params) => {
        if (!params?.data?.hasOwnProperty('editable')) return true
        if (!params.data?.editable) return false
        return true
      },
      cellDataType: false,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
      },
      cellEditorSelector: (params) => {
        if (params.data?.type === 'dropDown') {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: params.data?.dropDownValues,
            },
          }
        }

        if (params.data?.type === 'number') {
          return {
            component: 'agTextCellEditor',
            params: {
              precision: 3,
              min: params.data?.min,
              max: params.data?.max,
            },
          }
        }

        if (params.data?.type === 'bool') {
          return {
            component: 'agCheckboxCellEditor',
          }
        }

        return {
          component: 'agTextCellEditor',
        }
      },
      valueSetter: (params) => {
        if (params.oldValue === params.newValue) return

        let gridData = []
        gridApi.current.forEachNode((node) => {
          gridData.push(node.data)
        })

        if (params.data?.type === 'dropDown' || params.data?.type === 'bool' || params.data?.type === 'text') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (index >= 0) {
            gridData[index].value = params.newValue
          }
        }

        if (params.data?.type === 'number') {
          let index = gridData.findIndex((data) => data.id === params.data?.id)
          if (params.newValue === null || params.newValue === undefined) params.newValue = params.data?.min
          if (index >= 0) gridData[index].value = parseFloat(params.newValue)
          if (gridData[index].value < params.data?.min) gridData[index].value = params.data?.min
          if (gridData[index].value > params.data?.max) gridData[index].value = params.data?.max
        }
      },
      cellRendererSelector: (params) => {
        if (params.data?.type === 'bool') {
          return {
            component: 'agCheckboxCellRenderer',
          }
        }
        return null
      },
      valueFormatter: (params) => {
        if (params.data?.type === 'number') {
          return `${numberWithCommasDecimals(params.data?.value, 3)}`
        }
        return params.data?.value
      },
    },
  ]

  const getCurveData = (data) => {
    let curveData = [
      { label: `Well Name`, value: '', id: 0, type: 'text', tag: 'wellName', editable: false },
      { label: `Project UUID`, value: '', id: 1, type: 'text', tag: 'projectUuid', editable: false },
      { label: `Project UOM`, value: '', id: 2, type: 'text', tag: 'projectUom', editable: false },
      { label: `Well UUID`, value: '', id: 3, type: 'text', tag: 'lateralUuid', editable: false },
      { label: `Target Line UUID`, value: '', id: 4, type: 'text', tag: 'targetLineUuid', editable: false },
      { label: `Auto Update`, value: '', id: 5, type: 'bool', tag: 'autoCheck', editable: true },
      {
        label: `Transition Type`,
        value: '',
        id: 7,
        type: 'dropDown',
        tag: 'backToPlanTransition',
        editable: true,
        dropDownValues: [],
      },
      {
        label: `Transition DLS`,
        value: '',
        id: 8,
        type: 'number',
        tag: 'backToPlanDls',
        editable: true,
        min: 0.25,
        max: 20,
      },
    ]

    if (!data) return curveData
    if (data?.autoCheck === true) {
      curveData.push({
        label: `Update Rate (mins)`,
        value: '',
        id: 6,
        type: 'number',
        tag: 'updateRate',
        editable: true,
        min: 0.5,
        max: 10,
      })
    }

    for (let i = 0; i < curveData.length; i++) {
      if (curveData[i].tag === '') continue
      if (!data.hasOwnProperty(curveData[i].tag)) continue
      curveData[i].value = data[curveData[i].tag]
      if (curveData[i].tag === 'backToPlanTransition') {
        curveData[i].dropDownValues = ['STEP', 'SMOOTH']
      }
    }

    return curveData.sort((a, b) => a.id - b.id)
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      if (handleUpdateConfig) handleUpdateConfig([event.data])
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
      className={getAgGridTheme()}>
      <AgGridReact
        rowData={getCurveData(config)}
        columnDefs={columnDefs}
        getRowId={getRowId}
        gridOptions={gridOptions}
        headerHeight={0}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDef={defaultColDef}
        alwaysShowVerticalScroll={true}
      />
    </div>
  )
}

export default SoloCloudSettingsGrid
