import { CatmullRomCurve3, DoubleSide } from 'three'
import { cloneDeep } from 'lodash'

const Wellbore = ({ wellData, segments = 16 }) => {
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (wellData.data.length < 2) return null

  let surveys = []
  let projections = []
  for (let i = 0; i < wellData.data.length; i++) {
    if (wellData.svyData[i].isProjection) {
      if (projections.length === 0 && i > 0) projections.push(cloneDeep(wellData.data[i - 1]))
      projections.push(cloneDeep(wellData.data[i]))
    }

    if (!wellData.svyData[i].isProjection) surveys.push(cloneDeep(wellData.data[i]))
  }

  const path = new CatmullRomCurve3(surveys)
  const pathProjections = new CatmullRomCurve3(projections)

  return (
    <>
      <mesh>
        <tubeGeometry args={[path, surveys.length > 64 ? surveys.length : 64, 0.5, segments, false]} />
        <meshStandardMaterial color={wellData.color} side={DoubleSide} />
      </mesh>
      {projections.length > 1 ? (
        <mesh>
          <tubeGeometry
            args={[pathProjections, projections.length > 64 ? projections.length : 64, 0.5, segments, false]}
          />
          <meshStandardMaterial color={0x00ff00} side={DoubleSide} />
        </mesh>
      ) : null}
    </>
  )
}

export default Wellbore
