import React, { useRef, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ProductFeaturesModal = ({ allFeatures, selectedFeatures, onCancel, onApply }) => {
  const gridApi = useRef(null)
  const [columnDefs, setColumnDefs] = useState([])
  const { getAgGridTheme } = useInnovaTheme()

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const leftAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
    }),
    [],
  )

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) {
      return
    }

    gridApi.current?.autoSizeAllColumns()
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      autoHeight: true,
      editable: true,
      headerClass: 'header-no-padding',
    }
  }, [])

  const getProductDescrFromKey = (featureId) => {
    const lastDashIndex = featureId.lastIndexOf('-')
    if (lastDashIndex === -1) return ''
    let productKey = featureId.substring(0, lastDashIndex)
    let product = allFeatures.find((p) => p.productKey === productKey)
    return product ? product.header : ''
  }

  const getFeatureDescFromKey = (featureId) => {
    const lastDashIndex = featureId.lastIndexOf('-')
    if (lastDashIndex === -1) return ''
    let productKey = featureId.substring(0, lastDashIndex)

    let product = allFeatures.find((p) => p.productKey === productKey)
    if (!product) return ''

    let feature = product.data?.find((f) => f.id === featureId)
    return feature ? feature.desc : ''
  }

  const doesFeatureExist = (productDesc, featureDesc) => {
    let product = allFeatures.find((p) => p.header === productDesc)
    if (!product) return false

    if (productDesc === 'Well Seeker' && featureDesc !== 'Base') return false

    let feature = product.data?.find((f) => f.desc === featureDesc)
    return feature ? true : false
  }

  const onGridReady = (params) => {
    gridApi.current = params.api

    if (!Array.isArray(allFeatures)) return

    let columnDefsFeats = [
      {
        field: 'feature',
        colId: 'feature',
        cellStyle: leftAlignCell,
      },
    ]

    allFeatures.forEach((product) => {
      columnDefsFeats.push({
        editable: (params) => {
          return doesFeatureExist(params.column.colId, params.data?.feature)
        },
        field: product.header,
        colId: product.header,
        headerName: product.header,
        minWidth: 50,
        cellStyle: centerAlignCell,
        cellRenderer: 'agCheckboxCellRenderer',
        cellEditor: 'agCheckboxCellEditor',
      })
    })

    setColumnDefs(columnDefsFeats)

    if (!Array.isArray(selectedFeatures)) return

    let featuresSet = new Set()

    for (let i = 0; i < allFeatures.length; i++) {
      if (!Array.isArray(allFeatures[i].data)) continue
      for (let j = 0; j < allFeatures[i].data.length; j++) {
        featuresSet.add(allFeatures[i].data[j].desc)
      }
    }

    let features = [...featuresSet]
    features.sort((a, b) => (a === 'Base' ? -1 : b === 'Base' ? 1 : a.localeCompare(b)))

    let rowData = []

    for (let i = 0; i < features.length; i++) {
      let row = { feature: features[i] }

      allFeatures.forEach((product) => {
        row[product.header] = selectedFeatures.find(
          (featureId) =>
            getProductDescrFromKey(featureId) === product.header && getFeatureDescFromKey(featureId) === features[i],
        )
          ? true
          : false
      })

      rowData.push(row)
    }

    gridApi.current.setGridOption('rowData', rowData)
  }

  const getSelectedFeatures = () => {
    let selectedFeatures = []

    gridApi.current.forEachNode((node) => {
      if (!node.data) return

      for (let i = 0; i < allFeatures.length; i++) {
        if (node.data[allFeatures[i].header]) {
          let featureId = allFeatures[i].data.find((f) => f.desc === node.data.feature).id
          selectedFeatures.push(featureId)
        }
      }
    })

    return selectedFeatures
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Select All',
        disabled: false,
        action: () => {
          gridApi.current.forEachNode((node) => {
            if (params.column.colId === 'Well Seeker') {
              node.setDataValue(params.column.colId, node.data.feature === 'Base')
            } else {
              node.setDataValue(params.column.colId, true)
            }
          })
        },
        icon: '<span class="iconify" data-icon="ep:select" data-width="20" style="color:#00FF00"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Unselect All',
        disabled: false,
        action: () => {
          gridApi.current.forEachNode((node) => {
            node.setDataValue(params.column.colId, false)
          })
        },
        icon: '<span class="iconify" data-icon="maki:cross" data-width="20" style="color:#F00F00"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        PaperProps={{
          sx: {
            height: '80vh',
            width: '80vw',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => onCancel()}>
        <DialogTitle>Products Features</DialogTitle>
        <DialogContent>
          <div className={getAgGridTheme()} style={{ height: '100%', width: '100%' }}>
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              headerHeight={30}
              onFirstDataRendered={onFirstDataRendered}
              onGridReady={onGridReady}
              getContextMenuItems={getContextMenuItems}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => onCancel()} color='secondary'>
            Close
          </Button>
          <Button variant='outlined' onClick={() => onApply(getSelectedFeatures())} color='primary'>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default ProductFeaturesModal
