import parse from 'html-react-parser'
import React, { useEffect, useRef } from 'react'
import ReactToPrint from 'react-to-print'

const HtmlStringPdfDocument = React.forwardRef(({ htmlString }, ref) => {
  if (!htmlString || typeof htmlString !== 'string' || htmlString === '') return null
  return (
    <div ref={ref}>
      <style type='text/css' media='print'>
        {`\
          @page { size: portrait; }\
        `}
      </style>
      <div style={{ backgroundColor: 'white', minHeight:'1200px' }}>{parse(htmlString)}</div>
    </div>
  )
})

const HtmlPdf = ({ htmlString, fileName = null, cleanUpfunction = null, forcePrint = false }) => {
  const componentRef = useRef()

  useEffect(() => {
    if (fileName !== null) document.title = fileName
    document.getElementById('print-button').click()
  }, [htmlString, forcePrint]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <ReactToPrint
        trigger={() => <button id='print-button'></button>}
        content={() => componentRef.current}
        onAfterPrint={() => {
          document.title = 'Innova Portal'
          if (cleanUpfunction !== null) cleanUpfunction()
        }}
      />
      <HtmlStringPdfDocument htmlString={htmlString} ref={componentRef} />
    </div>
  )
}

export default HtmlPdf
