import React from 'react'
import { InsertDriveFile } from '@mui/icons-material'
import { Icon as Iconify } from '@iconify/react'

const FileTypeIconSelector = (props) => {
  let { file, style } = props
  if (typeof props.file !== 'string') return <InsertDriveFile />
  var fileExt = file.split('.').pop()
  const getFileTypeIcon = () => {
    switch (fileExt) {
      case 'pdf':
        return 'vscode-icons:file-type-pdf2'
      case 'folder':
        return 'material-symbols:folder'
      case 'xls':
        return 'vscode-icons:file-type-excel2'
      case 'xlsx':
        return 'vscode-icons:file-type-excel2'
      case 'bmp':
        return 'ic:baseline-image'
      case 'jpg':
        return 'ic:baseline-image'
      case 'jpeg':
        return 'ic:baseline-image'
      case 'png':
        return 'ic:baseline-image'
      case 'zip':
        return 'material-symbols:folder-zip'
      default:
        return 'ic:round-insert-drive-file'
    }
  }

  return <Iconify icon={getFileTypeIcon()} style={style} />
}

export default FileTypeIconSelector
