import rigActiveIcon from '../../assets/RigIcons/rigIconActive.png'
import rigTdIcon from '../../assets/RigIcons/rigIconTd.png'
import rigUpcomingIcon from '../../assets/RigIcons/rigIconUpcoming.png'
import rigStandbyIcon from '../../assets/RigIcons/rigIconStandby.png'
import wellCompleteIcon from '../../assets/WellIcons/wellIconComplete.png'
import wellUnknownIcon from '../../assets/WellIcons/wellIconUnknown.png'
import rigIcon from '../../assets/rigIcon.png'
import upcomingIconpng from '../../assets/upcomingIconpng.png'
import wellIcon from '../../assets/wellIcon.png'

export function GetActivityIcon(activity) {
  if (
    activity === 'N/A' ||
    activity === 'Complete' ||
    activity === 'Finished' ||
    activity === 'EOW Ready' ||
    activity === 'EOW Sent' ||
    activity === 'Transferred' ||
    activity === 'Verified' ||
    activity === 'Total' ||
    activity === 'Other' ||
    activity === ''
  )
    return false

  return true
}

export function getWellstatusList() {
  return [
    'Active',
    'Complete',
    'Finished',
    'Standby',
    'Batch',
    'Upcoming',
    'TD',
    'Finished',
    'EOW Ready',
    'EOW Sent',
    'Verified',
    'Transferred',
  ]
}

export function getMapIcon(activity) {
  if (activity === 'Active') return rigActiveIcon
  if (activity === 'TD') return rigTdIcon
  if (activity === 'Upcoming') return rigUpcomingIcon
  if (activity === 'Standby' || activity === 'Batch') return rigStandbyIcon
  if (
    activity === 'Total' ||
    activity === 'Verified' ||
    activity === 'Transferred' ||
    activity === 'Finished' ||
    activity === 'Complete' ||
    activity === 'EOW Ready' ||
    activity === 'EOW Sent' ||
    activity === 'Finished'
  )
    return wellCompleteIcon
  return wellUnknownIcon
}

//Update getMapIcon function to grab correct Avatar for WellList on AnalyticsCard.js
export function getStatusAvatar(activity) {
  if (activity === 'Upcoming') return upcomingIconpng
  if (activity === 'Active' || activity === 'TD' || activity === 'Standby' || activity === 'Batch') return rigIcon
  if (
    activity === 'Total' ||
    activity === 'Verified' ||
    activity === 'Transferred' ||
    activity === 'Finished' ||
    activity === 'Complete' ||
    activity === 'EOW Ready' ||
    activity === 'EOWR Ready' ||
    activity === 'EOW Sent' ||
    activity === 'EOWR Sent' ||
    activity === 'Finished'
  )
    return wellIcon
  return rigIcon
}

export function ShowDataIcons(activity) {
  if (activity === 'Active' || activity === 'Standby' || activity === 'Batch' || activity === 'TD') return true

  return false
}

export function GetRigStateColor(rigState) {
  let col = '#00000000'
  if (rigState === 'UNKNOWN') col = '#00000000'
  if (rigState === 'ROTATING') col = '#59FF00'
  if (rigState === 'SLIDING') col = '#F59B00'
  if (rigState === 'CIRCULATING' || rigState === 'CIRCULATING_ROTATING') col = '#FFEF00'
  if (rigState === 'CONNECTION') col = '#0782f5'
  if (rigState === 'TRIP_OUT') col = '#47ff63'
  if (rigState === 'TRIP_IN') col = '#000080'
  if (rigState === 'PUMP_OUT') col = '#f03e8c'
  if (rigState === 'PUMP_IN') col = '#296aff'
  if (rigState === 'DRY_REAM_OUT') col = '#f1777d'
  if (rigState === 'DRY_REAM_IN') col = '#f1e477'
  if (rigState === 'REAM_OUT') col = '#fcfec0'
  if (rigState === 'REAM_IN') col = '#c0fefc'
  if (col !== '#00000000') {
    col += '9A'
  }

  return col
}

export function GetRigStateText(rigState) {
  if (rigState === 'UNKNOWN') return 'Unknown'
  if (rigState === 'ROTATING') return 'Rotating'
  if (rigState === 'SLIDING') return 'Sliding'
  if (rigState === 'CIRCULATING' || rigState === 'CIRCULATING_ROTATING') return 'Circulating'
  if (rigState === 'CONNECTION') return 'Connection'
  if (rigState === 'TRIP_OUT') return 'Trip Out'
  if (rigState === 'TRIP_IN') return 'Trip In'
  if (rigState === 'PUMP_OUT') return 'Pump Out'
  if (rigState === 'PUMP_IN') return 'Pump In'
  if (rigState === 'DRY_REAM_OUT') return 'Dry Ream Out'
  if (rigState === 'DRY_REAM_IN') return 'Dry Ream In'
  if (rigState === 'REAM_OUT') return 'Ream Out'
  if (rigState === 'REAM_IN') return 'Ream In'
  return 'Unknown'
}

export function GetActivityColor(activity) {
  if (activity === 'Active') return '#8bc34a'
  if (activity === 'Complete') return '#03a9f4'
  if (activity === 'Finished') return '#03a9f4'
  if (activity === 'Upcoming') return '#87CEEB'
  if (activity === 'Standby') return '#ff6347'
  if (activity === 'TD') return '#FFD700'
  if (activity === 'Batch') return '#3edbf0'
  if (activity === 'Finished') return '#ffcc29'
  if (activity === 'EOW Ready') return '#77acf1'
  if (activity === 'EOWR Ready') return '#77acf1'
  if (activity === 'EOW Sent') return '#c0fefc'
  if (activity === 'EOWR Sent') return '#c0fefc'
  if (activity === 'Transferred') return '#9f5f80'
  if (activity === 'Verified') return '#81b214'
  if (activity === 'N/A') return '#b00020'

  return '#b00020'
}

export function GetActivityText(activity) {
  if (activity === 'Active') return 'A'
  if (activity === 'Complete') return 'CP'
  if (activity === 'Finished') return 'FN'
  if (activity === 'Upcoming') return 'UP'
  if (activity === 'Standby') return 'SB'
  if (activity === 'TD') return 'TD'
  if (activity === 'Batch') return 'BT'
  if (activity === 'Finished') return 'FN'
  if (activity === 'EOWR Ready') return 'ER'
  if (activity === 'EOW Ready') return 'ER'
  if (activity === 'EOWR Sent') return 'ES'
  if (activity === 'EOW Sent') return 'ES'
  if (activity === 'Transferred') return 'TR'
  if (activity === 'Verified') return 'VR'
  if (activity === 'N/A') return 'NA'
  if (activity === 'Total') return 'T'

  return 'UN'
}

export function GetChartOrientation(orient) {
  if (orient === 'top') return 'top'
  if (orient === 'bottom') return 'bottom'
  if (orient === 'left') return 'left'
  if (orient === 'right') return 'right'

  return 'top'
}
