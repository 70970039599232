import React, { useState } from 'react'
import useWellData from 'components/common/hooks/useWellData'
import ConnectionAnalysisChart from 'components/WellPages/ConnectionAnalysis/ConnectionAnalysisChart'
import { Box } from '@mui/material'
import ConnectionSummaryGrid from './ConnectionSummaryGrid'
import ConnectionStatusGrid from './ConnectionStatusGrid'

const ConnectionAnalysisCard = () => {
  const [numConnections, setNumConnections] = useState(10)
  const { getConnectionData, getAllConnectionData } = useWellData()

  const calcAvgValues = (data) => {
    let avgValues = { wt2wt: 0, slp2slp: 0, connTime: 0, surveyTime: 0 }
    let divisor = { wt2wt: 0, slp2slp: 0, connTime: 0, surveyTime: 0 }

    if (!Array.isArray(data) || data.length === 0) return avgValues
    for (let i = 0; i < data.length; i++) {
      if (data[i].wt2WtTime > 0) {
        avgValues.wt2wt += Number(data[i].wt2WtTime) / 60
        divisor.wt2wt++
      }

      if (data[i].slp2SlpTime > 0) {
        avgValues.slp2slp += Number(data[i].slp2SlpTime) / 60
        divisor.slp2slp++
      }

      if (data[i].connectionTime > 0) {
        avgValues.connTime += Number(data[i].connectionTime) / 60
        divisor.connTime++
      }

      if (data[i].surveyTime > 0) {
        avgValues.surveyTime += Number(data[i].surveyTime) / 60
        divisor.surveyTime++
      }
    }

    avgValues.wt2wt = divisor.wt2wt > 0 ? avgValues.wt2wt / divisor.wt2wt : 0
    avgValues.slp2slp = divisor.slp2slp > 0 ? avgValues.slp2slp / divisor.slp2slp : 0
    avgValues.connTime = divisor.connTime > 0 ? avgValues.connTime / divisor.connTime : 0
    avgValues.surveyTime = divisor.surveyTime > 0 ? avgValues.surveyTime / divisor.surveyTime : 0

    return avgValues
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <ConnectionAnalysisChart
          connectionData={getConnectionData(numConnections)}
          avgValues={calcAvgValues(getConnectionData(numConnections))}
          showCompleteLabel={false}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <ConnectionSummaryGrid
          connectionData={getAllConnectionData()}
          avgValues={{ wt2wt: 0, slp2slp: 0, connTime: 0, surveyTime: 0 }}
        />
        <Box sx={{ width: '100%', height: '50px' }}>
          <ConnectionStatusGrid
            connectionData={getAllConnectionData()}
            numConnections={numConnections}
            setNumConnections={setNumConnections}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ConnectionAnalysisCard
