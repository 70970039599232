import React, { useMemo, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Tooltip, Snackbar, Alert } from '@mui/material'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { saveItemToLS } from 'utils/localStorage'
import cloneDeep from 'lodash/cloneDeep'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import DailyCostDetailsGrid from './DailyCostDetailsGrid'
import { getRandomColor } from 'utils/colorFunctions'
import { Icon as Iconify } from '@iconify/react'
import {
  sortColDefs,
  htmlSymbolHandling,
  showHideDetailGrid,
  CustomLoadingOverlay,
  getStringId,
} from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DailyCostGrid = ({
  setShowChart,
  showChart,
  gridData,
  setFilteredData,
  isLoading,
  getDropDowns,
  getSerialNumbers,
  getXlsData,
  setDateModalVisible,
  setSubmitXlsDateRange,
}) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const containerRef = useRef(null)
  const { getAgGridTheme, getTextColor } = useInnovaTheme()

  const addCostData = useInnovaAxios({
    url: '/well/wellCost/addCost',
  })

  const updateCostData = useInnovaAxios({
    url: '/well/wellCost/updateMultiCost',
  })

  useEffect(() => {
    if (_isMounted.current && gridApi.current && gridData?.length > 0) {
      gridApi.current.onFilterChanged()
    }
  }, [gridData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()
    return time1 < time2
  }

  const isValidDate = (value) => {
    return value instanceof Date || !isNaN(Date.parse(value))
  }

  const dateSortComparator = (date1, date2) => {
    if (!isValidDate(date1) || !isValidDate(date2)) return 0
    const parsedDate1 = new Date(Date.parse(date1))
    const parsedDate2 = new Date(Date.parse(date2))
    return parsedDate1.getTime() - parsedDate2.getTime()
  }

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const expandIconRenderer = (params) => {
    return (
      <Box
        onClick={() => showHideDetailGrid(params, 'dailyReportId', false, setShowChart, showChart?.current)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Iconify
          icon={!params?.node?.expanded ? 'material-symbols:chevron-right-rounded' : 'mdi:chevron-down'}
          style={{ color: getTextColor(), height: '30px', width: '30px' }}
        />
      </Box>
    )
  }

  const columnDefs = [
    {
      field: 'costDetails',
      colId: 'costDetails',
      headerName: '',
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      sortable: false,
      resizable: false,
      width: 30,
      pinned: 'left',
      lockPosition: 'left',
      cellRenderer: expandIconRenderer,
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'Report #',
      colId: 'rptNo',
      field: 'dailyReportNumber',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'Date',
      colId: 'date',
      field: 'date',
      filter: 'agSetColumnFilter',
      comparator: dateSortComparator,
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        if (!params.data?.date) return ''
        if (typeof params.data?.date !== 'string') return ''
        if (params.data?.date === '') return ''
        if (isDateLessThan(params.data?.date, '1990-01-01')) return ''
        return new Date(Date.parse(params.data?.date + 'T00:00:01')).toLocaleDateString('default', {
          day: 'numeric',
          year: 'numeric',
          month: 'short',
        })
      },
    },
    {
      headerName: 'Daily Cost',
      colId: 'totalCost',
      field: 'totalCost',
      cellStyle: centerAlignCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) =>
        `${getUnitsText(UNITS_FOR.Cost)}${numberWithCommasDecimals(params.data?.totalCost, 2)}`,
    },
    {
      field: 'Cost Summary',
      colId: 'costCodeSummary',
      width: 250,
      sortable: false,
      cellStyle: centerAlignCell,
      cellRenderer: (params) => {
        if (!Array.isArray(params.data?.costs)) return null

        let groupedCosts = []
        for (let i = 0; i < params.data?.costs.length; i++) {
          let index = groupedCosts.findIndex((cost) => cost.costCode === params.data?.costs[i].costCode)
          if (index < 0) {
            groupedCosts.push(cloneDeep(params.data?.costs[i]))
            continue
          }

          groupedCosts[index].totalValue += params.data?.costs[i].totalValue
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '7px',
              width: params.column.actualWidth - 10,
              height: 25,
              borderTopLeftRadius: '3px',
              borderBottomLeftRadius: '3px',
              border: '1px solid rgba(255,255,255,0.2)',
            }}>
            {groupedCosts.map((val, idx) => {
              let widthPercentage = (val.totalValue / 24) * 100
              let widthPixelCount = (widthPercentage / 100) * params.column.actualWidth
              return (
                <Tooltip
                  key={`${idx}-${params.data?.dailyReportNumber}`}
                  title={`${val.costCode}: ${val.description} - ${getUnitsText(
                    UNITS_FOR.Cost,
                  )}${numberWithCommasDecimals(val.totalValue, 2)}`}
                  placement='bottom'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: 'rgb(19,62,96)',
                        fontSize: '12px',
                        fontFamily: 'Roboto',
                      },
                    },
                  }}>
                  <Box
                    sx={{
                      width: widthPixelCount,
                      height: '100%',
                      backgroundColor: `${val.color}`,
                      borderTopLeftRadius: idx === 0 ? '1px' : '0px',
                      borderBottomLeftRadius: idx === 0 ? '1px' : '0px',
                    }}></Box>
                </Tooltip>
              )
            })}
          </Box>
        )
      },
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: false,
      wrapText: false,
      resizable: true,
      headerClass: 'header-no-padding', //This style removes the 15px horizontal padding ag grid provides
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('dailyCostGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    detailRowHeight: 200,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getAllDates = () => {
    if (!gridData) return
    if (!Array.isArray(gridData) || !gridData?.length > 0) return
    let currentDate = new Date(Date.now()).toISOString()
    let startDate = currentDate
    let endDate = currentDate

    endDate = isValidDate(gridData[0]?.date) ? `${gridData[0]?.date}` : currentDate
    startDate = isValidDate(gridData[gridData.length - 1].date) ? `${gridData[gridData.length - 1].date}` : currentDate

    return { startDate: startDate, endDate: endDate }
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('dailyCostGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export: Daily Cost',
        action: () => {
          getXlsData('dailyCost', params.node.data?.date)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export: All Costs',
        action: () => {
          getXlsData()
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export: Grouped Costs',
        action: () => {
          const { startDate, endDate } = getAllDates()
          getXlsData('grouped', startDate, endDate)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export: Costs by Date Range',
        action: () => {
          setDateModalVisible(true)
          setSubmitXlsDateRange(true)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Export: Cost Tracking',
        action: () => {
          const { startDate, endDate } = getAllDates()
          getXlsData('costTracking', startDate, endDate)
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const updateGridTotals = async (params) => {
    if (!params) return
    if (!params.hasOwnProperty('action')) return
    if (!Array.isArray(params.data)) return
    if (params.data?.length === 0) return
    if (!gridApi.current) return

    const currentRowData = gridApi.current.getRowNode(params.data[0].dailyReportId)
    if (!currentRowData) return
    let newRowData = cloneDeep(currentRowData.data)

    const { costCodes } = getDropDowns()

    for (let i = 0; i < params.data?.length; i++) {
      let costCode = costCodes.find((code) => code.value === params.data[i].costCode)
      params.data[i].color = costCode ? costCode.color : getRandomColor()
    }

    let returnData = null
    if (params.action === 'delete') {
      let index = newRowData.costs.findIndex((cost) => cost.uid === params.data[0].uid)
      if (index < 0) return
      newRowData.costs.splice(index, 1)
    }

    if (params.action === 'add') {
      let escapedData = htmlSymbolHandling(params.data[0])
      escapedData.wellName = params.data[0].actualwell

      let response = await addCostData(escapedData)
      if (response?.error) {
        setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
        return
      }

      params.data[0].uid = parseInt(response.data?.uid)
      params.data[0].sequenceNo = Array.isArray(newRowData.costs) ? newRowData.costs.length + 1 : 99999
      newRowData.costs.push(params.data[0])
      returnData = params.data[0]
    }

    if (params.action === 'update') {
      for (let i = 0; i < params.data?.length; i++) {
        let index = newRowData.costs.findIndex((cost) => cost.uid === params.data[i].uid)
        if (index < 0) return
        newRowData.costs[index] = { ...params.data[i] }

        params.data[i].quantity = parseFloat(params.data[i].quantity)
        params.data[i].value = parseFloat(params.data[i].value)
        params.data[i].totalValue = parseFloat(params.data[i].totalValue)
        params.data[i].sequenceNo = parseInt(params.data[i].sequenceNo)
        params.data[i].bhaNum = parseInt(params.data[i].bhaNum)
        params.data[i] = htmlSymbolHandling(params.data[i])
      }

      let response = await updateCostData({
        costData: JSON.stringify(params.data),
        wellName: params.data[0].actualwell,
      })

      if (response?.error) {
        setStatus({ show: true, severity: 'error', message: response?.error?.response?.data?.error })
        return
      }
    }

    newRowData.totalCost = 0
    for (let i = 0; i < newRowData.costs.length; i++) {
      newRowData.totalCost += newRowData.costs[i].totalValue
    }

    gridApi.current.applyTransaction({
      update: [cloneDeep(newRowData)],
    })

    return returnData
  }

  const DetailCellRenderer = forwardRef((params, ref) => {
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          return true
        },
      }
    })

    let height = 200
    if (containerRef.current) {
      height = containerRef.current.clientHeight * 0.5
    }

    return (
      <Box sx={{ height: height }}>
        <DailyCostDetailsGrid
          data={params.data}
          refreshCallback={updateGridTotals}
          getDropDowns={getDropDowns}
          getSerialNumbers={getSerialNumbers}
        />
      </Box>
    )
  })

  const icons = useMemo(() => {
    return {}
  }, [])

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.dailyReportId)
    }
  }, [])

  const onFilterChanged = (params) => {
    if (!gridApi.current) return
    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (_isMounted.current) setFilteredData(filteredNodes)
  }

  return (
    <React.Fragment>
      <div ref={containerRef} style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
        <AgGridReact
          rowData={gridData}
          loading={isLoading}
          columnDefs={sortColDefs(columnDefs, 'dailyCostGrid')}
          headerHeight={30}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          masterDetail={true}
          detailCellRenderer={DetailCellRenderer}
          onGridReady={onGridReady}
          getContextMenuItems={getContextMenuItems}
          animateRows={true}
          icons={icons}
          detailRowAutoHeight={true}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          getRowId={getRowId}
        />
      </div>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DailyCostGrid
