import React, { useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box } from '@mui/material'
import useUpdateWellInfo from 'components/common/hooks/useUpdateWellInfo'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const verticalTextStyle = {
  writingMode: 'vertical-rl',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#222628',
    fontWeight: 'bold',
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  paddingTop: '5px',
  paddingBottom: '5px',
  borderTop: '1px solid gray',
  borderBottom: '1px solid gray',
  fontWeight: '400',
  width: '25px',
  color: '#f0f0f0',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const ActualWellGrid = ({ actualWellData, handleUpdate }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const containerRef = useRef(null)
  const { getDropDownList } = useUpdateWellInfo()
  const { getAgGridTheme, getChartBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (gridApi.current) autoSizeColumns()
  }, [actualWellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const columnDefs = useMemo(
    () => [
      {
        field: 'label',
        colId: 'label',
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        },
      },
      {
        field: 'value',
        colId: 'value',
        flex: 1,
        minWidth: 200,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        },
        editable: true,
        cellEditorSelector: (params) => {
          if (params.data?.type === 'dropDown') {
            return {
              component: 'agSelectCellEditor',
              params: {
                values: params.data?.dropDownValues,
              },
            }
          }

          return {
            component: 'agTextCellEditor',
          }
        },
      },
    ],
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (event) => {
      handleUpdate(event.data)
    },
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const getActualWellData = (data) => {
    let actualWell = [
      { label: `Actual Well Name`, value: '', id: -1, type: 'text', tag: `actualWellName` },
      { label: `Description`, value: '', id: -1, type: 'text', tag: `description` },
      {
        label: `Well Status`,
        value: '',
        id: -1,
        type: 'dropDown',
        tag: `wellStatus`,
        dropDownValues: getDropDownList('wellStatus').sort(),
      },
    ]

    for (let i = 0; i < actualWell.length; i++) {
      actualWell[i].id = i
      if (actualWell[i].tag === '') continue

      if (!data) continue
      if (!data.hasOwnProperty(actualWell[i].tag)) continue
      if (!data[actualWell[i].tag]) continue
      actualWell[i].value = data[actualWell[i].tag]
    }

    return actualWell
  }

  return (
    <Box
      sx={{ width: '100%', height: '100%', backgroundColor: getChartBackColor(), display: 'flex', flexDirection: 'row' }}>
      <Box sx={verticalTextStyle}>Properties</Box>
      <div ref={containerRef} style={{ height: '100%', width: 'calc(100% - 25px)' }} className={getAgGridTheme()}>
        <AgGridReact
          className='ag-grid-theme-dark'
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          rowData={getActualWellData(actualWellData)}
          headerHeight={0}
          onFirstDataRendered={onFirstDataRendered}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default ActualWellGrid
