import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { cloneDeep } from 'lodash'
import PdfDocument from 'components/common/PDFGen/PdfDocument'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { userUserRoleAtom } from 'atoms'
import { useRecoilValue } from 'recoil'

function useBids() {
    const _isMounted = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const isDeleting = useRef(false)
    const isUpdating = useRef(false)
    const isAdding = useRef(false)
    const bidData = useRef(null)
    const [bids, setBids] = useState(null)
    const dropDowns = useRef({ addresses: [], operators: [], costSchedules: [] })
    const userRole = useRecoilValue(userUserRoleAtom)

    const getBids = useInnovaAxios({
        url: '/bids/getBids',
    })

    const getDropDowns = useInnovaAxios({
        url: '/bids/getDropDowns',
    })

    const addBidSheet = useInnovaAxios({
        url: '/bids/createBid',
    })

    const updateBidSheet = useInnovaAxios({
        url: '/bids/updateBid',
    })

    const deleteBidSheet = useInnovaAxios({
        url: '/bids/deleteBid',
    })

    const copyBidSheet = useInnovaAxios({
        url: '/bids/copyBid',
    })

    const transferBid = useInnovaAxios({
        url: '/bids/linkBidToWell',
    })

    useEffect(() => {
        _isMounted.current = true
        fetchBids()
        fetchDropDowns()
        return () => {
            _isMounted.current = false
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const transferBidToWell = async (data, wellName) => {
        if (!data) return
        if (!data.hasOwnProperty('operator')) return { error: true, message: 'no operator' }
        if (!data.hasOwnProperty('recordId')) return { error: true, message: 'no recordId' }
        if (!wellName) return { error: true, message: 'no wellName' }
        if (typeof wellName !== 'string') return { error: true, message: 'wellName not string' }
        if (wellName === '') return { error: true, message: 'wellName empty' }

        if (isUpdating.current) return
        if (isLoading) return
        if (!_isMounted.current) return
        setIsLoading(true)
        isUpdating.current = true
        let res = await transferBid({
            bidSheet: JSON.stringify(data),
            actualWell: wellName
        })
        isUpdating.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        return { error: false, data: `bid transferred to ${wellName}` }
    }

    const deleteBid = async (data) => {
        if (!data) return
        if (!data.hasOwnProperty('operator')) return { error: true, message: 'no operator' }
        if (!data.hasOwnProperty('recordId')) return { error: true, message: 'no recordId' }
        if (isDeleting.current) return
        if (isLoading) return
        if (!_isMounted.current) return
        setIsLoading(true)
        isDeleting.current = true
        let res = await deleteBidSheet({
            bidSheet: JSON.stringify(data),
        })
        isDeleting.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!Array.isArray(bidData.current)) return { error: true, message: `bidData not array` }
        let index = bidData.current.findIndex((bid) => bid.uid === data.uid)
        if (index < 0) return { error: true, message: `index < 0` }
        bidData.current.splice(index, 1)
        return { error: false, data: data }
    }

    const updateBid = async (data) => {
        if (!data) return
        if (!data.hasOwnProperty('recordId')) return { error: true, message: 'no recordId' }
        if (!data.hasOwnProperty('operator')) return { error: true, message: 'no operator' }
        if (isLoading) return
        if (isUpdating.current) return
        if (!_isMounted.current) return

        if (!Array.isArray(bidData.current)) return { error: true, message: `bidData not array` }
        let index = bidData.current.findIndex((bid) => bid.uid === data.uid)
        if (index < 0) return { error: true, message: `index < 0` }

        let newOperatorName = ''
        if (bidData.current[index].operator !== data.operator) {
            newOperatorName = data.operator
            data.operator = bidData.current[index].operator
        }

        setIsLoading(true)
        isUpdating.current = true
        let res = await updateBidSheet({
            bidSheet: JSON.stringify(data),
            newOperatorName: newOperatorName
        })
        isUpdating.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        bidData.current[index] = cloneDeep(res.data)
        return { error: false, data: cloneDeep(res.data) }
    }

    const addNewBid = async (data) => {
        if (!data.hasOwnProperty('operator')) return { error: true, message: 'no operator' }
        if (isAdding.current) return
        if (isLoading) return
        if (!_isMounted.current) return

        setIsLoading(true)
        isAdding.current = true
        let res = await addBidSheet({
            bidSheet: JSON.stringify(data),
        })
        isAdding.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!res.data) return { error: true, message: `res.data not valid` }
        res.data.dateCreated = new Date(Date.now()).toISOString().substring(0, 10)

        bidData.current.push(res.data)
        return { error: false, data: res.data }
    }

    const copyBid = async (data) => {
        if (!data.hasOwnProperty('operator')) return { error: true, message: 'no operator' }
        if (isAdding.current) return
        if (isLoading) return
        if (!_isMounted.current) return

        setIsLoading(true)
        isAdding.current = true
        let res = await copyBidSheet({
            bidSheet: JSON.stringify(data),
        })
        isAdding.current = false

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return { error: true, message: `${res?.error?.response?.data?.error}` }
        }

        if (!res.data) return { error: true, message: `res.data not valid` }
        res.data.dateCreated = new Date(Date.now()).toISOString().substring(0, 10)

        bidData.current.push(res.data)
        return { error: false, data: res.data }
    }

    const fetchBids = async () => {
        if (isLoading) return
        if (!_isMounted.current) return

        setIsLoading(true)
        let res = await getBids()
        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return
        }

        bidData.current = Array.isArray(res.data) ? res.data : []
        setBids(Array.isArray(res.data) ? res.data : [])
    }

    const fetchDropDowns = async () => {
        if (!_isMounted.current) return

        let res = await getDropDowns()

        if (!_isMounted.current) return
        setIsLoading(false)

        if (res?.error) {
            return
        }

        if (!res.data.hasOwnProperty('addresses')) return
        dropDowns.current = res.data
    }

    const getBidData = () => {
        return Array.isArray(bidData.current) ? bidData.current : []
    }

    const generatePdfDocument = (uid) => {
        let index = bidData.current.findIndex((bid) => bid.uid === uid)
        if (index < 0) return null

        let selectedBid = bidData.current[index]

        let docData = [
            {
                tableType: 'header',
                showTitle: true,
                title: `${selectedBid.operator} - ${selectedBid.description}`,
                fontSize: 6,
                data: [
                    [{ text: 'Cover Letter', isHeader: true }],
                    [{ text: unescapeHtml(selectedBid.frontPage), textAlign: 'left' }],
                ],
            },
            {
                fontSize: 6,
                sectionAfter: 5,
                data: [
                    [{ text: 'Notes', isHeader: true }],
                    [{ text: unescapeHtml(selectedBid.notes), textAlign: 'left' }],
                ],
            },
            {
                fontSize: 6,
                fixedHeaders: 1,
                manualWidth: true,
                columnWidths: ['25%', '25%', '17%', '17%', '17%'],
                data: addBidDetails(selectedBid.bidDetails),
            },
        ]

        return docData
    }

    const addBidDetails = (bidDetails) => {
        if (!bidDetails) return []
        if (!Array.isArray(bidDetails)) return []

        let details = []
        details.push(
            [{ text: 'Bid Details', isHeader: true, columnSpan: 5 }],
            [
                { text: 'Description', isHeader: true },
                { text: 'Code', isHeader: true },
                { text: `Cost`, isHeader: true },
                { text: `Schedule`, isHeader: true },
                { text: ``, isHeader: true },
            ],
        )

        for (let i = 0; i < bidDetails.length; i++) {
            details.push([
                { text: unescapeHtml(bidDetails[i].description) },
                { text: bidDetails[i].costCode },
                { text: bidDetails[i].cost },
                { text: bidDetails[i].costSchedule },
                { text: '' },
            ])

            if (Array.isArray(bidDetails[i].details)) {
                details.push([
                    { text: ``, isHeader: true },
                    { text: 'Description', isHeader: true },
                    { text: 'Code', isHeader: true },
                    { text: `Cost`, isHeader: true },
                    { text: `Schedule`, isHeader: true },
                ])

                for (let j = 0; j < bidDetails[i].details.length; j++) {
                    details.push([
                        { text: '' },
                        { text: unescapeHtml(bidDetails[i].details[j].description) },
                        { text: bidDetails[i].details[j].costCode },
                        { text: bidDetails[i].details[j].cost },
                        { text: bidDetails[i].details[j].costSchedule },
                    ])
                }
            }
        }

        return details
    }

    const getBidPdf = (uid) => {
        if (!Array.isArray(bidData.current)) return null

        return ({
            fileName: `Bid`,
            data: <PdfDocument data={generatePdfDocument(uid)} multiDocument={false} pageOrientation={'portrait'} reportSettings={userRole?.userPrefs?.reportSettings} />
        })
    }

    const getOperators = () => {
        return Array.isArray(dropDowns.current.operators) ? dropDowns.current.operators.sort((firstEl, secondEl) => firstEl.localeCompare(secondEl)) : []
    }

    const getCostSchedules = () => {
        return Array.isArray(dropDowns.current.costSchedules) ? dropDowns.current.costSchedules : []
    }

    const getAddresses = () => {
        return Array.isArray(dropDowns.current.addresses) ? dropDowns.current.addresses : []
    }

    return {
        isLoading,
        isUpdating,
        isDeleting,
        getBidData,
        fetchBids,
        addNewBid,
        deleteBid,
        updateBid,
        getBidPdf,
        copyBid,
        getOperators,
        getCostSchedules,
        getAddresses,
        transferBidToWell,
        bids
    }
}

export default useBids