import React, { useEffect, useRef, useState, useMemo } from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Tooltip from '@mui/material/Tooltip'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import { Icon as Iconify } from '@iconify/react'
import { Checkbox, CircularProgress, Backdrop } from '@mui/material'
import { appColors } from 'utils'
import { useRecoilValue } from 'recoil'
import { currentWellSelector } from 'atoms'
import { checkFeature } from 'components/userPermissions'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import useMotorReportImages from 'components/common/hooks/useMotorReportImages'
import { userUserRoleAtom } from 'atoms'
import { BitGradeGrid } from 'components/common/CellEditors/BitGradeEditor'
import { AgGridReact } from 'ag-grid-react'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const DysfunctionsGrid = ({ dysfunctions }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  let columnDefs = [
    {
      field: 'dysfunction',
      colId: 'dysfunction',
      headerName: 'Dysfunction',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) return
        if (typeof params.value !== 'string') return

        const words = params.value.split('_')
        if (words.length > 0) {
          words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)
        }

        const formattedString = words.join(' ')
        return formattedString
      },
    },
  ]

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={
          Array.isArray(dysfunctions)
            ? dysfunctions.map((item, index) => {
                return { uid: index, dysfunction: item }
              })
            : []
        }
        columnDefs={columnDefs}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

const RecommendationsGrid = ({ recommendations }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.uid)
    }
  }, [])

  let columnDefs = [
    {
      field: 'recommendation',
      colId: 'recommendation',
      headerName: 'Recommendations',
      minWidth: 150,
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (!params.value) return
        if (typeof params.value !== 'string') return

        const words = params.value.split('_')
        if (words.length > 0) {
          words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)
        }

        const formattedString = words.join(' ')
        return formattedString
      },
    },
  ]

  const onFirstDataRendered = (params) => {
    if (gridApi.current) {
      gridApi.current.onFilterChanged()
    }

    autoSizeColumns()
  }

  const gridOptions = {
    suppressRowClickSelection: true,
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const getRecommendations = () => {
    if (!recommendations) return []
    if (typeof recommendations !== 'string') return []
    const splitRecs = recommendations.split('\n')

    let gridData = []
    for (let i = 0; i < splitRecs.length; i++) {
      splitRecs[i] = splitRecs[i].trim()
      if (splitRecs[i] !== '') gridData.push({ uid: i, recommendation: splitRecs[i] })
    }

    return gridData
  }

  return (
    <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
      <AgGridReact
        rowData={getRecommendations()}
        columnDefs={columnDefs}
        animateRows={false}
        enableBrowserTooltips={true}
        gridOptions={gridOptions}
        headerHeight={30}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

const AcceptBitGradeDialog = ({ bitGrade, setOpen, setStatus, bhaNum, wellName, bitGradeUpdated }) => {
  const _isMounted = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const bitGradeGrid = useRef(null)

  const updateBitGrade = useInnovaAxios({
    url: '/well/drillString/updateBitGrade',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateBitGrade = async () => {
    if (!bitGradeGrid.current) return
    if (isLoading) return

    bitGradeUpdated.current = bitGradeGrid.current?.getBitGradeFromGrid()

    setIsLoading(true)
    const res = await updateBitGrade({
      wellName: wellName,
      bhaNum: bhaNum,
      bitGrade: bitGradeUpdated.current,
    })

    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      bitGradeUpdated.current = null
      return
    }

    setStatus({
      show: true,
      severity: 'success',
      message: `Bit grade updated`,
    })

    setOpen(false)
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '60vw',
          maxWidth: '60vw',
          height: '50vh',
          backgroundColor: 'itemBackground',
        },
      }}
      scroll='paper'
      open={true}
      onClose={() => setOpen(false)}>
      <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <DialogTitle>Bit Grade</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', height: '30%' }}>
          <BitGradeGrid ref={bitGradeGrid} bitGrade={bitGrade?.grade} isAutoGrade={true} />
        </Box>
        <Box sx={{ width: '100%', height: '35%' }}>
          <DysfunctionsGrid dysfunctions={bitGrade?.dysfunction} />
        </Box>
        <Box sx={{ width: '100%', height: '35%' }}>
          <RecommendationsGrid recommendations={bitGrade?.recommendations} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ right: '10px' }}
          color='primary'
          variant='contained'
          component='span'
          onClick={() => {
            handleUpdateBitGrade()
          }}>
          Accept
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
          }}
          color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const BhaImagesModal = ({ setOpen, bhaNum, bitGradeUpdated }) => {
  const _isMounted = useRef(false)
  const bitGradingResults = useRef(null)
  const [showAcceptBitGrading, setShowAcceptBitGrading] = useState(false)
  const selectMultipleRef = useRef(null)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const currentWell = useRecoilValue(currentWellSelector).wellName
  const [isLoading, setIsLoading] = useState(false)
  const { imageObjects, uploadImages, deleteImages, refreshImages, loading, setBitImage, bitImages } =
    useMotorReportImages(bhaNum, currentWell)
  const { getLinearGradient, getTextColor } = useInnovaTheme()

  const gradeBit = useInnovaAxios({
    url: '/well/drillString/gradeBit',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleGradeBit = async () => {
    if (isLoading) return
    setIsLoading(true)
    const res = await gradeBit({ wellName: currentWell, bhaNum: bhaNum })
    if (!_isMounted.current) return
    setIsLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!res.data) return

    bitGradingResults.current = res?.data
    setShowAcceptBitGrading(true)
  }

  const onSelectMultipleImages = async (event) => {
    let numExistingImages = 0
    if (!Array.isArray(imageObjects)) return
    for (let i = 0; i < imageObjects.length; i++) {
      if (!imageObjects[i].isBlank) numExistingImages++
    }

    let newImages = []
    for (let i = 0; i < event.target.files.length; i++) {
      numExistingImages++
      if (numExistingImages >= imageObjects.length) {
        break
      }

      newImages.push(event.target.files[i])
    }

    event.target.value = null

    if (newImages.length === 0) return

    const res = await uploadImages([], newImages)
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res ? 'success' : 'error',
      message: res ? 'Images uploaded' : 'Image upload failed',
    })

    await refreshImages()
  }

  const onSelectUploadFile = async (event, index) => {
    let replaceFileNames = []
    if (Array.isArray(imageObjects) && index >= 0 && index < imageObjects.length) {
      replaceFileNames.push(imageObjects[index].fileName)
    }

    const res = await uploadImages(replaceFileNames, [event.target.files[0]])
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res ? 'success' : 'error',
      message: res ? 'Images uploaded' : 'Image upload failed',
    })

    event.target.value = null
    await refreshImages()
  }

  const onDeleteImages = async (index) => {
    if (!Array.isArray(imageObjects)) return
    if (index >= imageObjects.length) return

    let imageNamesToDelete = []
    if (index < 0) {
      for (let i = 0; i < imageObjects.length; i++) {
        if (imageObjects[i].fileName !== '') {
          imageNamesToDelete.push(imageObjects[i].fileName)
        }
      }
    }

    if (index >= 0) {
      if (imageObjects[index].fileName !== '') {
        imageNamesToDelete.push(imageObjects[index].fileName)
      }
    }

    if (imageNamesToDelete.length === 0) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'No images to delete',
      })
      return
    }

    const res = await deleteImages(imageNamesToDelete)
    if (!_isMounted.current) return

    setStatus({
      show: true,
      severity: res ? 'success' : 'error',
      message: res ? 'Images delete' : 'Image delete failed',
    })
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleSelectBitImage = (fileName) => {
    if (typeof fileName !== 'string') return
    if (fileName === '') return
    if (!Array.isArray(bitImages)) return
    let index = bitImages.findIndex((img) => img === fileName)
    let setAsBitImage = index < 0 ? true : false

    setBitImage(bhaNum, currentWell, fileName, setAsBitImage)
  }

  const ImageCheckBox = ({ fileName }) => {
    let isChecked = false
    if (typeof fileName === 'string') {
      if (bitImages.findIndex((img) => img === fileName) >= 0) isChecked = true
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: 'auto',
          color: getTextColor(),
        }}>
        {fileName !== '' ? (
          <>
            Drill Bit
            <Checkbox
              sx={{ marginLeft: '5px', padding: 0 }}
              checked={isChecked}
              tabIndex={-1}
              disableRipple
              onClick={(event) => {
                handleSelectBitImage(fileName)
              }}
            />
          </>
        ) : null}
      </Box>
    )
  }

  const FileName = ({ fileName }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          justifyContent: 'center',
          alignItems: 'center',
          color: getTextColor(),
        }}>
        {fileName?.length > 30 ? fileName.substring(0, 30) + '...' : fileName}
      </Box>
    )
  }

  const SelectImageIcon = ({ index }) => {
    const inputRef = useRef(null)

    return (
      <Tooltip
        title='Select image'
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Box
          onClick={() => {
            if (!inputRef.current) return
            inputRef.current.click()
          }}
          sx={{
            width: '25px',
            display: 'flex',
            margin: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}>
          <input
            ref={inputRef}
            style={{ display: 'none' }}
            type='file'
            accept='image/png, image/jpeg, image/bmp'
            onChange={(event) => {
              onSelectUploadFile(event, index)
            }}
          />
          <Iconify icon='bi:image' style={{ color: appColors.accentColor, fontSize: '24px' }} />
        </Box>
      </Tooltip>
    )
  }

  const DeleteImageIcon = ({ index }) => {
    return (
      <Tooltip
        title='Delete image'
        placement='right'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <Box
          sx={{
            width: '25px',
            display: 'flex',
            margin: '5px',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={async () => {
            await onDeleteImages(index)
          }}>
          <Iconify icon='fa-regular:trash-alt' style={{ color: '#C00000', fontSize: '24px' }} />
        </Box>
      </Tooltip>
    )
  }

  const ClampedImage = ({ src, maxHeight }) => {
    const [dimensions, setDimensions] = useState({ width: 200, height: 200 })
    const imgRef = useRef(null)

    const calculateDimensions = () => {
      if (imgRef.current) {
        const img = imgRef.current
        const aspectRatio = img.naturalWidth / img.naturalHeight
        const clampedHeight = Math.min(maxHeight, img.naturalHeight)
        const clampedWidth = clampedHeight * aspectRatio
        setDimensions({ width: clampedWidth, height: clampedHeight })
      }
    }

    return (
      <Box
        component='img'
        ref={imgRef}
        src={src}
        alt=''
        onLoad={calculateDimensions}
        sx={{
          maxWidth: 'calc(100% - 20px)',
          width: dimensions.width,
          height: dimensions.height,
          margin: '10px',
        }}
      />
    )
  }

  const RenderImageListItem = ({ obj: imgObj, idx: index }) => {
    let imageName = imgObj && imgObj.file && imgObj.file.name !== 'empty' ? imgObj.file.name : ''

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '8px',
          border: '1px solid #000',
          background: getLinearGradient(),
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: 'calc(100% - 20px)',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '10px',
            marginRight: '10px',
          }}>
          <FileName fileName={imageName} />
          <ImageCheckBox fileName={imageName} />
          <DeleteImageIcon index={index} />
          <SelectImageIcon index={index} />
        </Box>
        <ClampedImage src={imgObj?.previewImage} maxHeight={500} />
      </Box>
    )
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          width: '60vw',
          maxWidth: '60vw',
          height: '80vh',
          backgroundColor: 'itemBackground',
        },
      }}
      scroll='paper'
      open={true}
      onClose={() => setOpen(false)}>
      <Backdrop style={{ color: '#fff', zIndex: 99999 }} open={loading || isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <DialogTitle>BHA Images</DialogTitle>
      <DialogContent>
        {imageObjects?.map((imgObj, i) => {
          return <RenderImageListItem key={i} obj={imgObj} idx={i} />
        })}
      </DialogContent>
      <DialogActions>
        {checkFeature(11, userRole?.roleAttributes?.featureId) && bitImages?.length > 3 ? (
          <Button sx={{ right: '10px' }} color='primary' variant='contained' component='span' onClick={handleGradeBit}>
            Grade Bit
          </Button>
        ) : null}
        <Button
          sx={{ right: '10px' }}
          color='secondary'
          variant='contained'
          component='span'
          onClick={async () => {
            await onDeleteImages('-1')
          }}>
          Delete All
        </Button>
        <input
          style={{ display: 'none' }}
          ref={selectMultipleRef}
          type='file'
          multiple={true}
          accept='image/png, image/jpeg, image/bmp'
          onChange={onSelectMultipleImages}
        />
        <Button
          sx={{ right: '10px' }}
          color='primary'
          variant='contained'
          component='span'
          onClick={() => {
            selectMultipleRef.current.click()
          }}>
          Select Multiple
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
          }}
          color='primary'>
          Close
        </Button>
      </DialogActions>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
      {showAcceptBitGrading ? (
        <AcceptBitGradeDialog
          setOpen={setShowAcceptBitGrading}
          setStatus={setStatus}
          bitGrade={bitGradingResults.current}
          bhaNum={bhaNum}
          wellName={currentWell}
          bitGradeUpdated={bitGradeUpdated}
        />
      ) : null}
    </Dialog>
  )
}

export default BhaImagesModal
