import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { CustomLoadingOverlay ,getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MotorReportModalGrid = ({ gridData, isLoading, handleUpdate }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme } = useInnovaTheme()
  const gridApi = useRef(null)
  
  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleUpdateFunc = useCallback(
    async (data) => {
      let gridData = [data]
      gridApi.current.forEachNode((node) => {
        if (node.data && node.data.payloadTag !== data.payloadTag) {
          gridData.push(node.data)
        }
      })

      let res = await handleUpdate(gridData)
      if (!res) return

      gridApi.current.applyTransaction({
        update: [data],
      })

      setTimeout(() => {
        autoSizeColumns()
      }, 100)
    },
    [handleUpdate],
  )

  const columnDefs = [
    {
      headerName: '',
      field: 'label',
      colId: 'label',
      maxWidth: 200,
    },
    {
      headerName: '',
      field: 'value',
      colId: 'value',
      maxWidth: 800,
      cellStyle: { textAlign: 'right' },
      editable: (params) => {
        if (!params?.data?.hasOwnProperty('editable')) return true
        if (!params.data?.editable) return false
        return true
      },
      cellRendererSelector: (params) => {
        if (params?.data?.type === 'boolean') {
          return { component: 'agCheckboxCellRenderer' }
        }
        return null
      },
      cellEditorSelector: (params) => {
        if (params.data?.type === 'string') {
          return {
            component: 'agTextCellEditor',
          }
        }

        if (params.data?.type === 'boolean') {
          return {
            component: 'agCheckboxCellEditor',
          }
        }

        if (params.data?.type === 'longstring') {
          return {
            component: 'agLargeTextCellEditor',
            params: {
              maxLength: 1000,
              rows: 10,
              cols: 50,
            },
            popup: true,
          }
        }

        if (params.data?.type === 'dropDown') {
          return {
            component: 'agSelectCellEditor',
            params: {
              values: params.data?.dropDownValues,
            },
            popup: false,
          }
        }

        if (params.data?.type === 'float') {
          return {
            component: 'agNumberCellEditor',
            params: {
              min: params?.data?.min ? params?.data?.min : -1000000,
              max: params?.data?.max ? params?.data?.max : 1000000,
              precision: params?.data?.precision ? params?.data?.precision : 2,
            },
          }
        }

        return undefined
      },
      valueGetter: (params) => {
        if (params?.data?.type === 'string' || params?.data?.type === 'longstring') {
          return unescapeHtml(params?.data?.value)
        }

        if (params?.data?.type === 'float') {
          if (isNaN(parseFloat(params?.data?.value))) return 0.0
          return parseFloat(params?.data?.value)
        }

        return params?.data?.value
      },
      valueFormatter: (params) => {
        if (params?.data?.type === 'float') {
          if (isNaN(parseFloat(params?.data?.value))) return 0.0
          return `${numberWithCommasDecimals(params?.data?.value, 2)}`
        }

        if (params?.data?.type === 'date') {
          return dateTimeFormatter(params?.data?.value)
        }
      },
    },
  ]

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      autoHeight: true,
      editable: false,
      suppressHeaderMenuButton: false,
      headerClass: 'header-no-padding',
      suppressKeyboardEvent: (params) => {
        if (!params.editing) {
          let isBackspaceKey = params.event.keyCode === 8
          let isDeleteKey = params.event.keyCode === 46

          if (isBackspaceKey || isDeleteKey) {
            const focusedCell = params.api.getFocusedCell()
            if (!focusedCell?.column?.colDef.editable) return false
            let data = params.data
            data[focusedCell?.column?.colDef?.field] = ''
            handleUpdateFunc(data)
          }
        }
        return false
      },
    }
  }, [handleUpdateFunc])

  function isDateLessThan(date1, date2) {
    const time1 = new Date(date1).getTime()
    const time2 = new Date(date2).getTime()

    return time1 < time2
  }

  const dateTimeFormatter = (value) => {
    if (!value) return ''
    if (typeof value !== 'string') return ''
    if (value === '') return ''
    if (isDateLessThan(value, '1990-01-01')) return ''
    if (value.length < 10) value += 'T00:00:01'
    value = value.replace(/Z/g, '')

    return new Date(Date.parse(value)).toLocaleDateString('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
  }

  const gridOptions = {
    suppressRowClickSelection: true,
    onCellEditingStopped: (params) => {
      handleUpdateFunc(params.data)
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        
        loading={isLoading}
        className='ag-grid-theme-dark'
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        rowData={gridData}
        headerHeight={0}
        onFirstDataRendered={onFirstDataRendered}
        getRowId={getRowId}
      />
    </div>
  )
}

export default MotorReportModalGrid
