import React, { useEffect, useRef, useState } from 'react'
import { currentPageAtom, actionBarWidthAtom, engineeringDashLayoutAtom } from '../../../atoms'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SplitPane from 'components/common/SplitPane'
import EngineeringParametersGrid from './EngineeringParametersGrid'
import EngineeringDashboardEditorPane from './Editors/EngineeringDashboardEditorPane'
import EngineeringDashboardChartsPane from './Charts/EngineeringDashboardChartsPane'
import 'components/common/SplitPane/splitPaneResizer.css'
import cloneDeep from 'lodash/cloneDeep'

const EngineeringDashboardPage = () => {
  const _isMounted = useRef(false)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const [pageLayout, setPageLayout] = useRecoilState(engineeringDashLayoutAtom)

  const getPaneVisible = (index) => {
    if (!Array.isArray(pageLayout)) return true
    if (index >= pageLayout.length) return true
    return pageLayout[index].visible
  }

  const [showControls, setShowControls] = useState(getPaneVisible(0))
  const [showEditor, setShowEditor] = useState(getPaneVisible(1))

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setActivePage(PAGE_KEYS.engineeringDashboardKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) return index === 0 ? '20%' : '70%'
    if (index >= pageLayout.length) return index === 0 ? '20%' : '70%'
    return pageLayout[index].size
  }

  const onSetPaneVisible = (index, value) => {
    if (Array.isArray(pageLayout)) return
    if (index >= pageLayout.length) return
    let newPanes = cloneDeep(pageLayout)
    newPanes[index].visible = value
    setPageLayout(newPanes)
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  const showHidePane = () => {
    onSetPaneVisible(0, !showControls)
    setShowControls(!showControls)
  }

  const showHideEditor = () => {
    onSetPaneVisible(1, !showEditor)
    setShowEditor(!showEditor)
  }

  return (
    <SplitPane
      split='vertical'
      onDragFinished={(params) => onDragFinished(params, 0)}
      defaultSize={getInitialPaneSize(0)}
      size={showControls ? getInitialPaneSize(0) : '25px'}
      allowResize={showControls}
      style={{
        height: 'calc(100vh - 64px)',
        marginLeft: leftPos,
        width: `calc(100% - ${leftPos}px)`,
        maxWidth: `calc(100% - ${leftPos}px)`,
      }}>
      <EngineeringParametersGrid showHidePane={showHidePane} showControls={showControls} />
      <SplitPane
        split='horizontal'
        size={showEditor ? getInitialPaneSize(1) : 'calc(100% - 25px)'}
        allowResize={showEditor}
        defaultSize={getInitialPaneSize(1)}
        onDragFinished={(params) => onDragFinished(params, 1)}>
        <EngineeringDashboardChartsPane />
        <EngineeringDashboardEditorPane showHideEditor={showHideEditor} showEditor={showEditor} />
      </SplitPane>
    </SplitPane>
  )
}

export default EngineeringDashboardPage
