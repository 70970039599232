import React, { useRef, useState, useMemo, useEffect } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { cloneDeep } from 'lodash'
import { appColors } from 'utils'
import { AgGridReact } from 'ag-grid-react'
import { saveItemToLS } from 'utils/localStorage'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import { sortColDefs, CustomLoadingOverlay, getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const checkbox = (params) => {
  return params.node.group === true
}

const AntiCollisionGrid = ({ isLoading, acData, setFilteredData }) => {
  const _isMounted = useRef(false)
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    onFilterChanged()
  }, [acData]) // eslint-disable-line react-hooks/exhaustive-deps

  const isDataNull = (params) => {
    let val = params.value
    if (typeof val === 'string') val = parseFloat(val)
    if (Math.abs(val - -999.25) < 0.01) return ''
    return val
  }

  const getSFcolor = (params) => {
    if (!params.value) return appColors.itemTextColor
    if (typeof params.value !== 'number') return appColors.itemTextColor
    if (params.value <= 1) return 'red'
    if (params.value <= 3) return 'orange'
    return 'green'
  }

  const getC2Ccolor = (params) => {
    if (!params.value) return appColors.itemTextColor
    if (typeof params.value !== 'number') return appColors.itemTextColor
    if (params.value <= 50) return 'red'
    if (params.value <= 100) return 'orange'
    return 'green'
  }

  const onFirstDataRendered = (params) => {
    if (gridApi.current) gridApi.current.onFilterChanged()
    setTimeout(() => {
      autoSizeColumns()
    }, 100)
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const handleFilterModel = (filterModel) => {
    if (!filterModel) return false
    if (
      filterModel.hasOwnProperty('C2C') ||
      filterModel.hasOwnProperty('SF') ||
      filterModel.hasOwnProperty('warning') ||
      filterModel.hasOwnProperty('ES')
    )
      return true

    return false
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return
    if (!Array.isArray(acData?.acResults)) return
    if (!Array.isArray(acData?.wells)) return

    let selectedNodes = gridApi.current.getSelectedNodes()
    let selectedRowData = []
    if (selectedNodes.length > 0)
      selectedNodes.forEach((rowNode) => {
        selectedRowData.push(rowNode.data)
      })

    let filteredNodes = []
    gridApi.current.forEachNodeAfterFilter((node) => {
      if (node?.data) filteredNodes.push(node.data)
    })

    //rebuild acData object
    const uniqueOffsets = new Set()

    let rowData = selectedRowData?.length > 0 ? selectedRowData : filteredNodes
    rowData.forEach((station) => {
      uniqueOffsets.add(station.offsetName)
    })

    let useFilter = handleFilterModel(gridApi.current.getFilterModel())

    let filteredData = cloneDeep(acData)
    filteredData.acResults = useFilter
      ? filteredNodes.filter((well) => Array.from(uniqueOffsets).includes(well.offsetName))
      : selectedRowData.length > 0
      ? selectedRowData
      : filteredNodes

    filteredData.wells = acData.wells.filter((well) => Array.from(uniqueOffsets).includes(well.wellName))

    filteredData.wells.forEach((well) => {
      let acArray = filteredData.acResults.filter((station) => station.offsetName === well.wellName)
      if (acArray.length > 1) {
        well.acResults = acArray
      }
    })

    if (_isMounted.current) setFilteredData(filteredData)
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Expand All',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
          autoSizeColumns()
        },
        icon: '<span class="iconify" data-icon="ri:expand-up-down-fill" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse All',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="ion:chevron-collapse-sharp" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('antiCollisionGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset filters',
        disabled: false,
        action: () => {
          gridApi.current.setFilterModel(null)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset selected',
        disabled: false,
        action: () => {
          gridApi.current.deselectAll()
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'AntiColData.xlsx',
            sheetName: 'AntiColData',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ]
  }

  const getRowStyle = (params) => {
    if (params?.data?.minC2c || params?.data?.minEs || params?.data?.minSf) {
      return { border: '3px solid red' }
    }
  }

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: false,
      editable: false,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
      headerClass: 'header-no-padding',
    }
  }, [])

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('antiCollisionGrid', 'colLayout', colLayout)
    }
  }

  const columnDefs = [
    {
      headerName: 'Well Name',
      field: 'offsetName',
      colId: 'offsetName',
      rowGroup: true,
      hide: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
    },
    {
      headerName: `MD (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'refMd',
      colId: 'refMd',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: 'Inc (°)',
      field: 'refInc',
      colId: 'refInc',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: 'Azi (°)',
      field: 'refAzi',
      colId: 'refAzi',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Off MD (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'offMd',
      colId: 'offMd',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: 'Off Inc (°)',
      field: 'offInc',
      colId: 'offInc',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: 'Off Azi (°)',
      field: 'offAzi',
      colId: 'offAzi',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Off TVD (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'offTVD',
      colId: 'offTVD',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Off NS (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'offNS',
      colId: 'offNS',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Off EW (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'offEW',
      colId: 'offEW',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Ref Casing (${getUnitsText(UNITS_FOR.Diameter)})`,
      field: 'refCasingOd',
      colId: 'refCasingOd',
      width: 110,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Off Casing (${getUnitsText(UNITS_FOR.Diameter)})`,
      field: 'offCasingOd',
      colId: 'offCasingOd',
      width: 110,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: 'TFO (°)',
      field: 'tfo',
      colId: 'tfo',
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Ref Ellipse (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'refEllip',
      colId: 'refEllip',
      width: 110,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Off Ellipse (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'offEllip',
      colId: 'offEllip',
      width: 110,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
    },
    {
      headerName: `Ellipse Sep (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'ES',
      colId: 'ES',
      suppressHeaderMenuButton: false,
      cellStyle: (params) => {
        return { textAlign: 'center', color: params?.data?.minEs ? 'red' : null }
      },
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
      filter: 'agNumberColumnFilter',
      width: 150,
    },
    {
      headerName: `C2C (${getUnitsText(UNITS_FOR.Depth)})`,
      field: 'C2C',
      colId: 'C2C',
      suppressHeaderMenuButton: false,
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
      cellStyle: (params) => {
        return { textAlign: 'center', color: getC2Ccolor(params) }
      },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'SF',
      field: 'SF',
      colId: 'SF',
      minWidth: 65,
      suppressHeaderMenuButton: false,
      cellStyle: (params) => {
        return { padding: '0px', textAlign: 'center', color: getSFcolor(params) }
      },
      valueFormatter: (params) => {
        return `${numberWithCommasDecimals(isDataNull(params), 2)}`
      },
      filter: 'agNumberColumnFilter',
    },
    {
      headerName: 'Warning',
      field: 'warning',
      colId: 'warning',
      suppressHeaderMenuButton: false,
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      valueFormatter: (params) => {
        let warnings = []
        if (!params) return ''
        if (!params.hasOwnProperty('data')) return ''
        if (!params.data) return ''
        if (!params.data?.hasOwnProperty('warning')) return ''

        if (params.data?.warning !== '') warnings.push(params.data?.warning)
        if (params.data?.minC2c) warnings.push('Min C2C')
        if (params.data?.minEs) warnings.push('Min ES')
        if (params.data?.minSf) warnings.push('Min SF')
        if (warnings.length === 0) return ''
        return warnings.join(',')
      },
    },
  ]

  const gridOptions = {
    suppressRowClickSelection: true,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: () => {
      saveColumnState()
    },
    onColumnVisible: () => {
      saveColumnState()
    },
    loadingOverlayComponent: CustomLoadingOverlay,
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.itemIndex)
    }
  }, [])

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Well Name',
      field: 'offsetName',
      cellRenderer: 'agGroupCellRenderer',
      cellStyle: { display: 'flex', alignItems: 'start', justifyContent: 'start' },
      cellRendererParams: {
        checkbox,
        suppressCount: true,
      },
    }
  }, [])

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  return (
    <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
      <AgGridReact
        rowData={Array.isArray(acData.acResults) ? acData.acResults : []}
        columnDefs={sortColDefs(columnDefs, 'antiCollisionGrid')}
        headerHeight={30}
        loading={isLoading}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        rowSelection={'multiple'}
        groupSelectsChildren={true}
        suppressRowClickSelection={true}
        suppressAggFuncInHeader={true}
        onGridReady={onGridReady}
        gridOptions={gridOptions}
        getRowId={getRowId}
        getContextMenuItems={getContextMenuItems}
        onFirstDataRendered={onFirstDataRendered}
        onFilterChanged={onFilterChanged}
        animateRows={true}
        groupRowsSticky={true}
        getRowStyle={getRowStyle}
        onSelectionChanged={onFilterChanged}
      />
    </div>
  )
}

export default AntiCollisionGrid
