import React from 'react'
import { Box } from '@mui/material'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const AnalyticsHorizontalBarChart = ({ data, title = '', inverseSort = false }) => {
  const { theme, getChartBackColor, getTextColor } = useInnovaTheme()

  const createChartData = () => {
    if (!data) return { labels: [], datasets: [] }
    if (!Array.isArray(data)) return { labels: [], datasets: [] }
    if (data.length === 0) return { labels: [], datasets: [] }

    let unsortedData = data.map((well) => {
      if (!Array.isArray(well.closestApproach) || well.closestApproach.length === 0) {
        return { wellName: well.wellName, c2c: 0, sf: 0, wellPlan: 0, leaseLine: 0 }
      }

      let c2c = well.closestApproach[0].closestOffset.c2c
      let sf = well.closestApproach[0].closestOffset.sf
      let wellPlan = well.closestApproach[0].wellPlan.c2c
      let leaseLine = well.closestApproach[0].closestLeaseLine.c2c

      if (c2c < 0) c2c = 0
      if (sf < 0) sf = 0
      if (wellPlan < 0) wellPlan = 0
      if (leaseLine < 0) leaseLine = 0

      return { wellName: well.wellName, c2c: c2c, sf: sf, leaseLine: leaseLine, wellPlan: wellPlan }
    })

    unsortedData.sort((a, b) => {
      if (a.c2c < b.c2c) return inverseSort ? 1 : -1
      if (a.c2c > b.c2c) return inverseSort ? -1 : 1
      return 0
    })

    const chartData = {
      labels: unsortedData.map((pt) => pt.wellName),
      datasets: [
        {
          data: unsortedData.map((pt) => pt.c2c),
          backgroundColor: '#db502a',
          label: 'Offset C2C',
          maxBarThickness: 24,
        },
        {
          data: unsortedData.map((pt) => pt.wellPlan),
          backgroundColor: '#2ab5db',
          label: 'Wellplan',
          maxBarThickness: 24,
        },
        {
          data: unsortedData.map((pt) => pt.leaseLine),
          backgroundColor: '#a82adb',
          label: 'Lease Line',
          maxBarThickness: 24,
        },
        {
          data: unsortedData.map((pt) => pt.sf),
          backgroundColor: '#5cdb2a',
          label: 'SF',
          yAxisID: 'y2',
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    plugins: {
      GradientPlugIn: {
        showGradient: true,
        theme: theme,
      },
      title: {
        display: title.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          color: getTextColor(),
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: 'rgb(100, 100, 100)',
        },
      },
      y: {
        position: 'left',
        title: {
          display: true,
          text: 'C2C',
          color: appColors.itemTextColor,
        },
        ticks: {
          color: getTextColor(),
        },
        grid: {
          color: 'rgb(100, 100, 100)',
        },
      },
      y2: {
        position: 'right',
        title: {
          display: true,
          text: 'SF',
          color: appColors.itemTextColor,
        },
        ticks: {
          color: appColors.headerTextColor,
        },
        beginAtZero: true,
        max: 10,
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 20,
        top: 20,
      },
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: getChartBackColor(),
      }}>
      <Bar type='bar' options={chartOptions} data={createChartData()} />
    </Box>
  )
}

export default AnalyticsHorizontalBarChart
