import { useRef, useEffect } from 'react'

const useCodes = (elementId) => {
  const codes = useRef(new Set())

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp' || e.code === 'Period' || e.code === 'Comma') {
        e.preventDefault()
      }
      codes.current.add(e.code)
    }

    const onKeyUp = (e) => {
      if (e.code === 'ArrowDown' || e.code === 'ArrowUp' || e.code === 'Period' || e.code === 'Comma') {
        e.preventDefault()
      }
      codes.current.delete(e.code)
    }

    const element = document.getElementById(elementId)

    if (!element) {
      console.warn(`Element with ID '${elementId}' not found.`)
      return
    }

    element.setAttribute('tabIndex', '0') // Make the element focusable

    const onFocus = () => {
      element.addEventListener('keydown', onKeyDown)
      element.addEventListener('keyup', onKeyUp)
    }

    const onBlur = () => {
      element.removeEventListener('keydown', onKeyDown)
      element.removeEventListener('keyup', onKeyUp)
      codes.current.clear()
    }

    element.addEventListener('focus', onFocus)
    element.addEventListener('blur', onBlur)

    return () => {
      element.removeEventListener('focus', onFocus)
      element.removeEventListener('blur', onBlur)
      element.removeEventListener('keydown', onKeyDown)
      element.removeEventListener('keyup', onKeyUp)
    }
  }, [elementId])

  return codes
}

export default useCodes
