import { useEffect, useState, useRef } from 'react'
import useInnovaAxios from './useInnovaAxios'

function useInnovaLicenseFeatures(refetchData) {
  const _isMounted = useRef(true)
  const [dropDowns, setDropDowns] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [rawData, setRawData] = useState()
  const defEngineeringFeatures = [1, 2, 3, 4, 5, 6, 7, 10]
  const defWellSeekerFeatures = [1, 3, 4, 6, 8]

  const getData = useInnovaAxios({
    url: '/admin/licenses/getLicenseFeatureOptions',
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    getDropDowns()
  }, [rawData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [refetchData]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = async () => {
    const response = await getData()
    if (_isMounted.current && response?.data) {
      setRawData(response.data)
    }
  }

  async function getDropDowns() {
    if (_isMounted.current === true) setIsLoading(true)
    if (!rawData) return

    let newDropDowns = []
    if (Array.isArray(rawData.wellSeeker)) {
      rawData.wellSeeker.forEach((val) => {
        let index = defWellSeekerFeatures.findIndex((element) => element === val.id)
        newDropDowns.push({
          label: val.description,
          value: val.description,
          product: 'Well Seeker',
          tag: `wellSeeker-${val.id}`,
          isDef: index >= 0 ? true : false,
          id: val.id,
        })
      })
    }

    if (Array.isArray(rawData.engineering)) {
      rawData.engineering.forEach((val) => {
        let index = defEngineeringFeatures.findIndex((element) => element === val.id)
        newDropDowns.push({
          label: val.description,
          value: val.description,
          product: 'Engineering',
          tag: `engineering-${val.id}`,
          isDef: index >= 0 ? true : false,
          id: val.id,
        })
      })
    }

    if (_isMounted.current === true) {
      setDropDowns(newDropDowns)
      setIsLoading(false)
    }
  }

  return [dropDowns, isLoading]
}

export default useInnovaLicenseFeatures
