import React, { useRef, useEffect, useMemo, useState } from 'react'

import Avatar from '@mui/material/Avatar'
import { Box } from '@mui/material'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import { Icon as Iconify } from '@iconify/react'
import { getComponentColor, getDrillStringIcon } from 'components/common/drillStringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { AgGridReact } from 'ag-grid-react'
import { appColors } from 'utils'
import { saveItemToLS } from 'utils/localStorage'
import { sortColDefs ,getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const LogisticsGrid = ({ invListData, searchText }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()
  const [resetCols, setResetCols] = useState(false)
  const { getUnitsText } = useUnits()
  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const defaultColDef = {
    resizable: true,
    sortable: true,
    autoHeight: true,
    editable: false,
  }

  useEffect(() => {
    if (searchText !== '' && invListData.length > 0) {
      expandAllNodes('expand')
    }
    autoSizeColumns()
  }, [invListData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getContextMenuItems = (params) => {
    return [
      {
        name: 'Expand All',
        disabled: false,
        action: () => {
          expandAllNodes('expand')
        },
        icon: '<span class="iconify" data-icon="ri:expand-up-down-fill" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Collapse All',
        disabled: false,
        action: () => {
          expandAllNodes('collapse')
        },
        icon: '<span class="iconify" data-icon="ion:chevron-collapse-sharp" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      {
        name: 'Reset columns',
        disabled: false,
        action: () => {
          gridApi.current.resetColumnState()
          saveItemToLS('logisticsGrid', 'colLayout', null)
          setResetCols(!resetCols)
        },
        icon: '<span class="iconify" data-icon="carbon:reset" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
      'copy',
      {
        name: 'Export',
        disabled: false,
        action: () => {
          gridApi.current.exportDataAsExcel({
            fileName: 'LogisticsData.xlsx',
            sheetName: 'Logistics',
          })
        },
        icon: '<span class="iconify" data-icon="icomoon-free:file-excel" data-width="20" style="color:#4BB2F9"></span>',
        cssClasses: ['leftAlign'],
      },
    ];
  }

  const columnDefs = useMemo(
    () => [
      { colId: 'opName', headerName: 'Operator', field: 'operator', rowGroup: true, hide: true },
      { colId: 'well', headerName: 'Well', field: 'actualWell', rowGroup: true, hide: true },
      {
        headerName: '',
        colId: 'icon',
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                margin: 'auto',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'lightgrey',
                borderRadius: '50%',
              }}>
              {params?.data.hasOwnProperty('toolType') ? (
                <Avatar
                  alt={params?.data?.compType}
                  src={getDrillStringIcon(params?.data?.toolType)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        colId: 'description',
        headerName: 'Description',
        field: 'description',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        valueFormatter: (params) => unescapeHtml(params.value),
      },
      {
        colId: 'toolType',
        headerName: 'Tool Type',
        field: 'toolType',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: (params) => {
          return (
            <span style={{ color: getComponentColor(params?.data?.toolType) }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params?.data?.toolType === 'Unknown' ? '' : params?.data?.toolType}
            </span>
          )
        },
      },
      {
        colId: 'make',
        headerName: 'Make',
        field: 'make',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      { colId: 'model', headerName: 'Model', field: 'model', cellStyle: centerAlignCell },
      {
        colId: 'serialNum',
        headerName: 'Serial #',
        field: 'serialNum',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellStyle: centerAlignCell,
      },
      {
        colId: 'status',
        headerName: 'Status',
        field: 'toolStatus',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: !params.data?.dirty ? '#66FF00' : '#ff0000',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.toolStatus}
            </span>
          )
        },
      },
      {
        colId: 'od',
        headerName: `OD (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'od',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.value > 0 ? params.value.toFixed(3) : ''
        },
      },
      {
        colId: 'id',
        headerName: `ID (${getUnitsText(UNITS_FOR.Diameter)})`,
        field: 'id',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.value > 0 ? params.value.toFixed(3) : ''
        },
      },
      {
        colId: 'length',
        headerName: `Length (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'length',
        cellStyle: centerAlignCell,
        valueFormatter: (params) => {
          return params.value > 0 ? params.value.toFixed(2) : ''
        },
      },
      {
        colId: 'cnxTop',
        headerName: 'CNX Top',
        field: 'cnxTop',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'cnxBottom',
        headerName: 'CNX Bottom',
        field: 'cnxBtm',
        cellStyle: centerAlignCell,
      },
      {
        colId: 'inHole',
        headerName: 'In Hole',
        field: 'inHole',
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: params.data?.inHole ? 'green' : 'red',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.inHole ? 'Yes' : ''}
            </span>
          )
        },
      },
      {
        colId: 'backLoad',
        headerName: 'Ready for Bkld',
        field: 'readyForBackload',
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: params.data?.readyForBackload ? 'green' : 'red',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.readyForBackload ? 'Yes' : ''}
            </span>
          )
        },
      },
      {
        colId: 'failed',
        headerName: 'Failed',
        field: 'failed',
        sortable: true,
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: params.data?.failed ? 'red' : 'green',
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.failed ? 'Yes' : ''}
            </span>
          )
        },
      },
    ],
    [getUnitsText],
  )

  const GroupRowInnerRenderer = (props) => {
    let rowData = null
    if (Array.isArray(invListData) && invListData.length > 0) {
      rowData = invListData.find((item) => item.actualWell === props.node.key)
    }

    let wellStatus = rowData ? rowData.wellStatus : 'Unknown'

    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
        <Box
          sx={{
            margin: 'auto',
            width: 35,
            height: 35,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
            borderRadius: '50%',
          }}>
          {props.node.field === 'actualWell' ? (
            <Avatar
              alt={props.node.field}
              src={getStatusAvatar(wellStatus)}
              style={{ width: 35, height: 35, backgroundColor: GetActivityColor(wellStatus) }}
            />
          ) : null}
          {props.node.field !== 'actualWell' ? (
            <Iconify icon='ic:baseline-oil-barrel' width='35' height='35' color='black' />
          ) : null}
        </Box>
        <Box
          sx={{
            marginLeft: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {props.node.key}
        </Box>
        {props.node.field === 'actualWell' && rowData ? (
          <>
            <Box
              sx={{ marginLeft: '10px', color: appColors.itemTextColor, fontWeight: 500 }}>{` / ${rowData.rig}`}</Box>
            <Box sx={{ marginLeft: '10px', color: '#FFFFFF', fontWeight: 500 }}>{` / ${rowData.state}`}</Box>
            <Box
              sx={{
                marginLeft: '10px',
                color: appColors.itemTextColor,
                fontWeight: 500,
              }}>{` / ${rowData.county}`}</Box>
            <Box sx={{ marginLeft: '10px', color: '#FFFFFF', fontWeight: 500 }}>{` / ${rowData.jobNum}`}</Box>
          </>
        ) : null}
      </Box>
    )
  }

  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    suppressCount: true,
    checkbox: false,
  }

  const expandAllNodes = (action) => {
    if (typeof action !== 'string') return
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    if (action === 'expand') gridApi.current.expandAll()
    if (action !== 'expand') gridApi.current.collapseAll()
  }

   const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    
    expandAllNodes('expand')
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const saveColumnState = () => {
    if (gridApi.current) {
      const colLayout = gridApi.current.getColumnState()
      if (colLayout) saveItemToLS('logisticsGrid', 'colLayout', colLayout)
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    onDragStopped: (event) => {
      saveColumnState()
    },
    onColumnVisible: (event) => {
      saveColumnState()
    },
  }



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId( params.data?.uid)
    }
  }, [])

  return (
    <Box sx={{ flex: '1 1 auto', display: 'flex', border: '1px solid #000' }}>
      <div className={getAgGridTheme()} style={{ width: '100%', height: '100%' }}>
        <AgGridReact
          
          rowData={invListData}
          columnDefs={sortColDefs(columnDefs, 'logisticsGrid')}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          groupRowRendererParams={groupRowRendererParams}
          groupDisplayType={'groupRows'}
          groupRowsSticky={true}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          gridOptions={gridOptions}
          getContextMenuItems={getContextMenuItems}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}

export default LogisticsGrid
