import { useEffect, useRef, useState } from 'react'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'
import { Buffer } from 'buffer'
import { multiParse } from 'utils'
import useInnovaAxios from '../useInnovaAxios'
import { cloneDeep } from 'lodash'

const uploadImageUrl = '/well/wallPlotComposer/uploadImages'

const useWallPlotComposerFiles = (well) => {
  const _isMounted = useRef(false)
  const wellNameRef = useRef(well)
  const [images, setImages] = useState([])
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()
  const isFetchingImages = useRef(false)
  const isUploadingImages = useRef(false)
  const isDeletingImages = useRef(false)
  const [loading, setLoading] = useState(false)
  const [imageList, setImageList] = useState([])

  const getImages = useInnovaAxios({
    url: '/well/wallPlotComposer/getImages',
  })

  const getImageList = useInnovaAxios({
    url: '/well/wallPlotComposer/getImageList',
  })

  const deleteWallPlotImages = useInnovaAxios({
    url: '/well/wallPlotComposer/deleteImages',
  })

  useEffect(() => {
    _isMounted.current = true
    // fetchImageList()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchImages = async (fileName) => {
    if (!wellNameRef.current) return
    if (typeof wellNameRef.current !== 'string') return
    if (wellNameRef.current === '') return
    if (isFetchingImages.current) return
    if (!fileName || fileName === '') return

    isFetchingImages.current = true
    setLoading(true)
    const res = await getImages({
      wellName: wellNameRef.current,
      filename: fileName,
    })

    isFetchingImages.current = false
    if (!_isMounted.current) return
    if (res?.error) {
      setLoading(false)
      return
    }
    if (!res?.data) {
      setLoading(false)
      return
    }
    setLoading(false)

    const mfpBoundary = res.data.substring(2, res.data.search('Content')).trim()
    let parts = multiParse(Buffer.from(res.data), mfpBoundary)
    setImages(
      parts.map((part) => {
        let imageObj = { ...part }
        imageObj.data = 'data:image/*;base64,' + part.data
        imageObj.filename = part.filename
        return imageObj
      }),
    )
  }

  const fetchImageList = async () => {
    if (!wellNameRef.current) return
    if (typeof wellNameRef.current !== 'string') return
    if (wellNameRef.current === '') return

    isFetchingImages.current = true

    const res = await getImageList({
      wellName: wellNameRef.current,
    })
    isFetchingImages.current = false
    if (res?.error) {
      setImageList([])
      return
    }

    if (!Array.isArray(res.data)) {
      setImageList([])
      return
    }

    setImageList(cloneDeep(res.data))
  }

  const uploadImages = async (fileNames, files) => {
    if (isUploadingImages.current) return false
    if (!files && !Array.isArray(files)) return false
    let formData = new FormData()

    isUploadingImages.current = true
    setLoading(true)
    const accessToken = await getAccessTokenSilently()

    let options = {
      url: uploadImageUrl,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    formData.append('wellName', wellNameRef.current)
    formData.append('fileNames', fileNames.join(','))

    files.forEach((file) => {
      formData.append('file', file)
    })

    const response = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    isUploadingImages.current = false

    if (response.error) {
      if (_isMounted.current) setLoading(false)
      return null
    }

    if (_isMounted.current) setLoading(false)
    return response.data
  }

  const deleteImages = async (deletedFileNames) => {
    if (isDeletingImages.current) return

    isDeletingImages.current = true

    const response = await deleteWallPlotImages({
      wellName: wellNameRef.current,
      fileNames: [deletedFileNames],
    })
    isDeletingImages.current = false

    if (response.error) {
      return false
    }

    await fetchImageList()
    if (_isMounted.current) return true
  }

  const setWpWellName = (wellName) => {
    wellNameRef.current = wellName
  }

  return { images, fetchImages, imageList, fetchImageList, uploadImages, deleteImages, loading, setWpWellName }
}

export default useWallPlotComposerFiles
