import React, { useEffect, useRef, useMemo, useCallback } from 'react'
import { Box } from '@mui/material'
import { AgGridReact } from 'ag-grid-react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { analyticsSelectedWells } from 'atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'
import { dateComparator, isDateLessThan ,getStringId } from 'components/common/AgGridUtils'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const defaultColDef = {
  resizable: true,
  sortable: true,
  autoHeight: true,
  editable: false,
}

const AnalyticsResultsGrid = ({ wellData, setSelectedWells, showHideGridPane, showGrid }) => {
  const _isMounted = useRef(false)
  const { getAgGridTheme, getWindowBarColor } = useInnovaTheme()
  const gridApi = useRef(null)
  const { getUnitsText } = useUnits()
  const setStoredSelectedWells = useSetRecoilState(analyticsSelectedWells)
  const initialSelectedRows = useRecoilValue(analyticsSelectedWells)

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    autoSizeColumns()
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const dateSortComparator = (date1, date2) => {
    const isValidDate = (value) => {
      return value instanceof Date || !isNaN(Date.parse(value))
    }

    if (!isValidDate(date1) || !isValidDate(date2)) {
      return 0
    }

    const parsedDate1 = new Date(Date.parse(date1))
    const parsedDate2 = new Date(Date.parse(date2))

    return parsedDate1.getTime() - parsedDate2.getTime()
  }

  const dateFilterComparator = useCallback(dateComparator, [])

  const dateTimeFormatter = useMemo(
    () => (value) => {
      if (!value) return ''
      if (typeof value !== 'string') return ''
      if (value === '') return ''
      if (isDateLessThan(value, '1990-01-01')) return ''
      value = value.replace(/Z/g, '')

      return new Date(Date.parse(value)).toLocaleDateString('default', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    [],
  )

  const columnDefs = useMemo(
    () => [
      {
        headerName: '',
        width: 100,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        cellRenderer: (params) => {
          return (
            <Box
              sx={{
                margin: 'auto',
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: GetActivityColor(params?.data?.wellStatus),
                borderRadius: '50%',
              }}>
              {params?.data.hasOwnProperty('wellStatus') ? (
                <Avatar
                  alt={params?.data?.wellStatus}
                  src={getStatusAvatar(params?.data?.wellStatus)}
                  style={{ width: 35, height: 35 }}
                />
              ) : null}
            </Box>
          )
        },
      },
      {
        headerName: 'Operator',
        field: 'operator',
        wrapText: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Organization',
        field: 'organization',
        wrapText: true,
        autoHeight: true,
        minWidth: 200,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Well',
        field: 'actualWell',
        wrapText: true,
        autoHeight: true,
        minWidth: 300,
        maxWidth: 400,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Job #',
        field: 'jobNum',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Rig',
        field: 'rig',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'State',
        field: 'state',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'County',
        field: 'county',
        cellStyle: centerAlignCell,
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
      },
      {
        headerName: 'Status',
        field: 'wellStatus',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellRenderer: (params) => {
          return (
            <span
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: GetActivityColor(params.data?.wellStatus),
              }}>
              <i className='fa fa-check' aria-hidden='true'></i>
              {params.data?.wellStatus}
            </span>
          )
        },
      },
      {
        headerName: 'Formation',
        field: 'formation',
        filter: 'agSetColumnFilter',
        filterParams: {
          excelMode: 'windows',
        },
        cellStyle: centerAlignCell,
      },
      {
        headerName: 'Start',
        field: 'startDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        comparator: dateSortComparator,
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.startDate)
        },
      },
      {
        headerName: 'End',
        field: 'endDate',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        comparator: dateSortComparator,
        minWidth: 120,
        valueFormatter: (params) => {
          return dateTimeFormatter(params.data?.endDate)
        },
      },
      {
        headerName: `Depth (${getUnitsText(UNITS_FOR.Depth)})`,
        field: 'currentDepth',
        cellStyle: centerAlignCell,
        valueGetter: (params) => {
          if (typeof params.data?.currentDepth !== 'string') return 0
          if (params.data?.currentDepth === '') return 0

          return parseFloat(params.data?.currentDepth)
        },
        valueFormatter: (params) => {
          return numberWithCommasDecimals(params.value, 2)
        },
        filter: 'agNumberColumnFilter',
      },
    ],
    [dateTimeFormatter, getUnitsText, dateFilterComparator],
  )



  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.id)
    }
  }, [])

  const onSelectionChanged = () => {
    if (!gridApi.current) return
    let selectedRows = gridApi.current.getSelectedNodes()

    if (!Array.isArray(selectedRows)) {
      if (_isMounted.current) {
        setSelectedWells([])
        setStoredSelectedWells([])
      }
    }

    let newSelection = []
    let newSelectionWellNames = []
    for (let i = 0; i < selectedRows.length; i++) {
      newSelection.push({ ...selectedRows[i].data })
      newSelectionWellNames.push(selectedRows[i].data.actualWell)
    }

    if (_isMounted.current) {
      setSelectedWells(newSelection)
      setStoredSelectedWells(newSelectionWellNames)
    }
  }

  const gridOptions = {
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
    rowMultiSelectWithClick: true,
    onSelectionChanged: onSelectionChanged,
  }

  const setInitialSelection = () => {
    if (!gridApi.current) return
    if (!Array.isArray(initialSelectedRows)) return
    if (initialSelectedRows.length === 0) return

    gridApi.current.forEachNode((node) => {
      let index = initialSelectedRows.findIndex((well) => node.data.actualWell === well)
      node.setSelected(index >= 0)
    })
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
    setInitialSelection()
  }

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onFilterChanged = (params) => {
    if (!gridApi.current) return
    let selectedRows = gridApi.current.getSelectedNodes()
    let filteredNodes = []
    params.api.forEachNodeAfterFilter((node) => {
      filteredNodes.push(node.data)
    })

    if (!Array.isArray(selectedRows)) {
      if (_isMounted.current) {
        setSelectedWells([])
        setStoredSelectedWells([])
      }
    }

    let newSelection = []
    let newSelectionWellNames = []
    for (let i = 0; i < selectedRows.length; i++) {
      let index = filteredNodes.findIndex((node) => node.id === selectedRows[i].data.id)
      if (index < 0) continue

      newSelection.push({ ...selectedRows[i].data })
      newSelectionWellNames.push(selectedRows[i].data.actualWell)
    }

    if (_isMounted.current) {
      setSelectedWells(newSelection)
      setStoredSelectedWells(newSelectionWellNames)
    }
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          height: '25px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: getWindowBarColor(),
        }}>
        {!showGrid ? (
          <Box
            onClick={showHideGridPane}
            sx={{
              marginLeft: '50%',
              color: '#f0f0f0',
              cursor: 'pointer',
              '&:hover': {
                color: '#429ceb',
              },
            }}>
            Search Grid
          </Box>
        ) : null}
        <Box
          onClick={showHideGridPane}
          sx={{
            marginLeft: 'auto',
            width: '25px',
            height: '25px',
            backgroundColor: '#222628',
            cursor: 'pointer',
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Iconify icon={'zondicons:view-hide'} width={'20px'} height={'20px'} color={'#808080'} />
        </Box>
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 25px)' }}>
        <AgGridReact
          rowData={wellData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          animateRows={true}
          rowSelection='multiple'
          enableRangeSelection='true'
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          onFilterChanged={onFilterChanged}
          onSelectionChanged={onFilterChanged}
          headerHeight={30}
        />
      </div>
    </Box>
  )
}

export default AnalyticsResultsGrid
