import React, { useEffect, useRef, useState } from 'react'
import { Snackbar } from '@mui/material'
import SurveyProgramGrid from './SurveyProgramGrid'
import Alert from '@mui/material/Alert'
import SplitPane from 'components/common/SplitPane'
import SideTrackGrid from './SideTrackGrid'

const SurveyProgramPage = ({ wellName, isPlan }) => {
  const _isMounted = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      <SplitPane
        split='horizontal'
        size={'20%'}
        allowResize={true}
        defaultSize={'20%'}
        minSize={'10%'}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SideTrackGrid wellName={wellName} isPlan={isPlan} setStatus={setStatus} />
        <SurveyProgramGrid wellName={wellName} isPlan={isPlan} />
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default SurveyProgramPage
