import { useEffect, useRef, useState } from 'react'
import useInnovaAxios from '../useInnovaAxios'
import { cloneDeep } from 'lodash'

const useWallPlotComposerTemplates = (wellName) => {
  const _isMounted = useRef(false)
  const wellNameRef = useRef(wellName)
  const isLoadingRef = useRef(false)
  const isSavingRef = useRef(false)
  const isDeletingRef = useRef(false)
  const [loading, setLoading] = useState(false)
  const [wallplotList, setWallplotList] = useState([])

  const getTemplates = useInnovaAxios({
    url: '/well/wallPlotComposer/getTemplates',
  })

  const deleteTemplates = useInnovaAxios({
    url: '/well/wallPlotComposer/deleteTemplates',
  })

  const addTemplates = useInnovaAxios({
    url: '/well/wallPlotComposer/addTemplates',
  })

  const updateTemplates = useInnovaAxios({
    url: '/well/wallPlotComposer/updateTemplates',
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const fetchWallPlots = async () => {
    if (!wellNameRef.current) return
    if (typeof wellNameRef.current !== 'string') return
    if (wellNameRef.current === '') return
    if (isLoadingRef.current) return
    if (!_isMounted.current) return

    isLoadingRef.current = true

    const res = await getTemplates({
      wellName: wellNameRef.current,
    })

    isLoadingRef.current = false

    if (!_isMounted.current) return
    if (res?.error || !res.data) {
      setLoading(false)
      return
    }

    setWallplotList(res?.data)
    setLoading(false)
  }

  const fetchAllWallPlots = async () => {
    if (isLoadingRef.current) return
    if (!_isMounted.current) return

    isLoadingRef.current = true

    const res = await getTemplates({})

    isLoadingRef.current = false

    if (!_isMounted.current) return
    if (res?.error || !res.data) {
      setLoading(false)
      return
    }

    setWallplotList(res?.data)
    setLoading(false)
  }

  const addWallPlot = async ({ uid, templateData }) => {
    if (!_isMounted.current) return
    if (isSavingRef.current) return
    if (!wellNameRef.current) return
    if (!templateData) return

    isSavingRef.current = true

    let localTemplateData = cloneDeep(templateData)
    localTemplateData.uid = uid
    localTemplateData.wellName = wellNameRef.current

    const templatePayload = [localTemplateData]
    const templateString = JSON.stringify(templatePayload)
    let res = null

    res = await addTemplates({
      wellName: wellNameRef.current,
      templateData: templateString,
    })
    isSavingRef.current = false

    return res.data // this is an array of templates
  }

  const updateWallPlot = async ({ uid, templateData }) => {
    if (!_isMounted.current) return
    if (isSavingRef.current) return
    if (!wellNameRef.current) return
    if (!templateData) return

    if (isSavingRef.current) return
    isSavingRef.current = true

    let localTemplateData = cloneDeep(templateData)
    localTemplateData.uid = uid
    localTemplateData.wellName = wellNameRef.current

    const templatePayload = [localTemplateData]
    const templateString = JSON.stringify(templatePayload)
    let res = null

    res = await updateTemplates({
      wellName: wellNameRef.current,
      templateData: templateString,
    })
    isSavingRef.current = false

    return res?.data // this is an array of templates
  }

  const deleteWallPlot = async ({ wellName, templateUid }) => {
    if (!_isMounted.current) return
    if (isDeletingRef.current) return
    if (!wellName || !templateUid) return

    const apiParams = {
      uid: templateUid,
      wellName: wellName,
    }
    isDeletingRef.current = true

    const templatePayload = [apiParams]
    const templateString = JSON.stringify(templatePayload)

    const res = await deleteTemplates({
      wellName: wellName,
      templateData: templateString,
    })

    if (!_isMounted.current) return

    isDeletingRef.current = false
    if (res?.error) {
      return
    }
    fetchWallPlots(wellName)
  }

  const setWpWellName = (wellName) => {
    wellNameRef.current = wellName
  }

  return {
    fetchWallPlots,
    wallplotList,
    addWallPlot,
    updateWallPlot,
    deleteWallPlot,
    loading,
    setWpWellName,
    fetchAllWallPlots,
  }
}

export default useWallPlotComposerTemplates
