import { PlanTieOnGrid } from './PlanTieOnGrid'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import { htmlSymbolHandling } from 'components/common/AgGridUtils'
import SplitPane from 'components/common/SplitPane'
import { Box, CircularProgress, Tooltip, Alert, Snackbar } from '@mui/material'
import { cloneDeep } from 'lodash'
import { Icon as Iconify } from '@iconify/react'
import { isEqual } from 'lodash'
import { useRecoilState } from 'recoil'
import { dateBaseTreeUpdateNameAtom, plannedWellPropertiesLayoutAtom } from 'atoms'
import PlannedWellGrid from './PlannedWellGrid'
import VerticalSectionGrid from './VerticalSectionGrid'
import DepthReferenceGrid from './DepthReferenceGrid'
import ConfirmDialog from 'components/common/ConfirmDialog'
import MagneticsGrid from './MagneticsGrid'

const PlannedWellPropertiesPage = ({ plannedWell, nodeId }) => {
  const _isMounted = useRef(false)
  const [isUpdating, setUpdating] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [plannedWellData, setPlannedWellData] = useState(null)
  const plannedWelldataRef = useRef(null)
  const [dataChanged, setDataChanged] = useState(false)
  const isUpdatingRef = useRef(false)
  const orgDataRef = useRef(plannedWellData)
  const tvdAdjustOptionsRef = useRef(null)
  const isInterpolating = useRef(false)
  const facilityWellOffsetRef = useRef({ localNorth: 0, localEast: 0, localCoordRef: 'WELL' })
  const [pageLayout, setPageLayout] = useRecoilState(plannedWellPropertiesLayoutAtom)
  const [databaseTreeUpdateName, setDataBaseTreeNameUpdate] = useRecoilState(dateBaseTreeUpdateNameAtom)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const localCoordOffset = useRef({ localNorth: 0, localEast: 0 })
  const tieOnSurveyHeadersRef = useRef([])
  const [tieOnSurveyHeaders, setTieOnSurveyHeaders] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })

  const getPlannedWell = useInnovaAxios({
    url: '/plannedWell/getPlannedWells',
  })

  const updatePlannedWell = useInnovaAxios({
    url: '/plannedWell/updatePlan',
  })

  const getPlanFacilityOffset = useInnovaAxios({
    url: '/coordinateConv/getWellFacilityOffset',
  })

  const adjustDatumElevation = useInnovaAxios({
    url: '/well/adjustDatumElevation',
  })

  const getLocalOffset = useInnovaAxios({
    url: '/coordinateConv/getLocalOffset',
  })

  const interpMd = useInnovaAxios({
    url: '/survey/interpMd',
  })

  const getAvailableSurveyHeaders = useInnovaAxios({
    url: '/plannedWell/getAvailableSurveyHeadersForPlan',
  })

  useEffect(() => {
    _isMounted.current = true
    fetchData()
    fetchWellFacilityOffset()
    fetchLocalOffset()
    fetchTieOnSurveyHeaders()
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchLocalOffset = async () => {
    if (!plannedWell) return
    if (typeof plannedWell !== 'string') return
    if (plannedWell === '') return

    const res = await getLocalOffset({ wellName: plannedWell, isPlan: true })

    if (!_isMounted.current) return
    if (res?.error) return
    if (!res.data) return
    localCoordOffset.current = cloneDeep(res.data)
  }

  const handleTvdChange = useCallback(async () => {
    if (isUpdatingRef.current) return
    if (!tvdAdjustOptionsRef.current) return
    setLoading(true)

    isUpdatingRef.current = true
    const res = await adjustDatumElevation(tvdAdjustOptionsRef.current)
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })
      return
    }
  }, [adjustDatumElevation, setStatus])

  const handleTreeNameUpdate = (data) => {
    if (!data) return
    if (!plannedWellData) return
    if (data.caller === 'UI') return
    if (data.nodeId !== nodeId) return

    let newData = cloneDeep(plannedWellData)
    newData.actualWellName = data.name
    orgDataRef.current.actualWellName = data.name
    orgDataRef.current.orgPlannedWellName = data.name
    plannedWelldataRef.current.actualWellName = data.name
    setPlannedWellData(newData)
    setDataBaseTreeNameUpdate(null)
  }

  const getInitialPaneSize = (index) => {
    if (!Array.isArray(pageLayout)) {
      if (index === 0) return '20%'
      if (index === 1) return '50%'

      return '35%'
    }

    if (index >= pageLayout.length) {
      if (index === 0) return '20%'
      if (index === 1) return '50%'

      return '35%'
    }
    return pageLayout[index].size
  }

  const onDragFinished = (newSize, index) => {
    let newPanes = cloneDeep(pageLayout)
    if (Array.isArray(pageLayout) && index < pageLayout.length) {
      newPanes[index].size = newSize
    }
    setPageLayout(newPanes)
  }

  useEffect(() => {
    handleTreeNameUpdate(databaseTreeUpdateName)
  }, [databaseTreeUpdateName]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchWellFacilityOffset = async () => {
    const res = await getPlanFacilityOffset({ wellName: plannedWell, isPlan: true })
    if (!_isMounted.current) return

    if (res?.error) return
    if (!res.data) return
    facilityWellOffsetRef.current = res.data
  }

  const fetchData = async () => {
    if (isLoading) return
    setLoading(true)
    const res = await getPlannedWell({ planName: plannedWell })

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (!Array.isArray(res.data)) return
    if (res.data.length === 0) return

    //Flatten Data
    let planData = {
      wellName: res.data[0].wellName,
      actualWellName: res.data[0].actualWellName,
      principal: res.data[0].principal,
      description: res.data[0].description,
      locked: res.data[0].locked,
      datumName: res.data[0].depthReference.Name,
      datumElevation: res.data[0].depthReference.Elevation,
      VsAzi: res.data[0].verticalSection.VsAzi,
      VsOrgNorth: res.data[0].verticalSection.VsOrgNorth,
      VsOrgEast: res.data[0].verticalSection.VsOrgEast,
      VsOrigin: res.data[0].verticalSection.VsOrigin,
      sideTrackParentWell: res.data[0].sidetrackData.parentWell,
      sideTrackDepth: res.data[0].sidetrackData.Depth,
      sideTrackErrorsStart: res.data[0].sidetrackData.sideTrackErrorsStart,
      isSideTrack: res.data[0].sidetrackData.isSidetrack,
      md: res.data[0].tieOnData.tieOn.md,
      inc: res.data[0].tieOnData.tieOn.inc,
      azi: res.data[0].tieOnData.tieOn.azi,
      tvd: res.data[0].tieOnData.tieOn.tvd,
      ns: res.data[0].tieOnData.tieOn.ns,
      ew: res.data[0].tieOnData.tieOn.ew,
      inputMeth: res.data[0].tieOnData.inputMeth,
      parentSurveyWell: res.data[0].tieOnData.parentSurveyWell,
      parentTieOn: res.data[0].tieOnData.parentTieOn,
      orgPlannedWellName: res.data[0].actualWellName,
      bhalNs: res.data[0].bottomHoleLocation?.ns,
      bhalEw: res.data[0].bottomHoleLocation?.ew,
      vsAziUseBhl: res.data[0].verticalSection.UseBhl,
    }

    plannedWelldataRef.current = cloneDeep(planData)
    orgDataRef.current = cloneDeep(planData)
    setPlannedWellData(planData)
  }

  const handleUpdate = async (data) => {
    if (!data) return
    if (isUpdatingRef.current) return
    setUpdating(true)

    isUpdatingRef.current = true
    let res = await updatePlannedWell(htmlSymbolHandling(data))
    isUpdatingRef.current = false

    if (!_isMounted.current) return
    setUpdating(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return
    }

    if (orgDataRef.current.actualWellName !== data.actualWellName || orgDataRef.current.principal !== data.principal) {
      setDataBaseTreeNameUpdate({
        name: data.actualWellName,
        nodeId: nodeId,
        caller: 'UI',
        isPrincipal: data.principal,
        level: data.principal ? 'PLANNEDWELL_PRINCIPAL' : 'PLANNEDWELL',
      })
    }

    if (data.datumElevation !== orgDataRef.current.datumElevation) {
      tvdAdjustOptionsRef.current = {
        actualWell: data.actualWellName,
        newElevation: data.datumElevation,
        deltaElevation: data.datumElevation - orgDataRef.current.datumElevation,
        well: data.wellName,
        preserveTvds: true,
        newDatumName: data.datumName,
      }

      setConfirm({
        show: true,
        title: 'TVD Adjustments',
        text: `Do you wish to preserve the TVD's for the wells using this datum?`,
      })
    }

    orgDataRef.current = cloneDeep(data)
    setDataChanged(false)
  }

  const updatePlannedWellDepthRef = (data) => {
    if (!data) return

    let newData = cloneDeep(plannedWelldataRef.current)
    newData.datumName = data.datumName
    newData.datumElevation = data.datumElevation

    setDataChanged(!isEqual(newData, orgDataRef.current))
    plannedWelldataRef.current = cloneDeep(newData)
    setPlannedWellData(newData)
  }

  const getTieOnSvy = () => {
    if (!plannedWelldataRef.current) return null
    if (plannedWelldataRef.current.inputMeth !== 'SURVEY') return null
    if (!Array.isArray(tieOnSurveyHeadersRef.current)) return null
    return tieOnSurveyHeadersRef.current.find(
      (svy) =>
        svy.survey === plannedWelldataRef.current.parentTieOn &&
        svy.wellName === plannedWelldataRef.current.parentSurveyWell,
    )
  }

  const getDeltaElevation = (tieOnWell, tieOnSurvey) => {
    if (
      !tieOnWell ||
      !tieOnSurvey ||
      !Array.isArray(tieOnSurveyHeadersRef.current) ||
      tieOnSurveyHeadersRef.current.length === 0
    )
      return 0
    if (typeof tieOnWell !== 'string') return 0
    if (typeof tieOnSurvey !== 'string') return 0
    if (tieOnWell === '') return 0
    if (tieOnSurvey === '') return 0

    let tieOnSvyHeader = tieOnSurveyHeadersRef.current.find(
      (svy) => svy.wellName === tieOnWell && svy.survey === tieOnSurvey,
    )
    if (!tieOnSvyHeader) return 0

    return tieOnSvyHeader.datumElevation - plannedWelldataRef.current.datumElevation
  }

  const interpSurvey = async (data) => {
    if (!data) return null
    if (!data.parentSurveyWell) return null
    if (!data.parentTieOn) return null
    if (data.parentSurveyWell === '') return null
    if (data.parentTieOn === '') return null
    if (isInterpolating.current) return null

    setLoading(true)
    isInterpolating.current = true
    const res = await interpMd({
      wellName: data.parentSurveyWell,
      surveyName: data.parentTieOn,
      md: data.md,
      isPlan: data.isPlan,
    })
    isInterpolating.current = false

    if (!_isMounted.current) return
    setLoading(false)

    if (res?.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: `${res?.error?.response?.data?.error}`,
      })

      return null
    }

    if (!res.data) return null
    return res.data
  }

  const updateTieOnData = async (data) => {
    let newData = cloneDeep(plannedWelldataRef.current)
    if (plannedWelldataRef.current.inputMeth === 'SURFACE') {
      newData.md = 0
      newData.inc = data.inc
      newData.azi = data.azi
      newData.tvd = 0
      newData.ns = 0
      newData.ew = 0
    }

    if (plannedWelldataRef.current.inputMeth === 'USER') {
      newData.md = data.md
      newData.inc = data.inc
      newData.azi = data.azi
      newData.tvd = data.tvd
      newData.ns = data.ns - localCoordOffset.current.localNorth
      newData.ew = data.ew - localCoordOffset.current.localEast
    }

    if (plannedWelldataRef.current.inputMeth === 'SURVEY') {
      let svyHeader = tieOnSurveyHeadersRef.current.find(
        (svy) =>
          svy.wellName === plannedWelldataRef.current.parentSurveyWell &&
          svy.survey === plannedWelldataRef.current.parentTieOn,
      )

      if (svyHeader) {
        let deltaElevation = getDeltaElevation(newData.parentSurveyWell, newData.parentTieOn)
        newData.md = data.md + deltaElevation
        let interpData = await interpSurvey(newData)

        if (interpData) {
          newData.md = roundToNearestWholeIfClose(interpData.md)
          newData.inc = interpData.inc
          newData.azi = interpData.azi
          newData.tvd = interpData.tvd
          newData.ns = interpData.ns
          newData.ew = interpData.ew
        }
      }
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    plannedWelldataRef.current = newData
    setPlannedWellData(newData)
  }

  function roundToNearestWholeIfClose(number) {
    if (Math.abs(number - Math.round(number)) < 0.01) {
      return Math.round(number)
    } else {
      return number
    }
  }

  const fetchTieOnSurveyHeaders = async () => {
    if (!plannedWell) return
    if (typeof plannedWell !== 'string') return
    if (plannedWell === '') return

    const res = await getAvailableSurveyHeaders({ wellName: plannedWell })

    if (!_isMounted.current) return
    if (res?.error) return

    if (!Array.isArray(res.data)) return
    let svyHeaders = []
    for (let i = 0; i < res.data.length; i++) {
      svyHeaders.push({
        survey: res.data[i].survey,
        wellName: res.data[i].actualWell,
        depthFrom: res.data[i].tieOn.depthFrom,
        depthTo: res.data[i].tieOn.depthTo,
        datumElevation: res.data[i].datumElevation,
        isPlan: res.data[i].isPlan,
      })
    }

    svyHeaders.sort((a, b) => {
      if (a.depthFrom < b.depthFrom) return -1
      if (a.depthFrom > b.depthFrom) return 1
      return 0
    })

    tieOnSurveyHeadersRef.current = cloneDeep(svyHeaders)
    setTieOnSurveyHeaders(svyHeaders)
  }

  const updatePlannedWellData = async (data) => {
    if (!data) return

    let newData = cloneDeep(plannedWelldataRef.current)
    if (newData.hasOwnProperty(data.tag)) {
      newData[data.tag] = data.value
    }

    if (newData.VsOrigin === 'Well') {
      newData.VsOrgNorth =
        facilityWellOffsetRef.current?.localCoordRef === 'WELL' ? 0 : facilityWellOffsetRef.current?.localNorth
      newData.VsOrgEast =
        facilityWellOffsetRef.current?.localCoordRef === 'WELL' ? 0 : facilityWellOffsetRef.current?.localEast
    }

    if (newData.VsOrigin === 'Facility') {
      newData.VsOrgNorth =
        facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localNorth
      newData.VsOrgEast =
        facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localEast
    }

    if (newData.vsAziUseBhl) {
      let bhlNs = plannedWelldataRef.current.bhalNs
      let bhlEw = plannedWelldataRef.current.bhalEw

      bhlNs += facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localNorth
      bhlEw += facilityWellOffsetRef.current?.localCoordRef !== 'WELL' ? 0 : facilityWellOffsetRef.current?.localEast

      let deltaNs = bhlNs - newData.VsOrgNorth
      let deltaEw = bhlEw - newData.VsOrgEast
      let vsAzi = Math.atan2(deltaEw, deltaNs) * (180 / Math.PI)
      if (vsAzi < 0) vsAzi += 360
      if (vsAzi > 360) vsAzi -= 360
      newData.VsAzi = vsAzi
    }

    if (newData.inputMeth === 'Survey') newData.inputMeth = 'SURVEY'
    if (newData.inputMeth === 'Surface') newData.inputMeth = 'SURFACE'
    if (newData.inputMeth === 'User Defined') newData.inputMeth = 'USER'

    if (newData.inputMeth !== 'SURVEY') {
      newData.parentTieOn = ''
      newData.parentSurveyWell = ''
    }

    if (newData.inputMeth === 'SURVEY' && tieOnSurveyHeadersRef.current.length > 0) {
      if (newData.parentTieOn === '') {
        newData.parentTieOn = tieOnSurveyHeadersRef.current[tieOnSurveyHeadersRef.current.length - 1].survey
      }

      if (newData.parentSurveyWell === '') {
        newData.parentSurveyWell = tieOnSurveyHeadersRef.current[tieOnSurveyHeadersRef.current.length - 1].wellName
      }
    }

    if (newData.inputMeth === 'SURFACE') {
      newData.md = 0
      newData.inc = 0
      newData.azi = 0
      newData.tvd = 0
      newData.ns = 0
      newData.ew = 0
    }

    if (newData.inputMeth === 'SURVEY') {
      let svyHeader = tieOnSurveyHeadersRef.current.find(
        (svy) => svy.wellName === newData.parentSurveyWell && svy.survey === newData.parentTieOn,
      )

      if (svyHeader) {
        let deltaElevation = getDeltaElevation(newData.parentSurveyWell, newData.parentTieOn)
        newData.md = svyHeader.depthTo + deltaElevation
        let interpData = await interpSurvey(newData)

        if (interpData) {
          newData.md = roundToNearestWholeIfClose(interpData.md)
          newData.inc = interpData.inc
          newData.azi = interpData.azi
          newData.tvd = interpData.tvd
          newData.ns = interpData.ns
          newData.ew = interpData.ew
        }
      }
    }

    setDataChanged(!isEqual(newData, orgDataRef.current))
    plannedWelldataRef.current = cloneDeep(newData)
    setPlannedWellData(newData)
  }

  const SaveButton = () => {
    return (
      <Box
        onClick={() => handleUpdate(plannedWelldataRef.current)}
        sx={{
          backgroundColor: 'green',
          margin: '10px',
          position: 'absolute',
          top: 0,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Save changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'humbleicons:save'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const DiscardButton = () => {
    return (
      <Box
        onClick={() => {
          plannedWelldataRef.current = cloneDeep(orgDataRef.current)
          setDataChanged(false)
          setPlannedWellData(orgDataRef.current)
        }}
        sx={{
          backgroundColor: 'red',
          margin: '10px',
          position: 'absolute',
          top: 60,
          right: 25,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
        }}>
        <Tooltip
          title='Discard changes'
          placement='right'
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgb(19,62,96)',
                fontSize: '12px',
                fontFamily: 'Roboto',
              },
            },
          }}>
          <Iconify icon={'material-symbols:cancel'} style={{ color: 'white', height: '40px', width: '40px' }} />
        </Tooltip>
      </Box>
    )
  }

  const handleCloseStatus = () => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  return (
    <React.Fragment>
      {isLoading || isUpdating ? (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onNo={() => {
            tvdAdjustOptionsRef.current.preserveTvds = false
            handleTvdChange()
          }}
          onConfirm={() => {
            tvdAdjustOptionsRef.current.preserveTvds = true
            handleTvdChange()
          }}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {dataChanged && !isUpdating ? <SaveButton /> : null}
      {dataChanged && !isUpdating ? <DiscardButton /> : null}
      <SplitPane
        split='horizontal'
        size={'20%'}
        allowResize={true}
        defaultSize={'20%'}
        minSize={'10%'}
        onDragFinished={(params) => onDragFinished(params, 0)}
        style={{
          height: '100%',
          width: `calc(100% - 25px)`,
          maxWidth: `calc(100% - 25px)`,
        }}>
        <SplitPane
          split='vertical'
          size={getInitialPaneSize(1)}
          allowResize={true}
          defaultSize={'50%'}
          minSize={'10%'}
          onDragFinished={(params) => onDragFinished(params, 1)}
          style={{
            height: '100%',
            width: `100%`,
            maxWidth: `100%`,
          }}>
          <PlannedWellGrid plannedWellData={plannedWellData} handleUpdate={updatePlannedWellData} />
          <VerticalSectionGrid wellData={plannedWellData} handleUpdate={updatePlannedWellData} />
        </SplitPane>
        <SplitPane
          split='horizontal'
          size={getInitialPaneSize(2)}
          allowResize={true}
          defaultSize={'35%'}
          minSize={'10%'}
          onDragFinished={(params) => onDragFinished(params, 2)}
          style={{
            height: '100%',
            width: `100%`,
            maxWidth: `100%`,
          }}>
          <DepthReferenceGrid wellData={plannedWellData} handleUpdate={updatePlannedWellDepthRef} />
          <SplitPane
            split='horizontal'
            size={'50%'}
            allowResize={true}
            defaultSize={'50%'}
            minSize={'10%'}
            onDragFinished={(params) => onDragFinished(params, 2)}
            style={{
              height: '100%',
              width: `100%`,
              maxWidth: `100%`,
            }}>
            <PlanTieOnGrid
              plannedWellData={plannedWellData}
              updateTieOnData={updateTieOnData}
              getTieOnSvy={getTieOnSvy}
              getDeltaElevation={getDeltaElevation}
              localCoordOffset={localCoordOffset}
              plannedWelldataRef={plannedWelldataRef}
              tieOnSurveyHeaders={tieOnSurveyHeaders}
              updatePlannedWellData={updatePlannedWellData}
            />
            <MagneticsGrid wellName={plannedWellData?.actualWellName} isPlan={true} />
          </SplitPane>
        </SplitPane>
      </SplitPane>
      {status?.show ? (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={status?.show}
          autoHideDuration={2000}
          onClose={handleCloseStatus}>
          <Alert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default PlannedWellPropertiesPage
