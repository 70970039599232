import React, { useEffect, useRef } from 'react'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import Canvas from 'components/common/Canvas'
import { numberWithCommasDecimals } from 'utils/stringFunctions'
import * as d3 from 'd3'
import { uuidv4 } from 'utils/stringFunctions'
import { Box } from '@mui/material'
import { useResizeDetector } from 'react-resize-detector'
import { DRAWERWIDE, DRAWERSLIM } from 'components/WellPages/EngineeringDashboard/EngineeringToolBar'
import { normalize } from 'utils/numberFunctions.js'
import { lerpColor } from 'utils/colorFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const ForceDeflectionContourChart = ({
  id = uuidv4(),
  isToolbarExpanded,
  backColor = null,
  gridColor = '#808080',
  borderColor = '#404040',
  textColor = null,
  minDataColor = '#0000FF',
  midDataColor = '#00FF00',
  maxDataColor = '#FF0000',
  data,
  chartData,
  height = '200px',
  printing = false,
}) => {
  const TOOLBAR_OFFSET = isToolbarExpanded ? DRAWERWIDE : DRAWERSLIM
  const TITLE_OFFSET = 0.075
  const X_SCALE_OFFSET = 0.1
  const Y_SCALE_OFFSET = 0.05
  const RIGHT_PADDING = 15
  const TICKSX = 20
  const TICKSY = 20
  const TICKSZ = 10
  const { width: chartWidth, height: chartHeight, ref } = useResizeDetector()
  const { getUnitsText } = useUnits()
  const _isMounted = useRef(false)
  const mousePosition = useRef(null)
  const { getChartBackColor, getTextColor, theme } = useInnovaTheme()

  if(!backColor) backColor = getChartBackColor()
  if(!textColor) textColor = getTextColor()

  const handleContextMenu = (e) => {}

  const handleMouseMove = (e) => {
    mousePosition.current = { x: e.offsetX, y: e.offsetY }
  }

  const handleMouseOut = (e) => {
    mousePosition.current = null
  }

  useEffect(() => {
    _isMounted.current = true
    document.getElementById(id).addEventListener('mousemove', handleMouseMove)
    document.getElementById(id).addEventListener('mouseout', handleMouseOut)
    document.getElementById(id).addEventListener('contextmenu', handleContextMenu)

    return () => {
      _isMounted.current = false
      if (document.getElementById(id)) {
        document.getElementById(id)?.removeEventListener('mousemove', handleMouseMove)
        document.getElementById(id)?.removeEventListener('mouseout', handleMouseOut)
        document.getElementById(id)?.removeEventListener('contextmenu', handleContextMenu)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const calculateNiceScales = (min, max, numTicks = 10) => {
    const range = max - min
    const rawStep = range / numTicks

    const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep)))
    const residual = rawStep / magnitude

    let niceStep = magnitude
    if (residual > 5) {
      niceStep = 10 * magnitude
    } else if (residual > 2) {
      niceStep = 5 * magnitude
    } else if (residual > 1) {
      niceStep = 2 * magnitude
    }

    const niceMin = Math.floor(min / niceStep) * niceStep
    const niceMax = Math.ceil(max / niceStep) * niceStep

    return { niceMin, niceMax, niceStep }
  }

  function getMaxX(data) {
    if (!data) return 100
    if (!Array.isArray(data)) return 100
    if (data.length === 0) return 100
    return d3.max(data, (d) => d.distanceFromBit)
  }

  function getMinX(data) {
    if (!data) return 0
    if (!Array.isArray(data)) return 0
    if (data.length === 0) return 0
    return d3.min(data, (d) => d.distanceFromBit)
  }

  function getMaxY(data) {
    if (!data) return 100
    if (!Array.isArray(data)) return 100
    if (data.length === 0) return 100
    return d3.max(data, (d) => 0.7 * d.od)
  }

  function getMinY(data) {
    if (!data) return 0
    if (!Array.isArray(data)) return 0
    if (data.length === 0) return 0
    return d3.min(data, (d) => -0.7 * d.od)
  }

  function getMaxZ(data) {
    if (!data) return 100
    if (!Array.isArray(data)) return 100
    if (data.length === 0) return 100
    return d3.max(data, (d) => d[chartData?.tag])
  }

  function getMinZ(data) {
    if (!data) return 0
    if (!Array.isArray(data)) return 0
    if (data.length === 0) return 0
    return d3.min(data, (d) => d[chartData?.tag])
  }

  const getFontString = (context, fontSize) => {
    let scale = getFontScale(context)
    return fontSize * scale
  }

  const getFontScale = (context) => {
    const { width } = getHeightWidth(context)
    const BASE_LINE_WIDTH = 300
    if (width >= BASE_LINE_WIDTH) return 1
    return Math.max(0.1, width / BASE_LINE_WIDTH)
  }

  const getHeightWidth = (context) => {
    return {
      height: getHeight(),
      width: getWidth(),
    }
  }

  const hitTest = (r, pt) => {
    return pt.x >= r.x && pt.x <= r.x + r.w && pt.y >= r.y && pt.y <= r.y + r.h
  }

  const getInterpolatedCoordinateX = (v, minVal, maxVal, minPos, maxPos) => {
    if (v < minVal) return -999.25
    if (v > maxVal) return -999.25
    if (minVal === maxVal) return -999.25
    if (minPos === maxPos) return -999.25
    let percent = (v - minVal) / (maxVal - minVal)
    return minPos + percent * (maxPos - minPos)
  }

  const getInterpolatedCoordinateY = (v, minVal, maxVal, minPos, maxPos) => {
    if (v < minVal) return -999.25
    if (v > maxVal) return -999.25
    if (minVal === maxVal) return -999.25
    if (minPos === maxPos) return -999.25
    let percent = (v - minVal) / (maxVal - minVal)
    return maxPos - percent * (maxPos - minPos)
  }

  const getNearestDataPointIndex = (minXPos, maxXPos, minXVal, maxXVal, mouseX) => {
    if (!data) return -1
    if (!Array.isArray(data)) return -1
    if (data.length === 0) return -1
    if (minXVal === maxXVal) return -1
    if (minXPos === maxXPos) return -1
    let percent = (mouseX - minXPos) / (maxXPos - minXPos)
    let xVal = minXVal + percent * (maxXVal - minXVal)
    let index = -1
    let minDistance = 999.25
    for (let i = 0; i < data.length; i++) {
      let distance = Math.abs(data[i].distanceFromBit - xVal)
      if (distance < minDistance) {
        minDistance = distance
        index = i
      }
    }
    if (minDistance > 0.5) return -1
    return index
  }

  const calculateTitleRect = (context) => {
    const { height, width } = getHeightWidth(context)
    let titleRectH = TITLE_OFFSET * height
    let titleRectW = width * 1 - TOOLBAR_OFFSET

    return {
      titleRectH,
      titleRectW,
    }
  }

  const calculateXScaleRect = (context) => {
    const { height, width } = getHeightWidth(context)
    let xScaleH = X_SCALE_OFFSET * height
    let xScaleW = width * 1 - TOOLBAR_OFFSET
    let xRectX = Y_SCALE_OFFSET * width + TOOLBAR_OFFSET
    let xRectY = TITLE_OFFSET * height

    return {
      xScaleH,
      xScaleW,
      xRectX,
      xRectY,
    }
  }

  const calculateYScaleRect = (context) => {
    const { height, width } = getHeightWidth(context)
    let yScaleH = height - TITLE_OFFSET * height - X_SCALE_OFFSET * height
    let yScaleW = width * Y_SCALE_OFFSET
    let yRectX = TOOLBAR_OFFSET
    let yRectY = TITLE_OFFSET * height

    return {
      yScaleH,
      yScaleW,
      yRectX,
      yRectY,
    }
  }

  const calculateChartRect = (context) => {
    const { height, width } = getHeightWidth(context)
    let chartRectH = height - TITLE_OFFSET * height - X_SCALE_OFFSET * height
    let chartRectW = width * 1 - TOOLBAR_OFFSET - Y_SCALE_OFFSET * width - RIGHT_PADDING
    let chartRectX = Y_SCALE_OFFSET * width + TOOLBAR_OFFSET
    let chartRectY = TITLE_OFFSET * height

    return {
      chartRectH,
      chartRectW,
      chartRectX,
      chartRectY,
    }
  }

  const drawBackGround = (context) => {
    context.clearRect(0, 0, context.canvas.width * 2, context.canvas.height * 2)
    context.fillStyle = backColor
    if (printing) {
      context.fillStyle = '#FFF'
    }

    context.beginPath()
    context.fillRect(0, 0, context.canvas.width * 2, context.canvas.height * 2)
  }

  const drawTitle = (context) => {
    const { titleRectH, titleRectW } = calculateTitleRect(context)

    context.font = `${getFontString(context, 0.95)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillStyle = textColor
    context.strokeStyle = textColor

    if (printing){
      context.fillStyle = '#000'
      context.strokeStyle = '#000'
    }

    context.beginPath()
    context.fillText(
      `${chartData?.title} (${chartData?.units === 'degrees' ? '°' : getUnitsText(chartData?.units)})`,
      TOOLBAR_OFFSET + titleRectW / 2,
      titleRectH / 2,
    )
  }

  const drawXScale = (context, minX, maxX, stepX) => {
    let unitName = getUnitsText(UNITS_FOR.Diameter)
    const { xScaleH, xScaleW, xRectY } = calculateXScaleRect(context)
    const { chartRectH, chartRectW, chartRectX } = calculateChartRect(context)
    context.font = `${getFontString(context, 0.65)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'top'
    context.fillStyle = textColor
    context.strokeStyle = textColor
    if (printing){
      context.fillStyle = '#000'
      context.strokeStyle = '#000'
    }
    context.beginPath()
    context.fillText(
      `Distance From Bit (${unitName})`,
      TOOLBAR_OFFSET + xScaleW / 2,
      context.canvas.height - xScaleH / 2,
    )

    let step = stepX

    while (step <= maxX) {
      let x = getInterpolatedCoordinateX(step, minX, maxX, chartRectX, chartRectX + chartRectW)
      context.beginPath()
      context.textAlign = 'center'
      context.textBaseline = 'top'
      context.fillText(`${numberWithCommasDecimals(step, 2)}`, x, xRectY + chartRectH + 2)

      step += stepX
    }
  }

  const drawYScale = (context, minY, maxY, stepY) => {
    let unitName = getUnitsText(UNITS_FOR.Diameter)
    const { height } = getHeightWidth(context)
    const { yScaleH, yScaleW, yRectY } = calculateYScaleRect(context)
    context.font = `${getFontString(context, 0.65)}em sans-serif`
    context.textAlign = 'center'
    context.textBaseline = 'bottom'
    context.fillStyle = textColor
    context.strokeStyle = textColor
    if (printing){
      context.fillStyle = '#000'
      context.strokeStyle = '#000'
    }
    context.save()
    context.translate(TOOLBAR_OFFSET + yScaleW / 2, TITLE_OFFSET * height + yScaleH / 2)
    context.rotate(-Math.PI / 2)
    context.fillText(`OD (${unitName})`, 0, 0)
    context.restore()

    let step = minY

    while (step <= maxY) {
      let y = getInterpolatedCoordinateY(step, minY, maxY, yRectY, yRectY + yScaleH)
      context.beginPath()
      context.textAlign = 'right'
      context.textBaseline = 'middle'
      context.fillText(`${numberWithCommasDecimals(step, 2)}`, TOOLBAR_OFFSET + yScaleW - 2, y)

      step += stepY
    }
  }

  const drawChartRect = (context, minX, maxX, stepX, minY, maxY, stepY) => {
    const { chartRectH, chartRectW, chartRectX, chartRectY } = calculateChartRect(context)
    let XL = chartRectX
    let XR = chartRectX + chartRectW
    let YT = chartRectY
    let YB = chartRectY + chartRectH

    let gradient = context.createLinearGradient(chartRectX, chartRectY, chartRectW, chartRectH)
    gradient.addColorStop(0, borderColor)
    gradient.addColorStop(1, backColor)
    context.fillStyle = gradient
    if (printing){
      context.fillStyle = '#FFF'
    }
    context.beginPath()
    context.fillRect(chartRectX, chartRectY, chartRectW, chartRectH)

    context.fillStyle = gridColor
    context.strokeStyle = gridColor
    if (printing){
      context.fillStyle = '#000'
      context.strokeStyle = '#000'
    }
    context.lineWidth = 1

    let step = stepX
    while (step <= maxX - stepX) {
      let x = getInterpolatedCoordinateX(step, minX, maxX, chartRectX, chartRectX + chartRectW)
      context.beginPath()
      context.setLineDash([5, 3])
      context.moveTo(x, YT)
      context.lineTo(x, YB)
      context.stroke()
      step += stepX
    }

    step = minY + stepY
    while (step <= maxY - stepY) {
      let y = getInterpolatedCoordinateY(step, minY, maxY, chartRectY, chartRectY + chartRectH)
      context.beginPath()
      context.moveTo(XL, y)
      context.lineTo(XR, y)
      context.stroke()
      step += stepY
    }

    context.fillStyle = borderColor
    context.strokeStyle = borderColor
    if (printing){
      context.fillStyle = '#000'
      context.strokeStyle = '#000'
    }
    context.lineWidth = 1
    context.beginPath()
    context.setLineDash([0, 0])
    context.moveTo(XL, YT)
    context.lineTo(XR, YT)
    context.lineTo(XR, YB)
    context.lineTo(XL, YB)
    context.lineTo(XL, YT)
    context.stroke()
  }

  const drawLegend = (context, minZ, maxZ) => {
    const { chartRectH, chartRectW, chartRectX, chartRectY } = calculateChartRect(context)
    let legendW = 0.2 * (chartRectW - chartRectX)
    let legendH = 0.2 * (chartRectH - chartRectY)
    let legendX = chartRectX + chartRectW - legendW - (chartRectW - chartRectX) * 0.05
    let legendY = chartRectY + (chartRectH - chartRectY) * 0.05

    context.font = `${getFontString(context, 0.5)}em sans-serif`
    context.fillStyle = backColor
    if (printing){
      context.fillStyle = '#FFF'
    }
    context.beginPath()
    context.fillRect(legendX, legendY, legendW, legendH)

    let offsetX = 0.1 * legendW
    let offsetY = 0.25 * legendH

    let gradient = context.createLinearGradient(
      legendX + offsetX,
      legendY + offsetY,
      legendX + legendW - offsetX,
      legendY + offsetY,
    )
    gradient.addColorStop(0, minDataColor)
    gradient.addColorStop(0.5, midDataColor)
    gradient.addColorStop(1, maxDataColor)

    context.fillStyle = gradient
    context.beginPath()
    context.fillRect(legendX + offsetX, legendY + offsetY, legendW - 2 * offsetX, legendH - 3 * offsetY)

    context.fillStyle = textColor
    context.strokeStyle = textColor
    if (printing){
      context.fillStyle = '#000'
      context.strokeStyle = '#000'
    }
    context.textAlign = 'center'
    context.textBaseline = 'top'
    let max = Math.max(Math.abs(minZ), Math.abs(maxZ))
    context.fillText(`${numberWithCommasDecimals(0, 1)}`, legendX + offsetX, legendY + 2 * offsetY + 2)
    context.fillText(`${numberWithCommasDecimals(max, 1)}`, legendX + legendW - offsetX, legendY + 2 * offsetY + 2)
  }

  const drawDataPoint = (
    context,
    dataToDraw,
    nextDataToDraw,
    maxX,
    minX,
    maxY,
    minY,
    maxZ,
    minZ,
    chartRectX,
    chartRectY,
    chartRectH,
    chartRectW,
  ) => {
    let xL = getInterpolatedCoordinateX(dataToDraw.distanceFromBit, minX, maxX, chartRectX, chartRectX + chartRectW)
    let xR = getInterpolatedCoordinateX(nextDataToDraw.distanceFromBit, minX, maxX, chartRectX, chartRectX + chartRectW)

    let yT = getInterpolatedCoordinateY(
      dataToDraw.od / 2 + dataToDraw.hsDef,
      minY,
      maxY,
      chartRectY,
      chartRectY + chartRectH,
    )
    let yB = getInterpolatedCoordinateY(
      -dataToDraw.od / 2 + dataToDraw.hsDef,
      minY,
      maxY,
      chartRectY,
      chartRectY + chartRectH,
    )

    let max = Math.max(Math.abs(minZ), Math.abs(maxZ))
    let normColor = normalize(Math.abs(dataToDraw[chartData?.tag]), 0, max)
    if (normColor <= 0.5) {
      normColor = normColor * 2
      let color = lerpColor(minDataColor, midDataColor, normColor)
      context.fillStyle = color
    } else {
      normColor = (normColor - 0.5) * 2
      let color = lerpColor(midDataColor, maxDataColor, normColor)
      context.fillStyle = color
    }

    context.save()

    // Translate and rotate the context
    context.translate((xL + xR) / 2, (yT + yB) / 2)
    context.rotate((dataToDraw.hsRot * Math.PI) / 180)

    // Draw the rotated rectangle
    context.fillRect(-(xR - xL) / 2, -(yB - yT) / 2, xR - xL, yB - yT)

    // Restore the original transformation matrix
    context.restore()
  }

  const drawData = (context, maxX, minX, maxY, minY, maxZ, minZ) => {
    const { chartRectH, chartRectW, chartRectX, chartRectY } = calculateChartRect(context)

    if (!data) return
    for (let i = 0; i < data.length - 1; i++) {
      let dataToDraw = data[i]
      let nextDataToDraw = data[i + 1]

      drawDataPoint(
        context,
        dataToDraw,
        nextDataToDraw,
        maxX,
        minX,
        maxY,
        minY,
        maxZ,
        minZ,
        chartRectX,
        chartRectY,
        chartRectH,
        chartRectW,
      )
    }
  }

  const drawToolTipLine = (context, minX, maxX) => {
    if (!mousePosition.current) return
    const { chartRectH: h, chartRectW: w, chartRectX: x, chartRectY: y } = calculateChartRect(context)
    if (!hitTest({ x, y, w, h }, mousePosition.current)) return
    let index = getNearestDataPointIndex(x, x + w, minX, maxX, mousePosition.current.x)

    if (index === -1) return
    context.strokeStyle = backColor
    context.lineWidth = 1
    context.fillStyle = backColor

    context.beginPath()
    context.setLineDash([5, 3])
    context.moveTo(mousePosition.current.x, y)
    context.lineTo(mousePosition.current.x, y + h)
    context.stroke()
  }

  const drawToolTipText = (context, minX, maxX) => {
    if (!mousePosition.current) return
    const { chartRectH: h, chartRectW: w, chartRectX: x, chartRectY: y } = calculateChartRect(context)
    if (!hitTest({ x, y, w, h }, mousePosition.current)) return
    let index = getNearestDataPointIndex(x, x + w, minX, maxX, mousePosition.current.x)
    let mouseOffset = 2

    if (index === -1) return
    context.strokeStyle = backColor
    context.lineWidth = 1
    context.fillStyle = backColor

    let toolTipW = 0.15 * w
    let toolTipH = 0.24 * h
    let toolTipX = mousePosition.current.x + mouseOffset
    let toolTipY = mousePosition.current.y - toolTipH

    let toolTipMidX = toolTipX + 0.85 * toolTipW
    let toolTipMidY = toolTipY + 0.1 * toolTipH

    let Xflip = false
    if (toolTipMidX > x + w) {
      toolTipX -= toolTipW + 2 * mouseOffset
      Xflip = true
    }

    if (toolTipMidY < y) {
      toolTipY += toolTipH
      if (!Xflip) toolTipX -= toolTipW + 2 * mouseOffset
    }

    let gradient = context.createLinearGradient(toolTipX, toolTipY, toolTipX + toolTipW, toolTipY)
    gradient.addColorStop(0, borderColor)
    gradient.addColorStop(1, backColor)
    context.fillStyle = gradient
    context.beginPath()
    context.fillRect(toolTipX, toolTipY, toolTipW, toolTipH)

    let textHeight = 0.25 * toolTipH
    context.font = `${getFontString(context, 0.5)}em sans-serif`
    context.fillStyle = theme === 'dark' ? textColor : '#FFF'
    context.strokeStyle = theme === 'dark' ? textColor : '#FFF'
    context.textAlign = 'left'
    context.textBaseline = 'middle'
    context.fillText(
      `${chartData?.title} (${chartData?.units === 'degrees' ? '°' : getUnitsText(chartData?.units)})`,
      toolTipX,
      toolTipY + textHeight / 2,
    )
    context.fillText(
      `Dist: ${numberWithCommasDecimals(data[index].distanceFromBit, 2)}`,
      toolTipX,
      toolTipY + textHeight / 2 + textHeight,
    )
    context.fillText(
      `${chartData?.yAxisTitle}: ${numberWithCommasDecimals(data[index][chartData?.tag], 2)} `,
      toolTipX,
      toolTipY + textHeight / 2 + 2 * textHeight,
    )
    context.fillText(`${data[index].description}`, toolTipX, toolTipY + textHeight / 2 + 3 * textHeight)
  }

  const draw = (context, frameCount) => {
    //Frame count can be used for animations
    const {
      niceMin: niceMinX,
      niceMax: niceMaxX,
      niceStep: stepX,
    } = calculateNiceScales(getMinX(data), getMaxX(data), TICKSX)
    const {
      niceMin: niceMinY,
      niceMax: niceMaxY,
      niceStep: stepY,
    } = calculateNiceScales(getMinY(data), getMaxY(data), TICKSY)
    const { niceMin: niceMinZ, niceMax: niceMaxZ } = calculateNiceScales(getMinZ(data), getMaxZ(data), TICKSZ)

    context.globalAlpha = 1
    drawBackGround(context)
    drawTitle(context)
    drawXScale(context, niceMinX, niceMaxX, stepX)
    drawYScale(context, niceMinY, niceMaxY, stepY)
    drawChartRect(context, niceMinX, niceMaxX, stepX, niceMinY, niceMaxY, stepY)
    drawData(context, niceMaxX, niceMinX, niceMaxY, niceMinY, niceMaxZ, niceMinZ)
    context.globalAlpha = 0.8
    drawToolTipLine(context, niceMinX, niceMaxX)
    drawLegend(context, niceMinZ, niceMaxZ)
    drawToolTipText(context, niceMinX, niceMaxX)
  }

  const getWidth = () => {
    return chartWidth ? chartWidth : 1
  }

  const getHeight = () => {
    return chartHeight ? chartHeight : 1
  }

  return (
    <Box
      sx={{
        backgroundColor: getChartBackColor(),
        display: 'flex',
        height: height,
        width: '100%',
      }}
      id={id}
      ref={ref}>
      <Canvas
        square={false}
        draw={draw}
        width={'100%'}
        height={height}
        style={{
          width: '100%',
          height: height,
        }}
      />
    </Box>
  )
}

export default ForceDeflectionContourChart
