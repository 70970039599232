import React, { useRef, useState, useEffect } from 'react'
import useMotorReport from 'components/common/hooks/useMotorReport'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material'
import MotorReportModalGrid from './MotorReportModalGrid'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const MotorReportModal = ({ setOpen, bhaNum, setStatus, wellName }) => {
  const _isMounted = useRef(false)
  const { loading: isLoading, fetchMotorReportData, updateMotorReport, currentWellRef } = useMotorReport(wellName)
  const [motorReportData, setMotorReportData] = useState([])
  const bhaNumRef = useRef(bhaNum)
  const { getWindowBarColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current) {
      if (!isLoading) setMotorReportData(fetchMotorReportData(bhaNum))
    }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const getMotorReport = (key) => {
    if (!motorReportData) return []
    if (!key) return []
    if (typeof key !== 'string') return []
    if (!motorReportData.hasOwnProperty(key)) return []
    if (!Array.isArray(motorReportData[key])) return []
    return motorReportData[key]
  }

  const handleUpdate = async (data) => {
    let res = await updateMotorReport(bhaNumRef.current, data)
    if (res.isError) {
      setStatus({ show: true, severity: 'error', message: res.message })
      return false
    }

    setStatus({ show: true, severity: 'success', message: 'Motor report updated' })
    return true
  }

  const boxStyles = {
    background: getWindowBarColor(),
    border: '1px solid grey',
    color: '#C0C0C0',
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '5px',
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'lg'}
        PaperProps={{
          sx: {
            height: '70vh',
            backgroundColor: 'itemBackground',
            overflowY: 'visible',
          },
        }}
        open={true}
        onClose={() => setOpen(false)}>
        <DialogTitle>{`Motor Performance Report - ${currentWellRef.current}`}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '80%' }}>
              <Box
                sx={{
                  flex: 1,
                  minHeight: '40vh',
                }}>
                <Box sx={boxStyles}>Run Summary</Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('runSummary')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  minHeight: '40vh',
                }}>
                <Box sx={boxStyles}>Drilling Parameters</Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('drillingParams')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  minHeight: '40vh',
                }}>
                <Box sx={boxStyles}>Mud Properties</Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('mudProperties')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Box
                sx={{
                  flex: 1,
                  minHeight: '30vh',
                }}>
                <Box
                  sx={{
                    marginTop: '30px',
                    ...boxStyles,
                  }}>
                  Personnel
                </Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('personnelData')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  minHeight: '30vh',
                }}>
                <Box
                  sx={{
                    marginTop: '30px',
                    ...boxStyles,
                  }}>
                  MWD
                </Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('mwdData')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  minHeight: '20vh',
                }}>
                <Box
                  sx={{
                    marginTop: '60px',
                    ...boxStyles,
                  }}>
                  Comments / Failure Information
                </Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('comments')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  minHeight: '30vh',
                }}>
                <Box
                  sx={{
                    marginTop: '90px',
                    ...boxStyles,
                  }}>
                  Failure Information
                </Box>
                <MotorReportModalGrid
                  gridData={getMotorReport('failureInfo')}
                  isLoading={isLoading}
                  handleUpdate={handleUpdate}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default MotorReportModal
