// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef, useState } from 'react'
import useInnovaAuth from 'components/common/hooks/useInnovaAuth'
import axios from 'axios'

const useAxiosGzip = (options) => {
  const _isMounted = useRef(false)
  const { getAccessTokenSilently, user, getDatabaseOrg } = useInnovaAuth()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  const fetchGzipResponse = async (payload, newUrl) => {
    const result = await getGzipResponse(options.url, { payload, url: newUrl, timeout: options.timeout })
    return result
  }

  const getGzipResponse = async (url, options) => {
    let formData = new FormData()
    const accessToken = await getAccessTokenSilently()

    let axiosOptions = {
      url: url,
    }

    formData.append('userName', user?.name)
    formData.append('databaseOrg', getDatabaseOrg())
    formData.append('productKey', process.env.REACT_APP_ICP_PRODUCT_KEY)
    if (options.payload) {
      for (const property in options.payload) {
        if (Object.prototype.hasOwnProperty.call(options.payload, property)) {
          formData.append(property, options.payload[property])
        }
      }
    }

    try {
      const response = await axios({
        method: 'post',
        baseURL: process.env.REACT_APP_ICP_API,
        timeout: options.timeout ? options.timeout : 60000,
        ...axiosOptions,
        data: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: 'arraybuffer',
      })

      let decompressed = {}
      if (response.data) {
        try {
          const decoder = new TextDecoder('utf-8')
          const text = decoder.decode(response.data)
          decompressed = JSON.parse(text)
        } catch (e) {
          console.error('decode error', e)
          return {
            data: null,
            error: null,
            headers: response.headers,
          }
        }
      }

      return {
        data: decompressed,
        error: response.error,
        headers: response.headers,
      }
    } catch (error) {
      try {
        const decoder = new TextDecoder('utf-8')
        const text = decoder.decode(error.response.data)
        let decompressed = JSON.parse(text)

        return {
          data: null,
          error: decompressed?.error,
        }
      } catch (e) {
        return {
          data: null,
          error: e,
        }
      }
    }
  }
  return fetchGzipResponse
}

export default useAxiosGzip
