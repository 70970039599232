import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from '@sheet/core'
import { analyticsFilterDefsAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import useInnovaAxios from 'components/common/hooks/useInnovaAxios'
import AnalyticsHorizontalBarChart from './AnalyticsHorizontalBarChart'
import { getParameter, getParameterActive } from '../AnalyticsPage/FilterDefs'
import { array2pipestr } from 'utils'

const TopTenKpiChart = ({ url, dataParam, title, pageKey, label }) => {
  const _isMounted = useRef(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const searchParams = useRecoilValue(analyticsFilterDefsAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)

  const getKpiData = useInnovaAxios({
    url: url,
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(pageKey)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData()
  }, [searchParams, selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  const getNumResults = () => {
    let numRes = getParameter('#Results', searchParams)
    if (!Array.isArray(numRes)) numRes = 10
    if (Array.isArray(numRes) && numRes.length === 0) numRes = 10
    if (Array.isArray(numRes) && numRes.length > 0) {
      numRes = numRes[0]
      if (typeof numRes === 'string') {
        numRes = parseInt(numRes)
      }
    }

    if (!getParameterActive('#Results', searchParams)) numRes = 10
    return parseInt(numRes)
  }

  const getNonDrill = () => {
    if (!getParameterActive('Inc Non Drill', searchParams)) return false
    let param = getParameter('Inc Non Drill', searchParams)
    if (typeof param === 'string' && param === 'Yes') return true
    if (Array.isArray(param) && param.length > 0) return param[0] === 'Yes'
    return false
  }

  const fetchData = async () => {
    if (!_isMounted) return
    if (isLoading) return
    setLoading(true)

    const res = await getKpiData({
      numResults: getNumResults(),
      nonDrill: getNonDrill(),
      wellList: array2pipestr(selectedWells),
    })

    if (res?.error) {
      return
    }

    if (!_isMounted.current) {
      return
    }

    setLoading(false)
    if (res?.data) {
      setData(res.data)
      return
    }

    setData([])
  }

  const onXlsxExport = () => {
    let topTen = XLSX.utils.json_to_sheet(data)
    let wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, topTen, title)
    let titleLower = title.toLowerCase()
    XLSX.writeFile(wb, `${titleLower}.xlsx`)
  }

  return (
    <AnalyticsHorizontalBarChart
      data={data}
      title={title}
      param={dataParam}
      label={label}
      isLoading={isLoading}
      onXlsxExport={onXlsxExport}
    />
  )
}

export default TopTenKpiChart
