import React, { useMemo, useRef, useState, useEffect } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { Box, Button } from '@mui/material'
import SearchBar from 'components/common/SearchBar'
import { debounce } from 'lodash'
import { GetActivityColor, getStatusAvatar } from 'components/common/activitySelector'
import Avatar from '@mui/material/Avatar'
import { getStringId } from 'components/common/AgGridUtils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const WellSelectorEditor = (props) => {
  const gridApi = useRef(null)
  const { getAgGridTheme, searchBarStyle } = useInnovaTheme()
  const [searchText, setSearchText] = useState('')

  const debounceSetQuickFilter = debounce((text) => {
    if (gridApi.current) {
      gridApi.current.setGridOption('quickFilterText', text)
    }
  }, 300)

  function getValue() {
    if (!gridApi.current) return ''
    const selectedRows = gridApi.current.getSelectedRows()
    if (!Array.isArray(selectedRows)) return ''
    if (selectedRows.length === 0) return ''
    return selectedRows[0].actualWell
  }

  useEffect(() => {
    debounceSetQuickFilter(searchText)
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const onFirstDataRendered = (params) => {
    const selectedNode = []
    let rowIndex = -1
    gridApi.current.forEachNode((node) => {
      if (node.data && node.data.actualWell === props.data.actualWell) {
        rowIndex = node.rowIndex
        selectedNode.push(node)
      }
    })

    gridApi.current.setNodesSelected({
      nodes: selectedNode,
      newValue: true,
    })

    if (rowIndex !== -1) {
      gridApi.current.ensureIndexVisible(rowIndex, 'middle')
    }

    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    gridApi.current?.autoSizeAllColumns()
  }

  const onGridReady = (params) => {
    gridApi.current = params.api
  }

  const centerAlignCell = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  )

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      sortable: true,
      editable: false,
      autoHeight: true,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
      headerClass: 'header-no-padding',
      filter: 'agSetColumnFilter',
      filterParams: {
        excelMode: 'windows',
      },
      cellStyle: centerAlignCell,
    }
  }, [centerAlignCell])

  let columnDefs = [
    {
      headerName: '',
      width: 100,
      headerCheckboxSelection: false,
      checkboxSelection: true,
      cellRenderer: (params) => {
        return (
          <Box
            sx={{
              margin: 'auto',
              width: 35,
              height: 35,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: GetActivityColor(params?.data?.status),
              borderRadius: '50%',
            }}>
            {params?.data.hasOwnProperty('status') ? (
              <Avatar
                alt={params?.data?.status}
                src={getStatusAvatar(params?.data?.status)}
                style={{ width: 35, height: 35 }}
              />
            ) : null}
          </Box>
        )
      },
    },
    {
      field: 'operator',
      colId: 'operator',
      headerName: 'Operator',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'start' },
    },
    {
      field: 'actualWell',
      colId: 'actualWell',
      headerName: 'Well',
      cellStyle: { display: 'flex', alignItems: 'center', justifyContent: 'start' },
    },
    {
      field: 'jobNum',
      colId: 'jobNum',
      headerName: 'Job#',
    },
    {
      field: 'rig',
      colId: 'rig',
      headerName: 'Rig',
    },
    {
      field: 'state',
      colId: 'state',
      headerName: 'State',
    },
    {
      field: 'county',
      colId: 'county',
      headerName: 'County',
    },
    {
      field: 'status',
      colId: 'status',
      headerName: 'Status',
      cellStyle: (params) => {
        return {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          color: GetActivityColor(params?.data?.status),
        }
      },
    },
  ]

  const gridOptions = {
    onCellDoubleClicked: (params) => {
      props.stopEditing()
    },
    rowHeight: 35,
    sideBar: {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
      ],
      defaultToolPanel: '',
      position: 'left',
    },
  }

  const getRowId = useMemo(() => {
    return (params) => {
      return getStringId(params.data?.actualWell)
    }
  }, [])

  return (
    <Box sx={{ height: '500px', width: '70vw', maxWidth: '1500px', maxHeight: '600px' }}>
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={searchBarStyle()}
        />
        <Box
          sx={{
            width: '100px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              props.onValueChange(getValue())
              props.stopEditing()
            }}>
            Select
          </Button>
        </Box>
      </Box>
      <div className={getAgGridTheme()} style={{ width: '100%', height: 'calc(100% - 50px)' }}>
        <AgGridReact
          rowData={Array.isArray(props.wellList) ? props.wellList : []}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={false}
          enableBrowserTooltips={true}
          gridOptions={gridOptions}
          headerHeight={30}
          onGridReady={onGridReady}
          rowSelection='single'
          onFirstDataRendered={onFirstDataRendered}
          getContextMenuItems={(params) => []}
          getRowId={getRowId}
        />
      </div>
    </Box>
  )
}

export default WellSelectorEditor
