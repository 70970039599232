import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilValue } from 'recoil'
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Tooltip,
  Collapse,
  Switch,
  Box,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Icon as Iconify } from '@iconify/react'
import UserProfileCard from './UserProfileCard'
import NovDefaultIcon from 'assets/wellScreenIcons/novGrey.png'
import RigIcon from 'assets/rigIcon.png'
import { checkFeature } from '../userPermissions'
import { userRoleSelector } from 'atoms'
import OilPriceCard from './OilPriceCard'
import { appColors } from 'utils'
import BulkEditDialog from 'components/Admin/BulkEdit/BulkEditDialog'
import TableExportModal from 'components/Admin/TableExportModal/TableExportModal'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const NavMenu = ({ isAuthenticated }) => {
  const { theme, toggleTheme } = useInnovaTheme()
  const [showDrawer, toggleDrawer] = useState(false)
  const [subMenuState, setSubMenuState] = useState({
    userAdmin: false,
    desktopAppUpdates: false,
    innovaAdmin: false,
    innovaBilling: false,
    innovaLicensing: false,
    dataAcq: false,
    adminTools: false,
    innovaFinAdmin: false,
  })

  const [showTableExport, setShowTableExport] = useState(false)
  const [showDbCleaningDialog, setShowDbCleaningDialog] = useState(false)
  const userRole = useRecoilValue(userRoleSelector)
  const navigate = useNavigate()

  if (!isAuthenticated) return null

  const navigateTo = (route) => {
    navigate(route)
    toggleDrawer(false)
  }

  const toggleSubMenuState = (key) => {
    setSubMenuState({ ...subMenuState, [key]: !subMenuState[key] })
  }

  const getSubMenuState = (key) => {
    return subMenuState[key]
  }

  const ListItem = ({
    visible = true,
    route,
    icon,
    text,
    onClick,
    isNested,
    color = '#000',
    height = 27,
    width = 27,
  }) => {
    if (!visible) return null
    let isImage = false
    if (typeof icon === 'string') {
      if (icon.includes('data:image') || icon.includes('/static/')) {
        isImage = true
      }
    }

    return (
      <ListItemButton
        onClick={onClick ? onClick : () => navigateTo(route)}
        sx={{ paddingLeft: isNested ? '32px' : '10px' }}>
        {isImage ? (
          <img alt={'img'} src={icon} style={{ height: height, width: width }} />
        ) : (
          <Iconify icon={icon} style={{ fontSize: '24px', color }} />
        )}
        <ListItemText sx={{ paddingLeft: '4px' }}>{text}</ListItemText>
      </ListItemButton>
    )
  }

  const ThemeButton = () => {
    return (
      <Box sx={{ paddingLeft: '3px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Switch checked={theme === 'dark'} onChange={() => toggleTheme()} />
        <ListItemText sx={{ paddingLeft: '4px' }}>{'Dark Mode'}</ListItemText>
      </Box>
    )
  }

  const InnovaAdminList = () => {
    if (!userRole.innovaAdmin) return null

    return (
      <React.Fragment>
        <ListItem
          text={'Innova Billing'}
          icon={'ant-design:dollar-circle-filled'}
          color={appColors.itemTextColor}
          onClick={() => toggleSubMenuState('innovaBilling')}
        />
        <Collapse in={getSubMenuState('innovaBilling')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Invoices'}
              icon={'fa-solid:file-invoice-dollar'}
              route={'/innovaAdmin/innovaBilling/invoices'}
              isNested={true}
            />
            <ListItem
              text={'Quotes'}
              icon={'fa-solid:file-invoice'}
              route={'/innovaAdmin/innovaBilling/quotes'}
              isNested={true}
            />
            <ListItem
              text={'Companys'}
              icon={'ic:baseline-oil-barrel'}
              route={'/innovaAdmin/innovaBilling/companys'}
              isNested={true}
            />
            <ListItem
              text={'Contacts'}
              icon={'bxs:contact'}
              route={'/innovaAdmin/innovaBilling/contacts'}
              isNested={true}
            />
            <ListItem
              text={'Software Details'}
              icon={'ic:baseline-computer'}
              route={'/innovaAdmin/innovaBilling/softwareDetails'}
              isNested={true}
            />
            <ListItem
              text={'Software Billing'}
              icon={'medical-icon:billing'}
              route={'/innovaAdmin/innovaBilling/softwareBilling'}
              isNested={true}
            />
          </List>
        </Collapse>
        <ListItem
          text={'Innova Licensing'}
          icon={'mdi:license'}
          color={appColors.itemTextColor}
          onClick={() => toggleSubMenuState('innovaLicensing')}
        />
        <Collapse in={getSubMenuState('innovaLicensing')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Software Licenses'}
              icon={'carbon:license-draft'}
              route={'/innovaAdmin/licensing/licenses'}
              isNested={true}
            />
            <ListItem
              text={'License Requests'}
              icon={'carbon:license-global'}
              route={'/innovaAdmin/licensing/licenseRequest'}
              isNested={true}
            />
            <ListItem
              text={'License Deactivations'}
              icon={'tabler:license-off'}
              route={'/innovaAdmin/licensing/licenseDeactivations'}
              isNested={true}
            />
          </List>
        </Collapse>
        <ListItem
          text={'Innova Admin'}
          icon={'ic:baseline-verified-user'}
          color={appColors.itemTextColor}
          onClick={() => toggleSubMenuState('innovaAdmin')}
        />
        <Collapse in={getSubMenuState('innovaAdmin')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Organizations'}
              icon={'clarity:users-solid'}
              route={'/admin/innovaAdmin/orgs'}
              isNested={true}
            />
            <ListItem
              text={'DB Task Status'}
              icon={'fluent-mdl2:database-sync'}
              route={'/innovaAdmin/databaseRestoreStatus'}
              isNested={true}
            />
            <ListItem
              text={'DB Backups'}
              icon={'carbon:data-backup'}
              route={'/innovaAdmin/databaseBackupFiles'}
              isNested={true}
            />
            <ListItem
              text={'Infrastructure Health'}
              icon={'material-symbols:dashboard-customize-rounded'}
              route={'/admin/innovaAdmin/dashboard'}
              isNested={true}
            />
            <ListItem
              text={'ICDS Servers'}
              icon={'fa-solid:server'}
              route={'/admin/innovaAdmin/icds'}
              isNested={true}
            />
            <ListItem
              text={'AC Dashboard Status'}
              icon={'solar:target-linear'}
              route={'/admin/innovaAdmin/acDashboard'}
              isNested={true}
            />
            <ListItem
              text={'Drill Link'}
              icon={NovDefaultIcon}
              route={'/admin/innovaAdmin/drillLink'}
              isNested={true}
            />
            <ListItem
              text={'Innova Admin Users'}
              icon={'dashicons:admin-users'}
              route={'/innovaAdmin/innovaAdminUsers'}
              isNested={true}
            />
            <ListItem text={'API Health'} icon={'mdi:api'} route={'/admin/innovaAdmin/apiHealth'} isNested={true} />
            <ListItem
              text={'Endpoint Usage'}
              icon={'mdi:web'}
              route={'/admin/innovaAdmin/endPointUsage'}
              isNested={true}
            />
            <ListItem
              text={'CRM System'}
              icon={'fa6-brands:salesforce'}
              route={'/admin/innovaAdmin/crmSystem'}
              isNested={true}
            />
            <ListItem
              text={'Innova Vendors'}
              icon={'fa-solid:warehouse'}
              route={'/admin/innovaAdmin/innovaVendors'}
              isNested={true}
            />
            <ListItem text={'Well List'} icon={'game-icons:oil-pump'} route={'/well-list-multi-db'} isNested={true} />
            <ListItem
              text={'Data Acq Health'}
              icon={'eos-icons:big-data-outlined'}
              route={'/admin/innovaAdmin/dataAcqHealth'}
              isNested={true}
            />
          </List>
        </Collapse>
        <ListItem
          text={'Wellseeker Updates'}
          icon={'material-symbols:update'}
          color={appColors.itemTextColor}
          onClick={() => toggleSubMenuState('desktopAppUpdates')}
        />
        <Collapse in={getSubMenuState('desktopAppUpdates')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Upload Files'}
              icon={'akar-icons:cloud-upload'}
              route={'/admin/wellSeekerUpdateFiles'}
              isNested={true}
            />
            <ListItem
              text={'Config'}
              icon={'clarity:update-line'}
              route={'/admin/wellSeekerUpdateConfig'}
              isNested={true}
            />
          </List>
        </Collapse>
        <Divider variant='middle' />
      </React.Fragment>
    )
  }

  const InnovaFinAdminList = () => {
    if (!userRole.finAdmin) return null

    return (
      <React.Fragment>
        <ListItem
          text={'Innova Accounting'}
          icon={'mdi:bank'}
          color={appColors.itemTextColor}
          onClick={() => toggleSubMenuState('innovaFinAdmin')}
        />
        <Collapse in={getSubMenuState('innovaFinAdmin')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Bank Accounts'}
              icon={'ci:settings-filled'}
              route={'/admin/bankRec/accounts'}
              isNested={true}
            />
            <ListItem
              text={'Employees'}
              icon={'clarity:users-solid'}
              route={'/admin/bankRec/employees'}
              isNested={true}
            />
          </List>
        </Collapse>
        <Divider variant='middle' />
      </React.Fragment>
    )
  }

  const AdminList = () => {
    return (
      <React.Fragment>
        <ListItem
          text={'Database Admin Tools'}
          icon={'eos-icons:data-mining'}
          onClick={() => toggleSubMenuState('adminTools')}
        />
        <Collapse in={getSubMenuState('adminTools')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Shipping Addresses'}
              icon={'fa6-solid:address-card'}
              route={'/admin/shippingAddresses'}
              isNested={true}
              visible={checkFeature(29, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Bids'}
              icon={'material-symbols:request-quote-sharp'}
              route={'/admin/bids'}
              isNested={true}
              visible={checkFeature(23, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Rig Names'}
              icon={RigIcon}
              route={'/admin/rigNames'}
              isNested={true}
              visible={checkFeature(21, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Data QC'}
              icon={'grommet-icons:validate'}
              route={'/admin/dataQc'}
              isNested={true}
              visible={checkFeature(17, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Object Validation'}
              icon={'vscode-icons:file-type-json-schema'}
              route={'/admin/objectQc'}
              isNested={true}
              visible={userRole?.roleAttributes?.permissions?.admin}
            />
            <ListItem
              text={'Invoice QC'}
              icon={'mdi:invoice-check-outline'}
              route={'/admin/invoiceQc'}
              isNested={true}
              visible={checkFeature(19, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'CRS'}
              icon={'clarity:map-line'}
              route={'/admin/crs'}
              isNested={true}
              visible={checkFeature(25, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Personnel Names'}
              icon={'bi:person-circle'}
              route={'/admin/personnelNames'}
              isNested={true}
              visible={checkFeature(20, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Personnel Utilization'}
              icon={'mdi:chart-gantt'}
              route={'/admin/personnelUtilization'}
              isNested={true}
              visible={checkFeature(20, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Audit Data'}
              icon={'ant-design:audit-outlined'}
              route={'/admin/auditData'}
              isNested={true}
              visible={checkFeature(15, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Table Export'}
              icon={'dashicons:database-export'}
              onClick={() => {
                toggleDrawer(false)
                setShowTableExport(true)
              }}
              isNested={true}
              visible={checkFeature(16, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Component Catalog'}
              icon={'grommet-icons:catalog'}
              route={'/admin/compCatalog'}
              isNested={true}
              visible={checkFeature(24, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Centralizer Catalog'}
              icon={'fluent:pipeline-add-20-filled'}
              route={'/admin/centralizerCatalog'}
              isNested={true}
              visible={checkFeature(46, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Stop Collar Catalog'}
              icon={'game-icons:spiked-collar'}
              route={'/admin/stopCollarCatalog'}
              isNested={true}
              visible={checkFeature(45, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Bulk Edit'}
              icon={'mdi:database-edit-outline'}
              onClick={() => {
                toggleDrawer(false)
                setShowDbCleaningDialog(true)
              }}
              isNested={true}
              visible={checkFeature(18, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Third Party Apps'}
              icon={'icon-park-outline:app-store'}
              route={'/admin/thirdPartyAppCreds'}
              isNested={true}
              visible={checkFeature(30, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Database Changes'}
              icon={'eos-icons:database-migration'}
              isNested={true}
              route = {'/admin/databaseChanges'}
              visible={checkFeature(17, userRole?.roleAttributes?.featureId)}
            />
          </List>
        </Collapse>
        <ListItem
          text={'File Manager'}
          icon={'material-symbols:folder-open-outline-rounded'}
          route={'/admin/fileManager'}
          visible={checkFeature(28, userRole?.roleAttributes?.featureId)}
        />
        <ListItem
          text={'Error Models'}
          icon={'ant-design:warning-filled'}
          route={'/errorModels'}
          visible={checkFeature(33, userRole?.roleAttributes?.featureId)}
        />
      </React.Fragment>
    )
  }

  const UserAdminList = () => {
    if (
      !checkFeature(31, userRole?.roleAttributes?.featureId) &&
      !checkFeature(22, userRole?.roleAttributes?.featureId) &&
      !userRole.innovaAdmin
    ) {
      return null
    }

    return (
      <React.Fragment>
        <ListItem
          text={'WellSeeker Admin'}
          icon={'ic:baseline-supervised-user-circle'}
          route={'/admin/wellSeekerAdmin'}
          visible={userRole.innovaAdmin ? true : checkFeature(31, userRole?.roleAttributes?.featureId)}
        />
        <ListItem
          text={'User Management'}
          icon={'ic:round-admin-panel-settings'}
          onClick={() => toggleSubMenuState('userAdmin')}
          visible={userRole.innovaAdmin ? true : checkFeature(22, userRole?.roleAttributes?.featureId)}
        />
        <Collapse in={getSubMenuState('userAdmin')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Users'}
              icon={'clarity:users-solid'}
              route={'/admin/userAdmin/users'}
              isNested={true}
              visible={userRole.innovaAdmin ? true : checkFeature(22, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Roles'}
              icon={'ci:settings-filled'}
              route={'/admin/userAdmin/roles'}
              isNested={true}
              visible={userRole.innovaAdmin ? true : checkFeature(22, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Units'}
              icon={'ph:scales-bold'}
              route={'/admin/userAdmin/units'}
              isNested={true}
              visible={userRole.innovaAdmin ? true : checkFeature(22, userRole?.roleAttributes?.featureId)}
            />
            <ListItem
              text={'Database Access'}
              icon={'fluent:database-person-20-filled'}
              route={'/admin/userAdmin/databaseAccess'}
              isNested={true}
              visible={userRole.innovaAdmin ? true : checkFeature(22, userRole?.roleAttributes?.featureId)}
            />
          </List>
        </Collapse>
      </React.Fragment>
    )
  }

  const DataAcqList = () => {
    if (!checkFeature(8, userRole?.roleAttributes?.featureId)) return null

    return (
      <React.Fragment>
        <ListItem text={'Data Acquisition'} icon={'mdi:access-point'} onClick={() => toggleSubMenuState('dataAcq')} />
        <Collapse in={getSubMenuState('dataAcq')} timeout='auto' unmountOnExit>
          <List sx={{ width: (theme) => theme.spacing(32) }}>
            <ListItem
              text={'Data Configurations'}
              icon={'bytesize:settings'}
              route={'/dataAcquisition'}
              isNested={true}
            />
            <ListItem text={'Data stores'} icon={'eos-icons:database'} route={'/dataStores'} isNested={true} />
            <ListItem
              text={'WITSML Servers'}
              icon={'ant-design:database-filled'}
              route={'/witsmlServers'}
              isNested={true}
            />
            <ListItem
              text={'File History'}
              icon={'ic:baseline-upload-file'}
              route={'/dataAckFileHistory'}
              isNested={true}
            />
          </List>
        </Collapse>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Tooltip
        title={'Main menu'}
        placement='bottom'
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'rgb(19,62,96)',
              fontSize: '12px',
              fontFamily: 'Roboto',
            },
          },
        }}>
        <IconButton
          sx={{ marginRight: '16px', paddingLeft: '0px', fontSize: '36px' }}
          aria-label='menu'
          onClick={() => toggleDrawer(true)}
          size='large'>
          <MenuIcon sx={{fontSize: 'inherit'}} />
        </IconButton>
      </Tooltip>
      {showTableExport ? <TableExportModal onClose={() => setShowTableExport(false)} /> : null}
      {showDbCleaningDialog ? (
        <BulkEditDialog
          title={'Bulk Edit'}
          open={showDbCleaningDialog}
          handleShowBulkEdit={() => setShowDbCleaningDialog(!showDbCleaningDialog)}
        />
      ) : null}
      <Drawer
        anchor='left'
        open={showDrawer}
        onClose={() => toggleDrawer(false)}
        sx={{
          zIndex: 10000,
        }}>
        <UserProfileCard />
        <OilPriceCard />
        <List sx={{ width: (theme) => theme.spacing(32) }}>
          <ListItem text={'Wells'} route={'/well-list'} icon={'ic:baseline-home'} />
          <ListItem text={'Profile'} route={'/user-profile'} icon={'ion:person-sharp'} />
          <ThemeButton />
          <Divider variant='middle' />
          <InnovaAdminList />
          <InnovaFinAdminList />
          <AdminList />
          <ListItem
            text={'Tool Orders'}
            route={'/toolOrders'}
            icon={'emojione-monotone:hammer-and-wrench'}
            visible={checkFeature(6, userRole?.roleAttributes?.featureId)}
          />
          <ListItem
            text={'Asset Search'}
            route={'/assetSearch'}
            icon={'fluent:box-search-20-regular'}
            visible={checkFeature(44, userRole?.roleAttributes?.featureId)}
          />
          <ListItem
            text={'Incident Dashboard'}
            route={'/incidentDashboard'}
            icon={'mdi:monitor-dashboard'}
            visible={checkFeature(27, userRole?.roleAttributes?.featureId)}
          />
          <DataAcqList />
          <UserAdminList />
        </List>
      </Drawer>
    </React.Fragment>
  )
}

export default NavMenu
