import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AgGridReact } from 'ag-grid-react'
import { Avatar } from '@mui/material'
import { unescapeHtml } from 'utils/htmlSymbolHandling'
import { getDrillStringIcon } from 'components/common/drillStringFunctions'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const BhaDetailsModal = ({ bhaData, setOpen }) => {
  const gridApi = useRef(null)
  const { getAgGridTheme } = useInnovaTheme()

  const centerAlignCell = () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  })

  const columnDefs = [
    {
      headerName: '',
      width: 50,
      cellRenderer: (params) => {
        return (
          <Avatar
            src={getDrillStringIcon(params.data?.hasOwnProperty('tfa') ? 'Drill Bit' : 'Motor')}
            style={{ backgroundColor: 'lightgrey', height: '30px', width: '30px', marginTop: '5px' }}
          />
        )
      },
    },
    {
      headerName: 'Component',
      width: 150,
      cellStyle: centerAlignCell,
      valueGetter: (params) => {
        if (params.data?.hasOwnProperty('tfa')) {
          return 'Bit'
        } else {
          return 'Motor'
        }
      },
    },
    {
      headerName: 'Description',
      field: 'description',
      width: 180,
      cellStyle: centerAlignCell,
      valueFormatter: (params) => {
        return unescapeHtml(params.data?.description)
      },
    },
    { headerName: 'Make', field: 'make', width: 150, cellStyle: { textAlign: 'center' } },
    {
      headerName: 'Model',
      field: 'model',
      width: 140,
      cellStyle: centerAlignCell,
    },
    {
      headerName: 'OD',
      field: 'Od',
      width: 100,
      cellStyle: centerAlignCell,
    },
  ]

  const onFirstDataRendered = (params) => {
    autoSizeColumns()
  }

  const autoSizeColumns = () => {
    if (gridApi.current === null) return
    if (gridApi.current.isDestroyed()) return
    setTimeout(() => gridApi.current?.autoSizeAllColumns(),100)
  }

  const defaultColDef = {
    resizable: true,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    headerClass: 'daily-report-col-headers',
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        PaperProps={{
          sx: {
            height: '50vh',
            backgroundColor: 'itemBackground',
          },
        }}
        open={true}
        onClose={() => setOpen(false)}>
        <DialogTitle>BHA Details</DialogTitle>
        <DialogContent>
          <div style={{ height: '100%', width: '100%' }} className={getAgGridTheme()}>
            <AgGridReact
              id='bhaGrid'
              className='ag-grid-theme-dark'
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={bhaData}
              headerHeight={30}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export default BhaDetailsModal
