import * as THREE from 'three'

import React, { useCallback, useRef, useEffect } from 'react'

import { Text, Box } from '@react-three/drei'
import { fonts } from './fonts'
import { numberWithCommasDecimals } from 'utils/stringFunctions'

const GridBox = ({ minMaxValues, showVerticalSides }) => {
  return (
    <React.Fragment>
      {showVerticalSides ? (
        <>
          <Box
            args={[minMaxValues.xLen, minMaxValues.yLen, 0]}
            position={
              new THREE.Vector3(
                minMaxValues.xOffsets.offset,
                minMaxValues.yOffsets.offset,
                minMaxValues.zLen / 2 + minMaxValues.zOffsets.offset,
              )
            }>
            <meshBasicMaterial attach='material' color={0x6495ed} transparent={true} opacity={0.25} />
          </Box>
          <Box
            args={[0, minMaxValues.yLen, minMaxValues.zLen]}
            position={
              new THREE.Vector3(
                minMaxValues.xLen / 2 + minMaxValues.xOffsets.offset,
                minMaxValues.yOffsets.offset,
                minMaxValues.zOffsets.offset,
              )
            }>
            <meshBasicMaterial attach='material' color={0x6495ed} transparent={true} opacity={0.25} />
          </Box>
        </>
      ) : null}
      <Box
        args={[minMaxValues.xLen, 0, minMaxValues.zLen]}
        position={new THREE.Vector3(minMaxValues.xOffsets.offset, minMaxValues.minY, minMaxValues.zOffsets.offset)}>
        <meshBasicMaterial attach='material' color={0x3cb382} transparent={true} opacity={0.25} />
      </Box>
    </React.Fragment>
  )
}

export function getMinMaxValues(data, minMaxValues) {
  if (data) {
    data.forEach((point) => {
      updateMinMaxValues(point, minMaxValues)
    })
  }
}

function updateMinMaxValues(pt, minMaxValues) {
  if (!pt) return
  if (pt.x < minMaxValues.minX) minMaxValues.minX = pt.x
  if (pt.x > minMaxValues.maxX) minMaxValues.maxX = pt.x
  if (pt.y < minMaxValues.minY) minMaxValues.minY = pt.y
  if (pt.y > minMaxValues.maxY) minMaxValues.maxY = pt.y
  if (pt.z < minMaxValues.minZ) minMaxValues.minZ = pt.z
  if (pt.z > minMaxValues.maxZ) minMaxValues.maxZ = pt.z
}

export function getMinMaxData(minMaxValues, refData, offsetData, tgtData = null, templateData = null, bhaData = null) {
  resetMinMaxValues(minMaxValues)

  if (Array.isArray(refData) && refData?.length > 0) getMinMaxValues(refData[0].data, minMaxValues)
  if (Array.isArray(offsetData) && offsetData?.length > 0) {
    offsetData.forEach((offsetWell) => getMinMaxValues(offsetWell.data, minMaxValues))
  }

  let isTgt = false
  if (tgtData) {
    isTgt = true
    resetMinMaxValues(minMaxValues)
    updateMinMaxValues(tgtData, minMaxValues) //Target Center

    if (Array.isArray(tgtData.points)) {
      tgtData.points.forEach((point) => {
        updateMinMaxValues(point.top, minMaxValues)
        updateMinMaxValues(point.bottom, minMaxValues)
      })
    }
  }

  let isTemplate = false
  if (templateData) {
    isTemplate = true
    resetMinMaxValues(minMaxValues)

    if (Array.isArray(templateData)) {
      templateData.forEach((slot) => {
        updateMinMaxValues(slot, minMaxValues)
      })
    }
  }

  let isBhaData = false
  if (bhaData) {
    isBhaData = true
    resetMinMaxValues(minMaxValues)

    if (Array.isArray(bhaData)) {
      bhaData.forEach((pt) => {
        updateMinMaxValues(pt, minMaxValues)
      })
    }
  }

  let roundingValue = 500
  if(isBhaData) roundingValue = 10

  minMaxValues.xLen = minMaxValues.maxX - minMaxValues.minX
  minMaxValues.yLen = minMaxValues.maxY - minMaxValues.minY
  minMaxValues.zLen = minMaxValues.maxZ - minMaxValues.minZ

  let midX = minMaxValues.minX + minMaxValues.xLen / 2
  let midY = minMaxValues.minY + minMaxValues.yLen / 2
  let midZ = minMaxValues.minZ + minMaxValues.zLen / 2

  if (minMaxValues.xLen < roundingValue) minMaxValues.xLen = roundingValue
  if (minMaxValues.yLen < roundingValue) minMaxValues.yLen = roundingValue
  if (minMaxValues.zLen < roundingValue) minMaxValues.zLen = roundingValue

  minMaxValues.xLen *= 1.1
  minMaxValues.yLen *= 1.1
  minMaxValues.zLen *= 1.1

  minMaxValues.xLen = roundUp(minMaxValues.xLen, roundingValue)
  minMaxValues.yLen = roundUp(minMaxValues.yLen, roundingValue)
  minMaxValues.zLen = roundUp(minMaxValues.zLen, roundingValue)

  minMaxValues.maxX = midX + minMaxValues.xLen / 2
  minMaxValues.minX = midX - minMaxValues.xLen / 2
  minMaxValues.maxY = midY + minMaxValues.yLen / 2
  minMaxValues.minY = midY - minMaxValues.yLen / 2
  minMaxValues.maxZ = midZ + minMaxValues.zLen / 2
  minMaxValues.minZ = midZ - minMaxValues.zLen / 2

  if (!isTgt && !isBhaData) {
    minMaxValues.maxY = 0
    minMaxValues.yLen = minMaxValues.maxY - minMaxValues.minY
  }

  if (isTemplate) {
    minMaxValues.minY = 0
    minMaxValues.maxY = 0
    minMaxValues.yLen = 0
  }

  calcAllOffsets(minMaxValues)
}

export function roundUp(numToRound, multiple) {
  if (multiple === null || multiple === undefined || multiple === 0) {
    return 0
  }

  if (numToRound === null || numToRound === undefined) {
    return 0
  }

  if (typeof multiple !== 'number') {
    multiple = parseFloat(multiple)
  }

  if (typeof numToRound !== 'number') {
    numToRound = parseFloat(numToRound)
  }

  if (isNaN(multiple) || isNaN(numToRound)) {
    return NaN
  }

  let rounded = Math.ceil(numToRound / multiple) * multiple

  if (numToRound < 0) {
    rounded = Math.floor(numToRound / multiple) * multiple
  }

  return rounded
}

export function resetMinMaxValues(minMaxValues) {
  minMaxValues.minX = 999999
  minMaxValues.maxX = -999999
  minMaxValues.minY = 999999
  minMaxValues.maxY = -999999
  minMaxValues.minZ = 999999
  minMaxValues.maxZ = -999999
}

function calcAxisOffset(min, max) {
  let len = Math.abs(max - min)
  let offset = min + len / 2
  return { len: len, offset: offset }
}

function calcAllOffsets(minMaxData) {
  minMaxData.xOffsets = calcAxisOffset(minMaxData.minX, minMaxData.maxX)
  minMaxData.yOffsets = calcAxisOffset(minMaxData.minY, minMaxData.maxY)
  minMaxData.zOffsets = calcAxisOffset(minMaxData.minZ, minMaxData.maxZ)
}

const GridLines = ({
  refData,
  offsetData,
  scale,
  tgtData,
  bhaData,
  templateData,
  showVerticalSides = true,
  labelColor = 0x909090,
  gridColor = 0x909090,
}) => {
  const linesX = []
  const gridLinesRef = useRef()

  const minMaxValues = {
    minX: 0,
    maxX: 100,
    minY: 0,
    maxY: 100,
    minZ: 0,
    maxZ: 100,
    xLen: 100,
    yLen: 100,
    zLen: 100,
  }

  getMinMaxData(minMaxValues, refData, offsetData, tgtData, templateData, bhaData)

  const axisLabelsNS = []
  const axisLabelsEW = []
  const axisLabelsTVDX = []
  const axisLabelsTVDY = []
  const axisLabelsTVDZ = []

  let halfX = minMaxValues.xLen / 2
  let halfZ = minMaxValues.zLen / 2

  let numSteps = 20
  let textOffset = 20
  let axisLabelsOffset = 40
  let fontSizeScale = '8'
  let fontSizeAxisLabels = '20'
  if (minMaxValues.xLen < 500 || minMaxValues.yLen < 500 || minMaxValues.zLen < 500) {
    numSteps = 10
    textOffset = 5
    fontSizeScale = '2'
    fontSizeAxisLabels = '5'
    axisLabelsOffset = 10
  }

  let step = minMaxValues.xLen / numSteps
  let ROUNDING = 0

  // bottom grid
  for (let i = -halfX + minMaxValues.xOffsets.offset; i <= halfX + minMaxValues.xOffsets.offset; i += step) {
    linesX.push(new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset + halfZ))
    linesX.push(new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset - halfZ))

    axisLabelsEW.push({
      label: numberWithCommasDecimals(i / scale, ROUNDING),
      point: new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset + halfZ + textOffset),
    })

    axisLabelsEW.push({
      label: numberWithCommasDecimals(i / scale, ROUNDING),
      point: new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset - halfZ - textOffset),
    })
  }

  step = minMaxValues.zLen / numSteps
  for (let i = -halfZ + minMaxValues.zOffsets.offset; i <= halfZ + minMaxValues.zOffsets.offset; i += step) {
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, minMaxValues.minY, i))
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset - halfX, minMaxValues.minY, i))

    axisLabelsNS.push({
      label: numberWithCommasDecimals(i / scale, ROUNDING),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset + halfX + textOffset, minMaxValues.minY, i),
    })

    axisLabelsNS.push({
      label: numberWithCommasDecimals(i / scale, ROUNDING),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset - halfX - textOffset, minMaxValues.minY, i),
    })
  }

  //Vertical Grids
  if (showVerticalSides) {
    step = Math.abs(minMaxValues.yLen / numSteps)
    for (let i = minMaxValues.minY; i <= minMaxValues.maxY; i += step) {
      linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, i, minMaxValues.zOffsets.offset + halfZ))
      linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, i, minMaxValues.zOffsets.offset - halfZ))

      axisLabelsTVDZ.push({
        label: numberWithCommasDecimals(-i / scale, ROUNDING),
        point: new THREE.Vector3(
          minMaxValues.xOffsets.offset - halfX - textOffset,
          i,
          minMaxValues.zOffsets.offset + halfZ,
        ),
      })

      axisLabelsTVDY.push({
        label: numberWithCommasDecimals(-i / scale, ROUNDING),
        point: new THREE.Vector3(
          minMaxValues.xOffsets.offset + halfX + textOffset,
          i,
          minMaxValues.zOffsets.offset + halfZ,
        ),
      })

      axisLabelsTVDX.push({
        label: numberWithCommasDecimals(-i / scale, ROUNDING),
        point: new THREE.Vector3(
          minMaxValues.xOffsets.offset + halfX + textOffset,
          i,
          minMaxValues.zOffsets.offset - halfZ,
        ),
      })
    }

    for (let i = minMaxValues.minY; i <= minMaxValues.maxY; i += step) {
      linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, i, minMaxValues.zOffsets.offset + halfZ))
      linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset - halfX, i, minMaxValues.zOffsets.offset + halfZ))
    }

    step = minMaxValues.xLen / numSteps
    for (let i = -halfX + minMaxValues.xOffsets.offset; i <= halfX + minMaxValues.xOffsets.offset; i += step) {
      linesX.push(new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset + halfZ))
      linesX.push(new THREE.Vector3(i, minMaxValues.maxY, minMaxValues.zOffsets.offset + halfZ))
    }

    step = minMaxValues.zLen / numSteps
    for (let i = -halfZ + minMaxValues.zOffsets.offset; i <= halfZ + minMaxValues.zOffsets.offset; i += step) {
      linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, minMaxValues.minY, i))
      linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, minMaxValues.maxY, i))
    }
  }

  const points = linesX
  const onUpdate = useCallback(
    (self) => {
      self.setFromPoints(points)
      self.verticesNeedUpdate = true
      self.computeBoundingSphere()
    },
    [points],
  )

  useEffect(() => {
    if (gridLinesRef.current) {
      gridLinesRef.current.onUpdate(gridLinesRef.current)
    }
  }, [points])

  return (
    <React.Fragment>
      <GridBox minMaxValues={minMaxValues} showVerticalSides={showVerticalSides} />
      <lineSegments>
        <bufferGeometry ref={gridLinesRef} attach='geometry' onUpdate={onUpdate} />
        <lineBasicMaterial attach='material' color={gridColor} />
      </lineSegments>
      {axisLabelsNS.map((item, index) => {
        return (
          <Text
            key={`${index}NS`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize={fontSizeScale}
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='+x-z'
          />
        )
      })}
      {axisLabelsEW.map((item, index) => {
        return (
          <Text
            key={`${index}EW`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize={fontSizeScale}
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='-z-x'
            fontScale={0.5}
          />
        )
      })}
      {axisLabelsTVDX.map((item, index) => {
        return (
          <Text
            key={`${index}TVDZ`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize={fontSizeScale}
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='+x+y'
            fontScale={0.5}
          />
        )
      })}
      {axisLabelsTVDY.map((item, index) => {
        return (
          <Text
            key={`${index}TVDY`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize={fontSizeScale}
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='+x+y'
            fontScale={0.5}
          />
        )
      })}
      {axisLabelsTVDZ.map((item, index) => {
        return (
          <Text
            key={`${index}TVDX`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize={fontSizeScale}
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='-z+y'
            fontScale={0.5}
          />
        )
      })}
      <Text
        position-x={minMaxValues.xOffsets.offset}
        position-y={minMaxValues.minY}
        position-z={minMaxValues.zOffsets.offset + halfZ + axisLabelsOffset}
        text={'North / South'}
        font={fonts.Roboto}
        fontSize={fontSizeAxisLabels}
        anchorX='center'
        anchorY='middle'
        orientation='+x-z'
        color={labelColor}
      />
      <Text
        position-x={minMaxValues.xOffsets.offset + halfX + axisLabelsOffset}
        position-y={minMaxValues.minY}
        position-z={minMaxValues.zOffsets.offset}
        text={'East / West'}
        font={fonts.Roboto}
        fontSize={fontSizeAxisLabels}
        anchorX='center'
        anchorY='middle'
        orientation='-z-x'
        color={labelColor}
      />
    </React.Fragment>
  )
}

export default GridLines
