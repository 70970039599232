import React from 'react'
import { Line } from 'react-chartjs-2'
import { Box, CircularProgress } from '@mui/material'
import useUnits, { UNITS_FOR } from '../../../common/hooks/useUnits'
import { appColors } from 'utils'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const LadderChart = ({ data, isLoading }) => {
  const { getUnitsText } = useUnits()
  const { theme, getChartBackColor } = useInnovaTheme()

  const createChartData = () => {
    const chartData = {
      datasets: [],
    }

    if (!data) return chartData
    if (!Array.isArray(data.wells)) return chartData

    let maxX = 0
    data.wells.forEach((well) => {
      let newSeries = {
        type: 'line',
        label: well.wellName,
        borderWidth: 2,
        pointRadius: 1,
        borderColor: appColors.surveyTrajectoryColor,
        backgroundColor: appColors.surveyTrajectoryColor,
        data: [],
      }

      if (Array.isArray(well.acResults)) {
        for (let i = 0; i < well.acResults.length; i++) {
          if (well.acResults[i].refMd > maxX) maxX = well.acResults[i].refMd
          newSeries.data.push({
            x: well.acResults[i].refMd,
            y: well.acResults[i].C2C,
            maxY: well.acResults[i].errorRadius,
            minY: 0,
          })
        }
      }

      chartData.datasets.push(newSeries)
    })

    return chartData
  }

  function createChartOptions() {
    return {
      maintainAspectRatio: false,
      scaleShowLabels: false,
      interaction: {
        intersect: false,
        mode: 'nearest',
      },
      plugins: {
        GradientPlugIn: {
          showGradient: true,
          theme: theme,
        },
        dataLabelPlugin: {
          color: '#ffffff',
          visible: true,
          textAlign: 'right',
        },
        title: {
          display: true,
          text: 'Ladder Plot',
          color: appColors.itemTextColor,
          font: {
            size: 20,
          },
        },
        tooltip: {
          mode: 'nearest',
          intersect: false,
          callbacks: {
            title: function (tooltipItem) {
              return tooltipItem[0].dataset.label
            },
            label: function (context) {
              return [` ${+context.parsed.x.toFixed(2)} MD`, ` ${context.parsed.y.toFixed(2)} C2C`]
            },
          },
          filter: function (tooltipItem, index) {
            if (index > 0) return false
            return true
          },
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        },
        legend: {
          display: false,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'xy',
          },
          limits: {
            x: {
              min: 'original',
              max: 'original',
            },
            y: {
              min: 'original',
              max: 'original',
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'xy',
          },
        },
      },
      scales: {
        x: {
          type: 'linear',
          beginAtZero: true,
          title: {
            display: true,
            text: `Measured Depth (${getUnitsText(UNITS_FOR.Depth)})`,
            color: appColors.itemTextColor,
            font: {
              size: 15,
            },
          },
          ticks: {
            color: appColors.headerTextColor,
          },
          grid: {
            color: appColors.headerTextColor,
          },
        },
        y: {
          type: 'linear',
          beginAtZero: true,
          min: 0,
          title: {
            display: true,
            text: `Center to Center Distance (${getUnitsText(UNITS_FOR.Depth)})`,
            color: appColors.itemTextColor,
            font: {
              size: 15,
            },
          },
          ticks: {
            color: appColors.headerTextColor,
          },
          grid: {
            color: appColors.headerTextColor,
          },
        },
      },
    }
  }

  return (
    data && (
      <Box sx={{ display: 'flex', width: '99.9%', height: '100%', background: getChartBackColor() }}>
        {isLoading ? (
          <CircularProgress
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : null}
        <Line type='line' options={createChartOptions()} data={createChartData()} />
      </Box>
    )
  )
}

export default LadderChart
