import React from 'react'
import MapView from 'components/common/MapView/MapView'
import DonutChartJs from 'components/common/DonutChartJs'
import { GetActivityColor } from 'components/common/activitySelector'

import { Box } from '@mui/material'

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
}

const WellsMapMultiDbPage = ({ filteredWells, selectedWells }) => {
  const getDonutChartData = (property, allowedValues) => {
    if (!allowedValues) return []
    if (!Array.isArray(allowedValues)) return []

    if (!filteredWells) return []
    if (!Array.isArray(filteredWells)) return []
    if (filteredWells.length === 0) return []

    let wellCounts = []
    for (let i = 0; i < filteredWells.length; i++) {
      let wellProp = filteredWells[i].hasOwnProperty(property) ? filteredWells[i][property] : 'N/A'
      if (typeof wellProp !== 'string') wellProp = 'N/A'

      let index = wellCounts.findIndex((stat) => stat.tag === wellProp.toLowerCase())

      if (index >= 0) {
        wellCounts[index].count++
      }

      if (index < 0) {
        wellCounts.push({ label: wellProp, tag: wellProp.toLowerCase(), count: 1 })
      }
    }

    if (allowedValues.length === 0) {
      return wellCounts.map((stat) => {
        return {
          value: stat.count,
          label: stat.label,
        }
      })
    }

    return allowedValues.map((val) => {
      let index = wellCounts.findIndex((count) => count.tag === val.toLowerCase())

      return {
        value: index >= 0 ? wellCounts[index].count : 0,
        color: GetActivityColor(val),
        label: val,
      }
    })
  }

  const DonutChart = ({ param, title }) => {
    let data = getDonutChartData(param, [])

    return (
      <Box sx={{ ...boxStyle }}>
        <DonutChartJs
          title={title}
          data={data}
          centerText={Array.isArray(data) ? data.length : null}
          legendLocation={'right'}
          showLegend={false}
          showTitle={true}
        />
      </Box>
    )
  }

  const getSelectedWells = () => {
    if (!Array.isArray(selectedWells)) return []
    if (!Array.isArray(filteredWells)) return []

    let visibleWells = []
    for (let i = 0; i < selectedWells.length; i++) {
      let filteredWellsIndex = filteredWells.findIndex((well) => well.id === selectedWells[i].id)
      if (filteredWellsIndex >= 0) {
        visibleWells.push(selectedWells[i])
      }
    }

    return visibleWells
  }

  return (
    <Box
      sx={{
        ...boxStyle,
        flexDirection: 'row',
      }}>
      <Box
        sx={{
          ...boxStyle,
          flexDirection: 'row',
          flex: 6,
        }}>
        <MapView wells={getSelectedWells()} />
      </Box>
      <Box
        sx={{
          ...boxStyle,
          flexDirection: 'column',
          flex: 1,
        }}>
        <DonutChart param={'organization'} title={'Organization'} />
        <DonutChart param={'wellStatus'} title={'Well Status'} />
        <DonutChart param={'operator'} title={'Operator'} />
      </Box>
    </Box>
  )
}

export default WellsMapMultiDbPage
