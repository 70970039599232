import React, { useEffect, useState, useRef } from 'react'
import { appColors } from 'utils'
import { Box, Checkbox } from '@mui/material'
import { Icon as Iconify } from '@iconify/react'
import { TreeView } from '@mui/lab'
import formationsIcon from 'assets/wellScreenIcons/formationsGrey.png'
import wellIcon from 'assets/wellScreenIcons/wellHeadGrey.png'
import trajIcon from 'assets/wellScreenIcons/surveys-grey.png'
import logIcon from 'assets/wellScreenIcons/edr-grey.png'
import { cloneDeep } from 'lodash'
import TreeNode from 'components/DatabaseTree/DatabaseTree/TreeNode'
import { MinusSquare, PlusSquare } from 'components/DatabaseTree/DatabaseTree/DatabaseTree'
import plannedWellIcon from 'assets/wellScreenIcons/schematicBlue.png'
import actualWellIcon from 'assets/wellScreenIcons/actualWell.png'
import useInnovaTheme from 'components/common/hooks/useInnovaTheme'

const TREE_ICON_SIZE = 20
const TREE_ICON_PADDING = 10

export const SOLO_CLOUD_TREE_LEVELS = {
  SOLO_CLOUD: 'Solo Cloud',
  PROJECT: 'project',
  WELL: 'well',
  TARGET_LINE_HEADER: 'targetLineHeader',
  TARGET_LINE: 'targetLine',
  TOP_SET: 'topSet',
  TOP_HEADER: 'topHeader',
  PLAN_HEADER: 'planHeader',
  PLAN: 'plan',
}

export const createNodeId = (uuid, parentUuid) => {
  return uuid + '-' + parentUuid
}

const bfsSearch = (graph, targetId) => {
  const queue = [...graph]

  while (queue.length > 0) {
    const currNode = queue.shift()
    if (currNode.nodeId === targetId) {
      return currNode
    }
    if (currNode.children) {
      queue.push(...currNode.children)
    }
  }
  return []
}

const SoloCloudTree = ({
  treeData,
  fetchWells,
  fetchTargetLines,
  fetchTopSets,
  fetchPlans,
  checkedNodes,
  setCheckedNodes,
  setActiveNode,
  config,
  shouldUpdate,
  fetchStarredObjects,
}) => {
  const _isMounted = useRef(false)
  const initialSelectionSet = useRef(false)
  const [expandedNodes, setExpandedNodes] = useState([SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD])
  const { getBackColor } = useInnovaTheme()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  const setInitialSelection = async () => {
    if (!Array.isArray(treeData.current)) return
    if (treeData.current.length === 0) return

    initialSelectionSet.current = true
    let initalSelection = []
    let initialExpanded = [getRootNodeId()]

    let projectNodeId = ''
    let wellNodeId = ''

    let projectNode = {}
    let wellNode = {}

    if (config?.projectUuid && config?.projectUuid !== '') {
      projectNodeId = createNodeId(config.projectUuid, SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD)
      let nodeProject = bfsSearch(treeData.current, projectNodeId)
      if (nodeProject?.nodeId) {
        projectNode = {
          children: [],
          nodeId: nodeProject.nodeId,
          uuid: nodeProject.uuid,
          level: SOLO_CLOUD_TREE_LEVELS.PROJECT,
          name: nodeProject.name,
          parentUuid: SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD,
          parentNodeId: SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD,
        }

        initalSelection.push(projectNode)

        if (config?.lateralUuid && config?.lateralUuid !== '') {
          let wellRes = await fetchWells({
            uuid: config.projectUuid,
            nodeId: createNodeId(config.projectUuid, SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD),
          })

          if (wellRes?.error) return

          wellNodeId = createNodeId(config.lateralUuid, projectNode.uuid)
          let nodeWell = bfsSearch(treeData.current, wellNodeId)

          if (nodeWell?.nodeId) {
            wellNode = {
              children: [],
              nodeId: nodeWell.nodeId,
              uuid: nodeWell.uuid,
              level: SOLO_CLOUD_TREE_LEVELS.WELL,
              name: nodeWell.name,
              parentUuid: projectNode.uuid,
              parentNodeId: projectNode.nodeId,
            }
            projectNode.children.push(wellNode)
            initialExpanded.push(projectNode.nodeId)
            initalSelection.push(wellNode)

            if (config?.targetLineUuid && config?.targetLineUuid !== '') {
              initialExpanded.push('targetLineHeader' + wellNode.nodeId)

              let targetLineRes = await fetchTargetLines({
                uuid: config.lateralUuid,
                nodeId: createNodeId(config.lateralUuid, projectNode.uuid),
                parentUuid: config.projectUuid,
                parentNodeId: createNodeId(config.projectUuid, SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD),
              })

              if (targetLineRes?.error) return

              let targetLineNodeId = createNodeId(config.targetLineUuid, wellNode.uuid)
              let nodeTargetLine = bfsSearch(treeData.current, targetLineNodeId)

              if (nodeTargetLine?.nodeId) {
                let targetLineNode = {
                  children: [],
                  nodeId: nodeTargetLine.nodeId,
                  uuid: nodeTargetLine.uuid,
                  level: SOLO_CLOUD_TREE_LEVELS.TARGET_LINE,
                  name: nodeTargetLine.name,
                  parentUuid: wellNode.uuid,
                  parentNodeId: wellNode.nodeId,
                }
                wellNode.children.push(targetLineNode)
                initialExpanded.push(wellNode.nodeId)
                initalSelection.push(targetLineNode)
              }
            }
          }
        }
      }
    }

    setCheckedNodes(initalSelection)
    setExpandedNodes(initialExpanded)
  }

  useEffect(() => {
    if (!initialSelectionSet.current) setInitialSelection()
  }, [config, shouldUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleNodeExpanded = (nodeId) => {
    let newNodes = [...expandedNodes]
    if (newNodes.includes(nodeId)) {
      newNodes.splice(newNodes.indexOf(nodeId), 1)
    } else {
      newNodes.push(nodeId)
    }

    setExpandedNodes(newNodes)
  }

  function getAllIds(node, idList = []) {
    idList.push(node.nodeId)
    if (node.children) {
      node.children.forEach((child) => getAllIds(child, idList))
    }
    return idList
  }

  const getAllChildNodes = (id) => {
    return getAllIds(bfsSearch(treeData.current, id))
  }

  const getParentNodes = (id, list = []) => {
    const node = bfsSearch(treeData.current, id)
    if (node.parentNodeId !== undefined && node.parentNodeId !== null && node.parentNodeId !== '') {
      list.push(node.parentNodeId)
      return getParentNodes(node.parentNodeId, list)
    }

    return list
  }

  const isNodeChecked = (nodeId) => {
    if (!Array.isArray(checkedNodes)) return false
    let index = checkedNodes.findIndex((n) => n.nodeId === nodeId)
    if (index >= 0) return true
    return false
  }

  const isNodeCheckedAtLevel = (level) => {
    if (!Array.isArray(checkedNodes)) return false
    return checkedNodes.findIndex((n) => n.level === level) >= 0
  }

  const getChildLevels = (level) => {
    const { PROJECT, WELL, TARGET_LINE, TARGET_LINE_HEADER, TOP_SET, TOP_HEADER, PLAN, PLAN_HEADER } =
      SOLO_CLOUD_TREE_LEVELS

    if (level === PROJECT)
      return [PROJECT, WELL, TARGET_LINE, TARGET_LINE_HEADER, TOP_SET, TOP_HEADER, PLAN, PLAN_HEADER]
    if (level === WELL) return [WELL, TARGET_LINE, TARGET_LINE_HEADER, TOP_SET, TOP_HEADER, PLAN, PLAN_HEADER]
    if (level === TARGET_LINE_HEADER) return [TARGET_LINE_HEADER, TARGET_LINE]
    if (level === TARGET_LINE) return [TARGET_LINE]
    if (level === TOP_HEADER) return [TOP_HEADER, TOP_SET]
    if (level === TOP_SET) return [TOP_SET]
    if (level === PLAN_HEADER) return [PLAN_HEADER, PLAN]
    if (level === PLAN) return [PLAN]
    return []
  }

  const handleNodeSelect = (event, nodeId) => {
    event.stopPropagation()

    //If node is checked unselect node and all children
    if (isNodeChecked(nodeId)) {
      let prevCheckedNodes = cloneDeep(checkedNodes)
      if (!Array.isArray(prevCheckedNodes)) prevCheckedNodes = []

      let index = prevCheckedNodes.findIndex((n) => n.nodeId === nodeId)
      if (index >= 0) prevCheckedNodes.splice(index, 1)

      const childNodes = getAllChildNodes(nodeId)
      for (let i = 0; i < childNodes.length; i++) {
        if (!isNodeChecked(childNodes[i])) continue
        let index = prevCheckedNodes.findIndex((n) => n.nodeId === childNodes[i])
        if (index < 0) continue
        prevCheckedNodes.splice(index, 1)
      }

      setCheckedNodes(prevCheckedNodes)
      return
    }

    //If node is unchecked the check all parents
    let node = bfsSearch(treeData.current, nodeId)
    if (node?.nodeId === undefined || node?.nodeId === null || node?.nodeId === '') return

    let prevCheckedNodes = cloneDeep(checkedNodes)
    if (!Array.isArray(prevCheckedNodes)) prevCheckedNodes = []

    //If node alread selectd at current level uncheck and uncheck all its children
    if (isNodeCheckedAtLevel(node.level)) {
      let levelsToRemove = getChildLevels(node.level)
      prevCheckedNodes = prevCheckedNodes.filter((n) => levelsToRemove.findIndex((l) => l === node.level) < 0)
      prevCheckedNodes = prevCheckedNodes.filter((n) => levelsToRemove.findIndex((l) => l === n.level) < 0)
    }

    prevCheckedNodes.push({ nodeId: node.nodeId, uuid: node.uuid, level: node.level, name: node.name })

    const parentNodes = getParentNodes(nodeId)
    for (let i = 0; i < parentNodes.length; i++) {
      let node = bfsSearch(treeData.current, parentNodes[i])
      if (!node) continue
      if (prevCheckedNodes.findIndex((n) => n.nodeId === node.nodeId) >= 0) continue

      prevCheckedNodes.push({ nodeId: node.nodeId, uuid: node.uuid, level: node.level, name: node.name })
    }

    setCheckedNodes(prevCheckedNodes)
  }

  const getRootNodeId = () => {
    return SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD
  }

  const isNodeExpanded = (id) => {
    return expandedNodes.includes(id)
  }

  const TreeNodeIcon = ({ nodeId, icon, starred }) => {
    return (
      <React.Fragment>
        <Checkbox
          sx={{ margin: 0, padding: 0 }}
          checked={checkedNodes.findIndex((n) => n.nodeId === nodeId) >= 0}
          tabIndex={-1}
          disableRipple
          onClick={(event) => {
            handleNodeSelect(event, nodeId)
          }}
        />
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${TREE_ICON_SIZE}px`,
            height: `${TREE_ICON_SIZE}px`,
            marginRight: `${TREE_ICON_PADDING}px`,
            padding: '1px',
            border: starred ? `1px solid tomato` : 'none',
          }}
        />
      </React.Fragment>
    )
  }

  const TreeNodeIconHeader = ({ icon }) => {
    return (
      <React.Fragment>
        <img
          alt={'Icon'}
          src={icon}
          style={{
            width: `${TREE_ICON_SIZE}px`,
            height: `${TREE_ICON_SIZE}px`,
            paddingRight: `${TREE_ICON_PADDING}px`,
          }}
        />
      </React.Fragment>
    )
  }

  const TargetLineNodeHeader = ({ well }) => {
    return (
      <TreeNode
        key={'targetLineHeader' + well.nodeId}
        nodeId={'targetLineHeader' + well.nodeId}
        labelText='Target Line'
        onLabelClick={() => setActiveNode(well, SOLO_CLOUD_TREE_LEVELS.TARGET_LINE_HEADER)}
        labelIcon={<TreeNodeIconHeader icon={trajIcon} />}
        onIconClick={async (event) => {
          event.stopPropagation()
          if (!isNodeExpanded('targetLineHeader' + well.nodeId)) {
            await fetchTargetLines(well)
          }
          handleToggleNodeExpanded('targetLineHeader' + well.nodeId)
        }}>
        <TargetLineNodes wellChildren={well.children} />
      </TreeNode>
    )
  }

  const TopSetNodeHeader = ({ well }) => {
    return (
      <TreeNode
        key={'topSetHeader' + well.nodeId}
        nodeId={'topSetHeader' + well.nodeId}
        labelText='Top Set'
        onLabelClick={() => setActiveNode(well, SOLO_CLOUD_TREE_LEVELS.TOP_HEADER)}
        labelIcon={<TreeNodeIconHeader icon={logIcon} />}
        onIconClick={async (event) => {
          event.stopPropagation()
          if (!isNodeExpanded('topSetHeader' + well.nodeId)) {
            await fetchTopSets(well)
          }
          handleToggleNodeExpanded('topSetHeader' + well.nodeId)
        }}>
        <TopSetNodes wellChildren={well.children} />
      </TreeNode>
    )
  }

  const PlanNodeHeader = ({ well }) => {
    return (
      <TreeNode
        key={'planHeader' + well.nodeId}
        nodeId={'planHeader' + well.nodeId}
        labelText='Plan'
        onLabelClick={() => {
          setActiveNode(well, SOLO_CLOUD_TREE_LEVELS.PLAN_HEADER)
        }}
        labelIcon={<TreeNodeIconHeader icon={plannedWellIcon} />}
        onIconClick={async (event) => {
          event.stopPropagation()
          if (!isNodeExpanded('planHeader' + well.nodeId)) {
            await fetchPlans(well)
          }
          handleToggleNodeExpanded('planHeader' + well.nodeId)
        }}>
        <PlanNodes wellChildren={well.children} />
      </TreeNode>
    )
  }

  const TargetLineNodes = ({ wellChildren }) => {
    return Array.isArray(wellChildren)
      ? wellChildren.map((child) => {
          return child.level === SOLO_CLOUD_TREE_LEVELS.TARGET_LINE ? (
            <TreeNode
              key={`${child.nodeId}`}
              nodeId={`${child.nodeId}`}
              labelText={child.name}
              onLabelClick={() => setActiveNode(child, child.level)}
              labelIcon={<TreeNodeIcon nodeId={child.nodeId} icon={trajIcon} starred={child.starred} />}
            />
          ) : null
        })
      : null
  }

  const TopSetNodes = ({ wellChildren }) => {
    return Array.isArray(wellChildren)
      ? wellChildren.map((child) => {
          return child.level === SOLO_CLOUD_TREE_LEVELS.TOP_SET ? (
            <TreeNode
              key={`${child.nodeId}`}
              nodeId={`${child.nodeId}`}
              labelText={child.name}
              onLabelClick={() => setActiveNode(child, child.level)}
              labelIcon={<TreeNodeIcon nodeId={child.nodeId} icon={formationsIcon} starred={child.starred} />}
            />
          ) : null
        })
      : null
  }

  const PlanNodes = ({ wellChildren }) => {
    return Array.isArray(wellChildren)
      ? wellChildren.map((child) => {
          return child.level === SOLO_CLOUD_TREE_LEVELS.PLAN ? (
            <TreeNode
              key={`${child.nodeId}`}
              nodeId={`${child.nodeId}`}
              labelText={child.name}
              onLabelClick={() => setActiveNode(child, child.level)}
              labelIcon={<TreeNodeIcon nodeId={child.nodeId} icon={plannedWellIcon} starred={child.starred} />}
            />
          ) : null
        })
      : null
  }

  const WellNodes = ({ wells }) => {
    return Array.isArray(wells)
      ? wells.map((well) => (
          <TreeNode
            key={`${well.nodeId}`}
            nodeId={`${well.nodeId}`}
            endIcon={<PlusSquare />}
            labelText={well.name}
            labelIcon={<TreeNodeIcon nodeId={well.nodeId} icon={actualWellIcon} />}
            onLabelClick={() => setActiveNode(well, well.level)}
            onIconClick={async (event) => {
              event.stopPropagation()
              fetchStarredObjects(well)
              handleToggleNodeExpanded(`${well.nodeId}`)
            }}>
            <TargetLineNodeHeader well={well} />
            <TopSetNodeHeader well={well} />
            <PlanNodeHeader well={well} />
          </TreeNode>
        ))
      : null
  }

  const ProjectNodes = ({ projects }) => {
    return Array.isArray(projects)
      ? projects.map((project) => (
          <TreeNode
            key={`${project.nodeId}`}
            nodeId={`${project.nodeId}`}
            endIcon={<PlusSquare />}
            labelText={project.name}
            labelIcon={<TreeNodeIcon nodeId={project.nodeId} icon={wellIcon} />}
            onLabelClick={() => setActiveNode(project, project.level)}
            onIconClick={async (event) => {
              event.stopPropagation()
              if (!isNodeExpanded(`${project.nodeId}`)) {
                await fetchWells(project)
              }

              handleToggleNodeExpanded(`${project.nodeId}`)
            }}>
            <WellNodes wells={project.children} />
          </TreeNode>
        ))
      : null
  }

  return (
    <Box sx={{ overflow: 'auto', width: '100%', height: `calc(100% - 175px)`, backgroundColor: getBackColor() }}>
      <TreeView
        aria-label='controlled'
        expanded={expandedNodes}
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}>
        <TreeNode
          labelText={getRootNodeId()}
          labelIcon={
            <Iconify
              icon='eos-icons:database'
              style={{
                width: `${TREE_ICON_SIZE}px`,
                height: `${TREE_ICON_SIZE}px`,
                paddingRight: `${TREE_ICON_PADDING}px`,
                color: appColors.headerTextColor,
              }}
            />
          }
          nodeId={getRootNodeId()}
          onLabelClick={() => setActiveNode(getRootNodeId(), SOLO_CLOUD_TREE_LEVELS.SOLO_CLOUD)}
          onIconClick={async (event) => {
            event.stopPropagation()
            handleToggleNodeExpanded(getRootNodeId())
          }}>
          <ProjectNodes projects={treeData.current} />
        </TreeNode>
      </TreeView>
    </Box>
  )
}

export default SoloCloudTree
